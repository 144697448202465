import React from 'react'
import StorePaymentContainer from '../../../components/market/store/StorePaymentContainer'
import StorePaymentHeader from '../../../components/market/store/StorePaymentHeader'

const StorePaymentScreen = () => {
  return (
    <>
      <div className='payment'>
        <StorePaymentHeader />
        <StorePaymentContainer />
      </div>
    </>
  )
}

export default StorePaymentScreen