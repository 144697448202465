import React, { useContext, useEffect, useState } from 'react';
import { animated } from 'react-spring';
import { useTransition } from '@react-spring/core';
import { CSSTransition } from 'react-transition-group';
import { ToastContainer, toast } from 'react-toastify';
import Swal from 'sweetalert2';
import dailyduck from './MyContainner.module.css';
import CustomerWrite from '../../components/my/CustomerWrite';
import { useNavigate } from 'react-router-dom';
import { LoginContext } from '../../contexts/LoginContextProvider';
import * as support from '../../apis/support';
import * as auth from '../../apis/auth';
import { format } from 'date-fns';
import Lightbox from 'react-image-lightbox-rotate-latest';
import 'react-image-lightbox-rotate-latest/style.css'; // 이 스타일은 lightbox CSS 입니다.

const MyCustomerService = () => {
  const navigate = useNavigate();
  const { isLogin, login, logout, userInfo, remove } = useContext(LoginContext);
  const [activeButton, setActiveButton] = useState('customer');
  const [showCustomerWrite, setShowCustomerWrite] = useState(false);

  const [customerList, setCustomerList] = useState([]);
  const [answerList, setAnswerList] = useState([]);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);

  useEffect(() => {
    inquirySelect();
  }, [userInfo]);

  const inquirySelect = async () => {
    const response = await support.inquirySelect(userInfo?.userId);
    const data = response.data;

    setCustomerList(data);
  };

  const findAnswer = (customerNo) => {
    const answer = answerList.find(answer => answer.customer === customerNo);
    return answer ? answer.content : null;
  };

  const handleCSBack = () => {
    navigate('/my');
  };

  const [openStates, setOpenStates] = useState(customerList.map(() => false));

  const toggleOpen = (index) => {
    const newOpenStates = [...openStates];
    newOpenStates[index] = !newOpenStates[index];
    setOpenStates(newOpenStates);
  };

  const onDelete = async (no) => {
    const result = await Swal.fire({
      customClass: {
        container: `${dailyduck.favority_delete_container}`,
        confirmButton: `${dailyduck.favority_delete_confirm}`,
        cancelButton: `${dailyduck.favority_delete_cancle}`
      },
      title: '삭제',
      html: `<p>문의글을 삭제하시겠습니까?</p>`,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '예',
      cancelButtonText: '아니오',
      reverseButtons: true
    });

    if (result.isConfirmed) {
      try {
        // let data = 'SUCCESS';
        const response = await auth.customerDelete(no);
        const data = response.data;

        if (data === 'SUCCESS') {
          toast.success('해당 항목이 성공적으로 삭제되었습니다.', {
            icon: false,
            hideProgressBar: true,
          });

          inquirySelect();
        }
      } catch (error) {
        toast.error('삭제 중 오류가 발생했습니다.', {
          icon: false,
          hideProgressBar: true,
        });
      }
    }
  };

  const onCustomerWrite = () => {
    setShowCustomerWrite(true);
  };

  const formatDate = (date) => {
    return format(new Date(date), 'yyyy-MM-dd HH:mm:ss');
  };

  const openLightbox = (image) => {
    setCurrentImage(image);
    setIsLightboxOpen(true);
  };

  return (
    <>
      <div className={`${dailyduck.my_customner_container}`}>
        <div className={dailyduck.my_customner_box}>
          <div className={`${dailyduck.my_customner_header}`}>
            <div>
              <button onClick={() => handleCSBack()}>
                <span className="material-symbols-outlined">arrow_back_ios</span>
              </button>
            </div>
            <div>
              <div>
                <span>1:1 문의</span>
              </div>
            </div>
          </div>
          <div className={`${dailyduck.my_customner_tab}`}>
            <div className='d-flex w-100'>
              <div className={`w-100 ${dailyduck.my_customer_service_btn} ${activeButton === 'customer' ? dailyduck.my_customer_service_active : ''}`}>
                <button onClick={() => setActiveButton('customer')}>1:1 문의하기</button>
              </div>
              <div className={`w-100 ${dailyduck.my_customer_service_btn} ${activeButton === 'mycustomer' ? dailyduck.my_customer_service_active : ''}`}>
                <button onClick={() => setActiveButton('mycustomer')}>나의 문의내역</button>
              </div>
            </div>
          </div>
          {activeButton === 'customer' &&
          <>
            <div className={`${dailyduck.my_customer_service_content}`}>
              <div className='pt-2'><span>상담시간</span></div>
              <div className='pt-3'><span>평일(월 ~ 금) 10:00 ~ 17:00</span></div>
              <div className='pt-2'><span>(Off-time 12:30 ~ 13:30, 토/일/공휴일 휴무)</span></div>
              <div className='pt-3'><span>* 한 번 등록된 상담 내용은 수정이 불가합니다.</span></div>
            </div>
            <div className={`${dailyduck.my_customer_service_footer_btn}`}>
              <button onClick={() => onCustomerWrite()}>1:1문의 작성하기</button>
            </div>
          </>
          }
          {activeButton === 'mycustomer' &&
          customerList.length !== 0 ?
            customerList.map((item, index) => (
              <div key={index}>
                <div className={`${dailyduck.my_customer_my_box} py-3 border-bottom px-3`}>
                  <div className={`${dailyduck.my_customer_my_content}`}>
                    <button onClick={() => toggleOpen(index)}>
                      <div className={`py-1 ${dailyduck.gray_color} dd-fs-7`}><span>{formatDate(item.inquiryRegDate)}</span></div>
                      <div className='py-1'><span>{item.inquiryTitle}</span></div>
                      <div className={`py-1 ${dailyduck.gray_color} dd-fs-7`}><span>{item.inquiryState}</span></div>
                    </button>
                  </div>
                  <div className={`${dailyduck.my_customer_my_delete}`}>
                    <button onClick={() => onDelete(item.inquiryNo)}>삭제</button>
                  </div>
                </div>
                <CSSTransition in={openStates[index]} timeout={500} unmountOnExit>
                  <div className='py-3 bg-body-tertiary px-3'>
                    <div className='pb-1'><span>Q.</span></div>
                    <div dangerouslySetInnerHTML={{ __html: item.inquiryContent }}></div>
                    <div className={`${dailyduck.my_customer_my_img_box} pb-4`}>
                      {item.inquiryImg1 && <div><img src={item.inquiryImg1} alt="" onClick={() => openLightbox(item.inquiryImg1)} /></div>}
                      {item.inquiryImg2 && <div><img src={item.inquiryImg2} alt="" onClick={() => openLightbox(item.inquiryImg2)} /></div>}
                      {item.inquiryImg3 && <div><img src={item.inquiryImg3} alt="" onClick={() => openLightbox(item.inquiryImg3)} /></div>}
                      {item.inquiryImg4 && <div><img src={item.inquiryImg4} alt="" onClick={() => openLightbox(item.inquiryImg4)} /></div>}
                    </div>
                    <div className='pb-1'><span>A.</span></div>
                    <div dangerouslySetInnerHTML={{ __html: item.inquiryAnswer || '답변을 기다리는 중입니다.' }}></div>
                  </div>
                </CSSTransition>
              </div>
            )) :
            activeButton === 'mycustomer' &&
            <div className={`${dailyduck.my_customer_no_data}`}><span>1:1 문의 내역이 없습니다.</span></div>
          }
          <ToastContainer
            position="top-center"
            autoClose={500}
            hideProgressBar={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </div>
      </div>
      {showCustomerWrite &&
        <CustomerWrite dailyduck={dailyduck} showCustomerWrite={showCustomerWrite} setShowCustomerWrite={setShowCustomerWrite} userInfo={userInfo} support={support} inquirySelect={inquirySelect}/>
      }
      {isLightboxOpen && (
        <Lightbox
          mainSrc={currentImage}
          onCloseRequest={() => setIsLightboxOpen(false)}
        />
      )}
    </>
  );
};

export default MyCustomerService;
