import api from "./api";


// 메세지 목록 조회
export const messageListSelect = (userId) => api.get(`/api/chat/messageList/${userId}`);

// 메세지 내용 조회
export const messageContentSelect = (roomId) => api.get(`/api/chat/messageContentSelect/${roomId}`);

// 메세지 전송
export const sendAdd = (roomId, userId, sellerId, content, productNumber) => api.post(`/api/chat/messageSendAdd`, {roomId:roomId, userId:userId, sellerId:sellerId, content:content, productNumber:productNumber});

// 셀러 메세지 목록 조회
export const sellerMessageListSelect = (userId) => api.get(`/api/chat/sellerMessageList/${userId}`);

// 셀러 메세지 전송
export const sellerSendAdd = (roomId, userId, sellerId, content) => api.post(`/api/chat/sellerMessageSendAdd`, {roomId:roomId, userId:userId, sellerId:sellerId, content:content});

// 파일 업로드
export const uploadFile = (formData, config) => api.post(`/api/chat/fileUpload`, formData, config);

// 셀러 파일 업로드
export const sellerUploadFile = (formData, config) => api.post(`/api/chat/sellerUploadFile`, formData, config);

// 셀러 읽음 처리 업데이트
export const stateUpdate = (roomId) => api.put(`/api/chat/sellerStateUpdate/${roomId}`);

// 셀러 결제 요청
export const sellerPaymentSendAdd = (roomId, userId, sellerId, paymentMessage, paymentPrice) => api.post(`/api/chat/sellerPaymentSendAdd`, {roomId:roomId, userId:userId, sellerId:sellerId, paymentMessage:paymentMessage, paymentPrice:paymentPrice});

// 셀러 결제 취소 업데이트
export const paymentCancelUpdate = (messageNo) => api.put(`/api/chat/paymentCancelUpdate?messageNo=${messageNo}`);
