import React, { useContext, useEffect, useState } from 'react';
import admin from '../../../admin.module.css';
import * as adminApi from '../../../../apis/admin';
import { Pagination } from 'antd';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { LoginContext } from '../../../../contexts/LoginContextProvider';
import { format } from 'date-fns';

const AdminProceed = () => {
    const { userInfo } = useContext(LoginContext);
    const MySwal = withReactContent(Swal);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(7);
    const [totalItems, setTotalItems] = useState(0);

    const [settleList, setSettleList] = useState([]);
    const [filteredSettleList, setFilteredSettleList] = useState([]);
    
    const [totalPaymentAmount, setTotalPaymentAmount] = useState(0);
    const [totalFees, setTotalFees] = useState(0);
    const [totalSettlementAmount, setTotalSettlementAmount] = useState(0);

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        settleSelect();
    }, []);

    useEffect(() => {
        filterData();
    }, [startDate, endDate, searchText, settleList]);

    const settleSelect = async () => {
        const response = await adminApi.settleSelect(userInfo && userInfo?.userId);
        const data = response.data;

        // console.log('profitSelect : ', data);
        setSettleList(data);

        // 초기 데이터로 계산된 총합을 설정
        calculateTotals(data);
    };

    const calculateTotals = (data) => {
        if (data !== '') {
            const totalFees = data
                .filter(item => item.withdrawalState === "출금완료")
                .reduce((acc, item) => acc + item.totalSettlementAmount, 0);
    
            const totalSettlementAmount = data
                .filter(item => item.withdrawalState === "출금신청")
                .reduce((acc, item) => acc + item.totalSettlementAmount, 0);
    
            setTotalFees(totalFees);
            setTotalSettlementAmount(totalSettlementAmount);
        }
    };

    const filterData = () => {
        let filteredData = settleList;
    
        // 날짜 필터링
        if (startDate && endDate) {
            filteredData = filteredData.filter(item => {
                const settleDate = new Date(item.periodRegDate).setHours(0, 0, 0, 0); // 시간 제거
                const start = new Date(startDate).setHours(0, 0, 0, 0);
                const end = new Date(endDate).setHours(23, 59, 59, 999); // 종료일의 끝까지 포함
    
                return settleDate >= start && settleDate <= end;
            });
        }
    
        // 텍스트 필터링
        if (searchText) {
            filteredData = filteredData.filter(item =>
                item.sellerId.toLowerCase().includes(searchText.toLowerCase())
            );
        }
    
        // 필터된 데이터로 총합 계산
        calculateTotals(filteredData);
        setFilteredSettleList(filteredData);
        setTotalItems(filteredData.length);
    };

    const handleDateChange = (e, isStart) => {
        if (isStart) {
            setStartDate(e.target.value);
        } else {
            setEndDate(e.target.value);
        }
    };

    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
    };

    const formatDate = (date) => {
        return format(new Date(date), 'yyyy-MM-dd HH:mm:ss');
    };

    // 페이지네이션을 위한 현재 아이템들 계산
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredSettleList.slice(indexOfFirstItem, indexOfLastItem);

    const onWithdrawal = (item) => {
        MySwal.fire({
            customClass: {
              popup: `${admin.admin_seller_add_popup}`
            },
            html: `
                  <div>
                      <div class='${admin.admin_seller_add_title}'><span>출금을 신청하시겠습니까?</span></div>
                      <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                        <button class='mx-1' id='seller_add_cancel'>취소</button>
                        <button class='mx-1' id='seller_add_success'>신청</button>
                      </div>
                  </div>
              `,
            showConfirmButton: false,
            showCancelButton: false,
          });
          document.addEventListener('click', sellerAddSuccess);
          document.addEventListener('click', sellerAddCancel);
      
          async function sellerAddSuccess(e) {
            if (e.target && e.target.id === 'seller_add_success') {
              try {
                const response = await adminApi.withdrawalRequest(item);
                const data = response.data;

                if (data === 'SUCCESS') {
                  MySwal.fire({
                    customClass: {
                      popup: `${admin.admin_seller_add_popup}`
                    },
                    html: `
                              <div>
                                <div class='${admin.admin_seller_add_title}'><span>출금 신청이 완료되었습니다.</span></div>
                                <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                    <button class='mx-1' id='seller_add_confirm'>확인</button>
                                </div>
                              </div>
                              `,
                    showConfirmButton: false,
                    showCancelButton: false,
                  });
                  document.addEventListener('click', sellerAddConfirm);
      
                  function sellerAddConfirm(e) {
                    if (e.target && e.target.id === 'seller_add_confirm') {
                        settleSelect();
                        MySwal.close();
                        document.removeEventListener('click', sellerAddConfirm);
                        document.removeEventListener('click', sellerAddSuccess);
                        document.removeEventListener('click', sellerAddCancel);
                    }
                  }
                } else {
                  MySwal.fire({
                    customClass: {
                      popup: `${admin.admin_seller_add_popup}`
                    },
                    html: `
                              <div>
                                <div class='${admin.admin_seller_add_title}'><span>출금 신청에 실패하였습니다.</span></div>
                                <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                    <button class='mx-1' id='seller_add_confirm'>확인</button>
                                </div>
                              </div>
                              `,
                    showConfirmButton: false,
                    showCancelButton: false,
                  });
                  document.addEventListener('click', sellerAddConfirm);
      
                  function sellerAddConfirm(e) {
                    if (e.target && e.target.id === 'seller_add_confirm') {
                      MySwal.close();
                      document.removeEventListener('click', sellerAddConfirm);
                      document.removeEventListener('click', sellerAddSuccess);
                      document.removeEventListener('click', sellerAddCancel);
                    }
                  }
                }
              } catch (error) {
                console.error('error : ', error);
              }
            }
          }
      
          function sellerAddCancel(e) {
            if (e.target && e.target.id === 'seller_add_cancel') {
              MySwal.close();
              document.removeEventListener('click', sellerAddSuccess);
              document.removeEventListener('click', sellerAddCancel);
            }
          }
    }

    return (
        <div className={`${admin.admin_seller_box} px-3 py-3`}>
            <div>
                <div className={`${admin.admin_calculate_header}`}>
                    <div><span>정산관리</span></div>
                </div>
                {/* <div className={`${admin.admin_calculate_title}`}><span>수익금 내역</span></div> */}
                {
                    settleList.length > 0 ?
                    <div>
                        <div>
                            <div className={`${admin.admin_calculate_proceed_total_box}`}>
                                <div>
                                    <div><span>셀러 판매 순위</span></div>
                                    <div>
                                        <div><span>0위</span></div>
                                        <div><span>카테고리 명</span></div>
                                    </div>
                                    <div>
                                        <div><span>적용된 정산 기간 :</span></div>&nbsp;
                                        {
                                            startDate === '' && endDate === '' ?
                                            <div>
                                                <span>전체기간</span>
                                            </div>
                                            :
                                            <div>
                                                <span>{startDate ? new Date(startDate).toLocaleDateString('ko-KR') : '시작일'}</span>
                                                <span> ~ </span>
                                                <span>{endDate ? new Date(endDate).toLocaleDateString('ko-KR') : '종료일'}</span>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div>
                                    <div><span>누적 출금 내역</span></div>
                                    <div>
                                        <div><span>{totalFees.toLocaleString()}</span></div>
                                        <div><span>KRW</span></div>
                                    </div>
                                    <div>
                                        <div><span>적용된 정산 기간 :</span></div>&nbsp;
                                        {
                                            startDate === '' && endDate === '' ?
                                            <div>
                                                <span>전체기간</span>
                                            </div>
                                            :
                                            <div>
                                                <span>{startDate ? new Date(startDate).toLocaleDateString('ko-KR') : '시작일'}</span>
                                                <span> ~ </span>
                                                <span>{endDate ? new Date(endDate).toLocaleDateString('ko-KR') : '종료일'}</span>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div>
                                    <div><span>미출금 금액</span></div>
                                    <div>
                                        <div><span>{totalSettlementAmount.toLocaleString()}</span></div>
                                        <div><span>KRW</span></div>
                                    </div>
                                    <div>
                                        <div><span>적용된 정산 기간 :</span></div>&nbsp;
                                        {
                                            startDate === '' && endDate === '' ?
                                            <div>
                                                <span>전체기간</span>
                                            </div>
                                            :
                                            <div>
                                                <span>{startDate ? new Date(startDate).toLocaleDateString('ko-KR') : '시작일'}</span>
                                                <span> ~ </span>
                                                <span>{endDate ? new Date(endDate).toLocaleDateString('ko-KR') : '종료일'}</span>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className={`${admin.admin_calculate_proceed_detail_box}`}>
                            <div>
                                <div className={`${admin.admin_calculate_proceed_detail_title}`}><span>출금 내역</span></div>
                                <div className={`${admin.admin_calculate_proceed_detail_search_btn_box}`}>
                                    <div>
                                        <div className={`${admin.admin_calculate_proceed_detail_payment_date}`}>
                                            <div><span>정산기간</span></div>
                                            <div>
                                                <div><input type="date" value={startDate} onChange={(e) => handleDateChange(e, true)} /></div>
                                                <div>&nbsp;<span>~</span>&nbsp;</div>
                                                <div><input type="date" value={endDate} onChange={(e) => handleDateChange(e, false)} /></div>
                                            </div>
                                        </div>
                                        <div className={`${admin.admin_calculate_proceed_detail_search}`}>
                                            <div><span>검색</span></div>
                                            <div>
                                                <div><input type="text" value={searchText} onChange={handleSearchChange} /></div>
                                                <div><span className="material-symbols-outlined">search</span></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${admin.admin_calculate_proceed_detail_btn}`}>
                                        <div><button>필터</button></div>
                                        <div><button>전체 엑셀 다운로드</button></div>
                                    </div>
                                </div>
                            </div>
                            <div className={`${admin.admin_calculate_settle_detail_container}`}>
                                <div>
                                    <div className={`${admin.admin_calculate_settle_detail_header}`}>
                                        <div><span>구분</span></div>
                                        <div><span>정산 기간</span></div>
                                        <div><span>정산 요청 금액</span></div>
                                        <div><span>수수료</span></div>
                                        <div><span>출금신청</span></div>
                                        <div><span>출금신청일</span></div>
                                        <div><span>출금완료일</span></div>
                                        <div><span>상세내역</span></div>
                                    </div>
                                    {
                                        Array.isArray(currentItems) && currentItems.map((item, index) => (
                                            <div key={index} className={`${admin.admin_calculate_settle_detail_content}`}>
                                                <div><span>{item.periodSettlementNo}</span></div>
                                                <div><span>{item.settlementStartDate}</span><span>&nbsp;~&nbsp;</span><span>{item.settlementEndDate}</span></div>
                                                <div><span>{item.totalSettlementAmount.toLocaleString()}</span></div>
                                                <div><span>{item.totalFee.toLocaleString()}</span></div>
                                                <div>
                                                    {
                                                        item.withdrawalState === "출금신청" ?
                                                        <button className={`${admin.admin_calculate_settle_withdrawal_btn}`} onClick={() => onWithdrawal(item)}>출금</button>
                                                        :
                                                        <button className={`${admin.admin_calculate_settle_withdrawal_state_btn}`} disabled={true}>{item.withdrawalState}</button>
                                                    }
                                                </div>
                                                <div>
                                                    {
                                                        item.withdrawalDate !== null ?
                                                        <span>{formatDate(item.withdrawalDate)}</span>
                                                        : null
                                                    }
                                                </div>
                                                <div>
                                                    {
                                                        item.withdrawalConfirmDate !== null ?
                                                        <span>{formatDate(item.withdrawalConfirmDate)}</span>
                                                        : null
                                                    }
                                                </div>
                                                <div><button>다운로드</button></div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            <Pagination
                                current={currentPage}
                                total={totalItems}
                                pageSize={itemsPerPage}
                                onChange={(page) => setCurrentPage(page)}
                            />
                        </div>
                    </div>
                    :
                    <div className={`${admin.admin_calculate_proceed_detail_no_data}`}>
                        <div><span>등록된 내역이 없습니다.</span></div>
                    </div>
                }
            </div>
        </div>
    )
}

export default AdminProceed;
