import React, { useEffect, useState } from 'react';
import admin from '../../../admin.module.css';
import TextEditor from './TextEditor';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useLocation, useNavigate } from 'react-router-dom';
import * as adminApi from '../../../../apis/admin';

const AdminSellerNoticeUpdate = () => {
    const navigate = useNavigate();
    const MySwal = withReactContent(Swal);
    const location = useLocation();

    const noticeInfo = location.state;

    // 에디터 내용 담을 변수
    const [data, setData] = useState("");
    const [fileName, setFileName] = useState('');
    const [update, setUpdate] = useState(false);
    const [updateSave, setUpdateSave] = useState(false);

    const [updateNotice, setUpdateNotice] = useState({
        sellerNoticeTitle: '',  // 초기 값을 빈 문자열로 설정
        sellerNoticeContent: '',
        ...noticeInfo,  // 초기 값을 noticeInfo로 설정하여 자동으로 반영
    });

    useEffect(() => {
        if (noticeInfo) {
            setUpdateNotice((prev) => ({
                ...prev,
                ...noticeInfo,
                sellerNoticeContent: noticeInfo.sellerNoticeContent || '',
            }));
            setData(noticeInfo.sellerNoticeContent || '');
        }
    }, [noticeInfo]);

    useEffect(() => {
        // console.log('updateNotice : ', updateNotice);
    }, [updateNotice]);
    
    useEffect(() => {
        setUpdateNotice((prev) => ({
            ...prev,
            sellerNoticeContent: data,
        }));
    }, [data]);

    const onNoticeTitle = (e) => {
        setUpdateNotice((prev) => ({
            ...prev,
            sellerNoticeTitle: e.target.value,
        }));
    };
    
    const onDetailContent = () => {

    }
    const onNoticeCancel = () => {
        MySwal.fire({
            customClass: {
                popup: `${admin.admin_notice_add_popup}`
            },
            html : 
            `
                <div>
                    <div class='${admin.admin_notice_add_title}'><span>페이지를 이동 하시겠습니까?</span></div>
                    <div class='${admin.admin_notice_add_content}'><span>작성 중인 내용은 저장 되지않습니다.</span></div>
                    <div class='${admin.admin_notice_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                      <button class='mx-1' id='seller_add_cancel'>취소</button>
                      <button class='mx-1' id='seller_add_success'>이동</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });
        document.addEventListener('click', noticeAddSuccess);
        document.addEventListener('click', noticeAddCancel);
        
        function noticeAddSuccess(e) {
            if(e.target && e.target.id == 'seller_add_success') {
                navigate(-1);
                document.removeEventListener('click', noticeAddSuccess);
                document.removeEventListener('click', noticeAddCancel);
                MySwal.close();
            }
        }
        
        function noticeAddCancel(e) {
            if(e.target && e.target.id == 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', noticeAddSuccess);
                document.removeEventListener('click', noticeAddCancel);
            }
        }
    }

    const onNoticeUpdate = () => {
        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html : 
            `
                <div>
                    <div class='${admin.admin_seller_add_title}'><span>변경 내용을 저장 하시겠습니까?</span></div>
                    <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                      <button class='mx-1' id='seller_add_cancel'>취소</button>
                      <button class='mx-1' id='seller_add_success'>저장</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });
        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);
        
        async function sellerAddSuccess(e) {
            if(e.target && e.target.id == 'seller_add_success') {

                const response = await adminApi.sellerNoticeUpdate(updateNotice);
                const data = response.data;

                if(data === 'SUCCESS') {
                    MySwal.fire({
                        customClass: {
                            popup: `${admin.admin_seller_add_popup}`
                        },
                        html : 
                        `
                        <div>
                        <div class='${admin.admin_seller_add_title}'><span>변경 내용이 저장 되었습니다.</span></div>
                        <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                        </div>
                        </div>
                        `,
                        showConfirmButton: false,
                        showCancelButton: false,
                    });
                    document.addEventListener('click', sellerAddConfirm);
                    document.removeEventListener('click', sellerAddSuccess);
                    document.removeEventListener('click', sellerAddCancel);
                    
                    function sellerAddConfirm(e) {
                        if(e.target && e.target.id == 'seller_add_confirm') {
                            MySwal.close();
                            document.removeEventListener('click', sellerAddConfirm);
                            navigate(-2);
                        }
                    }
                } else {
                    MySwal.fire({
                        customClass: {
                            popup: `${admin.admin_seller_add_popup}`
                        },
                        html : 
                        `
                        <div>
                        <div class='${admin.admin_seller_add_title}'><span>변경 내용 저장에 실패하였습니다.</span></div>
                        <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                        </div>
                        </div>
                        `,
                        showConfirmButton: false,
                        showCancelButton: false,
                    });
                    document.addEventListener('click', sellerAddConfirm);
                    document.removeEventListener('click', sellerAddSuccess);
                    document.removeEventListener('click', sellerAddCancel);
                    
                    function sellerAddConfirm(e) {
                        if(e.target && e.target.id == 'seller_add_confirm') {
                            MySwal.close();
                            document.removeEventListener('click', sellerAddConfirm);
                        }
                    }
                }
                
            }
        }
        
        function sellerAddCancel(e) {
            if(e.target && e.target.id == 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
            }
        }
    }

    return (
        <div className={`${admin.admin_seller_notice_box} px-3 py-3`}>
            <div>
                <div className={`${admin.admin_seller_notice_header}`}>
                    <div><span>셀러 공지사항</span></div>
                </div>
                <div className={`${admin.admin_seller_notice_content}`}>
                    <div className={`${admin.admin_seller_notice_add_title}`}>
                        <div><span>제목</span></div>
                        <div><input type="text" onChange={(e) => onNoticeTitle(e)} value={updateNotice && updateNotice.sellerNoticeTitle}/></div>
                    </div>
                    <div>
                        <TextEditor setData={setData} admin={admin} fileName={fileName} setFileName={setFileName} onDetailContent={onDetailContent} update={update} data={data}/>
                    </div>
                </div>
                <div>
                    <div className={`${admin.admin_seller_notice_add_btn_box}`}>
                        <div><button onClick={() => onNoticeCancel()}>취소</button></div>
                        <div><button onClick={() => onNoticeUpdate()}>수정하기</button></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminSellerNoticeUpdate