import React from 'react'

const LoginPwChangeSection = ( {seller, loginCheck, onPw, onNewPw, onCheckPw, pwCheckState, changeSuccess} ) => {
  return (
    <div>
        <div className={`${seller.seller_login_box} ${pwCheckState ? '' : seller.h_400}`}>
            <div className='d-flex justify-content-center align-items-center pt-5 pb-4'><span>비밀번호 변경</span></div>
            <div className={`px-3 py-2`}>
                <input type="password" className={`${seller.seller_login_input}`} placeholder='현재 비밀번호' onChange={(e) => onPw(e)} />
            </div>
            <div className={`px-3 py-2`}>
                <input type="password" className={`${seller.seller_login_input}`} placeholder='새 비밀번호' onChange={(e) => onNewPw(e)} />
            </div>
            <div className={`px-3 py-2`}>
                <input type="password" className={`${seller.seller_login_input}`} placeholder='새 비밀번호 확인' onChange={(e) => onCheckPw(e)} />
            </div>
            {
                pwCheckState ? '' :
                <div className='px-3 pb-3'>
                    <div className={`${seller.seller_idpw_check}`}>
                        <div className='ps-3'><img src="../img/seller/warning.png" alt="경고" /></div>
                        <div className='d-flex align-items-center ps-2'><span>비밀번호가 일치하지 않습니다.</span></div>
                    </div>
                </div>
            }
            <div className={`${seller.seller_login_btn} px-3`}><button onClick={() => changeSuccess()}>완료</button></div>
        </div>
    </div>
  )
}

export default LoginPwChangeSection