import React, { useEffect, useState } from 'react'
import { animated } from 'react-spring';
import { useSpring, useTransition } from '@react-spring/core';
import { CSSTransition } from 'react-transition-group';
import * as auth from '../../apis/auth';

const MyReservationDetail = ( {showMyReservation, setShowMyReservation, dailyduck, showOD, setShowOD, selectReservation, formatDate2 } ) => {

    const [userInfo, setUserInfo] = useState({});

    useEffect(() => {
        // console.log('selectReservation : ', selectReservation);
        userSelect();
    }, [selectReservation]);

    const userSelect = async () => {
        const response = await auth.userselect(selectReservation.userId);
        const data = response.data;

        // console.log('user : ', data);
        setUserInfo(data);
    }
    const transitions = useTransition(showMyReservation, {
        from: { transform: "translateX(100%)" },
        enter: { transform: "translateX(0)" },
        leave: { transform: "translateX(100%)" },
        config: {
            duration: 300,
        },
    });

    const handleMyReservationBack = () => {
        setShowMyReservation(false);
    }

    const [openStates, setOpenStates] = useState(false);
    const toggleOpen = () => {setOpenStates(!openStates);};
  return (
    <>
        {
            showMyReservation &&
            <div className={`${dailyduck.my_reservation_detail_container}`}>
                    {transitions((style, item) =>
                        item ? 
                        <animated.div className={dailyduck.my_reservation_detail_box} style={style}>
                            <div className={dailyduck.my_reservation_detail_header}>
                                <div>
                                    <button onClick={() => handleMyReservationBack()}>
                                        <span className="material-symbols-outlined">arrow_back_ios</span>
                                    </button>
                                </div>
                                <div>
                                    <div>
                                        <span>예약 상세</span>
                                    </div>
                                </div>
                            </div>
                            <div className={dailyduck.my_reservation_detail_content}>
                                <div className={dailyduck.my_reservation_detail_content_reservation_info}>
                                    <div>{selectReservation.reservationState}</div>
                                    <div className={`${dailyduck.my_reservation_content_detail_info_box}`}>
                                        <div className={`${dailyduck.my_reservation_detail_img}`}><img src={`${selectReservation.mainItemImg}`} alt="예약이미지" /></div>
                                        <div className={`${dailyduck.my_reservation_detail_info}`}>
                                            <div><span className={`${dailyduck.gray_color}`}>{selectReservation.shopName}</span></div>
                                            <div><span>{selectReservation.mainOptionName}</span></div>
                                            <div><span>{selectReservation.mainItemPrice.toLocaleString() + '원'}</span></div>
                                        </div>
                                    </div>
                                    <div className={`${dailyduck.my_reservation_content_detail_person_box}`}>
                                        <div><span>예약자 정보</span></div>
                                        <div>
                                            <div>
                                                <div className={`${dailyduck.my_reservation_info_title} ${dailyduck.gray_color}`}><span>이름</span></div>
                                                <div className={`${dailyduck.my_reservation_info_content}`}><span>{userInfo && userInfo?.userName}</span></div>
                                            </div>
                                            <div>
                                                <div className={`${dailyduck.my_reservation_info_title} ${dailyduck.gray_color}`}><span>이메일</span></div>
                                                <div className={`${dailyduck.my_reservation_info_content}`}><span>{userInfo && userInfo?.userEmail}</span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`${dailyduck.my_reservation_content_detail_date_box}`}>
                                    <div><span>예약일 정보</span></div>
                                    <div>
                                        <div className={`${dailyduck.my_reservation_date_title} ${dailyduck.gray_color}`}><span>예약일</span></div>
                                        <div className={`${dailyduck.my_reservation_date_content}`}><span>{formatDate2(selectReservation.dateRangeStrings[0])} ~ {formatDate2(selectReservation.dateRangeStrings[1])}</span></div>
                                    </div>
                                </div>
                                {
                                    selectReservation.options.length !== 0 ? (
                                        <div>
                                            <div className={`${dailyduck.my_reservation_option_content}`}>
                                                <div><span>추가 정보</span></div>
                                                {
                                                    selectReservation.options.map((item, index) => {
                                                        return (
                                                            <div key={index} className={`${dailyduck.my_reservation_option_box}`}>
                                                                <div className={`${dailyduck.my_reservation_add_info_title} ${dailyduck.gray_color}`}><span>{item.name}</span></div>
                                                                <div className={`${dailyduck.my_reservation_add_info_content}`}><span>{item.value}({(item.price * (item.quantity ? item.quantity : 1)).toLocaleString()+'원'}){item.quantity ? ' - ' + item.quantity + '개':  ''}</span></div>
                                                            </div>
                                                        )
                                                    }) 
                                                }
                                            </div>
                                            {/* <div>
                                                <div><span>사진정보</span></div>
                                                <div className='py-3'>
                                                    <div><span className={`${dailyduck.gray_color}`}>사진 제출 내역</span></div>
                                                    <div>
                                                        swiper 라이브러리
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    )
                                    : (
                                        <div className={`${dailyduck.my_reservation_no_option}`}><span>옵션값이 없는 상품입니다.</span></div>
                                    )
                                }
                                <div className={`${dailyduck.my_reservation_payment_info}`}>
                                    <div className={`${dailyduck.my_reservation_payment_info_btn}`}>
                                        <button onClick={() => toggleOpen()}>
                                            <div><span>결제정보</span></div>
                                            <div>
                                                <span className="material-symbols-outlined">{openStates ? "expand_less" : "expand_more"}</span>
                                            </div>
                                        </button>
                                    </div>
                                    {/* 펼쳐지는 영역 시작 */}
                                    <CSSTransition in={openStates} timeout={500} classNames="review" unmountOnExit>
                                    <div className={`${dailyduck.my_reservation_payment_info_box}`}>
                                        <div>
                                            <div>
                                                <div><span className={`${dailyduck.gray_color}`}>총 상품금액</span></div>
                                                <div><span>{parseInt(selectReservation.amount).toLocaleString()}원</span></div>
                                            </div>
                                            <div>
                                                <div><span className={`${dailyduck.gray_color}`}>포인트 사용</span></div>
                                                <div><span>(-) {selectReservation.point}</span></div>
                                            </div>
                                            <div>
                                                <div><span className={`${dailyduck.gray_color}`}>쿠폰 사용</span></div>
                                                <div><span>(-) {selectReservation.couponDiscount}</span></div>
                                            </div>
                                            <div>
                                                <div><span className={`${dailyduck.gray_color}`}>결제수단</span></div>
                                                <div><span>{selectReservation.reservationPaymentType}</span></div>
                                            </div>
                                        </div>
                                        <div>
                                            <div><span>총 결제금액</span></div>
                                            <div><span>{(selectReservation.amount - selectReservation.point - selectReservation.couponDiscount).toLocaleString()}원</span></div>
                                        </div>
                                    </div>
                                    </CSSTransition>
                                    {/* 펼쳐지는 영역 끝 */}
                                </div>
                                {
                                    selectReservation.cancelContent != null &&
                                    <div>
                                        <div><span>최소 사유</span></div>
                                        <div><span className={`${dailyduck.gray_color}`}>안녕하세요 고객님. 고객님의 요청으로 예약 취소 처리해드렸습니다.</span></div>
                                    </div>
                                }
                            </div>
                        </animated.div>
                        : null
                    )}
                </div>
        }
    </>
  )
}

export default MyReservationDetail