import React, { useContext, useEffect, useState } from 'react'
import { animated } from 'react-spring';
import { useSpring, useTransition } from '@react-spring/core';
import FavorityData from '../../components/my/FavorityData';
import * as auth from '../../apis/auth';
import dailyduck from '../../containers/my/MyContainner.module.css'
import { useLocation, useNavigate } from 'react-router-dom';
import { LoginContext } from '../../contexts/LoginContextProvider';
import { ToastContainer, toast } from 'react-toastify'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import FavorityDdayUpdate from '../../components/my/FavorityDdayUpdate';
import MyFavorityAdd from '../../components/my/MyFavorityAdd';

const MyFavority = ( {} ) => {

    const navigate = useNavigate();  
    const MySwal = withReactContent(Swal);  
    const { isLogin, login, logout, userInfo, remove } = useContext(LoginContext);
    const [ users, setUser ] = useState({});
    const [userFavorityList, setUserFavorityList] = useState([]);
    const [ items, setItems ] = useState({
        top: [],
        bottom: [],
    });
    const [ showFavorityDdayUpdate, setShowFavorityDdayUpdate ] = useState(false);
    const [ ddayUpdate, setDdayUpdate ] = useState(false);
    const [ myFavorityUpdate, setMyFavorityUpdate ] = useState({});
    const [ ddayInput, setDdayInput ] = useState('');
    const [ showJoinFavority, setShowJoinFavority ] = useState(false);
    const [ showFavorityDday, setShowFavorityDday ] = useState(false);
    const [ showFavorityAdd, setShowFavorityAdd ] = useState(false); 
    const [ favorityList, setFavorityList ] = useState([
        {
          favorityNo : '',
          favorityName : '',
          favorityDday : '',
          favorityImgUrl : '',
        }
      ]);

    const fetchData = async () => {
        try {
            if(userInfo) {
                // 비동기 요청 실행
                const response = await auth.userselect(userInfo.userId);
                // 데이터 설정
                setUser(response.data);
      
                // // console.log(response.data);
            }
        } catch (error) {
            // 오류 처리
            console.error("데이터를 불러오는 데 실패했습니다.", error);
        }
    };
    const favorityData = async () => {
        try {
          const response = await auth.favorityList(userInfo.userId);
          
          setUserFavorityList(response.data);
          
          // // console.log(response.data);
    
        } catch (error) {
          console.error("데이터를 불러오는 데 실패했습니다.", error);
        }
      }
    useEffect(() => {
        fetchData();
        favorityData();
    }, [userInfo]);

    const handleFavorityBack = () => {
        navigate('/my');
    }


    useEffect(() => {
        const favorityData = async () => {
            try {
              const response = await auth.favorityList(userInfo.userId);
              
              setUserFavorityList(response.data);
              
              // console.log(response.data);
      
            } catch (error) {
              console.error("데이터를 불러오는 데 실패했습니다.", error);
            }
          }
          favorityData();

          // console.log(ddayUpdate);
    }, [ddayUpdate]);

    const handleddayDelete = async (no, name) => {
        // SweetAlert2를 사용하여 사용자에게 삭제 확인 요청
        const result = await Swal.fire({
          customClass: {
            container: `${dailyduck.favority_delete_container}`,
            confirmButton: `${dailyduck.favority_delete_confirm}`, // 커스텀 클래스 이름을 사용
            cancelButton: `${dailyduck.favority_delete_cancle}`
          },
          title: '최애 삭제',
          html: `<p>선택하신 최애(${name})를 삭제하시겠습니까?</p>`,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '예',
          cancelButtonText: '아니오',
          reverseButtons: true
        });
      
         // 사용자가 '예'를 누른 경우
      if (result.isConfirmed) {
        try {
          const response = await auth.favorityDelete(no, userInfo ? userInfo.userId : '');
          const data = await response.data;
          
          // console.log(data);
    
          if(data === 'SUCCESS') {
            toast.success('해당 항목이 성공적으로 삭제되었습니다.', {
              icon: false,
              hideProgressBar: true, // 진행 상태 표시줄 숨기기
            });
            favorityData();
            // setDdayUpdate(true);
            setTimeout(() => setDdayUpdate(true), 2000);
          }
          // 삭제 성공 알림 (Toast 사용)
          } catch (error) {
            // 에러 처리
            console.error(error);
            // 에러 알림 (Toast 사용)
            toast.error('삭제 중 오류가 발생했습니다.', {
              icon: false,
              hideProgressBar: true, // 진행 상태 표시줄 숨기기
            });
          }
      }
        // console.log(no);
      }

      const handleddayUpdate = async (id) => {

        try {
          const response = await auth.myFavorityUpdate(id, users ? users.userId : 'null');
          const data = response.data;
          // // console.log(data);
          setMyFavorityUpdate(data);
          setShowFavorityDdayUpdate(true);
        } catch (error) {
          console.error(error);
        }
      }
    
      const onDdayUpdateInput = (e) => {
        // // console.log(e.target.value);
        setDdayInput(e.target.value);
      }

      const successUpdate = async (no) => {
        try {
          const response = await auth.ddayUpdate(no, ddayInput, userInfo ? userInfo.userId : 'null');
          const data = await response.data;
      
          // console.log(data);
          if(data === 'SUCCESS') {
            
            setDdayUpdate(true);
            setTimeout(() => setDdayUpdate(false), 100);
            setShowFavorityDdayUpdate(false);
          } 
      
        } catch (error) {
          // console.log(error);
        }
        // console.log(no);
      }

      const handlefavorityAddBtn = () => {
        // setShowJoinFavority(true);
        navigate('/my/favority/add', { state : {userFavorityList : userFavorityList}});
      }



  return (
    <>
        {
            <div className={`${dailyduck.myfavority_container} bg-white`}>
              <div className={dailyduck.myfavority_box}>
                  <div className={dailyduck.my_favority_header}>
                      <div>
                          <button onClick={() => handleFavorityBack()} className='reservation_pay_close'>
                            <span className="material-symbols-outlined">arrow_back_ios</span>
                          </button>
                      </div>
                      <div>
                          <div>
                              <span>나의 최애</span>
                          </div>
                      </div>
                  </div>
                  <div className={dailyduck.my_favority_info_title}>
                      <div className='py-3 px-3 d-flex align-items-center'>
                          <span className='bold fs-5'>등록된 최애</span>
                          <span className='ps-2'>{userFavorityList ? userFavorityList.length : 0}</span>
                      </div>
                      <div className='py-2 px-3 bold'>
                          <span>응원마켓 최애</span>
                      </div>
                      <div className='px-3 dd-fs-7 gray_color'>
                          <span>최애 변경을 원할 시 아래 리스트의 등록된 최애를 드래그하여 변경해주세요.</span>
                      </div>
                  </div>
                  <div className={dailyduck.my_favority_content}>
                      <div>
                          <FavorityData items={items} setItems={setItems} dailyduck={dailyduck} userFavorityList={userFavorityList} users={users} showFavorityDdayUpdate={showFavorityDdayUpdate} setShowFavorityDdayUpdate={setShowFavorityDdayUpdate} handleddayUpdate={handleddayUpdate} handleddayDelete={handleddayDelete} />
                      </div>
                  </div>
                  <div className={`${dailyduck.favority_add_btn} px-3`}>
                      <button onClick={() => handlefavorityAddBtn()}>최애 등록하기</button>
                  </div>
              </div>
            </div>
        }
        {
        showFavorityDdayUpdate &&
        <FavorityDdayUpdate dailyduck={dailyduck} showFavorityDdayUpdate={showFavorityDdayUpdate} setShowFavorityDdayUpdate={setShowFavorityDdayUpdate}
                            myFavorityUpdate={myFavorityUpdate} onDdayUpdateInput={onDdayUpdateInput} successUpdate={successUpdate}
        />
        }
        {/* {
        showJoinFavority &&
        <MyFavorityAdd showJoinFavority={showJoinFavority} joinTransitions3={joinTransitions3} animated={animated} 
        setShowJoinFavority={setShowJoinFavority} useTransition={useTransition} favorityList={favorityList} 
        setFavorityList={setFavorityList} users={users} favoritySave={favoritySave} showFavorityDday={showFavorityDday}
        showFavorityAdd={showFavorityAdd} setShowFavorityDday={setShowFavorityDday} setShowFavorityAdd={setShowFavorityAdd}
        />
        } */}
    </>
  )
}

export default MyFavority