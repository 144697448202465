import React, { useEffect, useState } from 'react';
import { Modal, Pagination, Button, Checkbox, Input, Select } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import admin from '../../../admin.module.css';
import { Option } from 'antd/es/mentions';
import * as adminApi from '../../../../apis/admin';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const AdminAgree = () => {
    const [activeButton, setActiveButton] = useState('basic');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(14);
    const [totalItems, setTotalItems] = useState(0);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [isCustomModalVisible, setIsCustomModalVisible] = useState(false);
    const [isCustomEditModalVisible, setIsCustomEditModalVisible] = useState(false);
    const [allCheckedBasic, setAllCheckedBasic] = useState(false);
    const [checkedListBasic, setCheckedListBasic] = useState([]);
    const [allCheckedCustom, setAllCheckedCustom] = useState(false);
    const [checkedListCustom, setCheckedListCustom] = useState([]);
    const [agreementTitle, setAgreementTitle] = useState('');
    const [agreementContent, setAgreementContent] = useState('');
    const [editAgreementTitle, setEditAgreementTitle] = useState('');
    const [editAgreementContent, setEditAgreementContent] = useState('');
    const [customAgreementTitle, setCustomAgreementTitle] = useState('');
    const [customEditAgreementTitle, setCustomEditAgreementTitle] = useState('');
    const [customAgreementContent, setCustomAgreementContent] = useState('');
    const [customEditAgreementContent, setCustomEditAgreementContent] = useState('');
    const [termsContent, setTermsContent] = useState({});
    const [termsEditContent, setTermsEditContent] = useState({});
    const [customTermsContent, setCustomTermsContent] = useState({});
    const [customEditTermsContent, setCustomEditTermsContent] = useState({});
    const [termsSelect, setTermsSelect] = useState('');
    const [termsEditSelect, setTermsEditSelect] = useState('');
    const [termsCustomSelect, setTermsCustomSelect] = useState('');
    const [termsCustomEditSelect, setTermsCustomEditSelect] = useState('');
    const [basicTermsList, setBasicTermsList] = useState([]);
    const [customTermsList, setCustomTermsList] = useState([]);
    const [editingTerm, setEditingTerm] = useState(null);
    const [customEditingTerm, setCustomEditingTerm] = useState(null);
    const MySwal = withReactContent(Swal);

    const paginate = pageNumber => setCurrentPage(pageNumber);

    useEffect(() => {
        basicTermsSelect();
        customTermsSelect();
    }, []);

    useEffect(() => {
      // console.log('termsEditContent : ', termsEditContent);
    },[termsEditContent]);

    const basicTermsSelect = async () => {
        const response = await adminApi.basicTermsSelect();
        const data = response.data;

        // console.log(data);
        setBasicTermsList(data);
        setCheckedListBasic(new Array(data.length).fill(false));
    }

    const customTermsSelect = async () => {
        const response = await adminApi.customTermsSelect();
        const data = response.data;

        // console.log(data);
        setCustomTermsList(data);
        setCheckedListCustom(new Array(data.length).fill(false));
    }

    const showModal = () => {
        setIsModalVisible(true);
        setTermsSelect('required');
        setTermsContent({
            ...termsContent, termsState: true
        });
    };

    const showCustomModal = () => {
        setIsCustomModalVisible(true);
        setTermsCustomSelect('required');
        setCustomTermsContent({
            ...customTermsContent, customTermsState: true
        });
    }

    const showEditModal = (term) => {
        setEditingTerm(term);
        setEditAgreementTitle(term.termsTitle);
        setEditAgreementContent(term.termsContent);
        setTermsEditSelect(term.termsState ? 'required' : 'optional');
        setTermsEditContent({
            ...termsContent,
            termsTitle: term.termsTitle,
            termsContent: term.termsContent,
            termsState: term.termsState
        });
        setIsEditModalVisible(true);
    }
    const showCustomEditModal = (term) => {
        setCustomEditingTerm(term);
        setCustomEditAgreementTitle(term.customTermsTitle);
        setCustomEditAgreementContent(term.customTermsContent);
        setTermsCustomEditSelect(term.customTermsState ? 'required' : 'optional');
        setCustomEditTermsContent({
            ...customEditTermsContent,
            customTermsTitle: term.customTermsTitle,
            customTermsContent: term.customTermsContent,
            customTermsState: term.customTermsState
        });

        setIsCustomEditModalVisible(true);
    }

    const handleOk = () => {
        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html:
                `
                <div>
                    <div class='${admin.admin_seller_add_title}'><span>기본 약관을 등록 하시겠습니까?</span></div>
                    <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                    <button class='mx-1' id='seller_add_cancel'>취소</button>
                    <button class='mx-1' id='seller_add_success'>등록</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });
        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);

        function sellerAddSuccess(e) {
            if (e.target && e.target.id == 'seller_add_success') {
                termsAdd();
            }
        }

        const termsAdd = async () => {
            const response = await adminApi.basicTermsAdd(termsContent);
            const data = response.data;

            if (data === 'SUCCESS') {
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
                basicTermsSelect();
                MySwal.close();
                setIsModalVisible(false);
            } else {
                alert('등록에 실패하였습니다')
            }
        }

        function sellerAddCancel(e) {
            if (e.target && e.target.id == 'seller_add_cancel') {
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);

                MySwal.close();
            }
        }
    };

    const handleCustomOk = () => {
        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html:
                `
                <div>
                    <div class='${admin.admin_seller_add_title}'><span>커스텀 약관을 등록 하시겠습니까?</span></div>
                    <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                    <button class='mx-1' id='seller_add_cancel'>취소</button>
                    <button class='mx-1' id='seller_add_success'>등록</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });
        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);

        function sellerAddSuccess(e) {
            if (e.target && e.target.id == 'seller_add_success') {
                customTermsAdd();
            }
        }

        const customTermsAdd = async () => {
            const response = await adminApi.customTermsAdd(customTermsContent);
            const data = response.data;

            if (data === 'SUCCESS') {
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
                customTermsSelect();
                MySwal.close();
                setIsCustomModalVisible(false);
            } else {
                alert('등록에 실패하였습니다')
            }
        }

        function sellerAddCancel(e) {
            if (e.target && e.target.id == 'seller_add_cancel') {
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);

                MySwal.close();
            }
        }
    };

    const handleEditOk = async () => {
        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html:
                `
                <div>
                    <div class='${admin.admin_seller_add_title}'><span>약관을 수정 하시겠습니까?</span></div>
                    <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                    <button class='mx-1' id='seller_edit_cancel'>취소</button>
                    <button class='mx-1' id='seller_edit_success'>수정</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });
        document.addEventListener('click', sellerEditSuccess);
        document.addEventListener('click', sellerEditCancel);

        function sellerEditSuccess(e) {
            if (e.target && e.target.id == 'seller_edit_success') {
                termsEdit();
            }
        }

        const termsEdit = async () => {
            const response = await adminApi.basicTermsEdit(editingTerm.termsNo, termsEditContent);
            const data = response.data;

            if (data === 'SUCCESS') {
                document.removeEventListener('click', sellerEditSuccess);
                document.removeEventListener('click', sellerEditCancel);
                basicTermsSelect();
                MySwal.close();
                setIsEditModalVisible(false);
            } else {
                alert('수정에 실패하였습니다')
            }
        }

        function sellerEditCancel(e) {
            if (e.target && e.target.id == 'seller_edit_cancel') {
                document.removeEventListener('click', sellerEditSuccess);
                document.removeEventListener('click', sellerEditCancel);

                MySwal.close();
            }
        }
    };

    const handleCustomEditOk = async () => {
        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html:
                `
                <div>
                    <div class='${admin.admin_seller_add_title}'><span>약관을 수정 하시겠습니까?</span></div>
                    <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                    <button class='mx-1' id='seller_edit_cancel'>취소</button>
                    <button class='mx-1' id='seller_edit_success'>수정</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });
        document.addEventListener('click', sellerEditSuccess);
        document.addEventListener('click', sellerEditCancel);

        function sellerEditSuccess(e) {
            if (e.target && e.target.id == 'seller_edit_success') {
                termsEdit();
            }
        }

        const termsEdit = async () => {
            const response = await adminApi.customTermsEdit(customEditingTerm.customTermsNo, customEditTermsContent);
            const data = response.data;

            if (data === 'SUCCESS') {
                document.removeEventListener('click', sellerEditSuccess);
                document.removeEventListener('click', sellerEditCancel);
                customTermsSelect();
                MySwal.close();
                setIsCustomEditModalVisible(false);
            } else {
                alert('수정에 실패하였습니다')
            }
        }

        function sellerEditCancel(e) {
            if (e.target && e.target.id == 'seller_edit_cancel') {
                document.removeEventListener('click', sellerEditSuccess);
                document.removeEventListener('click', sellerEditCancel);

                MySwal.close();
            }
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleEditCancel = () => {
        setIsEditModalVisible(false);
    };

    const handleCustomCancel = () => {
        setIsCustomModalVisible(false);
    };
    const handleCustomEditCancel = () => {
        setIsCustomEditModalVisible(false);
    };

    const handleAllCheckBasic = e => {
        setAllCheckedBasic(e.target.checked);
        setCheckedListBasic(checkedListBasic.map(() => e.target.checked));
    };

    const handleAllCheckCustom = e => {
        setAllCheckedCustom(e.target.checked);
        setCheckedListCustom(checkedListCustom.map(() => e.target.checked));
    };

    const handleCheckBasic = (index, e) => {
        const updatedCheckedList = [...checkedListBasic];
        updatedCheckedList[index] = e.target.checked;
        setCheckedListBasic(updatedCheckedList);
        setAllCheckedBasic(updatedCheckedList.every(checked => checked));
    };

    const handleCheckCustom = (index, e) => {
        const updatedCheckedList = [...checkedListCustom];
        updatedCheckedList[index] = e.target.checked;
        setCheckedListCustom(updatedCheckedList);
        setAllCheckedCustom(updatedCheckedList.every(checked => checked));
    };
    const handleSingleDeleteBasic = async (termsNo) => {
      // console.log('termsNo : ', termsNo);

      const response = await adminApi.basicSingleTermsDelete(termsNo);
      if (response.data === 'SUCCESS') {
        basicTermsSelect();
      } else {
        alert('삭제에 실패하였습니다');
      }

    }
    const handleDeleteBasic = async () => {
      const termsToDelete = basicTermsList.filter((_, index) => checkedListBasic[index]).map(term => term.termsNo);
      // console.log(termsToDelete);
      const response = await adminApi.basicTermsDelete(termsToDelete);
      if (response.data === 'SUCCESS') {
        basicTermsSelect();
      } else {
        alert('삭제에 실패하였습니다');
      }
    };
    const handleSingleDeleteCustom = async (termsNo) => {
      // console.log('termsNo : ', termsNo);

      const response = await adminApi.customSingleTermsDelete(termsNo);
      if (response.data === 'SUCCESS') {
        basicTermsSelect();
      } else {
        alert('삭제에 실패하였습니다');
      }
    }
    
    const handleDeleteCustom = async () => {
        const termsToDelete = customTermsList.filter((_, index) => checkedListCustom[index]).map(term => term.customTermsNo);
        const response = await adminApi.customTermsDelete(termsToDelete);
        if (response.data === 'SUCCESS') {
            customTermsSelect();
        } else {
            alert('삭제에 실패하였습니다');
        }
    };

    const onBasicTermsTitle = (e) => {
        setAgreementTitle(e.target.value);
        setTermsContent({
            ...termsContent, termsTitle: e.target.value
        });
    };
    const onBasicEditTermsTitle = (e) => {
        setEditAgreementTitle(e.target.value);
        setTermsEditContent({
            ...termsEditContent, termsTitle: e.target.value
        });
    };

    const onCustomTermsTitle = (e) => {
        setCustomAgreementTitle(e.target.value);
        setCustomTermsContent({
            ...customTermsContent, customTermsTitle: e.target.value
        });
    };
    const onCustomEditTermsTitle = (e) => {
        setCustomEditAgreementTitle(e.target.value);
        setCustomEditTermsContent({
            ...customEditTermsContent, customTermsTitle: e.target.value
        });
    };

    const onBasicSelect = (value) => {
        setTermsSelect(value);
        if (value === 'required') {
            setTermsContent({
                ...termsContent, termsState: true
            });
        } else {
            setTermsContent({
                ...termsContent, termsState: false
            });
        }
    };
    const onBasicEditSelect = (value) => {
        setTermsEditSelect(value);
        if (value === 'required') {
            setTermsEditContent({
                ...termsEditContent, termsState: true
            });
        } else {
            setTermsEditContent({
                ...termsEditContent, termsState: false
            });
        }
    };

    const onCustomSelect = (value) => {
      setTermsCustomSelect(value);
        if (value === 'required') {
            setCustomTermsContent({
                ...customTermsContent, customTermsState: true
            });
        } else {
            setCustomTermsContent({
                ...customTermsContent, customTermsState: false
            });
        }
    };
    const onCustomEditSelect = (value) => {
        setTermsCustomEditSelect(value);
        if (value === 'required') {
            setCustomEditTermsContent({
                ...customEditTermsContent, customTermsState: true
            });
        } else {
            setCustomEditTermsContent({
                ...customEditTermsContent, customTermsState: false
            });
        }
    };

    const onTerms = (e) => {
        setAgreementContent(e);
        setTermsContent({
            ...termsContent, termsContent: e
        });
    };
    const onEditTerms = (e) => {
        setEditAgreementContent(e);
        setTermsEditContent({
            ...termsEditContent, termsContent: e
        });
    };

    const onCustomTerms = (e) => {
        setCustomAgreementContent(e);
        setCustomTermsContent({
            ...customTermsContent, customTermsContent: e
        });
    };
    const onCustomEditTerms = (e) => {
        setCustomEditAgreementContent(e);
        setCustomEditTermsContent({
            ...customEditTermsContent, customTermsContent: e
        });
    };

    const termsStateChange = (termsState) => {
        if (termsState === true) {
            return '필수';
        } else {
            return '선택';
        }
    };

    return (
        <div className={`${admin.adimn_operate_agree_box} px-3 py-3`}>
            <div>
                <div className={`${admin.adimn_operate_agree_title}`}><span>약관</span></div>
                <div className={`${admin.admin_operate_agree_tap} pt-2 border-bottom`}>
                    <div className={`${activeButton === 'basic' ? admin.admin_operate_agree_tap_active : ''}`}><button onClick={() => setActiveButton('basic')}>기본약관</button></div>
                    <div className={`${activeButton === 'custom' ? admin.admin_operate_agree_tap_active : ''}`}><button onClick={() => setActiveButton('custom')}>커스텀약관</button></div>
                </div>
                {activeButton === 'basic' && (
                    <div>
                        <div className={`${admin.admin_operate_agree_info_txt} py-2`}><span>회원가입 및 기본 약관입니다. 상품 및 이벤트 추가를 위한 약관 등록시 커스텀 약관을 이용해주세요.</span></div>
                        <div className={`${admin.admin_operate_agree_add_btn} py-2`}>
                            <Button onClick={showModal}>약관추가</Button>
                            {checkedListBasic.some(checked => checked) && (
                                <Button className='ms-1' onClick={handleDeleteBasic}>삭제</Button>
                            )}
                        </div>
                        <div>
                            <div>
                                <div className={`${admin.adimn_operate_agree_content_header}`}>
                                    <div><input type="checkbox" checked={allCheckedBasic} onChange={handleAllCheckBasic} /></div>
                                    <div><span>약관명</span></div>
                                    <div><span>약관내용</span></div>
                                    <div><span>약관상태</span></div>
                                    <div><span>관리</span></div>
                                </div>
                                {
                                    basicTermsList.map((item, index) => (
                                        <div className={`${admin.adimn_operate_agree_content_content}`} key={item.termsNo}>
                                            <div><input type="checkbox" checked={checkedListBasic[index]} onChange={e => handleCheckBasic(index, e)} /></div>
                                            <div><span>{item.termsTitle}</span></div>
                                            <div dangerouslySetInnerHTML={{ __html: item.termsContent }}></div>
                                            <div><span>{termsStateChange(item.termsState)}</span></div>
                                            <div className={`${admin.admin_operate_agree_content_btn}`}>
                                                <div><Button onClick={() => showEditModal(item)}>수정</Button></div>
                                                <div><Button onClick={() => handleSingleDeleteBasic(item.termsNo)}>삭제</Button></div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            <Pagination current={currentPage} total={totalItems} onChange={paginate} pageSize={itemsPerPage} />
                        </div>
                    </div>
                )}
                {activeButton === 'custom' && (
                    <div>
                        <div className={`${admin.admin_operate_agree_info_txt} py-2`}><span>상품 추가 및 이벤트 관리를 위한 커스텀 약관입니다. 회원가입 및 중요 약관은 기본 약관을 이용해주세요.</span></div>
                        <div className={`${admin.admin_operate_agree_add_btn} py-2`}>
                            <Button onClick={showCustomModal}>약관추가</Button>
                            {checkedListCustom.some(checked => checked) && (
                                <Button className='ms-1' onClick={handleDeleteCustom}>삭제</Button>
                            )}
                        </div>
                        <div>
                            <div>
                                <div className={`${admin.adimn_operate_agree_content_header}`}>
                                    <div><input type="checkbox" checked={allCheckedCustom} onChange={handleAllCheckCustom} /></div>
                                    <div><span>약관명</span></div>
                                    <div><span>약관내용</span></div>
                                    <div><span>약관상태</span></div>
                                    <div><span>관리</span></div>
                                </div>
                                {
                                    customTermsList.map((item, index) => (
                                        <div className={`${admin.adimn_operate_agree_content_content}`} key={item.customTermsNo}>
                                            <div><input type="checkbox" checked={checkedListCustom[index]} onChange={e => handleCheckCustom(index, e)} /></div>
                                            <div><span>{item.customTermsTitle}</span></div>
                                            <div dangerouslySetInnerHTML={{ __html: item.customTermsContent }}></div>
                                            <div><span>{termsStateChange(item.customTermsState)}</span></div>
                                            <div className={`${admin.admin_operate_agree_content_btn}`}>
                                                <div><Button onClick={() => showCustomEditModal(item)}>수정</Button></div>
                                                <div><Button onClick={() => handleSingleDeleteCustom(item.customTermsNo)}>삭제</Button></div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            <Pagination current={currentPage} total={totalItems} onChange={paginate} pageSize={itemsPerPage} />
                        </div>
                    </div>
                )}
            </div>
            <Modal
                title="약관 추가"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="등록"
                cancelText="취소"
                className={admin.admin_terms_modal}
            >
                <div className={`${admin.admin_terms_modal_title_box}`}>
                    <div className='pe-1'>
                        <Input
                            placeholder="약관 제목을 입력하세요"
                            value={agreementTitle}
                            onChange={e => onBasicTermsTitle(e)}
                            className={`${admin.admin_terms_modal_title}`}
                        />
                    </div>
                    <div className='ps-1'>
                        <Select
                            className={admin.admin_terms_modal_select}
                            value={termsSelect ? termsSelect : ''}
                            onChange={(value) => onBasicSelect(value)}
                        >
                            <Option value='required'>필수</Option>
                            <Option value='optional'>선택</Option>
                        </Select>
                    </div>
                </div>
                <ReactQuill
                    theme="snow"
                    value={agreementContent}
                    onChange={(e) => onTerms(e)}
                    className={admin.admin_quill}
                />
            </Modal>
            <Modal
                title="약관 수정"
                visible={isEditModalVisible}
                onOk={handleEditOk}
                onCancel={handleEditCancel}
                okText="수정"
                cancelText="취소"
                className={admin.admin_terms_modal}
            >
                <div className={`${admin.admin_terms_modal_title_box}`}>
                    <div className='pe-1'>
                        <Input
                            placeholder="약관 제목을 입력하세요"
                            value={editAgreementTitle}
                            onChange={e => onBasicEditTermsTitle(e)}
                            className={`${admin.admin_terms_modal_title}`}
                        />
                    </div>
                    <div className='ps-1'>
                        <Select
                            className={admin.admin_terms_modal_select}
                            value={termsEditSelect}
                            onChange={(value) => onBasicEditSelect(value)}
                        >
                            <Option value='required'>필수</Option>
                            <Option value='optional'>선택</Option>
                        </Select>
                    </div>
                </div>
                <ReactQuill
                    theme="snow"
                    value={editAgreementContent}
                    onChange={(e) => onEditTerms(e)}
                    className={admin.admin_quill}
                />
            </Modal>
            <Modal
                title="약관 추가"
                visible={isCustomModalVisible}
                onOk={handleCustomOk}
                onCancel={handleCustomCancel}
                okText="등록"
                cancelText="취소"
                className={admin.admin_terms_modal}
            >
                <div className={`${admin.admin_terms_modal_title_box}`}>
                    <div className='pe-1'>
                        <Input
                            placeholder="약관 제목을 입력하세요"
                            value={customAgreementTitle}
                            onChange={e => onCustomTermsTitle(e)}
                            className={`${admin.admin_terms_modal_title}`}
                        />
                    </div>
                    <div className='ps-1'>
                        <Select
                            className={admin.admin_terms_modal_select}
                            defaultValue={termsCustomSelect ? termsCustomSelect : ''}
                            onChange={(value) => onCustomSelect(value)}
                        >
                            <Option value='required'>필수</Option>
                            <Option value='optional'>선택</Option>
                        </Select>
                    </div>
                </div>
                <ReactQuill
                    theme="snow"
                    value={customAgreementContent}
                    onChange={(e) => onCustomTerms(e)}
                    className={admin.admin_quill}
                />
            </Modal>
            <Modal
                title="약관 수정"
                visible={isCustomEditModalVisible}
                onOk={handleCustomEditOk}
                onCancel={handleCustomEditCancel}
                okText="수정"
                cancelText="취소"
                className={admin.admin_terms_modal}
            >
                <div className={`${admin.admin_terms_modal_title_box}`}>
                    <div className='pe-1'>
                        <Input
                            placeholder="약관 제목을 입력하세요"
                            value={customEditAgreementTitle}
                            onChange={e => onCustomEditTermsTitle(e)}
                            className={`${admin.admin_terms_modal_title}`}
                        />
                    </div>
                    <div className='ps-1'>
                        <Select
                            className={admin.admin_terms_modal_select}
                            value={termsCustomEditSelect ? termsCustomEditSelect : ''}
                            onChange={(value) => onCustomEditSelect(value)}
                        >
                            <Option value='required'>필수</Option>
                            <Option value='optional'>선택</Option>
                        </Select>
                    </div>
                </div>
                <ReactQuill
                    theme="snow"
                    value={customEditAgreementContent}
                    onChange={(e) => onCustomEditTerms(e)}
                    className={admin.admin_quill}
                />
            </Modal>
        </div>
    );
};

export default AdminAgree;
