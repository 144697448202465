import React, { useEffect, useRef, useState } from 'react';
import { Modal, Pagination, Button, Checkbox, Input, Select } from 'antd';
import admin from '../../../admin.module.css';
import { Option } from 'antd/es/mentions';
import * as adminApi from '../../../../apis/admin';
import * as support from '../../../../apis/support';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import TextEditor from '../shop/TextEditor'; // TextEditor 컴포넌트 임포트

const AdminNotification = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(14);
    const [totalItems, setTotalItems] = useState(0);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [allCheckedBasic, setAllCheckedBasic] = useState(false);
    const [checkedListBasic, setCheckedListBasic] = useState([]);
    const [agreementTitle, setAgreementTitle] = useState('');
    const [editAgreementTitle, setEditAgreementTitle] = useState('');
    const [notificationContent, setNotificationContent] = useState({});
    const [notificationEditContent, setNotificationEditContent] = useState({});
    const [notificationInfoSelect, setNotificationInfoSelect] = useState('');
    const [notificationEditSelect, setTermsEditSelect] = useState('');
    const [notificationList, setNotificationList] = useState([]);
    const [editingNotification, setEditingNotification] = useState(null);
    const [data, setData] = useState(''); // 에디터 내용 담을 변수
    const [fileName, setFileName] = useState(''); // 파일 이름 변수
    const [update, setUpdate] = useState(false); // 업데이트 상태 변수
    const MySwal = withReactContent(Swal);

    const paginate = pageNumber => setCurrentPage(pageNumber);

    useEffect(() => {
        notificationSelect();
    }, []);

    useEffect(() => {
      // console.log('notificationEditContent : ', notificationEditContent);
    },[notificationEditContent]);

    const notificationSelect = async () => {
        const response = await adminApi.notificationSelect();
        const data = response.data;

        // console.log(data);
        setNotificationList(data);
        setCheckedListBasic(new Array(data.length).fill(false));
    }

    const showModal = () => {
        setIsModalVisible(true);
        setNotificationInfoSelect('exposure');
        setNotificationContent({
            ...notificationContent, notificationState: true
        });
    };

    const showEditModal = (notification) => {
        setEditingNotification(notification);
        setEditAgreementTitle(notification.notificationTitle);
        setData(notification.notificationContent); // 에디터 내용 설정
        setFileName(''); // 파일 이름 초기화
        setTermsEditSelect(notification.notificationState ? 'exposure' : 'hide');
        setNotificationEditContent({
            ...notificationContent,
            notificationTitle: notification.notificationTitle,
            notificationContent: notification.notificationContent,
            notificationState: notification.notificationState
        });
        setIsEditModalVisible(true);
    };

    const handleOk = () => {
        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html:
                `
                <div>
                    <div class='${admin.admin_seller_add_title}'><span>공지를 등록 하시겠습니까?</span></div>
                    <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                    <button class='mx-1' id='seller_add_cancel'>취소</button>
                    <button class='mx-1' id='seller_add_success'>등록</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });
        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);

        function sellerAddSuccess(e) {
            if (e.target && e.target.id === 'seller_add_success') {
                notificationAdd();
            }
        }

        const notificationAdd = async () => {
            const response = await adminApi.notificationAdd({ ...notificationContent, notificationContent: data });
            const dataResponse = response.data;

            if (dataResponse === 'SUCCESS') {
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
                notificationSelect();
                MySwal.close();
                setIsModalVisible(false);
            } else {
                alert('등록에 실패하였습니다');
            }
        }

        function sellerAddCancel(e) {
            if (e.target && e.target.id === 'seller_add_cancel') {
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);

                MySwal.close();
            }
        }
    };

    const handleEditOk = async () => {
        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html:
                `
                <div>
                    <div class='${admin.admin_seller_add_title}'><span>공지를 수정 하시겠습니까?</span></div>
                    <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                    <button class='mx-1' id='seller_edit_cancel'>취소</button>
                    <button class='mx-1' id='seller_edit_success'>수정</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });
        document.addEventListener('click', sellerEditSuccess);
        document.addEventListener('click', sellerEditCancel);

        function sellerEditSuccess(e) {
            if (e.target && e.target.id === 'seller_edit_success') {
                termsEdit();
            }
        }

        const termsEdit = async () => {
            const response = await adminApi.notificationEdit(editingNotification.notificationNo, { ...notificationEditContent, notificationContent: data });
            const dataResponse = response.data;

            if (dataResponse === 'SUCCESS') {
                document.removeEventListener('click', sellerEditSuccess);
                document.removeEventListener('click', sellerEditCancel);
                alert('수정에 성공하였습니다.');
                notificationSelect();
                MySwal.close();
                setIsEditModalVisible(false);
            } else {
                alert('수정에 실패하였습니다');
            }
        }

        function sellerEditCancel(e) {
            if (e.target && e.target.id === 'seller_edit_cancel') {
                document.removeEventListener('click', sellerEditSuccess);
                document.removeEventListener('click', sellerEditCancel);

                MySwal.close();
            }
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleEditCancel = () => {
        setIsEditModalVisible(false);
    };

    const handleAllCheckBasic = e => {
        setAllCheckedBasic(e.target.checked);
        setCheckedListBasic(checkedListBasic.map(() => e.target.checked));
    };

    const handleCheckBasic = (index, e) => {
        const updatedCheckedList = [...checkedListBasic];
        updatedCheckedList[index] = e.target.checked;
        setCheckedListBasic(updatedCheckedList);
        setAllCheckedBasic(updatedCheckedList.every(checked => checked));
    };

    const handleSingleDeleteBasic = async (notificationNo) => {
        // console.log('notificationNo : ', notificationNo);

        const response = await adminApi.singleNotificationDelete(notificationNo);
        if (response.data === 'SUCCESS') {
            notificationSelect();
        } else {
            alert('삭제에 실패하였습니다');
        }
    }

    const handleDeleteBasic = async () => {
        const termsToDelete = notificationList.filter((_, index) => checkedListBasic[index]).map(term => term.notificationNo);
        // console.log(termsToDelete);
        const response = await adminApi.notificationDelete(termsToDelete);
        if (response.data === 'SUCCESS') {
            notificationSelect();
        } else {
            alert('삭제에 실패하였습니다');
        }
    };

    const onBasicTermsTitle = (e) => {
        setAgreementTitle(e.target.value);
        setNotificationContent({
            ...notificationContent, notificationTitle: e.target.value
        });
    };

    const onBasicEditTermsTitle = (e) => {
        setEditAgreementTitle(e.target.value);
        setNotificationEditContent({
            ...notificationEditContent, notificationTitle: e.target.value
        });
    };

    const onBasicSelect = (value) => {
        setNotificationInfoSelect(value);
        setNotificationContent({
            ...notificationContent, notificationState: value === 'exposure'
        });
    };

    const onBasicEditSelect = (value) => {
        setTermsEditSelect(value);
        setNotificationEditContent({
            ...notificationEditContent, notificationState: value === 'exposure'
        });
    };

    const notificationStateChange = (notificationState) => {
        return notificationState ? '노출' : '숨김';
    };
    const onDetailContent  = () => {}

    return (
        <div className={`${admin.adimn_operate_agree_box} px-3 py-3`}>
            <div>
                <div className={`${admin.adimn_operate_agree_title}`}><span>공지</span></div>
                    <div>
                        <div className={`${admin.admin_operate_agree_info_txt} py-2`}><span>이벤트 페이지 공지/발표에 보이는 공지사항입니다.</span></div>
                        <div className={`${admin.admin_operate_agree_add_btn} py-2`}>
                            <Button onClick={showModal}>공지추가</Button>
                            {checkedListBasic.some(checked => checked) && (
                                <Button className='ms-1' onClick={handleDeleteBasic}>삭제</Button>
                            )}
                        </div>
                        <div>
                            <div>
                                <div className={`${admin.adimn_operate_agree_content_header}`}>
                                    <div><input type="checkbox" checked={allCheckedBasic} onChange={handleAllCheckBasic} /></div>
                                    <div><span>공지명</span></div>
                                    <div><span>공지내용</span></div>
                                    <div><span>공지상태</span></div>
                                    <div><span>관리</span></div>
                                </div>
                                {notificationList.map((item, index) => (
                                    <div className={`${admin.adimn_operate_agree_content_content}`} key={item.notificationNo}>
                                        <div><input type="checkbox" checked={checkedListBasic[index]} onChange={e => handleCheckBasic(index, e)} /></div>
                                        <div><span>{item.notificationTitle}</span></div>
                                        <div dangerouslySetInnerHTML={{ __html: item.notificationContent }}></div>
                                        <div><span>{notificationStateChange(item.notificationState)}</span></div>
                                        <div className={`${admin.admin_operate_agree_content_btn}`}>
                                            <div><Button onClick={() => showEditModal(item)}>수정</Button></div>
                                            <div><Button onClick={() => handleSingleDeleteBasic(item.notificationNo)}>삭제</Button></div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <Pagination current={currentPage} total={totalItems} onChange={paginate} pageSize={itemsPerPage} />
                        </div>
                    </div>
            </div>
            <Modal
                title="공지 추가"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="등록"
                cancelText="취소"
                className={admin.admin_terms_modal}
            >
                <div className={`${admin.admin_terms_modal_title_box}`}>
                    <div className='pe-1'>
                        <Input
                            placeholder="공지 제목을 입력하세요"
                            value={agreementTitle}
                            onChange={onBasicTermsTitle}
                            className={`${admin.admin_terms_modal_title}`}
                        />
                    </div>
                    <div className='ps-1'>
                        <Select
                            className={admin.admin_terms_modal_select}
                            value={notificationInfoSelect ? notificationInfoSelect : ''}
                            onChange={onBasicSelect}
                        >
                            <Option value='exposure'>노출</Option>
                            <Option value='hide'>숨김</Option>
                        </Select>
                    </div>
                </div>
                <div className='position-relative'>
                    <TextEditor
                        setData={setData}
                        admin={admin}
                        fileName={fileName}
                        setFileName={setFileName}
                        update={update}
                        data={data}
                        onDetailContent ={onDetailContent }
                    />
                </div>
            </Modal>
            <Modal
                title="공지 수정"
                visible={isEditModalVisible}
                onOk={handleEditOk}
                onCancel={handleEditCancel}
                okText="수정"
                cancelText="취소"
                className={admin.admin_terms_modal}
            >
                <div className={`${admin.admin_terms_modal_title_box}`}>
                    <div className='pe-1'>
                        <Input
                            placeholder="공지 제목을 입력하세요"
                            value={editAgreementTitle}
                            onChange={onBasicEditTermsTitle}
                            className={`number${admin.admin_terms_modal_title}`}
                        />
                    </div>
                    <div className='ps-1'>
                        <Select
                            className={admin.admin_terms_modal_select}
                            value={notificationEditSelect}
                            onChange={onBasicEditSelect}
                        >
                            <Option value='exposure'>노출</Option>
                            <Option value='hide'>숨김</Option>
                        </Select>
                    </div>
                </div>
                <div className='position-relative'>
                    <TextEditor
                        setData={setData}
                        admin={admin}
                        fileName={fileName}
                        setFileName={setFileName}
                        update={update}
                        data={data}
                        onDetailContent ={onDetailContent }
                    />
                </div>
            </Modal>
        </div>
    );
};

export default AdminNotification;
