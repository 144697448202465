import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import * as shopby from '../../../apis/shopby';
import store from './store.module.css';
import { animated } from 'react-spring';
import { useSpring, useTransition } from '@react-spring/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { FaStar } from 'react-icons/fa';
import styled from 'styled-components';
import StoreProductInfo from '../../../components/market/store/detail/StoreProductInfo';
import ReviewComment from '../../../components/market/store/detail/ReviewComment';
import FaqScreen from '../../../components/market/store/detail/FaqScreen';
import BusinessInfo from '../../../components/market/store/detail/BusinessInfo';
import { Select } from 'antd';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import { LoginContext } from '../../../contexts/LoginContextProvider';
import * as storeapi from '../../../apis/store';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const { Option } = Select;

const ARRAY = [0, 1, 2, 3, 4];

const StoreDetail = () => {
    const MySwal = withReactContent(Swal);
    const { isLogin, sellerlogout, userInfo, loginCheck, roles } = useContext(LoginContext);
    const navigate = useNavigate();
    const { productNo } = useParams();
    const [product, setProduct] = useState({});
    const [productImg, setProductImg] = useState([]);
    const [productShopName, setProductShopName] = useState('');
    const [activeTab, setActiveTab] = useState('상품설명');
    const [loading, setLoading] = useState(false);
    const [shared, setShared] = useState(false);
    const [clipboardMessage, setClipboardMessage] = useState('');
    const [optionNameList, setOptionNameList] = useState([]);
    const [optionValueList, setOptionValueList] = useState([]);
    const [deliveryInfo, setDeliveryInfo] = useState();
    const [categories, setCategories] = useState({});
    const [selectedOptions, setSelectedOptions] = useState({});
    const [finalSelections, setFinalSelections] = useState([]);
    const [quantity, setQuantity] = useState(1); // State for managing quantity for products without options
    const [showQuantitySelect, setShowQuantitySelect] = useState(false); // State to control the display of quantity selection
    const [optionSelect, setOptionSelect] = useState(false);
    const [shopInfo, setShopInfo] = useState(null);
    const [reviewList, setReviewList] = useState([]);
    const [reviewCount, setReviewCount] = useState(0);
    const [ratingAverage, setRatingAverage] = useState(0);
    const [averageRatingDecimal, setAverageRatingDecimal] = useState(0);
    const [isHeartClicked, setIsHeartClicked] = useState(false);
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [reviewFilterActive, setReviewFilterActive] = useState('basic');

    const generateId = () => Date.now();

    useEffect(() => {
        // console.log('finalSelections : ', finalSelections);
    }, [finalSelections]);

    const handleSelectChange = (value, categoryName) => {
        const updatedOptions = { ...selectedOptions };
    
        if (value === "선택 안함") {
            updatedOptions[categoryName] = "선택 안함";
        } else {
            updatedOptions[categoryName] = value;
        }
    
        setSelectedOptions(updatedOptions);
    
        const essentialOptions = optionNameList.filter(option => option.optionEssential);
        const optionalOptions = optionNameList.filter(option => !option.optionEssential);
        const allOptionsSelected = Object.keys(updatedOptions).length === essentialOptions.length + optionalOptions.length;
    
        if (!allOptionsSelected) {
            return;
        }
    
        const selectedOptionsArray = Object.values(updatedOptions).filter(optionValue => optionValue !== "선택 안함");
    
        const isDuplicate = finalSelections.some(selection =>
            JSON.stringify(selection.selections) === JSON.stringify(updatedOptions)
        );
    
        if (isDuplicate) {
            alert('이미 선택된 옵션입니다.');
            return;
        }
    
        if (product.combinationState) {

            const filteredCombinations = product.combinationOptions.filter(combination =>
                combination.options.every(opt => {
                    const optionValue = updatedOptions[opt.optionName];
                    return optionValue === "선택 안함" || optionValue === opt.optionValue;
                })
            );

            // const selectedCombination = product.combinationOptions.find(combination =>
            //     combination.options.every(opt => updatedOptions[opt.optionName] === opt.optionValue)
            // );

            const selectedCombination = filteredCombinations.find(combination =>
                combination.stock > 0 && combination.status === "available"
            );
    
            // if (selectedCombination && selectedCombination.stock > 0) {
            if (selectedCombination) {
                const newSelection = {
                    id: generateId(),
                    selections: { ...updatedOptions },
                    quantity: 1,
                    addPrice: selectedCombination.price,
                    stockCnt: selectedCombination.stock,
                };
                setFinalSelections([...finalSelections, newSelection]);
            } else {
                console.error("선택된 조합 옵션이 없습니다 또는 품절입니다.");
                return;
            }
        } else {
            const matchedOptions = selectedOptionsArray.every(optionValue =>
                optionValueList.some(option => option.optionValueName === optionValue)
            );
    
            if (!matchedOptions) {
                console.error(`Selected option combination "${selectedOptionsArray.join('|')}" not found.`);
                return;
            }
    
            const addPrice = selectedOptionsArray.reduce((total, optionValue) => {
                const option = optionValueList.find(opt => opt.optionValueName === optionValue);
                return total + (option ? option.optionPrice : 0);
            }, 0);
    
            const stockCnt = Math.min(...selectedOptionsArray.map(optionValue => {
                const option = optionValueList.find(opt => opt.optionValueName === optionValue);
                return option ? option.optionInventory : Infinity;
            }));
    
            const newSelection = {
                id: generateId(),
                selections: { ...updatedOptions },
                quantity: 1,
                addPrice: addPrice,
                stockCnt: stockCnt,
            };
    
            setFinalSelections([...finalSelections, newSelection]);
        }
    
        setTimeout(() => {
            setSelectedOptions({});
        }, 0);
    };

    const sliderRef = useRef();

    const [clicked, setClicked] = useState(
        ARRAY.map((_, idx) => idx < ratingAverage)
    );

    const [ratings, setRatings] = useState({
        5: 0,
        4: 0,
        3: 0,
        2: 0,
        1: 0,
    });
    const [highestCount, setHighestCount] = useState(0);

    useEffect(() => {
        // ratings가 업데이트 될 때마다 highestCount를 업데이트
        setHighestCount(Math.max(...Object.values(ratings)));
    }, [ratings]);

    useEffect(() => {

        // console.log('shopUserId : ', product.userId);
        const shopSelect = async (sellerId) => {
            const response = await storeapi.sellerShopSelect(sellerId);
            const data = response.data;

            // console.log('shop : ' , data);
            setShopInfo(data);
        }

        if(product.userId) {
            shopSelect(product.userId);
        }

        favorityCheck();
    }, [product]);

    useEffect(() => {
        // console.log('ratings : ', ratings);
    },[ratings]);


    useEffect(() => {
        const selectReview = async () => {
            try {
                const response = await storeapi.selectReview(productNo);
                const data = response.data;
    
                // console.log('reviewSelect : ', data);
    
                // data가 배열인지 확인하고, 그렇지 않다면 빈 배열로 설정
                const reviews = Array.isArray(data) ? data : [];
    
                setReviewList(reviews);
    
                // 리뷰 수 설정
                setReviewCount(reviews.length);
    
                // 데이터에서 rating 값을 추출하여 배열로 만들기
                const ratingValues = reviews.map(review => review.rating);
    
                // 소수점 첫째 자리까지 평균 계산
                const averageRatingWithDecimal = (
                    ratingValues.reduce((acc, curr) => acc + curr, 0) / ratingValues.length
                ).toFixed(1);
    
                // 정수로 평균 계산
                const averageRating = Math.floor(averageRatingWithDecimal);
    
                // 기존 ratings 상태 업데이트
                setRatings(prevRatings => {
                    const updatedRatings = { ...prevRatings };
                    // 새로 가져온 rating 값들에 대한 카운트 업데이트
                    ratingValues.forEach(rating => {
                        updatedRatings[rating] = (updatedRatings[rating] || 0) + 1;
                    });
                    return updatedRatings;
                });
    
                // 평균값 로그 출력
                // console.log('Average Rating (Integer):', averageRating);
                // console.log('Average Rating (Decimal):', averageRatingWithDecimal);
    
                // 상태 업데이트
                setRatingAverage(averageRating);
                setAverageRatingDecimal(averageRatingWithDecimal);
            } catch (error) {
                console.error('Failed to fetch reviews:', error);
                // 에러가 발생할 경우 적절한 상태 초기화 또는 에러 처리 로직 추가
                setReviewList([]);
                setReviewCount(0);
                setRatingAverage(0);
                setAverageRatingDecimal(0);
            }
        };
    
        selectReview();
    }, [productNo]);
    

    useEffect(() => {
        setClicked(ARRAY.map((_, idx) => idx < ratingAverage));
    }, [ratingAverage]);

    useEffect(() => {
        const productDetail = async () => {
            const response = await storeapi.productDetaile(productNo);
            const data = response.data;

            if (response.status === 200) {
                // console.log('productDetail : ', data);
                setProduct(data);
                setProductImg(data.productListImage);
                setOptionNameList(data.optionNameList);
                setOptionValueList(data.optionValueList);

                const deliveryResponse = await storeapi.deliverySelect(data.deliveryTemplate, data.userId);
                const deliveryData = deliveryResponse.data;
                setDeliveryInfo(deliveryData);

                userViews(data.productNumber, data.userId, data.mainCategory);
            }
        };
        productDetail();

        setLoading(true);

    }, [productNo]);

    const userViews = async (productNumber, sellerId, mainCategory) => {
        const response = await storeapi.userViews(userInfo&&userInfo?.userId, productNumber, sellerId, mainCategory);
        const data = response.data;
        
        // console.log('userViews : ', data);
    }

useEffect(() => {
        const categoriesTemp = {};
    
        if (product.combinationState) {
            // 조합 옵션 처리
            product.combinationOptions.forEach(combination => {
                combination.options.forEach(option => {
                    if (!categoriesTemp[option.optionName]) {
                        categoriesTemp[option.optionName] = new Set();
                    }
                    categoriesTemp[option.optionName].add(option.optionValue);
                });
            });
        } else {
            // 단일 옵션 처리
            optionNameList.forEach(option => {
                categoriesTemp[option.optionName] = new Set();
            });
    
            optionValueList.forEach(option => {
                categoriesTemp[option.optionName].add(option.optionValueName);
            });
        }
    
        // 중복 제거 후 categories 상태 설정
        Object.keys(categoriesTemp).forEach(key => {
            categoriesTemp[key] = Array.from(categoriesTemp[key]);
        });
    
        setCategories(categoriesTemp);
    }, [optionNameList, optionValueList, product.combinationOptions, product.combinationState]);

    const handleBack = () => {
        navigate(-1);
    }

    useEffect(() => {
        if (!window.Kakao.isInitialized()) {
            window.Kakao.init(process.env.REACT_APP_KAKAO_API_KEY);
        }
    }, []);

    const transitions = useTransition(shared, {
        from: { transform: "translateY(100%)" },
        enter: { transform: "translateY(0)" },
        leave: { transform: "translateY(100%)" },
        config: {
            duration: 300,
        },
    });

    const handleShare = () => {
        setShared(true);
    }

    const handleShardBack = () => {
        setShared(false);
    }

    const handleShareKakaoClick = () => {
        if (window.Kakao) {
            const kakao = window.Kakao;

            kakao.Share.sendDefault({
                objectType: 'feed',
                content: {
                    title: product ? product.productName : 'title',
                    description: '데일리덕에서 구매하고 포인트 받자!',
                    imageUrl: product ? product.productMainImage : 'https://dailyduck.com/img/kakaolink40_original.png',
                    link: {
                        mobileWebUrl: 'https://dailyduck.com/store/product/' + productNo,
                        webUrl: 'https://dailyduck.com/store/product/' + productNo,
                    },
                },
            });
        }
    }

    const handleTwitterShare = () => {
        const url = `https://dailyduck.com/store/product/${productNo}`;
        const text = `데일리덕에서 구매하고 포인트 받자!`;
        const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(url)}`;
        window.open(twitterUrl);
    };

    const handleNaverLineShare = () => {
        const url = `https://social-plugins.line.me/lineit/share?url=https://dailyduck.com/store/product/${productNo}`;
        window.open(url);
    }

    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    const handleSmsShare = () => {
        const productLink = `https://dailyduck.com/store/product/${productNo}`;
        const productName = `${product && product.productName}`;
        const productDescription = "데일리덕에서 구매하고 포인트 받자!";

        if (!isMobile) {
            alert("SMS 공유는 모바일 기기에서만 가능합니다.");
            return;
        }

        const message = encodeURIComponent(`${productName} - ${productDescription}\n링크: ${productLink}`);
        window.location.href = `sms:&body=${message}`;
    }

    const handleClipboardShare = async () => {
        const text = `https://dailyduck.com/store/product/${productNo}`;
        if ('clipboard' in navigator) {
            try {
                await navigator.clipboard.writeText(text);
                setClipboardMessage('클립보드에 복사되었습니다.');
                setTimeout(() => setClipboardMessage(''), 2000);
            } catch (err) {
                console.error('클립보드 복사에 실패했습니다: ', err);
            }
        } else {
            const textarea = document.createElement('textarea');
            textarea.value = text;
            document.body.appendChild(textarea);
            textarea.select();
            document.execCommand('copy');
            document.body.removeChild(textarea);
            setClipboardMessage('클립보드에 복사되었습니다.');
            setTimeout(() => setClipboardMessage(''), 2000);
        }
    }

    const handleMoreShare = () => {
        const productLink = `https://dailyduck.com/store/product/${productNo}`;
        const productName = `${product && product.productName}`;
        const productDescription = "데일리덕에서 구매하고 포인트 받자!";

        if (navigator.share) {
            navigator.share({
                title: `${productName}`,
                text: `${productDescription}`,
                url: `${productLink}`,
            })
                .then(() => alert('공유 성공!'))
                .catch((error) => alert('공유 실패:', error));
        } else {
            alert('Web Share API를 지원하지 않는 브라우저입니다.');
        }
    }

    const handleBuyClick = () => {
        if (optionNameList.length > 0) {
            setOptionSelect(true);
        } else {
            setShowQuantitySelect(true);
        }
    }

    const buyTransitions = useTransition(optionSelect, {
        from: { transform: "translateY(100%)" },
        enter: { transform: "translateY(0)" },
        leave: { transform: "translateY(100%)" },
        config: {
            duration: 300,
        },
    });

    const quantityTransitions = useTransition(showQuantitySelect, {
        from: { transform: "translateY(100%)" },
        enter: { transform: "translateY(0)" },
        leave: { transform: "translateY(100%)" },
        config: {
            duration: 300,
        },
    });

    const incrementQuantity = (id) => {
        setFinalSelections(finalSelections.map(selection => {
            if (selection.id === id) {
                if (selection.quantity < selection.stockCnt) {
                    return { ...selection, quantity: selection.quantity + 1 };
                } else {
                    toast.warn('재고수량이 부족합니다.', {
                        position: "bottom-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: true,
                    });
                    return selection;
                }
            } else {
                return selection;
            }
        }));
    };

    const incrementSimpleQuantity = () => {
        if (quantity < product.inventoryCnt) {
            setQuantity(prev => prev + 1);
        } else {
            toast.warn('재고수량이 부족합니다.', {
                position: "bottom-center",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: true,
            });
        }
    };

    const decrementSimpleQuantity = () => {
        setQuantity(prev => Math.max(1, prev - 1));
    };

    const decrementQuantity = (id) => {
        setFinalSelections(finalSelections.map(selection => 
            selection.id === id ? { ...selection, quantity: Math.max(1, selection.quantity - 1) } : selection
        ));
    };

    const handleDelete = (id) => {
        setFinalSelections(finalSelections.filter(selection => selection.id !== id));
    };

    useEffect(() => {
        // console.log('deliveryInfo : ', deliveryInfo)
    }, [deliveryInfo])
    
    const totalQuantity = finalSelections.length > 0  
    ? finalSelections.reduce((acc, curr) => acc + curr.quantity, 0)
    : quantity;

    const totalPrice = finalSelections.length > 0 
    ? finalSelections.reduce((acc, curr) => acc + (product.productsalePrice + curr.addPrice) * curr.quantity, 0)
    : product.productsalePrice !== null ? product.productsalePrice * quantity : product.productPrice * quantity;

    let shippingFee = deliveryInfo?.dtDeliveryPrice;
    if (totalPrice >= deliveryInfo?.dtDeliveryFree) {
        shippingFee = 0;
    }

    const cartAddBtn = async () => {
        MySwal.fire({
            customClass: {
                popup: `${store.store_cart_add_popup}`
            },
            html : 
            `
                <div>
                    <div class='${store.store_cart_add_title}'><span>장바구니 담기</span></div>
                    <div class='${store.store_cart_add_content}'><span>선택하신 아이템을 장바구니에 담을까요?</span></div>
                    <div class='${store.store_cart_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                      <button class='mx-1' id='cart_add_cancel'>아니오</button>
                      <button class='mx-1' id='cart_add_success'>예</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,

        });
        
        document.addEventListener('click', cartAddSuccess);

        
        function cartAddSuccess(e) {
            if(e.target && e.target.id == 'cart_add_success') {
                cartAdd();
                MySwal.close();
                document.removeEventListener('click', cartAddSuccess);
            }

            if(e.target && e.target.id == 'cart_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', cartAddSuccess);
            }
        }

        const cartAdd = async () => {
            // console.log('finalSelectionsCart : ',finalSelections);
            try {
                const newItem = {
                    userId: userInfo && userInfo?.userId,
                    productNo: product && product?.productNo,
                    productNumber: product && product?.productNumber,
                    productName: product && product?.productName,
                    sellerId: product && product?.userId,
                    selectOption: finalSelections.map(selection => ({
                        ...selection,
                        selections: Object.entries(selection.selections).map(([optionName, optionValue]) => ({
                            optionName,
                            optionValue
                        }))
                    })),
                    productImg: product && product?.productMainImage,
                    productPrice: product && product?.productPrice,
                    productSalePrice: product && product?.productsalePrice,
                    totalPrice: totalPrice,
                    totalQuantity: totalQuantity,
                    shopProfile: shopInfo.shopProfileImg,
                    shopName: shopInfo.shopName,
                    shopNo: shopInfo.shopNo,
                    deliveryFree : deliveryInfo.dtDeliveryFree,
                    deliveryPrice : deliveryInfo.dtDeliveryPrice,
                    deliveryType : deliveryInfo.dtDeliveryPm,
                    zipcode : deliveryInfo.dtShippingAddressZipcode,
                    deliveryPhone : deliveryInfo.dtCompanyTel,
                    commission : product && product?.commission,
                    consignmentStatus : product && product?.consignmentStatus,
                    combinationState : product && product?.combinationState,
                    optionPrice : finalSelections.reduce((total, item) => total + (item.addPrice || 0), 0)

                };
                
                // console.log('newItem : ', newItem);
                const response = await storeapi.cartAdd(newItem);
                const data = await response.data;

                // console.log(data);

                if(data === 'DUPLICATION') {
                    MySwal.close();
                    MySwal.fire({
                        customClass: {
                            popup: `${store.store_cart_add_popup}`
                        },
                        html : 
                        `
                        <div>
                        <div class='${store.store_cart_add_title}'><span>장바구니 담기</span></div>
                        <div class='${store.store_cart_add_content}'><span>선택하신 아이템이 이미 장바구니에 담겨있습니다.</span></div>
                        <div class='${store.store_cart_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                        <button class='mx-1' id='cart_add_return_cancel'>확인</button>
                        </div>
                        </div>
                        `,
                        showConfirmButton: false,
                        showCancelButton: false,
                    });
                    document.removeEventListener('click', cartAddSuccess);
                    document.addEventListener('click', cartAddReturnSuccess);
                }

                if(data === 'SUCCESS') {
                    MySwal.close();
                    MySwal.fire({
                        customClass: {
                            popup: `${store.store_cart_add_popup}`
                        },
                        html : 
                        `
                        <div>
                        <div class='${store.store_cart_add_title}'><span>장바구니 담기</span></div>
                        <div class='${store.store_cart_add_content}'><span>선택하신 아이템을 장바구니에 담았습니다.</span><br/><span>장바구니로 이동할까요?</span></div>
                        <div class='${store.store_cart_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                        <button class='mx-1' id='cart_add_return_cancel'>아니오</button>
                        <button class='mx-1' id='cart_add_return_success'>예</button>
                        </div>
                        </div>
                        `,
                        showConfirmButton: false,
                        showCancelButton: false,
                    });
                    document.removeEventListener('click', cartAddSuccess);
                    document.addEventListener('click', cartAddReturnSuccess);
                }
            } catch (error) {
                console.err('error : ', error)
            }
        }

        function cartAddReturnSuccess(e) {
            if(e.target && e.target.id == 'cart_add_return_success') {
                MySwal.close();
                document.removeEventListener('click', cartAddReturnSuccess);
                navigate('/cart');
            }
            if(e.target && e.target.id == 'cart_add_return_cancel') {
                MySwal.close();
                document.removeEventListener('click', cartAddReturnSuccess);
            }
        }
    }

    const buyNow = async () => {
        // console.log('buyNow : ', product);
        // console.log('buyNow : ', finalSelections);

        const newItem = {
            paymentNumber : Date.now(), // 주문번호
            paymentImg : product.productMainImage, // 주문상품 이미지
            paymentProductNumber : product.productNumber, // 주문상품 번호
            paymentProductName : product.productName, // 주문상품 이름
            paymentOption : [finalSelections], // 선택옵션
            principalAmount : product.productsalePrice, // 상품 원 금액
            deliveryType : deliveryInfo.dtDeliveryPm, // 배송 종류
            deliveryPrice : shippingFee, // 배송비
            paymentPrice : totalPrice, // 총 결제 금액
            sellerId : product.userId,
            shopName : shopInfo.shopName,
            totalQuantity : totalQuantity,
            consignmentStatus : product.consignmentStatus,
            combinationState : product.combinationState
        }

        // console.log('newItem : ', newItem);

        navigate(`/store/payment/${newItem.paymentNumber}`, {state : {paymentInfo : newItem, deliveryInfo : deliveryInfo}});
    }

    useEffect(() => {
        // console.log('shippingFee : ', shippingFee);
    }, [shippingFee]);

    const onLogin = () => {
        Swal.fire({
            text: '로그인 페이지로 이동합니다.',
            showCancelButton: false,
            confirmButtonText: '확인',
            focusConfirm: false,
            customClass: {
                confirmButton: 'my-confirm-btn'
            }
        }).then((result) => {
            if (result.isConfirmed) {
                navigate('/');
            }
        });
    }

    const onmessage = async () => {
        if(!isLogin) {
            MySwal.fire({
                customClass: {
                    popup: `${store.store_login_check_popup}`
                },
                html: `
                      <div>
                          <div class='${store.store_login_check_popup_title}'><span>로그인 후 사용 가능합니다.</span></div>
                          <div class='${store.store_login_check_popup_sub_title}'><span>로그인하여 메세지 기능을 사용해보세요!</span></div>
                          <div class='${store.store_login_check_popup_btn}'>
                            <button id='seller_add_cancel'>취소</button>
                            <button id='seller_add_success'>로그인</button>
                          </div>
                      </div>
                  `,
                showConfirmButton: false,
                showCancelButton: false,
            });
            document.addEventListener('click', sellerAddSuccess);
            document.addEventListener('click', sellerAddCancel);

            async function sellerAddSuccess(e) {
                if (e.target && e.target.id === 'seller_add_success') {
                    MySwal.close();
                    document.removeEventListener('click', sellerAddSuccess);
                    document.removeEventListener('click', sellerAddCancel);
                    navigate('/');
                }
            }

            function sellerAddCancel(e) {
                if (e.target && e.target.id === 'seller_add_cancel') {
                    MySwal.close();
                    document.removeEventListener('click', sellerAddSuccess);
                    document.removeEventListener('click', sellerAddCancel);
                }
            }
        }

        if(isLogin) {
            const response = await storeapi.messageCheck(product && product.productNumber, userInfo && userInfo.userId, product && product.userId);
            const data = response.data;
    
            console.log(data);
    
            if(data != 'FAIL') {
                navigate(`/message/chat/${data}`);
            }
        }
    }

    const isSoldOut = (item) => {
        // 옵션이 없는 경우 inventoryCnt 값이 0인지 확인
        if (!item.optionValueList?.length && !item.combinationOptions?.length) {
          return item.inventoryCnt === 0;
        }
      
        // 단일 옵션이 있을 때, optionInventory가 모두 0 또는 null인지 확인
        if (Array.isArray(item.optionValueList) && item.optionValueList.length > 0) {
          return item.optionValueList.every(
            (option) => option.optionInventory === 0 || option.optionInventory === null
          );
        }
      
        // 조합 옵션이 있을 때, stock이 모두 0인지 확인
        if (Array.isArray(item.combinationOptions) && item.combinationOptions.length > 0) {
          return item.combinationOptions.every(
            (combination) => combination.stock === 0
          );
        }
      
        return false; // 기본값은 품절 아님
      };
      

    const handleRestockNotification = () => {

    }

    const favorityCheck = async () => {
        const response = await storeapi.favorityCheck(productNo, userInfo?.userId);
        const data = response.data;

        // console.log('favorityCheck : ', data);

        if(data !== null) {
            setIsHeartClicked(true);
        } 
        if(data === 'noData') {
            setIsHeartClicked(false);
        }
    }

    const handleHeartClick = async (productNo) => {
        if(!isLogin) {
            toast.error('로그인 후 사용해 주세요');
            return;
        }
        // console.log('productNo : ', productNo);

        if(isHeartClicked) {
            // console.log('해제');
            
            const response = await storeapi.storeWishlistRemove(productNo, userInfo?.userId);
            const data = response.data;
            // console.log('해제 : ', data);

            if(data === 'SUCCESS') {
                MySwal.fire({
                    customClass: {
                        popup: `${store.my_store_detail_favority_popup}`
                    },
                    html : 
                    `
                    <div>
                    <div class='${store.my_store_detail_favority_title}'><span>찜 목록에서 제거되었습니다.</span></div>
                    <div class='${store.my_store_detail_favority_confirm_btn_box}'>
                        <button id='favority_confirm'>확인</button>
                    </div>
                    </div>
                    `,
                    showConfirmButton: false,
                    showCancelButton: false,
                  });
                  document.addEventListener('click', favorityConfirm);
                  
                  function favorityConfirm(e) {
                      if(e.target && e.target.id == 'favority_confirm') {
                          MySwal.close();
                          document.removeEventListener('click', favorityConfirm);
                        //   favorityCheck();
                        
                        setIsHeartClicked(!isHeartClicked);
                      }
                  }
            }
        } else {
            // console.log('등록');

            const response = await storeapi.storeWishlistAdd(productNo, userInfo?.userId);
            const data = response.data;

            if(data === 'SUCCESS') {
                MySwal.fire({
                    customClass: {
                        popup: `${store.my_store_detail_favority_popup}`
                    },
                    html : 
                    `
                    <div>
                    <div class='${store.my_store_detail_favority_title}'><span>찜 목록에 등록되었습니다.</span></div>
                    <div class='${store.my_store_detail_favority_confirm_btn_box}'>
                        <button id='favority_confirm'>확인</button>
                    </div>
                    </div>
                    `,
                    showConfirmButton: false,
                    showCancelButton: false,
                  });
                  document.addEventListener('click', favorityConfirm);
                  
                  function favorityConfirm(e) {
                      if(e.target && e.target.id == 'favority_confirm') {
                          MySwal.close();
                          document.removeEventListener('click', favorityConfirm);
                        //   favorityCheck();
                        setIsHeartClicked(!isHeartClicked);
                      }
                  }
            }
        }
    }

    useEffect(() => {
        if (Object.keys(selectedOptions).length === optionNameList.length) {
            // 모든 옵션이 선택되었을 때 상태 초기화
            setSelectedOptions({});
        }
    }, [selectedOptions, optionNameList.length]);

    const handleFilterModal = () => {
        setShowFilterModal(true);
    }

    useEffect(()=> {
        // console.log('트루여부 : ', product?.productPrice === null && product?.productsalePrice === null || product?.productPrice === product?.productsalePrice);
    }, [product]);

    return (
        <>

            <div className={`${store.store_product_detail_container}`}>
                <div className={`${store.store_product_detail_header}`}>
                    <div className={`${store.store_product_detail_header_left}`}><button onClick={() => handleBack()}><span className="material-symbols-outlined">arrow_back_ios</span></button></div>
                    <div className={`${store.store_product_detail_header_right}`}>
                        <button><span className="material-symbols-outlined" onClick={() => handleShare()}>share</span></button>
                    </div>
                </div>
                        
                {
                    !loading ?
                    <div className={`${store.store_product_detail_loading}`}>
                        <img src={`${process.env.PUBLIC_URL}/img/loading/kwangduck_loading.gif`} alt="덕덕" />
                    </div>
                    :
                    <div className={`${store.store_product_detail_content} ${shared ? store.store_detaile_shared_active : ''} ${optionSelect ? store.store_detaile_shared_active : ''}`}>
                        <div className='position-relative'>
                            <div className={`${store.store_favority_heart_box}`}>
                                <button
                                    className='heart_btn'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleHeartClick(product.productNo);
                                    }}
                                    >
                                        {isHeartClicked ? (
                                        <img
                                            className='icon_size'
                                            src={`${process.env.PUBLIC_URL}/img/icon/venue/heart_fill.png`}
                                            alt='찜O'
                                            />
                                        ) : (
                                        <img
                                            className='icon_size'
                                            src={`${process.env.PUBLIC_URL}/img/icon/venue/white_heart.png`}
                                            alt='찜X'
                                        />
                                    )}
                                    </button>
                            </div>
                            <Swiper
                                slidesPerView={1}
                                spaceBetween={30}
                                loop={true}
                                pagination={{
                                clickable: true,
                                }}
                                modules={[Pagination, Navigation]}
                                className={`${store.store_my_slider}`}
                            >
                                <SwiperSlide><img src={product.productMainImage} alt='이미지' /></SwiperSlide>
                                {Array.isArray(product.productListImage) && product.productListImage.map((slide, index) => (
                                    <SwiperSlide key={index}><img src={slide} alt='이미지' /></SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                        <div>
                            <div className={`px-3 pt-3 ${store.store_shop_message_box}`}>
                                <Link to={`/shopinfo/${product.userId}`} className={`${store.store_shop_profile_box}`}>
                                    <div><img src={shopInfo && shopInfo?.shopProfileImg ? shopInfo?.shopProfileImg : `${process.env.PUBLIC_URL}/img/user/default_img.png`} alt="프로필" /></div>
                                    <div><span className='ps-2'>{shopInfo && shopInfo.shopName}</span></div>
                                    <div><span className="material-symbols-outlined">chevron_right</span></div>
                                </Link>
                                <div className={`${store.store_bottom_btn_middle}`}>
                                    <button onClick={() => onmessage()}>
                                        <div><span className="material-symbols-outlined">mail</span></div>
                                        <div className='ps-1'><span>메세지</span></div>
                                    </button>
                                </div>
                            </div>
                            <div className={`px-3 pt-3 ${store.store_shop_product_name}`}>
                                <span>{product?.productName}</span>
                            </div>
                            <div className={`ps-3 ${store.store_shop_review_box}`}>
                                <Wrap className=''>
                                    <Stars>
                                        {ARRAY.map((el, idx) => {
                                        return (
                                            <FaStar
                                            key={idx}
                                            size="20"
                                            className={clicked[el] && 'yellowStar'}
                                            />
                                            );
                                        })}
                                    </Stars>
                                    <Link onClick={() => setActiveTab('리뷰')}>
                                        <RatingText>리뷰 {product && reviewCount}개 보기</RatingText>
                                    </Link>
                                </Wrap>
                            </div>
                            <div className='d-flex venus_price_box pt-2 px-3'>
                                    {
                                        product?.discountRate !== 0 &&
                                        <div className='py-2 signature_color bold pe-3'>
                                            <span>{product?.discountRate}%</span>
                                        </div>
                                    }
                                <div className='py-1 pe-2 fs-5'>
                                    {
                                        product?.productsalePrice !== null ?
                                        <span className='bold'>
                                            &#8361;
                                            {product.productsalePrice !== undefined 
                                                ? product.productsalePrice.toLocaleString() 
                                                : 'N/A'
                                            }
                                        </span>
                                        :
                                        <span className='bold'>
                                            &#8361;
                                            {product.productPrice !== undefined 
                                                ? product.productPrice.toLocaleString() 
                                                : 'N/A'
                                            }
                                        </span>
                                    }
                                </div>
                                <div className='py-2 gray_color px-2'>
                                {
                                    product?.productsalePrice === null || (product?.productPrice === product?.productsalePrice) 
                                    ? null 
                                    : <span className='line-through'>
                                        &#8361;
                                        {product.productPrice !== undefined 
                                        ? product.productPrice.toLocaleString() 
                                        : 'N/A'
                                        }
                                    </span>
                                }
                                </div>
                            </div>
                            <div className='d-flex justify-content-between text-center pt-4 px-3'>
                            <div className='dd-fs-6'>
                                <Link className={activeTab === '상품설명' ? 'check bold' : 'bold'} onClick={() => setActiveTab('상품설명')}>
                                    상품설명
                                </Link>
                            </div>
                            <div className='dd-fs-6'>
                                <Link className={activeTab === '리뷰' ? 'check bold' : 'bold'} onClick={() => setActiveTab('리뷰')}>
                                    리뷰
                                </Link>
                            </div>
                            <div className='dd-fs-6'>
                                <Link className={activeTab === 'FAQ' ? 'check bold' : 'bold'} onClick={() => setActiveTab('FAQ')}>
                                    FAQ
                                </Link>
                            </div>
                            <div className='dd-fs-6'>
                                <Link className={activeTab === '사업자정보' ? 'check bold' : 'bold'} onClick={() => setActiveTab('사업자정보')}>
                                    사업자정보
                                </Link>
                            </div>
                        </div>
                        <div className='bottom_line pt-1'></div>
                        {activeTab === '상품설명' && <StoreProductInfo product={product} Wrap2={Wrap2} Stars={Stars} ARRAY={ARRAY} FaStar={FaStar} clicked={clicked}
                                                        ratings={ratings} highestCount={highestCount} store={store} averageRatingDecimal={averageRatingDecimal}
                        />}
                        {
                            activeTab === '리뷰' && <ReviewComment reviewList={reviewList} reviewCount={reviewCount} ratingAverage={ratingAverage} averageRatingDecimal={averageRatingDecimal} ARRAY={ARRAY} FaStar={FaStar} clicked={clicked}
                                                        ratings={ratings} highestCount={highestCount} store={store} handleFilterModal={handleFilterModal} reviewFilterActive={reviewFilterActive}
                                                        />
                        }
                        {activeTab === 'FAQ' && <FaqScreen product={product} store={store} />}
                        {activeTab === '사업자정보' && <BusinessInfo />}
                        </div>
                    </div>
                }
                {!loading ? (
                    <div className={`${store.store_bottom_btn_box}`}>
                        <div className={`${store.store_bottom_btn_login}`}>
                        <button onClick={handleRestockNotification}>재입고 알림 신청</button>
                        </div>
                    </div>
                    ) : (
                    isLogin ? (
                        isSoldOut(product) ? (
                        <div className={`${store.store_bottom_btn_box}`}>
                            <div className={`${store.store_bottom_btn_login}`}>
                            <button onClick={handleRestockNotification}>재입고 알림 신청</button>
                            </div>
                        </div>
                        ) : (
                        <div className={`${store.store_bottom_btn_box}`}>
                            <div className={`${store.store_bottom_btn_cart}`}>
                            <button onClick={handleBuyClick}>장바구니</button>
                            </div>
                            <div className={`${store.store_bottom_btn_right}`}>
                            <button onClick={handleBuyClick}>구매하기</button>
                            </div>
                        </div>
                        )
                    ) : (
                        <div className={`${store.store_bottom_btn_box}`}>
                        <div className={`${store.store_bottom_btn_login}`}>
                            <button onClick={() => onLogin()}>로그인 후 이용하기</button>
                        </div>
                        </div>
                    )
                    )}
                {
                    shared &&
                    <div className={`${store.store_shared_background}`} >
                        {transitions((style, item) =>
                            item ? 
                            <animated.div className={`${store.store_shard_box}`} style={style}>
                                <div className='pt-5 px-3 d-flex justify-content-between'>
                                    <div><h5>공유하기</h5></div>
                                    <div><button className={`${store.store_shared_close}`} onClick={handleShardBack}><span className="material-symbols-outlined">close</span></button></div>
                                </div>
                                <div className='d-flex justify-content-center py-3'>
                                    <div className={`${store.store_shard_btn_box}`}>
                                        <button onClick={handleShareKakaoClick}>
                                            <div>
                                                <div><img src="../../img/icon/share/kakaotalk.png" alt="카카오톡" /></div>
                                                <div className='py-1'><span>카카오톡</span></div>
                                            </div>
                                        </button>
                                    </div>
                                    <div className={`${store.store_shard_btn_box}`}>
                                        <button onClick={handleTwitterShare}>
                                            <div>
                                                <div><img src="../../img/icon/share/twiter.png" alt="트위터" /></div>
                                                <div className='py-1'><span>트위터</span></div>
                                            </div>
                                        </button>
                                    </div>
                                    <div className={`${store.store_shard_btn_box}`}>
                                        <button onClick={handleNaverLineShare}>
                                            <div>
                                                <div><img src="../../img/icon/share/line.png" alt="라인" /></div>
                                                <div className='py-1'><span>라인</span></div>
                                            </div>
                                        </button>
                                    </div>
                                    <div className={`${store.store_shard_btn_box}`}>
                                        <button onClick={handleSmsShare}>
                                            <div>
                                                <div><img src="../../img/icon/share/message.png" alt="SMS" /></div>
                                                <div className='py-1'><span>SMS</span></div>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                                <div className='d-flex'>
                                    <div className={`${store.store_shard_btn_bottom_box}`}>
                                        <button onClick={() => handleClipboardShare()}>
                                            <div>
                                                <div><img src="../../img/icon/share/url.png" alt="" /></div>
                                                <div><span>URL</span></div>
                                            </div>
                                        </button>
                                    </div>
                                    <div className={`${store.store_shard_btn_bottom_box}`}>
                                        <button onClick={handleMoreShare}>
                                            <div>
                                                <div><img src="../../img/icon/share/more.png" alt="" /></div>
                                                <div><span>더보기</span></div>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                                {
                                    clipboardMessage !== '' ? 
                                    <div className={`${store.store_clipboard_box}`}><span>{clipboardMessage}</span></div>
                                    :
                                    null
                                }
                            </animated.div>
                            : null
                        )}
                    </div>
                }
                {
                    optionSelect &&
                    <div className={`${store.store_buy_option_background}`}>
                    {buyTransitions((style, item) =>
                        item ?
                        <animated.div className={`${store.store_buy_option_box}`} style={style}>
                            <div className={`${store.store_buy_option_box_header}`}>
                                <div><span>옵션</span></div>
                                <div><button className={`${store.store_final_option_select_close}`} onClick={() => setOptionSelect(false)}><span className="material-symbols-outlined">close</span></button></div>
                            </div>
                            <div className={`${store.store_option_select_box}`}>
                                {Object.entries(categories).map(([categoryName, values], index) => {
                                    const isEssential = optionNameList.find(option => option.optionName === categoryName)?.optionEssential;
                                    const essentialLabel = isEssential ? "(필수)" : "(선택)";

                                    return (
                                        <Select
                                            key={`${categoryName}-${index}`} 
                                            placeholder={`${categoryName} ${essentialLabel}`} 
                                            className={`${store.store_buy_option_select}`}
                                            onChange={(value) => handleSelectChange(value, categoryName)}
                                            value={selectedOptions[categoryName] || undefined}
                                        >
                                            {!isEssential && (
                                                <Option key={`${categoryName}-none`} value="선택 안함">
                                                    <div style={{padding : '10px 0'}}>
                                                        <span>선택 안함</span>
                                                    </div>
                                                </Option>
                                            )}
                                            {Array.from(new Set(values)).map((value, valueIndex) => {
                                                let isOutOfStock = false;
                                                let displayPrice = "";

                                                if (!product.combinationState) {
                                                    // 단일 옵션일 경우
                                                    const optionDetails = optionValueList.find(option => option.optionValueName === value);
                                                    isOutOfStock = optionDetails && (optionDetails.optionInventory === 0 || optionDetails.optionInventory === null);
                                                    displayPrice = optionDetails && optionDetails.optionPrice ? `(+${optionDetails.optionPrice}원)` : "";
                                                } else {
                                                    // console.log('product.combinationOptions : ', product.combinationOptions);
                                                    // 조합 옵션일 경우
                                                    const relatedCombination = product.combinationOptions.find(combination =>
                                                        combination.options.some(opt => opt.optionName === categoryName && opt.optionValue === value)
                                                    );
                                                    isOutOfStock = relatedCombination && relatedCombination.stock === 0;

                                                    // 선택된 옵션으로 매칭되는 조합 옵션을 찾기
                                                    const matchedCombination = product.combinationOptions.find(combination =>
                                                        combination.options.every(opt => 
                                                            selectedOptions[opt.optionName] === opt.optionValue || opt.optionValue === value
                                                        )
                                                    );

                                                    if (matchedCombination) {
                                                        displayPrice = matchedCombination.price ? `(+${matchedCombination.price}원)` : "";
                                                    }
                                                }

                                                return (
                                                    <Option key={`${categoryName}-${value}-${valueIndex}`} value={value} disabled={isOutOfStock}>
                                                        <div style={{padding : '10px 0'}}>
                                                            <span>{value} {isOutOfStock ? "(품절)" : ""} {displayPrice}</span>
                                                        </div>
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                    );
                                })}
                                </div>
                                {
                                    finalSelections.length !== 0 &&
                                    <div>
                                        <div className={`${store.store_final_option_select_box}`} style={finalSelections.length === 1 ? {justifyContent: 'center'} : {}}>
                                            {finalSelections.map((selection, index) => (
                                                <div key={selection.id} className={`${store.store_final_option_select}`}>
                                                    <div className={`d-flex justify-content-between ${store.store_final_option}`}>
                                                        <div>
                                                            {Object.entries(selection.selections).map(([key, value], index, array) => (
                                                                <span key={key}>
                                                                    {`${value}`}
                                                                    {index < array.length - 1 ? '/' : ''}
                                                                </span>
                                                            ))}
                                                        </div>
                                                        <div>
                                                            <button onClick={() => handleDelete(selection.id)} className={`${store.store_final_option_select_close}`}><span className="material-symbols-outlined">close</span></button>
                                                        </div>
                                                    </div>
                                                    <div className='d-flex justify-content-between pt-3'>
                                                        <div className='d-flex align-items-center'>
                                                            <button className={`${store.store_final_option_count_btn}`} onClick={() => decrementQuantity(selection.id)}>-</button>
                                                            <span className={`${store.store_final_option_count_span}`}>{selection.quantity}</span>
                                                            <button className={`${store.store_final_option_count_btn}`} onClick={() => incrementQuantity(selection.id)}>+</button>
                                                        </div>
                                                        <div>
                                                            <span>{product && ((parseInt(product.productsalePrice) + selection.addPrice)*selection.quantity).toLocaleString()}원</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                }
                                {
                                    finalSelections.length !== 0 &&
                                    <div className='px-3 py-3'>
                                        <div className={`d-flex justify-content-between pb-1 pt-3 ${store.store_total_price_box}`}>
                                            <div><span>총 {totalQuantity}개의 상품</span></div>
                                            <div className={`${store.store_total_price}`}><span>{totalPrice.toLocaleString()}원</span></div>
                                        </div>
                                        <div>
                                            <div className={`d-flex justify-content-between py-1 ${store.store_delivery_shipping_fee_box}`}>
                                                <div><span>배송비</span></div>
                                                <div>{shippingFee !== 0 ? shippingFee.toLocaleString() + '원' : '무료'} ({deliveryInfo.dtDeliveryFree.toLocaleString()} 이상 무료배송)</div>
                                            </div>
                                        </div>
                                        <div className={`w-100 text-end ${store.store_delivery_fee_box}`}>
                                            <span>제주 및 도서지역 {deliveryInfo && deliveryInfo.dtOtherAddPrice.toLocaleString()}원 추가</span>
                                        </div>
                                        <div className={`d-flex justify-content-between ${store.store_cart_buy_btn_box} text-center py-3`}>
                                            <div className='w-100 me-2'><button className={`${store.store_cart_btn}`} onClick={cartAddBtn}>장바구니</button></div>
                                            <div className='w-100 ms-2'><button className={`${store.store_buy_btn}`} onClick={buyNow}>바로구매</button></div>
                                        </div>
                                    </div>
                                }
                        </animated.div>
                        : null
                    )}
                    </div>
                }
                {
                    showQuantitySelect &&
                    <div className={`${store.store_buy_option_background}`}>
                    {quantityTransitions((style, item) =>
                        item ?
                        <animated.div className={`${store.store_buy_option_box}`} style={style}>
                            <div className='d-flex justify-content-between pt-5 px-3'>
                                <div><h5>수량 선택</h5></div>
                                <div><button className={`${store.store_final_option_select_close}`} onClick={() => setShowQuantitySelect(false)}><span className="material-symbols-outlined">close</span></button></div>
                            </div>
                            <div className='px-3 py-3'>
                                <div className='d-flex align-items-center justify-content-center'>
                                    <button className={`${store.store_final_option_count_btn}`} onClick={decrementSimpleQuantity}>-</button>
                                    <span className={`${store.store_final_option_count_span}`}>{quantity}</span>
                                    <button className={`${store.store_final_option_count_btn}`} onClick={incrementSimpleQuantity}>+</button>
                                </div>
                                <div className='pt-3'>
                                    <div className={`d-flex justify-content-between pb-1 ${store.store_total_price_box}`}>
                                        <div><span>총 {quantity}개의 상품</span></div>
                                        <div className={`${store.store_total_price}`}><span>{totalPrice.toLocaleString()}원</span></div>
                                    </div>
                                    <div className={`d-flex justify-content-between py-1 ${store.store_delivery_shipping_fee_box}`}>
                                        <div><span>배송비</span></div>
                                        <div>{shippingFee !== 0 ? shippingFee.toLocaleString() + '원' : '무료'} ({deliveryInfo.dtDeliveryFree.toLocaleString()} 이상 무료배송)</div>
                                    </div>
                                    <div className={`w-100 text-end ${store.store_delivery_fee_box}`}>
                                        <span>제주 및 도서지역 {deliveryInfo && deliveryInfo.dtOtherAddPrice.toLocaleString()}원 추가</span>
                                    </div>
                                    <div className={`d-flex justify-content-between ${store.store_cart_buy_btn_box} text-center py-3`}>
                                        <div className='w-100 me-2'><button className={`${store.store_cart_btn}`} onClick={cartAddBtn}>장바구니</button></div>
                                        <div className='w-100 ms-2'><button className={`${store.store_buy_btn}`} onClick={buyNow}>바로구매</button></div>
                                    </div>
                                </div>
                            </div>
                        </animated.div>
                        : null
                    )}
                    </div>
                }
                <ToastContainer
                    hideProgressBar={true}
                    autoClose={1000}
                    position='top-center' 
                />
            </div>

            {
                showFilterModal &&
                <div className={`${store.store_review_filter_modal_container}`}>
                    <div className={`${store.store_review_filter_modal_box}`}>
                        <div className={`${store.store_review_filter_modal_header}`}>
                            <div><button onClick={() => setShowFilterModal(false)}><span className="material-symbols-outlined">close</span></button></div>
                        </div>
                        <div className={`${store.store_review_filter_modal_content}`}>
                            <div><button className={`${reviewFilterActive === 'basic' ? store.store_filter_active : ''}`} onClick={() => {setReviewFilterActive('basic'); setShowFilterModal(false);}}>기본순</button></div>
                            <div><button className={`${reviewFilterActive === 'popular' ? store.store_filter_active : ''}`} onClick={() => {setReviewFilterActive('popular'); setShowFilterModal(false);}}>인기순</button></div>
                            <div><button className={`${reviewFilterActive === 'rateHigh' ? store.store_filter_active : ''}`} onClick={() => {setReviewFilterActive('rateHigh'); setShowFilterModal(false);}}>별점 높은 순</button></div>
                            <div><button className={`${reviewFilterActive === 'rateRow' ? store.store_filter_active : ''}`} onClick={() => {setReviewFilterActive('rateRow'); setShowFilterModal(false);}}>별점 낮은 순</button></div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default StoreDetail;

const Wrap = styled.div`
  display: flex;
  padding-top: 15px;
`;
const Wrap2 = styled.div`
  display: flex;
  justify-content: center;
`;

const RatingText = styled.div`
  color: #787878;
  font-size: 1em;
  font-weight: 400;
  border-bottom: 1px solid #787878;
  padding-top: 2px;
  margin-left: 10px;
`;

const Stars = styled.div`
  display: flex;
  padding-top: 5px;

  & svg {
    color: #e4e4e4;
  }

  .yellowStar {
    color: #fcc419;
  }
`;
