import React, { useRef, useState } from 'react'
import { Pagination } from 'antd';

const SellerReviewMain = ( {seller, onReviewClick} ) => {

  const [ reviewList, setReviewList ] = useState([
    {reviewNo: 1, productName: 'Leon Bridges - Coming Home (Deluxe Edition) - 1 LP vlnyl', reviewContent: '유저가 작성한 리뷰 내용이 노출됩니다. 유저가 작성한 리뷰 내용이 노출됩니다. 유저가 작성한 리뷰 내용이 노출됩니다. 유저가 작성한 리뷰 내용이 노출됩니다.', reviewRegDate: '2023.01.23', reviewState: '답변예정'}
  ]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(6);
  const [totalItems, setTotalItems] = useState(0);

  
  // 페이지 번호 클릭 시 호출될 함수
  const paginate = pageNumber => setCurrentPage(pageNumber);

  return (
    <div className={`px-3 ${seller.seller_right_container}`}>
      <div>
        <div className='pt-3 pb-2 border-bottom'><span>리뷰 관리</span></div>
        <div className={`pt-4 pb-1 ${seller.seller_border_bottom_color} w-100 d-flex`}>
          <div className='w-10p dd-fs-7 d-flex align-item-center justify-content-center'><span>번호</span></div>
          <div className='w-35p dd-fs-7 d-flex align-item-center justify-content-center'><span>상품정보</span></div>
          <div className='w-35p dd-fs-7 d-flex align-item-center justify-content-center'><span>리뷰 내용</span></div>
          <div className='w-10p dd-fs-7 d-flex align-item-center justify-content-center'><span>작성일</span></div>
          <div className='w-10p dd-fs-7 d-flex align-item-center justify-content-center'><span>답변 유무</span></div>
        </div>
        <div>
          {
            reviewList.length != 0 ?
            Array.isArray(reviewList) && reviewList.map((review, index) => (
              <div className={`d-flex dd-fs-7 align-items-center py-3 ${seller.seller_store_review_box}`} onClick={() => onReviewClick(review.reviewNo)}>
                <div className='w-10p d-flex justify-content-center align-items-center'><span>{review.reviewNo}</span></div>
                <div className='w-35p d-flex justify-content-center align-items-center'>
                  <div className={`${seller.seller_store_review_img_box}`}><img src="../../img/album/album1.png" alt="상품이미지" /></div>
                  <div className='px-2'><span>{review.productName}</span></div>
                </div>
                <div className={`w-35p ${seller.seller_store_review_content_box} px-2`}><span>{review.reviewContent}</span></div>
                <div className='w-10p d-flex justify-content-center align-items-center'><span>{review.reviewRegDate}</span></div>
                <div className='w-10p d-flex justify-content-center align-items-center'><span>{review.reviewState}</span></div>
              </div>
            )) :
            <div className='w-100 text-center py-5 dd-fs-7 gray_color'>
              <div><span>등록된 내역이 없습니다.</span></div>
            </div>
          }
        </div>
        <Pagination current={currentPage} total={totalItems} onChange={paginate} pageSize={itemsPerPage} />  
      </div>
    </div>
  )
}

export default SellerReviewMain