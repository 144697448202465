import React, { useEffect, useState } from 'react'
import { Select } from 'antd';

const { Option } = Select;

const SellerStoreBusinessInfo = ( {seller, userInfo, auth} ) => {

  useEffect(() => {
    userSelect();
  }, [userInfo]);

  const userSelect = async () => {
    const response = await auth.userselect(userInfo.userId);
    const data = response.data;

    // console.log(data);
    setUser(data);
  }
  
  const [ user, setUser] = useState([]);
  return (
          <div className={`px-3 ${seller.seller_right_container}`}>
            <div>
              <div className='pt-3 pb-2 border-bottom'><span>사업자 정보</span></div>
              <div className={`pt-4 pb-1 ${seller.seller_border_bottom_color}`}><span>사업자 정보</span></div>
              <div>
                <div className='d-flex w-100 border-bottom align-items-center py-3'>
                  <div className={`${seller.seller_shop_left}`}><span>사업자등록번호</span></div>
                  <div className={`${seller.seller_shop_middle} px-3`}><input type="text" placeholder={user && user.businessRegistrationNumber}  disabled={true} /></div>
                </div>
                <div className='d-flex w-100 border-bottom align-items-center py-3'>
                  <div className={`${seller.seller_shop_left}`}><span>대표자명</span></div>
                  <div className={`${seller.seller_shop_middle} px-3`}>
                      <input type="text" placeholder={user && user.representativeName}  disabled={true}/>
                  </div>
                </div>
                <div className='d-flex w-100 border-bottom align-items-center py-3'>
                  <div className={`${seller.seller_shop_left}`}><span>업태</span></div>
                  <div className={`${seller.seller_shop_middle} px-3`}>
                      <input type="text" placeholder={user && user.businessStatus}  disabled={true}/>
                  </div>
                </div>
                <div className='d-flex w-100 border-bottom align-items-center py-3'>
                  <div className={`${seller.seller_shop_left}`}><span>업종</span></div>
                  <div className={`${seller.seller_shop_middle} px-3`}>
                      <input type="text" placeholder={user && user.businessSectors}  disabled={true}/>
                  </div>
                </div>
                <div className='d-flex w-100 border-bottom align-items-center py-3'>
                  <div className={`${seller.seller_shop_left}`}><span>상호명</span></div>
                  <div className={`${seller.seller_shop_middle} px-3`}>
                      <input type="text" placeholder={user && user.representativeName}  disabled={true}/>
                  </div>
                </div>
                <div className='d-flex w-100 border-bottom align-items-center py-3'>
                  <div className={`${seller.seller_shop_left}`}><span>사업장 소재지</span></div>
                  <div className={`${seller.seller_shop_middle} px-3`}>
                      <input type="text" placeholder={user && user.businessName}  disabled={true}/>
                  </div>
                </div>
                <div className='d-flex w-100 border-bottom align-items-center py-3'>
                  <div className={`${seller.seller_shop_left}`}><span>사업자 통장 사본</span></div>
                  <div className={`${seller.seller_shop_middle} px-3`}>
                      <input type="text" placeholder={user && user.businessBankbookImg}  disabled={true}/>
                  </div>
                </div>
                <div className='d-flex w-100 border-bottom align-items-center py-3'>
                  <div className={`${seller.seller_shop_left}`}><span>통신판매업신고번호(선택)</span></div>
                  <div className={`${seller.seller_shop_middle} px-3`}>
                      <input type="text" placeholder={user && user.businessMailOrderNumber}  disabled={true}/>
                  </div>
                </div>
                <div className='d-flex w-100 border-bottom align-items-center py-3'>
                  <div className={`${seller.seller_shop_left}`}><span>통신판매업신고증(선택)</span></div>
                  <div className={`${seller.seller_shop_middle} px-3`}>
                      <input type="text" placeholder={user && user.businessBankbookImg}  disabled={true}/>
                  </div>
                </div>
                <div className='d-flex w-100 border-bottom align-items-center py-3'>
                  <div className={`${seller.seller_shop_total}`}><span>*수정사항이 있는 경우 관리자에게 문의하여 요청해주세요.</span></div>
                </div>
              </div>
            </div>
          </div>
  )
}

export default SellerStoreBusinessInfo