import React, { useContext, useEffect, useState } from 'react';
import admin from '../../../admin.module.css';
import { Select, Pagination, Image } from 'antd';
import * as adminApi from '../../../../apis/admin';
import { LoginContext } from '../../../../contexts/LoginContextProvider';
import { format } from 'date-fns';
import { Option } from 'antd/es/mentions';

const AdminReview = () => {
  const { isLogin, adminlogout, userInfo, loginCheck, roles } = useContext(LoginContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5); // 초기 값으로 5개 설정
  const [totalItems, setTotalItems] = useState(0); // 초기 값으로 0 설정
  const [reviewList, setReviewList] = useState([]);
  const [selectedReviews, setSelectedReviews] = useState([]); // 선택된 리뷰 관리
  const [selectAll, setSelectAll] = useState(false); // 전체 선택 여부 관리
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [reviewInfo, setReviewInfo] = useState(null);
  const [imgList, setImgList] = useState([]);
  const [isImageClick, setIsImageClick] = useState(false); // 이미지 클릭 여부 추적

  useEffect(() => {
    if (roles.isAdmin) {
      allReviewSelect();
    } else {
      sellerReviewSelect();
    }
  }, [userInfo]);

  const sellerReviewSelect = async () => {
    const response = await adminApi.sellerReviewSelect(userInfo && userInfo?.userId);
    const data = response.data;
  
    // console.log('API 응답 데이터:', data); // 데이터가 배열인지 확인
  
    // 데이터가 배열인지 확인하고 변환
    const reviewArray = Array.isArray(data) ? data : Object.keys(data).map((key) => data[key]);
  
    setReviewList(reviewArray); // 배열로 변환 후 상태 업데이트
    setTotalItems(reviewArray.length); // 총 아이템 수 설정
  };
  
  const allReviewSelect = async () => {
    const response = await adminApi.allReviewSelect();
    const data = response.data;
  
    // console.log('API 응답 데이터:', data); // 데이터가 배열인지 확인
  
    // 응답 데이터가 배열인지 확인하고 변환
    const reservationsReviewArray = Array.isArray(data) ? data : Object.keys(data).map((key) => data[key]);
  
    setReviewList(reservationsReviewArray); // 배열로 변환 후 상태 업데이트
    setTotalItems(reservationsReviewArray.length); // 총 아이템 수 설정
  };
  

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const currentReviews = reviewList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const formatDate = (date) => {
    return format(new Date(date), 'yyyy-MM-dd HH:mm:ss');
  };

  // 전체 체크박스 클릭 시
  const onSelectAll = () => {
    const isSelectAll = !selectAll;
    setSelectAll(isSelectAll);
    setSelectedReviews(isSelectAll ? currentReviews : []);
  };

  // 개별 체크박스 클릭 시
  const onSelectReview = (review) => {
    const isSelected = selectedReviews.some((item) => item.storeReview.storeReviewNo === review.storeReview.storeReviewNo);
    const newSelectedReviews = isSelected
      ? selectedReviews.filter((item) => item.storeReview.storeReviewNo !== review.storeReview.storeReviewNo)
      : [...selectedReviews, review];

    setSelectedReviews(newSelectedReviews);
    setSelectAll(newSelectedReviews.length === currentReviews.length);
  };

  // 내보내기 버튼 클릭 시
  const onExport = () => {
    // console.log('Selected Reviews:', selectedReviews);
    // 내보내기 로직 추가
  };

  // 페이지당 항목 수 변경 시
  const onItemsPerPageChange = (value) => {
    setItemsPerPage(value);
    setCurrentPage(1); // 페이지 수를 초기화
  };

  const onItemReview = (item) => {
    if (!isImageClick) { // 이미지 클릭이 아닌 경우에만 모달을 열도록 설정
      setShowReviewModal(true);
      setReviewInfo(item);
      setImgList([
        item.storeReview.photo1,
        item.storeReview.photo2,
        item.storeReview.photo3,
        item.storeReview.photo4,
      ].filter((img) => img !== null));
    }
    setIsImageClick(false); // 상태 초기화
  };

  const onImageClick = (event) => {
    event.stopPropagation(); // 이벤트 전파 방지
    setIsImageClick(true); // 이미지 클릭 여부 설정
  };

  return (
    <div className={`${admin.admin_shop_review_box} px-3 py-3`}>
      <div>
        <div className={`${admin.admin_shop_review_header}`}><span>구매평</span></div>
        <div>
          <div className={`${admin.admin_shop_review_count_search} py-2`}>
            <div className='d-flex align-items-center'>
              <div><span>전체</span><span>{totalItems}</span><span>건</span></div>
            </div>
            <div>
              <div className={`${admin.admin_shop_review_search_box}`}>
                <div>
                  <Select className={`${admin.admin_shop_review_search_select}`}>
                    <Option value="">옵션 선택</Option>
                  </Select>
                </div>
                <div>
                  <input type="text" />
                </div>
                <div><button>검색</button></div>
              </div>
            </div>
          </div>
          <div className='d-flex justify-content-end py-1 align-items-center border-bottom'>
            <div className={`${admin.admin_shop_review_download_btn_box}`}><button onClick={onExport}><span className={`material-symbols-outlined ${admin.admin_gray_font_2}`}>download</span>내보내기</button></div>
            <div className='ps-1'>
              <Select defaultValue={itemsPerPage} onChange={onItemsPerPageChange} style={{ width: 120 }}>
                <Option value={5}>5개씩보기</Option>
                <Option value={10}>10개씩보기</Option>
                <Option value={15}>15개씩보기</Option>
              </Select>
            </div>
          </div>
          <div className={`${admin.admin_shop_review_content_container}`}>
            <div>
              <div className={`${admin.admin_shop_review_content_header}`}>
                <div><input type="checkbox" checked={selectAll} onChange={onSelectAll} /></div>
                <div><span>번호</span></div>
                <div><span>상품정보</span></div>
                <div><span>리뷰이미지</span></div>
                <div><span>리뷰내용</span></div>
                <div><span>작성자</span></div>
                <div><span>작성일자</span></div>
                <div><span>답변유무</span></div>
                <div><span></span></div>
                  {currentReviews.map((review, index) => {
                    const options = review.storePayment.options;

                    const images = [
                      review.storeReview.photo1,
                      review.storeReview.photo2,
                      review.storeReview.photo3,
                      review.storeReview.photo4,
                    ].filter((image) => image !== null);

                    const isChecked = selectedReviews.some((item) => item.storeReview.storeReviewNo === review.storeReview.storeReviewNo);

                    return (
                      <div className={`${admin.admin_shop_review_content_content}`} key={review.storeReview.storeReviewNo} onClick={() => onItemReview(review)}>
                        <div><input type="checkbox" checked={isChecked} onChange={() => onSelectReview(review)} /></div>
                        <div><span>{index + 1 + (currentPage - 1) * itemsPerPage}</span></div>
                        <div className='px-1'>
                          <div><img src={review.storePayment.paymentImg} alt="상품이미지" /></div>
                          <div className='ps-1'>
                            <div><span>{review.storePayment.paymentProductName}</span></div>
                            {options !== null && options.length > 0 ? (
                              options.map((option, index) => (
                                <div key={index}><span>{option.selectionOptionValue}</span></div>
                              ))
                            ) : (
                              <div><span>옵션없음</span></div>
                            )}
                          </div>
                        </div>
                        <div>
                          <Image.PreviewGroup>
                            <div className='d-flex justify-content-center align-items-center'>
                              {images.slice(0, 2).map((image, imgIndex) => (
                                <div className='px-1 py-1' key={imgIndex}>
                                  <Image
                                    src={image}
                                    alt=""
                                    width={50}
                                    height={50}
                                    onClick={onImageClick}
                                  />
                                </div>
                              ))}
                            </div>
                            <div className='d-flex justify-content-center align-items-center'>
                              {images.slice(2).map((image, imgIndex) => (
                                <div className='px-1 py-1' key={imgIndex}>
                                  <Image
                                    src={image}
                                    alt=""
                                    width={50}
                                    height={50}
                                    onClick={onImageClick}
                                  />
                                </div>
                              ))}
                            </div>
                          </Image.PreviewGroup>
                        </div>
                        <div>
                          <div><span>{review.storeReview.reviewText}</span></div>
                        </div>
                        <div>
                          <div><span>{review.users.userName}</span><span>({review.users.userId})</span></div>
                        </div>
                        <div>
                          <div><span>{formatDate(review.storeReview.reviewRegDate)}</span></div>
                        </div>
                        <div>
                          <div><span>{review.storeReview.storeReceiveReview !== null ? '답변완료' : '답변대기'}</span></div>
                        </div>
                        <div>
                          <div className='py-1'><button>숨기기</button></div>
                          <div className='py-1'><button>삭제</button></div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        <Pagination
          current={currentPage}
          pageSize={itemsPerPage}
          total={totalItems}
          onChange={paginate}
          style={{ marginTop: '20px', textAlign: 'center' }}
        />
      </div>
      {showReviewModal && (
        <div className={admin.admin_shop_review_modal_container}>
          <div className={admin.admin_shop_review_modal_box}>
            <div className={admin.admin_shop_review_modal_header}>
              <div><span>리뷰상세</span></div>
              <div><button onClick={() => setShowReviewModal(false)}><span className="material-symbols-outlined">close</span></button></div>
            </div>
            <div className={admin.admin_shop_review_modal_content}>
              <div>
                <div><span>구매정보</span></div>
                <div>
                  <div><img src={reviewInfo.storePayment.paymentImg} alt="" /></div>
                  <div>
                    <div><span>{reviewInfo.storePayment.paymentProductName}</span></div>
                    <div>
                      {reviewInfo.storePayment.options !== null && reviewInfo.storePayment.options.length > 0 ? (
                        reviewInfo.storePayment.options.map((option, index) => (
                          <div key={index}>
                            <span>{option.selectionOptionValue}</span>
                          </div>
                        ))
                      ) : (
                        <div><span>옵션없음</span></div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div><span>리뷰이미지</span></div>
                <div>
                  <Image.PreviewGroup>
                    {imgList.map((img, index) => (
                      <div key={index}>
                        <Image src={img} alt="" width={100} height={100} />
                      </div>
                    ))}
                  </Image.PreviewGroup>
                </div>
              </div>
              <div>
                <div><span>리뷰내용</span></div>
                <div><span>{reviewInfo.storeReview.reviewText}</span></div>
              </div>
              <div>
                <div><span>답변</span></div>
                <div><textarea name="" id=""></textarea></div>
              </div>
            </div>
            <div className={admin.admin_shop_review_modal_footer}>
              <div><button onClick={() => setShowReviewModal(false)}>취소</button></div>
              <div><button>답변등록</button></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminReview;
