import React, { useEffect, useState } from 'react';
import admin from '../../../admin.module.css';
import { format } from 'date-fns';
import * as adminApi from '../../../../apis/admin';
import { Pagination } from 'antd';

const AdminFeeSetting = () => {
    const [isTotalUserChecked, setIsTotalUserChecked] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [searchText, setSearchText] = useState('');
    const [sellerList, setSellerList] = useState([]);
    const [filteredSellerList, setFilteredSellerList] = useState([]);
    const [selectedSellers, setSelectedSellers] = useState({});
    const [updatedValues, setUpdatedValues] = useState({});
    const [consignmentFee, setConsignmentFee] = useState(0);
    const [sellerOwnFee, setSellerOwnFee] = useState(0);
    const [foreignPaymentFee, setForeignPaymentFee] = useState(0);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(7); // 페이지당 항목 수

    useEffect(() => {
        if (isTotalUserChecked === 'individual') sellerSelect();
    }, [isTotalUserChecked]);

    const sellerSelect = async () => {
        const response = await adminApi.sellerSelect();
        const data = response.data;
        setSellerList(data);
        // sellerList가 업데이트된 후 updatedValues 초기화
        const initialValues = {};
        data.forEach((item) => {
            initialValues[item.userNo] = {
                consignmentFee: item.consignmentFee,
                sellerOwnFee: item.sellerOwnFee,
                foreignPaymentFee: item.foreignPaymentFee,
            };
        });
        setUpdatedValues(initialValues);
    };

    const onTotalUserCheck = (e) => {
        setIsTotalUserChecked(e.target.id);
    };

    const handleDateChange = (e, isStart) => {
        if (isStart) {
            setStartDate(e.target.value);
        } else {
            setEndDate(e.target.value);
        }
    };

    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
    };

    const sellerType = (type) => {
        if (type === 'storeseller') return '스토어';
        if (type === 'supportseller') return '서포트';
    };

    const formatDate = (date) => {
        return format(new Date(date), 'yyyy-MM-dd HH:mm:ss');
    };

    const handleCheckboxChange = (userNo) => {
        setSelectedSellers(prevState => ({
            ...prevState,
            [userNo]: !prevState[userNo]
        }));
    };

    const handleInputChange = (e, userNo, field) => {
        const value = e.target.value;
        setUpdatedValues(prevState => ({
            ...prevState,
            [userNo]: {
                ...prevState[userNo],
                [field]: value
            }
        }));
    };

    const handleUpdateClick = async () => {
        const selectedUpdates = Object.keys(selectedSellers)
            .filter(userNo => selectedSellers[userNo])
            .map(userNo => ({
                userNo,
                consignmentFee: updatedValues[userNo]?.consignmentFee ?? sellerList.find(seller => seller.userNo === userNo).consignmentFee,
                sellerOwnFee: updatedValues[userNo]?.sellerOwnFee ?? sellerList.find(seller => seller.userNo === userNo).sellerOwnFee,
                foreignPaymentFee: updatedValues[userNo]?.foreignPaymentFee ?? sellerList.find(seller => seller.userNo === userNo).foreignPaymentFee,
            }));

        // console.log('Selected Updates:', selectedUpdates);

        try {
            const response = await adminApi.updateSellerFees(selectedUpdates);
            const data = response.data;
    
            if(data === 'SUCCESS') {
                alert('선택된 유저의 수수료가 수정되었습니다.');
                sellerSelect();
            } else {
                alert('선택된 유저의 수수료 수정에 실패했습니다.');
            }
        } catch (error) {
            console.error('해당 유저 업데이트 중 오류가 발생했습니다.', error);
        }
    };

    const allSelected = Object.values(selectedSellers).some(selected => selected);

    useEffect(() => {
        filterData();
    }, [startDate, endDate, searchText, sellerList]);

    const filterData = () => {
        let filteredData = sellerList;
    
        // 날짜 필터링
        if (startDate && endDate) {
            filteredData = filteredData.filter(item => {
                const userDate = new Date(item.userRegDate).setHours(0, 0, 0, 0); // 시간 제거
                const start = new Date(startDate).setHours(0, 0, 0, 0);
                const end = new Date(endDate).setHours(23, 59, 59, 999); // 종료일의 끝까지 포함
    
                return userDate >= start && userDate <= end;
            });
        }
    
        // 텍스트 필터링
        if (searchText) {
            const searchTextLower = searchText.toLowerCase();
            filteredData = filteredData.filter(item => {
                const classificationKorean = item.userClassification === 'storeseller' ? '스토어' : 
                                              item.userClassification === 'supportseller' ? '서포트' : '';
    
                return (
                    (item.shopName && item.shopName.toLowerCase().includes(searchTextLower)) ||
                    (item.userId && item.userId.toLowerCase().includes(searchTextLower)) ||
                    (classificationKorean && classificationKorean.includes(searchText)) ||
                    (item.userName && item.userName.toLowerCase().includes(searchTextLower)) ||
                    (item.businessName && item.businessName.toLowerCase().includes(searchTextLower)) ||
                    (item.businessLocation && item.businessLocation.toLowerCase().includes(searchTextLower))
                );
            });
        }
    
        setFilteredSellerList(filteredData);
    };

    const handleAllUserFeeUpdate = async () => {
        try {
            const response = await adminApi.allUserFeeUpdate(consignmentFee, sellerOwnFee, foreignPaymentFee);
            const data = response.data;
    
            if(data === 'SUCCESS') {
                alert('모든 셀러의 수수료가 일괄 수정되었습니다.');
                sellerSelect();
            } else {
                alert('수수료 일괄 수정에 실패했습니다.');
            }
        } catch (error) {
            console.error('에러가 발생했습니다. ', error);
        }
    }

    const paginatedData = filteredSellerList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    return (
        <div className={`${admin.admin_seller_box} px-3 py-3`}>
            <div>
                <div className={`${admin.admin_calculate_header}`}>
                    <div><span>정산 수수료 설정</span></div>
                </div>
                <div>
                    <div className={`${admin.admin_fee_setting_usercheck_box}`}>
                        <div className={`${admin.admin_fee_setting_usercheck}`}>
                            <div>
                                <div><input type="radio" id='all' name='userCheck' onChange={onTotalUserCheck} /></div>
                                <div><label htmlFor="all">스토어 전체회원적용</label></div>
                            </div>
                            <div>
                                <div><input type="radio" id='individual' name='userCheck' onChange={onTotalUserCheck} /></div>
                                <div><label htmlFor="individual">개별회원적용</label></div>
                            </div>
                        </div>
                        {
                            isTotalUserChecked === 'all' &&
                            <div className={`${admin.admin_fee_setting_usercheck_all}`}>
                                <div className={`${admin.admin_fee_setting_usercheck_all_title}`}><span>*PG수수료와 플랫폼 수수료를 포함한 금액을 입력해주세요.</span></div>
                                <div className={`${admin.admin_fee_setting_usercheck_all_content}`}>
                                    <div>
                                        <div><span>기본 셀러 수수료</span></div>
                                        <div>
                                            <div>
                                                <div><input type="number" onChange={(e) => setSellerOwnFee(e.target.value)} /></div>
                                                <div><span>%</span></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div><span>해외결제수수료</span><br /><span>(기본셀러에만 적용)</span></div>
                                        <div>
                                            <div>
                                                <div><input type="number" onChange={(e) => setForeignPaymentFee(e.target.value)} /></div>
                                                <div><span>%</span></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div><span></span>데일리덕 위탁 상품</div>
                                        <div>
                                            <div>
                                                <div><input type="number" onChange={(e) => setConsignmentFee(e.target.value)} /></div>
                                                <div><span>%</span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`${admin.admin_fee_setting_usercheck_all_update_btn}`}>
                                    <button onClick={handleAllUserFeeUpdate}>수정</button>
                                </div>
                            </div>
                        }
                    </div>
                    {
                        isTotalUserChecked === 'individual' &&
                        <div className={`${admin.admin_calculate_proceed_detail_box}`}>
                            <div>
                                <div className={`${admin.admin_calculate_proceed_detail_title}`}><span>유저리스트</span></div>
                                <div className={`${admin.admin_calculate_proceed_detail_search_btn_box}`}>
                                    <div>
                                        <div className={`${admin.admin_calculate_proceed_detail_payment_date}`}>
                                            <div><span>가입일</span></div>
                                            <div>
                                                <div><input type="date" value={startDate} onChange={(e) => handleDateChange(e, true)} /></div>
                                                <div>&nbsp;<span>~</span>&nbsp;</div>
                                                <div><input type="date" value={endDate} onChange={(e) => handleDateChange(e, false)} /></div>
                                            </div>
                                        </div>
                                        <div className={`${admin.admin_calculate_proceed_detail_search}`}>
                                            <div><span>검색</span></div>
                                            <div>
                                                <div><input type="text" value={searchText} onChange={handleSearchChange} /></div>
                                                <div><span className="material-symbols-outlined">search</span></div>
                                            </div>
                                        </div>
                                    </div>
                                    {allSelected && (
                                        <div className={`${admin.admin_calculate_fee_setting_update_btn}`}>
                                            <button onClick={handleUpdateClick}>수정</button>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className={`${admin.admin_fee_setting_user_list_container}`}>
                                <div className={`${admin.admin_fee_setting_user_list_header}`}>
                                    <div><input type="checkbox" onChange={(e) => {
                                        const checked = e.target.checked;
                                        const newSelections = {};
                                        sellerList.forEach(item => {
                                            newSelections[item.userNo] = checked;
                                        });
                                        setSelectedSellers(newSelections);
                                    }} /></div>
                                    <div><span>셀러구분</span></div>
                                    <div><span>상점이름</span></div>
                                    <div><span>유저아이디</span></div>
                                    <div><span>위탁수수료</span></div>
                                    <div><span>기본수수료</span></div>
                                    <div><span>해외결제수수료</span></div>
                                    <div><span>가입일</span></div>
                                </div>
                                {
                                    Array.isArray(paginatedData) && paginatedData.map((item, index) => (
                                        <div className={`${admin.admin_fee_setting_user_list_content}`} key={index}>
                                            <div>
                                                <input type="checkbox" checked={selectedSellers[item.userNo] || false} onChange={() => handleCheckboxChange(item.userNo)} />
                                            </div>
                                            <div><span>{sellerType(item.userClassification)}</span></div>
                                            <div><span>{item.shopName}</span></div>
                                            <div><span>{item.userId}</span></div>
                                            <div>
                                                <div><input type="number" value={updatedValues[item.userNo]?.consignmentFee || item.consignmentFee} onChange={(e) => handleInputChange(e, item.userNo, 'consignmentFee')} /></div>
                                                <div><span>%</span></div>
                                            </div>
                                            <div>
                                                <div><input type="number" value={updatedValues[item.userNo]?.sellerOwnFee || item.sellerOwnFee} onChange={(e) => handleInputChange(e, item.userNo, 'sellerOwnFee')} /></div>
                                                <div><span>%</span></div>
                                            </div>
                                            <div>
                                                <div><input type="number" value={updatedValues[item.userNo]?.foreignPaymentFee || item.foreignPaymentFee} onChange={(e) => handleInputChange(e, item.userNo, 'foreignPaymentFee')} /></div>
                                                <div><span>%</span></div>
                                            </div>
                                            <div><span>{formatDate(item.userRegDate)}</span></div>
                                        </div>
                                    ))
                                }
                            </div>
                            
                            <Pagination
                                current={currentPage}
                                total={filteredSellerList.length}
                                pageSize={itemsPerPage}
                                onChange={(page) => setCurrentPage(page)}
                            />
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default AdminFeeSetting;
