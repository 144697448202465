import React, { useContext, useEffect, useState } from 'react';
import { Modal, Pagination, Button, Checkbox, Input, Select } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import admin from '../../../admin.module.css';
import * as adminApi from '../../../../apis/admin';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { LoginContext } from '../../../../contexts/LoginContextProvider';

const AdminExcluded = () => {
    const { userInfo } = useContext(LoginContext);
    const MySwal = withReactContent(Swal);

    const [excludedKeyword, setExcludedKeyword] = useState('');
    const [keywordList, setKeywordList] = useState([]);
    const [keywordString, setKeywordString] = useState('');

    useEffect(() => {
        if(userInfo) {
            excludedKeywordRead();
        }
    }, [userInfo])

    const excludedKeywordRead = async () => {
        const response = await adminApi.excludedKeywordRead();
        const data = response.data;

        // console.log('excludedKeyword : ', data);
        setKeywordList(data);
        setKeywordString(data.map(item => item.excludedKeyword).join(', '));
    }

    const onExcludedKeyword = (e) => {
        setExcludedKeyword(e.target.value);
    }

    const handleExcludedKeywordAdd = () => {
        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html: `
                <div>
                    <div class='${admin.admin_seller_add_title}'><span>입력하신 키워드 ${excludedKeyword} 를 등록하시겠습니까?</span></div>
                    <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                      <button class='mx-1' id='seller_add_cancel'>아니오</button>
                      <button class='mx-1' id='seller_add_success'>예</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });

        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);

        async function sellerAddSuccess(e) {
            if (e.target && e.target.id === 'seller_add_success') {
                try {
                    const newItem = {
                        regUser : userInfo && userInfo?.userId,
                        excludedKeyword : excludedKeyword
                    }
                    const response = await adminApi.excludedKeywordAdd(newItem);
                    const data = response.data;
            
                    // console.log('refuseData : ', data);
        
                    if (data === 'SUCCESS') {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                                <div class='${admin.admin_seller_add_title}'><span>입력하신 ${excludedKeyword}가(이) 등록되었습니다.</span></div>
                                <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                    <button class='mx-1' id='seller_add_confirm'>확인</button>
                                </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);
        
                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                excludedKeywordRead();
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    } else {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                                <div class='${admin.admin_seller_add_title}'><span>입력하신 ${excludedKeyword} 키워드 등록에 실패했습니다.</span></div>
                                <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                    <button class='mx-1' id='seller_add_confirm'>확인</button>
                                </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);
        
                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                excludedKeywordRead();
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    }
                } catch (error) {
                    console.error('error : ', error);
                }
            }
        }
    
        function sellerAddCancel(e) {
            if (e.target && e.target.id === 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
            }
        }
    }

    const handleExcludedKeywordRemove = () => {
        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html: `
                <div>
                    <div class='${admin.admin_seller_add_title}'><span>입력하신 키워드 ${excludedKeyword} 를 삭제하시겠습니까?</span></div>
                    <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                      <button class='mx-1' id='seller_add_cancel'>아니오</button>
                      <button class='mx-1' id='seller_add_success'>예</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });

        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);

        async function sellerAddSuccess(e) {
            if (e.target && e.target.id === 'seller_add_success') {
                try {
                    const response = await adminApi.excludedKeywordRemove(userInfo && userInfo?.userId, excludedKeyword);
                    const data = response.data;
            
                    // console.log('refuseData : ', data);
        
                    if (data === 'SUCCESS') {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                                <div class='${admin.admin_seller_add_title}'><span>입력하신 ${excludedKeyword}가(이) 삭제되었습니다.</span></div>
                                <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                    <button class='mx-1' id='seller_add_confirm'>확인</button>
                                </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);
        
                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                excludedKeywordRead();
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    } else {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                                <div class='${admin.admin_seller_add_title}'><span>입력하신 ${excludedKeyword} 키워드 삭제에 실패했습니다.</span></div>
                                <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                    <button class='mx-1' id='seller_add_confirm'>확인</button>
                                </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);
        
                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                excludedKeywordRead();
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    }
                } catch (error) {
                    console.error('error : ', error);
                }
            }
        }
    
        function sellerAddCancel(e) {
            if (e.target && e.target.id === 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
            }
        }
    }

    return (
        <div className={`${admin.adimn_operate_suggest_container} px-3 py-3`}>
            <div className={`${admin.adimn_operate_suggest_box}`}>
                <div className={`${admin.adimn_operate_suggest_header}`}>
                    <div><span>제외키워드</span></div>
                    <div>
                        <span>*유저닉네임 설정시 제외할 키워드입니다.</span>
                        <br />
                        <span>*예) 하니, 뉴진스 등등...</span>
                    </div>
                </div>
                <div className={`${admin.adimn_operate_suggest_content}`}>
                    <div className={`${admin.adimn_operate_suggest_content_input}`}>
                        <div><span>키워드</span></div>
                        <div>
                            <input 
                                type="text" 
                                onChange={(e) => onExcludedKeyword(e)}
                                // onKeyDown={(e) => {
                                //     if (e.key === 'Enter') {
                                //         handleExcludedKeywordAdd();
                                //     }
                                // }} 
                            />
                        </div>
                        <div>
                            <div><button onClick={() => handleExcludedKeywordAdd()}>등록</button></div>
                            <div><button onClick={() => handleExcludedKeywordRemove()}>삭제</button></div>
                        </div>
                    </div>
                    <div className={`${admin.adimn_operate_suggest_content_textarea}`}>
                        <textarea name="" id="" disabled={true} value={keywordString}></textarea>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminExcluded;
