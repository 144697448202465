import styled from 'styled-components';
import Calendar from 'react-calendar';

const StyledCalendar = styled(Calendar)`
    width: 100% !important;
    height: 100vh !important;
    border: none !important;

    .react-calendar__navigation {
        width: 20% !important;
    }

    .react-calendar__viewContainer {
        border-radius: 12px !important;
        box-shadow: 0 0 6px 1px rgb(223, 223, 223) !important;
    }

    .react-calendar__navigation__arrow.react-calendar__navigation__next2-button,
    .react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
        display: none;
    }

    .react-calendar__navigation__arrow.react-calendar__navigation__prev-button,
    .react-calendar__navigation__arrow.react-calendar__navigation__next-button {
        border: 1px solid rgb(223, 223, 223);
        border-radius: 8px;
    }

    .react-calendar__month-view__weekdays__weekday {
        width: 100% !important;
        padding: 20px !important;
        border-bottom: 1px solid rgba(241, 242, 243, 1);
        border-right: 1px solid rgba(241, 242, 243, 1);
    }

    .react-calendar__month-view__weekdays div:nth-of-type(7) {
        border-right: none !important;
    }

    .react-calendar__month-view__days abbr {
        display: block;
        // width: 100%;
        height: 120px;
        // line-height: 50px;
        // padding-left: 20px;
        text-align: left !important;
        margin-left: 10px;
        margin-top: 10px;
    }

    .react-calendar__tile {
        padding: 0 !important;
    }

    .react-calendar__tile--now {
        width: 100%;
        height: 120px;
        background-color: inherit !important;
        display: flex; /* 플렉스 컨테이너 설정 */
        flex-direction: column; /* 수직 정렬 */
        justify-content: flex-start; /* 상단에 정렬 */
        align-items: flex-start; /* 왼쪽 정렬 */
    }

    .react-calendar__tile--now abbr {
        background-color: rgba(255, 114, 153, 1) !important;
        color: #ffffff !important;
        border-radius: 50% !important;
        display: flex !important;
        width: 24px !important;
        height: 24px !important;
        line-height: 24px !important;
        text-align: center !important;
        align-items: center !important;
        justify-content: center !important;
    }



    .react-calendar__tile--active {
        background-color: rgba(255, 114, 153, 1) !important;
    }

    .react-calendar__month-view__days__day {
        position: relative;
        width: 100%;
        height: 120px;
    }
`;

export default StyledCalendar;
