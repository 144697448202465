import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import * as auth from '../../apis/auth';
import axios from 'axios';
import ss from '../../containers/market/support/venue/support_shop.module.css';
import store from '../../containers/market/store/store.module.css';
import Select from 'react-select';
import { loadTossPayments, ANONYMOUS } from '@tosspayments/tosspayments-sdk';
import PostModal from '../market/store/PostModal';
import PaymentInfo from '../market/support/info/PaymentInfo';
import Country from '../../components/join/Country';
import { useSpring, animated } from 'react-spring';
import * as storeApi from '../../apis/store';
import { LoginContext } from '../../contexts/LoginContextProvider';

const generateRandomString = () => window.btoa(Math.random()).slice(0, 20);

const EventPaymentContainer = () => {
    const navigate = useNavigate();
    const { userInfo } = useContext(LoginContext);
    const context = useContext(LoginContext);
    const countries = context.countries;
    const location = useLocation();
    const paymentInfo = location.state?.paymentInfo || [];
    const [user, setUser] = useState(null);
    const [activeButton, setActiveButton] = useState('');
    const [bankSelect, setBankSelect] = useState('');
    const [cashReceipt, setCashReceipt] = useState('');
    const [cashReceiptType, setCashReceiptType] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('');
    const [exchangeRate, setExchangeRate] = useState(null);
    const [priceInUSD, setPriceInUSD] = useState(0);
    const [selectedPayment, setSelectedPayment] = useState(null);
    const [point, setPoint] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [isChecked, setIsChecked] = useState(false);
    const [selectedInfo, setSelectedInfo] = useState(null);
    const totalPoint = user?.userPoint || 0;
    const [price, setPrice] = useState(0);
    const [couponDiscount, setCouponDiscount] = useState(0);
    const [showDeliveryChange, setShowDeliveryChange] = useState(false);
    const [userDeliveryList, setUserDeliveryList] = useState([]);
    const [selectDelivery, setSelectDelivery] = useState(null);
    const [showDeliveryUpdate, setShowDeliveryUpdate] = useState(false);
    const [updateDeliveryInfo, setUpdateDeliveryInfo] = useState(null);
    const [showDeliveryAdd, setShowDeliveryAdd] = useState(false);
    const [addDeliveryInfo, setAddDeliveryInfo] = useState(null);
    const [addressCheck, setAddressCheck] = useState(false);
    const [address, setAddress] = useState('');
    const [modalVisible, setModalVisible] = useState(false);
    const [zoneCode, setZoneCode] = useState('');
    const [roadAddress, setroadAddress] = useState('');
    const [sigungu, setSigungu] = useState('');
    const [sido, setSido] = useState('');
    const [detailAddress, setDetailAddress] = useState();
    const [showCountry, setShowCountry] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredCountries, setFilteredCountries] = useState(countries);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [ couponList, setCouponList ] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState({
        img: "../../../../img/icon/country/South_Korea_(KR).png",
        name: user && user.userRepublic ? user.userRepublic : "Republic of Korea",
        number: "+82"
    });

    const [ready, setReady] = useState(false);
    const [widgets, setWidgets] = useState(null);
    const [amount, setAmount] = useState({
        currency: "KRW",
        value: 50000,
    });
    
    const [options, setOptions] = useState([]);
    const [availableCoupons, setAvailableCoupons] = useState(0);
    const [selectionCoupon, setSelectionCoupon] = useState(null);

    useEffect(() => {
        // console.log('paymentInfo : ', paymentInfo);
        // console.log('User : ', user);
    }, [user, paymentInfo]);

    useEffect(() => {
        if (ready) {
            widgets.setAmount({
                currency: "KRW",
                value: totalPrice,
            });
        }
    }, [totalPrice, ready]);

    const [isToggled, setToggle] = useState(false);
    const animation = useSpring({
        height: isToggled ? "150px" : "0px",
        overflow: "hidden",
        display: "flex",
        paddingTop: isToggled ? "1.5rem" : "0rem",
        paddingBottom: isToggled ? "1.5rem" : "0rem",
    });

    useEffect(() => {
        const fetchPaymentWidgets = async () => {
            const tossPayments = await loadTossPayments(process.env.REACT_APP_TOSS_CLIENT_KEY);
            const widgets = tossPayments.widgets({ customerKey: ANONYMOUS });
            setWidgets(widgets);
        };

        fetchPaymentWidgets();
        couponSelect();
    }, []);

    const couponSelect = async () => {
        const response = await auth.userCouponSelect(userInfo && userInfo.userId);
        const data = response.data;

        // console.log(data);
        setCouponList(data);
    };

    useEffect(() => {
        if (couponList.length > 0) {
            const availableCouponsList = couponList.filter(coupon => !coupon.userCouponUse && price >= coupon.couponDiscountCondition);
            const couponOptions = availableCouponsList.map(coupon => ({
                label: coupon.couponName,
                value: {
                    couponNo: coupon.couponNo,
                    couponNumber: coupon.couponNumber,
                    couponName: coupon.couponName,
                    couponRate: coupon.couponBenefitType === 'rate' ? coupon.couponBenefitNum : 0,
                    couponPrice: coupon.couponBenefitType === 'amount' ? coupon.couponBenefitNum : 0,
                    userId: userInfo.userId,
                    couponExpirationPeriod: coupon.couponEndDate,
                    couponApprovalDate: coupon.couponRegDate,
                    minPrice: coupon.couponDiscountCondition,
                    rateMaxPrice: coupon.couponMaximumDiscount,
                }
            }));
            setOptions([{ label: "사용안함", value: { couponNo: 1, couponName: "사용안함", couponRate: 0, couponPrice: 0 } }, ...couponOptions]);
            setAvailableCoupons(availableCouponsList.length);
        }
    }, [couponList, price, userInfo.userId]);

    useEffect(() => {
        const renderPaymentWidgets = async () => {
            if (widgets == null) {
                return;
            }

            try {
                await widgets.setAmount(amount);

                await widgets.renderPaymentMethods({
                    selector: "#payment-method",
                    variantKey: "DEFAULT",
                });

                await widgets.renderAgreement({
                    selector: "#agreement",
                    variantKey: "AGREEMENT",
                });

                setReady(true);
            } catch (error) {
                if (error.name === 'AlreadyWidgetRenderedError') {
                    widgets.cleanup();
                    await renderPaymentWidgets();
                } else {
                    console.error(error);
                }
            }
        };

        renderPaymentWidgets();
    }, [widgets]);

    const handleCountry = () => {
        setShowCountry(true);
    };

    const handleCountryClose = () => {
        setShowCountry(false);
    };

    const onDetailAddress = (e) => {
        setDetailAddress(e.target.value);
        setUpdateDeliveryInfo({
            ...updateDeliveryInfo, detailAddress: e.target.value
        });
    };

    const onAddDetailAddress = (e) => {
        setDetailAddress(e.target.value);
        setAddDeliveryInfo({
            ...addDeliveryInfo, detailAddress: e.target.value
        });
    };

    function handleSelectedCountry(country) {
        if (!country) {
            return;
        }

        setSelectedCountry({
            name: country.name,
            number: country.number,
            img: country.img
        });
    }

    const onAddressSave = async () => {
        const user = {
            userId: userInfo.userId,
            zipCode: zoneCode,
            streetNameAddress: roadAddress,
            detailAddress: detailAddress,
            sigungu: sigungu,
            sido: sido,
        };

        const response = await auth.addressChange(user);
        const data = await response.data;
    };

    useEffect(() => {
        if(userInfo.userId) {
            userSelect();
        }
    }, [userInfo]);


    useEffect(() => {
        if (exchangeRate !== null) {
            setPriceInUSD((totalPrice / exchangeRate).toFixed(2));
        }
    }, [totalPrice, exchangeRate]);

    const fetchExchangeRate = async () => {
        try {
            const response = await axios.get('https://api.exchangerate-api.com/v4/latest/USD');
            setExchangeRate(response.data.rates.KRW);
        } catch (error) {
            console.error("환율 정보를 가져오는 중 오류 발생", error);
        }
    };

    useEffect(() => {
        fetchExchangeRate();
        userDeliverySelect();
    }, []);

    const userSelect = async () => {
        if (userInfo?.userId) {
            const response = await auth.userselect(userInfo.userId);
            const data = response.data;

            setUser(data);
        }
    };

    const userDeliverySelect = async () => {
        if (userInfo?.userId) {
            try {
                const response = await auth.userDeliverySelect(userInfo.userId);
                const data = response.data;

                setUserDeliveryList(data);
                const defaultDelivery = data.find(delivery => delivery.deliveryType === 1);
                if (defaultDelivery) {
                    setSelectDelivery(defaultDelivery);
                }
            } catch (error) {
                console.error('error : ', error);
            }
        }
    };

    const calculateTotalPrice = () => {
        if (paymentInfo.length === 0) return 0;
        let total = paymentInfo.eventPrice;
        // paymentInfo.paymentOption.forEach(option => {
        //     if (option.addPrice) {
        //         total += option.addPrice;
        //     }
        // });
        // return total + paymentInfo.deliveryPrice;
        return total;
    };
    const calculateTotalProductPrice = () => {
        if (paymentInfo.length === 0) return 0;
        let total = paymentInfo.eventPrice;
        // paymentInfo.paymentOption.forEach(option => {
        //     if (option.addPrice) {
        //         total += option.addPrice;
        //     }
        // });
        return total;
    };

    useEffect(() => {
        setTotalPrice(calculateTotalPrice() - point - couponDiscount);
        setPrice(calculateTotalProductPrice());
    }, [point, couponDiscount, paymentInfo]);


    const handleCouponChange = (selectedOption) => {
        const selectedCoupon = selectedOption.value;
        if (price < selectedCoupon.minPrice) {
            alert(`현재 금액이 ${selectedCoupon.minPrice.toLocaleString()}원 보다 작아 이 쿠폰을 사용할 수 없습니다.`);
            setCouponDiscount(0);
            return;
        }

        let discount = 0;
        if (selectedCoupon.couponRate > 0) {
            discount = Math.min(price * (selectedCoupon.couponRate / 100), selectedCoupon.rateMaxPrice);
        } else if (selectedCoupon.couponPrice > 0) {
            discount = selectedCoupon.couponPrice;
        }
        setCouponDiscount(discount);
        setSelectionCoupon(selectedCoupon);

    };

    const handlePoint = (e) => {
        const value = e.target.value ? parseInt(e.target.value) : 0;
        if (value > totalPoint) {
            alert('보유한 포인트보다 높은 값을 입력할 수 없습니다.');
            setPoint(totalPoint);
        } else {
            setPoint(value);
        }
    };

    const handleUseAll = () => {
        setPoint(totalPoint);
    };

    const handleInfoClick = (info) => {
        setSelectedInfo(info);
    };

    const handleBack = () => {
        setSelectedInfo(null);
    };

    const addAddress = (sido, sigungu, streetNameAddress) => {
        return `${sido} ${sigungu} ${streetNameAddress}`;
    };

    const onDeliveryUpdate = (item) => {
        setUpdateDeliveryInfo(item);
        setShowDeliveryUpdate(true);
    };

    const onDeliveryDelete = async (deliveryNo) => {
        const response = await auth.deliveryDelete(deliveryNo);
        const data = response.data;

        if (data === 'SUCCESS') {
            alert('배송지가 삭제되었습니다.');
            userDeliverySelect();
        }
    };

    const onUpdateDeliverySave = async () => {
        try {
            const response = await auth.deliveryUpdate(updateDeliveryInfo);
            const data = response.data;

            if (data === 'SUCCESS') {
                userDeliverySelect();
                setShowDeliveryUpdate(false);
            }
        } catch (error) {
            console.error('error : ', error);
        }
    };

    const onAddDeliverySave = async () => {
        try {
            const response = await auth.deliveryAdd(addDeliveryInfo);
            const data = response.data;

            if (data === 'SUCCESS') {
                alert('배송지가 등록되었습니다.');
                userDeliverySelect();
                setShowDeliveryAdd(false);
            };
        } catch (error) {
            console.error('error : ', error);
        }
    };

    const handlePhoneNumber = (e) => {
        setPhoneNumber(e.target.value);

        setUpdateDeliveryInfo({
            ...updateDeliveryInfo, deliveryPhone: e.target.value
        });
    };

    const handleAddPhoneNumber = (e) => {
        setPhoneNumber(e.target.value);

        setAddDeliveryInfo({
            ...addDeliveryInfo, deliveryPhone: e.target.value
        });
    };

    const onUpdateDeliveryUser = (e) => {
        setUpdateDeliveryInfo({
            ...updateDeliveryInfo, deliveryUser: e.target.value
        });
    };

    const onUpdateDeliveryName = (e) => {
        setUpdateDeliveryInfo({
            ...updateDeliveryInfo, deliveryName: e.target.value
        });
    };

    const onAddDeliveryName = (e) => {
        setAddDeliveryInfo({
            ...addDeliveryInfo, deliveryName: e.target.value
        });
    };

    const onAddDeliveryUser = (e) => {
        setAddDeliveryInfo({
            ...addDeliveryInfo, deliveryUser: e.target.value
        });
    };

    const handleCloseModal = () => {
        if (address === '') {
            setAddressCheck(false);
        }
        setModalVisible(false);
    };

    const handleAddress = (data) => {
        const refinedRoadAddress = data.roadAddress.replace(`${data.sido} `, '').replace(`${data.sigungu} `, '');

        setZoneCode(data.zonecode);
        setSigungu(data.sigungu);
        setSido(data.sido);
        setroadAddress(refinedRoadAddress);
        setAddress(data.address);

        setUpdateDeliveryInfo((prevInfo) => ({
            ...prevInfo,
            zipcode: data.zonecode,
            sigungu: data.sigungu,
            sido: data.sido,
            streetNameAddress: refinedRoadAddress,
            detailAddress: ''
        }));

        setAddDeliveryInfo((prevInfo) => ({
            ...prevInfo,
            zipcode: data.zonecode,
            sigungu: data.sigungu,
            sido: data.sido,
            streetNameAddress: refinedRoadAddress,
            detailAddress: ''
        }));

        setTimeout(() => {
            setAddressCheck(true);
        }, 100);
    };

    function generateRandom(length) {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    useEffect(() => {
        // console.log('selectionCoupon : ', selectionCoupon);
    }, [selectionCoupon])

    const onPayment = async () => {
        try {
            
            if(selectDelivery === null) {
                alert('배송지를 등록해주세요.');
                return;
            }

            if (ready && widgets) {
                const orderId = generateRandomString();
                const paymentKey = generateRandom(20); // paymentKey 생성

                // const flattenedOptions = paymentInfo.paymentOption.flat();
                

                const newItem = {
                    eventNumber : paymentInfo.eventNumber,
                    // shopName : paymentInfo.shopName,
                    userId : user.userId,
                    // sellerId : paymentInfo.sellerId,
                    paymentKey : '',
                    paymentImg : paymentInfo.eventImage1,
                    paymentProductName : paymentInfo.eventTitle,
                    paymentProductNumber : paymentInfo.paymentProductNumber,
                    principalAmount : paymentInfo.eventPrice,
                    deliveryType : paymentInfo.deliveryType,
                    deliveryPrice : paymentInfo.deliveryPrice,
                    deliveryCompany : '',
                    waybillNumber : '',
                    // options : flattenedOptions,
                    deliveryUser : selectDelivery.deliveryUser,
                    deliveryAddress : selectDelivery.sido + ' ' + selectDelivery.sigungu + ' ' + selectDelivery.streetNameAddress + ' ' + selectDelivery.detailAddress,
                    zipcode : selectDelivery.zipcode,
                    deliveryPhone : selectDelivery.deliveryPhone,
                    point : point,
                    coupon : couponDiscount,
                    paymentPrice : totalPrice,
                    paymentType : activeButton,
                    paymentBankName : '',
                    paymentAccountNumber : '',
                    paymentDepositDeadline : '',
                    paymentState : '',
                    couponNo : selectionCoupon ? selectionCoupon.couponNo : '',
                    couponName : selectionCoupon ? selectionCoupon.couponName : ''
                }

                localStorage.setItem('eventPayment', JSON.stringify(newItem));

                await widgets.requestPayment({
                    orderId : orderId,
                    orderName: paymentInfo.eventTitle,
                    customerName: user.userName,
                    customerEmail: user.userEmail,
                    successUrl: `${window.location.origin}/event/payment/successcomplete`,
                    failUrl: `${window.location.origin}/payment/fail`,
                });
            }
        } catch (error) {
            console.error('Payment request error:', error);
        }
    };

    return (
        <>
            <div className='payment_box'>
                <div className={`payment_toggle ${store.store_payment_order_product}`}>
                    <div><span className='bold'>주문상품</span></div>
                    <div className={`${store.store_order_product_title}`}>
                        <span>{isToggled || !paymentInfo ? '' : paymentInfo.eventTitle}</span>
                        {isToggled ? (
                            <span className="material-symbols-outlined" onClick={() => setToggle(!isToggled)}>expand_less</span>
                        ) : (
                            <span className="material-symbols-outlined" onClick={() => setToggle(!isToggled)}>expand_more</span>
                        )}
                    </div>
                </div>
                {paymentInfo && (
                    <animated.div style={animation} className='d-flex justify-content-between payment_reservation px-3'>
                        <div className='payment_img'>
                            <img src={paymentInfo.eventImage1} alt="사진" />
                        </div>
                        <div className='payment_reservation_title ps-2'>
                            <div className='payment_reservation_area'><span>{paymentInfo.eventTitle}</span></div>
                            <div className='payment_reservation_option dd-fs-7'>
                                <div><span>{paymentInfo && paymentInfo.eventSubTitle}</span></div>
                                {/* {
                                    Array.isArray(paymentInfo.paymentOption) && paymentInfo.paymentOption.length > 0 && paymentInfo.paymentOption.map((optionArray, index) => (
                                        optionArray.map((option, optionIndex) => (
                                            <div key={optionIndex}>
                                                {
                                                    Object.entries(option.selections).map(([key, value]) => (
                                                        <span key={key}>{key} <br /> {value}</span>
                                                    ))
                                                }
                                            </div>
                                        ))
                                    ))
                                } */}
                            </div>
                        </div>
                    </animated.div>
                )}
                <div className='payment_couse mt-2'></div>
                <div className={`py-3 px-3 ${store.store_payment_orderer_info_box}`}>
                    <div><span>주문자 정보</span></div>
                    <div>
                        <div>
                            <div><span>이름</span></div>
                            <div><span>{user && user.userName}</span></div>
                        </div>
                        <div>
                            <div><span>이메일</span></div>
                            <div><span>{user && user.userEmail}</span></div>
                        </div>
                        <div>
                            <div><span>휴대폰</span></div>
                            <div><span>{user && user.userTel}</span></div>
                        </div>
                    </div>
                </div>
                <div className='payment_couse'></div>
                <div className={`${store.store_payment_delivery_info_box}`}>
                    <div>
                        <div><span>배송지</span></div>
                        <div><button onClick={() => setShowDeliveryChange(true)}>변경</button></div>
                    </div>
                    {
                        selectDelivery ?
                            <div>
                                <div><span>{selectDelivery.deliveryName}</span></div>
                                <div><span>{selectDelivery.zipcode}</span></div>
                                <div><span>{addAddress(selectDelivery.sido, selectDelivery.sigungu, selectDelivery.streetNameAddress)}</span></div>
                                <div><span>{selectDelivery.detailAddress}</span></div>
                            </div>
                            :
                            <div className='py-3 text-center dd-fs-8'>
                                <span>회원 정보에 등록된 배송지가 없습니다.</span><br /><span>배송지 등록을 진행해주세요.</span>
                            </div>
                    }
                </div>
                <div className='payment_couse'></div>
                {/* <div className='py-3 px-3'>
                    <div><span>포인트 사용</span></div>
                    <div className='d-flex justify-content-between pt-1 use_point_held_box'>
                        <div><span>보유 포인트</span></div>
                        <div><span>{user?.userPoint.toLocaleString()}P</span></div>
                    </div>
                    <div className='d-flex justify-content-between use_point_box'>
                        <div className='use_point_title'><span>사용할 포인트</span></div>
                        <div>
                            <div><input type="text" value={point} onChange={handlePoint} /></div>
                            <div><button onClick={handleUseAll}>전액사용</button></div>
                        </div>
                    </div>
                </div>
                <div className='payment_couse'></div>
                <div className='px-3 py-3 payment_coupon'>
                    <div><span>쿠폰 사용</span></div>
                    <div>
                        <Select
                            options={options}
                            isSearchable={false}
                            placeholder={`사용가능 쿠폰 ${availableCoupons}장 / 전체 ${couponList.length}장`}
                            onChange={handleCouponChange}
                            styles={{
                                option: (provided) => ({
                                    ...provided,
                                    padding: 15,
                                    fontSize: '0.9em'
                                }),
                            }}
                            className='payment_coupon_select'
                        />
                    </div>
                </div> */}
                {/* <div><span>포인트와 쿠폰 사용이 불가능한 결제입니다.</span></div> */}
                {/* <div className='payment_couse'></div> */}
                <div className='py-3 mx-3 border-bottom payment_pay_price_box'>
                    <div><span>결제금액</span></div>
                    <div>
                        <div className='d-flex justify-content-between'>
                            <div><span>총 상품금액</span></div>
                            <div><span>{price.toLocaleString()}</span><span>원</span></div>
                        </div>
                        {/* <div className='d-flex justify-content-between'>
                            <div><span>배송비</span></div>
                            <div><span>(+) {paymentInfo.deliveryPrice.toLocaleString()}</span><span>원</span></div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div><span>쿠폰 할인</span></div>
                            <div><span>(-) {couponDiscount.toLocaleString()}</span><span>원</span></div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div><span>포인트 사용</span></div>
                            <div><span>(-) </span>{point.toLocaleString()}<span></span><span>P</span></div>
                        </div> */}
                    </div>
                </div>
                <div className='py-3 px-3 d-flex justify-content-between bold'>
                    <div><span>총 결제금액</span></div>
                    <div>
                        <span>{totalPrice.toLocaleString()}</span><span>원</span>
                        {
                            paymentMethod === 'overseas' &&
                            <span>({priceInUSD} USD)</span>
                        }
                    </div>
                </div>
                <div className='payment_couse'></div>
                <div className='payment_select py-3 px-3'>
                    <div className='pb-2'><span>결제수단</span></div>
                    <div id="payment-method" className="payment_select_btn_box"></div>
                    <div id="agreement" className="w-100" />
                </div>
                <div className='payment_couse'></div>
                <div className='py-3 px-3'>
                    <div className='payment_info_box'>
                        <div className='py-1'><span className='signature_color'>· </span><span className='signature_color underline dd-fs-8' onClick={() => handleInfoClick('개인정보 수집 및 이용')}>개인정보 수집 및 이용</span></div>
                        <div className='py-1'><span className='signature_color'>· </span><span className='signature_color underline dd-fs-8' onClick={() => handleInfoClick('개인정보 제 3자 제공')}>개인정보 제 3자 제공</span></div>
                        <div className='py-1'><span className='signature_color'>· </span><span className='signature_color underline dd-fs-8' onClick={() => handleInfoClick('예약상품 안내사항(취소 및 환불 사항 등)')}>예약상품 안내사항(취소 및 환불 사항 등)</span></div>
                        <div className='py-1'><span className='signature_color'>· </span><span className='signature_color underline dd-fs-8' onClick={() => handleInfoClick('결제 전 주의사항 안내')}>결제 전 주의사항 안내</span></div>
                    </div>
                    <div className='payment_agreement py-3'>
                        <span className='pe-2'><input type="checkbox" id='agree' onChange={(e) => setIsChecked(e.target.checked)} /></span>
                        <label htmlFor='agree'>위 내용을 확인 하였으며 결제에 동의합니다.</label>
                    </div>
                </div>
                <div className='payment_couse'></div>
                <div className='py-3 px-3'>
                    <button
                        className={`payment_btn ${isChecked ? 'payment_btn_active' : ''}`}
                        disabled={!isChecked}
                        onClick={onPayment}
                    >
                        결제하기
                    </button>
                </div>
            </div>
            {selectedInfo && <PaymentInfo selectedInfo={selectedInfo} handleBack={handleBack} />}

            {showDeliveryChange &&
                <div className={`${store.store_delivery_change_modal_box}`}>
                    <div>
                        <div className={`${store.store_delivery_change_modal_header}`}>
                            <div><button onClick={() => setShowDeliveryChange(false)}><span className="material-symbols-outlined">arrow_back_ios</span></button></div>
                            <div><span>배송지 변경</span></div>
                        </div>
                        <div className={`${store.store_delivery_change_modal_content}`}>
                            {
                                Array.isArray(userDeliveryList) && userDeliveryList.map((item, index) => (
                                    <button
                                        key={index}
                                        className={`${store.store_delivery_change_modal_list_box}`}
                                        onClick={() => setSelectDelivery(item)}
                                    >
                                        <div>
                                            <div>
                                                <div>
                                                    <span>{item.deliveryUser} ({item.deliveryName})</span>
                                                </div>
                                                {
                                                    item.deliveryType === 1 &&
                                                    <div><span>기본배송지</span></div>
                                                }
                                            </div>
                                            <div>
                                                <input type="radio" name="delivery" checked={selectDelivery.deliveryNo === item.deliveryNo} readOnly />
                                            </div>
                                        </div>
                                        <div><span>{item.deliveryPhone}</span></div>
                                        <div>
                                            <div><span>{item.zipcode}</span></div>
                                            <div><span>{addAddress(item.sido, item.sigungu, item.streetNameAddress)}</span></div>
                                            <div><span>{item.detailAddress}</span></div>
                                        </div>
                                        <div>
                                            <div><button onClick={(e) => { e.stopPropagation(); onDeliveryUpdate(item) }}>수정하기</button></div>
                                            <div><button onClick={(e) => { e.stopPropagation(); onDeliveryDelete(item.deliveryNo) }}>삭제하기</button></div>
                                        </div>
                                    </button>
                                ))
                            }
                        </div>
                        <div className={`${store.store_delivery_change_modal_footer}`}>
                            <div><button onClick={() => { setShowDeliveryAdd(true); setAddDeliveryInfo({ ...addDeliveryInfo, deliveryUserId: userInfo.userId }); }}>배송지 추가</button></div>
                            <div><button onClick={() => setShowDeliveryChange(false)}>배송지 선택</button></div>
                        </div>
                    </div>
                </div>
            }

            {showDeliveryUpdate &&
                <div className={`${store.store_delivery_update_box}`}>
                    <div className={`${store.store_delivery_update_box_header}`}>
                        <div><button onClick={() => setShowDeliveryUpdate(false)}><span className="material-symbols-outlined">arrow_back_ios</span></button></div>
                        <div><span>배송지 수정</span></div>
                    </div>
                    <div className={`${store.store_delivery_update_box_content}`}>
                        <div className='myinfo_input_box'>
                            <div className='pb-1'><span>국가</span></div>
                            <div className='join_input'>
                                {!selectedCountry ?
                                    <button type="text" className='d-flex' placeholder='국가' onClick={handleCountry}>
                                        <span className='country_name_input'>국가선택</span>
                                        <span className="material-symbols-outlined">expand_more</span>
                                    </button> :
                                    <button type="text" className='d-flex' placeholder='국가' onClick={handleCountry}>
                                        <img src={selectedCountry?.img} alt={selectedCountry?.name} />
                                        <span className='country_name_input'>{selectedCountry?.name}</span>
                                        <span className="material-symbols-outlined">expand_more</span>
                                    </button>
                                }
                            </div>
                        </div>
                        <div className='py-3'>
                            <div><span>배송지이름</span></div>
                            <div><input type="text" className='join_input_tag' value={updateDeliveryInfo.deliveryName} onChange={(e) => onUpdateDeliveryName(e)} /></div>
                        </div>
                        <div className='py-3'>
                            <div><span>이름</span></div>
                            <div><input type="text" className='join_input_tag' value={updateDeliveryInfo.deliveryUser} onChange={(e) => onUpdateDeliveryUser(e)} /></div>
                        </div>
                        <div className=''>
                            <div className={`${store.store_delivery_update_address_search_box}`}>
                                <div>
                                    <span>배송지</span>
                                </div>
                                <div className='pt-1'>
                                    <button onClick={() => { setModalVisible(true); setAddressCheck(true); }}>주소검색</button>
                                </div>
                            </div>
                            <div className={`${store.adress_box}`}>
                                <div>
                                    <div className='py-1'>
                                        <input type="text" className='join_input_tag' placeholder={updateDeliveryInfo ? updateDeliveryInfo.zipcode : '우편번호'} disabled={true} />
                                    </div>
                                    <div className='py-1'>
                                        <input type="text" className='join_input_tag' placeholder={updateDeliveryInfo ? updateDeliveryInfo.sido : '특별시/광역시/도'} value={updateDeliveryInfo ? updateDeliveryInfo.sido : ''} disabled={true} />
                                    </div>
                                    <div className='py-1'>
                                        <input type="text" className='join_input_tag' placeholder={updateDeliveryInfo ? updateDeliveryInfo.sigungu : '시/군/구'} value={updateDeliveryInfo ? updateDeliveryInfo.sigungu : ''} disabled={true} />
                                    </div>
                                    <div className='py-1'>
                                        <input type="text" className='join_input_tag' placeholder={updateDeliveryInfo ? updateDeliveryInfo.streetNameAddress : '도로명 주소'} value={updateDeliveryInfo ? updateDeliveryInfo.streetNameAddress : ''} disabled={true} />
                                    </div>
                                    <div className='py-1'>
                                        <input type="text" className='join_input_tag' placeholder={updateDeliveryInfo ? updateDeliveryInfo.detailAddress : '상세주소'} onChange={(e) => onDetailAddress(e)} disabled={!addressCheck} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='py-3'>
                            <div className='pb-1'><span>휴대폰 번호</span></div>
                            <div className={`d-flex ${store.myinfo_phone_box}`}>
                                <div className={`join_input ${store.phone_number_box} pe-1`}>
                                    <input type="number" className='join_input_tag' placeholder={updateDeliveryInfo ? updateDeliveryInfo.deliveryPhone : '핸드폰 번호'} value={updateDeliveryInfo.deliveryPhone} onChange={(e) => handlePhoneNumber(e)} />
                                    <span style={{ color: 'rgba(255, 3, 102, 1)' }}>{selectedCountry?.number}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`${store.store_delivery_update_box_footer}`}>
                        <button onClick={onUpdateDeliverySave}>완료</button>
                    </div>
                </div>
            }

            {showDeliveryAdd &&
                <div className={`${store.store_delivery_update_box}`}>
                    <div className={`${store.store_delivery_update_box_header}`}>
                        <div><button onClick={() => setShowDeliveryAdd(false)}><span className="material-symbols-outlined">arrow_back_ios</span></button></div>
                        <div><span>배송지 추가</span></div>
                    </div>
                    <div className={`${store.store_delivery_update_box_content}`}>
                        <div className='myinfo_input_box'>
                            <div className='pb-1'><span>국가</span></div>
                            <div className='join_input'>
                                {!selectedCountry ?
                                    <button type="text" className='d-flex' placeholder='국가' onClick={handleCountry}>
                                        <span className='country_name_input'>국가선택</span>
                                        <span className="material-symbols-outlined">expand_more</span>
                                    </button> :
                                    <button type="text" className='d-flex' placeholder='국가' onClick={handleCountry}>
                                        <img src={selectedCountry?.img} alt={selectedCountry?.name} />
                                        <span className='country_name_input'>{selectedCountry?.name}</span>
                                        <span className="material-symbols-outlined">expand_more</span>
                                    </button>
                                }
                            </div>
                        </div>
                        <div className='py-3'>
                            <div><span>배송지이름</span></div>
                            <div><input type="text" className='join_input_tag' onChange={(e) => onAddDeliveryName(e)} /></div>
                        </div>
                        <div className='py-3'>
                            <div><span>이름</span></div>
                            <div><input type="text" className='join_input_tag' onChange={(e) => onAddDeliveryUser(e)} /></div>
                        </div>
                        <div className=''>
                            <div className={`${store.store_delivery_update_address_search_box}`}>
                                <div>
                                    <span>배송지</span>
                                </div>
                                <div className='pt-1'>
                                    <button onClick={() => { setModalVisible(true); setAddressCheck(true); }}>주소검색</button>
                                </div>
                            </div>
                            <div className={`${store.adress_box}`}>
                                <div>
                                    <div className='py-1'>
                                        <input type="text" className='join_input_tag' placeholder={'우편번호'} value={addDeliveryInfo?.zipcode ? addDeliveryInfo?.zipcode : ''} disabled={true} />
                                    </div>
                                    <div className='py-1'>
                                        <input type="text" className='join_input_tag' placeholder={'특별시/광역시/도'} value={addDeliveryInfo?.sido ? addDeliveryInfo?.sido : ''} disabled={true} />
                                    </div>
                                    <div className='py-1'>
                                        <input type="text" className='join_input_tag' placeholder={'시/군/구'} value={addDeliveryInfo?.sigungu ? addDeliveryInfo?.sigungu : ''} disabled={true} />
                                    </div>
                                    <div className='py-1'>
                                        <input type="text" className='join_input_tag' placeholder={'도로명 주소'} value={addDeliveryInfo?.streetNameAddress ? addDeliveryInfo?.streetNameAddress : ''} disabled={true} />
                                    </div>
                                    <div className='py-1'>
                                        <input type="text" className='join_input_tag' placeholder={'상세주소'} value={addDeliveryInfo?.detailAddress ? addDeliveryInfo?.detailAddress : ''} onChange={(e) => onAddDetailAddress(e)} disabled={!addressCheck} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='py-3'>
                            <div className='pb-1'><span>휴대폰 번호</span></div>
                            <div className={`d-flex ${store.myinfo_phone_box}`}>
                                <div className={`join_input ${store.phone_number_box} pe-1`}>
                                    <input type="number" className='join_input_tag' placeholder={'핸드폰 번호'} onChange={(e) => handleAddPhoneNumber(e)} />
                                    <span style={{ color: 'rgba(255, 3, 102, 1)' }}>{selectedCountry?.number}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`${store.store_delivery_update_box_footer}`}>
                        <button onClick={onAddDeliverySave}>완료</button>
                    </div>
                </div>
            }
            {
                showCountry &&
                <Country showCountry={showCountry} setShowCountry={setShowCountry} handleCountryClose={handleCountryClose}
                    searchTerm={searchTerm} setSearchTerm={setSearchTerm} countries={countries}
                    filteredCountries={filteredCountries} setFilteredCountries={setFilteredCountries}
                    setSelectedCountry={setSelectedCountry} handleSelectedCountry={handleSelectedCountry} />
            }
            {
                modalVisible && (
                    <PostModal
                        onClose={handleCloseModal}
                        onComplete={handleAddress}
                        store={store}
                    />
                )
            }
        </>
    );
}

export default EventPaymentContainer