import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DateSelect = ({ transitions, animated, handleBack, startDate, endDate, dateRange, setDateRange, ko, format, differenceInDays }) => {
    
    

    // // console.log(startDate);
    // // console.log(endDate);

    const datesFromDatabase = ['2024-03-07', '2024-03-09', '2024-03-10'];

    const excludedDates = datesFromDatabase.map(date => new Date(date));

    const isWithinUnavailableDates = (start, end) => {
      for (let date of excludedDates) {
        if (start <= date && date <= end) {
          return true;
        }
      }
      return false;
    }
    
    const handleDateChange = (dates) => {
      const [start, end] = dates;
      if (isWithinUnavailableDates(start, end)) {
        alert('선택하신 날짜 범위에 예약 불가능한 날짜가 포함되어 있습니다.');
        return;
      }
      setDateRange(dates);
    }

    return (
        <div className='date_picker_filter_container'>
            {transitions((style, item) =>
                item ?
                    <animated.div className='date_picker_filter_box' style={style}>
                        <div>
                            <div className='date_picker_filter_header'>
                                <div>
                                    <button onClick={() => handleBack()}>
                                        <span className="material-symbols-outlined">arrow_back_ios</span>
                                    </button>
                                </div>
                                <div><span>예약 일정 선택</span></div>
                                <div >
                                    <button onClick={() => handleBack()}>
                                        <span className="material-symbols-outlined">close</span>
                                    </button>
                                </div>
                            </div>
                            <div className='date_picker_filter_calendar'>
                                <DatePicker
                                    selected={startDate}
                                    startDate={startDate}
                                    endDate={endDate}
                                    onChange={handleDateChange}
                                    selectsRange
                                    inline
                                    minDate={new Date()}
                                    locale={ko}
                                    excludeDates={excludedDates}
                                />
                            </div>
                            <div className='datepick_footer'>
                                <div className='datepick_footer_select_date'>
                                {startDate && endDate ?
                                <div className='d-flex justify-content-between'>
                                    <div>
                                        <span className='datepick bold'>
                                            {format(startDate, 'yyyy.MM.dd(eee)', { locale: ko })} ~ {format(endDate, 'yyyy.MM.dd(eee)', { locale: ko })}
                                        </span>
                                    </div>
                                    <div>
                                        <span className='datepick_date'>{differenceInDays(endDate, startDate) + 1}일</span>
                                    </div>

                                </div>
                                    : <span className='datepick'>가능한 일자를 선택해 주세요.</span>
                                }
                                </div>
                                <div>
                                    <button className={startDate && endDate ? 'datepick_btn datepick_active' : 'datepick_btn'} onClick={() => handleBack()}>완료</button>
                                </div>
                            </div>
                        </div>
                    </animated.div>
                    : null
            )}
            
        </div>
    )
}

export default DateSelect;
