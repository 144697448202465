import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/autoplay';
import market from '../../containers/market/market.module.css';
import { Link } from 'react-router-dom';

const MarketBanner = ({ banner }) => {
    const [currentIndex, setCurrentIndex] = useState(0);  // 초기값을 0으로 설정
    const [isSwiperReady, setIsSwiperReady] = useState(false);  // Swiper 준비 상태 추가

    useEffect(() => {
        // console.log('banner : ', banner);
    }, [banner]);

    // Filter and sort the banner array
    const filteredBanners = banner
        .filter(item => item.bannerExposure)
        .sort((a, b) => a.bannerExposureOrder - b.bannerExposureOrder);

    // 총 슬라이드 수는 필터링된 배너의 길이로 설정
    const totalSlides = filteredBanners.length;

    return (
        <div className={`${market.banner_container}`}>
            <Swiper
                slidesPerView={1}
                spaceBetween={30}
                loop={true}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                }}
                onSwiper={(swiper) => {
                    swiper.autoplay.start();  // Swiper가 초기화된 후 autoplay를 강제로 시작
                    setIsSwiperReady(true);
                    setCurrentIndex(swiper.realIndex || 0);
                }}
                onSlideChange={(swiper) => setCurrentIndex(swiper.realIndex || 0)}
                modules={[Autoplay]}
                className={`${market.main_banner_swiper}`}
            >
                {filteredBanners.map((item, index) => (
                    <SwiperSlide key={index}>
                        <Link to={item.bannerTypeLink}>
                            <div className='market_banner'>
                                <img src={item.bannerImg} alt="배너이미지2" className='w-100' />
                            </div>
                            <div className='market_title_box'>
                                <div className='market_main_title'>
                                    <span>{item.bannerMainTitle}</span>
                                </div>
                                <div className='market_sub_title'>
                                    <span>{item.bannerSubTitle}</span>
                                </div>
                            </div>
                        </Link>
                    </SwiperSlide>
                ))}
            </Swiper>

            {/* 페이지 수 표시 */}
            {totalSlides > 0 && ( // Swiper가 준비되기 전이라도 0/totalSlides 표시
                <div className={market.banner_pagination_display}>
                    {isSwiperReady ? (currentIndex + 1) : 1}/{totalSlides}
                </div>
            )}
        </div>
    );
};

export default MarketBanner;
