import React, { useState, useEffect, useRef } from 'react';
import { checkEmail, checkUserId, emailCheck, emailAuthNumberCheck, phoneAuth, phoneAuthCheck } from '../../../apis/auth';
import * as auth from '../../../apis/auth';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import seller from '../../../seller/seller.module.css';
import * as adminApi from '../../../apis/admin';
import * as support from '../../../apis/support';


const JoinSection = ({ admin, onBack, setShowJoin }) => {

    const MySwal = withReactContent(Swal);
    // 기존 상태와 함수 정의
    const [userId, setUserId] = useState('');
    const [color, setColor] = useState('black');
    const [idColor, setIdColor] = useState('black');
    const [borderColor, setBorderColor] = useState('');
    const [idBorderColor, setIdBorderColor] = useState('');
    const [message, setMessage] = useState('');
    const [idMessage, setIdMessage] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [passwordConfirmVisible, setPasswordConfirmVisible] = useState(false);
    const [passwordConfirmCheck, setPasswordConfirmCheck] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [showEmailAuth, setShowEmailAuth] = useState(false);
    const [authNumber, setAuthNumber] = useState('');
    const [emailCertification, setEmailCertification] = useState(false);
    const [phoneCertification, setPhoneCertification] = useState(false);
    const [phone, setPhone] = useState('');
    const [showPhoneAuth, setShowPhoneAuth] = useState(false);
    const [phoneAuthNumber, setPhoneAuthNumber] = useState('');
    const [showBusinessAddress, setShowBusinessAddress] = useState(false);

    const [postcode, setPostcode] = useState('');
    const [address, setAddress] = useState('');
    const [detailAddress, setDetailAddress] = useState('');
    const [extraAddress, setExtraAddress] = useState('');

    const [bankCopy, setBankCopy] = useState(null);
    const [bankCopyPreview, setBankCopyPreview] = useState(null);
    const [salesReport, setSalesReport] = useState(null);
    const [salesReportPreview, setSalesReportPreview] = useState(null);
    const [activeBusinessState, setActiveBusinessState] = useState('');
    const [shopProfile, setShopProfile] = useState(null);
    const [shopProfilePreview, setShopProfilePreview] = useState(null);
    const [shopBack, setShopBack] = useState(null);
    const [shopBackPreview, setShopBackPreview] = useState(null);
    const [registrationNumber1, setRegistrationNumber1] = useState('');
    const [registrationNumber2, setRegistrationNumber2] = useState('');
    
    const [ selectBank, setSelectBank ] = useState({bankImg : '', bankName : ''});
    const [ showBankSelect, setShowBankSelect ] = useState(false);
    const [ shopInfo, setShopInfo ] = useState({
        userId : '',
        shopName : '',
        shopDetailContent : '',
        shopProfileImg : '',
        shopBackImg : '',
        shopBankImg : '',
        shopAccountNumber : '',
        shopDepositorName : ''
    });
    const [ activeBankStock, setActiveBankStock ] = useState('bank');
    const [ bankList, setBankList ] = useState([]);
    const [ stockList, setStockList ] = useState([]);

    const bankCopyInputRef = useRef();
    const salesReportInputRef = useRef();
    const shopProfileInputRef = useRef();
    const shopBackInputRef = useRef();

    const [userInput, setUserInput] = useState({
        sellerId: '',
        sellerEmail: '',
        sellerPhone: '',
        password: '',
        businessNumber: '',
        representative: '',
        businessName: '',
        businessType: '',
        businessSector: '',
        postCode: '',
        address: '',
        extraAddress: '',
        detailAddr: '',
        businessState : '',
        residentRegistrationNumber : ''
    });

    const [isPasswordValid, setIsPasswordValid] = useState({
        hasEnglish: false,
        hasNumber: false,
        hasSpecialChar: false,
        isValidLength: false,
    });

    const [isFormValid, setIsFormValid] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setBankList([
          {bankImg : `${process.env.PUBLIC_URL}/img/bank/kb.jpg`, bankName : '국민은행'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/ibk.jpg`, bankName : '기업은행'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/nh.jpg`, bankName : '농협은행'},
          {bankImg : `${process.env.PUBLIC_URL}/img/bank/shinhan.jpg`, bankName : '신한은행'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/koreapost.jpg`, bankName : '우체국'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/sc.jpg`, bankName : 'SC제일은행'},
          {bankImg : `${process.env.PUBLIC_URL}/img/bank/hana.jpg`, bankName : '하나은행'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/citi.jpg`, bankName : '한국씨티은행'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/woori.jpg`, bankName : '우리은행'},
          {bankImg : `${process.env.PUBLIC_URL}/img/bank/bnk.jpg`, bankName : '경남은행'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/kjb.jpg`, bankName : '광주은행'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/dgb.jpg`, bankName : '대구은행'},
          {bankImg : `${process.env.PUBLIC_URL}/img/bank/deutsche.jpg`, bankName : '도이치은행'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/bnk.jpg`, bankName : '부산은행'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/kdb.jpg`, bankName : '산업은행'},
          {bankImg : `${process.env.PUBLIC_URL}/img/bank/suhyup.jpg`, bankName : '수협중앙회'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/kjb.jpg`, bankName : '전북은행'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/shinhan.jpg`, bankName : '제주은행'},
          {bankImg : `${process.env.PUBLIC_URL}/img/bank/mg.jpg`, bankName : '새마을금고'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/shinhyup.jpg`, bankName : '신용협동조합(신협)'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/hsbc.jpg`, bankName : 'HSBC은행'},
          {bankImg : `${process.env.PUBLIC_URL}/img/bank/sb.jpg`, bankName : '저축은행'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/boa.jpg`, bankName : 'BOA은행'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/jd.jpg`, bankName : 'JP모간체이스은행'},
          {bankImg : `${process.env.PUBLIC_URL}/img/bank/bnp.jpg`, bankName : 'BNP파리바은행'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/ccb.jpg`, bankName : '중국건설은행'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/sj.jpg`, bankName : '산림조합'},
          {bankImg : `${process.env.PUBLIC_URL}/img/bank/icbc.jpg`, bankName : '중국공상은행'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/nh.jpg`, bankName : '지역농축협'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/kakaobank.jpg`, bankName : '카카오뱅크'},
          {bankImg : `${process.env.PUBLIC_URL}/img/bank/toss.jpg`, bankName : '토스뱅크'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/kbank.jpg`, bankName : '케이뱅크'}
        ]);
        setStockList([
          {bankImg : `${process.env.PUBLIC_URL}/img/bank/nh.jpg`, bankName : 'NH투자증권'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/yuanta.jpg`, bankName : '유안타증권'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/kb.jpg`, bankName : 'KB증권'},
          {bankImg : `${process.env.PUBLIC_URL}/img/bank/mirae.jpg`, bankName : '미래에셋대우'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/samsung.jpg`, bankName : '삼성증권'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/truetriend.jpg`, bankName : '한국투자증권'},
          {bankImg : `${process.env.PUBLIC_URL}/img/bank/kyonbo.jpg`, bankName : '교보증권'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/hi.jpg`, bankName : '하이투자증권'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/hyundai.jpg`, bankName : '현대차증권'},
          {bankImg : `${process.env.PUBLIC_URL}/img/bank/sk.jpg`, bankName : 'SK증권'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/hanhwa.jpg`, bankName : '한화투자증권'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/hana.jpg`, bankName : '하나금융투자'},
          {bankImg : `${process.env.PUBLIC_URL}/img/bank/shinhan.jpg`, bankName : '신한금융투자'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/eugene.jpg`, bankName : '유진투자증권'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/meritz.jpg`, bankName : '메리츠종합금융증권'},
          {bankImg : `${process.env.PUBLIC_URL}/img/bank/si.jpg`, bankName : '신영증권'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/ebest.jpg`, bankName : '이베스트투자증권'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/cape.jpg`, bankName : '케이프투자증권'},
          {bankImg : `${process.env.PUBLIC_URL}/img/bank/buguk.jpg`, bankName : '부국증권'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/kiwoom.jpg`, bankName : '키움증권'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/daishin.jpg`, bankName : '대신증권'},
          {bankImg : `${process.env.PUBLIC_URL}/img/bank/db.jpg`, bankName : 'DB금융투자'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/ktb.jpg`, bankName : 'KTB투자증권'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/fundonline.jpg`, bankName : '한국포스증권'},
          {bankImg : `${process.env.PUBLIC_URL}/img/bank/toss.jpg`, bankName : '토스증권'}
        ]);
      }, []);

    useEffect(() => {
        // console.log('userInput : ', userInput);
        // console.log('shopInfo : ', shopInfo);
    }, [userInput, shopInfo]);

    useEffect(() => {
        checkFormValidity();
    }, [userId, idMessage, emailCertification, phoneCertification, password, passwordConfirm, userInput, shopInfo]);

    const checkEnglish = password => /[a-zA-Z]/.test(password);
    const checkNumber = password => /\d/.test(password);
    const checkSpecialChar = password => /[~@%^&*_|<>{}!]/.test(password);
    const checkLength = password => password.length >= 8;

    const passwordCheck = (password) => {
        if (typeof password !== 'string') {
            return {
                hasEnglish: false,
                hasNumber: false,
                hasSpecialChar: false,
                isValidLength: false,
            };
        }
        return {
            hasEnglish: checkEnglish(password),
            hasNumber: checkNumber(password),
            hasSpecialChar: checkSpecialChar(password),
            isValidLength: checkLength(password),
        };
    };

    const onBankSelect = (item) => {
        // console.log('item : ', item);
        setSelectBank({
          ...selectBank, ...item
        });
        setShopInfo(shopInfo => {
          return {...shopInfo, shopBankImg: item.bankImg, shopBankName: item.bankName};
        });
        setShowBankSelect(false);
    }

    const onBankAccount = (e) => {
        setShopInfo({
            ...shopInfo, shopAccountNumber : e.target.value
        })
    }

    const onDepositerName = (e) => {
        setShopInfo({
            ...shopInfo, shopDepositorName : e.target.value
        })
    }

    const idCheck = (e) => {
        setUserId(e.target.value);
        setUserInput({
            ...userInput,
            sellerId: e.target.value,
        });
        setShopInfo({
            ...shopInfo, userId : e.target.value
        })
    };

    const emailCheck = (e) => {
        setEmail(e.target.value);
        setUserInput({
            ...userInput,
            sellerEmail: e.target.value,
        });
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        if (!password) {
            setIsFocused(false);
        }
    };

    const handlePasswordChange = (e) => {
        const updatedPassword = e.target.value;
        setPassword(updatedPassword);
        setIsPasswordValid(passwordCheck(updatedPassword));
        setUserInput({
            ...userInput,
            password: updatedPassword,
        });
    };

    const handleTogglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const handlePasswordConfirm = (e) => {
        setPasswordConfirm(e.target.value);
        setPasswordConfirmCheck(password !== e.target.value);
    };

    const handleTogglePasswordConfirmVisibility = () => {
        setPasswordConfirmVisible(!passwordConfirmVisible);
    };

    useEffect(() => {}, [userId]);

    useEffect(() => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.(com|net|co\.kr|xyz|cn|com\.cn|net\.cn|org\.cn|ph|in|co\.in|jp|co\.jp|tw|com\.tw|sq|com\.sq|hk|com\.hk|co\.id|vn|com\.vn|my|com\.my|ir|cx|af|am|com\.bd|io|co\.il|kg|la|mn|pk|ps|tj|tm|ae|us|co|bz|mx|com\.mx|com\.ar|ag|bo|com\.bo|cr|ac|com\.gt|ht|hn|com\.ni|vc|gs|gd|pa|com\.ve|ca|de|co\.uk|fr|es|com\.es|nl|it|ru|eu|be|me|dk|gr|se|ch|pl|ro|at|az|by|gl|gg|is|im|je|kz|lv|li|lt|mt|md|pt|sm|cz|com\.tr|so|mu|bi|cm|td|com\.et|cg|cd|co\.ls|mw|ma|na|com\.ng|rw|st|sc|co\.bw|tv|cc|ac|co\.ck|as|ws|com\.au|co\.nz|com\.ai|bs|com\.bs|do|com\.do|com\.fj|com\.jm|ki|ly|fm|ms|nr|nu|nf|pn|pr|sh|lccom\.sb|tt|vg|hm|tf)$/;

        if (emailRegex.test(email)) {
            const timer = setTimeout(() => {
                checkEmail(email)
                    .then((res) => {
                        if (res.data.userEmail) {
                            setMessage('중복된 이메일입니다.');
                            setColor('rgba(255, 92, 92, 1)');
                            setBorderColor('rgba(255, 92, 92, 1)');
                        } else {
                            setMessage('사용 가능한 이메일입니다.');
                            setColor('green');
                            setBorderColor('green');
                        }
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }, 1000);

            return () => clearTimeout(timer);
        } else {
            if (email !== '') {
                setMessage('유효하지 않은 이메일 형식입니다.');
                setColor('rgba(255, 92, 92, 1)');
                setBorderColor('rgba(255, 92, 92, 1)');
            } else {
                setMessage('');
            }
        }
    }, [email]);

    const onEmailAuth = async () => {
        setIsLoading(true);

        const response = await auth.emailCheck(email);
        const data = response.data;

        // console.log(data);
        if (data === 'SUCCESS') {
            setIsLoading(false);
            MySwal.fire({
                customClass: {
                  popup: `${admin.admin_seller_join_popup}`
                },
                html: `
                <div>
                  <div class='${admin.admin_seller_join_title}'><span>인증메일이 발송되었습니다.</span></div>
                  <div class='${admin.admin_seller_join_sub_title}'><span>인증메일이 도착하지 않을 경우 스팸메일함을 확인해주세요.</span></div>
                  <div class='${admin.admin_seller_join_confirm_btn_box}'>
                    <button id='address_confirm'>확인</button>
                  </div>
                </div>
                `,
                showConfirmButton: false,
                showCancelButton: false,
              });
      
              document.addEventListener('click', addressConfirm);
      
              function addressConfirm(e) {
                if (e.target && e.target.id === 'address_confirm') {
                  MySwal.close();
                  document.removeEventListener('click', addressConfirm);
                  setShowEmailAuth(true);
                }
              }
        } else {
            alert('이메일 인증 버튼을 다시 한번 눌러주세요.');
        }
    };

    const emailAuthNumber = (e) => {
        setAuthNumber(e.target.value);
    };

    const emailAuthCheck = async () => {
        const response = await auth.emailAuthNumberCheck(email, authNumber);
        const data = response.data;
        if (data === 'SUCCESS') {
            setShowEmailAuth(false);
            setEmailCertification(true);
        }
    };

    const onIdCheck = async () => {
        const idRegex = /^[A-Za-z0-9]+$/;
        if (idRegex.test(userId)) {
            const timer = setTimeout(() => {
                checkUserId(userId)
                    .then((res) => {
                        if (res.data.userId) {
                            setIdMessage('중복된 아이디입니다.');
                            setIdColor('rgba(255, 92, 92, 1)');
                            setIdBorderColor('rgba(255, 92, 92, 1)');
                        } else {
                            setIdMessage('사용 가능한 아이디입니다.');
                            setIdColor('green');
                            setIdBorderColor('green');
                        }
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }, 1000);
            return () => clearTimeout(timer);
        } else {
            if (userId !== '') {
                setIdMessage('아이디는 영어와 숫자만 포함해야 합니다.');
                setIdColor('rgba(255, 92, 92, 1)');
                setIdBorderColor('rgba(255, 92, 92, 1)');
            } else {
                setIdMessage('');
            }
        }
    };

    const onPhone = (e) => {
        setPhone(e.target.value);
        setUserInput({...userInput, sellerPhone: e.target.value});
    };

    const onPhoneCertification = async () => {
        const response = await auth.phoneAuth(userInput.sellerPhone);
        const data = response.data;
        if (data === 'SUCCESS') {
            MySwal.fire({
                customClass: {
                  popup: `${admin.admin_seller_join_popup}`
                },
                html: `
                <div>
                  <div class='${admin.admin_seller_join_title}'><span>인증문자가 발송되었습니다.</span></div>
                  <div class='${admin.admin_seller_join_sub_title}'><span>인증문자가 도착하지 않을 경우 휴대폰번호를 확인해주세요.</span></div>
                  <div class='${admin.admin_seller_join_confirm_btn_box}'>
                    <button id='address_confirm'>확인</button>
                  </div>
                </div>
                `,
                showConfirmButton: false,
                showCancelButton: false,
              });
      
              document.addEventListener('click', addressConfirm);
      
              function addressConfirm(e) {
                if (e.target && e.target.id === 'address_confirm') {
                  MySwal.close();
                  document.removeEventListener('click', addressConfirm);
                  setShowPhoneAuth(true);
                }
              }
        }
    };

    const onPhoneAuthNumber = (e) => {
        setPhoneAuthNumber(e.target.value);
    };

    const onPhoneAuthCheck = async () => {
        const response = await auth.phoneAuthCheck(userInput.sellerPhone, phoneAuthNumber);
        const data = response.data;
        if (data === 'SUCCESS') {
            setShowPhoneAuth(false);
            setPhoneCertification(true);
        }
    };

    // Daum 우편번호 API를 호출하는 함수
    const sample3_execDaumPostcode = () => {
        const element_wrap = document.getElementById('wrap');
        new window.daum.Postcode({
            oncomplete: function (data) {
                let addr = '';
                let extraAddr = '';

                if (data.userSelectedType === 'R') {
                    addr = data.roadAddress;
                } else {
                    addr = data.jibunAddress;
                }

                if (data.userSelectedType === 'R') {
                    if (data.bname !== '' && /[동|로|가]$/g.test(data.bname)) {
                        extraAddr += data.bname;
                    }
                    if (data.buildingName !== '' && data.apartment === 'Y') {
                        extraAddr += (extraAddr !== '' ? ', ' + data.buildingName : data.buildingName);
                    }
                    if (extraAddr !== '') {
                        extraAddr = ' (' + extraAddr + ')';
                    }
                }

                setPostcode(data.zonecode);
                setAddress(addr);
                setExtraAddress(extraAddr);
                setDetailAddress('');

                setUserInput( { ...userInput,
                    postCode: data.zonecode, address: addr, extraAddress: extraAddr
                } )

                element_wrap.style.display = 'none';
                setShowBusinessAddress(true);
            },
            onresize: function (size) {
                element_wrap.style.height = size.height + 'px';
            },
            width: '100%',
            height: '100%',
        }).embed(element_wrap);
        element_wrap.style.display = 'block';
    };

    const closePostcodePopup = () => {
        document.getElementById('wrap').style.display = 'none';
    };

    const handleBankCopyChange = (e, setFile, setPreview) => {
        const file = e.target.files[0];
        setFile(file);
        const reader = new FileReader();
        reader.onloadend = () => {
            setPreview(reader.result);
        };
        if (file) {
            reader.readAsDataURL(file);
            bankCopyUpload(file);
        }

        e.target.value = '';
    };
    const bankCopyUpload = async (file) => {
        const formData = new FormData();
        formData.append('image', file);
        
        try {
            // const response = await axios.post('/api/file/sellerUpload', formData, {
            //     headers: {
            //         'Content-Type': 'multipart/form-data',
            //     },
            // });
            // // console.log(response.data);
            // const data = response.data;
            // const imageUrl = `${process.env.REACT_APP_API_SERVER}/img?file=${encodeURIComponent(data)}`;
            const config = { headers: { 'content-type': 'multipart/form-data' } };
            const response = await support.uploadImageToServer(formData, config);
            const data = response.data;
            const imageUrl = `${process.env.REACT_APP_API_SERVER}/img?file=${encodeURIComponent(data)}`;
            setUserInput({...userInput, bankCopy: imageUrl});
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    }

    const handleSaleReportChange = (e, setFile, setPreview) => {
        const file = e.target.files[0];
        setFile(file);
        const reader = new FileReader();
        reader.onloadend = () => {
            setPreview(reader.result);
        };
        if (file) {
            reader.readAsDataURL(file);
            saleReportUpload(file);
        }
        // console.log(file)
        e.target.value = '';
    };
    const saleReportUpload = async (file) => {
        const formData = new FormData();
        formData.append('image', file);
        
        try {
            // const response = await axios.post('/api/file/sellerUpload', formData, {
            //     headers: {
            //         'Content-Type': 'multipart/form-data',
            //     },
            // });
            // // console.log(response.data);
            // const data = response.data;
            // const imageUrl = `${process.env.REACT_APP_API_SERVER}/img?file=${encodeURIComponent(data)}`;
            const config = { headers: { 'content-type': 'multipart/form-data' } };
            const response = await support.uploadImageToServer(formData, config);
            const data = response.data;
            const imageUrl = `${process.env.REACT_APP_API_SERVER}/img?file=${encodeURIComponent(data)}`;
            setUserInput({...userInput, salesReport: imageUrl});
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    }
    const handleShopProfileChange = (e, setFile, setPreview) => {
        const file = e.target.files[0];
        setFile(file);
        const reader = new FileReader();
        reader.onloadend = () => {
            setPreview(reader.result);
        };
        if (file) {
            reader.readAsDataURL(file);
            shopProfileUpload(file);
        }
        // console.log(file)
        e.target.value = '';
    };
    const shopProfileUpload = async (file) => {
        const formData = new FormData();
        formData.append('image', file);
        
        try {
            // const response = await axios.post('/api/file/sellerUpload', formData, {
            //     headers: {
            //         'Content-Type': 'multipart/form-data',
            //     },
            // });
            // // console.log(response.data);
            // const data = response.data;
            // const imageUrl = `${process.env.REACT_APP_API_SERVER}/img?file=${encodeURIComponent(data)}`;
            const config = { headers: { 'content-type': 'multipart/form-data' } };
            const response = await support.uploadImageToServer(formData, config);
            const data = response.data;
            const imageUrl = `${process.env.REACT_APP_API_SERVER}/img?file=${encodeURIComponent(data)}`;
            setShopInfo({...shopInfo, shopProfileImg: imageUrl});
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    }
    const handleShopBackChange = (e, setFile, setPreview) => {
        const file = e.target.files[0];
        setFile(file);
        const reader = new FileReader();
        reader.onloadend = () => {
            setPreview(reader.result);
        };
        if (file) {
            reader.readAsDataURL(file);
            shopBackUpload(file);
        }
        // console.log(file)
        e.target.value = '';
    };
    const shopBackUpload = async (file) => {
        const formData = new FormData();
        formData.append('image', file);
        
        try {
            // const response = await axios.post('/api/file/sellerUpload', formData, {
            //     headers: {
            //         'Content-Type': 'multipart/form-data',
            //     },
            // });
            // // console.log(response.data);
            // const data = response.data;
            // const imageUrl = `${process.env.REACT_APP_API_SERVER}/img?file=${encodeURIComponent(data)}`;
            const config = { headers: { 'content-type': 'multipart/form-data' } };
            const response = await support.uploadImageToServer(formData, config);
            const data = response.data;
            const imageUrl = `${process.env.REACT_APP_API_SERVER}/img?file=${encodeURIComponent(data)}`;
            setShopInfo({...shopInfo, shopBackImg: imageUrl});
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    }

    const removeFile = (setFile, setPreview) => {
        setFile(null);
        setPreview(null);
    };

    const uploadFile = async (file) => {
        // console.log(file);
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios.post('/api/file/sellerUpload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            // console.log(response.data);
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    const checkFormValidity = () => {
        if(userInput.businessState === 'business') {
            const isValid =
                idMessage === '사용 가능한 아이디입니다.' &&
                emailCertification &&
                phoneCertification &&
                isPasswordValid.hasEnglish &&
                isPasswordValid.hasNumber &&
                isPasswordValid.hasSpecialChar &&
                isPasswordValid.isValidLength &&
                password === passwordConfirm &&
                userInput.address &&
                userInput.businessName &&
                userInput.businessNumber &&
                userInput.businessSector &&
                userInput.businessState &&
                userInput.businessType &&
                userInput.password &&
                userInput.representative &&
                // userInput.salesReportNumber &&
                userInput.sellerEmail &&
                userInput.sellerId &&
                userInput.sellerPhone &&
                shopInfo.shopName &&
                // shopInfo.shopDetailContent &&
                shopInfo.shopAccountNumber &&
                shopInfo.shopDepositorName;
            
            setIsFormValid(isValid);
        }
        
        if(userInput.businessState === 'nonbusiness') {
            const isValid =
                idMessage === '사용 가능한 아이디입니다.' &&
                emailCertification &&
                phoneCertification &&
                isPasswordValid.hasEnglish &&
                isPasswordValid.hasNumber &&
                isPasswordValid.hasSpecialChar &&
                isPasswordValid.isValidLength &&
                password === passwordConfirm &&
                userInput.address &&
                userInput.password &&
                userInput.businessName &&
                registrationNumber1 &&
                registrationNumber2 &&
                userInput.sellerEmail &&
                userInput.sellerId &&
                userInput.sellerPhone &&
                shopInfo.shopName &&
                // shopInfo.shopDetailContent &&
                shopInfo.shopAccountNumber &&
                shopInfo.shopDepositorName;
            
            setIsFormValid(isValid);
            
        }
    };

    const check = () => {
        // console.log(idMessage);
        // console.log(emailCertification);
        // console.log(phoneCertification);
        // console.log(isPasswordValid.hasEnglish);
        // console.log(isPasswordValid.hasNumber);
        // console.log(isPasswordValid.hasSpecialChar);
        // console.log(isPasswordValid.isValidLength);
        // console.log(password === passwordConfirm);
        // console.log(Object.values(userInput).every(value => value !== ''));
        // console.log(userInput);
        }
        
    const handleSignup = async () => {
        let newItem = {}
        if(userInput.businessState === 'nonbusiness') {
            newItem = {
                ...userInput, residentRegistrationNumber : registrationNumber1+registrationNumber2
            }
        } else {
            newItem = {
                ...userInput
            }
        }

        // console.log('newItem : ', newItem);
        const response = await auth.sellerJoin(newItem);
        const data = response.data;
        
        // console.log('data : ', data);

        if(data === 'SUCCESS') {
            sellerShopFullAdd();
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html : 
                `
                <div>
                <div class='${admin.admin_seller_add_title}'><span>회원가입 신청이 완료 되었습니다.</span></div>
                <div class='${admin.admin_seller_add_sub_title}'><span>관리자 승인 후 로그인이 가능합니다.</span></div>
                <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                <button class='mx-1' id='seller_add_confirm'>확인</button>
                </div>
                </div>
                `,
                showConfirmButton: false,
                showCancelButton: false,
            });
            document.addEventListener('click', sellerAddConfirm);
            
            function sellerAddConfirm(e) {
                if(e.target && e.target.id == 'seller_add_confirm') {
                    setShowJoin(false);
                    MySwal.close();
                    document.removeEventListener('click', sellerAddConfirm);
                }
            }
        }
    };

    const sellerShopFullAdd = async () => {
        const response = await adminApi.sellerShopFullAdd(shopInfo);
        const data = response.data;
    }

    const onbusinessState = (e) => {
        // console.log(e.target.id);
        setActiveBusinessState(e.target.id);

        setUserInput({
            ...userInput, businessState : e.target.id
        })
    }

    const onShopName = (e) => {
        setShopInfo({
            ...shopInfo, shopName : e.target.value
        })
    }
    const onShopContent = (e) => {
        setShopInfo({
            ...shopInfo, shopDetailContent : e.target.value
        })
    }

    const registration1 = (e) => {
        const inputValue = e.target.value;
        
        // 입력값이 6자리 이상이면 값을 설정하지 않음
        if (inputValue.length <= 6) {
            setRegistrationNumber1(inputValue);
        }
    }
    const registration2 = (e) => {
        const inputValue = e.target.value;
        
        // 입력값이 6자리 이상이면 값을 설정하지 않음
        if (inputValue.length <= 7) {
            setRegistrationNumber2(inputValue);
        }
    }

    return (
        <>
            {
                isLoading &&
                <div className={`${admin.admin_join_section_loading}`}>
                    <div><img src={`${process.env.PUBLIC_URL}/img/kwangduck_loading.gif`} alt="Loading..." /></div>
                </div>
            }
            <div className={`${admin.admin_join_section_box}`}>
                <div className='px-3 py-3'>
                    <div className={`${admin.admin_join_section_close_box}`}><button onClick={onBack}><span className="material-symbols-outlined">close</span></button></div>
                    <div className={`${admin.admin_join_section_header}`}>
                        <div><img src="../../img/logo_pink2.png" alt="로고" /></div>
                    </div>
                    <div className={`${admin.admin_join_section_content}`}>
                        <div className={`${admin.admin_join_section_content_id_box}`}>
                            <div>
                                <div><span>아이디</span><span className={`${admin.admin_join_section_content_essential}`}>(필수)</span></div>
                                <div>
                                    <div><input type="text" placeholder='사용하실 아이디를 입력해주세요.' onChange={idCheck} /></div>
                                    <div><button className={userId === '' ? '' : admin.admin_join_section_content_id_active} onClick={() => onIdCheck()} disabled={userId === '' ? true : false}>중복체크</button></div>
                                </div>
                            </div>
                            {
                                idMessage !== '' &&
                                <div><span style={{ color: idColor }}>{idMessage}</span></div>
                            }
                        </div>
                        <div className={`${admin.admin_join_section_content_email_box}`}>
                            <div>
                                <div><span>이메일<span className={`${admin.admin_join_section_content_essential}`}>(필수)</span></span></div>
                                <div>
                                    <div><input type="text" placeholder='이메일' onChange={emailCheck} style={{ borderColor: borderColor }} disabled={showEmailAuth || emailCertification}  /></div>
                                </div>
                            </div>
                            <div><span style={{ color: color }}>{message}</span></div>
                            {message === '사용 가능한 이메일입니다.' && (
                                <div className={`${admin.admin_join_section_content_email_auth_btn}`}>
                                    {emailCertification ? (
                                        <button className={`${admin.admin_join_content_auth_confirm}`} disabled={emailCertification}>인증완료</button>
                                    ) : (
                                        <button onClick={() => onEmailAuth()}>이메일 인증</button>
                                    )}
                                </div>
                            )}
                            {showEmailAuth && (
                                <div className={`${admin.admin_join_section_content_email_auth_number_box}`}>
                                    <div><span>인증번호</span></div>
                                    <div>
                                        <div><input type="number" onChange={(e) => emailAuthNumber(e)} /></div>
                                        <div><button onClick={() => emailAuthCheck()}>인증확인</button></div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className={`${admin.admin_join_section_content_phone_box}`}>
                            <div>
                                <div><span>휴대폰번호<span className={`${admin.admin_join_section_content_essential}`}>(필수)</span></span></div>
                                <div>
                                    <div><input type="number" onChange={(e) => onPhone(e)} disabled={showPhoneAuth || phoneCertification} /></div>
                                    <div>
                                        {phoneCertification ? (
                                            <button className={`${admin.admin_join_section_content_phone_certification_success}`} disabled={phoneCertification}>인증완료</button>
                                        ) : (
                                            <button className={phone === '' ? '' : admin.admin_join_section_content_phone_active} disabled={phone === '' ? true : false} onClick={() => onPhoneCertification()}>인증번호발송</button>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {showPhoneAuth && (
                                <div className={`${admin.admin_join_section_content_phone_confirm_box}`}>
                                    <div><span>인증번호</span></div>
                                    <div>
                                        <div><input type="number" onChange={(e) => onPhoneAuthNumber(e)} /></div>
                                        <div><button className={phoneAuthNumber === '' ? '' : admin.admin_join_section_content_phone_confirm_active} onClick={() => onPhoneAuthCheck()} disabled={phoneAuthNumber === '' ? true : false}>인증확인</button></div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className={`${admin.admin_join_section_content_pw_box}`}>
                            <div className={`${admin.admin_join_section_content_pw_input_box}`}>
                                <div><span>비밀번호<span className={`${admin.admin_join_section_content_essential}`}>(필수)</span></span></div>
                                <div>
                                    <input
                                        type={passwordVisible ? 'text' : 'password'}
                                        placeholder='비밀번호'
                                        onFocus={handleFocus}
                                        onBlur={handleBlur}
                                        onChange={(e) => handlePasswordChange(e)}
                                        value={password}
                                        style={{ paddingRight: '40px' }}
                                    />
                                    <button onClick={handleTogglePasswordVisibility}>
                                        {passwordVisible ? <span className="material-symbols-outlined">visibility</span> : <span className="material-symbols-outlined">visibility_off</span>}
                                    </button>
                                </div>
                            </div>
                            {isFocused && (
                                <div className={`${admin.admin_join_section_content_pw_input_check}`}>
                                    <div className='pt-2 px-2 eng_check'>
                                        <span style={{ color: isPasswordValid.hasEnglish ? 'rgba(0, 189, 103, 1)' : 'rgba(196, 199, 202, 1)' }}>
                                            <span className="material-symbols-outlined" style={{ color: isPasswordValid.hasEnglish ? 'rgba(0, 189, 103, 1)' : 'rgba(196, 199, 202, 1)' }}>check</span>영문
                                        </span>
                                    </div>
                                    <div className='pt-2 px-2 number_check'>
                                        <span style={{ color: isPasswordValid.hasNumber ? 'rgba(0, 189, 103, 1)' : 'rgba(196, 199, 202, 1)' }}>
                                            <span className="material-symbols-outlined" style={{ color: isPasswordValid.hasNumber ? 'rgba(0, 189, 103, 1)' : 'rgba(196, 199, 202, 1)' }}>check</span>숫자
                                        </span>
                                    </div>
                                    <div className='pt-2 px-2 spec_check'>
                                        <span style={{ color: isPasswordValid.hasSpecialChar ? 'rgba(0, 189, 103, 1)' : 'rgba(196, 199, 202, 1)' }}>
                                            <span className="material-symbols-outlined" style={{ color: isPasswordValid.hasSpecialChar ? 'rgba(0, 189, 103, 1)' : 'rgba(196, 199, 202, 1)' }}>check</span>특수문자
                                        </span>
                                    </div>
                                    <div className='pt-2 px-2 word_check'>
                                        <span style={{ color: isPasswordValid.isValidLength ? 'rgba(0, 189, 103, 1)' : 'rgba(196, 199, 202, 1)' }}>
                                            <span className="material-symbols-outlined" style={{ color: isPasswordValid.isValidLength ? 'rgba(0, 189, 103, 1)' : 'rgba(196, 199, 202, 1)' }}>check</span>8자 이상
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className={`${admin.admin_join_section_content_pw_confirm_input_box} border-bottom`}>
                            <div className={`${admin.admin_join_section_content_pw_confirm_input}`}>
                                <div className='pb-1'><span>비밀번호 확인</span></div>
                                <div>
                                    <input
                                        type={passwordConfirmVisible ? 'text' : 'password'}
                                        placeholder='비밀번호 확인'
                                        style={{ paddingRight: '40px' }}
                                        onChange={(e) => handlePasswordConfirm(e)}
                                    />
                                    <button onClick={handleTogglePasswordConfirmVisibility}>
                                        {passwordConfirmVisible ? <span className="material-symbols-outlined">visibility</span> : <span className="material-symbols-outlined">visibility_off</span>}
                                    </button>
                                </div>
                            </div>
                            {passwordConfirm !== '' && (
                                password === passwordConfirm ? (
                                    <div className={`${admin.admin_join_section_content_pw_confirm_input_check_txt}`}>
                                        <span style={{ color: 'green' }}>비밀번호가 일치합니다.</span>
                                    </div>
                                ) : (
                                    <div className={`${admin.admin_join_section_content_pw_confirm_input_check_txt}`}>
                                        <span style={{ color: 'red' }}>비밀번호가 일치하지 않습니다.</span>
                                    </div>
                                )
                            )}
                        </div>
                        <div className={`${admin.admin_join_section_business_person_box}`}>
                            <div><span>사업자 여부</span></div>
                            <div>
                                <div>
                                    <input type="radio" name="busimessperson" id="business" onChange={(e) => onbusinessState(e)}/>
                                    <label htmlFor="business">사업자</label>
                                </div>
                                <div>
                                    <input type="radio" name="busimessperson" id="nonbusiness" onChange={(e) => onbusinessState(e)}/>
                                    <label htmlFor="nonbusiness">비사업자</label>
                                </div>
                            </div>
                        </div>
                        {
                            activeBusinessState === 'business' &&
                            <div>
                                <div>

                                    <div className={`${admin.admin_join_section_business_header}`}><span>사업자 정보</span></div>
                                    <div>
                                        <div className={`${admin.admin_join_section_business_number}`}>
                                            <div><span>사업자 등록번호</span><span className={`${admin.admin_join_section_content_essential}`}>(필수)</span></div>
                                            <div><input type="number" name="" id="" onChange={(e) => setUserInput({...userInput, businessNumber: e.target.value})} /></div>
                                        </div>
                                        <div className={`${admin.admin_join_section_representative}`}>
                                            <div><span>대표자명</span><span className={`${admin.admin_join_section_content_essential}`}>(필수)</span></div>
                                            <div><input type="text" name="" id="" onChange={(e) => setUserInput({...userInput, representative: e.target.value})} /></div>
                                        </div>
                                        <div className={`${admin.admin_join_section_business_name}`}>
                                            <div><span>상호명</span><span className={`${admin.admin_join_section_content_essential}`}>(필수)</span></div>
                                            <div><input type="text" name="" id="" onChange={(e) => setUserInput({...userInput, businessName: e.target.value})} /></div>
                                        </div>
                                        <div className={`${admin.admin_join_section_business_type}`}>
                                            <div><span>업태</span><span className={`${admin.admin_join_section_content_essential}`}>(필수)</span></div>
                                            <div><input type="text" name="" id="" onChange={(e) => setUserInput({...userInput, businessType: e.target.value})} /></div>
                                        </div>
                                        <div className={`${admin.admin_join_section_business_sector}`}>
                                            <div><span>업종</span><span className={`${admin.admin_join_section_content_essential}`}>(필수)</span></div>
                                            <div><input type="text" name="" id="" onChange={(e) => setUserInput({...userInput, businessSector: e.target.value})} /></div>
                                        </div>
                                        <div>
                                            <div className={`${admin.admin_join_section_business_addr}`}>
                                                <div><span>사업장 소재지</span><span className={`${admin.admin_join_section_content_essential}`}>(필수)</span></div>
                                                <div><input type="button" onClick={sample3_execDaumPostcode} value="주소 검색" /></div>
                                            </div>
                                            {
                                                showBusinessAddress &&
                                                <div>
                                                    <div className={`${admin.admin_join_section_business_addr_box1}`}>
                                                        <input type="text" id="sample3_postcode" placeholder="우편번호" value={postcode} readOnly />
                                                        <input type="text" id="sample3_address" placeholder="주소" value={address} readOnly /><br />
                                                    </div>
                                                    <div className={`${admin.admin_join_section_business_addr_box2}`}>
                                                        <input type="text" id="sample3_detailAddress" placeholder="상세주소" value={detailAddress} onChange={(e) => {setDetailAddress(e.target.value); setUserInput({...userInput, detailAddr: e.target.value})}} />
                                                        <input type="text" id="sample3_extraAddress" placeholder="참고항목" value={extraAddress} readOnly />
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div id="wrap" style={{ display: 'none', border: '1px solid', width: '100%', height: '300px', margin: '5px 0', position: 'relative' }}>
                                            <img src="//t1.daumcdn.net/postcode/resource/images/close.png" id="btnFoldWrap" style={{ cursor: 'pointer', position: 'absolute', right: '0px', top: '-1px', zIndex: 1 }} onClick={closePostcodePopup} alt="접기 버튼" />
                                        </div>

                                        <div className={`${admin.admin_join_content_img_upload_box}`}>
                                            <div><span>통장사본</span><span className={`${admin.admin_join_section_content_essential}`}>(필수)</span></div>
                                            <input
                                                type="file"
                                                ref={bankCopyInputRef}
                                                style={{ display: 'none' }}
                                                onChange={(e) => handleBankCopyChange(e, setBankCopy, setBankCopyPreview)}
                                            />
                                            <div>
                                                <button className={`${admin.admin_join_content_img_select}`} onClick={() => bankCopyInputRef.current.click()}>파일 선택</button>
                                                <span className={`${admin.admin_join_content_img_span}`}>jpg, jpeg, png 파일만 업로드해주세요.</span>
                                            </div>
                                        </div>
                                            {bankCopyPreview && (
                                                <div className={`${admin.admin_join_content_img_preview}`}>
                                                    <img src={bankCopyPreview} alt="통장사본 미리보기" style={{ width: '100px', height: '100px' }} />
                                                    <button className={`${admin.admin_join_content_img_preview_delete}`} onClick={() => removeFile(setBankCopy, setBankCopyPreview)}><span className="material-symbols-outlined">delete</span></button>
                                                </div>
                                            )}

                                        <div className={`${admin.admin_join_content_bank_select_box}`}>
                                            <div><span>정산은행</span><span className={`${admin.admin_join_section_content_essential}`}>(필수)</span></div>
                                            <div>
                                                <button onClick={() => setShowBankSelect(true)} className={`${admin.admin_join_content_bank_select_btn} ${selectBank.bankImg === '' && selectBank.bankName === '' ? admin.join_content_bank_no_data : admin.join_content_bank_data}`}>
                                                {
                                                    selectBank.bankImg === '' && selectBank.bankName === '' ?
                                                    <div>
                                                        <span>은행을 선택해주세요.</span>
                                                    </div>
                                                    :
                                                    <div>
                                                        <div><img src={`${process.env.PUBLIC_URL}${selectBank.bankImg}`} alt="은행이미지" /></div>
                                                        <div><span>{selectBank.bankName}</span></div>
                                                    </div>
                                                }
                                                
                                                </button>
                                            </div>
                                        </div>
                                        <div className={`${admin.admin_join_content_bank_input_box}`}>
                                            <div><span>정산계좌번호</span><span className={`${admin.admin_join_section_content_essential}`}>(필수)</span></div>
                                            <div><input type="text" value={shopInfo.shopAccountNumber !== '' ? shopInfo.shopAccountNumber : ''} onChange={(e) => onBankAccount(e)} /></div>
                                        </div>
                                        <div className={`${admin.admin_join_content_bank_input_box}`}>
                                            <div><span>예금주명</span><span className={`${admin.admin_join_section_content_essential}`}>(필수)</span></div>
                                            <div><input type="text" value={shopInfo.shopDepositorName !== '' ? shopInfo.shopDepositorName : ''} onChange={(e) => onDepositerName(e)} /></div>
                                        </div>
                                        <div className={`${admin.admin_join_section_business_report_number}`}>
                                            <div>
                                                <div><span>통신판매업신고번호</span></div>
                                                <div><span>(선택)</span></div>
                                            </div>
                                            <div><input type="text" name="" id="" onChange={(e) => setUserInput({...userInput, salesReportNumber: e.target.value})} /></div>
                                        </div>
                                        <div className={`${admin.admin_join_content_img_upload_box}`}>
                                            <div>
                                                <div><span>통신판매업신고증</span></div>
                                                <div><span>(선택)</span></div>
                                            </div>
                                            <input
                                                type="file"
                                                ref={salesReportInputRef}
                                                style={{ display: 'none' }}
                                                onChange={(e) => handleSaleReportChange(e, setSalesReport, setSalesReportPreview)}
                                            />
                                            <div>
                                                <button className={`${admin.admin_join_content_img_select}`} onClick={() => salesReportInputRef.current.click()}>파일 선택</button>
                                                <span className={`${admin.admin_join_content_img_span}`}>jpg, jpeg, png 파일만 업로드해주세요.</span>
                                            </div>
                                        </div>
                                            {salesReportPreview && (
                                                <div className={`${admin.admin_join_content_img_preview}`}>
                                                    <img src={salesReportPreview} alt="통신판매업신고증 미리보기" style={{ width: '100px', height: '100px' }} />
                                                    <button className={`${admin.admin_join_content_img_preview_delete}`} onClick={() => removeFile(setSalesReport, setSalesReportPreview)}><span className="material-symbols-outlined">delete</span></button>
                                                </div>
                                            )}
                                    </div>
                                </div>
                                <div className={`${admin.admin_join_content_shop_box}`}>
                                    <div className={`${admin.admin_join_content_shop_box_header}`}>
                                        <div><span>상점 사전 등록</span></div>
                                        <div><span>* 상점 정보를 사전 등록하여 가입 승인 이후 별도의 작업없이 바로 상점 이용이 가능합니다.</span></div>
                                        <div><span>* 상점명을 제외한 상점소개, 프로필, 배경은 가입 승인 후에도 언제든지 변경 가능합니다.</span></div>
                                    </div>
                                    <div className={`${admin.admin_join_content_shop_box_content}`}>
                                        <div className={`${admin.admin_join_content_shop_box_content_shop_name}`}>
                                            <div><span>상점명</span><span className={`${admin.admin_join_section_content_essential}`}>(필수)</span></div>
                                            <div><input type="text" onChange={(e) => onShopName(e)}/></div>
                                        </div>
                                        <div className={`${admin.admin_join_content_shop_box_content_shop_content}`}>
                                            <div><span>상점소개</span></div>
                                            <div><input type="text" onChange={(e) => onShopContent(e)}/></div>
                                        </div>
                                        <div className={`${admin.admin_join_content_shop_box_content_shop_profile}`}>
                                            <div><span>상점프로필</span></div>
                                            <input
                                                type="file"
                                                ref={shopProfileInputRef}
                                                style={{ display: 'none' }}
                                                onChange={(e) => handleShopProfileChange(e, setShopProfile, setShopProfilePreview)}
                                            />
                                            <div className={`${admin.admin_join_content_content_shop_box_btn}`}><button onClick={() => shopProfileInputRef.current.click()}>업로드</button></div>
                                        </div>
                                        {shopProfilePreview && (
                                            <div className={`${admin.admin_join_shop_box_img_preview}`}>
                                                <img src={shopProfilePreview} alt="상점프로필 미리보기" />
                                                <button className={`${admin.admin_join_content_img_preview_delete}`} onClick={() => removeFile(setShopProfile, setShopProfilePreview)}><span className="material-symbols-outlined">delete</span></button>
                                            </div>
                                        )}
                                        <div className={`${admin.admin_join_content_shop_box_content_shop_back}`}>
                                            <div><span>상점배경</span></div>
                                            <input
                                                type="file"
                                                ref={shopBackInputRef}
                                                style={{ display: 'none' }}
                                                onChange={(e) => handleShopBackChange(e, setShopBack, setShopBackPreview)}
                                            />
                                            <div className={`${admin.admin_join_content_content_shop_box_btn}`}>
                                                <button onClick={() => shopBackInputRef.current.click()}>업로드</button>
                                            </div>
                                        </div>
                                        {shopBackPreview && (
                                            <div className={`${admin.admin_join_shop_back_box_img_preview}`}>
                                                <img src={shopBackPreview} alt="상점배경 미리보기" />
                                                <button className={`${admin.admin_join_content_img_preview_delete}`} onClick={() => removeFile(setShopBack, setShopBackPreview)}><span className="material-symbols-outlined">delete</span></button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                
                                <div className={`${admin.admin_join_section_business_btn}`}>
                                    <div><button onClick={() => onBack()}>뒤로가기</button></div>
                                    <div><button className={isFormValid ? admin.admin_join_section_business_btn_active : ''} onClick={handleSignup} disabled={!isFormValid}>회원가입</button></div>
                                    {/* <div><button onClick={check}>check</button></div> */}
                                </div>
                            </div>
                        }
                        {
                            activeBusinessState === 'nonbusiness' &&
                            <div>
                                <div className={`${admin.admin_join_section_business_header}`}><span>비사업자 정보</span></div>
                                <div>
                                    <div className={`${admin.admin_join_section_representative}`}>
                                        <div><span>이름</span><span className={`${admin.admin_join_section_content_essential}`}>(필수)</span></div>
                                        <div><input type="text" name="" id="" onChange={(e) => setUserInput({...userInput, businessName: e.target.value})} /></div>
                                    </div>
                                    <div className={`${admin.admin_join_section_registration_number}`}>
                                        <div><span>주민등록번호</span><span className={`${admin.admin_join_section_content_essential}`}>(필수)</span></div>
                                        <div>
                                            <div><input type="number" name="" id="" value={registrationNumber1} onChange={(e) => registration1(e)} /></div>
                                            <div><span>-</span></div>
                                            <div><input type="password" name="" id="" value={registrationNumber2} onChange={(e) => registration2(e)} /></div>
                                        </div>
                                    </div>
                                    <div className={`${admin.admin_join_section_registration_number_text}`}>
                                        <div><span className={`${admin.admin_join_section_content_essential}`}>*작가님의 소중한 개인정보는 암호화되어 저장됩니다.</span></div>
                                    </div>
                                    <div>
                                        <div className={`${admin.admin_join_section_business_addr}`}>
                                            <div><span>주소지</span><span className={`${admin.admin_join_section_content_essential}`}>(필수)</span></div>
                                            <div><input type="button" onClick={sample3_execDaumPostcode} value="주소 검색" /></div>
                                        </div>
                                        {
                                            showBusinessAddress &&
                                            <div>
                                                <div className={`${admin.admin_join_section_business_addr_box1}`}>
                                                    <input type="text" id="sample3_postcode" placeholder="우편번호" value={postcode} readOnly />
                                                    <input type="text" id="sample3_address" placeholder="주소" value={address} readOnly /><br />
                                                </div>
                                                <div className={`${admin.admin_join_section_business_addr_box2}`}>
                                                    <input type="text" id="sample3_detailAddress" placeholder="상세주소" value={detailAddress} onChange={(e) => {setDetailAddress(e.target.value); setUserInput({...userInput, detailAddr: e.target.value})}} />
                                                    <input type="text" id="sample3_extraAddress" placeholder="참고항목" value={extraAddress} readOnly />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div id="wrap" style={{ display: 'none', border: '1px solid', width: '100%', height: '300px', margin: '5px 0', position: 'relative' }}>
                                        <img src="//t1.daumcdn.net/postcode/resource/images/close.png" id="btnFoldWrap" style={{ cursor: 'pointer', position: 'absolute', right: '0px', top: '-1px', zIndex: 1 }} onClick={closePostcodePopup} alt="접기 버튼" />
                                    </div>

                                    <div className={`${admin.admin_join_content_img_upload_box}`}>
                                        <div><span>통장사본</span><span className={`${admin.admin_join_section_content_essential}`}>(필수)</span></div>
                                        <input
                                            type="file"
                                            ref={bankCopyInputRef}
                                            style={{ display: 'none' }}
                                            onChange={(e) => handleBankCopyChange(e, setBankCopy, setBankCopyPreview)}
                                        />
                                        <div>
                                            <button className={`${admin.admin_join_content_img_select}`} onClick={() => bankCopyInputRef.current.click()}>파일 선택</button>
                                        </div>
                                    </div>
                                        {bankCopyPreview && (
                                            <div className={`${admin.admin_join_content_img_preview}`}>
                                                <img src={bankCopyPreview} alt="통장사본 미리보기" style={{ width: '100px', height: '100px' }} />
                                                <button className={`${admin.admin_join_content_img_preview_delete}`} onClick={() => removeFile(setBankCopy, setBankCopyPreview)}><span className="material-symbols-outlined">delete</span></button>
                                            </div>
                                        )}
                                    <div className={`${admin.admin_join_content_bank_select_box}`}>
                                        <div><span>정산은행</span><span className={`${admin.admin_join_section_content_essential}`}>(필수)</span></div>
                                        <div>
                                            <button onClick={() => setShowBankSelect(true)} className={`${admin.admin_join_content_bank_select_btn} ${selectBank.bankImg === '' && selectBank.bankName === '' ? admin.join_content_bank_no_data : admin.join_content_bank_data}`}>
                                            {
                                                selectBank.bankImg === '' && selectBank.bankName === '' ?
                                                <div>
                                                    <span>은행을 선택해주세요.</span>
                                                </div>
                                                :
                                                <div>
                                                    <div><img src={`${process.env.PUBLIC_URL}${selectBank.bankImg}`} alt="은행이미지" /></div>
                                                    <div><span>{selectBank.bankName}</span></div>
                                                </div>
                                            }
                                            
                                            </button>
                                        </div>
                                    </div>
                                    <div className={`${admin.admin_join_content_bank_input_box}`}>
                                        <div><span>정산계좌번호</span><span className={`${admin.admin_join_section_content_essential}`}>(필수)</span></div>
                                        <div><input type="text" value={shopInfo.shopAccountNumber !== '' ? shopInfo.shopAccountNumber : ''} onChange={(e) => onBankAccount(e)} /></div>
                                    </div>
                                    <div className={`${admin.admin_join_content_bank_input_box}`}>
                                        <div><span>예금주명</span><span className={`${admin.admin_join_section_content_essential}`}>(필수)</span></div>
                                        <div><input type="text" value={shopInfo.shopDepositorName !== '' ? shopInfo.shopDepositorName : ''} onChange={(e) => onDepositerName(e)} /></div>
                                    </div>
                                </div>
                                <div className={`${admin.admin_join_content_shop_box}`}>
                                    <div className={`${admin.admin_join_content_shop_box_header}`}>
                                        <div><span>상점 사전 등록</span></div>
                                        <div><span>* 상점 정보를 사전 등록하여 가입 승인 이후 별도의 작업없이 바로 상점 이용이 가능합니다.</span></div>
                                        <div><span>* 상점명을 제외한 상점소개, 프로필, 배경은 가입 승인 후에도 언제든지 변경 가능합니다.</span></div>
                                    </div>
                                    <div className={`${admin.admin_join_content_shop_box_content}`}>
                                        <div className={`${admin.admin_join_content_shop_box_content_shop_name}`}>
                                            <div><span>상점명</span><span className={`${admin.admin_join_section_content_essential}`}>(필수)</span></div>
                                            <div><input type="text" onChange={(e) => onShopName(e)}/></div>
                                        </div>
                                        <div className={`${admin.admin_join_content_shop_box_content_shop_content}`}>
                                            <div><span>상점소개</span></div>
                                            <div><input type="text" onChange={(e) => onShopContent(e)}/></div>
                                        </div>
                                        <div className={`${admin.admin_join_content_shop_box_content_shop_profile}`}>
                                            <div><span>상점프로필</span></div>
                                            <input
                                                type="file"
                                                ref={shopProfileInputRef}
                                                style={{ display: 'none' }}
                                                onChange={(e) => handleShopProfileChange(e, setShopProfile, setShopProfilePreview)}
                                            />
                                            <div className={`${admin.admin_join_content_content_shop_box_btn}`}><button onClick={() => shopProfileInputRef.current.click()}>업로드</button></div>
                                        </div>
                                        {shopProfilePreview && (
                                            <div className={`${admin.admin_join_shop_box_img_preview}`}>
                                                <img src={shopProfilePreview} alt="상점프로필 미리보기" />
                                                <button className={`${admin.admin_join_content_img_preview_delete}`} onClick={() => removeFile(setShopProfile, setShopProfilePreview)}><span className="material-symbols-outlined">delete</span></button>
                                            </div>
                                        )}
                                        <div className={`${admin.admin_join_content_shop_box_content_shop_back}`}>
                                            <div><span>상점배경</span></div>
                                            <input
                                                type="file"
                                                ref={shopBackInputRef}
                                                style={{ display: 'none' }}
                                                onChange={(e) => handleShopBackChange(e, setShopBack, setShopBackPreview)}
                                            />
                                            <div className={`${admin.admin_join_content_content_shop_box_btn}`}>
                                                <button onClick={() => shopBackInputRef.current.click()}>업로드</button>
                                            </div>
                                        </div>
                                        {shopBackPreview && (
                                            <div className={`${admin.admin_join_shop_back_box_img_preview}`}>
                                                <img src={shopBackPreview} alt="상점배경 미리보기" />
                                                <button className={`${admin.admin_join_content_img_preview_delete}`} onClick={() => removeFile(setShopBack, setShopBackPreview)}><span className="material-symbols-outlined">delete</span></button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className={`${admin.admin_join_section_business_btn}`}>
                                    <div><button onClick={() => onBack()}>뒤로가기</button></div>
                                    <div><button className={isFormValid ? admin.admin_join_section_business_btn_active : ''} onClick={handleSignup} disabled={!isFormValid}>회원가입</button></div>
                                    {/* <div><button onClick={check}>check</button></div> */}
                                </div>
                            </div>
                        }
                    </div>
                </div>

            </div>
            {
            showBankSelect &&
            <div className={`${seller.seller_bank_select_modal_container}`}>
            <div className={`${seller.seller_bank_select_modal_box}`}>
                <div className={`${seller.seller_bank_select_modal_header}`}>
                <div><span>은행선택</span></div>
                <div><button onClick={() => setShowBankSelect(false)}><span className="material-symbols-outlined">close</span></button></div>
                </div>
                <div className={`${seller.seller_bank_select_modal_tab}`}>
                <div><button className={activeBankStock === 'bank' ? seller.bank_select_active : ''} onClick={() => setActiveBankStock('bank')}>은행</button></div>
                <div><button className={activeBankStock === 'stock' ? seller.bank_select_active : ''} onClick={() => setActiveBankStock('stock')}>증권</button></div>
                </div>
                <div className={`${seller.seller_bank_select_modal_content} `}>
                {
                    activeBankStock === 'bank' &&
                    <div className={`${seller.seller_bank_select_modal_bank_box} `}>
                    {
                        bankList.map((item, index) => (
                        <button key={index} onClick={() => onBankSelect(item)}>
                            <div><img src={item.bankImg} alt="은행이미지" /></div>
                            <div><span>{item.bankName}</span></div>
                        </button>
                        ))
                    }
                    </div>
                }
                {
                    activeBankStock === 'stock' &&
                    <div className={`${seller.seller_bank_select_modal_bank_box} `}>
                    {
                        stockList.map((item, index) => (
                        <button key={index} onClick={() => onBankSelect(item)}>
                            <div><img src={item.bankImg} alt="은행이미지" /></div>
                            <div><span>{item.bankName}</span></div>
                        </button>
                        ))
                    }
                    </div>
                }
                </div>
            </div>
            </div>
        }
        </>
    );
};

export default JoinSection;
