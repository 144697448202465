import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import store from '../../../containers/market/store/store.module.css';

const SupportFaqDetail = () => {
    const back = useNavigate();

    const location = useLocation();

    const { faq } = location.state || {}; // state에서 faq 값을 추출

    useEffect(() => {
        console.log('faq : ', faq);
    }, [faq]);

    const backBtn = () => {
        back(-1);
    }

    const formatDate = (dateString) => {
        // dateString이 null, undefined, 또는 빈 문자열인 경우 처리
        if (!dateString) {
            return ''; // 또는 다른 기본값을 반환할 수 있습니다.
        }
        return dateString.replace('T', ' ').substring(0, 19);
    };
    
  return (
    <div className={`${store.store_faq_detail_container}`}>
        <div className={`${store.store_faq_detail_box}`}>
            <div className={`${store.store_faq_detail_header}`}>
                <div>
                    <button onClick={() => backBtn()}>
                        <span class="material-symbols-outlined">arrow_back_ios</span>
                    </button>
                </div>
                <div><span>FAQ</span></div>
            </div>
            <div className={`${store.store_faq_detail_content}`}>
                <div className={`${store.store_faq_detail_content_title}`}><span>{faq.question}</span></div>
                <div className={`${store.store_faq_detail_content_date}`}>
                    <span>{formatDate(faq.sellerFaqRegDate)}</span>
                </div>
                <div className={`${store.store_faq_detail_content_text}`}>
                    <span>
                        {faq.answer}
                    </span>
                </div>
            </div>
        </div>
    </div>
  )
}

export default SupportFaqDetail