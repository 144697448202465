import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const AccountSearch = () => {
    const location = useLocation();
    const { emailStatus } = location.state || {};
    const [ imgUrl, setImgUrl ] = useState(emailStatus.userProfile !== '' && emailStatus.userProfile !== null ? `${process.env.REACT_APP_API_SERVER}/img?file=${emailStatus.userProfile}` : '../img/user/ProfileCircle.png');
    const [ showLogin, setShowLogin ] = useState(false);

    // console.log(emailStatus);

    // useEffect를 사용하여 emailStatus가 변경될 때만 setImgUrl 호출
    useEffect(() => {
      if (emailStatus === 'NoEmail') {
        setImgUrl('../img/user/noData.png');
        setShowLogin(false);
      } else {
        setShowLogin(true);
      }
    }, [emailStatus]); // 의존성 배열에 emailStatus 추가

    const back = useNavigate();
    const screenBack = () => {
      back(-1);
    }

    const login = useNavigate();
    const loginMove = () => {
      login('/');
    }

    const passwordScreen = useNavigate();
    const passwordSearchMove = () => {
      passwordScreen('/passwordSearch');
    }

    // 날짜 포맷 변경 함수
    const formatDate = (dateString) => {
      if (dateString) { // dateString이 null이나 undefined가 아닐 때만 실행
          const date = new Date(dateString);
          if (isNaN(date.getTime())) { // 유효하지 않은 날짜인지 체크
              console.error('Invalid date value:', dateString);
              return ''; // 또는 적절한 기본값 반환
          }
          const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
          return new Intl.DateTimeFormat('ko-KR', options)
              .format(date)
              .replace(/\./g, '')
              .replace(/(\d{4})(\d{2})(\d{2})/, '$1.$2.$3');
      } else {
          return ''; // dateString이 null인 경우 빈 문자열 반환
      }
    };

    // 클립보드에 복사하는 함수
    const copyToClipboard = (text) => {
      navigator.clipboard.writeText(text)
        .then(() => {
          alert('복사되었습니다.');
        })
        .catch((err) => {
          console.error('클립보드 복사에 실패했습니다:', err);
        });
    };


  return (
    <>
        <div className='account_search_container'>
          <div className='account_search_box'>
            <div className='account_search_header'>
                <div>
                    <button onClick={screenBack}>
                        <span className="material-symbols-outlined">arrow_back_ios</span>
                    </button>
                </div>
                <div><span>계정 찾기</span></div>
            </div>
            {
              emailStatus !== 'NoEmail' ? 
            <div className='account_user_info'>
                <div><img src={imgUrl} alt="유저프로필" /></div>
                <div>
                  <div>
                    <div><span>아이디</span></div>
                    <div><span>{emailStatus.userId}</span></div>
                    <div><button onClick={() => copyToClipboard(emailStatus.userId)}>복사</button></div>
                  </div>
                  <div>
                    <div><span>이메일</span></div>
                    <div><span>{emailStatus.userEmail}</span></div>
                  </div>
                  <div>
                    <div><span>가입일</span></div>
                      <div><span>{formatDate(emailStatus.userRegDate)}</span></div>
                  </div>
                </div>
            </div>
            :
            <div className='account_user_info_no_data'>
              <div><img src={imgUrl} alt="유저프로필" /></div>
              <div>
                <div><span>가입된 계정이 없습니다.</span></div>
                <div><span>지금 데익리덕에 가입하고 덕질을 시작해보세요!</span></div>
              </div>
            </div>
            }

            <div>
              {
                showLogin ? (
                  <div className='email_success pb-4'>
                    <div className='es_password_search'>
                      <button className='es_password_searchBtn' onClick={passwordSearchMove}>비밀번호 찾기</button>
                    </div>
                    <div className='es_login'>
                      <button className='es_login_btn' onClick={loginMove}>로그인</button>
                    </div>
                  </div>
                ) : (
                  <div className='socitial_success pb-4 px-3'>
                    <button onClick={loginMove}>로그인 화면으로</button>
                  </div>
                )
              }
              </div>
          </div>
        </div>
    </>
  );
};

export default AccountSearch;
