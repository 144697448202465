import React, { useContext, useState, useEffect } from 'react'
import { LoginContext } from '../../contexts/LoginContextProvider';
import Country from '../join/Country';
import { useNavigate } from 'react-router-dom';
import * as auth from '../../apis/auth'

const EmailSearch = () => {

    const context = useContext(LoginContext);
    const countries = context.countries;

    const [showCountry, setShowCountry] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredCountries, setFilteredCountries] = useState(countries);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [isCheck, setIsCheck] = useState(false);
    const [certificationInputVisible, setCertificationInputVisible] = useState(false);
    const [timer, setTimer] = useState(180);
    const [name, setName] = useState('');
    
    const [mockCertificationNumber, setMockCertificationNumber] = useState('000000');
    const [userCertificationNumber, setUserCertificationNumber] = useState('');
    const [certificationSuccess, setCertificationSuccess] = useState(false); // 인증 성공 상태 추가

    const back = useNavigate();
    const account = useNavigate();

    const screenBack = () => {
        back(-1);
    }

    const handleCountry = () => {
        setShowCountry(true);
    };

    const handleCountryClose = () => {
        setShowCountry(false);
    };

    const handleSelectedCountry = (country) => {
        if (!country) {
            // console.log('Country not defined');
            return;
        }

        setSelectedCountry({
            name: country.name,
            number: country.number,
            img: country.img
        });

        // console.log(country.img);
    };

    const handlePhoneNumber = (e) => {
        setPhoneNumber(e.target.value);
    };

    const onHandleName = (e) => {
        setName(e.target.value);
    };

    useEffect(() => {
        if (name && selectedCountry && phoneNumber) {
            setIsCheck(true);
        } else {
            setIsCheck(false);
        }
    }, [name, selectedCountry, phoneNumber]);

    const certificationNumber = async () => {
        setIsCheck(false); // 인증번호 받기 버튼 비활성화
        
        setTimer(300);
        
        try {
            // const response = await auth.cernumber();
            // const data = response.data;
            // const statusCode = response.status;
            
            const response = await auth.phoneAuth(phoneNumber);
            const data = response.data;
            
            if (data === 'SUCCESS') {
                setCertificationInputVisible(true); // 인증번호 인풋 창 활성화

                // 타이머 시작
                let countdown = timer;
                const interval = setInterval(() => {
                    countdown -= 1;
                    setTimer(countdown);
                    if (countdown <= 0) {
                        clearInterval(interval);
                        setIsCheck(true);
                    }
                }, 1000);
            }
        } catch (error) {
            console.error('API 호출 중 오류 발생:', error); // 오류 처리
        }

    };

    const checkCertificationNumber = (e) => {
        setUserCertificationNumber(e.target.value);
    
        // 2초 뒤에 인증번호 검증 요청 보내기
        const timer = setTimeout(async () => {
            try {
                const response = await auth.phoneAuthCheck(phoneNumber, e.target.value);
                const data = response.data;
    
                if (data === 'SUCCESS') {
                    setIsCheck(true);
                    setCertificationSuccess(true); // 인증번호가 맞으면 인증 성공 상태를 true로 설정
                    setCertificationInputVisible(false);
                } else {
                    setIsCheck(false);
                    setCertificationSuccess(false); // 인증번호가 틀리면 인증 성공 상태를 false로 설정
                }
            } catch (error) {
                console.error('인증번호 확인 중 오류 발생:', error);
            }
        }, 2000);
    
        // 컴포넌트가 언마운트될 때 타이머를 클리어하여 메모리 누수를 방지합니다.
        return () => clearTimeout(timer);
    };

    const emailSearchNext = async () => {
        try {
            const response = await auth.emailSearch(name, phoneNumber);
            const data = await response.data;
    
            if(response.status === 200) {
                if(data === 'NoEmail') {
                    account('/account', { state: { emailStatus: data } }); // 경로와 state를 객체로 전달
                } else {
                    account('/account', { state: { emailStatus: data } }); // 경로와 state를 객체로 전달
                }
            } else {
                // 실패시 로직 작성 예정
            }
        } catch (error) {
            console.error(error);
        }
    };

  return (
    <>
        <div className='email_search_container position-relative'>
            <div className='email_search_box'>
                <div className='email_search_header'>
                    <div>
                        <button onClick={screenBack}>
                            <span className="material-symbols-outlined">arrow_back_ios</span>
                        </button>
                    </div>
                    <div><span>아이디 찾기</span></div>
                </div>

                <div>
                    <div className='join_input_box'>
                        <div><span>이름</span></div>
                        <div><input type="text" placeholder='이름' className='join_input_tag' onChange={(e) => onHandleName(e) } /></div>
                    </div>
                    <div className='join_input_box'>
                        <div className='pb-1'><span>국가</span></div>
                        <div className='join_input'>
                            {!selectedCountry ? 
                                <button type="text" className='d-flex' placeholder='국가' onClick={handleCountry}>
                                    <span className='country_name_input'>국가선택</span>
                                    <span className="material-symbols-outlined">expand_more</span>
                                </button> : 
                                <button type="text" className='d-flex' placeholder='국가' onClick={handleCountry}>
                                    <img src={selectedCountry?.img} alt={selectedCountry?.name} />
                                    <span className='country_name_input'>{selectedCountry?.name}</span>
                                    <span className="material-symbols-outlined">expand_more</span>
                                </button>
                            }
                        </div>
                    </div>
                    <div className='emailsearch_input_box pb-100p'>
                        <div className='pb-1'><span>휴대폰 번호</span></div>
                        <div className='join_input phone_number_box'>
                            <input type="number" className='join_input_tag' placeholder='휴대폰 번호' onChange={(e) => handlePhoneNumber(e)} />
                            <span style={{color : 'rgba(255, 3, 102, 1)'}}>{selectedCountry?.number}</span>
                        </div>
                        {
                            certificationInputVisible && (
                                <div className='join_input position-relative pt-2'>
                                    <input type="number" className='join_input_tag' placeholder='인증번호' onChange={checkCertificationNumber} />
                                    <span className='cer_span'>{Math.floor(timer / 60)}:{('0' + timer % 60).slice(-2)}</span>
                                </div>
                            )
                        }
                        {
                            certificationSuccess &&
                            <div><span style={{color : 'green'}}>인증되었습니다.</span></div>
                        }
                    </div>
                </div>
            </div>
            {
                showCountry &&
                    <Country showCountry={showCountry} setShowCountry={setShowCountry} handleCountryClose={handleCountryClose} 
                            searchTerm={searchTerm} setSearchTerm={setSearchTerm} countries={countries}
                            filteredCountries={filteredCountries} setFilteredCountries={setFilteredCountries}
                            setSelectedCountry={setSelectedCountry} handleSelectedCountry={handleSelectedCountry} />
            }
            <div className='position-absolute bottom-0 py-4 px-4 w-100'>
                <div className='cer_btn_box'>
                    {/* "인증번호 받기" 버튼은 인증 성공 전에만 표시되고, 인증 성공 후에는 사라지도록 수정 */}
                    {!certificationSuccess && <button className={`${isCheck && 'cer_active'}`} onClick={certificationNumber} disabled={!isCheck}>인증번호 받기</button>}
                    {certificationSuccess && <button onClick={emailSearchNext} className='cer_active'>다음</button>}
                </div>
            </div>
        </div>
    </>
  )
}

export default EmailSearch