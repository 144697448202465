import React, {useState, useEffect} from 'react'

const FavorityAdd = ( {showFavorityAdd, favorityAddTransition, animated, setShowFavorityAdd} ) => {

    const [text, setText] = useState('');
    const [favority, setFavority] = useState('');
    const [group, setGroup] = useState('');
    const [next, setNext] = useState(false);

    const handleFavAddBack = () => {
        setShowFavorityAdd(false);
    }

    const handleFavAddClose = () => {

    }

    const maxLength = 500;
  
    const handleChange = (e) => {
      const { value } = e.target;
      if (value.length <= maxLength) {
        setText(value);
      }
    };

    const favorityName = (e) => {
        setFavority(e.target.value);
    }

    const groupName = (e) => {
        setGroup(e.target.value);
    }

    useEffect(() => {
        if(favority) {
            setNext(true);
        } else {
            setNext(false);
        }

    }, [favority])

  return (
    <>
        {showFavorityAdd && (
            <div className='join_favority_container'>
                {favorityAddTransition((style, item) =>
                item ? (
                    <animated.div className='join_favority_detail_box' style={style}>
                        <div>
                            <div className='d-flex justify-content-between py-4 px-2'>
                            <div className='ps-3'>
                                <button className='region_close' onClick={() => handleFavAddBack()}>
                                <span className='material-symbols-outlined'>arrow_back_ios</span>
                                </button>
                            </div>
                            <div>
                                <h5>최애 선택</h5>
                            </div>
                            <div className='pe-3'>
                                <button className='region_close' onClick={() => handleFavAddClose()}>
                                <span className='material-symbols-outlined'>close</span>
                                </button>
                            </div>
                            </div>
                        </div>
                        <div className='py-3 mx-3'>
                            <div className='pb-4'>
                                <div><span className='bold'>[필수]</span><span>최애의 이름을 입력해주세요.</span></div>
                                <div className='pt-1'><input type="text" className='input_form' onChange={(e) => favorityName(e)} /></div>
                            </div>
                            <div className='pb-4'>
                                <div><span className='bold'>[선택]</span><span>소속 그룹 이름을 입력해주세요.</span></div>
                                <div className='pt-1'><input type="text" className='input_form' onChange={(e) => groupName(e)} /></div>
                            </div>
                            <div>
                                <div><span className='bold'>[선택]</span><span>추가 정보가 있다면 알려주세요.</span></div>
                                <div className='pt-1 textarea-container'>
                                    <textarea
                                        name=""
                                        id=""
                                        cols="30"
                                        rows="10"
                                        className='textarea_form'
                                        value={text}
                                        onChange={handleChange}
                                    >    
                                    </textarea>
                                    <div className='counter'>{`${text.length}/${maxLength}`}</div>
                                </div>
                            </div>
                        </div>
                        <div className='favority_add_btnBox px-3'>
                            <div><button className={`${next && 'favority_add_active'}`} disabled={!next}>요청하기</button></div>
                        </div>
                    </animated.div>
                ) :null
                )}
            </div>
        )}
    </>
  );
};

export default FavorityAdd