import React, { useRef, useState } from 'react'

const SellerStoreMain = ( {seller, support} ) => {

  const [ceoSay, setCeoSay] = useState('');

  let message = '내용을 입력하세요.';

  const onCeoSay = (e) => {
    const { value } = e.target;
    if (value.length <= maxLength) {
      setCeoSay(value);
    }
  }
  const maxLength = 200;

  const fileInputRef = useRef(null);
  const fileInputRef2 = useRef(null);
  const [ userImg, setUserImg ] = useState('');
  const [ userImg2, setUserImg2 ] = useState('');
  const [ defaultImg, setDefaultImg ] = useState(true);
  const [ defaultImg2, setDefaultImg2 ] = useState(true);

  const handleImageChange = async (e) => {

    const file = e.target.files[0];

    const formData = new FormData();
    formData.append('image', file);

    const config = {
      headers: {
        'content-type' : 'multipart/form-data'
      }
    };

    const response = await support.uploadImageToServer(formData, config);
    const data = response.data;

    const imageUrl = `${process.env.REACT_APP_API_SERVER}/img?file=${encodeURIComponent(data)}`;

    setUserImg(imageUrl);
    // console.log(imageUrl);
  };

  const handleImageChange2 = async (e) => {
    const file = e.target.files[0];
    if (file && file.type.substr(0, 5) === 'image') {
      const reader = new FileReader();

      reader.onloadend = () => {
          setUserImg2('');
          setUserImg2(reader.result);
          setDefaultImg2(false);
      };

      reader.readAsDataURL(file);
      
    } else {
      console.error("선택된 파일이 이미지가 아닙니다.");
    }
  };

  const handleClick = () => {
    document.getElementById('hiddenShopProfileImg').click();
  };
  const handleDeleteClick = () => {
    setUserImg('');
    setDefaultImg(true);
  }
  const handleClick2 = () => {
    fileInputRef2.current.click();
  };
  const handleDeleteClick2 = () => {
    setUserImg2('');
    setDefaultImg2(true);
  }

  return (
    <div className={`px-3 ${seller.seller_right_container}`}>
      <div>
        <div className='pt-3 pb-2 border-bottom'><span>프로필</span></div>
        <div className={`pt-4 pb-1 ${seller.seller_border_bottom_color}`}><span>상점 정보 등록</span></div>
        <div>
          <div className='d-flex w-100 border-bottom align-items-center py-3'>
            <div className={`${seller.seller_settlement_left}`}><span>상점명</span></div>
            <div className={`${seller.seller_settlement_middle} px-3`}><input type="text" placeholder='상점명을 입력하세요.' /></div>
            <div className={`${seller.seller_settlement_right}`}><span>*상점명은 최대 10자까지 입력 가능합니다.</span></div>
          </div>
          <div className='d-flex w-100 border-bottom align-items-center py-3'>
            <div className={`${seller.seller_settlement_left}`}><span>상점 소개</span></div>
            <div className={`${seller.seller_store_info_textbox} ${seller.seller_settlement_middle} px-3`}>
              <textarea 
                name="" 
                id="" 
                cols="30" 
                rows="10" 
                placeholder={`${message}`} 
                onChange={(e) => onCeoSay(e)}
                value={ceoSay}
              >
              </textarea>
              <div className='counter me-2 dd-fs-7'>{`${ceoSay.length}/${maxLength}`}</div>
              </div>
            <div className={`${seller.seller_settlement_right}`}><span>*상점 소개 내용은 최대 200자까지 입력 가능합니다.</span></div>
          </div>
          <div className='d-flex w-100 border-bottom align-items-center py-3'>
            <div className={`${seller.seller_settlement_left}`}><span>상점 프로필 사진 등록</span></div>
            <div className={`${seller.seller_settlement_middle} px-3`}>
              <div className={`${seller.seller_settlement_store_profile_img} position-relative`}>
                {
                  defaultImg ? 
                  <div className={`position-absolute ${seller.seller_settlement_default_img}`}>
                    <img src="../../img/seller/icon/default2.png" alt="이미지" />
                  </div>
                  :
                  <img src={userImg} alt="상점 프로필" /> 
                }
              </div>
              <div className='pt-2'>
                  {/* 실제 파일 입력 필드는 숨김 처리 */}
                  <input type="file" onChange={(e) => handleImageChange(e)} id='hiddenShopProfileImg' style={{display: 'none'}} />
                  {/* 사용자에게 보여지는 버튼. 클릭 시 파일 입력 필드를 트리거함 */}
                  <button className='bg-body py-1 px-3 me-1 rounded border border-1' onClick={() => handleClick()}>등록</button>
                  <button className='bg-body py-1 px-3 ms-1 rounded border border-1' onClick={handleDeleteClick}>삭제</button>
              </div>
            </div>
            <div className={`${seller.seller_settlement_right}`}>
              <div><span>*업로드 가능한 파일 확장자: jpg, png, heic</span></div>
              <div><span>*이미지 용량: 1MB 이하</span></div>
            </div>
          </div>
          <div className='d-flex w-100 border-bottom align-items-center py-3'>
            <div className={`${seller.seller_settlement_left}`}><span>상점 배경 사진 등록</span></div>
            <div className={`${seller.seller_settlement_middle} px-3`}>
              <div className={`${seller.seller_settlement_store_banner_img} position-relative`}>
                {
                  defaultImg2 ? 
                  <div className={`position-absolute ${seller.seller_settlement_default_img}`}>
                    <img src="../../img/seller/icon/default2.png" alt="이미지" />
                  </div>
                  :
                  <img src={userImg2} alt="상점 프로필" /> 
                }
              </div>
              <div className='pt-2'>
                  {/* 실제 파일 입력 필드는 숨김 처리 */}
                  <input type="file" onChange={handleImageChange2} ref={fileInputRef2} style={{display: 'none'}} />
                  {/* 사용자에게 보여지는 버튼. 클릭 시 파일 입력 필드를 트리거함 */}
                  <button className='bg-body py-1 px-3 me-1 rounded border border-1' onClick={handleClick2}>등록</button>
                  <button className='bg-body py-1 px-3 ms-1 rounded border border-1' onClick={handleDeleteClick2}>삭제</button>
              </div>
            </div>
            <div className={`${seller.seller_settlement_right}`}>
              <div><span>*업로드 가능한 파일 확장자: jpg, png, heic</span></div>
              <div><span>*이미지 용량: 1MB 이하</span></div>
            </div>
          </div>
        </div>
        <div className='w-100 d-flex justify-content-center align-items-center pt-5'>
          <div className={`${seller.seller_settlement_save_btn}`}>
            <button>저장하기</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SellerStoreMain