import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import dailyduck from './MyContainner.module.css'
import ReviewWrite from '../../components/my/ReviewWrite';
import ReviewWritten from '../../components/my/ReviewWritten';
import * as auth from '../../apis/auth';
import { LoginContext } from '../../contexts/LoginContextProvider';
import { format } from 'date-fns';

const MyReview = () => {
    const navigate = useNavigate();
    const { userInfo } = useContext(LoginContext);
    const [activeButton, setActiveButton] = useState('writable');
    const [categoryButton, setCategoryButton] = useState('store');
    const [storeList, setStoreList] = useState([]);
    const [reservationList, setReservationList] = useState([]);
    const [showReviewWrite, setShowReviewWrite] = useState(false);
    const [showReviewWritten, setShowReviewWritten] = useState(false);
    const [selectedReview, setSelectedReview] = useState(null);

    const [writableReviews, setWritableReviews] = useState([]);
    const [writtenReviews, setWrittenReviews] = useState([]);
    const [filterGroupStores, setFilterGroupStores] = useState({});
    const [supportReviewCompleted, setSupportReviewCompleted] = useState([]);
    const [storeReviewCompleted, setStoreReviewCompleted] = useState([]);
    const [selectReservation, setSelectReservation] = useState(null);
    const [selectReservationReview, setSelectReservationReview] = useState(null);
    const [selectSupport, setSelectSupport] = useState(null);

    const currentReviews = activeButton === 'writable' ? writableReviews : writtenReviews;

    useEffect(() => {
        reservationSelect();
        supportReviewComplete();
        storePaymentList();
        storeReviewComplete();
    }, []);

    const reservationSelect = async () => {
        const response = await auth.reservationSelect(userInfo?.userId);
        const data = response.data;

        // console.log('data : ', data)
        const reservationsArray = Object.keys(data).map(key => data[key]);
        setReservationList(reservationsArray);
        // console.log("reservationList : ", reservationsArray);
    }

    const supportReviewComplete = async () => {
        const response = await auth.supportReviewComplete(userInfo?.userId);
        const data = response.data;

        // console.log('review : ', data);

        if(data !== null) {
            const reservationsReviewArray = Object.keys(data).map(key => data[key]);
            setSupportReviewCompleted(reservationsReviewArray);
            // console.log('reservationsReviewArray : ', reservationsReviewArray);
        } else {
            setSupportReviewCompleted([]);
        }
    }

    const storePaymentList = async () => {
        const response = await auth.storeOrderCompSelect(userInfo?.userId);
        const data = response.data;

        const storeArray = Object.keys(data).map(key => data[key]);

        // console.log('storeArray : ',storeArray);
        setStoreList(storeArray);
    }
    const storeReviewComplete = async () => {
        const response = await auth.storeReviewComplete(userInfo?.userId);
        const data = response.data;

        // console.log('review : ', data);
        const storeReviewArray = Object.keys(data).map(key => data[key]);
        setStoreReviewCompleted(storeReviewArray);
        // console.log('storeReviewArray : ', storeReviewArray);
    }

    const handleReviewBack = () => {
        navigate(-1);
    }

    const reviewWrite = (reservation, support, store, storePayment) => {
        // console.log('reservation : ', reservation);
        // console.log('support : ', support);
        // setSelectedReview({ reservation, support });
        // setShowReviewWrite(true);

        if(reservation !== null || support !== null) {
            navigate('/my/review/write', { state : {reservation : reservation, support : support}})
        }
        
        if(store !== null || storePayment !== null) {
            navigate('/my/review/write', { state : {storePayment : storePayment, store : store}})
        }
    }

    const reviewWritten = (reservation, reservationReview, support, storeReview, productDTO, storePayment) => {
        // setSelectReservation(reservation);
        // setSelectReservationReview(reservationReview);
        // setSelectSupport(support);
        // setShowReviewWritten(true);

        if(reservation !== null && reservationReview !== null && support !== null) {
            navigate('/my/review/written', {state : {reservation: reservation, reservationReview: reservationReview, support: support}})
        }
        
        if(storeReview !== null && productDTO !== null & storePayment !== null) {
            navigate('/my/review/written', {state : {storeReview: storeReview, productDTO: productDTO, storePayment: storePayment}})
        }

    }

    useEffect(() => {
        const elements = document.getElementsByClassName('page_inner');
        if (showReviewWrite || showReviewWritten) {
            for (let element of elements) {
                element.style.height = '100vh';
            }
        } else {
            for (let element of elements) {
                element.style.height = '';
            }
        }
        return () => {
            for (let element of elements) {
                element.style.height = '';
            }
        }
    }, [showReviewWrite, showReviewWritten]);


    const formatDate = (dateString) => {
        // dateString이 null, undefined 또는 빈 문자열일 경우 안전하게 처리
        if (!dateString) {
            return '날짜 없음'; // 기본값으로 '날짜 없음' 반환
        }
    
        const date = new Date(dateString);
    
        // 유효한 날짜인지 확인
        if (isNaN(date.getTime())) {
            console.error('Invalid date:', dateString);
            return '유효하지 않은 날짜'; // 오류 메시지 또는 기본값 반환
        }
    
        return format(date, 'yyyy-MM-dd HH:mm:ss'); // 시간 포맷이 24시간제로 수정됨
    };
    
    

    return (
        <>
            <div className={`${dailyduck.mycouponporint_container}`}>
                <div className={dailyduck.mycouponporint_box}>
                    <div className={dailyduck.mycouponporint_header}>
                        <div>
                            <button onClick={() => handleReviewBack()} className='reservation_pay_close'>
                                <span className="material-symbols-outlined">arrow_back_ios</span>
                            </button>
                        </div>
                        <div>
                            <span>리뷰</span>
                        </div>
                    </div>
                    <div className={`${dailyduck.my_couponpoint_content}`}>
                        <div className='py-2 px-3 bg-white'>
                            <div className='d-flex w-100'>
                                <div className={`${dailyduck.my_review_writable} pb-1 ${activeButton == 'writable' ? dailyduck.review_active : ''}`} onClick={() => setActiveButton('writable')}><button>작성 가능한 리뷰</button></div>
                                <div className={`${dailyduck.my_review_written} pb-1 ${activeButton == 'written' ? dailyduck.review_active : ''}`} onClick={() => setActiveButton('written')}><button>작성한 리뷰</button></div>
                            </div>
                        </div>
                        <div className='py-3 px-3 bg-white'>
                            <div className={`d-flex ${dailyduck.my_review_category_box}`}>
                                <div className={`pe-2`}><button className={`${categoryButton == 'store' ? dailyduck.signature_color : dailyduck.gray_color}`} onClick={() => setCategoryButton('store')}>스토어</button></div>
                                <div className={``}><span className={`${dailyduck.gray_color}`}>|</span></div>
                                <div className={`ps-2`}><button className={`${categoryButton == 'support' ? dailyduck.signature_color : dailyduck.gray_color}`} onClick={() => setCategoryButton('support')}>서포트</button></div>
                            </div>
                        </div>
                        {activeButton == 'writable' && categoryButton == 'store' &&
                            <div className='mt-2'>
                                {
                                storeList.length > 0 ?
                                    storeList.map((item, index) => (
                                        <div key={index} className='px-3 bg-white mb-2 pb-2'>
                                            <div className='d-flex pt-3 '>
                                                <div className='pe-1'><span>주문일</span></div>
                                                <div className={`ps-1 ${dailyduck.gray_color} ${dailyduck.font_size_09}`}><span>{formatDate(item.storePayment.paymentRegDate)}</span></div>
                                            </div>
                                            <div className={`${dailyduck.my_review_content_box} my-3 py-3`}>
                                                <div className={`${dailyduck.my_review_content_left}`}><img src={item.storePayment.paymentImg} alt={item.storePayment.paymentProductName} /></div>
                                                <div className={`${dailyduck.my_review_content_middle} px-2`}>
                                                    <div><span>{item.storePayment.shopName}</span></div>
                                                    <div><span>{item.storePayment.paymentProductName}</span></div>
                                                    {
                                                        item.storePayment.options && item.storePayment.options.length > 0 ? (
                                                            item.storePayment.options.map((option, index) => (
                                                                <div key={index}>
                                                                    <div><span>{option.selectionOptionName} : </span><span>{option.selectionOptionValue}</span></div>
                                                                </div>
                                                            ))
                                                        ) : (
                                                            <div>
                                                                <span>{parseInt(item.storePayment.paymentPrice).toLocaleString()}</span>
                                                                <span>/</span>
                                                                <span>{item.storePayment.totalQuantity}개</span>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                                <div className={`${dailyduck.my_review_content_right}`}>
                                                    <button onClick={() => reviewWrite(null, null, item.productDTO, item.storePayment)}>리뷰쓰기</button>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                    :
                                    <div className={dailyduck.my_review_non_review}><span>작성 가능한 리뷰가 없습니다.</span></div>
                                }
                            </div>
                        }
                        {activeButton == 'writable' && categoryButton == 'support' &&
                            <div className='mt-2'>
                                {
                                reservationList.length > 0 ?
                                    reservationList.map((item, index) => (
                                        <div key={index} className='px-3 bg-white mb-2 pb-2 border-bottom'>
                                            <div className='d-flex pt-3 '>
                                                <div className='pe-1'><span>주문일</span></div>
                                                <div className={`ps-1 ${dailyduck.gray_color} ${dailyduck.font_size_09}`}><span>{formatDate(item.reservation.reservationRegDate)}</span></div>
                                            </div>
                                            <div className={`${dailyduck.my_review_content_box} my-3 py-3`}>
                                                <div className={`${dailyduck.my_review_content_left}`}><img src={item.reservation.mainItemImg} alt={item.support.productName} /></div>
                                                <div className={`${dailyduck.my_review_content_middle} px-2`}>
                                                    <div><span>{item.support.shopName}</span></div>
                                                    <div><span>{item.support.productName}</span></div>
                                                    <div><span>{item.reservation.mainOptionName}</span></div>
                                                </div>
                                                <div className={`${dailyduck.my_review_content_right}`}>
                                                    <button onClick={() => reviewWrite(item.reservation, item.support, null, null)}>리뷰쓰기</button>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                    :
                                    <div className={dailyduck.my_review_non_review}><span>작성 가능한 리뷰가 없습니다.</span></div>
                                }
                            </div>
                        }
                        {activeButton == 'written' && categoryButton == 'store' &&
                            <div className='mt-2'>
                                {
                                    Array.isArray(storeReviewCompleted) && storeReviewCompleted.length > 0 ? (

                                        storeReviewCompleted.map((item, index) => (
                                            <div key={index} className='px-3 bg-white pb-4'>
                                                <div className='d-flex'>
                                                    <div className='pe-1'><span>주문일</span></div>
                                                    <div className={`ps-1 ${dailyduck.gray_color} ${dailyduck.font_size_09}`}><span>{formatDate(item.storePayment.paymentRegDate)}</span></div>
                                                </div>
                                                <div className={`${dailyduck.my_review_written_box} py-3 border-bottom`} onClick={() => reviewWritten(null, null, null, item.storeReview, item.productDTO, item.storePayment)}>
                                                    <div className={`${dailyduck.my_review_written_left}`}><img src={item.storePayment.paymentImg} alt={item.storePayment.paymentProductName} /></div>
                                                    <div className={`${dailyduck.my_review_written_middle} px-2`}>
                                                        <div><span>{item.storePayment.shopName}</span></div>
                                                        <div><span>{item.storePayment.paymentProductName}</span></div>
                                                        {
                                                            item.storePayment.options && item.storePayment.options.length > 0 ? (
                                                                item.storePayment.options.map((option, index) => (
                                                                    <div key={index}>
                                                                        <div><span>{option.selectionOptionName} : </span><span>{option.selectionOptionValue}</span></div>
                                                                    </div>
                                                                ))
                                                            ) : (
                                                                <div>
                                                                    <div><span>단일옵션</span></div>
                                                                </div>
                                                            )
                                                        }
                                                        <div>
                                                            <span>{parseInt(item.storePayment.paymentPrice).toLocaleString()}</span>
                                                            <span>/</span>
                                                            <span>{item.storePayment.totalQuantity}개</span>
                                                        </div>
                                                    </div>
                                                    <div className={`${dailyduck.my_review_written_right}`}>
                                                        <button ><span className="material-symbols-outlined">arrow_forward_ios</span></button>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    )
                                    :
                                    (
                                        <div className={dailyduck.my_review_non_review}><span>아직 등록된 리뷰가 없습니다👀</span></div>
                                    )
                                }
                            </div>
                        }
                        {activeButton == 'written' && categoryButton == 'support' &&
                            <div className='mt-2'>
                                {
                                    Array.isArray(supportReviewCompleted) && supportReviewCompleted.length > 0 ? (
                                        supportReviewCompleted.map((item, index) => (
                                            <div key={index} className='px-3 bg-white pb-4'>
                                                <div className='d-flex'>
                                                    <div className='pe-1'><span>주문일</span></div>
                                                    <div className={`ps-1 ${dailyduck.gray_color} ${dailyduck.font_size_09}`}><span>{item.reservation !== null ? formatDate(item.reservation.reservationRegDate) : '날짜없음'}</span></div>
                                                </div>
                                                <div className={`${dailyduck.my_review_written_box} py-3 border-bottom`} onClick={() => reviewWritten(item.reservation, item.reservationReview, item.support, null, null, null)}>
                                                    <div className={`${dailyduck.my_review_written_left}`}><img src={item.reservation !== null ? item.reservation.mainItemImg : ''} alt={item.reservation !== null ? item.reservation.productName : ''} /></div>
                                                    <div className={`${dailyduck.my_review_written_middle} px-2`}>
                                                        <div><span>{item.support.shopName}</span></div>
                                                        <div><span>{item.support.productName}</span></div>
                                                        <div><span>{item.reservation !== null ? item.reservation.mainOptionName : ''}</span></div>
                                                    </div>
                                                    <div className={`${dailyduck.my_review_written_right}`}>
                                                        <button ><span className="material-symbols-outlined">arrow_forward_ios</span></button>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    )
                                    :
                                    (
                                        <div className={dailyduck.my_review_non_review}><span>아직 등록된 리뷰가 없습니다👀</span></div>
                                    )
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
            {showReviewWrite &&
                <ReviewWrite showReviewWrite={showReviewWrite} setShowReviewWrite={setShowReviewWrite} dailyduck={dailyduck} selectedReview={selectedReview} reservationSelect={reservationSelect}/>
            }
            {showReviewWritten &&
                <ReviewWritten showReviewWritten={showReviewWritten} setShowReviewWritten={setShowReviewWritten} dailyduck={dailyduck} selectReservation={selectReservation} selectReservationReview={selectReservationReview} selectSupport={selectSupport} formatDate={formatDate}/>
            }
        </>
    )
}

export default MyReview
