import React, { useEffect, useRef, useState } from 'react';
import admin from '../../../admin.module.css';
import { Select } from 'antd';
import { Pagination } from 'antd';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import * as support from '../../../../apis/support';
import * as adminApi from '../../../../apis/admin';
import { format, isSameMonth, subDays, isSameDay } from 'date-fns';

const { Option } = Select;

const AdminBanner = () => {
    const [activeButton, setActiveButton] = useState('mainBanner');
    const [pushChecked, setPushChecked] = useState(false);
    const [exposureChecked, setExposureChecked] = useState(false);
    const [banner, setBanner] = useState([]);
    const [content, setContent] = useState({});
    const [editContent, setEditContent] = useState({});
    const [showMainBanner, setShowMainBanner] = useState(false);
    const [showMainBannerUpdate, setShowMainBannerUpdate] = useState(false);
    const [linkState, setLinkState] = useState('');
    const [typeState, setTypeState] = useState('');
    const [editLinkState, setEditLinkState] = useState('');
    const [editTypeState, setEditTypeState] = useState('');
    const [previewImage, setPreviewImage] = useState("../../img/admin/defaultImage.png");
    const [editPreviewImage, setEditPreviewImage] = useState("../../img/admin/defaultImage.png");
    const [checkedBanners, setCheckedBanners] = useState([]);
    const MySwal = withReactContent(Swal);

    // 글자 수 제한 및 상태 추가
    const [mainTitleCount, setMainTitleCount] = useState(0);
    const [subTitleCount, setSubTitleCount] = useState(0);
    const MAIN_TITLE_LIMIT = 35;
    const SUB_TITLE_LIMIT = 45;

    useEffect(() => {
        bannerSelect();
    }, []);

    useEffect(() => {
        // console.log('banner : ', banner);
    }, [banner]);

    useEffect(() => {
        if (showMainBannerUpdate) {
            setEditLinkState(editContent.bannerLink ? 'link_true' : 'link_false');
            setEditTypeState(editContent.bannerType || '');
        }
    }, [showMainBannerUpdate]);

    const bannerSelect = async () => {
        const response = await adminApi.bannerSelect();
        const data = response.data;
        const sortedData = data.sort((a, b) => a.bannerExposureOrder - b.bannerExposureOrder);
        setBanner(sortedData);
    };

    const handlePushChange = (index, bannerNo) => {
        // console.log('bannerNo : ', bannerNo);
        if (banner[index].bannerExposure) {
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html:
                    `
                    <div>
                        <div class='${admin.admin_seller_add_title}'><span>배너를 숨기겠습니까?</span></div>
                        <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                        <button class='mx-1' id='seller_add_cancel'>취소</button>
                        <button class='mx-1' id='seller_add_success'>숨김</button>
                        </div>
                    </div>
                `,
                showConfirmButton: false,
                showCancelButton: false,
            });
            document.addEventListener('click', sellerAddSuccess);
            document.addEventListener('click', sellerAddCancel);

            function sellerAddSuccess(e) {
                if (e.target && e.target.id == 'seller_add_success') {
                    document.removeEventListener('click', sellerAddSuccess);
                    document.removeEventListener('click', sellerAddCancel);
                    exposureState();
                    MySwal.close();
                    let newBanner = [...banner];
                    newBanner[index].bannerExposure = !newBanner[index].bannerExposure;
                    setBanner(newBanner);
                }
            }
            
            const exposureState = async () => {
                const response = await adminApi.exposureStateUpdate(bannerNo);
                const data = response.data;

                // console.log(data);
            }

            function sellerAddCancel(e) {
                if (e.target && e.target.id == 'seller_add_cancel') {
                    MySwal.close();
                    document.removeEventListener('click', sellerAddCancel);
                }
            }
        } else {
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html:
                    `
                    <div>
                        <div class='${admin.admin_seller_add_title}'><span>배너를 노출 하시겠습니까?</span></div>
                        <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                        <button class='mx-1' id='seller_add_cancel'>취소</button>
                        <button class='mx-1' id='seller_add_success'>노출</button>
                        </div>
                    </div>
                `,
                showConfirmButton: false,
                showCancelButton: false,
            });
            document.addEventListener('click', sellerAddSuccess);
            document.addEventListener('click', sellerAddCancel);

            function sellerAddSuccess(e) {
                if (e.target && e.target.id == 'seller_add_success') {
                    document.removeEventListener('click', sellerAddSuccess);
                    exposureState();
                    MySwal.close();
                    let newBanner = [...banner];
                    newBanner[index].bannerExposure = !newBanner[index].bannerExposure;
                    setBanner(newBanner);
                }
            }

                        
            const exposureState = async () => {
                const response = await adminApi.exposureStateUpdateSuccess(bannerNo);
                const data = response.data;

                // console.log(data);
            }

            function sellerAddCancel(e) {
                if (e.target && e.target.id == 'seller_add_cancel') {
                    MySwal.close();
                    document.removeEventListener('click', sellerAddCancel);
                }
            }
        }
    };

    const handleOrderChange = async (value, bannerNo) => {
        // console.log('Selected Order:', value);
        // console.log('Banner No:', bannerNo);

        const response = await adminApi.exposureOrderUpdate(value, bannerNo);
        const data = response.data;

        if(data === 'SUCCESS') {
            bannerSelect();
        } else {
            alert('순서 변경에 실패했습니다.');
        }
    };

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            const allBannerNos = banner.map(item => item.bannerNo);
            setCheckedBanners(allBannerNos);
        } else {
            setCheckedBanners([]);
        }
    };

    const handleSelectBanner = (bannerNo) => {
        setCheckedBanners(prevState =>
            prevState.includes(bannerNo)
                ? prevState.filter(no => no !== bannerNo)
                : [...prevState, bannerNo]
        );
    };

    const handleDeleteBanners = () => {
        // console.log('Deleting Banners: ', checkedBanners);
        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html:
                `
                <div>
                    <div class='${admin.admin_seller_add_title}'><span>배너를 삭제 하시겠습니까?</span></div>
                    <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                    <button class='mx-1' id='seller_add_cancel'>취소</button>
                    <button class='mx-1' id='seller_add_success'>삭제</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });
        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);

        function sellerAddSuccess(e) {
            if (e.target && e.target.id == 'seller_add_success') {
                document.removeEventListener('click', sellerAddSuccess);
                bannerDelete();
                MySwal.close();
            }
        }

        const bannerDelete = async () => {
            const response = await adminApi.bannerDelete(checkedBanners);
            const data = response.data;

            if(data === 'SUCCESS') {
                bannerSelect();
            } else {
                alert('삭제에 실패했습니다.');
            }
        }

        function sellerAddCancel(e) {
            if (e.target && e.target.id == 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddCancel);
            }
        }
    };

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(14);
    const [totalItems, setTotalItems] = useState(0);

    const paginate = pageNumber => setCurrentPage(pageNumber);

    const onMainBannerClose = () => {
        setShowMainBanner(false);
        setContent({});
        setLinkState('');
        setTypeState('');
        setPreviewImage("../../img/admin/defaultImage.png");
    };

    const onMainBannerUpdateClose = () => {
        setShowMainBannerUpdate(false);
        setEditContent({});
        setEditLinkState('');
        setEditTypeState('');
        setEditPreviewImage("../../img/admin/defaultImage.png");
    };

    const fileInputRef = useRef(null);
    const handleImageClick = () => {
        fileInputRef.current.click();
    };

    const handleImageUpload = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                if (showMainBannerUpdate) {
                    setEditPreviewImage(reader.result);
                } else {
                    setPreviewImage(reader.result);
                }
            };
            reader.readAsDataURL(file);

            const formData = new FormData();
            formData.append('image', file);

            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            };

            const response = await support.uploadImageToServer(formData, config);
            const data = response.data;

            // console.log('data : ', data);
            if (data != '') {
                const imageUrl = `${process.env.REACT_APP_API_SERVER}/img?file=${encodeURIComponent(data)}`;

                // console.log(imageUrl);

                if (showMainBannerUpdate) {
                    setEditContent({
                        ...editContent, bannerImg: imageUrl
                    });
                } else {
                    setContent({
                        ...content, bannerImg: imageUrl
                    });
                }
            }
        }
    };

    const onLinked = (e) => {
        // console.log(e.target.id);
        const isLinkTrue = e.target.id === 'link_true';
        if (showMainBannerUpdate) {
            setEditLinkState(e.target.id);
            setEditContent({
                ...editContent, bannerLink: isLinkTrue
            });
        } else {
            setLinkState(e.target.id);
            setContent({
                ...content, bannerLink: isLinkTrue
            });
        }
    };

    const onType = (e) => {
        // console.log(e.target.id);
        if (showMainBannerUpdate) {
            setEditTypeState(e.target.id);
            setEditContent({
                ...editContent, bannerType: e.target.id
            });
        } else {
            setTypeState(e.target.id);
            setContent({
                ...content, bannerType: e.target.id
            });
        }
    };

    const handleExposureChange = () => {
        setExposureChecked(!exposureChecked);

        if (showMainBannerUpdate) {
            setEditContent({
                ...editContent, bannerExposure: !exposureChecked
            });
        } else {
            setContent({
                ...content, bannerExposure: !exposureChecked
            });
        }
    };

    useEffect(() => {
        // console.log('content : ', content);
    }, [content]);
    useEffect(() => {
        // console.log('editContent : ', editContent);
    }, [editContent]);

    const onBannerName = (e) => {
        if (showMainBannerUpdate) {
            setEditContent({
                ...editContent, bannerName: e.target.value
            });
        } else {
            setContent({
                ...content, bannerName: e.target.value
            });
        }
    };
    // 메인 타이틀 변경 핸들러
    const onMainTitle = (e) => {
        const value = e.target.value;
        const count = value.length;
        
        if (count > MAIN_TITLE_LIMIT) {
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html:
                    `
                    <div>
                        <div class='${admin.admin_seller_add_title}'><span>입력 가능한 글자수를 초과하였습니다.</span></div>
                        <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                        <button class='mx-1' id='seller_add_success'>확인</button>
                        </div>
                    </div>
                `,
                showConfirmButton: false,
                showCancelButton: false,
            });
            document.addEventListener('click', sellerAddSuccess);

            function sellerAddSuccess(e) {
                if (e.target && e.target.id == 'seller_add_success') {
                    setContent({
                        ...content,
                        bannerMainTitle: value.substring(0, MAIN_TITLE_LIMIT) // 제한된 길이로 조정
                    });
                    MySwal.close();
                    document.removeEventListener('click', sellerAddSuccess);
                }
            }
        } else {
            setContent({
                ...content,
                bannerMainTitle: value
            });
        }
        setMainTitleCount(count > MAIN_TITLE_LIMIT ? MAIN_TITLE_LIMIT : count);
    };

    // 서브 타이틀 변경 핸들러
    const onSubTitle = (e) => {
        const value = e.target.value;
        const count = value.length;
        
        if (count > SUB_TITLE_LIMIT) {
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html:
                    `
                    <div>
                        <div class='${admin.admin_seller_add_title}'><span>입력 가능한 글자수를 초과하였습니다.</span></div>
                        <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                        <button class='mx-1' id='seller_add_success'>확인</button>
                        </div>
                    </div>
                `,
                showConfirmButton: false,
                showCancelButton: false,
            });
            document.addEventListener('click', sellerAddSuccess);

            function sellerAddSuccess(e) {
                if (e.target && e.target.id == 'seller_add_success') {
                    setContent({
                        ...content,
                        bannerSubTitle: value.substring(0, SUB_TITLE_LIMIT) // 제한된 길이로 조정
                    });
                    MySwal.close();
                    document.removeEventListener('click', sellerAddSuccess);
                }
            }
        } else {
            setContent({
                ...content,
                bannerSubTitle: value
            });
        }
        setSubTitleCount(count > SUB_TITLE_LIMIT ? SUB_TITLE_LIMIT : count);
    };
    // 메인 타이틀 변경 핸들러
    const onEditMainTitle = (e) => {
        const value = e.target.value;
        const count = value.length;
        
        if (count > MAIN_TITLE_LIMIT) {
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html:
                    `
                    <div>
                        <div class='${admin.admin_seller_add_title}'><span>입력 가능한 글자수를 초과하였습니다.</span></div>
                        <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                        <button class='mx-1' id='seller_add_success'>확인</button>
                        </div>
                    </div>
                `,
                showConfirmButton: false,
                showCancelButton: false,
            });
            document.addEventListener('click', sellerAddSuccess);

            function sellerAddSuccess(e) {
                if (e.target && e.target.id == 'seller_add_success') {
                    setEditContent({
                        ...editContent,
                        bannerMainTitle: value.substring(0, MAIN_TITLE_LIMIT) // 제한된 길이로 조정
                    });
                    MySwal.close();
                    document.removeEventListener('click', sellerAddSuccess);
                }
            }
        } else {
            setEditContent({
                ...editContent,
                bannerMainTitle: value
            });
        }
        setMainTitleCount(count > MAIN_TITLE_LIMIT ? MAIN_TITLE_LIMIT : count);
    };

    // 서브 타이틀 변경 핸들러
    const onEditSubTitle = (e) => {
        const value = e.target.value;
        const count = value.length;
        
        if (count > SUB_TITLE_LIMIT) {
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html:
                    `
                    <div>
                        <div class='${admin.admin_seller_add_title}'><span>입력 가능한 글자수를 초과하였습니다.</span></div>
                        <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                        <button class='mx-1' id='seller_add_success'>확인</button>
                        </div>
                    </div>
                `,
                showConfirmButton: false,
                showCancelButton: false,
            });
            document.addEventListener('click', sellerAddSuccess);

            function sellerAddSuccess(e) {
                if (e.target && e.target.id == 'seller_add_success') {
                    setEditContent({
                        ...editContent,
                        bannerSubTitle: value.substring(0, SUB_TITLE_LIMIT) // 제한된 길이로 조정
                    });
                    MySwal.close();
                    document.removeEventListener('click', sellerAddSuccess);
                }
            }
        } else {
            setEditContent({
                ...editContent,
                bannerSubTitle: value
            });
        }
        setSubTitleCount(count > SUB_TITLE_LIMIT ? SUB_TITLE_LIMIT : count);
    };

    const onLink = (e) => {
        if (showMainBannerUpdate) {
            setEditContent({
                ...editContent, bannerTypeLink: e.target.value
            });
        } else {
            setContent({
                ...content, bannerTypeLink: e.target.value
            });
        }
    };

    const onBannerAdd = async () => {
        const response = await adminApi.bannerAdd(content);
        const data = response.data;

        // console.log('data : ', data);

        if(data === 'SUCCESS') {
            alert('배너가 등록되었습니다.');
            bannerSelect();
            setShowMainBanner(false);
            
        } else {
            alert('배너 등록에 실패했습니다.');
        }
    };

    const onBannerEdit = async () => {
        const response = await adminApi.bannerUpdate(editContent);
        const data = response.data;

        // console.log('data : ', data);

        if(data === 'SUCCESS') {
            alert('배너 수정이 완료되었습니다.');
            bannerSelect();
            setShowMainBannerUpdate(false);
        } else {
            alert('배너 수정이 실패했습니다.');
        }
    };

    const handleViewDetails = (item) => {
        setEditContent(item);
        setEditPreviewImage(item.bannerImg);
        setShowMainBannerUpdate(true);
    };

    // Get unique bannerExposureOrder values for options
    const uniqueBannerExposureOrders = [...new Set(banner.map(item => item.bannerExposureOrder))];

    const formatDate = (date) => {
        if (!date) return '';
        const parsedDate = new Date(date);
        if (isNaN(parsedDate)) {
          console.warn('Invalid date value:', date);
          return '';
        }
        return format(parsedDate, 'yyyy-MM-dd HH:mm:ss');
      };

    return (
        <div className={`${admin.admin_operate_banner_box}`}>
            <div className='px-3 py-3'>
                <div>
                    <div className={`${admin.admin_operate_banner_title}`}><span>배너</span></div>
                </div>
                <div className={`${admin.admin_operate_banner_tap_btn}`}>
                    <div className={`${activeButton === 'mainBanner' ? admin.admin_operate_banner_tap_active : ''}`}><button onClick={() => setActiveButton('mainBanner')}>메인배너</button></div>
                    <div className={`${activeButton === 'horizentalBanner' ? admin.admin_operate_banner_tap_active : ''}`}><button onClick={() => setActiveButton('horizentalBanner')}>가로배너</button></div>
                    <div className={`${activeButton === 'adBanner' ? admin.admin_operate_banner_tap_active : ''}`}><button onClick={() => setActiveButton('adBanner')}>광고배너</button></div>
                </div>
                {
                    activeButton === 'mainBanner' &&
                    <div>
                        <div>
                            <div className='d-flex justify-content-between w-100 px-3 py-2 border-top border-bottom align-items-center'>
                                <div><span className={`${admin.admin_font_date_font}`}>전체 {banner.length}</span></div>
                                <div>
                                    <button className={`${admin.admin_operate_banner_btn_style}`} onClick={() => setShowMainBanner(true)}>배너등록</button>
                                    {checkedBanners.length > 0 && (
                                        <button className={`${admin.admin_operate_banner_btn_style}`} onClick={handleDeleteBanners}>삭제</button>
                                    )}
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div className={`${admin.admin_operate_banner_content_header}`}>
                                        <div><input type="checkbox" onChange={handleSelectAll} checked={checkedBanners.length === banner.length} /></div>
                                        <div><span>배너명</span></div>
                                        <div><span>배너이미지</span></div>
                                        <div><span>등록일자</span></div>
                                        <div><span>노출여부</span></div>
                                        <div><span>노출순서</span></div>
                                        <div><span>노출위치</span></div>
                                        <div><span>관리</span></div>
                                    </div>
                                    {banner.map((item, index) => (
                                        <div key={index} className={`${admin.admin_operate_banner_content_content}`}>
                                            <div><input type="checkbox" checked={checkedBanners.includes(item.bannerNo)} onChange={() => handleSelectBanner(item.bannerNo)} /></div>
                                            <div><span>{item.bannerName}</span></div>
                                            <div><img src={item.bannerImg} alt="배너이미지" /></div>
                                            <div><span>{formatDate(item.bannerRegDate)}</span></div>
                                            <div>
                                                <label className={`${admin.admin_active_switch}`}>
                                                    <input type="checkbox" checked={item.bannerExposure} onChange={() => handlePushChange(index, item.bannerNo)} />
                                                    <span className={`${admin.admin_active_slider} ${admin.admin_active_round}`}></span>
                                                </label>
                                            </div>
                                            <div>
                                                <Select value={item.bannerExposureOrder} onChange={(value) => handleOrderChange(value, item.bannerNo)}>
                                                    {uniqueBannerExposureOrders.map(order => (
                                                        <Option key={order} value={order}>{order}</Option>
                                                    ))}
                                                </Select>
                                            </div>
                                            <div><span>메인</span></div>
                                            <div><button onClick={() => handleViewDetails(item)}>상세보기</button></div>
                                        </div>
                                    ))}
                                    <Pagination current={currentPage} total={totalItems} onChange={paginate} pageSize={itemsPerPage} />
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
            {
                showMainBanner &&
                <div className={`${admin.admin_operate_banner_main_banner_container}`} onClick={() => onMainBannerClose()}>
                    <div className={`${admin.admin_operate_banner_main_banner_box}`} onClick={(e) => e.stopPropagation()}>
                        <div className={`${admin.admin_operate_banner_main_banner_title} py-3 px-3`}>
                            <div><span>메인 배너 등록</span></div>
                            <div onClick={() => onMainBannerClose()}><span className="material-symbols-outlined">close</span></div>
                        </div>
                        <div className={`${admin.admin_operate_banner_main_banner_content}`}>
                            <div>
                                <div className='px-3'>
                                    <div className={`${admin.admin_operate_banner_main_banner_name}`}>
                                        <div><span>배너명</span></div>
                                        <div><input type="text" value={content.bannerName || ''} onChange={(e) => onBannerName(e)} /></div>
                                    </div>
                                    <div className={`${admin.admin_operate_banner_main_banner_img}`}>
                                        <div><span>배너이미지</span></div>
                                        <div>
                                            <div>
                                                <img src={previewImage} alt="Preview" />
                                            </div>
                                        </div>
                                        <div className={`${admin.admin_operate_banner_main_banner_img_btn_txt}`}>
                                            <input
                                                type="file"
                                                accept="image/*"
                                                onChange={handleImageUpload}
                                                ref={fileInputRef}
                                                style={{ display: 'none' }}
                                                id="imageUpload"
                                            />
                                            <span className={`${admin.admin_operate_banner_img_text}`}>* 700px x 400px 사이즈 권장</span>
                                            <label htmlFor="imageUpload">
                                                <button onClick={handleImageClick}>이미지추가</button>
                                            </label>
                                        </div>
                                    </div>
                                    <div className={`${admin.admin_operate_banner_main_banner_name}`}>
                                        <div><span>메인타이틀</span></div>
                                        <div className={`${admin.admin_operate_banner_title_input_box}`}>
                                            <div><input type="text" value={content.bannerMainTitle || ''} placeholder='최대 30자 내로 작성해주세요.' onChange={onMainTitle} /></div>
                                            <div><span>{mainTitleCount}/{MAIN_TITLE_LIMIT}</span></div>
                                        </div>
                                    </div>
                                    <div className={`${admin.admin_operate_banner_main_banner_name}`}>
                                        <div><span>서브타이틀</span></div>
                                        <div className={`${admin.admin_operate_banner_title_input_box}`}>
                                            <div><input type="text" value={content.bannerSubTitle || ''} placeholder='최대 45자 내로 작성해주세요.' onChange={onSubTitle} /></div>
                                            <div><span>{subTitleCount}/{SUB_TITLE_LIMIT}</span></div>
                                        </div>
                                    </div>
                                    <div className={`${admin.admin_operate_banner_main_banner_exposure}`}>
                                        <div><span>노출여부</span></div>
                                        <div>
                                            <label className={`${admin.admin_active_switch}`}>
                                                <input type="checkbox" checked={exposureChecked} onChange={handleExposureChange} />
                                                <span className={`${admin.admin_active_slider} ${admin.admin_active_round}`}></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className={`${admin.admin_operate_banner_main_banner_link}`}>
                                        <div><span>링크 여부</span></div>
                                        <div>
                                            <div>
                                                <input type="radio" name="link" id="link_true" onChange={(e) => onLinked(e)} />
                                                <label className='ps-1' htmlFor="link_true">사용</label>
                                            </div>
                                            <div className='ps-4'>
                                                <input type="radio" name="link" id="link_false" onChange={(e) => onLinked(e)} />
                                                <label className='ps-1' htmlFor="link_false">사용안함</label>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        linkState === 'link_true' &&
                                        <div className={`${admin.admin_operate_banner_main_banner_type}`}>
                                            <div><span>종류</span></div>
                                            <div>
                                                <div>
                                                    <input type="radio" name="type" id="event" onChange={(e) => onType(e)} />
                                                    <label className='ps-1' htmlFor="event">이벤트</label>
                                                </div>
                                                <div className='ps-4'>
                                                    <input type="radio" name="type" id="product" onChange={(e) => onType(e)} />
                                                    <label className='ps-1' htmlFor="product">상품</label>
                                                </div>
                                                <div className='ps-4'>
                                                    <input type="radio" name="type" id="linked" onChange={(e) => onType(e)} />
                                                    <label className='ps-1' htmlFor="linked">링크</label>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        linkState === 'link_true' && typeState === 'linked' &&
                                        <div className={`${admin.admin_operate_banner_main_banner_name}`}>
                                            <div><span>링크</span></div>
                                            <div><input type="text" placeholder='ex) /store/인형' value={content.bannerTypeLink || ''} onChange={(e) => onLink(e)} /></div>
                                        </div>
                                    }
                                    {
                                        linkState === 'link_true' && typeState === 'product' &&
                                        <div className={`${admin.admin_operate_banner_main_banner_select_box}`}>
                                            <div>
                                                <div><span>상품</span></div>
                                                <div><button>상품선택</button></div>
                                            </div>
                                            <div></div>
                                        </div>
                                    }
                                    {
                                        linkState === 'link_true' && typeState === 'event' &&
                                        <div className={`${admin.admin_operate_banner_main_banner_select_box}`}>
                                            <div>
                                                <div><span>이벤트</span></div>
                                                <div><button>이벤트선택</button></div>
                                            </div>
                                            <div></div>
                                        </div>
                                    }
                                    <div className={`${admin.admin_operate_banner_main_banner_add_btn}`}><button onClick={onBannerAdd}>배너 등록</button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                showMainBannerUpdate &&
                <div className={`${admin.admin_operate_banner_main_banner_container}`} onClick={() => onMainBannerUpdateClose()}>
                    <div className={`${admin.admin_operate_banner_main_banner_box}`} onClick={(e) => e.stopPropagation()}>
                        <div className={`${admin.admin_operate_banner_main_banner_title} py-3 px-3`}>
                            <div><span>배너 수정</span></div>
                            <div onClick={() => onMainBannerUpdateClose()}><span className="material-symbols-outlined">close</span></div>
                        </div>
                        <div className={`${admin.admin_operate_banner_main_banner_content}`}>
                            <div>
                                <div className='px-3'>
                                    <div className={`${admin.admin_operate_banner_main_banner_name}`}>
                                        <div><span>배너명</span></div>
                                        <div><input type="text" value={editContent.bannerName || ''} onChange={(e) => onBannerName(e)} /></div>
                                    </div>
                                    <div className={`${admin.admin_operate_banner_main_banner_img}`}>
                                        <div><span>배너이미지</span></div>
                                        <div>
                                            <div>
                                                <img src={editPreviewImage} alt="Preview" />
                                            </div>
                                        </div>
                                        <div className={`${admin.admin_operate_banner_main_banner_img_btn_txt}`}>
                                            <input
                                                type="file"
                                                accept="image/*"
                                                onChange={handleImageUpload}
                                                ref={fileInputRef}
                                                style={{ display: 'none' }}
                                                id="imageUpload"
                                            />
                                            <span className={`${admin.admin_operate_banner_img_text}`}>* 700px x 400px 사이즈 권장</span>
                                            <label htmlFor="imageUpload">
                                                <button onClick={handleImageClick}>이미지추가</button>
                                            </label>
                                        </div>
                                    </div>
                                    <div className={`${admin.admin_operate_banner_main_banner_name}`}>
                                        <div><span>메인타이틀</span></div>
                                        <div className={`${admin.admin_operate_banner_title_input_box}`}>
                                            <div><input type="text" value={editContent.bannerMainTitle || ''} placeholder='최대 30자 내로 작성해주세요.' onChange={onEditMainTitle} /></div>
                                            <div><span>{mainTitleCount}/{MAIN_TITLE_LIMIT}</span></div>
                                        </div>
                                        
                                    </div>
                                    <div className={`${admin.admin_operate_banner_main_banner_name}`}>
                                        <div><span>서브타이틀</span></div>
                                        <div className={`${admin.admin_operate_banner_title_input_box}`}>
                                            <div><input type="text" value={editContent.bannerSubTitle || ''} placeholder='최대 45자 내로 작성해주세요.' onChange={onEditSubTitle} /></div>
                                            <div><span>{subTitleCount}/{SUB_TITLE_LIMIT}</span></div>
                                        </div>
                                            
                                    </div>
                                    <div className={`${admin.admin_operate_banner_main_banner_exposure}`}>
                                        <div><span>노출여부</span></div>
                                        <div>
                                            <label className={`${admin.admin_active_switch}`}>
                                                <input type="checkbox" checked={editContent.bannerExposure} onChange={handleExposureChange} />
                                                <span className={`${admin.admin_active_slider} ${admin.admin_active_round}`}></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className={`${admin.admin_operate_banner_main_banner_link}`}>
                                        <div><span>링크 여부</span></div>
                                        <div>
                                            <div>
                                                <input type="radio" name="editLink" id="link_true" checked={editLinkState === 'link_true'} onChange={(e) => onLinked(e)} />
                                                <label className='ps-1' htmlFor="link_true">사용</label>
                                            </div>
                                            <div className='ps-4'>
                                                <input type="radio" name="editLink" id="link_false" checked={editLinkState === 'link_false'} onChange={(e) => onLinked(e)} />
                                                <label className='ps-1' htmlFor="link_false">사용안함</label>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        editLinkState === 'link_true' &&
                                        <div className={`${admin.admin_operate_banner_main_banner_type}`}>
                                            <div><span>종류</span></div>
                                            <div>
                                                <div>
                                                    <input type="radio" name="editType" id="event" checked={editTypeState === 'event'} onChange={(e) => onType(e)} />
                                                    <label className='ps-1' htmlFor="event">이벤트</label>
                                                </div>
                                                <div className='ps-4'>
                                                    <input type="radio" name="editType" id="product" checked={editTypeState === 'product'} onChange={(e) => onType(e)} />
                                                    <label className='ps-1' htmlFor="product">상품</label>
                                                </div>
                                                <div className='ps-4'>
                                                    <input type="radio" name="editType" id="linked" checked={editTypeState === 'linked'} onChange={(e) => onType(e)} />
                                                    <label className='ps-1' htmlFor="linked">링크</label>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        editLinkState === 'link_true' && editTypeState === 'linked' &&
                                        <div className={`${admin.admin_operate_banner_main_banner_name}`}>
                                            <div><span>링크</span></div>
                                            <div><input type="text" placeholder='ex) /store/인형' value={editContent.bannerTypeLink || ''} onChange={(e) => onLink(e)} /></div>
                                        </div>
                                    }
                                    {
                                        editLinkState === 'link_true' && editTypeState === 'product' &&
                                        <div className={`${admin.admin_operate_banner_main_banner_select_box}`}>
                                            <div>
                                                <div><span>상품</span></div>
                                                <div><button>상품선택</button></div>
                                            </div>
                                            <div></div>
                                        </div>
                                    }
                                    {
                                        editLinkState === 'link_true' && editTypeState === 'event' &&
                                        <div className={`${admin.admin_operate_banner_main_banner_select_box}`}>
                                            <div>
                                                <div><span>이벤트</span></div>
                                                <div><button>이벤트선택</button></div>
                                            </div>
                                            <div></div>
                                        </div>
                                    }
                                    <div className={`${admin.admin_operate_banner_main_banner_add_btn}`}><button onClick={onBannerEdit}>배너 수정</button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default AdminBanner;
