import React, { useContext, useEffect, useRef, useState } from 'react';
import { loadTossPayments, ANONYMOUS } from '@tosspayments/tosspayments-sdk';
import { useNavigate } from 'react-router-dom';
import dailyduck from './MyContainner.module.css';
import { LoginContext } from '../../contexts/LoginContextProvider';
import * as auth from '../../apis/auth';

const generateRandomString = () => window.btoa(Math.random()).slice(0, 20);

const MyPointRecharge = ( {} ) => {

    const {userInfo} = useContext(LoginContext);
    const navigate = useNavigate();
    const [isChecked, setIsChecked] = useState(false);
    const [totalPrice, setTotalPrice] = useState(50000); // 초기값 설정
    const [user, setUser] = useState({});
    const [pointInput, setPointInput] = useState(0); // 초기값 설정
    
    const [ready, setReady] = useState(false);
    const [widgets, setWidgets] = useState(null);
    const [amount, setAmount] = useState({
        currency: "KRW",
        value: 50000,
    });

    useEffect(() => {
        userSelect();
    }, []);

    const userSelect = async () => {
        if (userInfo?.userId) {
            const response = await auth.userselect(userInfo.userId);
            const data = response.data;
            setUser(data);
        }
    };
    useEffect(() => {
        if (ready) {
            if (totalPrice > 0) {
                widgets.setAmount({
                    currency: "KRW",
                    value: totalPrice,
                });
            }
        }
    }, [totalPrice, ready]);

    useEffect(() => {
        if (pointInput > 0) {
            setTotalPrice(pointInput);
        }
    }, [pointInput]);

    useEffect(() => {
            fetchPaymentWidgets();
    }, []);

    const fetchPaymentWidgets = async () => {
        const tossPayments = await loadTossPayments(process.env.REACT_APP_TOSS_CLIENT_KEY);
        const widgets = tossPayments.widgets({ customerKey: ANONYMOUS });
        setWidgets(widgets);
    };

    useEffect(() => {
        const renderPaymentWidgets = async () => {
            if (widgets == null) {
                return;
            }

            try {
                await widgets.setAmount(amount);

                await widgets.renderPaymentMethods({
                    selector: "#payment-method",
                    variantKey: "DEFAULT",
                });

                await widgets.renderAgreement({
                    selector: "#agreement",
                    variantKey: "AGREEMENT",
                });

                setReady(true);
            } catch (error) {
                if (error.name === 'AlreadyWidgetRenderedError') {
                    widgets.cleanup();
                    await renderPaymentWidgets();
                } else {
                    console.error(error);
                }
            }
        };

        renderPaymentWidgets();
    }, [widgets]);

    const handleCprBack = () => {
        navigate('/my/cp');
    }

    const onPayment = async () => {
        try {
            if (ready && widgets) {
                const orderId = generateRandomString();

                const newItem = {
                    point: totalPrice,
                    pointContent: '포인트충전',
                    pointState: '지급',
                    userEmail: user.userEmail,
                    userId: user.userId,
                    userNick: user.userNick
                }

                localStorage.setItem('pointInfo', JSON.stringify(newItem));

                await widgets.requestPayment({
                    orderId : orderId,
                    orderName: '포인트충전',
                    customerName: user.userName,
                    customerEmail: user.userEmail,
                    successUrl: `${window.location.origin}/point/payment/successcomplete`,
                    failUrl: `${window.location.origin}/payment/fail`,
                });
            }
            
        } catch (error) {
            console.error('Payment request error:', error);
            alert(error);
        }
    }

    const onPointRecharge = (e) => {
        const value = parseInt(e.target.value, 10);
        if (value > 0) {
            setPointInput(value);
        } else {
            alert("충전 금액은 0보다 커야 합니다.");
        }
    }

    return (
        <>
        {
            <div className={`${dailyduck.mycouponporint_container}`}>
                    <div className={dailyduck.mycouponporint_box}>
                        <div className={dailyduck.my_coupon_point_header}>
                            <div>
                                <button onClick={() => handleCprBack()}>
                                <span className="material-symbols-outlined">arrow_back_ios</span>
                                </button>
                            </div>
                            <div>
                                <div>
                                    <span>쿠폰·포인트</span>
                                </div>
                            </div>
                        </div>
                        <div className={dailyduck.my_point_recharge_content}>
                            <div className='px-3 bg-white'>
                                <div className={`py-3 ${dailyduck.my_point_charge_title}`}><span>충전 금액</span></div>
                                <div>
                                    <div className='pb-1'>
                                        <span>충전 금액</span>
                                    </div>
                                    <div>
                                        <input type="number" className='join_input_tag' placeholder='충전 금액(숫자만 입력)' onChange={(e) => onPointRecharge(e)} value={pointInput > 0 ? pointInput : ''}/>
                                    </div>
                                    <div className={`py-3 ${dailyduck.my_point_charge_btn} d-flex justify-content-around align-items-center`}>
                                        <button onClick={() => setPointInput(pointInput + 10000)}>+1만원</button>
                                        <button onClick={() => setPointInput(pointInput + 30000)}>+3만원</button>
                                        <button onClick={() => setPointInput(pointInput + 50000)}>+5만원</button>
                                        <button onClick={() => setPointInput(pointInput + 100000)}>+10만원</button>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-2 bg-white px-3'>
                                <div className='py-3'><span>충전 방법</span></div>
                                <div>
                                <div className="wrapper w-100">
                                    <div className="max-w-540 w-100">
                                        <div id="payment-method" className="w-100" />
                                        <div id="agreement" className="w-100" />
                                        <div className="btn-wrapper w-100">
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className='pt-3 px-3'>
                                <div className='py-1 dd-fs-7'><span>· 결제 안내사항입니다.</span></div>
                                <div className='py-1 dd-fs-7'><span>· 결제 전 주의사항 안내 내용입니다.</span></div>
                            </div>
                            <div className='payment_agreement py-3 px-3'>
                                <span className='pe-2'><input type="checkbox" id='agree' onChange={(e) => setIsChecked(e.target.checked)} /></span>
                                <label htmlFor='agree'>위 내용을 확인 하였으며 결제에 동의합니다.</label>
                            </div>
                        </div>
                        <div className='py-3 px-3'>
                            <button
                                className={`payment_btn ${isChecked ? 'payment_btn_active' : ''}`}
                                disabled={!isChecked}
                                onClick={onPayment}
                            >
                                충전하기
                            </button>
                        </div>
                    </div>
            </div>
        }
        </>
    )
}

export default MyPointRecharge;
