import React, { useContext, useEffect, useState, useRef } from 'react';
import seller from '../../../../seller.module.css';
import * as message from '../../../../../apis/message';
import * as sellerapi from '../../../../../apis/seller';
import { LoginContext } from '../../../../../contexts/LoginContextProvider';
import PaymentRequestPopup from './PaymentRequestPopup';

const SellerMessage = () => {
    const { userInfo } = useContext(LoginContext);
    const [messages, setMessages] = useState([]);
    const [shopInfo, setShopInfo] = useState({});
    const [userData, setUserData] = useState({});
    const [content, setContent] = useState('');
    const [chatData, setChatData] = useState([]);
    const [selectedRoomId, setSelectedRoomId] = useState(null);
    const [isScrolledUp, setIsScrolledUp] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState({});
    const messagesEndRef = useRef(null);
    const messageContainerRef = useRef(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [preview, setPreview] = useState(null);
    const [isPaymentPopupOpen, setIsPaymentPopupOpen] = useState(false);
    const [paymentRequest, setPaymentRequest] = useState(null);

    useEffect(() => {
        chatListSelect();
        const interval = setInterval(() => {
            chatListSelect();
        }, 1000);

        return () => clearInterval(interval);
    }, [selectedRoomId]);

    useEffect(() => {
        if (!isScrolledUp) {
            scrollToBottom();
        }
    }, [messages]);

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleScroll = () => {
        if (messageContainerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = messageContainerRef.current;
            if (scrollTop + clientHeight < scrollHeight - 50) {
                setIsScrolledUp(true);
            } else {
                setIsScrolledUp(false);
            }
        }
    };

    const chatListSelect = async () => {
        try {
            const response = await message.sellerMessageListSelect(userInfo && userInfo.userId);
            const data = response.data;
            // console.log('Data received: ', data);

            setChatData(data);

            if (selectedRoomId) {
                const selectedChat = data.find(chat => chat.roomId === selectedRoomId);
                if (selectedChat) {
                    setMessages(selectedChat.messageList || []);
                    setShopInfo(selectedChat.shopDTO || {});
                    setUserData(selectedChat.user || {});
                    if (selectedChat.productNumber) {
                        productSelect(selectedChat.productNumber);
                    } else {
                        setSelectedProduct({});
                    }
                }
            }
        } catch (error) {
            console.error('Error fetching chat list:', error);
        }
    }

    const onContent = (e) => {
        setContent(e.target.value);
    }

    const onSend = async () => {
        const roomId = selectedRoomId;
        const userId = userData.userId;
        const sellerId = userInfo?.userId;

        if (selectedFile) {
            const formData = new FormData();
            formData.append('file', selectedFile);
            formData.append('roomId', roomId);
            formData.append('userId', userId);
            formData.append('sellerId', sellerId);

            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            };

            try {
                const response = await message.sellerUploadFile(formData, config);
                const data = response.data;

                if (data === 'SUCCESS') {
                    chatListSelect();
                    setSelectedFile(null);
                    setPreview(null);
                }
            } catch (error) {
                console.error('File upload error:', error);
            }
        }

        if (content !== '') {
            const response = await message.sellerSendAdd(roomId, userId, sellerId, content);
            const data = response.data;

            if (data === 'SUCCESS') {
                chatListSelect();
                setContent('');
            }
        }

        if (paymentRequest) {
            // console.log(paymentRequest.serviceDescription);
            // console.log(paymentRequest.price);
            const response = await message.sellerPaymentSendAdd(roomId, userId, sellerId, paymentRequest.serviceDescription, paymentRequest.price);
            const data = response.data;

            if (data === 'SUCCESS') {
                chatListSelect();
                setPaymentRequest(null);
            }
        }
    };

    const handleRoomClick = async (roomId, messageList, shopDTO, user, productNumber) => {
        setSelectedRoomId(roomId);
        setMessages(messageList || []);
        setShopInfo(shopDTO || {});
        setUserData(user || {});
        roomStateUpdate(roomId);
        if (productNumber) {
            await productSelect(productNumber);
        } else {
            setSelectedProduct({});
        }
    };

    const roomStateUpdate = async (roomId) => {
        try {
            const response = await message.stateUpdate(roomId);
            const data = response.data;

            if (data === 'SUCCESS') {
                chatListSelect();
            }

        } catch (error) {
            console.error(error);
        }
    }

    const downloadFile = async (fileUrl, fileName) => {
        try {
            const response = await fetch(fileUrl);
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('File download error:', error);
        }
    };

    const renderMessageContent = (msg) => {
        if (msg.messageSend) {
            return msg.messageSend;
        } else if (msg.messageFile) {
            const fileUrl = `${process.env.REACT_APP_API_SERVER}/chat/download?file=${msg.messageFile}`;
            const fileType = msg.messageFile.split('.').pop().toLowerCase();
            const imageTypes = ['png', 'jpg', 'jpeg', 'gif', 'heic'];

            if (imageTypes.includes(fileType)) {
                return (
                    <>
                        <div className={`${seller.message_img_rander}`}>
                            <div><img src={`${process.env.REACT_APP_API_SERVER}/img?file=${msg.messageFile}`} alt="file preview" /></div>
                            <div><button onClick={() => downloadFile(fileUrl, msg.messageFile)}><span className="material-symbols-outlined">download</span></button></div>
                        </div>
                    </>
                );
            } else {
                return (
                    <>
                        <div className={`${seller.message_file_rander}`}>
                            <div><img src="../../img/seller/icon/default_file.png" alt="파일 아이콘" /></div>
                            <div><span>{msg.messageFile}</span></div>
                            <div><button onClick={() => downloadFile(fileUrl, msg.messageFile)}><span className="material-symbols-outlined">download</span></button></div>
                        </div>
                    </>
                );
            }
        } else if (msg.paymentMessage && msg.paymentPrice) {
            return (
                <div className={`${seller.payment_message}`}>
                    <div className={`${seller.payment_request_title} py-2`}>
                        <span>데일리덕 세이프결제를 요청했어요.</span>
                    </div>
                    {selectedProduct.productName && (
                        <div className={`${seller.product_info}`}>
                            <div><img src={selectedProduct.representativeImage} alt="Product" /></div>
                            <div className='ps-2'><span>{selectedProduct.productName}</span></div>
                        </div>
                    )}
                    <div className={`${seller.payment_request_service}`}>
                        <div><span>제공 결제 내용</span></div>
                        <div><span>{msg.paymentMessage}</span></div>
                    </div>
                    <div className={`${seller.payment_request_price}`}>
                        <div><span>금액</span></div>
                        <div><span>{msg.paymentPrice.toLocaleString()}원</span></div>
                    </div>
                        
                    <div className={`${seller.payment_request_btn}`}>
                        <button onClick={() => handlePaymentCancel(msg.messageNo)}>취소하기</button>
                        <button onClick={() => handlePaymentRequestAgain(msg.paymentMessage, msg.paymentPrice)}>다시 요청</button>
                    </div>
                </div>
            );
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setSelectedFile(file);

        const fileTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'image/heic'];
        if (file && fileTypes.includes(file.type)) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result);
            };
            reader.readAsDataURL(file);
        } else {
            setPreview(null);
        }
    };

    const removePreview = () => {
        setSelectedFile(null);
        setPreview(null);
    };

    const getUnreadCount = (messages) => {
        return messages.filter(message => !message.sellerReadingState).length;
    };

    const handleSendPaymentRequest = (request) => {
        setPaymentRequest(request);
    };

    const productSelect = async (productNumber) => {
        try {
            const response = await sellerapi.detailProductSelect(productNumber);
            const data = response.data;
            // console.log('Product data: ', data);
            setSelectedProduct(data);
        } catch (error) {
            console.error('Error fetching product details:', error);
        }
    };

    const handlePaymentRequestAgain = (serviceDescription, price) => {
        setPaymentRequest({ serviceDescription, price });
        onSend();
    };

    const handlePaymentCancel = async (messageNo) => {
        // console.log('Canceled messageNo:', messageNo);
        const response = await message.paymentCancelUpdate(messageNo);
        const data = response.data;

        // console.log(data);
    };

    const getProfileImageUrl = (url) => {
        if (!url) {
            return `${process.env.PUBLIC_URL}/img/my/defaultUser.png`; // url이 null 또는 undefined일 경우 빈 문자열을 반환
        }
    
        // 소셜 로그인 이미지: 'https://' 또는 'http://'가 포함되면서 외부 도메인일 경우
        if (url.startsWith('https://') || url.startsWith('http://')) {
            return url; // 소셜 로그인 이미지 URL 그대로 반환
        }
        
        // 직접 업로드된 이미지: '/root/app/images/' 혹은 서버 경로를 포함하는 경우
        return `${process.env.REACT_APP_API_SERVER}/img?file=${url}`;
    };

    return (
        <div className={`${seller.seller_dashboard_container} py-3 px-3`}>
            <div>
                <div className='pb-3 w-100 border-bottom fs-5'><span>메세지</span></div>
            </div>
            <div className={`${seller.seller_message_box}`}>
                <div className={`${seller.seller_message_list}`}>
                    {chatData.map((chat) => (
                        <div 
                            key={chat.roomId} 
                            className={`${seller.seller_message_list_box} ${selectedRoomId === chat.roomId ? seller.seller_message_list_box_active : ''}`}
                            onClick={() => handleRoomClick(chat.roomId, chat.messageList, chat.shopDTO, chat.user, chat.productNumber)}
                        >
                            <div>
                                <div><img src={chat.user && chat.user.userProfile ? getProfileImageUrl(chat.user.userProfile) : `${process.env.PUBLIC_URL}/img/my/defaultUser.png`} alt="" /></div>
                            </div>
                            <div>
                                <div>
                                    <div><span>{chat.user && chat.user.userNick ? chat.user.userNick : '닉네임 미설정'}</span></div>
                                    <div><span>{new Date(chat.roomRegDate).toLocaleTimeString()}</span></div>
                                </div>
                                <div>
                                    <div><span>{chat.messageList[chat.messageList.length - 1]?.messageSend}</span></div>
                                </div>
                            </div>
                            {
                                getUnreadCount(chat.messageList) === 0 ?
                                null
                                :
                                <div className={`${seller.seller_reading_count}`}><span>{getUnreadCount(chat.messageList)}</span></div>
                            }
                        </div>
                    ))}
                </div>
                <div 
                    className={`${seller.seller_message_content}`} 
                    ref={messageContainerRef} 
                    onScroll={handleScroll} // 스크롤 이벤트 처리
                >
                    {selectedRoomId ? (
                        messages.length > 0 ? (
                            <>
                                {messages.map((msg) => (
                                    <div key={msg.messageNo}>
                                        {msg.sendId === userInfo?.userId ? (
                                            <div className={`${seller.message_chat_sender_box}`}>
                                                <div className={`${seller.message_chat_sender}`}>
                                                    <div>
                                                        {renderMessageContent(msg)}
                                                    </div>
                                                </div>
                                                <div>
                                                    <img src={shopInfo.shopProfileImg} alt="셀러프로필" />
                                                </div>
                                            </div>
                                        ) : (
                                            <div className={`${seller.message_chat_recipient_box}`}>
                                                <div>
                                                    <img src={`${getProfileImageUrl(userData.userProfile)}`} alt="유저프로필" />
                                                </div>
                                                <div className={`${seller.message_chat_recipient}`}>
                                                    <div>
                                                        {renderMessageContent(msg)}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ))}
                                <div ref={messagesEndRef} />
                                {/* 이미지일 경우 미리보기 */}
                                {preview ? (
                                    <div className={`${seller.message_chat_preview}`}>
                                    <div className='position-relative'>
                                        <img src={preview} alt="Preview" style={{ maxWidth: '100%', maxHeight: '300px' }} />
                                        <button className={`${seller.message_chat_preview_cancel}`} onClick={removePreview}><span className="material-symbols-outlined">close</span></button>
                                    </div>
                                    </div>
                                ) : selectedFile ? (
                                    <div className={`${seller.message_chat_preview}`}>
                                    <div className='position-relative'>
                                        <div className={`${seller.message_chat_preview_file}`}>
                                        <div><img src="../../img/seller/icon/default_file.png" alt="파일 아이콘" /></div>
                                        <div><span>{selectedFile.name}</span></div>
                                        </div>
                                        <button className={`${seller.message_chat_preview_cancel}`} onClick={removePreview}><span className="material-symbols-outlined">close</span></button>
                                    </div>
                                    </div>
                                ) : null}

                                {/* 결제 요청 미리보기 */}
                                {paymentRequest && (
                                    <div className={`${seller.message_chat_payment_request}`}>
                                        <div className='position-relative'>
                                            <div className={`${seller.payment_request_title}`}>
                                                <span>데일리덕 세이프결제를 요청했어요.</span>
                                            </div>
                                            <div className={`${seller.payment_request_img}`}>
                                                <div><img src={selectedProduct.representativeImage} alt="" /></div>
                                                <div className='ps-2'>
                                                    <span>{selectedProduct.productName}</span>
                                                </div>
                                            </div>
                                            {/* <span>상품: {paymentRequest.selectedProduct}</span> */}
                                            <div className={`${seller.payment_request_service}`}>
                                                <div><span>제공 결제 내용</span></div>
                                                <div>{paymentRequest.serviceDescription}</div>
                                            </div>
                                            <div className={`${seller.payment_request_price}`}>
                                                <div><span>금액</span></div>
                                                <div><span>{paymentRequest.price.toLocaleString()}원</span></div>
                                            </div>
                                            <button className={`${seller.message_chat_preview_cancel}`} onClick={() => setPaymentRequest(null)}><span className="material-symbols-outlined">close</span></button>
                                        </div>
                                    </div>
                                )}
                            </>
                        ) : (
                            <div>메시지가 없습니다.</div>
                        )
                    ) : (
                        <div>채팅방을 선택해주세요.</div>
                    )}
                </div>
                {isScrolledUp && ( // 스크롤 상태에 따라 버튼 렌더링
                    <div className={`${seller.scroll_to_bottom_btn}`}>
                        <button onClick={scrollToBottom}><span className="material-symbols-outlined">expand_circle_down</span></button>
                    </div>
                )}
            </div>
            {selectedRoomId && (
                <div className={`${seller.seller_message_input}`}>
                    <div className={`${seller.seller_message_input_btn}`}>
                        <div>
                            {/* 이미지 / 파일 추가 버튼 */}
                            <input type="file" onChange={handleFileChange} style={{ display: 'none' }} id="file-upload" />
                            {/* <label htmlFor="file-upload"></label> */}
                            <button onClick={() => document.getElementById('file-upload').click()}>
                                <span className="material-symbols-outlined">upload</span>
                                <span className='ps-1'>파일등록</span>
                            </button>
                        </div>
                        <div className='ps-2'>
                            <button onClick={() => {setIsPaymentPopupOpen(true); productSelect(chatData.find(chat => chat.roomId === selectedRoomId)?.productNumber)}}>
                                <span className="material-symbols-outlined">credit_card</span>
                                <span className='ps-1'>결제요청보내기</span>
                            </button>
                        </div>
                    </div>
                    <div className={`${seller.seller_message_input_send}`}>
                        <div>
                            <input 
                                type="text" 
                                onChange={(e) => onContent(e)} 
                                value={content}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        onSend();
                                    }
                                }} 
                                disabled={!selectedRoomId} // 채팅방이 선택되지 않았을 때 입력 비활성화
                            />
                        </div>
                        <div><button onClick={onSend} disabled={!selectedRoomId}>전송</button></div>
                    </div>
                </div>
            )}
            {isPaymentPopupOpen && (
                <PaymentRequestPopup
                    onClose={() => setIsPaymentPopupOpen(false)} 
                    onSend={handleSendPaymentRequest} 
                    selectedProduct={selectedProduct} // 프롭스로 전달
                />
            )}
        </div>
    );
}

export default SellerMessage;
