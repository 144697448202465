import React, { useEffect, useRef, useState } from 'react';
import admin from '../../../admin.module.css';
import { Button, DatePicker, Input, Select, Pagination } from 'antd';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import * as adminApi from '../../../../apis/admin';
import { format } from 'date-fns';

const AdminSellerNotice = () => {
    const MySwal = withReactContent(Swal);
    const navigate = useNavigate();
    const { RangePicker } = DatePicker;
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10); // 페이지 당 항목 수
    const [noticeList, setNoticeList] = useState([]);
    const [filteredNotices, setFilteredNotices] = useState([]); // 필터링된 공지사항 리스트
    const [selectDate, setSelectDate] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const ref = useRef();

    useEffect(() => {
        // 모든 공지사항 데이터를 가져옵니다.
        const fetchNotices = async () => {
            const response = await adminApi.sellerNoticeSelect();
            const data = response.data;
            setNoticeList(data);
            setFilteredNotices(data); // 초기에는 모든 데이터를 보여줍니다.
        };
        fetchNotices();
    }, []);

    useEffect(() => {
        const inputs = ref.current.querySelectorAll('.ant-picker-input input');
        inputs.forEach(input => {
            input.setAttribute('readonly', 'readonly');
        });
    }, []);

    const KSTformatDate = (date) => {
        return format(new Date(date), 'yyyy-MM-dd HH:mm:ss');
    };

    const handleDateChange = (dates, dateStrings) => {
        setSelectDate(dateStrings);
        filterNotices(searchValue, dateStrings);
    };

    const onSearchValue = (e) => {
        const value = e.target.value;
        setSearchValue(value);
        filterNotices(value, selectDate);
    };

    const filterNotices = (title, dateRange) => {
        let filtered = noticeList;

        // 제목 필터링
        if (title) {
            filtered = filtered.filter(notice => notice.sellerNoticeTitle.includes(title));
        }

        // 날짜 필터링
        if (dateRange.length === 2) {
            const [startDate, endDate] = dateRange;
            filtered = filtered.filter(notice => {
                const noticeDate = new Date(notice.sellerNoticeRegDate);
                return noticeDate >= new Date(startDate) && noticeDate <= new Date(endDate);
            });
        }

        setFilteredNotices(filtered);
        setCurrentPage(1); // 필터링 후 첫 페이지로 이동
    };

    const paginate = pageNumber => setCurrentPage(pageNumber);

    // 현재 페이지에서 보여줄 항목들을 계산합니다.
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentNotices = filteredNotices.slice(indexOfFirstItem, indexOfLastItem);

    const onSearchClick = () => {
        filterNotices(searchValue, selectDate);
    };

    const onNoticeAdd = () => {
        navigate('/admin/seller/notice/add');
    };

    const onNoticeRead = (item) => {
        navigate(`/admin/seller/notice/${item.sellerNoticeNo}`, {state : {notice : item}});
    };

    const onNoticeDelete = (no) => {
        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html: 
            `
                <div>
                    <div class='${admin.admin_seller_add_title}'><span>게시글을 삭제 하시겠습니까?</span></div>
                    <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                      <button class='mx-1' id='seller_add_cancel'>취소</button>
                      <button class='mx-1' id='seller_add_success'>삭제</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });
        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);
        
        async function sellerAddSuccess(e) {
            if(e.target && e.target.id === 'seller_add_success') {
                // 데이터 삭제 로직 추가
                const response = await adminApi.sellerNoticeDelete(no);
                const data = response.data;

                if(data === 'SUCCESS') {
                    MySwal.fire({
                        customClass: {
                            popup: `${admin.admin_seller_add_popup}`
                        },
                        html : 
                        `
                        <div>
                        <div class='${admin.admin_seller_add_title}'><span>게시글 삭제가 완료 되었습니다.</span></div>
                        <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                        </div>
                        </div>
                        `,
                        showConfirmButton: false,
                        showCancelButton: false,
                    });
                    document.addEventListener('click', sellerAddConfirm);
                    document.removeEventListener('click', sellerAddSuccess);
                    
                    function sellerAddConfirm(e) {
                        if(e.target && e.target.id == 'seller_add_confirm') {
                            setNoticeList(prevList => prevList.filter(notice => notice.sellerNoticeNo !== no));
                            setFilteredNotices(prevList => prevList.filter(notice => notice.sellerNoticeNo !== no));
                            MySwal.close();
                            document.removeEventListener('click', sellerAddConfirm);
                            document.removeEventListener('click', sellerAddSuccess);
                            document.removeEventListener('click', sellerAddCancel);
                        }
                    }
                } else {
                    MySwal.fire({
                        customClass: {
                            popup: `${admin.admin_seller_add_popup}`
                        },
                        html : 
                        `
                        <div>
                        <div class='${admin.admin_seller_add_title}'><span>게시글 삭제에 실패하였습니다.</span></div>
                        <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                        </div>
                        </div>
                        `,
                        showConfirmButton: false,
                        showCancelButton: false,
                    });
                    document.addEventListener('click', sellerAddConfirm);
                    document.removeEventListener('click', sellerAddSuccess);
                    
                    function sellerAddConfirm(e) {
                        if(e.target && e.target.id == 'seller_add_confirm') {
                            MySwal.close();
                            document.removeEventListener('click', sellerAddConfirm);
                            document.removeEventListener('click', sellerAddSuccess);
                            document.removeEventListener('click', sellerAddCancel);
                        }
                    }
                }
            }
        }
        
        function sellerAddCancel(e) {
            if(e.target && e.target.id === 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
            }
        }
    };

    return (
        <div className={`${admin.admin_seller_notice_box} px-3 py-3`}>
            <div>
                <div className={`${admin.admin_seller_notice_header}`}>
                    <div><span>셀러 공지사항</span></div>
                </div>
                <div className={`${admin.admin_seller_notice_search_box} py-3`}>
                    <div className={`${admin.admin_seller_notice_search_input}`}>
                        <div className={`ps-1 ${admin.admin_seller_notice_search_input_txt}`}>
                            <input 
                                type="text" 
                                value={searchValue}
                                onChange={onSearchValue}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        onSearchClick();
                                    }
                                }} 
                                placeholder='제목으로 검색해주세요.'
                            />
                        </div>
                        <div className={`ps-1 ${admin.admin_seller_notice_search_btn}`}>
                            <button onClick={onSearchClick}>검색</button>
                        </div>
                    </div>
                    <div className={`${admin.admin_seller_notice_search_date}`}>
                        <div ref={ref}>
                            <RangePicker className={`${admin.admin_seller_notice_datepicker}`}
                                separator="~"
                                placeholder={['0000.00.00', '0000.00.00']}
                                onChange={handleDateChange}
                            />
                        </div>
                    </div>
                </div>
                <div className={`${admin.admin_seller_notice_add_box}`}>
                    <div className='d-flex align-items-end'>
                        <span>전체</span>
                        <span className='ps-2 dd-fs-6'>{filteredNotices.length}</span>
                        <span>건</span>
                    </div>
                    <div><button onClick={onNoticeAdd}>공지사항 등록</button></div>
                </div>
                <div>
                    <div className={`${admin.admin_seller_notice_table_header} border-bottom py-3`}>
                        <div><span>번호</span></div>
                        <div><span>제목</span></div>
                        <div><span>작성일자</span></div>
                        <div><span></span></div>
                    </div>
                    {
                        currentNotices.length > 0 ?
                        currentNotices.map((item, index) => (
                            <div className={`${admin.admin_seller_notice_table_section} py-2 border-bottom`} onClick={() => onNoticeRead(item)} key={index}>
                                <div><span>{item.sellerNoticeNo}</span></div>
                                <div><span>{item.sellerNoticeTitle}</span></div>
                                <div><span>{KSTformatDate(item.sellerNoticeRegDate)}</span></div>
                                <div><button onClick={(e) => {e.stopPropagation(); onNoticeDelete(item.sellerNoticeNo)}}>삭제</button></div>
                            </div>
                        ))
                        : 
                        <div className={`${admin.admin_seller_notice_table_no_data}`}><span>등록된 셀러 공지사항이 없습니다.</span></div>
                    }
                </div>
                <Pagination 
                    current={currentPage} 
                    total={filteredNotices.length} 
                    onChange={paginate} 
                    pageSize={itemsPerPage} 
                    showSizeChanger={false}
                />
            </div>
        </div>
    );
}

export default AdminSellerNotice;
