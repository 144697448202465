import React, { useEffect, useState } from 'react'
import seller from '../../seller.module.css';
import SellerHeader from '../../components/header/SellerHeader';
import StoreSellerSide from '../../components/side/StoreSellerSide';
import SellerDashBoardMain from '../../components/section/storedashboard/SellerDashBoardMain';
import SellerBoardMain from '../../components/section/storedashboard/board/SellerBoardMain';
import SellerSettlementMain from '../../components/section/storedashboard/settlement/SellerSettlementMain';
import SellerReviewMain from '../../components/section/storedashboard/review/SellerReviewMain';
import SellerStoreMain from '../../components/section/storedashboard/store/SellerStoreMain';
import SellerSalesMain from '../../components/section/storedashboard/sales/SellerSalesMain';
import SellerEditMain from '../../components/section/storedashboard/edit/SellerEditMain';
import SellerProductMain from '../../components/section/storedashboard/product/SellerProductMain';
import SellerOrderMain from '../../components/section/storedashboard/order/SellerOrderMain';
import SellerCancelMain from '../../components/section/storedashboard/cancel/SellerCancelMain';
import SellerReturnMain from '../../components/section/storedashboard/return/SellerReturnMain';
import SellerSwapMain from '../../components/section/storedashboard/swap/SellerSwapMain';
import SellerFaqMain from '../../components/section/storedashboard/board/SellerFaqMain';
import SellerCaculatorMain from '../../components/section/storedashboard/settlement/SellerCaculatorMain';
import SellerReviewModal from '../../components/section/storedashboard/review/SellerReviewModal';
import Swal from 'sweetalert2';
import SellerStoreSettleinfo from '../../components/section/storedashboard/store/SellerStoreSettleinfo';
import SellerStoreBusinessInfo from '../../components/section/storedashboard/store/SellerStoreBusinessInfo';

const SellerStoreDashBoard = () => {
    // 선택된 메뉴의 상태를 관리하기 위한 state
    const [ activeMenu, setActiveMenu ] = useState('dashboard');
    const [ showDashBoard, setShowDashBoard ] = useState(false);
    const [ showBoard, setShowBoard ] = useState(false);
    const [ showSettlement, setShowSettlement ] = useState(false);
    const [ showReview, setShowReview ] = useState(false);
    const [ showStore, setShowStore ] = useState(false);
    const [ showSales, setShowSales ] = useState(false);
    const [ showEdit, setShowEdit ] = useState(false);
    const [ showProduct, setShowProduct ] = useState(false);
    const [ showOrder, setShowOrder ] = useState(false);
    const [ showCancel, setShowCancel ] = useState(false);
    const [ showReturn, setShowReturn ] = useState(false);
    const [ showSwap, setShowSwap ] = useState(false);

    const [ activeBoard, setActiveBoard ] = useState('board');
    const [ activeSettlement, setActiveSettlement ] = useState('proceeds');
    const [ activeShopManagement, setActiveShopManagement] = useState('profile');

    const [ showReviewModal, setShowReviewModal ] = useState(false);

    // 메뉴 버튼을 클릭했을 때 호출될 함수
    const handleMenuClick = (menuName) => {
        setActiveMenu(menuName);
    };

    const onReviewClick = (no) => {
        // console.log("no : " + no);
        setShowReviewModal(true);
    }
    const changeActiveBoard = (newBoard) => {
        setActiveBoard(newBoard); // 하위 메뉴 상태 변경
        setActiveMenu('board'); // 상위 메뉴 상태도 'board'로 변경
    }
    const changeActiveSettlement = (newSettlement) => {
        setActiveSettlement(newSettlement); // 하위 메뉴 상태 변경
        setActiveMenu('settlement'); // 상위 메뉴 상태도 'settlement'로 변경
    }
    const changeActiveShopManagement = (newShopMangement) => {
        setActiveShopManagement(newShopMangement);
        setActiveMenu('store');
    }

    const [ reviewAnswerCheck, setReviewAnswerCheck ] = useState(true);
    const [ reviewAnswer, setReviewAnswer ] = useState('');

    const onReviewAnswer = (e) => {
        // console.log(e.target.value);
        setReviewAnswer(e.target.value);
    }

    useEffect(()=> {
        if(reviewAnswer != '') {
            setReviewAnswerCheck(false);
        } else {
            setReviewAnswerCheck(true);
        }
    }, [reviewAnswer]);

    const handleReviewWrite = () => {
        Swal.fire({
            title: '리뷰 답변 수정 완료',
            html: '<p>리뷰 내용 답변 수정이 완료되어 저장되었습니다.</p>',
            showCancelButton: false,
            confirmButtonText: '확인',
            focusConfirm: false,
            customClass: {
                confirmButton: `${seller.success_seller_review_btn}`,
                title: `${seller.success_seller_review_title}`,
                htmlContainer: `${seller.success_seller_review_text}`,
                actions: `${seller.success_seller_review_actions}`,
                popup: `${seller.success_seller_review_popup}`
            }
        }).then((result) => {
            if (result.isConfirmed) {
                setShowReviewModal(false);
            }
        });
    }

    return (
        <>
            <div className={`${seller.seller_container}`}>
                <SellerHeader seller={seller} />
                {
                    showReviewModal &&
                    <SellerReviewModal seller={seller} setShowReviewModal={setShowReviewModal} onReviewAnswer={onReviewAnswer} reviewAnswerCheck={reviewAnswerCheck} handleReviewWrite={handleReviewWrite}/>
                }
                <div className='d-flex'>
                    <StoreSellerSide seller={seller} activeMenu={activeMenu} handleMenuClick={handleMenuClick} activeBoard={activeBoard} setActiveBoard={setActiveBoard} 
                                    activeSettlement={activeSettlement} setActiveSettlement={setActiveSettlement} changeActiveSettlement={changeActiveSettlement} changeActiveBoard={changeActiveBoard}
                                    showReviewModal={showReviewModal} activeShopManagement={activeShopManagement} setActiveShopManagement={setActiveShopManagement} changeActiveShopManagement={changeActiveShopManagement}
                    />
                    {
                        activeMenu == 'dashboard' && 
                        <SellerDashBoardMain seller={seller} activeMenu={activeMenu}/>
                    }
                    {
                        activeMenu == 'board' && (
                            <>
                                {activeBoard == 'board' &&
                                    <SellerBoardMain seller={seller} activeBoard={activeBoard} setActiveBoard={setActiveBoard} />
                                }
                                {activeBoard == 'faq' &&
                                    <SellerFaqMain seller={seller} activeBoard={activeBoard} setActiveBoard={setActiveBoard} />
                                }
                            </>
                        )
                    }
                    {
                        activeMenu == 'settlement' && (
                            <>
                                {activeSettlement == 'proceeds' &&
                                    <SellerSettlementMain seller={seller} />
                                }
                                {activeSettlement == 'calculate' &&
                                    <SellerCaculatorMain seller={seller} />
                                }
                            </>
                        )
                    }
                    {
                        activeMenu == 'review' &&
                        <SellerReviewMain seller={seller} onReviewClick={onReviewClick} />
                    }
                    {
                        activeMenu == 'store' &&
                        <>
                            {
                                activeShopManagement == 'profile' && 
                                <SellerStoreMain seller={seller} />
                            }
                            {
                                activeShopManagement == 'settlementinfo' && 
                                <SellerStoreSettleinfo seller={seller} />
                            }
                            {
                                activeShopManagement == 'businessinfo' && 
                                <SellerStoreBusinessInfo seller={seller} />
                            }
                        </>
                    }
                    {
                        activeMenu == 'sales' &&
                        <SellerSalesMain seller={seller} />
                    }
                    {
                        activeMenu == 'edit' &&
                        <SellerEditMain seller={seller} />
                    }
                    {
                        activeMenu == 'product' &&
                        <SellerProductMain seller={seller} />
                    }
                    {
                        activeMenu == 'order' &&
                        <SellerOrderMain seller={seller} />
                    }
                    {
                        activeMenu == 'cancel' &&
                        <SellerCancelMain seller={seller} />
                    }
                    {
                        activeMenu == 'return' &&
                        <SellerReturnMain seller={seller} />
                    }
                    {
                        activeMenu == 'swap' &&
                        <SellerSwapMain seller={seller} />
                    }

                </div>
            </div>
        </>
    )
}

export default SellerStoreDashBoard