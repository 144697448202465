import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as categorys from '../../../apis/category.js';
import * as storeapi from '../../../apis/store.js';
import * as auth from '../../../apis/auth.js';
import Filter from '../../../components/market/store/Filter.jsx';
import StoreCategory from '../../../components/market/store/StoreCategory.jsx';
import StoreRental from '../../../components/market/store/StoreRental.jsx';
import StoreHeader from '../../../components/market/store/header/StoreHeader.jsx';
import store from './store.module.css';
import DailyduckInNavigator from '../../../components/footer/DailyduckInNavigator.jsx';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import { LoginContext } from '../../../contexts/LoginContextProvider.jsx';

const MarketStore = () => {
  const { userInfo } = useContext(LoginContext);
  const location = useLocation();
  const activeCategory = location.state?.activeCategory;
  const [activeFilter, setActiveFilter] = useState('total');
  const { category } = useParams();
  const [storeData, setStoreData] = useState([]);
  const [filteredStoreData, setFilteredStoreData] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [isHeartClicked, setIsHeartClicked] = useState(false);
  const [subCategory, setSubCategory] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState([]);
  const [activeSubCategory, setActiveSubCategory] = useState('all');
  const [favorityList, setFavorityList] = useState([]);
  const [showFavorityOnly, setShowFavorityOnly] = useState(false);
  const [selectedFavority, setSelectedFavority] = useState(null);
  const [showRentalSortList, setShowRentalSortList] = useState(false);
  const [activeSort, setActiveSort] = useState('sort');

  const [ loading, setLoading ] = useState(false);

  const rentalProductListRef = useRef(null);

  const navigate = useNavigate();
  const backNavigate = () => {
    navigate('/market'); 
  };

  useEffect(() => {
    categorySelect();
    storeSubCategorySelect();
    getCategory();

    setActiveSubCategory('all');
  }, [category]);

  useEffect(() => {
    if (userInfo?.userId) {
      userFanFavoritySelect();
    }
  }, [userInfo]);

  useEffect(() => {
    // '전체' 카테고리를 추가하여 첫 번째 요소로 추가
    const allCategory = {
      storeSubCategoryEngName: 'all',
      storeSubCategoryKorName: '전체'
    };
    
    // subCategory가 배열인지 확인하고, 그렇지 않으면 빈 배열을 사용
    const filteredSubCategories = [allCategory, ...(Array.isArray(subCategory) ? subCategory.filter(sub => sub.storeMainCategoryEngName === category) : [])];
    
    console.log('filteredSubCategories : ', filteredSubCategories);
  
    setSelectedSubCategory(filteredSubCategories);
  
  }, [subCategory, category]);

    // 서브 카테고리가 변경될 때 스크롤을 맨 위로 이동
    useEffect(() => {
      if (rentalProductListRef.current) {
        rentalProductListRef.current.scrollTop = 0;
      }
    }, [activeSubCategory]);
  

  useEffect(() => {
    filterStoreData();
  }, [storeData, activeSubCategory, showFavorityOnly, selectedFavority]);

  useEffect(() => {
    console.log('showFavorityOnly : ', showFavorityOnly);
    if(showFavorityOnly) {
      setSelectedFavority('');
    } else {
      setSelectedFavority(null);
    }
  }, [showFavorityOnly])

  const categorySelect = async () => {

    const response = await categorys.storeCategory();
    const data = response.data;

    if(response.status === 200) {
      setCategoryList(data);
    }
  };

  const getCategory = async () => {
    setLoading(true);
    
    const response = await storeapi.userProductSelect(category);
    const data = response.data;
    
    if(response.status === 200) {
      setLoading(false);
      setStoreData(shuffleArray(data));
    }
  };

  const storeSubCategorySelect = async () => {
    const response = await categorys.storeSubCategorySelect();
    const data = response.data;
    
    console.log('storeSubCategorySelect : ', data);
    setSubCategory(data);
  }

  const filterStoreData = () => {
    let data = storeData;

    if (showFavorityOnly) {
      data = data.filter(item =>
        favorityList.some(favority =>
          item.productName.includes(favority.favorityName) || 
          (item.favority && item.favority.includes(favority.favorityName))
        )
      );
    }

    if (selectedFavority) {
      data = data.filter(item => 
        item.productName.includes(selectedFavority) ||
        (item.favority && item.favority.includes(selectedFavority))
      );
    }

    // 전체(subCategory가 'all')일 때 모든 데이터를 보여줍니다.
    if (activeSubCategory !== 'all') {
      data = data.filter(item => item.subCategory === activeSubCategory);
    }

    if (activeSubCategory === 'all') {
      data = data.filter(item => 
        item.saleState === '판매중' && 
        item.enabledState === '승인완료'
      );
    } else {
      // 특정 카테고리일 경우 상태, 판매 상태, 그리고 subCategory를 기준으로 필터링
      data = data.filter(item => 
        item.saleState === '판매중' && 
        item.enabledState === '승인완료' &&
        item.subCategory === activeSubCategory
      );
    }

    setFilteredStoreData(shuffleArray(data));
  };

  const userFanFavoritySelect = async () => {
    try {
      const response = await auth.favorityList(userInfo?.userId);
      const data = response.data;
      setFavorityList(data || []);
    } catch (error) {
      console.error(error);
    }
  };

  const handleHeartClick = () => {
    setIsHeartClicked(!isHeartClicked);
  };

  const onRentalSortData = (e) => {
    const sortType = e.target.id;
    setActiveSort(sortType);
  
    let sortedData = [...filteredStoreData];
  
    switch (sortType) {
      case 'latest':
        sortedData.sort((a, b) => new Date(b.regDate) - new Date(a.regDate));
        break;
      case 'sales':
        sortedData.sort((a, b) => b.saleCount - a.saleCount);
        break;
      case 'review':
        sortedData.sort((a, b) => b.reviewCount - a.reviewCount);
        break;
      case 'low':
        sortedData.sort(
          (a, b) =>
            (a.productsalePrice ?? a.productPrice) - (b.productsalePrice ?? b.productPrice)
        );
        break;
      case 'high':
        sortedData.sort(
          (a, b) =>
            (b.productsalePrice ?? b.productPrice) - (a.productsalePrice ?? a.productPrice)
        );
        break;
      default:
        break;
    }
  
    setFilteredStoreData(sortedData);
    setShowRentalSortList(false);
  };

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  return (
    <>
      <div className={`${store.store_container}`}>
        <StoreHeader activeCategory={activeCategory} backNavigate={backNavigate} store={store} />
        <StoreCategory category={category} categoryList={categoryList} store={store} />
        {
          Array.isArray(selectedSubCategory) && selectedSubCategory.length !== 0 && (
          <div className={`${store.store_sub_category_box}`}>
            <Swiper
                slidesPerView="auto"
                freeMode={true}
                spaceBetween={10}
                className={`${store.store_sub_category_swiper}`}
            >
                {selectedSubCategory.map((item, index) => (
                    <SwiperSlide key={index}>
                        <div className={`${store.store_sub_category}`}>
                            <button
                                className={`${activeSubCategory === item.storeSubCategoryEngName ? store.store_sub_category_active : ''}`}
                                onClick={() => setActiveSubCategory(item.storeSubCategoryEngName)}
                            >
                                {item.storeSubCategoryKorName}
                            </button>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
          </div>
          )
        }
        <Filter store={store} setActiveFilter={setActiveFilter} activeFilter={activeFilter} />
        {
            loading ?
            <div className={`${store.store_product_detail_loading}`}>
                <img src={`${process.env.PUBLIC_URL}/img/kwangduck_loading.gif`} alt="" />
            </div>
            :
            <StoreRental 
              store={store} 
              category={category} 
              isHeartClicked={isHeartClicked} 
              onHeartClick={handleHeartClick} 
              storeData={filteredStoreData} 
              favorityList={favorityList} 
              showFavorityOnly={showFavorityOnly}
              setShowFavorityOnly={setShowFavorityOnly}
              selectedFavority={selectedFavority}
              setSelectedFavority={setSelectedFavority}
              onRentalSort={() => setShowRentalSortList(true)}
              activeSort={activeSort}
              userInfo={userInfo}
              rentalProductListRef={rentalProductListRef}
            />
        }
        <DailyduckInNavigator />
      </div>
      {
        showRentalSortList &&
        <div className={`${store.store_rental_product_sort_modal_container}`}>
          <div className={`${store.store_rental_product_sort_modal_box}`}>
            <div className={`${store.store_rental_product_sort_modal_header}`}>
              <div><button onClick={() => setShowRentalSortList(false)}><span className="material-symbols-outlined">close</span></button></div>
            </div>
            <div className={`${store.store_rental_product_sort_modal_content}`}>
                {['latest', 'sales', 'review', 'low', 'high'].map(type => (
                  <div key={type}>
                    <div>
                      <input 
                        type="radio" 
                        name='rentalSort' 
                        id={type} 
                        onChange={onRentalSortData} 
                        checked={activeSort === type}
                      />
                    </div>
                    <div><label htmlFor={type}>{{
                      latest: '최신순',
                      sales: '판매순',
                      review: '리뷰많은순',
                      low: '낮은가격순',
                      high: '높은가격순'
                    }[type]}</label></div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default MarketStore;
