import React, { useEffect, useState } from 'react';
import admin from '../../../admin.module.css';
import { Pagination } from 'antd';
import * as adminApi from '../../../../apis/admin';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination as SwiperPagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const AdminParticipant = () => {
    const [eventList, setEventList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5);
    const [totalItems, setTotalItems] = useState(0);
    const [eventInfo, setEventInfo] = useState({});
    const [ showParticipantModal, setShowParticipantModal ] = useState(false);
    const [ participantList, setParticipantList ] = useState([]);
    const [ listImage, setListImage ] = useState([]);

    useEffect(() => {
        eventSelect();
    }, []);

    const eventSelect = async () => {
        const response = await adminApi.eventSelect();
        const data = response.data;

        // console.log('eventData : ', data);

        const luckedList = data.filter(event => event.eventType === 'lucked');

        // console.log('lucked : ', luckedList);

        let newData = luckedList.map(item => ({
            eventNumber: item.eventNumber,
            eventTitle: item.eventTitle,
            eventStartDate: item.eventStartDate,
            eventEndDate: item.eventEndDate,
            eventContent: item.eventContent,
            eventImage1: item.eventImage1,
            eventImage2: item.eventImage2,
            eventImage3: item.eventImage3,
            eventImage4: item.eventImage4,
            eventImage5: item.eventImage5,
            eventNo: item.eventNo,
            eventPrice: item.eventPrice,
            eventRegDate: item.eventRegDate,
            eventSubTitle: item.eventSubTitle,
            eventType: item.eventType,
            eventUpdDate: item.eventUpdDate,
            eventWinnerDate: item.eventWinnerDate,
            planProductRate: item.planProductRate,
            planProductSelect: item.planProductSelect,
            planType: item.planType,
            selectProduct: item.selectProduct,
            userId: item.userId,
            eventParticipant: 0 // 초기값 설정
        }));

        const eventNumbers = newData.map(item => item.eventNumber);
        const response2 = await adminApi.participantSelect(eventNumbers);
        const data2 = response2.data;

        // console.log('participant : ', data2);

        newData = newData.map((item, index) => ({
            ...item,
            eventParticipant: data2[index]
        }));

        // console.log(newData);

        setEventList(newData);
        setTotalItems(newData.length);
    };

    const formatDate = (dateString) => {
        return dateString.replace('T', ' ').substring(0, 19);
    };

    const paginate = pageNumber => setCurrentPage(pageNumber);

    const displayedEvents = eventList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const onEventSelect = async (item) => {
        setEventInfo(item);
        setShowParticipantModal(true);
        
        const response = await adminApi.eventParticipantSelect(item.eventNumber);
        const data = response.data;

        // console.log('list : ', data);
        setParticipantList(data);
    }

    useEffect(() => {
        // console.log('eventInfo : ', eventInfo);
        if (eventInfo) {
            const images = [
                eventInfo.eventImage1,
                eventInfo.eventImage2,
                eventInfo.eventImage3,
                eventInfo.eventImage4,
                eventInfo.eventImage5,
            ].filter(image => image !== null && image !== '');

            setListImage(images);
        }
    }, [eventInfo])

    return (
        <div className={`${admin.admin_event_winner_box} px-3 py-3`}>
            <div>
                <div className={`${admin.admin_event_participants_title}`}><span>참여자 목록</span></div>
                <div className={`py-3 ${admin.admin_event_winner_add_btn}`}>
                    {/* <div><button>삭제</button></div> */}
                </div>
                <div className={`${admin.admin_event_participants_container}`}>
                    <div>
                        <div className={`${admin.admin_event_participants_header}`}>
                            <div><input type="checkbox" name="" id="" /></div>
                            <div><span>번호</span></div>
                            <div><span>이미지</span></div>
                            <div><span>이벤트 제목</span></div>
                            <div><span>이벤트 기간</span></div>
                            <div><span>당첨자 발표일</span></div>
                            <div><span>참여자 수</span></div>
                        </div>

                        {
                            displayedEvents.map((item, index) => (
                                <div className={`${admin.admin_event_participants_content}`} key={index} onClick={() => onEventSelect(item)}>
                                    <div><input type="checkbox" name="" id="" /></div>
                                    <div><span>{(currentPage - 1) * itemsPerPage + index + 1}</span></div>
                                    <div><img src={item.eventImage1} alt="" /></div>
                                    <div><span>{item.eventTitle}</span></div>
                                    <div>
                                        <div><span>{formatDate(item.eventStartDate)}</span></div>
                                        <div><span>~</span></div>
                                        <div><span>{formatDate(item.eventEndDate)}</span></div>
                                    </div>
                                    <div><span>{formatDate(item.eventWinnerDate)}</span></div>
                                    <div><span>{item.eventParticipant}</span></div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <Pagination
                    current={currentPage}
                    total={totalItems}
                    onChange={paginate}
                    pageSize={itemsPerPage}
                    showSizeChanger={false}
                />
            </div>

            {
                showParticipantModal &&
                <div className={`${admin.admin_participants_modal_container}`}>
                    <div className={`${admin.admin_participants_modal_box}`}>
                        <div className={`${admin.admin_participants_modal_header}`}>
                            <div><span>이벤트 참여자 목록</span></div>
                            <div><button onClick={() => setShowParticipantModal(false)}><span class="material-symbols-outlined">close</span></button></div>
                        </div>
                        <div className={`${admin.admin_participants_modal_event_box}`}>
                            <div className={`${admin.admin_participants_modal_event_info}`}>
                                <div>
                                    <Swiper
                                        slidesPerView={1}
                                        spaceBetween={30}
                                        loop={true} // 루프 활성화
                                        autoplay={{
                                            delay: 2000, // 2초마다 자동 재생
                                            disableOnInteraction: false, // 사용자의 스와이프 후에도 자동 재생을 계속 작동하도록 설정
                                        }}
                                        modules={[SwiperPagination, Navigation, Autoplay]} // Autoplay 모듈 추가
                                        className={`${admin.admin_participants_swiper}`}
                                        >
                                        {
                                            listImage.map((item, index) => (
                                            <SwiperSlide key={index}><img src={item} alt="" /></SwiperSlide>
                                            ))
                                        }
                                    </Swiper>
                                </div>
                                <div className='px-2'>
                                    <div className={`${admin.admin_participants_modal_event_detail}`}>
                                        <div><span>이벤트 제목</span></div>
                                        <div><span>{eventInfo.eventTitle}</span></div>
                                    </div>
                                    <div className={`${admin.admin_participants_modal_event_detail}`}>
                                        <div><span>이벤트 금액</span></div>
                                        <div><span>{eventInfo.eventPrice}</span></div>
                                    </div>
                                    <div className={`${admin.admin_participants_modal_event_detail}`}>
                                        <div><span>이벤트 기간</span></div>
                                        <div><span>{formatDate(eventInfo.eventStartDate)}</span><span>&nbsp;~&nbsp;</span><span>{formatDate(eventInfo.eventEndDate)}</span></div>
                                    </div>
                                    <div className={`${admin.admin_participants_modal_event_detail}`}>
                                        <div><span>당첨자 발표일</span></div>
                                        <div><span>{formatDate(eventInfo.eventWinnerDate)}</span></div>
                                    </div>
                                    <div className={`${admin.admin_participants_modal_event_detail_content}`}>
                                        <div><span>이벤트 내용</span></div>
                                        <div dangerouslySetInnerHTML={{ __html: eventInfo.eventContent}}></div>
                                    </div>
                                    <div className={`${admin.admin_participants_modal_event_detail_btn}`}><button>상세보기</button></div>
                                </div>
                            </div>
                            <div className={`${admin.admin_participant_list}`}>
                                <div><span>참여인원 </span><span>{participantList.length}명</span></div>
                                {
                                    participantList.length > 0 ?
                                    <div>
                                        <div>
                                            <div className={`${admin.admin_participant_list_header}`}>
                                                <div><span>참여순서</span></div>
                                                <div><span>아이디</span></div>
                                                <div><span>참여이벤트</span></div>
                                                <div><span>결제금액</span></div>
                                                <div><span>결제방식</span></div>
                                                <div><span>배송정보</span></div>
                                                <div><span>참여시간</span></div>
                                            </div>
                                            {
                                                participantList.map((item, index) => (
                                                    <div className={`${admin.admin_participant_list_content}`}>
                                                        <div><span>{index+1}</span></div>
                                                        <div><span>{item.userId}</span></div>
                                                        <div><span>{item.paymentProductName}</span></div>
                                                        <div><span>{item.amount}</span></div>
                                                        <div><span>{item.paymentType}</span></div>
                                                        <div>
                                                            <div><span>{item.deliveryUser}</span></div>
                                                            <div><span>{item.deliveryPhone}</span></div>
                                                            <div><span>{item.deliveryAddress}</span></div>
                                                        </div>
                                                        <div><span>{formatDate(item.epRegDate)}</span></div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    :
                                    <div className={`${admin.admin_participant_list_no_data}`}><span>참여자가 없습니다.</span></div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default AdminParticipant;
