import React from 'react';
import DailyduckNavigator from '../../components/footer/Dailyduck_Navigator';
import DailyduckHeader from '../../components/header/Dailyduck_Header';
import MessageComponent from '../../components/message/MessageComponent';
import message from './message.module.css';

const MessageContainer = () => {
  return (
    <>
      <DailyduckHeader />
      <MessageComponent message={message}/>
      <DailyduckNavigator />
    </>
  )
}

export default MessageContainer