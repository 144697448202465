import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import styled from "styled-components";

export const StyledCalendarWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  .react-calendar {
    width: 100%;
    border: none;
    border-radius: 0.5rem;
    box-shadow: 4px 2px 10px 0px rgba(0, 0, 0, 0.13);
    padding: 3% 5%;
    background-color: white;
  }

  /* 달력 기본 */
  .react-calendar {
    box-shadow: none !important;
    height: 100% !important;
  }
  /* 달력 높이 */
  .react-calendar__tile.react-calendar__month-view__days__day {
    // height: 85px !important;
    aspect-ratio : 100 / 100;
    border: none !important;
  }
  /* 일 가운데 정렬*/
  .react-calendar__month-view__days abbr {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0;
  }
  // .react-calendar__tile--now > abbr {
  //   width: 100%;
  //   border: 1px solid rgba(255, 114, 153, 1);
  //   background-color: inherit;
  //   color: rgba(255, 114, 153, 1) !important;
  // }
  .react-calendar__tile--now {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    background-color: transparent !important; /* 선택된 날짜의 배경을 투명하게 */
    border-radius: 50%; /* 원형으로 유지 */
    padding: 0 !important; /* padding을 제거하여 원이 커지는 현상 방지 */
  }
  
  .react-calendar__tile--now > abbr {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 75% !important;
    height: 75% !important;
    border: 2px solid rgba(255, 114, 153, 1);
    background-color: inherit;
    color: rgba(255, 114, 153, 1) !important;
  }

  .react-calendar__month-view__weekdays__weekday {
    border: none !important;
  }

  /* 전체 폰트 컬러 */
  .react-calendar__month-view {
    abbr {
      color: ${(props) => props.theme.gray_1};
    }
  }

  /* 네비게이션 가운데 정렬 */
  .react-calendar__navigation {
    width: 100% !important;
    justify-content: space-between;
  }

  /* 네비게이션 폰트 설정 */
  .react-calendar__navigation button {
    font-weight: 800;
    font-size: 1rem;
  }

  /* 네비게이션 버튼 컬러 */
  .react-calendar__navigation button:focus {
    background-color: white;
  }

  /* 네비게이션 비활성화 됐을때 스타일 */
  .react-calendar__navigation button:disabled {
    background-color: white;
    color: ${(props) => props.theme.darkBlack};
  }

  /* 년/월 상단 네비게이션 칸 크기 줄이기 */
  .react-calendar__navigation__label {
    flex-grow: 0 !important;
  }

  /* 요일 밑줄 제거 */
  .react-calendar__month-view__weekdays abbr {
    text-decoration: none;
    font-weight: 800;
  }

  /* 일요일에만 빨간 폰트 */
  .react-calendar__month-view__weekdays__weekday--weekend abbr[title="일요일"] {
      color: red;
    }
    .react-calendar__month-view__weekdays__weekday--weekend abbr[title="토요일"] {
        color: blue;
    }

  /* 오늘 날짜 폰트 컬러 */
  .react-calendar__tile--now {
    background: none;
    abbr {
      color: white;
    }
  }

  /* 네비게이션 월 스타일 적용 */
  .react-calendar__year-view__months__month {
    border-radius: 0.8rem;
    background-color: ${(props) => props.theme.gray_5};
    padding: 0;
  }

  /* 네비게이션 현재 월 스타일 적용 */
  .react-calendar__tile--hasActive {
    background-color: ${(props) => props.theme.primary_2};
    abbr {
      color: white;
    }
  }

  /* 일 날짜 간격 */
  .react-calendar__tile {
    // padding: 5px 0px 18px;
    position: relative;
  }

  /* 네비게이션 월 스타일 적용 */
  .react-calendar__year-view__months__month {
    flex: 0 0 calc(33.3333% - 10px) !important;
    margin-inline-start: 5px !important;
    margin-inline-end: 5px !important;
    margin-block-end: 10px;
    padding: 20px 6.6667px;
    font-size: 0.9rem;
    font-weight: 600;
    color: ${(props) => props.theme.gray_1};
  }

  /* 선택한 날짜 스타일 적용 */
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus,
  .react-calendar__tile--active {
    background-color: inherit !important;
    color : white !important;
    // border: 1px solid rgba(255, 114, 153, 1);
    border-radius: 50%;
  }

  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding : 0 !important;
    border-radius: 50%;
    }
  .react-calendar__tile:enabled:hover abbr {
      width: 70%; /* 원의 크기를 적당히 설정 */
      height: 70%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #CECECE;
      border-radius: 50%;
  }
  // .react-calendar__tile--active abbr {
  //   color: white !important;
  // }

  .react-calendar__tile--active {
    display : flex !important;
    justify-content: center;
    align-items: center;
    background-color: transparent !important; /* 선택된 날짜의 배경을 투명하게 */
    border-radius: 50%; /* 원형으로 유지 */
    padding: 0 !important; /* padding을 제거하여 원이 커지는 현상 방지 */
  }

  .react-calendar__tile--active abbr {
    width: 70%; /* 원의 크기를 적당히 설정 */
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 114, 153, 1) !important; /* 원의 배경색 설정 */
    border-radius: 50%; /* 원형 */
    color: white !important; /* 텍스트 색상 */
  }
`;

export const StyledCalendar = styled(Calendar)``;

/* 오늘 버튼 스타일 */
export const StyledDate = styled.div`
  position: absolute;
  right: 45%;
  top: -1%;
//   background-color: ${(props) => props.theme.primary_3};
  border: 1px solid rgba(255, 114, 153, 1);
  color: ${(props) => props.theme.yellow_2};
  width: 10%;
  min-width: fit-content;
  height: 2rem;
  text-align: center;
  margin: 0 auto;
  line-height: 2rem;
  border-radius: 8px;
  font-size: 0.8rem;
  font-weight: 800;
  cursor:pointer;
`;

/* 오늘 날짜에 텍스트 삽입 스타일 */
export const StyledToday = styled.div`
  font-size: x-small;
  color: ${(props) => props.theme.br_2};
  font-weight: 600;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
`;

/* 출석한 날짜에 스탬프 이미지 표시 스타일 */
export const StyledStamp = styled.img`
  border-radius: 50%;
  width: 70%;
  height: 70%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
`;
