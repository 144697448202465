import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import event from '../../../containers/event/event.module.css';

const EventMore = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const scrollRef = useRef(null);

    const [eventProduct, setEventProduct] = useState(location?.state.eventProduct.slice(0, 6) || []);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(location?.state.eventProduct.length > 6);

    useEffect(() => {
        // console.log('eventProduct : ', eventProduct);
    }, [eventProduct]);

    useEffect(() => {
        // console.log('useEffect called');

        const handleScroll = () => {
            if (scrollRef.current) {
                const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
                // console.log('scrollTop:', scrollTop);
                // console.log('scrollHeight:', scrollHeight);
                // console.log('clientHeight:', clientHeight);

                if (scrollTop + clientHeight >= scrollHeight - 5 && hasMore) {
                    // console.log('End of div reached');
                    loadMoreProducts();
                }
            }
        };

        const currentScrollRef = scrollRef.current;
        if (currentScrollRef) {
            currentScrollRef.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (currentScrollRef) {
                currentScrollRef.removeEventListener('scroll', handleScroll);
            }
        };
    }, [scrollRef.current, hasMore]);

    const loadMoreProducts = () => {
        if (!hasMore) return;

        // console.log('Loading more products');
        const startIndex = page * 6;
        const endIndex = (page + 1) * 6;
        const moreProducts = location?.state.eventProduct.slice(startIndex, endIndex);

        if (moreProducts.length > 0) {
            setEventProduct(prevProducts => [...prevProducts, ...moreProducts]);
            setPage(prevPage => prevPage + 1);
        }

        if (endIndex >= location?.state.eventProduct.length) {
            setHasMore(false);
        }
    };

    const onBack = () => {
        navigate(-1);
    };

    const onEventProduct = (item) => {
        // console.log('item.event_number : ', item.event_number);
        const newItem = {
            eventContent: item.event_content,
            eventType: item.event_type,
            eventEndDate: item.event_end_date,
            eventImage1: item.event_image1,
            eventImage2: item.event_image2,
            eventImage3: item.event_image3,
            eventImage4: item.event_image4,
            eventNo: item.event_no,
            eventNumber: item.event_number,
            eventPrice: item.event_price,
            eventRegDate: item.event_reg_date,
            eventStartDate: item.event_start_date,
            eventSubTitle: item.event_sub_title,
            eventTitle: item.event_title,
            eventUpdDate: item.event_upd_date,
            eventWinnerDate: item.event_winner_date,
            type: item.type,
            userId: item.user_id
          }

        navigate(`/event/${newItem.eventType}/${newItem.eventNumber}`, { state: { eventInfo: newItem } });
    }

    // 날짜 문자열을 그대로 반환하는 함수
    const formatDate = (dateString) => {
        return dateString.replace('T', ' ').substring(0, 19);
    };

    return (
        <div>
            <div className={`${event.event_store_more_header}`}>
                <div>
                    <button onClick={() => onBack()}>
                        <span className="material-symbols-outlined">arrow_back_ios</span>
                    </button>
                </div>
                <div>이벤트 검색 상품</div>
            </div>
            <div className={`${event.event_store_more_content} py-3`} ref={scrollRef}>
                <div className='px-3'>
                    <div className='px-1 py-1'>
                    {
                      eventProduct.map((item, index) => (
                        <div className='search_content_event_item' key={index}  onClick={() => onEventProduct(item)}>
                          <div><img src={item.event_image1} alt="" /></div>
                          <div>
                            <div><span>{item.event_title}</span></div>
                            <div><span>{item.event_sub_title}</span></div>
                            <div><span>{formatDate(item.event_start_date)}</span><span>~</span><span>{formatDate(item.event_end_date)}</span></div>
                          </div>
                        </div>
                      ))
                    }
                    </div>
                    {!hasMore && <div className={`${event.event_store_more_last_txt} pt-4`}><span>검색된 상품의 마지막입니다.</span></div>}
                </div>
            </div>
        </div>
    );
};

export default EventMore;
