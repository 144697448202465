import React, { useContext, useEffect, useState } from 'react'
import { Pagination } from 'antd';
import seller from '../../../../seller.module.css';
import admin from '../../../../../admin/admin.module.css';
import { LoginContext } from '../../../../../contexts/LoginContextProvider';
import * as adminApi from '../../../../../apis/admin';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const SellerSettlementMain = () => {
  const { userInfo } = useContext(LoginContext);
  const MySwal = withReactContent(Swal);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(7);
  const [totalItems, setTotalItems] = useState(0);

  const [profitList, setProfitList] = useState([]);
  const [filteredProfitList, setFilteredProfitList] = useState([]);
  
  const [totalPaymentAmount, setTotalPaymentAmount] = useState(0);
  const [totalFees, setTotalFees] = useState(0);
  const [totalSettlementAmount, setTotalSettlementAmount] = useState(0);

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if(userInfo) {
        profitSelect();
    }
  }, [userInfo]);

  useEffect(() => {
      filterData();
  }, [startDate, endDate, searchText, profitList]);

  const profitSelect = async () => {
      const response = await adminApi.profitSelect(userInfo && userInfo?.userId);
      const data = response.data;

      // console.log('profitSelect : ', data);
      setProfitList(data);

      // 초기 데이터로 계산된 총합을 설정
      calculateTotals(data);
  };

  const calculateTotals = (data) => {
      if(data !== '') {
          const totalPayment = data.reduce((acc, item) => acc + item.totalPrice + item.deliveryPrice, 0);
          const totalFee = data.reduce((acc, item) => acc + item.totalFee, 0);
          const totalSettlement = data.reduce((acc, item) => acc + item.settlementAmount, 0);
  
          setTotalPaymentAmount(totalPayment);
          setTotalFees(totalFee);
          setTotalSettlementAmount(totalSettlement);
      }
  };

  const filterData = () => {
      let filteredData = profitList;
  
      // 날짜 필터링
      if (startDate && endDate) {
          filteredData = filteredData.filter(item => {
              const profitDate = new Date(item.profitRegDate).setHours(0, 0, 0, 0); // 시간 제거
              const start = new Date(startDate).setHours(0, 0, 0, 0);
              const end = new Date(endDate).setHours(23, 59, 59, 999); // 종료일의 끝까지 포함
  
              return profitDate >= start && profitDate <= end;
          });
      }
  
      // 텍스트 필터링
      if (searchText) {
          filteredData = filteredData.filter(item => 
              item.productName.toLowerCase().includes(searchText.toLowerCase()) ||
              item.type.toLowerCase().includes(searchText.toLowerCase()) ||
              item.unitPrice.toString().includes(searchText) ||
              item.totalPrice.toString().includes(searchText) ||
              item.settlementAmount.toString().includes(searchText) ||
              item.sellerId.toLowerCase().includes(searchText.toLowerCase())
          );
      }
  
      // 필터된 데이터로 총합 계산
      calculateTotals(filteredData);
      setFilteredProfitList(filteredData);
      setTotalItems(filteredData.length);
  };

  const handleDateChange = (e, isStart) => {
      if (isStart) {
          setStartDate(e.target.value);
      } else {
          setEndDate(e.target.value);
      }
  };

  const handleSearchChange = (e) => {
      setSearchText(e.target.value);
  };

  // 페이지네이션을 위한 현재 아이템들 계산
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredProfitList.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div className={`px-3 ${seller.seller_right_container}`}>
      <div>
        <div className={`${admin.admin_calculate_header}`}>
            <div><span>정산관리</span></div>
        </div>
        <div className={`${admin.admin_calculate_title}`}><span>수익금 내역</span></div>
        {
            profitList.length > 0 ?
            <div>
                <div>
                    <div className={`${admin.admin_calculate_proceed_total_box}`}>
                        <div>
                            <div><span>총 결제금액</span></div>
                            <div>
                                <div><span>{totalPaymentAmount.toLocaleString()}</span></div>
                                <div><span>KRW</span></div>
                            </div>
                            <div>
                                <div><span>적용된 정산 기간 :</span></div>&nbsp;
                                {
                                    startDate === '' && endDate === '' ?
                                    <div>
                                        <span>전체기간</span>
                                    </div>
                                    :
                                    <div>
                                        <span>{startDate ? new Date(startDate).toLocaleDateString('ko-KR') : '시작일'}</span>
                                        <span> ~ </span>
                                        <span>{endDate ? new Date(endDate).toLocaleDateString('ko-KR') : '종료일'}</span>
                                    </div>
                                }
                            </div>
                        </div>
                        <div>
                            <div><span>총 수수료</span></div>
                            <div>
                                <div><span>{totalFees.toLocaleString()}</span></div>
                                <div><span>KRW</span></div>
                            </div>
                            <div>
                                <div><span>적용된 정산 기간 :</span></div>&nbsp;
                                {
                                    startDate === '' && endDate === '' ?
                                    <div>
                                        <span>전체기간</span>
                                    </div>
                                    :
                                    <div>
                                        <span>{startDate ? new Date(startDate).toLocaleDateString('ko-KR') : '시작일'}</span>
                                        <span> ~ </span>
                                        <span>{endDate ? new Date(endDate).toLocaleDateString('ko-KR') : '종료일'}</span>
                                    </div>
                                }
                            </div>
                        </div>
                        <div>
                            <div><span>총 정산금액</span></div>
                            <div>
                                <div><span>{totalSettlementAmount.toLocaleString()}</span></div>
                                <div><span>KRW</span></div>
                            </div>
                            <div>
                                <div><span>적용된 정산 기간 :</span></div>&nbsp;
                                {
                                    startDate === '' && endDate === '' ?
                                    <div>
                                        <span>전체기간</span>
                                    </div>
                                    :
                                    <div>
                                        <span>{startDate ? new Date(startDate).toLocaleDateString('ko-KR') : '시작일'}</span>
                                        <span> ~ </span>
                                        <span>{endDate ? new Date(endDate).toLocaleDateString('ko-KR') : '종료일'}</span>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className={`${admin.admin_calculate_proceed_detail_box}`}>
                    <div>
                        <div className={`${admin.admin_calculate_proceed_detail_title}`}><span>상세내역</span></div>
                        <div className={`${admin.admin_calculate_proceed_detail_search_btn_box}`}>
                            <div>
                                <div className={`${admin.admin_calculate_proceed_detail_payment_date}`}>
                                    <div><span>결제일</span></div>
                                    <div>
                                        <div><input type="date" value={startDate} onChange={(e) => handleDateChange(e, true)} /></div>
                                        <div>&nbsp;<span>~</span>&nbsp;</div>
                                        <div><input type="date" value={endDate} onChange={(e) => handleDateChange(e, false)} /></div>
                                    </div>
                                </div>
                                <div className={`${admin.admin_calculate_proceed_detail_search}`}>
                                    <div><span>검색</span></div>
                                    <div>
                                        <div><input type="text" value={searchText} onChange={handleSearchChange} /></div>
                                        <div><span className="material-symbols-outlined">search</span></div>
                                    </div>
                                </div>
                            </div>
                            <div className={`${admin.admin_calculate_proceed_detail_btn}`}>
                                <div><button>필터</button></div>
                                <div><button>내역 엑셀 다운로드</button></div>
                            </div>
                        </div>
                    </div>
                    <div className={`${admin.admin_calculate_proceed_detail_container}`}>
                        <div>
                            <div className={`${admin.admin_calculate_proceed_detail_header}`}>
                                <div><span>아이디</span></div>
                                <div><span>구분</span></div>
                                <div><span>상품명</span></div>
                                <div><span>단가</span></div>
                                <div><span>수량</span></div>
                                <div><span>상품금액</span></div>
                                <div><span>프로모션비</span></div>
                                <div><span>배송비</span></div>
                                <div><span>수수료(플랫폼+PG)</span></div>
                                <div><span>결제통화</span></div>
                                <div><span>정산통화</span></div>
                                <div><span>정산금</span></div>
                            </div>
                            {
                                Array.isArray(currentItems) && currentItems.map((item, index) => (
                                    <div key={index} className={`${admin.admin_calculate_proceed_detail_content}`}>
                                        <div><span>{item.sellerId}</span></div>
                                        <div><span>{item.type}</span></div>
                                        <div><span>{item.productName}</span></div>
                                        <div><span>{item.unitPrice.toLocaleString()}</span></div>
                                        <div><span>{item.totalQuantity}</span></div>
                                        <div><span>{item.totalPrice.toLocaleString()}</span></div>
                                        <div><span>{item.promosion}</span></div>
                                        <div><span>{item.deliveryPrice.toLocaleString()}</span></div>
                                        <div><span>{item.totalFee.toLocaleString()}</span></div>
                                        <div><span>{item.paymentCurrency}</span></div>
                                        <div><span>{item.settlementCurrency}</span></div>
                                        <div><span>{item.settlementAmount.toLocaleString()}</span></div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <Pagination
                        current={currentPage}
                        total={totalItems}
                        pageSize={itemsPerPage}
                        onChange={(page) => setCurrentPage(page)}
                    />
                </div>
            </div>
            :
            <div className={`${admin.admin_calculate_proceed_detail_no_data}`}>
                <div><span>등록된 내역이 없습니다.</span></div>
            </div>
        }
      </div>
    </div>
  )
}

export default SellerSettlementMain