import React, { useEffect, useRef, useState } from 'react';
import admin from '../../../admin.module.css';
import * as adminApi from '../../../../apis/admin';

const AdminUserAdd = () => {

    const [selectedFile, setSelectedFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const fileInputRef = useRef(null);

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            const formData = new FormData();
            formData.append('file', file);
    
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                },
                timeout: 30000
            };
    
            setIsLoading(true);
            
            try {
                const response = await adminApi.userExcel(formData, config);
                const data = response.data;
                
                if(data === 'SUCCESS') {
                    setIsLoading(false);
                    alert('엑셀 파일이 성공적으로 업로드되었습니다.');
                } else {
                    setIsLoading(false);
                    alert('엑셀 파일 업로드에 실패했습니다.');
                }
            } catch (error) {
                setIsLoading(false);
                alert(`파일 업로드 중 오류가 발생했습니다. ${error}`);
            } finally {
                // 입력 값을 비워서 동일한 파일을 다시 선택해도 이벤트가 발생하도록 함
                event.target.value = null;
            }
        }
    };

    return (
        <>
            {
                isLoading &&
                <div className={`${admin.admin_user_list_loading}`}>
                    <div><img src={`${process.env.PUBLIC_URL}/img/kwangduck_loading.gif`} alt="" /></div>
                </div>
            }
            <div className={`${admin.admin_user_list_box}`}>
                <div>
                    <div><span>유저 엑셀 등록</span></div>
                    <div>
                        <div>
                            <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                accept=".xlsx"
                                onChange={handleFileChange}
                            />
                            <button className='ms-2' onClick={() => fileInputRef.current.click()}>엑셀 파일 업로드</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AdminUserAdd