import styled from "@emotion/styled";
import { useState } from "react";
import StarInput from "./StarInput";
import dailyduck from '../../containers/my/MyContainner.module.css';

const StarRating = ({ rating, setRating }) => {
  const handleClickRating = (value) => {
    setRating(value);
  };

  return (
    <div className={`${dailyduck.review_rating_box}`}>
      {/* <Name>별점</Name> */}
      <div className={`${dailyduck.review_rating_star_box}`}>
        <StarInput onClickRating={handleClickRating} value={5} isHalf={false} />
        <StarInput onClickRating={handleClickRating} value={4.5} isHalf={true} />
        <StarInput onClickRating={handleClickRating} value={4} isHalf={false} />
        <StarInput onClickRating={handleClickRating} value={3.5} isHalf={true} />
        <StarInput onClickRating={handleClickRating} value={3} isHalf={false} />
        <StarInput onClickRating={handleClickRating} value={2.5} isHalf={true} />
        <StarInput onClickRating={handleClickRating} value={2} isHalf={false} />
        <StarInput onClickRating={handleClickRating} value={1.5} isHalf={true} />
        <StarInput onClickRating={handleClickRating} value={1} isHalf={false} />
        <StarInput onClickRating={handleClickRating} value={0.5} isHalf={true} />
      </div>
      {/* <RatingValue>{rating}</RatingValue> */}
    </div>
  );
};

export default StarRating;
