import React, { useContext, useEffect, useRef, useState } from 'react';
import admin from '../../../admin.module.css';
import { Pagination, Select } from 'antd';
import { Swiper, SwiperSlide } from 'swiper/react';
import * as support from '../../../../apis/support';
import * as adminApi from '../../../../apis/admin';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import TextEditor from '../shop/TextEditor';
import { LoginContext } from '../../../../contexts/LoginContextProvider';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Option } from 'antd/es/mentions';

const AdminEvent = () => {
  const { userInfo } = useContext(LoginContext);
  const MySwal = withReactContent(Swal);
  const [activeButton, setActiveButton] = useState('all');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(6);
  const [totalItems, setTotalItems] = useState(0);
  const [showEventAdd, setShowEventAdd] = useState(false);
  const [showEventUpdate, setShowEventUpdate] = useState(false);
  const [activeEventType, setActiveEventType] = useState('');
  const [event, setEvent] = useState(null);
  const [updateEvent, setUpdateEvent] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [listImages, setListImages] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [planProductSelect, setPlanProductSelect] = useState('');
  const [productList, setProductList] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [planType, setPlanType] = useState('');
  const [priceDiscountNum, setPriceDiscountNum] = useState(0);
  const listImagesInputRef = useRef(null);
  const planListImagesInputRef = useRef(null);
  const [data, setData] = useState("");
  const [fileName, setFileName] = useState('');
  const [update, setUpdate] = useState(false);

  // 전체 선택 여부와 개별 선택 상태를 관리하기 위한 상태 변수 추가
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    // console.log('selectedProducts : ', selectedProducts);
  }, [selectedProducts])

  useEffect(() => {
    eventSelect();
    productSelect();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setEvent({ ...event, eventContent: data });
    setUpdateEvent({...updateEvent, eventContent : data});
  }, [data]);

  useEffect(() => {
    // console.log('event : ', event);
  }, [event]);

  useEffect(() => {
    // console.log('updateEvent : ', updateEvent);
    // console.log('productList : ', productList);
  }, [updateEvent]);

  const eventSelect = async () => {
    const response = await adminApi.eventSelect();
    const data = response.data;

    // console.log('eventData : ', data);
    setEventList(data);
    setTotalItems(data.length);
  }

  const productSelect = async () => {
    const response = await adminApi.totalProductSelect();
    const data = response.data;

    // console.log(data);
    setProductList(data);
  }

  const eventPlanProductSelect = async (eventNumber) => {
    // console.log("eventNumber : " + eventNumber);
    const response = await adminApi.eventPlanProductSelect(eventNumber);
    const data = response.data;

    // console.log(data);

    setSelectedProducts(data);
  }

  const handleEventTypeClick = (eventType) => {
    setActiveButton(eventType);
    setCurrentPage(1); // Reset to first page on event type change
    if (eventType === 'all') {
      setTotalItems(eventList.length);
    } else {
      const filteredEvents = eventList.filter(event => event.eventType === eventType);
      setTotalItems(filteredEvents.length);
    }
  }

  const paginate = pageNumber => setCurrentPage(pageNumber);

  const handleListImagesUploadClick = () => {
    listImagesInputRef.current.click();
  };

  const handlePlanListImagesUploadClick = () => {
    planListImagesInputRef.current.click();
  };

  const handleListImagesChange = async (e) => {
    const files = e.target.files;
    if (files) {
      if (files.length > 5) {
        alert('5개의 파일만 선택해주세요.');
        return;
      } else {
        const fileList = [];
        const formData = new FormData();
        for (const file of files) {
          if (file.size > 5 * 1024 * 1024) {
            alert("파일 크기는 5MB를 초과할 수 없습니다.");
            return;
          } else {
            formData.append('images', file);
          }
        }

        const config = {
          headers: {
            'content-type': 'multipart/form-data'
          }
        };

        const response = await support.uploadImagesToServer(formData, config);
        const data = response.data;

        // console.log('imgUrl : ', data[0]);

        for (let i = 0; i < data.length; i++) {
          const imageUrl = `${process.env.REACT_APP_API_SERVER}/img?file=${encodeURIComponent(data[i])}`;
          // console.log('imageUrl : ', imageUrl);
          fileList.push(imageUrl);
        }
        setListImages(fileList);
        setEvent({
          ...event, eventImage1: fileList[0], eventImage2: fileList[1], eventImage3: fileList[2], eventImage4: fileList[3], eventImage5: fileList[4]
        });
        setUpdateEvent({
          ...updateEvent, eventImage1: fileList[0], eventImage2: fileList[1], eventImage3: fileList[2], eventImage4: fileList[3], eventImage5: fileList[4]
        });
      }
    }
  };

  const handlePlanListImagesChange = async (e) => {
    const files = e.target.files;
    if (files) {
      if (files.length > 5) {
        alert('5개의 파일만 선택해주세요.');
        return;
      } else {
        const fileList = [];
        const formData = new FormData();
        for (const file of files) {
          if (file.size > 5 * 1024 * 1024) {
            alert("파일 크기는 5MB를 초과할 수 없습니다.");
            return;
          } else {
            formData.append('images', file);
          }
        }

        const config = {
          headers: {
            'content-type': 'multipart/form-data'
          }
        };

        const response = await support.uploadImagesToServer(formData, config);
        const data = response.data;

        // console.log('imgUrl : ', data[0]);

        for (let i = 0; i < data.length; i++) {
          const imageUrl = `${process.env.REACT_APP_API_SERVER}/img?file=${encodeURIComponent(data[i])}`;
          // console.log('imageUrl : ', imageUrl);
          fileList.push(imageUrl);
        }
        setListImages(fileList);
        setEvent({
          ...event, eventImage1: fileList[0], eventImage2: fileList[1], eventImage3: fileList[2], eventImage4: fileList[3], eventImage5: fileList[4]
        });
        setUpdateEvent({
          ...updateEvent, eventImage1: fileList[0], eventImage2: fileList[1], eventImage3: fileList[2], eventImage4: fileList[3], eventImage5: fileList[4]
        });
      }
    }
  };

  const onEventType = (eventType) => {
    setEvent({
      ...event, eventType: eventType, userId: userInfo && userInfo.userId
    })
  }

  const onUpdateEventType = (eventType) => {
    setUpdateEvent({
      ...updateEvent, eventType: eventType, userId: userInfo && userInfo.userId
    })
  }

  const onLuckedTitle = (e) => {
    setEvent({
      ...event, eventTitle: e.target.value
    })
  }
  const onPlanTitle = (e) => {
    setEvent({
      ...event, eventTitle: e.target.value
    })
  }
  const onUpdatePlanTitle = (e) => {
    setUpdateEvent({
      ...updateEvent, eventTitle: e.target.value
    })
  }
  const onUpdateLuckedTitle = (e) => {
    setUpdateEvent({
      ...updateEvent, eventTitle: e.target.value
    })
  }
  const onLuckedPrice = (e) => {
    setEvent({
      ...event, eventPrice: e.target.value
    })
  }
  const onUpdateLuckedPrice = (e) => {
    setUpdateEvent({
      ...updateEvent, eventPrice: e.target.value
    })
  }
  const onLuckedStartDate = (e) => {
    const localDate = new Date(e.target.value);
    const utcDate = new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000).toISOString();
    setEvent({
      ...event, eventStartDate: utcDate
    })
  }
  const onUpdateLuckedStartDate = (e) => {
    const localDate = new Date(e.target.value);
    const utcDate = new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000).toISOString();
    // console.log(utcDate); // 확인용 로그
    setUpdateEvent({
      ...updateEvent, eventStartDate: utcDate
    });
  }
  const onUpdatePlanStartDate = (e) => {
    const localDate = new Date(e.target.value);
    const utcDate = new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000).toISOString();
    // console.log(utcDate); // 확인용 로그
    setUpdateEvent({
      ...updateEvent, eventStartDate: utcDate
    });
  }
  const onLuckedEndDate = (e) => {
    const localDate = new Date(e.target.value);
    const utcDate = new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000).toISOString();
    // console.log(utcDate); // 확인용 로그
    setEvent({
      ...event, eventEndDate: utcDate
    })
  }
  const onUpdateLuckedEndDate = (e) => {
    const localDate = new Date(e.target.value);
    const utcDate = new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000).toISOString();
    // console.log(utcDate); // 확인용 로그
    setUpdateEvent({
      ...updateEvent, eventEndDate: utcDate
    })
  }
  const onUpdatePlanEndDate = (e) => {
    const localDate = new Date(e.target.value);
    const utcDate = new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000).toISOString();
    // console.log(utcDate); // 확인용 로그
    setUpdateEvent({
      ...updateEvent, eventEndDate: utcDate
    })
  }
  const onLuckedWinnerDate = (e) => {
    const localDate = new Date(e.target.value);
    const utcDate = new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000).toISOString();
    // console.log(utcDate); // 확인용 로그
    setEvent({
      ...event, eventWinnerDate: utcDate
    })
  }
  const onUpdateLuckedWinnerDate = (e) => {
    const localDate = new Date(e.target.value);
    const utcDate = new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000).toISOString();
    // console.log(utcDate); // 확인용 로그
    setUpdateEvent({
      ...updateEvent, eventWinnerDate: utcDate
    })
  }
  const onLuckedSubTitle = (e) => {
    // console.log(e.target.value);
    setEvent({
      ...event, eventSubTitle: e.target.value
    })
  }
  const onUpdateLuckedSubTitle = (e) => {
    // console.log(e.target.value);
    setUpdateEvent({
      ...updateEvent, eventSubTitle: e.target.value
    })
  }

  const handleEventAdd = () => {
    MySwal.fire({
      customClass: {
        popup: `${admin.admin_seller_add_popup}`
      },
      html: `
            <div>
                <div class='${admin.admin_seller_add_title}'><span>이벤트를 등록하시겠습니까?</span></div>
                <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                  <button class='mx-1' id='seller_add_cancel'>취소</button>
                  <button class='mx-1' id='seller_add_success'>등록</button>
                </div>
            </div>
        `,
      showConfirmButton: false,
      showCancelButton: false,
    });
    document.addEventListener('click', sellerAddSuccess);
    document.addEventListener('click', sellerAddCancel);

    async function sellerAddSuccess(e) {
      if (e.target && e.target.id === 'seller_add_success') {
        try {
          let response = '';
          let data = '';
          if (event.eventType === 'lucked') {
            response = await adminApi.eventAdd(event);
            data = response.data;
          } else if (event.eventType === 'plan') {
            let newItem = {};
            if (event.planProductSelect === 'all_product') {
              newItem = {
                ...event, selectProduct: productList
              }
            } else {
              newItem = {
                ...event, selectProduct: selectedProducts
              }
            }
            // console.log('newItem : ', newItem);
            response = await adminApi.planEventAdd(newItem);
            data = response.data;
          }

          if (data === 'SUCCESS') {
            MySwal.fire({
              customClass: {
                popup: `${admin.admin_seller_add_popup}`
              },
              html: `
                        <div>
                        <div class='${admin.admin_seller_add_title}'><span>이벤트 등록이 완료되었습니다.</span></div>
                        <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                        </div>
                        </div>
                        `,
              showConfirmButton: false,
              showCancelButton: false,
            });
            document.addEventListener('click', sellerAddConfirm);

            function sellerAddConfirm(e) {
              if (e.target && e.target.id === 'seller_add_confirm') {
                eventSelect();
                setShowEventAdd(false);
                MySwal.close();
                document.removeEventListener('click', sellerAddConfirm);
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
              }
            }
          } else {
            MySwal.fire({
              customClass: {
                popup: `${admin.admin_seller_add_popup}`
              },
              html: `
                        <div>
                        <div class='${admin.admin_seller_add_title}'><span>이벤트 등록에 실패하였습니다.</span></div>
                        <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                        </div>
                        </div>
                        `,
              showConfirmButton: false,
              showCancelButton: false,
            });
            document.addEventListener('click', sellerAddConfirm);

            function sellerAddConfirm(e) {
              if (e.target && e.target.id === 'seller_add_confirm') {
                MySwal.close();
                document.removeEventListener('click', sellerAddConfirm);
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
              }
            }
          }
        } catch (error) {
          console.error('error : ', error);
        }
      }
    }

    function sellerAddCancel(e) {
      if (e.target && e.target.id === 'seller_add_cancel') {
        MySwal.close();
        document.removeEventListener('click', sellerAddSuccess);
        document.removeEventListener('click', sellerAddCancel);
      }
    }
  }

  const handleEventUpdateBtn = (event) => {
    MySwal.fire({
      customClass: {
        popup: `${admin.admin_seller_add_popup}`
      },
      html: `
            <div>
                <div class='${admin.admin_seller_add_title}'><span>이벤트를 수정하시겠습니까?</span></div>
                <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                  <button class='mx-1' id='seller_update_cancel'>취소</button>
                  <button class='mx-1' id='seller_update_success'>수정</button>
                </div>
            </div>
        `,
      showConfirmButton: false,
      showCancelButton: false,
    });
    document.addEventListener('click', sellerUpdateSuccess);
    document.addEventListener('click', sellerUpdateCancel);

    async function sellerUpdateSuccess(e) {
      if (e.target && e.target.id === 'seller_update_success') {
        try {
          let response;
          let data;
          if(updateEvent.eventType === 'plan') {
            let newItem = {};
            if (event.planProductSelect === 'all_product') {
              newItem = {
                ...updateEvent, selectProduct: productList
              }
            } else {
              newItem = {
                ...updateEvent, selectProduct: selectedProducts

              }
            }

            // const response = await adminApi.eventPlanProductUpdate(newItem);

            // console.log('newItem : ', newItem);

            response = await adminApi.eventUpdate(newItem);
            data = response.data;
          } else {
            response = await adminApi.eventUpdate(updateEvent);
            data = response.data;
          }


          if (data === 'SUCCESS') {
            MySwal.fire({
              customClass: {
                popup: `${admin.admin_seller_add_popup}`
              },
              html: `
                        <div>
                        <div class='${admin.admin_seller_add_title}'><span>이벤트 수정이 완료되었습니다.</span></div>
                        <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                        <button class='mx-1' id='seller_update_confirm'>확인</button>
                        </div>
                        </div>
                        `,
              showConfirmButton: false,
              showCancelButton: false,
            });
            document.addEventListener('click', sellerUpdateConfirm);

            function sellerUpdateConfirm(e) {
              if (e.target && e.target.id === 'seller_update_confirm') {
                eventSelect();
                setShowEventUpdate(false);
                MySwal.close();
                document.removeEventListener('click', sellerUpdateConfirm);
                document.removeEventListener('click', sellerUpdateSuccess);
                document.removeEventListener('click', sellerUpdateCancel);
              }
            }
          } else {
            MySwal.fire({
              customClass: {
                popup: `${admin.admin_seller_add_popup}`
              },
              html: `
                        <div>
                        <div class='${admin.admin_seller_add_title}'><span>이벤트 수정에 실패하였습니다.</span></div>
                        <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                        <button class='mx-1' id='seller_update_confirm'>확인</button>
                        </div>
                        </div>
                        `,
              showConfirmButton: false,
              showCancelButton: false,
            });
            document.addEventListener('click', sellerUpdateConfirm);

            function sellerUpdateConfirm(e) {
              if (e.target && e.target.id === 'seller_update_confirm') {
                MySwal.close();
                document.removeEventListener('click', sellerUpdateConfirm);
                document.removeEventListener('click', sellerUpdateSuccess);
                document.removeEventListener('click', sellerUpdateCancel);
              }
            }
          }
        } catch (error) {
          console.error('error : ', error);
        }
      }
    }

    function sellerUpdateCancel(e) {
      if (e.target && e.target.id === 'seller_update_cancel') {
        MySwal.close();
        document.removeEventListener('click', sellerUpdateSuccess);
        document.removeEventListener('click', sellerUpdateCancel);
      }
    }
  }

  const formatDate = (dateString) => {
    // dateString이 null, undefined, 또는 빈 문자열인 경우 처리
    if (!dateString) {
        return ''; // 또는 다른 기본값을 반환할 수 있습니다.
    }
    return dateString.replace('T', ' ').substring(0, 19);
};

const formatDateForInput = (dateString) => {
  if (!dateString) return '';

  // 서버에서 UTC로 저장된 시간을 그대로 사용하려면 `Z`가 붙은 시간 형태로 인식 필요
  const date = new Date(dateString.includes('Z') ? dateString : `${dateString}Z`); // 'Z' 추가하여 UTC로 인식
  
  // 로컬 시간으로 조정하지 않고 그대로 처리
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

  const eventCheck = (eventType) => {
    if (eventType === 'lucked') {
      return '럭드'
    } else if (eventType === 'quiz') {
      return '퀴즈'
    } else if (eventType === 'plan') {
      return '기획전'
    } else if (eventType === 'vote') {
      return '투표'
    }
  }

  // 전체 선택 체크박스 핸들러
  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    setIsAllSelected(isChecked);
    if (isChecked) {
      setSelectedItems(displayedEvents.map(item => item.eventNumber));
    } else {
      setSelectedItems([]);
    }
  };

  // 개별 체크박스 핸들러
  const handleSelectItem = (eventNumber) => {
    setSelectedItems((prevSelected) => {
      if (prevSelected.includes(eventNumber)) {
        return prevSelected.filter((item) => item !== eventNumber);
      } else {
        return [...prevSelected, eventNumber];
      }
    });
  };

  // 선택된 이벤트 삭제 핸들러
  const handleDeleteSelected = async () => {
    if (selectedItems.length === 0) {
      alert("삭제할 항목을 선택하세요.");
      return;
    }

    // console.log('selectedItems : ', selectedItems);
    
    const confirmed = window.confirm("선택한 항목을 삭제하시겠습니까?");
    if (!confirmed) return;

    try {
      const response = await adminApi.deleteEvents(selectedItems);
      if (response.data === 'SUCCESS') {
        alert("삭제되었습니다.");
        eventSelect(); // 새로고침
      } else {
        alert("삭제에 실패했습니다.");
      }
    } catch (error) {
      console.error('삭제 오류:', error);
      alert("삭제 중 오류가 발생했습니다.");
    }
  };

  const handleEventUpdate = (item) => {
    setShowEventUpdate(true);
    setUpdateEvent(item);
    setActiveEventType(item.eventType);
    setData(item.eventContent);
    setListImages([
      item.eventImage1,
      item.eventImage2,
      item.eventImage3,
      item.eventImage4,
      item.eventImage5,
    ]);

    if(item.eventType === 'plan') {
      setPlanProductSelect(item.planProductSelect);
      eventPlanProductSelect(item.eventNumber);
    }
  }

  const handleUpdateCancel = () => {
    setShowEventUpdate(false);
    setData('');
    setListImages([]);
  }

  const onPlanType = (value) => {
    setPlanType(value);
    setEvent({
      ...event, planType: value
    });
  }
  const onUpdatePlanType = (value) => {
    setUpdateEvent({
      ...updateEvent, planType : value
    })
  }

  const onPlanProductSlect = (e) => {
    setPlanProductSelect(e.target.id);
    setEvent({
      ...event, planProductSelect: e.target.id
    })
  }
  const onUpdatePlanProductSlect = (e) => {
    setUpdateEvent({
      ...updateEvent, planProductSelect: e.target.id
    })
  }

  const handleCheckboxChange = (product) => {
    setSelectedProducts((prevSelected) => {
      if (prevSelected.some(p => p.productNo === product.productNo)) {
        return prevSelected.filter((item) => item.productNo !== product.productNo);
      } else {
        return [...prevSelected, { ...product, eventRate: 0, eventRatePrice: 0 }];
      }
    });
  };
  const isProductSelected = (productNo) => {
    return selectedProducts.some((item) => item.productNo === productNo);
  };
  const handleEventRateChange = (productNo, value) => {
    setSelectedProducts((prevSelected) =>
      prevSelected.map((item) => {
        if (item.productNo === productNo) {
          const eventRate = Math.min(100, Math.floor(value));
          const eventRatePrice = (item.productPrice * (1 - eventRate / 100)).toFixed(2);
          return {
            ...item,
            eventRate,
            eventRatePrice: Math.min(eventRatePrice, item.productPrice)
          };
        }
        return item;
      })
    );
  };

  const handleEventRatePriceChange = (productNo, value) => {
    setSelectedProducts((prevSelected) =>
      prevSelected.map((item) => {
        if (item.productNo === productNo) {
          const eventRatePrice = Math.min(value, item.productPrice);
          const eventRate = Math.floor((1 - eventRatePrice / item.productPrice) * 100);
          return {
            ...item,
            eventRate: Math.min(eventRate, 100),
            eventRatePrice
          };
        }
        return item;
      })
    );
  };

  const onPriceDiscountNum = (e) => {
    // console.log('할인율 : ', e.target.value);
    let discount = parseInt(e.target.value);
    if (discount > 100) {
      alert('할인율은 100%를 넘길 수 없습니다. ');
      discount = 0;
      setPriceDiscountNum(discount);
      return;
    }
    setPriceDiscountNum(discount);
    setEvent({
      ...event, planProductRate: discount
    })
  }

  const filteredEventList = activeButton === 'all'
    ? eventList
    : eventList.filter(event => event.eventType === activeButton);

  const displayedEvents = filteredEventList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const onDetailContent = (data) => {

  }

  const handleItemDelete = (eventNo) => {
    // console.log('eventNo : ', eventNo);

    MySwal.fire({
      customClass: {
        popup: `${admin.admin_seller_add_popup}`
      },
      html: `
            <div>
                <div class='${admin.admin_seller_add_title}'><span>이벤트를 삭제하시겠습니까?</span></div>
                <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                  <button class='mx-1' id='seller_update_cancel'>취소</button>
                  <button class='mx-1' id='seller_update_success'>삭제</button>
                </div>
            </div>
        `,
      showConfirmButton: false,
      showCancelButton: false,
    });
    document.addEventListener('click', sellerUpdateSuccess);
    document.addEventListener('click', sellerUpdateCancel);

    async function sellerUpdateSuccess(e) {
      if (e.target && e.target.id === 'seller_update_success') {
        try {
          const response = await adminApi.eventSingleDelete(eventNo);
          const data = response.data;

          if (data === 'SUCCESS') {
            MySwal.fire({
              customClass: {
                popup: `${admin.admin_seller_add_popup}`
              },
              html: `
                        <div>
                        <div class='${admin.admin_seller_add_title}'><span>이벤트 삭제가 완료되었습니다.</span></div>
                        <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                        <button class='mx-1' id='seller_update_confirm'>확인</button>
                        </div>
                        </div>
                        `,
              showConfirmButton: false,
              showCancelButton: false,
            });
            document.addEventListener('click', sellerUpdateConfirm);

            function sellerUpdateConfirm(e) {
              if (e.target && e.target.id === 'seller_update_confirm') {
                eventSelect();
                MySwal.close();
                document.removeEventListener('click', sellerUpdateConfirm);
                document.removeEventListener('click', sellerUpdateSuccess);
                document.removeEventListener('click', sellerUpdateCancel);
              }
            }
          } else {
            MySwal.fire({
              customClass: {
                popup: `${admin.admin_seller_add_popup}`
              },
              html: `
                        <div>
                        <div class='${admin.admin_seller_add_title}'><span>이벤트 삭제에 실패하였습니다.</span></div>
                        <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                        <button class='mx-1' id='seller_update_confirm'>확인</button>
                        </div>
                        </div>
                        `,
              showConfirmButton: false,
              showCancelButton: false,
            });
            document.addEventListener('click', sellerUpdateConfirm);

            function sellerUpdateConfirm(e) {
              if (e.target && e.target.id === 'seller_update_confirm') {
                MySwal.close();
                document.removeEventListener('click', sellerUpdateConfirm);
                document.removeEventListener('click', sellerUpdateSuccess);
                document.removeEventListener('click', sellerUpdateCancel);
              }
            }
          }
        } catch (error) {
          console.error('error : ', error);
        }
      }
    }

    function sellerUpdateCancel(e) {
      if (e.target && e.target.id === 'seller_update_cancel') {
        MySwal.close();
        document.removeEventListener('click', sellerUpdateSuccess);
        document.removeEventListener('click', sellerUpdateCancel);
      }
    }
  }

  return (
    <div className={`${admin.amdin_event_setting_box} px-3 py-3`}>
      <div>
        <div className={`${admin.admin_event_setting_title}`}><span>이벤트 관리</span></div>
        <div className={`${admin.admin_event_setting_tap}`}>
          <div className={`${activeButton === 'all' ? admin.admin_event_setting_tap_active : ''}`}><button onClick={() => handleEventTypeClick('all')}>전체 ({eventList.length})</button></div>
          <div className={`${activeButton === 'lucked' ? admin.admin_event_setting_tap_active : ''}`}><button onClick={() => handleEventTypeClick('lucked')}>럭드 ({eventList.filter(event => event.eventType === 'lucked').length})</button></div>
          <div className={`${activeButton === 'quiz' ? admin.admin_event_setting_tap_active : ''}`}><button onClick={() => handleEventTypeClick('quiz')}>퀴즈 ({eventList.filter(event => event.eventType === 'quiz').length})</button></div>
          <div className={`${activeButton === 'plan' ? admin.admin_event_setting_tap_active : ''}`}><button onClick={() => handleEventTypeClick('plan')}>기획전 ({eventList.filter(event => event.eventType === 'plan').length})</button></div>
          <div className={`${activeButton === 'vote' ? admin.admin_event_setting_tap_active : ''}`}><button onClick={() => handleEventTypeClick('vote')}>투표 ({eventList.filter(event => event.eventType === 'vote').length})</button></div>
        </div>
        <div className={`${admin.admin_event_setting_event_add_btn}`}>
          <div><button onClick={() => { setShowEventAdd(true); setEvent({ ...event, eventNumber: Date.now() }) }}>이벤트 등록</button></div>
          <div className='ms-1'><button onClick={handleDeleteSelected}>삭제</button></div>
        </div>
        <div className={`${admin.admin_event_setting_event_content_box}`}>
          <div>
            <div className={`${admin.admin_event_setting_event_content_header}`}>
              <div><input type="checkbox" checked={isAllSelected} onChange={handleSelectAll} /></div>
              <div><span>이미지</span></div>
              <div><span>제목</span></div>
              <div><span>이벤트 요약</span></div>
              <div><span>진행기간</span></div>
              <div><span>이벤트 종류</span></div>
              <div></div>
            </div>
            {
              displayedEvents.map((item, index) => (
                <div className={`${admin.admin_event_setting_event_content_content}`} key={item.eventNumber}>
                  <div><input type="checkbox" checked={selectedItems.includes(item.eventNumber)} onChange={() => handleSelectItem(item.eventNumber)} /></div>
                  <div><img src={item.eventImage1} alt="이벤트이미지" /></div>
                  <div><span>{item.eventTitle}</span></div>
                  <div><span>{item.eventSubTitle}</span></div>
                  <div>
                    <div><span>{formatDate(item.eventStartDate)}</span></div>
                    <div><span>~</span></div>
                    <div><span>{formatDate(item.eventEndDate)}</span></div>
                  </div>
                  <div><span>{eventCheck(item.eventType)}</span></div>
                  <div>
                    <div className='py-1'><button onClick={() => { handleEventUpdate(item); }}>수정</button></div>
                    <div className='py-1'><button onClick={() => handleItemDelete(item.eventNo)}>삭제</button></div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
        <Pagination
          current={currentPage}
          total={totalItems}
          onChange={paginate}
          pageSize={itemsPerPage}
          showSizeChanger={false} // Hide the option to change page size
        />
      </div>
      {
        showEventAdd &&
        <div className={`${admin.admin_event_setting_add_modal_container}`}>
          <div className={`${admin.admin_event_setting_add_modal_box}`}>
            <div className={`${admin.admin_event_setting_add_modal_header}`}>
              <div><span>이벤트 등록</span></div>
              <div><button onClick={() => setShowEventAdd(false)}><span className="material-symbols-outlined">close</span></button></div>
            </div>
            <div className={`${admin.admin_event_setting_add_modal_content}`}>
              <div className={`${admin.admin_event_setting_add_modal_event_type}`}>
                <div><span>이벤트 종류</span></div>
                <div>
                  <div><button className={`${activeEventType === 'vote' ? admin.admin_event_setting_add_modal_event_type_active : ''}`} onClick={() => { setActiveEventType('vote'); onEventType('vote') }}>투표</button></div>
                  <div><button className={`${activeEventType === 'lucked' ? admin.admin_event_setting_add_modal_event_type_active : ''}`} onClick={() => { setActiveEventType('lucked'); onEventType('lucked') }}>럭드</button></div>
                  <div><button className={`${activeEventType === 'plan' ? admin.admin_event_setting_add_modal_event_type_active : ''}`} onClick={() => { setActiveEventType('plan'); onEventType('plan') }}>기획전</button></div>
                  <div><button className={`${activeEventType === 'quiz' ? admin.admin_event_setting_add_modal_event_type_active : ''}`} onClick={() => { setActiveEventType('quiz'); onEventType('quiz') }}>퀴즈</button></div>
                </div>
              </div>
              {
                activeEventType === 'lucked' &&
                <div className={`${admin.admin_event_setting_add_modal_lucked}`}>
                  <div className={`${admin.admin_event_setting_add_modal_input_box}`}>
                    <div><span>럭키드로우 제목</span></div>
                    <div><input type="text" onChange={(e) => onLuckedTitle(e)} /></div>
                  </div>
                  <div className={`${admin.admin_event_setting_add_modal_input_box}`}>
                    <div><span>럭키드로우 요약</span></div>
                    <div><textarea onChange={(e) => onLuckedSubTitle(e)}></textarea></div>
                  </div>
                  <div className={`${admin.admin_event_setting_add_modal_price_box}`}>
                    <div><span>럭키드로우 금액</span></div>
                    <div>
                      <div>
                        <div><input type="number" name="" id="" onChange={(e) => onLuckedPrice(e)} /></div>
                        <div><span>원</span></div>
                      </div>
                    </div>
                  </div>
                  <div className={`${admin.admin_event_setting_add_modal_img_box}`}>
                    <div>
                      <div><span>럭키드로우 이미지</span></div>
                      <div><button onClick={handleListImagesUploadClick}>이미지 등록</button></div>
                    </div>
                    <input
                      type="file"
                      ref={listImagesInputRef}
                      onChange={handleListImagesChange}
                      multiple
                      style={{ display: 'none' }}
                    />
                    <Swiper
                      slidesPerView={windowWidth < 500 ? 3 : 5}
                      spaceBetween={30}
                      freeMode={true}
                      className={`${admin.admin_event_setting_add_modal_swiper} py-2`}
                    >
                      {
                        listImages.map((image, index) => (
                          <SwiperSlide key={index}>
                            <div className={`${admin.admin_event_setting_add_modal_swiper_img}`}>
                              <img
                                src={image} alt={`리스트 이미지 ${index + 1}`}
                              />
                            </div>
                          </SwiperSlide>
                        ))
                      }
                    </Swiper>
                  </div>
                  <div className={`${admin.admin_event_setting_add_modal_lucked_content}`}>
                    <div><span>럭키드로우 내용</span></div>
                    <div>
                      <TextEditor setData={setData} admin={admin} fileName={fileName} onDetailContent={onDetailContent} setFileName={setFileName} update={update} data={data} />
                    </div>
                  </div>
                  <div className={`${admin.admin_event_setting_add_modal_date_box}`}>
                    <div><span>럭드 응모기간</span></div>
                    <div>
                      <div><input type="datetime-local" name="" id="" onChange={(e) => onLuckedStartDate(e)} /></div>
                      <div><span>&nbsp;~&nbsp;</span></div>
                      <div><input type="datetime-local" name="" id="" onChange={(e) => onLuckedEndDate(e)} /></div>
                    </div>
                  </div>
                  <div className={`${admin.admin_event_setting_add_modal_date_box}`}>
                    <div><span>당첨자 발표일</span></div>
                    <div>
                      <div><input type="datetime-local" name="" id="" onChange={(e) => onLuckedWinnerDate(e)} /></div>
                    </div>
                  </div>
                </div>
              }
              {
                activeEventType === 'plan' &&
                <div className={`${admin.admin_event_setting_add_modal_plan}`}>
                  <div className={`${admin.admin_event_setting_add_modal_input_box}`}>
                    <div><span>기획전 종류</span></div>
                    <div>
                      <Select
                        className={`${admin.admin_event_setting_plan_select}`}
                        onChange={(value) => onPlanType(value)}
                      >
                        <Option value='none'>설정없음</Option>
                        <Option value='deliveryFree'>배송비 무료</Option>
                        <Option value='priceDiscount'>금액할인</Option>
                      </Select>
                    </div>
                  </div>
                  {
                    planType === 'priceDiscount' &&
                    <div className={`${admin.admin_event_setting_add_modal_discount_box}`}>
                      <div><span>할인율</span></div>
                      <div>
                        <div>
                          <div><input type="number" onChange={(e) => onPriceDiscountNum(e)} value={priceDiscountNum === 0 ? 0 : priceDiscountNum} /></div>
                          <div><span>%</span></div>
                        </div>
                      </div>
                    </div>
                  }
                  <div className={`${admin.admin_event_setting_add_modal_input_box}`}>
                    <div><span>기획전 제목</span></div>
                    <div><input type="text" onChange={(e) => onPlanTitle(e)} /></div>
                  </div>
                  <div className={`${admin.admin_event_setting_add_modal_img_box}`}>
                    <div>
                      <div><span>기획전 이미지</span></div>
                      <div><button onClick={handlePlanListImagesUploadClick}>이미지 등록</button></div>
                    </div>
                    <input
                      type="file"
                      ref={planListImagesInputRef}
                      onChange={handlePlanListImagesChange}
                      multiple
                      style={{ display: 'none' }}
                    />
                    <Swiper
                      slidesPerView={windowWidth < 500 ? 3 : 5}
                      spaceBetween={30}
                      freeMode={true}
                      className={`${admin.admin_event_setting_add_modal_swiper} py-2`}
                    >
                      {
                        listImages.map((image, index) => (
                          <SwiperSlide key={index}>
                            <div className={`${admin.admin_event_setting_add_modal_swiper_img}`}>
                              <img
                                src={image} alt={`리스트 이미지 ${index + 1}`}
                              />
                            </div>
                          </SwiperSlide>
                        ))
                      }
                    </Swiper>
                  </div>
                  <div className={`${admin.admin_event_setting_add_modal_lucked_content}`}>
                    <div><span>기획전 내용</span></div>
                    <div>
                      <TextEditor setData={setData} admin={admin} fileName={fileName} onDetailContent={onDetailContent} setFileName={setFileName} update={update} data={data} />
                    </div>
                  </div>
                  <div className={`${admin.admin_event_setting_add_modal_date_box}`}>
                    <div><span>기획전 기간</span></div>
                    <div>
                      <div><input type="datetime-local" name="" id="" onChange={(e) => onLuckedStartDate(e)} /></div>
                      <div><span>&nbsp;~&nbsp;</span></div>
                      <div><input type="datetime-local" name="" id="" onChange={(e) => onLuckedEndDate(e)} /></div>
                    </div>
                  </div>
                  <div className={`${admin.admin_event_setting_add_modal_product_select}`}>
                    <div><span>기획전 상품 선택</span></div>
                    <div>
                      <div>
                        <div><input type="radio" name="plan_product" id="all_product" onChange={(e) => onPlanProductSlect(e)} /></div>
                        <div className='ps-1'><label htmlFor="all_product">전체상품</label></div>
                      </div>
                      <div className='ps-3'>
                        <div><input type="radio" name="plan_product" id="select_product" onChange={(e) => onPlanProductSlect(e)} /></div>
                        <div className='ps-1'><label htmlFor="select_product">직접선택</label></div>
                      </div>
                    </div>
                  </div>
                  {
                    planProductSelect === 'all_product' &&
                    <div className={`${admin.admin_event_setting_plan_all_select}`}><span>전체 상품에 적용됩니다.</span></div>
                  }
                  {
                    planProductSelect === 'select_product' &&
                    <div className={`${admin.admin_event_setting_plan_product_select}`}>
                      <div>
                        {productList.map((item, index) => (
                          <div className={`${admin.admin_event_setting_plan_product_select_item}`} key={index}>
                            <div>
                              <input
                                type="checkbox"
                                onChange={() => handleCheckboxChange(item)}
                                checked={isProductSelected(item.productNo)}
                              />
                            </div>
                            <div>
                              <div>
                                <div><img src={item.productMainImage} alt="상품이미지" /></div>
                              </div>
                              <div>
                                <div><span>{item.middleCategory}</span></div>
                                <div><span>{item.productName}</span></div>
                                <div>
                                  <span>{item.productPrice ? item.productPrice.toLocaleString() : '가격 없음'}/</span>
                                  <span>{item.productsalePrice ? item.productsalePrice.toLocaleString() : '할인 가격 없음'}/</span>
                                  <span>{item.discountRate ? item.discountRate.toLocaleString() + '%' : '할인 없음'}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div>
                        {selectedProducts.map((product, index) => (
                          <div className={`${admin.admin_event_setting_plan_product_select_item_choice}`} key={index}>
                            <div>
                              <input
                                type="checkbox"
                                onChange={() => handleCheckboxChange(product)}
                                checked={selectedProducts.includes(product)}
                              />
                            </div>
                            <div>
                              <div>
                                <div><img src={product.productMainImage} alt="상품이미지" /></div>
                              </div>
                              <div>
                                <div><span>{product.middleCategory}</span></div>
                                <div><span>{product.productName}</span></div>
                                <div>
                                <span>{product.productPrice ? product.productPrice.toLocaleString() : '가격 없음'}/</span>
                                  <span>{product.productsalePrice ? product.productsalePrice.toLocaleString() : '할인 가격 없음'}/</span>
                                  <span>{product.discountRate ? product.discountRate.toLocaleString() + '%' : '할인 없음'}</span>
                                </div>
                              </div>
                            </div>
                            <div className={`${admin.admin_event_setting_plan_product_rate}`}>
                              <div>
                                <div><span>할인금액</span></div>
                                <div>
                                  <input
                                    type="number"
                                    value={product.eventRatePrice}
                                    onChange={(e) => handleEventRatePriceChange(product.productNo, parseFloat(e.target.value))}
                                  />
                                  <span>원</span>
                                </div>
                              </div>
                              <div>
                                <div><span>할인율</span></div>
                                <div>
                                  <input
                                    type="number"
                                    value={product.eventRate}
                                    onChange={(e) => handleEventRateChange(product.productNo, parseFloat(e.target.value))}
                                  />
                                  <span>%</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>

                  }
                </div>
              }
            </div>
            <div className={`${admin.admin_event_setting_add_modal_footer}`}>
              <div><button>취소</button></div>
              <div><button onClick={handleEventAdd}>이벤트 등록</button></div>
            </div>
          </div>
        </div>
      }
      {
        showEventUpdate &&
        <div className={`${admin.admin_event_setting_add_modal_container}`}>
          <div className={`${admin.admin_event_setting_add_modal_box}`}>
            <div className={`${admin.admin_event_setting_add_modal_header}`}>
              <div><span>이벤트 수정</span></div>
              <div><button onClick={() => handleUpdateCancel()}><span className="material-symbols-outlined">close</span></button></div>
            </div>
            <div className={`${admin.admin_event_setting_add_modal_content}`}>
              <div className={`${admin.admin_event_setting_add_modal_event_type}`}>
                <div><span>이벤트 종류</span></div>
                <div>
                  <div><button className={`${activeEventType === 'vote' ? admin.admin_event_setting_add_modal_event_type_active : ''}`} onClick={() => { setActiveEventType('vote'); onUpdateEventType('vote') }} disabled={activeEventType === 'lucked' || activeEventType === 'plan' || activeEventType === 'quiz'}>투표</button></div>
                  <div><button className={`${activeEventType === 'lucked' ? admin.admin_event_setting_add_modal_event_type_active : ''}`} onClick={() => { setActiveEventType('lucked'); onUpdateEventType('lucked') }} disabled={activeEventType === 'vote' || activeEventType === 'plan' || activeEventType === 'quiz'}>럭드</button></div>
                  <div><button className={`${activeEventType === 'plan' ? admin.admin_event_setting_add_modal_event_type_active : ''}`} onClick={() => { setActiveEventType('plan'); onUpdateEventType('plan') }} disabled={activeEventType === 'vote' || activeEventType === 'lucked' || activeEventType === 'quiz'}>기획전</button></div>
                  <div><button className={`${activeEventType === 'quiz' ? admin.admin_event_setting_add_modal_event_type_active : ''}`} onClick={() => { setActiveEventType('quiz'); onUpdateEventType('quiz') }} disabled={activeEventType === 'vote' || activeEventType === 'lucked' || activeEventType === 'plan'}>퀴즈</button></div>
                </div>
              </div>
              {
                activeEventType === 'lucked' &&
                <div className={`${admin.admin_event_setting_add_modal_lucked}`}>
                  <div className={`${admin.admin_event_setting_add_modal_input_box}`}>
                    <div><span>럭키드로우 제목</span></div>
                    <div><input type="text" value={updateEvent?.eventTitle || ''} onChange={(e) => onUpdateLuckedTitle(e)} /></div>
                  </div>
                  <div className={`${admin.admin_event_setting_add_modal_input_box}`}>
                    <div><span>럭키드로우 요약</span></div>
                    <div><textarea value={updateEvent?.eventSubTitle || ''} onChange={(e) => onUpdateLuckedSubTitle(e)}></textarea></div>
                  </div>
                  <div className={`${admin.admin_event_setting_add_modal_price_box}`}>
                    <div><span>럭키드로우 금액</span></div>
                    <div>
                      <div>
                        <div><input type="number" value={updateEvent?.eventPrice || ''} onChange={(e) => onUpdateLuckedPrice(e)} /></div>
                        <div><span>원</span></div>
                      </div>
                    </div>
                  </div>
                  <div className={`${admin.admin_event_setting_add_modal_img_box}`}>
                    <div>
                      <div><span>럭키드로우 이미지</span></div>
                      <div><button onClick={handleListImagesUploadClick}>이미지 등록</button></div>
                    </div>
                    <input
                      type="file"
                      ref={listImagesInputRef}
                      onChange={handleListImagesChange}
                      multiple
                      style={{ display: 'none' }}
                    />
                    <Swiper
                      slidesPerView={windowWidth < 500 ? 3 : 5}
                      spaceBetween={30}
                      freeMode={true}
                      className={`${admin.admin_event_setting_add_modal_swiper} py-2`}
                    >
                      {
                        listImages.map((image, index) => (
                          <SwiperSlide key={index}>
                            <div className={`${admin.admin_event_setting_add_modal_swiper_img}`}>
                              <img
                                src={image} alt={`리스트 이미지 ${index + 1}`}
                              />
                            </div>
                          </SwiperSlide>
                        ))
                      }
                    </Swiper>
                  </div>
                  <div className={`${admin.admin_event_setting_add_modal_lucked_content}`}>
                    <div><span>럭키드로우 내용</span></div>
                    <div>
                      <TextEditor setData={setData} admin={admin} fileName={fileName} onDetailContent={onDetailContent} setFileName={setFileName} update={update} data={data} />
                    </div>
                  </div>
                  <div className={`${admin.admin_event_setting_add_modal_date_box}`}>
                    <div><span>럭드 응모기간</span></div>
                    <div>
                      <div><input type="datetime-local" value={formatDateForInput(updateEvent?.eventStartDate)} onChange={(e) => onUpdateLuckedStartDate(e)} /></div>
                      <div><span>&nbsp;~&nbsp;</span></div>
                      <div><input type="datetime-local" value={formatDateForInput(updateEvent?.eventEndDate)} onChange={(e) => onUpdateLuckedEndDate(e)} /></div>
                    </div>
                  </div>
                  <div className={`${admin.admin_event_setting_add_modal_date_box}`}>
                    <div><span>당첨자 발표일</span></div>
                    <div>
                      <div><input type="datetime-local" value={formatDateForInput(updateEvent?.eventWinnerDate)} onChange={(e) => onUpdateLuckedWinnerDate(e)} /></div>
                    </div>
                  </div>
                </div>
              }
              {
                activeEventType === 'plan' &&
                <div className={`${admin.admin_event_setting_add_modal_plan}`}>
                  <div className={`${admin.admin_event_setting_add_modal_input_box}`}>
                    <div><span>기획전 종류</span></div>
                    <div>
                      <Select
                        className={`${admin.admin_event_setting_plan_select}`}
                        onChange={(value) => onUpdatePlanType(value)}
                        value={updateEvent && updateEvent.planType}
                      >
                        <Option value='none'>설정없음</Option>
                        <Option value='deliveryFree'>배송비 무료</Option>
                        <Option value='priceDiscount'>금액할인</Option>
                      </Select>
                    </div>
                  </div>
                  {
                    planType === 'priceDiscount' &&
                    <div className={`${admin.admin_event_setting_add_modal_discount_box}`}>
                      <div><span>할인율</span></div>
                      <div>
                        <div>
                          <div><input type="number" onChange={(e) => onPriceDiscountNum(e)} value={priceDiscountNum === 0 ? 0 : priceDiscountNum} /></div>
                          <div><span>%</span></div>
                        </div>
                      </div>
                    </div>
                  }
                  <div className={`${admin.admin_event_setting_add_modal_input_box}`}>
                    <div><span>기획전 제목</span></div>
                    <div><input type="text" onChange={(e) => onUpdatePlanTitle(e)} value={updateEvent && updateEvent.eventTitle} /></div>
                  </div>
                  <div className={`${admin.admin_event_setting_add_modal_img_box}`}>
                    <div>
                      <div><span>기획전 이미지</span></div>
                      <div><button onClick={handlePlanListImagesUploadClick}>이미지 등록</button></div>
                    </div>
                    <input
                      type="file"
                      ref={planListImagesInputRef}
                      onChange={handlePlanListImagesChange}
                      multiple
                      style={{ display: 'none' }}
                    />
                    <Swiper
                      slidesPerView={windowWidth < 500 ? 3 : 5}
                      spaceBetween={30}
                      freeMode={true}
                      className={`${admin.admin_event_setting_add_modal_swiper} py-2`}
                    >
                      {
                        listImages.map((image, index) => (
                          <SwiperSlide key={index}>
                            <div className={`${admin.admin_event_setting_add_modal_swiper_img}`}>
                              <img
                                src={image} alt={`리스트 이미지 ${index + 1}`}
                              />
                            </div>
                          </SwiperSlide>
                        ))
                      }
                    </Swiper>
                  </div>
                  <div className={`${admin.admin_event_setting_add_modal_lucked_content}`}>
                    <div><span>기획전 내용</span></div>
                    <div>
                      <TextEditor setData={setData} admin={admin} fileName={fileName} onDetailContent={onDetailContent} setFileName={setFileName} update={update} data={data} />
                    </div>
                  </div>
                  <div className={`${admin.admin_event_setting_add_modal_date_box}`}>
                    <div><span>기획전 기간</span></div>
                    <div>
                      <div><input type="datetime-local" name="" id="" value={updateEvent && formatDateForInput(updateEvent.eventStartDate)} onChange={(e) => onUpdatePlanStartDate(e)} /></div>
                      <div><span>&nbsp;~&nbsp;</span></div>
                      <div><input type="datetime-local" name="" id="" value={updateEvent && formatDateForInput(updateEvent.eventEndDate)} onChange={(e) => onUpdatePlanEndDate(e)} /></div>
                    </div>
                  </div>
                  <div className={`${admin.admin_event_setting_add_modal_product_select}`}>
                    <div><span>기획전 상품 선택</span></div>
                    <div>
                      <div>
                        <div><input type="radio" name="plan_product" id="all_product" onChange={(e) => onUpdatePlanProductSlect(e)} checked={updateEvent.planProductSelect === 'all_product'}/></div>
                        <div className='ps-1'><label htmlFor="all_product">전체상품</label></div>
                      </div>
                      <div className='ps-3'>
                        <div><input type="radio" name="plan_product" id="select_product" onChange={(e) => onUpdatePlanProductSlect(e)} checked={updateEvent.planProductSelect === 'select_product'}/></div>
                        <div className='ps-1'><label htmlFor="select_product">직접선택</label></div>
                      </div>
                    </div>
                  </div>
                  {
                    planProductSelect === 'all_product' &&
                    <div className={`${admin.admin_event_setting_plan_all_select}`}><span>전체 상품에 적용됩니다.</span></div>
                  }
                  {
                    planProductSelect === 'select_product' &&
                    <div className={`${admin.admin_event_setting_plan_product_select}`}>
                      <div>
                        {productList.map((item, index) => (
                          <div className={`${admin.admin_event_setting_plan_product_select_item}`} key={index}>
                            <div>
                              <input
                                type="checkbox"
                                onChange={() => handleCheckboxChange(item)}
                                checked={isProductSelected(item.productNo)}
                              />
                            </div>
                            <div>
                              <div>
                                <div><img src={item.productMainImage} alt="상품이미지" /></div>
                              </div>
                              <div>
                                <div><span>{item.middleCategory}</span></div>
                                <div><span>{item.productName}</span></div>
                                <div>
                                  <span>{item.productPrice ? item.productPrice.toLocaleString() : '가격 없음'}/</span>
                                  <span>{item.productsalePrice ? item.productsalePrice.toLocaleString() : '할인 가격 없음'}/</span>
                                  <span>{item.discountRate ? item.discountRate.toLocaleString() + '%' : '할인 없음'}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div>
                        {selectedProducts.map((product, index) => (
                          <div className={`${admin.admin_event_setting_plan_product_select_item_choice}`} key={index}>
                            <div>
                              <input
                                type="checkbox"
                                onChange={() => handleCheckboxChange(product)}
                                checked={selectedProducts.includes(product)}
                              />
                            </div>
                            <div>
                              <div>
                                <div><img src={product.productMainImage} alt="상품이미지" /></div>
                              </div>
                              <div>
                                <div><span>{product.middleCategory}</span></div>
                                <div><span>{product.productName}</span></div>
                                <div>
                                  <span>{product.productPrice ? product.productPrice.toLocaleString() : '가격 없음'}/</span>
                                  <span>{product.productsalePrice ? product.productsalePrice.toLocaleString() : '할인 가격 없음'}/</span>
                                  <span>{product.discountRate ? product.discountRate.toLocaleString() + '%' : '할인 없음'}</span>
                                </div>
                              </div>
                            </div>
                            <div className={`${admin.admin_event_setting_plan_product_rate}`}>
                              <div>
                                <div><span>할인금액</span></div>
                                <div>
                                  <input
                                    type="number"
                                    value={product.eventRatePrice}
                                    onChange={(e) => handleEventRatePriceChange(product.productNo, parseFloat(e.target.value))}
                                  />
                                  <span>원</span>
                                </div>
                              </div>
                              <div>
                                <div><span>할인율</span></div>
                                <div>
                                  <input
                                    type="number"
                                    value={product.eventRate}
                                    onChange={(e) => handleEventRateChange(product.productNo, parseFloat(e.target.value))}
                                  />
                                  <span>%</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>

                  }
                </div>
              }
            </div>
            <div className={`${admin.admin_event_setting_add_modal_footer}`}>
              <div><button onClick={handleUpdateCancel}>취소</button></div>
              <div><button onClick={handleEventUpdateBtn}>이벤트 수정</button></div>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default AdminEvent;
