import React from 'react'
import { Link, useNavigate} from 'react-router-dom'

const StoreHeader = ( {activeCategory, backNavigate, store} ) => {
  return (
    <>
      <div className=''>
        {/* <div className='position-fixed'> */}
          <div className={`${store.store_header}`}>
            <div>
              <button className='support_header_btn' onClick={() => backNavigate()}>
                <span className="material-symbols-outlined">arrow_back_ios</span>
              </button>
            </div>
            <div className=''>
              <span>
                스토어
              </span>
            </div>
          </div>
        </div>
      {/* </div> */}
    </>
  )
}

export default StoreHeader