import React, { useContext, useEffect, useState } from 'react';
import { TimePicker } from 'antd';
import dayjs from 'dayjs';
import * as sellerapi from '../../../../../apis/seller';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import seller from '../../../../seller.module.css';
import { LoginContext } from '../../../../../contexts/LoginContextProvider';
import { fi } from 'date-fns/locale';

const SellerStoreOperating = () => {
    const { isLogin, sellerlogout, userInfo, loginCheck, roles } = useContext(LoginContext);
    const format = 'HH:mm';
    const MySwal = withReactContent(Swal);

    const [userTimeSelect, setUserTimeSelect] = useState([]);
    const [serverData, setServerData] = useState(false);

    useEffect(() => {
        if(userInfo) {
            fetchData();
        }
    }, [userInfo]);

    const fetchData = async () => {
        try {
            const response = await fetch(`/api/seller/operatingTime/${userInfo && userInfo?.userId}`);
            const data = await response.json();

            // console.log("data : ", data);
            if (data && data.length > 0) {
                const formattedData = data.map(item => ({
                    ...item,
                    openTime: dayjs(item.openTime, format),
                    closeTime: dayjs(item.closeTime, format)
                }));
                setUserTimeSelect(formattedData);
                setServerData(true);
            } else {
                setUserTimeSelect([
                    { operatingNo: 1, dayName: '월요일', openTime: dayjs('00:00', format), closeTime: dayjs('00:00', format), userId: `${userInfo && userInfo?.userId}`, holidayState: false },
                    { operatingNo: 2, dayName: '화요일', openTime: dayjs('00:00', format), closeTime: dayjs('00:00', format), userId: `${userInfo && userInfo?.userId}`, holidayState: false },
                    { operatingNo: 3, dayName: '수요일', openTime: dayjs('00:00', format), closeTime: dayjs('00:00', format), userId: `${userInfo && userInfo?.userId}`, holidayState: false },
                    { operatingNo: 4, dayName: '목요일', openTime: dayjs('00:00', format), closeTime: dayjs('00:00', format), userId: `${userInfo && userInfo?.userId}`, holidayState: false },
                    { operatingNo: 5, dayName: '금요일', openTime: dayjs('00:00', format), closeTime: dayjs('00:00', format), userId: `${userInfo && userInfo?.userId}`, holidayState: false },
                    { operatingNo: 6, dayName: '토요일', openTime: dayjs('00:00', format), closeTime: dayjs('00:00', format), userId: `${userInfo && userInfo?.userId}`, holidayState: false },
                    { operatingNo: 7, dayName: '일요일', openTime: dayjs('00:00', format), closeTime: dayjs('00:00', format), userId: `${userInfo && userInfo?.userId}`, holidayState: false },
                ]);
                setServerData(false);
            }
        } catch (error) {
            console.error('Error fetching operating hours:', error);
        }
    };

    const handleTimeChange = (time, dayIndex, type) => {
        if (!time || !time.isValid()) {
            console.error('Invalid time:', time);
            return;
        }
    
        // console.log('Time change:', { dayIndex, type, time });
        const updatedTimeSelect = [...userTimeSelect];
        updatedTimeSelect[dayIndex] = {
            ...updatedTimeSelect[dayIndex],
            [type]: time
        };
        setUserTimeSelect(updatedTimeSelect);
        // console.log('Updated time select:', updatedTimeSelect);
    };

    const handleSave = () => {
        const dataToSave = userTimeSelect.map(item => ({
            ...item,
            openTime: item.openTime.format(format),
            closeTime: item.closeTime.format(format)
        }));
        // console.log('Saving operating hours:', dataToSave);
        operatingTimeSave(dataToSave);
    };

    function operatingTimeSave(dataToSave) {
        MySwal.fire({
            customClass: {
                popup: `${seller.seller_operating_add_popup}`
            },
            html : 
            `
                <div>
                    <div class='${seller.seller_operating_add_title}'><span>운영시간을 등록 하시겠습니까?</span></div>
                    <div class='${seller.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                      <button class='mx-1' id='seller_add_cancel'>취소</button>
                      <button class='mx-1' id='seller_add_success'>등록</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });
        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);
        
        async function sellerAddSuccess(e) {
            if(e.target && e.target.id == 'seller_add_success') {
                const response = await sellerapi.operatingCreate(userInfo && userInfo?.userId, dataToSave);
                const data = response.data;

                // console.log('dataSuccess : ', data);

                if(response.status === 200) {
                    fetchData();
                }
                MySwal.fire({
                    customClass: {
                        popup: `${seller.admin_seller_add_popup}`
                    },
                    html : 
                    `
                    <div>
                    <div class='${seller.admin_seller_add_title}'><span>운영 시간 등록이 완료 되었습니다.</span></div>
                    <div class='${seller.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                    <button class='mx-1' id='seller_add_confirm'>확인</button>
                    </div>
                    </div>
                    `,
                    showConfirmButton: false,
                    showCancelButton: false,
                });
                document.addEventListener('click', sellerAddConfirm);
                document.removeEventListener('click', sellerAddSuccess);
                
                function sellerAddConfirm(e) {
                    if(e.target && e.target.id == 'seller_add_confirm') {
                        MySwal.close();
                        document.removeEventListener('click', sellerAddConfirm);
                    }
                }
            }
        }
        
        function sellerAddCancel(e) {
            if(e.target && e.target.id == 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddCancel);
            }
        }
        
    }

    // console.log('Rendering with state:', userTimeSelect);

    return (
        <div className={`${seller.seller_right_container} px-3 py-3`}>
            <div>
                <div><span>운영시간</span></div>
                <div className={`${seller.seller_store_operating_box}`}>
                    {Array.isArray(userTimeSelect) && userTimeSelect.map((item, index) => (
                        <div key={index} className={`${seller.seller_store_operating_date_box}`}>
                            <div><span>{item.dayName}</span></div>
                            <div className={`${seller.seller_store_operating_time_select_box}`}>
                                <div>
                                    <TimePicker
                                        value={item.openTime}
                                        format={format}
                                        onChange={(time, timeString) => {
                                            // console.log('Open Time Change:', time, timeString);
                                            handleTimeChange(time, index, 'openTime');
                                        }}
                                        className={`${seller.seller_timepicker_select}`}
                                        disabled={item.holidayState}
                                    />
                                </div>
                                <div><span>&nbsp;~&nbsp;</span></div>
                                <div>
                                    <TimePicker
                                        value={item.closeTime}
                                        format={format}
                                        onChange={(time, timeString) => {
                                            // console.log('Close Time Change:', time, timeString);
                                            handleTimeChange(time, index, 'closeTime');
                                        }}
                                        className={`${seller.seller_timepicker_select}`}
                                        disabled={item.holidayState}
                                    />
                                </div>
                            </div>
                            <div>
                                <input
                                    type="checkbox"
                                    name='holiday'
                                    id={`holiday-${index}`}
                                    checked={item.holidayState}
                                    onChange={() => {
                                        const updatedTimeSelect = [...userTimeSelect];
                                        updatedTimeSelect[index].holidayState = !updatedTimeSelect[index].holidayState;
                                        setUserTimeSelect(updatedTimeSelect);
                                    }}
                                />
                                <label className='ps-1' htmlFor={`holiday-${index}`}>정기휴무</label>
                            </div>
                        </div>
                    ))}
                </div>
                <div className={`${seller.seller_store_operating_add_btn}`}>
                    <button onClick={handleSave}>{ serverData ? '운영시간 수정' : '운영시간 저장' }</button>
                </div>
            </div>
        </div>
    );
};

export default SellerStoreOperating;
