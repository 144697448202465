import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';

const AddressCountry = ({handleIntenationalCountryClose, showInternationalCountry, searchTermInternational, setSearchTermInternational, countries,filteredCountries, setFilteredCountries, setInternationalSelectedCountry, handleInternationalSelectedCountry}) => {
    const props = useSpring({ 
        to: { opacity: 1, transform: 'scale(1) translate(-50%, -50%)' }, 
        from: { opacity: 0, transform: 'scale(0) translate(-50%, -50%)' } 
    });



    useEffect(() => {
        setFilteredCountries(
            countries.filter((country) =>
                country?.name.toLowerCase().includes(searchTermInternational.toLowerCase()) || // 이름으로 검색
                country?.number.includes(searchTermInternational) || // 전화번호로 검색
                country?.krname.includes(searchTermInternational) || // 한글로 검색
                country?.code.includes(searchTermInternational) // 국가코드로 검색
            )
        );
    }, [searchTermInternational]);

    return (
        <>
            {
                showInternationalCountry &&
                <div className='country_container'>
                    <animated.div className='country_box' style={props}>
                        <div>
                            <div className='d-flex justify-content-center py-4 px-2 position-relative'>
                                <div className='pt-3'><span>Select a Country</span></div>
                                <div className='pe-3 position-absolute top-0 end-0 pt-3'><button className='region_close' onClick={() => handleIntenationalCountryClose()}><span className="material-symbols-outlined">close</span></button></div>
                            </div>
                        </div>
                        <div>
                            <div className='country_search_box'>
                                <input type="text" placeholder='Search' onChange={event => setSearchTermInternational(event.target.value)} />
                                <span className="material-symbols-outlined">search</span>
                            </div>
                        </div>
                        <div className='py-3 px-4'>
                            <div className='country_list'>
                                {filteredCountries.map((country, index) => (
                                    <button key={index} className='d-flex' onClick={() => {setInternationalSelectedCountry(country); handleInternationalSelectedCountry(country); handleIntenationalCountryClose();}}>
                                        <div className='country_img'><img src={country?.img} alt={country?.name} /></div>
                                        <div className='country_name'><span>{country?.name}</span> <span>({country?.code})</span></div>
                                        <div className='country_number'><span>{country?.number}</span></div>
                                    </button>
                                ))}
                            </div>
                        </div>
                    </animated.div>
                </div>
            }
        </>
    );
};

export default AddressCountry;
