import React, { useState } from 'react'
import { Pagination } from 'antd';

const SellerCaculatorMain = ( {seller} ) => {

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);

  // 페이지 번호 클릭 시 호출될 함수
  const paginate = pageNumber => setCurrentPage(pageNumber);

  return (
    <div className={`px-3 ${seller.seller_right_container}`}>
      <div>
        <div className='pt-3 pb-2 border-bottom'><span>정산 관리</span></div>
        <div className={`pt-4 pb-1 ${seller.seller_border_bottom_color}`}><span>정산 내역</span></div>
        <div></div>
        <div className='w-100 text-center py-5 dd-fs-7 gray_color'>
              <div><span>등록된 내역이 없습니다.</span></div>
        </div>
        <Pagination current={currentPage} total={totalItems} onChange={paginate} pageSize={itemsPerPage} />
      </div>
    </div>
  )
}

export default SellerCaculatorMain