import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ss from '../../../../containers/market/support/venue/support_shop.module.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Image } from 'antd';
import 'antd/es/style/reset.css';

const VenueRental = ({ category, onHeartClick, supportData, date, userInfo, activeSubCategory }) => {
  const safeSupportData = Array.isArray(supportData) ? supportData : [];
  const [userId, setUserId] = useState('');
  const [heartClicked, setHeartClicked] = useState({});
  const [currentPreviewIndex, setCurrentPreviewIndex] = useState(null); // null로 초기화하여 프리뷰가 열리지 않은 상태로 시작

  useEffect(() => {
    if (userInfo?.userId) {
      setUserId(userInfo.userId);
    }
  }, [userInfo]);

  const filteredSupportData = activeSubCategory === 'all' 
  ? safeSupportData.filter(
    (support) =>
      support.supportSaleState === '판매중' && 
      support.supportState === '승인완료'
  )
  : safeSupportData.filter(
      (support) => 
        support.supportSaleState === '판매중' && 
        support.supportState === '승인완료' &&
        support.subCategory === activeSubCategory
    );

  const getMinPrice = (items) => {
    if (!Array.isArray(items) || items.length === 0) return null;
    return Math.min(...items.map((item) => item.mainItemPrice));
  };

  const initialHeartState = filteredSupportData.reduce((acc, support) => {
    acc[support.productNo] = false;
    return acc;
  }, {});

  const fetchWishlist = useCallback(async () => {
    if (!userId) return [];

    try {
      const response = await fetch(`/api/support/wishlist/${userId}`);
      const data = await response.json();
      return Array.isArray(data) ? data : [];
    } catch (error) {
      console.error('Failed to fetch wishlist:', error);
      return [];
    }
  }, [userId]);

  useEffect(() => {
    if (userId) {
      fetchWishlist().then((wishlist) => {
        const newHeartState = { ...initialHeartState };
        wishlist.forEach((item) => {
          newHeartState[item.productNo] = true;
        });
        setHeartClicked(newHeartState);
      });
    }
  }, [fetchWishlist, userId]);

  const handleHeartClick = async (productNo) => {
    if (!userId) {
      toast.error('로그인 후 사용해 주세요');
      return;
    }

    setHeartClicked((prevState) => {
      const newState = {
        ...prevState,
        [productNo]: !prevState[productNo],
      };
      return newState;
    });

    const isFavorite = !heartClicked[productNo];

    const url = isFavorite ? '/api/support/addWishlist' : '/api/support/removeWishlist';
    await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId, productNo }),
    });

    onHeartClick(productNo);
  };

  const formatRating = (rating) => {
    return rating.toFixed(1);
  };

  const handleImageClick = (index) => {
    setCurrentPreviewIndex(index); // 클릭된 이미지 인덱스를 설정하여 프리뷰에서 시작 이미지로 설정
  };

  return (
    <div className="venue">
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastClassName={`${ss.custom_toast}`}
      />
      <div className="d-flex justify-content-between venue_total py-1 px-3">
        <span>
          전체<span className="product_count ps-1">{filteredSupportData.length}</span>
        </span>
        <span>
          <button className="recent">
            기본순<span className="material-symbols-outlined">expand_more</span>
          </button>
        </span>
      </div>
      <div className="venue_product_box pt-3 px-2">
        {filteredSupportData.map((support) => {
          const minPrice = getMinPrice(support.mainItem);
          const hasOptions = Array.isArray(support.mainItem) && support.mainItem.length > 0;
          const isHeartClicked = heartClicked[support.productNo];

          // 이미지 그룹 생성
          const images = [
            support.representativeImage,
            support.listImage1,
            support.listImage2,
            support.listImage3,
            support.listImage4,
          ].filter(Boolean); // 유효한 이미지들만 포함

          return (
            <div className="position-relative px-2" key={support.productNo}>
              <Link
                className="venue_product"
                to={`/venue/${support.productNumber}`}
                state={{ category: category, support: support, date: date }}
                onClick={(e) => {
                  if (currentPreviewIndex !== null) {
                    e.preventDefault(); // 프리뷰가 열려 있을 때 링크 실행 방지
                  }
                }}
              >
                <div className={`search_content_support_info ${ss.support_content_info}`}>
                  <div>
                    <div>
                      <img src={support.shopProfile ? support.shopProfile : `${process.env.PUBLIC_URL}/img/shop/default_profile.png`} alt="" />
                    </div>
                    <div>
                      <span>{support.productName}</span>
                    </div>
                  </div>
                  <div>
                    <span>{support.shopInfo}</span>
                  </div>
                  <div className={`${ss.support_content_price_rate_star_box}`}>
                    <div>
                      <div>
                        <img src="../img/icon/star_fill.png" alt="별점" />
                      </div>
                      <div className="ps-1">
                        <span>{formatRating(support.reviewRating)}</span>
                      </div>
                    </div>
                    <div>
                      <span>리뷰</span>
                      <span>{support.reviewCount}</span>
                    </div>
                    <div>
                      {minPrice !== null && (
                        <span>
                          &#8361;{minPrice.toLocaleString()}{hasOptions ? ' ~' : ''}
                        </span>
                      )}
                    </div>
                  </div>
                  <div>
                    {/* PreviewGroup을 사용하여 이미지 그룹으로 프리뷰 */}
                    <Image.PreviewGroup
                      preview={{
                        current: currentPreviewIndex, // 시작 프리뷰 이미지 인덱스 설정
                        onChange: (current) => setCurrentPreviewIndex(current), // 프리뷰 이미지가 변경될 때 인덱스 업데이트
                        onVisibleChange: (visible) => {
                          if (!visible) setCurrentPreviewIndex(null); // 프리뷰가 닫히면 인덱스를 null로 초기화
                        },
                      }}
                    >
                      <Swiper 
                          slidesPerView={"auto"} 
                          spaceBetween={10} 
                          modules={[Pagination, Navigation]}
                          className='search_content_support_item'
                        >
                        {images.map((image, index) => (
                          <SwiperSlide key={index}>
                            <div className="search_content_support_item_img">
                              <Image
                                src={image}
                                alt={`이미지${index + 1}`}
                                onClick={(e) => {
                                  e.preventDefault(); // 링크 기본 동작 방지
                                  e.stopPropagation(); // 이벤트 전파 방지
                                  handleImageClick(index); // 클릭된 이미지로부터 프리뷰 시작
                                }}
                              />
                            </div>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </Image.PreviewGroup>
                  </div>
                </div>
              </Link>
              <div className="position-absolute pe-2 pb-2 z-3 mt-2 heart_box">
                <button
                  className="heart_btn"
                  onClick={(e) => {
                    e.preventDefault();
                    handleHeartClick(support.productNo);
                  }}
                >
                  {isHeartClicked ? (
                    <img className="icon_size" src="../img/icon/venue/heart_fill.png" alt="찜O" />
                  ) : (
                    <img className="icon_size" src="../img/icon/venue/white_heart.png" alt="찜X" />
                  )}
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default VenueRental;
