import React, { useEffect, useState } from 'react';
import { animated } from 'react-spring';
import { useTransition } from '@react-spring/core';
import { useDropzone } from "react-dropzone";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import * as adminApi from '../../apis/admin';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const CustomerWrite = ({ dailyduck, showCustomerWrite, setShowCustomerWrite, userInfo, support, inquirySelect }) => {

    const MySwal = withReactContent(Swal);
    const [images, setImages] = useState([]);
    const [ceoSay, setCeoSay] = useState('');
    const [inquiryContent, setInquiryContent] = useState({});

    let message = '문의 내용을 작성해주세요.';

    useEffect(() => {
        setInquiryContent({
            ...inquiryContent, userId: userInfo?.userId
        });
    }, [userInfo]);

    const stripHtml = (html) => {
        const div = document.createElement('div');
        div.innerHTML = html;
        return div.textContent || div.innerText || '';
    }

    const onInqueryCon = (e) => {
        const plainText = stripHtml(e);
        if (plainText.length <= maxLength) {
            setCeoSay(e);
            setInquiryContent({
                ...inquiryContent, inquiryContent: e
            });
        } else {
            const truncatedText = plainText.substring(0, maxLength);
            const div = document.createElement('div');
            div.textContent = truncatedText;
            setCeoSay(div.innerHTML);
            setInquiryContent({
                ...inquiryContent, inquiryContent: div.innerHTML
            });
        }
    };

    const maxLength = 500;

    const transitions = useTransition(showCustomerWrite, {
        from: { transform: "translateX(100%)" },
        enter: { transform: "translateX(0)" },
        leave: { transform: "translateX(100%)" },
        config: {
            duration: 300,
        },
    });
    
    const handleCSWBack = () => {
        setShowCustomerWrite(false);
    }

    const { getRootProps, getInputProps } = useDropzone({
        accept: "image/*",
        onDrop: (acceptedFiles) => {
            const newFiles = acceptedFiles.slice(0, 4 - images.length); // 기존 이미지 갯수를 고려해 최대 4개까지만 추가
    
            if (newFiles.length === 0) {
                alert("이미 4개의 이미지가 등록되었습니다.");
                return;
            }
    
            // console.log('filteredFiles : ', newFiles);
    
            const formData = new FormData();
    
            for (const file of newFiles) {
                if (file.size > 5 * 1024 * 1024) {
                    alert("파일 크기는 5MB를 초과할 수 없습니다.");
                    return;
                } else {
                    formData.append('images', file);
                }
            }
    
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            };
    
            imgUpload(formData, config);
        },
    });
    
    const imgUpload = async (formData, config) => {
        const response = await support.uploadImagesToServer(formData, config);
        const data = response.data;
    
        const imageUrls = data.map(file => `${process.env.REACT_APP_API_SERVER}/img?file=${file}`);
    
        // 현재 이미지 개수 확인 후 새로운 이미지를 할당
        setInquiryContent((prevContent) => {
            const updatedInquiryContent = { ...prevContent };
            
            imageUrls.forEach((url, index) => {
                const imgField = `inquiryImg${images.length + index + 1}`;
                updatedInquiryContent[imgField] = url;
            });
            
            return updatedInquiryContent;
        });
    
        setImages((prevImages) => [
            ...prevImages,
            ...imageUrls.slice(0, 4 - prevImages.length).map((url, index) => ({ preview: url, id: prevImages.length + index })),
        ]);
    };
    

    const removeImage = async (index) => {
        try {
            const imageToRemove = images[index].preview;
            const queryParams = imageToRemove.split('?')[1];
            let filePath = '';
            const params = queryParams.split('&');
            for (let param of params) {
                if (param.startsWith('file=')) {
                    filePath = decodeURIComponent(param.split('=')[1]);
                    break;
                }
            }
            const fileName = filePath.split('/').pop();
            // console.log('fileName : ' + fileName);

            await fetch(`/api/file/delete/${fileName}`, {
                method: 'DELETE',
            });

            const newImages = [...images];
            newImages.splice(index, 1);
            setImages(newImages);

            const updatedInquiryContent = { ...inquiryContent };
            for (let i = index + 1; i <= 4; i++) {
                updatedInquiryContent[`inquiryImg${i}`] = updatedInquiryContent[`inquiryImg${i + 1}`] || '';
            }
            delete updatedInquiryContent[`inquiryImg5`];
            setInquiryContent(updatedInquiryContent);

            // console.log('newImages : ', newImages);
        } catch (error) {
            console.error('Failed to delete image from server', error);
        }
    };

    useEffect(() => {
        // console.log('inquiryContent : ', inquiryContent);
        // console.log('images : ', images);
    }, [inquiryContent, images])

    const onInquiryTitle = (e) => {
        setInquiryContent({
            ...inquiryContent, inquiryTitle: e.target.value
        })
    }

    const onInquirySave = async () => {
        MySwal.fire({
            customClass: {
                popup: `${dailyduck.inquiry_popup}`
            },
            html:
                `
                <div>
                    <div class='${dailyduck.inquiry_popup_title}'><span>1:1 문의를 등록 하시겠습니까?</span></div>
                    <div class='${dailyduck.inquiry_popup_btn_box}'>
                        <button class='mx-1' id='seller_add_cancel'>취소</button>
                        <button class='mx-1' id='seller_add_success'>등록</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });
        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);

        function sellerAddSuccess(e) {
            if (e.target && e.target.id == 'seller_add_success') {
                inquiryAdd();
            }
        }

        const inquiryAdd = async () => {
            const response = await support.inquiryAdd(inquiryContent);
            const data = response.data;

            if (data === 'SUCCESS') {
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
                
                MySwal.fire({
                    customClass: {
                        popup: `${dailyduck.inquiry_popup}`
                    },
                    html:
                    `
                    <div>
                    <div class='${dailyduck.inquiry_popup_title}'><span>1:1 문의가 등록되었습니다.</span></div>
                    <div class='${dailyduck.inquiry_popup_confirm_btn_box}'>
                    <button id='seller_add_confirm'>확인</button>
                    </div>
                    </div>
                    `,
                    showConfirmButton: false,
                    showCancelButton: false,
                });
                document.addEventListener('click', sellerAddConfirm);
                
                function sellerAddConfirm(e) {
                    if (e.target && e.target.id == 'seller_add_confirm') {
                        setShowCustomerWrite(false);
                        inquirySelect();
                        MySwal.close();
                        document.removeEventListener('click', sellerAddCancel);
                        document.removeEventListener('click', sellerAddSuccess);
                        document.removeEventListener('click', sellerAddConfirm);
                    }
                }
            } else {
                alert('등록에 실패하였습니다')
            }
        }

        function sellerAddCancel(e) {
            if (e.target && e.target.id == 'seller_add_cancel') {
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);

                MySwal.close();
            }
        }
    }

    return (
        <>
            {
                showCustomerWrite &&
                <div className={`${dailyduck.my_customner_write_container}`}>
                    {transitions((style, item) =>
                        item ?
                            <animated.div className={dailyduck.my_customner_write_box} style={style}>
                                <div className={dailyduck.my_customner_write_header}>
                                    <div>
                                        <button onClick={() => handleCSWBack()}>
                                            <span className="material-symbols-outlined">arrow_back_ios</span>
                                        </button>
                                    </div>
                                    <div>
                                        <span>1:1 문의 작성</span>
                                    </div>
                                </div>
                                <div className={dailyduck.my_customner_write_content}>
                                    <div className='py-3'>
                                        <div><span>제목</span></div>
                                        <div><input type="text" className='join_input_tag' onChange={(e) => onInquiryTitle(e)} /></div>
                                    </div>
                                    <div className='py-3'>
                                        <div><span>문의내용</span></div>
                                        <div className={`${dailyduck.my_review_write_textbox}`}>
                                            <ReactQuill
                                                theme="snow"
                                                value={ceoSay}
                                                onChange={(e) => onInqueryCon(e)}
                                                className={dailyduck.quill}
                                            />
                                            <div  className={`${dailyduck.my_review_write_text_counter}`}>{`${stripHtml(ceoSay).length}/${maxLength}`}</div>
                                        </div>
                                    </div>
                                    <div className='py-3 bg-white'>
                                        <div className='d-flex justify-content-between pb-1'>
                                            <div><span>사진(선택)</span></div>
                                            <div><span className='gray_round'>선택</span></div>
                                        </div>
                                        <div className='request_input_img'>
                                            <div className='request_drop'>
                                                <div className="drop_box" {...getRootProps()} >
                                                    <input {...getInputProps()} />
                                                    <span className="material-symbols-outlined">add</span>
                                                </div>
                                            </div>
                                            <div className='request_slider'>
                                                <Swiper
                                                    slidesPerView={'auto'}
                                                    spaceBetween={10}
                                                    freeMode={true}
                                                    className="mySwiper"
                                                >
                                                    {images.map((image, index) => (
                                                        <SwiperSlide key={index}>
                                                            <div className='request_slider_imgBox'>
                                                                <img src={image.preview} alt={`preview ${index}`} />
                                                                <button onClick={() => removeImage(index)}><span className="material-symbols-outlined">close</span></button>
                                                            </div>
                                                        </SwiperSlide>
                                                    ))}
                                                </Swiper>
                                            </div>
                                        </div>
                                        <div className='pt-2 pb-4 border-bottom'><span>*사진은 최대 4개까지 등록 가능합니다.</span></div>
                                    </div>
                                </div>
                                <div className={`${dailyduck.my_review_write_success_btn}`}>
                                    <button onClick={onInquirySave}>등록하기</button>
                                </div>
                            </animated.div>
                            : null
                    )}
                </div>
            }
        </>
    )
}

export default CustomerWrite;
