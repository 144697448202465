import React, { useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

// import required modules
import { FreeMode, Pagination } from 'swiper/modules';

const SupportCategory = ( {category, categoryList} ) => {

  const categoryRefs = {
    광고: useRef(null),
    렌트: useRef(null),
    장소대관: useRef(null),
    케이터링: useRef(null),
    응원차: useRef(null),
    화환: useRef(null),
    선물포장: useRef(null),
    기부: useRef(null)
    // 빅스케일: useRef(null),
  };

  // useEffect(() => {
  //   if (categoryRefs[category]) {
  //     categoryRefs[category].current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
  //   }
  // }, [category]);

  useEffect(() => {
    // console.log('supportCategory : ', category);
  }, [category])
  

  return (
    <div className='support_category'>
      <Swiper
                      slidesPerView={'auto'}
                      spaceBetween={10}
                      freeMode={true}
                      className="my_category"
      >
        {
          categoryList.map((item, index) => (
            <SwiperSlide>
              <Link to={`/support/${item.dailyduckSupportcategoryEngName}`} ref={categoryRefs[item.dailyduckSupportcategoryName]} className={`support ${category === item.dailyduckSupportcategoryEngName ? 'check' : 'noncheck'}`} >
                {item.dailyduckSupportcategoryName}
              </Link>
            </SwiperSlide>
          ))
        }
        {/* <SwiperSlide>
          <Link to={`/support/${'장소대관'}`} ref={categoryRefs['장소대관']} className={`support ${category === '장소대관' ? 'check' : 'noncheck'}`}>
            장소대관
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link to={`/support/${'응원차'}`} ref={categoryRefs['응원차']} className={`support ${category === '응원차' ? 'check' : 'noncheck'}`}>
            응원차
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link to={`/support/${'케이터링'}`} ref={categoryRefs['케이터링']} className={`support ${category === '케이터링' ? 'check' : 'noncheck'}`}>
            케이터링
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link to={`/support/${'화환'}`} ref={categoryRefs['화환']} className={`support ${category === '화환' ? 'check' : 'noncheck'}`}>
            화환
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link to={`/support/${'선물포장'}`} ref={categoryRefs['선물포장']} className={`support ${category === '선물포장' ? 'check' : 'noncheck'}`}>
            선물포장
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link to={`/support/${'광고'}`} ref={categoryRefs['광고']} className={`support ${category === '광고' ? 'check' : 'noncheck'}`}>
            광고
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link to={`/support/${'기부'}`} ref={categoryRefs['기부']} className={`support ${category === '기부' ? 'check' : 'noncheck'}`}>
            기부
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link to={`/support/${'빅스케일'}`} ref={categoryRefs['빅스케일']} className={`support ${category === '빅스케일' ? 'check' : 'noncheck'}`}>
            빅스케일
          </Link>
        </SwiperSlide> */}
      </Swiper>
    </div>
  )
}

export default SupportCategory