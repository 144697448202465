import React, { useEffect } from 'react';
import { animated } from 'react-spring';
import { useSpring, useTransition } from '@react-spring/core';

const FavorityDdayUpdate = ( {showFavorityDdayUpdate, setShowFavorityDdayUpdate, dailyduck, myFavorityUpdate, onDdayUpdateInput, successUpdate} ) => {
    const handleBack = () => {
        setShowFavorityDdayUpdate(false);
    }

    const transitions = useTransition(showFavorityDdayUpdate, {
        from: { transform: "translateY(100%)" },
        enter: { transform: "translateY(0)" },
        leave: { transform: "translateY(100%)" },
        config: {
            duration: 300,
        },
    });

    return (
        <>
            {
                showFavorityDdayUpdate && 
                <div className={`${dailyduck.favority_dday_background}`}>
                    {transitions((style, item) =>
                    item ? 
                        <animated.div className={dailyduck.myfavority_box} style={style}>
                            <div className={`${dailyduck.favority_dday_container}`}>
                                <div className='d-flex justify-content-between py-3 px-3 mt-3'>
                                    <div><span className={`${dailyduck.favority_update_title}`}>D-DAY 수정</span></div>
                                    <div><button onClick={handleBack} className={`${dailyduck.favority_update_close_btn}`}><span className="material-symbols-outlined">close</span></button></div>
                                </div>
                                <div className='py-3 px-3'>
                                    <div>
                                        <span className={`${dailyduck.gray_color}`}>최애({myFavorityUpdate ? myFavorityUpdate.favorityName : ''})와의 기념일</span>
                                    </div>
                                    <div className='position-relative'>
                                        <input type="number" className={`${dailyduck.favority_dday_update_input}`} placeholder={`${myFavorityUpdate ? myFavorityUpdate.favorityDday : '1'}`} onChange={(e) => onDdayUpdateInput(e)}/>
                                        <span className={`${dailyduck.favority_dday_update_text}`}>D+</span>
                                    </div>
                                </div>
                                <div className={`${dailyduck.favority_update_btn} px-3 pt-5`}>
                                    <button onClick={() => successUpdate(myFavorityUpdate.favorityNo)}>완료</button>
                                </div>
                            </div>
                        </animated.div>
                        : null
                        )
                    };
                </div>
            }
        </>
    )
}

export default FavorityDdayUpdate