import React, { useEffect, useState } from 'react'
import { animated } from 'react-spring';
import { useSpring, useTransition } from '@react-spring/core';
import dailyduck from './MyContainner.module.css'
import { useNavigate } from 'react-router-dom';
import MyTermsDetail from '../../components/my/MyTermsDetail';
import * as auth from '../../apis/auth';

const MyTerms = ( {showTerms, setShowTerms} ) => {

    const navigate = useNavigate();
    const [ showTermsDetail, setShowTermsDetail ] = useState(false);
    const [ termsData, setTeramsData ] = useState({});
    const [ termsList, setTermsList ] = useState([]) 

    useEffect(() => {
        termsSelect();
    }, []);

    const onTerms = (item) => {
        setTeramsData(item);
        setShowTermsDetail(true);
      }

    const handleTermsBack = () => {
        navigate('/my');
    }

    const termsSelect = async () => {
        const response = await auth.termsSelect();
        const data = response.data;

        // console.log('terms : ', data);
        setTermsList(data);
    }
  return (
    <>
        {
            <div className={`${dailyduck.my_terms_container}`}>
                    <div className={dailyduck.my_terms_box}>
                        <div className={dailyduck.my_terms_header}>
                            <div >
                                <button onClick={() => handleTermsBack()}>
                                    <span className="material-symbols-outlined">arrow_back_ios</span>
                                </button>
                            </div>
                            <div>
                                <div>
                                    <span>이용약관</span>
                                </div>
                            </div>
                        </div>
                        <div className={dailyduck.my_terms_content}>
                            {
                                termsList.map((item, index) => (
                                    <button className={`${dailyduck.my_terms_btn}`} onClick={() => onTerms(item)} key={index}>
                                        <div>
                                            <div><span>{item.termsTitle}</span></div>
                                            <div><span>{item.termsRegDate}</span></div>
                                        </div>
                                        <div><span className="material-symbols-outlined">arrow_forward_ios</span></div>
                                    </button>
                                ))
                            }
                        </div>
                    </div>
                </div>
          }
          {
            showTermsDetail &&
            <MyTermsDetail dailyduck={dailyduck} showTermsDetail={showTermsDetail} setShowTermsDetail={setShowTermsDetail} setShowTerms={setShowTerms} 
                           termsData={termsData}
            />
          }
      </>
  )
}

export default MyTerms