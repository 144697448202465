import React, { useState, useEffect, useContext } from 'react';
import { useSpring, animated } from 'react-spring';
import Select from "react-select";
import PaymentInfo from '../info/PaymentInfo';
import { useLocation, useNavigate } from 'react-router-dom';
import * as auth from '../../../../apis/auth';
import { LoginContext } from '../../../../contexts/LoginContextProvider';
import * as support from '../../../../apis/support';
import { format } from 'date-fns';
import axios from 'axios';
import ss from '../../../../containers/market/support/venue/support_shop.module.css';
import { loadTossPayments, ANONYMOUS } from '@tosspayments/tosspayments-sdk';
import store from '../../../../containers/market/store/store.module.css';

const generateRandomString = () => window.btoa(Math.random()).slice(0, 20);

const PaymentContainer = () => {
    const navigate = useNavigate();
    const { userInfo } = useContext(LoginContext);
    const location = useLocation();
    const reservationList = location.state?.reservationList || [];
    const venueDetail = location.state?.venueDetail || [];
    const [ user, setUser ] = useState();
    const [ activeButton, setActiveButton ] = useState('');
    const [ bankSelect, setBankSelect ] = useState('');
    const [ cashReceipt, setCashReceipt ] = useState('');
    const [ cashReceiptType, setCashReceiptType ] = useState('');
    const [ paymentMethod, setPaymentMethod ] = useState('');
    const [exchangeRate, setExchangeRate] = useState(null);
    const [priceInUSD, setPriceInUSD] = useState(0);

    const [selectedPayment, setSelectedPayment] = useState(null);
    const [point, setPoint] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [defaultPrice, setDefaultPrice] = useState(0);
    const [isChecked, setIsChecked] = useState(false);
    const [selectedInfo, setSelectedInfo] = useState(null);
    const totalPoint = user?.userPoint || 0;
    const [price, setPrice] = useState(0);
    const [couponDiscount, setCouponDiscount] = useState(0);
    const [ couponList, setCouponList ] = useState([]);

    const [ready, setReady] = useState(false);
    const [widgets, setWidgets] = useState(null);
    const [amount, setAmount] = useState({
        currency: "KRW",
        value: 50000,
    });

    const [options, setOptions] = useState([]);
    const [availableCoupons, setAvailableCoupons] = useState(0);
    const [selectionCoupon, setSelectionCoupon] = useState(null);
    const [selectedPaymentType, setSelectedPaymentType] = useState('domestic');

    // useEffect(() => {
    //     setAmount()
    // }, [reservationList]);

    useEffect(() => {
        if (reservationList.length > 0) {
            setAmount({
                currency: selectedPaymentType === 'domestic' ? "KRW" : "USD",
                value: calculateTotalPrice(), // 예약 데이터에 따른 결제 금액 계산 함수 사용
            });
        }
    }, [reservationList]);

    useEffect(() => {
        if (ready) {
            if(selectedPaymentType === 'domestic') {
                widgets.setAmount({
                    currency: "KRW",
                    value: totalPrice,
                });
            } else {
                widgets.setAmount({
                    currency: "USD",
                    value: totalPrice,
                });
            }
        }
    }, [totalPrice, ready]);

    useEffect(() => {
        const fetchPaymentWidgets = async () => {
            const tossPayments = await loadTossPayments(process.env.REACT_APP_TOSS_CLIENT_KEY);
            const widgets = tossPayments.widgets({ customerKey: ANONYMOUS });
            setWidgets(widgets);
        };

        fetchPaymentWidgets();
        couponSelect();
    }, []);

    const couponSelect = async () => {
        const response = await auth.userCouponSelect(userInfo && userInfo.userId);
        const data = response.data;

        console.log(data);
        setCouponList(data);
    };

    useEffect(() => {
        if (couponList.length > 0) {
            const availableCouponsList = couponList.filter(coupon => !coupon.userCouponUse && price >= coupon.couponDiscountCondition);
            const couponOptions = availableCouponsList.map(coupon => ({
                label: coupon.couponName,
                value: {
                    couponNo: coupon.couponNo,
                    couponNumber: coupon.couponNumber,
                    couponName: coupon.couponName,
                    couponRate: coupon.couponBenefitType === 'rate' ? coupon.couponBenefitNum : 0,
                    couponPrice: coupon.couponBenefitType === 'amount' ? coupon.couponBenefitNum : 0,
                    userId: userInfo.userId,
                    couponExpirationPeriod: coupon.couponEndDate,
                    couponApprovalDate: coupon.couponRegDate,
                    minPrice: coupon.couponDiscountCondition,
                    rateMaxPrice: coupon.couponMaximumDiscount,
                }
            }));
            setOptions([{ label: "사용안함", value: { couponNo: 1, couponName: "사용안함", couponRate: 0, couponPrice: 0 } }, ...couponOptions]);
            setAvailableCoupons(availableCouponsList.length);
        }
    }, [couponList, price, userInfo]);

    useEffect(() => {
        let isMounted = true;
    
        const renderPaymentWidgets = async () => {
            if (widgets) {
                // DOM 초기화 전에 null 확인
                // const domesticElement = document.getElementById("domestic-payment-method");
                // const overseasElement = document.getElementById("overseas-payment-method");
                const pamentElement = document.getElementById("payment-method");
                const agreementElement = document.getElementById("agreement");
    
                // if (domesticElement) {
                //     domesticElement.innerHTML = '';
                // }
                // if (overseasElement) {
                //     overseasElement.innerHTML = '';
                // }
                if (agreementElement) {
                    pamentElement.innerHTML = '';
                }
                if (agreementElement) {
                    agreementElement.innerHTML = '';
                }
            }
    
            try {
                const tossPayments = await loadTossPayments(process.env.REACT_APP_TOSS_CLIENT_KEY);
                const newWidgets = tossPayments.widgets({ customerKey: ANONYMOUS });
                
                if (isMounted) {
                    setWidgets(newWidgets);
                    await newWidgets.setAmount(amount);
    
                    // if (selectedPaymentType === 'domestic') {
                    //     await newWidgets.renderPaymentMethods({
                    //         selector: "#domestic-payment-method",
                    //         variantKey: "DEFAULT",
                    //     });
                    // } else if (selectedPaymentType === 'overseas') {
                        //     await newWidgets.renderPaymentMethods({
                            //         selector: "#overseas-payment-method",
                            //         variantKey: "overseas",
                            //     });
                            // }
                            
                    await newWidgets.renderPaymentMethods({
                        selector: "#payment-method",
                        variantKey: `${selectedPaymentType === 'domestic' ? "DEFAULT" : "overseas"}`,
                    });

                    await newWidgets.renderAgreement({
                        selector: "#agreement",
                        variantKey: "AGREEMENT",
                    });
                    setReady(true);
                }
            } catch (error) {
                console.error('Error rendering widgets:', error);
            }
        };
    
        renderPaymentWidgets();
    
        return () => {
            isMounted = false;
            if (widgets) {
                setWidgets(null);  // 상태 초기화
            }
        };
    }, [selectedPaymentType, amount]);
    
    useEffect(() => {
        let isMounted = true; // 마운트 상태를 체크합니다.
    
        const renderPaymentWidgets = async () => {
            if (!widgets) return;
    
            try {
                if (isMounted) {
                    await widgets.setAmount(amount);
    
                    await widgets.renderPaymentMethods({
                        selector: "#payment-method",
                        variantKey: "DEFAULT",
                    });
    
                    await widgets.renderAgreement({
                        selector: "#agreement",
                        variantKey: "AGREEMENT",
                    });
                    setReady(true);
                }
            } catch (error) {
                console.error('Error rendering widgets:', error);
            }
        };
    
        renderPaymentWidgets();
    
        return () => {
            isMounted = false; // 컴포넌트가 언마운트될 때 마운트 상태를 false로 설정
        };
    }, [widgets]);

    useEffect(() => {
        userSelect();
    }, [userInfo]);

    useEffect(() => {
        console.log('reservationList : ', reservationList);
        console.log('venueDetail : ', venueDetail);
    }, []);

    useEffect(() => {
        if (exchangeRate !== null) {
            setPriceInUSD((totalPrice / exchangeRate).toFixed(2));
        }
    }, [totalPrice, exchangeRate]);

    const fetchExchangeRate = async () => {
        try {
            const response = await axios.get('https://api.exchangerate-api.com/v4/latest/USD'); // USD 기준 환율 정보를 가져올 API
            setExchangeRate(response.data.rates.KRW);
        } catch (error) {
            console.error("환율 정보를 가져오는 중 오류 발생", error);
        }
    };

    useEffect(() => {
        fetchExchangeRate();
    }, []);

    const userSelect = async () => {
        if (userInfo?.userId) {
            const response = await auth.userselect(userInfo.userId);
            const data = response.data;

            console.log(data);
            setUser(data);
        }
    };

    const formatDate = (date) => {
        return format(new Date(date), 'yyyy-MM-dd');
    };

    const handleClick = (payment) => {
        setSelectedPayment(payment);
    };

    const handleBack = () => {
        setSelectedInfo(null);
    };

    const calculateTotalPrice = () => {
        if (reservationList.length === 0) return 0;

        let total = reservationList.mainItemPrice;

        console.log('total : ', total);
        console.log('exchangeRate : ', exchangeRate);

        if(selectedPaymentType === 'domestic') {
            reservationList.options.forEach(option => {
                if (option.price) {
                    if(option.quantity) {
                        total += option.price * option.quantity;
                    } else {
                        total += option.price;
                    }
                }
            });
            return total;
        } else {
            return (reservationList.totalPrice / exchangeRate).toFixed(2)
        }
    };

    useEffect(() => {
        setTotalPrice(calculateTotalPrice() - point - couponDiscount);
        setPrice(calculateTotalPrice());
        setDefaultPrice(reservationList.mainItemPrice);
    }, [point, couponDiscount, reservationList, selectedPaymentType]);

    const [isToggled, setToggle] = useState(false);
    const animation = useSpring({
        height: isToggled ? "150px" : "0px",
        overflow: "hidden",
        display: "flex",
        paddingTop: isToggled ? "1.5rem" : "0rem",
        paddingBottom: isToggled ? "1.5rem" : "0rem",
    });


    const handleCouponChange = (selectedOption) => {
        const selectedCoupon = selectedOption.value;
        if (price < selectedCoupon.minPrice) {
            alert(`현재 금액이 ${selectedCoupon.minPrice.toLocaleString()}원 보다 작아 이 쿠폰을 사용할 수 없습니다.`);
            setCouponDiscount(0);
            return;
        }

        let discount = 0;
        if (selectedCoupon.couponRate > 0) {
            discount = Math.min(price * (selectedCoupon.couponRate / 100), selectedCoupon.rateMaxPrice);
        } else if (selectedCoupon.couponPrice > 0) {
            discount = selectedCoupon.couponPrice;
        }
        setCouponDiscount(discount);
        setSelectionCoupon(selectedCoupon);
    };

    const handlePoint = (e) => {
        const value = e.target.value ? parseInt(e.target.value) : 0;
        if(selectedPaymentType === 'domestic') {
            if (value > totalPoint) {
                alert('보유한 포인트보다 높은 값을 입력할 수 없습니다.');
                setPoint(totalPoint);
            } else if (value > defaultPrice) {
                alert('결제 금액보다 많은 포인트를 사용할 수 없습니다.');
                setPoint(defaultPrice);
            } else {
                setPoint(value);
            }
        } else {
            if (value > totalPoint) {
                alert('보유한 포인트보다 높은 값을 입력할 수 없습니다.');
                setPoint(totalPoint);
            } else if (value > defaultPrice) {
                alert('결제 금액보다 많은 포인트를 사용할 수 없습니다.');
                setPoint(defaultPrice);
            } else {
                setPoint(value);
            }
        }
    };

    const handleUseAll = () => {
        if(selectedPaymentType === 'domestic') {
            if (totalPoint > totalPrice) {
                setPoint(totalPrice); // totalPrice까지만 포인트를 적용
            } else {
                setPoint(totalPoint);
            }
        } else {
            if (totalPoint > defaultPrice) {
                setPoint(defaultPrice); // totalPrice까지만 포인트를 적용
            } else {
                setPoint(totalPoint);
            }
        }
    };

    const [cardInfo, setCardInfo] = useState({
        cardNumber: '',
        expiry: '',
        birth: '',
        pwd2Digit: ''
    });

    const [displayExpiry, setDisplayExpiry] = useState('');

    const handleInputChange = (field) => (e) => {
        let { value } = e.target;

        if (field === 'cardNumber') {
            value = value.replace(/[^0-9]/g, '').replace(/(\d{4})(?=\d)/g, '$1-');
        }

        if (field === 'expiry') {
            value = value.replace(/[^0-9]/g, '').replace(/(\d{2})(\d{4})/, '$1-$2');
            setDisplayExpiry(value);
        }

        if (field === 'birth') {
            value = value.replace(/[^0-9]/g, '').slice(0, 6);
        }

        if (field === 'pwd2Digit') {
            value = value.replace(/[^0-9]/g, '').slice(0, 2);
        }

        setCardInfo({
            ...cardInfo,
            [field]: value
        });
    };

    const handleBlur = (field) => (e) => {
        if (field === 'cardNumber') {
            const value = e.target.value.replace(/-/g, '');
            setCardInfo({
                ...cardInfo,
                [field]: value
            });
        }

        if (field === 'expiry') {
            const value = e.target.value.replace(/[^0-9]/g, '');
            if (value.length === 6) {
                const [month, year] = [value.slice(0, 2), value.slice(2)];
                setCardInfo({
                    ...cardInfo,
                    [field]: `${year}-${month}`
                });
                setDisplayExpiry(`${month}-${year}`);
            }
        }
    };

    const isCardInfoValid = () => {
        return Object.values(cardInfo).every((value) => value !== '');
    };

    const handleInfoClick = (info) => {
        setSelectedInfo(info);
    };

    useEffect(() => {
        setPaymentMethod(''); // activeButton이 변경될 때 paymentMethod 초기화
    }, [activeButton]);

    const onPayment = async () => {
        try {
            if (ready && widgets) {
                const orderId = generateRandomString();
                // const paymentKey = generateRandom(20); // paymentKey 생성
                const reservation = {
                    ...reservationList, 
                    point : point, 
                    couponDiscount : couponDiscount, 
                    shopName : venueDetail[0].shopName,
                    couponNo : selectionCoupon ? selectionCoupon.couponNo : '',
                    couponName : selectionCoupon ? selectionCoupon.couponName : '',
                    totalPrice : totalPrice
                }

                localStorage.setItem('reservation', JSON.stringify(reservation));

                await widgets.requestPayment({
                    orderId : orderId,
                    orderName: venueDetail[0].productName,
                    customerName: user.userName,
                    customerEmail: user.userEmail,
                    successUrl: `${window.location.origin}/support/payment/successcomplete`,
                    failUrl: `${window.location.origin}/support/payment/fail`,
                    pendingUrl: `${window.location.origin}/payment/pending`
                });
            }
        } catch (error) {
            console.error('Payment request error:', error);
        }
        
    };

    const onNoPaymentPayment = async () => {
        const orderId = generateRandomString();

        const reservation = {
            ...reservationList, 
            point : point, 
            couponDiscount : couponDiscount, 
            shopName : venueDetail[0].shopName,
            couponNo : selectionCoupon ? selectionCoupon.couponNo : '',
            couponName : selectionCoupon ? selectionCoupon.couponName : '',
            totalPrice : totalPrice,
            orderId : orderId,
            amount : totalPrice,
            reservationPaymentType : '0원결제',
        }

        try {
            const response = await support.reservation(reservation);
            const data = response.data;
            console.log(data);

            const reservationNo = reservation.reservationNo;

            console.log('reservationNo = ', reservationNo);

            navigate(`/payment/success/${reservationNo}`);
        } catch (error) {
            console.error('Error storing payment info:', error);
        }
    }

    const postPaymentResultToServer = async (rsp, paymentData) => {
        try {
            const response = await fetch('/api/payment', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    imp_uid: rsp.imp_uid,
                    merchant_uid: rsp.merchant_uid,
                    amount: paymentData.amount
                }),
            });

            const result = await response.json();

            if (result.success) {
                console.log(rsp.imp_uid);
                console.log(rsp.merchant_uid);
                // 서버 검증 성공 시 로직
                const response = await support.reservation(reservationList, rsp.imp_uid, rsp.merchant_uid, paymentData.amount, point, couponDiscount, activeButton);
                const data = response.data;

                console.log(data);

                const reservationNo = reservationList.reservationNo;

                console.log('reservationNo = ', reservationNo);

                navigate(`/payment/success/${reservationNo}`);
            } else {
                // 서버 검증 실패 시 로직
                alert(`결제 검증에 실패하였습니다: ${result.error_msg}`);
            }
        } catch (error) {
            console.error('결제 검증 중 오류 발생', error);
            alert('결제 검증 중 오류가 발생했습니다.');
        }
    };

    return (
        <>
            <div className='payment_box'>
                <div className='d-flex justify-content-between px-3 pt-3 payment_toggle'>
                    <div><span className='bold'>예약상품</span></div>
                    <div>
                        <span>{isToggled || !venueDetail.length ? '' : venueDetail[0].productName}</span>
                        {isToggled ? (
                            <span className="material-symbols-outlined" onClick={() => setToggle(!isToggled)}>expand_less</span>
                        ) : (
                            <span className="material-symbols-outlined" onClick={() => setToggle(!isToggled)}>expand_more</span>
                        )}
                    </div>
                </div>
                {venueDetail.length > 0 && (
                    <animated.div style={animation} className='d-flex justify-content-between payment_reservation px-3'>
                        <div className='payment_img'>
                            <img src={venueDetail[0].representativeImage} alt="사진" />
                        </div>
                        <div className='payment_reservation_title ps-2'>
                            <div className='payment_reservation_area'><span>{venueDetail[0].productName}</span></div>
                            <div className='payment_reservation_option'>
                                <div><span>{reservationList && reservationList.mainOptionName}({reservationList.mainItemPrice.toLocaleString()+'원'})</span></div>
                                {
                                    reservationList && reservationList.options.map((option, index) => (
                                        <div key={index}><span>{option.name} : {option.value}({(option.price * (option.quantity ? option.quantity : 1)).toLocaleString() + '원' }){option.quantity ? ' - '+option.quantity+'개' : ''}</span></div>
                                    ))
                                }
                            </div>
                        </div>
                    </animated.div>
                )}
                <div className='payment_couse mt-2'></div>
                {reservationList.length > 0 && (
                    <div className='d-flex justify-content-between align-items-center py-3 px-3 payment_reserve_date'>
                        <div><span>예약 신청 일자</span></div>
                        <div>
                            <span>{formatDate(reservationList.dateRange[0])} ~ {formatDate(reservationList.dateRange[1])}</span>
                            {/* <span><img src="../img/icon/support/calendar.png" alt="달력" /></span> */}
                        </div>
                    </div>
                )}
                <div className='payment_couse'></div>
                <div className='py-3 px-3'>
                    <div><span>포인트 사용</span></div>
                    <div className='d-flex justify-content-between pt-1 use_point_held_box'>
                        <div><span>보유 포인트</span></div>
                        <div><span>{user?.userPoint.toLocaleString()}P</span></div>
                    </div>
                    <div className='d-flex justify-content-between use_point_box'>
                        <div className='use_point_title'><span>사용할 포인트</span></div>
                        <div>
                            <div><input type="text" value={point} onChange={handlePoint} /></div>
                            <div><button onClick={handleUseAll}>전액사용</button></div>
                        </div>
                    </div>
                </div>
                <div className='payment_couse'></div>
                {/* <div className='px-3 py-3 payment_coupon'>
                    <div><span>쿠폰 사용</span></div>
                    <div>
                    <Select
                            options={options}
                            isSearchable={false}
                            placeholder={`사용가능 쿠폰 ${availableCoupons}장 / 전체 ${availableCoupons}장`}
                            onChange={handleCouponChange}
                            styles={{
                                option: (provided) => ({
                                    ...provided,
                                    padding: 15,
                                    fontSize: '0.9em'
                                }),
                            }}
                            className='payment_coupon_select'
                        />
                    </div>
                </div> */}
                <div className='payment_couse'></div>
                <div className='py-3 mx-3 border-bottom payment_pay_price_box'>
                    <div><span>결제금액</span></div>
                    <div>
                        <div className='d-flex justify-content-between'>
                            <div><span>총 상품금액</span></div>
                            <div><span>{price.toLocaleString()}</span><span>{selectedPaymentType === 'domestic' ? '원' : ' USD'}</span></div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div><span>쿠폰 할인</span></div>
                            <div><span>(-){couponDiscount.toLocaleString()}</span><span>원</span></div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div><span>포인트 사용</span></div>
                            <div><span>(-)</span>{point.toLocaleString()}<span></span><span>P</span></div>
                        </div>
                    </div>
                </div>
                <div className='py-3 px-3 d-flex justify-content-between bold'>
                    <div><span>총 결제금액</span></div>
                    <div>
                        {
                            selectedPaymentType === 'domestic' &&
                            <span>{totalPrice.toLocaleString() + '원'}</span>
                        }
                        {
                            selectedPaymentType === 'overseas' &&
                            <span>{totalPrice + ' USD'} </span>
                        }
                    </div>
                </div>
                <div className='payment_couse'></div>
                {
                    totalPrice !== 0 ?
                    <div className='payment_select py-3 px-3'>
                        <div className='pb-2'><span>결제수단</span></div>
                        <div className={`${store.store_payment_type_tap_btn}`}>
                            <div className={`${selectedPaymentType === 'domestic' ? store.store_payment_type_tap_btn_active : ''}`}><button onClick={() => setSelectedPaymentType('domestic')}>국내결제</button></div>
                            <div className={`${selectedPaymentType === 'overseas' ? store.store_payment_type_tap_btn_active : ''}`}><button onClick={() => setSelectedPaymentType('overseas')}>Overseas payment</button></div>
                        </div>
                        <div>
                            <div id="payment-method" className="payment_select_btn_box"></div>
                            <div id="agreement" className="w-100" />
                        </div>
                    </div>
                    :
                    <div className='payment_no_price'>
                        <span>결제할 금액이 없습니다.</span>
                    </div>
                }
                <div className='payment_couse'></div>
                <div className='py-3 px-3'>
                    <div className='payment_info_box'>
                        <div className='py-1'><span className='signature_color'>· </span><span className='signature_color underline' onClick={() => handleInfoClick('개인정보 수집 및 이용')}>개인정보 수집 및 이용</span></div>
                        <div className='py-1'><span className='signature_color'>· </span><span className='signature_color underline' onClick={() => handleInfoClick('개인정보 제 3자 제공')}>개인정보 제 3자 제공</span></div>
                        <div className='py-1'><span className='signature_color'>· </span><span className='signature_color underline' onClick={() => handleInfoClick('예약상품 안내사항(취소 및 환불 사항 등)')}>예약상품 안내사항(취소 및 환불 사항 등)</span></div>
                        <div className='py-1'><span className='signature_color'>· </span><span className='signature_color underline' onClick={() => handleInfoClick('결제 전 주의사항 안내')}>결제 전 주의사항 안내</span></div>
                    </div>
                    <div className='payment_agreement py-3'>
                        <span className='pe-2'><input type="checkbox" id='agree' onChange={(e) => setIsChecked(e.target.checked)} /></span>
                        <label htmlFor='agree'>위 내용을 확인 하였으며 결제에 동의합니다.</label>
                    </div>
                </div>
                <div className='payment_couse'></div>
                <div className='py-3 px-3'>
                    {
                        totalPrice !== 0 ?
                        <button
                            className={`payment_btn ${isChecked ? 'payment_btn_active' : ''}`}
                            disabled={!isChecked}
                            onClick={onPayment}
                        >
                            결제하기
                        </button>
                        :
                        <button
                            className={`payment_btn ${isChecked ? 'payment_btn_active' : ''}`}
                            disabled={!isChecked}
                            onClick={onNoPaymentPayment}
                        >
                            결제하기
                        </button>
                    }
                </div>
            </div>
            {selectedInfo && <PaymentInfo selectedInfo={selectedInfo} handleBack={handleBack} />}
        </>
    );
}

export default PaymentContainer;
