import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import 'react-calendar/dist/Calendar.css'; // 기본 스타일
import moment from "moment";
import StyledCalendar from './StyledCalendar'; // StyledCalendar를 import

const DateCalendar = ({ seller, regularHolidays, setSelectedWeekOfMonth, setSelectedDayOfWeek, selectedWeekOfMonth, selectedDayOfWeek,
    holidays, setHolidays, currentRegularHolidays, setCurrentRegularHolidays, generateRegularHolidays, sellerapi, userInfo,
    setSelectDateOne, selectDateOne, ondataLoading
}) => {
    const MySwal = withReactContent(Swal);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [eventTitle, setEventTitle] = useState("");
    const [eventContent, setEventContent] = useState("");
    const [profileImage, setProfileImage] = useState(null);
    const [profileImagePreview, setProfileImagePreview] = useState(null); // 프리뷰를 위한 상태
    const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false); // 모달 상태

    useEffect(() => {
        // console.log('startDate : ', startDate);
        // console.log('endDate : ', endDate);

        if (startDate && endDate) {
            showEventForm();
        }
    }, [startDate, endDate]);

    useEffect(() => {
        // 정기 휴무일이 변경될 때마다 현재 정기 휴무일 상태를 업데이트합니다.
        setCurrentRegularHolidays(generateRegularHolidays(selectedDayOfWeek, selectedWeekOfMonth));
    }, [selectedWeekOfMonth, selectedDayOfWeek]);

    useEffect(() => {
        // console.log('Holidays: ', holidays);
    }, [holidays]);

    const handlePrevMonth = () => {
        // setSelectedWeekOfMonth("");
        // setSelectedDayOfWeek("");
    };

    const handleNextMonth = () => {
        // setSelectedWeekOfMonth("");
        // setSelectedDayOfWeek("");
    };

    const isRegularHoliday = (date) => {
        const dateString = moment(date).format('YYYY-MM-DD');
        return regularHolidays.includes(dateString);
    };

    const isHoliday = (date) => {
        const dateString = moment(date).format('YYYY-MM-DD');
        return holidays.some(event => moment(dateString).isBetween(event.temporaryDate, event.temporaryEndDate, null, '[]'));
    };

    const eventDelete = async (temporaryNo) => {
        // console.log(temporaryNo);
        MySwal.fire({
            customClass: {
                popup: `${seller.admin_seller_add_popup}`
            },
            html: `
                <div>
                    <div class='${seller.seller_seller_cancel_title} py-2'><span>등록된 이벤트를 삭제하시겠습니까?</span></div>
                    <div class='${seller.seller_seller_cancel_sub_title} py-2'><span>삭제된 내용은 되돌릴 수 없습니다.</span></div>
                    <div class='${seller.seller_seller_cancel_btn_box} py-2 d-flex w-100 justify-content-between'>
                        <button class='mx-1' id='seller_update_cancel'>취소</button>
                        <button class='mx-1' id='seller_update_success'>삭제</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });
        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);
        
        async function sellerAddSuccess(e) {
            if(e.target && e.target.id == 'seller_update_success') {
                try {
                    const response = await sellerapi.temporaryDelete(temporaryNo, userInfo && userInfo.userId);
                    const data = response.data;

                    // console.log(data);

                    if(data === 'SUCCESS') {
                        ondataLoading();
                        document.removeEventListener('click', sellerAddSuccess);
                        document.removeEventListener('click', sellerAddCancel);
                        MySwal.close();
                    } else {
                        alert('이벤트 제거에 실패했습니다.');
                    }
                } catch (error) {
                    console.error('error : ', error);
                }
            }
        }
        
        function sellerAddCancel(e) {
            if(e.target && e.target.id == 'seller_update_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
            }
        }
    };

    const onDayClick = (value, event) => {
        const clickedDateString = moment(value).format('YYYY-MM-DD');
        const clickedEvent = holidays.find(event => moment(clickedDateString).isBetween(event.temporaryDate, event.temporaryEndDate, null, '[]'));
        // console.log(clickedEvent);

        if (clickedEvent) {
            MySwal.fire({
                customClass: {
                    popup: `${seller.seller_date_popup}`,
                    htmlContainer: `${seller.seller_date_html_container}`
                },
                html: `
                    <div>
                        <div class='d-flex justify-content-center align-items-center position-relative pb-5 pt-2'>
                            <div>이벤트 내용</div>
                            <div class=${seller.seller_date_close}><button id="swal-close-button"><span class="material-symbols-outlined">close</span></button></div>
                        </div>
                        <div class='${seller.seller_date_selectedate_box} py-3'>
                            <div class='${seller.seller_date_selectedate_text}'><span>이벤트 제목</span></div>
                            <div>${clickedEvent.temporaryTitle}</div>
                        </div>
                        <div class='${seller.seller_date_selectedate_box} py-3'>
                            <div class='${seller.seller_date_selectedate_text}'><span>이벤트 내용</span></div>
                            <div>${clickedEvent.temporaryContent}</div>
                        </div>
                        <div class='${seller.seller_date_selectedate_img_box} py-3'>
                            <div class='${seller.seller_date_selectedate_text}'><span>이벤트 이미지</span></div>
                            <div>
                                <div>
                                    <img src="${process.env.REACT_APP_API_SERVER}/img?file=${clickedEvent.temporaryProfileImg}" alt="" />
                                </div>
                            </div>
                        </div>
                        <div class='d-flex justify-content-between pt-3'>
                            <div><button class='${seller.seller_event_delete}' id="custom-cancel-button">이벤트 삭제</button></div>
                            <div><button class='${seller.seller_event_confirm}' id="custom-confirm-button">확인</button></div>
                        </div>
                    </div>
                `,
                showConfirmButton: false,
                showCancelButton: false,
                imageAlt: 'Event Image',
            });

            const confirmButton = document.getElementById('custom-confirm-button');
            const cancelButton = document.getElementById('custom-cancel-button');
            const closeButton = document.getElementById('swal-close-button');
        
            const handleConfirmClick = () => MySwal.close();
            const handleCloseClick = () => MySwal.close();
            const handleCancelClick = () => eventDelete(clickedEvent.temporaryNo);
        
            confirmButton.addEventListener('click', handleConfirmClick);
            cancelButton.addEventListener('click', handleCancelClick);
            closeButton.addEventListener('click', handleCloseClick);
        
            // 이벤트 리스너를 제거하는 클린업 함수 설정
            MySwal.getPopup().addEventListener('close', () => {
                confirmButton.removeEventListener('click', handleConfirmClick);
                cancelButton.removeEventListener('click', handleCancelClick);
                closeButton.removeEventListener('click', handleCloseClick);
            });

            return;
        }

        if (isRegularHoliday(value)) {
            MySwal.fire('휴무일', '휴무일은 선택할 수 없습니다.', 'warning');
            return;
        }

        if (!startDate) {
            setStartDate(value);
            setEndDate(null); // Ensure endDate is reset when a new startDate is selected
        } else if (!endDate) {
            // Ensure startDate is before endDate
            if (moment(value).isBefore(startDate, 'day')) {
                MySwal.fire('날짜 선택 오류', '종료 날짜는 시작 날짜 이후여야 합니다.', 'warning');
                return;
            }
            
            // 시작일과 종료일 사이에 정기 휴무일이나 이벤트가 있는지 확인
            const range = getDatesInRange(startDate, value);
            if (range.some(date => isRegularHoliday(date) || isHoliday(date))) {
                MySwal.fire('휴무일 포함', '선택한 날짜 범위에 휴무일이 포함되어 있습니다.', 'warning');
                return;
            }

            setEndDate(value);
        } else {
            setStartDate(value);
            setEndDate(null);
        }
    };

    const getDatesInRange = (start, end) => {
        const dates = [];
        let currentDate = moment(start);

        while (currentDate <= moment(end)) {
            dates.push(currentDate.toDate());
            currentDate = currentDate.add(1, 'days');
        }

        return dates;
    };

    const showEventForm = () => {
        const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
        const formattedEndDate = endDate ? moment(endDate).format('YYYY-MM-DD') : '';

        MySwal.fire({
            customClass: {
                popup: `${seller.seller_date_popup}`,
                htmlContainer: `${seller.seller_date_html_container}`
            },
            html: `
                <div>
                    <div class='d-flex justify-content-center align-items-center position-relative pb-5 pt-2'>
                        <div>이벤트 등록</div>
                        <div class=${seller.seller_date_close}><button id="swal-close-button"><span class="material-symbols-outlined">close</span></button></div>
                    </div>
                    <div class='${seller.seller_event_title} pb-3'>
                        <div><span>*이벤트 정보를 입력하세요.</span></div>
                        <div>
                            <div>${formattedStartDate}</div>
                            <div>~</div>
                            <div>${formattedEndDate}</div>
                        </div>
                    </div>
                    <div class='${seller.seller_date_selectedate_box} py-3'>
                        <div class='${seller.seller_date_selectedate_text}'><span>이벤트 제목</span></div>
                        <input type='text' id='eventTitle' class='${seller.seller_date_input}' />
                    </div>
                    <div class='${seller.seller_date_selectedate_box} py-3'>
                        <div class='${seller.seller_date_selectedate_text}'><span>이벤트 내용</span></div>
                        <textarea id='eventContent' class='${seller.seller_date_textarea}'></textarea>
                    </div>
                    <div class='${seller.seller_date_selectedate_box} py-3'>
                        <div class='${seller.seller_date_selectedate_text}'><span>이벤트 이미지</span></div>
                        <input type='file' id='profileImage' class='${seller.seller_date_input}' style="display:none" />
                        <button id="profileImageButton" class='${seller.seller_event_img_select}'>이미지 선택</button>
                    </div>
                    <div id="imagePreviewContainer" style="margin-top: 10px;"></div> <!-- 미리보기 이미지가 표시될 컨테이너 -->
                    <div class='d-flex justify-content-center pt-3'>
                        <div><button class='${seller.seller_date_cancel}' id="custom-cancel-button">취소</button></div>
                        <div><button class='${seller.seller_date_confirm}' id="custom-confirm-button">확인</button></div>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });

        const confirmButton = document.getElementById('custom-confirm-button');
        const cancelButton = document.getElementById('custom-cancel-button');
        const closeButton = document.getElementById('swal-close-button');
        const profileImageButton = document.getElementById('profileImageButton');
        const profileImageInput = document.getElementById('profileImage');
        const imagePreviewContainer = document.getElementById('imagePreviewContainer');
    
        const handleConfirmClick = () => handleEventSave();
        const handleCloseClick = () => MySwal.close();
        const handleProfileImageButtonClick = () => profileImageInput.click();
        const handleProfileImageChange = () => {
            const file = profileImageInput.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = () => {
                    imagePreviewContainer.innerHTML = `<img src="${reader.result}" alt="Uploaded Preview" style="width: 100px; height: 100px; object-fit: cover; cursor: pointer;" id="uploadedImagePreview" />`;
                    setProfileImagePreview(reader.result); // Preview state에 이미지 URL 저장
                    document.getElementById('uploadedImagePreview').addEventListener('click', () => setIsPreviewModalOpen(true));
                };
                reader.readAsDataURL(file);
            }
        };
    
        confirmButton.addEventListener('click', handleConfirmClick);
        cancelButton.addEventListener('click', handleCloseClick);
        closeButton.addEventListener('click', handleCloseClick);
        profileImageButton.addEventListener('click', handleProfileImageButtonClick);
        profileImageInput.addEventListener('change', handleProfileImageChange);
    
        // 이벤트 리스너를 제거하는 클린업 함수 설정
        MySwal.getPopup().addEventListener('close', () => {
            confirmButton.removeEventListener('click', handleConfirmClick);
            cancelButton.removeEventListener('click', handleCloseClick);
            closeButton.removeEventListener('click', handleCloseClick);
            profileImageButton.removeEventListener('click', handleProfileImageButtonClick);
        });
    };

    const uploadImage = async (imageFile) => {
        const formData = new FormData();
        formData.append('image', imageFile);

        try {
            const response = await sellerapi.imgUpload(formData);
            const data = response.data;

            // console.log(data);
            
            return data; // Assuming the response contains the image URL as a string
        } catch (error) {
            console.error('Error uploading image:', error);
            return null;
        }
    };

    const handleEventSave = async () => {
        const title = document.getElementById('eventTitle').value;
        const content = document.getElementById('eventContent').value;
        const image = document.getElementById('profileImage').files[0];

        // console.log('image : ', image);

        const imageUrl = image ? await uploadImage(image) : null;

        // console.log('imageUrl : ', imageUrl);

        const newEvent = {
            startDate: moment(startDate).format('YYYY-MM-DD'),
            endDate: moment(endDate).format('YYYY-MM-DD'),
            title,
            content,
            imageUrl,
        };

        // console.log('newEvent : ', newEvent);

        setProfileImagePreview(imageUrl); // 프리뷰 이미지 상태 업데이트

        // Update state
        setHolidays([...holidays, newEvent]);

        // Call API to save the event
        await sellerapi.temporaryAdd(newEvent, userInfo && userInfo.userId);

        ondataLoading();

        MySwal.close();
    };

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = date.getMonth() + 1; // getMonth()는 0부터 시작합니다.
        const day = date.getDate();
    
        // YYYY-MM-DD 포맷으로 변환
        return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
    };

    const tileClassName = ({ date, view }) => {
        if (view === 'month') {
            const dateString = formatDate(date);
            if (holidays.some(event => moment(dateString).isBetween(event.temporaryDate, event.temporaryEndDate, null, '[]'))) {
                return 'temporary-holiday'; // 임시 휴무일 클래스 추가
            } else if (regularHolidays.includes(dateString)) {
                return 'regular-holiday'; // 정기 휴무일 클래스 추가
            } else if (startDate && endDate && moment(date).isBetween(startDate, endDate, null, '[]')) {
                return 'selected-range'; // 선택된 날짜 범위 클래스 추가
            } else if (startDate && !endDate && moment(date).isSameOrAfter(startDate)) {
                return 'selected-range'; // 시작 날짜부터 현재 선택한 날짜까지 범위를 표시
            }
        }
    };

    const tileContent = ({ date, view }) => {
        const dateString = formatDate(date);
        const event = holidays.find(event => moment(dateString).isBetween(event.temporaryDate, event.temporaryEndDate, null, '[]'));

        if (view === 'month') {
            if (event) {
                return (
                    <div style={{
                        position: 'absolute',
                        top: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(241, 242, 243, 1)',
                        zIndex: 0
                    }}>
                        <div style={{
                            position: 'absolute',
                            left: 0,
                            top: 0,
                            bottom: 0,
                            right: 0,
                            background: 'transparent',
                            pointerEvents: 'none'
                        }}>
                            <svg style={{
                                position: 'absolute',
                                left: 0,
                                top: 0,
                                width: '100%',
                                height: '100%'
                            }}>
                                <line x1="100%" y1="0" x2="0" y2="100%" stroke="rgba(223, 224, 226, 1)" strokeWidth="2" />
                            </svg>
                        </div>
                        <p style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '80%',
                            backgroundColor: 'white',
                            color: 'rgba(255, 114, 153, 1)',
                            padding: '5px',
                            fontWeight: 'bold'
                        }}>{event.temporaryTitle}</p>
                    </div>
                );
            } else if (regularHolidays.includes(dateString)) {
                return (
                    <div style={{
                        position: 'absolute',
                        top: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(241, 242, 243, 1)',
                        zIndex: 0
                    }}>
                        <div style={{
                            position: 'absolute',
                            left: 0,
                            top: 0,
                            bottom: 0,
                            right: 0,
                            background: 'transparent',
                            pointerEvents: 'none'
                        }}>
                            <svg style={{
                                position: 'absolute',
                                left: 0,
                                top: 0,
                                width: '100%',
                                height: '100%'
                            }}>
                                <line x1="100%" y1="0" x2="0" y2="100%" stroke="rgba(223, 224, 226, 1)" strokeWidth="2" />
                            </svg>
                        </div>
                        <p style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '80%',
                            backgroundColor: 'white',
                            color: 'rgba(255, 114, 153, 1)',
                            padding: '5px',
                            fontWeight: 'bold'
                        }}>정기 휴무일</p>
                    </div>
                );
            }
        }
    };

    return (
        <>
            <StyledCalendar
                onClickDay={onDayClick}
                tileClassName={tileClassName}
                tileContent={tileContent}
                calendarType="gregory"
                formatDay={(locale, date) => moment.utc(date).local().format("D")}
                onChangeMonth={(date) => {
                    if (moment(date).isBefore(moment(), 'month')) {
                        handlePrevMonth();
                    } else {
                        handleNextMonth();
                    }
                }}
            />
            
            {isPreviewModalOpen && (
                <div className={`${seller.seller_calendar_modal_container}`}>
                    <div className={`${seller.seller_calendar_modal_box}`}>
                        <div className={`${seller.seller_calendar_modal_header}`}>
                            <div><button><span className="material-symbols-outlined">close</span></button></div>
                        </div>
                        <div className={`${seller.seller_calendar_modal_content}`}>
                            <div>
                                <img src={profileImagePreview} alt="Preview Modal"/>
                            </div>
                        </div>
                        <div className={`${seller.seller_calendar_modal_footer}`}>
                            <button onClick={() => setIsPreviewModalOpen(false)}>닫기</button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default DateCalendar;
