import React, { useState } from 'react'
import { animated } from 'react-spring';
import { useSpring, useTransition } from '@react-spring/core';
import dailyduck from './MyContainner.module.css'
import { useNavigate } from 'react-router-dom';

const MyStoreEntry = ( {showStoreEntry, setShowStoreEntry} ) => {

    const navigate = useNavigate();
    const [ activeButton, setActiveButton ] = useState('store');

    const transitions = useTransition(showStoreEntry, {
        from: { transform: "translateX(100%)" },
        enter: { transform: "translateX(0)" },
        leave: { transform: "translateX(100%)" },
        config: {
            duration: 200,
        },
    });   
    const handleSEBack = () => {
        navigate('/my');
    }
  return (
    <>
        {
            <div className={`${dailyduck.my_store_entry_container}`}>
                    <div className={dailyduck.my_store_entry_box}>
                        <div className={dailyduck.my_store_entry_header}>
                            <div>
                                <button onClick={() => handleSEBack()}>
                                    <span className="material-symbols-outlined">arrow_back_ios</span>
                                </button>
                            </div>
                            <div>
                                <div>
                                    <span>입점·제휴 문의하기</span>
                                </div>
                            </div>
                        </div>
                        <div className={dailyduck.my_store_entry_tab}>
                            <div className={`w-100 d-flex ${dailyduck.my_entry_box}`}>
                                <div className={`w-100 ${activeButton == 'store' ? dailyduck.my_entry_active : ''}`}><button onClick={() => setActiveButton('store')}>스토어 입점</button></div>
                                <div className={`w-100 ${activeButton == 'support' ? dailyduck.my_entry_active : ''}`}><button onClick={() => setActiveButton('support')}>서포트 입점</button></div>
                            </div>
                        </div>
                        <div className={dailyduck.my_store_entry_content}>
                            {
                                activeButton == 'store' &&
                                <div>
                                    <div><span>스토어 입점 안내</span></div>
                                    <ul className={`${dailyduck.my_store_entry_list}`}>
                                        <li>스토어 입점 심사 기준, 필요 서류 등에 대한 자세한 안내는
                                        아래 버튼을 통해 구글폼 링크로 접속하여 확인 부탁드립니다 😊</li>
                                    </ul>
                                </div>
                            }
                            {
                                activeButton == 'support' &&
                                <div>
                                    <div><span>서포트 입점 안내</span></div>
                                    <ul className={`${dailyduck.my_store_entry_list}`}>
                                        <li>서포트 입점 심사 기준, 필요 서류 등에 대한 자세한 안내는
                                        아래 버튼을 통해 구글폼 링크로 접속하여 확인 부탁드립니다 😊</li>
                                    </ul>
                                </div>

                            }
                        </div>
                        <div className={dailyduck.my_store_entry_footer}>
                            {
                                activeButton == 'store' &&
                                <div className={`${dailyduck.my_store_entry_btn}`}><button>스토어 입점 신청하러 가기</button></div>
                            }
                            {
                                activeButton == 'support' &&
                                <div className={`${dailyduck.my_store_entry_btn}`}><button>서포트 입점 신청하러 가기</button></div>
                            }
                        </div>
                    </div>
                </div>
          }
      </>
  )
}

export default MyStoreEntry