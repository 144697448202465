import React from 'react'
import { Link } from 'react-router-dom'

const EventFaqScreen = () => {
  return (
    <div className='mt-3 px-4 faq_box'>
        <div className='border-bottom py-2'>
            <h5>FAQ<span className='ps-2'>2</span></h5>
        </div>
        <div className='border-bottom'>
            <Link className='py-3' to={{pathname: `/store/FAQ/1`}}>
                <div className='py-3'>
                    <span>샐러가 작성한 FAQ 제목이 노출됩니다.</span>
                </div>
                <div className='pb-3'>
                    <span>2023.04.13</span>
                </div>
            </Link>
        </div>
        <div className='border-bottom'>
            <Link className='py-3' to={{pathname: `/store/FAQ/2`}}>
                <div className='py-3'>
                    <span>샐러가 작성한 FAQ 제목이 노출됩니다.</span>
                </div>
                <div className='pb-3'>
                    <span>2023.04.13</span>
                </div>
            </Link>
        </div>
    </div>
  )
}

export default EventFaqScreen