import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import event from '../../containers/event/event.module.css';
import { format } from 'date-fns';

const EventAnnouncement = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const announcement = location.state?.announcement;

    useEffect(() => {
        // console.log('announcement : ', announcement);
    }, [announcement]);

    const KSTformatDate = (date) => {
        return format(new Date(date), 'yyyy-MM-dd HH:mm:ss');
    };

    const onBack = () => {
        navigate(-1);
    }

  return (
    <div className={`${event.announcement_container}`}>
        <div className={`${event.announcement_box}`}>
            <div className={`${event.announcement_header}`}>
                <div><button onClick={onBack}><span className="material-symbols-outlined">arrow_back_ios</span></button></div>
                <div><span>당첨자발표</span></div>
            </div>
            <div className={`${event.announcement_content}`}>
                <div className={`${event.announcement_content_title}`}><span>{announcement.announcementTitle}</span></div>
                <div className={`${event.announcement_content_date}`}><span>{KSTformatDate(announcement.announcementRegDate)}</span></div>
                <div className={`${event.announcement_content_info}`}>
                    <div dangerouslySetInnerHTML={{ __html: announcement.announcementContent }}></div>
                </div>
            </div>
            <div className={`${event.announcement_footer}`}>
                <div><button>당첨여부 확인하기</button></div>
            </div>
        </div>
    </div>
  )
}

export default EventAnnouncement