import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Select } from 'antd';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as adminApi from '../../../apis/admin';

const { Option } = Select;

const StoreRental = ({
  category,
  isHeartClicked,
  onHeartClick,
  storeData,
  date,
  store,
  favorityList,
  showFavorityOnly,
  setShowFavorityOnly,
  selectedFavority,
  setSelectedFavority,
  onRentalSort,
  activeSort,
  userInfo,
  rentalProductListRef
}) => {
  const navigate = useNavigate();

  const initialHeartState = storeData.reduce((acc, item) => {
    acc[item.productNo] = false;
    return acc;
  }, {});

  const [heartClicked, setHeartClicked] = useState(initialHeartState);
  const [columnClass, setColumnClass] = useState('col-6'); // 초기값 설정
  const [badgeList, setBadgeList] = useState([]); // 뱃지 목록 상태 추가

  // storeData가 정의되지 않았거나 배열이 아닌 경우 빈 배열로 대체하여 오류 방지
  const safeStoreData = Array.isArray(storeData) ? storeData : [];

  useEffect(() => {
    // console.log('safeStoreData : ', safeStoreData);
    badgeSelect();
  }, [safeStoreData]);

  // 뱃지 목록 가져오기
  const badgeSelect = async () => {
    const response = await adminApi.badgesSelect();
    const data = response.data;

    setBadgeList(data); // 뱃지 목록 상태에 저장
    // console.log('badgeSelect : ', data);
  };

  const onDetail = (productNo, index) => {
    navigate(`/store/product/${productNo}`);
  };

  useEffect(() => {
    const updateColumnClass = () => {
      if (window.innerWidth > 500) {
        setColumnClass('col-4'); // 화면이 500px 이상일 때
      } else {
        setColumnClass('col-6'); // 화면이 500px 이하일 때
      }
    };

    updateColumnClass();
    window.addEventListener('resize', updateColumnClass);

    return () => {
      window.removeEventListener('resize', updateColumnClass);
    };
  }, []);

  const isSoldOut = (item) => {
    // 옵션이 없는 경우 inventoryCnt 값이 0인지 확인
    if (!item.optionValueList?.length && !item.combinationOptions?.length) {
      return item.inventoryCnt === 0;
    }
  
    // 단일 옵션이 있을 때, optionInventory가 모두 0 또는 null인지 확인
    if (Array.isArray(item.optionValueList) && item.optionValueList.length > 0) {
      return item.optionValueList.every(
        (option) => option.optionInventory === 0 || option.optionInventory === null
      );
    }
  
    // 조합 옵션이 있을 때, stock이 모두 0인지 확인
    if (Array.isArray(item.combinationOptions) && item.combinationOptions.length > 0) {
      return item.combinationOptions.every(
        (combination) => combination.stock === 0
      );
    }
  
    return false; // 기본값은 품절 아님
  };
  

  const handleFavorityCheckboxChange = (e) => {
    setShowFavorityOnly(e.target.checked);
  };

  const handleFavoritySelectChange = (value) => {
    setSelectedFavority(value);
  };

  const sortFilter = (data) => {
    switch (data) {
      case 'latest':
        return '최신순';
      case 'sales':
        return '판매순';
      case 'review':
        return '리뷰많은순';
      case 'low':
        return '낮은가격순';
      case 'high':
        return '높은가격순';
      case 'sort':
        return '정렬';
      default:
        return '정렬';
    }
  };

  const fetchWishlist = useCallback(async () => {
    // userInfo가 없거나 userId가 없을 때 빈 배열 반환
    if (!userInfo?.userId) return [];

    try {
      const response = await fetch(`/api/store/storeWishlist/${userInfo.userId}`);
      const data = await response.json();
      return Array.isArray(data) ? data : [];
    } catch (error) {
      console.error('Failed to fetch wishlist:', error);
      return [];
    }
  }, [userInfo]);

  useEffect(() => {
    // userInfo와 userId가 있을 때만 위시리스트를 불러옴
    if (userInfo?.userId) {
      fetchWishlist().then((wishlist) => {
        const newHeartState = { ...initialHeartState };
        wishlist.forEach((item) => {
          newHeartState[item.productNo] = true;
        });
        setHeartClicked(newHeartState);
      });
    }
  }, [fetchWishlist, userInfo]);

  const formatRating = (rating) => {
    return rating.toFixed(1); // 소수점 첫째 자리까지 표시하도록 고정
  };

  const handleHeartClick = async (productNo) => {
    if (!userInfo?.userId) {
      toast.error('로그인 후 사용해 주세요');
      return;
    }

    setHeartClicked((prevState) => {
      const newState = {
        ...prevState,
        [productNo]: !prevState[productNo],
      };
      return newState;
    });

    const isFavorite = !heartClicked[productNo];
    const url = isFavorite
      ? '/api/store/addStoreWishlist'
      : '/api/store/removeStoreWishlist';
    const userId = userInfo.userId;

    await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId, productNo }),
    });

    onHeartClick(productNo);
  };

  // productBadge에 해당하는 뱃지 스타일을 가져오는 함수
  const getBadgeStyle = (badgeName) => {
    const matchedBadge = Array.isArray(badgeList) && badgeList.find(
      (badge) => badge.korBadgeName === badgeName
    );
    return matchedBadge
      ? {
          color: matchedBadge.badgeFontColor,
          backgroundColor: matchedBadge.badgeBackColor,
        }
      : {};
  };

  return (
    <>
      <div className={`${store.store_rental_product_list_header}`}>
        <div>
          <div className='d-flex'>
            <div className='pe-1'>
              <span>전체</span>
            </div>
            <div className='ps-1'>
              <span>{safeStoreData.length}</span>
            </div>
          </div>
          <div className='d-flex align-items-center'>
            <div
              className={`d-flex align-items-center ${store.store_favority_product_check} pe-3`}
            >
              <div>
                <input
                  type='checkbox'
                  id='favority_select'
                  checked={showFavorityOnly}
                  onChange={handleFavorityCheckboxChange}
                />
              </div>
              <div className='ps-2'>
                <Select
                  className={`${store.store_favority_select}`}
                  placeholder='최애 상품만'
                  value={selectedFavority}
                  onChange={handleFavoritySelectChange}
                  allowClear
                >
                  <Option value=''>전체최애</Option>
                  {favorityList.map((favority) => (
                    <Option key={favority.favorityNo} value={favority.favorityName}>
                      {favority.favorityName}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <div className={`${store.store_rental_product_sort_btn}`}>
              <button onClick={onRentalSort}>
                <div>
                  <span>{sortFilter(activeSort)}</span>
                </div>
                <div>
                  <span className='material-symbols-outlined'>expand_more</span>
                </div>
              </button>
            </div>
          </div>
        </div>
        {/* <div className='px-3 pb-2'></div> */}
      </div>
      <div className={`${store.store_rental_product_list}`} ref={rentalProductListRef}>
        <div className='row'>
          {safeStoreData.map((item, index) => {
            const isHeartClicked = heartClicked[item && item?.productNo];
            const badgeStyle = getBadgeStyle(item && item?.productBadge); // 스타일 가져오기

            return (
              <div
                key={index}
                className={`${store.store_item_list_box} ${columnClass}`}
              >
                <div
                  className={`${store.store_item_list}`}
                  onClick={() => onDetail(item.productNo, index)}
                  >
                  <div className={`${store.store_item_list_img_box}`}>
                    {isSoldOut(item) && (
                      <div className={`${store.store_stockCnt_zero_box}`}>
                        <div
                          className={`${store.store_stockCnt_zero}`}
                          onClick={() => onDetail(item.productNo, index)}
                        >
                          <span>품절</span>
                        </div>
                      </div>
                    )}
                    <img src={item.productMainImage} alt={item.productName} />
                    <div className={`${store.store_item_heart_box}`}>
                      <button
                        className='heart_btn'
                        onClick={(e) => {
                          e.stopPropagation();
                          handleHeartClick(item.productNo);
                        }}
                      >
                        {isHeartClicked ? (
                          <img
                            className='icon_size'
                            src='../img/icon/venue/heart_fill.png'
                            alt='찜O'
                          />
                        ) : (
                          <img
                            className='icon_size'
                            src='../img/icon/venue/white_heart.png'
                            alt='찜X'
                          />
                        )}
                      </button>
                    </div>
                  </div>
                  <div
                    className={`${store.store_gray} ${store.store_fs_small} ${store.store_item_list_shop_name}`}
                  >
                    <span>{item.shopName}</span>
                  </div>
                  <div
                    className={`${store.store_item_list_productname} ${store.store_fs_middle}`}
                  >
                    <span>{item.productName}</span>
                  </div>
                  {item.productsalePrice !== item.productPrice ? (
                    <div className='d-flex pt-2 align-items-center'>
                      {
                        item.productsalePrice === null ? null :
                        <div className={`${store.store_item_list_discount_rate}`}>
                          <span>{item.discountRate?.toLocaleString()}%</span>
                        </div>
                      }
                      {
                        item.productsalePrice === null ?  
                        <div className={`${store.store_item_list_discount_price}`}>
                          <span>
                            &#8361;{item.productPrice?.toLocaleString()}
                          </span>
                        </div>
                        :
                        <div className={`${store.store_item_list_discount_price}`}>
                          <span>
                            &#8361;{item.productsalePrice?.toLocaleString()}
                          </span>
                        </div>
                      }
                      {/* <div className={`${store.store_item_list_price}`}><span>&#8361;{item.productPrice.toLocaleString()}</span></div> */}
                    </div>
                  ) : (
                    <div className='d-flex py-2 align-items-center'>
                      <div className={`${store.store_item_list_discount_price}`}>
                        <span>
                          &#8361;{item.productsalePrice.toLocaleString()}
                        </span>
                      </div>
                    </div>
                  )}
                  <div className={`${store.store_item_list_badge_review}`}>
                    <div className='d-flex align-items-center justify-content-start'>
                      <div
                        className={`${store.star_iconImg} d-flex align-items-center justify-content-start`}
                      >
                        <img src='../img/icon/star_fill.png' alt='별점' />
                      </div>
                      <div className='d-flex align-items-center justify-content-start ps-1'>
                        <span className='star_grade'>
                          {formatRating(item.reviewRating)}({item.reviewCount})
                        </span>
                      </div>
                    </div>
                    {item.productBadge !== null && (
                      <div className='pt-1'>
                        <div
                          className={`${store.store_item_list_label}`}
                          style={{
                            ...badgeStyle
                          }}
                        >
                          <span>{item.productBadge}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <ToastContainer
        hideProgressBar={true}
        autoClose={1000}
        position='top-center'
      />
    </>
  );
};

export default StoreRental;
