import React, { useEffect, useRef, useState } from 'react';
import admin from '../../../admin.module.css';
import { Button, DatePicker, Input, Select, Pagination } from 'antd';
import * as adminApi from '../../../../apis/admin';
import { format } from 'date-fns';
import * as support from '../../../../apis/support';

const AdminFavority = () => {
    const { Option } = Select;
    const { RangePicker } = DatePicker;
    const [selectDate, setSelectDate] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [favority, setFavority] = useState([]);
    const [addModal, setAddModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [addContent, setAddContent] = useState(null);
    const [editContent, setEditContent] = useState(null);
    const [editPreviewImage, setEditPreviewImage] = useState('');
    const [addPreviewImage, setAddPreviewImage] = useState('');
    const [selectedValue, setSelectedValue] = useState('all');
    const [searchValue, setSearchValue] = useState('');
    const [filteredFavority, setFilteredFavority] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [favorityRequestModal, setFavorityRequestModal] = useState(false);
    const [requestFavorityList, setRequestFavorityList] = useState([]);
    const [requestActiveButton, setRequestActiveButton] = useState('all');
    const [filteredRequestFavorityList, setFilteredRequestFavorityList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);


    const fileInputRef = useRef(null);
    const imageInputRef = useRef(null);
    const addImageInputRef = useRef(null);
    const ref = useRef();

    useEffect(() => {
        favoritySelect();
        requestFavority();
    }, []);

    useEffect(() => {
        filterRequestFavority();
    }, [requestActiveButton, requestFavorityList]);

    useEffect(() => {
        filterFavority();
    }, [favority, selectedValue, searchValue, selectDate]);

    const favoritySelect = async () => {
        const response = await adminApi.favoritySelect();
        const data = response.data;
        setFavority(data);
    }

    const requestFavority = async () => {
        const response = await adminApi.requestFavority();
        const data = response.data;

        // console.log('requestFavority : ', data);
        setRequestFavorityList(data);
    }

    const handleDateChange = (dates, dateStrings) => {
        setSelectDate(dateStrings);
    };

    const handleChange = (value) => {
        setSelectedValue(value);
    };

    const onSearchValue = (e) => {
        setSearchValue(e.target.value);
    };

    useEffect(() => {
        const inputs = ref.current.querySelectorAll('.ant-picker-input input');
        inputs.forEach(input => {
            input.setAttribute('readonly', 'readonly');
        });
    }, []);

    const filterFavority = () => {
        let filtered = favority;
    
        if (searchValue) {
            switch (selectedValue) {
                case 'name':
                    filtered = filtered.filter((item) =>
                        [item.artistName, item.engName, item.realName].some((name) =>
                            name?.toLowerCase().includes(searchValue.toLowerCase()) // Optional chaining 적용
                        )
                    );
                    break;
                case 'phone': // 그룹 검색
                    filtered = filtered.filter((item) =>
                        item.artistGroup?.toLowerCase().includes(searchValue.toLowerCase()) // Optional chaining 적용
                    );
                    break;
                case 'agency':
                    filtered = filtered.filter((item) =>
                        item.artistAgency?.toLowerCase().includes(searchValue.toLowerCase()) // Optional chaining 적용
                    );
                    break;
                case 'fandom':
                    filtered = filtered.filter((item) =>
                        item.fandom?.toLowerCase().includes(searchValue.toLowerCase()) // Optional chaining 적용
                    );
                    break;
                case 'labels':
                    filtered = filtered.filter((item) =>
                        item.labels?.toLowerCase().includes(searchValue.toLowerCase()) // Optional chaining 적용
                    );
                    break;
                case 'nickname':
                    filtered = filtered.filter((item) =>
                        item.nickName?.toLowerCase().includes(searchValue.toLowerCase()) // Optional chaining 적용
                    );
                    break;
                case 'birth':
                    filtered = filtered.filter((item) =>
                        item.birth?.toLowerCase().includes(searchValue.toLowerCase()) // Optional chaining 적용
                    );
                    break;
                case 'all':
                default:
                    filtered = filtered.filter((item) =>
                        [
                            item.artistAgency,
                            item.artistGroup,
                            item.artistName,
                            item.birth,
                            item.color,
                            item.engName,
                            item.fandom,
                            item.labels,
                            item.nickName,
                            item.realName,
                        ].some((field) =>
                            field?.toLowerCase().includes(searchValue.toLowerCase()) // Optional chaining 적용
                        )
                    );
                    break;
            }
        }
    
        // 날짜 필터링
        if (selectDate.length === 2) {
            const [startDate, endDate] = selectDate;
            filtered = filtered.filter((item) => {
                const artistUpdDate = new Date(item.artistUpdDate);
                return (
                    artistUpdDate >= new Date(startDate) &&
                    artistUpdDate <= new Date(endDate)
                );
            });
        }
    
        setFilteredFavority(filtered);
    };
    

    const filterRequestFavority = () => {
        let filtered = [];

        if (requestActiveButton === 'all') {
            filtered = requestFavorityList;
        } else if (requestActiveButton === 'request') {
            filtered = requestFavorityList.filter(item => !item.favorityRequestState);
        } else if (requestActiveButton === 'complate') {
            filtered = requestFavorityList.filter(item => item.favorityRequestState);
        }

        setFilteredRequestFavorityList(filtered);
    };

    const handleFavorityRequestStateChange = async (item) => {
        if (!item.favorityRequestState) {
            // // 여기에서 API 호출 등을 통해 상태를 업데이트
            // item.favorityRequestState = true; // 임시 처리 (실제 처리 로직 필요)
            // setRequestFavorityList([...requestFavorityList]);

            const response = await adminApi.favorityRequestStateChange(item);
            const data = response.data;

            // console.log('FavorityRequestStateChange : ', data);

            if(data === 'SUCCESS') {
                alert('등록 요청 최애가 확인처리되었습니다.');
                requestFavority();
            } else {
                alert('등록 요청 최애 확인 처리에 실패하였습니다.');
            }
        }
    };

    const totalRequests = requestFavorityList.length;
    const pendingRequests = requestFavorityList.filter(item => !item.favorityRequestState).length;
    const completedRequests = requestFavorityList.filter(item => item.favorityRequestState).length;

    const handlePageChange = (page, pageSize) => {
        setCurrentPage(page);
        setPageSize(pageSize);
    };

    // 페이지네이션된 데이터 가져오기
    const paginatedFavority = filteredFavority.slice(
        (currentPage - 1) * pageSize,
        currentPage * pageSize
    );

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            setIsLoading(true);

            setSelectedFile(file);
            const formData = new FormData();
            formData.append('file', file);

            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            };

            try {
                const response = await adminApi.favorityExcel(formData, config);
                
                if(response.status === 200) {
                    setIsLoading(false);
                    alert('엑셀 파일이 성공적으로 업로드되었습니다.');
                    favoritySelect();
                } else {
                    alert('엑셀 파일 업로드에 실패하였습니다.');
                }
            } catch (error) {
                alert('파일 업로드에 실패했습니다.');
            }
        }
    };

    const formatDate = (date) => {
        return format(new Date(date), 'yyyy-MM-dd');
    };

    const formatDateInput = (date) => {
        if (!date) return '';
        return format(new Date(date), 'yyyy-MM-dd');
    };

    const onEdit = (item) => {
        setEditContent(item);
        setEditPreviewImage(item.artistImg);
        setEditModal(true);
    }

    const onEditModalClose = () => {
        setEditModal(false);
    }
    const onAddModalClose = () => {
        setAddModal(false);
    }

    const handleImageUpload = async (event) => {
        const file = event.target.files[0];
        if (file) {
            // const reader = new FileReader();
            // reader.onloadend = () => {
            //     setEditPreviewImage(reader.result);
            // };
            // reader.readAsDataURL(file);

            const formData = new FormData();
            formData.append('image', file);

            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            };

            const response = await support.uploadImageToServer(formData, config);
            const data = response.data;

            // console.log(data);

            if (data) {
                setEditPreviewImage(data);
                setEditContent({
                    ...editContent,
                    artistImg: data
                });
            }
        }
    };
    const handleAddImageUpload = async (event) => {
        const file = event.target.files[0];
        if (file) {

            const formData = new FormData();
            formData.append('image', file);

            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            };

            const response = await support.uploadImageToServer(formData, config);
            const data = response.data;

            // console.log(data);

            if (data) {
                // const imageUrl = `${process.env.REACT_APP_API_SERVER}/img?file=${encodeURIComponent(data)}`;
                setAddPreviewImage(data);
                setAddContent({
                    ...addContent,
                    artistImg: data
                });
            }
        }
    };

    const onEditSave = async () => {

        try {
            const response = await adminApi.editSave(editContent);
            const data = response.data;
    
            if(data === 'SUCCESS') {
                alert('최애가 업데이트되었습니다.')
                setEditModal(false);
                favoritySelect();
                editContent(null);
            } else {
                alert('최애 업데이트에 실패하였습니다.')
            }
        } catch (error) {
            console.error('error : ', error);
        }
    }

    const onAddSave = async () => {

        try {
            const response = await adminApi.favorityAdd(addContent);
            const data = response.data;
    
            if(data === 'SUCCESS') {
                alert('최애가 등록되었습니다.')
                setAddModal(false);
                favoritySelect();
                addContent(null);
            } else {
                alert('최애 등록에 실패하였습니다.')
            }
        } catch (error) {
            console.error('error : ', error);
        }
    }

    const onArtistDelete = async (item) => {
        // console.log('artistNo : ', item.artistNo);

        const response = await adminApi.artistDelete(item.artistNo);
        const data = response.data;

        if(data === 'SUCCESS') {
            alert(`최애 ${item.artistName}가(이) 삭제되었습니다.`);
            favoritySelect();
        }
    }

    const handleFavorityRequest = () => {
        setFavorityRequestModal(true);
    }

    return (
        <>
            {
                isLoading &&
                <div className={`${admin.admin_operate_favority_loading}`}>
                    <div><img src={`${process.env.PUBLIC_URL}/img/kwangduck_loading.gif`} alt="" /></div>
                </div>
            }
            <div className={`${admin.admin_operate_favority_box} px-3 py-3`}>
                <div>
                    <div className={`${admin.admin_operate_inquiry_title}`}><span>최애 관리</span></div>
                    <div className={`${admin.admin_userList_search_box} py-3`}>
                        <div className={`${admin.admin_userList_search_input}`}>
                            <div>
                                <Select
                                    className={`${admin.admin_userList_search_select}`}
                                    onChange={handleChange}
                                    defaultValue={selectedValue}
                                >
                                    <Option value="all">전체</Option>
                                    <Option value="name">이름</Option>
                                    <Option value="phone">그룹</Option>
                                    <Option value="agency">에이전시</Option>
                                    <Option value="fandom">팬덤</Option>
                                    <Option value="labels">레이블</Option>
                                    <Option value="nickname">별명</Option>
                                    <Option value="birth">생일</Option>
                                </Select>
                            </div>
                            <div className={`ps-1 ${admin.admin_userList_search_input_txt}`}>
                                <Input
                                    type="text"
                                    onChange={onSearchValue}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            filterFavority();
                                        }
                                    }}
                                />
                            </div>
                            <div className={`ps-1 ${admin.admin_userList_search_btn}`}>
                                {/* <button onClick={onSearchClick}>검색</button> */}
                            </div>
                        </div>
                        <div className={`${admin.admin_userList_search_date}`}>
                            <div ref={ref}>
                                <RangePicker className={`${admin.admin_userList_datepicker}`}
                                    separator="~"
                                    placeholder={['0000.00.00', '0000.00.00']}
                                    onChange={handleDateChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={`py-2 border-bottom ${admin.admin_operate_favority_add}`}>
                        <button onClick={() => setAddModal(true)}>최애 추가</button>
                        <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            accept=".xlsx"
                            onChange={handleFileChange}
                        />
                        <button className='ms-2' onClick={() => fileInputRef.current.click()}>엑셀 파일 업로드</button>
                    </div>
                    <div>
                        <div>
                            <div className={`${admin.admin_operate_favority_content_header}`}>
                                <div><span>번호</span></div>
                                <div><span>이미지</span></div>
                                <div><span>최애 이름</span></div>
                                <div><span>소속 그룹</span></div>
                                <div><span>등록일자</span></div>
                                <div><span>관리</span></div>
                            </div>
                            {paginatedFavority.map((item, index) => (
                                <div key={index} className={`${admin.admin_operate_favority_content_content}`}>
                                    <div><span>{(currentPage - 1) * pageSize + index + 1}</span></div>
                                    <div><img src={`${process.env.REACT_APP_API_SERVER}/img?file=${item.artistImg}`} alt="" /></div>
                                    <div><span>{item.artistName}</span></div>
                                    <div><span>{item.artistGroup}</span></div>
                                    <div><span>{format(new Date(item.artistRegDate), 'yyyy-MM-dd')}</span></div>
                                    <div>
                                        <div><Button onClick={() => onEdit(item)}>수정</Button></div>
                                        <div><Button onClick={() => onArtistDelete(item)}>삭제</Button></div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <Pagination
                            current={currentPage}
                            pageSize={pageSize}
                            total={filteredFavority.length}
                            onChange={handlePageChange}
                            // showSizeChanger
                        />
                    </div>
                </div>
                {
                    editModal && 
                    <div className={`${admin.admin_favority_edit_modal}`}>
                        <div className={`${admin.admin_favority_edit_modal_box}`}>
                            <div className={`${admin.admin_favority_edit_modal_header}`}>
                                <div><span>최애수정</span></div>
                                <div><button onClick={onEditModalClose}><span className="material-symbols-outlined">close</span></button></div>
                            </div>
                            <div className={`${admin.admin_favority_edit_modal_content}`}>
                                <div>
                                    <div><span>최애 이름</span></div>
                                    <div><input type="text" value={editContent.artistName} onChange={(e) => setEditContent({...editContent, artistName: e.target.value})} /></div>
                                </div>
                                <div>
                                    <div><span>이미지</span></div>
                                    <div>
                                        <div><img src={`${process.env.REACT_APP_API_SERVER}/img?file=${editPreviewImage}`} alt="이미지" /></div>
                                    </div>
                                    <div>
                                        <button onClick={() => imageInputRef.current.click()}>수정</button>
                                        <input
                                            type="file"
                                            ref={imageInputRef}
                                            style={{ display: 'none' }}
                                            accept="image/*"
                                            onChange={handleImageUpload}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div><span>소속그룹</span></div>
                                    <div><input type="text" value={editContent.artistGroup} onChange={(e) => setEditContent({...editContent, artistGroup: e.target.value})} /></div>
                                </div>
                                <div>
                                    <div><span>소속사</span></div>
                                    <div><input type="text" value={editContent.artistAgency} onChange={(e) => setEditContent({...editContent, artistAgency: e.target.value})} /></div>
                                </div>
                                <div>
                                    <div><span>소속레이블</span></div>
                                    <div><input type="text" value={editContent.labels} onChange={(e) => setEditContent({...editContent, labels: e.target.value})} /></div>
                                </div>
                                <div>
                                    <div><span>데뷔일</span></div>
                                    <div><input type="date" value={formatDateInput(editContent.debutDate)} onChange={(e) => setEditContent({...editContent, debutDate: e.target.value})} /></div>
                                </div>
                                <div>
                                    <div><span>팬덤</span></div>
                                    <div><input type="text" value={editContent.fandom} onChange={(e) => setEditContent({...editContent, fandom: e.target.value})} /></div>
                                </div>
                                <div>
                                    <div><span>별명</span></div>
                                    <div><textarea value={editContent.nickName} onChange={(e) => setEditContent({...editContent, nickName: e.target.value})}></textarea></div>
                                </div>
                                <div>
                                    <div><span>실명</span></div>
                                    <div><input type="text" value={editContent.realName} onChange={(e) => setEditContent({...editContent, realName: e.target.value})} /></div>
                                </div>
                                <div>
                                    <div><span>좋아하는 색상</span></div>
                                    <div><input type="text" value={editContent.color} onChange={(e) => setEditContent({...editContent, color: e.target.value})} /></div>
                                </div>
                                <div>
                                    <div><span>생일</span></div>
                                    <div><input type="date" value={formatDateInput(editContent.birth)} onChange={(e) => setEditContent({...editContent, birth: e.target.value})} /></div>
                                </div>
                                <div>
                                    <div><span>영어이름</span></div>
                                    <div><input type="text" value={editContent.engName} onChange={(e) => setEditContent({...editContent, engName: e.target.value})} /></div>
                                </div>
                                <div>
                                    <div><button onClick={() => onEditSave()}>수정하기</button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    addModal && 
                    <div className={`${admin.admin_favority_edit_modal}`}>
                        <div className={`${admin.admin_favority_edit_modal_box}`}>
                            <div className={`${admin.admin_favority_edit_modal_header}`}>
                                <div><span>최애등록</span></div>
                                <div><button onClick={onAddModalClose}><span className="material-symbols-outlined">close</span></button></div>
                            </div>
                            <div className={`${admin.admin_favority_edit_modal_content}`}>
                                <div>
                                    <div><span>최애 이름</span></div>
                                    <div><input type="text" onChange={(e) => setAddContent({...addContent, artistName: e.target.value})} /></div>
                                </div>
                                <div>
                                    <div><span>이미지</span></div>
                                    <div>
                                        <div><img src={`${process.env.REACT_APP_API_SERVER}/img?file=${addPreviewImage}`} alt="이미지" /></div>
                                    </div>
                                    <div>
                                        <button onClick={() => addImageInputRef.current.click()}>수정</button>
                                        <input
                                            type="file"
                                            ref={addImageInputRef}
                                            style={{ display: 'none' }}
                                            accept="image/*"
                                            onChange={handleAddImageUpload}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div><span>소속그룹</span></div>
                                    <div><input type="text" onChange={(e) => setAddContent({...addContent, artistGroup: e.target.value})} /></div>
                                </div>
                                <div>
                                    <div><span>소속사</span></div>
                                    <div><input type="text" onChange={(e) => setAddContent({...addContent, artistAgency: e.target.value})} /></div>
                                </div>
                                <div>
                                    <div><span>소속레이블</span></div>
                                    <div><input type="text" onChange={(e) => setAddContent({...addContent, labels: e.target.value})} /></div>
                                </div>
                                <div>
                                    <div><span>데뷔일</span></div>
                                    <div><input type="date" onChange={(e) => setAddContent({...addContent, debutDate: e.target.value})} /></div>
                                </div>
                                <div>
                                    <div><span>팬덤</span></div>
                                    <div><input type="text" onChange={(e) => setAddContent({...addContent, fandom: e.target.value})} /></div>
                                </div>
                                <div>
                                    <div><span>별명</span></div>
                                    <div><textarea onChange={(e) => setAddContent({...addContent, nickName: e.target.value})}></textarea></div>
                                </div>
                                <div>
                                    <div><span>실명</span></div>
                                    <div><input type="text" onChange={(e) => setAddContent({...addContent, realName: e.target.value})} /></div>
                                </div>
                                <div>
                                    <div><span>좋아하는 색상</span></div>
                                    <div><input type="text" onChange={(e) => setAddContent({...addContent, color: e.target.value})} /></div>
                                </div>
                                <div>
                                    <div><span>생일</span></div>
                                    <div><input type="date" onChange={(e) => setAddContent({...addContent, birth: e.target.value})} /></div>
                                </div>
                                <div>
                                    <div><span>영어이름</span></div>
                                    <div><input type="text" onChange={(e) => setAddContent({...addContent, engName: e.target.value})} /></div>
                                </div>
                                <div>
                                    <div><button onClick={() => onAddSave()}>최애등록하기</button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div className={`${admin.admin_favority_request_btn}`}>
                    <button onClick={handleFavorityRequest}>최애 추가요청</button>
                    {
                        pendingRequests !== 0 ?
                        <span>{pendingRequests}</span>
                        : null
                    }
                </div>

            </div>
            {
                favorityRequestModal &&
                <div className={`${admin.admin_favority_request_container}`}>
                    <div className={`${admin.admin_favority_request_box}`}>
                        <div className={`${admin.admin_favority_request_header}`}>
                            <div><span>최애 추가요청 목록</span></div>
                            <div><button onClick={() => setFavorityRequestModal(false)}><span className="material-symbols-outlined">close</span></button></div>
                        </div>
                        <div className={`${admin.admin_favority_request_tab}`}>
                            <div className={`${requestActiveButton === 'all' ? admin.admin_favority_request_active : ''}`}>
                                <button onClick={() => setRequestActiveButton('all')}>전체({totalRequests})</button>
                            </div>
                            <div className={`${requestActiveButton === 'request' ? admin.admin_favority_request_active : ''}`}>
                                <button onClick={() => setRequestActiveButton('request')}>요청({pendingRequests})</button>
                            </div>
                            <div className={`${requestActiveButton === 'complate' ? admin.admin_favority_request_active : ''}`}>
                                <button onClick={() => setRequestActiveButton('complate')}>처리완료({completedRequests})</button>
                            </div>
                        </div>
                        <div className={`${admin.admin_favority_request_content}`}>
                            <div className={`${admin.admin_favority_request_content_header}`}>
                                <div><span>최애이름</span></div>
                                <div><span>최애그룹</span></div>
                                <div><span>최애정보</span></div>
                                <div><span>유저아이디</span></div>
                                <div><span>신청일</span></div>
                                <div><span>처리</span></div>
                            </div>
                            {
                                filteredRequestFavorityList.map((item, index) => (
                                    <div key={index} className={`${admin.admin_favority_request_content_info}`}>
                                        <div><span>{item.favorityRequestName}</span></div>
                                        <div><span>{item.favorityRequestGroup}</span></div>
                                        <div><span>{item.favorityRequestText}</span></div>
                                        <div><span>{item.userId}</span></div>
                                        <div><span>{formatDate(item.regDate)}</span></div>
                                        <div>
                                            {
                                                item.favorityRequestState ? (
                                                    <div><span>처리완료</span></div>
                                                ) : (
                                                    <div><button onClick={() => handleFavorityRequestStateChange(item)}>확인처리</button></div>
                                                )
                                            }
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default AdminFavority;
