import React, { useEffect, useState } from 'react'
import * as chart from '../../apis/chart'


const SmimingChart = ( {users, userFavorityList, smiming, artistName} ) => {
  // artistName 상태를 관리하기 위한 useState 훅
  
  const [ activeButton, setActiveButton ] = useState('my_favority');
  const [ musicList, setMusicList ] = useState([]);

  useEffect(() => {
    // console.log(musicList);
  }, [musicList])

  useEffect(() => {
    if(artistName === '') {
      
    } else {
      if(activeButton === 'my_favority') {
        myFavority();
      }
      if(activeButton === 'melon') {
        melonChart();
      }
      if(activeButton === 'genie') {
        genieChart();
      }
      if(activeButton === 'vibe') {
        vibeChart();
      }
      if(activeButton === 'bugs') {
        bugsChart();
      }
    }
  }, [activeButton, artistName])

  const melonChart = async () => {
    const response = await chart.melonChart();
    const data = response.data;

    // // console.log(data.data);
    setMusicList([]);
    setMusicList(data.data);
  }
  const genieChart = async () => {
    const response = await chart.genieChart();
    const data = response.data;
    
    setMusicList([]);
    setMusicList(data.data);
    // // console.log(data);
  }
  const vibeChart = async () => {
    const response = await chart.vibeChart();
    const data = response.data;
    setMusicList([]);
    setMusicList(data.data);
    
    // // console.log(data);
  }
  const bugsChart = async () => {
    const response = await chart.bugsChart();
    const data = response.data;
    setMusicList([]);
    setMusicList(data.data);
    
    // // console.log(data);
  }
  const myFavority = async () => {
    const response = await chart.myFavority(artistName);
    const data = response.data;
    setMusicList([]);
    setMusicList(data.data);

    // // console.log(data);
  }

  const [isFixed, setIsFixed] = useState(false);

  // 스크롤 이벤트 핸들러
  const handleScroll = () => {
    // 여기서는 예시로 100px 이상 스크롤됐을 때를 조건으로 합니다.
    if (window.scrollY > 100) {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    // 컴포넌트가 언마운트될 때 이벤트 리스너를 제거합니다.
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div className='w-100 pb-5 mb-5'>
        <div className='w-100'>
          <div className={`${smiming.smiming_select_btn} ${isFixed ? smiming.fixed : ''}`}>
            <div className={`w-100 ${activeButton === 'my_favority' ? smiming.active_btn : ''}`}><button onClick={() => setActiveButton('my_favority')}>최애 순위</button></div>
            <div className={`w-100 ${activeButton === 'melon' ? smiming.active_btn : ''}`}><button onClick={() => setActiveButton('melon')}>멜론 TOP100</button></div>
            <div className={`w-100 ${activeButton === 'genie' ? smiming.active_btn : ''}`}><button onClick={() => setActiveButton('genie')}>지니 TOP100</button></div>
            <div className={`w-100 ${activeButton === 'vibe' ? smiming.active_btn : ''}`}><button onClick={() => setActiveButton('vibe')}>바이브 TOP100</button></div>
            <div className={`w-100 ${activeButton === 'bugs' ? smiming.active_btn : ''}`}><button onClick={() => setActiveButton('bugs')}>벅스 TOP100</button></div>
          </div>
          <div>
            {
              Array.isArray(musicList) && musicList.map((item, index) => (
                <div key={index} className='py-3 border-bottom mx-3'>
                  <div className='d-flex align-items-center w-100'>
                    <div className={`${smiming.smiming_chart_box_left}`}><span>{item.rank}</span></div>
                    <div className={`${smiming.smiming_chart_box_middle}`}><img src={item.albumArt} alt="" srcset="" /></div>
                    <div className={`${smiming.smiming_chart_box_right} ps-2`}>
                      <div><span>{item.albumName}</span></div>
                      <div><span>{item.title}</span></div>
                      <div><span>{item.artistName}</span></div>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default SmimingChart