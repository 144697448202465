import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';

const Country = ({handleCountryClose, showCountry, searchTerm, setSearchTerm, countries,filteredCountries, setFilteredCountries, setSelectedCountry, handleSelectedCountry}) => {
    const props = useSpring({ 
        to: { opacity: 1, transform: 'scale(1) translate(-50%, -50%)' }, 
        from: { opacity: 0, transform: 'scale(0) translate(-50%, -50%)' } 
    });



    useEffect(() => {
        setFilteredCountries(
            countries.filter((country) =>
                country?.name.toLowerCase().includes(searchTerm.toLowerCase()) || // 이름으로 검색
                country?.number.includes(searchTerm) || // 전화번호로 검색
                country?.krname.includes(searchTerm) || // 한글로 검색
                country?.code.includes(searchTerm) // 국가코드로 검색
            )
        );
    }, [searchTerm]);

    return (
        <>
            {
                showCountry &&
                <div className='country_container'>
                    <animated.div className='country_box' style={props}>
                        <div>
                            <div className='d-flex justify-content-center py-4 px-2 position-relative'>
                                <div className='pt-3'><h5>Select a Country</h5></div>
                                <div className='pe-3 position-absolute top-0 end-0 pt-3'><button className='region_close' onClick={() => handleCountryClose()}><span className="material-symbols-outlined">close</span></button></div>
                            </div>
                        </div>
                        <div>
                            <div className='country_search_box'>
                                <input type="text" placeholder='Search' onChange={event => setSearchTerm(event.target.value)} />
                                <span className="material-symbols-outlined">search</span>
                            </div>
                        </div>
                        <div className='py-3 px-4'>
                            <div className='country_list'>
                                {filteredCountries.map((country, index) => (
                                    <button key={index} className='d-flex' onClick={() => {setSelectedCountry(country); handleSelectedCountry(country); handleCountryClose();}}>
                                        <div className='country_img'><img src={country?.img} alt={country?.name} /></div>
                                        <div className='country_name'><span>{country?.name}</span> <span>({country?.code})</span></div>
                                        <div className='country_number'><span>{country?.number}</span></div>
                                    </button>
                                ))}
                            </div>
                        </div>
                    </animated.div>
                </div>
            }
        </>
    );
};

export default Country;
