import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import * as auth from '../../apis/auth';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import dailyduck from '../../containers/my/MyContainner.module.css';

const PasswordChange = () => {

  const location = useLocation();
  const MySwal = withReactContent(Swal);
  const { userStatus } = location.state || {};
  const [ user, setUser ] = useState({
    userId : '',
    userPw : '',
    userName : '',
    userEmail : ''
  });

  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordConfirmVisible, setPasswordConfirmVisible] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState({
      hasEnglish: false,
      hasNumber: false,
      hasSpecialChar: false,
      isValidLength: false,
  });
  const [passwordConfirmCheck, setPasswordConfirmCheck] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const [isCheck, setIsCheck] = useState(false);

  const back = useNavigate();

  useEffect(() => {
    // console.log('userStatus : ', userStatus);
  }, [userStatus]);

  useEffect(() => {
    // console.log('user : ', user);
  }, [user])

  const screenBack = () => {
      back(-1);
  }

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
      if (!password) {
      setIsFocused(false);
      }
  };

  const handlePasswordConfirm = (e) => {
    setPasswordConfirm(e.target.value);
  }

  useEffect(() => {
    if(password === passwordConfirm) {
      setPasswordConfirmCheck(true);
    } else {
      setPasswordConfirmCheck(false);
    }
  }, [handlePasswordConfirm])

  const handlePasswordChange = (e) => {
    const updatedPassword = e.target.value;
    setPassword(updatedPassword);
    setIsPasswordValid(passwordCheck(updatedPassword)); // passwordCheck 함수를 업데이트된 비밀번호로 호출
  };

  const handleTogglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
    
  const handleTogglePasswordConfirmVisibility = () => {
      setPasswordConfirmVisible(!passwordConfirmVisible);
  };

  const checkEnglish = password => /[a-zA-Z]/.test(password);
  const checkNumber = password => /\d/.test(password);
  const checkSpecialChar = password => /[~@%^&*_|<>{}!]/.test(password);
  const checkLength = password => password.length >= 8;

  const passwordCheck = (password) => {
    // password가 문자열이 아니라면, 모든 검사를 false로 설정
    if (typeof password !== 'string') {
        return {
          hasEnglish: false,
          hasNumber: false,
          hasSpecialChar: false,
          isValidLength: false,
        };
    }

    return {
        hasEnglish: checkEnglish(password),
        hasNumber: checkNumber(password),
        hasSpecialChar: checkSpecialChar(password),
        isValidLength: checkLength(password),
    };
  };

  const isFormFilled = () => {

    return password && passwordConfirm && isPasswordValid
            && isPasswordValid.hasEnglish && isPasswordValid.hasNumber
            && isPasswordValid.hasSpecialChar && isPasswordValid.isValidLength
            && passwordConfirmCheck
            ;
  }

  const passwordChangeSuccess = async () => {

    let userInfo = {
        userId : userStatus.userId,
        userPw : password,
        userName : userStatus.userName,
        userEmail : userStatus.userEmail
    };

    if(userInfo.userId !== '' || userInfo.userPw !== '' || userInfo.userName !== '' || userInfo.userEmail !== '') {
      const response = await auth.update(userInfo);
      const data = await response.data;

      // console.log(data);

      if(data === 'SUCCESS') {
          MySwal.fire({
            customClass: {
                popup: `${dailyduck.my_info_address_popup}`
            },
            html : 
            `
            <div>
            <div class='${dailyduck.my_info_address_title}'><span>비밀번호가 변경되었습니다.</span></div>
            <div class='${dailyduck.my_info_address_sub_title}'><span>변경된 비밀번호로 로그인해주세요.</span></div>
            <div class='${dailyduck.my_info_address_confirm_btn_box}'>
                <button id='address_confirm'>확인</button>
            </div>
            </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
          });
          document.addEventListener('click', addressConfirm);
          
          function addressConfirm(e) {
              if(e.target && e.target.id == 'address_confirm') {
                  MySwal.close();
                  document.removeEventListener('click', addressConfirm);
                  back('/');
              }
          }
      }
    }
  }

  useEffect(() => {
    // console.log(user.userId + " / " + user.userPw + " / " + user.userName + " / " + user.userEmail);
  }, [user]); // user 상태가 변경될 때마다 실행
  
  return (
    <>
        <div className='email_search_container position-relative'>
          <div className='position-relative d-flex justify-content-center py-3'>
              <div className='position-absolute start-0 top-0 py-3 ps-3'>
                  <button className='emailsearch_back' onClick={screenBack}>
                      <span className="material-symbols-outlined">arrow_back_ios</span>
                  </button>
              </div>
              <div><h5>비밀번호 찾기</h5></div>
          </div>
          <div>
            <div className='join_input_box'>
                <div className='pb-1'><span>비밀번호</span></div>
                <div className='join_input' style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                <input 
                    type={passwordVisible ? "text" : "password"} 
                    className='join_input_tag' 
                    placeholder='비밀번호' 
                    onFocus={handleFocus} 
                    onBlur={handleBlur} 
                    onChange={(e) => handlePasswordChange(e)} 
                    value={password} 
                    style={{ paddingRight: '40px' }}
                />
                <button className='password_eye_btn' onClick={handleTogglePasswordVisibility} >
                    {passwordVisible ? <span className="material-symbols-outlined">visibility</span> : <span className="material-symbols-outlined">visibility_off</span>}
                </button>
                </div>
                {isFocused && (
                    <div className='d-flex justify-content-center pw_check_point'>
                    <div className='pt-2 px-2 eng_check'>
                        <span style={{ color: isPasswordValid.hasEnglish ? "rgba(0, 189, 103, 1)" : "rgba(196, 199, 202, 1)"}}>
                        <span className="material-symbols-outlined" style={{ color: isPasswordValid.hasEnglish ? "rgba(0, 189, 103, 1)" : "rgba(196, 199, 202, 1)" }}>check</span>영문
                        </span>
                    </div>
                    <div className='pt-2 px-2 number_check'>
                        <span style={{ color: isPasswordValid.hasNumber ? "rgba(0, 189, 103, 1)" : "rgba(196, 199, 202, 1)"}}>
                        <span className="material-symbols-outlined" style={{ color: isPasswordValid.hasNumber ? "rgba(0, 189, 103, 1)" : "rgba(196, 199, 202, 1)" }}>check</span>숫자
                        </span>
                    </div>
                    <div className='pt-2 px-2 spec_check'>
                        <span style={{ color: isPasswordValid.hasSpecialChar ? "rgba(0, 189, 103, 1)" : "rgba(196, 199, 202, 1)"}}>
                        <span className="material-symbols-outlined" style={{ color: isPasswordValid.hasSpecialChar ? "rgba(0, 189, 103, 1)" : "rgba(196, 199, 202, 1)"}}>check</span>특수문자
                        </span>
                    </div>
                    <div className='pt-2 px-2 word_check'>
                        <span style={{ color: isPasswordValid.isValidLength ? "rgba(0, 189, 103, 1)" : "rgba(196, 199, 202, 1)"}}>
                        <span className="material-symbols-outlined" style={{ color: isPasswordValid.isValidLength ? "rgba(0, 189, 103, 1)" : "rgba(196, 199, 202, 1)"}}>check</span>8자 이상
                        </span>
                    </div>
                    </div>
                )}
            </div>
            <div className='join_input_box'>
                <div className='pb-1'><span>비밀번호 확인</span></div>
                <div className='join_input' style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                    <input 
                    type={passwordConfirmVisible ? "text" : "password"} 
                    className='join_input_tag' 
                    placeholder='비밀번호 확인' 
                    style={{ paddingRight: '40px' }}
                    onChange={(e) => handlePasswordConfirm(e)} 
                    />
                    <button className='password_eye_btn' onClick={handleTogglePasswordConfirmVisibility} >
                    {passwordConfirmVisible ? <span className="material-symbols-outlined">visibility</span> : <span className="material-symbols-outlined">visibility_off</span>}
                    </button>
                </div>
                    {passwordConfirm !== '' && (
                        password === passwordConfirm ? 
                        <span style={{ color: 'green' }}>비밀번호가 일치합니다.</span> :
                        <span style={{ color: 'red' }}>비밀번호가 일치하지 않습니다.</span>
                    )}
            </div>
          </div>
          <div className='password_change_successBox px-3 pb-3'>
            <div><button className={`${isFormFilled() ? 'password_active' : ''}`} disabled={!isFormFilled()} onClick={passwordChangeSuccess}>완료</button></div>
          </div>
        </div>
    </>
  )
}

export default PasswordChange