import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as auth from '../../apis/auth';
import { LoginContext } from '../../contexts/LoginContextProvider';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';

const AppleCallback = () => {
  const { isLogin, login, logout, remove, loginCheck } = useContext(LoginContext);
  const navigate = useNavigate();

  const [userInfo, setUserInfo] = useState(null);
  const [appleExist, setAppleExist] = useState(true);

  useEffect(() => {
    // console.log('userInfo : ', userInfo);
  }, [userInfo]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenParam = urlParams.get('token');

    // console.log('tokenParam : ', tokenParam);

    if (tokenParam) {
      try {
        // tokenParam을 JSON으로 파싱
        const token = JSON.parse(decodeURIComponent(tokenParam));

        // console.log('token : ', token);

        // id_token 추출 및 디코딩
        const decodedToken = jwtDecode(token.id_token);
        // console.log('Decoded id_token:', decodedToken);

        // 유저 정보를 상태에 저장
        setUserInfo(decodedToken);

        // 유저 존재 여부 확인
        checkUserExists(decodedToken);
      } catch (error) {
        console.error('Error decoding token:', error);
      }
    } else {
      console.error('No token found in URL');
    }
  }, [navigate]);

  const checkUserExists = async (user) => {
    const response = await auth.appleUserSearch(user.sub); // user.sub를 userId로 사용
    const data = response.data;

    // console.log(data);

    if (data === 'exist') {
      // console.log(user.sub);
      const userSelectResponse = await auth.appleUserSelect(user.sub);
      const userSelectData = userSelectResponse.data;

      // console.log('userSelectData:', userSelectData);

      // 로그인 처리를 위한 토큰 요청
      const tokenResponse = await auth.getToken({ userId: userSelectData.userId });
      const tokenData = tokenResponse.data;
      // console.log('tokenData:', tokenData);

      if (tokenResponse.status === 200) {
        const token = tokenData.token;

        // 쿠키에 토큰 저장
        Cookies.set("accessToken", token);
        localStorage.setItem("accessToken", token);

        // 로그인 체크 함수 호출
        await loginCheck();

        // 리다이렉션
        navigate('/market');
      }
    } else {
      setAppleExist(false);
    }
  }

  const join = async () => {
    navigate('/apple/join', { state: { apple: userInfo } });
  }

  return (
    <div>
      <div className='twitter_login_info_img_box'>
        <img src="../../img/logo_pink2.png" alt="데일리덕" />
      </div>
      {userInfo && !appleExist ? (
        <div className='twitter_login_info_box'>
          <div>
            <img src={`${process.env.PUBLIC_URL}/img/user/ProfileCircle.png`} alt="Profile" />
          </div>
          {/* <div>
            <span>{userInfo.sub}</span>
          </div>
          <div>
            <span>{userInfo.name}</span>
          </div> */}
          <div>
            <span>{userInfo.email}</span>
          </div>
          <div className='twitter_login_info_btn'>
            <button onClick={join}>데일리덕 가입하기</button>
          </div>
        </div>
      ) : (
        <p>사용자 정보를 확인 중입니다...</p>
      )}
    </div>
  );
};

export default AppleCallback;
