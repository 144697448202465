import React, { useContext, useEffect, useState } from 'react';
import seller from '../../../../seller.module.css';
import { LoginContext } from '../../../../../contexts/LoginContextProvider';
import * as sellerApi from '../../../../../apis/seller';
import { Pagination } from 'antd';
import { format } from 'date-fns';
import Swal from 'sweetalert2';

const SellertMessagePayment = () => {
    const { userInfo } = useContext(LoginContext);
    const [messagePaymentList, setMessagePaymentList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(6);
    const [totalItems, setTotalItems] = useState(0);
    const [selectedItems, setSelectedItems] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredPaymentList, setFilteredPaymentList] = useState([]);
    const [showPartialModal, setShowPartialModal] = useState(false);
    const [selectedPartialItem, setSelectedPartialItem] = useState([]);
    const [activePaymentState, setActivePaymentState] = useState('전체');

    const paginate = pageNumber => setCurrentPage(pageNumber);

    useEffect(() => {
        // console.log('selectedPartialItem : ', selectedPartialItem);
    }, [selectedPartialItem])

    useEffect(() => {
        if (userInfo) {
            messagePaymentSelect();
        }
    }, [userInfo]);

    useEffect(() => {
        filterPayments();
    }, [searchTerm, messagePaymentList, activePaymentState]);

    const messagePaymentSelect = async () => {
        const response = await sellerApi.messagePaymentSelect(userInfo && userInfo?.userId);
        const data = response.data;

        // console.log('paymentList : ', data);
        setMessagePaymentList(data);
        setFilteredPaymentList(data);
        setTotalItems(data.length);
    }

    const KSTformatDate = (date) => {
        return format(new Date(date), 'yyyy-MM-dd HH:mm:ss');
    };

    const filterPayments = () => {
        let filtered = messagePaymentList.filter(item =>
            (item.orderId && item.orderId.includes(searchTerm)) ||
            (item.paymentName && item.paymentName.includes(searchTerm)) ||
            (item.paymentNumber && item.paymentNumber.includes(searchTerm)) ||
            (item.userId && item.userId.includes(searchTerm))
        );

        if (activePaymentState !== '전체') {
            filtered = filtered.filter(item => item.paymentStatus === activePaymentState);
        }

        setFilteredPaymentList(filtered);
        setTotalItems(filtered.length);
        setCurrentPage(1); // 검색 시 첫 페이지로 이동
    };

    // Get current items
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredPaymentList.slice(indexOfFirstItem, indexOfLastItem);

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            const allCurrentItems = currentItems.map(item => item.chatPaymentNo);
            setSelectedItems(allCurrentItems);
        } else {
            setSelectedItems([]);
        }
    };

    const handleSelectItem = (e, item) => {
        if (e.target.checked) {
            setSelectedItems(prevItems => [...prevItems, item.chatPaymentNo]);
        } else {
            setSelectedItems(prevItems => prevItems.filter(id => id !== item.chatPaymentNo));
        }
    };

    const handleCancel = () => {
        const selectedInfo = messagePaymentList.filter(item => selectedItems.includes(item.chatPaymentNo));
        // console.log('Selected items for cancellation:', selectedInfo);
        // Add your cancel logic here
        if (selectedInfo.length === 0) {
            Swal.fire({
                customClass: {
                    popup: ``
                },
                html: `<div><div class=''><span>선택된 취소 주문이 없습니다.</span></div></div>`,
                showConfirmButton: false,
                showCancelButton: false,
                timer: 2000
            });
            return;
        }

        const hasInvalidState = selectedInfo.some(item => {
            const messageInfo = messagePaymentList.find(message => message.chatPaymentNo === item.chatPaymentNo);
            // console.log('messageInfo : ', messageInfo);
            if (!messageInfo) return true;
            if (messageInfo.paymentStatus === '결제취소') {
                return true;
            } else if (messageInfo.paymentStatus === '결제완료' || messageInfo.paymentStatus === '취소요청') {
                return false;
            }
        });

        if (hasInvalidState) {
            Swal.fire({
                customClass: {
                    popup: ``
                },
                html: `<div><div class=''><span>이미 결제 취소된 거래건입니다.</span></div></div>`,
                showConfirmButton: false,
                showCancelButton: false,
                timer: 2000
            });
        } else {
            Swal.fire({
                customClass: {
                    popup: `${seller.seller_message_payment_cancel_popup}`
                },
                html: `
                    <div>
                        <div class='${seller.seller_message_payment_cancel_title}'><span>${selectedInfo.length}개 상품을 취소 처리 하시겠습니까?</span></div>
                        <div class='${seller.seller_message_payment_cancel_sub_title}'><span>전액 취소 처리가 되며 부분취소의 경우 부분취소처리를 이용해주세요.</span></div>
                        <div class='${seller.seller_message_payment_cancel_btn_box} py-2 d-flex w-100 justify-content-between'>
                          <button class='mx-1' id='seller_add_cancel'>아니오</button>
                          <button class='mx-1' id='seller_add_success'>예</button>
                        </div>
                    </div>
                `,
                showConfirmButton: false,
                showCancelButton: false,
            });
            document.addEventListener('click', sellerAddSuccess);
            document.addEventListener('click', sellerAddCancel);

            async function sellerAddSuccess(e) {
                if (e.target && e.target.id === 'seller_add_success') {
                    try {
                        const response = await sellerApi.messagePaymentCancel(selectedInfo);
                        const data = response.data;

                        if (data === 'SUCCESS') {
                            Swal.fire({
                                customClass: {
                                    popup: ``
                                },
                                html: `
                                    <div>
                                        <div class=''><span>${selectedInfo.length}개 상품이 취소처리 되었습니다.</span></div>
                                        <div class=''><span>고객에게 환불관련 안내 메세지가 전달되었습니다.</span></div>
                                        <div class='py-2 d-flex w-100 justify-content-between'>
                                            <button class='mx-1' id='seller_add_confirm'>확인</button>
                                        </div>
                                    </div>
                                `,
                                showConfirmButton: false,
                                showCancelButton: false,
                            });
                            document.addEventListener('click', sellerAddConfirm);

                            function sellerAddConfirm(e) {
                                if (e.target && e.target.id === 'seller_add_confirm') {
                                    messagePaymentSelect();
                                    Swal.close();
                                    document.removeEventListener('click', sellerAddConfirm);
                                    document.removeEventListener('click', sellerAddSuccess);
                                    document.removeEventListener('click', sellerAddCancel);
                                }
                            }
                        } else {
                            Swal.fire({
                                customClass: {
                                    popup: ``
                                },
                                html: `
                                <div>
                                <div class=''><span>${selectedInfo.length}개 상품 취소 처리에 실패했습니다.</span></div>
                                <div class='py-2 d-flex w-100 justify-content-between'>
                                    <button class='mx-1' id='seller_add_confirm'>확인</button>
                                </div>
                                </div>
                                `,
                                showConfirmButton: false,
                                showCancelButton: false,
                            });
                            document.addEventListener('click', sellerAddConfirm);

                            function sellerAddConfirm(e) {
                                if (e.target && e.target.id === 'seller_add_confirm') {
                                    messagePaymentSelect();
                                    Swal.close();
                                    document.removeEventListener('click', sellerAddConfirm);
                                    document.removeEventListener('click', sellerAddSuccess);
                                    document.removeEventListener('click', sellerAddCancel);
                                }
                            }
                        }
                    } catch (error) {
                        console.error('error : ', error);
                    }
                }
            }

            function sellerAddCancel(e) {
                if (e.target && e.target.id === 'seller_add_cancel') {
                    Swal.close();
                    document.removeEventListener('click', sellerAddSuccess);
                    document.removeEventListener('click', sellerAddCancel);
                }
            }
        }
    };

    const handlePartialCancel = () => {
        const selectedInfo = messagePaymentList.filter(item => selectedItems.includes(item.chatPaymentNo));
        // console.log('Selected items for partial cancellation:', selectedInfo);
        if (selectedInfo.length === 0) {
            Swal.fire({
                customClass: {
                    popup: ``
                },
                html: `<div><div class=''><span>선택된 취소 주문이 없습니다.</span></div></div>`,
                showConfirmButton: false,
                showCancelButton: false,
                timer: 2000
            });
            return;
        }

        const hasInvalidState = selectedInfo.some(item => {
            const messageInfo = messagePaymentList.find(message => message.chatPaymentNo === item.chatPaymentNo);
            // console.log('messageInfo : ', messageInfo);
            if (!messageInfo) return true;
            if (messageInfo.paymentStatus === '결제취소') {
                return true;
            } else if (messageInfo.paymentStatus === '결제완료' || messageInfo.paymentStatus === '취소요청' || messageInfo.paymentStatus === '부분취소') {
                return false;
            }
        });

        if (hasInvalidState) {
            Swal.fire({
                customClass: {
                    popup: ``
                },
                html: `<div><div class=''><span>이미 결제 취소된 거래건입니다.</span></div></div>`,
                showConfirmButton: false,
                showCancelButton: false,
                timer: 2000
            });
        } else {
            setSelectedPartialItem(selectedInfo);
            setShowPartialModal(true);
        }
    };

    const handlePartialInputChange = (e, chatPaymentNo, amount) => {
        let { value } = e.target;
        if (value > amount) {
            alert('취소 금액은 결제 금액보다 클 수 없습니다.');
            value = amount;
        }
        setSelectedPartialItem(prevState =>
            prevState.map(item =>
                item.chatPaymentNo === chatPaymentNo
                    ? { ...item, cancelPriceInput: value }
                    : item
            )
        );

        // Update the input value in the DOM
        e.target.value = value;
    };

    const handlePartialCancelSubmit = () => {
        // console.log('Updated selected items for partial cancellation:', selectedPartialItem);

        Swal.fire({
            customClass: {
                popup: `${seller.seller_message_payment_cancel_popup}`
            },
            html: `
                <div>
                    <div class='${seller.seller_message_payment_cancel_title}'><span>${selectedPartialItem.length}개 상품을 부분취소 처리 하시겠습니까?</span></div>
                    <div class='${seller.seller_message_payment_cancel_btn_box} py-2 d-flex w-100 justify-content-between'>
                      <button class='mx-1' id='seller_add_cancel'>아니오</button>
                      <button class='mx-1' id='seller_add_success'>예</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });
        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);

        async function sellerAddSuccess(e) {
            if (e.target && e.target.id === 'seller_add_success') {
                try {
                    const response = await sellerApi.messagePartialPaymentCancel(selectedPartialItem);
                    const data = response.data;

                    if (data === 'SUCCESS') {
                        Swal.fire({
                            customClass: {
                                popup: ``
                            },
                            html: `
                                <div>
                                    <div class=''><span>${selectedPartialItem.length}개 상품이 부분취소처리 되었습니다.</span></div>
                                    <div class=''><span>고객에게 환불관련 안내 메세지가 전달되었습니다.</span></div>
                                    <div class='py-2 d-flex w-100 justify-content-between'>
                                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                                    </div>
                                </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);

                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                messagePaymentSelect();
                                setShowPartialModal(false);
                                Swal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    } else {
                        Swal.fire({
                            customClass: {
                                popup: ``
                            },
                            html: `
                            <div>
                            <div class=''><span>${selectedPartialItem.length}개 상품 부분취소 처리에 실패했습니다.</span></div>
                            <div class='py-2 d-flex w-100 justify-content-between'>
                                <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);

                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                messagePaymentSelect();
                                Swal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    }
                } catch (error) {
                    console.error('error : ', error);
                }
            }
        }

        function sellerAddCancel(e) {
            if (e.target && e.target.id === 'seller_add_cancel') {
                Swal.close();
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
            }
        }

        // setShowPartialModal(false);
    };

    return (
        <div className={`${seller.seller_dashboard_container} py-3 px-3`}>
            <div>
                <div className={`${seller.seller_message_payment_header}`}>
                    <span>메세지 결제내역</span>
                </div>
                <div className={`${seller.seller_message_payment_tab}`}>
                    <div className={activePaymentState === '전체' ? seller.seller_message_payment_tab_active : ''}><button onClick={() => setActivePaymentState('전체')}>전체</button></div>
                    <div className={activePaymentState === '결제완료' ? seller.seller_message_payment_tab_active : ''}><button onClick={() => setActivePaymentState('결제완료')}>결제완료</button></div>
                    <div className={activePaymentState === '부분취소' ? seller.seller_message_payment_tab_active : ''}><button onClick={() => setActivePaymentState('부분취소')}>부분취소</button></div>
                    <div className={activePaymentState === '결제취소' ? seller.seller_message_payment_tab_active : ''}><button onClick={() => setActivePaymentState('결제취소')}>결제취소</button></div>
                </div>
                <div className={`${seller.seller_message_payment_search_btn_box}`}>
                    <div>
                        <div><button onClick={handleCancel}>취소처리</button></div>
                        <div><button onClick={handlePartialCancel}>부분취소처리</button></div>
                    </div>
                    <div>
                        <div>
                            <input
                                type="text"
                                placeholder="검색어를 입력하세요"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className={`${seller.seller_message_payment_content}`}>
                    <div className={`${seller.seller_message_payment_content_header}`}>
                        <div><span><input type="checkbox" onChange={handleSelectAll} checked={selectedItems.length === currentItems.length && currentItems.length > 0} /></span></div>
                        <div><span>결제아이디</span></div>
                        <div><span>결제내용</span></div>
                        <div><span>결제정보</span></div>
                        <div><span>결제일</span></div>
                    </div>
                    {
                        currentItems.map((item, index) => (
                            <div className={`${seller.seller_message_payment_content_info}`} key={index}>
                                <div><span><input type="checkbox" onChange={(e) => handleSelectItem(e, item)} checked={selectedItems.includes(item.chatPaymentNo)} /></span></div>
                                <div><span>{item.orderId}</span></div>
                                <div>
                                    <div><span>{item.paymentNumber}</span></div>
                                    <div><span>{item.paymentName}</span></div>
                                </div>
                                <div>
                                    <div>
                                        <div>
                                            <div><span>결제금액</span></div>
                                            <div><span>{item.amount.toLocaleString()}원</span></div>
                                        </div>
                                        <div>
                                            <div><span>결제여부</span></div>
                                            <div><span>{item.paymentStatus}</span></div>
                                        </div>
                                        <div>
                                            <div><span>결제방식</span></div>
                                            <div><span>{item.paymentType}</span></div>
                                        </div>
                                    </div>
                                    {
                                        item.paymentStatus === '결제취소' &&
                                        <div className={`${seller.seller_message_payment_cancel_info}`}>
                                            <div>
                                                <div className='pb-2'><span>취소정보</span></div>
                                                <div className={`${seller.seller_message_payment_cancel_info_content}`}>
                                                    <div>
                                                        <div><span>취소내용</span></div>
                                                        <div><span>{item.paymentCancelContent}</span></div>
                                                    </div>
                                                    <div>
                                                        <div><span>취소금액</span></div>
                                                        <div><span>{item.cancelPrice.toLocaleString()}원</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        item.paymentStatus === '부분취소' &&
                                        <div className={`${seller.seller_message_payment_cancel_info}`}>
                                            <div>
                                                <div className='pb-2'><span>부분취소정보</span></div>
                                                <div className={`${seller.seller_message_payment_cancel_info_content}`}>
                                                    <div>
                                                        <div><span>부분취소내용</span></div>
                                                        <div><span>{item.paymentCancelContent}</span></div>
                                                    </div>
                                                    <div>
                                                        <div><span>취소금액</span></div>
                                                        <div><span>{item.cancelPrice.toLocaleString()}원</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div>
                                    <div>
                                        <span>{KSTformatDate(item.chatPaymentRegDate)}</span>
                                    </div>
                                    {
                                        item.paymentStatus === '결제취소' &&
                                        <div className={`${seller.seller_message_payment_cancel_date}`}>
                                            <div><span>결제취소일</span></div>
                                            <div><span>{KSTformatDate(item.paymentCancelDate)}</span></div>
                                        </div>
                                    }
                                    {
                                        item.paymentStatus === '부분취소' &&
                                        <div className={`${seller.seller_message_payment_cancel_date}`}>
                                            <div><span>결제 부분취소일</span></div>
                                            <div><span>{KSTformatDate(item.paymentCancelDate)}</span></div>
                                        </div>
                                    }
                                </div>
                            </div>
                        ))
                    }
                </div>
                <Pagination current={currentPage} total={totalItems} onChange={paginate} pageSize={itemsPerPage} />
            </div>
            {
                showPartialModal &&
                <div className={`${seller.seller_message_payment_partial_modal_container}`}>
                    <div className={`${seller.seller_message_payment_partial_modal_box}`}>
                        <div className={`${seller.seller_message_payment_partial_modal_header}`}>
                            <div><span>부분취소</span></div>
                            <div><button onClick={() => setShowPartialModal(false)}><span className="material-symbols-outlined">close</span></button></div>
                        </div>
                        <div className={`${seller.seller_message_payment_partial_modal_content}`}>
                            {
                                selectedPartialItem.map((item, index) => (
                                    <div key={index} className={`${seller.seller_message_payment_partial_modal_content_item}`}>
                                        <div>
                                            <div><span>{index + 1}.</span></div>
                                            <div className={`${seller.seller_message_payment_partial_modal_content_dflex}`}>
                                                <div><span>결제번호</span></div>
                                                <div><span>{item.paymentNumber}</span></div>
                                            </div>
                                            <div className={`${seller.seller_message_payment_partial_modal_content_dflex}`}>
                                                <div><span>결제내용</span></div>
                                                <div><span>{item.paymentName}</span></div>
                                            </div>
                                            <div className={`${seller.seller_message_payment_partial_modal_content_dflex}`}>
                                                <div><span>결제금액</span></div>
                                                <div><span>{item.amount.toLocaleString()}원</span></div>
                                            </div>
                                            {
                                                item.paymentStatus === '부분취소' && 
                                                <div className={`${seller.seller_message_payment_partial_modal_content_dflex} ${seller.seller_partial_active}`}>
                                                    <div><span>취소가능금액</span></div>
                                                    <div><span>{(item.amount-item.cancelPrice).toLocaleString()}원</span></div>
                                                </div>
                                            }
                                            <div className={`${seller.seller_message_payment_partial_modal_content_dflex}`}>
                                                <div><span>취소금액</span></div>
                                                <div className='d-flex align-items-center'><input type="number" onChange={(e) => handlePartialInputChange(e, item.chatPaymentNo, item.amount)} /><span className='ps-2'>원</span></div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        <div className={`${seller.seller_message_payment_partial_modal_footer}`}>
                            <div><button onClick={() => setShowPartialModal(false)}>취소</button></div>
                            <div><button onClick={handlePartialCancelSubmit}>부분취소요청</button></div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default SellertMessagePayment;
