import React, { useEffect, useState } from 'react';
import * as category from '../../../../../apis/category';
import * as support from '../../../../../apis/support';
import { Select } from 'antd';
import TextEditor from '../edit/TextEditor';
import TextEditor2 from '../edit/TextEditor2';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useNavigate } from 'react-router-dom';
import SellerSalesMain from '../sales/SellerSalesMain';

const { Option } = Select;

const SellerProductDetail = ({
        seller, sellerapi, userInfo, detailProduct, setShowDetail, productDetailList, setProductDetailList, onProductUpdate, productInfo, setProductInfo, charCount, setCharCount, charLimit,
        showFaqModal, setShowFaqModal, faqList, setFaqList,
        selectedFaqs, setSelectedFaqs, showFaqAdd, setShowFaqAdd, faqListSelect, sellerFaqAdd, handleDetaoleCheckboxChange, handleFaqSelect, handleRemoveFaq
    }) => {
    const MySwal = withReactContent(Swal);
    const navigate = useNavigate();
    const [imageRepresetativePreview, setImageRepresetativePreview] = useState('');
    const [imageListImgPreview1, setImageListImgPreview1] = useState('');
    const [imageListImgPreview2, setImageListImgPreview2] = useState('');
    const [imageListImgPreview3, setImageListImgPreview3] = useState('');
    const [imageListImgPreview4, setImageListImgPreview4] = useState('');
    const [productName, setProductName] = useState('');
    const [mainCategory, setMainCategory] = useState('서포트');
    const [mainCategorySelect, setMainCategorySelect] = useState('');
    const [middleCategory, setMiddleCategory] = useState([]);
    const [middleCategorySelect, setMiddleCategorySelect] = useState('');
    const [subCategory, setSubCategory] = useState([]);
    const [selectSubCategory, setSelectSubCategory] = useState([]);
    const [subCategorySelect, setSubCategorySelect] = useState([]);
    const [products, setProducts] = useState([{ options: [], useOptions: false }]);
    const [data, setData] = useState("");
    const [data2, setData2] = useState("");
    const [fileName, setFileName] = useState('');

    

    const onDetailContent = (data) => {
        setProductDetailList(currentProductList => ({
            ...currentProductList,
            detailContents: data
        }));
    };
    const onExchangeReturn = (data) => {
        setProductDetailList(currentProductList => ({
            ...currentProductList,
            exchangeReturn: data
        }));
    };

    const updateProductList = (newProducts) => {
        setProducts(newProducts);
    
        setProductDetailList(currentProductList => ({
            ...currentProductList,
            mainItem: newProducts.map((product, index) => ({
                mainItemNo: product.mainItemNo || '',
                mainItemIndex: index + 1, // mainItemIndex 설정
                mainItemImg: product.imageUrl || '',
                mainItemPrice: product.mainItemPrice || '',
                mainItemOptionState: product.useOptions || false,
                mainOptionName: product.mainOptionName || '',
                userId: userInfo.userId || '',
                productNumber: '' // 서버에 전송 후 설정 예정
            })),
            mainItemOption: newProducts.flatMap((product, productIndex) => {
                // mainItem의 mainItemNo에 해당하는 옵션만 포함
                return product.options.map((option, optionIndex) => ({
                    mainItemNo: product.mainItemNo, // 현재 product의 mainItemNo
                    mainItemIndex: productIndex + 1, // mainItemIndex 설정
                    optionNo: option.optionNo || '',
                    optionTitle: option.title || '',
                    optionType: option.type || '',
                    optionRequired: option.required || false,
                    optionPrice: option.type === 'shortAnswer' ? option.price || 0 : (option.items ? option.items.map(item => item.price).join(';') : 0),
                    optionName: option.items ? option.items.map(item => item.name).join(';') : '',
                    optionDateTime: option.dateTime || false,
                    useQuantity: option.useQuantity || false
                }));
            }).filter(option => option.mainItemNo) // mainItemNo가 있는 옵션만 포함
        }));
    };

    const initializeProductData = () => {
        if (detailProduct) {
            setImageRepresetativePreview(detailProduct.representativeImage);
            setImageListImgPreview1(detailProduct.listImage1);
            setImageListImgPreview2(detailProduct.listImage2);
            setImageListImgPreview3(detailProduct.listImage3);
            setImageListImgPreview4(detailProduct.listImage4);
            setProductName(detailProduct.productName);
            setMiddleCategorySelect(detailProduct.middleCategory);

            // 소분류 값 필터링 후 설정
            const filteredSubCategories = subCategory.filter(sub => sub.supportMainCategoryEngName === detailProduct.middleCategory);
            setSelectSubCategory(filteredSubCategories);
            setSubCategorySelect(detailProduct.subCategory || ''); // 초기 소분류 설정

            setData(detailProduct.detailContents);
            setData2(detailProduct.exchangeReturn);
            setProductInfo(detailProduct.productInfo);
    
            const initialProducts = (detailProduct?.mainItem || []).map(item => ({
                mainItemNo: item.mainItemNo,
                imageUrl: item.mainItemImg,
                mainItemPrice: item.mainItemPrice,
                useOptions: item.mainItemOptionState,
                mainOptionName: item.mainOptionName,
                options: (detailProduct?.mainItemOption || [])
                    .filter(option => option.mainItemNo === item.mainItemNo)  // mainItemNo에 맞는 옵션만 필터링
                    .map(option => ({
                        type: option.optionType,
                        title: option.optionTitle,
                        required: option.optionRequired,
                        price: option.optionType === 'shortAnswer' ? option.optionPrice : 0,
                        items: option.optionType === 'shortAnswer' ? [] : (option.optionName || '').split(';').map((name, index) => ({
                            name,
                            price: parseInt((option.optionPrice || '').split(';')[index], 10) || 0
                        })),
                        dateTime: option.optionDateTime,
                        useQuantity : option.useQuantity
                    }))
            }));
            
    
            updateProductList(initialProducts);
            setSelectedFaqs(detailProduct.faqs);
    
            const faqArray = detailProduct.faq ? detailProduct.faq.split(',').map(Number) : []; // faq 값을 배열로 변환
    
            setProductDetailList(currentProductList => ({
                ...currentProductList,
                productNo: detailProduct?.productNo || '',
                productNumber: detailProduct?.productNumber || '',
                productName: detailProduct?.productName || '',
                productInfo: detailProduct?.productInfo || '',
                mainCategory: 'support',
                middleCategory: detailProduct?.middleCategory || '',
                subCategory: detailProduct?.subCategory || '',
                favorityArtist: '',
                relatedProduct: '',
                setAmount: '',
                regularPrice: '',
                salePrice: '',
                discount: '',
                mainItem: (initialProducts || []).map(product => ({
                    mainItemNo: product.mainItemNo || '',
                    mainItemIndex: product.mainItemIndex || '',
                    mainItemImg: product.imageUrl || '',
                    mainItemPrice: product.mainItemPrice || '',
                    mainItemOptionState: product.useOptions || false,
                    mainOptionName: product.mainOptionName || '',
                    userId: userInfo?.userId || '',
                    productNumber: detailProduct?.productNumber || ''
                })),
                mainItemOption: (detailProduct?.mainItemOption || []).map(option => ({
                    mainItemNo: option.mainItemNo || '',
                    mainItemIndex: option.mainItemIndex || '',
                    optionNo: option.optionNo || '',
                    optionTitle: option.optionTitle || '',
                    optionType: option.optionType || '',
                    optionName: option.optionType === 'shortAnswer' ? '' : (option.optionName || '').split(';').map((name, index) => ({
                        name,
                        price: parseInt((option.optionPrice || '').split(';')[index], 10) || 0
                    })).map(item => item.name).join(';'),
                    optionPrice: option.optionType === 'shortAnswer' ? option.optionPrice || '' : (option.optionName || '').split(';').map((name, index) => ({
                        name,
                        price: parseInt((option.optionPrice || '').split(';')[index], 10) || 0
                    })).map(item => item.price).join(';'),
                    optionRequired: option.optionRequired || false,
                    optionDateTime: option.optionDateTime || false,
                    useQuantity : option.useQuantity || false
                })),
                representativeImage: detailProduct?.representativeImage || '',
                listImage1: detailProduct?.listImage1 || '',
                listImage2: detailProduct?.listImage2 || '',
                listImage3: detailProduct?.listImage3 || '',
                listImage4: detailProduct?.listImage4 || '',
                userId: userInfo?.userId || '',
                detailContents: detailProduct?.detailContents || '',
                faq: faqArray || [],
                dateSelect: '',
                photoSelect: '',
                contentSelect: '',
                exchangeReturn: detailProduct?.exchangeReturn || '',
                faqs: detailProduct?.faqs || []
            }));            
        }
    };

    useEffect(() => {
        if (subCategory.length > 0 && detailProduct) {
            initializeProductData();
        }
    }, [subCategory, detailProduct]);

    useEffect(() => {
        middleCategoryData();
        supportSubCategorySelect();
        // initializeProductData();
        console.log('디테일 페이지 : ', detailProduct);
    }, [detailProduct]);

    const middleCategoryData = async () => {
        const response = await category.supportCategory();
        const data = response.data;
        setMiddleCategory(data);
    };

    const supportSubCategorySelect = async () => {
        const response = await category.supportSubCategorySelect();
        const data = response.data;

        console.log('supportSubCategorySelect : ', data);
        setSubCategory(data);
    }

    const handleImageAdd = (productIndex) => {
        document.getElementById(`hiddenImageInput_${productIndex}`).click();
    };

    const imageAdd = async (e, productIndex) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('image', file);
        const config = { headers: { 'content-type': 'multipart/form-data' } };
        const response = await support.uploadImageToServer(formData, config);
        const data = response.data;
        const imageUrl = `${process.env.REACT_APP_API_SERVER}/img?file=${encodeURIComponent(data)}`;
        const newProducts = [...products];
        newProducts[productIndex].imageUrl = imageUrl;
        updateProductList(newProducts);
    };

    const handleAddProduct = () => {
        const newProducts = [...products, { options: [], useOptions: false }];
        updateProductList(newProducts);
    };

    const handleAddOption = (productIndex) => {
        const newProducts = [...products];
        newProducts[productIndex].options.push({ type: '', items: [] });
        updateProductList(newProducts);
    };
    const handleRemoveProduct = (productIndex) => {
        const newProducts = [...products];
        newProducts.splice(productIndex, 1);
        updateProductList(newProducts);
    };
    const handleAddOptionItem = (productIndex, optionIndex) => {
        const newProducts = [...products];
        newProducts[productIndex].options[optionIndex].items = newProducts[productIndex].options[optionIndex].items || [];
        newProducts[productIndex].options[optionIndex].items.push({ name: '', price: 0 });
        updateProductList(newProducts);
    };

    const handleUseOptionsChange = (productIndex, useOptions) => {
        const newProducts = [...products];
        newProducts[productIndex].useOptions = useOptions;
        updateProductList(newProducts);
    };

    const handleOptionTitleChange = (e, productIndex, optionIndex) => {
        const newProducts = [...products];
        newProducts[productIndex].options[optionIndex].title = e.target.value;
        updateProductList(newProducts);
    };

    const handleShortAnswerPriceChange = (e, productIndex, optionIndex) => {
        const newProducts = [...products];
        newProducts[productIndex].options[optionIndex].price = parseInt(e.target.value, 10) || 0;
        updateProductList(newProducts);
    };

    const handleRemoveOption = (productIndex, optionIndex) => {
        const newProducts = [...products];
        newProducts[productIndex].options = newProducts[productIndex].options.filter((_, i) => i !== optionIndex);
        updateProductList(newProducts);
    };

    const handleRemoveOptionItem = (productIndex, optionIndex, itemIndex) => {
        const newProducts = [...products];
        newProducts[productIndex].options[optionIndex].items = newProducts[productIndex].options[optionIndex].items.filter((_, i) => i !== itemIndex);
        updateProductList(newProducts);
    };

    const handleTypeChange = (value, productIndex, optionIndex) => {
        console.log('value : ', value);
        const newProducts = [...products];
        newProducts[productIndex].options[optionIndex].type = value;
        newProducts[productIndex].options[optionIndex].items = newProducts[productIndex].options[optionIndex].items || [];
    
        // '단일선택' 또는 '중복선택'일 경우에만 useQuantity 속성 추가
        if (value === 'singleSelection' || value === 'multipleSelection') {
            newProducts[productIndex].options[optionIndex].useQuantity = newProducts[productIndex].options[optionIndex].useQuantity || false;
        } else {
            delete newProducts[productIndex].options[optionIndex].useQuantity;
        }

        // newProducts[productIndex].options[optionIndex].dateTime = value === 'dateSelection';
    
        updateProductList(newProducts);
    };

    const handleOptionItemChange = (e, productIndex, optionIndex, itemIndex, field) => {
        const newProducts = [...products];
        newProducts[productIndex].options[optionIndex].items[itemIndex][field] = field === 'price' ? parseInt(e.target.value, 10) : e.target.value;
        updateProductList(newProducts);
    };

    const renderOptionContent = (option, productIndex, optionIndex) => {
        switch (option.type) {
            case 'shortAnswer':
                return (
                    <div key={optionIndex} className={`${seller.seller_edit_option_select_short_answer}`}>
                        <div><input type="text" placeholder='입력하고 싶은 레터링을 추가해 주세요.' value={option.title || ''} onChange={(e) => handleOptionTitleChange(e, productIndex, optionIndex)} /></div>
                        <div><span>금액추가</span></div>
                        <div>
                            <div><span>&#8361;</span></div>
                            <div>
                                <input
                                    type="number"
                                    placeholder='0'
                                    value={option.price || 0}
                                    onChange={(e) => handleShortAnswerPriceChange(e, productIndex, optionIndex)}
                                />
                            </div>
                        </div>
                    </div>
                );
            case 'singleSelection':
            case 'multipleSelection':
                return (
                    <div key={optionIndex} className={`${seller.seller_edit_option_select_single_selection}`}>
                        <div><input type="text" placeholder={`${option.type === 'singleSelection' ? '단일선택' : '중복선택'} 제목을 입력해주세요.`} value={option.title || ''} onChange={(e) => handleOptionTitleChange(e, productIndex, optionIndex)} /></div>
                        {option.items.map((item, itemIndex) => (
                            <div key={itemIndex} className={`${seller.seller_edit_option_select_single_selection_add}`}>
                                <div><input type="text" placeholder='옵션명' value={item.name} onChange={(e) => handleOptionItemChange(e, productIndex, optionIndex, itemIndex, 'name')} /></div>
                                <div><span>금액추가</span></div>
                                <div>
                                    <div><span>&#8361;</span></div>
                                    <div><input type="number" placeholder='0' value={item.price} onChange={(e) => handleOptionItemChange(e, productIndex, optionIndex, itemIndex, 'price')} /></div>
                                </div>
                                <div className='ps-3'>
                                    <button onClick={() => handleRemoveOptionItem(productIndex, optionIndex, itemIndex)}><span className="material-symbols-outlined">cancel</span></button>
                                </div>
                            </div>
                        ))}
                        <div className={`${seller.seller_edit_option_select_single_selection_add_btn}`}><button onClick={() => handleAddOptionItem(productIndex, optionIndex)}>옵션추가</button></div>
                    </div>
                );
            case 'dateSelection':
                return (
                    <div key={optionIndex} className={`${seller.seller_edit_option_select_date}`}>
                        <div><input type="text" placeholder='ex) 언제까지 배달을 희망하시나요?' value={option.title || ''} onChange={(e) => handleOptionTitleChange(e, productIndex, optionIndex)} /></div>
                        <div className='py-2'>
                            <div>
                                <input 
                                    type="checkbox" 
                                    checked={option.dateTime || false}
                                    onChange={(e) => {
                                        const newProducts = [...products];
                                        newProducts[productIndex].options[optionIndex].dateTime = e.target.checked;
                                        updateProductList(newProducts);
                                    }}
                                />
                                <label className='ps-1' htmlFor="">시간 포함</label>
                            </div>
                        </div>
                    </div>
                );
            case 'fileUpload':
                return (
                    <div key={optionIndex} className={`${seller.seller_edit_option_select_file_upload}`}>
                        <div><input type="text" placeholder='ex) 인쇄할 이미지 파일을 올려주세요.' value={option.title || ''} onChange={(e) => handleOptionTitleChange(e, productIndex, optionIndex)} /></div>
                        <div className='py-2'>
                            <div><span>최대 50mb 첨부 가능</span></div>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    const getOptionLabel = (value) => {
        switch (value) {
            case 'shortAnswer':
                return '단답형';
            case 'singleSelection':
                return '단일선택';
            case 'multipleSelection':
                return '중복선택';
            case 'dateSelection':
                return '날짜선택';
            case 'fileUpload':
                return '파일 업로드';
            default:
                return '';
        }
    };

    const onMainCategory = (e) => {
        const newCategory = e;
        setMainCategorySelect(newCategory);
        setProductDetailList(currentProductList => ({
            ...currentProductList,
            mainCategory: newCategory
        }));
    };

    const onMiddleCategory = (e) => {
        const newCategory = e;
        setMiddleCategorySelect(newCategory);

        // 선택된 중분류에 맞는 소분류 필터링
        const filteredSubCategories = subCategory.filter(sub => sub.supportMainCategoryEngName === newCategory);
        console.log('filteredSubCategories : ', filteredSubCategories);
        setSelectSubCategory(filteredSubCategories);

        // 소분류 초기화
        setSubCategorySelect('');
        setProductDetailList(currentProductList => ({
            ...currentProductList,
            middleCategory: newCategory,
            subCategory: ''
        }));
    };

    const onSubCategory = (value) => {
        setSubCategorySelect(value);
        setProductDetailList(currentProductList => ({
            ...currentProductList,
            subCategory: value
        }));
    };


    const representativeImageAdd = async (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('image', file);
        const config = { headers: { 'content-type': 'multipart/form-data' } };
        const response = await support.uploadImageToServer(formData, config);
        const data = response.data;
        const imageUrl = `${process.env.REACT_APP_API_SERVER}/img?file=${encodeURIComponent(data)}`;
        setImageRepresetativePreview(imageUrl);
        setProductDetailList(currentProductList => ({
            ...currentProductList,
            representativeImage: imageUrl
        }));
    };

    const handleRepresentativeInputClick = () => {
        document.getElementById('hiddenRepresentativeInput').click();
    };

    const handleRepresentativeDeleteClick = async () => {
        try {
            const queryParams = imageRepresetativePreview.split('?')[1];
            let filePath = '';
            const params = queryParams.split('&');
            for (let param of params) {
                if (param.startsWith('file=')) {
                    filePath = decodeURIComponent(param.split('=')[1]);
                    break;
                }
            }
            const fileName = filePath.split('/').pop();
            await fetch(`/api/file/delete/${fileName}`, { method: 'DELETE' });
            setImageRepresetativePreview('');
            setProductDetailList(currentProductList => ({
                ...currentProductList,
                representativeImage: ''
            }));
        } catch (error) {
            console.error('Failed to delete image from server', error);
        }
    };

    const handleListImgInputClick1 = () => {
        document.getElementById('hiddenListImgInput1').click();
    };

    const handleListImgInputClick2 = () => {
        document.getElementById('hiddenListImgInput2').click();
    };

    const handleListImgInputClick3 = () => {
        document.getElementById('hiddenListImgInput3').click();
    };

    const handleListImgInputClick4 = () => {
        document.getElementById('hiddenListImgInput4').click();
    };

    const listImageAdd1 = async (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('image', file);
        const config = { headers: { 'content-type': 'multipart/form-data' } };
        const response = await support.uploadImageToServer(formData, config);
        const data = response.data;
        const imageUrl = `${process.env.REACT_APP_API_SERVER}/img?file=${encodeURIComponent(data)}`;
        setImageListImgPreview1(imageUrl);
        setProductDetailList(currentProductList => ({
            ...currentProductList,
            listImage1: imageUrl
        }));
    };

    const handleListImgDeleteClick1 = async () => {
        try {
            const queryParams = imageListImgPreview1.split('?')[1];
            let filePath = '';
            const params = queryParams.split('&');
            for (let param of params) {
                if (param.startsWith('file=')) {
                    filePath = decodeURIComponent(param.split('=')[1]);
                    break;
                }
            }
            const fileName = filePath.split('/').pop();
            await fetch(`/api/file/delete/${fileName}`, { method: 'DELETE' });
            setImageListImgPreview1('');
            setProductDetailList(currentProductList => ({
                ...currentProductList,
                listImage1: ''
            }));
        } catch (error) {
            console.error('Failed to delete image from server', error);
        }
    };

    const listImageAdd2 = async (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('image', file);
        const config = { headers: { 'content-type': 'multipart/form-data' } };
        const response = await support.uploadImageToServer(formData, config);
        const data = response.data;
        const imageUrl = `${process.env.REACT_APP_API_SERVER}/img?file=${encodeURIComponent(data)}`;
        setImageListImgPreview2(imageUrl);
        setProductDetailList(currentProductList => ({
            ...currentProductList,
            listImage2: imageUrl
        }));
    };

    const handleListImgDeleteClick2 = async () => {
        try {
            const queryParams = imageListImgPreview2.split('?')[1];
            let filePath = '';
            const params = queryParams.split('&');
            for (let param of params) {
                if (param.startsWith('file=')) {
                    filePath = decodeURIComponent(param.split('=')[1]);
                    break;
                }
            }
            const fileName = filePath.split('/').pop();
            await fetch(`/api/file/delete/${fileName}`, { method: 'DELETE' });
            setImageListImgPreview2('');
            setProductDetailList(currentProductList => ({
                ...currentProductList,
                listImage2: ''
            }));
        } catch (error) {
            console.error('Failed to delete image from server', error);
        }
    };

    const listImageAdd3 = async (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('image', file);
        const config = { headers: { 'content-type': 'multipart/form-data' } };
        const response = await support.uploadImageToServer(formData, config);
        const data = response.data;
        const imageUrl = `${process.env.REACT_APP_API_SERVER}/img?file=${encodeURIComponent(data)}`;
        setImageListImgPreview3(imageUrl);
        setProductDetailList(currentProductList => ({
            ...currentProductList,
            listImage3: imageUrl
        }));
    };

    const handleListImgDeleteClick3 = async () => {
        try {
            const queryParams = imageListImgPreview3.split('?')[1];
            let filePath = '';
            const params = queryParams.split('&');
            for (let param of params) {
                if (param.startsWith('file=')) {
                    filePath = decodeURIComponent(param.split('=')[1]);
                    break;
                }
            }
            const fileName = filePath.split('/').pop();
            await fetch(`/api/file/delete/${fileName}`, { method: 'DELETE' });
            setImageListImgPreview3('');
            setProductDetailList(currentProductList => ({
                ...currentProductList,
                listImage3: ''
            }));
        } catch (error) {
            console.error('Failed to delete image from server', error);
        }
    };

    const listImageAdd4 = async (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('image', file);
        const config = { headers: { 'content-type': 'multipart/form-data' } };
        const response = await support.uploadImageToServer(formData, config);
        const data = response.data;
        const imageUrl = `${process.env.REACT_APP_API_SERVER}/img?file=${encodeURIComponent(data)}`;
        setImageListImgPreview4(imageUrl);
        setProductDetailList(currentProductList => ({
            ...currentProductList,
            listImage4: imageUrl
        }));
    };

    const handleListImgDeleteClick4 = async () => {
        try {
            const queryParams = imageListImgPreview4.split('?')[1];
            let filePath = '';
            const params = queryParams.split('&');
            for (let param of params) {
                if (param.startsWith('file=')) {
                    filePath = decodeURIComponent(param.split('=')[1]);
                    break;
                }
            }
            const fileName = filePath.split('/').pop();
            await fetch(`/api/file/delete/${fileName}`, { method: 'DELETE' });
            setImageListImgPreview4('');
            setProductDetailList(currentProductList => ({
                ...currentProductList,
                listImage4: ''
            }));
        } catch (error) {
            console.error('Failed to delete image from server', error);
        }
    };

    const detailClose = () => {
        setShowDetail(false);
    };

    const onProductName = (e) => {
        const newName = e.target.value;
        setProductName(newName);
        setProductDetailList(currentProductList => ({
            ...currentProductList,
            productName: newName
        }));
    };

    
    const onProductCancel = () => {
        MySwal.fire({
            customClass: {
                popup: `${seller.admin_seller_add_popup}`
            },
            html : 
            `
                <div>
                    <div class='${seller.seller_seller_cancel_title} py-2'><span>작성을 취소하시겠습니까?</span></div>
                    <div class='${seller.seller_seller_cancel_sub_title} py-2'><span>작성중인 내용은 저장되지 않습니다.</span></div>
                    <div class='${seller.seller_seller_cancel_btn_box} py-2 d-flex w-100 justify-content-between'>
                        <button class='mx-1' id='seller_update_cancel'>취소</button>
                        <button class='mx-1' id='seller_update_success'>확인</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });
        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);
        
        function sellerAddSuccess(e) {
            if(e.target && e.target.id == 'seller_update_success') {
                setShowDetail(false);
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
                MySwal.close();
            }
        }
        
        function sellerAddCancel(e) {
            if(e.target && e.target.id == 'seller_update_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
            }
        }
    }

    useEffect(() => {
        // console.log('productDetailList : ', productDetailList);
    },[productDetailList]);

    const onProductInfo = (e) => {
        const newInfo = e.target.value;
        
        // Check if the new input exceeds the limit
        if (newInfo.length > charLimit) {
            alert('입력 가능한 문자수가 초과하였습니다.'); // Alert when limit is exceeded
            return; // Prevent further action
        }

        setProductInfo(newInfo);
        setCharCount(newInfo.length); // Update character count

        // Update product list or other state as needed
        setProductDetailList((currentProductList) => ({
            ...currentProductList,
            productInfo: newInfo,
        }));
    };

    return (
        <>
            <div className={`${seller.seller_product_detail_container}`}>
                <div>
                    <div className={`${seller.seller_product_detail_header} px-3 py-3`}>
                        <div><span>상품 수정</span></div>
                        <div><button onClick={() => detailClose()}><span className="material-symbols-outlined">close</span></button></div>
                    </div>
                    <div className={`${seller.seller_product_detail_content}`}>
                        {/* 메인 이미지 */}
                        <div className='px-5 py-3'>
                            <div className={`pt-5 pb-1 ${seller.seller_border_bottom_color} d-flex justify-content-between`}>
                                <div><span>메인 이미지 등록</span></div>
                                <div><span>펼치기</span><span><span className="material-symbols-outlined">expand_more</span></span></div>
                            </div>
                            <div className='d-flex w-100 border-bottom align-items-center py-3'>
                                <div className={`${seller.seller_settlement_left}`}><span>썸네일 이미지 등록</span></div>
                                <div className={`${seller.seller_settlement_middle} px-3`}>
                                    <div className={`${seller.seller_settlement_store_profile_img} position-relative`}>
                                        {imageRepresetativePreview === '' ? 
                                            <div className={`position-absolute ${seller.seller_settlement_default_img}`}>
                                                <img src="../img/seller/icon/default2.png" alt="" />
                                            </div>
                                            :
                                            <img src={imageRepresetativePreview === '' ? '' : imageRepresetativePreview} alt="상점 프로필" /> 
                                        }
                                    </div>
                                    <div className='pt-2'>
                                        <input
                                            id="hiddenRepresentativeInput"
                                            type="file"
                                            onChange={(event) => representativeImageAdd(event)}
                                            style={{display: 'none'}}
                                        />
                                        <button className='bg-body py-1 px-3 me-1 rounded border border-1' onClick={() => handleRepresentativeInputClick()} >등록</button>
                                        <button className='bg-body py-1 px-3 ms-1 rounded border border-1' onClick={() => handleRepresentativeDeleteClick()} >삭제</button>
                                    </div>
                                </div>
                                <div className={`${seller.seller_settlement_right}`}>
                                    <div><span>*업로드 가능한 파일 확장자: jpg, png, heic</span></div>
                                    <div><span>*이미지 용량: 1MB 이하</span></div>
                                </div>
                            </div>
                        </div>
                        <div className='px-5 py-3'>
                            <div className={`pt-5 pb-1 ${seller.seller_border_bottom_color} d-flex justify-content-between`}>
                                <div><span>리스트 이미지 등록</span></div>
                                <div><span>펼치기</span><span><span className="material-symbols-outlined">expand_more</span></span></div>
                            </div>
                            <div className={`${seller.seller_representative_list_img_box}`}>
                                <div className={`${seller.seller_edit_left}`}><span>리스트 이미지</span></div>
                                <div className={`${seller.seller_edit_right} row`}>
                                    <div className='col px-3 py-3'>
                                        <div className={`${seller.seller_edit_img_box}`}>
                                            {imageListImgPreview1 === '' ?
                                                <img src="../img/seller/icon/default2.png" alt="" />
                                                :
                                                <img className={`${seller.representativeImg}`} src={imageListImgPreview1 === '' ? '' : imageListImgPreview1} alt="" />
                                            }
                                        </div>
                                        <div className='d-flex align-items-center'>
                                            <div className='pt-2'>
                                                <button className={`${seller.seller_edit_listimg_add}`} onClick={() => handleListImgInputClick1()} >등록</button>
                                                <input
                                                    id="hiddenListImgInput1"
                                                    type="file"
                                                    onChange={(event) => listImageAdd1(event)}
                                                    style={{display: 'none'}}
                                                />
                                            </div>
                                            <div className='pt-2 ps-2'><button className={`${seller.seller_edit_listimg_cancel}`} onClick={() => handleListImgDeleteClick1()} >삭제</button></div>
                                        </div>
                                    </div>
                                    <div className='col px-3 py-3'>
                                        <div className={`${seller.seller_edit_img_box}`}>
                                            {imageListImgPreview2 === '' ?
                                                <img src="../img/seller/icon/default2.png" alt="" />
                                                :
                                                <img className={`${seller.representativeImg}`} src={imageListImgPreview2 === '' ? '' : imageListImgPreview2} alt="" />
                                            }
                                        </div>
                                        <div className='d-flex w-100 align-items-center'>
                                            <div className='pt-2'>
                                                <button className={`${seller.seller_edit_listimg_add}`} onClick={() => handleListImgInputClick2()} >등록</button>
                                                <input
                                                    id="hiddenListImgInput2"
                                                    type="file"
                                                    onChange={(event) => listImageAdd2(event)}
                                                    style={{display: 'none'}}
                                                />
                                            </div>
                                            <div className='pt-2 ps-2'><button className={`${seller.seller_edit_listimg_cancel}`} onClick={() => handleListImgDeleteClick2()} >삭제</button></div>
                                        </div>
                                    </div>
                                    <div className='col px-3 py-3'>
                                        <div className={`${seller.seller_edit_img_box}`}>
                                            {imageListImgPreview3 === '' ?
                                                <img src="../img/seller/icon/default2.png" alt="" />
                                                :
                                                <img className={`${seller.representativeImg}`} src={imageListImgPreview3 === '' ? '' : imageListImgPreview3} alt="" />
                                            }
                                        </div>
                                        <div className='d-flex align-items-center'>
                                            <div className='pt-2'>
                                                <button className={`${seller.seller_edit_listimg_add}`} onClick={() => handleListImgInputClick3()} >등록</button>
                                                <input
                                                    id="hiddenListImgInput3"
                                                    type="file"
                                                    onChange={(event) => listImageAdd3(event)}
                                                    style={{display: 'none'}}
                                                />
                                            </div>
                                            <div className='pt-2 ps-2'><button className={`${seller.seller_edit_listimg_cancel}`} onClick={() => handleListImgDeleteClick3()} >삭제</button></div>
                                        </div>
                                    </div>
                                    <div className='col px-3 py-3'>
                                        <div className={`${seller.seller_edit_img_box}`}>
                                            {imageListImgPreview4 === '' ?
                                                <img src="../img/seller/icon/default2.png" alt="" />
                                                :
                                                <img className={`${seller.representativeImg}`} src={imageListImgPreview4 === '' ? '' : imageListImgPreview4} alt="" />
                                            }
                                        </div>
                                        <div className='d-flex align-items-center'>
                                            <div className='pt-2'>
                                                <button className={`${seller.seller_edit_listimg_add}`} onClick={() => handleListImgInputClick4()} >등록</button>
                                                <input
                                                    id="hiddenListImgInput4"
                                                    type="file"
                                                    onChange={(event) => listImageAdd4(event)}
                                                    style={{display: 'none'}}
                                                />
                                            </div>
                                            <div className='pt-2 ps-2'><button className={`${seller.seller_edit_listimg_cancel}`} onClick={() => handleListImgDeleteClick4()} >삭제</button></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* 상품명 등록 */}
                        <div className='px-5 py-3'>
                            <div className='border-bottom'>
                                <div className={`pt-5 pb-1 ${seller.seller_border_bottom_color} d-flex justify-content-between`}>
                                    <div><span>상품명 등록</span></div>
                                    <div><span>펼치기</span><span><span className="material-symbols-outlined">expand_more</span></span></div>
                                </div>
                                <div className={`${seller.seller_edit_product_name_box} py-3`}>
                                    <div className={`${seller.seller_edit_left}`}>상품명</div>
                                    <div className={`${seller.seller_edit_right}`}>
                                        <input className={`${seller.seller_edit_input_w100}`} type="text" placeholder={productName ? productName : '상품명을 입력해주세요.'} onChange={(e) => onProductName(e)} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* 상품명 등록 */}
                        <div className='px-5 py-3'>
                            <div className='border-bottom'>
                                <div className={`pt-5 pb-1 ${seller.seller_border_bottom_color} d-flex justify-content-between`}>
                                    <div><span>상품소개 등록</span></div>
                                    <div>
                                        <span>펼치기</span>
                                        <span><span className="material-symbols-outlined">expand_more</span></span>
                                    </div>
                                </div>
                                <div className={`${seller.seller_edit_product_name_box} py-3`}>
                                    <div className={`${seller.seller_edit_left}`}>상품소개</div>
                                    <div className={`${seller.seller_edit_right} ${seller.seller_edit_product_info}`}>
                                        <textarea
                                            className={`${seller.seller_edit_textarea_w100}`}
                                            placeholder={`${productInfo ? productInfo : '상품소개를 입력해주세요(ex. 무드가 있는 데일리덕에서 최애를 빛내보세요!)' }`}
                                            onChange={onProductInfo}
                                            value={productInfo ? productInfo : ''} // Controlled input with state value
                                        ></textarea>
                                        <div className={`${seller.seller_edit_product_info_txt_cnt}`}><span>{charCount}/{charLimit}</span></div> {/* Character count display */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* 카테고리 */}
                        <div className='px-5 py-3'>
                            <div className={`pt-5 pb-1 ${seller.seller_border_bottom_color} d-flex justify-content-between`}>
                                <div><span>상품 분류</span></div>
                                <div><span>펼치기</span><span><span className="material-symbols-outlined">expand_more</span></span></div>
                            </div>
                            <div className='d-flex w-100 align-items-center py-3 border-bottom'>
                                <div className={`${seller.seller_edit_three_left}`}>대분류</div>
                                <div className={`${seller.seller_edit_three_middle} pe-3`}>
                                    <Select className={`${seller.seller_edit_select}`} 
                                        placeholder="카테고리를 선택해주세요." 
                                        onChange={(e) => onMainCategory(e)}
                                        value={'support'}
                                    >
                                        <Option value="support">서포트</Option>
                                    </Select>
                                </div>
                                <div className={`${seller.seller_edit_three_right}`}><span></span></div>
                            </div>
                            <div className='d-flex w-100 align-items-center py-3 border-bottom'>
                                <div className={`${seller.seller_edit_three_left}`}>중분류</div>
                                <div className={`${seller.seller_edit_three_middle} pe-3`}>
                                    <Select className={`${seller.seller_edit_select}`} 
                                        placeholder="카테고리를 선택해주세요." 
                                        onChange={(e) => onMiddleCategory(e)}
                                        value={middleCategorySelect || ''}
                                    >
                                        {middleCategory.map((item, index) => (
                                            <Option key={index} value={item.dailyduckSupportcategoryEngName}>{item.dailyduckSupportcategoryName}</Option>
                                        ))}
                                    </Select>
                                </div>
                                <div className={`${seller.seller_edit_three_right}`}><span></span></div>
                            </div>
                            <div className='d-flex w-100 align-items-center py-3 border-bottom'>
                                <div className={`${seller.seller_edit_three_left}`}>소분류</div>
                                <div className={`${seller.seller_edit_three_middle} pe-3`}>
                                    <Select 
                                        className={`${seller.seller_edit_select}`}
                                        placeholder="카테고리를 선택해주세요." 
                                        onChange={onSubCategory} 
                                        value={subCategorySelect || undefined}
                                        disabled={selectSubCategory.length === 0} // 소분류가 있을 때만 활성화
                                    >
                                        {selectSubCategory.map((item, index) => (
                                            <Option key={index} value={item.supportSubCategoryEngName}>
                                                {item.supportSubCategoryKorName}
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                                <div className={`${seller.seller_edit_three_right}`}><span></span></div>
                            </div>
                        </div>

                        {/* 상품 정보 */}
                        <div className='px-5 py-3'>
                            <div className={`pt-4 pb-1 ${seller.seller_border_bottom_color}`}><span>상품 정보</span></div>
                            <div className='py-3'>
                                {products.map((product, productIndex) => (
                                    <div key={productIndex}>
                                        <div className={`${seller.seller_edit_product_info_box}`}>
                                            <button className={`${seller.seller_edit_product_info_delete}`} onClick={() => handleRemoveProduct(productIndex)}>
                                                <span className="material-symbols-outlined">cancel</span>
                                            </button>
                                            <div>
                                                <div className={`${seller.seller_edit_product_info_img_box}`}>
                                                    {product.imageUrl ?
                                                        <img src={product.imageUrl} alt="" />
                                                        :
                                                        <button onClick={() => handleImageAdd(productIndex)}>+</button>
                                                    }
                                                    <input
                                                        id={`hiddenImageInput_${productIndex}`}
                                                        type="file"
                                                        onChange={(event) => imageAdd(event, productIndex)}
                                                        style={{ display: 'none' }}
                                                    />
                                                </div>
                                            </div>
                                            <div className={`${seller.seller_edit_product_info_content_box}`}>
                                                <div className={`${seller.seller_edit_product_info_option_name}`}>
                                                    <div><span>상품 이름</span></div>
                                                    <div>
                                                        <div>
                                                            <input 
                                                                type="text" 
                                                                value={product.mainOptionName || ''} 
                                                                onChange={(e) => {
                                                                    const newProducts = [...products];
                                                                    newProducts[productIndex].mainOptionName = e.target.value;
                                                                    updateProductList(newProducts);
                                                                }} 
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={`${seller.seller_edit_product_info_price_box} py-2`}>
                                                    <div><span>판매가</span></div>
                                                    <div>
                                                        <div>
                                                            <input 
                                                                type="number" 
                                                                value={product.mainItemPrice || 0}
                                                                // placeholder='0' 
                                                                onChange={(e) => {
                                                                    const newProducts = [...products];
                                                                    newProducts[productIndex].mainItemPrice = e.target.value;
                                                                    updateProductList(newProducts);
                                                                }} 
                                                            />
                                                        </div>
                                                        <div><span>&#8361;</span></div>
                                                    </div>
                                                </div>
                                                <div className={`${seller.seller_edit_product_info_option_box} py-2`}>
                                                    <div>옵션 사용여부</div>
                                                    <div>
                                                        <div>
                                                            <input
                                                                type="radio"
                                                                name={`option_select_${productIndex}`}
                                                                id={`option_true_${productIndex}`}
                                                                checked={product.useOptions}
                                                                onChange={() => handleUseOptionsChange(productIndex, true)}
                                                            />
                                                            <label className='ps-1' htmlFor={`option_true_${productIndex}`}>사용함</label>
                                                        </div>
                                                        <div className='ps-5'>
                                                            <input
                                                                type="radio"
                                                                name={`option_select_${productIndex}`}
                                                                id={`option_false_${productIndex}`}
                                                                checked={!product.useOptions}
                                                                onChange={() => handleUseOptionsChange(productIndex, false)}
                                                            />
                                                            <label className='ps-1' htmlFor={`option_false_${productIndex}`}>사용안함</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {product.useOptions && (
                                            <div className={`${seller.seller_edit_product_info_option_detail_box}`}>
                                                {product.options.map((option, optionIndex) => (
                                                    <div key={optionIndex} className={`${seller.seller_edit_option_select}`}>
                                                        <div className={`${seller.seller_edit_option_select_close}`}>
                                                            <button onClick={() => handleRemoveOption(productIndex, optionIndex)}><span className="material-symbols-outlined">cancel</span></button>
                                                        </div>
                                                        <div className={`${seller.seller_edit_option_select_add}`}>
                                                            <div><span>옵션 추가</span></div>
                                                            <div className='me-3'>
                                                                <Select
                                                                    className={`${seller.seller_edit_option_select_type}`}
                                                                    onChange={(value) => handleTypeChange(value, productIndex, optionIndex)}
                                                                    options={[
                                                                        { value: 'shortAnswer', label: '단답형' },
                                                                        { value: 'singleSelection', label: '단일선택' },
                                                                        { value: 'multipleSelection', label: '중복선택' },
                                                                        { value: 'dateSelection', label: '날짜선택' },
                                                                        { value: 'fileUpload', label: '파일 업로드' }
                                                                    ]}
                                                                    value={option.type ? { value: option.type, label: getOptionLabel(option.type) } : null}
                                                                />
                                                            </div>
                                                            <div>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={option.required || false}
                                                                    onChange={(e) => {
                                                                        const newProducts = [...products];
                                                                        newProducts[productIndex].options[optionIndex].required = e.target.checked;
                                                                        updateProductList(newProducts);
                                                                    }}
                                                                />
                                                                <label className='ps-1'>필수</label>
                                                            </div>
                                                            {/* 단일선택 또는 중복선택일 경우에만 수량사용 체크박스 표시 */}
                                                            {(option.type === 'singleSelection' || option.type === 'multipleSelection') && (
                                                                <div className='ps-3'>
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={option.useQuantity || false}
                                                                        onChange={(e) => {
                                                                            const newProducts = [...products];
                                                                            newProducts[productIndex].options[optionIndex].useQuantity = e.target.checked;
                                                                            updateProductList(newProducts);
                                                                        }}
                                                                    />
                                                                    <label className='ps-1'>수량사용</label>
                                                                </div>
                                                            )}
                                                        </div>
                                                        {renderOptionContent(option, productIndex, optionIndex)}
                                                    </div>
                                                ))}
                                                <div className={`${seller.seller_edit_option_select_add_btn}`}>
                                                    <button onClick={() => handleAddOption(productIndex)}><span class="material-symbols-outlined">add</span></button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ))}
                                <div className={`${seller.seller_edit_product_info_add_btn}`}>
                                    <button onClick={handleAddProduct}>상품추가</button>
                                </div>
                            </div>
                        </div>
                        <div className='px-5 py-3'>
                            <div className={`pt-4 pb-1 ${seller.seller_border_bottom_color}`}><span>제품 상세</span></div>
                            <div className='py-3'>
                                <div><span>상세 내용</span></div>
                                <div className='position-relative'>
                                    <TextEditor setData={setData} seller={seller} fileName={fileName} setFileName={setFileName} onDetailContent={onDetailContent} data={data} />
                                </div>
                            </div>
                        </div>
                        <div className='px-5 py-3'>
                            <div className={`pt-4 pb-1 ${seller.seller_border_bottom_color}`}><span>FAQ (선택)</span></div>
                            <div className='d-flex w-100 align-items-center py-3'>
                                <div className={`${seller.seller_edit_left}`}><span>자주쓰는 FAQ</span></div>
                                <div className={`${seller.seller_edit_right}`}>
                                    <div><button className={`${seller.seller_edit_faq_select_btn}`} onClick={() => setShowFaqModal(true)}>FAQ 선택</button></div>
                                    <div className='py-3'><span className='dd-fs-7 ps-2'>*선택 시 상품의 FAQ 페이지에 노출됩니다.</span></div>
                                </div>
                            </div>
                            <div className='d-flex w-100 align-items-center py-3'>
                                <div className={`${seller.seller_edit_left}`}><span>FAQ 신규 등록</span></div>
                                <div className={`${seller.seller_edit_right}`}>
                                    <div className='py-1'>
                                        <button className={`${seller.seller_edit_faq_add_btn}`} onClick={() => sellerFaqAdd()}>질문 추가</button>
                                    </div>
                                </div>
                            </div>
                            {
                                selectedFaqs && selectedFaqs.length > 0 &&
                                <div className={`${seller.seller_edit_faq_select_item_box}`}>
                                    <div className={`${seller.seller_edit_faq_select_item_title}`}><span>선택된 FAQ</span></div>
                                    <div className={`${seller.seller_edit_faq_select_item_content}`}>
                                        {
                                            selectedFaqs.map((item, index) => {
                                                return (
                                                    <div className={`${seller.seller_edit_faq_select_item_content_item}`}>
                                                        <div className={`${seller.seller_edit_faq_select_item_content_item_question}`}>
                                                            <div><span>질문</span></div>
                                                            <div><span>{item.question}</span></div>
                                                        </div>
                                                        <div className={`${seller.seller_edit_faq_select_item_content_item_answer}`}>
                                                            <div><span>답변</span></div>
                                                            <div><span>{item.answer}</span></div>
                                                        </div>
                                                        <div className={`${seller.seller_edit_faq_select_item_content_item_delete}`}><button onClick={() => handleRemoveFaq(item)}><span className="material-symbols-outlined">remove</span></button></div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                        <div className='px-5 py-3'>
                            <div className={`pt-4 pb-1 ${seller.seller_border_bottom_color}`}><span>교환/반품 규정</span></div>
                            <div className='py-3'>
                                <TextEditor2 setData2={setData2} onExchangeReturn={onExchangeReturn} data2={data2}/>
                            </div>
                        </div>
                    </div>
                    <div className={`${seller.seller_product_detail_footer}`}>
                        <button
                        className={`${seller.seller_edit_produt_cancel_btn}`}
                        onClick={() => onProductCancel()}
                        >
                            취소
                        </button>
                        <button 
                        className={`${seller.seller_edit_produt_update_btn}`} 
                        onClick={() => onProductUpdate()}
                        >
                            상품수정
                        </button>
                    </div>
                </div>
            </div>
            {
                showFaqAdd && 
                <div className={`${seller.seller_edit_produt_faq_add_modal_container}`}>
                    <div className={`${seller.seller_edit_produt_faq_add_modal_box}`}>
                        <div className={`${seller.seller_edit_produt_faq_add_modal_header}`}>
                            <div><button onClick={() => setShowFaqAdd(false)}><span className="material-symbols-outlined">close</span></button></div>
                        </div>
                        <div className={`${seller.seller_edit_produt_faq_add_modal_content}`}>
                            <SellerSalesMain />
                        </div>
                    </div>
                </div>
            }
            {
                showFaqModal &&
                <div className={`${seller.seller_edit_produt_faq_select_modal_container}`}>
                    <div className={`${seller.seller_edit_produt_faq_select_modal_box}`}>
                        <div className={`${seller.seller_edit_produt_faq_select_modal_header}`}>
                            <div><span>FAQ 선택</span></div>
                            <div><button onClick={() => {setShowFaqModal(false); setSelectedFaqs([]);}}><span className="material-symbols-outlined">close</span></button></div>
                        </div>
                        <div className={`${seller.seller_edit_produt_faq_select_modal_content}`}>
                            {
                                Array.isArray(faqList) && faqList.map((item, index) => {
                                    // selectedFaqs가 undefined일 경우 빈 배열로 초기화
                                    const selectedFaqNos = Array.isArray(selectedFaqs) ? selectedFaqs.map(faq => faq.sellerFaqNo) : [];
                                    return (
                                        <div key={index} className={`${seller.seller_edit_produt_faq_select_modal_content_item}`}>
                                            <div>
                                                <input
                                                    type="checkbox"
                                                    checked={selectedFaqNos.includes(item.sellerFaqNo)}  // selectedFaqNos에 sellerFaqNo가 있는지 확인
                                                    onChange={() => handleDetaoleCheckboxChange(item)}
                                                />
                                            </div>
                                            <div>
                                                <div className={`${seller.seller_edit_produt_faq_select_modal_content_question}`}>
                                                    <div><span>질문</span></div>
                                                    <div><span>{item.question}</span></div>
                                                </div>
                                                <div className={`${seller.seller_edit_produt_faq_select_modal_content_answer}`}>
                                                    <div><span>답변</span></div>
                                                    <div><span>{item.answer}</span></div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className={`${seller.seller_edit_produt_faq_select_modal_footer}`}>
                            <div><button onClick={() => {setShowFaqModal(false); setSelectedFaqs([]);}}>취소</button></div>
                            <div><button onClick={handleFaqSelect}>선택</button></div>
                        </div>
                    </div>
                </div>
            }

        </>
    );
};

export default SellerProductDetail;
