import React, { useContext, useEffect, useState } from 'react';
import moment from "moment"
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import DateCalendar from './dateCalendar';
import * as sellerapi from '../../../../../apis/seller';
import seller from '../../../../seller.module.css';
import { LoginContext } from '../../../../../contexts/LoginContextProvider';


const SellerStoreDateSetting = () => {
    const { isLogin, sellerlogout, userInfo, loginCheck, roles } = useContext(LoginContext);
    const MySwal = withReactContent(Swal);

    const [ activeButton, setActiveButton ] = useState('productdate');
    const [ showItemList, setShowItemList ] = useState(false);
    const [ showRegularUpdate, setShowRegularUpdate ] = useState(false);
    const [ selectDateOne, setSelectDateOne] = useState('');

    const [regularHolidays, setRegularHolidays] = useState([]);
    const [selectedWeekOfMonth, setSelectedWeekOfMonth] = useState(""); // 선택한 주차
    const [selectedDayOfWeek, setSelectedDayOfWeek] = useState(""); // 선택한 요일

    const [holidays, setHolidays] = useState([]); // 임시 공휴일을 저장할 상태
    const [currentRegularHolidays, setCurrentRegularHolidays] = useState([]); // 정기 휴무일을 저장할 상태

    useEffect(() => {
        if(userInfo) {
            ondataLoading();
        }
        // console.log('holidays : ', holidays);
    }, [userInfo]);

    // useEffect(() => {
    //     // console.log(selectedWeekOfMonth)
    // }, [selectedWeekOfMonth])

    const ondataLoading = async () => {
        const response = await sellerapi.dateSettingLoading(userInfo && userInfo?.userId);
        const response2 = await sellerapi.dateTemporaryLoading(userInfo && userInfo?.userId);
        const data = response.data;
        const data2 = response2.data;
    
        data.sort((a, b) => new Date(a.holidayDate) - new Date(b.holidayDate));
        // console.log(data);
        // console.log('data2 : ', data2);
        // 데이터에서 holidayDate 값만 추출하여 새로운 배열을 생성
        const holidayDates = data.map(holiday => holiday.holidayDate);
        // const holidayDates2 = data2.map(temporary => temporary.temporaryDate);
        
        // 날짜 배열을 주차와 요일 정보가 담긴 배열로 변환
        const updatedSelectedWeeksAndDays = data.map(dateString => {
            const data = dateString.holidayDate;
            const {weekOfMonth, dayOfWeek } = convertDateToWeekAndDay(data);
            return { weekOfMonth, dayOfWeek };
        });

        // // console.log(updatedSelectedWeeksAndDays);

        // 객체 배열에서 중복 제거
        const uniqueData = Array.from(new Set(updatedSelectedWeeksAndDays.map(item => JSON.stringify(item))))
        .map(item => JSON.parse(item));

    
        setRegularHolidays(holidayDates);
        setSelectedWeeksAndDays(uniqueData);
        setHolidays(data2);
    
    }

    const onItemList = () => {
        setShowItemList(!showItemList);
    }
    
    const handleRegularUpdate = () => {
        setShowRegularUpdate(true);
    }

    const handleRegularUpdateCancel = () => {
        setShowRegularUpdate(false);
    }

    // 정기 휴무일을 설정하는 함수
    const handleRegularHolidays = async () => {
        const promises = selectedWeeksAndDays.map(async ({ weekOfMonth, dayOfWeek }) => {
            if (weekOfMonth !== "" && dayOfWeek !== "") {
                return await generateRegularHolidays(dayOfWeek, weekOfMonth);
            } else {
                return [];
            }
        });
    
        const holidaysArrays = await Promise.all(promises);
        const holidays = holidaysArrays.flat();
    
        setRegularHolidays(holidays);
    };

    const generateRegularHolidays = (dayOfWeek, weekOfMonth) => {
        const currentYear = moment().year(); // 현재 연도
        const holidays = [];
    
        for (let year = currentYear; year <= currentYear + 2; year++) { // 현재 연도부터 내후년까지
            for (let month = 0; month < 12; month++) { // 각 연도의 모든 월 순회
                let monthStart = moment({year, month}).startOf('month'); // 해당 연도, 월의 시작일
                
                let firstDayOfWeek = monthStart.clone();
                let dayOffset = (7 + dayOfWeek - monthStart.day()) % 7; // 첫 요일과 목표 요일 사이의 차이 계산
                firstDayOfWeek.add(dayOffset, 'days'); // 첫 주의 목표 요일로 이동
    
                // 첫 주가 아닌 경우, 추가 주차만큼 날짜를 추가
                if (weekOfMonth > 1) {
                    let targetDay = firstDayOfWeek.add(weekOfMonth - 1, 'weeks');
                    // 해당 날짜가 다음 달로 넘어가지 않았는지 확인하고 배열에 추가
                    if (targetDay.year() === year && targetDay.month() === month) {
                        holidays.push(targetDay.format('YYYY-MM-DD'));
                    }
                } else {
                    // 첫 주인 경우 바로 추가
                    holidays.push(firstDayOfWeek.format('YYYY-MM-DD'));
                }
            }
        }
        // // console.log("holidays : " + holidays);
    
        return holidays;
    };
    
    
    
    
    
    const handleSave = () => {
        MySwal.fire({
            customClass: {
                popup: `${seller.seller_date_save_popup}`
            },
            html : 
            `
                <div>
                    <div class='${seller.seller_date_save_title}'><span>영업·휴무일 관리등록</span></div>
                    <div class='${seller.seller_date_save_content}'><span>정기 휴무일 정보 수정이 완료되어 저장되었습니다.</span></div>
                    <div class='${seller.seller_date_save_btn} py-2'><button id='seller_date_save'>확인</button></div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });
    
        const handleClick = async (e) => {
            if(e.target && e.target.id == 'seller_date_save') {
                // 정기 휴무일을 설정하는 함수 호출
                handleRegularHolidays();
    
                try {
                    const response = await sellerapi.regularHoliday(regularHolidays, userInfo && userInfo?.userId);
                    const data = response.data;
                    // 데이터 저장 후 UI 로직 실행
                    setShowRegularUpdate(false);
                    MySwal.close();
                } catch (error) {
                    console.error("Error saving data:", error);
                }
            }
        };
    
        // 기존의 이벤트 리스너를 제거하고 새 리스너를 등록
        document.removeEventListener('click', handleClick);
        document.addEventListener('click', handleClick);
    };


    // 주차를 선택했을 때 호출되는 함수
    const handleWeekOfMonthChange = (event, index) => {
        const newSelectedWeeksAndDays = [...selectedWeeksAndDays];
        newSelectedWeeksAndDays[index].weekOfMonth = event.target.value;
        setSelectedWeeksAndDays(newSelectedWeeksAndDays);
    };

    // 요일을 선택했을 때 호출되는 함수
    const handleDayOfWeekChange = (event, index) => {
        const newSelectedWeeksAndDays = [...selectedWeeksAndDays];
        newSelectedWeeksAndDays[index].dayOfWeek = event.target.value;
        setSelectedWeeksAndDays(newSelectedWeeksAndDays);
    };

    const convertDateToWeekAndDay = (date) => {
        const momentDate = moment(date);
        // 월의 첫째 날과 해당 날짜의 차이(일)를 계산
        const startOfMonth = moment(date).startOf('month');
        const diffDays = momentDate.diff(startOfMonth, 'days');
        // 주차 계산: 차이(일)를 7로 나누고 1을 더함
        const weekOfMonth = Math.floor(diffDays / 7) + 1;
        // 요일 계산 (0: 일요일, 6: 토요일)
        const dayOfWeek = momentDate.day();
    
        return { weekOfMonth, dayOfWeek };
    };
    
    

    // 주차와 요일을 문자열로 변환하는 함수
    const convertWeekAndDayToString = (weekOfMonth, dayOfWeek) => {
        const weeks = ["매월 첫번째", "매월 두번째", "매월 세번째", "매월 네번째", "매월 다섯번째"];
        const days = ["일요일", "월요일", "화요일", "수요일", "목요일", "금요일", "토요일"];

        return `${weeks[weekOfMonth - 1]} ${days[dayOfWeek]}`;
    };

    // 선택한 주차와 요일을 저장하는 상태를 배열로 변경
    const [selectedWeeksAndDays, setSelectedWeeksAndDays] = useState([]);

    // 새로운 셀렉트 박스를 추가하는 함수
    const addSelectBoxes = () => {
        setSelectedWeeksAndDays([...selectedWeeksAndDays, { weekOfMonth: "", dayOfWeek: "" }]);
    };

    // 셀렉트 박스를 삭제하는 함수
    const removeSelectBoxes = async (index) => {
        // 삭제될 주차와 요일 정보 가져오기
        const removedWeekOfMonth = selectedWeeksAndDays[index].weekOfMonth;
        const removedDayOfWeek = selectedWeeksAndDays[index].dayOfWeek;
        
        // 삭제될 정기 휴무일 확인
        const removedHolidays = regularHolidays.filter(holiday => {
            const { weekOfMonth, dayOfWeek } = convertDateToWeekAndDay(holiday);
            return weekOfMonth === parseInt(removedWeekOfMonth) && dayOfWeek === parseInt(removedDayOfWeek);
        });


        const response = await sellerapi.removeHoliday(removedHolidays, userInfo && userInfo?.userId);
        const data = response.data;

        // 정기 휴무일에서 해당하는 주차와 요일에 해당하는 항목을 필터링하여 새로운 배열 생성
        const updatedRegularHolidays = regularHolidays.filter(holiday => {
            const { weekOfMonth, dayOfWeek } = convertDateToWeekAndDay(holiday);
            // 주차와 요일이 일치하는 항목은 제외하고 나머지만 남깁니다.
            return !(weekOfMonth === removedWeekOfMonth && dayOfWeek === removedDayOfWeek);
        });
    
        // 업데이트된 정기 휴무일 배열로 상태를 업데이트합니다.
        setRegularHolidays(updatedRegularHolidays);
    
        // 선택한 셀렉트 박스를 제거합니다.
        setSelectedWeeksAndDays(selectedWeeksAndDays.filter((_, i) => i !== index));

    };

    // useEffect(() => {
    //     // console.log("업데이트된 regularHolidays:", regularHolidays);
    //   }, [regularHolidays]);
      


    // 셀렉트 박스를 렌더링하는 함수
    const renderSelectBoxes = () => {
        return selectedWeeksAndDays.map((selected, index) => (
            <div key={index} className='py-2'>
                {/* 첫 번째 셀렉트 박스: 주차 선택 */}
                <select className={`${seller.seller_regular_select_btn} me-1`} value={selected.weekOfMonth} onChange={(event) => handleWeekOfMonthChange(event, index)}>
                    <option value="">매월 선택</option>
                    <option value="1">매월 첫번째</option>
                    <option value="2">매월 두번째</option>
                    <option value="3">매월 세번째</option>
                    <option value="4">매월 네번째</option>
                    <option value="5">매월 다섯번째</option>
                </select>

                {/* 두 번째 셀렉트 박스: 요일 선택 */}
                <select className={`${seller.seller_regular_select_btn} ms-1`} value={selected.dayOfWeek} onChange={(event) => handleDayOfWeekChange(event, index)}>
                    <option value="">요일 선택</option>
                    <option value="1">월요일</option>
                    <option value="2">화요일</option>
                    <option value="3">수요일</option>
                    <option value="4">목요일</option>
                    <option value="5">금요일</option>
                    <option value="6">토요일</option>
                    <option value="7">일요일</option>
                </select>

                {/* 삭제 버튼 */}
                <button type='button' onClick={() => removeSelectBoxes(index)} className={`${seller.seller_regular_select_remove} px-2`}>
                    <span className="material-symbols-outlined">cancel</span>
                </button>
            </div>
        ));
    };

    return (
        <div className={`${seller.seller_dashboard_container} py-3 px-3`}>
            <div>
                <div className='pb-3 w-100 border-bottom fs-5'><span>일정 관리</span></div>
            </div>
            <div>
                <div className={`${seller.seller_dashboard_tab_btn} pt-4 pb-3`}>
                    <div className={`w-100 ${activeButton === 'productdate' ? seller.seller_dashboard_tab_active : ''}`}><button onClick={() => setActiveButton('productdate')}>상품별 일정 관리</button></div>
                    <div className={`w-100 ${activeButton === 'regularholiday' ? seller.seller_dashboard_tab_active : ''}`}><button onClick={() => setActiveButton('regularholiday')}>정기 휴무일 등록</button></div>
                </div>
            </div>
            <div>
                <div className='dd-fs-7 d-flex justify-content-between align-items-center'>
                    {activeButton === 'productdate' &&
                        <div>
                            <div><span>*달력 시작일과 종료일 클릭 시 이벤트 등록이 가능합니다.</span></div>
                            <div><span>*휴무일이 설정되어있지 않은 일자는 정상 영업일로 등록됩니다.</span></div>
                        </div>
                    }
                    
                    {activeButton === 'regularholiday' &&
                        <div>
                            <div><span>*정기 휴무일이 있을 경우 정기 휴무일 주기와 요일을 설정해주세요.</span></div>
                        </div>
                    }

                    <div className='position-relative'>
                        <button className={`d-flex justify-content-between align-items-center ${seller.seller_store_date_item_select}`} onClick={() => onItemList()}>
                            <div><span>아이템이름</span></div>
                            <div><span className="material-symbols-outlined">{showItemList ? 'arrow_drop_up' : 'arrow_drop_down'}</span></div>
                        </button>
                        {showItemList &&
                            <div className={`${seller.seller_store_date_item_box} py-3 px-3`}>
                                <div className='text-center w-100 py-3 fs-5'><span>상품 선택</span></div>
                                <div>
                                    <div className={`${seller.seller_store_date_itemlist_box} border-bottom py-3`}>
                                        <div className='me-2'><input type="radio" id='가나다' name='productItem' /></div>
                                        <div><img src="../../img/support/venue_1.png" alt="상품이미지" /></div>
                                        <div className='ps-2'><label htmlFor='가나다'>Leon Bridges - Coming Home (Deluxe Edition) - 1 LP vinyl</label></div>
                                    </div>
                                    <div className={`${seller.seller_store_date_itemlist_box} border-bottom py-3`}>
                                        <div className='me-2'><input type="radio" id='라마바' name='productItem' /></div>
                                        <div><img src="../../img/support/venue_1.png" alt="상품이미지" /></div>
                                        <div className='ps-2'><label htmlFor='라마바'>Leon Bridges - Coming Home (Deluxe Edition) - 1 LP vinyl</label></div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                {activeButton === 'productdate' &&
                    <div>
                        <div className={`${seller.seller_calander} pt-3`}>
                            <DateCalendar seller={seller} regularHolidays={regularHolidays} setRegularHolidays={setRegularHolidays} selectedDayOfWeek={selectedDayOfWeek} 
                            selectedWeekOfMonth={selectedWeekOfMonth} setSelectedDayOfWeek={setSelectedDayOfWeek} setSelectedWeekOfMonth={setSelectedWeekOfMonth}
                            holidays={holidays} setHolidays={setHolidays} currentRegularHolidays={currentRegularHolidays} setCurrentRegularHolidays={setCurrentRegularHolidays}
                            generateRegularHolidays={generateRegularHolidays} sellerapi={sellerapi} userInfo={userInfo}
                            setSelectDateOne={setSelectDateOne} selectDateOne={selectDateOne} ondataLoading={ondataLoading}
                            />
                        </div>
                    </div>
                }
                {activeButton === 'regularholiday' &&
                    <div className={`${seller.seller_store_regular_box} py-4 mt-4 px-3`}>
                        <div className={`d-flex justify-content-between ${seller.seller_stroe_regular_update_box} pt-3 pb-2`}>
                            <div><span className='fs-5'>정기 휴무일</span></div>
                            {!showRegularUpdate &&<div><button onClick={handleRegularUpdate} className={`${seller.seller_signature_color2}`}>수정</button></div>}
                            
                            {showRegularUpdate &&
                                <div className='d-flex'>
                                    <div><button onClick={handleRegularUpdateCancel} className={`border ${seller.seller_gray_color} me-2`}>취소</button></div>
                                    <div><button className={`${seller.seller_signature_color2}`} onClick={handleSave} type='button'>저장</button></div>
                                </div>
                            }
                        </div>

                        <div>

                        </div>
                        {
                            regularHolidays.length != 0 ?
                            
                            !showRegularUpdate &&

                            <div>
                                {[...new Set(regularHolidays.map((holiday) => {
                                    // // console.log(convertDateToWeekAndDay(holiday));
                                    const { weekOfMonth, dayOfWeek } = convertDateToWeekAndDay(holiday);
                                    // // console.log("weekOfMonth : " + weekOfMonth)
                                    // // console.log("dayOfWeek : " + dayOfWeek)
                                    return convertWeekAndDayToString(weekOfMonth, dayOfWeek);
                                }))].map((holidayString) => <div>{holidayString}</div>)}
                            </div>
                            :
                            <div>
                                <span>등록된 정기 휴무일이 없어요.</span>
                            </div>
                        }
                        {
                            showRegularUpdate &&
                            <div>
                                <div><span>정기휴무 주기</span></div>
                                {renderSelectBoxes()}
                                <div onClick={addSelectBoxes} className={`${seller.seller_regular_add_btn_box}`}>
                                    <div className='d-flex align-items-center'>
                                        <span className={`material-symbols-outlined ${seller.seller_signature_color2}`}>add</span>
                                    </div>
                                    <div className='position-relative w-100'>
                                        <span className={`${seller.seller_regular_add_text}`}>정기 휴무일 추가</span>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default SellerStoreDateSetting;
