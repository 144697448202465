import React from 'react'
import {Link, useNavigate} from 'react-router-dom'

const EventPaymentHeader = () => {
    const back = useNavigate();

    const backNavigate = () => {
        back(-1);
    }
  return (
    <>
      <div>
        {/* <div className='position-fixed'> */}
          <div className='support_pay_header'>
            <div>
              <button onClick={() => backNavigate()}><span className="material-symbols-outlined">arrow_back_ios</span></button>
            </div>
            <div>
              <span>결제</span>
            </div>
          </div>
        </div>
    </>
  )
}

export default EventPaymentHeader