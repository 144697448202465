import React, { useContext, useEffect, useState } from 'react';
import admin from '../../../admin.module.css';
import { CSSTransition } from 'react-transition-group';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { LoginContext } from '../../../../contexts/LoginContextProvider';
import * as adminApi from '../../../../apis/admin';
import { Pagination } from 'antd';

const AdminSellerFaq = () => {
    const { userInfo } = useContext(LoginContext);
    const MySwal = withReactContent(Swal);
    const [faqAdd, setFaqAdd] = useState(false);
    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');
    const [openFaq, setOpenFaq] = useState({});
    const [faqContent, setFaqContent] = useState({});
    const [faqList, setFaqList] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [isEditing, setIsEditing] = useState({});

    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10; // 페이지당 항목 수

    useEffect(() => {
        faqSelect();
    }, []);

    // 페이지 이동 시 호출되는 함수
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    // 페이지별 항목 계산
    const startIndex = (currentPage - 1) * pageSize;
    // currentFaqList는 faqList가 배열일 때만 데이터를 slice합니다.
    const currentFaqList = Array.isArray(faqList) ? faqList.slice(startIndex, startIndex + pageSize) : [];


    const faqSelect = async () => {
        const response = await adminApi.faqSelect();
        setFaqList(response.data);
    };

    const onFaqAdd = () => {
        setFaqAdd(!faqAdd);
        setFaqContent({ ...faqContent, faqWriter: userInfo?.userId });
    };

    const onFaqCancel = () => {
        MySwal.fire({
            customClass: {
                popup: `${admin.admin_notice_add_popup}`
            },
            html: `
                <div>
                    <div class='${admin.admin_notice_add_title}'><span>작성을 취소 하시겠습니까?</span></div>
                    <div class='${admin.admin_notice_add_content}'><span>작성 중인 내용은 저장 되지않습니다.</span></div>
                    <div class='${admin.admin_notice_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                      <button class='mx-1' id='faq_add_cancel'>취소</button>
                      <button class='mx-1' id='faq_add_success'>확인</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });

        document.addEventListener('click', faqAddSuccess);
        document.addEventListener('click', faqAddCancel);

        function faqAddSuccess(e) {
            if (e.target && e.target.id === 'faq_add_success') {
                setFaqAdd(false);
                setQuestion('');
                setAnswer('');
                document.removeEventListener('click', faqAddSuccess);
                MySwal.close();
            }
        }

        function faqAddCancel(e) {
            if (e.target && e.target.id === 'faq_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', faqAddCancel);
            }
        }
    };

    const handleFaqAdd = async () => {
        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html: `
                  <div>
                      <div class='${admin.admin_seller_add_title}'><span>FAQ 를 등록하시겠습니까?</span></div>
                      <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                        <button class='mx-1' id='seller_add_cancel'>취소</button>
                        <button class='mx-1' id='seller_add_success'>등록</button>
                      </div>
                  </div>
              `,
            showConfirmButton: false,
            showCancelButton: false,
        });

        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);

        async function sellerAddSuccess(e) {
            if (e.target && e.target.id === 'seller_add_success') {
                try {
                    const response = await adminApi.faqInsert(faqContent);
                    const data = response.data;

                    if (data === 'SUCCESS') {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                              <div>
                                <div class='${admin.admin_seller_add_title}'><span>FAQ가 등록되었습니다.</span></div>
                                <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                    <button class='mx-1' id='seller_add_confirm'>확인</button>
                                </div>
                              </div>
                              `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });

                        document.addEventListener('click', sellerAddConfirm);

                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                setFaqAdd(false);
                                setQuestion('');
                                setAnswer('');
                                setFaqContent({});
                                faqSelect();
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    }
                } catch (error) {
                    console.error('error : ', error);
                }
            }
        }

        function sellerAddCancel(e) {
            if (e.target && e.target.id === 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
            }
        }
    };

    const handleSelectAll = (e) => {
        setSelectedItems(e.target.checked ? faqList.map(item => item.faqNo) : []);
    };

    const handleSelectItem = (faqNo) => {
        setSelectedItems(prev =>
            prev.includes(faqNo) ? prev.filter(id => id !== faqNo) : [...prev, faqNo]
        );
    };

    const toggleFaq = (faqNo) => {
        setOpenFaq(prev => ({ ...prev, [faqNo]: !prev[faqNo] }));
    };

    const startEditing = (faqNo) => {
        setIsEditing({ [faqNo]: true });
        const selectedFaq = faqList.find(item => item.faqNo === faqNo);
        setFaqContent({ faqQuestion: selectedFaq.faqQuestion, faqAnswer: selectedFaq.faqAnswer });
    };

    const cancelEditing = () => setIsEditing({});

    const saveEdit = async (faqNo) => {
        try {
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html: `
                      <div>
                          <div class='${admin.admin_seller_add_title}'><span>FAQ 를 수정하시겠습니까?</span></div>
                          <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_cancel'>취소</button>
                            <button class='mx-1' id='seller_add_success'>수정</button>
                          </div>
                      </div>
                  `,
                showConfirmButton: false,
                showCancelButton: false,
            });
    
            document.addEventListener('click', sellerAddSuccess);
            document.addEventListener('click', sellerAddCancel);
    
            async function sellerAddSuccess(e) {
                if (e.target && e.target.id === 'seller_add_success') {
                    try {
                        const response = await adminApi.updateFaq(faqNo, faqContent);;
                        const data = response.data;
    
                        if (data === 'SUCCESS') {
                            MySwal.fire({
                                customClass: {
                                    popup: `${admin.admin_seller_add_popup}`
                                },
                                html: `
                                  <div>
                                    <div class='${admin.admin_seller_add_title}'><span>FAQ가 수정되었습니다.</span></div>
                                    <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                                    </div>
                                  </div>
                                  `,
                                showConfirmButton: false,
                                showCancelButton: false,
                            });
    
                            document.addEventListener('click', sellerAddConfirm);
    
                            function sellerAddConfirm(e) {
                                if (e.target && e.target.id === 'seller_add_confirm') {
                                    faqSelect();
                                    cancelEditing();
                                    MySwal.close();
                                    document.removeEventListener('click', sellerAddConfirm);
                                    document.removeEventListener('click', sellerAddSuccess);
                                    document.removeEventListener('click', sellerAddCancel);
                                }
                            }
                        }
                    } catch (error) {
                        console.error('error : ', error);
                    }
                }
            }
    
            function sellerAddCancel(e) {
                if (e.target && e.target.id === 'seller_add_cancel') {
                    MySwal.close();
                    document.removeEventListener('click', sellerAddSuccess);
                    document.removeEventListener('click', sellerAddCancel);
                }
            }
        } catch (error) {
            console.error(error);
        }
    };
    
    const onQuestionInput = (e) => {
        setQuestion(e.target.value);
        setFaqContent({
            ...faqContent, faqQuestion : e.target.value
        })
    }
    const onAnswerInput = (e) => {
        setAnswer(e.target.value);
        setFaqContent({
            ...faqContent, faqAnswer : e.target.value
        })
    }

    return (
        <div className={`${admin.admin_seller_faq_box} px-3 py-3`}>
            <div>
                <div className={`${admin.admin_seller_faq_header}`}><span>셀러 FAQ</span></div>
                <div className={`${admin.admin_seller_faq_delete_box}`}>
                    <div>
                        <span>전체</span>
                        <span>{faqList.length}</span>
                        <span>건</span>
                    </div>
                    {selectedItems.length > 0 && 
                        <div><button>삭제</button></div>
                    }
                </div>
                <div className={`${admin.admin_seller_faq_table_header}`}>
                    <div><input type="checkbox" onChange={handleSelectAll} checked={selectedItems.length === faqList.length} /></div>
                    <div><span>번호</span></div>
                    <div><span>질문</span></div>
                    <div><span>작성일자</span></div>
                    <div></div>
                </div>
                <div>
                    {
                        faqAdd ? 
                        <div className={`${admin.admin_seller_faq_add}`}><button onClick={() => onFaqCancel()}>작성취소</button></div>
                        :
                        <div className={`${admin.admin_seller_faq_add}`}><button onClick={() => onFaqAdd()}>faq 추가</button></div>
                    }
                    <CSSTransition in={faqAdd} timeout={300} classNames="review" unmountOnExit>
                        <div className={`${admin.admin_seller_faq_add_form}`}>
                            <div></div>
                            <div></div>
                            <div>
                                <div className='position-relative pb-2'>
                                    <div><input type="text" placeholder='질문을 입력해주세요.' value={faqContent.faqQuestion || ''} onChange={(e) => onQuestionInput(e)}/></div>
                                    <div className={`${admin.admin_middle_center}`}><span>Q.</span></div>
                                </div>
                                <div>
                                    <textarea name="" id="" placeholder='답변을 입력해주세요.' value={faqContent.faqAnswer || ''} onChange={(e) => onAnswerInput(e)}></textarea>
                                </div>
                            </div>
                            <div></div>
                            <div>
                                <button onClick={() => handleFaqAdd()}>등록 하기</button>
                            </div>
                        </div>
                    </CSSTransition>
                </div>
                {currentFaqList.map((item, index) => (
                    <div key={item.faqNo}>
                        <div className={`${admin.admin_seller_faq_table_content}`}>
                            <div><input type="checkbox" onChange={() => handleSelectItem(item.faqNo)} checked={selectedItems.includes(item.faqNo)} /></div>
                            <div><span>{faqList.length - startIndex - index}</span></div>
                            {isEditing[item.faqNo] ?
                                <div className={`${admin.admin_seller_faq_table_udpate_input}`}>
                                    <div><span>Q.</span></div>
                                    <div>
                                        <input type="text" value={faqContent.faqQuestion} onChange={(e) => setFaqContent({ ...faqContent, faqQuestion: e.target.value })} />
                                    </div>
                                </div>
                                :
                                <div><span>Q. </span><span>{item.faqQuestion}</span></div>
                            }
                            <div><span>{new Date(item.faqRegDate).toLocaleDateString()}</span></div>
                            <div>
                                <button onClick={() => toggleFaq(item.faqNo)}>{openFaq[item.faqNo] ? '접기' : '자세히보기'}</button>
                            </div>
                        </div>
                        <CSSTransition in={openFaq[item.faqNo]} timeout={300} classNames="review" unmountOnExit>
                            <div className={`${admin.admin_seller_faq_table_content_answer}`}>
                                <div></div>
                                <div></div>
                                {
                                    isEditing[item.faqNo] ?
                                    <div className={`${admin.admin_seller_faq_table_udpate_textarea}`}>
                                        <div><span>A.</span></div>
                                        <div><textarea value={faqContent.faqAnswer} onChange={(e) => setFaqContent({ ...faqContent, faqAnswer: e.target.value })}></textarea></div>
                                    </div>
                                    :
                                    <div><span>A. </span><span>{item.faqAnswer}</span></div>
                                }
                                <div></div>
                                {
                                    isEditing[item.faqNo] ?
                                    <div className={`${admin.faq_content_update_cancel_btn}`}>
                                        <button onClick={() => saveEdit(item.faqNo)}>수정</button>
                                        <button onClick={cancelEditing}>취소</button>
                                    </div>
                                    :
                                    <div><button className={`${admin.faq_content_update_btn}`} onClick={() => startEditing(item.faqNo)}>내용 수정</button></div>
                                }
                            </div>
                        </CSSTransition>
                    </div>
                ))}
                {/* 페이지 네이션 컴포넌트 */}
                <Pagination
                    current={currentPage}
                    pageSize={pageSize}
                    total={faqList.length}
                    onChange={handlePageChange}
                    style={{ marginTop: '20px', textAlign: 'center' }}
                />
            </div>
        </div>
    );
};

export default AdminSellerFaq;
