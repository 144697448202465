import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import dailyduck from '../../containers/my/MyContainner.module.css';
import store from '../../containers/market/store/store.module.css';
import ss from '../../containers/market/support/venue/support_shop.module.css';
import * as auth from '../../apis/auth';
import { LoginContext } from '../../contexts/LoginContextProvider';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import Modal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';


const MySave = () => {
    const { userInfo } = useContext(LoginContext);
    const navigate = useNavigate();
    const [activeButton, setActiveButton] = useState('product');
    const [activeCategory, setActiveCategory] = useState('store');
    const [heartActive, setHeartActive] = useState({}); // 각 아이템의 하트 상태를 저장할 객체
    const [heartSupportActive, setHeartSupportActive] = useState({}); // 각 아이템의 하트 상태를 저장할 객체
    const [storeWishList, setStroeWishList] = useState([]);
    const [supportWishList, setSupportWishList] = useState([]);
    const [modalOpen, setModalOpen] = useState(false); // 모달 열림/닫힘 상태 관리
    const [selectedImage, setSelectedImage] = useState(''); // 선택된 이미지 URL 관리
    const [columnClass, setColumnClass] = useState('col-6'); // 초기값 설정

    useEffect(() => {
      const updateColumnClass = () => {
        if (window.innerWidth > 500) {
          setColumnClass('col-4'); // 화면이 500px 이상일 때
        } else {
          setColumnClass('col-6'); // 화면이 500px 이하일 때
        }
      };
  
      updateColumnClass();
      window.addEventListener('resize', updateColumnClass);
  
      return () => {
        window.removeEventListener('resize', updateColumnClass);
      };
    }, []);

    useEffect(() => {
      // console.log('supportWishList : ', supportWishList);
    }, [supportWishList])

    useEffect(() => {
        storeWishListSelect();
        supportWishListSelect();
    }, [userInfo]);

    const handleSaveBack = () => {
        navigate('/my');
    };

    const onHeart = async (productNo) => {
        
        const response = await auth.removeStoreWishList(productNo, userInfo && userInfo?.userId);
        const data = response.data;
        
        if(data === 'SUCCESS') {
            storeWishListSelect();
            // 클릭된 제품의 하트 상태를 토글
            setHeartActive((prevState) => ({
            ...prevState,
            [productNo]: !prevState[productNo], // 선택된 제품의 하트 상태 변경
            }));
        }
    };
    const onSupportHeart = async (productNo) => {    

        const response = await auth.removeSupportWishList(productNo, userInfo && userInfo?.userId);
        const data = response.data;

        if(data === 'SUCCESS') {
            supportWishListSelect();
            // 클릭된 제품의 하트 상태를 토글
            setHeartSupportActive((prevState) => ({
            ...prevState,
            [productNo]: !prevState[productNo], // 선택된 제품의 하트 상태 변경
            }));
        }
    };

    const storeWishListSelect = async () => {
        const response = await auth.storeWishListSelect(userInfo && userInfo?.userId);
        const data = response.data;

        // console.log('storeWishListSelect : ', data);
        setStroeWishList(data);

        // 초기 하트 상태 설정
        const initialHeartState = data.reduce((acc, item) => {
        acc[item.productNo] = true; // 모든 항목을 기본적으로 활성화
        return acc;
        }, {});
        setHeartActive(initialHeartState);
    };

    const supportWishListSelect = async () => {
        const response = await auth.supportWishListSelect(userInfo && userInfo?.userId);
        const data = response.data;

        // console.log('supportWishListSelect : ', data);
        setSupportWishList(data);

        // 초기 하트 상태 설정
        const initialHeartState = data.reduce((acc, item) => {
            acc[item.productNo] = true; // 모든 항목을 기본적으로 활성화
                return acc;
            }, {});
            setHeartSupportActive((prevState) => ({ ...prevState, ...initialHeartState })); // 기존 상태와 병합
    };

    const formatRating = (rating) => {
        return rating.toFixed(1); // 소수점 첫째 자리까지 표시하도록 고정
    };

    const getMinPrice = (items) => {
        if (!Array.isArray(items) || items.length === 0) return null;
        return Math.min(...items.map((item) => item.mainItemPrice));
    };

    // 이미지 클릭 핸들러
    const handleImageClick = (imageUrl) => {
        setSelectedImage(imageUrl); // 선택된 이미지 설정
        setModalOpen(true); // 모달 열기
    };

    const isSoldOut = (item) => {
      // 품절 여부 확인
      if (item.inventoryCnt === 0) {
        if (
          Array.isArray(item.optionValueList) &&
          item.optionValueList.every((option) => option.optionInventory === 0)
        ) {
          return true;
        }
      }
      return false;
    };

    const onDetail = (productNo) => {
      navigate(`/store/product/${productNo}`)
    }

  return (
    <>
      <div className={`${dailyduck.mycouponporint_container}`}>
        <div className={dailyduck.mycouponporint_box}>
          <div className={dailyduck.my_wishlist_header}>
            <div>
              <button onClick={handleSaveBack}><span className="material-symbols-outlined">arrow_back_ios</span></button>
            </div>
            <div><span>찜</span></div>
          </div>
          <div>
            <div className={`${dailyduck.my_save_type}`}>
              <div className={`${activeButton === 'product' ? dailyduck.my_save_active : ''}`}><button onClick={() => setActiveButton('product')}>상품</button></div>
              <div className={`${activeButton === 'shop' ? dailyduck.my_save_active : ''}`}><button onClick={() => setActiveButton('shop')}>상점</button></div>
            </div>
          </div>
          <div className='px-3 bg-white'>
            <div className={`py-3 ${dailyduck.my_save_category}`}>
              <div><button className={`${activeCategory === 'store' ? '' : dailyduck.gray_color}`} onClick={() => setActiveCategory('store')}>스토어</button></div>
              <div className={`px-2 ${dailyduck.gray_color}`}><span>|</span></div>
              <div><button className={`${activeCategory === 'support' ? '' : dailyduck.gray_color}`} onClick={() => setActiveCategory('support')}>서포트</button></div>
            </div>
          </div>
          <div className='py-3 px-3 bg-white'>
            {activeButton === 'product' && activeCategory === 'store' &&
              <>
                <div className='pb-3'>
                  <div><span>전체</span><span className='ps-2'>{storeWishList.length}</span></div>
                </div>
                <div className='row'>
                  {Array.isArray(storeWishList) && storeWishList.map((item, index) => {
                    return (
                      <div className={`${columnClass} position-relative`} key={index}>
                        {isSoldOut(item) && (
                          <div className={`${dailyduck.my_save_stock_cnt_zero_box}`}>
                            <div
                              className={`${dailyduck.my_save_stock_cnt_zero}`}
                              onClick={() => onDetail(item.productNo, index)}
                            >
                              <span>품절</span>
                            </div>
                          </div>
                        )}
                        <Link to={`/store/product/${item.productNo}`} className={`${dailyduck.my_product_store_box}`}>
                          <div className={`${dailyduck.my_product_store_img}`}>
                            <div className={`${dailyduck.my_store_heart}`}>
                              <button onClick={() => onHeart(item.productNo)}>
                                {heartActive[item.productNo] ? (
                                  <img src="../img/icon/venue/heart_fill.png" alt="하트" />
                                ) : (
                                  <img src="../img/icon/venue/white_heart.png" alt="빈하트" />
                                )}
                              </button>
                            </div>
                            <img src={item.productMainImage} alt="스토어찜이미지" />
                          </div>
                          <div className={`${dailyduck.my_product_store_store_name}`}><span>{item.shopName}</span></div>
                          <div className={`${dailyduck.my_product_store_name}`}><span>{item.productName}</span></div>
                          <div className={`${dailyduck.my_product_store_price}`}>
                            <div className={`${dailyduck.my_store_product_discount_rate}`}><span>{item.discountRate}%</span></div>
                            <div><span className={`${dailyduck.my_store_product_total_price}`}>&#8361;{item.productsalePrice.toLocaleString()}</span></div>
                            {item.discountRate !== 0 &&
                              <div><span className={`ps-2 align-bottom ${dailyduck.my_store_product_sub_price}`}>&#8361;{item.productPrice.toLocaleString()}</span></div>
                            }
                          </div>
                          <div className={`${dailyduck.my_product_store_review_box}`}>
                            <span className={`${dailyduck.my_product_store_review_star}`}><img src="../img/icon/star_fill.png" alt="별점" /></span>
                            <span className={`${dailyduck.my_product_store_review_score} ps-1`}>{formatRating(item.reviewRating)}</span>
                            <span className={`${dailyduck.my_product_store_review_count} px-1`}>({item.reviewCount})</span>
                          </div>
                          <div>
                            <div className={`${item.labelType}`}><span>{item.label}</span></div>
                          </div>
                        </Link>
                      </div>
                    )
                  })}
                </div>
              </>
            }
            {activeButton === 'product' && activeCategory === 'support' &&
              <>
                <div className='pb-3'>
                  <div><span>전체</span><span className='ps-2'>{supportWishList.length}</span></div>
                </div>
                <div>
                  {Array.isArray(supportWishList) && supportWishList.map((item, index) => {
                    const minPrice = getMinPrice(item.mainItem);
                    const hasOptions = Array.isArray(item.mainItem) && item.mainItem.length > 0;

                    // 이미지 그룹 생성
                    const images = [
                      item.representativeImage,
                      item.listImage1,
                      item.listImage2,
                      item.listImage3,
                      item.listImage4,
                    ].filter(Boolean); // 유효한 이미지들만 포함

                    return (
                      <div className='position-relative' key={index}>
                        <Link to={`/venue/${item.productNumber}`}>
                            <div className={`search_content_support_info ${ss.support_content_info}`}>
                                <div>
                                    <div>
                                    <img
                                        src={item.shopProfile}
                                        alt=""
                                        onClick={(e) => {
                                        e.preventDefault();
                                        handleImageClick(item.shopProfile);
                                        }}
                                    />
                                    </div>
                                    <div>
                                    <span>{item.productName}</span>
                                    </div>
                                </div>
                                <div>
                                    {/* <span>{item.productName}</span> */}
                                </div>
                                <div>
                                    <span>{item.shopInfo}</span>
                                </div>
                                <div className={`${ss.support_content_price_rate_star_box}`}>
                                    <div>
                                      <div>
                                          <img src="../img/icon/star_fill.png" alt="별점" />
                                      </div>
                                      <div className="ps-1">
                                          <span>{formatRating(item.reviewRating)}</span>
                                      </div>
                                    </div>
                                    <div>
                                      <span>리뷰</span>
                                      <span>{item.reviewCount}</span>
                                    </div>
                                    <div>
                                      {minPrice !== null && (
                                          <span>
                                          &#8361;{minPrice.toLocaleString()}{hasOptions ? ' ~' : ''}
                                          </span>
                                      )}
                                    </div>
                                </div>
                                <div className='position-relative'>
                                  <div className={`${dailyduck.my_support_heart}`}>
                                    <button onClick={(e) => {e.preventDefault(); onSupportHeart(item.productNo)}}>
                                      {heartSupportActive[item.productNo] ? (
                                        <img src="../img/icon/venue/heart_fill.png" alt="하트" />
                                      ) : (
                                        <img src="../img/icon/venue/white_heart.png" alt="빈하트" />
                                      )}
                                    </button>
                                  </div>
                                    <Swiper 
                                      slidesPerView={'auto'} 
                                      spaceBetween={10} 
                                      modules={[Pagination, Navigation]}
                                      className='search_content_support_item'
                                    >
                                      {
                                        images.map((item, index) => (
                                          <SwiperSlide>
                                              <div className="search_content_support_item_img">
                                                <img
                                                    src={item}
                                                    alt="이미지1"
                                                    onClick={(e) => {
                                                    e.preventDefault();
                                                    handleImageClick(item);
                                                    }}
                                                />
                                              </div>
                                          </SwiperSlide>
                                        ))
                                      }
                                    </Swiper>
                                </div>
                                </div>
                        </Link>
                      </div>
                    );
                  })}
                </div>
              </>
            }
            {activeButton === 'shop' && activeCategory === 'store' &&
              <>
                {/* {storeShopList.map((item, index) => (
                  <Link to={''} className='w-100' key={index}>
                    <div className={`${dailyduck.my_shop_store_box} my-2 py-2 border-bottom`}>
                      <div className={`${dailyduck.my_shop_store_img}`}><img src={item.shopImg} alt={item.shopName} /></div>
                      <div className={`${dailyduck.my_shop_store_name} ps-2`}><span>{item.shopName}</span></div>
                      <div className={`${dailyduck.my_shop_store_btn} text-end`}><span className="material-symbols-outlined">arrow_forward_ios</span></div>
                    </div>
                  </Link>
                ))} */}
              </>
            }
            {activeButton === 'shop' && activeCategory === 'support' &&
              <>
                {/* {supportShopList.map((item, index) => (
                  <Link to={''} className='w-100' key={index}>
                    <div className={`${dailyduck.my_shop_store_box} my-2 py-2 border-bottom`}>
                      <div className={`${dailyduck.my_shop_store_img}`}><img src={item.shopImg} alt={item.shopName} /></div>
                      <div className={`${dailyduck.my_shop_store_name} ps-2`}><span>{item.shopName}</span></div>
                      <div className={`${dailyduck.my_shop_store_btn} text-end`}><span className="material-symbols-outlined">arrow_forward_ios</span></div>
                    </div>
                  </Link>
                ))} */}
              </>
            }
          </div>
        </div>
      </div>
        {/* 모달 컴포넌트 */}
        <Modal 
          open={modalOpen} 
          onClose={() => setModalOpen(false)} 
          center>
            <img src={selectedImage} alt="프리뷰 이미지" />
        </Modal>
    </>
  );
};

export default MySave;
