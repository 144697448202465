import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ss from '../../containers/market/support/venue/support_shop.module.css';
import { format } from 'date-fns';
import market from '../../containers/market/market.module.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import * as admin from '../../apis/admin';

const CartPaymentComplete = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const storeItem = location.state?.storeItem || [];
    const supportItem = location.state?.supportItem || [];
    const currency = location.state?.currency || [];
    const totalPrice = location.state?.totalPrice || [];

    const [displayItemName, setDisplayItemName] = useState('');
    const [remainingItemsCount, setRemainingItemsCount] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [paymentType, setPaymentType] = useState('');

    const [recomandItem, setRecomandItem] = useState([]);

    const [paymentBankName, setPaymentBankName] = useState('');
    const [paymentAccountNumber, setPaymentAccountNumber] = useState('');
    const [paymentDepositDeadline, setPaymentDepositDeadline] = useState('');


    useEffect(() => {
        console.log('storeItem : ', storeItem);
        console.log('supportItem : ', supportItem);
        if(currency === 'KRW') {
            let storeTotalPrice = storeItem.reduce((sum, item) => sum + item.paymentPrice, 0);
            let supportTotalAmount = supportItem.reduce((sum, item) => sum + item.amount, 0);
            setTotalAmount(storeTotalPrice + supportTotalAmount);
            
        } else {
            setTotalAmount(totalPrice);
            
        }

        if (storeItem.length === 0 && supportItem.length > 0) {
            setDisplayItemName(supportItem[0]?.mainOptionName);
            setRemainingItemsCount(supportItem.length - 1);
            setPaymentType(supportItem[0]?.reservationPaymentType);

            if(supportItem[0]?.reservationPaymentType === '가상계좌') {
                setPaymentBankName(supportItem[0].paymentBankName);
                setPaymentAccountNumber(supportItem[0].paymentAccountNumber);
                setPaymentDepositDeadline(supportItem[0].paymentDepositDeadline);
            }
        } else if (supportItem.length === 0 && storeItem.length > 0) {
            setDisplayItemName(storeItem[0]?.paymentProductName);
            setRemainingItemsCount(storeItem.length - 1);
            setPaymentType(storeItem[0]?.paymentType);
            if(supportItem[0]?.paymentType === '가상계좌') {
                setPaymentBankName(supportItem[0].paymentBankName);
                setPaymentAccountNumber(supportItem[0].paymentAccountNumber);
                setPaymentDepositDeadline(supportItem[0].paymentDepositDeadline);
            }
        } else if (storeItem.length > 0 && supportItem.length > 0) {
            setDisplayItemName(storeItem[0]?.paymentProductName);
            setRemainingItemsCount(storeItem.length + supportItem.length - 1);
            setPaymentType(storeItem[0]?.paymentType);
            if(storeItem[0]?.paymentType === '가상계좌') {
                setPaymentBankName(storeItem[0].paymentBankName);
                setPaymentAccountNumber(storeItem[0].paymentAccountNumber);
                setPaymentDepositDeadline(storeItem[0].paymentDepositDeadline);
            }
        }

        recomandSelect();
    }, [storeItem, supportItem, totalPrice, currency]);

    
    const recomandSelect = async () => {
        const response = await admin.recomandProductSelect();
        const data = response.data;

        console.log(data);
        if (response.status === 200) {

            const limitedData = data.slice(0, 10);

            setRecomandItem(limitedData);
        } else {
            console.error('에러');
        }
    }

    const onStore = () => {
        navigate(`/store/album`);
    }
    
    const mainBtn = () => {
        navigate('/market');
    }

    const vBankDate = (date) => {
        return format(new Date(date), 'yyyy-MM-dd HH:mm:ss');
    }

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            alert("가상계좌번호가 클립보드에 복사되었습니다.");
        }).catch((err) => {
            console.error("클립보드 복사 실패: ", err);
        });
    }

    const bankName = (depositBankCode) => {
        
        let bankName = '';
        switch (depositBankCode) {
            case "39": bankName = '경남은행'; break;
            case "34": bankName = '광주은행'; break;
            case "12": bankName = '단위농협(지역농축협)'; break;
            case "32": bankName = '부산은행'; break;
            case "45": bankName = '새마을금고'; break;
            case "64": bankName = '산림조합'; break;
            case "88": bankName = '신한은행'; break;
            case "48": bankName = '신협'; break;
            case "27": bankName = '씨티은행'; break;
            case "20": bankName = '우리은행'; break;
            case "71": bankName = '우체국예금보험'; break;
            case "50": bankName = '저축은행중앙회'; break;
            case "37": bankName = '전북은행'; break;
            case "35": bankName = '제주은행'; break;
            case "90": bankName = '카카오뱅크'; break;
            case "89": bankName = '케이뱅크'; break;
            case "92": bankName = '토스뱅크'; break;
            case "81": bankName = '하나은행'; break;
            case "54": bankName = '홍콩상하이은행'; break;
            case "03": bankName = 'IBK기업은행'; break;
            case "06": bankName = 'KB국민은행'; break;
            case "31": bankName = 'DGB대구은행'; break;
            case "02": bankName = 'KDB산업은행'; break;
            case "11": bankName = 'NH농협은행'; break;
            case "23": bankName = 'SC제일은행'; break;
            case "07": bankName = 'Sh수협은행'; break;
            case "S8": bankName = '교보증권'; break;
            case "SE": bankName = '대신증권'; break;
            case "SK": bankName = '메리츠증권'; break;
            case "S5": bankName = '미래에셋증권'; break;
            case "SM": bankName = '부국증권'; break;
            case "S3": bankName = '삼성증권'; break;
            case "SN": bankName = '신영증권'; break;
            case "S2": bankName = '신한금융투자'; break;
            case "S0": bankName = '유안타증권'; break;
            case "SJ": bankName = '유진투자증권'; break;
            case "SQ": bankName = '카카오페이증권'; break;
            case "SB": bankName = '키움증권'; break;
            case "ST": bankName = '토스증권'; break;
            case "SR": bankName = '한국포스증권'; break;
            case "SH": bankName = '하나금융투자'; break;
            case "S9": bankName = '아이엠증권'; break;
            case "S6": bankName = '한국투자증권'; break;
            case "SG": bankName = '한화투자증권'; break;
            case "SA": bankName = '현대차증권'; break;
            case "SI": bankName = 'DB금융투자'; break;
            case "S4": bankName = 'KB증권'; break;
            case "SP": bankName = 'KTB투자증권'; break;
            case "SO": bankName = 'LIG투자증권'; break;
            case "SL": bankName = 'NH투자증권'; break;
            case "SD": bankName = 'SK증권'; break;
        
            default: bankName = '존재하지 않는 은행코드입니다.'; break;
        }

        return bankName;
    }

    const onReviewStore = () => {
        navigate(`/market/suggest/product`);
    }

    const handleProductClick = (path) => {
            navigate(path);
    };

    return (
        <div>
            <div>
                <div className={`${ss.reserve_payment_success_header}`}>
                    <button onClick={() => mainBtn()}><span className="material-symbols-outlined">close</span></button>
                    <button><img src={`${process.env.PUBLIC_URL}/img/logo_black.png`} alt="" /></button>
                </div>
                <div className={`${ss.message_payment_success_content}`}>
                    <div className={`${ss.message_payment_success_content_header}`}>
                        <div><img src={`${process.env.PUBLIC_URL}/img/payment_success.png`} alt="" /></div>
                        <div className='pt-1'><span>{paymentType === '가상계좌' ? '결제대기' : '결제완료'}</span></div>
                    </div>
                    <div className={`${ss.message_payment_success_content_box}`}>
                        <div className={`${ss.message_payment_success_content_style}`}>
                            <div><span>결제상품</span></div>
                            <div>
                                <span>
                                    {displayItemName} 외 {remainingItemsCount}건
                                </span>
                            </div>
                        </div>
                        {
                            paymentType === '가상계좌' ?
                            <div className={`${ss.message_payment_success_content_vbank_box}`}>
                                <div><span>가상계좌정보</span></div>
                                <div className={`${ss.message_payment_success_content_style}`}>
                                    <div><span>은행명</span></div>
                                    <div><span>{bankName(paymentBankName)}</span></div>
                                </div>
                                <div className={`${ss.message_payment_success_content_style}`}>
                                    <div><span>가상계좌번호</span></div>
                                    <div className={`${ss.message_payment_account}`}>
                                        <button onClick={() => copyToClipboard(paymentAccountNumber)}>
                                            {paymentAccountNumber}
                                        </button>
                                    </div>
                                </div>
                                <div className={`${ss.message_payment_success_content_style}`}>
                                    <div><span>입금금액</span></div>
                                    <div><span>{parseInt(totalAmount).toLocaleString()}원</span></div>
                                </div>
                                <div className={`${ss.message_payment_success_content_style}`}>
                                    <div><span>입금기한</span></div>
                                    <div><span>{vBankDate(paymentDepositDeadline)}</span></div>
                                </div>
                            </div>
                            :
                            <div className={`${ss.message_payment_success_content_style}`}>
                                <div><span>결제금액</span></div>
                                <div><span>{currency !== 'USD' ? totalAmount.toLocaleString() + '원' : totalAmount + ' USD'}</span></div>
                            </div>
                        }
                    </div>
                    
                    <div className={`${ss.message_payment_btn}`}>
                        <div><button onClick={() => mainBtn()}>홈으로</button></div>
                        <div><button onClick={() => onStore()}>계속쇼핑하기</button></div>
                    </div>
                </div>
                <div className={`${market.market_main_popularity_product}`}>
                    <div>
                        <button onClick={onReviewStore}>
                            <div><span>추천상품</span></div>
                            <div><span className="material-symbols-outlined">keyboard_arrow_right</span></div>
                        </button>
                    </div>
                    <div>
                        <Swiper
                            slidesPerView={'auto'}
                            spaceBetween={0}
                            modules={[Pagination, Navigation]} // Autoplay 모듈 추가
                            className={`${market.market_main_popularity_swiper}`}
                        >
                            {
                                recomandItem.map((item, index) => (
                                    <SwiperSlide className={`${market.market_main_popularity_swiper_box}`} key={index}>
                                        <div
                                            onClick={() => handleProductClick(`/store/product/${item.productNo}`)}
                                            className={`${market.market_main_popularity_box}`}
                                        >
                                            <div><img src={item.productMainImage} alt="" /></div>
                                            <div>
                                                <div><span>{item.shopName}</span></div>
                                                <div><span>{item.productName}</span></div>
                                                <div>
                                                    <div><span>&#8361;{item.productsalePrice.toLocaleString()}</span></div>
                                                    <div><span>&#8361;{item.productPrice.toLocaleString()}</span></div>
                                                </div>
                                                <div>
                                                {item.productBadge === 'freeDelivery' && <span className={market.freeDelivery}>무료배송</span>}
                                                {item.productBadge === 'md' && <span className={market.md}>MD추천</span>}
                                                {item.productBadge === 'best' && <span className={market.best}>BEST</span>}
                                                {item.productBadge === 'hot' && <span className={market.hot}>HOT</span>}
                                            </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))
                            }
                        </Swiper>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CartPaymentComplete;
