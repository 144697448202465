// <path-to-your-build>/src/ckeditor.ts or file containing editor configuration if you are integrating an editor from source.

// The editor creator to use.
import  Editor  from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';


const TextEditor2 = ({setData2, onExchangeReturn, data2}) => {
    const edrtorConfiguration = {
		toolbar: {
			items: [
				'bold',
				'italic',
				'link',
				'bulletedList',
				'fontSize',
				'undo',
				'redo',
				'strikethrough',
				'subscript',
				'superscript',
				'insertTable',
				'findAndReplace'
			]
		},
		language: 'ko',
		blockToolbar: [
		],
		table: {
			contentToolbar: [
				'tableColumn',
				'tableRow',
				'mergeTableCells',
				'tableCellProperties',
				'tableProperties'
			]
		}
    };

    return (
        <CKEditor 
        editor={Editor}
        config={edrtorConfiguration}
        data = {data2}
        onChange={(event, editor) => {
            setData2(editor.getData()); // 에디터 작성 내용 저장 
			onExchangeReturn(editor.getData());
        }}
        />
    )
}

export default TextEditor2;