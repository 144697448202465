import api from "./api";

export const board = (currentPage, itemsPerPage) => api.get(`/api/seller/board?page=${currentPage}&size=${itemsPerPage}`);
export const faq = (currentPage, itemsPerPage) => api.get(`/api/seller/faq?page=${currentPage}&size=${itemsPerPage}`);
export const shopSave = (shopInfo) => api.post('/api/seller/shopAdd', shopInfo);
export const shopSelect = (userId) => api.get(`/api/seller/shopselect/${userId}`);
export const regularHoliday = (holiday, userId) => api.post(`/api/seller/shopregularholiday/${userId}`, holiday);
export const removeHoliday = (holiday, userId) => api.delete(`/api/seller/shopregularholidayDelete`, { 
    data: holiday,
    params: { userId }
});
export const dateSettingLoading = (userId) => api.get(`/api/seller/shopregularholidayloading/${userId}`);
export const temporaryAdd = (newEvent, userId) => api.post(`/api/seller/temporaryAdd/${userId}`, newEvent);
export const dateTemporaryLoading = (userId) => api.get(`/api/seller/shoptemporaryloading/${userId}`);
export const temporaryDelete = (temporaryNo, userId) => api.delete(`/api/seller/temporaryDelete`, {
    data: temporaryNo,
    params: { userId }
});

export const faqAdd = (faq, userId) => api.post(`/api/seller/faqAdd/${userId}`, faq);
export const sellerFaqSelect = (userId) => api.get(`/api/seller/sellerFaqSelect/${userId}`);
export const faqUpdate = (faq, userId) => api.put(`/api/seller/faqUpdate/${userId}`, faq);
export const faqDelete = (faq, userId) => api.delete(`/api/seller/faqDelete`, {
    data : faq,
    params: {userId}
});

export const operatingCreate = (userId, dataToSave) => api.post(`/api/seller/operatingCreate/${userId}`, dataToSave);

export const supportProductAdd = (productList, userId) => api.post(`/api/seller/supportProductAdd/${userId}`, productList);

export const supportProductSelect = (userId) => api.get(`/api/seller/supportProductSelect/${userId}`);

export const detailProductSelect = (productNumber) => api.get(`/api/seller/detailProductSelect/${productNumber}`);

export const productUpdate = (productList, userId) => api.put(`/api/seller/productUpdate/${userId}`, productList);

export const deleteProducts = (productNo, userId) => api.delete(`/api/seller/deleteProducts`, {
    data : productNo,
    params: {userId}
});

export const stateChange = (state, productNos) => api.put(`/api/seller/stateChange`, {state, productNos});

export const copyProducts = (productNos) => api.post('/api/seller/productCopy', productNos);

export const registrationStateChange = (state, productNos) => api.put(`/api/seller/registrationStateChange`, {state, productNos});

export const reservationSelect = (userId) => api.get(`/api/seller/reservation/${userId}`);

export const imgUpload = (formData, config) => api.post('/api/file/upload', formData, config);

export const updateCompleteReservationStatus = (selectedReservations) => api.put(`/api/seller/updateCompleteReservationStatus`, selectedReservations);

export const updateCancelReservationStatus = (selectedReservations) => api.put(`/api/seller/updateCancelReservationStatus`, selectedReservations);

// 메세지 결제내역 조회
export const messagePaymentSelect = (userId) => api.get(`/api/seller/messagePaymentSelect/${userId}`);

// 메세지 결제 전액 취소
export const messagePaymentCancel = (selectedInfo) => api.put(`/api/seller/messagePaymentCancel`, selectedInfo);

// 메세지 결제 부분 취소
export const messagePartialPaymentCancel = (selectedInfo) => api.put(`/api/seller/messagePartialPaymentCancel`, selectedInfo);
