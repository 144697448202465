import React from 'react'
import DailyduckNavigator from '../../components/footer/Dailyduck_Navigator'
import SearchHeader from '../../components/search/header/SearchHeader'
import DailyduckHeader from '../../components/header/Dailyduck_Header'
import SearchContent from '../../components/search/contens/SearchContent'

const SearchContainer = () => {
  return (
    <>
        <DailyduckHeader />
        <SearchContent />
        <DailyduckNavigator />
    </>
  )
}

export default SearchContainer