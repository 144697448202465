import React from 'react'
import { animated } from 'react-spring';
import { useSpring, useTransition } from '@react-spring/core';
import { useNavigate } from 'react-router-dom';

const MyNoticeDetail = ( {dailyduck, showNoticeDetail, setShowNoticeDetail, detailNotice} ) => {

    const transitions = useTransition(showNoticeDetail, {
        from: { transform: "translateX(100%)" },
        enter: { transform: "translateX(0)" },
        leave: { transform: "translateX(100%)" },
        config: {
            duration: 300,
        },
    });   
    const handleNoticeDetailBack = () => {
        setShowNoticeDetail(false);
    }
    return (
        <>
            {
                showNoticeDetail &&
                <div className={`${dailyduck.my_notice_write_container}`}>
                    {transitions((style, item) =>
                        item ? 
                        <animated.div className={dailyduck.mycouponporint_box} style={style}>
                            <div className={`${dailyduck.my_notice_write_header}`}>
                                <div>
                                    <button onClick={() => handleNoticeDetailBack()}>
                                        <span className="material-symbols-outlined">arrow_back_ios</span>
                                    </button>
                                </div>
                                <div>
                                    <div><span>공지사항</span></div>
                                </div>
                            </div>
                            <div className={`${dailyduck.my_notice_write_content}`}>
                                <div><span>{detailNotice.notificationTitle}</span></div>
                                <div><span>{detailNotice.notificationRegDate}</span></div>
                                <div dangerouslySetInnerHTML={{ __html: detailNotice.notificationContent }}></div>
                            </div>
                        </animated.div>
                        : null
                    )}
                </div>
            }
        </>
    )
}

export default MyNoticeDetail