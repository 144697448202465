import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import dailyduck from '../../containers/my/MyContainner.module.css';
import * as auth from '../../apis/auth';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const ExchangePayment = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const MySwal = withReactContent(Swal);
    
    const [verificationResult, setVerificationResult] = useState(null);
    const [ exchangeItem, setExchangeItem ] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const paymentKey = query.get('paymentKey');
        const orderId = query.get('orderId');
        const amount = query.get('amount');
        const exchangeContent = JSON.parse(localStorage.getItem('exchangeContent'));

        // console.log('paymentKey : ', paymentKey);
        // console.log('orderId : ', orderId);
        // console.log('amount : ', amount);
        // console.log('exchangeContent : ', exchangeContent);

        const verifyPayment = async () => {
            try {
                const response = await axios.post('/api/payment/verify', {
                    paymentKey: paymentKey,
                    orderId: orderId,
                    amount: amount
                });
                setVerificationResult(response.data);
                // console.log('response.data : ', response.data);

                const updatedStorePaymentInfo = {
                    ...exchangeContent,
                    exchangeAddPaymentKey: paymentKey,
                    exchangeAddOrderId: orderId,
                    amount: amount,
                    exchangeAddPaymentType : response.data.method
                };
        
                setExchangeItem(updatedStorePaymentInfo);

                if (response.data.status === "DONE") {
                    try {
                        const response = await fetch('/api/payment', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                paymentKey: paymentKey,
                                amount: exchangeContent.exchangeAddTotalPrice
                            }),
                        });

                        const result = await response.json();
                        // console.log('result : ', result);

                        if (result.success) {
                            setExchangeItem((prevState) => {
                                const newState = { ...prevState, ...result };
                                exchangeAddPaymentAdd(newState);
                                return newState;
                            });
                        } else {
                            // 서버 검증 실패 시 로직
                            alert(`결제 검증에 실패하였습니다: ${result.error_msg}`);
                        }
                    } catch (error) {
                        console.error('error : ', error);
                    }
                } else {
                    // 결제 실패 처리
                    navigate(`/store/payment/fail`);
                }
            } catch (error) {
                console.error('결제 검증 오류:', error);
                navigate(`/store/payment/fail`);
            } finally {
                setLoading(false);
            }
        };

        verifyPayment();
    }, [location, navigate]);

    const exchangeAddPaymentAdd = async (newState) => {
        const response = await auth.exchangeApply(newState);
        const data = response.data;

        // console.log(data);

        if(data === 'SUCCESS') {
            MySwal.fire({
                customClass: {
                    popup: `${dailyduck.order_cancel_swal_popup}`
                },
                html : 
                `
                <div>
                <div class='${dailyduck.order_cancel_title}'><span>교환 신청이 완료 되었습니다.</span></div>
                <div class='${dailyduck.order_cancel_sub_title}'><span>교환 배송 시점에 따라 교환 일정이 달라집니다.</span></div>
                <div class='${dailyduck.order_cancel_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                    <button class='mx-1' id='seller_add_confirm'>확인</button>
                </div>
                </div>
                `,
                showConfirmButton: false,
                showCancelButton: false,
            });
            document.addEventListener('click', sellerAddConfirm);

            
            function sellerAddConfirm(e) {
                if(e.target && e.target.id == 'seller_add_confirm') {
                    MySwal.close();
                    document.removeEventListener('click', sellerAddConfirm);
                    navigate('/my/od');
                }
            }
        }
    }
  return (
    <div>
        <div>
            <div><span>결제 진행중</span></div>
        </div>
    </div>
  )
}

export default ExchangePayment