import React, { useEffect, useState } from 'react';
import admin from '../../../admin.module.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useNavigate } from 'react-router-dom';
import { Pagination } from 'antd';
import * as store from '../../../../apis/store';
import { formatDistanceToNow } from 'date-fns';
import { ko } from 'date-fns/locale';
import { createRoot } from 'react-dom/client';

const MySwal = withReactContent(Swal);

const RejectReasonModal = ({ productName, onCancel, onConfirm }) => {
    const [tempReason, setTempReason] = useState(''); // 로컬 상태로 거절 사유 관리

    return (
        <div>
            <div className={`${admin.admin_seller_add_title}`}><span>거절 사유</span></div>
            <div className={`${admin.admin_seller_approval_content}`}>
                <div><span>상품명</span></div>
                <div><span>{productName}</span></div>
            </div>
            <div className={`${admin.admin_seller_approval_content}`}>
                <div><span>거절 사유</span></div>
                <div>
                    <textarea
                        id="rejectionReasonTextarea"
                        name="rejectionReason"
                        cols="30"
                        rows="10"
                        placeholder="내용을 500자 이내로 입력하세요."
                        value={tempReason}
                        onChange={(e) => setTempReason(e.target.value)} // 로컬 상태로 값을 업데이트
                    ></textarea>
                </div>
            </div>
            <div className={`${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between`}>
                <button className='mx-1' onClick={onCancel}>취소</button>
                <button className='mx-1' onClick={() => {
                    // console.log('거절 사유:', tempReason); // 입력된 값이 제대로 반영되었는지 확인하기 위한 콘솔 출력
                    if (tempReason.trim() === '') {
                        alert('거절 사유를 입력해주세요.'); // 입력값이 없을 때 경고
                    } else {
                        onConfirm(tempReason); // 입력된 값을 onConfirm으로 넘김
                    }
                }}>확인</button>
            </div>
        </div>
    );
};



const AdminSellerProductInspection = () => {
    const navigate = useNavigate();
    const [activeButton, setActiveButton] = useState('total');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(14);
    const [totalItems, setTotalItems] = useState(0);
    const [products, setProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [counts, setCounts] = useState({ total: 0, wait: 0, success: 0, refuse: 0 });
    const [rejectionReason, setRejectionReason] = useState('');

    useEffect(() => {
        // console.log('products : ', products);
    }, [products]);

    useEffect(() => {
        productList();
    }, []);

    useEffect(() => {
        // console.log("Rejection Reason: ", rejectionReason);
    }, [rejectionReason]);

    const paginate = pageNumber => setCurrentPage(pageNumber);

    const onInspectionSelect = (productNumber) => {
        navigate(`/admin/shop/product/${productNumber}`);
    }

    const productList = async () => {
        const response = await store.productAwaitingApproval();
        const data = response.data;
        setProducts(data);
        setCounts({
            total: data.length,
            wait: data.filter(product => product.enabledState === '승인대기').length,
            success: data.filter(product => product.enabledState === '승인완료').length,
            refuse: data.filter(product => product.enabledState === '승인거절').length
        });
    }

    const handleCheckboxChange = (productNo) => {
        setSelectedProducts(prev => {
            if (prev.includes(productNo)) {
                return prev.filter(item => item !== productNo);
            } else {
                return [...prev, productNo];
            }
        });
    }

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            const allProductNos = filteredProducts.map(item => item.productNo);
            setSelectedProducts(allProductNos);
        } else {
            setSelectedProducts([]);
        }
    }

    const onApproval = () => {
        if (selectedProducts.length === 0) {
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html: `
                <div>
                    <div class='${admin.admin_seller_add_title}'><span>선택된 상품이 없습니다.</span></div>
                    <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                    </div>
                </div>
                `,
                showConfirmButton: false,
                showCancelButton: false,
            });
            document.addEventListener('click', sellerAddConfirm);

            function sellerAddConfirm(e) {
                if (e.target && e.target.id === 'seller_add_confirm') {
                    MySwal.close();
                    document.removeEventListener('click', sellerAddConfirm);
                }
            }
            return;
        }

        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html: `
                <div>
                    <div class='${admin.admin_seller_add_title}'><span>총 ${selectedProducts.length}개의 선택한 상품을 승인하시겠습니까?</span></div>
                    <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                      <button class='mx-1' id='seller_add_cancel'>취소</button>
                      <button class='mx-1' id='seller_add_success'>승인</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });

        document.addEventListener('click', sellerApprovalSuccess);
        document.addEventListener('click', sellerApprovalCancel);

        function sellerApprovalSuccess(e) {
            if (e.target && e.target.id === 'seller_add_success') {
                onStateUpdate();
            }
        }

        function sellerApprovalCancel(e) {
            if (e.target && e.target.id === 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerApprovalCancel);
            }
        }

        const onStateUpdate = async () => {
            const response = await store.productEnabledStateListUpdate(selectedProducts);
            const data = response.data;

            if (data === 'SUCCESS') {
                MySwal.fire({
                    customClass: {
                        popup: `${admin.admin_seller_add_popup}`
                    },
                    html: `
                    <div>
                        <div class='${admin.admin_seller_add_title}'><span>승인이 완료 되었습니다.</span></div>
                        <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_confirm'>확인</button>
                        </div>
                    </div>
                    `,
                    showConfirmButton: false,
                    showCancelButton: false,
                });
                document.addEventListener('click', sellerAddConfirm);
                document.removeEventListener('click', sellerApprovalSuccess);

                function sellerAddConfirm(e) {
                    if (e.target && e.target.id === 'seller_add_confirm') {
                        productList();
                        MySwal.close();
                        document.removeEventListener('click', sellerAddConfirm);
                    }
                }
            }
        }
    }

    const onApprovalDenied = (no, productName) => {
        const showRejectReasonModal = () => {
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_approval_popup}`
                },
                html: '<div id="reject-reason-modal-container"></div>',
                showConfirmButton: false,
                showCancelButton: false,
                didOpen: () => {
                    const container = document.getElementById('reject-reason-modal-container');
                    const root = createRoot(container);
                    root.render(
                        <RejectReasonModal
                        productName={productName}
                        onCancel={() => MySwal.close()}
                        onConfirm={(reason) => {
                            // console.log('서버로 보낼 거절 사유:', reason); // 서버로 보내기 전에 값 확인
                            confirmRejectionReason(no, reason); // reason 값을 넘겨줌
                        }}
                    />
                    );
                },
                willClose: () => {
                    const container = document.getElementById('reject-reason-modal-container');
                    const root = createRoot(container);
                    root.unmount();
                }
            });
        };

        const showCancelConfirmationModal = () => {
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html: `
                <div>
                    <div class='${admin.admin_seller_approval_title}'><span>등록을 취소 하시겠습니까?</span></div>
                    <div class='${admin.admin_seller_approval_content}'><span>작성 중인 내용은 저장 되지 않습니다.</span></div>
                    <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                        <button class='mx-1' id='seller_approval_denied_cancel2'>닫기</button>
                        <button class='mx-1' id='seller_approval_denied_success2'>확인</button>
                    </div>
                </div>
                `,
                showConfirmButton: false,
                showCancelButton: false,
                didOpen: () => {
                    document.getElementById('seller_approval_denied_cancel2').addEventListener('click', showRejectReasonModal);
                    document.getElementById('seller_approval_denied_success2').addEventListener('click', () => MySwal.close());
                },
                willClose: () => {
                    document.getElementById('seller_approval_denied_cancel2').removeEventListener('click', showRejectReasonModal);
                    document.getElementById('seller_approval_denied_success2').removeEventListener('click', () => MySwal.close());
                }
            });
        };

        const confirmRejectionReason = async (no, reason) => {
            // console.log('서버로 보낼 거절 사유:', reason); // 서버로 전달하기 전에 값 확인
            if (!reason || reason.trim() === '') {
                alert('거절 사유를 입력해주세요.');
                return;
            }
        
            try {
                const response = await store.approvalDenied(no, reason);
                const data = response.data;
        
                if (data === 'SUCCESS') {
                    productList();
                    MySwal.fire({
                        customClass: {
                            popup: `${admin.admin_seller_add_popup}`
                        },
                        html: `
                        <div>
                            <div class='${admin.admin_seller_add_title}'><span>거절 사유 등록이 완료 되었습니다.</span></div>
                            <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                        </div>
                        `,
                        showConfirmButton: false,
                        showCancelButton: false,
                        didOpen: () => {
                            document.getElementById('seller_add_confirm').addEventListener('click', () => MySwal.close());
                        },
                        willClose: () => {
                            document.getElementById('seller_add_confirm').removeEventListener('click', () => MySwal.close());
                        }
                    });
                } else {
                    alert('거절 사유 등록에 실패했습니다.');
                }
            } catch (error) {
                console.error('Error occurred while rejecting approval:', error);
                alert('오류가 발생했습니다. 다시 시도해주세요.');
            }
        };
        
        

        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html: `
            <div>
                <div class='${admin.admin_seller_add_title}'><span>해당 상품의 승인을 거절 하시겠습니까?</span></div>
                <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                    <button class='mx-1' id='seller_approval_denied_cancel'>취소</button>
                    <button class='mx-1' id='seller_approval_denied_success'>거절</button>
                </div>
            </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
            didOpen: () => {
                document.getElementById('seller_approval_denied_cancel').addEventListener('click', () => MySwal.close());
                document.getElementById('seller_approval_denied_success').addEventListener('click', showRejectReasonModal);
            },
            willClose: () => {
                document.getElementById('seller_approval_denied_cancel').removeEventListener('click', () => MySwal.close());
                document.getElementById('seller_approval_denied_success').removeEventListener('click', showRejectReasonModal);
            }
        });
    };

    const onSingleApproval = (no) => {
        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html: `
                <div>
                    <div class='${admin.admin_seller_add_title}'><span>선택한 상품을 승인하시겠습니까?</span></div>
                    <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify_content_between'>
                      <button class='mx-1' id='seller_add_cancel'>취소</button>
                      <button class='mx-1' id='seller_add_success'>승인</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });
        document.addEventListener('click', sellerSingleApprovalSuccess);
        document.addEventListener('click', sellerSingleApprovalCancel);

        function sellerSingleApprovalSuccess(e) {
            if (e.target && e.target.id === 'seller_add_success') {
                onProductStateUpdate();
            }
        }
        function sellerSingleApprovalCancel(e) {
            if (e.target && e.target.id === 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerSingleApprovalSuccess);
                document.removeEventListener('click', sellerSingleApprovalCancel);
            }
        }

        const onProductStateUpdate = async () => {
            const response = await store.productEnabledStateUpdate(no);
            const data = response.data;

            if (data === 'SUCCESS') {
                MySwal.fire({
                    customClass: {
                        popup: `${admin.admin_seller_add_popup}`
                    },
                    html: `
                    <div>
                        <div class='${admin.admin_seller_add_title}'><span>승인이 완료 되었습니다.</span></div>
                        <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_confirm'>확인</button>
                        </div>
                    </div>
                    `,
                    showConfirmButton: false,
                    showCancelButton: false,
                });
                document.addEventListener('click', sellerAddConfirm);
                document.removeEventListener('click', sellerSingleApprovalSuccess);
                document.removeEventListener('click', sellerSingleApprovalCancel);

                function sellerAddConfirm(e) {
                    if (e.target && e.target.id === 'seller_add_confirm') {
                        productList();
                        MySwal.close();
                        document.removeEventListener('click', sellerAddConfirm);
                    }
                }
            }
        }
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return formatDistanceToNow(date, { addSuffix: true, locale: ko });
    };

    const filteredProducts = products.filter(product => {
        if (activeButton === 'wait') return product.enabledState === '승인대기';
        if (activeButton === 'success') return product.enabledState === '승인완료';
        if (activeButton === 'refuse') return product.enabledState === '승인거절';
        return true;
    });

    const onWaiting = () => {
        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_inspection_popup}`
            },
            html: `
                <div>
                    <div class='${admin.admin_seller_inspection_title}'><span>선택한 상품을 승인대기로 변경하시겠습니까?</span></div>
                    <div class='${admin.admin_seller_inspection_sub_title}'><span>승인거절 상품은 거절멘트가 제거됩니다.</span></div>
                    <div class='${admin.admin_seller_inspection_modal_btn_box} py-2 d-flex w-100 justify_content_between'>
                      <button class='mx-1' id='seller_add_cancel'>취소</button>
                      <button class='mx-1' id='seller_add_success'>승인대기로 되돌리기</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });
        document.addEventListener('click', sellerSingleApprovalSuccess);
        document.addEventListener('click', sellerSingleApprovalCancel);

        async function sellerSingleApprovalSuccess(e) {
            if (e.target && e.target.id === 'seller_add_success') {

                // console.log('selectedProducts : ', selectedProducts);
                const response = await store.sellerProductInsepectionWaiting(selectedProducts);
                const data = response.data;

                if (data === 'SUCCESS') {
                    MySwal.fire({
                        customClass: {
                            popup: `${admin.admin_seller_add_popup}`
                        },
                        html: `
                        <div>
                            <div class='${admin.admin_seller_add_title}'><span>승인대기로 변경 되었습니다.</span></div>
                            <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                        </div>
                        `,
                        showConfirmButton: false,
                        showCancelButton: false,
                    });
                    document.addEventListener('click', sellerAddConfirm);
                    document.removeEventListener('click', sellerSingleApprovalSuccess);
                    document.removeEventListener('click', sellerSingleApprovalCancel);

                    function sellerAddConfirm(e) {
                        if (e.target && e.target.id === 'seller_add_confirm') {
                            productList();
                            MySwal.close();
                            document.removeEventListener('click', sellerAddConfirm);
                        }
                    }
                } else {
                    MySwal.fire({
                        customClass: {
                            popup: `${admin.admin_seller_add_popup}`
                        },
                        html: `
                        <div>
                            <div class='${admin.admin_seller_add_title}'><span>승인대기 변경에 실패하였습니다.</span></div>
                            <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                        </div>
                        `,
                        showConfirmButton: false,
                        showCancelButton: false,
                    });
                    document.addEventListener('click', sellerAddConfirm);
                    document.removeEventListener('click', sellerSingleApprovalSuccess);
                    document.removeEventListener('click', sellerSingleApprovalCancel);

                    function sellerAddConfirm(e) {
                        if (e.target && e.target.id === 'seller_add_confirm') {
                            productList();
                            MySwal.close();
                            document.removeEventListener('click', sellerAddConfirm);
                        }
                    }
                }
            }
        }
        function sellerSingleApprovalCancel(e) {
            if (e.target && e.target.id === 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerSingleApprovalSuccess);
                document.removeEventListener('click', sellerSingleApprovalCancel);
            }
        }
    }

    return (
        <div className={`${admin.admin_seller_inspection_box} px-3 py-3`}>
            <div>
                <div className={`${admin.admin_seller_inspection_header} py-3`}><span>셀러 상품 검수</span></div>
                <div className={`${admin.admin_seller_inspection_tap} py-3`}>
                    <div className={`${activeButton === 'total' ? admin.admin_seller_inspection_active : ''}`}><button onClick={() => setActiveButton('total')}>전체({counts.total})</button></div>
                    <div className={`${activeButton === 'wait' ? admin.admin_seller_inspection_active : ''}`}><button onClick={() => setActiveButton('wait')}>승인 대기({counts.wait})</button></div>
                    <div className={`${activeButton === 'success' ? admin.admin_seller_inspection_active : ''}`}><button onClick={() => setActiveButton('success')}>승인 완료({counts.success})</button></div>
                    <div className={`${activeButton === 'refuse' ? admin.admin_seller_inspection_active : ''}`}><button onClick={() => setActiveButton('refuse')}>승인 거절({counts.refuse})</button></div>
                </div>
                <div className={`${admin.admin_seller_inspection_top} py-2`}>
                    <div className={`${admin.admin_seller_inspection_approval}`}>
                        <button onClick={() => onApproval()}>승인하기</button>
                        <button onClick={() => onWaiting()}>승인대기로 되돌리기</button>
                    </div>
                    <div className='d-flex'>
                        <button className={`${admin.admin_seller_inspection_download} me-1`}>
                            <div><span>내보내기</span></div>
                            <div><span className="material-symbols-outlined">download</span></div>
                        </button>
                        <div className={`${admin.admin_seller_inspection_input}`}><input type="text" /><button className={`${admin.admin_seller_inspection_search_btn}`}><span className="material-symbols-outlined">search</span></button></div>
                    </div>
                </div>
                <div className={`${admin.admin_seller_inspection_product_header} pt-3 pb-2`}>
                    <div><input type="checkbox" onChange={handleSelectAll} checked={selectedProducts.length === filteredProducts.length} /></div>
                    <div><span>번호</span></div>
                    <div><span>등록 상품 정보</span></div>
                    <div><span>판매가</span></div>
                    <div><span>카테고리</span></div>
                    <div><span>재고</span></div>
                    <div><span>등록일</span></div>
                    <div><span>수정일</span></div>
                    <div><span>등록 상태</span></div>
                </div>
                {
                    filteredProducts.map((item, index) => (
                        <div key={item.productNo}>
                            <div className={`${admin.admin_seller_inspection_product_content}`} onClick={() => onInspectionSelect(item.productNumber)}>
                                <div><input type="checkbox" onChange={() => handleCheckboxChange(item.productNo)} checked={selectedProducts.includes(item.productNo)} onClick={(e) => e.stopPropagation()} /></div>
                                <div><span>{index + 1}</span></div>
                                <div className={`${admin.admin_seller_inspection_product_info}`}>
                                    <div><img src={item.productMainImage} alt="상품이미지" /></div>
                                    <div>
                                        <div><span>{item.productName}</span></div>
                                        <div>
                                            {item.optionNameList.map(option => (
                                                <div key={option.optionNo}><span>{option.optionName} {item.optionValueList.filter(value => value.productNo === option.productNo).length}종</span></div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className={admin.admin_through_text}>
                                        <span>
                                            &#8361;
                                            {item.productPrice !== null && item.productPrice !== undefined 
                                            ? item.productPrice.toLocaleString() 
                                            : 'N/A'}
                                        </span>
                                        </div>
                                        <div>
                                        <span>
                                            &#8361;
                                            {item.productsalePrice !== null && item.productsalePrice !== undefined 
                                            ? item.productsalePrice.toLocaleString() 
                                            : 'N/A'}
                                        </span>
                                    </div>
                                </div>
                                <div className='text-start'><span>{item.mainCategory}&gt;{item.middleCategory}&gt;{item.subCategory}</span></div>
                                <div><span>{item.inventoryCnt}</span></div>
                                <div><span>{formatDate(item.regDate)}</span></div>
                                <div><span>{formatDate(item.updDate)}</span></div>
                                <div className={`${admin.admin_seller_inspection_btn_box}`}>
                                    <div><span>{item.enabledState}</span></div>
                                    <div><button onClick={(e) => { e.stopPropagation(); onSingleApproval(item.productNo) }}>승인하기</button></div>
                                    <div><button onClick={(e) => { e.stopPropagation(); onApprovalDenied(item.productNo, item.productName) }}>승인거절</button></div>
                                </div>
                            </div>
                        </div>
                    ))
                }
                <Pagination current={currentPage} total={totalItems} onChange={paginate} pageSize={itemsPerPage} />
            </div>
        </div>
    )
}

export default AdminSellerProductInspection;
