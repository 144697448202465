import React, { useEffect, useRef, useState } from 'react';
import admin from '../../../admin.module.css';
import { Button, DatePicker, Input, Select } from 'antd';
import { Option } from 'antd/es/mentions';
import { Link } from 'react-router-dom';
import * as auth from '../../../../apis/auth';
import { formatDistanceToNow } from 'date-fns';
import { ko } from 'date-fns/locale';
import { Pagination } from 'antd';


const AdminUserList = () => {
    const { Option } = Select;
    const { RangePicker } = DatePicker;
    const [ userList, setUserList ] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(14);
    const [totalItems, setTotalItems] = useState(0);
    const [totaluserCount, setTotalUserCount] = useState(0);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return formatDistanceToNow(date, { addSuffix: true, locale: ko });
    };

    // 페이지 번호 클릭 시 호출될 함수
    const paginate = pageNumber => setCurrentPage(pageNumber);

    useEffect(() => {
        userAll();
    }, [currentPage]);
    
    const userAll = async () => {
        const response = await auth.userAllSelect(currentPage, itemsPerPage);
        const data = response.data;

        setUserList(data.content);
        setTotalItems(data.totalElements);
        setTotalUserCount(data.totalElements);
    };
    
    const [selectDate, setSelectDate] = useState([]);


    const handleDateChange = (dates, dateStrings) => {
        // dates: Moment 객체 배열, 선택된 날짜 범위
        // dateStrings: 문자열 배열, 선택된 날짜 범위의 문자열 표현 ('YYYY-MM-DD' 형식)
        // console.log('선택된 날짜 범위:', dates);
        // console.log('선택된 날짜 범위 (문자열):', dateStrings);
        setSelectDate(dateStrings);
    };
    
    const onUserDelete = (event, userNo) => {
        event.stopPropagation(); // 이벤트 전파를 막음
        // console.log('userNo : ' + userNo);
    }

    const onUserSelect = (userId) => {
        // console.log(userId);
    }

    // 선택된 옵션의 값을 저장하기 위한 상태
    const [ selectedValue, setSelectedValue ] = useState('all');
    const [ searchValue, setSearchValue ] = useState('');

    // 옵션이 선택되었을 때 호출되는 함수
    const handleChange = (value) => {
        // console.log('선택된 값:', value); // 선택된 옵션의 값 확인
        setSelectedValue(value); // 상태 업데이트
    };
    const onSearchValue = (e) => {
        // console.log(e.target.value);
        setSearchValue(e.target.value);
    }

    const ref = useRef();

    useEffect(() => {
        const inputs = ref.current.querySelectorAll('.ant-picker-input input');
            inputs.forEach(input => {
            input.setAttribute('readonly', 'readonly');
        });
    }, []);

    const onSearchClick = async () => {
        // console.log('selectDate : ', selectDate);
        // console.log('selectedValue : ', selectedValue);
        // console.log('searchValue : ', searchValue);
        
        const formData = new FormData();

        formData.append('startDate', selectDate[0]);
        formData.append('endDate', selectDate[1]);
        formData.append('selectedValue', selectedValue);
        formData.append('searchValue', searchValue);

        for (let [key, value] of formData.entries()) {
            // console.log(key, value);
        }

        const config = {
            headers: {
              'content-type': 'multipart/form-data'
            }
        };

        const response = await auth.userSearchSelect(currentPage, itemsPerPage, formData, config);
        const data = response.data;

        // console.log(data);

        setUserList(data.content);
        setTotalItems(data.totalElements);
    }

    // 선택된 사용자의 userNo를 저장하는 배열
    const [selectedUserNos, setSelectedUserNos] = useState([]);
    // 전체 선택 상태
    const [isAllSelected, setIsAllSelected] = useState(false);

    // 전체 선택/해제 처리
    const handleSelectAll = (event) => {
        if (event.target.checked) {
        const allUserNos = userList.map(user => user.userNo);
        setSelectedUserNos(allUserNos);
        setIsAllSelected(true);
        } else {
        setSelectedUserNos([]);
        setIsAllSelected(false);
        }
    };

    // 개별 사용자 선택 처리
    const handleSelectUser = (userNo) => {
        if (selectedUserNos.includes(userNo)) {
        setSelectedUserNos(selectedUserNos.filter(no => no !== userNo));
        } else {
        setSelectedUserNos([...selectedUserNos, userNo]);
        }
    };
    


    return (
        <div className={`${admin.admin_userList_box} px-3 py-3`}>
            <div>
                <div className={`${admin.admin_userList_header}`}>
                    <div><h5>사용자 목록</h5></div>
                    <div className={`${admin.admin_userList_header_btn_box}`}>
                        <div><button>전체사용자 <span className={`${admin.admin_signature_color}`}>({totaluserCount})</span></button></div>
                        <div><button>그룹없음 <span className={`${admin.admin_signature_color}`}>({totaluserCount})</span></button></div>
                        <div><button>새그룹 만들기</button></div>
                    </div>
                </div>
                <div className={`${admin.admin_userList_search_box} py-3`}>
                    <div className={`${admin.admin_userList_search_input}`}>
                        <div>
                        <Select
                            className={`${admin.admin_userList_search_select}`}
                            onChange={handleChange}
                            defaultValue={selectedValue} // 기본 선택값 설정
                        >
                            <Option value="all">전체</Option> {/* '전체' 옵션 추가 */}
                            <Option value="name">이름</Option>
                            <Option value="nickname">닉네임</Option>
                            <Option value="phone">전화번호</Option>
                        </Select>
                        </div>
                        <div className={`ps-1 ${admin.admin_userList_search_input_txt}`}>
                        <input 
                            type="text" 
                            onChange={(e) => onSearchValue(e)}
                            onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                onSearchClick();
                            }
                            }} 
                        />
                        </div>
                        <div className={`ps-1 ${admin.admin_userList_search_btn}`}>
                            <button onClick={() => onSearchClick()}>검색</button>
                        </div>
                    </div>
                    <div className={`${admin.admin_userList_search_date}`}>
                        <div ref={ref}>
                            <RangePicker className={`${admin.admin_userList_datepicker}`}
                                separator="~"
                                placeholder={['0000.00.00', '0000.00.00']}
                                onChange={handleDateChange}
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <div className={`${admin.admin_userList_table_header} border-bottom py-3`}>
                        <div><input type="checkbox" checked={isAllSelected} onChange={handleSelectAll} /></div>
                        <div><span>이름</span></div>
                        <div><span>닉네임</span></div>
                        <div><span>아이디</span></div>
                        <div><span>이메일</span></div>
                        <div><span>글/댓글/구매평/문의</span></div>
                        <div><span>누적 구매금액</span></div>
                        <div><span>가입일</span></div>
                        <div><span>삭제</span></div>
                    </div>
                    {
                        Array.isArray(userList) && userList.map((item, index) => (
                            <div className={`${admin.admin_userList_table_section} py-2 border-bottom`} onClick={() => onUserSelect(item.userId)} key={index}>
                                <div><input type="checkbox" checked={selectedUserNos.includes(item.userNo)} onChange={() => handleSelectUser(item.userNo)} /></div>
                                <div><span>{item.userName}</span></div>
                                <div><span>{item.userNick}</span></div>
                                <div><span>{item.userId}</span></div>
                                <div><span>{item.userEmail}</span></div>
                                <div><span>0/0/0/0</span></div>
                                <div><span>0원</span></div>
                                <div><span>{formatDate(item.userRegDate)}</span></div>
                                <div><button className='border-0 bg-body' onClick={(event) => onUserDelete(event, item.userNo)}><span className="material-symbols-outlined">close</span></button></div>
                            </div>
                        ))
                    }
                    <Pagination current={currentPage} total={totalItems} onChange={paginate} pageSize={itemsPerPage} />
                </div>
            </div>
        </div>
    )
}

export default AdminUserList