// ErrorBoundary.js
import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error caught in Error Boundary: ", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
            <div>
                <span>페이지 로드 중 문제가 발생하였습니다.</span>
            </div>
            <div>
                <span>Chrome 브라우저를 사용하시는 경우 Google 자동 번역을 끄고 시도해주세요.</span>
            </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
