import { Link, useLocation } from 'react-router-dom';
import footer from './footer.module.css';
import { useContext } from 'react';
import { LoginContext } from '../../contexts/LoginContextProvider';

const DailyduckInNavigator = () => {
  const location = useLocation();
  const { roles } = useContext(LoginContext);

  const getImageSrc = (defaultImage, activeImage, path) => {
    return location.pathname.includes(path) ? activeImage : defaultImage;
  };
  
  const getTextClass = (defaultClass, activeClass, path) => {
    return location.pathname.includes(path) ? activeClass : defaultClass;
  };

  return (
    <div className='navigator text-center'>
      <div className={`d-flex justify-content-around ${footer.footer_navigator_box}`}>
        <div>
          <Link to="/market">
            <img src={getImageSrc("../img/icon/market.png", "../img/icon/market_pink.png", "store")} alt="Market" />
            <p className={getTextClass('', 'main_color2', 'store')}>응원마켓</p>
          </Link>
        </div>
        <div>
          <Link to="/event">
            <img src={getImageSrc("../img/icon/event.png", "../img/icon/event_pink.png", "/event")} alt="Album" />
            <p className={getTextClass('', 'main_color2', '/event')}>이벤트</p>
          </Link>
        </div>
        <div>
          <Link to="/search">
            <img src={getImageSrc("../img/icon/search.png", "../img/icon/search_pink.png", "/search")} alt="Search" />
            <p className={getTextClass('', 'main_color2', '/search')}>검색</p>
          </Link>
        </div>
        <div>
          <Link to="/message">
            <img src={getImageSrc("../img/icon/message.png", "../img/icon/message_pink.png", "/message")} alt="Message" />
            <p className={getTextClass('', 'main_color2', '/message')}>메세지</p>
          </Link>
        </div>
        <div>
          {
            (!roles || roles.isUser) &&
            <Link to="/my">
              <img src={getImageSrc("../img/icon/my.png", "../img/icon/my_pink.png", "/my")} alt="My" />
              <p className={getTextClass('', 'main_color2', '/my')}>내정보</p>
            </Link>
          }
          {
            roles && roles.isStore &&
            <Link to="/admin/dashboard">
              <img src={getImageSrc("../img/icon/my.png", "../img/icon/my_pink.png", "/admin/dashboard")} alt="My" />
              <p className={getTextClass('', 'main_color2', '/admin/dashboard')}>내상점</p>
            </Link>
          }
          {
            roles && roles.isSupport &&
            <Link to="/seller/supportdashboard">
              <img src={getImageSrc("../img/icon/my.png", "../img/icon/my_pink.png", "/seller/supportdashboard")} alt="My" />
              <p className={getTextClass('', 'main_color2', '/seller/supportdashboard')}>내상점</p>
            </Link>
          }
        </div>
      </div>
    </div>
  );
}

export default DailyduckInNavigator;
