import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import * as support from '../../apis/support';

const MessagePaymentSuccess = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [verificationResult, setVerificationResult] = useState(null);
    const [msgPaymentInfo, setMsgPaymentInfo] = useState({});

    useEffect(() => {
        // console.log('msgPaymentInfo : ', msgPaymentInfo);
    }, [msgPaymentInfo]);

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const paymentKey = query.get('paymentKey');
        const orderId = query.get('orderId');
        const amount = query.get('amount');
        const msg = JSON.parse(localStorage.getItem('msg'));



        // console.log('paymentKey : ', paymentKey);
        // console.log('orderId : ', orderId);
        // console.log('amount : ', amount);

        const verifyPayment = async () => {
            try {
                const response = await axios.post('/api/payment/verify', {
                    paymentKey: paymentKey,
                    orderId: orderId,
                    amount: amount
                });
                setVerificationResult(response.data);
                // console.log('response.data : ', response.data);

                const updatedMsgPaymentInfo = {
                    ...msg,
                    paymentKey: paymentKey,
                    orderId: orderId,
                    amount: amount,
                    paymentType : response.data.method
                };
        
                setMsgPaymentInfo(updatedMsgPaymentInfo);

                if (response.data.status === "DONE") {
                    try {
                        const response = await fetch('/api/payment', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                paymentKey: paymentKey,
                                amount: msg.paymentPrice
                            }),
                        });

                        const result = await response.json();
                        // console.log('result : ', result);

                        if (result.success) {
                            setMsgPaymentInfo((prevState) => {
                                const newState = { ...prevState, ...result };
                                msgPaymentAdd(newState);
                                return newState;
                            });
                        } else {
                            // 서버 검증 실패 시 로직
                            alert(`결제 검증에 실패하였습니다: ${result.error_msg}`);
                        }
                    } catch (error) {
                        console.error('error : ', error);
                    }
                } else {
                    // 결제 실패 처리
                    navigate(`/message/payment/fail`);
                }
            } catch (error) {
                console.error('결제 검증 오류:', error);
                navigate(`/massage/payment/fail`);
            } finally {
                setLoading(false);
            }
        };

        verifyPayment();
    }, [location, navigate]);

    const msgPaymentAdd = async (paymentInfo) => {
        // console.log('msgPaymentInfo : ', paymentInfo);

        // 서버 검증 성공 시 로직
        try {
            const response = await support.messagePayment(paymentInfo, paymentInfo.orderId, paymentInfo.paymentKey, paymentInfo.amount, paymentInfo.paymentNumber, paymentInfo.point, paymentInfo.coupon, paymentInfo.paymentType);
            const data = response.data;
            // console.log(data);

            const payment = paymentInfo.paymentNumber;
            // console.log('paymentNumber = ', payment);

            navigate(`/message/payment/success/${payment}`);
        } catch (error) {
            console.error('Error storing payment info:', error);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            {verificationResult ? (
                <div>결제가 성공적으로 완료되었습니다!</div>
            ) : (
                <div>결제 검증에 실패하였습니다.</div>
            )}
        </div>
    );
};

export default MessagePaymentSuccess;
