import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import event from '../../../containers/event/event.module.css';

const StoreMore = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const scrollRef = useRef(null);

    const [storeProduct, setStoreProduct] = useState(location?.state.storeProduct.slice(0, 6) || []);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(location?.state.storeProduct.length > 6);
    const [columnClass, setColumnClass] = useState('col-6'); // 초기값 설정

    useEffect(() => {
      const updateColumnClass = () => {
        if (window.innerWidth > 500) {
          setColumnClass('col-4'); // 화면이 500px 이상일 때
        } else {
          setColumnClass('col-6'); // 화면이 500px 이하일 때
        }
      };
  
      updateColumnClass();
      window.addEventListener('resize', updateColumnClass);
  
      return () => {
        window.removeEventListener('resize', updateColumnClass);
      };
    }, []);

    useEffect(() => {
        // console.log('storeProduct : ', storeProduct);
    }, [storeProduct]);

    useEffect(() => {
        // console.log('useEffect called');

        const handleScroll = () => {
            if (scrollRef.current) {
                const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
                // console.log('scrollTop:', scrollTop);
                // console.log('scrollHeight:', scrollHeight);
                // console.log('clientHeight:', clientHeight);

                if (scrollTop + clientHeight >= scrollHeight - 5 && hasMore) {
                    // console.log('End of div reached');
                    loadMoreProducts();
                }
            }
        };

        const currentScrollRef = scrollRef.current;
        if (currentScrollRef) {
            currentScrollRef.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (currentScrollRef) {
                currentScrollRef.removeEventListener('scroll', handleScroll);
            }
        };
    }, [scrollRef.current, hasMore]);

    const loadMoreProducts = () => {
        if (!hasMore) return;

        // console.log('Loading more products');
        const startIndex = page * 6;
        const endIndex = (page + 1) * 6;
        const moreProducts = location?.state.storeProduct.slice(startIndex, endIndex);

        if (moreProducts.length > 0) {
            setStoreProduct(prevProducts => [...prevProducts, ...moreProducts]);
            setPage(prevPage => prevPage + 1);
        }

        if (endIndex >= location?.state.storeProduct.length) {
            setHasMore(false);
        }
    };

    const onBack = () => {
        navigate(-1);
    };

    const onStoreProduct = (item) => {
        // console.log('item.product_no : ', item.product_no);
        navigate(`/store/product/${item.product_no}`);
    };

    return (
        <div>
            <div className={`${event.event_store_more_header}`}>
                <div>
                    <button onClick={() => onBack()}>
                        <span className="material-symbols-outlined">arrow_back_ios</span>
                    </button>
                </div>
                <div>스토어 검색 상품</div>
            </div>
            <div className={`${event.event_store_more_content}`} ref={scrollRef}>
                <div className='px-3'>
                    <div className='row'>
                        {storeProduct.map((item, index) => (
                            <div className={`${columnClass} search_content_store_item`} key={index}>
                                <div onClick={() => onStoreProduct(item)}>
                                    <div><img src={item.product_main_image} alt="" /></div>
                                    <div><span>{item.shop_name}</span></div>
                                    <div><span>{item.product_name}</span></div>
                                    <div>
                                        <div><span>{item.discount_rate}%</span></div>
                                        <div><span>{item.productsale_price.toLocaleString()}원</span></div>
                                        <div><span>{item.product_price.toLocaleString()}원</span></div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    {!hasMore && <div className={`${event.event_store_more_last_txt}`}><span>검색된 상품의 마지막입니다.</span></div>}
                </div>
            </div>
        </div>
    );
};

export default StoreMore;
