import React, { useState } from 'react';
import admin from '../../../admin.module.css';
import { Button, DatePicker, Input, Select } from 'antd';
import { Option } from 'antd/es/mentions';

const AdminDormentUser = () => {

    const [ searchValue, setSearchValue ] = useState('');
    const onSearchValue = (e) => {
        // console.log(e.target.value);
        setSearchValue(e.target.value);
    }

    const onSearchClick = () => {

    }

  return (
    <div className={`${admin.admin_dorment_box} px-3 py-3`}>
        <div>
            <div className={`${admin.admin_dorment_header}`}>
                <div className='pt-4'><h5>휴면 사용자</h5></div>
            </div>
            <div className={`${admin.admin_userList_search_box} py-3`}>
                <div className={`${admin.admin_userList_search_input}`}>
                    <div className={`ps-1 ${admin.admin_userList_search_input_txt}`}>
                        <input 
                            type="text" 
                            onChange={(e) => onSearchValue(e)}
                            onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                onSearchClick();
                            }
                            }} 
                        />
                    </div>
                    <div className={`ps-1 ${admin.admin_userList_search_btn}`}>
                        <button onClick={() => onSearchClick()}>검색</button>
                    </div>
                </div>
            </div>
            <div>
                <div className={`${admin.admin_userList_table_header} border-bottom py-3`}>
                    <div><input type="checkbox" name="" id="" /></div>
                    <div><span>이름</span></div>
                    <div><span>닉네임</span></div>
                    <div><span>아이디</span></div>
                    <div><span>이메일</span></div>
                    <div><span>글/댓글/구매평/문의</span></div>
                    <div><span>누적 구매금액</span></div>
                    <div><span>가입일</span></div>
                    <div><span>삭제</span></div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AdminDormentUser