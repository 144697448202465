import React, { useEffect, useState } from 'react';
import admin from '../../../admin.module.css';
import * as adminApi from '../../../../apis/admin';

const AdminPointProbability = () => {

    const [minPoint, setMinPoint] = useState(0);
    const [maxPoint, setMaxPoint] = useState(0);
    const [divisionNumber, setDivisionNumber] = useState(0);
    const [pointRateList, setPointRateList] = useState([]);

    useEffect(() => {
        pointRateSelect();
    }, []);

    const pointRateAdd = async () => {
        // console.log('minPoint : ', minPoint);
        // console.log('maxPoint : ', maxPoint);
        // console.log('divisionNumber : ', divisionNumber);

        try {
            const response = await adminApi.pointRateAdd(minPoint, maxPoint, divisionNumber);
            const data = response.data;
    
            // console.log('pointRateAdd : ', data);
    
            if (data === 'SUCCESS') {
                alert('입력한 포인트와 확률이 적용되었습니다.');
                pointRateSelect();
            } else {
                alert('입력한 포인트와 확률 적용에 실패했습니다.');
            }
        } catch (error) {
            console.error(error);
        }
    };

    const pointRateSelect = async () => {
        const response = await adminApi.pointRateSelect();
        const data = response.data;

        // console.log('pointRateSelect : ', data);
        setPointRateList(data);
    };

    const handleInputChange = (index, field, value) => {
        const updatedList = [...pointRateList];
        updatedList[index][field] = value;
        setPointRateList(updatedList);
        // console.log('Updated pointRateList:', updatedList);
    };

    const handlePointRateUpdate = async () => {
        const response = await adminApi.pointRateUpdate(pointRateList);
        const data = response.data;

        // console.log('handlePointRateUpdate : ', data);

        if(data === 'SUCCESS') {
            alert('포인트 확률 수정이 완료되었습니다.');
            pointRateSelect();
        }
    }

    return (
        <div className='px-3 py-3'>
            <div>
                <div className={admin.admin_point_rate_apply_title}><span>포인트 확률 조정</span></div>
                <div className={admin.admin_point_rate_apply_box}>
                    <div className={admin.admin_point_rate_apply_min_point}>
                        <div><span>최소포인트</span></div>
                        <div>
                            <div>
                                <input type="number" name="" id="" onChange={(e) => setMinPoint(e.target.value)} />
                                <span>P</span>
                            </div>
                        </div>
                    </div>
                    <div className={admin.admin_point_rate_apply_max_point}>
                        <div><span>최대포인트</span></div>
                        <div>
                            <div>
                                <input type="number" name="" id="" onChange={(e) => setMaxPoint(e.target.value)} />
                                <span>P</span>
                            </div>
                        </div>
                    </div>
                    <div className={admin.admin_point_rate_apply_division}>
                        <div><span>나눌값</span></div>
                        <div>
                            <div>
                                <input type="number" name="" id="" onChange={(e) => setDivisionNumber(e.target.value)} />
                                <span>P</span>
                            </div>
                        </div>
                        <div><span>* 짝수값이 가장 정확합니다. (5, 10, 15, 20)</span></div>
                    </div>
                    <div className={admin.admin_point_rate_apply_btn}><button onClick={pointRateAdd}>적용</button></div>
                </div>
                <div className={admin.admin_point_rate_apply_content_update}>
                    <div><button onClick={handlePointRateUpdate}>수정</button></div>
                </div>
                <div className={admin.admin_point_rate_apply_container}>
                    <div className={admin.admin_point_rate_apply_header}>
                        <div><span>최소적용포인트</span></div>
                        <div><span>최대적용포인트</span></div>
                        <div><span>범위</span></div>
                        <div><span>당첨확률</span></div>
                    </div>
                    {
                        Array.isArray(pointRateList) && pointRateList.map((item, index) => (
                            <div className={admin.admin_point_rate_apply_content} key={index}>
                                <div><span>{item.minPoint}</span></div>
                                <div><span>{item.maxPoint}</span></div>
                                <div>
                                    <div>
                                        <input
                                            type="number"
                                            value={item.minNumber ? item.minNumber : 0}
                                            onChange={(e) => handleInputChange(index, 'minNumber', parseInt(e.target.value))}
                                        />
                                        <span>P</span>
                                    </div>
                                    <div><span>~</span></div>
                                    <div>
                                        <input
                                            type="number"
                                            value={item.maxNumber ? item.maxNumber : 0}
                                            onChange={(e) => handleInputChange(index, 'maxNumber', parseInt(e.target.value))}
                                        />
                                        <span>P</span>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <input
                                            type="number"
                                            value={item.rate ? item.rate : 0.0}
                                            onChange={(e) => handleInputChange(index, 'rate', parseFloat(e.target.value))}
                                        />
                                        <span>%</span>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default AdminPointProbability;
