import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as support from '../../apis/support';
import ss from '../../containers/market/support/venue/support_shop.module.css';

const MessagePaymentComplete = () => {
    const { paymentNumber } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const [ paymentInfo, setPaymentInfo ] = useState({});
    const [ roomId, setRoomId ] = useState('');

    useEffect(() => {
        // console.log('paymentNumber : ', paymentNumber);

        const msg = JSON.parse(localStorage.getItem('msg'));

        const searchParams = new URLSearchParams(location.search);
        const imp_uid = searchParams.get('imp_uid');
        const merchant_uid = searchParams.get('merchant_uid');
        const amount = searchParams.get('amount');
        const point = searchParams.get('point');
        const couponDiscount = searchParams.get('couponDiscount');
        const activeButton = searchParams.get('activeButton');

        // console.log('searchParams : ',searchParams);
        // console.log('imp_uid : ',imp_uid);
        // console.log('merchant_uid : ',merchant_uid);
        // console.log('amount : ',amount);
        // console.log('point : ',point);
        // console.log('couponDiscount : ',couponDiscount);
        // console.log('activeButton : ',activeButton);
        // console.log('msg : ',msg);

        if (imp_uid && merchant_uid ) {
            postPaymentResultToServer(imp_uid, merchant_uid, amount, point, couponDiscount, activeButton, msg);
        }


    }, [paymentNumber]);

    const postPaymentResultToServer = async (imp_uid, merchant_uid, amount, point, couponDiscount, activeButton, msg) => {
        try {
            const response = await fetch('/api/payment', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    imp_uid,
                    merchant_uid,
                    amount
                }),
            });
    
            const result = await response.json();
    
            // console.log('result : ', result);
            if (result.success) {
                // console.log(imp_uid);
                // console.log(merchant_uid);
                // console.log('activeButton : ', activeButton);
                // console.log('resultState : ', result.status);
                // console.log('resultVbankName : ', result.vbank_name);
                // console.log('resultVbankNum : ', result.vbank_num);
                
                const payment = paymentNumber;
                // console.log('paymentNumber = ', payment);

                // 서버 검증 성공 시 로직
                const response = await support.messagePayment(msg, imp_uid, merchant_uid, amount, paymentNumber, point, couponDiscount, activeButton, result.vbank_name, result.vbank_num, result.vbank_date, result.status);
                const data = response.data;
    
                // console.log(data);
    
    
    
                
                // 가상계좌 결제 정보 사용자에게 알림
                if (result.status === 'ready') {
                    alert(`입금 확인 후 결제 완료처리 됩니다.`);
                } else {
                    alert('결제가 성공적으로 완료되었습니다.');
                    // navigate(`/payment/success/${paymentNumber}`);
                }

                messagePaymentSelect();
            } else {
                // 서버 검증 실패 시 로직
                alert(`결제 검증에 실패하였습니다: ${result.error_msg}`);
            }
        } catch (error) {
            console.error('결제 검증 중 오류 발생', error);
            alert('결제 검증 중 오류가 발생했습니다.');
        }
    };

    useEffect(() => {
        messagePaymentSelect();
    }, []);

    const messagePaymentSelect = async () => {
        const response = await support.messagePaymentSelect(paymentNumber);
        const data = response.data;

        // console.log(data);
        setPaymentInfo(data);
        setRoomId(data.roomId);
    }

    const chat = () => {
        navigate(`/message/chat/${roomId}`);
    }
    const mainBtn = () => {
        navigate('/market');
    }

    const vBankDate = (depositDeadline) => {
        const vbankDate = new Date(depositDeadline * 1000).toLocaleString();

        return vbankDate;
    }

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            alert("가상계좌번호가 클립보드에 복사되었습니다.");
        }).catch((err) => {
            console.error("클립보드 복사 실패: ", err);
        });
    }

    return (
        <div>
            <div>
                <div className={`${ss.reserve_payment_success_header}`}>
                    <button onClick={() => mainBtn()}><span className="material-symbols-outlined">close</span></button>
                    <button><img src="../../img/logo_black.png" alt="" /></button>
                </div>
                <div className={`${ss.message_payment_success_content}`}>
                    <div className={`${ss.message_payment_success_content_header}`}>
                        <div><img src='../../../img/payment_success.png' alt="" /></div>
                        <div className='pt-1'><span>{paymentInfo.paymentType === '가상계좌' ? '입금대기' : '결제완료'}</span></div>
                    </div>
                    <div className={`${ss.message_payment_success_content_box}`}>
                        <div className={`${ss.message_payment_success_content_style}`}>
                            <div><span>결제번호</span></div>
                            <div><span>{paymentInfo.paymentNumber}</span></div>
                        </div>
                        <div className={`${ss.message_payment_success_content_style}`}>
                            <div><span>결제상품</span></div>
                            <div><span>{paymentInfo.paymentName}</span></div>
                        </div>
                        {
                        paymentInfo.paymentType === 'vbank' ?
                        <div className={`${ss.message_payment_success_content_vbank_box}`}>
                            <div><span>가상계좌정보</span></div>
                            <div className={`${ss.message_payment_success_content_style}`}>
                                <div><span>은행명</span></div>
                                <div><span>{paymentInfo.bankName}</span></div>
                            </div>
                            <div className={`${ss.message_payment_success_content_style}`}>
                                <div><span>가상계좌번호</span></div>
                                <div className={`${ss.message_payment_account}`}>
                                    <button onClick={() => copyToClipboard(paymentInfo.accountNumber)}>
                                        {paymentInfo.accountNumber}
                                    </button>
                                </div>
                            </div>
                            <div className={`${ss.message_payment_success_content_style}`}>
                                <div><span>입금금액</span></div>
                                <div><span>{paymentInfo.amount.toLocaleString()}원</span></div>
                            </div>
                            <div className={`${ss.message_payment_success_content_style}`}>
                                <div><span>입금기한</span></div>
                                <div><span>{vBankDate(paymentInfo.depositDeadline)}</span></div>
                            </div>
                        </div>
                        :
                        <div className={`${ss.message_payment_success_content_style}`}>
                            <div><span>결제금액</span></div>
                            <div><span>{paymentInfo?.amount?.toLocaleString()}원</span></div>
                        </div>
                    }
                    </div>
                    
                    <div className={`${ss.message_payment_btn}`}>
                        <div><button onClick={() => mainBtn()}>홈으로</button></div>
                        <div><button onClick={() => chat()}>대화중인 채팅으로 이동</button></div>
                    </div>
                </div>
                <div className='px-3 py-3'>
                    <div><span>추천상품</span></div>
                </div>
                <div>
                    <div className='row'>
                        <div className={`col-6`}>
                            <div className='px-2 py-2'>
                                <div className={`${ss.reserve_payment_suggest}`}>
                                    <div className={`${ss.reserve_payment_suggest_box}`}>
                                        <div>
                                            <img src="../../img/support/review_img1.png" alt="이미지" />
                                        </div>
                                        <div><span>상점명</span></div>
                                        <div><span>[1+1 특가] 상품이름 상품이름 상품이름 상품이름상품이름상품이름상품이름</span></div>
                                        <div>
                                            <div><span>3,000</span></div>
                                            <div className='ps-2'><span>4,000</span></div>
                                        </div>
                                        <div className='py-1'>
                                            <div className='star_iconImg'>
                                                <div>
                                                    <img src='../../img/icon/star_fill.png' alt='별점' />
                                                </div>
                                                <div>
                                                    <span className='star_grade'>4.5(2)</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div><span>무료배송</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MessagePaymentComplete
