import React from 'react'
import MessagePaymentHeader from '../../components/message/MessagePaymentHeader'
import MessagePaymentContainer from '../../components/message/MessagePaymentContainer'

const MessagePaymentScreen = () => {
  return (
    <>
      <div className='payment'>
        <MessagePaymentHeader />
        <MessagePaymentContainer />
      </div>
    </>
  )
}

export default MessagePaymentScreen