import React, { useEffect, useState } from 'react';
import FavorityDday from './FavorityDday';
import RecomMender from './RecomMender';
import FavorityAdd from './FavorityAdd';
import * as auth from '../../apis/auth';

const JoinFavority = ({ handleJoinFavBack, handleJoinFavClose, joinTransitions3, 
                        showJoinFavority, animated, useTransition, setShowAgree, 
                        setShowJoin, setShowJoinInfo, setShowJoinFavority,
                        userList, handleFavorityDdayClick, handleSkipClick, handleFavorityAddClick, handleFavDdayBack,
                        selectedItems, setSelectedItems
                      }) => {
  const [searchTerm, setSearchTerm] = useState(''); // 검색어를 저장하는 state
  const [columnClass, setColumnClass] = useState('col-6'); // 초기값 설정
  const [itemsFromDatabase, setItemsFromDatabase] = useState([]);

  useEffect(() => {
    artistSelect();
  }, []);

  useEffect(() => {
    const updateColumnClass = () => {
      if (window.innerWidth > 500) {
        setColumnClass('favority_col_4'); // 화면이 500px 이상일 때
      } else {
        setColumnClass('favotiry_col_6'); // 화면이 500px 이하일 때
      }
    };

    updateColumnClass();
    window.addEventListener('resize', updateColumnClass);

    return () => {
      window.removeEventListener('resize', updateColumnClass);
    };
  }, []);

  useEffect(() => {
    // console.log(selectedItems);
  }, [selectedItems]);

  const artistSelect = async () => {
    const response = await auth.artistSelect();
    const data = response.data;

    // console.log(data);
    setItemsFromDatabase(data);
  }

  const favoritySearch = (event) => {
    setSearchTerm(event.target.value);
  };





  const handleItemClick = (item) => {
    const isSelected = selectedItems.find((selectedItem) => selectedItem.artistNo === item.artistNo);
  
    if (isSelected) {
      setSelectedItems(selectedItems.filter((selectedItem) => selectedItem.artistNo !== item.artistNo));
    } else {
      setSelectedItems([
        ...selectedItems,
        {
          artistNo: item.artistNo,
          name: item.artistName,
          imgUrl: item.artistImg,
          dday: '', // dday 초기값 설정
        },
      ]);
    }
  };



  // 데이터베이스에서 가져온 값들
  // const itemsFromDatabase = [
  //   { id: 1, name: '혜린', imageSrc: '../img/join/favority/1.png' },
  //   { id: 2, name: '제니', imageSrc: '../img/join/favority/2.png' },
  //   { id: 3, name: '사나', imageSrc: '../img/join/favority/3.png' },
  //   { id: 4, name: '마크', imageSrc: '../img/join/favority/4.png' },
  //   { id: 5, name: '카리나', imageSrc: '../img/join/favority/5.png' },
  //   { id: 6, name: '뷔', imageSrc: '../img/join/favority/6.png' },
  //   { id: 7, name: '지수', imageSrc: '../img/join/favority/7.png' },
  //   { id: 8, name: '지민', imageSrc: '../img/join/favority/8.png' },
  //   { id: 9, name: '지코', imageSrc: '../img/join/favority/9.png' },
  //   { id: 10, name: '지드래곤', imageSrc: '../img/join/favority/10.png' },
  // ];

  // 검색어에 따라 필터된 아이템 목록
  const filteredItems = itemsFromDatabase.filter((item) =>
    item.artistName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.artistGroup.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.artistAgency.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.engName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.fandom.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.labels.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.realName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.nickName.toLowerCase().includes(searchTerm.toLowerCase()) 
  );

  return (
    <>
      {showJoinFavority && (
        <div className='join_favority_container'>
          {joinTransitions3((style, item) =>
            item ? (
              <animated.div className='join_favority_detail_box' style={style}>
                <div className='join_favority_detail_header'>
                  <div>
                    <button onClick={() => handleJoinFavBack()}>
                      <span className='material-symbols-outlined'>arrow_back_ios</span>
                    </button>
                  </div>
                  <div>
                    <span>최애 선택</span>
                  </div>
                  <div>
                    <button onClick={() => handleJoinFavClose()}>
                      <span className='material-symbols-outlined'>close</span>
                    </button>
                  </div>
                </div>
                <div className='join_favority_search_box'>
                  <span className="material-symbols-outlined">search</span>
                  <input type='text' placeholder='검색' onChange={favoritySearch} />
                </div>
                <div className='join_favority_list'>
                {filteredItems.map((item) => (
                  <div
                    key={item.artistNo}
                    className={`${columnClass} favority_list_detail ${selectedItems.some(selectedItem => selectedItem.artistNo === item.artistNo) ? 'list_select' : ''}`}
                    onClick={() => handleItemClick(item)}
                  >
                    <div>
                      <img src={`${process.env.REACT_APP_API_SERVER}/img?file=${item.artistImg}`} alt={item.artistName} />
                    </div>
                    <div className='pt-3 pb-3'>
                      <span>{item.artistName}</span>
                    </div>
                  </div>
                ))}
                </div>
                <div className='favority_btn_box'>
                  {selectedItems.length > 0 ? 
                    <div>
                      <div className='text-center pb-3 favority_select_span'>
                        <span>선택됨 ({selectedItems.length})</span>
                      </div>
                      <div>
                        <button className='next_btn' onClick={handleFavorityDdayClick} >다음</button>
                      </div>
                    </div>
                    : 
                    <div className='skip_add_btnBox text-center'>
                        <div><span>찾으시는 최애가 없으신가요?</span></div>
                        <div><span>최애추가를 신청해주시면 빠르게 등록해드리겠습니다.</span></div>
                        <div><button className='cheae_add_btn' onClick={handleFavorityAddClick}>최애추가 요청</button></div>
                        <div><button className='skip' onClick={handleSkipClick}>건너뛰기</button></div>
                    </div>
                  }
                </div>
              </animated.div>
            ) : null
          )}
        </div>
      )}
    </>
  );
};

export default JoinFavority;
