import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const VenueHeader = ({ category, ss, showDate, handleShare }) => {

    const navigate = useNavigate();
    const backNavigate = () => {
        navigate(-1);
    };

    return (
        <div className={`${ss.venue_header}`} style={showDate ? { zIndex: 100 } : {}}>
            <div>
                <button className='venue_header_btn' onClick={() => backNavigate()}>
                    <span className="material-symbols-outlined">arrow_back_ios</span>
                </button>
            </div>
            <div>
                <div>
                    <button className='' onClick={handleShare}>
                        <span className="material-symbols-outlined">share</span>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default VenueHeader;
