import React from 'react'
import PaymentHeader from '../../../components/market/support/payment/PaymentHeader'
import PaymentContainer from '../../../components/market/support/payment/PaymentContainer'

const PaymentScreen = () => {
  return (
    <>
      <div className='payment'>
        <PaymentHeader />
        <PaymentContainer />
      </div>
    </>
  )
}

export default PaymentScreen