import React, { useContext, useEffect, useState } from 'react';
import SellerHeader from '../seller/components/header/SellerHeader';
import seller from '../seller/seller.module.css';
import { CSSTransition } from 'react-transition-group';
import SupportSellerSide from '../seller/components/side/SupportSellerSide';
import { LoginContext } from '../contexts/LoginContextProvider';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Cookies from 'js-cookie';

function SellerLayout({ children }) {

    const { isLogin, sellerlogout, userInfo, loginCheck, roles } = useContext(LoginContext);

    const MySwal = withReactContent(Swal);
    const navigate = useNavigate();
    const [showMenu, setShowMenu] = useState(window.innerWidth >= 820);
    const [ activeMenu, setActiveMenu ] = useState(localStorage.getItem('activeMenu') || 'dashboard');
    const [ activeBoard, setActiveBoard ] = useState(localStorage.getItem('activeBoard') || 'board');
    const [ activeSettlement, setActiveSettlement ] = useState(localStorage.getItem('activeSettlement') || 'proceeds');
    const [ activeShopManagement, setActiveShopManagement] = useState(localStorage.getItem('activeShopManagement') || 'profile');
    const [ activeSalesManagement, setActiveSalesManagement] = useState(localStorage.getItem('activeSalesManagement') || 'faqs');

    const [openStates, setOpenStates] = useState(false);
    const [openSettlementStates, setOpenSettlementStates] = useState(false);
    const [openShopState, setOpenShopState] = useState(false);
    const [openSalesState, setOpenSalesState] = useState(false);

    const loginConfirm = useNavigate();

    useEffect(() => {

        const accessToken = Cookies.get("accessToken");

        // accessToken (jwt) 이 없음
        if( !accessToken ) {
            MySwal.fire({
                customClass: {
                    popup: `${seller.seller_date_save_popup}`
                },
                html : 
                `
                    <div>
                        <div class='${seller.seller_date_save_title}'><span>접근 제한</span></div>
                        <div class='${seller.seller_date_save_content}'><span>로그인 후 사용해주세요.</span></div>
                        <div class='${seller.seller_date_save_btn} py-2'><button id='seller_sales_save'>확인</button></div>
                    </div>
                `,
                showConfirmButton: false,
                showCancelButton: false,
            });
          
            document.addEventListener('click', function(e) {
                if(e.target && e.target.id === 'seller_sales_save') {
                    MySwal.close();
                }
            })
            
            loginConfirm('/seller');

            return
        } else {
            if(roles.isUser || roles.isStore) {
                MySwal.fire({
                    customClass: {
                        popup: `${seller.seller_date_save_popup}`
                    },
                    html : 
                    `
                        <div>
                            <div class='${seller.seller_date_save_title}'><span>접근 제한</span></div>
                            <div class='${seller.seller_date_save_content}'><span>서포트 셀러 등록 후 이용해 주세요.</span></div>
                            <div class='${seller.seller_date_save_btn} py-2'><button id='seller_sales_save'>확인</button></div>
                        </div>
                    `,
                    showConfirmButton: false,
                    showCancelButton: false,
                });
              
                document.addEventListener('click', function(e) {
                    if(e.target && e.target.id === 'seller_sales_save') {
                        MySwal.close();
                    }
                })
                
                loginConfirm('/seller');
            }
        }

    }, [userInfo]);

    const onMenuClick = () => {
        setShowMenu(!showMenu);
    }
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 820) {
                setShowMenu(true);
            } else {
                setShowMenu(false);
            }
        };

        // 컴포넌트 마운트 시에 이벤트 리스너 등록
        window.addEventListener('resize', handleResize);

        // 컴포넌트 언마운트 시에 이벤트 리스너 제거
        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        localStorage.setItem('activeMenu', activeMenu);
        localStorage.setItem('activeBoard', activeBoard);
        localStorage.setItem('activeSettlement', activeSettlement);
        localStorage.setItem('activeShopManagement', activeShopManagement);
        localStorage.setItem('activeSalesManagement', activeSalesManagement);
    }, [activeMenu, activeBoard, activeSettlement, activeShopManagement, activeSalesManagement]);


    const toggleOpen = () => {
        setOpenSettlementStates(false);
        setOpenShopState(false);
        setOpenSalesState(false);
        setOpenStates(!openStates);
    };
    const toggleSettlementOpen = () => {
        setOpenStates(false);
        setOpenShopState(false);
        setOpenSalesState(false);
        setOpenSettlementStates(!openSettlementStates);
    };
    const toggleShopOpen = () => {
        setOpenSettlementStates(false);
        setOpenStates(false);
        setOpenSalesState(false);
        setOpenShopState(!openShopState);
    };
    const toggleSalesOpen = () => {
        setOpenShopState(false);
        setOpenSettlementStates(false);
        setOpenStates(false);
        setOpenSalesState(!openSalesState);

    };

    // 메뉴 버튼을 클릭했을 때 호출될 함수
    const handleMenuClick = (menuName) => {
        setActiveMenu(menuName);

        if(menuName === 'dashboard') {
            navigate('/seller/supportdashboard');
            setActiveBoard('')
            setActiveSettlement('');
            setActiveShopManagement('');
            setActiveSalesManagement('');
            setOpenStates(false);
            setOpenSettlementStates(false);
            setOpenShopState(false);
            setOpenSalesState(false);
        }
        
        if(menuName === 'review') {
            navigate('/seller/review');
            setActiveBoard('')
            setActiveSettlement('');
            setActiveShopManagement('');
            setActiveSalesManagement('');
            setOpenStates(false);
            setOpenSettlementStates(false);
            setOpenShopState(false);
            setOpenSalesState(false);

        }

        if(menuName === 'board') {
            navigate('/seller/board');
            setActiveBoard('board');
            setActiveSettlement('');
            setActiveShopManagement('');
            setActiveSalesManagement('');
        }

        if(menuName === 'settlement') {
            navigate('/seller/proceeds');
            setActiveSettlement('proceeds');
            setActiveBoard('');
            setActiveShopManagement('');
            setActiveSalesManagement('');
        }

        if(menuName === 'store') {
            navigate('/seller/shop/profile');
            setActiveShopManagement('profile');
            setActiveBoard('');
            setActiveSettlement('');
            setActiveSalesManagement('');
        }
        
        if(menuName === 'edit') {
            navigate('/seller/productAdd');
            setActiveShopManagement('');
            setActiveBoard('');
            setActiveSettlement('');
            setActiveSalesManagement('');
            setOpenStates(false);
            setOpenSettlementStates(false);
            setOpenShopState(false);
            setOpenSalesState(false);
            
        }
        
        if(menuName === 'sales') {
            navigate('/seller/sales/faq');
            setActiveSalesManagement('faqs');
            setActiveBoard('');
            setActiveSettlement('');
            setActiveShopManagement('');
        }
        
        if(menuName === 'product') {
            navigate('/seller/products');
            setActiveShopManagement('');
            setActiveBoard('');
            setActiveSettlement('');
            setActiveSalesManagement('');
            setOpenStates(false);
            setOpenSettlementStates(false);
            setOpenShopState(false);
            setOpenSalesState(false);
        }

        if(menuName === 'reservation') {
            navigate('/seller/reservation');
            setActiveSalesManagement('');
            setActiveBoard('');
            setActiveSettlement('');
            setActiveShopManagement('');
            setOpenStates(false);
            setOpenSettlementStates(false);
            setOpenShopState(false);
            setOpenSalesState(false);
        }
        if(menuName === 'message') {
            navigate('/seller/message');
            setActiveSalesManagement('');
            setActiveBoard('');
            setActiveSettlement('');
            setActiveShopManagement('');
            setOpenStates(false);
            setOpenSettlementStates(false);
            setOpenShopState(false);
            setOpenSalesState(false);
        }
        if(menuName === 'messagePayment') {
            navigate('/seller/messagePayment');
            setActiveSalesManagement('');
            setActiveBoard('');
            setActiveSettlement('');
            setActiveShopManagement('');
            setOpenStates(false);
            setOpenSettlementStates(false);
            setOpenShopState(false);
            setOpenSalesState(false);
        }
    };

    const changeActiveBoard = (newBoard) => {
        setActiveBoard(newBoard); // 하위 메뉴 상태 변경
        if(newBoard === 'board') {
            navigate('/seller/board');
            setActiveMenu('board'); // 상위 메뉴 상태도 'board'로 변경
            setActiveSettlement('');
            setActiveShopManagement('');
            setActiveSalesManagement('');
        }
        if(newBoard === 'faq') {
            navigate('/seller/faq');
            setActiveMenu('board'); // 상위 메뉴 상태도 'board'로 변경
            setActiveSettlement('');
            setActiveShopManagement('');
            setActiveSalesManagement('');
        }
    }
    const changeActiveSettlement = (newSettlement) => {
        if(newSettlement === 'proceeds') {
            navigate('/seller/proceeds');
            setActiveSettlement(newSettlement); // 하위 메뉴 상태 변경
            setActiveMenu('settlement'); // 상위 메뉴 상태도 'settlement'로 변경
            setActiveBoard('');
            setActiveShopManagement('');
            setActiveSalesManagement('');
        }
        if(newSettlement === 'calculate') {
            navigate('/seller/calculate');
            setActiveSettlement(newSettlement); // 하위 메뉴 상태 변경
            setActiveMenu('settlement'); // 상위 메뉴 상태도 'settlement'로 변경
            setActiveBoard('');
            setActiveShopManagement('');
            setActiveSalesManagement('');
        }
    }
    const changeActiveShopManagement = (newShopMangement) => {
        if(newShopMangement === 'profile') {
            navigate('/seller/shop/profile')
            setActiveShopManagement(newShopMangement);
            setActiveMenu('store');
            setActiveBoard('');
            setActiveSettlement('');
            setActiveSalesManagement('');
        }
        if(newShopMangement === 'operating') {
            navigate('/seller/shop/operating')
            setActiveShopManagement(newShopMangement);
            setActiveMenu('store');
            setActiveBoard('');
            setActiveSettlement('');
            setActiveSalesManagement('');
        }
        if(newShopMangement === 'dateSetting') {
            navigate('/seller/shop/schedule')
            setActiveShopManagement(newShopMangement);
            setActiveMenu('store');
            setActiveBoard('');
            setActiveSettlement('');
            setActiveSalesManagement('');
        }
        if(newShopMangement === 'businessinfo') {
            navigate('/seller/shop/business')
            setActiveShopManagement(newShopMangement);
            setActiveMenu('store');
            setActiveBoard('');
            setActiveSettlement('');
            setActiveSalesManagement('');
        }
    }
    const changeActiveSalesManagement = (newSalesMangement) => {
        if(newSalesMangement === 'faqs') {
            navigate('/seller/sales/faq')
            setActiveSalesManagement(newSalesMangement);
            setActiveMenu('sales');
            setActiveBoard('');
            setActiveSettlement('');
            setActiveShopManagement('');
        }
    }

    const enhancedChildren = React.Children.map(children, child => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, {
            handleMenuClick, // 메뉴 변경 함수 전달
            setActiveMenu, // 쇼핑 메뉴 변경 함수 전달
            });
        }
        return child;
    });

    return (
        <div className={`${seller.seller_container}`}>
            <SellerHeader seller={seller} onMenuClick={onMenuClick} showMenu={showMenu}/>
            <div className=''>
                <CSSTransition in={showMenu} timeout={500} classNames="menu" unmountOnExit>
                        <SupportSellerSide seller={seller} activeMenu={activeMenu} handleMenuClick={handleMenuClick} activeBoard={activeBoard} setActiveBoard={setActiveBoard}
                                        changeActiveBoard={changeActiveBoard} activeSettlement={activeSettlement} setActiveSettlement={setActiveSettlement} changeActiveSettlement={changeActiveSettlement}
                                        activeShopManagement={activeShopManagement} setActiveShopManagement={setActiveShopManagement} changeActiveShopManagement={changeActiveShopManagement}
                                        setActiveSalesManagement={setActiveSalesManagement} changeActiveSalesManagement={changeActiveSalesManagement} activeSalesManagement={activeSalesManagement}
                                        sellerlogout={sellerlogout} userInfo={userInfo} openStates={openStates} openSettlementStates={openSettlementStates}
                                        openShopState={openShopState} openSalesState={openSalesState} toggleOpen={toggleOpen}
                                        toggleSettlementOpen={toggleSettlementOpen} toggleShopOpen={toggleShopOpen} toggleSalesOpen={toggleSalesOpen}
                        />
                </CSSTransition>
                <div className={`${seller.seller_content_container}`}>
                    {enhancedChildren}
                </div>
            </div>
        </div>
    );
}

export default SellerLayout