import api from './api'

// store category
export const storeCategory = () => api.get("/api/category/store");

// support category
export const supportCategory = () => api.get("/api/category/support");

// store category insert
export const storeCategoryInsert = (storeCategoryInfo) => api.post("/api/category/storeCategoryInsert", storeCategoryInfo);

// support category insert
export const supportCategoryInsert = (supportCategoryInfo) => api.post("/api/category/supportCategoryInsert", supportCategoryInfo);

// store sub category insert
export const storeSubCategoryInsert = (storeSubCategoryInfo) => api.post('/api/category/storeSubCategoryInsert', storeSubCategoryInfo);

// support sub category insert
export const supportSubCategoryInsert = (supportSubCategoryInfo) => api.post(`/api/category/supportSubCategoryInsert`, supportSubCategoryInfo);

// store sub category
export const storeSubCategorySelect = () => api.get(`/api/category/storeSubCategorySelect`);

// support sub category
export const supportSubCategorySelect = () => api.get(`/api/category/supportSubCategorySelect`);

// store category delete
export const storeCategoryDelete = (categoryNo) => api.delete(`/api/category/storeCategoryDelete/${categoryNo}`);

// support category delete
export const supportCategoryDelete = (categoryNo) => api.delete(`/api/category/supportCategoryDelete/${categoryNo}`);

// store sub category remove
export const supporSubCategoryRemove = (categoryNo) => api.delete(`/api/category/supporSubCategoryRemove/${categoryNo}`);

// support sub category remove
export const supportSubCategoryRemove = (categoryNo) => api.delete(`/api/category/supportSubCategoryRemove/${categoryNo}`);