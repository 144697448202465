import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import * as auth from '../../apis/auth';
import Cookies from 'js-cookie';
import { LoginContext } from '../../contexts/LoginContextProvider';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import admin from '../../admin/admin.module.css';

const MySwal = withReactContent(Swal);

const TwitterUserInfo = () => {
  const { isLogin, login, logout, remove, loginCheck } = useContext(LoginContext);
  const [userInfo, setUserInfo] = useState(null);
  const navigate = useNavigate();
  const [twitterExist, setTwitterExist] = useState(true);
  const [ joinObject, setJoinObject ] = useState({});

  useEffect(() => {
    fetch('/api/twitter/userinfo')
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`); // 응답 상태가 200이 아닌 경우 에러 처리
        }
        return response.json(); // JSON으로 변환 시도
      })
      .then(data => {
        const userInfo = JSON.parse(data.response); // JSON 응답 처리
        // console.log('userInfo : ', userInfo);
        setUserInfo(userInfo);

        // joinObject에 넣을 새로운 객체 구성
        const newJoinObject = {
          userProfile: userInfo.profile_image_url,
          userName: userInfo.name,
          userId: userInfo.id,
          twitter: userInfo.id
        };

        // console.log(newJoinObject);
        setJoinObject(newJoinObject);
        checkUserExists(newJoinObject);
      })
      .catch(error => {
        console.error('Error fetching user info:', error); // 에러 로그 출력
      });
}, []);


  const join = async () => {
    navigate('/twitter/join', { state: { twitter: userInfo } });
    // const response = await auth.twitterJoin(joinObject);
    // const data = await response.data;

    // if(data === 'SUCCESS') {
    //   MySwal.fire({
    //     customClass: {
    //         popup: `${admin.admin_seller_add_popup}`
    //     },
    //     html : 
    //     `
    //     <div>
    //     <div class='${admin.admin_seller_add_title}'><span>회원가입이 완료 되었습니다.</span></div>
    //     <div class='${admin.admin_seller_add_sub_title}'><span></span></div>
    //     <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
    //     <button class='mx-1' id='seller_add_confirm'>확인</button>
    //     </div>
    //     </div>
    //     `,
    //     showConfirmButton: false,
    //     showCancelButton: false,
    // });
    // document.addEventListener('click', sellerAddConfirm);
    
    // function sellerAddConfirm(e) {
    //     if(e.target && e.target.id == 'seller_add_confirm') {
    //         MySwal.close();
    //         // success('/market', { state: {key: 'test2'}});
    //         // login( joinObject.userId, joinObject.userPw );
    //         checkUserExists(userInfo);
    //         document.removeEventListener('click', sellerAddConfirm);
    //     }
    // }

      
    // } else {
    //   alert(`Server Error ${data}`);
    // }
  }

  const checkUserExists = async (newJoinObject) => {
    const response = await auth.twitterUserSearch(newJoinObject.userId);
    const data = response.data;

    // console.log('user : ', data);
    // console.log('status : ', response.status);
    // console.log('newJoinObject.id : ', newJoinObject.id);

    try {
      if (response.status === 200) {
        if (data === 'exist') {
          const userSelectResponse = await auth.twitterUserSelect(newJoinObject.userId);
          const userSelectData = userSelectResponse.data;

          // console.log('userSelectData : ', userSelectData);

          // 로그인 처리를 위한 토큰 요청
          const tokenResponse = await auth.getToken({ userId: userSelectData.twitter });
          const tokenData = tokenResponse.data;
          // console.log('tokenData : ', tokenData);
          if (tokenResponse.status === 200) {
            const token = tokenResponse.data.token;
            // 쿠키에 토큰 저장
            Cookies.set("accessToken", token);
            localStorage.setItem("accessToken", token);
            // 로그인 체크 함수 호출
            await loginCheck();
            navigate('/market');
          }
        } else {
          setTwitterExist(false);
        }
      }
    } catch (error) {
      console.error('error : ', error);
    }
  };

  return (
    <div>
      <div className='twitter_login_info_img_box'>
        <img src="../../img/logo_pink2.png" alt="데일리덕" />
      </div>
      {userInfo && !twitterExist ? (
        <div className='twitter_login_info_box'>
          <div>
            <img src={userInfo.profile_image_url_https} alt="Profile" />
          </div>
          <div>
            <span>{userInfo.id}</span>
          </div>
          <div>
            <span>{userInfo.name}</span>
          </div>
          <div>
            <span>{userInfo.screen_name}</span>
          </div>
          <div className='twitter_login_info_btn'>
            <button onClick={join}>데일리덕 가입하기</button>
          </div>
        </div>
      ) : (
        <p>사용자 정보를 확인 중입니다...</p>
      )}
    </div>
  );
};

export default TwitterUserInfo;
