import React, { useEffect, useState, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDropzone } from "react-dropzone";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import DateSelect from '../DateSelect';
import { animated } from 'react-spring';
import { useSpring, useTransition } from '@react-spring/core';


const VenueRequest = ( 
                        {
                        requestTransitions, handleRequest, handleRequestBack, showRequest, animated,
                        reservation, setReservation,reservationSelectOption, setReservationSelectOption, 
                        reservationSelectQuestion, setReservationSelectQuestion, reservationEssentialQuestion, 
                        setReservationEssentialQuestion, reservationRequest, setReservationRequest, date, questions, setQuestions, supportNo,
                        essentialQuestions, setEssentialQuestions, displayDate, transitions, startDate,
                        endDate, dateRange, setDateRange, ko, format, differenceInDays
                        } 
                    ) => {
    const [images, setImages] = useState([]);
    const [inputceoSay, setinputceoSay] = useState(false);
    const [ceoSay, setCeoSay] = useState('');
    const [showDate, setShowDate] = useState(false);
    const [props, set] = useSpring(() => ({ transform: 'translateY(100%)' }));

    const handleDateSelect = () => {
        setShowDate(true);
        set({ transform: 'translateY(0)' });
    }

    const dateTransitions = useTransition(showDate, {
        from: { transform: "translateY(100%)" },
        enter: { transform: "translateY(0)" },
        leave: { transform: "translateY(100%)" },
        config: {
          duration: 500,
        },
    });

    const handleBack = () => {
        setShowDate(false);
    }

    const next = useNavigate();
    const handleNext = () => {
        next(
            `/payment/${supportNo}`, 
                { 
                    state: 
                        {
                            reservation: reservation, 
                            reservationSelectOption : reservationSelectOption,
                            reservationSelectQuestion : reservationSelectQuestion,
                            reservationEssentialQuestion : reservationEssentialQuestion,
                            reservationRequest : reservationRequest
                        }
                }
            );
    }

    const sliderRef = useRef(); // 참조 생성
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
  
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        accept: "image/*",
        onDrop: (acceptedFiles) => {
            // 기존 코드에서 4개의 파일만 받도록 수정
            const filteredFiles = acceptedFiles.slice(0, 4);
            setImages(
              filteredFiles.map((file) =>
                Object.assign(file, {
                  preview: URL.createObjectURL(file),
                })
              )
            );

            // reservationRequest에 이미지 URL 저장
            const newReservationRequest = { ...reservationRequest };
            filteredFiles.forEach((file, index) => {
                const previewUrl = URL.createObjectURL(file);
                newReservationRequest[`reservationRequestImg${index + 1}`] = previewUrl;
            });
            setReservationRequest(newReservationRequest)
          },
    });

    const removeImage = (index) => {
        const newImages = [...images];
        newImages.splice(index, 1);
        setImages(newImages);

        // reservationRequest 업데이트
        const newReservationRequest = { ...reservationRequest };
        newImages.forEach((file, idx) => {
            newReservationRequest[`reservationRequestImg${idx + 1}`] = URL.createObjectURL(file);
        });
        // 빈 이미지 슬롯 처리
        for (let i = newImages.length + 1; i <= 4; i++) {
            newReservationRequest[`reservationRequestImg${i}`] = '';
        }
        setReservationRequest(newReservationRequest);
    };


    const skip = useNavigate();
    const handleSkip = () => {
        skip(`/payments/${supportNo}`);
    }

    let message = '사장님이 알아야 할 내용이 있거나 전하고 싶은 말을 남겨주세요!';

    const onCeoSay = (e) => {
        const { value } = e.target;
      if (value.length <= maxLength) {
        setCeoSay(value);
      }
    }

    useEffect(() => {
        setReservationRequest({
            ...reservationRequest,
            reservationRequestContent : ceoSay
        })
    }, [ceoSay]);
    
    useEffect(() => {
        // // console.log(reservationRequest);

    }, [reservationRequest]);

    const maxLength = 500;

  return (
    <div className='venue_request_box'>
      {showRequest && 
            <div className='venue_request_back'>
                {requestTransitions((style, item) =>
                item
                    ? <animated.div className="reservation_pay_back position-relative" style={style}>
                        <div className='position-absolute top-0 start-0 ps-4 pt-4'>
                        <button onClick={() => handleRequestBack()} className='reservation_pay_close'>
                            <span className="material-symbols-outlined">arrow_back_ios</span>
                        </button>
                        </div>
                        <div>
                            <div className='mt-4 mx-3 text-center'>
                                <h5>요청사항</h5>
                            </div>
                        </div>
                        <div className='d-flex py-4 mx-3'>
                            <div className='request_mini_img me-2'>
                                <img src="../img/support/venue_1.png" alt="이미지" />
                            </div>
                            <div className='px-3 request_mini_txt'>
                                <div><span>장소대관 장소명</span></div>
                                <div className='dd-fs-7'>
                                    <span>선택한 옵션 노출</span>
                                    <span>선택한 옵션 노출</span>
                                    <span>선택한 옵션 노출</span>
                                    <span>선택한 옵션 노출</span>
                                </div>
                            </div>
                        </div>
                        <div className='border-bottom'></div>
                        <div className='d-flex justify-content-between py-4 mx-3 request_reservation_datebox'>
                            <div><span>예약 신청 일자</span></div>
                            <div><span className='pe-1'>{displayDate}</span><button onClick={handleDateSelect}><img src="../img/icon/support/calendar.png" alt="달력" /></button></div>
                        </div>
                        <div className='border-bottom'></div>
                        <div className='py-3 mx-3 oner_say_massage'>
                            <div className='d-flex justify-content-between'>
                                <div><h5>사장님께 전할 말</h5></div>
                                <div><span className='gray_round'>선택</span></div>
                            </div>
                            <div className='textarea-container'>
                                <textarea 
                                    name="" 
                                    id="" 
                                    cols="30" 
                                    rows="10" 
                                    placeholder={`${message}`} 
                                    onChange={(e) => onCeoSay(e)}
                                    value={ceoSay}
                                    >
                                    </textarea>
                                    <div className='counter'>{`${ceoSay.length}/${maxLength}`}</div>
                            </div>
                        </div>
                        <div className='border-bottom'></div>
                        <div className='mx-3 py-3'>
                            <div className='d-flex justify-content-between pb-1'>
                                <div><h5>참고 사진</h5></div>
                                <div><span className='gray_round'>선택</span></div>
                            </div>
                            <div className='request_input_img'>
                                <div className='request_drop'>
                                    <div className="drop_box" {...getRootProps()} >
                                        <input {...getInputProps()} />
                                        <span className="material-symbols-outlined">add</span>
                                    </div>
                                </div>
                                <div className='request_slider'>
                                    <Swiper 
                                        slidesPerView={3}
                                        spaceBetween={15}
                                        freeMode={true}
                                        className="mySwiper"
                                    >
                                        {images.map((image, index) => (
                                            <SwiperSlide key={index}>
                                                <div className='request_slider_imgBox'>
                                                    <img src={image.preview} alt={`preview ${index}`} />
                                                    <button onClick={() => removeImage(index)}><span className="material-symbols-outlined">close</span></button>
                                                </div>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </div>
                            </div>
                            <div className='pt-2 pb-4 border-bottom'><span>*사진은 최대 4개까지 등록 가능합니다.</span></div>
                            <div className='py-3 d-flex justify-content-center request_btnbox'>
                                <div className='w-100 request_skip'><button onClick={() => handleSkip()}>건너뛰기</button></div>
                                <div className='w-100 request_next'><button onClick={() => handleNext()}>다음</button></div>
                            </div>
                        </div>
                        {
                        showDate && 
                        <DateSelect transitions={dateTransitions} animated={animated} handleBack={handleBack} 
                                    startDate={startDate} endDate={endDate} dateRange={dateRange} 
                                    setDateRange={setDateRange} ko={ko} format={format} differenceInDays={differenceInDays} />
                        }
                    </animated.div>
                    : null
                )}
        </div>
        }
    </div>
  )
}

export default VenueRequest