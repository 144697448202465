import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import event from '../../../containers/event/event.module.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import ss from '../../../containers/market/support/venue/support_shop.module.css';

const SupportMore = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const scrollRef = useRef(null);

    const [supportProduct, setSupportProduct] = useState(location?.state.supportProduct.slice(0, 6) || []);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(location?.state.supportProduct.length > 6);

    useEffect(() => {
        // console.log('supportProduct : ', supportProduct);
    }, [supportProduct]);

    useEffect(() => {
        // console.log('useEffect called');

        const handleScroll = () => {
            if (scrollRef.current) {
                const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
                // console.log('scrollTop:', scrollTop);
                // console.log('scrollHeight:', scrollHeight);
                // console.log('clientHeight:', clientHeight);

                if (scrollTop + clientHeight >= scrollHeight - 5 && hasMore) {
                    // console.log('End of div reached');
                    loadMoreProducts();
                }
            }
        };

        const currentScrollRef = scrollRef.current;
        if (currentScrollRef) {
            currentScrollRef.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (currentScrollRef) {
                currentScrollRef.removeEventListener('scroll', handleScroll);
            }
        };
    }, [scrollRef.current, hasMore]);

    const loadMoreProducts = () => {
        if (!hasMore) return;

        // console.log('Loading more products');
        const startIndex = page * 6;
        const endIndex = (page + 1) * 6;
        const moreProducts = location?.state.supportProduct.slice(startIndex, endIndex);

        if (moreProducts.length > 0) {
            setSupportProduct(prevProducts => [...prevProducts, ...moreProducts]);
            setPage(prevPage => prevPage + 1);
        }

        if (endIndex >= location?.state.supportProduct.length) {
            setHasMore(false);
        }
    };

    const onBack = () => {
        navigate(-1);
    };

    const onSupportItem = (item) => {
        // console.log('item.product_no : ', item.productNo);
        navigate(`/venue/${item.productNumber}`);
    };

    const getMinPrice = (items) => {
        if (!Array.isArray(items) || items.length === 0) return null;
        return Math.min(...items.map((item) => item.mainItemPrice));
    };

    const formatRating = (rating) => {
    if(rating !== null && rating !== '' && rating) {
        return rating.toFixed(1);
    } else {
        return rating;
    }
    };

    return (
        <div>
            <div className={`${event.event_store_more_header}`}>
                <div>
                    <button onClick={() => onBack()}>
                        <span className="material-symbols-outlined">arrow_back_ios</span>
                    </button>
                </div>
                <div>서포트 검색 상품</div>
            </div>
            <div className={`${event.event_store_more_content} py-3`} ref={scrollRef}>
                <div className='px-3'>
                    <div className='px-1 pt-1'>
                    {
                        supportProduct.map((item, index) => {

                            const minPrice = getMinPrice(item.mainItem);
                            const hasOptions = Array.isArray(item.mainItem) && item.mainItem.length > 0;

                            // 이미지 그룹 생성
                            const images = [
                                item.representativeImage,
                                item.listImage1,
                                item.listImage2,
                                item.listImage3,
                                item.listImage4,
                            ].filter(Boolean); // 유효한 이미지들만 포함

                            return (
                                <div className=' search_content_support_info' onClick={() => onSupportItem(item)} key={index}>
                                    <div>
                                        <div><img src={item.shopProfile} alt="" /></div>
                                        <div><span>{item.productName}</span></div>
                                    </div>
                                    {/* <div><span>{item.product_name}</span></div> */}
                                    <div><span>{item.productInfo}</span></div>
                                    <div className={`${ss.support_content_price_rate_star_box}`}>
                                        <div>
                                        <div>
                                            <img src={`${process.env.PUBLIC_URL}/img/icon/star_fill.png`} alt="별점" />
                                        </div>
                                        <div className="ps-1">
                                            <span>{formatRating(item.reviewRating)}</span>
                                        </div>
                                        </div>
                                        <div>
                                        <span>리뷰</span>
                                        <span>{item.reviewCount}</span>
                                        </div>
                                        <div>
                                        {minPrice !== null && (
                                            <span>
                                            &#8361;{minPrice.toLocaleString()}{hasOptions ? ' ~' : ''}
                                            </span>
                                        )}
                                        </div>
                                    </div>
                                    <div>
                                        <Swiper
                                            slidesPerView={'auto'}
                                            spaceBetween={10}
                                            modules={[Pagination, Navigation]} // Autoplay 모듈 추가
                                            className='search_content_support_item'
                                        >
                                            {
                                                images.map((item, index) => (
                                                    <SwiperSlide>
                                                        <div className='search_content_support_item_img'>
                                                            <img src={item} alt="이미지1" />
                                                        </div>
                                                    </SwiperSlide>
                                                ))
                                            }
                                        </Swiper>
                                    </div>
                                </div>
                            )
                        })
                    }
                    </div>
                    {!hasMore && <div className={`${event.event_store_more_last_txt} pt-4`}><span>검색된 상품의 마지막입니다.</span></div>}
                </div>
            </div>
        </div>
    );
};

export default SupportMore;
