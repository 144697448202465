import React, { useEffect, useState } from 'react'

const DailyduckMyTop = ( {
                          dailyduck, handleMyInfo, handleMyFavority, users, userImg, setUserImg, 
                          handleMyOD, handleMyCP, handleMyAD, handleMyReview, handleMySave,
                          handleNotice, handleCustomerService, handleStoreEntry, handleTerms,
                          handleNotification, handleSmining
                        } ) => {

    useEffect(() => {
        // users가 변경될 때마다 userProfile의 존재 여부를 확인하여 상태를 업데이트합니다.
        if (users && users.userProfile) {
            if (users.userProfile.startsWith('http')) {
            // 외부 URL인 경우 직접 URL을 사용합니다.
            setUserImg(users.userProfile);
            } else {
            // 내부 URL인 경우 API 서버 URL을 사용합니다.
            setUserImg(`${process.env.REACT_APP_API_SERVER}/img?file=${users.userProfile}`);
            }
        } else {
            // users.userProfile이 없는 경우 기본 이미지를 사용합니다.
            setUserImg('../../img/my/Profile_Circle.png');
        }
    }, [users, setUserImg]);

    const handleCopyToClipboard = () => {
        // 클립보드에 userNick 복사
        navigator.clipboard.writeText(users.userNick)
            .then(() => {
                alert(`'${users.userNick}' 닉네임이 클립보드에 복사되었습니다.`);
            })
            .catch((error) => {
                console.error('클립보드 복사 실패:', error);
                alert('클립보드 복사에 실패했습니다.');
            });
    };

    
  return (
    <>
        <div>
            <div className={`bg-white pb-4`}>
                <div className='text-center py-4 bg-white'>
                    <h5>내정보</h5>
                </div>
                <div className={`d-flex justify-content-between px-3 pt-3 mb-5 ${dailyduck.user_info}`} onClick={handleMyInfo}>
                    <div className={dailyduck.user_info_img}><img src={userImg} alt="유저이미지" /></div>
                    <div className={dailyduck.user_info_con}>
                        <div><span>{users ? users.userNick : '데일리덕'}</span></div>
                        <div><span>{users ? users.userEmail : 'newjeansduck@gmail.com'}</span></div>
                    </div>
                    <div><span class="material-symbols-outlined">chevron_right</span></div>
                </div>
                <div className={`${dailyduck.button_box} text-center py-4 mx-4`}>
                    <div className='row pb-3'>
                        <div className='col-4'>
                            <button onClick={handleMyFavority}>
                                <div><img src="../../img/my/my_favority.png" alt="나의 최애" /></div>
                                <div className='pt-2'><span>나의 최애</span></div>
                            </button>
                        </div>
                        <div className='col-4'>
                            <button onClick={handleMyOD}>
                                <div><img src="../../img/my/my_order.png" alt="주문배송" /></div>
                                <div className='pt-2'><span>주문·배송</span></div>
                            </button>
                        </div>
                        <div className='col-4'>
                            <button onClick={handleMyCP}>
                                <div><img src="../../img/my/coupon_point.png" alt="쿠폰포인트" /></div>
                                <div className='pt-2'><span>쿠폰·포인트</span></div>
                            </button>
                        </div>
                    </div>
                    <div className='row py-3'>
                        <div className='col-4'>
                            <button onClick={handleMyAD}>
                                <div><img src="../../img/my/my_page.png" alt="활동내역" /></div>
                                <div className='pt-2'><span>활동 내역</span></div>
                            </button>
                        </div>
                        <div className='col-4'>
                            <button onClick={handleMyReview}>
                                <div><img src="../../img/my/review.png" alt="리뷰" /></div>
                                <div className='pt-2'><span>리뷰</span></div>
                            </button>
                        </div>
                        <div className='col-4'>
                            <button onClick={handleMySave}>
                                <div><img src="../../img/my/like.png" alt="찜" /></div>
                                <div className='pt-2'><span>찜</span></div>
                            </button>
                        </div>
                    </div>
                </div>
                <div className={`${dailyduck.recommender} d-flex justify-content-between mx-4 mt-5 py-4 px-3`}>
                    <div><span>내 추천 아이디</span></div>
                    <button onClick={handleCopyToClipboard}>
                        <span className={`${dailyduck.recommender_user}`}>{users.userNick}</span>
                    </button>
                </div>
            </div>
            <div className={`${dailyduck.my_notice_btnBox} mt-2 bg-white py-3`}>
                <div className='mx-3 py-2 border-bottom'>
                    <button onClick={handleSmining}>스밍</button>
                </div>
                <div className='mx-3 py-2 border-bottom'>
                    <button onClick={handleNotice}>공지사항</button>
                </div>
                <div className='mx-3 py-2 border-bottom'>
                    <button onClick={handleCustomerService}>고객센터</button>
                </div>
                <div className='mx-3 py-2 border-bottom'>
                    <button onClick={handleStoreEntry}>입점문의</button>
                </div>
                <div className='mx-3 py-2 border-bottom'>
                    <button onClick={handleTerms}>이용약관</button>
                </div>
                <div className='mx-3 py-2 border-bottom'>
                    <button onClick={handleNotification}>알림설정</button>
                </div>
            </div>
            <div className={`${dailyduck.my_app_version} d-flex justify-content-between py-4 px-3`}>
                <div>앱 버전</div>
                <div>1.000.00</div>
            </div>
        </div>
    </>
  )
}

export default DailyduckMyTop