import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import event from '../../containers/event/event.module.css';
import { format } from 'date-fns';

const EventNotification = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const notification = location.state?.notification;

    useEffect(() => {
        // console.log('notification : ', notification);
    }, [notification]);

    const KSTformatDate = (date) => {
        return format(new Date(date), 'yyyy-MM-dd HH:mm:ss');
    };

    const onBack = () => {
        navigate(-1);
    }

  return (
    <div className={`${event.notification_container}`}>
        <div className={`${event.notification_box}`}>
            <div className={`${event.notification_header}`}>
                <div><button onClick={onBack}><span className="material-symbols-outlined">arrow_back_ios</span></button></div>
                <div><span>공지</span></div>
            </div>
            <div className={`${event.notification_content}`}>
                <div className={`${event.notification_content_title}`}><span>{notification.notificationTitle}</span></div>
                <div className={`${event.notification_content_date}`}><span>{KSTformatDate(notification.notificationRegDate)}</span></div>
                <div className={`${event.notification_content_info}`}>
                    <div dangerouslySetInnerHTML={{ __html: notification.notificationContent }}></div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default EventNotification