import React, { useContext, useEffect, useState } from 'react';
import admin from '../../../admin.module.css';
import { CSSTransition } from 'react-transition-group';
import * as adminApi from '../../../../apis/admin';
import { LoginContext } from '../../../../contexts/LoginContextProvider';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Pagination, Select } from 'antd';
import { Option } from 'antd/es/mentions';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

const AdminReturn = () => {
    const { userInfo, roles } = useContext(LoginContext);
    const MySwal = withReactContent(Swal);
    const [activeButton, setActiveButton] = useState('newReturn');
    const [returnProgress, setReturnProgress] = useState(false);
    const [orderList, setOrderList] = useState([]);
    const [deliveryCompanies, setDeliveryCompanies] = useState([]);
    const [deliveryInfo, setDeliveryInfo] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(7);
    const [selectedOrders, setSelectedOrders] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [showReturnRefuse, setShowReturnRefuse] = useState(false);
    const [refuseList, setRefuseList] = useState([]);

    const [showCancelMobileProgressBtn, setShowCancelMobileProgressBtn] = useState(false);
    const [showReturnMobileOther, setShowReturnMobileOther] = useState(false);


    const paginate = pageNumber => setCurrentPage(pageNumber);

    const onProgress = () => {
        setReturnProgress(!returnProgress);
    }

    useEffect(() => {
        if(userInfo) {
            if(roles.isAdmin) {
                adimnOrderSelect();
                deliveryCompanySelect();
            } else {
                orderSelect();
                deliveryCompanySelect();
            }
        }
    }, [userInfo]);

    const orderSelect = async () => {
        const response = await adminApi.orderSelect(userInfo.userId);
        const data = response.data.filter(order =>
            ["신규반품", "반품요청", "반품승인", "반품수거중", "반품수거완료", "환불진행중", "환불처리실패", "반품완료"].includes(order.paymentState)
        );

        const initialDeliveryInfo = data.reduce((acc, order) => {
            acc[order.paymentNo] = {
                returnDeliveryCompany: order.returnDeliveryCompany || '',
                returnDeliveryNumber: order.returnDeliveryNumber || ''
            };
            return acc;
        }, {});

        setOrderList(data);
        setTotalItems(data.length);
        setDeliveryInfo(initialDeliveryInfo); // Initialize deliveryInfo with existing values
    };

    const adimnOrderSelect = async () => {
        const response = await adminApi.adminOrderSelect();
        const data = response.data.filter(order =>
            ["신규반품", "반품요청", "반품승인", "반품수거중", "반품수거완료", "환불진행중", "환불처리실패", "반품완료"].includes(order.paymentState)
        );

        const initialDeliveryInfo = data.reduce((acc, order) => {
            acc[order.paymentNo] = {
                returnDeliveryCompany: order.returnDeliveryCompany || '',
                returnDeliveryNumber: order.returnDeliveryNumber || ''
            };
            return acc;
        }, {});

        setOrderList(data);
        setTotalItems(data.length);
        setDeliveryInfo(initialDeliveryInfo); // Initialize deliveryInfo with existing values
    }

    const deliveryCompanySelect = async () => {
        try {
            const response = await fetch('https://apis.tracker.delivery/carriers');
            const data = await response.json();

            console.log('delivery : ', data);
            setDeliveryCompanies(data);
        } catch (error) {
            console.error('Error fetching delivery companies:', error);
        }
    };

    const getFilteredOrders = (state) => {
        switch (state) {
            case 'newReturn':
                return orderList.filter(order => order.paymentState === '신규반품' || order.paymentState === '반품요청');
            case 'requestReturn':
                return orderList.filter(order => order.paymentState === '반품요청');
            case 'returnApproval':
                return orderList.filter(order => order.paymentState === '반품승인');
            case 'collecting':
                return orderList.filter(order => order.paymentState === '반품수거중');
            case 'completeCollectiong':
                return orderList.filter(order => order.paymentState === '반품수거완료');
            case 'progressReturn':
                return orderList.filter(order => order.paymentState === '환불진행중');
            case 'failReturn':
                return orderList.filter(order => order.paymentState === '환불처리실패');
            case 'completeReturn':
                return orderList.filter(order => order.paymentState === '반품완료');
            default:
                return orderList;
        }
    }

    const currentOrders = getFilteredOrders(activeButton);

    const calculateSubtotal = (order) => {
        if(order.options.length !== 0) {
            const optionsTotal = order.options.reduce((total, option) => {
                return total + (order.principalAmount * option.quantity) + (option.addPrice * option.quantity);
            }, 0);
            return optionsTotal + order.deliveryPrice;
        } else {
            return parseInt(order.paymentPrice);
        }
    };

    const calculateProductPrice = (order) => {
        console.log('order : ', order);
        if(order.options.length !== 0) {
            const optionsTotal = order.options.reduce((total, option) => {
                return total + (order.principalAmount) + (option.addPrice);
            }, 0);
            return optionsTotal;
        } else {
            return order.principalAmount;
        }
    }

    const handleSelectOrder = (paymentNo) => {
        if (selectedOrders.includes(paymentNo)) {
            setSelectedOrders(selectedOrders.filter(no => no !== paymentNo));
        } else {
            setSelectedOrders([...selectedOrders, paymentNo]);
        }
    };

    const handleSelectAll = () => {
        if (selectedOrders.length === currentOrders.length) {
            setSelectedOrders([]);
        } else {
            setSelectedOrders(currentOrders.map(order => order.paymentNo));
        }
    };

    const handleForciblyCompleteReturn = () => {
        if(selectedOrders.length === 0) {
            alert('선택된 반품 주문이 없습니다.');
            return;
        }
        
        const selectedOrderDetails = orderList.filter(order => selectedOrders.includes(order.paymentNo));
        const newItem = [];
        selectedOrderDetails.forEach(order => {
            console.log(`Payment No: ${order.paymentNo}, Payment Key: ${order.paymentKey}, Refund Amount: ${order.refundAmount}`);
            newItem.push({
                paymentNo: order.paymentNo,
                paymentKey: order.paymentKey,
                refundAmount: order.refundAmount,
                returnType : order.returnType,
                returnContent : order.returnContent
            });
        });

        console.log(newItem); // 최종 리스트 확인

        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html: `
                <div>
                    <div class='${admin.admin_seller_add_title}'><span>${selectedOrders.length}개 상품을 반품완료 처리 하시겠습니까?</span></div>
                    <div class='${admin.admin_seller_add_sub_title}'><span>${selectedOrders.length}개 상품을 반품완료 처리 하시겠습니까?</span></div>
                    <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                      <button class='mx-1' id='seller_add_cancel'>취소</button>
                      <button class='mx-1' id='seller_add_success'>반품완료</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });
        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);
    
        async function sellerAddSuccess(e) {
            if (e.target && e.target.id === 'seller_add_success') {
                try {
                    const response = await adminApi.orderReturnComplete(newItem);
                    const data = response.data;
        
                    if (data === 'SUCCESS') {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                            <div class='${admin.admin_seller_add_title}'><span>${selectedOrders.length}개 상품이 반품완료 처리 되었습니다.</span></div>
                            <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);
        
                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                if(roles.isAdmin) {
                                    adimnOrderSelect();
                                } else {
                                    orderSelect();
                                }
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    } else {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                            <div class='${admin.admin_seller_add_title}'><span>${selectedOrders.length}개 상품 반품완료 처리에 실패했습니다.</span></div>
                            <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);
        
                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                if(roles.isAdmin) {
                                    adimnOrderSelect();
                                } else {
                                    orderSelect();
                                }
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    }
                } catch (error) {
                    console.error('error : ', error);
                }
            }
        }
    
        function sellerAddCancel(e) {
            if (e.target && e.target.id === 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
            }
        }
    };

    const handleCollection = () => {
        if (selectedOrders.length === 0) {
            alert('선택된 반품 주문이 없습니다.');
            return;
        }
    
        const selectedOrderDetails = orderList.filter(order => selectedOrders.includes(order.paymentNo));
        const newItem = [];
    
        for (let order of selectedOrderDetails) {
            console.log(`Payment No: ${order.paymentNo}, Payment Key: ${order.paymentKey}, Refund Amount: ${order.refundAmount}`);
    
            if (deliveryInfo[order.paymentNo]?.returnDeliveryCompany === '' || deliveryInfo[order.paymentNo]?.returnDeliveryNumber === '') {
                alert(`주문번호 ${order.paymentNumber}의 수거택배명과 운송장번호가 비었습니다.`);
                return;
            }
    
            newItem.push({
                paymentNo: order.paymentNo,
                paymentKey: order.paymentKey,
                refundAmount: order.refundAmount,
                returnType: order.returnType,
                returnContent: order.returnContent,
                returnDeliveryCompany: deliveryInfo[order.paymentNo]?.returnDeliveryCompany || '',
                returnDeliveryNumber: deliveryInfo[order.paymentNo]?.returnDeliveryNumber || ''
            });
        }
    
        console.log(newItem); // 최종 리스트 확인
    
        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html: `
                <div>
                    <div class='${admin.admin_seller_add_title}'><span>${selectedOrders.length}개 상품을 수거지시 처리 하시겠습니까?</span></div>
                    <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                      <button class='mx-1' id='seller_add_cancel'>취소</button>
                      <button class='mx-1' id='seller_add_success'>수거지시</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });
    
        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);
    
        async function sellerAddSuccess(e) {
            if (e.target && e.target.id === 'seller_add_success') {
                try {
                    const response = await adminApi.orderReturnCollection(newItem);
                    const data = response.data;
    
                    if (data === 'SUCCESS') {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                            <div class='${admin.admin_seller_add_title}'><span>${selectedOrders.length}개 상품이 수거중 처리 되었습니다.</span></div>
                            <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);
    
                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                if(roles.isAdmin) {
                                    adimnOrderSelect();
                                } else {
                                    orderSelect();
                                }
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    } else {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                            <div class='${admin.admin_seller_add_title}'><span>${selectedOrders.length}개 상품 수거중 처리에 실패했습니다.</span></div>
                            <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);
    
                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                if(roles.isAdmin) {
                                    adimnOrderSelect();
                                } else {
                                    orderSelect();
                                }
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    }
                } catch (error) {
                    console.error('error : ', error);
                }
            }
        }
    
        function sellerAddCancel(e) {
            if (e.target && e.target.id === 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
            }
        }
    }

    const handleCollectionComp = () => {
        if (selectedOrders.length === 0) {
            alert('선택된 반품 주문이 없습니다.');
            return;
        }
    
        const selectedOrderDetails = orderList.filter(order => selectedOrders.includes(order.paymentNo));
        const newItem = [];
    
        for (let order of selectedOrderDetails) {
            console.log(`Payment No: ${order.paymentNo}, Payment Key: ${order.paymentKey}, Refund Amount: ${order.refundAmount}`);
    
            if (deliveryInfo[order.paymentNo]?.returnDeliveryCompany === '' || deliveryInfo[order.paymentNo]?.returnDeliveryNumber === '') {
                alert(`주문번호 ${order.paymentNumber}의 수거택배명과 운송장번호가 비었습니다.`);
                return;
            }
    
            newItem.push({
                paymentNo: order.paymentNo,
                paymentKey: order.paymentKey,
                refundAmount: order.refundAmount,
                returnType: order.returnType,
                returnContent: order.returnContent,
                returnDeliveryCompany: deliveryInfo[order.paymentNo]?.returnDeliveryCompany || '',
                returnDeliveryNumber: deliveryInfo[order.paymentNo]?.returnDeliveryNumber || ''
            });
        }
    
        console.log(newItem); // 최종 리스트 확인
    
        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html: `
                <div>
                    <div class='${admin.admin_seller_add_title}'><span>${selectedOrders.length}개 상품을 수거완료 처리 하시겠습니까?</span></div>
                    <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                      <button class='mx-1' id='seller_add_cancel'>취소</button>
                      <button class='mx-1' id='seller_add_success'>수거완료</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });
    
        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);
    
        async function sellerAddSuccess(e) {
            if (e.target && e.target.id === 'seller_add_success') {
                try {
                    const response = await adminApi.orderReturnCollectionComp(newItem);
                    const data = response.data;
    
                    if (data === 'SUCCESS') {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                            <div class='${admin.admin_seller_add_title}'><span>${selectedOrders.length}개 상품이 수거완료 처리 되었습니다.</span></div>
                            <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);
    
                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                if(roles.isAdmin) {
                                    adimnOrderSelect();
                                } else {
                                    orderSelect();
                                }
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    } else {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                            <div class='${admin.admin_seller_add_title}'><span>${selectedOrders.length}개 상품 수거완료 처리에 실패했습니다.</span></div>
                            <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);
    
                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                if(roles.isAdmin) {
                                    adimnOrderSelect();
                                } else {
                                    orderSelect();
                                }
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    }
                } catch (error) {
                    console.error('error : ', error);
                }
            }
        }
    
        function sellerAddCancel(e) {
            if (e.target && e.target.id === 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
            }
        }
    }

    const handleReturnApproval = () => {
        if (selectedOrders.length === 0) {
            alert('선택된 반품 주문이 없습니다.');
            return;
        }
    
        const selectedOrderDetails = orderList.filter(order => selectedOrders.includes(order.paymentNo));

        console.log(selectedOrderDetails); // 최종 리스트 확인

        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html: `
                <div>
                    <div class='${admin.admin_seller_add_title}'><span>${selectedOrders.length}개 상품을 반품승인 하시겠습니까?</span></div>
                    <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                      <button class='mx-1' id='seller_add_cancel'>취소</button>
                      <button class='mx-1' id='seller_add_success'>반품승인</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });
    
        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);

        async function sellerAddSuccess(e) {
            if (e.target && e.target.id === 'seller_add_success') {
                try {
                    const response = await adminApi.orderReturnApproval(selectedOrderDetails);
                    const data = response.data;
    
                    if (data === 'SUCCESS') {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                            <div class='${admin.admin_seller_add_title}'><span>${selectedOrders.length}개 상품이 반품승인 처리 되었습니다.</span></div>
                            <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);
    
                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                if(roles.isAdmin) {
                                    adimnOrderSelect();
                                } else {
                                    orderSelect();
                                }
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    } else {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                            <div class='${admin.admin_seller_add_title}'><span>${selectedOrders.length}개 상품 반품승인 처리에 실패했습니다.</span></div>
                            <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);
    
                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                if(roles.isAdmin) {
                                    adimnOrderSelect();
                                } else {
                                    orderSelect();
                                }
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    }
                } catch (error) {
                    console.error('error : ', error);
                }
            }
        }
    
        function sellerAddCancel(e) {
            if (e.target && e.target.id === 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
            }
        }

    }
    
    const handleReturnRefuse = () => {
        // 반품거절 로직 추가
        if (selectedOrders.length === 0) {
            alert('선택된 반품 주문이 없습니다.');
            return;
        }
    
        const selectedOrderDetails = orderList.filter(order => selectedOrders.includes(order.paymentNo));

        console.log(selectedOrderDetails); // 최종 리스트 확인
        setRefuseList(selectedOrderDetails);
        // API 호출 등 필요한 로직 추가
        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html: `
                <div>
                    <div class='${admin.admin_seller_add_title}'><span>${selectedOrders.length}개 상품을 반품거절 처리 하시겠습니까?</span></div>
                    <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                      <button class='mx-1' id='seller_add_cancel'>취소</button>
                      <button class='mx-1' id='seller_add_success'>반품거절</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });

        
        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);

        async function sellerAddSuccess(e) {
            if (e.target && e.target.id === 'seller_add_success') {
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
                MySwal.close();
                setShowReturnRefuse(true);
            }
        }
    
        function sellerAddCancel(e) {
            if (e.target && e.target.id === 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
            }
        }
    }

    const onReturnRefuseText = (e, item) => {
        const updatedRefuseList = refuseList.map(refuseItem => {
            if (refuseItem.paymentNumber === item.paymentNumber) {
                return { ...refuseItem, returnRefuseText: e.target.value };
            }
            return refuseItem;
        });
        setRefuseList(updatedRefuseList);
    }

    const onReturnRefuseBtn = () => {
        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html: `
                <div>
                    <div class='${admin.admin_seller_add_title}'><span>반품거절 처리 하시겠습니까?</span></div>
                    <div class='${admin.admin_seller_add_sub_title}'><span>반품거절 시 배송완료 상태로 되돌아가며 거절사유가 노출됩니다.</span></div>
                    <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                      <button class='mx-1' id='seller_add_cancel'>아니오</button>
                      <button class='mx-1' id='seller_add_success'>예</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });

        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);

        async function sellerAddSuccess(e) {
            if (e.target && e.target.id === 'seller_add_success') {
                try {
                    const response = await adminApi.returnRefuseUpdate(refuseList);
                    const data = response.data;
            
                    console.log('refuseData : ', data);
        
                    if (data === 'SUCCESS') {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                                <div class='${admin.admin_seller_add_title}'><span>반품 거절이 완료 되었습니다.</span></div>
                                <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                    <button class='mx-1' id='seller_add_confirm'>확인</button>
                                </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);
        
                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                if(roles.isAdmin) {
                                    adimnOrderSelect();
                                } else {
                                    orderSelect();
                                }
                                setShowReturnRefuse(false);
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    } else {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                                <div class='${admin.admin_seller_add_title}'><span>반품거절 처리에 실패했습니다.</span></div>
                                <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                    <button class='mx-1' id='seller_add_confirm'>확인</button>
                                </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);
        
                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                if(roles.isAdmin) {
                                    adimnOrderSelect();
                                } else {
                                    orderSelect();
                                }
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    }
                } catch (error) {
                    console.error('error : ', error);
                }
            }
        }
    
        function sellerAddCancel(e) {
            if (e.target && e.target.id === 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
            }
        }
    }

    useEffect(() => {
        console.log('refuseList : ', refuseList);
        console.log('deliveryInfo : ', deliveryInfo);
    }, [refuseList, deliveryInfo]);
    
    // 반품완료
    const handleCompleteReturn = () => {
        if (selectedOrders.length === 0) {
            alert('선택된 반품 주문이 없습니다.');
            return;
        }
    
        const selectedOrderDetails = orderList.filter(order => selectedOrders.includes(order.paymentNo));

        console.log(selectedOrderDetails); // 최종 리스트 확인

        const hasInvalidState = selectedOrders.some(paymentNo => {
            const order = orderList.find(order => order.paymentNo === paymentNo);

            console.log('order : ', order);

            if(deliveryInfo[order.paymentNo]?.returnDeliveryCompany === '' || deliveryInfo[order.paymentNo]?.returnDeliveryNumber === '') {
                return true;
            }

            // if(order.collectionDeliveryCompany === null || order.collectionDeliveryNumber === null) {
            //     return true;
            // }
        });

        if(hasInvalidState) {
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html: `
                        <div><div class='${admin.admin_seller_add_title}'><span>수거 또는 반품배송정보가 없는 아이템이 있습니다.</span></div></div>
                        <div><div class='${admin.admin_seller_add_sub_title}'><span>수거 또는 반품배송정보를 확인 후 다시 시도해주세요.</span></div></div>
                        <div><div class='${admin.admin_seller_add_sub_title}'><span>수거 또는 반품 배송정보가 필요없으실 경우 강제 반품완료 처리를 이용해주세요.</span></div></div>
                      `,
                showConfirmButton: false,
                showCancelButton: false,
                timer: 2000
            });
        } else {
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html: `
                    <div>
                        <div class='${admin.admin_seller_add_title}'><span>반품완료 처리 하시겠습니까?</span></div>
                        <div class='${admin.admin_seller_add_sub_title}'><span>환불 금액 및 기타 사항을 정확히 확인해주세요.</span></div>
                        <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                          <button class='mx-1' id='seller_add_cancel'>아니오</button>
                          <button class='mx-1' id='seller_add_success'>예</button>
                        </div>
                    </div>
                `,
                showConfirmButton: false,
                showCancelButton: false,
            });
    
            document.addEventListener('click', sellerAddSuccess);
            document.addEventListener('click', sellerAddCancel);
    
            async function sellerAddSuccess(e) {
                if (e.target && e.target.id === 'seller_add_success') {
                    try {
                        const response = await adminApi.orderReturnCompleteCheck(selectedOrderDetails);
                        const data = response.data;
                
                        console.log('refuseData : ', data);
            
                        if (data === 'SUCCESS') {
                            MySwal.fire({
                                customClass: {
                                    popup: `${admin.admin_seller_add_popup}`
                                },
                                html: `
                                <div>
                                    <div class='${admin.admin_seller_add_title}'><span>반품완료 처리가 완료 되었습니다.</span></div>
                                    <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                                    </div>
                                </div>
                                `,
                                showConfirmButton: false,
                                showCancelButton: false,
                            });
                            document.addEventListener('click', sellerAddConfirm);
            
                            function sellerAddConfirm(e) {
                                if (e.target && e.target.id === 'seller_add_confirm') {
                                    if(roles.isAdmin) {
                                        adimnOrderSelect();
                                    } else {
                                        orderSelect();
                                    }
                                    setShowReturnRefuse(false);
                                    MySwal.close();
                                    document.removeEventListener('click', sellerAddConfirm);
                                    document.removeEventListener('click', sellerAddSuccess);
                                    document.removeEventListener('click', sellerAddCancel);
                                }
                            }
                        } else {
                            MySwal.fire({
                                customClass: {
                                    popup: `${admin.admin_seller_add_popup}`
                                },
                                html: `
                                <div>
                                    <div class='${admin.admin_seller_add_title}'><span>반품완료 처리에 실패했습니다.</span></div>
                                    <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                                    </div>
                                </div>
                                `,
                                showConfirmButton: false,
                                showCancelButton: false,
                            });
                            document.addEventListener('click', sellerAddConfirm);
            
                            function sellerAddConfirm(e) {
                                if (e.target && e.target.id === 'seller_add_confirm') {
                                    if(roles.isAdmin) {
                                        adimnOrderSelect();
                                    } else {
                                        orderSelect();
                                    }
                                    MySwal.close();
                                    document.removeEventListener('click', sellerAddConfirm);
                                    document.removeEventListener('click', sellerAddSuccess);
                                    document.removeEventListener('click', sellerAddCancel);
                                }
                            }
                        }
                    } catch (error) {
                        console.error('error : ', error);
                    }
                }
            }
        
            function sellerAddCancel(e) {
                if (e.target && e.target.id === 'seller_add_cancel') {
                    MySwal.close();
                    document.removeEventListener('click', sellerAddSuccess);
                    document.removeEventListener('click', sellerAddCancel);
                }
            }
        }
    }
    const handleRefundProcessing = async () => {
        if (selectedOrders.length === 0) {
            alert('선택된 반품 주문이 없습니다.');
            return;
        }
    
        const hasInvalidState = selectedOrders.some(paymentNo => {
            const order = orderList.find(order => order.paymentNo === paymentNo);
            console.log('order : ', order);
    
            return ['반품요청', '반품승인', '반품수거중', '반품수거완료', '환불진행중', '반품완료'].includes(order.paymentState);
        });
    
        if (hasInvalidState) {
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html: `<div><div class='${admin.admin_seller_add_title}'><span>환불처리에 실패한 상품이 없습니다.</span></div></div>`,
                html: `<div><div class='${admin.admin_seller_sub_title}'><span>환불처리에 실패한 상품만 환불처리중으로 되돌릴 수 있습니다.</span></div></div>`,
                showConfirmButton: false,
                showCancelButton: false,
                timer: 2000
            });
            return;
        }
    
        const selectedOrderDetails = orderList.filter(order => selectedOrders.includes(order.paymentNo));
    
        // `selectedOrderDetails`에 올바른 데이터가 있는지 확인하기 위해 콘솔에 출력합니다.
        console.log('selectedOrderDetails : ', selectedOrderDetails);
    
        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html: `
                <div>
                    <div class='${admin.admin_seller_add_title}'><span>환불 진행중으로 되돌리시겠습니까?</span></div>
                    <div class='${admin.admin_seller_add_sub_title}'><span>환불에 실패한 상품만 처리됩니다.</span></div>
                    <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                      <button class='mx-1' id='seller_add_cancel'>아니오</button>
                      <button class='mx-1' id='seller_add_success'>예</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });
    
        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);
    
        async function sellerAddSuccess(e) {
            if (e.target && e.target.id === 'seller_add_success') {
                try {
                    const response = await adminApi.refundProcessing(selectedOrderDetails);
                    const data = response.data;
    
                    console.log('API Response : ', data);
    
                    if (data === 'SUCCESS') {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                                <div class='${admin.admin_seller_add_title}'><span>환불 처리중 상태로 변경 완료 되었습니다.</span></div>
                                <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                    <button class='mx-1' id='seller_add_confirm'>확인</button>
                                </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);
    
                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                if(roles.isAdmin) {
                                    adimnOrderSelect();
                                } else {
                                    orderSelect();
                                }
                                setShowReturnRefuse(false);
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    } else {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                                <div class='${admin.admin_seller_add_title}'><span>환불 처리중 상태 변경 처리에 실패했습니다.</span></div>
                                <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                    <button class='mx-1' id='seller_add_confirm'>확인</button>
                                </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);
    
                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                if(roles.isAdmin) {
                                    adimnOrderSelect();
                                } else {
                                    orderSelect();
                                }
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    }
                } catch (error) {
                    console.error('error : ', error);
                }
            }
        }
    
        function sellerAddCancel(e) {
            if (e.target && e.target.id === 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
            }
        }
    };

    const handleReturnRequest = () => {
        if (selectedOrders.length === 0) {
            alert('선택된 반품 주문이 없습니다.');
            return;
        }

        let paymentNumber = [];

        const hasInvalidState = selectedOrders.some(paymentNo => {
            const order = orderList.find(order => order.paymentNo === paymentNo);
            console.log('order : ', order);

            return ['반품요청', '환불진행중', '환불처리실패', '반품완료'].includes(order.paymentState);
        });

        const selectedOrderDetails = orderList.filter(order => selectedOrders.includes(order.paymentNo));

        console.log('selectedOrderDetails : ', selectedOrderDetails); // 최종 리스트 확인

        selectedOrderDetails.map((item, index) => {
            paymentNumber.push(item.paymentNumber);
        })

        console.log('paymentNumber : ', paymentNumber);

        if (hasInvalidState) {
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html: `<div><div class='${admin.admin_seller_add_title}'><span>반품 요청으로 돌릴 수 있는 상품이 없습니다.</span></div></div>
                <div><div class='${admin.admin_seller_sub_title}'><span>반품승인, 반품수거중, 반품수거완료인 상태만 되돌릴 수 있습니다.</span></div></div>`,
                showConfirmButton: false,
                showCancelButton: false,
                timer: 2000
            });
            return;
        } else {
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html: `
                    <div>
                        <div class='${admin.admin_seller_add_title}'><span>반품요청으로 되돌리시겠습니까?</span></div>
                        <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                        <button class='mx-1' id='seller_add_cancel'>아니오</button>
                        <button class='mx-1' id='seller_add_success'>예</button>
                        </div>
                    </div>
                `,
                showConfirmButton: false,
                showCancelButton: false,
            });

            document.addEventListener('click', sellerAddSuccess);
            document.addEventListener('click', sellerAddCancel);

            async function sellerAddSuccess(e) {
                if (e.target && e.target.id === 'seller_add_success') {
                    try {
                        console.log('selectedOrderDetails : ', selectedOrderDetails); // 서버에 보낼 데이터 확인
                        const response = await adminApi.returnRequest(selectedOrderDetails);
                        const data = response.data;

                        console.log('API Response : ', data); // 서버 응답 확인

                        if (data === 'SUCCESS') {
                            MySwal.fire({
                                customClass: {
                                    popup: `${admin.admin_seller_add_popup}`
                                },
                                html: `
                                <div>
                                    <div class='${admin.admin_seller_add_title}'><span>반품 요청 상태로 변경 완료 되었습니다.</span></div>
                                    <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                                    </div>
                                </div>
                                `,
                                showConfirmButton: false,
                                showCancelButton: false,
                            });
                            document.addEventListener('click', sellerAddConfirm);

                            function sellerAddConfirm(e) {
                                if (e.target && e.target.id === 'seller_add_confirm') {
                                    if(roles.isAdmin) {
                                        adimnOrderSelect();
                                    } else {
                                        orderSelect();
                                    }
                                    MySwal.close();
                                    document.removeEventListener('click', sellerAddConfirm);
                                    document.removeEventListener('click', sellerAddSuccess);
                                    document.removeEventListener('click', sellerAddCancel);
                                }
                            }
                        } else {
                            MySwal.fire({
                                customClass: {
                                    popup: `${admin.admin_seller_add_popup}`
                                },
                                html: `
                                <div>
                                    <div class='${admin.admin_seller_add_title}'><span>반품요청 상태 변경 처리에 실패했습니다.</span></div>
                                    <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                                    </div>
                                </div>
                                `,
                                showConfirmButton: false,
                                showCancelButton: false,
                            });
                            document.addEventListener('click', sellerAddConfirm);

                            function sellerAddConfirm(e) {
                                if (e.target && e.target.id === 'seller_add_confirm') {
                                    if(roles.isAdmin) {
                                        adimnOrderSelect();
                                    } else {
                                        orderSelect();
                                    }
                                    MySwal.close();
                                    document.removeEventListener('click', sellerAddConfirm);
                                    document.removeEventListener('click', sellerAddSuccess);
                                    document.removeEventListener('click', sellerAddCancel);
                                }
                            }
                        }
                    } catch (error) {
                        console.error('error : ', error);
                    }
                }
            }

            function sellerAddCancel(e) {
                if (e.target && e.target.id === 'seller_add_cancel') {
                    MySwal.close();
                    document.removeEventListener('click', sellerAddSuccess);
                    document.removeEventListener('click', sellerAddCancel);
                }
            }
        }
    };

    // 수거중으로 되돌리기
    const handleBackCollecting = () => {
        if (selectedOrders.length === 0) {
            alert('선택된 반품 주문이 없습니다.');
            return;
        }

        const hasInvalidState = selectedOrders.some(paymentNo => {
            const order = orderList.find(order => order.paymentNo === paymentNo);
            console.log('order : ', order);

            return ['환불진행중', '환불처리실패', '반품수거중', '반품완료'].includes(order.paymentState);
        });

        const selectedOrderDetails = orderList.filter(order => selectedOrders.includes(order.paymentNo));

        console.log('selectedOrderDetails : ', selectedOrderDetails); // 최종 리스트 확인

        if (hasInvalidState) {
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html: `<div><div class='${admin.admin_seller_add_title}'><span>반품 수거중으로 돌릴 수 있는 상품이 없습니다.</span></div></div>
                <div><div class='${admin.admin_seller_sub_title}'><span>반품요청, 반품승인, 반품수거완료인 상태만 되돌릴 수 있습니다.</span></div></div>`,
                showConfirmButton: false,
                showCancelButton: false,
                timer: 2000
            });
            return;
        } else {
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html: `
                    <div>
                        <div class='${admin.admin_seller_add_title}'><span>반품수거중으로 되돌리시겠습니까?</span></div>
                        <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                        <button class='mx-1' id='seller_add_cancel'>아니오</button>
                        <button class='mx-1' id='seller_add_success'>예</button>
                        </div>
                    </div>
                `,
                showConfirmButton: false,
                showCancelButton: false,
            });

            document.addEventListener('click', sellerAddSuccess);
            document.addEventListener('click', sellerAddCancel);

            async function sellerAddSuccess(e) {
                if (e.target && e.target.id === 'seller_add_success') {
                    try {
                        console.log('selectedOrderDetails : ', selectedOrderDetails); // 서버에 보낼 데이터 확인
                        const response = await adminApi.backCollecting(selectedOrderDetails);
                        const data = response.data;

                        console.log('API Response : ', data); // 서버 응답 확인

                        if (data === 'SUCCESS') {
                            MySwal.fire({
                                customClass: {
                                    popup: `${admin.admin_seller_add_popup}`
                                },
                                html: `
                                <div>
                                    <div class='${admin.admin_seller_add_title}'><span>반품 수거중 상태로 변경 완료 되었습니다.</span></div>
                                    <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                                    </div>
                                </div>
                                `,
                                showConfirmButton: false,
                                showCancelButton: false,
                            });
                            document.addEventListener('click', sellerAddConfirm);

                            function sellerAddConfirm(e) {
                                if (e.target && e.target.id === 'seller_add_confirm') {
                                    if(roles.isAdmin) {
                                        adimnOrderSelect();
                                    } else {
                                        orderSelect();
                                    }
                                    MySwal.close();
                                    document.removeEventListener('click', sellerAddConfirm);
                                    document.removeEventListener('click', sellerAddSuccess);
                                    document.removeEventListener('click', sellerAddCancel);
                                }
                            }
                        } else {
                            MySwal.fire({
                                customClass: {
                                    popup: `${admin.admin_seller_add_popup}`
                                },
                                html: `
                                <div>
                                    <div class='${admin.admin_seller_add_title}'><span>반품수거중 상태 변경 처리에 실패했습니다.</span></div>
                                    <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                                    </div>
                                </div>
                                `,
                                showConfirmButton: false,
                                showCancelButton: false,
                            });
                            document.addEventListener('click', sellerAddConfirm);

                            function sellerAddConfirm(e) {
                                if (e.target && e.target.id === 'seller_add_confirm') {
                                    if(roles.isAdmin) {
                                        adimnOrderSelect();
                                    } else {
                                        orderSelect();
                                    }
                                    MySwal.close();
                                    document.removeEventListener('click', sellerAddConfirm);
                                    document.removeEventListener('click', sellerAddSuccess);
                                    document.removeEventListener('click', sellerAddCancel);
                                }
                            }
                        }
                    } catch (error) {
                        console.error('error : ', error);
                    }
                }
            }

            function sellerAddCancel(e) {
                if (e.target && e.target.id === 'seller_add_cancel') {
                    MySwal.close();
                    document.removeEventListener('click', sellerAddSuccess);
                    document.removeEventListener('click', sellerAddCancel);
                }
            }
        }
    }
    // 수거완료로 되돌리기
    const handleBackCollectComp = () => {
        if (selectedOrders.length === 0) {
            alert('선택된 반품 주문이 없습니다.');
            return;
        }

        const hasInvalidState = selectedOrders.some(paymentNo => {
            const order = orderList.find(order => order.paymentNo === paymentNo);
            console.log('order : ', order);

            return ['환불진행중', '환불처리실패', '반품수거완료', '반품완료'].includes(order.paymentState);
        });

        const selectedOrderDetails = orderList.filter(order => selectedOrders.includes(order.paymentNo));

        console.log('selectedOrderDetails : ', selectedOrderDetails); // 최종 리스트 확인

        if (hasInvalidState) {
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html: `<div><div class='${admin.admin_seller_add_title}'><span>반품 수거완료로 돌릴 수 있는 상품이 없습니다.</span></div></div>
                <div><div class='${admin.admin_seller_sub_title}'><span>반품요청, 반품승인, 반품수거중인 상태만 되돌릴 수 있습니다.</span></div></div>`,
                showConfirmButton: false,
                showCancelButton: false,
                timer: 2000
            });
            return;
        } else {
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html: `
                    <div>
                        <div class='${admin.admin_seller_add_title}'><span>반품수거완료로 되돌리시겠습니까?</span></div>
                        <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                        <button class='mx-1' id='seller_add_cancel'>아니오</button>
                        <button class='mx-1' id='seller_add_success'>예</button>
                        </div>
                    </div>
                `,
                showConfirmButton: false,
                showCancelButton: false,
            });

            document.addEventListener('click', sellerAddSuccess);
            document.addEventListener('click', sellerAddCancel);

            async function sellerAddSuccess(e) {
                if (e.target && e.target.id === 'seller_add_success') {
                    try {
                        console.log('selectedOrderDetails : ', selectedOrderDetails); // 서버에 보낼 데이터 확인
                        const response = await adminApi.backCollectComp(selectedOrderDetails);
                        const data = response.data;

                        console.log('API Response : ', data); // 서버 응답 확인

                        if (data === 'SUCCESS') {
                            MySwal.fire({
                                customClass: {
                                    popup: `${admin.admin_seller_add_popup}`
                                },
                                html: `
                                <div>
                                    <div class='${admin.admin_seller_add_title}'><span>반품 수거완료 상태로 변경 완료 되었습니다.</span></div>
                                    <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                                    </div>
                                </div>
                                `,
                                showConfirmButton: false,
                                showCancelButton: false,
                            });
                            document.addEventListener('click', sellerAddConfirm);

                            function sellerAddConfirm(e) {
                                if (e.target && e.target.id === 'seller_add_confirm') {
                                    if(roles.isAdmin) {
                                        adimnOrderSelect();
                                    } else {
                                        orderSelect();
                                    }
                                    MySwal.close();
                                    document.removeEventListener('click', sellerAddConfirm);
                                    document.removeEventListener('click', sellerAddSuccess);
                                    document.removeEventListener('click', sellerAddCancel);
                                }
                            }
                        } else {
                            MySwal.fire({
                                customClass: {
                                    popup: `${admin.admin_seller_add_popup}`
                                },
                                html: `
                                <div>
                                    <div class='${admin.admin_seller_add_title}'><span>반품수거완료 상태 변경 처리에 실패했습니다.</span></div>
                                    <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                                    </div>
                                </div>
                                `,
                                showConfirmButton: false,
                                showCancelButton: false,
                            });
                            document.addEventListener('click', sellerAddConfirm);

                            function sellerAddConfirm(e) {
                                if (e.target && e.target.id === 'seller_add_confirm') {
                                    if(roles.isAdmin) {
                                        adimnOrderSelect();
                                    } else {
                                        orderSelect();
                                    }
                                    MySwal.close();
                                    document.removeEventListener('click', sellerAddConfirm);
                                    document.removeEventListener('click', sellerAddSuccess);
                                    document.removeEventListener('click', sellerAddCancel);
                                }
                            }
                        }
                    } catch (error) {
                        console.error('error : ', error);
                    }
                }
            }

            function sellerAddCancel(e) {
                if (e.target && e.target.id === 'seller_add_cancel') {
                    MySwal.close();
                    document.removeEventListener('click', sellerAddSuccess);
                    document.removeEventListener('click', sellerAddCancel);
                }
            }
        }
    }

    const handleDeliveryCompanyChange = (value, paymentNo) => {
        setDeliveryInfo(prev => ({
            ...prev,
            [paymentNo]: {
                ...prev[paymentNo],
                returnDeliveryCompany: value
            }
        }));
    };

    const handleWaybillNumberChange = (e, paymentNo) => {
        setDeliveryInfo(prev => ({
            ...prev,
            [paymentNo]: {
                ...prev[paymentNo],
                returnDeliveryNumber: e.target.value
            }
        }));
    };

    const onReturnBlur = (order, deliveryCompany, deliveryNumber) => {
        console.log('order : ', order);
        console.log('deliveryCompany : ', deliveryCompany);
        console.log('deliveryNumber : ', deliveryNumber);

        console.log('order : ', order);
        console.log('deliveryCompany : ', deliveryCompany);
        console.log('deliveryNumber : ', deliveryNumber);

        if(deliveryCompany === '' || deliveryNumber === '') {
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html: `<div><div class='${admin.admin_seller_add_title}'><span>${order.paymentNumber} 상품의 택배사와 운송장번호를 입력해주세요</span></div></div>`,
                showConfirmButton: false,
                showCancelButton: false,
                timer: 2000
            });
            return;
        } else {

            if(order.returnDeliveryCompany !== null || order.returnDeliveryNumber !== '') {
                // 비어있지 않을 때 로직 처리
            } else {
                    const deliveryProcessing = [];
                
                    deliveryProcessing.push({
                        paymentNo: order.paymentNo,
                        returnDeliveryCompany: deliveryCompany,
                        returnDeliveryNumber: deliveryNumber
                    });
            
                
                    console.log('deliveryProcessing : ', deliveryProcessing);
                    MySwal.fire({
                        customClass: {
                            popup: `${admin.admin_seller_add_popup}`
                        },
                        html: `
                            <div>
                                <div class='${admin.admin_seller_add_title}'><span>${order.paymentNumber} 상품을 반품 수거 하시겠습니까?</span></div>
                                <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                                <button class='mx-1' id='seller_add_cancel'>취소</button>
                                <button class='mx-1' id='seller_add_success'>수거처리</button>
                                </div>
                            </div>
                        `,
                        showConfirmButton: false,
                        showCancelButton: false,
                    });
                    document.addEventListener('click', sellerAddSuccess);
                    document.addEventListener('click', sellerAddCancel);
                
                    async function sellerAddSuccess(e) {
                        if (e.target && e.target.id === 'seller_add_success') {
                            try {
                                const response = await adminApi.deliveryReturnCollectionUpdate(deliveryProcessing);
                                const data = response.data;
                    
                                if (data === 'SUCCESS') {
                                    MySwal.fire({
                                        customClass: {
                                            popup: `${admin.admin_seller_add_popup}`
                                        },
                                        html: `
                                        <div>
                                        <div class='${admin.admin_seller_add_title}'><span>${order.paymentNumber} 상품이 수거 처리 되었습니다.</span></div>
                                        <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                                        </div>
                                        </div>
                                        `,
                                        showConfirmButton: false,
                                        showCancelButton: false,
                                    });
                                    document.addEventListener('click', sellerAddConfirm);
                    
                                    function sellerAddConfirm(e) {
                                        if (e.target && e.target.id === 'seller_add_confirm') {
                                            if(roles.isAdmin) {
                                                adimnOrderSelect();
                                            } else {
                                                orderSelect();
                                            }
                                            MySwal.close();
                                            document.removeEventListener('click', sellerAddConfirm);
                                            document.removeEventListener('click', sellerAddSuccess);
                                            document.removeEventListener('click', sellerAddCancel);
                                        }
                                    }
                                } else {
                                    MySwal.fire({
                                        customClass: {
                                            popup: `${admin.admin_seller_add_popup}`
                                        },
                                        html: `
                                        <div>
                                        <div class='${admin.admin_seller_add_title}'><span>${order.paymentNumber} 상품 수거 처리에 실패했습니다.</span></div>
                                        <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                                        </div>
                                        </div>
                                        `,
                                        showConfirmButton: false,
                                        showCancelButton: false,
                                    });
                                    document.addEventListener('click', sellerAddConfirm);
                    
                                    function sellerAddConfirm(e) {
                                        if (e.target && e.target.id === 'seller_add_confirm') {
                                            if(roles.isAdmin) {
                                                adimnOrderSelect();
                                            } else {
                                                orderSelect();
                                            }
                                            MySwal.close();
                                            document.removeEventListener('click', sellerAddConfirm);
                                            document.removeEventListener('click', sellerAddSuccess);
                                            document.removeEventListener('click', sellerAddCancel);
                                        }
                                    }
                                }
                            } catch (error) {
                                console.error('error : ', error);
                            }
                        }
                    }
                
                    function sellerAddCancel(e) {
                        if (e.target && e.target.id === 'seller_add_cancel') {
                            MySwal.close();
                            document.removeEventListener('click', sellerAddSuccess);
                            document.removeEventListener('click', sellerAddCancel);
                        }
                    }
                }
            }
    }

    // 여기
    const handleEdit = (order) => {
        const info = deliveryInfo[order.paymentNo];
        console.log(`Payment No: ${order.paymentNo}, Delivery Company: ${info?.returnDeliveryCompany}, Waybill Number: ${info?.returnDeliveryNumber}`);

        const deliveryProcessing = [];
                
        deliveryProcessing.push({
            paymentNo: order.paymentNo,
            returnDeliveryCompany: info?.returnDeliveryCompany,
            returnDeliveryNumber: info?.returnDeliveryNumber
        });

    
        console.log('deliveryProcessing : ', deliveryProcessing);
        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html: `
                <div>
                    <div class='${admin.admin_seller_add_title}'><span>${order.paymentNumber} 상품의 배송정보를 수정 하시겠습니까?</span></div>
                    <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                    <button class='mx-1' id='seller_add_cancel'>취소</button>
                    <button class='mx-1' id='seller_add_success'>수정</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });
        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);
    
        async function sellerAddSuccess(e) {
            if (e.target && e.target.id === 'seller_add_success') {
                try {
                    const response = await adminApi.deliveryReturnInfoUpdate(deliveryProcessing);
                    const data = response.data;
        
                    if (data === 'SUCCESS') {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                            <div class='${admin.admin_seller_add_title}'><span>${order.paymentNumber} 상품의 배송정보가 수정 되었습니다.</span></div>
                            <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);
        
                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                if(roles.isAdmin) {
                                    adimnOrderSelect();
                                } else {
                                    orderSelect();
                                }
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    } else {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                            <div class='${admin.admin_seller_add_title}'><span>${order.paymentNumber} 상품의 배송정보 수정에 실패했습니다.</span></div>
                            <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);
        
                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                if(roles.isAdmin) {
                                    adimnOrderSelect();
                                } else {
                                    orderSelect();
                                }
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    }
                } catch (error) {
                    console.error('error : ', error);
                }
            }
        }
    
        function sellerAddCancel(e) {
            if (e.target && e.target.id === 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
            }
        }

    };

    const handleCancelMobileProgress = () => {
        setShowCancelMobileProgressBtn(!showCancelMobileProgressBtn);
    }

    const handleReturnMobileOtherBtn = () => {
        setShowReturnMobileOther(!showReturnMobileOther);
    }

    const renderOrders = () => {
        return currentOrders.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((order, index) => (
            <div className={`${admin.admin_shop_return_content_content}`} key={index}>
                <div><input type="checkbox" checked={selectedOrders.includes(order.paymentNo)} onChange={() => handleSelectOrder(order.paymentNo)} /></div>

                <div className={`${admin.admin_shop_cancel_order_number}`}>
                    <div><span>{order.paymentNumber}</span></div>
                    <div><span>{new Date(order.paymentRegDate).toLocaleString()}</span></div>
                    <div className='py-3'><button><span className={`material-symbols-outlined ${admin.admin_gray_font_2}`}>smartphone</span>{order.deliveryUser}</button></div>
                    <div><button>주문서 출력</button></div>
                </div>

                <div className={`${admin.admin_shop_return_order_product_info}`}>
                    <div>
                        <input 
                            type="checkbox" 
                            // checked={selectedOrders.includes(order.paymentNo)} 
                            // onChange={() => handleSelectOrder(order.paymentNo)} 
                        />
                    </div>
                    <div className='ps-1'><img src={order.paymentImg} alt="주문이미지" /></div>
                    <div className='ps-1'>
                        <div><span>{order.paymentNumber}</span></div>
                        <div><span>{order.paymentProductName}</span></div>
                        <div>
                            {
                                order.options.map((option, index) => (
                                    <div key={index}><span>{option.selectionOptionName} :</span><span> {option.selectionOptionValue}</span></div>
                                ))
                            }
                        </div>
                    </div>
                </div>

                <div className='text-center'><span>{calculateProductPrice(order).toLocaleString()}원</span></div>

                {
                    order.options.length !== 0 &&
                    <div className='text-center'><span>{order.options.reduce((total, option) => total + option.quantity, 0)}</span></div>
                }
                {
                    order.options.length === 0 &&
                    <div className='text-center'><span>{order.totalQuantity}</span></div>
                }

                <div>
                    <div><span>{order.paymentState}</span></div>
                    <div className='py-2'>
                        <div><span>반품요청일</span></div>
                        <div><span>{new Date(order.returnRequestDate).toLocaleString()}</span></div>
                    </div>
                    {order.paymentState === '수거완료' && (
                        <div className='py-2'>
                            <div><span>반품수거완료일</span></div>
                            <div><span>{new Date(order.returnCollectComplateDate).toLocaleString()}</span></div>
                        </div>
                    )}
                </div>

                <div>
                    <div>
                        <div><span>수거방법</span></div>
                        <div><span>{order.returnCollectMethod}</span></div>
                    </div>
                    <div className='py-3'>
                        <div><span>원배송정보</span></div>
                        <div>
                            <div><span>{order.deliveryCompany}</span></div>
                            <div><span>{order.waybillNumber}</span></div>
                            <div><button>조회</button></div>
                        </div>
                    </div>
                        <div className='py-3'>
                            <div><span>수거배송정보</span></div>
                            <div className={`${admin.admin_return_collection_content}`}>
                                <div>
                                    <Select
                                        className={`${admin.admin_order_delivery_select}`}
                                        placeholder='택배사 선택'
                                        onChange={(value) => handleDeliveryCompanyChange(value, order.paymentNo)}
                                        value={deliveryInfo[order.paymentNo]?.returnDeliveryCompany || order.returnDeliveryCompany || undefined}
                                    >
                                        {deliveryCompanies.map((company) => (
                                            <Option key={company.id} value={company.name}>
                                                {company.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                                <div>
                                <input
                                    type="number"
                                    placeholder='송장번호'
                                    onChange={(e) => handleWaybillNumberChange(e, order.paymentNo)}
                                    value={deliveryInfo[order.paymentNo]?.returnDeliveryNumber || order.returnDeliveryNumber || ''}
                                    onBlur={() => onReturnBlur(order, deliveryInfo[order.paymentNo]?.returnDeliveryCompany, deliveryInfo[order.paymentNo]?.returnDeliveryNumber)}
                                />
                                </div>
                            </div>
                            {
                                order.paymentState === '반품요청' || deliveryInfo[order.paymentNo]?.returnDeliveryCompany === '' || deliveryInfo[order.paymentNo]?.returnDeliveryNumber === '' ?
                                null
                                :
                                <div>
                                    <div><button>조회</button></div>
                                    <div><button onClick={() => handleEdit(order)}>수정</button></div>
                                </div>
                            }
                        </div>
                </div>

                <div className={`px-2 ${admin.admin_shop_return_reason_box}`}>
                    <div><span>반품사유</span></div>
                    <div>
                        <div><span>{order.returnType}</span></div>
                        <div><span>{order.returnContent}</span></div>
                    </div>
                    {
                        order.returnRefuseText !== null &&
                        <div className={`py-2 ${admin.admin_shop_return_refuse_box}`}>
                            <div><span>거절사유</span></div>
                            <div>
                                <div><span>{order.returnRefuseText}</span></div>
                            </div>
                        </div>
                    }
                </div>

                <div>
                    <div>
                        <div><span>반품수거지</span></div>
                        <div className='d-flex'>
                            <div><span>{order.deliveryUser}</span></div>
                            <div><span>/</span></div>
                            <div><span>{order.deliveryPhone}</span></div>
                        </div>
                    </div>
                    <div className='py-2'>
                        <div><span>{order.deliveryAddress}</span></div>
                    </div>
                    <div><button>관리자 메모</button></div>
                </div>

                <div className={`px-2 ${admin.admin_shop_return_refund_info}`}>
                    <div>
                        <div><span>총 환불금액</span></div>
                        <div><span>{order.refundAmount.toLocaleString()}원</span></div>
                    </div>
                    <div>
                        <div><span>소계</span></div>
                        <div><span>{calculateSubtotal(order).toLocaleString()}원</span></div>
                    </div>
                    <div>
                        <div><span>배송비 합계</span></div>
                        <div>
                            {
                                order.returnType === '구매자 책임 사유' && order.returnCollectMethod === '수거신청' ?
                                <span>{(order.deliveryPrice*2).toLocaleString()}원</span>
                                :
                                <span>{order.deliveryPrice.toLocaleString()}원</span>
                            }
                        </div>
                    </div>
                </div>

                <div>
                    <div><span>{order.deliveryUser}</span><span>/</span><span>{order.deliveryPhone}</span></div>
                    <div>
                        <div><span>{order.deliveryAddress}</span></div>
                        <div><span>우&#41;{order.zipcode}</span></div>
                    </div>
                </div>

                <div className='px-2'>
                    <div className='d-flex justify-content-between'>
                        <div><span>총 결제금액</span></div>
                        <div><span>{parseInt(order.paymentPrice).toLocaleString()}원</span></div>
                    </div>
                    <div className={`${admin.admin_shop_return_total_box}`}>
                        <div className='d-flex justify-content-between border-bottom'>
                            <div><span>소계</span></div>
                            <div><span>{calculateSubtotal(order).toLocaleString()}원</span></div>
                        </div>
                        <div className='d-flex justify-content-between pt-1'>
                            <div><span>상품금액</span></div>
                            <div><span>{order.principalAmount.toLocaleString()}원</span></div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div><span>배송비</span></div>
                            <div><span>{order.deliveryPrice.toLocaleString()}원</span></div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div><span>포인트</span></div>
                            <div><span>{order.point.toLocaleString()}원</span></div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div><span>쿠폰</span></div>
                            <div><span>{order.coupon.toLocaleString()}원</span></div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div><span>결제방법</span></div>
                            <div className='text-end'>
                                <div><span>{order.paymentType}</span></div>
                                <div><span>{order.paymentBankName}({order.deliveryUser})</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ));
    };

    return (
        <div className={`${admin.admin_shop_return_box} px-3 py-3`}>
            <div className={`${admin.admin_shop_cancel_header}`}><span>반품</span></div>
            <div className={`${admin.admin_shop_return_tap}`}>
                <Swiper
                    slidesPerView={'auto'}
                    spaceBetween={10}
                    freeMode={true}
                    className={`${admin.admin_shop_product_tap_swiper}`}
                    >
                        <SwiperSlide>
                            <div className={`${activeButton === 'newReturn' ? admin.admin_shop_cancel_tap_active : ''}`}><button onClick={() => setActiveButton('newReturn')}>신규반품 ({getFilteredOrders('newReturn').length})</button></div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={`${activeButton === 'requestReturn' ? admin.admin_shop_cancel_tap_active : ''}`}><button onClick={() => setActiveButton('requestReturn')}>반품요청 ({getFilteredOrders('requestReturn').length})</button></div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={`${activeButton === 'returnApproval' ? admin.admin_shop_cancel_tap_active : ''}`}><button onClick={() => setActiveButton('returnApproval')}>반품승인 ({getFilteredOrders('returnApproval').length})</button></div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={`${activeButton === 'collecting' ? admin.admin_shop_cancel_tap_active : ''}`}><button onClick={() => setActiveButton('collecting')}>반품수거중 ({getFilteredOrders('collecting').length})</button></div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={`${activeButton === 'completeCollectiong' ? admin.admin_shop_cancel_tap_active : ''}`}><button onClick={() => setActiveButton('completeCollectiong')}>반품수거완료 ({getFilteredOrders('completeCollectiong').length})</button></div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={`${activeButton === 'progressReturn' ? admin.admin_shop_cancel_tap_active : ''}`}><button onClick={() => setActiveButton('progressReturn')}>환불진행중 ({getFilteredOrders('progressReturn').length})</button></div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={`${activeButton === 'failReturn' ? admin.admin_shop_cancel_tap_active : ''}`}><button onClick={() => setActiveButton('failReturn')}>환불처리실패 ({getFilteredOrders('failReturn').length})</button></div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={`${activeButton === 'completeReturn' ? admin.admin_shop_cancel_tap_active : ''}`}><button onClick={() => setActiveButton('completeReturn')}>반품완료 ({getFilteredOrders('completeReturn').length})</button></div>
                        </SwiperSlide>
                </Swiper>
            </div>
            <div className={`${admin.admin_shop_cancel_progress_btn}`}>
                <div><button onClick={handleCollection}>수거지시</button></div>
                <div className='ps-1'><button onClick={handleCollectionComp}>반품수거 완료</button></div>
                <div className='ps-1'><button onClick={handleCompleteReturn}>반품완료 처리</button></div>
                <div className='ps-1'><button onClick={handleReturnApproval}>반품 승인</button></div>
                <div className='ps-1'><button onClick={handleReturnRefuse}>반품 거부</button></div>
                <div className='ps-1 position-relative'>
                    <div><button className='d-flex align-items-center' onClick={onProgress}>기타 반품처리<span className={`material-symbols-outlined ${admin.admin_gray_font_2}`}>arrow_drop_down</span></button></div>
                    <CSSTransition in={returnProgress} timeout={300} classNames="review" unmountOnExit>
                        <div className={`${admin.admin_shop_cancel_progress_other_box}`}>
                            <div><button onClick={handleRefundProcessing}>환불처리중으로 되돌리기</button></div>
                            <div><button onClick={handleReturnRequest}>반품요청으로 되돌리기</button></div>
                            <div><button onClick={handleBackCollecting}>수거중으로 되돌리기</button></div>
                            <div><button onClick={handleBackCollectComp}>수거완료로 되돌리기</button></div>
                            <div><button>자동환불 재시도</button></div>
                            <div><button onClick={handleForciblyCompleteReturn}>강제 반품(환불) 완료 처리</button></div>
                        </div>
                    </CSSTransition>
                </div>
            </div>
            <div className={`${admin.admin_shop_return_progress_mobile_btn}`}>
                <div><button onClick={handleCancelMobileProgress}>상태 버튼</button></div>
                {
                    showCancelMobileProgressBtn &&
                    <div className={`${admin.admin_shop_return_progress_mobile_btn_content}`}>
                        <div><button onClick={handleCollection}>수거지시</button></div>
                        <div><button onClick={handleCollectionComp}>반품수거 완료</button></div>
                        <div><button onClick={handleCompleteReturn}>반품완료 처리</button></div>
                        <div><button onClick={handleReturnApproval}>반품 승인</button></div>
                        <div><button onClick={handleReturnRefuse}>반품 거부</button></div>
                        <div>
                            <div><button onClick={handleReturnMobileOtherBtn}>기타 반품처리<span className={`material-symbols-outlined ${admin.admin_gray_font_2}`}>arrow_drop_down</span></button></div>
                            {
                                showReturnMobileOther &&
                                <div>
                                    <div><button onClick={handleRefundProcessing}>환불처리중으로 되돌리기</button></div>
                                    <div><button onClick={handleReturnRequest}>반품요청으로 되돌리기</button></div>
                                    <div><button onClick={handleBackCollecting}>수거중으로 되돌리기</button></div>
                                    <div><button onClick={handleBackCollectComp}>수거완료로 되돌리기</button></div>
                                    <div><button>자동환불 재시도</button></div>
                                    <div><button onClick={handleForciblyCompleteReturn}>강제 반품(환불) 완료 처리</button></div>
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
            <div className={`${admin.admin_shop_return_content_box}`}>
                <div>
                    <div className={`${admin.admin_shop_return_content_header}`}>
                        <div><input type="checkbox" checked={selectedOrders.length === currentOrders.length} onChange={handleSelectAll} /></div>
                        <div><span>주문번호/시각</span></div>
                        <div><span>주문상품</span></div>
                        <div><span>상품금액</span></div>
                        <div><span>수량</span></div>
                        <div><span>주문상태</span></div>
                        <div><span>수거방법</span></div>
                        <div><span>반품/보류 사유</span></div>
                        <div><span>수거지 정보</span></div>
                        <div><span>환불정보</span></div>
                        <div><span>배송정보</span></div>
                        <div><span>결제금액</span></div>
                    </div>
                    {renderOrders()}
                </div>
            </div>

            <Pagination
                current={currentPage}
                total={totalItems}
                pageSize={itemsPerPage}
                onChange={(page) => setCurrentPage(page)}
            />
            {
                showReturnRefuse &&
                <div className={`${admin.admin_shop_cancel_refuse_modal_container}`}>
                    <div className={`${admin.admin_shop_cancel_refuse_modal_box}`}>
                        <div className={`${admin.admin_shop_cancel_refuse_modal_header}`}>
                            <div><span>반품거절사유</span></div>
                            <div><button onClick={() => setShowReturnRefuse(false)}><span className="material-symbols-outlined">close</span></button></div>
                        </div>
                        <div className={`${admin.admin_shop_cancel_refuse_modal_txt}`}>
                            <div><span>*선택하신 {refuseList && refuseList.length}개의 반품 주문을 처리합니다.</span></div>
                        </div>
                        <div className={`${admin.admin_shop_cancel_refuse_modal_content}`}>
                            <div>
                                <div>
                            {
                                Array.isArray(refuseList) && refuseList.map((item, index) => (
                                    <div key={index} className={`${admin.admin_shop_cancel_refuse_modal_refuse_box}`}>
                                        <div className={`${admin.admin_shop_cancel_refuse_modal_refuse_info}`}>
                                            <div className={`${admin.admin_shop_cancel_refuse_modal_refuse_order}`}>
                                                <div><span>주문정보</span></div>
                                                <div>
                                                    <div><img src={item.paymentImg} alt="" /></div>
                                                    <div>
                                                        <div><span>{item.paymentProductNumber}</span></div>
                                                        <div><span>{item.paymentProductName}</span></div>
                                                        <div><span>{parseInt(item.paymentPrice).toLocaleString()}원</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`${admin.admin_shop_cancel_refuse_modal_refuse_cancel}`}>
                                                <div><span>반품정보</span></div>
                                                <div>
                                                    <div className={`${admin.admin_shop_cancel_refuse_modal_refuse_cancel_info}`}>
                                                        <div><span>주문번호</span></div>
                                                        <div><span>{item.paymentNumber}</span></div>
                                                    </div>
                                                    <div className={`${admin.admin_shop_cancel_refuse_modal_refuse_cancel_info}`}>
                                                        <div><span>반품타입</span></div>
                                                        <div><span>{item.returnType}</span></div>
                                                    </div>
                                                    <div className={`${admin.admin_shop_cancel_refuse_modal_refuse_cancel_info}`}>
                                                        <div><span>반품사유</span></div>
                                                        <div><span>{item.returnContent}</span></div>
                                                    </div>
                                                    <div className={`${admin.admin_shop_cancel_refuse_modal_refuse_cancel_info}`}>
                                                        <div><span>환불금액</span></div>
                                                        <div><span>{parseInt(item.refundAmount).toLocaleString()}원</span></div>
                                                    </div>
                                                    <div className={`${admin.admin_shop_cancel_refuse_modal_refuse_cancel_info}`}>
                                                        <div><span>반품요청일</span></div>
                                                        <div><span>{new Date(item.paymentUpdDate).toLocaleString()}</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`${admin.admin_shop_cancel_refuse_modal_refuse_textarea}`}>
                                            <div><span>반품 거절사유</span></div>
                                            <div><textarea name="" id="" onChange={(e) => onReturnRefuseText(e, item)}></textarea></div>
                                        </div>
                                    </div>
                                ))
                            }
                                </div>
                            </div>
                        </div>
                        <div className={`${admin.admin_shop_cancel_refuse_modal_footer}`}>
                            <div><button onClick={() => setShowReturnRefuse(false)}>취소</button></div>
                            <div><button onClick={() => onReturnRefuseBtn()}>반품거절</button></div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default AdminReturn;
