import React, { useState, useEffect } from 'react';
import { recommend } from '../../apis/auth';
import {checkNickName} from '../../apis/auth'
import { useNavigate } from 'react-router-dom';
import * as auth from '../../apis/auth';


const RecomMender = ( { setShowAgree, setShowJoin, setShowJoinInfo, setShowJoinFavority, setShowRecommend,
                      showRecommend, recommendTransition, animated, selectedItems, userList} ) => {

    const [inputValue, setInputValue] = useState('');
    const [message, setMessage] = useState('');
    const [color, setColor] = useState('black');
    const [borderColor, setBorderColor] = useState('');
    const [isValid, setIsValid] = useState(false); // 유효한 추천 코드인지의 여부를 저장할 상태
    const [email, setEmail] = useState(userList.userEmail ? userList.userEmail : '');
    const [userId, setUserId] = useState(userList.userId ? userList.userId : '');
    const [favorityList, setFavorityList] = useState([
      {
        no : '',
        userId: '',
        favorityName : '',
        favorityDday : '',
        favorityImgUrl : '',
      }
    ]);

  
    useEffect(() => {
      const userEmail = email.split('@');
      // 먼저, 새로운 아이템 배열을 생성합니다.
      const newItems = selectedItems.map(item => ({
        no: item.artistNo,
        userId: userId,
        favorityName: item.name,
        favorityDday: item.dday,
        favorityImgUrl: item.imgUrl,
      }));
    
      // 그 다음, setFavorityList를 사용하여 상태를 업데이트합니다.
      // 이 때, 초기 상태가 빈 배열이 아닌, 하나의 빈 객체를 포함하는 배열로 되어있기 때문에,
      // 초기 상태를 빈 배열로 변경하거나, 아래 코드를 조금 수정해야 합니다.
      setFavorityList(newItems);
    }, []);

    useEffect(() => {
      // console.log(favorityList);
    }, [favorityList]);

    // join을 위한 객체 재구성
    const [ joinObject, setJoinObject ] = useState({});

    useEffect(() => {
      // birth를 'year-month-day' 형식의 문자열로 재구성
      const birth = `${userList.userYear}-${userList.userMonth}-${userList.userDay}`;

      const userId = userList.userEmail.split('@')[0];
  
      // joinObject에 넣을 새로운 객체 구성
      const newJoinObject = {
          userName: userList.userName,
          userId: userList.userId,
          userEmail: userList.userEmail,
          userPw: userList.userPw,
          userRepublic: userList.userRepublic,
          userTel: userList.userTel,
          userNick: userList.userNick,
          userGender: userList.userGender,
          userBirth: birth, // 재구성한 birth를 새로운 속성으로 추가
          recode: inputValue,
      };
  
      // setJoinObject를 사용하여 joinObject 상태 업데이트
      setJoinObject(newJoinObject);
  }, [inputValue]);

  // // console.log(`userName: ${joinObject.userName}, userId: ${joinObject.userId}, userEmail: ${joinObject.userEmail}, userPw: ${joinObject.userPw}, 
  // userRepublic: ${joinObject.userRepublic}, userTel: ${joinObject.userTel}, userNick: ${joinObject.userNick}, 
  // userGender: ${joinObject.userGender}, recode: ${joinObject.recode}`);
    

    const success = useNavigate();

    const joinSuccess = () => {
      joinProcess();
    }
    const joinSkip = async () => {
      const response = await auth.join(joinObject);
      const data = await response.data;

      if(data === 'SUCCESS') {

        if(favorityList != null) {
          const response2 = await auth.favorityAdd(favorityList);
          const data2 = await response2.data;
        }
        setShowAgree(false);
        setShowJoin(false);
        setShowJoinInfo(false);
        setShowJoinFavority(false);
        setShowRecommend(false);

        success('/market', { state: {key: 'test2'}});
        
      } else {
        alert(`Server Error ${data}`);
      }
    }
    
    const joinProcess = async () => {
      const response = await auth.join(joinObject);
      const data = await response.data;

      
      if(data === 'SUCCESS') {

        if(favorityList != null) {
          const response2 = await auth.favorityAdd(favorityList);
          const data2 = await response2.data;
        }
        setShowAgree(false);
        setShowJoin(false);
        setShowJoinInfo(false);
        setShowJoinFavority(false);
        setShowRecommend(false);

        success('/market', { state: {key: 'test1'}});
      } else {
        alert(`Server error ${data}`);
      }
    }
  
    const recommendInput = (e) => {
      // // console.log(e.target.value);
      setInputValue(e.target.value);
    }
  
    const handleRecommendBack = () => {
      setShowRecommend(false);
    }
  
    const handleFRecommendClose = () => {
      setShowAgree(false);
      setShowJoin(false);
      setShowJoinInfo(false);
      setShowJoinFavority(false);
      setShowRecommend(false);
    }
  
    useEffect(() => {
      if (inputValue.trim() !== '') {
        const timer = setTimeout(() => {
          checkNickName(inputValue)
            .then(res => {
              // console.log('res.data : ', res.data);
              if (res.data.userNick) {
                setMessage('존재하는 사용자의 닉네임입니다.');
                setColor('green');
                setBorderColor("green");
                setIsValid(true); // 유효한 추천 코드일 때 true로 설정
              } else {
                setMessage('존재하지 않는 사용자의 닉네임입니다.');
                setColor('rgba(255, 92, 92, 1)');
                setBorderColor("rgba(255, 92, 92, 1)");
                setIsValid(false); // 유효하지 않은 추천 코드일 때 false로 설정
              }
            })
            .catch(err => {
              console.error(err);
            });
        }, 1000);
  
        return () => clearTimeout(timer);
      } else {
        setMessage('');
        setColor('black');
        setBorderColor('');
        setIsValid(false); // 입력값이 없을 때 false로 설정
      }
    }, [inputValue]);
  return (
    <>
    {showRecommend && (
            <div className='recommender_container'>
                {recommendTransition((style, item) =>
                    item ? (
                        <animated.div className='recommender_detail_box' style={style}>
                          <div className='recommender_detail_header'>
                              <div>
                                <button onClick={() => handleRecommendBack()}>
                                    <span className='material-symbols-outlined'>arrow_back_ios</span>
                                </button>
                              </div>
                              <div>
                                <span>추천인 등록</span>
                              </div>
                              <div>
                                <button onClick={(e) => handleFRecommendClose(e)}>
                                    <span className='material-symbols-outlined'>close</span>
                                </button>
                              </div>
                          </div>
                          <div className='mx-3 pt-4'>
                            <div className='recommend_title_box'><span>추천인 닉네임 입력</span></div>
                            <div className='recommend_input_box'>
                              <input type="text" placeholder='추천인 닉네임을 입력하세요.' onChange={recommendInput} style={{ borderColor: borderColor }}/>
                            </div>
                            <div><span style={{color : color}}>{message}</span></div>
                          </div>
                          <div className='recommend_btn_box'>
                          {inputValue ? (
                            <div><button className={`${isValid ? '' : 'recommend_btn_box_gray'}`} disabled={!isValid} onClick={joinSuccess}>완료</button></div>
                          ) : (
                            <div><button onClick={joinSkip}>건너뛰기</button></div>
                          )}
                          </div>
                        </animated.div>
                    ) : null
                )}
            </div>
      )}
                
    </>
  )
}

export default RecomMender