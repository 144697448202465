import React from 'react'
import PlanPaymentContainer from '../../components/event/PlanPaymentContainer'
import PlanPaymentHeader from '../../components/event/PlanPaymentHeader'

const PlanPaymentScreen = () => {
  return (
    <>
      <div className='payment'>
        <PlanPaymentHeader />
        <PlanPaymentContainer />
      </div>
    </>
  )
}

export default PlanPaymentScreen