import React, { useContext, useEffect, useState } from 'react';
import { animated } from 'react-spring';
import { useTransition } from '@react-spring/core';
import { Select } from 'antd';
import { CSSTransition } from 'react-transition-group';
import dailyduck from '../../containers/my/MyContainner.module.css';
import { useLocation, useNavigate } from 'react-router-dom';
import * as admin from '../../apis/admin';
import * as auth from '../../apis/auth';
import * as storeapi from '../../apis/store';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { loadTossPayments, ANONYMOUS } from '@tosspayments/tosspayments-sdk';
import { LoginContext } from '../../contexts/LoginContextProvider';

const { Option } = Select;
const generateRandomString = () => window.btoa(Math.random()).slice(0, 20);

const Exchange = () => {
    const MySwal = withReactContent(Swal);
    const navigate = useNavigate();
    const location = useLocation();
    const orderInfo = location.state?.order;
    const {userInfo} = useContext(LoginContext);
    const [activeRefundState, setActiveRefundState] = useState('');
    const [user, setUser] = useState(null);
    const [showBuyerBox, setShowBuyerBox] = useState(false);
    const [showSellerBox, setShowSellerBox] = useState(false);
    const [buyerSelect, setBuyerSelect] = useState('');
    const [sellerSelect, setSellerSelect] = useState('');
    const [exchangeContent, setExchangeContent] = useState({
        paymentNo: orderInfo?.paymentNo || null,
        exchangeType: '',
        exchangeContent: '',
        exchangeDeliveryPrice: 0,
        exchangeCollectMethod: '',
        exchangeDeliveryCompany: '',
        exchangeDeliveryNumber: '',
        exchangeOptionName: '',
        exchangeOptionValue: '',
        productAddPrice: 0,
        exchangeAddTotalPrice: 0
    });
    const [refundMethodSelect, setRefundMethodSelect] = useState('');
    const [agreeCheck, setAgreeCheck] = useState(false);
    const [deliveryCompanies, setDeliveryCompanies] = useState([]);
    const [sellerRefundAddress, setSellerRefundAddress] = useState(null);
    const [shopInfo, setShopInfo] = useState(null);
    const [optionNameList, setOptionNameList] = useState([]);
    const [optionValueList, setOptionValueList] = useState([]);
    const [selectedOptionName, setSelectedOptionName] = useState('');
    const [selectedOptionValue, setSelectedOptionValue] = useState('');
    const [totalExchangePrice, setTotalExchangePrice] = useState(0);

    // 토스페이먼츠 붙힐 때
    // const [ready, setReady] = useState(false);
    // const [widgets, setWidgets] = useState(null);
    // const [amount, setAmount] = useState({
    //     currency: "KRW",
    //     value: 50000,
    // });

    const selectedOption = optionValueList.find(
        (option) => option.optionName === selectedOptionName && option.optionValueName === selectedOptionValue
    );

    // 토스페이먼츠 붙힐 때
    // useEffect(() => {
    //     if (ready) {
    //         widgets.setAmount({
    //             currency: "KRW",
    //             value: totalExchangePrice,
    //         });
    //     }
    // }, [totalExchangePrice, ready]);

    useEffect(() => {
        userSelect();
    }, []);

    // 토스페이먼츠 붙힐 때
    // useEffect(() => {
    //     if(activeRefundState === 'buyer') {
    //         fetchPaymentWidgets();
    //     }
    // }, [activeRefundState]);

    // const fetchPaymentWidgets = async () => {
    //     const tossPayments = await loadTossPayments(process.env.REACT_APP_TOSS_CLIENT_KEY);
    //     const widgets = tossPayments.widgets({ customerKey: ANONYMOUS });
    //     setWidgets(widgets);
    // };

    // useEffect(() => {
    //     const renderPaymentWidgets = async () => {
    //         if (widgets == null) {
    //             return;
    //         }

    //         try {
    //             await widgets.setAmount(amount);

    //             await widgets.renderPaymentMethods({
    //                 selector: "#payment-method",
    //                 variantKey: "DEFAULT",
    //             });

    //             await widgets.renderAgreement({
    //                 selector: "#agreement",
    //                 variantKey: "AGREEMENT",
    //             });

    //             setReady(true);
    //         } catch (error) {
    //             if (error.name === 'AlreadyWidgetRenderedError') {
    //                 widgets.cleanup();
    //                 await renderPaymentWidgets();
    //             } else {
    //                 console.error(error);
    //             }
    //         }
    //     };

    //     renderPaymentWidgets();
    // }, [widgets]);

    const userSelect = async () => {
        if (userInfo?.userId) {
            const response = await auth.userselect(userInfo.userId);
            const data = response.data;

            setUser(data);
        }
    };


    useEffect(() => {
        // console.log('orderInfo : ', orderInfo);

        deliveryCompanySelect();

        const sellerRefundSelect = async () => {
            const response = await admin.sellerRefundSelect(orderInfo?.paymentProductNumber);
            const data = response.data;

            // console.log('data : ', data);
            setSellerRefundAddress(data);
        };

        sellerRefundSelect();

        const shopSelect = async () => {
            const response = await storeapi.sellerShopSelect(orderInfo?.sellerId);
            const data = response.data;

            // console.log('shop : ' , data);
            setShopInfo(data);
        }
        shopSelect();

        const productSelect = async () => {
            const response = await storeapi.productRead(orderInfo?.paymentProductNumber);
            const data = response.data;

            // console.log('productRead : ', data);
            setOptionNameList(data.optionNameList);
            setOptionValueList(data.optionValueList);
        }

        productSelect();
        
    }, [orderInfo]);

    useEffect(() => {
        calculateRefundAmount();
    }, [activeRefundState, refundMethodSelect, selectedOptionValue]);

    useEffect(() => {
        const productAddPrice = calculateProductAddPrice();

        
            if(activeRefundState === 'buyer' && refundMethodSelect === 'type2') {
                setTotalExchangePrice(productAddPrice);
                setExchangeContent(prevState => ({
                    ...prevState, productAddPrice: productAddPrice - orderInfo.deliveryPrice
                }));
            } else if(activeRefundState === 'buyer' && refundMethodSelect === 'type1') {
                setTotalExchangePrice(productAddPrice);
                setExchangeContent(prevState => ({
                    ...prevState, productAddPrice: productAddPrice - (orderInfo.deliveryPrice*2)
                }));
            } else if(activeRefundState === 'seller') {
                setTotalExchangePrice(productAddPrice);
                setExchangeContent(prevState => ({
                    ...prevState, productAddPrice: productAddPrice
                }));
            }
        

    }, [activeRefundState, refundMethodSelect, selectedOption]);

    const transitions = useTransition(showBuyerBox, {
        from: { transform: "translateY(100%)" },
        enter: { transform: "translateY(0)" },
        leave: { transform: "translatey(100%)" },
        config: {
            duration: 200,
        },
    });
    const sellerTransitions = useTransition(showSellerBox, {
        from: { transform: "translateY(100%)" },
        enter: { transform: "translateY(0)" },
        leave: { transform: "translatey(100%)" },
        config: {
            duration: 200,
        },
    });

    const handleMyRefundBack = () => {
        navigate(-1);
    }

    const [openStates, setOpenStates] = useState(false);
    const toggleOpen = () => {setOpenStates(!openStates);};

    const onBuyerSelectBtn = (text) => {
        setBuyerSelect(text);
        setExchangeContent(prevState => ({
            ...prevState, exchangeContent: text
        }));
        setShowBuyerBox(false);
    }

    const onSellerSelectBtn = (text) => {
        setSellerSelect(text);
        setExchangeContent(prevState => ({
            ...prevState, exchangeContent: text
        }));
        setShowSellerBox(false);
    }

    const onDirectRefund = (e) => {
        // console.log(e.target.value);
        setExchangeContent(prevState => ({
            ...prevState, exchangeContent: e.target.value
        }));
    }

    const calculateTotalPrice = () => {
        if(orderInfo.eventType === 'plan' && orderInfo.planProductRate !== 0) {
            return orderInfo.options.reduce((total, option) => {
                const optionTotal = ((parseInt(orderInfo.cost) - (parseInt(orderInfo.cost)*(orderInfo.planProductRate*0.01))) * option.quantity) + (option.addPrice * option.quantity);
                return total + optionTotal;
            }, 0).toLocaleString();
        } else {
            return orderInfo.options.reduce((total, option) => {
                const optionTotal = (parseInt(orderInfo.principalAmount) * option.quantity) + (option.addPrice * option.quantity);
                return total + optionTotal;
            }, 0).toLocaleString();
        }
    };

    const onRefundMethod = (e) => {
        // console.log(e.target.id);
        setRefundMethodSelect(e.target.id);
        let method = ''
        if(e.target.id === 'type1') {
            method = '수거신청';
        } else {
            method = '직접배송'
        }
        setExchangeContent(prevState => ({
            ...prevState, exchangeCollectMethod: method
        }));
    }

    const onAgreeCheck = (e) => {
        setAgreeCheck(e.target.checked);
    }

    const deliveryCompanySelect = async () => {
        try {
            const response = await fetch('https://apis.tracker.delivery/carriers');
            const data = await response.json();

            // console.log('delivery : ', data);
            setDeliveryCompanies(data);
        } catch (error) {
            console.error('Error fetching delivery companies:', error);
        }
    };

    const copyToClipboard = () => {
        const textToCopy = `${sellerRefundAddress.dtShippingAddress}, ${sellerRefundAddress.dtShippingAddressDetail}`;
        navigator.clipboard.writeText(textToCopy).then(() => {
            alert('교환주소지가 클립보드에 복사되었습니다.');
        }).catch(err => {
            console.error('Failed to copy: ', err);
        });
    };

    const handleDeliveryCompanyChange = (value) => {
        setExchangeContent(prevState => ({
            ...prevState, exchangeDeliveryCompany: value
        }));
    };

    const onDeliveryNumber = (e) => {
        setExchangeContent(prevState => ({
            ...prevState, exchangeDeliveryNumber: e.target.value
        }));
    }

    const calculateRefundAmount = () => {
        let exchangeDeliveryPrice = 0;
        if (activeRefundState === 'buyer' && refundMethodSelect === 'type2') {
            exchangeDeliveryPrice = orderInfo.deliveryPrice;
        } else if (activeRefundState === 'buyer' && refundMethodSelect === 'type1') {
            exchangeDeliveryPrice = (orderInfo.deliveryPrice * 2);
        } else if (activeRefundState === 'seller') {
            exchangeDeliveryPrice = 0;
        }
        setExchangeContent(prevState => ({
            ...prevState, exchangeDeliveryPrice: exchangeDeliveryPrice
        }));
    };
    
    const calculateProductAddPrice = () => {
        let productAddPrice  = 0;

        if(optionNameList.length !== 0 && optionValueList.length !== 0) {
            if(activeRefundState === 'buyer' && refundMethodSelect === 'type2') {
                productAddPrice = orderInfo.deliveryPrice + selectedOption?.optionPrice;
            } else if(activeRefundState === 'buyer' && refundMethodSelect === 'type1') {
                productAddPrice = (orderInfo.deliveryPrice * 2) + selectedOption?.optionPrice;
            } else if(activeRefundState === 'seller') {
                productAddPrice = selectedOption?.optionPrice;
            }
    
            return productAddPrice;
        } else {
            if(activeRefundState === 'buyer' && refundMethodSelect === 'type2') {
                productAddPrice = orderInfo.deliveryPrice;
            } else if(activeRefundState === 'buyer' && refundMethodSelect === 'type1') {
                productAddPrice = (orderInfo.deliveryPrice * 2);
            } else if(activeRefundState === 'seller') {
                productAddPrice = 0;
            }
    
            return productAddPrice;
        }
    }

    const returnCheck = () => {
        return exchangeContent.exchangeType !== '' && exchangeContent.exchangeContent !== '' && exchangeContent.exchangeCollectMethod !== '' && exchangeContent.exchangeDeliveryPrice !== 0 && agreeCheck
    }

    const onReturnAdd = async () => {
        // console.log('exchangeContent : ', exchangeContent);
        MySwal.fire({
            customClass: {
                popup: `${dailyduck.order_cancel_swal_popup}`
            },
            html : 
            `
                <div>
                    <div class='${dailyduck.order_cancel_title}'><span>교환을 신청 하시겠습니까?</span></div>
                    <div class='${dailyduck.order_cancel_btn_box} py-2 d-flex w-100 justify-content-between'>
                      <button class='mx-1' id='seller_add_cancel'>아니요</button>
                      <button class='mx-1' id='seller_add_success'>네</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });
        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);

        async function sellerAddSuccess(e) {
            if(e.target && e.target.id == 'seller_add_success') {
                try {
                    // 토스페이먼츠 붙힐 때
                    // if (ready && widgets) {
                    //     const orderId = generateRandomString();

                    //     const updatePaymentInfo = {
                    //         ...exchangeContent,
                    //         exchangeAddTotalPrice : totalExchangePrice
                    //     }
        
                    //     localStorage.setItem('exchangeContent', JSON.stringify(updatePaymentInfo));
        
                    //     await widgets.requestPayment({
                    //         orderId : orderId,
                    //         orderName: orderInfo.paymentProductName,
                    //         customerName: user.userName,
                    //         customerEmail: user.userEmail,
                    //         successUrl: `${window.location.origin}/exchange/payment/successcomplete`,
                    //         failUrl: `${window.location.origin}/payment/fail`,
                    //     });
                    // }
                    const response = await auth.exchangeApply(exchangeContent);
                    const data = response.data;
            
                    // console.log(data);
        
                    if(data === 'SUCCESS') {
                        MySwal.fire({
                            customClass: {
                                popup: `${dailyduck.order_cancel_swal_popup}`
                            },
                            html : 
                            `
                            <div>
                            <div class='${dailyduck.order_cancel_title}'><span>교환 신청이 완료 되었습니다.</span></div>
                            <div class='${dailyduck.order_cancel_sub_title}'><span>구매자 귀책 사유의 경우 배송비 입금여부에 따라 교환 일정이 달라집니다.</span></div>
                            <div class='${dailyduck.order_cancel_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);

                        
                        function sellerAddConfirm(e) {
                            if(e.target && e.target.id == 'seller_add_confirm') {
                                MySwal.close();
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                                document.removeEventListener('click', sellerAddConfirm);
                                navigate(-1);
                            }
                        }
                    }
                    
                } catch (error) {
                    console.error('cancel error : ', error);
                    alert(error);
                }
                
                
            }
        }
        
        function sellerAddCancel(e) {
            if(e.target && e.target.id == 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
            }
        }
    }

    const handleOptionNameChange = (value) => {
        setSelectedOptionName(value);
        setExchangeContent(prevState => ({
            ...prevState,
            exchangeOptionName: value,
            exchangeOptionValue: '' // Reset the value when a new name is selected
        }));
    };

    const handleOptionValueChange = (value) => {
        setSelectedOptionValue(value);
        setExchangeContent(prevState => ({
            ...prevState,
            exchangeOptionValue: value
        }));
    };

    return (
        <>
            <div className={`${dailyduck.myrefund_container}`}>
                <div className={dailyduck.myrefund_box}>
                    <div className={dailyduck.my_refund_header}>
                        <div>
                            <button onClick={() => handleMyRefundBack()} className='reservation_pay_close'>
                                <span className="material-symbols-outlined">arrow_back_ios</span>
                            </button>
                        </div>
                        <div>
                            <div className='text-center py-4'>
                                <span>교환 신청</span>
                            </div>
                        </div>
                    </div>
                    <div className={`${dailyduck.my_order_exchange_content}`}>
                        <div className=''>
                            <div>
                                <div className={`d-flex pb-3 ${dailyduck.my_order_detail_box}`}>
                                    <div className={`${dailyduck.my_exchange_img}`}><img src={orderInfo.paymentImg} alt="앨범이미지" /></div>
                                    <div className={`${dailyduck.my_order_detail_info}`}>
                                        <div><span>{orderInfo.shopName}</span></div>
                                        <div><span>{orderInfo.paymentProductName}</span></div>
                                        <div>
                                            {
                                                orderInfo.options.map((option, index) => (
                                                    <div key={index}><span>{option.selectionOptionName} :</span><span> {option.selectionOptionValue} /</span><span> {option.quantity}개</span></div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                optionNameList.length !== 0 && optionValueList.length !== 0 &&
                                <div className={`${dailyduck.my_order_exchange_option_box}`}>
                                    <div><span>교환 옵션</span></div>
                                    <div>
                                        <div>
                                            <Select
                                                value={selectedOptionName}
                                                onChange={handleOptionNameChange}
                                                placeholder="옵션 선택"
                                                style={{ width: '100%' }}
                                                className={`${dailyduck.refund_method_delivery_select}`}
                                            >
                                                {optionNameList.map((option) => (
                                                    <Option key={option.optionNo} value={option.optionName}>
                                                        {option.optionName}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </div>
                                        {selectedOptionName && (
                                            <div className='mt-2'>
                                                <Select
                                                    value={selectedOptionValue}
                                                    onChange={handleOptionValueChange}
                                                    placeholder="세부 옵션 선택"
                                                    style={{ width: '100%' }}
                                                    className={`${dailyduck.refund_method_delivery_select}`}
                                                >
                                                    {optionValueList
                                                        .filter((option) => option.optionName === selectedOptionName)
                                                        .map((option) => (
                                                            <Option key={option.optionValueNo} value={option.optionValueName}>
                                                                {option.optionValueName}
                                                            </Option>
                                                        ))}
                                                </Select>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            }
                            <div className={`${dailyduck.my_order_cancel_content_cancellation}`}>
                                <div><span>교환 사유</span></div>
                                <div>
                                    <div><button className={activeRefundState === 'buyer' ? dailyduck.my_order_cancel_content_cancellation_active : ''} onClick={() => {setActiveRefundState('buyer'); setExchangeContent({...exchangeContent, exchangeType : '구매자 책임 사유', exchangeContent : ''}); setBuyerSelect(''); setSellerSelect('')}}>구매자 책임 사유</button></div>
                                    <div><button className={activeRefundState === 'seller' ? dailyduck.my_order_cancel_content_cancellation_active : ''} onClick={() => {setActiveRefundState('seller'); setExchangeContent({...exchangeContent, exchangeType : '판매자 책임 사유', exchangeContent : ''}); setBuyerSelect(''); setSellerSelect('')}}>판매자 책임 사유</button></div>
                                    <div><button className={activeRefundState === 'direct' ? dailyduck.my_order_cancel_content_cancellation_active : ''} onClick={() => {setActiveRefundState('direct'); setExchangeContent({...exchangeContent, exchangeType : '사유 직접 입력', exchangeContent : ''}); setBuyerSelect(''); setSellerSelect('')}}>사유 직접 입력</button></div>
                                </div>
                                <div>
                                    {
                                        activeRefundState === 'buyer' &&
                                        <div className={`${dailyduck.my_order_cancellation_box}`}><button onClick={() => setShowBuyerBox(true)}>{buyerSelect === '' ? '반품 사유를 선택해주세요.' : buyerSelect}</button></div>
                                    }
                                    {
                                        activeRefundState === 'seller' &&
                                        <div className={`${dailyduck.my_order_cancellation_box}`}><button onClick={() => setShowSellerBox(true)}>{sellerSelect === '' ? '반품 사유를 선택해주세요.' : sellerSelect}</button></div>
                                    }
                                    {
                                        activeRefundState === 'direct' &&
                                        <div className={`${dailyduck.my_order_cancellation_box}`}><input type="text" placeholder='반품 사유를 입력해주세요.' onChange={(e) => onDirectRefund(e)} /></div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={`${dailyduck.my_refund_method_box}`}>
                            <div>
                                <span>교환 방법</span>
                            </div>
                            <div>
                                <div className='py-2'><input type="radio" name="exchange_type" id="type1" onChange={(e) => onRefundMethod(e)} /><label className='ps-1' htmlFor='type1'>수거를 신청할게요.</label></div>
                                <div className='py-2'><input type="radio" name="exchange_type" id="type2" onChange={(e) => onRefundMethod(e)} /><label className='ps-1' htmlFor='type2'>직접 보낼게요.</label></div>
                            </div>
                            {
                                refundMethodSelect === 'type1' &&
                                <div className={`${dailyduck.my_refund_method_seller_refund}`}>
                                    <div>
                                        <div><span>판매자가 수거 신청을 합니다.</span></div>
                                        <div><span>* 상품 수거장소와 성함, 연락처를 확인해주세요.</span></div>
                                    </div>
                                    <div>
                                        <div className={`${dailyduck.my_refund_method_info_box}`}>
                                            <div><span>이름</span></div>
                                            <div><span>{orderInfo.deliveryUser}</span></div>
                                        </div>
                                        <div className={`${dailyduck.my_refund_method_info_box}`}>
                                            <div><span>연락처</span></div>
                                            <div><span>{orderInfo.deliveryPhone}</span></div>
                                        </div>
                                        <div className={`${dailyduck.my_refund_method_info_box}`}>
                                            <div><span>상품 수거장소</span></div>
                                            <div><span>{orderInfo.deliveryAddress}</span></div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                refundMethodSelect === 'type2' &&
                                <div className={`${dailyduck.my_refund_method_buyer_refund}`}>
                                    <div>
                                        <div><span>구매자가 직접 택배를 보내야 합니다.</span></div>
                                        <div><span>* 보내는 택배사, 운송장번호를 정확히 기재하시고 반품지를 확인해 주세요.<br />* 택배정보는 내정보-주문/배송-해당상품에서 다시 입력할 수 있습니다.</span></div>
                                    </div>
                                    <div>
                                        <div>
                                            <Select
                                                className={`${dailyduck.refund_method_delivery_select}`}
                                                placeholder='택배사 선택'
                                                onChange={(value) => handleDeliveryCompanyChange(value)}
                                            >
                                                {deliveryCompanies.map((company) => (
                                                    <Option key={company.id} value={company.name}>
                                                        {company.name}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </div>
                                        <div className='mt-1'>
                                            <input
                                                type="number"
                                                placeholder='운송장번호'
                                                onChange={(e) => onDeliveryNumber(e)}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <div><span>교환주소지</span></div>
                                            <div><span>{sellerRefundAddress?.dtShippingAddress} {sellerRefundAddress?.dtShippingAddressDetail}</span></div>
                                        </div>
                                        <div><button onClick={copyToClipboard}>교환주소지 복사</button></div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className={`${dailyduck.my_order_cancel_payment_info_box}`}>
                            <button className={`${dailyduck.my_order_cancel_payment_info_drop_down}`} onClick={() => toggleOpen()}>
                                <div><span>결제정보</span></div>
                                <div><span className="material-symbols-outlined">{openStates ? 'arrow_drop_up' : 'arrow_drop_down'}</span></div>
                            </button>
                            <CSSTransition in={!openStates} timeout={500} classNames="review" unmountOnExit>
                                <div className={`${dailyduck.my_order_cancel_payment_info_drop_down_info}`}>
                                    <div>
                                        <div className={`d-flex justify-content-between py-1`}>
                                            <div><span>총 상품금액</span></div>
                                            <div><span>{calculateTotalPrice()}원</span></div>
                                        </div>
                                        <div className={`d-flex justify-content-between py-1`}>
                                            <div><span>{'배송비'}</span></div>
                                            <div><span>{orderInfo.deliveryPrice.toLocaleString()}원</span></div>
                                        </div>
                                        <div className={`d-flex justify-content-between py-1`}>
                                            <div><span>포인트</span></div>
                                            <div><span>(-) {orderInfo.point.toLocaleString()}원</span></div>
                                        </div>
                                        <div className={`d-flex justify-content-between py-1`}>
                                            <div><span>쿠폰</span></div>
                                            <div><span>(-) {orderInfo.coupon.toLocaleString()}원</span></div>
                                        </div>
                                        <div className={`d-flex justify-content-between py-1`}>
                                            <div><span>결제수단</span></div>
                                            <div><span>{orderInfo.paymentType}</span></div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className={`d-flex justify-content-between`}>
                                            <div><span>총 결제금액</span></div>
                                            <div><span>{parseInt(orderInfo.paymentPrice).toLocaleString()}원</span></div>
                                        </div>
                                    </div>
                                </div>
                            </CSSTransition>
                        </div>
                        {
                            activeRefundState === 'buyer' || (selectedOption?.optionPrice > 0) ? (
                                <div className={`${dailyduck.my_order_exchange_additional_payment_amount}`}>
                                    <div className={`${dailyduck.my_order_exchange_additional_payment_amount_header}`}>
                                        <div><span>교환비용</span></div>
                                    </div>
                                    <div className={`${dailyduck.my_order_exchange_additional_payment_amount_content}`}>
                                        <div>
                                            <div><span>입금은행</span></div>
                                            <div>
                                                <div><img src={`${process.env.PUBLIC_URL}${shopInfo.shopBankImg}`} alt="은행이미지" /></div>
                                                <div><span>{shopInfo.shopBankName}</span></div>
                                            </div>
                                        </div>
                                        <div>
                                            <div><span>입금계좌</span></div>
                                            <div>
                                                <div><span>{shopInfo.shopAccountNumber}</span></div>
                                                <div className='ps-2'><button><span class="material-symbols-outlined">content_copy</span></button></div>
                                            </div>
                                        </div>
                                        <div>
                                            <div><span>예금주명</span></div>
                                            <div><span>{shopInfo.shopDepositorName}</span></div>
                                        </div>
                                    </div>
                                    <div className='py-2'>
                                        <div className={`${dailyduck.my_order_exchange_additional_payment_amount_footer}`}>
                                            <div><span>추가 결제 금액</span></div>
                                            {
                                                activeRefundState !== 'seller' &&
                                                <div>
                                                    <div><span>{activeRefundState === 'buyer' && refundMethodSelect === 'type2' ? '편도배송비' : '왕복배송비'}</span></div>
                                                    {
                                                        activeRefundState === 'buyer' && refundMethodSelect === 'type2' &&
                                                        <div><span>{((orderInfo.deliveryPrice)).toLocaleString()}원</span></div>
                                                    }
                                                    {
                                                        activeRefundState === 'buyer' && refundMethodSelect === 'type1' &&
                                                        <div><span>{(orderInfo.deliveryPrice * 2).toLocaleString()}원</span></div>
                                                    }
                                                </div>
                                            }
                                            {
                                                selectedOption?.optionPrice > 0 &&
                                                <div className={`${dailyduck.my_order_exchange_additional_payment_amount_add}`}>
                                                    <div><span>상품 추가금</span></div>
                                                    <div><span>{selectedOption?.optionPrice.toLocaleString()}원</span></div>
                                                </div>
                                            }
                                            <div className={`${dailyduck.my_order_exchange_additional_payment_amount_total}`}>
                                                <div><span>결제 필요금액</span></div>
                                                {
                                                    activeRefundState === 'buyer' && refundMethodSelect === 'type2' &&
                                                    <div><span>{totalExchangePrice.toLocaleString()}원</span></div>
                                                }
                                                {
                                                    activeRefundState === 'buyer' && refundMethodSelect === 'type1' &&
                                                    <div><span>{totalExchangePrice.toLocaleString()}원</span></div>
                                                }
                                                {
                                                    activeRefundState === 'seller' &&
                                                    <div><span>{totalExchangePrice.toLocaleString()}원</span></div>
                                                }
                                            </div>
                                        </div>
                                        <div className={`${dailyduck.my_order_exchange_additional_payment_amount_txt}`}><span>*배송비 및 추가결제금 입금 확인 후 교환 절차가 진행됩니다.</span></div>
                                    </div>
                                </div>
                            ) : (
                                <div className={`${dailyduck.my_order_exchange_additional_payment_amount_seller}`}>
                                    <span>추가 결제할 금액이 없습니다.</span>
                                </div>
                            )
                        }
                        {/* <div className='payment_select py-3 px-3'>
                            <div className='pb-2'><span>결제수단</span></div>
                            <div id="payment-method" className="payment_select_btn_box"></div>
                            <div id="agreement" className="w-100" />
                        </div> */}
                        <div className='payment_couse'></div>
                        <div className={`${dailyduck.my_order_cancel_precautions}`}>
                            <div><button>&#183; 교환 신청시 주의사항</button></div>
                            <div><button>&#183; 무통장 입금시 안내사항</button></div>
                        </div>
                        <div className={`${dailyduck.my_order_cancel_precautions_agree}`}>
                            <div><input type="checkbox" name="" id="agree" onChange={(e) => onAgreeCheck(e)} /></div>
                            <div><label htmlFor='agree'>주의 사항을 읽고 동의합니다.</label></div>
                        </div>
                    </div>
                    <div className={`${dailyduck.myrefund_btn} px-3`}>
                        <div className='py-4'>
                            <button className={returnCheck() ? dailyduck.my_return_btn_active : ''} onClick={() => onReturnAdd()} disabled={!returnCheck()}>교환신청하기</button>
                        </div>
                    </div>
                </div>
            </div>
            {
                showBuyerBox &&
                <div className={`${dailyduck.my_order_bottom_modal}`}>
                    {transitions((style, item) =>
                        item ?
                            <animated.div className={dailyduck.my_order_bottom_modal_container} style={style}>
                                <div className={dailyduck.my_order_bottom_modal_header}>
                                    <div><span>구매자 반품 사유 선택</span></div>
                                    <div><button onClick={() => setShowBuyerBox(false)}><span className="material-symbols-outlined">close</span></button></div>
                                </div>
                                <div className={dailyduck.my_order_bottom_modal_content}>
                                    <div><button className={buyerSelect === '배송지연' ? dailyduck.my_order_bottom_modal_content_active : ''} onClick={() => onBuyerSelectBtn('배송지연')}>배송지연</button></div>
                                    <div><button className={buyerSelect === '단순변심' ? dailyduck.my_order_bottom_modal_content_active : ''} onClick={() => onBuyerSelectBtn('단순변심')}>단순변심</button></div>
                                    <div><button className={buyerSelect === '상품 오주문' ? dailyduck.my_order_bottom_modal_content_active : ''} onClick={() => onBuyerSelectBtn('설명과 다른 제품')}>설명과 다른 제품</button></div>
                                    <div><button className={buyerSelect === '상품 불만족' ? dailyduck.my_order_bottom_modal_content_active : ''} onClick={() => onBuyerSelectBtn('가격 문제')}>가격 문제</button></div>
                                    <div><button className={buyerSelect === '중복 주문' ? dailyduck.my_order_bottom_modal_content_active : ''} onClick={() => onBuyerSelectBtn('중복 주문')}>중복 주문</button></div>
                                    <div><button className={buyerSelect === '주문실수' ? dailyduck.my_order_bottom_modal_content_active : ''} onClick={() => onBuyerSelectBtn('주문실수')}>주문실수</button></div>
                                    {/* <div><button className={buyerSelect === '색상 / 크기 문제' ? dailyduck.my_order_bottom_modal_content_active : ''} onClick={() => onBuyerSelectBtn('색상 / 크기 문제')}>색상 / 크기 문제</button></div>
                                    <div><button className={buyerSelect === '결제문제' ? dailyduck.my_order_bottom_modal_content_active : ''} onClick={() => onBuyerSelectBtn('결제문제')}>결제문제</button></div> */}
                                </div>
                            </animated.div>
                            : null
                    )}
                </div>
            }
            {
                showSellerBox &&
                <div className={`${dailyduck.my_order_bottom_modal}`}>
                    {sellerTransitions((style, item) =>
                        item ?
                            <animated.div className={dailyduck.my_order_bottom_modal_container} style={style}>
                                <div className={dailyduck.my_order_bottom_modal_header}>
                                    <div><span>판매자 반품 사유 선택</span></div>
                                    <div><button onClick={() => setShowSellerBox(false)}><span className="material-symbols-outlined">close</span></button></div>
                                </div>
                                <div className={dailyduck.my_order_bottom_modal_content}>
                                    <div><button className={sellerSelect === '상품불량' ? dailyduck.my_order_bottom_modal_content_active : ''} onClick={() => onSellerSelectBtn('상품불량')}>상품불량</button></div>
                                    <div><button className={sellerSelect === '오배송' ? dailyduck.my_order_bottom_modal_content_active : ''} onClick={() => onSellerSelectBtn('잘못된 상품 배송')}>잘못된 상품 배송</button></div>
                                    <div><button className={sellerSelect === '상품 불일치' ? dailyduck.my_order_bottom_modal_content_active : ''} onClick={() => onSellerSelectBtn('파손된 상품 배송')}>파손된 상품 배송</button></div>
                                    <div><button className={sellerSelect === '상품 누락' ? dailyduck.my_order_bottom_modal_content_active : ''} onClick={() => onSellerSelectBtn('상품 누락')}>상품 누락</button></div>
                                    <div><button className={sellerSelect === '배송 지연' ? dailyduck.my_order_bottom_modal_content_active : ''} onClick={() => onSellerSelectBtn('배송 지연')}>배송 지연</button></div>
                                    <div><button className={sellerSelect === '잘못된 상품 설명' ? dailyduck.my_order_bottom_modal_content_active : ''} onClick={() => onSellerSelectBtn('잘못된 상품 설명')}>잘못된 상품 설명</button></div>
                                    {/* <div><button className={sellerSelect === '고객 서비스 불만' ? dailyduck.my_order_bottom_modal_content_active : ''} onClick={() => onSellerSelectBtn('고객 서비스 불만')}>고객 서비스 불만</button></div>
                                    <div><button className={sellerSelect === '허위 광고' ? dailyduck.my_order_bottom_modal_content_active : ''} onClick={() => onSellerSelectBtn('허위 광고')}>허위 광고</button></div> */}
                                </div>
                            </animated.div>
                            : null
                    )}
                </div>
            }
        </>
    )
}

export default Exchange;
