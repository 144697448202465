import React, { useContext, useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import SalesCalendar from './board/SalesCalendar';
import seller from '../../../seller.module.css';
import { LoginContext } from '../../../../contexts/LoginContextProvider';
import * as supportApi from '../../../../apis/support';

const SellerDashBoardMain = () => {
    const { userInfo } = useContext(LoginContext);
    const [activeButton, setActiveButton] = useState('summary');
    const [dateActive, setDateActive] = useState('today');
    const [pageViewData, setPageViewData] = useState([]);
    const [reservationData, setReservationData] = useState([]);
    
    const [visitCount, setVisitCount] = useState(0);
    const [visitGrowth, setVisitGrowth] = useState(0);
    const [reservationCount, setReservationCount] = useState(0);
    const [reservationGrowth, setReservationGrowth] = useState(0);
    const [revenue, setRevenue] = useState(0);
    const [revenueGrowth, setRevenueGrowth] = useState(0);
    const [salesData, setSalesData] = useState([]); // 매출 데이터 추가

    const [newReservation, setNewReservation] = useState(0);
    const [confirmReservation, setConfirmReservation] = useState(0);
    const [providedReservation, setProvidedReservation] = useState(0);
    const [reservationReviewCnt, setReservationReviewCount] = useState(0);

    const [openStates, setOpenStates] = useState(false);

    useEffect(() => {
        fetchData();
        reservationSelect();
        reservationConfirmed();
        reservationProvided();
        reservationReviewCount();
    }, [userInfo]);

    useEffect(() => {
        updateCounts();
    }, [pageViewData, reservationData, dateActive]);

    const reservationSelect = async () => {
        const response = await supportApi.dashboardNewReservationSelect(userInfo && userInfo?.userId);
        const data = response.data;

        if(response.status === 200) {
            setNewReservation(data);
        }
    };

    const reservationConfirmed = async () => {
        const response = await supportApi.reservationConfirmed(userInfo && userInfo?.userId);
        const data = response.data;

        if(response.status === 200) {
            setConfirmReservation(data);
        }
    };
    const reservationProvided = async () => {
        const response = await supportApi.reservationProvided(userInfo && userInfo?.userId);
        const data = response.data;

        if(response.status === 200) {
            setProvidedReservation(data);
        }
    };
    const reservationReviewCount = async () => {
        const response = await supportApi.reservationReviewCount(userInfo && userInfo?.userId);
        const data = response.data;
    
        if(response.status === 200) {
            setReservationReviewCount(data);
        }
    };

    const fetchData = async () => {
        const pageViewResponse = await supportApi.supportPageViewSelect(userInfo?.userId);
        const reservationResponse = await supportApi.dashboardReservationSelect(userInfo?.userId);
        setPageViewData(pageViewResponse.data || []);
        setReservationData(reservationResponse.data || []);
    };

    const calculateGrowth = (current, previous) => {
        return previous === 0 ? 0 : ((current - previous) / previous) * 100;
    };

    const filterDataByDateRange = (data, dateKey, purchaseConfirmed = undefined) => {
        const today = new Date();
        const dateRanges = {
            today: 1,
            week: 7,
            month: 30,
            threeMonth: 90,
        };
        const daysToFilter = dateRanges[dateActive];
        const pastDate = new Date(today.getTime() - daysToFilter * 24 * 60 * 60 * 1000);

        return data.filter(item => {
            const itemDate = new Date(item[dateKey]);
            const isInDateRange = itemDate >= pastDate && itemDate <= today;
            const isConfirmed = purchaseConfirmed === undefined || item.purchaseConfirmation === purchaseConfirmed;
            return isInDateRange && isConfirmed;
        });
    };

    const updateCounts = () => {
        // 방문 수 계산
        const visitDataCurrent = filterDataByDateRange(pageViewData, 'pageViewDate');
        const visitDataPrevious = filterDataByDateRange(pageViewData, 'pageViewDate', false);
        setVisitCount(visitDataCurrent.length);
        setVisitGrowth(calculateGrowth(visitDataCurrent.length, visitDataPrevious.length));
    
        // 예약 수 계산
        const reservationDataCurrent = filterDataByDateRange(reservationData, 'reservationRegDate');
        const reservationDataPrevious = filterDataByDateRange(reservationData, 'reservationRegDate', false);
        setReservationCount(reservationDataCurrent.length);
        setReservationGrowth(calculateGrowth(reservationDataCurrent.length, reservationDataPrevious.length));
    
        // 수익 계산
        const revenueDataCurrent = filterDataByDateRange(reservationData, 'reservationUpdDate', true);
        const revenueCurrent = revenueDataCurrent.reduce((acc, item) => acc + item.amount, 0);
        const revenueDataPrevious = filterDataByDateRange(reservationData, 'reservationUpdDate', false);
        const revenuePrevious = revenueDataPrevious.reduce((acc, item) => acc + item.amount, 0);
        setRevenue(revenueCurrent);
        setRevenueGrowth(calculateGrowth(revenueCurrent, revenuePrevious));
    
        // 매출 데이터 생성
        const confirmedSalesData = reservationData.filter(
            item => item.reservationState === "구매확정" && item.reservationUpdDate
        );
        
        const groupedSalesData = confirmedSalesData.reduce((acc, item) => {
            // 날짜를 'YYYY-MM-DD' 형태로 포맷
            const dateKey = new Date(item.reservationUpdDate).toISOString().split("T")[0];
            if (!acc[dateKey]) {
                acc[dateKey] = { date: dateKey, sales: 0, count: 0 };
            }
            acc[dateKey].sales += item.amount;
            acc[dateKey].count += 1;
            return acc;
        }, {});
    
        setSalesData(Object.values(groupedSalesData));
    };
    

    const toggleOpen = () => setOpenStates(!openStates);

    const activeFormat = (text) => {
        if (text === 'today') return '오늘';
        if (text === 'week') return '1주';
        if (text === 'month') return '1개월';
        if (text === 'threeMonth') return '3개월';
    };

    return (
        <div className={`${seller.seller_dashboard_container} py-3 px-3`}>
            <div className='pb-3 w-100 border-bottom fs-5'><span>대시보드</span></div>
            <div className={`${seller.seller_dashboard_tab_btn} pt-4 pb-3`}>
                <div className={`w-100 ${activeButton === 'summary' ? seller.seller_dashboard_tab_active : ''}`}>
                    <button onClick={() => setActiveButton('summary')}>요약 현황</button>
                </div>
                <div className={`w-100 ${activeButton === 'salesbydate' ? seller.seller_dashboard_tab_active : ''}`}>
                    <button onClick={() => setActiveButton('salesbydate')}>일자별 매출 현황</button>
                </div>
            </div>

            {activeButton === 'summary' && (
                <div>
                    <div className={`pt-4 pb-2 w-100 ${seller.seller_border_bottom_color} d-flex justify-content-between position-relative`}>
                        <div><span>요약</span></div>
                        <div>
                            <button className='d-flex align-center bg-body border-0' onClick={toggleOpen}>
                                <div className='dd-fs-7'>{activeFormat(dateActive)}</div>
                                <div className='ps-2'><span className="material-symbols-outlined">{openStates ? 'expand_less' : 'expand_more'}</span></div>
                            </button>
                        </div>
                        <CSSTransition in={openStates} timeout={500} classNames="review" unmountOnExit>
                            <div className={`${seller.seller_date_select}`}>
                                <div className='w-100 border-bottom'><button className='w-100 py-2 px-4 border-0 bg-body' onClick={() => { setDateActive('today'); toggleOpen(); }}>오늘</button></div>
                                <div className='w-100 border-bottom'><button className='w-100 py-2 px-4 border-0 bg-body' onClick={() => { setDateActive('week'); toggleOpen(); }}>1주</button></div>
                                <div className='w-100 border-bottom'><button className='w-100 py-2 px-4 border-0 bg-body' onClick={() => { setDateActive('month'); toggleOpen(); }}>1개월</button></div>
                                <div className='w-100 border-bottom'><button className='w-100 py-2 px-4 border-0 bg-body' onClick={() => { setDateActive('threeMonth'); toggleOpen(); }}>3개월</button></div>
                            </div>
                        </CSSTransition>
                    </div>

                    <div className='py-4'>
                        <div className={`${seller.seller_dashboard_info_box} py-3`}>
                            <div className={`${seller.seller_dashboard_visit_box} px-3`}>
                                <div className='py-3'><span>방문</span></div>
                                <div className='py-3'><span>{visitCount}</span></div>
                                <div className='py-3'><span>{visitGrowth.toFixed(1)}%</span><span className='ps-3'>{activeFormat(dateActive)}</span></div>
                            </div>
                            <div className={`${seller.seller_dashboard_order_box} px-3`}>
                                <div className='py-3'><span>예약</span></div>
                                <div className='py-3'><span>{reservationCount}</span></div>
                                <div className='py-3'><span>{reservationGrowth.toFixed(1)}%</span><span className='ps-3'>{activeFormat(dateActive)}</span></div>
                            </div>
                            <div className={`${seller.seller_dashboard_revenue_box} px-3`}>
                                <div className='py-3'><span>수익</span></div>
                                <div className='py-3'><span>{revenue}</span></div>
                                <div className='py-3'><span>{revenueGrowth.toFixed(1)}%</span><span className='ps-3'>{activeFormat(dateActive)}</span></div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className={`${seller.seller_dashboard_support_current_box}`}>
                            <div className={`${seller.seller_dashboard_current_order} px-3 py-3 me-4`}>
                                <div className='pt-2 pb-2 border-bottom'><span>예약 현황</span></div>
                                <div className='d-flex justify-content-between py-2'>
                                    <div><span>신규예약</span></div>
                                    <div><span>{newReservation}</span></div>
                                </div>
                                <div className='d-flex justify-content-between py-2'>
                                    <div><span>예약확정</span></div>
                                    <div><span>{confirmReservation}</span></div>
                                </div>
                                <div className='d-flex justify-content-between py-2'>
                                    <div><span>제공완료(구매확정)</span></div>
                                    <div><span>{providedReservation}</span></div>
                                </div>
                            </div>
                            <div className={`${seller.seller_dashboard_current_review} px-3 py-3`}>
                                <div className='pt-2 pb-2 border-bottom'><span>리뷰 현황</span></div>
                                <div className='d-flex justify-content-between py-2'>
                                    <div><span>신규 리뷰</span></div>
                                    <div><span>{reservationReviewCnt}</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {activeButton === 'salesbydate' && (
                <div>
                    <div className={`pt-4 pb-2 w-100 ${seller.seller_border_bottom_color}`}>
                        <div><span>일자별 매출</span></div>
                    </div>
                    <div className={`${seller.seller_calander} pt-3`}>
                        <SalesCalendar salesData={salesData} seller={seller}/>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SellerDashBoardMain;
