import React, { useContext, useEffect, useState } from 'react';
import { Pagination } from 'antd';
import seller from '../../../../seller.module.css';
import SellerReviewModal from '../../storedashboard/review/SellerReviewModal';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import * as support from '../../../../../apis/support';
import { LoginContext } from '../../../../../contexts/LoginContextProvider';
import { format } from 'date-fns';

const SellerReviewMain = () => {

  const { userInfo } = useContext(LoginContext);

  const [ reviewList, setReviewList ] = useState([]);
  const MySwal = withReactContent(Swal);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(6);
  const [totalItems, setTotalItems] = useState(0);
  const [ showReviewModal, setShowReviewModal ] = useState(false);
  const [ reviewAnswerCheck, setReviewAnswerCheck ] = useState(true);
  const [ reviewAnswer, setReviewAnswer ] = useState('');

  const [ selectReservation, setSelectReservation ] = useState(null);
  const [ selectReservationReview, setSelectReservationReview ] = useState(null);
  const [ selectSupport, setSelectSupport ] = useState(null);

  // 페이지 번호 클릭 시 호출될 함수
  const paginate = pageNumber => setCurrentPage(pageNumber);

  const onReviewClick = (reservation, reservationReview, support) => {
    setSelectReservation(reservation);
    setSelectReservationReview(reservationReview);
    setSelectSupport(support);
    setShowReviewModal(true);
  }

  useEffect(()=> {
    if(reviewAnswer !== '') {
        setReviewAnswerCheck(false);
    } else {
        setReviewAnswerCheck(true);
    }
  }, [reviewAnswer]);

  const onReviewAnswer = (e) => {
    // console.log(e.target.value);
    setReviewAnswer(e.target.value);
  }

  const handleReviewWrite = (supportReviewNo, supportReceiveReview) => {
    if(supportReceiveReview === null) {
      Swal.fire({
        title: '리뷰 답변 등록 완료',
        html: '<span>리뷰 내용 답변 등록이 완료되어 저장되었습니다.</span>',
        showCancelButton: false,
        confirmButtonText: '확인',
        focusConfirm: false,
        customClass: {
            confirmButton: `${seller.success_seller_review_btn}`,
            title: `${seller.success_seller_review_title}`,
            htmlContainer: `${seller.success_seller_review_text}`,
            actions: `${seller.success_seller_review_actions}`,
            popup: `${seller.success_seller_review_popup}`
        }
    }).then((result) => {
        if (result.isConfirmed) {
            const reviewUpdate = async () => {
              const response = await support.reviewUpdate(supportReviewNo, reviewAnswer);
              const data = response.data;

              // console.log(data);

              if(data === 'SUCCESS') {
                productReview();
              }
            }
            reviewUpdate();
            setShowReviewModal(false);
        }
    });
    } else {
      Swal.fire({
          title: '리뷰 답변 수정 완료',
          html: '<p>리뷰 내용 답변 수정이 완료되어 저장되었습니다.</p>',
          showCancelButton: false,
          confirmButtonText: '확인',
          focusConfirm: false,
          customClass: {
              confirmButton: `${seller.success_seller_review_btn}`,
              title: `${seller.success_seller_review_title}`,
              htmlContainer: `${seller.success_seller_review_text}`,
              actions: `${seller.success_seller_review_actions}`,
              popup: `${seller.success_seller_review_popup}`
          }
      }).then((result) => {
          if (result.isConfirmed) {
            const reviewUpdate = async () => {
              const response = await support.reviewUpdate(supportReviewNo, reviewAnswer);
              const data = response.data;

              // console.log(data);

              if(data === 'SUCCESS') {
                productReview();
              }
            }
            reviewUpdate();
            setShowReviewModal(false);
          }
      });
    }
  }

  // 로컬 스토리지에서 데이터 가져오기
  useEffect(() => {
    const storedReviewList = localStorage.getItem('reviewList');
    const storedTotalItems = localStorage.getItem('totalItems');
    if (storedReviewList && storedReviewList !== "undefined") {
      setReviewList(JSON.parse(storedReviewList));
    }
    if (storedTotalItems && storedTotalItems !== "undefined") {
      setTotalItems(JSON.parse(storedTotalItems));
    }
  }, []);

  // 현재 페이지가 변경될 때마다 데이터 가져오기
  useEffect(() => {
    productReview();
  }, [currentPage, userInfo]);

  const productReview = async () => {
    const response = await support.supportSellerReviewSelect(userInfo?.userId, currentPage, itemsPerPage);
    const data = response.data;
    
    // console.log('data : ', data);
    const reviewArray = Object.keys(data).map(key => data[key]);
    // console.log('total : ', reviewArray.length);
    setReviewList(reviewArray);
    setTotalItems(reviewArray.length); // 총 아이템 수 설정

    // 로컬 스토리지에 데이터 저장
    localStorage.setItem('reviewList', JSON.stringify(reviewArray));
    localStorage.setItem('totalItems', JSON.stringify(reviewArray.length));
  }

  const formatDate = (date) => {
    return format(new Date(date), 'yyyy-MM-dd HH:mm:ss');
  };

  return (
    <div className={`px-3 ${seller.seller_right_container}`}>
      <div>
        <div className='pt-3 pb-2 border-bottom'><span>리뷰 관리</span></div>
        <div className={`pt-4 pb-1 ${seller.seller_border_bottom_color} w-100 d-flex`}>
          <div className='w-10p dd-fs-7 d-flex align-item-center justify-content-center'><span>번호</span></div>
          <div className='w-35p dd-fs-7 d-flex align-item-center justify-content-center'><span>상품정보</span></div>
          <div className='w-35p dd-fs-7 d-flex align-item-center justify-content-center'><span>리뷰 내용</span></div>
          <div className='w-10p dd-fs-7 d-flex align-item-center justify-content-center'><span>작성일</span></div>
          <div className='w-10p dd-fs-7 d-flex align-item-center justify-content-center'><span>답변 유무</span></div>
        </div>
        <div>
          {
            reviewList.length !== 0 ?
            Array.isArray(reviewList) && reviewList.map((review, index) => (
              <div className={`d-flex dd-fs-7 align-items-center py-3 ${seller.seller_store_review_box}`} onClick={() => onReviewClick(review.reservation, review.reservationReview, review.support)} key={index}>
                <div className='w-10p d-flex justify-content-center align-items-center'><span>{review.reservationReview?.rowNum}</span></div>
                <div className='w-35p d-flex justify-content-center align-items-center'>
                  <div className={`${seller.seller_store_review_img_box}`}><img src={review.support?.representativeImage} alt="상품이미지" /></div>
                  <div className='px-2'><span>{review.support.productName}</span></div>
                </div>
                <div className={`w-35p ${seller.seller_store_review_content_box} px-2`}><span>{review.reservationReview.reviewText}</span></div>
                <div className='w-10p d-flex justify-content-center align-items-center'><span>{formatDate(review.reservationReview.reviewRegDate)}</span></div>
                <div className='w-10p d-flex justify-content-center align-items-center'><span>{review.reservationReview.supportReceiveReview === null ? '답변예정' : '답변완료'}</span></div>
              </div>
            )) :
            <div className='w-100 text-center py-5 dd-fs-7 gray_color'>
              <div><span>등록된 내역이 없습니다.</span></div>
            </div>
          }
        </div>
        <Pagination current={currentPage} total={totalItems} onChange={paginate} pageSize={itemsPerPage} />  
      </div>
      {
          showReviewModal &&
          <SellerReviewModal seller={seller} setShowReviewModal={setShowReviewModal} onReviewAnswer={onReviewAnswer} reviewAnswerCheck={reviewAnswerCheck} handleReviewWrite={handleReviewWrite}
          selectReservation={selectReservation} selectReservationReview={selectReservationReview} selectSupport={selectSupport}
          />
      }
    </div>
  )
}

export default SellerReviewMain;
