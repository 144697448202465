import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import event from '../../containers/event/event.module.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import * as admin from '../../apis/admin';
import { Image } from 'antd';
import 'antd/es/style/reset.css';

const PopularSupportMore = () => {
    const navigate = useNavigate();
    const scrollRef = useRef(null);

    const [supportProduct, setSupportProduct] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [currentPreviewIndex, setCurrentPreviewIndex] = useState(null); // 프리뷰 상태 관리
    const [isPreviewOpen, setIsPreviewOpen] = useState(false); // 프리뷰 열림 상태 관리

    const formatRating = (rating) => {
        return rating.toFixed(1);
    };

    useEffect(() => {
        // 초기 데이터 로드
        const fetchInitialData = async () => {
            try {
                const response = await admin.popularSupportSelect();
                const data = response.data;

                if (response.status === 200) {
                    if (Array.isArray(data)) {
                        const sortedData = data.sort((a, b) => {
                            if (a.reservationSetting === "custom" && b.reservationSetting !== "custom") return -1;
                            if (a.reservationSetting !== "custom" && b.reservationSetting === "custom") return 1;
                            return a.reservationExposureOrder - b.reservationExposureOrder;
                        });
                        setSupportProduct(sortedData.slice(0, 6));
                    } else {
                        setSupportProduct([]);
                        console.warn('Data is not an array:', data);
                    }
                }
            } catch (error) {
                console.error('Error in supportSelect:', error);
                setSupportProduct([]);
            }
        };

        fetchInitialData();
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            if (scrollRef.current) {
                const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;

                if (scrollTop + clientHeight >= scrollHeight - 5 && hasMore) {
                    loadMoreProducts();
                }
            }
        };

        const currentScrollRef = scrollRef.current;
        if (currentScrollRef) {
            currentScrollRef.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (currentScrollRef) {
                currentScrollRef.removeEventListener('scroll', handleScroll);
            }
        };
    }, [hasMore]);

    const loadMoreProducts = () => {
        const startIndex = page * 6;
        const endIndex = (page + 1) * 6;

        setSupportProduct(prevProducts => {
            const moreProducts = prevProducts.slice(startIndex, endIndex);
            if (moreProducts.length === 0) {
                setHasMore(false);
                return prevProducts;
            }
            setPage(prevPage => prevPage + 1);
            return [...prevProducts, ...moreProducts];
        });
    };

    const onBack = () => {
        navigate(-1);
    };

    const onSupportItem = (item) => {
        if (!isPreviewOpen) { // 프리뷰가 열려있지 않을 때만 실행
            navigate(`/venue/${item.productNumber}`);
        }
    };

    const getMinPrice = (items) => {
        if (!Array.isArray(items) || items.length === 0) return null;
        return Math.min(...items.map((item) => item.mainItemPrice));
    };

    const handleImageClick = (e, index) => {
        e.stopPropagation(); // 이벤트 전파를 막아 링크 실행 방지
        setCurrentPreviewIndex(index); // 프리뷰 시작 인덱스 설정
        setIsPreviewOpen(true); // 프리뷰 열림 상태로 설정
    };

    const handlePreviewClose = () => {
        setIsPreviewOpen(false); // 프리뷰 닫힘 상태로 설정
        setCurrentPreviewIndex(null); // 인덱스 초기화
    };

    return (
        <div>
            <div className={`${event.event_store_more_header}`}>
                <div>
                    <button onClick={() => onBack()}>
                        <span className="material-symbols-outlined">arrow_back_ios</span>
                    </button>
                </div>
                <div>인기 예약</div>
            </div>
            <div className={`${event.event_store_more_content} py-3`} ref={scrollRef}>
                <div className='px-3'>
                    <div className='px-1 pt-1'>
                    {
                        supportProduct.map((item, index) => {
                            const minPrice = getMinPrice(item.mainItem);
                            const hasOptions = Array.isArray(item.mainItem) && item.mainItem.length > 0;

                            // 이미지 그룹 생성
                            const images = [
                                item.representativeImage,
                                item.listImage1,
                                item.listImage2,
                                item.listImage3,
                                item.listImage4,
                            ].filter(Boolean); // 유효한 이미지들만 포함

                            return (
                                <div className='search_content_support_info' onClick={() => onSupportItem(item)} key={index}>
                                    <div>
                                        <div><img src={item.shopProfile} alt="" /></div>
                                        <div><span>{item.productName}</span></div>
                                    </div>
                                    <div><span>{item.shopInfo}</span></div>
                                    <div className={`${event.support_content_price_rate_star_box}`}>
                                        <div>
                                            <div>
                                                <img src="../../img/icon/star_fill.png" alt="별점" />
                                            </div>
                                            <div className="ps-1">
                                                <span>{formatRating(item.reviewRating)}</span>
                                            </div>
                                        </div>
                                        <div>
                                            <span>리뷰</span>
                                            <span>{item.reviewCount}</span>
                                        </div>
                                        <div>
                                            {minPrice !== null && (
                                                <span>
                                                    {minPrice.toLocaleString()}&#8361;{hasOptions ? ' ~' : ''}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div>
                                        <Image.PreviewGroup
                                            preview={{
                                                current: currentPreviewIndex,
                                                onChange: (current) => setCurrentPreviewIndex(current),
                                                onVisibleChange: (visible) => {
                                                    setIsPreviewOpen(visible); // 프리뷰가 열리고 닫힐 때 상태 업데이트
                                                    if (!visible) setCurrentPreviewIndex(null); // 프리뷰가 닫힐 때 인덱스 초기화
                                                },
                                            }}
                                        >
                                            <Swiper
                                                slidesPerView={'auto'}
                                                spaceBetween={10}
                                                modules={[Pagination, Navigation]}
                                                className='search_content_support_item'
                                            >
                                                {
                                                    images.map((image, idx) => (
                                                        <SwiperSlide key={idx}>
                                                            <div className='search_content_support_item_img'>
                                                                <Image
                                                                    src={image}
                                                                    alt={`이미지${idx + 1}`}
                                                                    onClick={(e) => handleImageClick(e, idx)} // 이벤트 전파 막기 및 클릭 처리
                                                                />
                                                            </div>
                                                        </SwiperSlide>
                                                    ))
                                                }
                                            </Swiper>
                                        </Image.PreviewGroup>
                                    </div>
                                </div>
                            );
                        })
                    }
                    </div>
                    {!hasMore && <div className={`${event.event_store_more_last_txt} pt-4`}><span>인기 예약의 마지막입니다.</span></div>}
                </div>
            </div>
        </div>
    );
};

export default PopularSupportMore;
