import React, { useContext, useEffect, useRef, useState } from 'react';
import admin from '../../../admin.module.css';
import * as adminApi from '../../../../apis/admin';
import { Button, DatePicker, Input, Select } from 'antd';
import { Option } from 'antd/es/mentions';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { LoginContext } from '../../../../contexts/LoginContextProvider';
import { formatDistanceToNow } from 'date-fns';
import { ko } from 'date-fns/locale';
import moment from 'moment';

const AdminCoupon = () => {
    const { userInfo } = useContext(LoginContext);
    const MySwal = withReactContent(Swal);
    const ref = useRef();
    const { RangePicker } = DatePicker;
    const [ activeButton, setActiveButton ] = useState('all');
    const [ activeCouponType, setActiveCouponType ] = useState('');
    const [ activeBenefitType, setActiveBenefitType ] = useState('');
    const [ showCouponModal, setShowCouponModal ] = useState(false);
    const [ userList, setUserList ] = useState([]);
    const [ selectedUsers, setSelectedUsers ] = useState([]);
    const [ selectCodeType, setSelectCodeType ] = useState('');
    const [ selectDate, setSelectDate ] = useState([]);
    const [ couponAddInfo, setCouponAddInfo ] = useState({});
    const [ couponList, setCouponList ] = useState([]);
    const [couponUpdateInfo, setCouponUpdateInfo] = useState({});
    const [showCouponUpdateModal, setShowCouponUpdateModal] = useState(false);


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return formatDistanceToNow(date, { addSuffix: true, locale: ko });
    };

    useEffect(() => {
        // console.log('selectedUsers : ', selectedUsers);
        setCouponAddInfo({
            ...couponAddInfo, selectUsers : selectedUsers
        })
    }, [selectedUsers])

    useEffect(() => {
        // console.log('couponAddInfo : ', couponAddInfo);
    }, [couponAddInfo])
    
    useEffect(() => {
        userAll();
        couponSelect();
    }, []);
        
    const userAll = async () => {
        const response = await adminApi.adminUserSelect();
        const data = response.data;

        setUserList(data);
    };

    const couponSelect = async () => {
        const response = await adminApi.adminCouponSelect();
        const data = response.data;

        // console.log(data);
        setCouponList(data);
    }

    const handleUserCheckboxChange = (user) => {
        setSelectedUsers(prevSelectedUsers => {
            if (prevSelectedUsers.some(selectedUser => selectedUser.userNo === user.userNo)) {
                return prevSelectedUsers.filter(selectedUser => selectedUser.userNo !== user.userNo);
            } else {
                return [...prevSelectedUsers, user];
            }
        });
    };

    const onCouponType = (e) => {
        // console.log(e.target.id);
        setActiveCouponType(e.target.id);
        setCouponAddInfo({
            ...couponAddInfo, couponType : e.target.id
        })
    }

    const onCouponTypeCode = (value) => {
        setSelectCodeType(value);
    }

    const onBenefitSelect = (value) => {
        setActiveBenefitType(value);
        setCouponAddInfo({
            ...couponAddInfo, couponBenefit : value
        })
    }

    const handleDateChange = (dates, dateStrings) => {
        // dates: Moment 객체 배열, 선택된 날짜 범위
        // dateStrings: 문자열 배열, 선택된 날짜 범위의 문자열 표현 ('YYYY-MM-DD' 형식)
        // console.log('선택된 날짜 범위:', dates);
        // console.log('선택된 날짜 범위 (문자열):', dateStrings);
        setSelectDate(dateStrings);
        setCouponAddInfo({
            ...couponAddInfo, couponStartDate : dateStrings[0], couponEndDate : dateStrings[1]
        })
    };

    const onCouponName = (e) => {
        setCouponAddInfo({
            ...couponAddInfo, couponName : e.target.value
        })
    }

    const onCouponTypeAuto = (value) => {
        setCouponAddInfo({
            ...couponAddInfo, couponConditionIssue : value
        })
    }

    const onCouponBenefitType = (value) => {
        setCouponAddInfo({
            ...couponAddInfo, couponBenefitType : value
        })
    }

    const onCouponBenefitNum = (e) => {
        setCouponAddInfo({
            ...couponAddInfo, couponBenefitNum : e.target.value
        })
    }

    const onDiscountCondition = (e) => {
        setCouponAddInfo({
            ...couponAddInfo, couponDiscountCondition : e.target.value
        })
    }
    const onMaximumDiscount = (e) => {
        setCouponAddInfo({
            ...couponAddInfo, couponMaximumDiscount : e.target.value
        })
    }
    const onCouponState = (value) => {
        setCouponAddInfo({
            ...couponAddInfo, couponState : value
        })
    }

    const onDateRangeCheck = (e) => {
        // console.log(e.target.checked);
        if(e.target.checked) {
            setCouponAddInfo({
                ...couponAddInfo, couponStartDate : '1900-01-01', couponEndDate : '2999-12-30'
            })
        } else {
            setCouponAddInfo({
                ...couponAddInfo, couponStartDate : '', couponEndDate : ''
            })
        }
    }

    const couponAddBtn = () => {
        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html: `
                <div>
                    <div class='${admin.admin_seller_add_title}'><span>쿠폰을 발급하시겠습니까?</span></div>
                    <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                      <button class='mx-1' id='seller_add_cancel'>취소</button>
                      <button class='mx-1' id='seller_add_success'>발급</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });
        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);
    
        async function sellerAddSuccess(e) {
            if (e.target && e.target.id === 'seller_add_success') {
                try {
                    const response = await adminApi.couponAdd(couponAddInfo);
                    const data = response.data;
        
                    if (data === 'SUCCESS') {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                            <div class='${admin.admin_seller_add_title}'><span>쿠폰 발급이 완료되었습니다.</span></div>
                            <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);
        
                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                userAll();
                                couponSelect();
                                setShowCouponModal(false);
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    } else {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                            <div class='${admin.admin_seller_add_title}'><span>쿠폰 발급에 실패하였습니다.</span></div>
                            <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);
        
                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    }
                } catch (error) {
                    console.error('error : ', error);
                }
            }
        }
    
        function sellerAddCancel(e) {
            if (e.target && e.target.id === 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
            }
        }
    }

    const benefitCheck = (benefit) => {
        if(benefit === 'amount') {
            return '금액 할인';
        } else {
            return '배송비 무료'
        }
    }

    const stateCheck = (state) => {
        if(state === 'ing') {
            return '진행중';
        } else if(state === 'wait') {
            return '대기';
        } else {
            return '종료';
        }
    }

    const typeCheck = (type, codeName, codeType, codeCount) => {
        if(type === 'user') {
            return '유저 지정 발급';
        } else if(type === 'code') {
            if(codeType === 'single_code') {
                return `쿠폰코드` + <br /> + `단일 쿠폰 발행` + <br /> + `(${codeCount})개`;
            } else {
                return `쿠폰코드` + <br /> + `여러 쿠폰 발행` + <br /> + `${codeName}`;
            }
        } else {
            return '자동 발행';
        }
    }

    const dateCheck = (start, end) => {
        if (start === '1899-12-31T15:00:00.000+00:00' && end === '2999-12-29T15:00:00.000+00:00') {
            return <span>기간 제한 없음</span>;
        } else {
            return (
                <div>
                    <div><span>{start.split('T')[0]}</span></div>
                    <div><span>~</span></div>
                    <div><span>{end.split('T')[0]}</span></div>
                </div>
            );
        }
    }
   
    const issueCheck = (issue) => {
        if(issue === 'join') {
            return '첫 회원가입';
        } else if(issue === 'product') {
            return '첫 상품구매';
        } else {
            return '첫 이벤트참여';
        }
    }

    const handleCouponUpdateClick = (coupon) => {
        setCouponUpdateInfo(coupon);
        setShowCouponUpdateModal(true);
    }

    const couponUpdateBtn = async () => {
        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html: `
                <div>
                    <div class='${admin.admin_seller_add_title}'><span>쿠폰을 수정하시겠습니까?</span></div>
                    <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                        <button class='mx-1' id='seller_add_cancel'>취소</button>
                        <button class='mx-1' id='seller_add_success'>수정</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });
        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);
    
        async function sellerAddSuccess(e) {
            if (e.target && e.target.id === 'seller_add_success') {
                try {
                    const response = await adminApi.couponUpdate(couponUpdateInfo);
                    const data = response.data;
    
                    if (data === 'SUCCESS') {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                            <div class='${admin.admin_seller_add_title}'><span>쿠폰 수정이 완료되었습니다.</span></div>
                            <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);
    
                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                                setShowCouponUpdateModal(false);
                                couponSelect(); // 쿠폰 목록 다시 불러오기
                            }
                        }
                    } else {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                            <div class='${admin.admin_seller_add_title}'><span>쿠폰 수정에 실패하였습니다.</span></div>
                            <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);
    
                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    }
                } catch (error) {
                    console.error('error : ', error);
                }
            }
        }
    
        function sellerAddCancel(e) {
            if (e.target && e.target.id === 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
            }
        }
    }
    
    

  return (
    <div className={`${admin.admin_give_coupon_box} px-3 py-3`}>
        <div>
            <div className={`${admin.admin_give_coupon_title}`}><span>쿠폰</span></div>
            <div className={`${admin.admin_give_coupon_tap}`}>
                <div className={`${activeButton === 'all' ? admin.admin_give_coupon_tap_active : ''}`}><button onClick={() => setActiveButton('all')}>전체 (0)</button></div>
                <div className={`${activeButton === 'wait' ? admin.admin_give_coupon_tap_active : ''}`}><button onClick={() => setActiveButton('wait')}>대기 (0)</button></div>
                <div className={`${activeButton === 'ing' ? admin.admin_give_coupon_tap_active : ''}`}><button onClick={() => setActiveButton('ing')}>진행중 (0)</button></div>
                <div className={`${activeButton === 'end' ? admin.admin_give_coupon_tap_active : ''}`}><button onClick={() => setActiveButton('end')}>종료 (0)</button></div>
            </div>
            <div className={`${admin.admin_give_coupon_search_add}`}>
                <div className={`${admin.admin_give_coupon_search}`}>
                    <div><input type="text" /></div>
                    <div className='ps-1'><button>검색</button></div>
                </div>
                <div className={`${admin.admin_give_coupon_add}`}>
                    <div><button onClick={() => setShowCouponModal(true)}>쿠폰 등록</button></div>
                </div>
            </div>
            <div>
                <div>
                    <div className={`${admin.admin_give_coupon_content_header}`}>
                        <div><input type="checkbox" name="" id="" /></div>
                        <div><span>쿠폰명/사용혜택</span></div>
                        <div><span>상태</span></div>
                        <div><span>쿠폰형식</span></div>
                        <div><span>사용/발행</span></div>
                        <div><span>사용기간</span></div>
                        <div><span>등록일</span></div>
                        <div><span>수정일</span></div>
                        <div></div>
                    </div>
                    {
                        couponList.map((item, index) => (
                            <div className={`${admin.admin_give_coupon_content_content}`}>
                                <div><input type="checkbox" name="" id="" /></div>
                                <div>
                                    <div><span>{item.couponName}</span></div>
                                    <div><span>{benefitCheck(item.couponBenefit)}</span><span>{`(${item.couponDiscountCondition.toLocaleString()}원 이상 구매시 / 최대 ${item.couponMaximumDiscount.toLocaleString()}원)`}</span></div>
                                </div>
                                <div><span>{stateCheck(item.couponState)}</span></div>
                                <div>
                                    <span>{typeCheck(item.couponType, item.couponCodeType, item.couponCodeName, item.couponCodeCount)}</span>
                                    <br/>
                                    {
                                        item.couponType === 'auto' &&
                                        <span className={`${admin.admin_coupon_issue_tag}`}>({issueCheck(item.couponConditionIssue)})</span>
                                    }
                                </div>
                                <div><button onClick={(e) => {e.stopPropagation();}}>{`${item.couponUsage}회/${item.couponIssuance}회`}</button></div>
                                <div>
                                    {dateCheck(item.couponStartDate, item.couponEndDate)}
                                </div>
                                <div><span>{formatDate(item.couponRegDate)}</span></div>
                                <div><span>{formatDate(item.couponUpdDate)}</span></div>
                                <div>
                                    <div className='py-1'><button onClick={(e) => {e.stopPropagation(); handleCouponUpdateClick(item)}}>수정</button></div>
                                    <div className='py-1'><button onClick={(e) => {e.stopPropagation();}}>삭제</button></div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
        {
            showCouponModal &&
            <div className={`${admin.admin_coupon_modal_container}`}>
                <div className={`${admin.admin_coupon_modal_box}`}>
                    <div className={`${admin.admin_coupon_modal_header}`}>
                        <div><span>쿠폰등록</span></div>
                        <div><button onClick={() => setShowCouponModal(false)}><span className="material-symbols-outlined">close</span></button></div>
                    </div>
                    <div className={`${admin.admin_coupon_modal_content}`}>
                        <div className={`${admin.admin_coupon_modal_content_coupon_name}`}>
                            <div><span>쿠폰명</span></div>
                            <div><input type="text" onChange={(e) => onCouponName(e)}/></div>
                        </div>
                        <div className={`${admin.admin_coupon_modal_content_coupon_type}`}>
                            <div><span>쿠폰형식</span></div>
                            <div>
                                <div>
                                    <input type="radio" name='coupon_type' id='user' onChange={(e) => onCouponType(e)}/>
                                    <label className='ps-1' htmlFor="user">유저 지정 발급</label>
                                </div>
                                <div className='ps-3'>
                                    <input type="radio" name='coupon_type' id='code' onChange={(e) => onCouponType(e)}/>
                                    <label className='ps-1' htmlFor="code">쿠폰코드 생성</label>
                                </div>
                                <div className='ps-3'>
                                    <input type="radio" name='coupon_type' id='auto' onChange={(e) => onCouponType(e)}/>
                                    <label className='ps-1' htmlFor="auto">자동발행</label>
                                </div>
                            </div>
                        </div>
                        {
                            activeCouponType === 'user' &&
                            <div className={`${admin.admin_coupon_modal_content_coupon_type_user}`}>
                                <div>
                                    <div><span>유저 리스트</span></div>
                                    <div><span>선택 유저 리스트</span></div>
                                </div>
                                <div>
                                    <div className={`${admin.admin_point_modal_content_total_user_list}`}>
                                        {
                                            userList.map((user, index) => (
                                                <div key={index}>
                                                    <div><input 
                                                        type="checkbox" 
                                                        checked={selectedUsers.some(selectedUser => selectedUser.userNo === user.userNo)} 
                                                        onChange={() => handleUserCheckboxChange(user)} 
                                                    /></div>
                                                    <div className='ps-1'>
                                                        <div><span>{user.userName !== null ? user.userName : '이름없음'}</span>&nbsp;/&nbsp;</div>
                                                        <div><span>{user.userId}</span>&nbsp;/&nbsp;</div>
                                                        <div><span>{user.userEmail}</span></div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <div className={`${admin.admin_point_modal_content_select_user_list}`}>
                                        {
                                            selectedUsers.map((user, index) => (
                                                <div key={index}>
                                                    <div><input type="checkbox" checked={true} onChange={() => handleUserCheckboxChange(user)} /></div>
                                                    <div className='ps-1'>
                                                        <div><span>{user.userName !== null ? user.userName : '이름없음'}</span>&nbsp;/&nbsp;</div>
                                                        <div><span>{user.userId}</span>&nbsp;/&nbsp;</div>
                                                        <div><span>{user.userEmail}</span></div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            activeCouponType === 'code' &&
                            <div className={`${admin.admin_coupon_modal_content_coupon_type_code}`}>
                                <div><span>쿠폰코드</span></div>
                                <div>
                                    <div>
                                        <Select
                                            className={`${admin.admin_coupon_modal_content_coupon_type_code_select}`}
                                            onChange={(value) => onCouponTypeCode(value)}
                                            value={selectCodeType === '' ? 'single_code' : selectCodeType}
                                        >
                                            <Option value='single_code'>단일 쿠폰코드</Option>
                                            <Option value='multi_code'>여러 쿠폰코드</Option>
                                        </Select>
                                    </div>
                                    {
                                        selectCodeType === 'single_code' ?
                                        <div>
                                            <input type="text" placeholder='발급하실 코드이름을 작성해주세요.'/>
                                        </div>
                                        :
                                        <div>
                                            <input type="text" placeholder='발급하실 코드개수를 작성해주세요.'/>
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                        {
                            activeCouponType === 'auto' &&
                            <div className={`${admin.admin_coupon_modal_content_coupon_type_auto}`}>
                                <div><span>발행조건</span></div>
                                <div>
                                    <Select
                                        className={`${admin.admin_coupon_modal_content_coupon_type_auto_select}`}
                                        placeholder="발행 조건을 선택해주세요."
                                        onChange={(value) => onCouponTypeAuto(value)}
                                    >
                                        <Option value='join'>첫 회원가입</Option>
                                        <Option value='product'>첫 상품구매</Option>
                                        <Option value='event'>첫 이벤트참여</Option>
                                    </Select>
                                </div>
                            </div>
                        }
                        <div className={`${admin.admin_coupon_modal_content_coupon_benefit}`}>
                            <div><span>사용 혜택</span></div>
                            <div>
                                <div>
                                    <Select
                                         className={`${admin.admin_coupon_modal_content_coupon_benefit_select}`}
                                         onChange={(value) => onBenefitSelect(value)}
                                    >
                                        <Option value='amount'>금액할인</Option>
                                        <Option value='delivery'>배송비 할인</Option>
                                    </Select>
                                </div>
                                {
                                    activeBenefitType === 'amount' &&
                                    <div>
                                        <div><input type="number" name="" id="" onChange={(e) => onCouponBenefitNum(e)}/></div>
                                        <div>
                                            <Select
                                                className={`${admin.admin_coupon_modal_content_coupon_benefit_amount_select}`}
                                                onChange={(value) => onCouponBenefitType(value)}
                                            >
                                                <Option value='won'>원</Option>
                                                <Option value='rate'>%</Option>
                                            </Select>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className={`${admin.admin_coupon_modal_content_coupon_discount_condition}`}>
                            <div><span>최소주문금액</span></div>
                            <div>
                                <div>
                                    <div><input type="number" onChange={(e) => onDiscountCondition(e)}/></div>
                                    <div><span>원</span></div>
                                </div>
                            </div>
                        </div>
                        <div className={`${admin.admin_coupon_modal_content_coupon_maximum_discount}`}>
                            <div><span>최대할인금액</span></div>
                            <div>
                                <div>
                                    <div><input type="number" onChange={(e) => onMaximumDiscount(e)}/></div>
                                    <div><span>원</span></div>
                                </div>
                            </div>
                        </div>
                        <div className={`${admin.admin_coupon_modal_content_coupon_date_use}`}>
                            <div><span>사용기한</span></div>
                            <div>
                                <div ref={ref}>
                                    <RangePicker className={`${admin.admin_userList_datepicker}`}
                                        separator="~"
                                        placeholder={['0000.00.00', '0000.00.00']}
                                        onChange={handleDateChange}
                                    />
                                </div>
                                <div>
                                    <input type="checkbox" name="" id="" onChange={(e) => onDateRangeCheck(e)}/>
                                    <label className='ps-1' htmlFor="">기한제한없음</label>
                                </div>
                            </div>
                        </div>
                        <div className={`${admin.admin_coupon_modal_content_coupon_state}`}>
                            <div><span>쿠폰상태</span></div>
                            <div>
                                <Select
                                    className={`${admin.admin_coupon_modal_content_coupon_state_select}`}
                                    onChange={(value) => onCouponState(value)}
                                >
                                    <Option value='ing'>진행중</Option>
                                    <Option value='wait'>대기</Option>
                                    <Option value='end'>종료</Option>
                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className={`${admin.admin_coupon_modal_footer}`}>
                        <div><button onClick={() => setShowCouponModal(false)}>취소</button></div>
                        <div><button onClick={couponAddBtn}>쿠폰등록</button></div>
                    </div>
                </div>
            </div>
        }

        {
            showCouponUpdateModal &&
            <div className={`${admin.admin_coupon_modal_container}`}>
                <div className={`${admin.admin_coupon_modal_box}`}>
                    <div className={`${admin.admin_coupon_modal_header}`}>
                        <div><span>쿠폰 수정</span></div>
                        <div><button onClick={() => setShowCouponUpdateModal(false)}><span className="material-symbols-outlined">close</span></button></div>
                    </div>
                    <div className={`${admin.admin_coupon_modal_content}`}>
                        <div className={`${admin.admin_coupon_modal_content_coupon_name}`}>
                            <div><span>쿠폰명</span></div>
                            <div><input type="text" value={couponUpdateInfo.couponName} onChange={(e) => setCouponUpdateInfo({...couponUpdateInfo, couponName: e.target.value})}/></div>
                        </div>
                        <div className={`${admin.admin_coupon_modal_content_coupon_type}`}>
                            <div><span>쿠폰형식</span></div>
                            <div>
                                <div>
                                    <input type="radio" name='coupon_type_update' id='user_update' checked={couponUpdateInfo.couponType === 'user'} onChange={(e) => setCouponUpdateInfo({...couponUpdateInfo, couponType: e.target.id})}/>
                                    <label className='ps-1' htmlFor="user_update">유저 지정 발급</label>
                                </div>
                                <div className='ps-3'>
                                    <input type="radio" name='coupon_type_update' id='code_update' checked={couponUpdateInfo.couponType === 'code'} onChange={(e) => setCouponUpdateInfo({...couponUpdateInfo, couponType: e.target.id})}/>
                                    <label className='ps-1' htmlFor="code_update">쿠폰코드 생성</label>
                                </div>
                                <div className='ps-3'>
                                    <input type="radio" name='coupon_type_update' id='auto_update' checked={couponUpdateInfo.couponType === 'auto'} onChange={(e) => setCouponUpdateInfo({...couponUpdateInfo, couponType: e.target.id})}/>
                                    <label className='ps-1' htmlFor="auto_update">자동발행</label>
                                </div>
                            </div>
                        </div>
                        {
                            couponUpdateInfo.couponType === 'user' &&
                            <div className={`${admin.admin_coupon_modal_content_coupon_type_user}`}>
                                <div>
                                    <div><span>유저 리스트</span></div>
                                    <div><span>선택 유저 리스트</span></div>
                                </div>
                                <div>
                                    <div className={`${admin.admin_point_modal_content_total_user_list}`}>
                                        {
                                            userList.map((user, index) => (
                                                <div key={index}>
                                                    <div><input 
                                                        type="checkbox" 
                                                        checked={selectedUsers.some(selectedUser => selectedUser.userNo === user.userNo)} 
                                                        onChange={() => handleUserCheckboxChange(user)} 
                                                    /></div>
                                                    <div className='ps-1'>
                                                        <div><span>{user.userName !== null ? user.userName : '이름없음'}</span>&nbsp;/&nbsp;</div>
                                                        <div><span>{user.userId}</span>&nbsp;/&nbsp;</div>
                                                        <div><span>{user.userEmail}</span></div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <div className={`${admin.admin_point_modal_content_select_user_list}`}>
                                        {
                                            selectedUsers.map((user, index) => (
                                                <div key={index}>
                                                    <div><input type="checkbox" checked={true} onChange={() => handleUserCheckboxChange(user)} /></div>
                                                    <div className='ps-1'>
                                                        <div><span>{user.userName !== null ? user.userName : '이름없음'}</span>&nbsp;/&nbsp;</div>
                                                        <div><span>{user.userId}</span>&nbsp;/&nbsp;</div>
                                                        <div><span>{user.userEmail}</span></div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            couponUpdateInfo.couponType === 'code' &&
                            <div className={`${admin.admin_coupon_modal_content_coupon_type_code}`}>
                                <div><span>쿠폰코드</span></div>
                                <div>
                                    <div>
                                        <Select
                                            className={`${admin.admin_coupon_modal_content_coupon_type_code_select}`}
                                            onChange={(value) => setCouponUpdateInfo({...couponUpdateInfo, couponCodeType: value})}
                                            value={couponUpdateInfo.couponCodeType || 'single_code'}
                                        >
                                            <Option value='single_code'>단일 쿠폰코드</Option>
                                            <Option value='multi_code'>여러 쿠폰코드</Option>
                                        </Select>
                                    </div>
                                    {
                                        couponUpdateInfo.couponCodeType === 'single_code' ?
                                        <div>
                                            <input type="text" value={couponUpdateInfo.couponCodeName || ''} onChange={(e) => setCouponUpdateInfo({...couponUpdateInfo, couponCodeName: e.target.value})} placeholder='발급하실 코드이름을 작성해주세요.'/>
                                        </div>
                                        :
                                        <div>
                                            <input type="text" value={couponUpdateInfo.couponCodeCount || ''} onChange={(e) => setCouponUpdateInfo({...couponUpdateInfo, couponCodeCount: e.target.value})} placeholder='발급하실 코드개수를 작성해주세요.'/>
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                        {
                            couponUpdateInfo.couponType === 'auto' &&
                            <div className={`${admin.admin_coupon_modal_content_coupon_type_auto}`}>
                                <div><span>발행조건</span></div>
                                <div>
                                    <Select
                                        className={`${admin.admin_coupon_modal_content_coupon_type_auto_select}`}
                                        placeholder="발행 조건을 선택해주세요."
                                        onChange={(value) => setCouponUpdateInfo({...couponUpdateInfo, couponConditionIssue: value})}
                                        value={couponUpdateInfo.couponConditionIssue || ''}
                                    >
                                        <Option value='join'>첫 회원가입</Option>
                                        <Option value='product'>첫 상품구매</Option>
                                        <Option value='event'>첫 이벤트참여</Option>
                                    </Select>
                                </div>
                            </div>
                        }
                        <div className={`${admin.admin_coupon_modal_content_coupon_benefit}`}>
                            <div><span>사용 혜택</span></div>
                            <div>
                                <div>
                                    <Select
                                        className={`${admin.admin_coupon_modal_content_coupon_benefit_select}`}
                                        onChange={(value) => setCouponUpdateInfo({...couponUpdateInfo, couponBenefit: value})}
                                        value={couponUpdateInfo.couponBenefit || ''}
                                    >
                                        <Option value='amount'>금액할인</Option>
                                        <Option value='delivery'>배송비 할인</Option>
                                    </Select>
                                </div>
                                {
                                    couponUpdateInfo.couponBenefit === 'amount' &&
                                    <div>
                                        <div><input type="number" value={couponUpdateInfo.couponBenefitNum || ''} onChange={(e) => setCouponUpdateInfo({...couponUpdateInfo, couponBenefitNum: e.target.value})}/></div>
                                        <div>
                                            <Select
                                                className={`${admin.admin_coupon_modal_content_coupon_benefit_amount_select}`}
                                                onChange={(value) => setCouponUpdateInfo({...couponUpdateInfo, couponBenefitType: value})}
                                                value={couponUpdateInfo.couponBenefitType || ''}
                                            >
                                                <Option value='won'>원</Option>
                                                <Option value='rate'>%</Option>
                                            </Select>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className={`${admin.admin_coupon_modal_content_coupon_discount_condition}`}>
                            <div><span>최소주문금액</span></div>
                            <div>
                                <div>
                                    <div><input type="number" value={couponUpdateInfo.couponDiscountCondition || ''} onChange={(e) => setCouponUpdateInfo({...couponUpdateInfo, couponDiscountCondition: e.target.value})}/></div>
                                    <div><span>원</span></div>
                                </div>
                            </div>
                        </div>
                        <div className={`${admin.admin_coupon_modal_content_coupon_maximum_discount}`}>
                            <div><span>최대할인금액</span></div>
                            <div>
                                <div>
                                    <div><input type="number" value={couponUpdateInfo.couponMaximumDiscount || ''} onChange={(e) => setCouponUpdateInfo({...couponUpdateInfo, couponMaximumDiscount: e.target.value})}/></div>
                                    <div><span>원</span></div>
                                </div>
                            </div>
                        </div>
                        <div className={`${admin.admin_coupon_modal_content_coupon_date_use}`}>
                            <div><span>사용기한</span></div>
                            <div>
                                <div ref={ref}>
                                    <RangePicker 
                                        className={`${admin.admin_userList_datepicker}`}
                                        separator="~"
                                        placeholder={['0000.00.00', '0000.00.00']}
                                        onChange={(dates, dateStrings) => handleDateChange(dates, dateStrings)}
                                        value={selectDate.length ? [moment(selectDate[0]), moment(selectDate[1])] : []}
                                    />
                                </div>
                                <div>
                                    <input type="checkbox" name="" id="" checked={couponUpdateInfo.couponStartDate === '1899-12-31T15:00:00.000+00:00' && couponUpdateInfo.couponEndDate === '2999-12-29T15:00:00.000+00:00'} onChange={(e) => onDateRangeCheck(e)}/>
                                    <label className='ps-1' htmlFor="">기한제한없음</label>
                                </div>
                            </div>
                        </div>
                        <div className={`${admin.admin_coupon_modal_content_coupon_state}`}>
                            <div><span>쿠폰상태</span></div>
                            <div>
                                <Select
                                    className={`${admin.admin_coupon_modal_content_coupon_state_select}`}
                                    onChange={(value) => setCouponUpdateInfo({...couponUpdateInfo, couponState: value})}
                                    value={couponUpdateInfo.couponState || ''}
                                >
                                    <Option value='ing'>진행중</Option>
                                    <Option value='wait'>대기</Option>
                                    <Option value='end'>종료</Option>
                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className={`${admin.admin_coupon_modal_footer}`}>
                        <div><button onClick={() => setShowCouponUpdateModal(false)}>취소</button></div>
                        <div><button onClick={couponUpdateBtn}>쿠폰수정</button></div>
                    </div>
                </div>
            </div>
        }

    </div>
  )
}

export default AdminCoupon