import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import * as support from '../../../../apis/support';

const SupportPaymentSuccess = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [verificationResult, setVerificationResult] = useState(null);
    const [supportPaymentInfo, setSupportPaymentInfo] = useState({});

    useEffect(() => {
        // console.log('supportPaymentInfo : ', supportPaymentInfo);
    }, [supportPaymentInfo]);

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const paymentKey = query.get('paymentKey');
        const orderId = query.get('orderId');
        const amount = query.get('amount');
        const reservation = JSON.parse(localStorage.getItem('reservation'));

        

        // console.log('paymentKey : ', paymentKey);
        // console.log('orderId : ', orderId);
        // console.log('amount : ', amount);
        // console.log('reservation : ', reservation);

        const verifyPayment = async () => {
            try {
                const response = await axios.post('/api/payment/verify', {
                    paymentKey: paymentKey,
                    orderId: orderId,
                    amount: amount
                });
                setVerificationResult(response.data);
                // console.log('response.data : ', response.data);

                let updatedSupportPaymentInfo = {};
                if(response.data.method === '가상계좌') {
                    updatedSupportPaymentInfo = {
                        ...reservation,
                        paymentKey: paymentKey,
                        orderId: orderId,
                        amount: Number(amount),
                        reservationPaymentType : response.data.method,
                        paymentBankName : response.data.virtualAccount.bankCode,
                        paymentAccountNumber : response.data.virtualAccount.accountNumber,
                        paymentDepositDeadline : response.data.virtualAccount.dueDate
                    };

                } else if(response.data.method === '카드') {
                    updatedSupportPaymentInfo = {
                        ...reservation,
                        paymentKey: paymentKey,
                        orderId: orderId,
                        amount: Number(amount),
                        reservationPaymentType : response.data.method,
                        issuerCode : response.data.card.issuerCode
                    };
                } else if(response.data.method === '간편결제') {
                    updatedSupportPaymentInfo = {
                        ...reservation,
                        paymentKey: paymentKey,
                        orderId: orderId,
                        amount: Number(amount),
                        reservationPaymentType : response.data.method,
                        issuerCode: response.data.card && response.data.card.issuerCode ? response.data.card.issuerCode : '',
                        easyPay : response.data.easyPay.provider
                    };
                } else if(response.data.method === '계좌이체') {
                    updatedSupportPaymentInfo = {
                        ...reservation,
                        paymentKey: paymentKey,
                        orderId: orderId,
                        amount: Number(amount),
                        reservationPaymentType : response.data.method,
                        transferBankCode : response.data.transfer.bankCode
                    };
                } else if(response.data.method === '해외간편결제') {
                    updatedSupportPaymentInfo = {
                        ...reservation,
                        paymentKey: paymentKey,
                        orderId: orderId,
                        amount: Number(amount),
                        reservationPaymentType : response.data.method,
                        currency : response.data.currency,
                        easyPay : response.data.easyPay
                    }
                }

                // console.log('updatedSupportPaymentInfo : ', updatedSupportPaymentInfo);
        
                setSupportPaymentInfo(updatedSupportPaymentInfo);

                if (response.data.status === "DONE") {
                    try {
                        const response = await fetch('/api/payment', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                paymentKey: paymentKey,
                                amount: reservation.totalPrice
                            }),
                        });

                        const result = await response.json();
                        // console.log('result : ', result);

                        if (result.success) {
                            setSupportPaymentInfo((prevState) => {
                                const newState = { ...prevState, ...result };
                                supportPaymentAdd(newState);
                                return newState;
                            });
                        } else {
                            // 서버 검증 실패 시 로직
                            alert(`결제 검증에 실패하였습니다: ${result.error_msg}`);
                        }
                    } catch (error) {
                        console.error('error : ', error);
                    }
                } else if(response.data.status === "WAITING_FOR_DEPOSIT") {
                    try {
                        const response = await fetch('/api/payment', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                paymentKey: paymentKey,
                                amount: reservation.totalPrice
                            }),
                        });

                        const result = await response.json();
                        // console.log('result : ', result);

                        if (result.success) {
                            setSupportPaymentInfo((prevState) => {
                                const newState = { ...prevState, ...result };
                                supportPaymentAdd(newState);
                                return newState;
                            });
                        } else {
                            // 서버 검증 실패 시 로직
                            alert(`결제 검증에 실패하였습니다: ${result.error_msg}`);
                        }
                    } catch (error) {
                        console.error('error : ', error);
                    }
                } else {
                    // 결제 실패 처리
                    navigate(`/support/payment/fail`);
                }
            } catch (error) {
                console.error('결제 검증 오류:', error);
                navigate(`/store/payment/fail`);
            } finally {
                setLoading(false);
            }
        };

        verifyPayment();
    }, [location, navigate]);

    const supportPaymentAdd = async (paymentInfo) => {
        // console.log('supportPaymentInfo : ', paymentInfo);

        // 서버 검증 성공 시 로직
        try {
            const response = await support.reservation(paymentInfo);
            const data = response.data;
            // console.log(data);

            const reservationNo = paymentInfo.reservationNo;

            // console.log('reservationNo = ', reservationNo);

            navigate(`/payment/success/${reservationNo}`);
        } catch (error) {
            console.error('Error storing payment info:', error);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            {verificationResult ? (
                <div>결제가 성공적으로 완료되었습니다!</div>
            ) : (
                <div>결제 검증에 실패하였습니다.</div>
            )}
        </div>
    );
};

export default SupportPaymentSuccess;
