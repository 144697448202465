import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import admin from '../../../admin.module.css';
import { Select } from 'antd';
import { Option } from 'antd/es/mentions';
import * as adminApi from '../../../../apis/admin';import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const AdminSellerManagementDetail = () => {
    const {no} = useParams();
    const navigate = useNavigate();
    const MySwal = withReactContent(Swal);
    const [ activeButton, setActiveButton ] = useState('seller');
    const [ shopInfo, setShopInfo ] = useState(null);
    const [ userInfo, setUserInfo ] = useState({});
    const [ showRefusalModal, setShowRefusalModal ] = useState(false);
    const [ refusalContent, setRefusalContent ] = useState('');
    const [showFullResidentNumber, setShowFullResidentNumber] = useState(false);

    // console.log('sellerNo : ' + no);

    useEffect(() => {
        shopSelect();
        userSelect();
    },[no]);

    useEffect(() => {
        // console.log('shopInfo : ', shopInfo);
    }, [shopInfo]);

    const shopSelect = async () => {
        const response = await adminApi.noShopSelect(no);
        const data = response.data;

        // console.log('shopSelect : ', data);
        setShopInfo(data);
    }

    const userSelect = async () => {
        const response = await adminApi.noUserSelect(no);
        const data = response.data;

        // console.log('userSelect : ', data);
        setUserInfo(data);
    }

    const onSellerUpdate = (no) => {
        navigate(`/admin/seller/management/update/${no}`);
    }

    const classficationCheck = (text) => {
        if(text === 'storeseller') {
            return '스토어셀러';
        }
        if(text === 'supportseller') {
            return '서포트셀러';
        }
    }

    const onSellerJoinApproval = (no) => {
        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html : 
            `
                <div>
                    <div class='${admin.admin_seller_add_title}'><span>셀러 가입을 승인 하시겠습니까?</span></div>
                    <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                      <button class='mx-1' id='seller_add_cancel'>취소</button>
                      <button class='mx-1' id='seller_add_success'>승인</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });
        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);
        
        async function sellerAddSuccess(e) {
            if(e.target && e.target.id == 'seller_add_success') {
                const response = await adminApi.sellerJoinApproval(no);
                const data = response.data;

                if(data === 'SUCCESS') {
                    MySwal.fire({
                        customClass: {
                            popup: `${admin.admin_seller_add_popup}`
                        },
                        html : 
                        `
                        <div>
                        <div class='${admin.admin_seller_add_title}'><span>셀러 가입이 승인되었습니다.</span></div>
                        <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                        </div>
                        </div>
                        `,
                        showConfirmButton: false,
                        showCancelButton: false,
                    });
                    document.addEventListener('click', sellerAddConfirm);
                    document.removeEventListener('click', sellerAddSuccess);
                    document.removeEventListener('click', sellerAddCancel);
                    
                    function sellerAddConfirm(e) {
                        if(e.target && e.target.id == 'seller_add_confirm') {
                            MySwal.close();
                            document.removeEventListener('click', sellerAddConfirm);
                            navigate(-1);
                        }
                    }
                } else {
                    MySwal.fire({
                        customClass: {
                            popup: `${admin.admin_seller_add_popup}`
                        },
                        html : 
                        `
                        <div>
                        <div class='${admin.admin_seller_add_title}'><span>셀러 가입 승인에 실패하였습니다.</span></div>
                        <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                        </div>
                        </div>
                        `,
                        showConfirmButton: false,
                        showCancelButton: false,
                    });
                    document.addEventListener('click', sellerAddConfirm);
                    document.removeEventListener('click', sellerAddSuccess);
                    document.removeEventListener('click', sellerAddCancel);
                    
                    function sellerAddConfirm(e) {
                        if(e.target && e.target.id == 'seller_add_confirm') {
                            MySwal.close();
                            document.removeEventListener('click', sellerAddConfirm);
                        }
                    }

                }
            }
        }
        
        function sellerAddCancel(e) {
            if(e.target && e.target.id == 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
            }
        }
    }

    const onSellerJoinRefusal = () => {
        setShowRefusalModal(!showRefusalModal);
    }

    const onRefusalRejection = (e) => {
        setRefusalContent(e.target.value);
    }
    const handleSellerRefusal = () => {
        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html : 
            `
                <div>
                    <div class='${admin.admin_seller_add_title}'><span>셀러 가입을 거절 하시겠습니까?</span></div>
                    <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                      <button class='mx-1' id='seller_add_cancel'>취소</button>
                      <button class='mx-1' id='seller_add_success'>거절</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });
        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);
        
        async function sellerAddSuccess(e) {
            if(e.target && e.target.id == 'seller_add_success') {
                const response = await adminApi.sellerJoinRefusal(no, refusalContent);
                const data = response.data;

                if(data === 'SUCCESS') {
                    MySwal.fire({
                        customClass: {
                            popup: `${admin.admin_seller_add_popup}`
                        },
                        html : 
                        `
                        <div>
                        <div class='${admin.admin_seller_add_title}'><span>셀러 가입이 거절되었습니다.</span></div>
                        <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                        </div>
                        </div>
                        `,
                        showConfirmButton: false,
                        showCancelButton: false,
                    });
                    document.addEventListener('click', sellerAddConfirm);
                    document.removeEventListener('click', sellerAddSuccess);
                    document.removeEventListener('click', sellerAddCancel);
                    
                    function sellerAddConfirm(e) {
                        if(e.target && e.target.id == 'seller_add_confirm') {
                            MySwal.close();
                            document.removeEventListener('click', sellerAddConfirm);
                            navigate(-1);
                        }
                    }
                } else {
                    MySwal.fire({
                        customClass: {
                            popup: `${admin.admin_seller_add_popup}`
                        },
                        html : 
                        `
                        <div>
                        <div class='${admin.admin_seller_add_title}'><span>셀러 가입 거절에 실패하였습니다.</span></div>
                        <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                        </div>
                        </div>
                        `,
                        showConfirmButton: false,
                        showCancelButton: false,
                    });
                    document.addEventListener('click', sellerAddConfirm);
                    document.removeEventListener('click', sellerAddSuccess);
                    document.removeEventListener('click', sellerAddCancel);
                    
                    function sellerAddConfirm(e) {
                        if(e.target && e.target.id == 'seller_add_confirm') {
                            MySwal.close();
                            document.removeEventListener('click', sellerAddConfirm);
                        }
                    }

                }
            }
        }
        
        function sellerAddCancel(e) {
            if(e.target && e.target.id == 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
            }
        }
    }

    const downloadBankbookFile = async (fileUrl, shopName) => {
        try {
          const response = await fetch(fileUrl);
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = `${shopName} 통장 사본`;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        } catch (error) {
          console.error('File download error:', error);
        }
      };
    const downloadMailOrderFile = async (fileUrl, shopName) => {
        try {
          const response = await fetch(fileUrl);
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = `${shopName} 통신판매업신고증`;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        } catch (error) {
          console.error('File download error:', error);
        }
      };

    const toggleFullResidentNumber = () => {
        setShowFullResidentNumber((prev) => !prev);
    };

    const formatResidentNumber = (number) => {
        if (!number || number.length !== 13) return { front: "", maskedBack: "" };

        const front = number.slice(0, 6);
        const back = showFullResidentNumber
            ? number.slice(6) // 전체 번호 표시
            : `${number[6]}******`; // 첫 번째 자리만 남기고 마스킹 처리

        return { front, back };
    };

    const { front, back } = formatResidentNumber(userInfo.residentRegistrationNumber);

    return (
        <div className={`${admin.admin_seller_manage_datail_box} px-3 py-3`}>
            <div>
                <div className={`${admin.admin_seller_manage_detail_header} py-3`}>
                    <div><span>셀러 상세정보</span></div>
                    <div>
                        <div><button onClick={() => onSellerJoinApproval(no)}>가입승인</button></div>
                        <div><button onClick={() => onSellerJoinRefusal()}>가입거절</button></div>
                        <div><button onClick={() => onSellerUpdate(no)}>정보수정</button></div>
                    </div>
                </div>
                <div className={`${admin.admin_seller_manage_detail_tab} py-3`}>
                    <div className={`${activeButton === 'seller' ? admin.admin_seller_manage_detail_tab_active : ''}`}><button onClick={() => setActiveButton('seller')}>셀러 정보</button></div>
                    <div className={`${activeButton === 'store' ? admin.admin_seller_manage_detail_tab_active : ''}`}><button onClick={() => setActiveButton('store')}>상점 정보</button></div>
                    <div className={`${activeButton === 'calculate' ? admin.admin_seller_manage_detail_tab_active : ''}`}><button onClick={() => setActiveButton('calculate')}>정산 정보</button></div>
                    <div className={`${activeButton === 'buisness' ? admin.admin_seller_manage_detail_tab_active : ''}`}><button onClick={() => setActiveButton('buisness')}>사업자 정보</button></div>
                </div>
                {
                    activeButton === 'seller' &&
                    <div className={`${admin.admin_seller_manage_detail_seller_box}`}>
                        <div>
                            <div><span>셀러구분</span></div>
                            <div><span>{classficationCheck(userInfo.userClassification)}</span></div>
                        </div>
                        <div>
                            <div><span>아이디</span></div>
                            <div><span>{userInfo.userId}</span></div>
                        </div>
                        <div>
                            <div><span>이메일</span></div>
                            <div><span>{userInfo.userEmail}</span></div>
                        </div>
                        <div>
                            <div><span>이름</span></div>
                            <div><span>{userInfo.userName}</span></div>
                        </div>
                        <div>
                            <div><span>주소</span></div>
                            <div><span>{userInfo.businessLocation}</span></div>
                        </div>
                        <div>
                            <div><span>연락처</span></div>
                            <div><span>{userInfo.userTel}</span></div>
                        </div>
                        <div>
                            <div><span>주민등록번호</span></div>
                            <div>
                                <div><span>{front}</span></div>
                                <div><span>-</span></div>
                                <div><span>{back}</span></div>
                                <div><button onClick={toggleFullResidentNumber}>
                                    {showFullResidentNumber ? "숨기기" : "전체보기"}
                                </button></div>
                            </div>
                        </div>
                    </div>
                }
                {
                    activeButton === 'store' && 
                        <div className={`${admin.admin_seller_manage_detail_shop_info_box}`}>
                            <div>
                                <div><span>상점명</span></div>
                                <div><span>{shopInfo && shopInfo?.shopName}</span></div>
                            </div>
                            <div>
                                <div><span>상점 소개</span></div>
                                <div><span>{shopInfo && shopInfo?.shopDetailContent}</span></div>
                            </div>
                            <div>
                                <div><span>상점 프로필</span></div>
                                <div>
                                    {
                                        shopInfo && shopInfo?.shopProfileImg !== '' ?
                                        <div className={`${admin.admin_seller_manage_detail_shop_info_profile}`}><img src={shopInfo?.shopProfileImg} alt="" /></div>
                                        :
                                        <div><span>프로필이 등록되지 않았습니다.</span></div>
                                    }
                                </div>
                            </div>
                            <div>
                                <div><span>상점 배경 사진</span></div>
                                <div>
                                    {
                                        shopInfo && shopInfo?.shopBackImg !== '' ?
                                        <div className={`${admin.admin_seller_manage_detail_shop_info_back}`}><img src={shopInfo?.shopBackImg} alt="" /></div>
                                        :
                                        <div><span>상점 배경이 등록되지 않았습니다.</span></div>
                                    }
                                </div>
                            </div>
                        </div>
                }
                {
                    activeButton === 'calculate' &&
                    <div className={`${admin.admin_seller_manage_detail_calcul_info_box}`}>
                        <div>
                            <div><span>정산 계좌 은행</span></div>
                            <div>
                                <div><img src={`${process.env.PUBLIC_URL}${shopInfo?.shopBankImg}`} alt="" /></div>
                                <div><span>{shopInfo?.shopBankName}</span></div>
                            </div>
                        </div>
                        <div>
                            <div><span>정산 계좌 번호</span></div>
                            <div><span>{shopInfo?.shopAccountNumber}</span></div>
                        </div>
                        <div>
                            <div><span>예금주명</span></div>
                            <div><span>{shopInfo?.shopDepositorName}</span></div>
                        </div>
                    </div>
                }
                {
                    activeButton === 'buisness' &&
                    <div className={`${admin.admin_seller_manage_detail_business_box}`}>
                        <div>
                            <div><span>사업자등록번호</span></div>
                            <div><span>{userInfo && userInfo?.businessRegistrationNumber}</span></div>
                        </div>
                        <div>
                            <div><span>대표자명</span></div>
                            <div><span>{userInfo && userInfo?.representativeName}</span></div>
                        </div>
                        <div>
                            <div><span>업태</span></div>
                            <div><span>{userInfo && userInfo?.businessStatus}</span></div>
                        </div>
                        <div>
                            <div><span>업종</span></div>
                            <div><span>{userInfo && userInfo?.businessSectors}</span></div>
                        </div>
                        <div>
                            <div><span>상호명</span></div>
                            <div><span>{userInfo && userInfo?.businessName}</span></div>
                        </div>
                        <div>
                            <div><span>사업장 소재지</span></div>
                            <div><span>{userInfo && userInfo?.businessLocation}</span></div>
                        </div>
                        <div>
                            <div><span>사업자 통장 사본</span></div>
                            {
                                userInfo.businessBankbookImg !== null && userInfo && userInfo?.businessBankbookImg !== '' ?
                                <div><button onClick={() => downloadBankbookFile(userInfo?.businessBankbookImg, shopInfo?.shopName)}>다운받기</button></div>
                                :
                                <div><span>등록된 파일이 없습니다.</span></div>
                            }
                        </div>
                        <div>
                            <div><span>통신판매업신고번호(선택)</span></div>
                            <div><span>{userInfo && userInfo?.businessMailOrderNumber}</span></div>
                        </div>
                        <div>
                            <div><span>통신판매업신고증(선택)</span></div>
                            {
                                userInfo.businessMailOrderImg !== null && userInfo && userInfo?.businessMailOrderImg !== '' ?
                                <div><button onClick={() => downloadMailOrderFile(userInfo?.businessMailOrderImg, shopInfo?.shopName)}>다운받기</button></div>
                                :
                                <div><span>등록된 파일이 없습니다.</span></div>
                            }
                        </div>
                    </div>
                }
            </div>

            {
                showRefusalModal &&
                <div className={`${admin.admin_refusal_modal_container}`}>
                    <div className={`${admin.admin_refusal_modal_box}`}>
                        <div className={`${admin.admin_refusal_modal_header}`}>
                            <div><span>거절사유입력</span></div>
                            <div><span className="material-symbols-outlined">close</span></div>
                        </div>
                        <div className={`${admin.admin_refusal_modal_content}`}>
                            <div><span>거절사유</span></div>
                            <div><textarea name="" id="" onChange={(e) => onRefusalRejection(e)}></textarea></div>
                        </div>
                        <div className={`${admin.admin_refusal_modal_footer}`}>
                            <div><button onClick={() => onSellerJoinRefusal()}>취소</button></div>
                            <div><button onClick={() => handleSellerRefusal()}>거절</button></div>
                        </div>
                    </div>
                </div>
            }
        </div>   
    )
}

export default AdminSellerManagementDetail