import React, {useState, useEffect, useContext} from 'react'
import { useNavigate } from 'react-router-dom';
import dailyduck from '../../containers/my/MyContainner.module.css';
import * as auth from '../../apis/auth';
import { LoginContext } from '../../contexts/LoginContextProvider';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const FavorityAdd = ( {} ) => {

    const { userInfo } = useContext(LoginContext);
    const MySwal = withReactContent(Swal);
    const navigate = useNavigate();
    const [text, setText] = useState('');
    const [favority, setFavority] = useState('');
    const [group, setGroup] = useState('');
    const [next, setNext] = useState(false);

    const handleFavAddBack = () => {
        navigate(-1);
    }

    const handleFavAddClose = () => {
        navigate(-2);
    }

    const maxLength = 500;
  
    const handleChange = (e) => {
      const { value } = e.target;
      if (value.length <= maxLength) {
        setText(value);
      }
    };

    const favorityName = (e) => {
        setFavority(e.target.value);
    }

    const groupName = (e) => {
        setGroup(e.target.value);
    }

    useEffect(() => {
        if(favority) {
            setNext(true);
        } else {
            setNext(false);
        }

    }, [favority]);

    const handleFavorityAddRequest = () => {
        // console.log('favoriryName : ', favority);
        // console.log('favoriryGroup : ', group);
        // console.log('favoriryOrderInfo : ', text);

        MySwal.fire({
            customClass: {
                popup: `${dailyduck.favority_add_request_container_modal}`
            },
            html: `
                  <div>
                      <div class='${dailyduck.favority_add_request_container_modal_title}'><span>입력하신 ${favority} 최애를 요청하시겠습니까?</span></div>
                      <div class='${dailyduck.favority_add_request_container_modal_btn} py-2 d-flex w-100 justify-content-between'>
                        <button class='mx-1' id='seller_add_cancel'>취소</button>
                        <button class='mx-1' id='seller_add_success'>요청</button>
                      </div>
                  </div>
              `,
            showConfirmButton: false,
            showCancelButton: false,
        });
        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);

        async function sellerAddSuccess(e) {
            if (e.target && e.target.id === 'seller_add_success') {
                try {
                    const response = await auth.favorityRequest(favority, group, text, userInfo?.userId);
                    const data = response.data;

                    if (data === 'SUCCESS') {
                        MySwal.fire({
                            customClass: {
                                popup: `${dailyduck.favority_add_request_container_modal}`
                            },
                            html: `
                              <div>
                                <div class='${dailyduck.favority_add_request_container_modal_title}'><span>최애 요청이 완료되었습니다.</span></div>
                                <div class='${dailyduck.favority_add_request_container_modal_single_btn}'>
                                    <button class='mx-1' id='seller_add_confirm'>확인</button>
                                </div>
                              </div>
                              `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);

                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                navigate(-1);
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    } else {
                        MySwal.fire({
                            customClass: {
                                popup: `${dailyduck.favority_add_request_container_modal}`
                            },
                            html: `
                              <div>
                                <div class='${dailyduck.favority_add_request_container_modal_title}'><span>최애 요청에 실패하였습니다.</span></div>
                                <div class='${dailyduck.favority_add_request_container_modal_single_btn}'>
                                    <button class='mx-1' id='seller_add_confirm'>확인</button>
                                </div>
                              </div>
                              `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);

                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    }
                } catch (error) {
                    console.error('error : ', error);
                }
            }
        }

        function sellerAddCancel(e) {
            if (e.target && e.target.id === 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
            }
        }
    }

  return (
    <>
        <div className={`${dailyduck.my_favority_add_request_container}`}>
                <div className={`${dailyduck.my_favority_add_request_box}`}>
                    <div className={`${dailyduck.my_favority_add_request_header}`}>
                        <div>
                            <button onClick={() => handleFavAddBack()}><span className='material-symbols-outlined'>arrow_back_ios</span></button>
                        </div>
                        <div>
                            <span>최애 요청</span>
                        </div>
                        <div>
                            <button onClick={() => handleFavAddClose()}><span className='material-symbols-outlined'>close</span></button>
                        </div>
                    </div>
                    <div className={`${dailyduck.my_favority_add_request_content}`}>
                        <div className='pb-4'>
                            <div><span className='bold'>[필수]</span><span>최애의 이름을 입력해주세요.</span></div>
                            <div className='pt-1'><input type="text" className='input_form' onChange={(e) => favorityName(e)} /></div>
                        </div>
                        <div className='pb-4'>
                            <div><span className='bold'>[선택]</span><span>소속 그룹 이름을 입력해주세요.</span></div>
                            <div className='pt-1'><input type="text" className='input_form' onChange={(e) => groupName(e)} /></div>
                        </div>
                        <div>
                            <div><span className='bold'>[선택]</span><span>추가 정보가 있다면 알려주세요.</span></div>
                            <div className='pt-1 textarea-container'>
                                <textarea
                                    name=""
                                    id=""
                                    cols="30"
                                    rows="10"
                                    className='textarea_form'
                                    value={text}
                                    onChange={handleChange}
                                >    
                                </textarea>
                                <div className='counter'>{`${text.length}/${maxLength}`}</div>
                            </div>
                        </div>
                    </div>
                    <div className={`${dailyduck.my_favority_add_request_footer}`}>
                        <div><button className={`${next && 'favority_add_active'}`} disabled={!next} onClick={handleFavorityAddRequest}>요청하기</button></div>
                    </div>
                </div>
        </div>
    </>
  );
};

export default FavorityAdd