import React, {useState} from 'react'

const Filter = ( { store, setActiveFilter, activeFilter } ) => {


  return (
    <>
      {/* <div className={`${store.store_filter_btn_box} px-3`}>
        <div><button className={activeFilter === 'total' ? store.store_filter_active : ''} onClick={() => setActiveFilter('total')}>전체</button></div>
        <div><button className={activeFilter === 'demand_servey' ? store.store_filter_active : ''} onClick={() => setActiveFilter('demand_servey')}>수요조사</button></div>
      </div> */}
    </>
  )
}

export default Filter