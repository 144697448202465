import React, { useState, useEffect, useRef } from 'react';
import { animated } from 'react-spring';
import { useSpring, useTransition } from '@react-spring/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import VenueRequest from './VenueRequest';
import { useNavigate } from 'react-router-dom';
import { ko } from 'date-fns/locale';
import { parse, format, differenceInDays, eachDayOfInterval } from 'date-fns';
import DateSelect from '../DateSelect';
import { Select } from 'antd';
import * as supportapi from '../../../../apis/support';
import DateRangePicker from './DateRangePicker';
import store from '../../../../containers/market/store/store.module.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const VenueReservation = ( 
                            {
                                isLogin, venueDetail, imgSlide, support, reservation, setReservation,
                                reservationSelectOption, setReservationSelectOption, reservationSelectQuestion,
                                setReservationSelectQuestion, reservationEssentialQuestion, setReservationEssentialQuestion,
                                reservationRequest, setReservationRequest, date, questions, setQuestions, supportNo,
                                essentialQuestions, setEssentialQuestions, ss, userInfo, temporarys, holidays, showDate, setShowDate, shopInfo
                            } 
                        ) => {
    const navigate = useNavigate();
    const MySwal = withReactContent(Swal);
    const [showModal, setShowModal] = useState(false);
    const [showReserve, setShowReserve] = useState(false);
    const [showRequest, setShowRequest] = useState(false);
    const [count, setCount] = useState(0);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [displayDate, setDisplayDate] = useState(date);
    const [selectQuestion, setSelectQuestion] = useState('');
    const [priceText, setPriceText] = useState('');
    const [props, api] = useSpring(() => ({ transform: 'translateX(100%)' }));
    const fileInputRefs = useRef({});
    const [cartReservationState, setCartReservationState] = useState('');
    const [selectedRadioOptions, setSelectedRadioOptions] = useState([]);
    const [updatedSelectedOption, setUpdatedSelectedOption] = useState(null);

    const [reservationList, setReservationList] = useState([]);

    useEffect(() => {
        if (reservationList.length > 0 && reservationList[0]?.options) {
            const findSelectedOption = reservationList[0].options.find(opt => opt.name === '단일 수량있음'); // 원하는 name 값으로 수정
            setUpdatedSelectedOption(findSelectedOption);
            console.log("Updated selected option:", findSelectedOption);
        } else {
            setUpdatedSelectedOption(null); // 초기 상태 설정
        }
    }, [reservationList]);
    

    useEffect(() => {
        if (date) {
            console.log('date 접근');
            // '2024.09.11(수)~2024.09.19(목)' 형식의 날짜 문자열에서 요일 정보를 제거
            const dateParts = date.split('~');
            const startDateStr = dateParts[0].trim().split('(')[0]; // '2024.09.11'
            const endDateStr = dateParts[1].trim().split('(')[0];   // '2024.09.19'
    
            // 요일을 제외한 문자열을 'yyyy.MM.dd' 형식으로 파싱
            const startDate = parse(startDateStr, 'yyyy.MM.dd', new Date());
            const endDate = parse(endDateStr, 'yyyy.MM.dd', new Date());
    
            // Date 객체들을 dateRange에 설정
            setDateRange([startDate, endDate]);

            let dateRange = [startDate, endDate];

            setReservationList(prev => {
                const updatedList = [...prev];
                if (updatedList.length > 0) {
                    updatedList[0] = { ...updatedList[0], dateRange: dateRange };
                } else {
                    updatedList.push({ dateRange: dateRange });
                }
                return updatedList;
            });
        }
    }, [date]);

    useEffect(() => {
        console.log(dateRange);
        setReservationList(prev => {
            const updatedList = [...prev];
            if (updatedList.length > 0) {
                updatedList[0] = { ...updatedList[0], dateRange: dateRange };
            } else {
                updatedList.push({ dateRange: dateRange });
            }
            return updatedList;
        });
    }, [dateRange]);

    useEffect(() => {
        const handleScroll = () => {
          console.log(`Scroll position: ${window.scrollY}`);
        };
    
        window.addEventListener('scroll', handleScroll);
    
        // Cleanup function to remove the event listener when the component unmounts
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
    

    const handleDate = () => {

        window.scrollTo({ top: 0, behavior: 'smooth' });
        
        setTimeout(() => {
            setShowDate(true);
            api.start({ transform: 'translateX(0)' });
        }, 300);
    };

    const dateTransitions = useTransition(showDate, {
        from: { transform: "translateX(100%)" },
        enter: { transform: "translateX(0)" },
        leave: { transform: "translateX(100%)" },
        config: { duration: 300 },
    });
    
    useEffect(() => {
        let startdate = startDate ? format(startDate, 'yyyy.MM.dd(eee)', { locale: ko }) : null;
        let enddate = endDate ? format(endDate, 'yyyy.MM.dd(eee)', { locale: ko }) : null;

        if (startdate && enddate) {
            setDisplayDate(startdate + '~' + enddate); // 날짜 상태 업데이트
        }
    }, [startDate, endDate]);

    useEffect(() => {
        console.log('reservationList : ', reservationList);
    }, [reservationList])
    
    useEffect(() => {
        setReservation({
            ...reservation,
            reservationDate: displayDate
        });
    }, [displayDate]);

    const handleReservation = (text) => {
        setCartReservationState(text);
        setShowReserve(true);
        api.start({ transform: 'translateX(0)' });
    };

    const loginLink = useNavigate();
    
    const handleLogin = () => {
        Swal.fire({
            text: '로그인 페이지로 이동합니다.',
            showCancelButton: false,
            confirmButtonText: '확인',
            focusConfirm: false,
            customClass: {
                confirmButton: 'my-confirm-btn'
            }
        }).then((result) => {
            if (result.isConfirmed) {
                loginLink('/');
            }
        });
    };

    const handleRequest = () => {
        setShowRequest(true);
        api.start({ transform: 'translateX(0)' });
    };

    const handleOptionBack = () => {
        setShowModal(false);
        setReservationList([]);
    };

    const handleBack = () => {
        setShowDate(false);
    };

    const handleRequestBack = () => {
        setShowRequest(false);
    };

    const transitions = useTransition(showModal, {
        from: { transform: "translateX(100%)" },
        enter: { transform: "translateX(0)" },
        leave: { transform: "translateX(100%)" },
        config: { duration: 300 },
    });

    const requestTransitions = useTransition(showRequest, {
        from: { transform: "translateX(100%)" },
        enter: { transform: "translateX(0)" },
        leave: { transform: "translateX(100%)" },
        config: { duration: 300 },
    });

    useEffect(() => {
        if (showModal) {
            document.getElementsByClassName('venue_reservation')[0].style.overflow = 'hidden';
        } else {
            document.getElementsByClassName('venue_reservation')[0].style.overflow = 'auto';
        }
    }, [showModal]);

    const [selectedOptions, setSelectedOptions] = useState([]);
    const [shortAnswerOptions, setShortAnswerOptions] = useState([]);
    const [radioOptions, setRadioOptions] = useState(null);
    const [dateOptions, setDateOptions] = useState([]);
    const [fileUploadOption, setFileUploadOption] = useState([]);

    // 예시 Mock 데이터
    const mockData = [
        { no: '1', id: 'selectOption1', name: '빔 프로젝트 추가', price: 10000 },
        { no: '2', id: 'selectOption2', name: 'OO추가', price: 12000 },
        { no: '3', id: 'selectOption3', name: 'OO추가', price: 12000 },
    ];

    const [selectOption, setSelectOption] = useState([]);
    useEffect(() => {
        setSelectOption(mockData);
    }, []);

    const handleOptionChange = (e, option, name, price) => {
        const { checked } = e.target;
        const newOption = {
            type: 'multipleSelection',
            name: option.optionTitle,
            price: parseInt(price, 10) || 0,
            value: name,
            quantity: 1
        };
    
        setReservationList((prev) => {
            const updatedList = [...prev];
            const reservationItem = updatedList[0];
            const existingOptionIndex = reservationItem.options.findIndex(opt => opt.name === option.optionTitle && opt.value === name);
    
            if (checked) {
                if (existingOptionIndex >= 0) {
                    reservationItem.options[existingOptionIndex] = newOption;
                } else {
                    reservationItem.options.push(newOption);
                }
            } else {
                if (existingOptionIndex >= 0) {
                    reservationItem.options.splice(existingOptionIndex, 1);
                }
            }
    
            return updatedList;
        });
    };
    

    const handleShortAnswerChange = (e, option) => {
        const value = e.target.value;
        const newOption = {
            type: 'shortAnswer',
            name: option.optionTitle,
            price: parseInt(option.optionPrice, 10) || 0,
            value: value,
        };
    
        setReservationList((prev) => {
            const updatedList = [...prev];
            console.log('updatedList : ', updatedList);
            const reservationItem = updatedList[0];
            const existingOptionIndex = reservationItem.options.findIndex(opt => opt.name === option.optionTitle);
    
            if (value) {
                if (existingOptionIndex >= 0) {
                    reservationItem.options[existingOptionIndex] = newOption;
                } else {
                    reservationItem.options.push(newOption);
                }
            } else {
                if (existingOptionIndex >= 0) {
                    reservationItem.options.splice(existingOptionIndex, 1);
                }
            }
    
            return updatedList;
        });

        // Update short answer options
        setShortAnswerOptions((prev) => {
            const existingIndex = prev.findIndex(opt => opt.name === option.optionTitle);
            if (existingIndex >= 0) {
                const newOptions = [...prev];
                if (value) {
                    newOptions[existingIndex] = newOption;
                } else {
                    newOptions.splice(existingIndex, 1);
                }
                return newOptions;
            } else {
                return value ? [...prev, newOption] : prev;
            }
        });
    };
    

    const handleRadioChange = (e, option) => {
        const value = e.target.value;
        const price = parseInt(e.target.dataset.price, 10) || 0;
        const newOption = {
            type: 'singleSelection',
            name: option.optionTitle.trim(),  // 공백 제거
            price: price,
            value: value,
            quantity: 1 // 수량 기본값 설정
        };
    
        console.log("Adding option:", newOption);
    
        setReservationList((prev) => {
            const updatedList = [...prev];
            
            if (updatedList.length === 0) {
                updatedList.push({ options: [] });
            }
            
            const reservationItem = updatedList[0];
    
            const existingOptionIndex = reservationItem.options.findIndex(opt => opt.name === option.optionTitle.trim());
    
            if (existingOptionIndex >= 0) {
                reservationItem.options[existingOptionIndex] = newOption;
                console.log(`Option updated:`, newOption);
            } else {
                reservationItem.options.push(newOption);
                console.log(`Option added:`, newOption);
            }
    
            console.log("Updated reservationList:", updatedList);
            return updatedList;
        });
    
        setSelectedRadioOptions((prev) => {
            const existingIndex = prev.findIndex(opt => opt.name === option.optionTitle.trim());
            if (existingIndex >= 0) {
                const newOptions = [...prev];
                newOptions[existingIndex] = newOption;
                return newOptions;
            } else {
                return [...prev, newOption];
            }
        });
    };
    

    useEffect(() => {
        console.log("reservationList has been updated:", reservationList);
    }, [reservationList]);

    const handleDateChange = (e, option) => {
        const value = e.target.value;
        const newOption = {
            type: 'dateSelection',
            name: option.optionTitle,
            price: 0,
            value: value,
        };
    
        setReservationList((prev) => {
            const updatedList = [...prev];
            const reservationItem = updatedList[0];
            const existingOptionIndex = reservationItem.options.findIndex(opt => opt.name === option.optionTitle);
            
            if (value) {
                if (existingOptionIndex >= 0) {
                    reservationItem.options[existingOptionIndex] = newOption;
                } else {
                    reservationItem.options.push(newOption);
                }
            } else {
                if (existingOptionIndex >= 0) {
                    reservationItem.options.splice(existingOptionIndex, 1);
                }
            }
    
            return updatedList;
        });

        // Update date options
        setDateOptions((prev) => 
            value ? [...prev, newOption] : prev.filter((opt) => opt.name !== option.optionTitle)
        );
    };
    
    const handleFileUploadChange = async (e, option) => {
        const files = Array.from(e.target.files);
        const formData = new FormData();
        for (const file of files) {
            if (file.size > 50 * 1024 * 1024) {
                alert("파일 크기는 50MB를 초과할 수 없습니다.");
                return;
            } else {
                formData.append('images', file);
            }
        }
    
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
    
        try {
            const response = await supportapi.uploadImagesToServer(formData, config);
            const data = response.data;
    
            const newOption = {
                type: 'fileUpload',
                name: option.optionTitle,
                price: 0,
                files: data.map((fileData, index) => ({
                    originalFileName: files[index].name,
                    serverFileName: fileData,
                }))
            };
    
            setReservationList((prev) => {
                const updatedList = [...prev];
                const reservationItem = updatedList[0];
                const existingOptionIndex = reservationItem.options.findIndex(opt => opt.name === option.optionTitle);
    
                if (existingOptionIndex >= 0) {
                    reservationItem.options[existingOptionIndex] = newOption;
                } else {
                    reservationItem.options.push(newOption);
                }
    
                return updatedList;
            });

            // Update file upload options
            setFileUploadOption((prev) => {
                const existingIndex = prev.findIndex(opt => opt.name === option.optionTitle);
                if (existingIndex >= 0) {
                    const newOptions = [...prev];
                    newOptions[existingIndex] = {
                        ...newOptions[existingIndex],
                        files: [...newOptions[existingIndex].files, ...newOption.files]
                    };
                    return newOptions;
                } else {
                    return [...prev, newOption];
                }
            });
        } catch (error) {
            console.error('Error uploading files:', error);
        }
    };

    const handleFileUploadClick = (option) => {
        if (fileInputRefs.current[option.optionTitle]) {
            fileInputRefs.current[option.optionTitle].click();
        }
    };

    const handleRemoveFile = (fileToRemove, optionTitle) => {
        setFileUploadOption((prev) => prev.map(opt => 
            opt.name === optionTitle ? { 
                ...opt, 
                files: opt.files.filter(file => file.serverFileName !== fileToRemove.serverFileName) 
            } : opt
        ));
    };

    const [questionValue, setQuestionValue] = useState('');
    const [error, setError] = useState({ radio: false, question: false });
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        console.log('dateRange : ', dateRange);
    }, [dateRange])

    const handleNextClick = () => {

        if( dateRange[0] === null || dateRange[1] === null ) {
            alert('예약 신청 일자를 선택해주세요.');
            return;
        }

        const disabledDates = [
            ...holidays.map(item => new Date(item.holidayDate)),
            ...temporarys.flatMap(item => 
                eachDayOfInterval({ 
                    start: new Date(item.temporaryDate), 
                    end: new Date(item.temporaryEndDate) 
                })
            )
        ];
        
    
        // 예약 불가능한 날짜가 선택된 범위에 포함되는지 확인하는 함수
        const isWithinUnavailableDates = (start, end) => {
            for (let date of disabledDates) {
                if (start <= date && date <= end) {
                    return true;
                }
            }
            return false;
        }

        if(isWithinUnavailableDates(dateRange[0], dateRange[1])) {
            alert('예약날짜 범위에 예약이 불가능한 날짜가 포함되어있습니다.');

            return;
        }

        const selectedMainItem = reservationList[0]?.mainOptionName;
        const selectedMainItemData = venueDetail[0]?.mainItem.find(item => item.mainOptionName === selectedMainItem);
    
        if (!selectedMainItemData) {
            alert('선택된 메인 아이템이 없습니다.');
            return;
        }
    
        const selectedMainItemNo = selectedMainItemData.mainItemNo;

        // 선택된 mainItemNo에 해당하는 필수 옵션만 가져오기
        const requiredOptions = venueDetail[0]?.mainItemOption.filter(option => option.mainItemNo === selectedMainItemNo && option.optionRequired);

        const unselectedRequiredOptions = requiredOptions.filter(option => {
            if (option.optionType === 'singleSelection' || option.optionType === 'multipleSelection') {
                return !reservationList[0]?.options.some(selectedOption => selectedOption.name === option.optionTitle);
            }
            if (option.optionType === 'dateSelection') {
                return !reservationList[0]?.options.some(selectedOption => selectedOption.name === option.optionTitle && selectedOption.value);
            }
            if (option.optionType === 'shortAnswer') {
                return !reservationList[0]?.options.some(selectedOption => selectedOption.name === option.optionTitle && selectedOption.value.trim() !== '');
            }
            return false;
        });

        if (unselectedRequiredOptions.length > 0) {
            alert(`필수 옵션이 선택되지 않았습니다: ${unselectedRequiredOptions.map(opt => opt.optionTitle).join(', ')}`);
            return;
        }

        setReservationList(prev => {
            const updatedList = [...prev];
            if (updatedList.length > 0) {
                updatedList[0] = { ...updatedList[0], dateRange: dateRange };
            } else {
                updatedList.push({ dateRange: dateRange });
            }
            return updatedList;
        });

        const reservation = reservationList[0];

        let newItem = {};

        if(reservation.mainItemImg === '') {
            newItem = {
                ...reservation,
                mainItemImg : venueDetail[0].representativeImage
            }
        } else {
            newItem = {...reservation}
        }

        if(reservationList != null) {
            console.log('newItem : ', newItem);
            navigate(`/payment/${reservationList[0].reservationNo}`, { state: {reservationList : newItem, venueDetail : venueDetail}});
        }
    };

    const requestClass = showRequest ? 'request-show' : 'request-hide';

    const handleQuestionSelect = (questionId, value) => {
        const updatedQuestion = reservationSelectQuestion.map(question => {
          if (question.SelectQuestionNo === questionId) {
            return { ...question, reservationSelectQuestionContent: value };
          }
          return question;
        });
    
        setReservationSelectQuestion(updatedQuestion);
    };
    
    useEffect(() => {
        const fetchedQuestions = [
          { id: 1, question: '셀러가 입력한 질문 1' },
          { id: 2, question: '셀러가 입력한 질문 2' },
        ];
        
        setQuestions(fetchedQuestions);
    
        const initialOptions = fetchedQuestions.map(question => ({
            SelectQuestionNo: question.id,
            supportNo: supportNo,
            reservationSelectQuestionContent: '',
        }));
    
        setReservationSelectQuestion(initialOptions);
    }, [supportNo]);

    const handleQuestionEssential = (questionId, value) => {
        setQuestionValue(value);
        setError((prev) => ({ ...prev, question: false }));

        const updatedQuestion = reservationEssentialQuestion.map(question => {
          if (question.essentialQuestionNo === questionId) {
            return { ...question, reservationEssentialQuestionContent: value };
          }
          return question;
        });

        const allAnswered = updatedQuestion.every(question => question.reservationEssentialQuestionContent);
        setIsActive(allAnswered);
        setReservationEssentialQuestion(updatedQuestion);
    };

    useEffect(() => {
        const fetchedQuestions = [
          { id: 1, question: '셀러가 입력한 질문 1' },
          { id: 2, question: '셀러가 입력한 질문 2' },
        ];
        
        setEssentialQuestions(fetchedQuestions);
    
        const initialOptions = fetchedQuestions.map(question => ({
            essentialQuestionNo: question.id,
            supportNo: supportNo,
            reservationEssentialQuestionContent: '',
        }));
    
        setReservationEssentialQuestion(initialOptions);
    }, [supportNo]);

    const [selectedMainItem, setSelectedMainItem] = useState(null);
    const [mainItemPrice, setMainItemPrice] = useState(0);
    const [filteredOptions, setFilteredOptions] = useState([]);

    const handleMainItemChange = (value) => {
        setReservationList([]);
        const selectedItem = venueDetail[0]?.mainItem.find(item => item.mainOptionName === value);
        if (selectedItem) {
            const newItem = {
                reservationNo: Date.now(), // 고유 ID 사용
                mainItemNo: selectedItem.mainItemNo,
                mainOptionName: selectedItem.mainOptionName,
                productNumber: selectedItem.productNumber,
                mainItemImg: selectedItem.mainItemImg,
                mainItemPrice: selectedItem.mainItemPrice,
                sellerId: selectedItem.userId,
                userId: userInfo.userId,
                options: [] // 옵션 초기화
            };
            setReservationList([newItem]);
            setSelectedMainItem(selectedItem.mainOptionName);
            setMainItemPrice(selectedItem.mainItemPrice);

            const filtered = venueDetail[0]?.mainItemOption.filter(option => option.mainItemNo === selectedItem.mainItemNo);
            setFilteredOptions(filtered);
        }
    };
    

    const [totalPrice, setTotalPrice] = useState(0);

    useEffect(() => {
        setReservationList(prev => {
            const updatedList = [...prev];
            if (updatedList.length > 0) {
                updatedList[0] = { ...updatedList[0], totalPrice: totalPrice };
            } else {
                updatedList.push({ totalPrice: totalPrice });
            }
            return updatedList;
        });
    }, [totalPrice]);
    useEffect(() => {
        if (venueDetail && venueDetail.length > 0) {
            const selectedCheckboxPrices = selectedOptions.reduce((acc, curr) => acc + curr.price, 0);
            const radioOptionPrices = selectedRadioOptions.reduce((acc, curr) => acc + curr.price, 0);
            const mainItemPriceValue = selectedMainItem ? mainItemPrice : 0;
            const shortAnswerPrice = shortAnswerOptions.reduce((acc, curr) => acc + curr.price, 0);
    
            const total = parseInt(venueDetail[0].supportFirstPrice || 0, 10) + selectedCheckboxPrices + radioOptionPrices + mainItemPriceValue + shortAnswerPrice;
            setTotalPrice(total);
        }
    }, [selectedOptions, selectedRadioOptions, selectedMainItem, mainItemPrice, shortAnswerOptions, dateOptions, fileUploadOption, venueDetail]);

    useEffect(() => {
        if (reservationList.length > 0 && reservationList[0].options) {
            const reservationItem = reservationList[0];
    
            // 모든 선택된 옵션들의 가격 * 수량을 합산하여 총 가격 계산
            const selectedOptionsPrice = reservationItem.options.reduce((acc, curr) => {
                const optionPrice = curr.price || 0; // 누락된 price 값을 0으로 초기화
                const quantity = curr.quantity || 1; // quantity가 없을 경우 기본값을 1로 설정
                return acc + optionPrice * quantity;
            }, 0);
    
            const mainItemPriceValue = selectedMainItem ? mainItemPrice : 0;
            const total = mainItemPriceValue + selectedOptionsPrice;
            setTotalPrice(total);
        } else {
            // 옵션이 없을 경우 mainItemPrice로만 totalPrice를 설정
            setTotalPrice(selectedMainItem ? mainItemPrice : 0);
        }
    }, [reservationList, mainItemPrice, selectedOptions, selectedRadioOptions, shortAnswerOptions, dateOptions, fileUploadOption]);
    
    
    

    const cartAddBtn = async () => {
            
        if( dateRange[0] === null || dateRange[1] === null ) {
            alert('예약 신청 일자를 선택해주세요.');
            return;
        }

        const disabledDates = [
            ...holidays.map(item => new Date(item.holidayDate)),
            ...temporarys.flatMap(item => 
                eachDayOfInterval({ 
                    start: new Date(item.temporaryDate), 
                    end: new Date(item.temporaryEndDate) 
                })
            )
        ];
        
    
        // 예약 불가능한 날짜가 선택된 범위에 포함되는지 확인하는 함수
        const isWithinUnavailableDates = (start, end) => {
            for (let date of disabledDates) {
                if (start <= date && date <= end) {
                    return true;
                }
            }
            return false;
        }

        if(isWithinUnavailableDates(dateRange[0], dateRange[1])) {
            alert('예약날짜 범위에 예약이 불가능한 날짜가 포함되어있습니다.');

            return;
        }

        const selectedMainItem = reservationList[0]?.mainOptionName;
        const selectedMainItemData = venueDetail[0]?.mainItem.find(item => item.mainOptionName === selectedMainItem);
    
        if (!selectedMainItemData) {
            alert('선택된 메인 아이템이 없습니다.');
            return;
        }
    
        const selectedMainItemNo = selectedMainItemData.mainItemNo;

        // 선택된 mainItemNo에 해당하는 필수 옵션만 가져오기
        const requiredOptions = venueDetail[0]?.mainItemOption.filter(option => option.mainItemNo === selectedMainItemNo && option.optionRequired);

        const unselectedRequiredOptions = requiredOptions.filter(option => {
            if (option.optionType === 'singleSelection' || option.optionType === 'multipleSelection') {
                return !reservationList[0]?.options.some(selectedOption => selectedOption.name === option.optionTitle);
            }
            if (option.optionType === 'dateSelection') {
                return !reservationList[0]?.options.some(selectedOption => selectedOption.name === option.optionTitle && selectedOption.value);
            }
            if (option.optionType === 'shortAnswer') {
                return !reservationList[0]?.options.some(selectedOption => selectedOption.name === option.optionTitle && selectedOption.value.trim() !== '');
            }
            return false;
        });

        if (unselectedRequiredOptions.length > 0) {
            alert(`필수 옵션이 선택되지 않았습니다: ${unselectedRequiredOptions.map(opt => opt.optionTitle).join(', ')}`);
            return;
        }

        MySwal.fire({
            customClass: {
                popup: `${store.store_cart_add_popup}`
            },
            html : 
            `
                <div>
                    <div class='${store.store_cart_add_title}'><span>장바구니 담기</span></div>
                    <div class='${store.store_cart_add_content}'><span>선택하신 아이템을 장바구니에 담을까요?</span></div>
                    <div class='${store.store_cart_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                      <button class='mx-1' id='cart_add_cancel'>아니오</button>
                      <button class='mx-1' id='cart_add_success'>예</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,

        });
        
        document.addEventListener('click', cartAddSuccess);

        
        function cartAddSuccess(e) {
            if(e.target && e.target.id == 'cart_add_success') {
                reservationCartAdd();
                MySwal.close();
                document.removeEventListener('click', cartAddSuccess);
            }

            if(e.target && e.target.id == 'cart_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', cartAddSuccess);
            }
        }

        const reservationCartAdd = async () => {

            // console.log('finalSelectionsCart : ',finalSelections);
            try {
                if (reservationList.length > 0) {
                    const reservation = reservationList[0]; // 첫 번째 예약 항목을 가져옵니다
    
                    const newItem = {
                        userId: reservation.userId,
                        productNo: reservation.mainItemNo,
                        productNumber: reservation.productNumber,
                        productName: reservation.mainOptionName,
                        sellerId: reservation.sellerId,
                        mainOptionName: reservation.mainOptionName,
                        optionItem: reservation.options?.length > 0 ? reservation.options : [],
                        productImg: venueDetail[0].representativeImage,
                        productMinPrice: reservation.mainItemPrice, // 만약 최소 가격과 최대 가격이 동일한 경우
                        productMaxPrice: reservation.mainItemPrice, // 같은 값을 할당할 수 있습니다.
                        totalPrice: totalPrice, // 예약에 따른 총 가격을 계산해야 할 수도 있습니다.
                        shopNo: shopInfo.shopNo || '', // shopNo가 있다면 사용하고, 없다면 빈 값으로 설정합니다.
                        shopProfile: shopInfo.shopProfileImg || '', // shopProfile이 있다면 사용하고, 없다면 빈 값으로 설정합니다.
                        shopName: shopInfo.shopName || '', // shopName이 있다면 사용하고, 없다면 빈 값으로 설정합니다.
                        reservationStartDate: reservation.dateRange[0], // 예약 시작 날짜
                        reservationEndDate: reservation.dateRange[1] // 예약 종료 날짜
                    };
                
                    console.log('newItem : ', newItem);

                const response = await supportapi.supportCartAdd(newItem);
                const data = await response.data;

                console.log(data);

                if(data === 'DUPLICATION') {
                    MySwal.close();
                    MySwal.fire({
                        customClass: {
                            popup: `${store.store_cart_add_popup}`
                        },
                        html : 
                        `
                        <div>
                        <div class='${store.store_cart_add_title}'><span>장바구니 담기</span></div>
                        <div class='${store.store_cart_add_content}'><span>선택하신 아이템이 이미 장바구니에 담겨있습니다.</span></div>
                        <div class='${store.store_cart_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                        <button class='mx-1' id='cart_add_return_cancel'>확인</button>
                        </div>
                        </div>
                        `,
                        showConfirmButton: false,
                        showCancelButton: false,
                    });
                    document.removeEventListener('click', cartAddSuccess);
                    document.addEventListener('click', cartAddReturnSuccess);
                }

                if(data === 'SUCCESS') {
                    MySwal.close();
                    MySwal.fire({
                        customClass: {
                            popup: `${store.store_cart_add_popup}`
                        },
                        html : 
                        `
                        <div>
                        <div class='${store.store_cart_add_title}'><span>장바구니 담기</span></div>
                        <div class='${store.store_cart_add_content}'><span>선택하신 아이템을 장바구니에 담았습니다.</span><br/><span>장바구니로 이동할까요?</span></div>
                        <div class='${store.store_cart_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                        <button class='mx-1' id='cart_add_return_cancel'>아니오</button>
                        <button class='mx-1' id='cart_add_return_success'>예</button>
                        </div>
                        </div>
                        `,
                        showConfirmButton: false,
                        showCancelButton: false,
                    });
                    document.removeEventListener('click', cartAddSuccess);
                    document.addEventListener('click', cartAddReturnSuccess);
                }
                } else {
                    console.log('예약 목록이 비어있습니다.');
                }
            } catch (error) {
                console.err('error : ', error)
            }
        }

        function cartAddReturnSuccess(e) {
            if(e.target && e.target.id == 'cart_add_return_success') {
                MySwal.close();
                document.removeEventListener('click', cartAddReturnSuccess);
                navigate('/cart');
            }
            if(e.target && e.target.id == 'cart_add_return_cancel') {
                MySwal.close();
                document.removeEventListener('click', cartAddReturnSuccess);
            }
        }
    }

    // 수량 조절 함수
    const handleQuantityChange = (option, delta) => {
        setReservationList(prev => {
            const updatedList = [...prev];
            const reservationItem = updatedList[0];
    
            const optionIndex = reservationItem.options.findIndex(opt => opt.name === option.name && opt.value === option.value);
            if (optionIndex !== -1) {
                const newQuantity = Math.max(1, reservationItem.options[optionIndex].quantity + delta);
                reservationItem.options[optionIndex].quantity = newQuantity;
            }
    
            // 선택된 옵션들의 총 가격 계산
            const total = updatedList[0].options.reduce((acc, curr) => {
                return acc + curr.price * curr.quantity;
            }, mainItemPrice); // mainItemPrice를 추가해서 기본 가격 포함
    
            setTotalPrice(total); // totalPrice 업데이트
            return updatedList;
        });
    };
    
    

    const renderOption = (option) => {
        const { optionType, optionTitle, optionPrice, optionName, optionDateTime, optionRequired, useQuantity } = option;
        switch (optionType) {
            case 'shortAnswer':
                return (
                    <div className={`${ss.option_type_short_answer}`}>
                        <div>
                            <div><span>{optionTitle}</span></div>
                            <div className={`${optionRequired ? ss.option_type_request : ss.option_type_no_request}`}><span>{optionRequired ? '필수' : '선택'}</span></div>
                        </div>
                        <div>
                            <div><input type="text" onChange={(e) => handleShortAnswerChange(e, option)} /></div>
                            <div><span>+{optionPrice}원</span></div>
                        </div>
                    </div>
                );
                case 'singleSelection':
                    const singleOptions = optionName.split(';');
                    const singlePrices = optionPrice.split(';');
                    return (
                        <div className={`${ss.option_type_single_selection}`}>
                            <div>
                                <div><span>{optionTitle}</span></div>
                                <div className={`${optionRequired ? ss.option_type_request : ss.option_type_no_request}`}><span>{optionRequired ? '필수' : '선택'}</span></div>
                            </div>
                            <div>
                                {!optionRequired && (
                                    <div>
                                        <input 
                                            type="radio" 
                                            name={optionTitle} 
                                            id={`${optionTitle}_none`} 
                                            value="" 
                                            data-price="0" 
                                            onChange={(e) => handleRadioChange(e, option)} 
                                        />
                                        <label className='ps-1' htmlFor={`${optionTitle}_none`}>선택 안함</label>
                                    </div>
                                )}
                                {singleOptions.map((name, index) => {                                    
                                    const selectedOption = reservationList[0]?.options.find(opt => opt.name === optionTitle && opt.value === name);
                                    const isSelected = !!selectedOption;
                                    
                                    return (
                                        <div key={index}>
                                            <div>
                                                <input
                                                    type="radio"
                                                    name={optionTitle}
                                                    id={`${optionTitle}_${index}`}
                                                    value={name}
                                                    data-price={singlePrices[index] || 0}
                                                    onChange={(e) => handleRadioChange(e, option)}
                                                />
                                                <label className='ps-1' htmlFor={`${optionTitle}_${index}`}>{name}</label>
                                            </div>
                                            <span>+{parseInt(singlePrices[index]).toLocaleString() || 0}원</span>
                                            
                                            {/* 수량 조절 버튼: useQuantity가 true이고 해당 옵션이 선택된 경우에만 표시 */}
                                            {useQuantity && isSelected && (
                                                <div className={`${ss.option_quantity_control}`}>
                                                    <div><button onClick={() => handleQuantityChange(selectedOption, -1)}><span className="material-symbols-outlined">remove</span></button></div>
                                                    <div><span>{selectedOption.quantity}</span></div>
                                                    <div><button onClick={() => handleQuantityChange(selectedOption, 1)}><span className="material-symbols-outlined">add</span></button></div>
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    );
                    case 'multipleSelection':
                        const multipleOptions = optionName.split(';');
                        const multiplePrices = optionPrice.split(';');
                        return (
                            <div className={`${ss.option_type_multiple_selection}`}>
                                <div>
                                    <div><span>{optionTitle}</span></div>
                                    <div className={`${optionRequired ? ss.option_type_request : ss.option_type_no_request}`}>
                                        <span>{optionRequired ? '필수' : '선택'}</span>
                                    </div>
                                </div>
                                <div>
                                    {multipleOptions.map((name, index) => {
                                        const normalizeString = (str) => str.replace(/\s+/g, '').toLowerCase();

                                        const selectedOption = reservationList[0]?.options.find(
                                            opt => normalizeString(opt.name) === normalizeString(optionTitle) &&
                                                   normalizeString(opt.value) === normalizeString(name)
                                        );

                                        const isSelected = !!selectedOption;

                                        reservationList[0]?.options.forEach(opt => {
                                            console.log("Option in list:", { name: opt.name, value: opt.value });
                                        });

                                        if (selectedOption) {
                                            console.log("Found selected option:", selectedOption);
                                        } else {
                                            console.log("Could not find option for", { optionTitle, name });
                                        }
                                        console.log('isSelected : ', isSelected);

                                        return (
                                            <div key={index}>
                                                <div>
                                                    <input
                                                        type="checkbox"
                                                        name={optionTitle}
                                                        id={`multi_${index}`}
                                                        data-name={name}
                                                        data-price={multiplePrices[index] || 0}
                                                        onChange={(e) => handleOptionChange(e, option, name, multiplePrices[index])}
                                                        checked={isSelected}
                                                    />
                                                    <label className='ps-1' htmlFor={`multi_${index}`}>{name}</label>
                                                </div>
                                                <div><span>+{parseInt(multiplePrices[index]).toLocaleString() || 0}원</span></div>
                                                
                                                {/* 수량 조절 버튼: useQuantity가 true이고 해당 옵션이 선택된 경우에만 표시 */}
                                                {useQuantity && isSelected && (
                                                    <div className={`${ss.option_quantity_control}`}>
                                                        <div>
                                                            <button onClick={() => handleQuantityChange(selectedOption, -1)}>
                                                                <span className="material-symbols-outlined">remove</span>
                                                            </button>
                                                        </div>
                                                        <div><span>{selectedOption.quantity}</span></div>
                                                        <div>
                                                            <button onClick={() => handleQuantityChange(selectedOption, 1)}>
                                                                <span className="material-symbols-outlined">add</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        );
            case 'dateSelection':
                return (
                    <div className={`${ss.option_type_date_selection}`}>
                        <div>
                            <div><span>{optionTitle}</span></div>
                            <div className={`${optionRequired ? ss.option_type_request : ss.option_type_no_request}`}><span>{optionRequired ? '필수' : '선택'}</span></div>
                        </div>
                        <div>
                            <input type="datetime-local" onChange={(e) => handleDateChange(e, option)} />
                            {optionDateTime && <input type="datetime-local" onChange={(e) => handleDateChange(e, option)} />}
                        </div>
                    </div>
                );
            case 'fileUpload':
                return (
                    <div className={`${ss.option_type_file_upload}`}>
                        <div>
                            <div><span>{optionTitle}</span></div>
                            <div><span>{optionRequired ? '필수' : '선택'}</span></div>
                        </div>
                        <div>
                            <input
                                type="file"
                                accept="*/*"
                                multiple
                                onChange={(e) => handleFileUploadChange(e, option)}
                                ref={el => (fileInputRefs.current[option.optionTitle] = el)}
                                style={{ display: 'none' }}
                            />
                            <button className={`${ss.option_type_file_upload_btn}`} onClick={() => handleFileUploadClick(option)}>파일 업로드</button>
                            <Swiper
                                slidesPerView={3.5}
                                spaceBetween={30}
                                freeMode={true}
                                className={`${ss.option_type_file_name_list}`}
                            >
                                {fileUploadOption
                                    .filter(opt => opt.name === option.optionTitle)
                                    .flatMap(opt => opt.files.map((file, index) => (
                                        <SwiperSlide key={index}>
                                            <div className={`${ss.option_type_file_upload_list}`}>
                                                <span>{file.originalFileName}</span>
                                                <button onClick={() => handleRemoveFile(file, option.optionTitle)}>
                                                    <span className="material-symbols-outlined">cancel</span>
                                                </button>
                                            </div>
                                        </SwiperSlide>
                                    )))}
                            </Swiper>
    
                            <div className={`${ss.option_type_file_upload_txt}`}><span>최대 5개 파일 / 파일 크기 최대 50MB</span></div>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };
    

    return (
        <>
            <div className={`venue_reservation  ${showDate ? ss.show_date_active : ''}`}>
                <div className='px-3 py-3 d-flex justify-content-center'>
                    <div className='w-100'>
                        <div className='reservation_button_box2'>
                            {
                                isLogin ? (
                                    <div className={`${ss.cart_reserve_btn}`}>
                                        <button onClick={() => handleReservation('cart')}>장바구니</button>
                                        <button onClick={() => handleReservation('reservation')}>예약하기</button>
                                    </div>
                                ) : (
                                    <button onClick={handleLogin}>로그인 후 이용하기</button>
                                )
                            }
                        </div>
                    </div>
                </div>
                <div>
                    
                </div>
                
            </div>
            {showDate && <DateRangePicker transitions={dateTransitions} animated={animated} handleBack={handleBack} 
                                    startDate={startDate} endDate={endDate} dateRange={dateRange} 
                                    setDateRange={setDateRange} ko={ko} format={format} differenceInDays={differenceInDays} 
                                    temporarys={temporarys} holidays={holidays} ss={ss}
                                    />}
            {
                showReserve &&
                <div className={`${ss.reserve_backgorund}`}>
                    <div className={`${ss.reserve_container}`}>
                        <div>
                            <div className={`${ss.reserve_container_header}`}>
                                <div><span>기본 선택</span></div>
                                <div><button onClick={() => setShowReserve(false)}><span className="material-symbols-outlined">close</span></button></div>
                            </div>
                            <div className={`${ss.reserve_container_select_box}`}>
                                <Select onChange={handleMainItemChange} className={`${ss.reserve_container_select}`} placeholder='상품을 선택해주세요.'>
                                    {
                                        venueDetail[0]?.mainItem?.map((item, index) => (
                                            <Select.Option key={index} value={item.mainOptionName}>
                                                <div className='d-flex align-items-center'>
                                                    <div><img className={`${ss.reserve_container_option_img}`} src={item.mainItemImg} alt="" /></div>
                                                    <div><span className='ps-2'>{item.mainOptionName}</span></div>
                                                </div>
                                            </Select.Option>
                                        ))
                                    }
                                </Select>
                            </div>
                            {selectedMainItem && filteredOptions.length === 0 && (
                                <div className='d-flex justify-content-between reservation_date border-bottom py-3 px-3'>
                                    <div><span>예약 신청 일자</span></div>
                                    <div><span className='pe-1'>{displayDate}</span><button onClick={handleDate}><img src="../img/icon/support/calendar.png" alt="달력" /></button></div>
                                </div>
                            )}
                            <div className={`${ss.reserve_container_footer}`}>
                                <div className='px-3 w-100 text-end border-bottom py-3'>
                                    <div>
                                        <span>{mainItemPrice.toLocaleString()}</span>
                                        <span>원</span>
                                    </div>
                                </div>
                                {
                                    cartReservationState === 'reservation' && 
                                    <div className='px-3 py-3'>
                                        {selectedMainItem && filteredOptions.length > 0 ? (
                                            <div>
                                                <button className={`${selectedMainItem != null ? ss.reserve_container_footer_btn_active : ''}`} onClick={() => setShowModal(true)}>추가 옵션 선택</button>
                                            </div>
                                        ) : (
                                            <div>
                                                <button className={`${selectedMainItem != null ? ss.reserve_container_footer_btn_active : ''}`} onClick={() => handleNextClick()} disabled={selectedMainItem != null ? false : true}>예약하기</button>
                                            </div>
                                        )}
                                    </div>
                                }
                                {
                                    cartReservationState === 'cart' &&
                                    <div className='px-3 py-3'>
                                        {selectedMainItem && filteredOptions.length > 0 ? (
                                            <div>
                                                <button className={`${selectedMainItem != null ? ss.reserve_container_footer_btn_active : ''}`} onClick={() => setShowModal(true)}>추가 옵션 선택</button>
                                            </div>
                                        ) : (
                                            <div>
                                                <button className={`${selectedMainItem != null ? ss.reserve_container_footer_btn_active : ''}`} onClick={() => cartAddBtn()} disabled={selectedMainItem != null ? false : true}>장바구니담기</button>
                                            </div>
                                        )}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
            {showModal && 
                <div className={`reservation_pay ${requestClass}`}>
                    {transitions((style, item) =>
                    item
                        ? <animated.div className={`reservation_pay_back`} style={style}>
                        <div className='reservation_pay_header'>
                            <div>
                                <button onClick={() => handleOptionBack()}>
                                    <span className="material-symbols-outlined">arrow_back_ios</span>
                                </button>
                            </div>
                            <div>
                                <span>옵션선택</span>
                            </div>
                        </div>
                        {Array.isArray(venueDetail) && venueDetail.map((venue) => (
                        <div key={venue.supportNo}>
                            <div className='px-3 pt-3 pb-1'><h5 className='bold'>옵션을 선택해 주세요</h5></div>
                            <div className='reservation_swiper px-3'>
                            <Swiper
                                slidesPerView={1}
                                spaceBetween={30}
                                loop={true}
                                pagination={{
                                clickable: true,
                                }}
                                modules={[Pagination, Navigation]}
                                className="mySwiper"
                            >
                                {Array.isArray(imgSlide) && imgSlide.map((slide, index) => (
                                    <SwiperSlide key={index}><img src={slide} alt='이미지' /></SwiperSlide>
                                ))}
                            </Swiper>
                            </div>
                            
                            <div className='px-3 py-3 border-bottom'>
                                <div>
                                    <div><img src="" alt="" /></div>
                                    <div><span>{venue.shopName}</span></div>
                                </div>
                                <div><span>{selectedMainItem || support.productName}</span></div>
                            </div>
                            <div className='d-flex justify-content-between reservation_date border-bottom py-3 px-3'>
                                <div><span>예약 신청 일자</span></div>
                                <div><span className='pe-1'>{displayDate}</span><button onClick={handleDate}><img src="../img/icon/support/calendar.png" alt="달력" /></button></div>
                            </div>
                            {filteredOptions.map((option) => renderOption(option))}
                            <div className='pb-4 px-3'>
                                <div className='d-flex justify-content-between py-3'>
                                    <div>
                                        <span className='fs-5'>
                                            총 {selectedOptions.length + shortAnswerOptions.length + selectedRadioOptions.length + dateOptions.length + fileUploadOption.reduce((count, opt) => count + opt.files.length, 0)}개의 옵션 선택됨
                                        </span>
                                    </div>

                                    <div><span className='reservation_price fs-5'>{totalPrice.toLocaleString()}원</span></div>
                                </div>
                                {
                                    cartReservationState === 'cart' ? 
                                    <div><button className={`reservation_nextbtn reservation_nextbtn_active`} onClick={cartAddBtn}>장바구니담기</button></div>
                                    :
                                    <div><button className={`reservation_nextbtn reservation_nextbtn_active`} onClick={handleNextClick}>다음</button></div>
                                }
                            </div>
                        </div>
                        ))}
                        {showRequest &&
                            <div>
                                <VenueRequest requestTransitions={requestTransitions} handleRequest={handleRequest} handleRequestBack={handleRequestBack} 
                                                animated={animated} showRequest={showRequest} totalPrice={totalPrice} reservation={reservation} setReservation={setReservation}
                                                reservationSelectOption={reservationSelectOption} setReservationSelectOption={setReservationSelectOption}
                                                reservationSelectQuestion={reservationSelectQuestion} setReservationSelectQuestion={setReservationSelectQuestion}
                                                reservationEssentialQuestion={reservationEssentialQuestion} setReservationEssentialQuestion={setReservationEssentialQuestion}
                                                reservationRequest={reservationRequest} setReservationRequest={setReservationRequest} isLogin={isLogin} date={date}
                                                questions={questions} setQuestions={setQuestions} supportNo={supportNo} essentialQuestions={essentialQuestions} 
                                                setEssentialQuestions={setEssentialQuestions}  
                                                displayDate={displayDate} transitions={dateTransitions} handleBack={handleBack} 
                                                startDate={startDate} endDate={endDate} dateRange={dateRange} setShowDate={setShowDate}
                                                setDateRange={setDateRange} ko={ko} format={format} differenceInDays={differenceInDays} showDate={showDate}
                                />
                            </div>
                        }
                        </animated.div>
                        : null
                    )}
                </div>
            }
        </>
    );
};

export default VenueReservation;
