import React, { useState } from 'react'
import { CSSTransition } from 'react-transition-group';

const SupportSellerSide = ( {
    seller, activeMenu, handleMenuClick, setActiveBoard, activeBoard, changeActiveBoard, activeSettlement,
    setActiveSettlement, changeActiveSettlement, activeShopManagement, setActiveShopManagement, changeActiveShopManagement,
    setActiveSalesManagement, changeActiveSalesManagement, activeSalesManagement, sellerlogout, userInfo, openStates,
    openSettlementStates, openShopState, openSalesState, toggleOpen, toggleSettlementOpen, toggleShopOpen, toggleSalesOpen
} ) => {
  return (
    <div className={`${seller.seller_side_container} `}>
        {/* 유저 정보 (아이콘, 아이디, 권한명) */}
        <div className={`${seller.seller_side_profile}`}>
            <div className='d-flex pt-4 px-3 pb-5 align-items-center'>
                <div><img src="../../img/seller/icon/profile_circle.png" alt="기본이미지" /></div>
                <div className='ps-2'>
                    <div><span>{userInfo && userInfo.userId}</span></div>
                    <div className='dd-fs-7'><span>서포트 관리자</span></div>
                </div>
            </div>
        </div>

        {/* Menu */}
        <div className={`${seller.seller_side_menu}`}>
            <div>
                <div className='px-3'>
                    <div><span>스토어</span></div>
                    <div className='pt-2 pb-4 border-bottom'>
                        <div className='py-1 w-100'>
                            <button className={`${seller.seller_menu_btn} d-flex align-items-center ${activeMenu === 'dashboard' ? seller.seller_menu_active : ''}`}
                                    onClick={() => handleMenuClick('dashboard')}      
                            >
                                {/* Icon Img */}
                                <div className={`${seller.seller_menu_icon_box}`}><img src="../../img/seller/icon/dashboard.png" alt="대시보드" /></div>
                                {/* Menu Name */}
                                <div className={`${seller.seller_menu_name_box} ps-3`}><span>대시보드</span></div>
                            </button>
                        </div>
                        <div className='py-2 w-100'>
                            <button className={`${seller.seller_menu_btn} d-flex align-items-center ${activeMenu === 'board' ? seller.seller_menu_active : ''}`} 
                                    onClick={() => { handleMenuClick('board'); toggleOpen();}}
                            >
                                {/* Icon Img */}
                                <div className={`${seller.seller_menu_icon_box}`}><img src="../../img/seller/icon/board.png" alt="게시판" /></div>
                                {/* Menu Name */}
                                <div className={`${seller.seller_menu_name_box} ps-3`}><span>게시판</span></div>
                                {/* allow Icon */}
                                <div className={`${seller.seller_allow_icon}`}>
                                    <span className="material-symbols-outlined">{openStates ? 'expand_less' : 'expand_more'}</span>
                                </div>
                            </button>
                        </div>
                        {/* 펼쳐지는 영역 시작 */}
                        <CSSTransition in={openStates} timeout={500} classNames="review" unmountOnExit>
                            <div className='px-5 w-100'>
                                <div className='w-100 py-2'><button className={`${seller.seller_board_toggle_menu_btn} ${activeBoard=='board' ? seller.seller_signature_color : ''}`} onClick={() => {setActiveBoard('board'); changeActiveBoard('board')}}>공지사항</button></div>
                                <div className='w-100 py-2'><button className={`${seller.seller_board_toggle_menu_btn} ${activeBoard=='faq' ? seller.seller_signature_color : ''}`} onClick={() => {setActiveBoard('faq'); changeActiveBoard('faq')}}>FAQ</button></div>
                            </div>
                        </CSSTransition>
                        <div className='py-2 w-100'>
                            <button className={`${seller.seller_menu_btn} d-flex align-items-center ${activeMenu === 'settlement' ? seller.seller_menu_active : ''}`} 
                                    onClick={() => {handleMenuClick('settlement'); toggleSettlementOpen();}}
                            >
                                {/* Icon Img */}
                                <div className={`${seller.seller_menu_icon_box}`}><img src="../../img/seller/icon/settlement_management.png" alt="정산관리" /></div>
                                {/* Menu Name */}
                                <div className={`${seller.seller_menu_name_box} ps-3`}><span>정산 관리</span></div>
                                {/* allow Icon */}
                                <div className={`position-absolute ${seller.seller_allow_icon}`}>
                                    <span className="material-symbols-outlined">{openSettlementStates ? 'expand_less' : 'expand_more'}</span>
                                </div>
                            </button>
                        </div>
                        {/* 펼쳐지는 영역 시작 */}
                        <CSSTransition in={openSettlementStates} timeout={500} classNames="review" unmountOnExit>
                            <div className='px-5 w-100'>
                                <div className='w-100 py-2'><button className={`${seller.seller_board_toggle_menu_btn} ${activeSettlement=='proceeds' ? seller.seller_signature_color : ''}`} onClick={() => {setActiveSettlement('proceeds'); changeActiveSettlement('proceeds');}}>수익금 내역</button></div>
                                <div className='w-100 py-2'><button className={`${seller.seller_board_toggle_menu_btn} ${activeSettlement=='calculate' ? seller.seller_signature_color : ''}`} onClick={() => {setActiveSettlement('calculate'); changeActiveSettlement('calculate')}}>정산 내역</button></div>
                            </div>
                        </CSSTransition>
                        <div className='py-2 w-100'>
                            <button className={`${seller.seller_menu_btn} d-flex align-items-center ${activeMenu === 'review' ? seller.seller_menu_active : ''}`} 
                                    onClick={() => handleMenuClick('review')}
                            >
                                {/* Icon Img */}
                                <div className={`${seller.seller_menu_icon_box}`}><img src="../../img/seller/icon/review_management.png" alt="리뷰관리" /></div>
                                {/* Menu Name */}
                                <div className={`${seller.seller_menu_name_box} ps-3`}><span>리뷰 관리</span></div>
                            </button>
                        </div>
                    </div>
                    <div className='pt-3 pb-3 border-bottom'>
                    <div className='py-2 w-100'>
                            <button className={`${seller.seller_menu_btn} d-flex align-items-center ${activeMenu === 'store' ? seller.seller_menu_active : ''}`} 
                                    onClick={() => {handleMenuClick('store'); toggleShopOpen();}}
                            >
                                {/* Icon Img */}
                                <div className={`${seller.seller_menu_icon_box}`}><img src="../../img/seller/icon/store_management.png" alt="상점관리" /></div>
                                {/* Menu Name */}
                                <div className={`${seller.seller_menu_name_box} ps-3`}><span>상점 관리</span></div>
                                {/* allow Icon */}
                                <div className={`position-absolute ${seller.seller_allow_icon}`}>
                                    <span className="material-symbols-outlined">{openShopState ? 'expand_less' : 'expand_more'}</span>
                                </div>
                            </button>
                        </div>
                        {/* 펼쳐지는 영역 시작 */}
                        <CSSTransition in={openShopState} timeout={500} classNames="review" unmountOnExit>
                            <div className='px-5 w-100'>
                                <div className='w-100 py-2'><button className={`${seller.seller_board_toggle_menu_btn} ${activeShopManagement=='profile' ? seller.seller_signature_color : ''}`} onClick={() => {setActiveShopManagement('profile'); changeActiveShopManagement('profile');}}>프로필</button></div>
                                <div className='w-100 py-2'><button className={`${seller.seller_board_toggle_menu_btn} ${activeShopManagement=='operating' ? seller.seller_signature_color : ''}`} onClick={() => {setActiveShopManagement('operating'); changeActiveShopManagement('operating')}}>운영시간</button></div>
                                <div className='w-100 py-2'><button className={`${seller.seller_board_toggle_menu_btn} ${activeShopManagement=='dateSetting' ? seller.seller_signature_color : ''}`} onClick={() => {setActiveShopManagement('dateSetting'); changeActiveShopManagement('dateSetting')}}>일정 관리</button></div>
                                <div className='w-100 py-2'><button className={`${seller.seller_board_toggle_menu_btn} ${activeShopManagement=='businessinfo' ? seller.seller_signature_color : ''}`} onClick={() => {setActiveShopManagement('businessinfo'); changeActiveShopManagement('businessinfo')}}>사업자 정보</button></div>
                            </div>
                        </CSSTransition>
                        <div className='py-2 w-100'>
                            <button className={`${seller.seller_menu_btn} d-flex align-items-center ${activeMenu === 'sales' ? seller.seller_menu_active : ''}`}
                                    onClick={() => {handleMenuClick('sales'); toggleSalesOpen();}}
                            >
                                {/* Icon Img */}
                                <div className={`${seller.seller_menu_icon_box}`}><img src="../../img/seller/icon/sales_policy.png" alt="판매정책" /></div>
                                {/* Menu Name */}
                                <div className={`${seller.seller_menu_name_box} ps-3`}><span>판매 정책</span></div>
                                {/* allow Icon */}
                                <div className={`position-absolute ${seller.seller_allow_icon}`}>
                                    <span className="material-symbols-outlined">expand_more</span>
                                </div>
                            </button>
                        </div>
                        {/* 펼쳐지는 영역 시작 */}
                        <CSSTransition in={openSalesState} timeout={500} classNames="review" unmountOnExit>
                            <div className='px-5 w-100'>
                                <div className='w-100 py-2'><button className={`${seller.seller_board_toggle_menu_btn} ${activeSalesManagement=='faqs' ? seller.seller_signature_color : ''}`} onClick={() => {setActiveSalesManagement('faqs'); changeActiveSalesManagement('faqs')}}>자주쓰는 FAQ</button></div>
                            </div>
                        </CSSTransition>
                        <div className='py-2 w-100'>
                            <button className={`${seller.seller_menu_btn} d-flex align-items-center ${activeMenu === 'edit' ? seller.seller_menu_active : ''}`} 
                                    onClick={() => handleMenuClick('edit')}
                            >
                                {/* Icon Img */}
                                <div className={`${seller.seller_menu_icon_box}`}><img src="../../img/seller/icon/edit_square.png" alt="상품등록" /></div>
                                {/* Menu Name */}
                                <div className={`${seller.seller_menu_name_box} ps-3`}><span>상품 등록</span></div>
                            </button>
                        </div>
                        <div className='py-2 w-100'>
                            <button className={`${seller.seller_menu_btn} d-flex align-items-center ${activeMenu === 'product' ? seller.seller_menu_active : ''}`} 
                                    onClick={() => handleMenuClick('product')}
                            >
                                {/* Icon Img */}
                                <div className={`${seller.seller_menu_icon_box}`}><img src="../../img/seller/icon/product_category.png" alt="상품관리" /></div>
                                {/* Menu Name */}
                                <div className={`${seller.seller_menu_name_box} ps-3`}><span>상품 관리</span></div>
                            </button>
                        </div>
                        <div className='py-2 w-100'>
                            <button className={`${seller.seller_menu_btn} d-flex align-items-center ${activeMenu === 'reservation' ? seller.seller_menu_active : ''}`} 
                                    onClick={() => handleMenuClick('reservation')}
                            >
                                {/* Icon Img */}
                                <div className={`${seller.seller_menu_icon_box}`}><img src="../../img/seller/icon/calender.png" alt="예약관리" /></div>
                                {/* Menu Name */}
                                <div className={`${seller.seller_menu_name_box} ps-3`}><span>예약 관리</span></div>
                            </button>
                        </div>
                        <div className='py-2 w-100'>
                            <button className={`${seller.seller_menu_btn} d-flex align-items-center ${activeMenu === 'message' ? seller.seller_menu_active : ''}`} 
                                    onClick={() => handleMenuClick('message')}
                            >
                                {/* Icon Img */}
                                <div className={`${seller.seller_menu_icon_box}`}><img src="../../img/seller/icon/message.png" alt="메세지" /></div>
                                {/* Menu Name */}
                                <div className={`${seller.seller_menu_name_box} ps-3`}><span>메세지</span></div>
                            </button>
                        </div>
                        <div className='py-2 w-100'>
                            <button className={`${seller.seller_menu_btn} d-flex align-items-center ${activeMenu === 'messagePayment' ? seller.seller_menu_active : ''}`} 
                                    onClick={() => handleMenuClick('messagePayment')}
                            >
                                {/* Icon Img */}
                                <div className={`${seller.seller_menu_icon_box}`}><img src="../../img/seller/icon/settlement_management.png" alt="메세지" /></div>
                                {/* Menu Name */}
                                <div className={`${seller.seller_menu_name_box} ps-3`}><span>메세지결제내역</span></div>
                            </button>
                        </div>
                    </div>
                    <div className={`${seller.seller_footer_btn_box} px-5 py-3`}>
                        <div className='py-2'><button>비밀번호 재설정</button></div>
                        <div className='py-2'><button onClick={() => sellerlogout()}>로그아웃</button></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default SupportSellerSide