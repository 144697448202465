import React, { useContext, useEffect, useRef, useState } from 'react'
import { animated } from 'react-spring';
import { useSpring, useTransition } from '@react-spring/core';
import { LoginContext } from '../../contexts/LoginContextProvider';
import * as auth from '../../apis/auth';
import Country from '../join/Country';
import dailyduck from '../../containers/my/MyContainner.module.css'
import { useLocation, useNavigate } from 'react-router-dom';

const UserWithDraw = ( {} ) => {

    const { isLogin, login, logout, userInfo, remove } = useContext(LoginContext);

    const context = useContext(LoginContext);
    const countries = context.countries;
    const navigate = useNavigate();

    const {
        state : { users }
    } = useLocation();


    
    const [selectedCountry, setSelectedCountry] = useState({
        img:  `${process.env.PUBLIC_URL}/img/icon/country/South_Korea_(KR).png`,
        name: users ? users.userRepublic : "Republic of Korea",
        number: "+82"
    });

    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneTimer, setPhoneTimer] = useState(300);
    const [certificationInputVisible, setCertificationInputVisible] = useState(false);
    const [isCheck, setIsCheck] = useState(false);
    const [mockCertificationNumber, setMockCertificationNumber] = useState('000000');
    const [userCertificationNumber, setUserCertificationNumber] = useState('');
    const [certificationSuccess, setCertificationSuccess] = useState(false); // 인증 성공 상태 추가
    const [timer, setTimer] = useState(300);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredCountries, setFilteredCountries] = useState(countries);
    const [ showCountry, setShowCountry ] = useState(false);
    const [phoneAuthNumber, setPhoneAuthNumber] = useState('');

    const timerRef = useRef(null); // 타이머 관리를 위한 useRef

    // 타이머가 변경될 때마다 실행
    useEffect(() => {
        // 타이머가 0이면 타이머를 정지하고 버튼을 활성화
        if (timer === 0) {
            clearInterval(timerRef.current);
            setIsCheck(true);
        }
    }, [timer]);

    useEffect(() => {
        // 컴포넌트 언마운트 시 타이머 정리
        return () => clearInterval(timerRef.current);
    }, []);
    
    useEffect(() => {
        setPhoneNumber(users.userTel);
    }, [users]);

    // 국가 선택 기능
    const handleCountry = () => {
        setShowCountry(true);
    }

    const handleCountryClose = () => {
        setShowCountry(false);
    }

    const handlePhoneNumber = (e) => {
        setPhoneNumber(e.target.value);
    }

    const certificationNumber = async () => {
        setIsCheck(false); // 인증번호 받기 버튼 비활성화
        
        try {
            const response = await auth.phoneAuth(phoneNumber);
            const data = response.data;
            alert('인증번호가 발송되었습니다.')

            if (data === 'SUCCESS') {
                setCertificationInputVisible(true); // 인증번호 인풋 창 활성화

                // 타이머 시작
                clearInterval(timerRef.current); // 기존 타이머 정리
                setTimer(300); // 타이머 초기화
                timerRef.current = setInterval(() => {
                    setTimer(prevTimer => prevTimer - 1); // 타이머 상태 감소
                }, 1000);
            }
        } catch (error) {
            console.error('API 호출 중 오류 발생:', error); // 오류 처리
        }
    };

    const checkCertificationNumber = async (e) => {
        const inputValue = e.target.value;
        setPhoneAuthNumber(inputValue);

        // 입력값이 6자리일 때 2초 후에 phoneAuthCheck 호출
        if (inputValue.length === 6) {
            setTimeout(async () => {
                const response = await auth.phoneAuthCheck(phoneNumber, inputValue); // 입력된 인증 번호 사용
                const data = response.data;

                if (data === 'SUCCESS') {
                    alert('인증 완료되었습니다.');
                    backServerPhoneChange();
                    setIsCheck(true);
                    setCertificationSuccess(true); // 인증번호가 맞으면 인증 성공 상태를 true로 설정
                    setCertificationInputVisible(false);
                    clearInterval(timerRef.current); // 타이머 정리
                    setTimer(300); // 타이머 300초로 초기화
                } else {
                    alert('인증번호가 일치하지 않습니다.');
                    setIsCheck(false);
                    setCertificationSuccess(false); // 인증번호가 틀리면 인증 성공 상태를 false로 설정
                    setCertificationInputVisible(true);
                }
            }, 2000); // 2000밀리초 (2초) 후에 실행
        }
    };

    const backServerPhoneChange = async () => {
        const response = await auth.phoneChange(phoneNumber, userInfo ? userInfo.userId : 'null');
        const data = response.data;

        // console.log(data);
    }

    function handleSelectedCountry(country) {
        
        if (!country) {
            // // console.log('Country not defined');
            return;
        }

        setSelectedCountry({
            name: country.name,
            number: country.number,
            img: country.img
        });
    }

    const handleWithDrawBack = () => {
        navigate('/my/myinfo');
    }
    return (
        <>
                {
                    <div className={`${dailyduck.my_withdraw_container}`}>
                            <div className={dailyduck.my_withdraw_box}>
                                <div className='agree_header bg-white'>
                                    <div className='position-absolute top-0 start-0 ps-4 pt-4'>
                                        <button onClick={() => handleWithDrawBack()} className='reservation_pay_close'>
                                        <span className="material-symbols-outlined">arrow_back_ios</span>
                                        </button>
                                    </div>
                                    <div>
                                        <div className='text-center py-4'>
                                            <h5>회원탈퇴</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className='bg-white py-3 px-3'>
                                    <div className='myinfo_input_box'>
                                        <div className='pb-1'><span>국가</span></div>
                                        <div className='join_input'>
                                            {!selectedCountry ? 
                                                <button type="text" className='d-flex' placeholder='국가' onClick={handleCountry}>
                                                    <span className='country_name_input'>국가선택</span>
                                                    <span className="material-symbols-outlined">expand_more</span>
                                                </button> : 
                                                <button type="text" className='d-flex' placeholder='국가' onClick={handleCountry}>
                                                    <img src={selectedCountry?.img} alt={selectedCountry?.name} />
                                                    <span className='country_name_input'>{selectedCountry?.name}</span>
                                                    <span className="material-symbols-outlined">expand_more</span>
                                                </button>
                                            }
                                        </div>
                                    </div>
                                    <div className='myinfo_input_box pt-4'>
                                        <div className='pb-1'><span>휴대폰 번호</span></div>
                                            <div className={`d-flex ${dailyduck.myinfo_phone_box}`}>
                                                <div className={`join_input ${dailyduck.phone_number_box} pe-1`}>
                                                    <input type="number" className='join_input_tag' value={phoneNumber ? phoneNumber : '핸드폰 번호'} onChange={(e) => handlePhoneNumber(e)} />
                                                    <span style={{color : 'rgba(255, 3, 102, 1)'}}>{selectedCountry?.number}</span>
                                                </div>
                                                <div className={`${dailyduck.myinfo_phone_btn_box}`}>
                                                    <button className={`${!certificationInputVisible && dailyduck.myinfo_authnumber_btn_active}`} disabled={certificationInputVisible} onClick={certificationNumber}>인증</button>
                                                </div>
                                            </div>
                                        {
                                            certificationInputVisible && (
                                                <div>
                                                    <div className='join_input position-relative py-2'>
                                                        <input type="number" className='join_input_tag' placeholder='인증번호' onChange={checkCertificationNumber} />
                                                        <span className='cer_span'>{Math.floor(timer / 60)}:{('0' + timer % 60).slice(-2)}</span>
                                                    </div>
                                                    <div>
                                                        <span className='dd-fs-7 gray_color'>인증번호를 받지 못하셨나요?</span><span><button className={`${dailyduck.myinfo_authNumber_re} ps-2`} onClick={certificationNumber}>인증번호 재요청</button></span>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className='mt-2 py-3 bg-white px-3'>
                                    <div className='py-3 dd-fs-6'><span>탈퇴 전 반드시 확인해주세요</span></div>
                                    <div className={`${dailyduck.my_withdraw_point_box}`}>
                                        <div className='py-2'><span>잔여 포인트</span></div>
                                        <div className={`${dailyduck.my_withdraw_point}`}><span>{users && users.userPoint.toLocaleString()}P</span></div>
                                    </div>
                                    <div className='pt-4 px-2'>
                                        <li className='py-1 dd-fs-7' style={{listStyle : 'disc'}}>회원탈퇴 시 계정과 관련된 정보는 복구가 불가하며 n일 이후 재가입 가능합니다.</li>
                                        <li className='py-1 dd-fs-7' style={{listStyle : 'disc'}}>현재 보유중인 포인트는 모두 소멸되며 재가입 후에도 복구할 수 없습니다.</li>
                                        <li className='py-1 dd-fs-7' style={{listStyle : 'disc'}}>재가입 시에는 첫 가입 혜택을 받을 수 없습니다.</li>
                                    </div>
                                </div>
                                <div className={`${dailyduck.my_withdraw_btn} pt-5 bg-white px-3`}>
                                    <button className={`${certificationSuccess ? dailyduck.my_withdraw_active : ''}`} disabled={!certificationSuccess} onClick={() => remove()}>탈퇴하기</button>
                                </div>
                            {
                                showCountry &&
                                    <Country showCountry={showCountry} setShowCountry={setShowCountry} handleCountryClose={handleCountryClose} 
                                            searchTerm={searchTerm} setSearchTerm={setSearchTerm} countries={countries}
                                            filteredCountries={filteredCountries} setFilteredCountries={setFilteredCountries}
                                            setSelectedCountry={setSelectedCountry} handleSelectedCountry={handleSelectedCountry} />
                            }
                            </div>
                    </div>
                }
        </>
    )
}

export default UserWithDraw