import React, { useEffect, useRef, useState } from 'react';
import admin from '../../../admin.module.css';
import { Button, DatePicker, Input, Select, Modal } from 'antd';
import { CSSTransition } from 'react-transition-group';
import * as adminApi from '../../../../apis/admin';
import { format } from 'date-fns';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const AdminInquiry = () => {
    const { Option } = Select;
    const { RangePicker } = DatePicker;    
    const [selectDate, setSelectDate] = useState([]);
    const [activeButton, setActiveButton] = useState('all');
    const [inquiryList, setInquiryList] = useState([]);
    const [filteredInquiryList, setFilteredInquiryList] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');

    useEffect(() => {
        inquirySelect();
    }, []);

    useEffect(() => {
        filterInquiries();
    }, [activeButton, inquiryList]);

    const inquirySelect = async () => {
        const response = await adminApi.inquirySelect();
        const data = response.data.map(item => ({
            ...item,
            showQuickAnswer: false,
            quickAnswerContent: item.inquiryAnswer || '',
        }));

        // console.log('inquiryList : ', data);
        setInquiryList(data);
    };

    const filterInquiries = () => {
        if (activeButton === 'all') {
            setFilteredInquiryList(inquiryList);
        } else if (activeButton === 'wait') {
            setFilteredInquiryList(inquiryList.filter(item => item.inquiryState === '답변대기'));
        } else if (activeButton === 'complete') {
            setFilteredInquiryList(inquiryList.filter(item => item.inquiryState === '답변완료'));
        }
    };

    const getCountByState = (state) => {
        return inquiryList.filter(item => item.inquiryState === state).length;
    };

    const handleDateChange = (dates, dateStrings) => {
        // console.log('선택된 날짜 범위:', dates);
        // console.log('선택된 날짜 범위 (문자열):', dateStrings);
        setSelectDate(dateStrings);
    };

    const [selectedValue, setSelectedValue] = useState('all');
    const [searchValue, setSearchValue] = useState('');
    
    const handleChange = (value) => {
        // console.log('선택된 값:', value);
        setSelectedValue(value);
    };

    const onSearchValue = (e) => {
        // console.log(e.target.value);
        setSearchValue(e.target.value);
    };

    const ref = useRef();

    useEffect(() => {
        const inputs = ref.current.querySelectorAll('.ant-picker-input input');
        inputs.forEach(input => {
            input.setAttribute('readonly', 'readonly');
        });
    }, []);

    const onSearchClick = () => {
        // console.log('selectDate : ', selectDate);
        // console.log('selectedValue : ', selectedValue);
        // console.log('searchValue : ', searchValue);
        
        const formData = new FormData();

        formData.append('startDate', selectDate[0]);
        formData.append('endDate', selectDate[1]);
        formData.append('selectedValue', selectedValue);
        formData.append('searchValue', searchValue);

        for (let [key, value] of formData.entries()) {
            // console.log(key, value);
        }

        const config = {
            headers: {
              'content-type': 'multipart/form-data'
            }
        };
    };

    const toggleQuickAnswer = (index) => {
        const updatedList = [...inquiryList];
        updatedList[index].showQuickAnswer = !updatedList[index].showQuickAnswer;
        setInquiryList(updatedList);
    };

    const handleQuillChange = (index, content) => {
        const updatedList = [...inquiryList];
        updatedList[index].quickAnswerContent = content;

        // console.log('updatedList : ', updatedList)
        setInquiryList(updatedList);
    };

    const handleSaveAnswer = async (index) => {
        const inquiry = inquiryList[index];
        // console.log('inquiry : ', inquiry);
        
        // 서버에 답변 저장 요청
        const response = await adminApi.saveInquiryAnswer(inquiry.inquiryNo, inquiry.quickAnswerContent);
        
        if (response.data === 'SUCCESS') {
            // 답변 완료 상태로 업데이트
            const updatedList = [...inquiryList];
            updatedList[index].inquiryAnswer = inquiry.quickAnswerContent;
            updatedList[index].inquiryState = '답변완료';
            updatedList[index].showQuickAnswer = false; // 답변 창을 닫기 위해 false로 변경
            
            // 업데이트된 리스트 상태 반영
            setInquiryList(updatedList);
            filterInquiries();
            
            // 알림 표시
            alert('답변이 완료되었습니다.');
        } else {
            alert('답변 저장에 실패했습니다.');
        }
    };

    const formatDate = (date) => {
        return format(new Date(date), 'yyyy-MM-dd HH:mm:ss');
    };

    const showImagePreview = (imageSrc) => {
        setPreviewImage(imageSrc);
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <div className={`${admin.admin_operate_inquiry_box} px-3 py-3`}>
            <div>
                <div className={`${admin.admin_operate_inquiry_title}`}><span>문의</span></div>
                <div className={`${admin.admin_operate_inquiry_tap} border-bottom`}>
                    <div className={`px-1 ${activeButton === 'all' ? admin.admin_operate_inquiry_tap_active : ''}`}>
                        <button onClick={() => setActiveButton('all')}>전체 ({inquiryList.length})</button>
                    </div>
                    <div className={`px-1 ${activeButton === 'wait' ? admin.admin_operate_inquiry_tap_active : ''}`}>
                        <button onClick={() => setActiveButton('wait')}>답변대기 ({getCountByState('답변대기')})</button>
                    </div>
                    <div className={`px-1 ${activeButton === 'complete' ? admin.admin_operate_inquiry_tap_active : ''}`}>
                        <button onClick={() => setActiveButton('complete')}>답변완료 ({getCountByState('답변완료')})</button>
                    </div>
                </div>
                <div className={`${admin.admin_userList_search_box} py-3`}>
                    <div className={`${admin.admin_userList_search_input}`}>
                        <div>
                            <Select
                                className={`${admin.admin_userList_search_select}`}
                                onChange={handleChange}
                                defaultValue={selectedValue}
                            >
                                <Option value="all">전체</Option>
                                <Option value="name">이름</Option>
                                <Option value="nickname">닉네임</Option>
                                <Option value="phone">제목</Option>
                            </Select>
                        </div>
                        <div className={`ps-1 ${admin.admin_userList_search_input_txt}`}>
                            <input 
                                type="text" 
                                onChange={(e) => onSearchValue(e)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        onSearchClick();
                                    }
                                }} 
                            />
                        </div>
                        <div className={`ps-1 ${admin.admin_userList_search_btn}`}>
                            <button onClick={() => onSearchClick()}>검색</button>
                        </div>
                    </div>
                    <div className={`${admin.admin_userList_search_date}`}>
                        <div ref={ref}>
                            <RangePicker className={`${admin.admin_userList_datepicker}`}
                                separator="~"
                                placeholder={['0000.00.00', '0000.00.00']}
                                onChange={handleDateChange}
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <div className={`${admin.admin_operate_inquiry_content_header}`}>
                            <div><span>번호</span></div>
                            <div><span>이미지</span></div>
                            <div><span>닉네임</span></div>
                            <div><span>제목</span></div>
                            <div><span>작성일자</span></div>
                            <div><span>답변유무</span></div>
                            <div></div>
                        </div>
                        {filteredInquiryList.map((item, index) => (
                            <React.Fragment key={item.inquiryNo}>
                                <div className={`${admin.admin_operate_inquiry_content_content}`}>
                                    <div><span>{index + 1}</span></div>
                                    <div className={`${admin.admin_operate_inquiry_content_user_review}`}>
                                        <div><img src={item.inquiryImg1} alt="상품이미지" onClick={() => showImagePreview(item.inquiryImg1)} /></div>
                                    </div>
                                    <div><span>{item.userId}</span></div>
                                    <div><span>{item.inquiryTitle}</span></div>
                                    <div><span>{formatDate(item.inquiryRegDate)}</span></div>
                                    <div><span>{item.inquiryState}</span></div>
                                    <div className={`${admin.admin_operate_inquiry_content_quick}`} onClick={(e) => {e.stopPropagation(); toggleQuickAnswer(index);}}><button>빠른답변</button></div>
                                </div>
                                <CSSTransition in={item.showQuickAnswer} timeout={300} classNames="review" unmountOnExit>
                                    <div className={`${admin.admin_operate_inquiry_quick_content}`}>
                                        <div className='pb-3'><span>Q.</span><span className='ps-2'>{item.inquiryTitle}</span></div>
                                        <div dangerouslySetInnerHTML={{ __html: item.inquiryContent }}></div>
                                        <div className={`${admin.admin_operate_inquiry_quick_content_img}`}>
                                            <div><img src={item.inquiryImg1} alt="" onClick={() => showImagePreview(item.inquiryImg1)} /></div>
                                            <div><img src={item.inquiryImg2} alt="" onClick={() => showImagePreview(item.inquiryImg2)} /></div>
                                            <div><img src={item.inquiryImg3} alt="" onClick={() => showImagePreview(item.inquiryImg3)} /></div>
                                            <div><img src={item.inquiryImg4} alt="" onClick={() => showImagePreview(item.inquiryImg4)} /></div>
                                        </div>
                                        <div><span>A.</span></div>
                                        <div>
                                            <ReactQuill
                                                theme="snow"
                                                defaultValue={item.inquiryAnswer ? item.inquiryAnswer : ''}
                                                onChange={(content) => handleQuillChange(index, content)}
                                                className={admin.inquiry_quill}
                                            />
                                            {item.inquiryAnswer ? (
                                                <button className={`${admin.inquiry_btn}`} onClick={() => handleSaveAnswer(index)}>답변 수정</button>
                                            ) : (
                                                <button className={`${admin.inquiry_btn}`} onClick={() => handleSaveAnswer(index)}>답변 등록</button>
                                            )}
                                        </div>
                                    </div>
                                </CSSTransition>
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            </div>
            <Modal visible={isModalVisible} footer={null} onCancel={handleCancel}>
                <img alt="preview" style={{ width: '100%' }} src={previewImage} />
            </Modal>
        </div>
    );
};

export default AdminInquiry;
