import React, { useEffect, useState } from 'react';
import admin from '../../../admin.module.css';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { format } from 'date-fns';
import * as adminApi from '../../../../apis/admin';

const AdminSellerNoticeRead = () => {
    const MySwal = withReactContent(Swal);
    const location = useLocation();

    const notice  = location?.state;
    const navigate = useNavigate();

    const [noticeInfo, setNoticeInfo] = useState({});

    useEffect(() => {
        // console.log('notice : ', notice.notice);

        setNoticeInfo(notice.notice);
    }, [notice]);

    useEffect(() => {
        // console.log('noticeInfo : ', noticeInfo)
    }, [noticeInfo])

    const onNoticeList = () => {
        navigate(-1);
    }
    const onNoticeUpdate = () => {
        navigate(`/admin/seller/notice/update/${noticeInfo.sellerNoticeNo}`, {state : noticeInfo});
    }
    const onNoticeDelete = (no) => {
        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html : 
            `
                <div>
                    <div class='${admin.admin_seller_add_title}'><span>게시글을 삭제 하시겠습니까?</span></div>
                    <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                      <button class='mx-1' id='seller_add_cancel'>취소</button>
                      <button class='mx-1' id='seller_add_success'>삭제</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });
        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);
        
        async function sellerAddSuccess(e) {
            if(e.target && e.target.id == 'seller_add_success') {
                const response = await adminApi.sellerNoticeDelete(noticeInfo?.sellerNoticeNo);
                const data = response.data;

                if(data === 'SUCCESS') {
                    MySwal.fire({
                        customClass: {
                            popup: `${admin.admin_seller_add_popup}`
                        },
                        html : 
                        `
                        <div>
                        <div class='${admin.admin_seller_add_title}'><span>게시글 삭제가 완료 되었습니다.</span></div>
                        <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                        </div>
                        </div>
                        `,
                        showConfirmButton: false,
                        showCancelButton: false,
                    });
                    document.addEventListener('click', sellerAddConfirm);
                    document.removeEventListener('click', sellerAddSuccess);
                    document.removeEventListener('click', sellerAddCancel);
                    
                    function sellerAddConfirm(e) {
                        if(e.target && e.target.id == 'seller_add_confirm') {
                            MySwal.close();
                            document.removeEventListener('click', sellerAddConfirm);
                            navigate(-1);
                        }
                    }
                    
                } else {
                    MySwal.fire({
                        customClass: {
                            popup: `${admin.admin_seller_add_popup}`
                        },
                        html : 
                        `
                        <div>
                        <div class='${admin.admin_seller_add_title}'><span>게시글 삭제에 실패하였습니다.</span></div>
                        <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                        </div>
                        </div>
                        `,
                        showConfirmButton: false,
                        showCancelButton: false,
                    });
                    document.addEventListener('click', sellerAddConfirm);
                    document.removeEventListener('click', sellerAddSuccess);
                    document.removeEventListener('click', sellerAddCancel);
                    
                    function sellerAddConfirm(e) {
                        if(e.target && e.target.id == 'seller_add_confirm') {
                            MySwal.close();
                            document.removeEventListener('click', sellerAddConfirm);
                        }
                    }

                }
                
            }
        }
        
        function sellerAddCancel(e) {
            if(e.target && e.target.id == 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
            }
        }
    }
    
    const KSTformatDate = (date) => {
        // date가 유효한지 검사합니다.
        if (!date || isNaN(new Date(date))) {
            return 'Invalid Date'; // 또는 원하는 기본값을 반환하세요.
        }
        return format(new Date(date), 'yyyy-MM-dd HH:mm:ss');
    };

  return (
    <div className={`${admin.admin_seller_notice_box} px-3 py-3`}>
            <div>
                <div className={`${admin.admin_seller_notice_header}`}>
                    <div><h5>셀러 공지사항</h5></div>
                </div>
                <div className='px-3 pt-4'>
                    <div>
                        <div className={`${admin.admin_seller_notice_read_title}`}>
                            <div><h5>{noticeInfo.sellerNoticeTitle}</h5></div>
                            <div><span>{KSTformatDate(noticeInfo?.sellerNoticeRegDate)}</span></div>
                        </div>
                        <div className={`${admin.admin_seller_notice_read_content}`}>
                            <div dangerouslySetInnerHTML={{ __html: noticeInfo.sellerNoticeContent}}></div>
                        </div>
                    </div>
                    <div>
                        <div className={`${admin.admin_seller_notice_read_btn_box} py-5`}>
                            <div className='px-1'><button className={`${admin.admin_seller_notice_read_list}`} onClick={() => onNoticeList()}>목록</button></div>
                            <div className='px-1'><button className={`${admin.admin_seller_notice_read_update}`} onClick={() => onNoticeUpdate()}>게시글 수정</button></div>
                            <div className='px-1'><button className={`${admin.admin_seller_notice_read_delete}`} onClick={() => onNoticeDelete()}>게시글 삭제</button></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default AdminSellerNoticeRead