import React, { useState } from 'react';
import admin from '../../../admin.module.css';
import { Pagination } from 'antd';

const AdminEndEvent = () => {
  const [ activeButton, setActiveButton ] = useState('all');

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(14);
  const [totalItems, setTotalItems] = useState(0);    
  
  // 페이지 번호 클릭 시 호출될 함수
  const paginate = pageNumber => setCurrentPage(pageNumber);
  return (
    <div className={`${admin.amdin_event_setting_box} px-3 py-3`}>
        <div>
            <div className={`${admin.admin_event_setting_title}`}><span>지난 이벤트</span></div>
            <div className={`${admin.admin_event_setting_tap}`}>
                <div className={`${activeButton === 'all' ? admin.admin_event_setting_tap_active : ''}`}><button onClick={() => setActiveButton('all')}>전체 (0)</button></div>
                <div className={`${activeButton === 'lucked' ? admin.admin_event_setting_tap_active : ''}`}><button onClick={() => setActiveButton('lucked')}>럭드 (0)</button></div>
                <div className={`${activeButton === 'quiz' ? admin.admin_event_setting_tap_active : ''}`}><button onClick={() => setActiveButton('quiz')}>퀴즈 (0)</button></div>
                <div className={`${activeButton === 'special' ? admin.admin_event_setting_tap_active : ''}`}><button onClick={() => setActiveButton('special')}>기획전 (0)</button></div>
                <div className={`${activeButton === 'vote' ? admin.admin_event_setting_tap_active : ''}`}><button onClick={() => setActiveButton('vote')}>투표 (0)</button></div>
            </div>
            <div className={`${admin.admin_event_setting_event_content_box}`}>
              <div>
                <div className={`${admin.admin_event_setting_event_content_header}`}>
                  <div><input type="checkbox" /></div>
                  <div><span>이미지</span></div>
                  <div><span>제목</span></div>
                  <div><span>이벤트 요약</span></div>
                  <div><span>진행기간</span></div>
                  <div><span>이벤트 종류</span></div>
                  <div></div>
                </div>
                <div className={`${admin.admin_event_setting_event_content_content}`}>
                  <div><input type="checkbox" /></div>
                  <div><img src="../../img/event/event1.png" alt="이벤트이미지" /></div>
                  <div><span>데일리덕 이벤트</span></div>
                  <div><span>데일리덕에서 야심차게 준비한 이벤트 입니다.</span></div>
                  <div><span>2023-07-03 09:00</span><span>~</span><span>2023-08-03 13:11</span></div>
                  <div><span>럭드</span></div>
                  <div>
                      <div className='py-1'><button>수정</button></div>
                      <div className='py-1'><button>삭제</button></div>
                  </div>
                </div>
              </div>
            </div>
            
            <Pagination current={currentPage} total={totalItems} onChange={paginate} pageSize={itemsPerPage} />
        </div>
    </div>
  )
}

export default AdminEndEvent