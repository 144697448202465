import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import * as auth from '../../apis/auth';

const FavorityData = ({ items, setItems, dailyduck, userFavorityList, users, showFavorityDdayUpdate, setShowFavorityDdayUpdate, handleddayUpdate, handleddayDelete }) => {
  const [prevItems, setPrevItems] = useState({ top: [], bottom: [] });
  const [bottomMaxHeight, setBottomMaxHeight] = useState('500px'); // maxHeight 상태 추가

  useEffect(() => {
    // window height에 따라 maxHeight 설정
    const updateMaxHeight = () => {
      if (window.innerHeight <= 600) {
        setBottomMaxHeight('180px');
      } else if (window.innerHeight <= 670) {
        setBottomMaxHeight('280px');
      } else if (window.innerHeight <= 720) {
        setBottomMaxHeight('310px');
      } else if (window.innerHeight <= 750) {
        setBottomMaxHeight('330px');
      } else if(window.innerHeight <= 900) {
        setBottomMaxHeight('450px');
      } else {
        setBottomMaxHeight('500px');
      }
    };

    updateMaxHeight(); // 초기값 설정
    window.addEventListener('resize', updateMaxHeight); // 창 크기 변경 시 업데이트

    return () => window.removeEventListener('resize', updateMaxHeight);
  }, []);

  useEffect(() => {
    const topItems = [];
    const bottomItems = [];
    userFavorityList.forEach(data => {
      const item = {
        id: data.favorityNo.toString(),
        favorityName: data.favorityName,
        favorityImgUrl: data.favorityImgUrl,
        favorityDday: data.favorityDday,
        favorityRanking: data.favorityRanking,
        favorityStatus: data.favorityStatus
      };
      if(data.favorityStatus === 'top' && topItems.length < 1) {
        topItems.push(item);
      } else if(data.favorityStatus === 'bottom') {
        bottomItems.push(item);
      }
    });

    setItems({
      top: topItems,
      bottom: bottomItems
    });
  }, [userFavorityList]);

  useEffect(() => {
    const updatedItems = items.top.concat(items.bottom);
    const prevItemsList = prevItems.top.concat(prevItems.bottom);

    updatedItems.forEach((item, index) => {
      const prevItem = prevItemsList[index];
      if (!prevItem || item.id !== prevItem.id || item.favorityStatus !== prevItem.favorityStatus || item.favorityRanking !== prevItem.favorityRanking) {
        updateDatabase(item.id, item.favorityName, item.favorityStatus, item.favorityRanking);
      }
    });

    setPrevItems(items);
  }, [items]);

  const onDragEnd = async ({ source, destination }) => {
    if (!destination) return;

    const sourceKey = source.droppableId;
    const destinationKey = destination.droppableId;

    if(destinationKey === 'top' && items.top.length >= 1 && sourceKey !== destinationKey) {
      const movingItemName = items[sourceKey][source.index].favorityName;
      const result = await Swal.fire({
        customClass: {
          container: 'favority_data_modal',
          confirmButton: 'favority_data_confirm',
          cancelButton: 'favority_data_cancle'
        },
        title: '응원마켓 최애 변경',
        html: `선택하신 최애(${movingItemName})을<br>응원마켓 최애로 변경 하시겠습니까?<br>변경 시 응원마켓에<br> 선택하신 최애(${movingItemName}) 상품이 노출됩니다.`,
        showCancelButton: true,
        confirmButtonText: '예',
        cancelButtonText: '아니오',
        reverseButtons: true
      });

      if (result.isConfirmed) {
        moveItem(source, destination, true);
      }
      return;
    }

    moveItem(source, destination);
  };

  const moveItem = (source, destination, replace = false) => {
    const _items = JSON.parse(JSON.stringify(items));

    if(replace && _items.top.length && destination.droppableId === 'top') {
      const existingTopItem = _items.top.pop();
      existingTopItem.favorityStatus = 'bottom';
      existingTopItem.favorityRanking = _items.bottom.length + 2;
      _items.bottom.push(existingTopItem);
    }

    const [targetItem] = _items[source.droppableId].splice(source.index, 1);
    targetItem.favorityStatus = destination.droppableId;
    if(destination.droppableId === 'top') {
      targetItem.favorityRanking = 1;
    } else {
      targetItem.favorityRanking = _items.bottom.length + 2;
    }
    _items[destination.droppableId].splice(destination.index, 0, targetItem);

    _items.bottom.forEach((item, index) => {
      item.favorityRanking = index + 2;
    });

    setItems(_items);
  };

  const updateDatabase = async (id, name, status, ranking) => {
    const response = await auth.updateFavority(id, name, status, ranking, users && users?.userId);
    const data = response.data;
  };

  return (
    <div className={`${dailyduck.my_favority_data_container}`}>
      <div className="flex">
        <DragDropContext onDragEnd={onDragEnd}>
          <div className="grid flex-1 select-none grid-cols-2 gap-4 rounded-lg">
            {Object.keys(items).map((key) => (
              <Droppable key={key} droppableId={key}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className="flex flex-col gap-3 rounded-xl py-2 ring-1 ring-gray-300 transition-shadow"
                    style={{
                      minHeight: key === 'top' && '150px',
                      borderBottom: key === 'top' ? '1px solid rgba(241, 242, 243, 1)' : '',
                      maxHeight: key === 'bottom' && bottomMaxHeight,
                      overflowY: key === 'bottom' && 'auto'
                    }}
                  >
                    {key === 'bottom' && (
                      <div className="py-2 font-semibold text-lg">
                        <span>최애 리스트</span>
                      </div>
                    )}
                    {items[key].map((item, index) => (
                      <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={`${dailyduck.myfavority_total_box} my-2 rounded-lg bg-white p-3 transition-shadow dark:bg-[#121212] shadow-none ${snapshot.isDragging ? 'bg-opacity-90 shadow-2xl shadow-gray-400' : 'shadow'}`}
                          >
                            <div className="d-flex justify-content-between">
                              <div className={`${dailyduck.myfavority_img_box}`}>
                                <img src={`${process.env.REACT_APP_API_SERVER}/img?file=${item.favorityImgUrl}`} alt="가수 프로필" />
                              </div>
                              <div className={`${dailyduck.myfavority_name_dday_box}`}>
                                <div className={`${dailyduck.myfavority_name_box}`}><span>{item.favorityName}</span></div>
                                <div className={`${dailyduck.myfavority_dday_box}`}><span>D+</span><span>{item.favorityDday}</span></div>
                              </div>
                              <div className={`${dailyduck.myfavority_update_box}`}><button onClick={() => handleddayUpdate(item.id)}>수정</button></div>
                              <div className={`${dailyduck.myfavority_delete_box}`}><button onClick={() => handleddayDelete(item.id, item.favorityName)}><span className="material-symbols-outlined">close</span></button></div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            ))}
          </div>
        </DragDropContext>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default FavorityData;
