import React, { useEffect, useRef, useState } from 'react';
import admin from '../../../admin.module.css';
import { Select, DatePicker } from 'antd';
import { Option } from 'antd/es/mentions';
import { Pagination } from 'antd';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import * as support from '../../../../apis/support';
import * as adminApi from '../../../../apis/admin';
import moment from 'moment-timezone';
import { format } from 'date-fns';

const AdminPopup = () => {
    const ref = useRef();
    const { RangePicker } = DatePicker;
    const [pushChecked, setPushChecked] = useState(false);
    const MySwal = withReactContent(Swal);
    const [showMainPopup, setShowMainPopup] = useState(false);
    const [showMainPopupUpdate, setShowMainPopupUpdate] = useState(false);
    const [content, setContent] = useState({});
    const [editContent, setEditContent] = useState({});
    const [previewImage, setPreviewImage] = useState("../../img/admin/defaultImage.png");
    const [editPreviewImage, setEditPreviewImage] = useState("../../img/admin/defaultImage.png");
    const [exposureChecked, setExposureChecked] = useState(false);
    const [editExposureChecked, setEditExposureChecked] = useState(false);
    const [linkState, setLinkState] = useState('');
    const [typeState, setTypeState] = useState('');
    const [editLinkState, setEditLinkState] = useState('');
    const [editTypeState, setEditTypeState] = useState('');
    const [selectDate, setSelectDate] = useState([]);
    const [popup, setPopup] = useState([]);
    const [checkedPopup, setCheckedPopup] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(14);
    const [totalItems, setTotalItems] = useState(0);    
    
    const paginate = pageNumber => setCurrentPage(pageNumber);

    useEffect(() => {
        popupSelect();
    }, []);

    const popupSelect = async () => {
        const response = await adminApi.popupSelect();
        const data = response.data;

        // console.log(data);
        setPopup(data);
    }

    const handleDateChange = (dates, dateStrings) => {
        // console.log('선택된 날짜 범위:', dates);
        // console.log('선택된 날짜 범위 (문자열):', dateStrings);
        setContent({
            ...content, 
            popupStartDate: moment(dates[0]).tz('Asia/Seoul').format('YYYY.MM.DD HH:mm:ss'), 
            popupEndDate: moment(dates[1]).tz('Asia/Seoul').format('YYYY.MM.DD HH:mm:ss')
        });
        setSelectDate(dateStrings);
    };

    const handleEditDateChange = (dates, dateStrings) => {
        setEditContent({
            ...editContent,
            popupStartDate: moment(dates[0]).tz('Asia/Seoul').format('YYYY.MM.DD HH:mm:ss'),
            popupEndDate: moment(dates[1]).tz('Asia/Seoul').format('YYYY.MM.DD HH:mm:ss')
        });
        setSelectDate(dateStrings);
    };

    const onMainPopupClose = () => {
        setShowMainPopup(false);
    };

    const onMainPopupUpdateClose = () => {
        setShowMainPopupUpdate(false);
    };

    const onPopupName = (e) => {
        setContent({
            ...content, popupTitle: e.target.value
        });
    };

    const onEditPopupName = (e) => {
        setEditContent({
            ...editContent, popupTitle: e.target.value
        });
    };

    const fileInputRef = useRef(null);
    const handleImageClick = () => {
        fileInputRef.current.click();
    };

    const handleEditImageClick = () => {
        fileInputRef.current.click();
    };

    const handleImageUpload = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewImage(reader.result);
            };
            reader.readAsDataURL(file);

            const formData = new FormData();
            formData.append('image', file);

            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            };

            const response = await support.uploadImageToServer(formData, config);
            const data = response.data;

            // console.log('data : ', data);
            if (data != '') {
                const imageUrl = `${process.env.REACT_APP_API_SERVER}/img?file=${encodeURIComponent(data)}`;
                // console.log(imageUrl);
                setContent({
                    ...content, popupImg: imageUrl
                });
            }
        }
    };

    const handleEditImageUpload = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setEditPreviewImage(reader.result);
            };
            reader.readAsDataURL(file);

            const formData = new FormData();
            formData.append('image', file);

            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            };

            const response = await support.uploadImageToServer(formData, config);
            const data = response.data;

            // console.log('data : ', data);
            if (data != '') {
                const imageUrl = `${process.env.REACT_APP_API_SERVER}/img?file=${encodeURIComponent(data)}`;
                // console.log(imageUrl);
                setEditContent({
                    ...editContent, popupImg: imageUrl
                });
            }
        }
    };

    const handlePushChange = (index, popupNo) => {
        if(pushChecked === true) {
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html : 
                `
                    <div>
                        <div class='${admin.admin_seller_add_title}'><span>배너를 숨기겠습니까?</span></div>
                        <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                        <button class='mx-1' id='seller_add_cancel'>취소</button>
                        <button class='mx-1' id='seller_add_success'>숨김</button>
                        </div>
                    </div>
                `,
                showConfirmButton: false,
                showCancelButton: false,
            });
            document.addEventListener('click', sellerAddSuccess);
            document.addEventListener('click', sellerAddCancel);
            
            function sellerAddSuccess(e) {
                if(e.target && e.target.id == 'seller_add_success') {
                    document.removeEventListener('click', sellerAddSuccess);
                    document.removeEventListener('click', sellerAddCancel);
                    MySwal.close();
                    setPushChecked(!pushChecked);
                    pushExposureChange(false, popupNo);
                }
            }
            
            function sellerAddCancel(e) {
                if(e.target && e.target.id == 'seller_add_cancel') {
                    MySwal.close();
                    document.removeEventListener('click', sellerAddSuccess);
                    document.removeEventListener('click', sellerAddCancel);
                }
            }
        } else {
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html : 
                `
                    <div>
                        <div class='${admin.admin_seller_add_title}'><span>배너를 노출 하시겠습니까?</span></div>
                        <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                        <button class='mx-1' id='seller_add_cancel'>취소</button>
                        <button class='mx-1' id='seller_add_success'>노출</button>
                        </div>
                    </div>
                `,
                showConfirmButton: false,
                showCancelButton: false,
            });
            document.addEventListener('click', sellerAddSuccess);
            document.addEventListener('click', sellerAddCancel);
            
            function sellerAddSuccess(e) {
                if(e.target && e.target.id == 'seller_add_success') {
                    document.removeEventListener('click', sellerAddSuccess);
                    document.removeEventListener('click', sellerAddCancel);
                    MySwal.close();
                    setPushChecked(!pushChecked);
                    pushExposureChange(true, popupNo);
                }
            }
            
            function sellerAddCancel(e) {
                if(e.target && e.target.id == 'seller_add_cancel') {
                    MySwal.close();
                    document.removeEventListener('click', sellerAddSuccess);
                    document.removeEventListener('click', sellerAddCancel);
                }
            }
        }
    };

    const pushExposureChange = async (pushState, popupNo) => {
        let newItem = {
            isBoolean : pushState,
            popupNo : popupNo
        };

        console.log('newItem : ', newItem);

        const response = await adminApi.pushExposureChange(newItem);
        const data = response.data;

        if(data === 'SUCCESS') {
            popupSelect();
        }
    }


    const handleExposureChange = () => {
        setExposureChecked(!exposureChecked);
        setContent({
            ...content, popupExposure: !exposureChecked
        });
    };

    const handleEditExposureChange = () => {
        setEditExposureChecked(!editExposureChecked);
        setEditContent({
            ...editContent, popupExposure: !editExposureChecked
        });
    };

    const onLinked = (e) => {
        // console.log(e.target.id);
        const isLinkTrue = e.target.id === 'link_true';
        setLinkState(e.target.id);
        setContent({
            ...content, popupLink: isLinkTrue
        });
    };

    const onEditLinked = (e) => {
        const isLinkTrue = e.target.id === 'link_true';
        setEditLinkState(e.target.id);
        setEditContent({
            ...editContent, popupLink: isLinkTrue
        });
    };

    const onType = (e) => {
        // console.log(e.target.id);
        setTypeState(e.target.id);
        setContent({
            ...content, popupType: e.target.id
        });
    };

    const onEditType = (e) => {
        setEditTypeState(e.target.id);
        setEditContent({
            ...editContent, popupType: e.target.id
        });
    };

    const onLink = (e) => {
        setContent({
            ...content, popupTypeLink: e.target.value
        });
    };

    const onEditLink = (e) => {
        setEditContent({
            ...editContent, popupTypeLink: e.target.value
        });
    };

    const onPopupAdd = async () => {
        const response = await adminApi.popupAdd(content);
        const data = response.data;

        // console.log('data : ', data);

        if(data === 'SUCCESS') {
            alert('팝업이 등록되었습니다.');
            setContent({});
            setShowMainPopup(false);
            setPreviewImage("../../img/admin/defaultImage.png");
            setLinkState('');
            setTypeState('');
            popupSelect();
        } else {
            alert('배너 등록에 실패했습니다.');
        }
    };

    const onPopupUpdate = async () => {
        const response = await adminApi.popupUpdate(editContent);
        const data = response.data;

        // console.log('data : ', data);

        if(data === 'SUCCESS') {
            alert('팝업이 수정되었습니다.');
            setEditContent({});
            setShowMainPopupUpdate(false);
            setEditPreviewImage("../../img/admin/defaultImage.png");
            setEditLinkState('');
            setEditTypeState('');
            popupSelect();
        } else {
            alert('배너 수정에 실패했습니다.');
        }
    };

    const handleViewDetails = (item) => {
        setEditContent(item);
        setEditPreviewImage(item.popupImg);
        setEditLinkState(item.popupLink ? 'link_true' : 'link_false');
        setEditTypeState(item.popupType);
        setEditExposureChecked(item.popupExposure);
        setShowMainPopupUpdate(true);
    };

    const handleSelectAll = (e) => {
        const { checked } = e.target;
        if (checked) {
            const allPopupNos = popup.map((item) => item.popupNo);
            setCheckedPopup(allPopupNos);
        } else {
            setCheckedPopup([]);
        }
    };

    const handleSelect = (e, popupNo) => {
        const { checked } = e.target;
        if (checked) {
            setCheckedPopup([...checkedPopup, popupNo]);
        } else {
            setCheckedPopup(checkedPopup.filter((no) => no !== popupNo));
        }
    };

    const handleDelete = async () => {
        console.log('삭제할 팝업 번호:', checkedPopup);

        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html: `
                  <div>
                      <div class='${admin.admin_seller_add_title}'><span>선택된 팝업을 삭제 하시겠습니까?</span></div>
                      <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                        <button class='mx-1' id='seller_add_cancel'>취소</button>
                        <button class='mx-1' id='seller_add_success'>삭제</button>
                      </div>
                  </div>
              `,
            showConfirmButton: false,
            showCancelButton: false,
        });
        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);

        async function sellerAddSuccess(e) {
            if (e.target && e.target.id === 'seller_add_success') {
                try {
                    const response = await adminApi.popupDelete(checkedPopup);
                    const data = response.data;

                    if (data === 'SUCCESS') {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                              <div>
                                <div class='${admin.admin_seller_add_title}'><span>선택된 팝업이 삭제되었습니다.</span></div>
                                <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                    <button class='mx-1' id='seller_add_confirm'>확인</button>
                                </div>
                              </div>
                              `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);

                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                popupSelect();
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    } else {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                              <div>
                                <div class='${admin.admin_seller_add_title}'><span>팝업 삭제에 실패하였습니다.</span></div>
                                <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                    <button class='mx-1' id='seller_add_confirm'>확인</button>
                                </div>
                              </div>
                              `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);

                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    }
                } catch (error) {
                    console.error('error : ', error);
                }
            }
        }

        function sellerAddCancel(e) {
            if (e.target && e.target.id === 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
            }
        }
    };

    useEffect(() => {
        // console.log('content : ', content);
    }, [content]);

    useEffect(() => {
        // console.log('editContent : ', editContent);
    }, [editContent]);

    const handleLocateChange = (value) => {
        // console.log(value);
        setContent({
            ...content, popupExposureLocate: value
        });
    };

    const handleEditLocateChange = (value) => {
        setEditContent({
            ...editContent, popupExposureLocate: value
        });
    };

    const handleDirectionChange = (value) => {
        // console.log(value);
        setContent({
            ...content, popupExposureDirection: value
        });
    };

    const handleEditDirectionChange = (value) => {
        setEditContent({
            ...editContent, popupExposureDirection: value
        });
    };

    const formatDate = (date) => {
        return format(new Date(date), 'yyyy-MM-dd HH:mm:ss');
    };

    return (
        <div className={`${admin.admin_operate_banner_box} px-3 py-3`}>
            <div>
                <div>
                    <div className={`${admin.admin_operate_banner_title}`}><span>팝업</span></div>
                </div>
                <div>
                    <div>
                        <div className='d-flex justify-content-between w-100 px-3 py-2 border-top border-bottom align-items-center'>
                            <div><span className={`${admin.admin_font_date_font}`}>전체 {popup.length}</span></div>
                            <div>
                                <button className={`${admin.admin_operate_banner_btn_style}`} onClick={() => {setShowMainPopup(true); setContent({...content, popupExposureLocate : 'home', popupExposureDirection: 'bottom'});}}>팝업등록</button>
                                {checkedPopup.length > 0 && <button className={`${admin.admin_operate_banner_btn_style}`} onClick={handleDelete}>삭제</button>}
                            </div>
                        </div>
                        <div className={`${admin.admin_operate_popup_content_box}`}>
                            <div>
                                <div className={`${admin.admin_operate_banner_content_header}`}>
                                    <div><input type="checkbox" onChange={handleSelectAll} /></div>
                                    <div><span>팝업명</span></div>
                                    <div><span>팝업이미지</span></div>
                                    <div><span>기간</span></div>
                                    <div><span>노출여부</span></div>
                                    <div><span>등록일자</span></div>
                                    <div><span>노출위치</span></div>
                                    <div><span>관리</span></div>
                                </div>
                                {
                                    popup.map((item, index) => (
                                        <div className={`${admin.admin_operate_banner_content_content}`} key={index}>
                                            <div><input type="checkbox" checked={checkedPopup.includes(item.popupNo)} onChange={(e) => handleSelect(e, item.popupNo)} /></div>
                                            <div><span>{item.popupTitle}</span></div>
                                            <div><img src={item.popupImg} alt="배너이미지" /></div>
                                            <div><div><span>{item.popupStartDate}</span></div>
                                                <div><span>~</span></div>
                                                <div><span>{item.popupEndDate}</span></div></div>
                                            <div>
                                                <label className={`${admin.admin_active_switch}`}>
                                                    <input type="checkbox" checked={item.popupExposure} onChange={() => handlePushChange(index, item.popupNo)} />
                                                    <span className={`${admin.admin_active_slider} ${admin.admin_active_round}`}></span>
                                                </label>
                                            </div>
                                            <div>
                                                <span>{formatDate(item.popupRegDate)}</span>
                                            </div>
                                            <div><span>{item.popupExposureLocate}/{item.popupExposureDirection}</span></div>
                                            <div><button onClick={() => handleViewDetails(item)}>상세보기</button></div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <Pagination current={currentPage} total={totalItems} onChange={paginate} pageSize={itemsPerPage} />
                    </div>
                </div>
            </div>
            {
                showMainPopup &&
                <div className={`${admin.admin_operate_banner_main_banner_container}`} onClick={() => onMainPopupClose()}>
                    <div className={`${admin.admin_operate_banner_main_banner_box}`} onClick={(e) => e.stopPropagation()}>
                        <div className={`${admin.admin_operate_banner_main_banner_title} py-3 px-3`}>
                            <div><span>팝업 등록</span></div>
                            <div onClick={() => onMainPopupClose()}><span className="material-symbols-outlined">close</span></div>
                        </div>
                        <div className={`${admin.admin_operate_banner_main_banner_content}`}>
                            <div>
                                <div className='px-3'>
                                    <div className={`${admin.admin_operate_banner_main_banner_name}`}>
                                        <div><span>팝업명</span></div>
                                        <div><input type="text" value={content.popupTitle ? content.popupTitle : ''} onChange={(e) => onPopupName(e)} /></div>
                                    </div>
                                    <div className={`${admin.admin_operate_banner_main_banner_img}`}>
                                        <div><span>팝업이미지</span></div>
                                        <div>
                                            <div>
                                                <img src={previewImage} alt="Preview" />
                                            </div>
                                        </div>
                                        <div className='w-100 text-end my-2'>
                                            <input
                                                type="file"
                                                accept="image/*"
                                                onChange={handleImageUpload}
                                                ref={fileInputRef}
                                                style={{ display: 'none' }}
                                                id="imageUpload"
                                            />
                                            <label htmlFor="imageUpload">
                                                <button onClick={handleImageClick}>이미지추가</button>
                                            </label>
                                        </div>
                                    </div>
                                    <div className={`${admin.admin_operate_banner_main_popup_date}`}>
                                        <div><span>기간</span></div>
                                        <div ref={ref}>
                                            <RangePicker className={`${admin.admin_popup_datepicker}`}
                                                separator="~"
                                                placeholder={['0000.00.00', '0000.00.00']}
                                                showTime // 시간 선택 활성화
                                                format="YYYY.MM.DD HH:mm:ss" // 날짜와 시간 포맷 지정
                                                onChange={handleDateChange}
                                            />
                                        </div>
                                    </div>
                                    <div className={`${admin.admin_operate_banner_main_banner_exposure}`}>
                                        <div><span>노출여부</span></div>
                                        <div>
                                            <label className={`${admin.admin_active_switch}`}>
                                                <input type="checkbox" checked={exposureChecked} onChange={handleExposureChange} />
                                                <span className={`${admin.admin_active_slider} ${admin.admin_active_round}`}></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className={`${admin.admin_operate_popup_exposure_locate}`}>
                                        <div><span>노출위치</span></div>
                                        <div>
                                            <Select 
                                                className={`${admin.admin_operate_popup_select}`}
                                                defaultValue='home' 
                                                onChange={(value) => handleLocateChange(value)}
                                            >
                                                <Option value='home'>홈</Option>
                                                <Option value='store'>스토어</Option>
                                                <Option value='support'>서포트</Option>
                                            </Select>
                                        </div>
                                    </div>
                                    <div className={`${admin.admin_operate_popup_exposure_direction}`}>
                                        <div><span>노출방향</span></div>
                                        <div>
                                            <Select 
                                                className={`${admin.admin_operate_popup_select}`}
                                                defaultValue='bottom'
                                                onChange={(value) => handleDirectionChange(value)}
                                            >
                                                <Option value='top'>위</Option>
                                                <Option value='middle'>중간</Option>
                                                <Option value='bottom'>아래</Option>
                                            </Select>
                                        </div>
                                    </div>
                                    <div className={`${admin.admin_operate_banner_main_banner_link}`}>
                                        <div><span>링크 여부</span></div>
                                        <div>
                                            <div>
                                                <input type="radio" name="link" id="link_true" onChange={(e) => onLinked(e)} />
                                                <label className='ps-1' htmlFor="link_true">사용</label>
                                            </div>
                                            <div className='ps-4'>
                                                <input type="radio" name="link" id="link_false" onChange={(e) => onLinked(e)} />
                                                <label className='ps-1' htmlFor="link_false">사용안함</label>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        linkState === 'link_true' &&
                                        <div className={`${admin.admin_operate_banner_main_banner_type}`}>
                                            <div><span>종류</span></div>
                                            <div>
                                                <div>
                                                    <input type="radio" name="type" id="event" onChange={(e) => onType(e)} />
                                                    <label className='ps-1' htmlFor="event">이벤트</label>
                                                </div>
                                                <div className='ps-4'>
                                                    <input type="radio" name="type" id="product" onChange={(e) => onType(e)} />
                                                    <label className='ps-1' htmlFor="product">상품</label>
                                                </div>
                                                <div className='ps-4'>
                                                    <input type="radio" name="type" id="linked" onChange={(e) => onType(e)} />
                                                    <label className='ps-1' htmlFor="linked">링크</label>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        linkState === 'link_true' && typeState === 'linked' &&
                                        <div className={`${admin.admin_operate_banner_main_banner_name}`}>
                                            <div><span>링크</span></div>
                                            <div><input type="text" placeholder='ex) /store/인형' onChange={(e) => onLink(e)} /></div>
                                        </div>
                                    }
                                    {
                                        linkState === 'link_true' && typeState === 'product' &&
                                        <div className={`${admin.admin_operate_banner_main_banner_select_box}`}>
                                            <div>
                                                <div><span>상품</span></div>
                                                <div><button>상품선택</button></div>
                                            </div>
                                            <div></div>
                                        </div>
                                    }
                                    {
                                        linkState === 'link_true' && typeState === 'event' &&
                                        <div className={`${admin.admin_operate_banner_main_banner_select_box}`}>
                                            <div>
                                                <div><span>이벤트</span></div>
                                                <div><button>이벤트선택</button></div>
                                            </div>
                                            <div></div>
                                        </div>
                                    }
                                    <div className={`${admin.admin_operate_banner_main_banner_add_btn}`}><button onClick={onPopupAdd}>팝업 등록</button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                showMainPopupUpdate &&
                <div className={`${admin.admin_operate_banner_main_banner_container}`} onClick={() => onMainPopupUpdateClose()}>
                    <div className={`${admin.admin_operate_banner_main_banner_box}`} onClick={(e) => e.stopPropagation()}>
                        <div className={`${admin.admin_operate_banner_main_banner_title} py-3 px-3`}>
                            <div><span>팝업 수정</span></div>
                            <div onClick={() => onMainPopupUpdateClose()}><span className="material-symbols-outlined">close</span></div>
                        </div>
                        <div className={`${admin.admin_operate_banner_main_banner_content}`}>
                            <div>
                                <div className='px-3'>
                                    <div className={`${admin.admin_operate_banner_main_banner_name}`}>
                                        <div><span>팝업명</span></div>
                                        <div><input type="text" value={editContent.popupTitle ? editContent.popupTitle : ''} onChange={(e) => onEditPopupName(e)} /></div>
                                    </div>
                                    <div className={`${admin.admin_operate_banner_main_banner_img}`}>
                                        <div><span>팝업이미지</span></div>
                                        <div>
                                            <div>
                                                <img src={editPreviewImage} alt="Preview" />
                                            </div>
                                        </div>
                                        <div className='w-100 text-end my-2'>
                                            <input
                                                type="file"
                                                accept="image/*"
                                                onChange={handleEditImageUpload}
                                                ref={fileInputRef}
                                                style={{ display: 'none' }}
                                                id="editImageUpload"
                                            />
                                            <label htmlFor="editImageUpload">
                                                <button onClick={handleEditImageClick}>이미지추가</button>
                                            </label>
                                        </div>
                                    </div>
                                    <div className={`${admin.admin_operate_banner_main_popup_date}`}>
                                        <div><span>기간</span></div>
                                        <div ref={ref}>
                                            <RangePicker className={`${admin.admin_popup_datepicker}`}
                                                separator="~"
                                                placeholder={['0000.00.00', '0000.00.00']}
                                                showTime // 시간 선택 활성화
                                                format="YYYY.MM.DD HH:mm:ss" // 날짜와 시간 포맷 지정
                                                onChange={handleEditDateChange}
                                            />
                                        </div>
                                    </div>
                                    <div className={`${admin.admin_operate_banner_main_banner_exposure}`}>
                                        <div><span>노출여부</span></div>
                                        <div>
                                            <label className={`${admin.admin_active_switch}`}>
                                                <input type="checkbox" checked={editExposureChecked} onChange={handleEditExposureChange} />
                                                <span className={`${admin.admin_active_slider} ${admin.admin_active_round}`}></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className={`${admin.admin_operate_popup_exposure_locate}`}>
                                        <div><span>노출위치</span></div>
                                        <div>
                                            <Select 
                                                className={`${admin.admin_operate_popup_select}`}
                                                value={editContent.popupExposureLocate} 
                                                onChange={(value) => handleEditLocateChange(value)}
                                            >
                                                <Option value='home'>홈</Option>
                                                <Option value='store'>스토어</Option>
                                                <Option value='support'>서포트</Option>
                                            </Select>
                                        </div>
                                    </div>
                                    <div className={`${admin.admin_operate_popup_exposure_direction}`}>
                                        <div><span>노출방향</span></div>
                                        <div>
                                            <Select 
                                                className={`${admin.admin_operate_popup_select}`}
                                                value={editContent.popupExposureDirection}
                                                onChange={(value) => handleEditDirectionChange(value)}
                                            >
                                                <Option value='top'>위</Option>
                                                <Option value='middle'>중간</Option>
                                                <Option value='bottom'>아래</Option>
                                            </Select>
                                        </div>
                                    </div>
                                    <div className={`${admin.admin_operate_banner_main_banner_link}`}>
                                        <div><span>링크 여부</span></div>
                                        <div>
                                            <div>
                                                <input type="radio" name="editLink" id="link_true" checked={editLinkState === 'link_true'} onChange={(e) => onEditLinked(e)} />
                                                <label className='ps-1' htmlFor="link_true">사용</label>
                                            </div>
                                            <div className='ps-4'>
                                                <input type="radio" name="editLink" id="link_false" checked={editLinkState === 'link_false'} onChange={(e) => onEditLinked(e)} />
                                                <label className='ps-1' htmlFor="link_false">사용안함</label>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        editLinkState === 'link_true' &&
                                        <div className={`${admin.admin_operate_banner_main_banner_type}`}>
                                            <div><span>종류</span></div>
                                            <div>
                                                <div>
                                                    <input type="radio" name="editType" id="event" checked={editTypeState === 'event'} onChange={(e) => onEditType(e)} />
                                                    <label className='ps-1' htmlFor="event">이벤트</label>
                                                </div>
                                                <div className='ps-4'>
                                                    <input type="radio" name="editType" id="product" checked={editTypeState === 'product'} onChange={(e) => onEditType(e)} />
                                                    <label className='ps-1' htmlFor="product">상품</label>
                                                </div>
                                                <div className='ps-4'>
                                                    <input type="radio" name="editType" id="linked" checked={editTypeState === 'linked'} onChange={(e) => onEditType(e)} />
                                                    <label className='ps-1' htmlFor="linked">링크</label>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        editLinkState === 'link_true' && editTypeState === 'linked' &&
                                        <div className={`${admin.admin_operate_banner_main_banner_name}`}>
                                            <div><span>링크</span></div>
                                            <div><input type="text" placeholder='ex) /store/인형' value={editContent.popupTypeLink || ''} onChange={(e) => onEditLink(e)} /></div>
                                        </div>
                                    }
                                    {
                                        editLinkState === 'link_true' && editTypeState === 'product' &&
                                        <div className={`${admin.admin_operate_banner_main_banner_select_box}`}>
                                            <div>
                                                <div><span>상품</span></div>
                                                <div><button>상품선택</button></div>
                                            </div>
                                            <div></div>
                                        </div>
                                    }
                                    {
                                        editLinkState === 'link_true' && editTypeState === 'event' &&
                                        <div className={`${admin.admin_operate_banner_main_banner_select_box}`}>
                                            <div>
                                                <div><span>이벤트</span></div>
                                                <div><button>이벤트선택</button></div>
                                            </div>
                                            <div></div>
                                        </div>
                                    }
                                    <div className={`${admin.admin_operate_banner_main_banner_add_btn}`}><button onClick={onPopupUpdate}>팝업 수정</button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default AdminPopup;
