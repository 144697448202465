import React from 'react';
import { format } from 'date-fns';

const SellerReviewModal = ( {seller, setShowReviewModal, onReviewAnswer, reviewAnswerCheck, handleReviewWrite, selectReservation, selectReservationReview, selectSupport}) => {
    const storeReviewBack = () => {
        setShowReviewModal(false);
    }
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return format(date, 'yyyy-MM-dd');
    }
  return (
    <div className={`${seller.seller_review_modal_container}`}>
        <div className={`${seller.seller_review_modal}`}>
            <div className='d-flex justify-content-center align-items-center position-relative py-4'>
                <div><span>리뷰 상세</span></div>
                <div className='position-absolute end-0 pe-4'><span className={`material-symbols-outlined ${seller.seller_store_review_close}`} onClick={storeReviewBack}>close</span></div>
            </div>
            <div className='px-5'>
                <div className='d-flex align-items-center border-bottom py-3 w-100'>
                    <div className={`${seller.seller_store_review_left}`}><span>상품명</span></div>
                    <div className={`${seller.seller_store_review_right} d-flex align-items-center`}>
                        <div className={`${seller.seller_store_review_product_imgbox}`}><img src={selectSupport.representativeImage} alt="" /></div>
                        <div className='ps-3'><span>{selectSupport?.productName}</span></div>
                    </div>
                </div>
                <div className='d-flex align-items-center border-bottom py-3 w-100 justify-content-between'>
                    <div className='d-flex align-items-center py-3 w-100'>
                        <div className={`${seller.seller_store_review_left}`}><span>작성자</span></div>
                        <div className={`${seller.seller_store_review_right}`}><span>{selectReservationReview.userId}</span></div>
                    </div>
                    <div className='d-flex align-items-center py-3 w-100 border-start'>
                        <div className={`${seller.seller_store_review_left} ps-3`}><span>작성일</span></div>
                        <div className={`${seller.seller_store_review_right}`}><span>{formatDate(selectReservationReview.reviewRegDate)}</span></div>
                    </div>
                </div>
                <div className='d-flex align-items-center border-bottom py-3 w-100'>
                <div className={`${seller.seller_store_review_left}`}><span>별점</span></div>
                <div className={`${seller.seller_store_review_right}`}><span>2023.01.12</span></div>
                </div>
                <div className='d-flex align-items-center border-bottom py-3 w-100'>
                    <div className={`${seller.seller_store_review_left}`}><span>리뷰 사진</span></div>
                    <div className={`${seller.seller_store_review_right} d-flex justify-content-between`}>
                        {
                            selectReservationReview.fileList != null ?
                            selectReservationReview.fileList.map((item, index) => (
                                <div className={`${seller.seller_store_review_img}`}><img src={item} alt="리뷰이미지" /></div>
                            ))
                            :
                            <div>
                                <span>등록된 이미지가 없습니다.</span>
                            </div>
                        }
                        {/* <div className={`${seller.seller_store_review_img}`}><img src={selectReservationReview?.photo2} alt="리뷰이미지" /></div>
                        <div className={`${seller.seller_store_review_img}`}><img src={selectReservationReview?.photo3} alt="리뷰이미지" /></div>
                        <div className={`${seller.seller_store_review_img}`}><img src={selectReservationReview?.photo4} alt="리뷰이미지" /></div> */}
                    </div>
                </div>
                <div className='d-flex align-items-center border-bottom py-3 w-100'>
                    <div className={`${seller.seller_store_review_left}`}><span>리뷰 내용</span></div>
                    <div className={`${seller.seller_store_review_right} dd-fs-7`}><span>{selectReservationReview.reviewText}</span></div>
                </div>
                <div className='d-flex align-items-center border-bottom py-3 w-100'>
                    <div className={`${seller.seller_store_review_left}`}><span>답변 달기</span></div>
                    <div className={`${seller.seller_store_review_right}`}><textarea name="" id="" cols="30" rows="10" onChange={(e)=> onReviewAnswer(e)} placeholder={selectReservationReview.supportReceiveReview}></textarea></div>
                </div>
                <div className={`w-100 d-flex align-items-center justify-content-center ${seller.seller_store_review_btn} pt-4`}>
                    <button className={`${!reviewAnswerCheck ? seller.seller_store_review_active : ''}`} disabled={reviewAnswerCheck} onClick={() => handleReviewWrite(selectReservationReview.supportReviewNo, selectReservationReview.supportReceiveReview)}>완료</button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default SellerReviewModal