import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import * as auth from '../../apis/auth';
import { LoginContext } from '../../contexts/LoginContextProvider';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import admin from '../../admin/admin.module.css';

const KakaoLogin = () => {
  
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();
  const location = useLocation();

  const { isLogin, login, logout, userInfo, remove, loginCheck } = useContext(LoginContext);
  const [kakao, setKakao] = useState({});
  const [kakaoExist, setKakaoExist] = useState(false);

  const [ joinObject, setJoinObject ] = useState({});

  useEffect(() => {
    const handleKakaoLogin = async () => {
      const params = new URLSearchParams(location.search);
      const code = params.get('code');

      // console.log('params : ', params);
      // console.log('code : ', code);

      if (code) {
        try {
          const response = await axios.get(`/api/auth/kakao/callback?code=${code}`);
          const userInfo = response.data; // 변수 이름 변경
          // 서버에서 받아온 사용자 정보 처리
          // console.log('Kakao user info:', userInfo);
          // console.log('response:', response);
          // console.log('id:', userInfo.id);
          // 사용자 정보에 따라 필요한 로직 수행 (예: 로그인 처리, 회원가입 등)

          if (response.status === 200) {
              // // console.log('접근');
              const userSearchResponse = await auth.userSearch(userInfo.id);
              const userSearchData = userSearchResponse.data;
              
              if(userSearchData === 'exist') {
                const userSelectResponse = await auth.kakaoUserSelect(userInfo.id);
                const userSlectData = userSelectResponse.data;

                // console.log('userSlectData : ', userSlectData);

                // 로그인 처리를 위한 토큰 요청
                const tokenResponse = await auth.getToken({ userId: userSlectData.userId });
                const tokenData = tokenResponse.data;
                // console.log('tokenData : ', tokenData);
                if (tokenResponse.status === 200) {
                    const token = tokenResponse.data.token;
                    // 쿠키에 토큰 저장
                    Cookies.set("accessToken", token);
                    localStorage.setItem("accessToken", token);
                    // 로그인 체크 함수 호출
                    await loginCheck();
                    navigate('/market');
                }
              } else {
                  setKakaoExist(true);
                  setKakao(userInfo);

                          // phoneNumber 분리
                  const phoneNumber = userInfo.phoneNumber; // +82 10-1111-1111 형식
                  const [regionCode, localNumber] = phoneNumber.split(' ');

                  // 지역 코드와 현지 번호를 처리
                  const region = regionCode; // +82
                  const phone = `0${localNumber.replace(/-/g, '')}`; // 10-1111-1111 -> 01011111111

                  // joinObject에 넣을 새로운 객체 구성
                  const newJoinObject = {
                    userProfile : userInfo.profileImage,
                    userName: userInfo.nickname,
                    userId: userInfo.id,
                    // userEmail: userList.userEmail,
                    // userPw: userList.userPw,
                    // userRepublic: userList.userRepublic,
                    userTel: phone,
                    // userNick: userList.userNick,
                    userGender: userInfo.gender,
                    // userBirth: birth, // 재구성한 birth를 새로운 속성으로 추가
                    // recode: inputValue,
                    kakao: userInfo.id
                };

                
                // setJoinObject를 사용하여 joinObject 상태 업데이트
                // console.log(newJoinObject);
                setJoinObject(newJoinObject);

            }
            // console.log(userSearchData);
          }

        } catch (error) {
          console.error('Failed to handle Kakao login', error);
        }
      }
    };

    handleKakaoLogin();
  }, [location, navigate]);

  const join = async () => {
    navigate('/kakao/join', {state : {kakao : kakao}});
    // const response = await auth.kakaoJoin(joinObject);
    //   const data = await response.data;

    //   if(data === 'SUCCESS') {
    //     MySwal.fire({
    //       customClass: {
    //           popup: `${admin.admin_seller_add_popup}`
    //       },
    //       html : 
    //       `
    //       <div>
    //       <div class='${admin.admin_seller_add_title}'><span>회원가입이 완료 되었습니다.</span></div>
    //       <div class='${admin.admin_seller_add_sub_title}'><span></span></div>
    //       <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
    //       <button class='mx-1' id='seller_add_confirm'>확인</button>
    //       </div>
    //       </div>
    //       `,
    //       showConfirmButton: false,
    //       showCancelButton: false,
    //   });
    //   document.addEventListener('click', sellerAddConfirm);
      
    //   function sellerAddConfirm(e) {
    //       if(e.target && e.target.id == 'seller_add_confirm') {
    //           MySwal.close();
    //           // success('/market', { state: {key: 'test2'}});
    //           // login( joinObject.userId, joinObject.userPw );
    //           checkUserExists(joinObject);
    //           document.removeEventListener('click', sellerAddConfirm);
    //       }
    //   }

        
    //   } else {
    //     alert(`Server Error ${data}`);
    //   }
  }

  const checkUserExists = async (userInfo) => {
    const response = await auth.userSearch(userInfo.userId);
    const data = response.data;

    // console.log('user : ', data);
    // console.log('status : ', response.status);
    // console.log('userInfo.id : ', userInfo.userId);

    try {
      if (response.status === 200) {
        if (data === 'exist') {
          const userSelectResponse = await auth.kakaoUserSelect(userInfo.userId);
          const userSlectData = userSelectResponse.data;

          // console.log('userSlectData : ', userSlectData);

          // 로그인 처리를 위한 토큰 요청
          const tokenResponse = await auth.getToken({ userId: userSlectData.kakao });
          const tokenData = tokenResponse.data;
          // console.log('tokenData : ', tokenData);
          if (tokenResponse.status === 200) {
            const token = tokenResponse.data.token;
            // 쿠키에 토큰 저장
            Cookies.set("accessToken", token);
            // 로그인 체크 함수 호출
            await loginCheck();
            navigate('/market');
          }
        } else {
          setKakaoExist(false);
        }
      }
    } catch (error) {
      console.error('error : ', error);
    }
  };

  return (
    <div className='kakao_login_container'>
        {
            kakaoExist ? 
            <div className='w-100'>
                <div className='kakao_join_check_box'>
                    <div>
                        <div><img src={kakao.profileImage} alt="프로필이미지" /></div>
                        <div className='pt-2'><span>{kakao.nickname}</span></div>
                        <div><span>{kakao.email}</span></div>
                        <div><span>{kakao.phoneNumber}</span></div>
                    </div>
                    <div className='kakao_join_check_btn_box'>
                        <div><button onClick={() => join()}>데일리덕 회원가입하기</button></div>
                    </div>
                </div>
                <div className='kakao_join_dailyduck_logo'><img src="../../img/logo_pink2.png" alt="데일리덕" /></div>
            </div>
            :
            <div className='kakao_join_check_box'>
                <span>카카오 로그인 처리 중...</span>
            </div>
        }
    </div>
  );
};

export default KakaoLogin;
