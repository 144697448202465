import React, { useState } from 'react'
import { animated } from 'react-spring';
import { useSpring, useTransition } from '@react-spring/core';
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { FreeMode, Pagination } from 'swiper/modules';
import dailyduck from './MyContainner.module.css'

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import { useNavigate } from 'react-router-dom';

const ActiviryDetail = ( {} ) => {

    const navigatge = useNavigate();
    const [ activeButton, setActiveButton ] = useState('event');
    const [ activeCategory, setActiveCategory ] = useState('전체');

    const [ eventList, setEventList ] = useState([
        {eventNo: 1, eventName: '투표 이벤트 제목', eventImg: '../img/support/venue1.png', eventDate: '2023.05.02', eventType: '투표'},
        {eventNo: 2, eventName: '투표 이벤트 제목', eventImg: '../img/support/venue1.png', eventDate: '2023.05.02', eventType: '투표'},
        {eventNo: 3, eventName: '입력폼 이벤트 제목', eventImg: '../img/support/venue1.png', eventDate: '2023.05.05', eventType: '입력폼'},
        {eventNo: 4, eventName: '기획전 이벤트 제목', eventImg: '../img/support/venue1.png', eventDate: '2023.05.05', eventType: '기획전'},
        {eventNo: 5, eventName: '래플 이벤트 제목', eventImg: '../img/support/venue1.png', eventDate: '2023.05.11', eventType: '래플'},
        {eventNo: 6, eventName: '투표 이벤트 제목', eventImg: '../img/support/venue1.png', eventDate: '2023.05.11', eventType: '투표'},
        {eventNo: 7, eventName: '투표 이벤트 제목', eventImg: '../img/support/venue1.png', eventDate: '2023.05.13', eventType: '투표'}
    ]);

    // 날짜별로 이벤트 그룹화
    const groupedEvents = eventList.reduce((acc, event) => {
        (acc[event.eventDate] = acc[event.eventDate] || []).push(event);
        return acc;
    }, {});

    const filteredEvents = activeCategory === '전체' ? eventList : eventList.filter(event => event.eventType === activeCategory);

    // 날짜별로 필터링된 이벤트 그룹화
    const filteredGroupedEvents = filteredEvents.reduce((acc, event) => {
        (acc[event.eventDate] = acc[event.eventDate] || []).push(event);
        return acc;
    }, {});

    const handleButtonClick = (type) => {
        setActiveCategory(type);
    };

    const handleAdBack = () => {
        navigatge('/my');
    }
    return (
        <>
        {
            <div className={`${dailyduck.mycouponporint_container}`}>
                    <div className={dailyduck.mycouponporint_box}>
                        <div className={`${dailyduck.my_activiry_header}`}>
                            <div>
                                <button onClick={() => handleAdBack()}>
                                <span className="material-symbols-outlined">arrow_back_ios</span>
                                </button>
                            </div>
                            <div>
                                <span>횔동 내역</span>
                            </div>
                        </div>
                        <div className='bg-white'>
                            <div className={`${dailyduck.my_activity_detail_title}`}>
                                <div className={`px-1`}><button className={`${activeButton == 'event' ? '' : dailyduck.gray_color}`} onClick={() => setActiveButton('event')}>이벤트</button></div>
                                <div className={`px-1`}><span className={`${dailyduck.gray_color}`}> | </span></div>
                                <div className={`px-1`}><button className={`${activeButton == 'demand' ? '' : dailyduck.gray_color}`} onClick={() => setActiveButton('demand')}>수요조사</button></div>
                            </div>
                            {
                                activeButton == 'event' && 
                                <div className={`${dailyduck.my_activiry_swiper_box}`}>
                                    <Swiper
                                    slidesPerView={'auto'}
                                    spaceBetween={10}
                                    freeMode={true}
                                    className={`${dailyduck.my_activiry_swiper}`}
                                    loop={false} // 루프를 비활성화합니다.
                                    watchOverflow={true} // 슬라이드가 충분하지 않을 경우 스와이핑을 방지합니다.
                                    >
                                    {['전체', '투표', '래플', '기획전', '입력폼', '출석체크'].map((type) => (
                                        <SwiperSlide key={type} className={dailyduck.my_activity_detail_category}>
                                            <button
                                                className={type === activeCategory ? dailyduck.my_activity_active : ''}
                                                onClick={() => handleButtonClick(type)}
                                            >
                                                {type}
                                            </button>
                                        </SwiperSlide>
                                    ))}
                                    </Swiper>
                                </div>
                            }
                        </div>
                        {
                            activeButton == 'event' && 
                            <div className='mt-2'>
                                {Object.entries(filteredGroupedEvents).map(([date, events]) => (
                                    <div key={date} className='px-3 bg-white mb-2 pb-2'>
                                        <div className='d-flex pt-3 '>
                                            <div className='pe-1'><span>참여일</span></div>
                                            <div className={`ps-1 ${dailyduck.gray_color}`}><span>{date}</span></div>
                                        </div>
                                        {events.map(event => (
                                            <div key={event.eventNo} className={`${dailyduck.my_activity_detail_participation} my-3 py-3`}>
                                                <div className={`${dailyduck.my_activity_detail_participation_img} px-3 py-3`}><img src={event.eventImg} alt={event.eventName} /></div>
                                                <div className={`${dailyduck.my_activity_detail_participation_content} px-2`}>{event.eventName}</div>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        }
                        {
                            activeButton == 'demand' &&
                            <div className='mt-2 bg-white px-3'>
                                <div className='d-flex py-3'>
                                    <div><span>참여일</span></div>
                                    <div><span>2023.04.07</span></div>
                                </div>
                                <div className={`${dailyduck.my_activity_demand_container} py-3 px-2`}>
                                    <div className='pb-3'>
                                        <span>참여완료</span>
                                    </div>
                                    <div className={`d-flex ${dailyduck.my_activity_demand_box}`}>
                                        <div className={`${dailyduck.my_activity_demand_img}`}><img src="../img/support/venue_1.png" alt="수요조사 이미지" /></div>
                                        <div className={`${dailyduck.my_activity_demand_content}`}>
                                            <div className={`dd-fs-7 ${dailyduck.gray_color}`}><span>상점명</span></div>
                                            <div><span>상품명</span></div>
                                            <div className={`dd-fs-7 ${dailyduck.gray_color}`}><span>Black/S</span>, <span>1</span>개</div>
                                            <div><span>&#8361;15,000</span><span>원</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
            </div>
        }
        </>
    )
}

export default ActiviryDetail