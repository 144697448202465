import React from 'react';
import { toast, ToastContainer } from 'react-toastify';

const LoginSection = ({admin, adminLoginBtn, onId, onPw, stateCheck, onAdminPwChange, onAdminJoin, id, password, onAdminIdSearch}) => {
  return (
    <div className={`${admin.admin_login_box}`}>
        <div className='px-3'>
            <div className={`${admin.admin_login_img_box} py-5`}><img src="./img/admin/dailyduck_main_logo.png" alt="" /></div>
            <div className='px-5'>
                <div><span>관리자 아이디</span></div>
                <div className={`${admin.admin_login_input_box} pt-3`}><input type="text" value={id !== '' ? id : ''} placeholder='아이디를 입력해주세요' onChange={(e) => onId(e)} /></div>
            </div>
            <div className='px-5 pt-5'>
                <div><span>관리자 비밀번호</span></div>
                <div className={`${admin.admin_login_input_box} pt-3`}>
                    <input type="password" placeholder='비밀번호를 입력해주세요'
                        value={password !== '' ? password : ''}
                        onChange={(e) => onPw(e)} 
                        onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            adminLoginBtn();
                        }
                        }} 
                    />
                </div>
            </div>
            {
                stateCheck &&
                <div className='px-5 pt-1'><span className={`${admin.admin_login_error}`}>아이디 또는 비밀번호 입력이 잘못되었습니다. 다시 입력해주세요.</span></div>
            }
            <div className={`${admin.admin_login_pw_change_btnbox} px-5`}>
                <div><button onClick={() => onAdminJoin()}>회원가입</button></div>
                <div>
                    <div><button onClick={() => onAdminIdSearch()}>아이디 찾기</button></div>
                    <div><button onClick={() => onAdminPwChange()}>비밀번호 변경</button></div>
                </div>
            </div>
            <div className={`${admin.admin_login_btn_box}`}><button onClick={() => adminLoginBtn()}>로그인</button></div>
        </div>
        <ToastContainer
            position="top-right" // 알람 위치 지정
            autoClose={3000} // 자동 off 시간
            hideProgressBar={false} // 진행시간바 숨김
            closeOnClick // 클릭으로 알람 닫기
            rtl={false} // 알림 좌우 반전
            pauseOnFocusLoss // 화면을 벗어나면 알람 정지
            draggable // 드래그 가능
            pauseOnHover // 마우스를 올리면 알람 정지
            theme="light"
            // limit={1} // 알람 개수 제한
        />
    </div>
  )
}

export default LoginSection