import React, { useEffect, useState } from 'react';
import event from '../../containers/event/event.module.css';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { ToastContainer, toast } from 'react-toastify';

const EventVote = () => {
    
    const MySwal = withReactContent(Swal);
    const navigate = useNavigate();
    const { eventno } = useParams();
    const [isChecked, setIsChecked] = useState(false);
    const [selectedArtist, setSelectedArtist] = useState('');
    const [point, setPoint] = useState(0);

    const [ voteList, setVoteList ] = useState([
        {voteNo : 1, voteImg: '../../img/join/favority/1.png', artistName: '해린', artistGroup: '뉴진스', votes: 0},
        {voteNo : 2, voteImg: '../../img/join/favority/2.png', artistName: '제니', artistGroup: '블랙핑크', votes: 0},
        {voteNo : 3, voteImg: '../../img/join/favority/3.png', artistName: '사나', artistGroup: '트와이스', votes: 0},
        {voteNo : 4, voteImg: '../../img/join/favority/4.png', artistName: '마크', artistGroup: 'NCT', votes: 0}
    ]);


    const handleBack = () => {
        navigate(-1);
    }

    const handleDivClick = (artistName) => {
        setSelectedArtist(artistName);
        setIsChecked(true); // 라디오 버튼을 체크 상태로 변경
    };

    const changeEvent = (e) => {
        // // console.log(e.target.value);
    }

    const onVoteClick = () => {
        MySwal.fire({
            customClass: {
                popup: `${event.event_vote_popup}`
            },
            html : 
            `
                <div>
                    <div class='${event.event_vote_popup_title}'><span>투표하기</span></div>
                    <div class='${event.event_vote_popup_content}'><span>선택한 후보를 투표하시겠습니까?</span><br/><span>투표 참여시 n포인트가 차감됩니다.</span></div>
                    <div class='${event.event_vote_popup_btn_box} py-2 d-flex w-100 justify-content-between'>
                      <button class='mx-1' id='vote_cancel'>취소</button>
                      <button class='mx-1' id='vote_success'>확인</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,

        });

        document.addEventListener('click', voteSuccess);

        function voteSuccess(e) {
            if(e.target && e.target.id == 'vote_success') {
                if(point === 0) {
                    MySwal.fire({
                        customClass: {
                            popup: `${event.event_vote_popup}`
                        },
                        html : 
                        `
                            <div>
                                <div class='${event.event_vote_popup_title}'><span>포인트를 충전해주세요.</span></div>
                                <div class='${event.event_vote_popup_content}'><span>보유 포인트가 부족합니다.</span><br/><span>포인트를 충전하시겠습니까?</span></div>
                                <div class='${event.event_vote_popup_btn_box} py-2 d-flex w-100 justify-content-between'>
                                  <button class='mx-1' id='vote_point_cancel'>아니오</button>
                                  <button class='mx-1' id='vote_point_success'>예</button>
                                </div>
                            </div>
                        `,
                        showConfirmButton: false,
                        showCancelButton: false,
                    });

                    document.addEventListener('click', votePointSuccess);

                    function votePointSuccess(e) {
                        if(e.target && e.target.id == 'vote_point_success') {
                            navigate('/my/cpr');
                            MySwal.close();
                            document.removeEventListener('click', votePointSuccess);
                        }
                        if(e.target && e.target.id == 'vote_point_cancel') {
                            MySwal.close();
                            document.removeEventListener('click', votePointSuccess);
                        }
                    }
                } else {
                    toast.error('투표가 완료되었습니다.', {
                        position: "bottom-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    MySwal.close();
                }
                document.removeEventListener('click', voteSuccess);
            }
            if(e.target && e.target.id == 'vote_cancel') {
                MySwal.close();
                document.removeEventListener('click', voteSuccess);
            }
        }
    }

    return (
        <>
            <div className={`${event.event_vote_container}`}>
                <div className='pb-3 px-3 pt-4'>
                    <div><button className='border-0 bg-body' onClick={handleBack}><span className="material-symbols-outlined">arrow_back_ios</span></button></div>
                </div>
                <div className='px-3 pt-2 pb-2'>
                    <div className={`${event.event_vote_top_title}`}><span>투표 제목이 길어질 경우 두 줄로 작성됩니다.투표 제목이 길어질 경우 두 줄로 작성됩니다.</span></div>
                    <div className={`${event.event_vote_top_sub_title} pt-3`}><span>해당 투표 이벤트 내용에 대한 설명 내용입니다.</span></div>
                </div>
                <div>
                    {
                        Array.isArray(voteList) && voteList.map((item, index) => (
                            <div key={item.voteNo} className={`d-flex align-items-center mx-3 py-3 my-3 ${event.event_vote_content}`} onClick={() => handleDivClick(item.artistName)}>
                                <div className={`${event.event_vote_content_one}`}><span>{item.voteNo}</span></div>
                                <div className={`${event.event_vote_content_two}`}>
                                    <div>
                                        <img src={item.voteImg} alt={item.artistName} />
                                    </div>
                                </div>
                                <div className={`${event.event_vote_content_three}`}>
                                    <div><span>{item.artistName}</span></div>
                                    <div><span>({item.artistGroup})</span></div>
                                    <div><span>{item.votes}</span></div>
                                </div>
                                <div className={`${event.event_vote_content_four}`}>
                                <label htmlFor={`radio-${item.voteNo}`} className={`${event.styledLabel}`}>
                                    <input 
                                        type="radio"
                                        className={`${event.styledRadio}`}
                                        value={item.artistName} 
                                        name={'vote'} 
                                        id={`radio-${item.voteNo}`} 
                                        checked={selectedArtist === item.artistName}
                                        onChange={changeEvent}
                                    />
                                </label>
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className={`${event.event_vote_footer}`}>
                    <div className='py-3'><span>*투표 참여 시 n포인트가 차감됩니다.</span></div>
                    <div><button className={`${selectedArtist != '' ? event.vote_footer_btn_active : ''}`} disabled={selectedArtist == ''} onClick={onVoteClick}>투표하기</button></div>
                </div>
                <ToastContainer />
            </div>
        </>
      )
}

export default EventVote