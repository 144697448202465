import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import market from '../../containers/market/market.module.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay, FreeMode } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const MarketCategory = ({ storeCategory, supportCategory }) => {
  const [activeCategory, setActiveCategory] = useState('store');

  const handleClick = (category) => {
    setActiveCategory(category);
  };

  // 스토어와 서포트 카테고리를 그룹화하여 두 줄로 만들기
  const groupedCategories = [];
  for (let i = 0; i < Math.max(storeCategory.length, supportCategory.length); i++) {
    groupedCategories.push({
      store: storeCategory[i] || null,
      support: supportCategory[i] || null,
    });
  }

  return (
    <>
      <div className={`${market.market_mobile_category}`}>
        <div className='category_title d-flex justify-content-between'>
          <div
            className={`text-center w-100 ${activeCategory === 'store' ? 'category_active' : ''}`}
            onClick={() => handleClick('store')}
          >
            <button className={`${market.market_category_select_btn}`}>
              <span>스토어</span>
            </button>
          </div>
          <div
            className={`text-center w-100 ${activeCategory === 'support' ? 'category_active' : ''}`}
            onClick={() => handleClick('support')}
          >
            <button className={`${market.market_category_select_btn}`}>
              <span>서포트</span>
            </button>
          </div>
        </div>
        <div className={`row store ${activeCategory === 'store' ? '' : 'none'} py-3`}>
          {Array.isArray(storeCategory) && storeCategory.map((store) => (
            <div className='col-3' key={store.dailyduckStorecategoryNo}>
              <Link to={{ pathname: `/store/${store.dailyduckStorecategoryEngName}`, state: { activeCategory: activeCategory } }}>
                <div className='icon_box'>
                  <img src={`${process.env.REACT_APP_API_SERVER}/img?file=${store.dailyduckStorecategoryImg}`} alt={`${store.dailyduckStorecategoryName}`} />
                </div>
                <p>{store.dailyduckStorecategoryName}</p>
              </Link>
            </div>
          ))}
        </div>
        <div className={`row support ${activeCategory === 'support' ? '' : 'none'} py-3`}>
          {Array.isArray(supportCategory) && supportCategory.map((support) => (
            <div className='col-3' key={support.dailyduckSupportcategoryNo}>
              <Link to={{ pathname: `/support/${support.dailyduckSupportcategoryEngName}`, state: { activeCategory: activeCategory } }}>
                <div className='icon_box'>
                  <img src={`${process.env.REACT_APP_API_SERVER}/img?file=${support.dailyduckSupportcategoryImg}`} alt={`${support.dailyduckSupportcategoryName}`} />
                </div>
                <p>{support.dailyduckSupportcategoryName}</p>
              </Link>
            </div>
          ))}
        </div>
      </div>
      <div className={`${market.market_category_pc}`}>
        <Swiper
          slidesPerView={6.5}
          spaceBetween={30}
          freeMode={true}
          pagination={{ clickable: true }}
          className={`${market.admin_dashboard_swiper}`}
        >
          {groupedCategories.map((group, index) => (
            <SwiperSlide key={index}>
              <div className='row'>
                {/* 스토어 카테고리 항목 */}
                {group.store && (
                  <div className='col-12'>
                    <div className='store'>
                      <Link to={{
                        pathname: `/store/${group.store.dailyduckStorecategoryEngName}`,
                        state: { activeCategory: 'store' }
                      }}>
                        <div className='icon_box'>
                          <img src={`${process.env.REACT_APP_API_SERVER}/img?file=${group.store.dailyduckStorecategoryImg}`} alt={`${group.store.dailyduckStorecategoryName}`} />
                        </div>
                        <p>{group.store.dailyduckStorecategoryName}</p>
                      </Link>
                    </div>
                  </div>
                )}
                {/* 서포트 카테고리 항목 */}
                {group.support && (
                  <div className='col-12'>
                    <div className='support'>
                      <Link to={{
                        pathname: `/support/${group.support.dailyduckSupportcategoryEngName}`,
                        state: { activeCategory: 'support' }
                      }}>
                        <div className='icon_box'>
                          <img src={`${process.env.REACT_APP_API_SERVER}/img?file=${group.support.dailyduckSupportcategoryImg}`} alt={`${group.support.dailyduckSupportcategoryName}`} />
                        </div>
                        <p>{group.support.dailyduckSupportcategoryName}</p>
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};

export default MarketCategory;
