import React, { useContext, useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import seller from '../../../../seller.module.css';
import * as sellerapi from '../../../../../apis/seller';
import { LoginContext } from '../../../../../contexts/LoginContextProvider';

const SellerSalesMain = () => {
  const { isLogin, sellerlogout, userInfo, loginCheck, roles } = useContext(LoginContext);

  const MySwal = withReactContent(Swal);

  const [ activeButton, setActiveButton ] = useState('faqs');
  // const [ ]

  // 질문과 답변을 저장하는 상태를 배열로 생성
  const [faqList, setFaqList] = useState([]);

  // 수정 버튼 클릭 시
  const [ updateBtn, setUpdateBtn ] = useState(false);

  useEffect(() => {
    faqListSelect();
  }, [userInfo]);
  
  const faqListSelect = async () => {
    const response = await sellerapi.sellerFaqSelect(userInfo && userInfo.userId);
    const data = response.data;

    // console.log(data);
    setFaqList(data);

  }

  const addQuestion = () => {
    // 편집 중인 게시글이 있는지 확인
    if (editing !== null) {
      // 편집 중인 게시글이 있다면 저장 요청의 안내 메시지 띄우기
      MySwal.fire({
        customClass: {
            popup: `${seller.seller_date_save_popup}`
        },
        html : 
        `
            <div>
                <div class='${seller.seller_date_save_title}'><span>저장 요청</span></div>
                <div class='${seller.seller_date_save_content}'><span>현재 작성중인 FAQ가 있습니다. 저장 후에 새로운 FAQ를 추가해주세요.</span></div>
                <div class='${seller.seller_date_save_btn} py-2'><button id='seller_sales_save'>확인</button></div>
            </div>
        `,
        showConfirmButton: false,
        showCancelButton: false,
      });
  
      // 확인 버튼 클릭 이벤트 리스너 추가
      document.addEventListener('click', function (e) {
        if(e.target && e.target.id == 'seller_sales_save') {
          MySwal.close();
        }
      });
    } else {
      // 편집 중인 게시글이 없다면 새로운 질문 추가 로직 수행
      setFaqList(prevFaqList => {
        if (prevFaqList.length === 0 || (prevFaqList[prevFaqList.length - 1].question !== '' && prevFaqList[prevFaqList.length - 1].answer !== '')) {
          setEditing(prevFaqList.length); 
          // console.log(prevFaqList.length+1);
          return [...prevFaqList, {sellerFaqCnt: prevFaqList.length+1 , question: "", answer: "" }];
        } else {
          return prevFaqList;
        }
      });
    }
  };
  

  const removeQuestion = (index) => {
      MySwal.fire({
        customClass: {
            popup: `${seller.seller_date_save_popup}`
        },
        html : 
        `
            <div>
                <div class='${seller.seller_date_save_title}'><span>FAQ 삭제</span></div>
                <div class='${seller.seller_date_save_content}'><span>선택하신 FAQ를 정말 삭제하시겠습니까?</span><br/><span>삭제 시 취소가 불가능합니다.</span></div>
                <div class='${seller.seller_sales_remove_btn_box} py-2 d-flex w-100 justify-content-between'>
                  <button class='mx-1' id='seller_sales_cancel'>아니오</button>
                  <button class='mx-1' id='seller_date_success'>예</button>
                </div>
            </div>
        `,
        showConfirmButton: false,
        showCancelButton: false,
    });
    document.addEventListener('click', sellerFaqDelete);
    document.addEventListener('click', sellerFaqCancel);

    function sellerFaqDelete(e) {
      if(e.target && e.target.id == 'seller_date_success') {
        setFaqList(faqList.filter((_, i) => i !== index));
        faqDeleteMethod();
        MySwal.close();
        document.removeEventListener('click', sellerFaqDelete);
      }
    }

    const faqDeleteMethod = async () => {
      const response = await sellerapi.faqDelete(faqList[index], userInfo && userInfo.userId);
      const data = response.data;

      // console.log(data);
    }
    
    function sellerFaqCancel(e) {
      if(e.target && e.target.id == 'seller_sales_cancel') {
        MySwal.close();
        document.removeEventListener('click', sellerFaqCancel);
      }
    }
  };

  const handleQuestionChange = (event, index) => {
      const newFaqList = [...faqList];
      newFaqList[index].question = event.target.value;
      setFaqList(newFaqList);
  };

  const handleAnswerChange = (event, index) => {
      const newFaqList = [...faqList];
      newFaqList[index].answer = event.target.value;
      setFaqList(newFaqList);
  };

  const [editing, setEditing] = useState(null);

  const startEditing = (index) => {
    setEditing(index);
    setUpdateBtn(true);
  };
  
  const stopEditing = (index) => {
    const newFaqList = [...faqList];

    if(newFaqList[index].question != '' && newFaqList[index].answer != '') {
        MySwal.fire({
          customClass: {
              popup: `${seller.seller_date_save_popup}`
          },
          html : 
          `
              <div>
                  <div class='${seller.seller_date_save_title}'><span>FAQ 등록 완료</span></div>
                  <div class='${seller.seller_date_save_content}'><span>${index+1}번 FAQ 내용 등록이 완료되어 저장되었습니다.</span></div>
                  <div class='${seller.seller_date_save_btn} py-2'><button id='seller_sales_save'>확인</button></div>
              </div>
          `,
          showConfirmButton: false,
          showCancelButton: false,
      });

      document.addEventListener('click', test);

      function test(e) {
        // console.log('test');
        if(e.target && e.target.id == 'seller_sales_save') {
          setEditing(null);
          faqAddMethod();
          MySwal.close();
          document.removeEventListener('click', test);
        }
      }
        const faqAddMethod = async () => {
          const response = await sellerapi.faqAdd(faqList, userInfo && userInfo.userId);
          const data = response.data;

          // console.log(data);
        }
    } else {
      MySwal.fire({
        customClass: {
            popup: `${seller.seller_date_save_popup}`
        },
        html : 
        `
            <div>
                <div class='${seller.seller_date_save_title}'><span>FAQ 등록 실패</span></div>
                <div class='${seller.seller_date_save_content}'><span>${index+1}번 FAQ 내용 중 빈값이 있습니다.</span></div>
                <div class='${seller.seller_date_save_btn} py-2'><button id='seller_sales_save'>확인</button></div>
            </div>
        `,
        showConfirmButton: false,
        showCancelButton: false,
      });

      document.addEventListener('click', function (e) {
        if(e.target && e.target.id == 'seller_sales_save') {
          MySwal.close();
          setEditing(index);
        }
      });

    }
  };

  const updateEditing = (index) => {
    const newFaqList = [...faqList];

    if(newFaqList[index].question != '' && newFaqList[index].answer != '') {
        MySwal.fire({
          customClass: {
              popup: `${seller.seller_date_save_popup}`
          },
          html : 
          `
              <div>
                  <div class='${seller.seller_date_save_title}'><span>FAQ 수정 완료</span></div>
                  <div class='${seller.seller_date_save_content}'><span>${index+1}번 FAQ 내용 수정이 완료되어 저장되었습니다.</span></div>
                  <div class='${seller.seller_date_save_btn} py-2'><button id='seller_sales_save'>확인</button></div>
              </div>
          `,
          showConfirmButton: false,
          showCancelButton: false,
      });

      document.addEventListener('click', test);

      function test(e) {
        if(e.target && e.target.id == 'seller_sales_save') {
          setEditing(null);
          faqUpdateMethod();
          MySwal.close();
          document.removeEventListener('click', test);
        }
      }
        
        // document.addEventListener('click', function (e) {
        //   if(e.target && e.target.id == 'seller_sales_save') {
        //   }
        // });

        const faqUpdateMethod = async () => {
          const response = await sellerapi.faqUpdate(faqList[index], userInfo && userInfo.userId);
          const data = response.data;

          // console.log(data);
        }
    } else {
      MySwal.fire({
        customClass: {
            popup: `${seller.seller_date_save_popup}`
        },
        html : 
        `
            <div>
                <div class='${seller.seller_date_save_title}'><span>FAQ 수정 실패</span></div>
                <div class='${seller.seller_date_save_content}'><span>${index+1}번 FAQ 내용 중 빈값이 있습니다.</span></div>
                <div class='${seller.seller_date_save_btn} py-2'><button id='seller_sales_save'>확인</button></div>
            </div>
        `,
        showConfirmButton: false,
        showCancelButton: false,
      });

      document.addEventListener('click', function (e) {
        if(e.target && e.target.id == 'seller_sales_save') {
          MySwal.close();
          setEditing(index);
        }
      });

    }
  };

  const renderFaqList = () => {
      return faqList.map((item, index) => (
        <>
          <div className={`${seller.seller_sales_faq_qa}`}>
            <div className={`${seller.seller_sales_faq_qa_left} text-center`}><span>{item.sellerFaqCnt}</span></div>
            <div className={`${seller.seller_sales_faq_qa_middle}`}>
              <div className={`${seller.seller_sales_faq_qa_middle_box}`}>
                <div className='py-1 d-flex align-items-center'>
                  {
                    editing === index ? (
                      <div className={`${seller.seller_sales_right_Q}`}>
                        <input type="text" className={`${seller.seller_qa_input}`} placeholder='자주 묻는 질문 입력' value={item.question} onChange={(event) => handleQuestionChange(event, index)} />
                        <span>Q.</span>
                      </div>
                    ) : (
                      <>
                        <span className={`${seller.seller_signature_color}`}>Q.&nbsp;</span>
                        <span>{item.question}</span>
                      </>
                    )
                  }
                </div>
                <div className='py-1 d-flex align-items-center'>
                  {editing === index ? (
                    <div className={`${seller.seller_sales_right_A}`}>
                      <input type="text" className={`${seller.seller_qa_input}`} placeholder='답변을 입력해주세요.' value={item.answer} onChange={(event) => handleAnswerChange(event, index)} />
                      <span>A.</span>
                    </div>
                  ) : (
                    <>
                      <span className={`${seller.seller_signature_color}`}>A.&nbsp;</span>
                      <span>{item.answer}</span>
                    </>
                  )
                  }
                </div>
              </div>
            </div>
            <div className={`${seller.seller_sales_faq_qa_right}`}>
              {editing === index ? (
                <div className='py-1'>
                  {
                    updateBtn ?
                    <button onClick={() => updateEditing(index)}>완료</button>
                    :
                    <button onClick={() => stopEditing(index)}>등록</button>
                  }
                </div>
              ) : (
                <>
                  <div className='py-1'>
                    <button onClick={() => startEditing(index)}>수정</button>
                  </div>
                  <div className='py-1'>
                    <button onClick={() => removeQuestion(index)}>삭제</button>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      ));
  };

  return (
    <div className={`${seller.seller_dashboard_container} py-3 px-3`}>
        <div>
            <div className='pb-3 w-100 border-bottom fs-5'><span>자주쓰는 FAQ</span></div>
        </div>
        <div>
            <div className={`${seller.seller_dashboard_tab_btn} pt-4 pb-3`}>
                <div className={`w-100 ${activeButton == 'faqs' ? seller.seller_dashboard_tab_active : ''}`}><button onClick={() => setActiveButton('faqs')}>자주쓰는 FAQ 등록</button></div>
                {/* <div className={`w-100 ${activeButton == 'faqsinput' ? seller.seller_dashboard_tab_active : ''}`}><button onClick={() => setActiveButton('faqsinput')}>등록된 자주쓰는 FAQ 관리</button></div> */}
            </div>
        </div>
        {
          activeButton == 'faqs' &&
          <>
            <div className={`${seller.seller_sales_faq_top}`}>
              <div className={`${seller.seller_sales_faq_left}`}><span>번호</span></div>
              <div className={`${seller.seller_sales_faq_middle}`}><span>내용</span></div>
              <div className={`${seller.seller_sales_faq_right}`}><span></span></div>
            </div>
            {
              faqList.length != 0 ?
              <>
                <div className={`${seller.seller_sales_container} py-3`}>
                    {/* <div className={`${seller.seller_sales_left}`}><span>FAQ 신규 등록</span></div> */}
                    <div className={`${seller.seller_faq}`}>
                      <div>
                        {renderFaqList()}
                      </div>
                    </div>
                </div>
              </>
              :
                <div className='text-center w-100 py-5'>
                  <span>등록된 FAQ가 없습니다.</span>
                </div>
            }
                <div className={`${seller.seller_sales_question_add_btn}`}>
                  <button onClick={addQuestion}>질문 추가</button>
                </div>
          </>
        }
    </div>
  )
}

export default SellerSalesMain