import React from 'react'
import CartPaymentHeader from '../../components/market/CartPaymentHeader'
import CartPaymentContent from '../../components/market/CartPaymentContent'

const CartPaymentContainer = () => {
  return (
    <>
      <div className='payment'>
        <CartPaymentHeader />
        <CartPaymentContent />
      </div>
    </>
  )
}

export default CartPaymentContainer