import React, { useState, useEffect } from 'react';
import seller from '../../../../seller/seller.module.css';

const PaymentRequestPopup = ({ onClose, onSend, selectedProduct }) => {
    const [serviceDescription, setServiceDescription] = useState('');
    const [price, setPrice] = useState('');
    const [productOptions, setProductOptions] = useState([]);
    const [serviceFee, setServiceFee] = useState(0.15);
    const [paymentFee, setPaymentFee] = useState(0.035);

    useEffect(() => {
        if (selectedProduct) {
            setProductOptions([selectedProduct.productName]);
        }
    }, [selectedProduct]);

    const handleSend = () => {
        if (selectedProduct && serviceDescription && price) {
            onSend({ selectedProduct: selectedProduct.productName, serviceDescription, price });
            onClose();
        } else {
            alert("모든 필드를 입력해주세요.");
        }
    };

    const check = () => {
        return serviceDescription !== '' && price !== '';
    };

    const calculateFees = (amount) => {
        const serviceFeeAmount = Math.floor(amount * serviceFee);
        const paymentFeeAmount = Math.floor(amount * paymentFee);
        const vatAmount = Math.floor((serviceFeeAmount + paymentFeeAmount) * 0.1);
        const settlementAmount = amount - (serviceFeeAmount + paymentFeeAmount + vatAmount);
        
        return {
            serviceFeeAmount,
            paymentFeeAmount,
            vatAmount,
            settlementAmount
        };
    };

    const fees = calculateFees(price);

    const formatCurrency = (amount) => {
        return amount.toLocaleString();
    };

    return (
        <div className={seller.popup_container}>
            <div className={seller.popup}>
                <div className={seller.popup_content}>
                    <div className={seller.popup_memo}>
                        <div><span>결제 요청 전, 꼭 확인해 주세요.</span></div>
                        <div><span>- 대화를 시작한 시점에서 상품은 디폴트로 설정됩니다.</span></div>
                        <div><span>- 제공할 옵션 내용을 명시하여 주시기 바랍니다.</span></div>
                        <div><span>- 제공할 옵션의 금액과 수수료를 제외한 나머지 금액이 정산시 지급됩니다.</span></div>
                    </div>
                    <div className={`py-3 ${seller.popup_info_box}`}>
                        <div className='pb-2'>
                            <div className={`${seller.font_size_09}`}><span>상품 선택</span></div>
                            <div>
                                <select value={selectedProduct?.productName} disabled className={`${seller.popup_select}`}>
                                    {productOptions.map((product, index) => (
                                        <option key={index} value={product}>{product}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className='pb-2'>
                            <div className={`${seller.font_size_09}`}><span>제공 결제 내용</span></div>
                            <div className={`${seller.popup_service_title}`}>
                                <input type="text" value={serviceDescription} onChange={(e) => setServiceDescription(e.target.value)} />
                            </div>
                        </div>
                        <div>
                            <div className={`${seller.font_size_09}`}><span>금액</span></div>
                            <div className={`${seller.popup_service_price}`}>
                                <input type="number" value={price} onChange={(e) => setPrice(e.target.value)} />
                                <div className={`${seller.popup_service_price_txt}`}><span>원</span></div>
                            </div>
                        </div>
                        <div className={`${seller.popup_service_fee_box}`}>
                            <div>
                                <div className={`${seller.popup_service_fee}`}>
                                    <div><span>(a)_서비스 이용료</span></div>
                                    <div><span>-{formatCurrency(fees.serviceFeeAmount)}</span><span>원</span></div>
                                </div>
                                <div className={`${seller.popup_payment_fee}`}>
                                    <div><span>(b)_결제 수수료 및 결제망 이용료</span></div>
                                    <div><span>-{formatCurrency(fees.paymentFeeAmount)}</span><span>원</span></div>
                                </div>
                                <div className={`${seller.popup_vat_fee}`}>
                                    <div><span>(c)_(a)+(b)에 대한 VAT</span></div>
                                    <div><span>-{formatCurrency(fees.vatAmount)}</span><span>원</span></div>
                                </div>
                            </div>
                            <div className={`${seller.popup_settlement_amount}`}>
                                <div><span>최종 정산액</span></div>
                                <div><span>{formatCurrency(fees.settlementAmount)}</span><span>원</span></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={seller.popup_actions}>
                    <button className={check() ? seller.popup_actions_active : ''} onClick={handleSend} disabled={!check()}>결제 요청하기</button>
                </div>
                <div className={`${seller.popup_close}`}>
                    <button onClick={onClose}><span className="material-symbols-outlined">close</span></button>
                </div>
            </div>
        </div>
    );
};

export default PaymentRequestPopup;
