import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

/**
 * icon : success, error, warning, info, question
 */

const MySwal = withReactContent(Swal)

// 기본 alert
export const alert = (title, text, icon, callback) => {
    MySwal.fire({
        title: title,
        text: text,
    })
    .then( callback )
  }

// confirm
export const confirm = (title, text, icon, callback) => {
    MySwal.fire({
        title: title,
        text: text,
        showCancelButton: true,
        cancelButtonColor: "#F1F2F3",
        confirmButtonText: "로그아웃",
        customClass: {
            confirmButton: 'my-logout-btn' // 여기서 정의한 CSS 클래스를 사용합니다.
        },
        confirmButtonColor: "#FF0366",
        cancelButtonText: "취소",
        reverseButtons: true
    })
    .then( callback )
}
// confirm
export const removeConfirm = (title, text, icon, callback) => {
    MySwal.fire({
        title: title,
        text: text,
        showCancelButton: true,
        cancelButtonColor: "#F1F2F3",
        confirmButtonText: "탈퇴하기",
        customClass: {
            confirmButton: 'my-logout-btn' // 여기서 정의한 CSS 클래스를 사용합니다.
        },
        confirmButtonColor: "#FF0366",
        cancelButtonText: "취소",
    })
    .then((result) => {
        if (result.isConfirmed) {
            callback(result);
        }
    })
}