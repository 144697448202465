import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const FaqScreen = ({ product, store }) => {

    const navigate = useNavigate();
    // Null 값을 제외한 실제 FAQ 데이터만 필터링
    const filteredFaqs = Array.isArray(product.productFaqs) ? product.productFaqs.filter(item => item) : [];

    const formatDate = (dateString) => {
        // dateString이 null, undefined, 또는 빈 문자열인 경우 처리
        if (!dateString) {
            return ''; // 또는 다른 기본값을 반환할 수 있습니다.
        }
        return dateString.replace('T', ' ').substring(0, 19);
    };

    const onFaqItem = (item) => {
        // console.log('item : ', item);
        navigate(`/store/FAQ/${item.sellerFaqNo}`, { state : {faq : item}});
    }

    return (
        <div className='mt-3 px-4 faq_box'>
            <div className='border-bottom py-2'>
                <span>FAQ<span className='ps-2'>{filteredFaqs.length}</span></span>
            </div>
            {
                filteredFaqs.length !== 0 ? filteredFaqs.map((item, index) => (
                    item && (  // item이 null이 아닌 경우에만 렌더링
                        <div className={`${store.store_detail_faq_item}`} key={index}>
                            <button onClick={() => onFaqItem(item)}>
                                <div>
                                    <span>{item.question}</span>
                                </div>
                                <div>
                                    <span>{formatDate(item.sellerFaqRegDate)}</span>
                                </div>
                            </button>
                        </div>
                    )
                ))
                :
                <div className={`${store.store_detail_faq_no_data}`}><span>등록된 FAQ가 없습니다.</span></div>
            }
        </div>
    );
}

export default FaqScreen;
