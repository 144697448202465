import React, { useContext, useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import BoardTable from '../board/BoardTable';
import { format, isSameMonth, subDays, isSameDay } from 'date-fns';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import admin from '../../admin.module.css';
import { LoginContext } from '../../../contexts/LoginContextProvider';
import * as adminApi from '../../../apis/admin';
import * as sellerapi from '../../../apis/seller';
import * as auth from '../../../apis/auth';
import { useNavigate } from 'react-router-dom';

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip" style={{ backgroundColor: 'white', padding: '10px', border: '1px solid #ccc' }}>
        <p>{`${label}`}</p>
        {payload.map((entry, index) => (
          <p key={index} style={{ color: entry.color }}>{`${entry.name} : ${entry.value}`}</p>
        ))}
      </div>
    );
  }
  return null;
};

const CustomLegend = (props) => {
  const { payload } = props;
  return (
    <ul className='d-flex'>
      {payload.map((entry, index) => (
        <li key={`item-${index}`} style={{ color: entry.color }} className='px-2'>
          <div className='d-flex align-items-center position-relative'>
            <div style={{ width: '10px', height: '10px', borderRadius: '50%', backgroundColor: entry.color, paddingTop: '10px' }}></div>
            <div>{entry.value}</div>
          </div>
        </li>
      ))}
    </ul>
  );
};

const AdminMain = ({handleMenuClick, setActiveShopping}) => {
  const { roles, userInfo } = useContext(LoginContext);
  const navigate = useNavigate();
  const [orderList, setOrderList] = useState([]);
  const [deliveryInfo, setDeliveryInfo] = useState({});
  const [paymentLog, setPaymentLog] = useState([]);
  const [pageViews, setPageViews] = useState([]);
  const [data, setData] = useState([]);
  const [reviewList, setReviewList] = useState([]);
  const [shopInfo, setShopInfo] = useState({});
  const [user, setUser] = useState({});
  const [contentsList, setContentsList] = useState([
    { contentsNo: 1, contentsType: '입력폼 참여', userProfile: '../img/join/favority/1.png', contentsTitle: '로티 소세지훈 수요조사', contentsWriter: '테스트1', contentsRegDate: '2024-05-02 00:33' },
    { contentsNo: 2, contentsType: '입력폼 참여', userProfile: '../img/join/favority/9.png', contentsTitle: '로티 소세지훈 수요조사', contentsWriter: '테스트2', contentsRegDate: '2024-05-02 00:33' },
    // 나머지 초기 데이터
  ]);
  const [infoList, setInfoList] = useState([
    { infoNo: 10, infoType: '', infoTitle: '전문가 찾기 Beta 오픈 소식 🎉', infoWriter: '데일리덕', infoRegDate: '2024-04-30' },
    { infoNo: 9, infoType: '데일리덕', infoTitle: '근로자의 날 휴무안내', infoWriter: '데일리덕', infoRegDate: '2024-04-29' },
    // 나머지 초기 데이터
  ]);
  const [sellerCustomer, setSellerCustomer] = useState([
    { customerNo: 10, customerTitle: '주문건 취소 관련 문의', userId: 'test', state: '답변대기', customerRegDate: '2024-04-30' },
    { customerNo: 9, customerTitle: '주문건 취소 관련 문의', userId: 'test', state: '답변완료', customerRegDate: '2024-04-30' },
    // 나머지 초기 데이터
  ]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [currentIndex, setCurrentIndex] = useState(1);
  const [slideImage, setSlideImage] = useState([
    '../../img/admin/tosspayments.png',
    '../../img/admin/tosspayments.png',
    '../../img/admin/tosspayments.png',
    '../../img/admin/tosspayments.png'
  ]);
  const totalSlides = slideImage.length;

  const columns = roles.isAdmin ? [
    { Header: '일자', accessor: 'date' },
    { Header: '주문수', accessor: 'orderQuantity' },
    { Header: '매출액', accessor: 'orderSales', Cell: ({ value }) => `${value.toLocaleString('ko-KR')}원` },
    { Header: '방문자', accessor: 'visit' },
    { Header: '가입', accessor: 'join' },
    { Header: '문의', accessor: 'customer' },
    { Header: '후기', accessor: 'review' },
  ] : [
    { Header: '일자', accessor: 'date' },
    { Header: '주문수', accessor: 'orderQuantity' },
    { Header: '매출액', accessor: 'orderSales', Cell: ({ value }) => `${value.toLocaleString('ko-KR')}원` },
    { Header: '방문자', accessor: 'visit' },
    { Header: '후기', accessor: 'review' },
  ];

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (roles.isAdmin) {
      adimnOrderSelect();
      paymentLogAllSelect();
      viewAllSelect();
      allReviewSelect();
      shopSelect();
      userSelect();
    } else if (roles.isStore) {
      orderSelect();
      paymentLogSelect();
      viewSelect();
      sellerReviewSelect();
      shopSelect();
      userSelect();
    }
  }, [roles, userInfo]);

  useEffect(() => {
    if (paymentLog.length > 0 && pageViews.length > 0) {
      const summarizedData = summarizePaymentLog(paymentLog, pageViews);
      const dataWithSummaries = calculateSummaries(summarizedData);
      setTableData(dataWithSummaries);

      const updatedData = generateLast7DaysData(pageViews);
      setData(updatedData);
    }
  }, [paymentLog, pageViews]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const shopSelect = async () => {
    const response = await sellerapi.shopSelect(userInfo && userInfo.userId);
    const data = response.data;

    // console.log('shop : ', data);
    setShopInfo(data);
  }

  const userSelect = async () => {
    const response = await auth.userselect(userInfo && userInfo?.userId);
    const data = response.data;

    // console.log(data);
    setUser(data);
  }

  const orderSelect = async () => {
    const response = await adminApi.orderSelect(userInfo.userId);
    const data = response.data;
    const initialDeliveryInfo = data.reduce((acc, order) => {
      acc[order.paymentNo] = {
        deliveryCompany: order.deliveryCompany || '',
        waybillNumber: order.waybillNumber || ''
      };
      return acc;
    }, {});

    setOrderList(data);
    setDeliveryInfo(initialDeliveryInfo);
  };

  const adimnOrderSelect = async () => {
    const response = await adminApi.adminOrderSelect();
    const data = response.data;
    const initialDeliveryInfo = data.reduce((acc, order) => {
      acc[order.paymentNo] = {
        deliveryCompany: order.deliveryCompany || '',
        waybillNumber: order.waybillNumber || ''
      };
      return acc;
    }, {});

    setOrderList(data);
    setDeliveryInfo(initialDeliveryInfo);
  };

  const paymentLogSelect = async () => {
    const response = await adminApi.paymentLogSelect(userInfo && userInfo?.userId);
    const data = response.data;
    if (data !== '') {
      setPaymentLog(data);
    }
  };

  const paymentLogAllSelect = async () => {
    const response = await adminApi.paymentLogAllSelect(userInfo && userInfo?.userId);
    const data = response.data;
    if (data !== '') {
      setPaymentLog(data);
    }
  };

  const viewSelect = async () => {
    const response = await adminApi.viewSelect(userInfo && userInfo?.userId);
    const data = response.data;
    if (data !== '') {
      setPageViews(data);
    }
  };

  const viewAllSelect = async () => {
    const response = await adminApi.viewAllSelect();
    const data = response.data;
    if (data !== '') {
      setPageViews(data);
    }
  };

  const sellerReviewSelect = async () => {
    const response = await adminApi.sellerReviewSelect(userInfo && userInfo?.userId);
    const data = response.data;
    const reservationsReviewArray = Object.keys(data).map((key) => data[key]);
    const slicedReservationsReviewArray = reservationsReviewArray.slice(0, 6);
    setReviewList(slicedReservationsReviewArray);
  };

  const allReviewSelect = async () => {
    const response = await adminApi.allReviewSelect();
    const data = response.data;
    const reservationsReviewArray = Object.keys(data).map((key) => data[key]);
    const slicedReservationsReviewArray = reservationsReviewArray.slice(0, 6);
    setReviewList(slicedReservationsReviewArray);
  };

  const generateLast7DaysData = (views) => {
    const today = new Date();
    const last7Days = Array.from({ length: 7 }, (_, i) => subDays(today, i));
    const viewSummary = views.reduce((acc, view) => {
      const dateString = format(new Date(view.pageViewDate), 'MM-dd');
      if (!acc[dateString]) {
        acc[dateString] = { visitors: 0, pageviews: 0 };
      }
      acc[dateString].visitors += 1;
      return acc;
    }, {});

    return last7Days.map(date => {
      const dateString = format(date, 'MM-dd');
      return {
        date: dateString,
        visitors: viewSummary[dateString]?.visitors || 0,
        pageviews: viewSummary[dateString]?.pageviews || 0
      };
    });
  };

  const formatNumber = (number, suffix = '') => {
    return `${number.toLocaleString('ko-KR')}${suffix}`;
  };

  const summarizePageViews = (views) => {
    if (!Array.isArray(views) || views.length === 0) {
      return {};
    }

    const summary = views.reduce((acc, view) => {
      const dateString = format(new Date(view.pageViewDate), 'yyyy-MM-dd');
      if (!acc[dateString]) {
        acc[dateString] = 0;
      }
      acc[dateString] += 1;
      return acc;
    }, {});

    return summary;
  };

  const summarizePaymentLog = (logs, views) => {
    const today = new Date();
    const last7Days = Array.from({ length: 7 }, (_, i) => subDays(today, i));
    const pageViewsSummary = summarizePageViews(views);

    const summary = last7Days.map((date) => {
      const dateString = format(date, 'yyyy-MM-dd');
      const logsForDate = logs.filter((log) =>
        isSameDay(new Date(log.paymentLogRegDate), date)
      );

      const orderQuantity = logsForDate.length;
      const orderSales = logsForDate.reduce(
        (sum, log) => sum + parseInt(log.totalPrice, 10),
        0
      );

      const visit = pageViewsSummary[dateString] || 0;

      return {
        date: dateString,
        orderQuantity,
        orderSales,
        visit,
        review: 0,
      };
    });

    return summary;
  };

  const calculateSummaries = (data) => {
    let last7DaysSummary = {
      date: '최근 7일 합계',
      orderQuantity: 0,
      orderSales: 0,
      visit: 0,
      review: 0,
    };

    let thisMonthSummary = {
      date: '이번달 합계',
      orderQuantity: 0,
      orderSales: 0,
      visit: 0,
      review: 0,
    };

    const last7Days = subDays(new Date(), 7);

    data.forEach((row) => {
      const rowDate = new Date(row.date);
      if (isSameMonth(rowDate, new Date())) {
        thisMonthSummary.orderQuantity += row.orderQuantity;
        thisMonthSummary.orderSales += row.orderSales;
        thisMonthSummary.visit += row.visit;
        thisMonthSummary.review += row.review;
      }
      if (rowDate >= last7Days) {
        last7DaysSummary.orderQuantity += row.orderQuantity;
        last7DaysSummary.orderSales += row.orderSales;
        last7DaysSummary.visit += row.visit;
        last7DaysSummary.review += row.review;
      }
    });

    last7DaysSummary = {
      ...last7DaysSummary,
      orderQuantity: formatNumber(last7DaysSummary.orderQuantity, '건'),
      orderSales: `${last7DaysSummary.orderSales.toLocaleString('ko-KR')}`, 
      visit: formatNumber(last7DaysSummary.visit, '명'),
    };
    
    thisMonthSummary = {
      ...thisMonthSummary,
      orderQuantity: formatNumber(thisMonthSummary.orderQuantity, '건'),
      orderSales: `${thisMonthSummary.orderSales.toLocaleString('ko-KR')}`, 
      visit: formatNumber(thisMonthSummary.visit, '명'),
    };
    

    return [...data, last7DaysSummary, thisMonthSummary];
  };

  const renderLineChart = () => {
    if (data.length === 0) {
      return null;
    }

    return (
      <LineChart
        width={windowWidth < 991 ? 650 : 500}
        height={windowWidth < 991 ? 400 : 300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
        className={`${admin.admin_dashboard_visitors_chart}`}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip content={<CustomTooltip />} />
        <Legend content={<CustomLegend />} />
        <Line type="monotone" dataKey="visitors" name="방문자수" stroke="#8884d8" activeDot={{ r: 8 }} />
        <Line type="monotone" dataKey="pageviews" name="페이지뷰" stroke="#82ca9d" />
      </LineChart>
    );
  };

  const onCustomerClick = (no) => {
    // console.log(no);
    handleMenuClick('shopping');
    setActiveShopping('review');
    navigate(`/admin/shop/review`);
  };

  const onContentClick = (no) => {
    // console.log(no);
  };

  const onInfoClick = (no) => {
    // console.log(no);
  };

  const sellerCustomerClick = (no) => {
    // console.log(no);
  };

  const countByState = (stateArray) => {
    return orderList.filter(order => stateArray.includes(order.paymentState)).length;
  };

  const newOrdersCount = countByState(['결제완료', '결제대기', '배송준비중']);
  const cancelRequestsCount = countByState(['취소요청']);
  const returnRequestsCount = countByState(['반품요청']);
  const exchangeRequestsCount = countByState(['교환요청']);
  const todayTotal = newOrdersCount + cancelRequestsCount + returnRequestsCount + exchangeRequestsCount;

  const reviewTypeFilter = (category) => {
    if (category === 'album') { return '앨범 구매 구매평 입니다.'; }
    if (category === 'penssi') { return '팬시 구매 구매평 입니다.'; }
    if (category === 'doll') { return '인형 구매 구매평 입니다.'; }
    if (category === 'commission') { return '커미션 구매 구매평 입니다.'; }
    if (category === 'birthcafeitem') { return '생카템 구매 구매평 입니다.'; }
    if (category === 'penssaitem') { return '팬싸템 구매 구매평 입니다.'; }
    if (category === 'consertitem') { return '콘서트템 구매 구매평 입니다.'; }
    if (category === 'sonitem') { return '손민수템 구매 구매평 입니다.'; }
  };

  const formatDate = (date) => {
    if (!date) return '';
    const parsedDate = new Date(date);
    if (isNaN(parsedDate)) {
      console.warn('Invalid date value:', date);
      return '';
    }
    return format(parsedDate, 'yyyy-MM-dd HH:mm:ss');
  };

  const onNewOrder = () => {
    // 'shopping' 탭으로 변경하고, 쇼핑 메뉴에서 'order'를 활성화
    handleMenuClick('shopping');
    setActiveShopping('order');
    navigate(`/admin/shop/order`);
  };

  const onCancelOrder = () => {
    handleMenuClick('shopping');
    setActiveShopping('cancel');
    navigate(`/admin/shop/cancel`);
  };

  const onReturnOrder = () => {
    handleMenuClick('shopping');
    setActiveShopping('return');
    navigate(`/admin/shop/return`);
  };

  const onExchangeOrder = () => {
    handleMenuClick('shopping');
    setActiveShopping('change');
    navigate(`/admin/shop/change`);
  };

  const onWaitingReply = () => {
    handleMenuClick('shopping');
    setActiveShopping('review');
    navigate(`/admin/shop/review`);
  };

  const handleMonthStatistics = () => {
    navigate(`/admin/dashboard/monthly`);
  }

  return (
    <>
      <div className={`${admin.admin_dashboard_board} px-3 py-3`}>
        <div className={`${admin.admin_dashboard_board_left}`}>
          <div className={`${admin.admin_dashboard_board_left_today}`}>
            <div className={`px-3 py-3 border-bottom ${admin.admin_dashboard_board_today_title}`}>
              <div><span>오늘의 할일</span></div>
              <div><span className={`ms-2 px-2 py-1 ${admin.admin_dashboard_board_today_count}`}>{todayTotal}</span></div>
            </div>
            <div className={`d-flex px-3 py-2 ${admin.admin_dashboard_board_left_today_top}`}>
              <div onClick={() => onNewOrder()}><span className='pe-2'>신규주문</span><span className={`${admin.admin_signature_color}`}>{newOrdersCount}</span></div>
              <div onClick={() => onCancelOrder()}><span className='pe-2'>취소관리</span><span className={`${admin.admin_signature_color}`}>{cancelRequestsCount}</span></div>
              <div onClick={() => onReturnOrder()}><span className='pe-2'>반품관리</span><span className={`${admin.admin_signature_color}`}>{returnRequestsCount}</span></div>
              <div onClick={() => onExchangeOrder()}><span className='pe-2'>교환관리</span><span className={`${admin.admin_signature_color}`}>{exchangeRequestsCount}</span></div>
              <div onClick={() => onWaitingReply()}><span className='pe-2'>답변대기 문의</span><span className={`${admin.admin_signature_color}`}>0</span></div>
            </div>
            {roles.isAdmin &&
              <div className={`d-flex px-3 py-2 ${admin.admin_dashboard_board_left_today_bottom}`}>
                <div><span className='pe-2'>체크인</span><span className={`${admin.admin_signature_color}`}>0</span></div>
                <div><span className='pe-2'>체크아웃</span><span className={`${admin.admin_signature_color}`}>0</span></div>
                <div><span className='pe-2'>입금대기</span><span className={`${admin.admin_signature_color}`}>0</span></div>
                <div><span className='pe-2'>예약완료</span><span className={`${admin.admin_signature_color}`}>0</span></div>
                <div><span className='pe-2'>취소요청</span><span className={`${admin.admin_signature_color}`}>0</span></div>
                <div><span className='pe-2'>취소처리중</span><span className={`${admin.admin_signature_color}`}>0</span></div>
                <div><span className='pe-2'>문의</span><span className={`${admin.admin_signature_color}`}>0</span></div>
              </div>
            }
          </div>
          <div className={`${admin.admin_dashboard_chart_table_box}`}>
            <div className={`${admin.admin_dashboard_visitors_chart_box} me-1`}>
              <div className='border-bottom py-3 px-3'>
                <div><span>방문자 현황</span></div>
              </div>
              <div className='py-3'>
                {renderLineChart()}
              </div>
            </div>
            <div className={`${admin.admin_dashboard_visitors_table} ms-1`}>
              <div className='d-flex justify-content-between align-items-center px-3 py-3 border-bottom'>
                <div><span>일자별 요약</span></div>
                <div><button onClick={handleMonthStatistics}>월별통계</button></div>
              </div>
              <div>
                <BoardTable columns={columns} data={tableData} admin={admin} />
              </div>
            </div>
          </div>
          <div className={`d-flex w-100 ${admin.adimn_dashboard_cusetomer_contents_box}`}>
            <div className={`me-1 ${admin.admin_dashboard_customer_box}`}>
              <div className='border-bottom py-3 px-3'>
                <div><span>{roles.isAdmin ? '문의/구매평' : '구매평'}</span></div>
              </div>
              {Array.isArray(reviewList) && reviewList.map((item, index) => (
                <div className={`px-3 py-2 ${admin.admin_dashboard_customer_content}`} key={index} onClick={() => onCustomerClick(item.customerNo)}>
                  <div><span>[구매평]&nbsp;</span><span>{reviewTypeFilter(item.productDTO?.middleCategory)}</span></div>
                  <div><span>{item.users?.userName}</span><span> / </span><span>{formatDate(item.storeReview?.reviewRegDate)}</span></div>
                </div>
              ))}
            </div>
            {
              roles.isAdmin &&
              <div className={`ms-1 ${admin.admin_dashboard_contents_box}`}>
                <div className='border-bottom py-3 px-3'>
                  <div><span>컨텐츠 반응</span></div>
                </div>
                {Array.isArray(contentsList) && contentsList.map((item, index) => (
                  <div className={`px-3 py-2 ${admin.admin_dashboard_contents_detail_box}`} key={index} onClick={() => onContentClick(item.contentsNo)}>
                    <div className={`${admin.admin_dashboard_contents_img}`}>
                      <img src={item.userProfile} alt="프로필" />
                    </div>
                    <div className={`${admin.admin_dashboard_contents_content} ps-2`}>
                      <div><span>[{item.contentsType}]</span><span>{item.contentsTitle}</span></div>
                      <div><span>{item.contentsWriter}</span><span> / </span><span>{item.contentsRegDate}</span></div>
                    </div>
                  </div>
                ))}
              </div>
            }
          </div>
          <div className={`d-flex w-100 ${admin.admin_dashboard_info_inquiry}`}>
            <div className={`me-1 ${admin.admin_dashboard_dailyduck_info}`}>
              <div className='border-bottom py-3 px-3 d-flex justify-content-between align-items-center'>
                <div><span>데일리덕 소식</span></div>
                <div><button className='border-0 bg-body dd-fs-8 gray_color2'>더보기</button></div>
              </div>
              <div>
                {Array.isArray(infoList) && infoList.map((item, index) => (
                  <div className={`${admin.admin_dashboard_dailyduck_info_content} px-3 py-2`} key={index} onClick={() => onInfoClick(item.infoNo)}>
                    <div>
                      {item.infoType !== '' ? <span>[{item.infoType}] </span> : null}
                      <span>{item.infoTitle}</span>
                    </div>
                    <div><span>{item.infoRegDate}</span></div>
                  </div>
                ))}
              </div>
            </div>
            <div className={`ms-1 ${admin.admin_dashboard_dailyduck_inquiry}`}>
              <div className='border-bottom py-3 px-3'>
                <div><span>데일리덕에 문의하신 내역</span></div>
              </div>
              <div>
                {Array.isArray(sellerCustomer) && sellerCustomer.map((item, index) => (
                  <div className={`${admin.admin_dashboard_seller_customer_box} px-3 py-2`} key={index} onClick={() => sellerCustomerClick(item.customerNo)}>
                    <div><span>{item.customerTitle}</span></div>
                    <div><span>{item.state}</span></div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className={`${admin.admin_dashboard_board_right}`}>
          <div className={`${admin.admin_dashboard_board_user_profile}`}>
            <div><img src={`${shopInfo && shopInfo?.shopProfileImg}`} alt="프로필 사진" /></div>
            <div>
              <div><span>{shopInfo && shopInfo?.shopName}</span></div>
              <div><span className="material-symbols-outlined">arrow_drop_down</span></div>
            </div>
            <div><span>{user && user?.userEmail}</span></div>
          </div>
          <div className={`${admin.admin_dashboard_board_user_memo}`}>
            <div>
              <div><img src={`${shopInfo && shopInfo?.shopProfileImg}`} alt="프로필" /></div>
              <div className='ps-1'><span>{shopInfo && shopInfo?.shopName}</span></div>
            </div>
            <div>
              <div>
                <input type="text" placeholder='관리자들과 공유할 메모를 남겨주세요.' />
              </div>
              <div>
                <button>저장</button>
              </div>
            </div>
          </div>
          <div className={`${admin.admin_dashboard_operator_setting} px-3 py-3`}>
            <div className='d-flex justify-content-between'>
              <div><span>운영진</span></div>
              <div><button className='border-0 bg-body dd-fs-8 gray_color2'>관리</button></div>
            </div>
            <div className='py-3 d-flex'>
              <div className='me-2'>
                <img src="../img/join/favority/12.png" alt="프로필" />
              </div>
              <div className='me-2'>
                <img src="../img/join/favority/12.png" alt="프로필" />
              </div>
            </div>
          </div>
          <div>
            <div className='position-relative'>
              <Swiper
                slidesPerView={1}
                spaceBetween={30}
                loop={true}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: false,
                }}
                onSlideChange={(swiper) => setCurrentIndex(swiper.realIndex + 1)}
                modules={[Pagination, Navigation, Autoplay]}
                className={`${admin.admin_dashboard_swiper}`}
              >
                {slideImage.map((item, index) => (
                  <SwiperSlide key={index}>
                    <img src={item} alt="" />
                  </SwiperSlide>
                ))}
              </Swiper>
              <div className='position-absolute bottom-0 end-0 px-2 py-1 mb-1' style={{ zIndex: '999', backgroundColor: 'rgba(0, 0, 0, 0.8', color: 'white', fontSize: '0.6em', borderRadius: '30px' }}>{`${currentIndex}/${totalSlides}`}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminMain;
