import React, { useEffect, useState } from 'react';
import event from '../../containers/event/event.module.css';
import { useNavigate, useParams } from 'react-router-dom';
import { animated } from 'react-spring';
import { useSpring, useTransition } from '@react-spring/core';
import { Select } from 'antd';

const { Option } = Select;

const RaffleSubscribe = () => {
    const {eventno} = useParams();
    const navigate = useNavigate();
    const [ showOptionSelect, setShowOptionSelect ] = useState(false);
    const [ option, setOption] = useState('');
    const [ isChecked, setIsChecked ] = useState(false);
    const [ paymentState, setPaymentState ] = useState(false);

    const transitions = useTransition(showOptionSelect, {
        from: { transform: "translateY(100%)" },
        enter: { transform: "translateY(0)" },
        leave: { transform: "translateY(100%)" },
        config: {
            duration: 300,
        },
    });

    const handleBack = () => {
        navigate(-1);
    }

    const onOptionSelectBtn = () => {
        setShowOptionSelect(true);
    }

    // 가정된 목 데이터 구조
    const mockData = [
        { id: 1, options: ['블랙', '레드', '블루'], selected: '' },
        { id: 2, options: ['XS', 'S', 'M', 'L', 'XL'], selected: '' },
        // 추가 셀렉트 박스 데이터 예시
        // { id: 3, options: ['옵션1', '옵션2'], selected: '' },
    ];

        const [selectData, setSelectData] = useState(mockData);
        const [finalSelection, setFinalSelection] = useState('');

        useEffect(() => {
            // 마지막 셀렉트 박스의 선택이 변경될 때마다 실행
            const lastSelected = selectData[selectData.length - 1].selected;
            if (lastSelected) {
                const selectedOptions = selectData.filter(sd => sd.selected).map(sd => sd.selected).join('/');
                setFinalSelection(`${selectedOptions}`);
                setShowOptionSelect(false);
                setOption(selectedOptions);
            }
        }, [selectData]);

        const handleSelectChange = (id, value) => {
            const newData = selectData.map(sd => {
                if (sd.id === id) {
                    return { ...sd, selected: value };
                }
                if (sd.id > id) {
                    // 현재 선택된 셀렉트 박스 이후의 모든 셀렉트 박스를 초기화
                    return { ...sd, selected: '' };
                }
                return sd;
            });
            setSelectData(newData);
        };

        const handleAgree = (e) => {
            // console.log(e.target.checked);
            setIsChecked(e.target.checked);
        }

        useEffect(() => {
            if(option !== '' && isChecked) {
                setPaymentState(true);
            } else {
                setPaymentState(false);
            }
        }, [option, isChecked]);
        const onPayment = () => {
            navigate('/raffle/payment');
        }
  return (
    <>
        <div className={`${event.event_raffle_container}`}>
            <div className='py-4 px-3'>
                <div><button onClick={handleBack} className='border-0 bg-body'><span className="material-symbols-outlined">arrow_back_ios_new</span></button></div>
            </div>
            <div className={`${event.event_raffle_detail_info_box}`}>
                <div className={`px-3`}>
                    <div className={`${event.event_raffle_detail_title}`}><span>응모하기</span></div>
                    <div className={`${event.event_raffle_detail_sub_title}`}><span>당첨 시 구매할 상품의 옵션을 선택해주세요.</span></div>
                </div>
                <div className={`px-3 pt-3 pb-4`}>
                    <button className={`${event.event_raffle_detail_option_btn}`} onClick={() => onOptionSelectBtn()}>
                        <div><span>{option === '' ? '옵션을 선택해주세요.' : option}</span></div>
                        <div><span className="material-symbols-outlined">expand_more</span></div>
                    </button>
                </div>
            </div>
            <div className='px-3 py-3'>
                <ul className={`${event.event_raffle_info_list_box}`}>
                    <li>n원 결제 시 응모하기가 가능합니다.</li>
                    <li>응모하기 안내사항 내용입니다.</li>
                    <li>응모하기 안내사항 내용입니다.</li>
                    <li>응모하기 안내사항 내용입니다.</li>
                    <li>응모하기 안내사항 내용입니다.</li>
                    <li>데일리덕 서비스 래플 응모하기 안내사항 내용입니다.래플 응모하기 안내사항 내용입니다.</li>
                </ul>
            </div>
            <div className={`${event.event_raffle_detail_checkbox} px-3`}>
                <div><input type="checkbox" onChange={(e) => handleAgree(e)} /></div>
                <div className='ps-3'><span>안내사항을 확인하였으며 이에 동의합니다.</span></div>
            </div>
            <div className={`${event.event_raffle_detail_payment} pb-4`}>
                <div className='px-3'>
                    <button disabled={!paymentState} className={`${paymentState ? event.event_payment_active : ''}`} onClick={() => onPayment()}>결제하기</button>
                </div>
            </div>
            {
                showOptionSelect &&
                <div className={`${event.event_raffle_option_background}`}>
                    {
                        transitions((style, item) =>
                            item ?
                            <animated.div className={`${event.event_raffle_option_box}`} style={style}>
                                <div className='d-flex justify-content-between px-3 pt-4'>
                                    <div className='fs-5'><span>옵션</span></div>
                                    <div><button className='border-0 bg-body' onClick={() => setShowOptionSelect(false)}><span className="material-symbols-outlined">close</span></button></div>
                                </div>
                                <div className='px-3 py-3'>
                                    {selectData.map((data, index) => (
                                        // 이전 셀렉트 박스가 선택되었거나, 첫 번째 셀렉트 박스인 경우에만 해당 셀렉트 박스를 표시
                                        (index === 0 || selectData[index - 1].selected) && (
                                            <div key={data.id} className='my-2'>
                                                <Select
                                                    value={data.selected}
                                                    onChange={(value) => handleSelectChange(data.id, value)}
                                                    className={`${event.event_raffle_option_select}`}
                                                >
                                                    <Option value="">선택하세요</Option>
                                                    {data.options.map((option, idx) => (
                                                        <Option key={idx} value={option}>{option}</Option>
                                                    ))}
                                                </Select>
                                            </div>
                                        )
                                    ))}
                                </div>
                            </animated.div>
                            : null
                        )
                    }
                </div>
            }
        </div>
    </>
  )
}

export default RaffleSubscribe