import React, { useEffect, useState } from 'react';
import admin from '../../../admin.module.css';
import * as adminApi from '../../../../apis/admin';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Select } from 'antd';
import { format } from 'date-fns';
import { reservationSelect } from '../../../../apis/auth';

const { Option } = Select;

const AdminPopularSection = () => {
    const MySwal = withReactContent(Swal);
    const [activeButton, setActiveButton] = useState('product');
    const [showProduct, setShowProduct] = useState(false);
    const [showSupport, setShowSupport] = useState(false);
    const [showReview, setShowReview] = useState(false);
    const [productList, setProductList] = useState([]);
    const [supportList, setSupportList] = useState([]);
    const [reviewList, setReviewList] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [selectedSupports, setSelectedSupports] = useState([]);
    const [selectedReviews, setSelectedReviews] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchSupportTerm, setSearchSupportTerm] = useState('');
    const [searchReviewTerm, setSearchReviewTerm] = useState('');
    const [activePopularProductState, setActivePopularProductState] = useState('user');
    const [activeReservationProductState, setActiveReservationProductState] = useState('user');
    const [userPopularList, setUserPopularList] = useState([]);
    const [autoPopularList, setAutoPopularList] = useState([]);
    const [discountProduct, setDiscountProduct] = useState([]);
    const [recomandProduct, setRecomandProduct] = useState([]);
    const [popularReviewList, setPopularReviewList] = useState([]);
    const [popularCustomSupportList, setPopularCustomSupportList] = useState([]);
    const [popularAutoSupportList, setPopularAutoSupportList] = useState([]);

    useEffect(() => {
        // console.log('selectedReviews : ', selectedReviews);
    }, [selectedReviews]);

    useEffect(() => {
        productSelect();
        supportSelect();
        reviewItemSelect();
        customProductSelect();
        customAutoProductSelect();
        discountProductSelect();
        recomandProductSelect();
        popularCustomSupportSelect();
        popularAutoSupportSelect();
        popularReviewSelect();
    }, []);

    const productSelect = async () => {
        const response = await adminApi.totalProductSelect();
        const data = response.data;
    
        // enabledState가 "승인거절"인 항목을 제외
        const filteredData = data.filter(product => product.enabledState !== "승인거절");
    
        // console.log('Filtered products: ', filteredData);
        setProductList(filteredData);
    };

    const supportSelect = async () => {
        const response = await adminApi.totalSupportSelect();
        const data = response.data;
        
        // console.log('supportProduct : ', data);
        setSupportList(data);
    };

    const reviewItemSelect = async () => {
        const response = await adminApi.reviewItemSelect();
        const data = response.data;
    
        const supportResponse = await adminApi.reviewSupportItemSelect();
        const supportData = supportResponse.data;
        
        // console.log('reviews : ', data);
        // console.log('supportData : ', supportData);
        
        // 두 배열을 병합
        const combinedData = [...data, ...supportData];
    
        // 상태로 설정
        setReviewList(combinedData);
    };

    const customProductSelect = async () => {
        const response = await adminApi.customProductSelect();
        const data = response.data;

        // console.log('custom : ', data);
        // popularExposureOrder 순으로 정렬
        const sortedList = data.sort((a, b) => a.popularExposureOrder - b.popularExposureOrder);
        setUserPopularList(sortedList);
    };
    const customAutoProductSelect = async () => {
        const response = await adminApi.customAutoProductSelect();
        const data = response.data;
        
        // console.log('customAuto : ', data);
        // popularExposureOrder 순으로 정렬
        const sortedList = data.sort((a, b) => a.popularExposureOrder - b.popularExposureOrder);
        setAutoPopularList(sortedList);
    };

    const discountProductSelect = async () => {
        const response = await adminApi.discountProductSelect();
        const data = response.data;

        // console.log('custom : ', data);
        // popularExposureOrder 순으로 정렬
        const sortedList = data.sort((a, b) => a.discountExposureOrder - b.discountExposureOrder);
        setDiscountProduct(sortedList);
    };

    const recomandProductSelect = async () => {
        const response = await adminApi.recomandProductSelect();
        const data = response.data;

        // console.log('custom : ', data);
        // popularExposureOrder 순으로 정렬
        const sortedList = data.sort((a, b) => a.recomandExposureOrder - b.recomandExposureOrder);
        setRecomandProduct(sortedList);
    };

    const popularCustomSupportSelect = async () => {
        const response = await adminApi.popularCustomSupportSelect();
        const data = response.data;

        // console.log('popularCustomSupportSelect : ', data);
        setPopularCustomSupportList(data);
    }

    const popularAutoSupportSelect = async () => {
        const response = await adminApi.popularAutoSupportSelect();
        const data = response.data;

        // console.log('support : ', data);
        setPopularAutoSupportList(data);
    }
    const popularReviewSelect = async () => {
        const response = await adminApi.popularReviewSelect();
        const data = response.data;
    
        // console.log('popularReviewSelect : ', data);
    
        if (Array.isArray(data)) {
            // popularExposureOrder 순으로 정렬
            const sortedList = data.sort((a, b) => a.reviewExposureOrder - b.reviewExposureOrder);
            setPopularReviewList(sortedList);
        } else {
            console.error('Data is not an array:', data);
        }
    };
    

    const handleCheckboxChange = (item) => {
        setSelectedProducts((prevSelectedProducts) => {
            const isAlreadySelected = prevSelectedProducts.some(product => product.productNo === item.productNo);
            // console.log('isAlreadySelected : ', isAlreadySelected);

            if (isAlreadySelected) {
                return prevSelectedProducts.filter(product => product.productNo !== item.productNo);
            } else {
                return [...prevSelectedProducts, { ...item, popularSetting: 'custom' }];
            }
        });
    };
    const handleSupportCheckboxChange = (item) => {
        // console.log('supportItem : ', item);
        setSelectedSupports((prevSelectedSupports) => {
            // console.log('prevSelectedSupports : ', prevSelectedSupports);
            const isAlreadySelected = prevSelectedSupports.some(support => support.productNo === item.productNo);
            // console.log('isAlreadySelected : ', isAlreadySelected);

            if (isAlreadySelected) {
                return prevSelectedSupports.filter(support => support.productNo !== item.productNo);
            } else {
                return [...prevSelectedSupports, { ...item, reservationSetting: 'custom' }];
            }
        });
    };
    const handleReviewCheckboxChange = (item) => {
        // console.log('reviewItem : ', item);
        setSelectedReviews((prevSelectedReviews) => {
            // console.log('prevSelectedReviews : ', prevSelectedReviews);
            const isAlreadySelected = prevSelectedReviews.some(review => review.storeReviewNo === item.storeReviewNo);
            // console.log('isAlreadySelected : ', isAlreadySelected);

            if (isAlreadySelected) {
                return prevSelectedReviews.filter(review => review.storeReviewNo !== item.storeReviewNo);
            } else {
                return [...prevSelectedReviews, { ...item, reviewSetting: 'custom' }];
            }
        });
    };

    const handleExposureChange = (productNo) => {
        setSelectedProducts((prevSelectedProducts) => 
            prevSelectedProducts.map(item => 
                item.productNo === productNo 
                    ? { ...item, popularExposure: !item.popularExposure } 
                    : item
            )
        );
    };

    const handleSupportExposureChange = (productNo) => {
        setSelectedSupports((prevSelectedSupports) => 
            prevSelectedSupports.map(item => 
                item.productNo === productNo 
                    ? { ...item, reservationExposure: !item.reservationExposure } 
                    : item
            )
        );
    };

    const handleReviewExposureChange = (storeReviewNo) => {
        setSelectedReviews((prevSelectedReviews) => 
            prevSelectedReviews.map(item => 
                item.storeReviewNo === storeReviewNo 
                    ? { ...item, reviewExposure: !item.reviewExposure } 
                    : item
            )
        );
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };
    const handleSupportSearchChange = (event) => {
        setSearchSupportTerm(event.target.value);
    };
    const handleReviewSearchChange = (event) => {
        setSearchReviewTerm(event.target.value);
    };

    const filteredProductList = productList.filter((item) => {
        const searchLower = searchTerm.toLowerCase();
        return (
            item.productName.toLowerCase().includes(searchLower) ||
            item.productPrice.toString().includes(searchLower) ||
            item.productsalePrice.toString().includes(searchLower) ||
            item.saleState.toLowerCase().includes(searchLower) ||
            item.userId.toLowerCase().includes(searchLower) ||
            item.productNumber.toString().includes(searchLower) ||
            item.mainCategory.toLowerCase().includes(searchLower) ||
            item.middleCategory.toLowerCase().includes(searchLower) ||
            item.enabledState.toLowerCase().includes(searchLower)
        );
    });
    const filteredSupportList = supportList.filter((item) => {
        // console.log('supportItemFilter : ', item);
        const searchLower = searchSupportTerm.toLowerCase();
        return (
            item.productName.toLowerCase().includes(searchLower) ||
            item.shopName.toString().includes(searchLower) ||
            item.productNumber.toString().includes(searchLower) ||
            item.userId.toLowerCase().includes(searchLower) ||
            item.productNumber.toString().includes(searchLower) ||
            item.mainCategory.toLowerCase().includes(searchLower) ||
            item.middleCategory.toLowerCase().includes(searchLower) ||
            item.supportState.toLowerCase().includes(searchLower)
        );
    });

    const filteredReviewList = reviewList.filter((item) => {
        // console.log('reviewItemFilter : ', item);
        const searchLower = searchReviewTerm.toLowerCase();
        
        // Check if search term is a number
        const isNumericSearch = !isNaN(searchReviewTerm);
        
        return (
            (item.rating && item.rating.toString().toLowerCase().includes(searchLower)) || // Check if item.rating exists before calling methods
            (item.sellerId && item.sellerId.toString().includes(searchLower)) ||
            (item.reviewText && item.reviewText.toLowerCase().includes(searchLower)) ||
            (item.userId && item.userId.toLowerCase().includes(searchLower)) ||
            (item.productNumber && item.productNumber.toString().includes(searchLower)) ||
            (item.storeReceiveReview && item.storeReceiveReview.toLowerCase().includes(searchLower)) ||
            (item.reviewRegDate && item.reviewRegDate.toLowerCase().includes(searchLower)) ||
            (isNumericSearch && (
                (item.rating && item.rating.toString().includes(searchReviewTerm)) || // Check if item.rating exists
                (item.sellerId && item.sellerId.toString().includes(searchReviewTerm)) ||
                (item.productNumber && item.productNumber.toString().includes(searchReviewTerm))
            ))
        );
    });
    

    const handleExposureToggle = async (popularProductNo, newExposureStatus) => {
        // console.log('popularProductNo : ', popularProductNo);
        // console.log('newExposureStatus : ', newExposureStatus);

        const response = await adminApi.updatePopularExposure(popularProductNo, newExposureStatus);
        const data = response.data;

        if(data === 'SUCCESS') {
            if(newExposureStatus === false) {
                alert('숨김처리 되었습니다.');
            } else {
                alert('노출처리 되었습니다.');
            }
            setUserPopularList(prevList => 
                prevList.map(item => 
                    item.popularProductNo === popularProductNo 
                        ? { ...item, popularExposure: newExposureStatus }
                        : item
                )
            );

            customProductSelect();
        }

    };

    const handleReservationCustomExposureToggle = async (popularSupportNo, newExposureStatus) => {
        // console.log('popularSupportNo : ', popularSupportNo);
        // console.log('newExposureStatus : ', newExposureStatus);

        const response = await adminApi.updateReservationCustomPopularExposure(popularSupportNo, newExposureStatus);
        const data = response.data;

        if(data === 'SUCCESS') {
            if(newExposureStatus === false) {
                alert('숨김처리 되었습니다.');
            } else {
                alert('노출처리 되었습니다.');
            }
            setPopularCustomSupportList(prevList => 
                prevList.map(item => 
                    item.popularSupportNo === popularSupportNo 
                        ? { ...item, reservationExposure: newExposureStatus }
                        : item
                )
            );

            popularCustomSupportSelect();
        }

    };
    const handleReservationAutoExposureToggle = async (popularSupportNo, newExposureStatus) => {
        // console.log('popularSupportNo : ', popularSupportNo);
        // console.log('newExposureStatus : ', newExposureStatus);

        const response = await adminApi.updateReservationAutoPopularExposure(popularSupportNo, newExposureStatus);
        const data = response.data;

        if(data === 'SUCCESS') {
            if(newExposureStatus === false) {
                alert('숨김처리 되었습니다.');
            } else {
                alert('노출처리 되었습니다.');
            }
            setPopularAutoSupportList(prevList => 
                prevList.map(item => 
                    item.popularSupportNo === popularSupportNo 
                        ? { ...item, reservationExposure: newExposureStatus }
                        : item
                )
            );

            popularAutoSupportSelect();
        }

    };

    const handleDiscountExposureToggle = async (discountProductNo, newExposureStatus) => {
        // console.log('discountProductNo : ', discountProductNo);
        // console.log('newExposureStatus : ', newExposureStatus);

        const response = await adminApi.updateDiscountExposure(discountProductNo, newExposureStatus);
        const data = response.data;

        if(data === 'SUCCESS') {
            if(newExposureStatus === false) {
                alert('숨김처리 되었습니다.');
            } else {
                alert('노출처리 되었습니다.');
            }
            setDiscountProduct(prevList => 
                prevList.map(item => 
                    item.discountProductNo === discountProductNo 
                        ? { ...item, discountExposure: newExposureStatus }
                        : item
                )
            );

            discountProductSelect();
        }

    };

    const handleRecomandExposureToggle = async (recomandProductNo, newExposureStatus) => {
        // console.log('recomandProductNo : ', recomandProductNo);
        // console.log('newExposureStatus : ', newExposureStatus);

        const response = await adminApi.updateRecomandExposure(recomandProductNo, newExposureStatus);
        const data = response.data;

        if(data === 'SUCCESS') {
            if(newExposureStatus === false) {
                alert('숨김처리 되었습니다.');
            } else {
                alert('노출처리 되었습니다.');
            }
            setRecomandProduct(prevList => 
                prevList.map(item => 
                    item.recomandProductNo === recomandProductNo 
                        ? { ...item, recomandExposure: newExposureStatus }
                        : item
                )
            );

            recomandProductSelect();
        }

    };

    const handleReviewExposureToggle = async (popularReviewNo, newExposureStatus) => {
        // console.log('popularReviewNo : ', popularReviewNo);
        // console.log('newExposureStatus : ', newExposureStatus);

        const response = await adminApi.updateReviewExposure(popularReviewNo, newExposureStatus);
        const data = response.data;

        if(data === 'SUCCESS') {
            if(newExposureStatus === false) {
                alert('숨김처리 되었습니다.');
            } else {
                alert('노출처리 되었습니다.');
            }
            setPopularReviewList(prevList => 
                prevList.map(item => 
                    item.popularReviewNo === popularReviewNo 
                        ? { ...item, reviewExposure: newExposureStatus }
                        : item
                )
            );

            popularReviewSelect();
        }

    };

    const popularProductAdd = () => {
        MySwal.fire({
            customClass: {
              popup: `${admin.admin_seller_add_popup}`
            },
            html: `
                  <div>
                      <div class='${admin.admin_seller_add_title}'><span>인기상품을 등록하시겠습니까?</span></div>
                      <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                        <button class='mx-1' id='seller_add_cancel'>취소</button>
                        <button class='mx-1' id='seller_add_success'>등록</button>
                      </div>
                  </div>
              `,
            showConfirmButton: false,
            showCancelButton: false,
          });
          document.addEventListener('click', sellerAddSuccess);
          document.addEventListener('click', sellerAddCancel);
      
          async function sellerAddSuccess(e) {
            if (e.target && e.target.id === 'seller_add_success') {
              try {
                const response = await adminApi.popularProductAdd(selectedProducts);
                const data = response.data;

                // console.log(data);
      
                if (data === 'SUCCESS') {
                  MySwal.fire({
                    customClass: {
                      popup: `${admin.admin_seller_add_popup}`
                    },
                    html: `
                              <div>
                              <div class='${admin.admin_seller_add_title}'><span>인기상품 등록이 완료되었습니다.</span></div>
                              <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                              <button class='mx-1' id='seller_add_confirm'>확인</button>
                              </div>
                              </div>
                              `,
                    showConfirmButton: false,
                    showCancelButton: false,
                  });
                  document.addEventListener('click', sellerAddConfirm);
      
                  function sellerAddConfirm(e) {
                    if (e.target && e.target.id === 'seller_add_confirm') {
                        customProductSelect();
                        setShowProduct(false);
                        MySwal.close();
                        document.removeEventListener('click', sellerAddConfirm);
                        document.removeEventListener('click', sellerAddSuccess);
                        document.removeEventListener('click', sellerAddCancel);
                    }
                  }
                } else {
                  MySwal.fire({
                    customClass: {
                      popup: `${admin.admin_seller_add_popup}`
                    },
                    html: `
                              <div>
                              <div class='${admin.admin_seller_add_title}'><span>인기상품 등록에 실패하였습니다.</span></div>
                              <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                              <button class='mx-1' id='seller_add_confirm'>확인</button>
                              </div>
                              </div>
                              `,
                    showConfirmButton: false,
                    showCancelButton: false,
                  });
                  document.addEventListener('click', sellerAddConfirm);
      
                  function sellerAddConfirm(e) {
                    if (e.target && e.target.id === 'seller_add_confirm') {
                      MySwal.close();
                      document.removeEventListener('click', sellerAddConfirm);
                      document.removeEventListener('click', sellerAddSuccess);
                      document.removeEventListener('click', sellerAddCancel);
                    }
                  }
                }
              } catch (error) {
                console.error('error : ', error);
              }
            }
          }
      
          function sellerAddCancel(e) {
            if (e.target && e.target.id === 'seller_add_cancel') {
              MySwal.close();
              document.removeEventListener('click', sellerAddSuccess);
              document.removeEventListener('click', sellerAddCancel);
            }
          }
    }
    const discountProductAdd = () => {
        MySwal.fire({
            customClass: {
              popup: `${admin.admin_seller_add_popup}`
            },
            html: `
                  <div>
                      <div class='${admin.admin_seller_add_title}'><span>할인상품을 등록하시겠습니까?</span></div>
                      <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                        <button class='mx-1' id='seller_add_cancel'>취소</button>
                        <button class='mx-1' id='seller_add_success'>등록</button>
                      </div>
                  </div>
              `,
            showConfirmButton: false,
            showCancelButton: false,
          });
          document.addEventListener('click', sellerAddSuccess);
          document.addEventListener('click', sellerAddCancel);
      
          async function sellerAddSuccess(e) {
            if (e.target && e.target.id === 'seller_add_success') {
              try {
                const response = await adminApi.discountProductAdd(selectedProducts);
                const data = response.data;

                // console.log(data);
      
                if (data === 'SUCCESS') {
                  MySwal.fire({
                    customClass: {
                      popup: `${admin.admin_seller_add_popup}`
                    },
                    html: `
                              <div>
                              <div class='${admin.admin_seller_add_title}'><span>할인상품 등록이 완료되었습니다.</span></div>
                              <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                              <button class='mx-1' id='seller_add_confirm'>확인</button>
                              </div>
                              </div>
                              `,
                    showConfirmButton: false,
                    showCancelButton: false,
                  });
                  document.addEventListener('click', sellerAddConfirm);
      
                  function sellerAddConfirm(e) {
                    if (e.target && e.target.id === 'seller_add_confirm') {
                        customProductSelect();
                        setShowProduct(false);
                        MySwal.close();
                        document.removeEventListener('click', sellerAddConfirm);
                        document.removeEventListener('click', sellerAddSuccess);
                        document.removeEventListener('click', sellerAddCancel);
                    }
                  }
                } else {
                  MySwal.fire({
                    customClass: {
                      popup: `${admin.admin_seller_add_popup}`
                    },
                    html: `
                              <div>
                              <div class='${admin.admin_seller_add_title}'><span>할인상품 등록에 실패하였습니다.</span></div>
                              <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                              <button class='mx-1' id='seller_add_confirm'>확인</button>
                              </div>
                              </div>
                              `,
                    showConfirmButton: false,
                    showCancelButton: false,
                  });
                  document.addEventListener('click', sellerAddConfirm);
      
                  function sellerAddConfirm(e) {
                    if (e.target && e.target.id === 'seller_add_confirm') {
                      MySwal.close();
                      document.removeEventListener('click', sellerAddConfirm);
                      document.removeEventListener('click', sellerAddSuccess);
                      document.removeEventListener('click', sellerAddCancel);
                    }
                  }
                }
              } catch (error) {
                console.error('error : ', error);
              }
            }
          }
      
          function sellerAddCancel(e) {
            if (e.target && e.target.id === 'seller_add_cancel') {
              MySwal.close();
              document.removeEventListener('click', sellerAddSuccess);
              document.removeEventListener('click', sellerAddCancel);
            }
          }
    }

    const recomandProductAdd = () => {
        MySwal.fire({
            customClass: {
              popup: `${admin.admin_seller_add_popup}`
            },
            html: `
                  <div>
                      <div class='${admin.admin_seller_add_title}'><span>추천상품을 등록하시겠습니까?</span></div>
                      <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                        <button class='mx-1' id='seller_add_cancel'>취소</button>
                        <button class='mx-1' id='seller_add_success'>등록</button>
                      </div>
                  </div>
              `,
            showConfirmButton: false,
            showCancelButton: false,
          });
          document.addEventListener('click', sellerAddSuccess);
          document.addEventListener('click', sellerAddCancel);
      
          async function sellerAddSuccess(e) {
            if (e.target && e.target.id === 'seller_add_success') {
              try {
                const response = await adminApi.recomandProductAdd(selectedProducts);
                const data = response.data;

                // console.log(data);
      
                if (data === 'SUCCESS') {
                  MySwal.fire({
                    customClass: {
                      popup: `${admin.admin_seller_add_popup}`
                    },
                    html: `
                              <div>
                              <div class='${admin.admin_seller_add_title}'><span>추천상품 등록이 완료되었습니다.</span></div>
                              <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                              <button class='mx-1' id='seller_add_confirm'>확인</button>
                              </div>
                              </div>
                              `,
                    showConfirmButton: false,
                    showCancelButton: false,
                  });
                  document.addEventListener('click', sellerAddConfirm);
      
                  function sellerAddConfirm(e) {
                    if (e.target && e.target.id === 'seller_add_confirm') {
                        recomandProductSelect();
                        setShowProduct(false);
                        MySwal.close();
                        document.removeEventListener('click', sellerAddConfirm);
                        document.removeEventListener('click', sellerAddSuccess);
                        document.removeEventListener('click', sellerAddCancel);
                    }
                  }
                } else {
                  MySwal.fire({
                    customClass: {
                      popup: `${admin.admin_seller_add_popup}`
                    },
                    html: `
                              <div>
                              <div class='${admin.admin_seller_add_title}'><span>추천상품 등록에 실패하였습니다.</span></div>
                              <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                              <button class='mx-1' id='seller_add_confirm'>확인</button>
                              </div>
                              </div>
                              `,
                    showConfirmButton: false,
                    showCancelButton: false,
                  });
                  document.addEventListener('click', sellerAddConfirm);
      
                  function sellerAddConfirm(e) {
                    if (e.target && e.target.id === 'seller_add_confirm') {
                      MySwal.close();
                      document.removeEventListener('click', sellerAddConfirm);
                      document.removeEventListener('click', sellerAddSuccess);
                      document.removeEventListener('click', sellerAddCancel);
                    }
                  }
                }
              } catch (error) {
                console.error('error : ', error);
              }
            }
          }
      
          function sellerAddCancel(e) {
            if (e.target && e.target.id === 'seller_add_cancel') {
              MySwal.close();
              document.removeEventListener('click', sellerAddSuccess);
              document.removeEventListener('click', sellerAddCancel);
            }
          }
    }
    const popularSupportAdd = () => {
        MySwal.fire({
            customClass: {
              popup: `${admin.admin_seller_add_popup}`
            },
            html: `
                  <div>
                      <div class='${admin.admin_seller_add_title}'><span>인기 예약상품을 등록하시겠습니까?</span></div>
                      <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                        <button class='mx-1' id='seller_add_cancel'>취소</button>
                        <button class='mx-1' id='seller_add_success'>등록</button>
                      </div>
                  </div>
              `,
            showConfirmButton: false,
            showCancelButton: false,
          });
          document.addEventListener('click', sellerAddSuccess);
          document.addEventListener('click', sellerAddCancel);
      
          async function sellerAddSuccess(e) {
            if (e.target && e.target.id === 'seller_add_success') {
              try {
                const response = await adminApi.popularSupportAdd(selectedSupports);
                const data = response.data;

                // console.log(data);
      
                if (data === 'SUCCESS') {
                  MySwal.fire({
                    customClass: {
                      popup: `${admin.admin_seller_add_popup}`
                    },
                    html: `
                              <div>
                              <div class='${admin.admin_seller_add_title}'><span>인기 예약상품 등록이 완료되었습니다.</span></div>
                              <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                              <button class='mx-1' id='seller_add_confirm'>확인</button>
                              </div>
                              </div>
                              `,
                    showConfirmButton: false,
                    showCancelButton: false,
                  });
                  document.addEventListener('click', sellerAddConfirm);
      
                  function sellerAddConfirm(e) {
                    if (e.target && e.target.id === 'seller_add_confirm') {
                        customProductSelect();
                        setShowProduct(false);
                        MySwal.close();
                        document.removeEventListener('click', sellerAddConfirm);
                        document.removeEventListener('click', sellerAddSuccess);
                        document.removeEventListener('click', sellerAddCancel);
                    }
                  }
                } else {
                  MySwal.fire({
                    customClass: {
                      popup: `${admin.admin_seller_add_popup}`
                    },
                    html: `
                              <div>
                              <div class='${admin.admin_seller_add_title}'><span>인기 예약상품 등록에 실패하였습니다.</span></div>
                              <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                              <button class='mx-1' id='seller_add_confirm'>확인</button>
                              </div>
                              </div>
                              `,
                    showConfirmButton: false,
                    showCancelButton: false,
                  });
                  document.addEventListener('click', sellerAddConfirm);
      
                  function sellerAddConfirm(e) {
                    if (e.target && e.target.id === 'seller_add_confirm') {
                      MySwal.close();
                      document.removeEventListener('click', sellerAddConfirm);
                      document.removeEventListener('click', sellerAddSuccess);
                      document.removeEventListener('click', sellerAddCancel);
                    }
                  }
                }
              } catch (error) {
                console.error('error : ', error);
              }
            }
          }
      
          function sellerAddCancel(e) {
            if (e.target && e.target.id === 'seller_add_cancel') {
              MySwal.close();
              document.removeEventListener('click', sellerAddSuccess);
              document.removeEventListener('click', sellerAddCancel);
            }
          }
    }

    const popularReviewAdd = () => {
        MySwal.fire({
            customClass: {
              popup: `${admin.admin_seller_add_popup}`
            },
            html: `
                  <div>
                      <div class='${admin.admin_seller_add_title}'><span>인기 리뷰를 등록하시겠습니까?</span></div>
                      <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                        <button class='mx-1' id='seller_add_cancel'>취소</button>
                        <button class='mx-1' id='seller_add_success'>등록</button>
                      </div>
                  </div>
              `,
            showConfirmButton: false,
            showCancelButton: false,
          });
          document.addEventListener('click', sellerAddSuccess);
          document.addEventListener('click', sellerAddCancel);
      
          async function sellerAddSuccess(e) {
            if (e.target && e.target.id === 'seller_add_success') {
              try {
                const response = await adminApi.popularReviewAdd(selectedReviews);
                const data = response.data;

                // console.log(data);
      
                if (data === 'SUCCESS') {
                  MySwal.fire({
                    customClass: {
                      popup: `${admin.admin_seller_add_popup}`
                    },
                    html: `
                              <div>
                              <div class='${admin.admin_seller_add_title}'><span>인기 리뷰 등록이 완료되었습니다.</span></div>
                              <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                              <button class='mx-1' id='seller_add_confirm'>확인</button>
                              </div>
                              </div>
                              `,
                    showConfirmButton: false,
                    showCancelButton: false,
                  });
                  document.addEventListener('click', sellerAddConfirm);
      
                  function sellerAddConfirm(e) {
                    if (e.target && e.target.id === 'seller_add_confirm') {
                        // reviewItemSelect();
                        // setShowReview(false);
                        MySwal.close();
                        document.removeEventListener('click', sellerAddConfirm);
                        document.removeEventListener('click', sellerAddSuccess);
                        document.removeEventListener('click', sellerAddCancel);
                    }
                  }
                } else {
                  MySwal.fire({
                    customClass: {
                      popup: `${admin.admin_seller_add_popup}`
                    },
                    html: `
                              <div>
                              <div class='${admin.admin_seller_add_title}'><span>인기 리뷰 등록에 실패하였습니다.</span></div>
                              <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                              <button class='mx-1' id='seller_add_confirm'>확인</button>
                              </div>
                              </div>
                              `,
                    showConfirmButton: false,
                    showCancelButton: false,
                  });
                  document.addEventListener('click', sellerAddConfirm);
      
                  function sellerAddConfirm(e) {
                    if (e.target && e.target.id === 'seller_add_confirm') {
                      MySwal.close();
                      document.removeEventListener('click', sellerAddConfirm);
                      document.removeEventListener('click', sellerAddSuccess);
                      document.removeEventListener('click', sellerAddCancel);
                    }
                  }
                }
              } catch (error) {
                console.error('error : ', error);
              }
            }
          }
      
          function sellerAddCancel(e) {
            if (e.target && e.target.id === 'seller_add_cancel') {
              MySwal.close();
              document.removeEventListener('click', sellerAddSuccess);
              document.removeEventListener('click', sellerAddCancel);
            }
          }
    }

    const formatDate = (date) => {
        return format(new Date(date), 'yyyy-MM-dd HH:mm:ss');
    };

    const handleOrderChange = async (productNo, newOrder) => {
        // 순서 변경 처리 로직
        // console.log('productNo : ', productNo);
        // console.log('newOrder : ', newOrder);

        const response = await adminApi.popularOrderChange(productNo, newOrder);
        const data = response.data;

        // console.log('data : ', data);

        if(data === 'SUCCESS') {
            alert('순서가 변경되었습니다.');
            customProductSelect();
        }
    };
    const handleDiscountOrderChange = async (productNo, newOrder) => {
        // 순서 변경 처리 로직
        // console.log('productNo : ', productNo);
        // console.log('newOrder : ', newOrder);

        const response = await adminApi.discountOrderChange(productNo, newOrder);
        const data = response.data;

        // console.log('data : ', data);

        if(data === 'SUCCESS') {
            alert('순서가 변경되었습니다.');
            discountProductSelect();
        }
    };
    const handleRecomandOrderChange = async (productNo, newOrder) => {
        // 순서 변경 처리 로직
        // console.log('productNo : ', productNo);
        // console.log('newOrder : ', newOrder);

        const response = await adminApi.recomandOrderChange(productNo, newOrder);
        const data = response.data;

        // console.log('data : ', data);

        if(data === 'SUCCESS') {
            alert('순서가 변경되었습니다.');
            recomandProductSelect();
        }
    };
    const handleReservationCustomOrderChange = async (productNo, newOrder) => {
        // 순서 변경 처리 로직
        // console.log('productNo : ', productNo);
        // console.log('newOrder : ', newOrder);

        const response = await adminApi.reservationCustomOrderChange(productNo, newOrder);
        const data = response.data;

        // console.log('data : ', data);

        if(data === 'SUCCESS') {
            alert('순서가 변경되었습니다.');
            popularCustomSupportSelect();
        }
    };
    const handleReservationAutoOrderChange = async (productNo, newOrder) => {
        // 순서 변경 처리 로직
        // console.log('productNo : ', productNo);
        // console.log('newOrder : ', newOrder);

        const response = await adminApi.reservationAutoOrderChange(productNo, newOrder);
        const data = response.data;

        // console.log('data : ', data);

        if(data === 'SUCCESS') {
            alert('순서가 변경되었습니다.');
            popularAutoSupportSelect();
        }
    };

    const handleReviewOrderChange = async (productNo, newOrder) => {
        // 순서 변경 처리 로직
        // console.log('productNo : ', productNo);
        // console.log('newOrder : ', newOrder);

        const response = await adminApi.reviewOrderChange(productNo, newOrder);
        const data = response.data;

        // console.log('data : ', data);

        if(data === 'SUCCESS') {
            alert('순서가 변경되었습니다.');
            popularReviewSelect();
        }
    };

    return (
        <div className={`${admin.admin_operate_popular_box} px-3 py-3`}>
            <div>
                <div className={`${admin.admin_operate_popular_header}`}>
                    <div><span>인기섹션관리</span></div>
                    <div><span>*메인화면에 노출되는 영역입니다.</span></div>
                </div>
                <div className={`${admin.admin_operate_popular_tab}`}>
                    <div className={activeButton ==='product' ? admin.admin_operate_popular_tab_active : ''}><button onClick={() => setActiveButton('product')}>상품</button></div>
                    <div className={activeButton ==='discount' ? admin.admin_operate_popular_tab_active : ''}><button onClick={() => setActiveButton('discount')}>할인상품</button></div>
                    <div className={activeButton ==='reservation' ? admin.admin_operate_popular_tab_active : ''}><button onClick={() => setActiveButton('reservation')}>예약</button></div>
                    <div className={activeButton ==='demand' ? admin.admin_operate_popular_tab_active : ''}><button onClick={() => setActiveButton('demand')}>수요조사</button></div>
                    <div className={activeButton ==='review' ? admin.admin_operate_popular_tab_active : ''}><button onClick={() => setActiveButton('review')}>리뷰</button></div>
                    <div className={activeButton ==='recomand' ? admin.admin_operate_popular_tab_active : ''}><button onClick={() => setActiveButton('recomand')}>추천상품</button></div>
                </div>
                {
                    activeButton === 'product' &&
                    <div className={`${admin.admin_operate_popular_content}`}>
                        <div className={`${admin.admin_operate_popular_content_btn}`}><button onClick={() => setShowProduct(true)}>상품 직접등록</button></div>
                        <div className={`${admin.admin_operate_popular_content_item_box}`}>
                            <div className={`${admin.admin_operate_popular_product_item_tap}`}>
                                <div className={activePopularProductState === 'user' ? admin.admin_operate_popular_product_item_tap_active : ''}><button onClick={() => setActivePopularProductState('user')}>직접등록상품</button></div>
                                <div className={activePopularProductState === 'auto' ? admin.admin_operate_popular_product_item_tap_active : ''}><button onClick={() => setActivePopularProductState('auto')}>자동등록상품</button></div>
                            </div>
                            {
                                activePopularProductState === 'user' &&
                                <div>
                                    <div className={`${admin.admin_operate_popular_content_item_header}`}>
                                        <div><span>상품정보</span></div>
                                        <div><span>노출여부</span></div>
                                        <div><span>노출순서</span></div>
                                        <div><span>등록일자</span></div>
                                        <div><span></span></div>
                                    </div>
                                    {
                                        userPopularList.map((item, index) => (
                                            <div className={`${admin.admin_operate_popular_content_item_content}`} key={index}>
                                                <div>
                                                    <div><img src={item.productMainImage} alt="" /></div>
                                                    <div>
                                                        <div><span>{item.productNumber}</span></div>
                                                        <div><span>{item.productName}</span></div>
                                                        <div><span>상품금액 : </span><span>{item.productPrice.toLocaleString()}원</span></div>
                                                        <div><span>할인금액 : </span><span>{item.productsalePrice.toLocaleString()}원</span></div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <label className={`${admin.admin_active_switch}`}>
                                                        <input 
                                                            type="checkbox" 
                                                            checked={item.popularExposure} 
                                                            onChange={() => handleExposureToggle(item.popularProductNo, !item.popularExposure)} 
                                                        />
                                                        <span className={`${admin.admin_active_slider} ${admin.admin_active_round}`}></span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <Select
                                                        value={item.popularExposureOrder}
                                                        onChange={(newOrder) => handleOrderChange(item.popularProductNo, newOrder)}
                                                    >
                                                        {userPopularList.map((_, orderIndex) => (
                                                            <Option key={orderIndex + 1} value={orderIndex + 1}>
                                                                {orderIndex + 1}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                </div>
                                                <div><span>{formatDate(item.regDate)}</span></div>
                                                <div>
                                                    <button>삭제</button>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            }
                            {
                                activePopularProductState === 'auto' &&
                                <div>
                                    <div className={`${admin.admin_operate_popular_content_item_header}`}>
                                        <div><span>상품정보</span></div>
                                        <div><span>노출여부</span></div>
                                        <div><span>노출순서</span></div>
                                        <div><span>등록일자</span></div>
                                        <div><span></span></div>
                                    </div>
                                    {
                                        autoPopularList.map((item, index) => (
                                            <div className={`${admin.admin_operate_popular_content_item_content}`} key={index}>
                                                <div>
                                                    <div><img src={item.productMainImage} alt="" /></div>
                                                    <div>
                                                        <div><span>{item.productNumber}</span></div>
                                                        <div><span>{item.productName}</span></div>
                                                        <div><span>상품금액 : </span><span>{item.productPrice.toLocaleString()}원</span></div>
                                                        <div><span>할인금액 : </span><span>{item.productsalePrice.toLocaleString()}원</span></div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <label className={`${admin.admin_active_switch}`}>
                                                        <input 
                                                            type="checkbox" 
                                                            checked={item.popularExposure} 
                                                            onChange={() => handleExposureToggle(item.popularProductNo, !item.popularExposure)} 
                                                        />
                                                        <span className={`${admin.admin_active_slider} ${admin.admin_active_round}`}></span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <Select
                                                        value={item.popularExposureOrder}
                                                        onChange={(newOrder) => handleOrderChange(item.popularProductNo, newOrder)}
                                                    >
                                                        {userPopularList.map((_, orderIndex) => (
                                                            <Option key={orderIndex + 1} value={orderIndex + 1}>
                                                                {orderIndex + 1}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                </div>
                                                <div><span>{formatDate(item.regDate)}</span></div>
                                                <div>
                                                    <button>삭제</button>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            }
                        </div>
                    </div>
                }
                {
                    activeButton === 'discount' &&
                    <div className={`${admin.admin_operate_popular_content}`}>
                        <div className={`${admin.admin_operate_popular_content_btn}`}><button onClick={() => setShowProduct(true)}>상품 등록</button></div>
                        <div className={`${admin.admin_operate_popular_content_item_box}`}>
                            <div>
                                <div className={`${admin.admin_operate_popular_content_item_header}`}>
                                    <div><span>상품정보</span></div>
                                    <div><span>노출여부</span></div>
                                    <div><span>노출순서</span></div>
                                    <div><span>등록일자</span></div>
                                    <div><span></span></div>
                                </div>
                                {
                                    discountProduct.map((item, index) => (
                                        <div className={`${admin.admin_operate_popular_content_item_content}`} key={index}>
                                            <div>
                                                <div><img src={item.productMainImage} alt="" /></div>
                                                <div>
                                                    <div><span>{item.productNumber}</span></div>
                                                    <div><span>{item.productName}</span></div>
                                                    <div><span>상품금액 : </span><span>{item.productPrice.toLocaleString()}원</span></div>
                                                    <div><span>할인금액 : </span><span>{item.productsalePrice.toLocaleString()}원</span></div>
                                                </div>
                                            </div>
                                            <div>
                                                <label className={`${admin.admin_active_switch}`}>
                                                    <input 
                                                        type="checkbox" 
                                                        checked={item.discountExposure} 
                                                        onChange={() => handleDiscountExposureToggle(item.discountProductNo, !item.discountExposure)} 
                                                    />
                                                    <span className={`${admin.admin_active_slider} ${admin.admin_active_round}`}></span>
                                                </label>
                                            </div>
                                            <div>
                                                <Select
                                                    value={item.discountExposureOrder}
                                                    onChange={(newOrder) => handleDiscountOrderChange(item.discountProductNo, newOrder)}
                                                >
                                                    {discountProduct.map((_, orderIndex) => (
                                                        <Option key={orderIndex + 1} value={orderIndex + 1}>
                                                            {orderIndex + 1}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </div>
                                            <div><span>{formatDate(item.regDate)}</span></div>
                                            <div>
                                                <button>삭제</button>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                }
                {
                    activeButton === 'reservation' &&
                    <div className={`${admin.admin_operate_popular_content}`}>
                        <div className={`${admin.admin_operate_popular_content_btn}`}><button onClick={() => setShowSupport(true)}>상품 직접등록</button></div>
                        <div className={`${admin.admin_operate_popular_content_item_box}`}>
                            <div className={`${admin.admin_operate_popular_product_item_tap}`}>
                                <div className={activeReservationProductState === 'user' ? admin.admin_operate_popular_product_item_tap_active : ''}><button onClick={() => setActiveReservationProductState('user')}>직접등록상품</button></div>
                                <div className={activeReservationProductState === 'auto' ? admin.admin_operate_popular_product_item_tap_active : ''}><button onClick={() => setActiveReservationProductState('auto')}>자동등록상품</button></div>
                            </div>
                            {
                                activeReservationProductState === 'user' &&
                                <div>
                                    <div className={`${admin.admin_operate_popular_content_item_header}`}>
                                        <div><span>상품정보</span></div>
                                        <div><span>노출여부</span></div>
                                        <div><span>노출순서</span></div>
                                        <div><span>등록일자</span></div>
                                        <div><span></span></div>
                                    </div>
                                    {
                                        popularCustomSupportList.map((item, index) => (
                                            <div className={`${admin.admin_operate_popular_content_item_content}`} key={index}>
                                                <div>
                                                    <div><img src={item.representativeImage} alt="" /></div>
                                                    <div>
                                                        <div><span>{item.productNumber}</span></div>
                                                        <div><span>{item.productName}</span></div>
                                                        <div>
                                                            {
                                                                item.mainItem.map((option, index) => (
                                                                    <div key={index}><span>{option.mainOptionName} : </span><span>{option.mainItemPrice.toLocaleString()}원</span></div>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <label className={`${admin.admin_active_switch}`}>
                                                        <input 
                                                            type="checkbox" 
                                                            checked={item.reservationExposure} 
                                                            onChange={() => handleReservationCustomExposureToggle(item.popularSupportNo, !item.reservationExposure)} 
                                                        />
                                                        <span className={`${admin.admin_active_slider} ${admin.admin_active_round}`}></span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <Select
                                                        value={item.reservationExposureOrder}
                                                        onChange={(newOrder) => handleReservationCustomOrderChange(item.popularSupportNo, newOrder)}
                                                    >
                                                        {userPopularList.map((_, orderIndex) => (
                                                            <Option key={orderIndex + 1} value={orderIndex + 1}>
                                                                {orderIndex + 1}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                </div>
                                                <div><span>{formatDate(item.supportRegDate)}</span></div>
                                                <div>
                                                    <button>삭제</button>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            }
                            {
                                activeReservationProductState === 'auto' &&
                                <div>
                                    <div className={`${admin.admin_operate_popular_content_item_header}`}>
                                        <div><span>상품정보</span></div>
                                        <div><span>노출여부</span></div>
                                        <div><span>노출순서</span></div>
                                        <div><span>등록일자</span></div>
                                        <div><span></span></div>
                                    </div>
                                    {
                                        popularAutoSupportList.map((item, index) => (
                                            <div className={`${admin.admin_operate_popular_content_item_content}`} key={index}>
                                                <div>
                                                    <div><img src={item.representativeImage} alt="" /></div>
                                                    <div>
                                                        <div><span>{item.productNumber}</span></div>
                                                        <div><span>{item.productName}</span></div>
                                                        <div>
                                                            {
                                                                item.mainItem.map((option, index) => (
                                                                    <div key={index}><span>{option.mainOptionName} : </span><span>{option.mainItemPrice.toLocaleString()}원</span></div>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <label className={`${admin.admin_active_switch}`}>
                                                        <input 
                                                            type="checkbox" 
                                                            checked={item.reservationExposure} 
                                                            onChange={() => handleReservationAutoExposureToggle(item.popularSupportNo, !item.reservationExposure)} 
                                                        />
                                                        <span className={`${admin.admin_active_slider} ${admin.admin_active_round}`}></span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <Select
                                                        value={item.reservationExposureOrder}
                                                        onChange={(newOrder) => handleReservationAutoOrderChange(item.popularSupportNo, newOrder)}
                                                    >
                                                        {popularAutoSupportList.map((_, orderIndex) => (
                                                            <Option key={orderIndex + 1} value={orderIndex + 1}>
                                                                {orderIndex + 1}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                </div>
                                                <div><span>{formatDate(item.supportRegDate)}</span></div>
                                                <div>
                                                    <button>삭제</button>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            }
                        </div>
                    </div>
                }
                {
                    activeButton === 'review' &&
                    <div className={`${admin.admin_operate_popular_content}`}>
                        <div className={`${admin.admin_operate_popular_content_btn}`}><button onClick={() => setShowReview(true)}>인기 리뷰 등록</button></div>
                        <div className={`${admin.admin_operate_popular_content_item_box}`}>
                            <div>
                                <div className={`${admin.admin_operate_popular_content_item_header}`}>
                                    <div><span>상품정보</span></div>
                                    <div><span>노출여부</span></div>
                                    <div><span>노출순서</span></div>
                                    <div><span>등록일자</span></div>
                                    <div><span></span></div>
                                </div>
                                {
                                    popularReviewList.map((item, index) => (
                                        <div className={`${admin.admin_operate_popular_content_item_content}`} key={index}>
                                            <div>
                                                <div><img src={item.photo1} alt="" /></div>
                                                <div>
                                                    <div><span>{item.productNumber}</span></div>
                                                    {/* <div><span>{item.productName}</span></div>
                                                    <div><span>상품금액 : </span><span>{item.productPrice.toLocaleString()}원</span></div>
                                                    <div><span>할인금액 : </span><span>{item.productsalePrice.toLocaleString()}원</span></div> */}
                                                </div>
                                            </div>
                                            <div>
                                                <label className={`${admin.admin_active_switch}`}>
                                                    <input 
                                                        type="checkbox" 
                                                        checked={item.reviewExposure} 
                                                        onChange={() => handleReviewExposureToggle(item.popularReviewNo, !item.reviewExposure)} 
                                                    />
                                                    <span className={`${admin.admin_active_slider} ${admin.admin_active_round}`}></span>
                                                </label>
                                            </div>
                                            <div>
                                                <Select
                                                    value={item.reviewExposureOrder}
                                                    onChange={(newOrder) => handleReviewOrderChange(item.popularReviewNo, newOrder)}
                                                >
                                                    {popularReviewList.map((_, orderIndex) => (
                                                        <Option key={orderIndex + 1} value={orderIndex + 1}>
                                                            {orderIndex + 1}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </div>
                                            <div><span>{formatDate(item.reviewRegDate)}</span></div>
                                            <div>
                                                <button>삭제</button>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                }
                {
                    activeButton === 'recomand' &&
                    <div className={`${admin.admin_operate_popular_content}`}>
                        <div className={`${admin.admin_operate_popular_content_btn}`}><button onClick={() => setShowProduct(true)}>상품 등록</button></div>
                        <div className={`${admin.admin_operate_popular_content_item_box}`}>
                            <div>
                                <div className={`${admin.admin_operate_popular_content_item_header}`}>
                                    <div><span>상품정보</span></div>
                                    <div><span>노출여부</span></div>
                                    <div><span>노출순서</span></div>
                                    <div><span>등록일자</span></div>
                                    <div><span></span></div>
                                </div>
                                {
                                    recomandProduct.map((item, index) => (
                                        <div className={`${admin.admin_operate_popular_content_item_content}`} key={index}>
                                            <div>
                                                <div><img src={item.productMainImage} alt="" /></div>
                                                <div>
                                                    <div><span>{item.productNumber}</span></div>
                                                    <div><span>{item.productName}</span></div>
                                                    <div><span>상품금액 : </span><span>{item.productPrice.toLocaleString()}원</span></div>
                                                    <div><span>할인금액 : </span><span>{item.productsalePrice.toLocaleString()}원</span></div>
                                                </div>
                                            </div>
                                            <div>
                                                <label className={`${admin.admin_active_switch}`}>
                                                    <input 
                                                        type="checkbox" 
                                                        checked={item.recomandExposure} 
                                                        onChange={() => handleRecomandExposureToggle(item.recomandProductNo, !item.recomandExposure)} 
                                                    />
                                                    <span className={`${admin.admin_active_slider} ${admin.admin_active_round}`}></span>
                                                </label>
                                            </div>
                                            <div>
                                                <Select
                                                    value={item.recomandExposureOrder}
                                                    onChange={(newOrder) => handleRecomandOrderChange(item.recomandProductNo, newOrder)}
                                                >
                                                    {recomandProduct.map((_, orderIndex) => (
                                                        <Option key={orderIndex + 1} value={orderIndex + 1}>
                                                            {orderIndex + 1}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </div>
                                            <div><span>{formatDate(item.regDate)}</span></div>
                                            <div>
                                                <button>삭제</button>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>

            {/* 모달영역 */}
            {
                showProduct &&
                <div className={`${admin.admin_operate_popular_product_modal_container}`}>
                    <div className={`${admin.admin_operate_popular_product_modal_box}`}>
                        <div className={`${admin.admin_operate_popular_product_modal_header}`}>
                            <div><span>인기상품 직접 등록</span></div>
                            <div><button onClick={() => setShowProduct(false)}><span className="material-symbols-outlined">close</span></button></div>
                        </div>
                        <div className={`${admin.admin_operate_popular_product_modal_content}`}>
                            <div className={`${admin.admin_operate_popular_product_modal_content_infomation}`}>
                                <div><span>* 상품 등록시 등록된 상품이 있을경우 후에 등록된 상품은 뒤쪽에 배치됩니다.</span></div>
                                <div><span>* 배치된 상품의 순서는 목록에서 변경가능합니다.</span></div>
                            </div>
                            <div className={`${admin.admin_operate_popular_product_modal_item_select}`}>
                                <div className={`${admin.admin_operate_popular_item_select_header}`}>
                                    <div><span>상품선택</span></div>
                                    <div>
                                        <input 
                                            type="text" 
                                            value={searchTerm} 
                                            onChange={handleSearchChange} 
                                            placeholder="검색어를 입력해주세요." 
                                        />
                                    </div>
                                </div>
                                <div className={`row ${admin.admin_operate_popular_item_box}`}>
                                {
                                    Array.isArray(filteredProductList) && filteredProductList.map((item, index) => (
                                        <div key={index} className={`col-4 ${admin.admin_operate_popular_item}`}>
                                            <div>
                                                <input 
                                                    type="checkbox" 
                                                    onChange={() => handleCheckboxChange(item)} 
                                                    checked={selectedProducts.some(product => product.productNo === item.productNo)} 
                                                />
                                            </div>
                                            <div>
                                                <div><img src={item.productMainImage} alt="" /></div>
                                                <div>
                                                    <div><span>{item.productNumber}</span></div>
                                                    <div><span>{item.productName}</span></div>
                                                    <div><span>판매금액 : </span><span>{item.productPrice.toLocaleString()}원</span></div>
                                                    {
                                                        item.productPrice !== item.productsalePrice &&
                                                        <div><span>할인금액 : </span><span>{item.productsalePrice.toLocaleString()}원</span></div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                                </div>
                            </div>
                            {/* 선택된 상품 리스트 */}
                            <div className={`${admin.admin_operate_popular_selected_items}`}>
                                <div><span>선택된 아이템</span></div>
                                <div className={`${admin.admin_operate_popular_selected_items_content} row`}>
                                    {
                                        selectedProducts.length > 0 ? selectedProducts.map((item, index) => (
                                            <div key={index} className={`${admin.admin_operate_popular_selected_item} col-6`}>
                                                <div>
                                                    <img src={item.productMainImage} alt={item.productName} />
                                                </div>
                                                <div>
                                                    <div><span>{item.productNumber}</span></div>
                                                    <div><span>{item.productName}</span></div>
                                                    <div><span>판매금액 : {item.productPrice.toLocaleString()}원</span></div>
                                                    {
                                                        item.productPrice !== item.productsalePrice &&
                                                        <div><span>할인금액 : {item.productsalePrice.toLocaleString()}원</span></div>
                                                    }
                                                </div>
                                                <div>
                                                    <div><span>노출여부</span></div>
                                                    <label className={`${admin.admin_active_switch}`}>
                                                        <input 
                                                            type="checkbox" 
                                                            checked={item.popularExposure}
                                                            onChange={() => handleExposureChange(item.productNo)} 
                                                        />
                                                        <span className={`${admin.admin_active_slider} ${admin.admin_active_round}`}></span>
                                                    </label>
                                                </div>
                                            </div>
                                        ))
                                        :
                                        <div className={`${admin.admin_operate_popular_selected_items_no_select}`}>
                                            <span>선택된 선택 인기상품이 없습니다.</span>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={`${admin.admin_operate_popular_product_modal_footer}`}>
                            <div><button onClick={() => {setShowProduct(false); setSelectedProducts([])}}>취소</button></div>
                            {
                                activeButton === 'product' &&
                                <div><button onClick={() => popularProductAdd()}>등록</button></div>
                            }
                            {
                                activeButton === 'discount' &&
                                <div><button onClick={() => discountProductAdd()}>등록</button></div>
                            }
                            {
                                activeButton === 'recomand' &&
                                <div><button onClick={() => recomandProductAdd()}>등록</button></div>
                            }
                        </div>
                    </div>
                </div>
            }
            {
                showSupport &&
                <div className={`${admin.admin_operate_popular_product_modal_container}`}>
                    <div className={`${admin.admin_operate_popular_product_modal_box}`}>
                        <div className={`${admin.admin_operate_popular_product_modal_header}`}>
                            <div><span>인기예약 직접 등록</span></div>
                            <div><button onClick={() => setShowSupport(false)}><span className="material-symbols-outlined">close</span></button></div>
                        </div>
                        <div className={`${admin.admin_operate_popular_product_modal_content}`}>
                            <div className={`${admin.admin_operate_popular_product_modal_content_infomation}`}>
                                <div><span>* 상품 등록시 등록된 상품이 있을경우 후에 등록된 상품은 뒤쪽에 배치됩니다.</span></div>
                                <div><span>* 배치된 상품의 순서는 목록에서 변경가능합니다.</span></div>
                            </div>
                            <div className={`${admin.admin_operate_popular_product_modal_item_select}`}>
                                <div className={`${admin.admin_operate_popular_item_select_header}`}>
                                    <div><span>상품선택</span></div>
                                    <div>
                                        <input 
                                            type="text" 
                                            value={searchSupportTerm} 
                                            onChange={handleSupportSearchChange} 
                                            placeholder="검색어를 입력해주세요." 
                                        />
                                    </div>
                                </div>
                                <div className={`row ${admin.admin_operate_popular_item_box}`}>
                                {
                                    Array.isArray(filteredSupportList) && filteredSupportList.map((item, index) => {
                                        const option = item.mainItem;

                                        // console.log('option : ', option);

                                        return (
                                            <div key={index} className={`col-4 ${admin.admin_operate_popular_item}`}>
                                                <div>
                                                    <input 
                                                        type="checkbox" 
                                                        onChange={() => handleSupportCheckboxChange(item)} 
                                                        checked={selectedSupports.some(supports => supports.productNo === item.productNo)} 
                                                    />
                                                </div>
                                                <div>
                                                    <div><img src={item.representativeImage} alt="" /></div>
                                                    <div>
                                                        <div><span>{item.productNumber}</span></div>
                                                        <div><span>{item.productName}</span></div>
                                                        <div>
                                                            {
                                                                item.mainItem && Array.isArray(item.mainItem) && item.mainItem.map((option, index) => (
                                                                    <div key={index}>
                                                                        <span>{option.mainOptionName} : </span>
                                                                        <span>{option.mainItemPrice.toLocaleString()}원</span>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        )
                                    })
                                }
                                </div>
                            </div>
                            {/* 선택된 상품 리스트 */}
                            <div className={`${admin.admin_operate_popular_selected_items}`}>
                                <div><span>선택된 아이템</span></div>
                                <div className={`${admin.admin_operate_popular_selected_items_content} row`}>
                                    {
                                        selectedSupports.length > 0 ? selectedSupports.map((item, index) => (
                                            <div key={index} className={`${admin.admin_operate_popular_selected_item} col-6`}>
                                                <div><img src={item.representativeImage} alt="" /></div>
                                                <div>
                                                    <div><span>{item.productNumber}</span></div>
                                                    <div><span>{item.productName}</span></div>
                                                    <div>
                                                        {
                                                            item.mainItem.map((option, index) => (
                                                                <div key={index}><span>{option.mainOptionName} : </span><span>{option.mainItemPrice.toLocaleString()}원</span></div>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                                <div>
                                                    <div><span>노출여부</span></div>
                                                    <label className={`${admin.admin_active_switch}`}>
                                                        <input 
                                                            type="checkbox" 
                                                            checked={item.popularExposure}
                                                            onChange={() => handleSupportExposureChange(item.productNo)} 
                                                        />
                                                        <span className={`${admin.admin_active_slider} ${admin.admin_active_round}`}></span>
                                                    </label>
                                                </div>
                                            </div>
                                        ))
                                        :
                                        <div className={`${admin.admin_operate_popular_selected_items_no_select}`}>
                                            <span>선택된 선택 인기상품이 없습니다.</span>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={`${admin.admin_operate_popular_product_modal_footer}`}>
                            <div><button onClick={() => {setShowSupport(false); setSelectedSupports([])}}>취소</button></div>
                            <div><button onClick={() => popularSupportAdd()}>등록</button></div>
                        </div>
                    </div>
                </div>
            }
            {
                showReview &&
                <div className={`${admin.admin_operate_popular_product_modal_container}`}>
                    <div className={`${admin.admin_operate_popular_product_modal_box}`}>
                        <div className={`${admin.admin_operate_popular_product_modal_header}`}>
                            <div><span>인기리뷰 직접 등록</span></div>
                            <div><button onClick={() => setShowReview(false)}><span className="material-symbols-outlined">close</span></button></div>
                        </div>
                        <div className={`${admin.admin_operate_popular_product_modal_content}`}>
                            <div className={`${admin.admin_operate_popular_product_modal_content_infomation}`}>
                                <div><span>* 인기리뷰 등록시 등록된 상품이 있을경우 후에 등록된 리뷰는 뒤쪽에 배치됩니다.</span></div>
                                <div><span>* 배치된 리뷰의 순서는 목록에서 변경가능합니다.</span></div>
                            </div>
                            <div className={`${admin.admin_operate_popular_product_modal_item_select}`}>
                                <div className={`${admin.admin_operate_popular_item_select_header}`}>
                                    <div><span>리뷰선택</span></div>
                                    <div>
                                        <input 
                                            type="text" 
                                            value={searchReviewTerm} 
                                            onChange={handleReviewSearchChange} 
                                            placeholder="검색어를 입력해주세요." 
                                        />
                                    </div>
                                </div>
                                <div className={`row ${admin.admin_operate_popular_item_box}`}>
                                {
                                    Array.isArray(filteredReviewList) && filteredReviewList.map((item, index) => {
                                        const option = item.mainItem;

                                        // console.log('option : ', option);

                                        return (
                                            <div key={index} className={`col-4 ${admin.admin_operate_popular_review_item}`}>
                                                <div>
                                                    <input 
                                                        type="checkbox" 
                                                        onChange={() => handleReviewCheckboxChange(item)} 
                                                        checked={selectedReviews.some(reviews => reviews.storeReviewNo === item.storeReviewNo)} 
                                                    />
                                                </div>
                                                <div>
                                                    <div><img src={item.photo1} alt="" /></div>
                                                    <div>
                                                        <div><span>{item.productNumber}</span></div>
                                                        <div><span>평점 : </span><span>{item.rating}</span></div>
                                                        <div>
                                                            <span>{item.reviewText}</span>
                                                        </div>
                                                        <div><span>등록일 : </span><span>{formatDate(item.reviewRegDate)}</span></div>
                                                    </div>
                                                </div>
                                            </div>

                                        )
                                    })
                                }
                                </div>
                            </div>
                            {/* 선택된 상품 리스트 */}
                            <div className={`${admin.admin_operate_popular_selected_items}`}>
                                <div><span>선택된 아이템</span></div>
                                <div className={`${admin.admin_operate_popular_selected_items_content} row`}>
                                    {
                                        selectedReviews.length > 0 ? selectedReviews.map((item, index) => (
                                            <div key={index} className={`${admin.admin_operate_popular_selected_item} col-6`}>
                                                <div><img src={item.photo1} alt="" /></div>
                                                <div>
                                                    <div><span>{item.productNumber}</span></div>
                                                    <div><span>평점 : </span><span>{item.rating}</span></div>
                                                    <div>
                                                        <span>{item.reviewText}</span>
                                                    </div>
                                                    <div><span>등록일 : </span><span>{formatDate(item.reviewRegDate)}</span></div>
                                                </div>
                                                <div>
                                                    <div><span>노출여부</span></div>
                                                    <label className={`${admin.admin_active_switch}`}>
                                                        <input 
                                                            type="checkbox" 
                                                            checked={item.popularExposure}
                                                            onChange={() => handleReviewExposureChange(item.storeReviewNo)} 
                                                        />
                                                        <span className={`${admin.admin_active_slider} ${admin.admin_active_round}`}></span>
                                                    </label>
                                                </div>
                                            </div>
                                        ))
                                        :
                                        <div className={`${admin.admin_operate_popular_selected_items_no_select}`}>
                                            <span>선택된 선택 인기리뷰가 없습니다.</span>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={`${admin.admin_operate_popular_product_modal_footer}`}>
                            <div><button onClick={() => {setShowReview(false); setSelectedSupports([])}}>취소</button></div>
                            <div><button onClick={() => popularReviewAdd()}>등록</button></div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default AdminPopularSection;
