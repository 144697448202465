import React, { useEffect, useRef, useState } from 'react';
import admin from '../../../admin.module.css';
import { Button, DatePicker, Input, Select } from 'antd';
import { Option } from 'antd/es/mentions';

const AdminManagement = () => {

    const { RangePicker } = DatePicker;

    const [selectDate, setSelectDate] = useState([]);
    const handleDateChange = (dates, dateStrings) => {
        // dates: Moment 객체 배열, 선택된 날짜 범위
        // dateStrings: 문자열 배열, 선택된 날짜 범위의 문자열 표현 ('YYYY-MM-DD' 형식)
        // console.log('선택된 날짜 범위:', dates);
        // console.log('선택된 날짜 범위 (문자열):', dateStrings);
        setSelectDate(dateStrings);
    };

    const [ selectedValue, setSelectedValue ] = useState('all');
    const [ searchValue, setSearchValue ] = useState('');
    // 옵션이 선택되었을 때 호출되는 함수
    const handleChange = (value) => {
        // console.log('선택된 값:', value); // 선택된 옵션의 값 확인
        setSelectedValue(value); // 상태 업데이트
    };
    const onSearchValue = (e) => {
        // console.log(e.target.value);
        setSearchValue(e.target.value);
    }

    const ref = useRef();

    useEffect(() => {
        const inputs = ref.current.querySelectorAll('.ant-picker-input input');
            inputs.forEach(input => {
            input.setAttribute('readonly', 'readonly');
        });
    }, []);

    const onSearchClick = () => {
        // console.log('selectDate : ', selectDate);
        // console.log('selectedValue : ', selectedValue);
        // console.log('searchValue : ', searchValue);
        
        const formData = new FormData();

        formData.append('startDate', selectDate[0]);
        formData.append('endDate', selectDate[1]);
        formData.append('selectedValue', selectedValue);
        formData.append('searchValue', searchValue);

        for (let [key, value] of formData.entries()) {
            // console.log(key, value);
        }

        const config = {
            headers: {
              'content-type': 'multipart/form-data'
            }
        };
    }

  return (
    <div className={`${admin.admin_management_box} px-3 py-3`}>
        <div>
            <div className={`${admin.admin_management_header}`}>
                <div><h5>운영진 목록</h5></div>
                <div className={`${admin.admin_management_header_btn_box}`}>
                    <div><button>전체운영자 <span className={`${admin.admin_signature_color}`}>(0)</span></button></div>
                    <div><button>그룹없음 <span className={`${admin.admin_signature_color}`}>(0)</span></button></div>
                    <div><button>새그룹 만들기</button></div>
                </div>
            </div>
            <div className={`${admin.admin_userList_search_box} py-3`}>
                <div className={`${admin.admin_userList_search_input}`}>
                    <div>
                    <Select
                        className={`${admin.admin_userList_search_select}`}
                        onChange={handleChange}
                        defaultValue={selectedValue} // 기본 선택값 설정
                    >
                        <Option value="all">전체</Option> {/* '전체' 옵션 추가 */}
                        <Option value="name">이름</Option>
                        <Option value="nickname">닉네임</Option>
                        <Option value="phone">전화번호</Option>
                    </Select>
                    </div>
                    <div className={`ps-1 ${admin.admin_userList_search_input_txt}`}>
                        <input 
                            type="text" 
                            onChange={(e) => onSearchValue(e)}
                            onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                onSearchClick();
                            }
                            }} 
                        />
                    </div>
                    <div className={`ps-1 ${admin.admin_userList_search_btn}`}>
                        <button onClick={() => onSearchClick()}>검색</button>
                    </div>
                </div>
                <div className={`${admin.admin_userList_search_date}`}>
                    <div ref={ref}>
                        <RangePicker className={`${admin.admin_userList_datepicker}`}
                            separator="~"
                            placeholder={['0000.00.00', '0000.00.00']}
                            onChange={handleDateChange}
                        />
                    </div>
                </div>
            </div>
            <div>
                <div className={`${admin.admin_userList_table_header} border-bottom py-3`}>
                    <div><input type="checkbox" name="" id="" /></div>
                    <div><span>이름</span></div>
                    <div><span>닉네임</span></div>
                    <div><span>아이디</span></div>
                    <div><span>이메일</span></div>
                    <div><span>부서</span></div>
                    <div><span>직급</span></div>
                    <div><span>가입일</span></div>
                    <div><span>삭제</span></div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AdminManagement