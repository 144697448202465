import React, { useContext, useEffect, useState } from 'react'
import { Pagination } from 'antd';
import * as storeseller from '../../../../../apis/seller'
import { format } from 'date-fns';
import { CSSTransition } from 'react-transition-group';
import seller from '../../../../seller.module.css';
import { LoginContext } from '../../../../../contexts/LoginContextProvider';

const SellerFaqMain = () => {
  const { isLogin, sellerlogout, userInfo, loginCheck, roles } = useContext(LoginContext);
  const [ boardList, setBoardList ] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);

  // 각 리뷰의 열림/닫힘 상태를 관리하는 state
  const [openStates, setOpenStates] = useState(boardList.map(() => false));

  // 리뷰 열림/닫힘 상태를 변경하는 함수
  const toggleOpen = (index) => {
    const newOpenStates = [...openStates];
    newOpenStates[index] = !newOpenStates[index];
    setOpenStates(newOpenStates);
  };

  // 게시글 데이터 가져오기
  useEffect(() => {
    fetchBoardList();
  }, [currentPage]);

  const fetchBoardList = async () => {
    try {
      const response = await storeseller.faq(currentPage, itemsPerPage);
      // console.log(response.data.content);
      setBoardList(response.data.content);
      setTotalItems(response.data.totalElements);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

    // 페이지 번호 클릭 시 호출될 함수
    const paginate = pageNumber => setCurrentPage(pageNumber);

  return (
    <div className={`px-3 ${seller.seller_right_container}`}>
      <div>
        <div className='pt-3 pb-2 border-bottom'><span>게시판</span></div>
        <div className={`pt-4 pb-1 ${seller.seller_border_bottom_color}`}><span>FAQ</span></div>
        <div>
          {
            boardList.length != 0 ?
            Array.isArray(boardList) && boardList.map((board, index) => (
              <div key={index}>
                <div className={`${seller.seller_board_box} py-3`} onClick={() => toggleOpen(index)}>
                  <div className={`${seller.seller_board_no_box} ps-2`}><span>{board.faqNo}</span></div>
                  <div className={`${seller.seller_board_title_box}`}><span>Q. {board.faqTitle}</span></div>
                  <div className={`${seller.seller_board_date_box} pe-2`}><span>{format(new Date(board.faqRegDate), 'yyyy.MM.dd')}</span></div>
                </div> 
                <CSSTransition in={openStates[index]} timeout={500} classNames="review" unmountOnExit>
                  <div className={`${seller.seller_board_content_box} py-3`}>
                    <div className={`${seller.seller_board_no_box}`}></div>
                    <div className='d-flex'>
                      <div>A.&nbsp;</div>
                      <div className={`${seller.seller_board_content_info}`} dangerouslySetInnerHTML={{ __html: board.faqContent}}></div>    
                    </div>    
                  </div>
                </CSSTransition>
              </div>
            )) :
            <div className='w-100 text-center py-5 dd-fs-7 gray_color'>
              <div><span>등록된 내역이 없습니다.</span></div>
            </div>
          }
        <Pagination current={currentPage} total={totalItems} onChange={paginate} pageSize={itemsPerPage} />
        </div>
      </div>
    </div>
  )
}

export default SellerFaqMain