import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { FaStar } from "react-icons/fa";
import dailyduck from '../../containers/my/MyContainner.module.css';

const HalfStarSVG = styled.svg`
  width: 1em;
  height: 1em;
  overflow: visible;
`;

const Label = styled.label`
  ${({ isHalf }) =>
    isHalf &&
    css`
      position: absolute;
      width: 27px;
      overflow: hidden;

      &:nth-of-type(10) {
        transform: translate(-251px);
      }
      &:nth-of-type(8) {
        transform: translate(-196px);
      }
      &:nth-of-type(6) {
        transform: translate(-140px);
      }
      &:nth-of-type(4) {
        transform: translate(-84px);
      }
      &:nth-of-type(2) {
        transform: translate(-28px);
      }
    `}
`;

const StarInput = ({ onClickRating, value, isHalf }) => {
  const handleClickRatingInput = () => {
    onClickRating(value);
  };

  return (
    <>
      <input className={`${dailyduck.review_star_input}`} type="radio" name="rating" id={`star${value}`} value={value} />
      <Label className={`${dailyduck.review_star_label}`}
        onClick={() => handleClickRatingInput(value)}
        isHalf={isHalf}
        htmlFor={`star${value}`}
      >
        {isHalf ? (
          <HalfStarSVG viewBox="0 0 576 512" fill="currentColor">
            <path d="M288 0v439.6L131.2 504c-23.2 12.3-50.9-7.4-46.4-33.7l25-145.5L4.2 221.7c-19-18.5-8.5-50.8 17.7-54.6l146.1-21.3L259.3 17.8c11.7-23.6 45.6-23.9 57.4 0l65.4 132.4 146.1 21.3c26.2 3.8 36.7 36.1 17.7 54.6l-105.7 103 25 145.5c4.5 26.3-23.2 46-46.4 33.7L288 439.6V0z"></path>
          </HalfStarSVG>
        ) : (
          <FaStar />
        )}
      </Label>
    </>
  );
};

export default StarInput;
