import React, { useContext, useEffect, useRef, useState } from 'react';
import admin from '../../../admin.module.css';
import { Button, DatePicker, Input, Select, Pagination } from 'antd';
import { Option } from 'antd/es/mentions';
import { LoginContext } from '../../../../contexts/LoginContextProvider';
import * as adminApi from '../../../../apis/admin';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { formatDistanceToNow, isWithinInterval, parseISO } from 'date-fns';
import { ko } from 'date-fns/locale';

const AdminPoint = () => {

    const { userInfo } = useContext(LoginContext);
    const MySwal = withReactContent(Swal);
    const ref = useRef();
    const { RangePicker } = DatePicker;
    const [selectedValue, setSelectedValue] = useState('all');
    const [searchValue, setSearchValue] = useState('');
    const [selectDate, setSelectDate] = useState([]);
    const [showPointModal, setShowPointModal] = useState(false);
    const [pointInfo, setPointInfo] = useState({
        pointState : '', point : 0, pointContent : '', selectUser : []
    });
    const [userList, setUserList] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [pointHistory, setPointHistory] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [totaluserCount, setTotalUserCount] = useState(0);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return formatDistanceToNow(date, { addSuffix: true, locale: ko });
    };

    useEffect(() => {
        // console.log('pointHistory : ', pointHistory);
    }, [pointHistory]);

    useEffect(() => {
        // console.log('userList : ', userList);
    }, [userList]);

    useEffect(() => {
        // console.log('pointInfo : ', pointInfo);
    }, [pointInfo]);

    useEffect(() => {
        // console.log('selectedUsers : ', selectedUsers);
        setPointInfo({
            ...pointInfo, selectUser : selectedUsers
        });
    }, [selectedUsers]);

    useEffect(() => {
        userAll();
        pointHistorySelect();
    }, []);
        
    const userAll = async () => {
        const response = await adminApi.adminUserSelect();
        const data = response.data;

        setUserList(data);
    };

    const pointHistorySelect = async () => {
        const response = await adminApi.pointHistorySelect();
        const data = response.data;

        setPointHistory(data);
        setTotalItems(data.length);
    };

    const handleChange = (value) => {
        setSelectedValue(value);
    };

    const onSearchValue = (e) => {
        setSearchValue(e.target.value);
    };

    const handleDateChange = (dates, dateStrings) => {
        setSelectDate(dateStrings);
    };

    const onSearchClick = async () => {
        // console.log('selectDate : ', selectDate);
        // console.log('selectedValue : ', selectedValue);
        // console.log('searchValue : ', searchValue);
        
        const formData = new FormData();

        formData.append('startDate', selectDate[0]);
        formData.append('endDate', selectDate[1]);
        formData.append('selectedValue', selectedValue);
        formData.append('searchValue', searchValue);

        for (let [key, value] of formData.entries()) {
            // console.log(key, value);
        }

        // const response = await auth.userSearchSelect(currentPage, itemsPerPage, formData);
        // const data = response.data;

        // setUserList(data.content);
        // setTotalItems(data.totalElements);
    };

    const onPoint = (value) => {
        let state = '';
        if (value === 'payment') {
            state = '지급';
        } else {
            state = '차감';
        }
        setPointInfo({
            ...pointInfo, pointState: state
        });
    };

    const handleUserCheckboxChange = (user) => {
        setSelectedUsers(prevSelectedUsers => {
            if (prevSelectedUsers.some(selectedUser => selectedUser.userNo === user.userNo)) {
                return prevSelectedUsers.filter(selectedUser => selectedUser.userNo !== user.userNo);
            } else {
                return [...prevSelectedUsers, user];
            }
        });
    };

    const handleSelectAllUsers = () => {
        if (selectedUsers.length === userList.length) {
            setSelectedUsers([]);
        } else {
            setSelectedUsers(userList);
        }
    };

    const onPointInput = (e) => {
        setPointInfo({
            ...pointInfo, point: e.target.value
        });
    };

    const onPointeContent = (e) => {
        if (e.target.value.length === 500) {
            alert('500자 이내로 작성해주세요.');
            return;
        }

        setPointInfo({
            ...pointInfo, pointContent: e.target.value
        });
    };

    const handlePointAdd = () => {
        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html: `
                <div>
                    <div class='${admin.admin_seller_add_title}'><span>${selectedUsers.length}명에게 포인트를 ${pointInfo.point}만큼 ${pointInfo.pointState} 하시겠습니까?</span></div>
                    <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                      <button class='mx-1' id='seller_add_cancel'>취소</button>
                      <button class='mx-1' id='seller_add_success'>${pointInfo.pointState}</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });
        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);
    
        async function sellerAddSuccess(e) {
            if (e.target && e.target.id === 'seller_add_success') {
                try {
                    const response = await adminApi.pointUpdate(pointInfo);
                    const data = response.data;
        
                    if (data === 'SUCCESS') {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                            <div class='${admin.admin_seller_add_title}'><span>${selectedUsers.length}명에게 포인트를 ${pointInfo.point}만큼 ${pointInfo.pointState} 하였습니다.</span></div>
                            <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);
        
                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                userAll();
                                pointHistorySelect();
                                setSelectedUsers([]);
                                setShowPointModal(false);
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    } else {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                            <div class='${admin.admin_seller_add_title}'><span>${selectedUsers.length}명에게 포인트를 ${pointInfo.point}만큼 ${pointInfo.pointState}에 실패하였습니다.</span></div>
                            <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);
        
                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    }
                } catch (error) {
                    console.error('error : ', error);
                }
            }
        }
    
        function sellerAddCancel(e) {
            if (e.target && e.target.id === 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
            }
        }
    };

    const filteredUserList = userList.filter(user => {
        const searchRegex = new RegExp(searchValue, 'i');
        return searchRegex.test(user.userName) || searchRegex.test(user.userNick) || searchRegex.test(user.userTel) || searchRegex.test(user.userId) || searchRegex.test(user.userEmail);
    });

    const filteredPointHistory = pointHistory.filter(point => {
        const searchRegex = new RegExp(searchValue, 'i');
        const isDateValid = selectDate.length === 2 && selectDate[0] && selectDate[1] ? 
            isWithinInterval(parseISO(point.pointRegDate), { start: parseISO(selectDate[0]), end: parseISO(selectDate[1]) }) 
            : true;

        if (selectedValue === 'name') {
            return isDateValid && searchRegex.test(point.userName);
        } else if (selectedValue === 'id') {
            return isDateValid && searchRegex.test(point.userId);
        } else if (selectedValue === 'nickname') {
            return isDateValid && searchRegex.test(point.userNick);
        } else if (selectedValue === 'pd') {
            return isDateValid && searchRegex.test(point.pointState);
        } else if (selectedValue === 'email') {
            return isDateValid && searchRegex.test(point.userEmail);
        } else {
            return isDateValid && (searchRegex.test(point.userName) || searchRegex.test(point.userId) || searchRegex.test(point.userNick) || searchRegex.test(point.pointState) || searchRegex.test(point.userEmail));
        }
    });

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentData = filteredPointHistory.slice(startIndex, endIndex);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    return (
        <div className={`${admin.admin_give_point_box} px-3 py-3 ${showPointModal ? admin.show_point_modal_active : ''}`}>
            <div>
                <div className={`${admin.admin_give_point_title}`}>
                    <div><span>포인트</span></div>
                    <div><button onClick={() => setShowPointModal(true)}>포인트 지급/차감</button></div>
                </div>
                <div className={`${admin.admin_userList_search_box} py-3`}>
                    <div className={`${admin.admin_userList_search_input}`}>
                        <div>
                        <Select
                            className={`${admin.admin_userList_search_select}`}
                            onChange={handleChange}
                            defaultValue={selectedValue}
                        >
                            <Option value="all">전체</Option>
                            <Option value="name">이름</Option>
                            <Option value="id">아이디</Option>
                            <Option value="nickname">닉네임</Option>
                            <Option value="pd">지급/차감</Option>
                            <Option value="email">이메일</Option>
                        </Select>
                        </div>
                        <div className={`ps-1 ${admin.admin_userList_search_input_txt}`}>
                        <input 
                            type="text" 
                            onChange={(e) => onSearchValue(e)}
                            onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                onSearchClick();
                            }
                            }} 
                        />
                        </div>
                        {/* <div className={`ps-1 ${admin.admin_userList_search_btn}`}>
                            <button onClick={() => onSearchClick()}>검색</button>
                        </div> */}
                    </div>
                    <div className={`${admin.admin_userList_search_date}`}>
                        <div ref={ref}>
                            <RangePicker className={`${admin.admin_userList_datepicker}`}
                                separator="~"
                                placeholder={['0000.00.00', '0000.00.00']}
                                onChange={handleDateChange}
                            />
                        </div>
                    </div>
                </div>
                <div className={`${admin.admin_give_point_content}`}>
                    <div className={`${admin.admin_give_point_content_title}`}>
                        <div><span>포인트 처리내역</span></div>
                        <div><button>내보내기</button></div>
                    </div>
                    <div>
                        <div>
                            <div>
                                <div className={`${admin.admin_give_point_content_header}`}>
                                    <div><span>번호</span></div>
                                    <div><span>이름(닉네임)</span></div>
                                    <div><span>이메일(아이디)</span></div>
                                    <div><span>사유/내용</span></div>
                                    <div><span>구분</span></div>
                                    <div><span>포인트</span></div>
                                    <div><span>일자</span></div>
                                </div>
                                {
                                    currentData.map((point, index) => (
                                        <div className={`${admin.admin_give_point_content_content}`} key={point.pointNo}>
                                            <div><span>{startIndex + index + 1}</span></div>
                                            <div><span>{point.userName}</span><span>({point.userNick})</span></div>
                                            <div>
                                                <div><span>{point.userEmail}</span></div>
                                                <div><span>({point.userId})</span></div>

                                            </div>
                                            <div><span>{point.pointContent}</span></div>
                                            <div><span>{point.pointState}</span></div>
                                            <div><span>{point.point}</span></div>
                                            <div><span>{formatDate(point.pointRegDate)}</span></div>
                                        </div>
                                    ))
                                }
                            </div>
                            <Pagination
                                current={currentPage}
                                pageSize={itemsPerPage}
                                total={filteredPointHistory.length}
                                onChange={handlePageChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {
                showPointModal &&
                <div className={`${admin.admin_point_modal_container}`}>
                    <div className={`${admin.admin_point_modal_box}`}>
                        <div className={`${admin.admin_point_modal_header}`}>
                            <div><span>포인트 지급/차감</span></div>
                            <div><button onClick={() => setShowPointModal(false)}><span className="material-symbols-outlined">close</span></button></div>
                        </div>
                        <div className={`${admin.admin_point_modal_select_box}`}>
                            <div>
                                <div><span>포인트 지급 여부</span></div>
                                <div>
                                    <Select 
                                        className={`${admin.admin_point_modal_select}`}
                                        placeholder='선택'
                                        onChange={(value) => onPoint(value)}
                                    >
                                        <Option value='payment'>지급</Option>
                                        <Option value='deduction'>차감</Option>
                                    </Select>
                                </div>
                            </div>
                            <div className={`${admin.admin_point_modal_point_input_box}`}>
                                <div><span>지급/차감 포인트</span></div>
                                <div>
                                    <div>
                                        <div><input type="number" onChange={(e) => onPointInput(e)}/></div>
                                        <div><span>P</span></div>
                                    </div>
                                </div>
                            </div>
                            <div className={`${admin.admin_point_modal_point_textarea_box}`}>
                                <div><span>사유/내용 입력</span></div>
                                <div>
                                    <div><textarea name="" id="" placeholder='사유/내용을 작성해주세요.' onChange={(e) => onPointeContent(e)}></textarea></div>
                                </div>
                            </div>
                            <div className={`${admin.admin_point_modal_point_search_box}`}>
                                <div><span>유저검색</span></div>
                                <div><input type="text" onChange={onSearchValue} /></div>
                                <div><span>* 이름, 닉네임, 이메일, 번호 기준으로 검색됩니다.</span></div>
                            </div>
                        </div>
                        <div className={`${admin.admin_point_modal_content}`}>
                            <div>
                                <div><span>유저 리스트</span></div>
                                <div><span>선택 유저 리스트</span></div>
                            </div>
                            <div>
                                <div className={`${admin.admin_point_modal_content_total_user_list}`}>
                                    {
                                        filteredUserList.map((user, index) => (
                                            <div key={index}>
                                                <div><input 
                                                    type="checkbox" 
                                                    checked={selectedUsers.some(selectedUser => selectedUser.userNo === user.userNo)} 
                                                    onChange={() => handleUserCheckboxChange(user)} 
                                                /></div>
                                                <div className='ps-1'>
                                                    <div><span>{user.userName !== null ? user.userName : '이름없음'}</span>&nbsp;/&nbsp;</div>
                                                    <div><span>{user.userId}</span>&nbsp;/&nbsp;</div>
                                                    <div><span>{user.userEmail}</span></div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className={`${admin.admin_point_modal_content_select_user_list}`}>
                                    {
                                        selectedUsers.map((user, index) => (
                                            <div key={index}>
                                                <div><input type="checkbox" checked={true} onChange={() => handleUserCheckboxChange(user)} /></div>
                                                <div className='ps-1'>
                                                    <div><span>{user.userName !== null ? user.userName : '이름없음'}</span>&nbsp;/&nbsp;</div>
                                                    <div><span>{user.userId}</span>&nbsp;/&nbsp;</div>
                                                    <div><span>{user.userEmail}</span></div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={`${admin.admin_point_modal_footer}`}>
                            <div><button onClick={() => setShowPointModal(false)}>취소</button></div>
                            <div><button onClick={handlePointAdd}>{pointInfo.pointState === '지급' ? '포인트 지급' : '포인트 차감'}</button></div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default AdminPoint;
