import React, {useContext, useEffect, useState} from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import * as auth from '../../apis/auth';
import { LoginContext } from '../../contexts/LoginContextProvider';
import dailyduck from '../../containers/my/MyContainner.module.css'

const FavorityDday = () => {


    const { userInfo } = useContext(LoginContext);
    const MySwal = withReactContent(Swal);
    const location = useLocation();
    const navigate = useNavigate();

    const selectedItems = location?.state;

    const [ favorityList, setFavorityList ] = useState([]);

    // 각 아이템에 대한 기념일 숫자를 저장할 객체 상태를 초기화합니다.
    const [numbers, setNumbers] = useState(() =>
        selectedItems.reduce((acc, item, index) => {
            acc[item.no] = ''; // 여기서 item.id는 각 아이템을 구별할 수 있는 고유한 값입니다.
            return acc;
        }, {})
    );

    useEffect(() => {
        const newItems = selectedItems.map(item => ({
          favorityNo: item.artistNo,
          userId: userInfo ? userInfo?.userId : '',
          favorityName: item.favorityName,
          favorityDday: '',
          favorityImgUrl: item.favorityImgUrl,
        }));

        setFavorityList(newItems);
    }, []);

    useEffect(() => {
        // console.log(favorityList);
    }, [favorityList])

    const handleChange = (no, event) => {
        const value = event.target.value;
        const regex = /^[0-9]*$/;
  
        if (regex.test(value)) {
            setNumbers((prevNumbers) => ({
                ...prevNumbers,
                [no]: value, // 특정 아이템의 상태만 업데이트합니다.
            }));
            updateDday(no, value);
        }
    };

    const updateDday = (no, ddayValue) => {
        setFavorityList((currentList) => {
            return currentList.map((item) => {
            if (item.favorityNo === no) {
                return { ...item, favorityDday: ddayValue };
            }
            return item;
            });
        });
    };

    const favoritySave = async () => {
        try {
            const response = await auth.favorityAdd(favorityList);
            const data = response.data;
    
            
            if(data === 'SUCCESS') {
                // console.log(data);
                MySwal.fire({
                    customClass: {
                        popup: `favority_add_save_popup`
                    },
                    html : 
                    `
                        <div>
                            <div class='favority_add_save_title'><span>최애 등록</span></div>
                            <div class='favority_add_save_content'><span>최애 등록이 완료되었습니다.</span></div>
                            <div class='favority_add_save_btn py-2'><button id='favority_add_save'>확인</button></div>
                        </div>
                    `,
                    showConfirmButton: false,
                    showCancelButton: false,
                });

                document.addEventListener('click', favorityAddSuccess);

                function favorityAddSuccess(e) {
                  if (e.target && e.target.id == 'favority_add_save') {
                    navigate('/my/favority');
                    MySwal.close();
                    document.removeEventListener('click', favorityAddSuccess);
                  }
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    const handleFavDdayBack = () => {
        navigate(-1);
    }

  return (
    <>
        <div className={`${dailyduck.favority_dday_add_container}`}>
            <div className={`${dailyduck.favority_dday_add_box}`}>
                <div className={`${dailyduck.favority_dday_add_header}`}>
                    <div>
                        <button onClick={() => handleFavDdayBack()}><span className='material-symbols-outlined'>arrow_back_ios</span></button>
                    </div>
                    <div><span>최애와의 기념일 설정</span></div>
                </div>
                <div className={`${dailyduck.favotiry_dday_add_selecteditem_box}`}>
                    {selectedItems.map((item, index) => (
                        <div key={index} className='mx-3 py-4 border-bottom'>
                            <div className='favority_dday_selectBox'>
                                <div className='favority_ddayImgBox'><img src={`${process.env.REACT_APP_API_SERVER}/img?file=${item.favorityImgUrl}`} alt={item.favorityName} /></div>
                                <div className='favority_ddayNumberBox'><span className='pe-2'>{item.favorityName}</span><span>D+<span>1</span></span></div>
                            </div>
                            <div className='favority_dday_inputBox'>
                                <div><span>최애({item.favorityName})와의 기념일</span></div>
                                <div className='d-flex position-relative dday_box'>
                                    <span>D+</span>
                                    <input
                                        type="text"
                                        value={numbers[item.artistNo] || ''} // 각 아이템의 고유한 상태를 사용합니다.
                                        onChange={(event) => handleChange(item.artistNo, event)} // 핸들러에 아이템의 ID를 전달합니다.
                                        className='dday_input'
                                        placeholder='12 (숫자만 입력)'
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className={`${dailyduck.favority_dday_add_footer}`}>
                    <div className='text-center favority_dday_info_box'>
                        <div><span>미설정 시 최애 등록일을 기준으로 D+1로 설정되며</span></div>
                        <div><span>내정보 - 나의 최애에서 변경이 가능합니다.</span></div>
                        {/* <div><button>건너뛰기</button></div> */}
                    </div>
                    <div className='favority_dday_next_btnbox pt-4'>
                        <button onClick={() => favoritySave()}>다음</button>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default FavorityDday