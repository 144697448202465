import React, { useContext, useEffect, useState } from 'react'
import event from '../../containers/event/event.module.css'
import store from '../../containers/market/store/store.module.css'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { animated } from 'react-spring';
import { useSpring, useTransition } from '@react-spring/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { format } from 'date-fns';
import { ko } from 'date-fns/locale';
import { LoginContext } from '../../contexts/LoginContextProvider';
import * as storeApi from '../../apis/store';

const EventRaffle = () => {
  
    const {eventNumber} = useParams();
    const {userInfo, isLogin} = useContext(LoginContext);
    const navigate = useNavigate();
    const location = useLocation(); 

    const { eventInfo } = location.state || {};

    const [ listImage, setListImage ] = useState([]);

    const [ participation, setParticipation ] = useState('');

    useEffect(() => {
        // console.log('eventInfo : ', eventInfo);

        if (eventInfo) {
            const images = [
                eventInfo.eventImage1,
                eventInfo.eventImage2,
                eventInfo.eventImage3,
                eventInfo.eventImage4,
                eventInfo.eventImage5,
            ].filter(image => image !== null && image !== '');

            setListImage(images);
        }

        const eventConfirm = async () => {
            try {
                const response = await storeApi.eventConfirm(eventInfo?.eventNumber, userInfo?.userId);
                const data = response.data;
                // console.log('eventCheck : ', data);

                setParticipation(data);

            } catch (error) {
                console.error('Error fetching event confirmation:', error);
            }
        }

        eventConfirm();


    }, [eventInfo]);

    useEffect(() => {
        // console.log('listImage : ', listImage);
    }, [listImage]);
    
    const [ clipboardMessage, setClipboardMessage ] = useState('');

    const [ shared, setShared ] = useState(false);
    const handleBack = () => {
        navigate(-1);
    }

    useEffect(() => {
        if (!window.Kakao.isInitialized()) {
            window.Kakao.init(process.env.REACT_APP_KAKAO_API_KEY);
        }
        }, []);

    const transitions = useTransition(shared, {
        from: { transform: "translateY(100%)" },
        enter: { transform: "translateY(0)" },
        leave: { transform: "translateY(100%)" },
        config: {
            duration: 300,
        },
    });

    const handleShare = () => {
        setShared(true);
    }
    const handleShardBack = () => {
        setShared(false);
    }
    const handleShareKakaoClick = () => {
        if (window.Kakao) {
            const kakao = window.Kakao;
        
            kakao.Share.sendDefault({
                objectType: 'feed',
                content: {
                title: 'title',
                description: '데일리덕에서 구매하고 포인트 받자!',
                imageUrl: 'https://dailyduck.com/img/kakaolink40_original.png',
                link: {
                    mobileWebUrl: 'https://dailyduck.com/event/raffle/' + eventNumber,
                    webUrl: 'https://dailyduck.com/event/raffle/' + eventNumber,
                },
                },
            });
        }
    }
    const handleTwitterShare = () => {
        const url = `https://dailyduck.com/event/raffle/${eventNumber}`;
        const text = `데일리덕에서 구매하고 포인트 받자!`;
        const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
            text
        )}&url=${encodeURIComponent(url)}`;
        window.open(twitterUrl);
    };
    const handleNaverLineShare = () => {
        const url = `https://social-plugins.line.me/lineit/share?url=https://dailyduck.com/event/raffle/${eventNumber}`
        window.open(url);
    }  

    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    const handleSmsShare = () => {
        const productLink = `https://dailyduck.com/event/raffle/${eventNumber}`;
        const productName = `test`;
        const productDescription = "데일리덕에서 구매하고 포인트 받자!";

        if (!isMobile) {
            alert("SMS 공유는 모바일 기기에서만 가능합니다.");
            return;
        }

        const message = encodeURIComponent(`${productName} - ${productDescription}\n링크: ${productLink}`);
        window.location.href = `sms:&body=${message}`;
    }
    const handleClipboardShare = async () => {
        const text = `https://dailyduck.com/event/raffle/${eventNumber}`
        if ('clipboard' in navigator) {
            try {
                await navigator.clipboard.writeText(text);
                setClipboardMessage('클립보드에 복사되었습니다.');
                setTimeout(() => setClipboardMessage(''), 2000);
            } catch (err) {
                console.error('클립보드 복사에 실패했습니다: ', err);
            }
        } else {
            // Fallback for older browsers
            const textarea = document.createElement('textarea');
            textarea.value = text;
            document.body.appendChild(textarea);
            textarea.select();
            document.execCommand('copy');
            document.body.removeChild(textarea);
            setClipboardMessage('클립보드에 복사되었습니다.');
            setTimeout(() => setClipboardMessage(''), 2000);
            }
    }
    const handleMoreShare = () => {
        const productLink = `https://dailyduck.com/event/raffle/${eventNumber}`;
        const productName = `test`;
        const productDescription = "데일리덕에서 구매하고 포인트 받자!";

        if (navigator.share) {
            navigator.share({
            title: `${productName}`,
            text: `${productDescription}`,
            url: `${productLink}`,
            })
            .then(() => alert('공유 성공!'))
            .catch((error) => alert('공유 실패:', error));
        } else {
            // Web Share API를 지원하지 않는 경우의 대체 처리
            alert('Web Share API를 지원하지 않는 브라우저입니다.');
        }
    }

    const onSubScribe = () => {
        // navigate(`/lucked/${eventNumber}`);
        navigate(`/lucked/payment/${eventNumber}`, {state : {paymentInfo : eventInfo }});
    }

    const formatDate = (date) => {
        return format(new Date(date), 'MM.dd HH:mm:ss (eee)', { locale: ko });
    };

    const onLogin = () => {
        navigate('/');
    };

  return (
    <>
      <div className={`${event.event_raffle_container} `}>
        <div className={`${event.event_product_detail_header}`}>
            <div className={`${event.event_product_detail_header_left}`}><button onClick={() => handleBack()}><span className="material-symbols-outlined">arrow_back_ios</span></button></div>
            <div className={`${event.event_product_detail_header_right}`}><button><span className="material-symbols-outlined" onClick={() => handleShare()}>share</span></button></div>
        </div>
        <div className={`${event.event_product_detail_img_box}`}>
          <div>
              <Swiper
                  slidesPerView={1}
                  spaceBetween={30}
                  loop={true}
                  pagination={{
                  clickable: true,
                  }}
                  // navigation={true}
                  modules={[Pagination, Navigation]}
                  className={`${event.event_raffle_swiper}`}
              >
                {
                    listImage.map((item, index) => (
                        <SwiperSlide key={index}><img src={item} alt="이미지" /></SwiperSlide>
                    ))
                }
              </Swiper>
          </div>
        </div>
        <div className='px-3 py-3'>
          <div className='border-bottom'>
            <div className={`${event.event_raffle_info_shop_box}`}><span>상점명</span></div>
            <div className={`${event.event_raffle_info_product_box}`}><span>{eventInfo.eventTitle}</span></div>
            <div className={`${event.event_raffle_info_price_box}`}><span>{eventInfo.eventPrice.toLocaleString()}원</span></div>
            <div className={`${event.event_raffle_info_people_box}`}><span>총 참여 인원은 1,164명 입니다.</span></div>
            <div className='py-3'>
              <div className={`${event.event_raffle_info_date_box}`}>
                <div><span>래플 응모기간</span></div>
                <div><span>{formatDate(eventInfo.eventStartDate)} ~ {formatDate(eventInfo.eventEndDate)}</span></div>
              </div>
              <div className={`${event.event_raffle_info_date_box}`}>
                <div><span>당첨자 발표일</span></div>
                <div><span>{formatDate(eventInfo.eventWinnerDate)}</span></div>
              </div>
              {/* <div className={`${event.event_raffle_info_date_box}`}>
                <div><span>당첨자 구매기간</span></div>
                <div><span>5.4 (목) 12:00 ~ 5.4 (목) 14:00</span></div>
              </div> */}
              <div className={`${event.event_raffle_info_date_box}`}>
                <div><span>안내사항</span></div>
                <div><span>안내사항 내용입니다. 안내사항 내용입니다. 안내사항 내용입니다.</span></div>
              </div>
            </div>
          </div>
        </div>
        <div className='px-3 pt-3 pb-4 border-bottom'>
          <div className={`${event.event_spare_buy_date_box}`}><span>예비 당첨자 구매기간 안내</span><button>자세히 보기</button></div>
          <div className={`${event.event_spare_ment_box}`}><span>당첨자가 구매하지 않았을 시, 추가 당첨 기회를 드립니다.</span></div>
        </div>
        <div className={`${event.event_raffle_content_box}`}>
            <div dangerouslySetInnerHTML={{ __html: eventInfo.eventContent}}></div>
        </div>
        {
            isLogin ?
            <div className={`${event.event_raffle_footer_box} border-top`}>
                {
                    participation === 'Participation' ?
                    <div className={`${event.event_raffle_footer_participation}`}><span>이미 참여한 이벤트입니다.</span></div>
                    :
                    <div className='px-3'><button onClick={onSubScribe}>응모하기</button></div>
                }
            </div>
            :
            <div className={`${event.event_raffle_footer_box} border-top`}>
                <div><button onClick={onLogin}>로그인 후 이벤트 참여하기</button></div>
            </div>
        }
        {
          shared &&
          <div className={`${store.store_shared_background}`} >
              {transitions((style, item) =>
                  item ? 
                  <animated.div className={`${store.store_shard_box}`} style={style}>
                      <div className='pt-5 px-3 d-flex justify-content-between'>
                          <div><h5>공유하기</h5></div>
                          <div><button className={`${store.store_shared_close}`} onClick={handleShardBack}><span className="material-symbols-outlined">close</span></button></div>
                      </div>
                      <div className='d-flex justify-content-center py-3'>
                          <div className={`${store.store_shard_btn_box}`}>
                              <button onClick={handleShareKakaoClick}>
                                  <div>
                                      <div><img src="../../img/icon/share/kakaotalk.png" alt="카카오톡" /></div>
                                      <div className='py-1'><span>카카오톡</span></div>
                                  </div>
                              </button>
                          </div>
                          <div className={`${store.store_shard_btn_box}`}>
                              <button onClick={handleTwitterShare}>
                                  <div>
                                      <div><img src="../../img/icon/share/twiter.png" alt="트위터" /></div>
                                      <div className='py-1'><span>트위터</span></div>
                                  </div>
                              </button>
                          </div>
                          <div className={`${store.store_shard_btn_box}`}>
                              <button onClick={handleNaverLineShare}>
                                  <div>
                                      <div><img src="../../img/icon/share/line.png" alt="라인" /></div>
                                      <div className='py-1'><span>라인</span></div>
                                  </div>
                              </button>
                          </div>
                          <div className={`${store.store_shard_btn_box}`}>
                              <button onClick={handleSmsShare}>
                                  <div>
                                      <div><img src="../../img/icon/share/message.png" alt="SMS" /></div>
                                      <div className='py-1'><span>SMS</span></div>
                                  </div>
                              </button>
                          </div>
                      </div>
                      <div className='d-flex'>
                          <div className={`${store.store_shard_btn_bottom_box}`}>
                              <button onClick={() => handleClipboardShare()}>
                                  <div>
                                      <div><img src="../../img/icon/share/url.png" alt="" /></div>
                                      <div><span>URL</span></div>
                                  </div>
                              </button>
                          </div>
                          <div className={`${store.store_shard_btn_bottom_box}`}>
                              <button onClick={handleMoreShare}>
                                  <div>
                                      <div><img src="../../img/icon/share/more.png" alt="" /></div>
                                      <div><span>더보기</span></div>
                                  </div>
                              </button>
                          </div>
                      </div>
                      {
                          clipboardMessage !== '' ? 
                          <div className={`${store.store_clipboard_box}`}><span>{clipboardMessage}</span></div>
                          :
                          null
                      }
                  </animated.div>
                  : null
              )}
          </div>
      }
      </div>
    </>
  )
}

export default EventRaffle