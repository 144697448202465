import React from 'react'

const SellerHeader = ( {seller, onMenuClick, showMenu} ) => {
  return (
    <div className={`${seller.seller_header}`}>
      <div className={`${seller.seller_header_background} position-relative`}>
        <div className={`${seller.seller_header_logo} ps-4`}><img src={`${process.env.PUBLIC_URL}/img/seller/dailyduck_black_logo.png`} alt="로고이미지" /></div>
        <div className={`${seller.seller_header_title} bold ps-3`}><span>셀러</span></div>
        <div className='position-absolute end-0 pe-3'>
          <button className={`${seller.seller_header_menu_btn}`} onClick={() => onMenuClick()}>
            {
              showMenu ?
              <span className="material-symbols-outlined">close</span>
              :
              <span className="material-symbols-outlined">menu</span>
            }
          </button>
        </div>
      </div>
    </div>
  )
}

export default SellerHeader