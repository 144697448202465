import React from 'react';

const StoreProductInfo = ({ product, Wrap2, Stars, ARRAY, FaStar, clicked, ratings, highestCount, store, averageRatingDecimal }) => {
  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: product?.productDetail }} className={`pt-3 px-3`}></div>
      <div dangerouslySetInnerHTML={{ __html: product?.returnRule }} className={`pt-3 px-3 ${store.store_return_box}`}></div>
      <div className='px-3 pb-5 mb-5'>
        <div className='ratio_box d-flex justify-content-between mt-3'>
          <div className='venue_ratio_box text-center'>
            <div className='fs-3'>{averageRatingDecimal !== 'NaN' ? averageRatingDecimal : 0.0}</div>
            <div className='magin-0a'>
              <Wrap2 className='ps-2'>
                <Stars>
                  {ARRAY.map((el, idx) => (
                    <FaStar
                      key={idx}
                      size="20"
                      className={clicked[el] ? 'yellowStar' : ''}
                    />
                  ))}
                </Stars>
              </Wrap2>
            </div>
          </div>
          <div className="vertical_line my-3"></div>
          <div className='venue_ratio_graph'>
            <table>
              {Object.entries(ratings).sort((a, b) => b[0] - a[0]).map(([star, count]) => (
                <tr key={star}>
                  <td width={'10%'} style={{ fontWeight: count === highestCount ? 'bold' : 'normal', color: count === highestCount ? 'black' : '#aaa' }}>{star}</td>
                  <td width={'80%'}>
                    <div className="barBackground mt-2">
                      <div className="bar" style={{ width: `${count * 10}%` }}></div>
                    </div>
                  </td>
                  <td width={'10%'} style={{ fontWeight: count === highestCount ? 'bold' : 'normal', color: count === highestCount ? 'black' : '#aaa' }}>{count}</td>
                </tr>
              ))}
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default StoreProductInfo;
