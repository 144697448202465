import React, { useEffect } from 'react'
import { animated } from 'react-spring';
import { useSpring, useTransition } from '@react-spring/core';

const MyTermsDetail = ( {dailyduck, showTermsDetail, setShowTermsDetail, setShowTerms, termsData } ) => {

    const transitions = useTransition(showTermsDetail, {
        from: { transform: "translateX(100%)" },
        enter: { transform: "translateX(0)" },
        leave: { transform: "translateX(100%)" },
        config: {
            duration: 300,
        },
    });   

    const handleTermsDetailBack = () => {
      setShowTermsDetail(false);
    }

    useEffect(() => {
        const elements = document.getElementsByClassName('page_inner');
        if(showTermsDetail) {
            for (let element of elements) {
                element.style.height = '100vh';
            }
        } else {
            for (let element of elements) {
                element.style.height = '';
            }
        }
        
        return () => {
            for (let element of elements) {
                element.style.height = '';
            }
        }
    }, [showTermsDetail]);
  return (
    <>
        {
            showTermsDetail &&
            <div className={`${dailyduck.my_terms_detail_container}`}>
                {transitions((style, item) =>
                    item ? 
                    <animated.div className={dailyduck.my_terms_detail_box} style={style}>
                        <div className={dailyduck.my_terms_header}>
                            <div >
                                <button onClick={() => handleTermsDetailBack()}>
                                    <span className="material-symbols-outlined">arrow_back_ios</span>
                                </button>
                            </div>
                            <div>
                                <div>
                                    <span>이용약관</span>
                                </div>
                            </div>
                        </div>
                        <div className={`${dailyduck.my_terms_detail_content}`}>
                            <div>
                                <div><span>{termsData ? termsData.termsTitle : ''}</span></div>
                                <div dangerouslySetInnerHTML={{ __html: termsData.termsContent }}></div>
                            </div>
                        </div>
                    </animated.div>
                    : null
                )}
            </div>
        }
    </>
  )
}

export default MyTermsDetail