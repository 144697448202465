import React, { useEffect, useState } from 'react';
import admin from '../../../admin.module.css';
import * as adminApi from '../../../../apis/admin';
import * as categoryApi from '../../../../apis/category';
import * as supportApi from '../../../../apis/support';
import { CSSTransition } from 'react-transition-group';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const AdminCategory = () => {
    const MySwal = withReactContent(Swal);

    const [activeCategory, setActiveCategory] = useState('store');
    const [storeCategoryAdd, setStoreCategoryAdd] = useState(false);
    const [storeSubCategoryAdd, setStoreSubCategoryAdd] = useState(false);
    const [supportCategoryAdd, setSupportCategoryAdd] = useState(false);
    const [supportSubCategoryAdd, setSupportSubCategoryAdd] = useState(false);
    const [storeCategory, setStoreCategory] = useState([]);
    const [supportCategory, setSupportCategory] = useState([]);
    const [openStore, setOpenStore] = useState({});
    const [openSupport, setOpenSupport] = useState({});
    const [imageStoreIconPreview, setImageStoreIconPreview] = useState('');
    const [imageSupportIconPreview, setImageSupportIconPreview] = useState('');
    const [storeCategoryInfo, setStoreCategoryInfo] = useState({
        dailyduckStorecategoryNo : '', dailyduckStorecategoryEngName : '', dailyduckStorecategoryName : '', dailyduckStorecategoryImg : '', dailyduckStorecategoryRegDate : ''
    });
    const [supportCategoryInfo, setSupportCategoryInfo] = useState({
        dailyduckSupportcategoryNo : '', dailyduckSupportcategoryEngName : '', dailyduckSupportcategoryName : '', dailyduckSupportcategoryImg : '', supportRegDate : ''
    });
    const [storeSubCategoryInfo, setStoreSubCategoryInfo] = useState({
        storeSubCategoryNo : 0, storeSubCategoryEngName : '', storeSubCategoryKorName : '', storeMainCategoryNo : 0, storeMainCategoryEngName: '',
        storeMainCategoryKorName: '', storeSubCategoryOrder: 0, regDate: '', updDate: ''
    })
    const [supportSubCategoryInfo, setSupportSubCategoryInfo] = useState({
        supportSubCategoryNo : 0, supportSubCategoryEngName : '', supportSubCategoryKorName : '', supportMainCategoryNo : 0, supportMainCategoryEngName: '',
        supportMainCategoryKorName: '', supportSubCategoryOrder: 0, regDate: '', updDate: ''
    })

    useEffect(() => {
        console.log('storeCategoryInfo : ', storeCategoryInfo);
        console.log('storeSubCategoryInfo : ', storeSubCategoryInfo);
    }, [storeCategoryInfo, storeSubCategoryInfo])

    useEffect(() => {
        fetchCategories();
    }, [activeCategory]);

    const fetchCategories = async () => {
        try {
            const storeCategoriesResponse = await categoryApi.storeCategory();
            const storeCategories = storeCategoriesResponse.data;
    
            const storeSubCategoriesResponse = await categoryApi.storeSubCategorySelect();
            const storeSubCategories = Array.isArray(storeSubCategoriesResponse.data) ? storeSubCategoriesResponse.data : [];
    
            const supportCategoriesResponse = await categoryApi.supportCategory();
            const supportCategories = supportCategoriesResponse.data;
    
            const supportSubCategoriesResponse = await categoryApi.supportSubCategorySelect();
            const supportSubCategories = Array.isArray(supportSubCategoriesResponse.data) ? supportSubCategoriesResponse.data : [];
    
            // 서브 카테고리를 메인 카테고리에 매핑
            const storeMappedCategories = storeCategories.map(category => {
                const subCategories = storeSubCategories.filter(
                    subCategory => subCategory.storeMainCategoryNo === category.dailyduckStorecategoryNo
                );
                return { ...category, subCategories }; // 각 카테고리에 맞는 서브 카테고리 배열을 추가
            });
    
            const supportMappedCategories = supportCategories.map(category => {
                const subCategories = supportSubCategories.filter(
                    subCategory => subCategory.supportMainCategoryNo === category.dailyduckSupportcategoryNo
                );
                return { ...category, subCategories }; // 각 카테고리에 맞는 서브 카테고리 배열을 추가
            });
    
            setStoreCategory(storeMappedCategories);
            setSupportCategory(supportMappedCategories);
        } catch (error) {
            console.error("Failed to fetch categories:", error);
        }
    };
    

    const toggleStore = (id) => {
        setOpenStore((prev) => ({
            ...prev,
            [id]: !prev[id],
        }));
    }

    const toggleSupport = (id) => {
        setOpenSupport((prev) => ({
            ...prev,
            [id]: !prev[id],
        }));
    }

    const onCategorySelect = (category) => {
        setActiveCategory(category);
    }

    // const storeCategorySelect = async () => {
    //     const response = await categoryApi.storeCategory();
    //     const data = response.data;

    //     console.log('storeCategorySelect : ', data);
    //     setStoreCategory(data);
    // }

    // const storeSubCategorySelect = async () => {
    //     const response = await categoryApi.storeSubCategorySelect();
    //     const data = response.data;

    //     console.log('storeSubCategorySelect : ', data);
    // }

    const supportCategorySelect = async () => {
        const response = await categoryApi.supportCategory();
        const data = response.data;

        console.log(data);
        setSupportCategory(data);
    }

    const handleStoreIconImageInputClick = () => {
        document.getElementById('hiddenStoreIconImageInput').click();
    };
    const handleSupportIconImageInputClick = () => {
        document.getElementById('hiddenSupportIconImageInput').click();
    };

    const storeIconImgAdd = async (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('image', file);
        const config = { headers: { 'content-type': 'multipart/form-data' } };
        const response = await supportApi.uploadImageToServer(formData, config);
        const data = response.data;
        const imageUrl = `${encodeURIComponent(data)}`;
        setImageStoreIconPreview(imageUrl);

        setStoreCategoryInfo(currentStoreCategoryInfo => ({
            ...currentStoreCategoryInfo,
            dailyduckStorecategoryImg: imageUrl
        }));
        
        e.target.value = '';
    };
    const handleStoreIconImageDeleteClick = async () => {
        try {
            const queryParams = imageStoreIconPreview.split('?')[1];
            let filePath = '';
            const params = queryParams.split('&');
            for (let param of params) {
                if (param.startsWith('file=')) {
                    filePath = decodeURIComponent(param.split('=')[1]);
                    break;
                }
            }
            const fileName = filePath.split('/').pop();
            await fetch(`/api/file/delete/${fileName}`, { method: 'DELETE' });
            setImageStoreIconPreview('');
            setStoreCategoryInfo(currentStoreCategoryInfo => ({
                ...currentStoreCategoryInfo,
                dailyduckStorecategoryImg: ''
            }));
        } catch (error) {
            console.error('Failed to delete image from server', error);
        }
    };
    const supportIconImgAdd = async (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('image', file);
        const config = { headers: { 'content-type': 'multipart/form-data' } };
        const response = await supportApi.uploadImageToServer(formData, config);
        const data = response.data;
        const imageUrl = `${encodeURIComponent(data)}`;
        setImageSupportIconPreview(imageUrl);

        setSupportCategoryInfo(currentSupportCategoryInfo => ({
            ...currentSupportCategoryInfo,
            dailyduckSupportcategoryImg: imageUrl
        }));

        e.target.value = '';
    };
    const handleSupportIconImageDeleteClick = async () => {
        try {
            const queryParams = imageSupportIconPreview.split('?')[1];
            let filePath = '';
            const params = queryParams.split('&');
            for (let param of params) {
                if (param.startsWith('file=')) {
                    filePath = decodeURIComponent(param.split('=')[1]);
                    break;
                }
            }
            const fileName = filePath.split('/').pop();
            await fetch(`/api/file/delete/${fileName}`, { method: 'DELETE' });
            setImageSupportIconPreview('');
            setSupportCategoryInfo(currentSupportCategoryInfo => ({
                ...currentSupportCategoryInfo,
                dailyduckSupportcategoryImg: ''
            }));
        } catch (error) {
            console.error('Failed to delete image from server', error);
        }
    };
    const onStoreEngCategoryName = (e) => {
            setStoreCategoryInfo(currentStoreCategoryInfo => ({
                ...currentStoreCategoryInfo,
                dailyduckStorecategoryEngName: e.target.value
            }));
    }
    const onStoreKorCategoryName = (e) => {
            setStoreCategoryInfo(currentStoreCategoryInfo => ({
                ...currentStoreCategoryInfo,
                dailyduckStorecategoryName: e.target.value
            }));
    }
    const onSupportEngCategoryName = (e) => {
            setSupportCategoryInfo(currentSupportCategoryInfo => ({
                ...currentSupportCategoryInfo,
                dailyduckSupportcategoryEngName: e.target.value
            }));
    }
    const onSupportKorCategoryName = (e) => {
            setSupportCategoryInfo(currentSupportCategoryInfo => ({
                ...currentSupportCategoryInfo,
                dailyduckSupportcategoryName: e.target.value
            }));
    }

    const storeReset = () => {
        setStoreCategoryAdd(false);
        setStoreCategoryInfo({
            dailyduckStorecategoryNo : '', 
            dailyduckStorecategoryEngName : '', 
            dailyduckStorecategoryName : '', 
            dailyduckStorecategoryImg : '', 
            dailyduckStorecategoryRegDate : ''
        });
    }
    const supportReset = () => {
        setSupportCategoryAdd(false);
        setSupportCategoryInfo({
            dailyduckSupportcategoryNo : '', 
            dailyduckSupportcategoryEngName : '', 
            dailyduckSupportcategoryName : '', 
            dailyduckSupportcategoryImg : '', 
            supportRegDate : ''
        });
    }

    const storeCategoryInsert = () => {
        try {
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html: `
                      <div>
                          <div class='${admin.admin_seller_add_title}'><span>스토어 카테고리를 등록 하시겠습니까?</span></div>
                          <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_cancel'>취소</button>
                            <button class='mx-1' id='seller_add_success'>등록</button>
                          </div>
                      </div>
                  `,
                showConfirmButton: false,
                showCancelButton: false,
            });
            document.addEventListener('click', sellerAddSuccess);
            document.addEventListener('click', sellerAddCancel);

            async function sellerAddSuccess(e) {
                if (e.target && e.target.id === 'seller_add_success') {
                    try {
                        const response = await categoryApi.storeCategoryInsert(storeCategoryInfo);
                        const data = response.data;

                        if (data === 'SUCCESS') {
                            MySwal.fire({
                                customClass: {
                                    popup: `${admin.admin_seller_add_popup}`
                                },
                                html: `
                                  <div>
                                    <div class='${admin.admin_seller_add_title}'><span>스토어 카테고리가 등록되었습니다.</span></div>
                                    <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                                    </div>
                                  </div>
                                  `,
                                showConfirmButton: false,
                                showCancelButton: false,
                            });
                            document.addEventListener('click', sellerAddConfirm);

                            function sellerAddConfirm(e) {
                                if (e.target && e.target.id === 'seller_add_confirm') {
                                    fetchCategories();
                                    storeReset();
                                    MySwal.close();
                                    document.removeEventListener('click', sellerAddConfirm);
                                    document.removeEventListener('click', sellerAddSuccess);
                                    document.removeEventListener('click', sellerAddCancel);
                                }
                            }
                        } else {
                            MySwal.fire({
                                customClass: {
                                    popup: `${admin.admin_seller_add_popup}`
                                },
                                html: `
                                  <div>
                                    <div class='${admin.admin_seller_add_title}'><span>스토어 카테고리 등록에 실패하였습니다.</span></div>
                                    <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                                    </div>
                                  </div>
                                  `,
                                showConfirmButton: false,
                                showCancelButton: false,
                            });
                            document.addEventListener('click', sellerAddConfirm);

                            function sellerAddConfirm(e) {
                                if (e.target && e.target.id === 'seller_add_confirm') {
                                    MySwal.close();
                                    document.removeEventListener('click', sellerAddConfirm);
                                    document.removeEventListener('click', sellerAddSuccess);
                                    document.removeEventListener('click', sellerAddCancel);
                                }
                            }
                        }
                    } catch (error) {
                        console.error('error : ', error);
                    }
                }
            }

            function sellerAddCancel(e) {
                if (e.target && e.target.id === 'seller_add_cancel') {
                    MySwal.close();
                    document.removeEventListener('click', sellerAddSuccess);
                    document.removeEventListener('click', sellerAddCancel);
                }
            }
        } catch (error) {
            console.error('스토어 카테고리 등록에 문제가 생겼습니다.', error);
        }
    }

    const supportCategoryInsert = () => {
        try {
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html: `
                      <div>
                          <div class='${admin.admin_seller_add_title}'><span>서포트 카테고리를 등록 하시겠습니까?</span></div>
                          <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_cancel'>취소</button>
                            <button class='mx-1' id='seller_add_success'>등록</button>
                          </div>
                      </div>
                  `,
                showConfirmButton: false,
                showCancelButton: false,
            });
            document.addEventListener('click', sellerAddSuccess);
            document.addEventListener('click', sellerAddCancel);

            async function sellerAddSuccess(e) {
                if (e.target && e.target.id === 'seller_add_success') {
                    try {
                        const response = await categoryApi.supportCategoryInsert(supportCategoryInfo);
                        const data = response.data;

                        if (data === 'SUCCESS') {
                            MySwal.fire({
                                customClass: {
                                    popup: `${admin.admin_seller_add_popup}`
                                },
                                html: `
                                  <div>
                                    <div class='${admin.admin_seller_add_title}'><span>서포트 카테고리가 등록되었습니다.</span></div>
                                    <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                                    </div>
                                  </div>
                                  `,
                                showConfirmButton: false,
                                showCancelButton: false,
                            });
                            document.addEventListener('click', sellerAddConfirm);

                            function sellerAddConfirm(e) {
                                if (e.target && e.target.id === 'seller_add_confirm') {
                                    fetchCategories();
                                    supportReset();
                                    MySwal.close();
                                    document.removeEventListener('click', sellerAddConfirm);
                                    document.removeEventListener('click', sellerAddSuccess);
                                    document.removeEventListener('click', sellerAddCancel);
                                }
                            }
                        } else {
                            MySwal.fire({
                                customClass: {
                                    popup: `${admin.admin_seller_add_popup}`
                                },
                                html: `
                                  <div>
                                    <div class='${admin.admin_seller_add_title}'><span>서포트 카테고리 등록에 실패하였습니다.</span></div>
                                    <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                                    </div>
                                  </div>
                                  `,
                                showConfirmButton: false,
                                showCancelButton: false,
                            });
                            document.addEventListener('click', sellerAddConfirm);

                            function sellerAddConfirm(e) {
                                if (e.target && e.target.id === 'seller_add_confirm') {
                                    MySwal.close();
                                    document.removeEventListener('click', sellerAddConfirm);
                                    document.removeEventListener('click', sellerAddSuccess);
                                    document.removeEventListener('click', sellerAddCancel);
                                }
                            }
                        }
                    } catch (error) {
                        console.error('error : ', error);
                    }
                }
            }

            function sellerAddCancel(e) {
                if (e.target && e.target.id === 'seller_add_cancel') {
                    MySwal.close();
                    document.removeEventListener('click', sellerAddSuccess);
                    document.removeEventListener('click', sellerAddCancel);
                }
            }
        } catch (error) {
            console.error('서포트 카테고리 등록에 문제가 생겼습니다.', error);
        }
    }

    const storeSubCategoryBtn = (item) => {
        setStoreSubCategoryAdd(!storeSubCategoryAdd);

        setStoreSubCategoryInfo(currentStoreSubCategoryInfo => ({
            ...currentStoreSubCategoryInfo,
            storeMainCategoryNo : item.dailyduckStorecategoryNo,
            storeMainCategoryEngName : item.dailyduckStorecategoryEngName,
            storeMainCategoryKorName : item.dailyduckStorecategoryName
        }));
    }
    const supportSubCategoryBtn = (item) => {
        setSupportSubCategoryAdd(!storeSubCategoryAdd);

        setSupportSubCategoryInfo(currentSupportSubCategoryInfo => ({
            ...currentSupportSubCategoryInfo,
            supportMainCategoryNo : item.dailyduckSupportcategoryNo,
            supportMainCategoryEngName : item.dailyduckSupportcategoryEngName,
            supportMainCategoryKorName : item.dailyduckSupportcategoryName
        }));
    }

    const onStoreSubCategoryEngName = (e) => {
        setStoreSubCategoryInfo(currentStoreSubCategoryInfo => ({
            ...currentStoreSubCategoryInfo,
            storeSubCategoryEngName : e.target.value
        }));
    }
    const onStoreSubCategoryKorName = (e) => {
        setStoreSubCategoryInfo(currentStoreSubCategoryInfo => ({
            ...currentStoreSubCategoryInfo,
            storeSubCategoryKorName : e.target.value
        }));
    }
    const onSupportSubCategoryEngName = (e) => {
        setSupportSubCategoryInfo(currentSupportSubCategoryInfo => ({
            ...currentSupportSubCategoryInfo,
            supportSubCategoryEngName : e.target.value
        }));
    }
    const onSupportSubCategoryKorName = (e) => {
        setSupportSubCategoryInfo(currentSupportSubCategoryInfo => ({
            ...currentSupportSubCategoryInfo,
            supportSubCategoryKorName : e.target.value
        }));
    }
    const storeSubReset = () => {
        setStoreSubCategoryAdd(false);
        setStoreSubCategoryInfo({
            storeMainCategoryNo : 0,
            storeMainCategoryEngName : '',
            storeMainCategoryKorName : '',
            storeSubCategoryEngName : '',
            storeSubCategoryKorName : ''
        });
    }
    const supportSubReset = () => {
        setSupportSubCategoryAdd(false);
        setSupportSubCategoryInfo({
            supportMainCategoryNo : 0,
            supportMainCategoryEngName : '',
            supportMainCategoryKorName : '',
            supportSubCategoryEngName : '',
            supportSubCategoryKorName : ''
        });
    }

    const storeSubCategoryInsert = () => {
        try {
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html: `
                      <div>
                          <div class='${admin.admin_seller_add_title}'><span>${storeSubCategoryInfo.storeMainCategoryKorName}의 서브 카테고리를 등록 하시겠습니까?</span></div>
                          <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_cancel'>취소</button>
                            <button class='mx-1' id='seller_add_success'>등록</button>
                          </div>
                      </div>
                  `,
                showConfirmButton: false,
                showCancelButton: false,
            });
            document.addEventListener('click', sellerAddSuccess);
            document.addEventListener('click', sellerAddCancel);

            async function sellerAddSuccess(e) {
                if (e.target && e.target.id === 'seller_add_success') {
                    try {
                        const response = await categoryApi.storeSubCategoryInsert(storeSubCategoryInfo);
                        const data = response.data;

                        if (data === 'SUCCESS') {
                            MySwal.fire({
                                customClass: {
                                    popup: `${admin.admin_seller_add_popup}`
                                },
                                html: `
                                  <div>
                                    <div class='${admin.admin_seller_add_title}'><span>${storeSubCategoryInfo.storeMainCategoryKorName}의 서브 카테고리가 등록되었습니다.</span></div>
                                    <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                                    </div>
                                  </div>
                                  `,
                                showConfirmButton: false,
                                showCancelButton: false,
                            });
                            document.addEventListener('click', sellerAddConfirm);

                            function sellerAddConfirm(e) {
                                if (e.target && e.target.id === 'seller_add_confirm') {
                                    fetchCategories();
                                    storeSubReset();
                                    MySwal.close();
                                    document.removeEventListener('click', sellerAddConfirm);
                                    document.removeEventListener('click', sellerAddSuccess);
                                    document.removeEventListener('click', sellerAddCancel);
                                }
                            }
                        } else {
                            MySwal.fire({
                                customClass: {
                                    popup: `${admin.admin_seller_add_popup}`
                                },
                                html: `
                                  <div>
                                    <div class='${admin.admin_seller_add_title}'><span>${storeSubCategoryInfo.storeMainCategoryKorName}의 서브 카테고리 등록에 실패하였습니다.</span></div>
                                    <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                                    </div>
                                  </div>
                                  `,
                                showConfirmButton: false,
                                showCancelButton: false,
                            });
                            document.addEventListener('click', sellerAddConfirm);

                            function sellerAddConfirm(e) {
                                if (e.target && e.target.id === 'seller_add_confirm') {
                                    MySwal.close();
                                    document.removeEventListener('click', sellerAddConfirm);
                                    document.removeEventListener('click', sellerAddSuccess);
                                    document.removeEventListener('click', sellerAddCancel);
                                }
                            }
                        }
                    } catch (error) {
                        console.error('error : ', error);
                    }
                }
            }

            function sellerAddCancel(e) {
                if (e.target && e.target.id === 'seller_add_cancel') {
                    MySwal.close();
                    document.removeEventListener('click', sellerAddSuccess);
                    document.removeEventListener('click', sellerAddCancel);
                }
            }
        } catch (error) {
            console.error('서브 카테고리 등록에 문제가 생겼습니다.', error);
        }
    }
    const supportSubCategoryInsert = () => {
        try {
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html: `
                      <div>
                          <div class='${admin.admin_seller_add_title}'><span>${supportSubCategoryInfo.supportMainCategoryKorName}의 서브 카테고리를 등록 하시겠습니까?</span></div>
                          <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_cancel'>취소</button>
                            <button class='mx-1' id='seller_add_success'>등록</button>
                          </div>
                      </div>
                  `,
                showConfirmButton: false,
                showCancelButton: false,
            });
            document.addEventListener('click', sellerAddSuccess);
            document.addEventListener('click', sellerAddCancel);

            async function sellerAddSuccess(e) {
                if (e.target && e.target.id === 'seller_add_success') {
                    try {
                        const response = await categoryApi.supportSubCategoryInsert(supportSubCategoryInfo);
                        const data = response.data;

                        if (data === 'SUCCESS') {
                            MySwal.fire({
                                customClass: {
                                    popup: `${admin.admin_seller_add_popup}`
                                },
                                html: `
                                  <div>
                                    <div class='${admin.admin_seller_add_title}'><span>${supportSubCategoryInfo.supportMainCategoryKorName}의 서브 카테고리가 등록되었습니다.</span></div>
                                    <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                                    </div>
                                  </div>
                                  `,
                                showConfirmButton: false,
                                showCancelButton: false,
                            });
                            document.addEventListener('click', sellerAddConfirm);

                            function sellerAddConfirm(e) {
                                if (e.target && e.target.id === 'seller_add_confirm') {
                                    fetchCategories();
                                    supportSubReset();
                                    MySwal.close();
                                    document.removeEventListener('click', sellerAddConfirm);
                                    document.removeEventListener('click', sellerAddSuccess);
                                    document.removeEventListener('click', sellerAddCancel);
                                }
                            }
                        } else {
                            MySwal.fire({
                                customClass: {
                                    popup: `${admin.admin_seller_add_popup}`
                                },
                                html: `
                                  <div>
                                    <div class='${admin.admin_seller_add_title}'><span>${supportSubCategoryInfo.supportMainCategoryKorName}의 서브 카테고리 등록에 실패하였습니다.</span></div>
                                    <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                                    </div>
                                  </div>
                                  `,
                                showConfirmButton: false,
                                showCancelButton: false,
                            });
                            document.addEventListener('click', sellerAddConfirm);

                            function sellerAddConfirm(e) {
                                if (e.target && e.target.id === 'seller_add_confirm') {
                                    MySwal.close();
                                    document.removeEventListener('click', sellerAddConfirm);
                                    document.removeEventListener('click', sellerAddSuccess);
                                    document.removeEventListener('click', sellerAddCancel);
                                }
                            }
                        }
                    } catch (error) {
                        console.error('error : ', error);
                    }
                }
            }

            function sellerAddCancel(e) {
                if (e.target && e.target.id === 'seller_add_cancel') {
                    MySwal.close();
                    document.removeEventListener('click', sellerAddSuccess);
                    document.removeEventListener('click', sellerAddCancel);
                }
            }
        } catch (error) {
            console.error('서브 카테고리 등록에 문제가 생겼습니다.', error);
        }
    }

    const storeCategoryDelete = (item) => {
        try {
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html: `
                      <div>
                          <div class='${admin.admin_seller_add_title}'><span>${item.dailyduckStorecategoryName} 카테고리를 삭제 하시겠습니까?</span></div>
                          <div class='${admin.admin_seller_add_sub_title}'><span>하위 메뉴가 있을 경우 하위 메뉴가 전부 삭제됩니다.</span></div>
                          <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_cancel'>취소</button>
                            <button class='mx-1' id='seller_add_success'>삭제</button>
                          </div>
                      </div>
                  `,
                showConfirmButton: false,
                showCancelButton: false,
            });
            document.addEventListener('click', sellerAddSuccess);
            document.addEventListener('click', sellerAddCancel);

            async function sellerAddSuccess(e) {
                if (e.target && e.target.id === 'seller_add_success') {
                    try {
                        const response = await categoryApi.storeCategoryDelete(item.dailyduckStorecategoryNo);
                        const data = response.data;

                        if (data === 'SUCCESS') {
                            MySwal.fire({
                                customClass: {
                                    popup: `${admin.admin_seller_add_popup}`
                                },
                                html: `
                                  <div>
                                    <div class='${admin.admin_seller_add_title}'><span>${item.dailyduckStorecategoryName} 카테고리가 삭제되었습니다.</span></div>
                                    <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                                    </div>
                                  </div>
                                  `,
                                showConfirmButton: false,
                                showCancelButton: false,
                            });
                            document.addEventListener('click', sellerAddConfirm);

                            function sellerAddConfirm(e) {
                                if (e.target && e.target.id === 'seller_add_confirm') {
                                    fetchCategories();
                                    supportSubReset();
                                    MySwal.close();
                                    document.removeEventListener('click', sellerAddConfirm);
                                    document.removeEventListener('click', sellerAddSuccess);
                                    document.removeEventListener('click', sellerAddCancel);
                                }
                            }
                        } else {
                            MySwal.fire({
                                customClass: {
                                    popup: `${admin.admin_seller_add_popup}`
                                },
                                html: `
                                  <div>
                                    <div class='${admin.admin_seller_add_title}'><span>${item.dailyduckStorecategoryName} 카테고리 삭제에 실패하였습니다.</span></div>
                                    <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                                    </div>
                                  </div>
                                  `,
                                showConfirmButton: false,
                                showCancelButton: false,
                            });
                            document.addEventListener('click', sellerAddConfirm);

                            function sellerAddConfirm(e) {
                                if (e.target && e.target.id === 'seller_add_confirm') {
                                    MySwal.close();
                                    document.removeEventListener('click', sellerAddConfirm);
                                    document.removeEventListener('click', sellerAddSuccess);
                                    document.removeEventListener('click', sellerAddCancel);
                                }
                            }
                        }
                    } catch (error) {
                        console.error('error : ', error);
                    }
                }
            }

            function sellerAddCancel(e) {
                if (e.target && e.target.id === 'seller_add_cancel') {
                    MySwal.close();
                    document.removeEventListener('click', sellerAddSuccess);
                    document.removeEventListener('click', sellerAddCancel);
                }
            }
        } catch (error) {
            console.error('카테고리 삭제에 문제가 생겼습니다.', error);
        }
    }
    const supportCategoryDelete = (item) => {
        try {
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html: `
                      <div>
                          <div class='${admin.admin_seller_add_title}'><span>${item.dailyduckSupportcategoryName} 카테고리를 삭제 하시겠습니까?</span></div>
                          <div class='${admin.admin_seller_add_sub_title}'><span>하위 메뉴가 있을 경우 하위 메뉴가 전부 삭제됩니다.</span></div>
                          <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_cancel'>취소</button>
                            <button class='mx-1' id='seller_add_success'>삭제</button>
                          </div>
                      </div>
                  `,
                showConfirmButton: false,
                showCancelButton: false,
            });
            document.addEventListener('click', sellerAddSuccess);
            document.addEventListener('click', sellerAddCancel);

            async function sellerAddSuccess(e) {
                if (e.target && e.target.id === 'seller_add_success') {
                    try {
                        const response = await categoryApi.supportCategoryDelete(item.dailyduckSupportcategoryNo);
                        const data = response.data;

                        if (data === 'SUCCESS') {
                            MySwal.fire({
                                customClass: {
                                    popup: `${admin.admin_seller_add_popup}`
                                },
                                html: `
                                  <div>
                                    <div class='${admin.admin_seller_add_title}'><span>${item.dailyduckSupportcategoryName} 카테고리가 삭제되었습니다.</span></div>
                                    <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                                    </div>
                                  </div>
                                  `,
                                showConfirmButton: false,
                                showCancelButton: false,
                            });
                            document.addEventListener('click', sellerAddConfirm);

                            function sellerAddConfirm(e) {
                                if (e.target && e.target.id === 'seller_add_confirm') {
                                    fetchCategories();
                                    supportSubReset();
                                    MySwal.close();
                                    document.removeEventListener('click', sellerAddConfirm);
                                    document.removeEventListener('click', sellerAddSuccess);
                                    document.removeEventListener('click', sellerAddCancel);
                                }
                            }
                        } else {
                            MySwal.fire({
                                customClass: {
                                    popup: `${admin.admin_seller_add_popup}`
                                },
                                html: `
                                  <div>
                                    <div class='${admin.admin_seller_add_title}'><span>${item.dailyduckSupportcategoryName} 카테고리 삭제에 실패하였습니다.</span></div>
                                    <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                                    </div>
                                  </div>
                                  `,
                                showConfirmButton: false,
                                showCancelButton: false,
                            });
                            document.addEventListener('click', sellerAddConfirm);

                            function sellerAddConfirm(e) {
                                if (e.target && e.target.id === 'seller_add_confirm') {
                                    MySwal.close();
                                    document.removeEventListener('click', sellerAddConfirm);
                                    document.removeEventListener('click', sellerAddSuccess);
                                    document.removeEventListener('click', sellerAddCancel);
                                }
                            }
                        }
                    } catch (error) {
                        console.error('error : ', error);
                    }
                }
            }

            function sellerAddCancel(e) {
                if (e.target && e.target.id === 'seller_add_cancel') {
                    MySwal.close();
                    document.removeEventListener('click', sellerAddSuccess);
                    document.removeEventListener('click', sellerAddCancel);
                }
            }
        } catch (error) {
            console.error('카테고리 삭제에 문제가 생겼습니다.', error);
        }
    }

    const onStoreSubCategoryRemove = (subItem) => {
        try {
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html: `
                      <div>
                          <div class='${admin.admin_seller_add_title}'><span>${subItem.storeSubCategoryKorName} 서브 카테고리를 삭제 하시겠습니까?</span></div>
                          <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_cancel'>취소</button>
                            <button class='mx-1' id='seller_add_success'>삭제</button>
                          </div>
                      </div>
                  `,
                showConfirmButton: false,
                showCancelButton: false,
            });
            document.addEventListener('click', sellerAddSuccess);
            document.addEventListener('click', sellerAddCancel);

            async function sellerAddSuccess(e) {
                if (e.target && e.target.id === 'seller_add_success') {
                    try {
                        const response = await categoryApi.supporSubCategoryRemove(subItem.storeSubCategoryNo);
                        const data = response.data;

                        if (data === 'SUCCESS') {
                            MySwal.fire({
                                customClass: {
                                    popup: `${admin.admin_seller_add_popup}`
                                },
                                html: `
                                  <div>
                                    <div class='${admin.admin_seller_add_title}'><span>${subItem.storeSubCategoryKorName} 서브 카테고리가 삭제되었습니다.</span></div>
                                    <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                                    </div>
                                  </div>
                                  `,
                                showConfirmButton: false,
                                showCancelButton: false,
                            });
                            document.addEventListener('click', sellerAddConfirm);

                            function sellerAddConfirm(e) {
                                if (e.target && e.target.id === 'seller_add_confirm') {
                                    fetchCategories();
                                    supportSubReset();
                                    MySwal.close();
                                    document.removeEventListener('click', sellerAddConfirm);
                                    document.removeEventListener('click', sellerAddSuccess);
                                    document.removeEventListener('click', sellerAddCancel);
                                }
                            }
                        } else {
                            MySwal.fire({
                                customClass: {
                                    popup: `${admin.admin_seller_add_popup}`
                                },
                                html: `
                                  <div>
                                    <div class='${admin.admin_seller_add_title}'><span>${subItem.storeSubCategoryKorName} 서브 카테고리 삭제에 실패하였습니다.</span></div>
                                    <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                                    </div>
                                  </div>
                                  `,
                                showConfirmButton: false,
                                showCancelButton: false,
                            });
                            document.addEventListener('click', sellerAddConfirm);

                            function sellerAddConfirm(e) {
                                if (e.target && e.target.id === 'seller_add_confirm') {
                                    MySwal.close();
                                    document.removeEventListener('click', sellerAddConfirm);
                                    document.removeEventListener('click', sellerAddSuccess);
                                    document.removeEventListener('click', sellerAddCancel);
                                }
                            }
                        }
                    } catch (error) {
                        console.error('error : ', error);
                    }
                }
            }

            function sellerAddCancel(e) {
                if (e.target && e.target.id === 'seller_add_cancel') {
                    MySwal.close();
                    document.removeEventListener('click', sellerAddSuccess);
                    document.removeEventListener('click', sellerAddCancel);
                }
            }
        } catch (error) {
            console.error('서브 카테고리 삭제에 문제가 생겼습니다.', error);
        }
    }
    
    const onSupportSubCategoryRemove = (subItem) => {
        try {
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html: `
                      <div>
                          <div class='${admin.admin_seller_add_title}'><span>${subItem.supportSubCategoryKorName} 서브 카테고리를 삭제 하시겠습니까?</span></div>
                          <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_cancel'>취소</button>
                            <button class='mx-1' id='seller_add_success'>삭제</button>
                          </div>
                      </div>
                  `,
                showConfirmButton: false,
                showCancelButton: false,
            });
            document.addEventListener('click', sellerAddSuccess);
            document.addEventListener('click', sellerAddCancel);

            async function sellerAddSuccess(e) {
                if (e.target && e.target.id === 'seller_add_success') {
                    try {
                        const response = await categoryApi.supportSubCategoryRemove(subItem.supportSubCategoryNo);
                        const data = response.data;

                        if (data === 'SUCCESS') {
                            MySwal.fire({
                                customClass: {
                                    popup: `${admin.admin_seller_add_popup}`
                                },
                                html: `
                                  <div>
                                    <div class='${admin.admin_seller_add_title}'><span>${subItem.supportSubCategoryKorName} 서브 카테고리가 삭제되었습니다.</span></div>
                                    <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                                    </div>
                                  </div>
                                  `,
                                showConfirmButton: false,
                                showCancelButton: false,
                            });
                            document.addEventListener('click', sellerAddConfirm);

                            function sellerAddConfirm(e) {
                                if (e.target && e.target.id === 'seller_add_confirm') {
                                    fetchCategories();
                                    supportSubReset();
                                    MySwal.close();
                                    document.removeEventListener('click', sellerAddConfirm);
                                    document.removeEventListener('click', sellerAddSuccess);
                                    document.removeEventListener('click', sellerAddCancel);
                                }
                            }
                        } else {
                            MySwal.fire({
                                customClass: {
                                    popup: `${admin.admin_seller_add_popup}`
                                },
                                html: `
                                  <div>
                                    <div class='${admin.admin_seller_add_title}'><span>${subItem.supportSubCategoryKorName} 서브 카테고리 삭제에 실패하였습니다.</span></div>
                                    <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                                    </div>
                                  </div>
                                  `,
                                showConfirmButton: false,
                                showCancelButton: false,
                            });
                            document.addEventListener('click', sellerAddConfirm);

                            function sellerAddConfirm(e) {
                                if (e.target && e.target.id === 'seller_add_confirm') {
                                    MySwal.close();
                                    document.removeEventListener('click', sellerAddConfirm);
                                    document.removeEventListener('click', sellerAddSuccess);
                                    document.removeEventListener('click', sellerAddCancel);
                                }
                            }
                        }
                    } catch (error) {
                        console.error('error : ', error);
                    }
                }
            }

            function sellerAddCancel(e) {
                if (e.target && e.target.id === 'seller_add_cancel') {
                    MySwal.close();
                    document.removeEventListener('click', sellerAddSuccess);
                    document.removeEventListener('click', sellerAddCancel);
                }
            }
        } catch (error) {
            console.error('서브 카테고리 삭제에 문제가 생겼습니다.', error);
        }
    }

    return (
        <div className='px-3 py-3'>
            <div>
                <div className={admin.admin_category_title}><span>카테고리 설정</span></div>
                <div className={admin.admin_category_tab}>
                    <div className={`${activeCategory === 'store' ? admin.admin_category_active : ''}`}>
                        <button className={`${activeCategory === 'store' ? admin.admin_category_active_txt : ''}`} onClick={() => onCategorySelect('store')}>스토어</button>
                    </div>
                    <div className={`${activeCategory === 'support' ? admin.admin_category_active : ''}`}>
                        <button className={`${activeCategory === 'support' ? admin.admin_category_active_txt : ''}`} onClick={() => onCategorySelect('support')}>서포트</button>
                    </div>
                </div>
                <div>
                    {
                        activeCategory === 'store' &&
                        <div className={admin.admin_category_store_box}>
                            <div className={admin.admin_category_store_add_btn}>
                                <button onClick={() => setStoreCategoryAdd(true)}>카테고리추가</button>
                            </div>
                            {
                                Array.isArray(storeCategory) && storeCategory.length > 0 && storeCategory.map((item, index) => {

                                    return (
                                        <React.Fragment key={index}>
                                            <div className={admin.admin_category_store_item}>
                                                <div className={admin.admin_category_store_item_info}>
                                                    <div><img src={`${process.env.REACT_APP_API_SERVER}/img?file=${item.dailyduckStorecategoryImg}`} alt="" /></div>
                                                    <div>
                                                        <div><span>{item.dailyduckStorecategoryEngName}</span></div>
                                                        <div><span>{item.dailyduckStorecategoryName}</span></div>
                                                    </div>
                                                </div>
                                                <div className={admin.admin_category_store_item_info_btn}>
                                                    <button onClick={(e) => {e.stopPropagation(); storeCategoryDelete(item);}}>카테고리삭제</button>
                                                    <button onClick={(e) => {e.stopPropagation(); toggleStore(item.dailyduckStorecategoryNo);}}>{!openStore[item.dailyduckStorecategoryNo] ? '하위메뉴보기' : '하위메뉴접기'}</button>
                                                </div>
                                            </div>
                                            
                                            {/* 하위 메뉴를 CSSTransition으로 보여주기 */}
                                            <CSSTransition in={openStore[item.dailyduckStorecategoryNo]} timeout={300} classNames="review" unmountOnExit>
                                                <div className={admin.admin_category_store_sub_item}>
                                                    <div className={admin.admin_category_store_sub_item_add_btn}>
                                                        <button onClick={() => storeSubCategoryBtn(item)}>하위메뉴추가</button>
                                                    </div>
                                                    <div>
                                                        {
                                                            item.subCategories && item.subCategories.length > 0 ? (
                                                                item.subCategories.map((subItem, subIndex) => (
                                                                    <div key={subIndex} className={admin.admin_category_store_sub_item_info}>
                                                                        <div>
                                                                            <div>
                                                                                <div><span>영문 서브 카테고리명&nbsp;-&nbsp;</span></div>
                                                                                <div><span>{subItem.storeSubCategoryEngName}</span></div>
                                                                            </div>
                                                                            <div>
                                                                                <div><span>한글 서브 카테고리명&nbsp;-&nbsp;</span></div>
                                                                                <div><span>{subItem.storeSubCategoryKorName}</span></div>
                                                                            </div>
                                                                        </div>
                                                                        <div><button onClick={() => onStoreSubCategoryRemove(subItem)}><span className="material-symbols-outlined">remove</span></button></div>
                                                                    </div>
                                                                ))
                                                            ) : (
                                                                <span>하위 메뉴가 없습니다.</span>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </CSSTransition>
                                        </React.Fragment>
                                    )
                                })
                            }
                        </div>
                    }
                    {
                        activeCategory === 'support' &&
                        <div className={admin.admin_category_store_box}>
                            <div className={admin.admin_category_store_add_btn}><button onClick={() => setSupportCategoryAdd(true)}>카테고리추가</button></div>
                            {
                                Array.isArray(supportCategory) && supportCategory.length > 0 && supportCategory.map((item, index) => {

                                    return (
                                        <React.Fragment key={index}>
                                            <div className={admin.admin_category_store_item}>
                                                <div className={admin.admin_category_store_item_info}>
                                                    <div><img src={`${process.env.REACT_APP_API_SERVER}/img?file=${item.dailyduckSupportcategoryImg}`} alt="" /></div>
                                                    <div>
                                                        <div><span>{item.dailyduckSupportcategoryEngName}</span></div>
                                                        <div><span>{item.dailyduckSupportcategoryName}</span></div>
                                                    </div>
                                                </div>
                                                <div className={admin.admin_category_store_item_info_btn}>
                                                    <button onClick={(e) => {e.stopPropagation(); supportCategoryDelete(item);}}>카테고리삭제</button>
                                                    <button onClick={(e) => {e.stopPropagation(); toggleSupport(index);}}>하위메뉴보기</button>
                                                </div>
                                            </div>
                                            <CSSTransition in={openSupport[index]} timeout={300} classNames="review" unmountOnExit>
                                                <div className={admin.admin_category_store_sub_item}>
                                                    <div className={admin.admin_category_store_sub_item_add_btn}>
                                                        <button onClick={() => supportSubCategoryBtn(item)}>하위메뉴추가</button>
                                                    </div>
                                                    <div>
                                                        {
                                                            item.subCategories && item.subCategories.length > 0 ? (
                                                                item.subCategories.map((subItem, subIndex) => (
                                                                    <div key={subIndex} className={admin.admin_category_store_sub_item_info}>
                                                                        <div>
                                                                            <div>
                                                                                <div><span>영문 서브 카테고리명&nbsp;-&nbsp;</span></div>
                                                                                <div><span>{subItem.supportSubCategoryEngName}</span></div>
                                                                            </div>
                                                                            <div>
                                                                                <div><span>한글 서브 카테고리명&nbsp;-&nbsp;</span></div>
                                                                                <div><span>{subItem.supportSubCategoryKorName}</span></div>
                                                                            </div>
                                                                        </div>
                                                                        <div><button onClick={() => onSupportSubCategoryRemove(subItem)}><span className="material-symbols-outlined">remove</span></button></div>
                                                                    </div>
                                                                ))
                                                            ) : (
                                                                <span>하위 메뉴가 없습니다.</span>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </CSSTransition>
                                        </React.Fragment>
                                    )
                                })
                            }
                        </div>
                    }
                </div>
            </div>
            {
                storeCategoryAdd &&
                <div className={`${admin.admin_category_add_modal}`}>
                    <div className={`${admin.admin_category_add_modal_box}`}>
                        <div className={`${admin.admin_category_add_modal_box_header}`}><button onClick={() => storeReset()}><span className="material-symbols-outlined">close</span></button></div>
                        <div className={`${admin.admin_category_add_modal_box_info}`}>
                            <div className={`${admin.admin_category_add_modal_box_info_img}`}>
                                <div><span>아이콘이미지</span></div>
                                <div>
                                    <input
                                        id="hiddenStoreIconImageInput"
                                        type="file"
                                        onChange={(event) => storeIconImgAdd(event)}
                                        style={{display: 'none'}}
                                    />
                                    {imageStoreIconPreview === '' ? 
                                        <div>
                                            <img src={`${process.env.PUBLIC_URL}/img/seller/icon/default2.png`} alt="" />
                                        </div>
                                        :
                                        <div><img src={imageStoreIconPreview === '' ? '' : `${process.env.REACT_APP_API_SERVER}/img?file=${imageStoreIconPreview}`} alt="상점 프로필" /></div> 
                                    }
                                    <div>
                                        <div><button onClick={() => handleStoreIconImageInputClick()}>등록</button></div>
                                        <div><button onClick={() => handleStoreIconImageDeleteClick()}>삭제</button></div>
                                    </div>
                                </div>
                            </div>
                            <div className={`${admin.admin_category_add_modal_box_info_txt}`}>
                                <div><span>영문 카테고리명</span></div>
                                <div><input type="text" onChange={(e) => onStoreEngCategoryName(e)}/></div>
                            </div>
                            <div className={`${admin.admin_category_add_modal_box_info_txt}`}>
                                <div><span>한글 카테고리명</span></div>
                                <div><input type="text" onChange={(e) => onStoreKorCategoryName(e)}/></div>
                            </div>
                        </div>
                        <div className={`${admin.admin_category_add_modal_footer}`}>
                            <div><button onClick={() => storeReset()}>취소</button></div>
                            <div><button onClick={() => storeCategoryInsert()}>등록</button></div>
                        </div>
                    </div>
                </div>
            }
            {
                supportCategoryAdd &&
                <div className={`${admin.admin_category_add_modal}`}>
                    <div className={`${admin.admin_category_add_modal_box}`}>
                    <div className={`${admin.admin_category_add_modal_box_header}`}><button onClick={() => supportReset()}><span className="material-symbols-outlined">close</span></button></div>
                        <div className={`${admin.admin_category_add_modal_box_info}`}>
                            <div className={`${admin.admin_category_add_modal_box_info_img}`}>
                                <div><span>아이콘이미지</span></div>
                                <div>
                                    <input
                                        id="hiddenSupportIconImageInput"
                                        type="file"
                                        onChange={(event) => supportIconImgAdd(event)}
                                        style={{display: 'none'}}
                                    />
                                    {imageSupportIconPreview === '' ? 
                                        <div>
                                            <img src={`${process.env.PUBLIC_URL}/img/seller/icon/default2.png`} alt="" />
                                        </div>
                                        :
                                        <div><img src={imageSupportIconPreview === '' ? '' : `${process.env.REACT_APP_API_SERVER}/img?file=${imageSupportIconPreview}`} alt="상점 프로필" /></div> 
                                    }
                                    <div>
                                        <div><button onClick={() => handleSupportIconImageInputClick()}>등록</button></div>
                                        <div><button onClick={() => handleSupportIconImageDeleteClick()}>삭제</button></div>
                                    </div>
                                </div>
                            </div>
                            <div className={`${admin.admin_category_add_modal_box_info_txt}`}>
                                <div><span>영문 카테고리명</span></div>
                                <div><input type="text" onChange={(e) => onSupportEngCategoryName(e)}/></div>
                            </div>
                            <div className={`${admin.admin_category_add_modal_box_info_txt}`}>
                                <div><span>한글 카테고리명</span></div>
                                <div><input type="text" onChange={(e) => onSupportKorCategoryName(e)}/></div>
                            </div>
                        </div>
                        <div className={`${admin.admin_category_add_modal_footer}`}>
                            <div><button onClick={() => supportReset()}>취소</button></div>
                            <div><button onClick={() => supportCategoryInsert()}>등록</button></div>
                        </div>
                    </div>
                </div>
            }
            {
             storeSubCategoryAdd &&
             <div className={`${admin.admin_sub_category_modal}`}>
                <div className={`${admin.admin_sub_category_modal_box}`}>
                    <div className={`${admin.admin_sub_category_modal_title}`}><button onClick={() => storeSubReset()}><span className="material-symbols-outlined">close</span></button></div>
                    <div  className={`${admin.admin_sub_category_modal_content}`}>
                        <div>
                            <div><span>영문 서브 카테고리명</span></div>
                            <div><input type="text" onChange={(e) => onStoreSubCategoryEngName(e)}/></div>
                        </div>
                        <div>
                            <div><span>한글 서브 카테고리명</span></div>
                            <div><input type="text" onChange={(e) => onStoreSubCategoryKorName(e)}/></div>
                        </div>
                    </div>
                    <div className={`${admin.admin_sub_category_modal_footer}`}>
                        <div><button onClick={() => storeSubReset()}>취소</button></div>
                        <div><button onClick={() => storeSubCategoryInsert()}>등록</button></div>
                    </div>
                </div>
             </div>   
            }
            {
             supportSubCategoryAdd &&
             <div className={`${admin.admin_sub_category_modal}`}>
                <div className={`${admin.admin_sub_category_modal_box}`}>
                    <div className={`${admin.admin_sub_category_modal_title}`}><button onClick={() => supportSubReset()}><span className="material-symbols-outlined">close</span></button></div>
                    <div  className={`${admin.admin_sub_category_modal_content}`}>
                        <div>
                            <div><span>영문 서브 카테고리명</span></div>
                            <div><input type="text" onChange={(e) => onSupportSubCategoryEngName(e)}/></div>
                        </div>
                        <div>
                            <div><span>한글 서브 카테고리명</span></div>
                            <div><input type="text" onChange={(e) => onSupportSubCategoryKorName(e)}/></div>
                        </div>
                    </div>
                    <div className={`${admin.admin_sub_category_modal_footer}`}>
                        <div><button onClick={() => supportSubReset()}>취소</button></div>
                        <div><button onClick={() => supportSubCategoryInsert()}>등록</button></div>
                    </div>
                </div>
             </div>   
            }
        </div>
    )
}

export default AdminCategory;
