import React from 'react';
import pp from './popup.module.css';
import { useSpring, useTransition } from '@react-spring/core';
import { animated } from 'react-spring';

const MiddlePopup = ( {showMiddlePopup, setShowMiddlePopUp, middlePopupData} ) => {

    const popupTransitions = useTransition(showMiddlePopup, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: {
            duration: 200,
        },
    });

    const handleClose = () => {
        setShowMiddlePopUp(false);
    };

    const handleTodayClose = () => {
        localStorage.setItem('middlePopupClosed', new Date().getTime()); // 24시간 유효하게 설정
        setShowMiddlePopUp(false);
    };
  return (
    <>
        {
            showMiddlePopup &&
            <div className={pp.middle_popup_container}>
                {
                    popupTransitions((style, item) =>
                        item ? 
                        <animated.div className={pp.middle_popup_box} style={style}>
                            <div>
                                <div className={pp.middle_popup_header}>
                                    <div>
                                        <div><button onClick={handleTodayClose}>오늘 하루 보지않기</button></div>
                                    </div>
                                    <div><button onClick={handleClose}><span className="material-symbols-outlined">close</span></button></div>
                                </div>
                            </div>
                        </animated.div>
                        : null
                    )
                }
            </div>
        }
    </>
  )
}

export default MiddlePopup
