import React, { useContext, useEffect, useState } from 'react'
import DailyduckHeader from '../components/header/Dailyduck_Header'
import DailyduckNavigator from '../components/footer/Dailyduck_Navigator'
import AlbumCategory from '../components/album/AlbumCategory'
import AlbumFavority from '../components/album/AlbumFavority'
import { useLocation, useNavigate } from 'react-router-dom';
import { LoginContext } from '../contexts/LoginContextProvider';
import * as auth from '../apis/auth'
import smiming from './smiming/smiming.module.css'
import SmimingChart from '../components/smiming/SmimingChart'

const HomeContainer = () => {
    
  const navigate = useNavigate();
  const { isLogin, sellerlogout, userInfo, loginCheck, roles } = useContext(LoginContext);
  const [ users, setUsers ] = useState({});
  const [ userFavorityList, setUserFavorityList ] = useState([]);
  const [ artistName, setArtistName ] = useState('');

  useEffect(() => {
    // console.log(users);
    // console.log(userFavorityList);
    // console.log(artistName);
  },[users, userFavorityList, artistName])

  useEffect(() => {
    userSelect();
  }, [userInfo]);

  useEffect(() => {
    if(users) {
      userFavority();
    }
  }, [users])
  
  useEffect(() => {
    // userFavorityList에서 favorityRanking이 1인 객체를 찾음
    if(userFavorityList) {
      const topArtist = userFavorityList.find(favority => favority.favorityRanking === 1);
  
      // 찾은 객체의 favorityName을 artistName 상태로 설정
      if (topArtist) {
        setArtistName(topArtist.favorityName);
      }
    }
  }, [userFavorityList]); // userFavorityList가 변경될 때마다 useEffect 실행
  
  const userSelect = async () => {
    const response = await auth.userselect(userInfo && userInfo.userId);
    const data = response.data;

    if(response.status === 200) {
      setUsers(data);
    }
  }

  const userFavority = async () => {
    const response = await auth.favorityList(users && users.userId);
    const data = response.data;

    if(response.status === 200) {
      setUserFavorityList(data);
    }
  }

  const location = useLocation();
  const key = location.state ? location.state.key : '기본값';

  // console.log(key); // 상태가 없다면 '기본값'을 출력

  const handleBack = () => {
    navigate(-1);
  }

  return (
    <>
        {/* <DailyduckHeader /> */}
        <div className='position-relative d-flex justify-content-center py-3 px-3'>
          <div className='position-absolute start-0 top-0 pt-3 ps-3'><button className='bg-body border-0' onClick={handleBack}><span class="material-symbols-outlined">arrow_back_ios</span></button></div>
          <div><h5>duckduck Chart</h5></div>
        </div>
        {
          artistName &&
          <SmimingChart userInfo={userInfo} users={users} userFavorityList={userFavorityList} smiming={smiming} artistName={artistName}/>
        }
        {/* <AlbumCategory />
        <AlbumFavority />
        <br /><br /><br /><br /><br /><br /><br /> */}
        {/* <DailyduckNavigator /> */}
    </>
  )
}

export default HomeContainer