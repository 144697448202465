import React from 'react'
import DailyduckHeader from '../../components/header/Dailyduck_Header'
import DailyduckNavigator from '../../components/footer/Dailyduck_Navigator'
import EventSection from '../../components/event/EventSection'
import event from './event.module.css'

const EventContainer = () => {
  return (
    <>
    <DailyduckHeader />
    <EventSection event={event} />
    <DailyduckNavigator />
    </>
  )
}

export default EventContainer