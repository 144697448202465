import React from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'; // 기본 스타일

const SalesCalendar = ({ salesData }) => {
    // 날짜를 클릭했을 때의 핸들러, 필요에 따라 구현
    const onDayClick = (value, event) => {
        // console.log(value, event);
    };
    const formatDay = (locale, date) => {
        return date.getDate(); // "일"을 제외하고 숫자만 반환
    };

    // 커스텀 렌더링을 위한 함수, 여기서 매출과 판매 건수를 표시
    const tileContent = ({ date, view }) => {
        // 월별 뷰에서만 데이터 표시
        if (view === 'month') {
            // 날짜를 기준으로 데이터 찾기, `salesData`는 날짜별 매출과 판매 건수를 포함해야 함
            const salesInfo = salesData.find(d => new Date(d.date).toDateString() === date.toDateString());
            
            // 매출과 판매 건수가 있는 경우 표시
            if (salesInfo) {

                const salesAmount = Number(salesInfo.sales); // salesInfo.sales를 숫자 타입으로 변환
                const formattedSales = salesAmount.toLocaleString(); // 숫자 타입으로 변환된 값을 toLocaleString()으로 포맷팅

                if(salesInfo.count != 0) {
                    return (
                        <div className='px-2 pt-3'>
                            <div className='w-100 text-start' style={{ color : 'rgba(255, 114, 153, 1)'}}><span>&#8361;{formattedSales}</span></div>
                            <div className='w-100 text-start dd-fs-7 pt-2'><span>판매 {salesInfo.count}건</span></div>
                        </div>
                    );
                    
                } else {
                    return (
                        <div className='px-2 pt-3'>
                            <div className='w-100 text-start'><span>&#8361;{formattedSales}</span></div>
                        </div>
                    );

                }
            }
        }
    };

    return (
        <Calendar
            onClickDay={onDayClick}
            formatDay={formatDay}
            tileContent={tileContent}
        />
    );
};

export default SalesCalendar;
