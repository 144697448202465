import React, { useState } from 'react'
import { animated } from 'react-spring';
import { useSpring, useTransition } from '@react-spring/core';
import dailyduck from './MyContainner.module.css'
import { useNavigate } from 'react-router-dom';

const MyNotification = ( {showNotification, setShowNotification} ) => {

    const navigate = useNavigate();
    const [pushChecked, setPushChecked] = useState(false);
    const [smsChecked, setSmsChecked] = useState(false);
    const [emailChecked, setEmailChecked] = useState(false);
    const [cpChecked, setCpChecked] = useState(false);
    const [reviewChecked, setReviewChecked] = useState(false);

    const handlePushChange = () => {setPushChecked(!pushChecked);};
    const handleSmsChange = () => {setSmsChecked(!smsChecked);};
    const handleEmailChange = () => {setEmailChecked(!emailChecked);};
    const handleCpChange = () => {setCpChecked(!cpChecked);};
    const handleReviewChange = () => {setReviewChecked(!reviewChecked);};

    const transitions = useTransition(showNotification, {
        from: { transform: "translateX(100%)" },
        enter: { transform: "translateX(0)" },
        leave: { transform: "translateX(100%)" },
        config: {
            duration: 300,
        },
    });   
    const handleNotificationBack = () => {
        navigate('/my');
    }

    return (
        <>
            {
                <div className={`${dailyduck.my_service_alarm_container}`}>
                        <div className={dailyduck.my_service_alarm_box}>
                            <div className={dailyduck.my_service_alarm_header}>
                                <div>
                                    <button onClick={() => handleNotificationBack()}>
                                        <span className="material-symbols-outlined">arrow_back_ios</span>
                                    </button>
                                </div>
                                <div>
                                    <div><span>알림 설정</span></div>
                                </div>
                            </div>
                            <div className={dailyduck.my_service_alarm_content}>
                                <div className={dailyduck.my_service_alarm_content_service}>
                                    <div><span>서비스 알림</span></div>
                                    <div>
                                        <div>
                                            <div><span>PUSH 알림</span></div>
                                            <div>    
                                                <label className={`${dailyduck.notification_switch}`}>
                                                <input type="checkbox" checked={pushChecked} onChange={handlePushChange} />
                                                <span className={`${dailyduck.notification_slider} ${dailyduck.notification_round}`}></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div>
                                            <div><span>문자 알림</span></div>
                                            <div>    
                                                <label className={`${dailyduck.notification_switch}`}>
                                                <input type="checkbox" checked={smsChecked} onChange={handleSmsChange} />
                                                <span className={`${dailyduck.notification_slider} ${dailyduck.notification_round}`}></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div>
                                            <div><span>이메일 알림</span></div>
                                            <div>    
                                                <label className={`${dailyduck.notification_switch}`}>
                                                <input type="checkbox" checked={emailChecked} onChange={handleEmailChange} />
                                                <span className={`${dailyduck.notification_slider} ${dailyduck.notification_round}`}></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={dailyduck.my_service_alarm_content_activity}>
                                    <div><span>내 활동 알림</span></div>
                                    <div>
                                        <div>
                                            <div>
                                                <div><span>쿠폰·포인트 소멸 알림</span></div>
                                                <div className={dailyduck.my_service_alarm_content_activity_sub_title}><span>내 쿠폰·포인트 소멸 전 알림</span></div>
                                            </div>
                                            <div>    
                                                <label className={`${dailyduck.notification_switch}`}>
                                                <input type="checkbox" checked={cpChecked} onChange={handleCpChange} />
                                                <span className={`${dailyduck.notification_slider} ${dailyduck.notification_round}`}></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <div>
                                                    <div><span>리뷰 좋아요·댓글 알림</span></div>
                                                    <div className={dailyduck.my_service_alarm_content_activity_sub_title}><span>내 리뷰에 좋아요·댓글 알림</span></div>
                                                </div>
                                            </div>
                                            <div>    
                                                <label className={`${dailyduck.notification_switch}`}>
                                                <input type="checkbox" checked={reviewChecked} onChange={handleReviewChange} />
                                                <span className={`${dailyduck.notification_slider} ${dailyduck.notification_round}`}></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default MyNotification