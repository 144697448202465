import React, { useEffect, useState, useContext, useRef } from 'react';
import message from '../../containers/messege/message.module.css';
import { useNavigate, useParams } from 'react-router-dom';
import * as messageapi from '../../apis/message';
import * as sellerapi from '../../apis/seller';
import { LoginContext } from '../../contexts/LoginContextProvider';

const MessageChat = () => {
  const { no } = useParams();
  const navigate = useNavigate();
  const { userInfo } = useContext(LoginContext);
  const [messages, setMessages] = useState([]);
  const [shopInfo, setShopInfo] = useState({});
  const [userData, setUserData] = useState({});
  const [content, setContent] = useState('');
  const [chatData, setChatData] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null); // Add state for selected product
  const messagesEndRef = useRef(null);
  const messageContainerRef = useRef(null);
  const scrollTimerRef = useRef(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);

  const onBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    messageSelect();

    const intervalId = setInterval(() => {
      messageSelect();
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (messageContainerRef.current) {
      messageContainerRef.current.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (messageContainerRef.current) {
        messageContainerRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [messageContainerRef]);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleScroll = () => {
    if (messageContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = messageContainerRef.current;
      if (scrollTop + clientHeight < scrollHeight - 50) {
        if (scrollTimerRef.current) {
          clearTimeout(scrollTimerRef.current);
        }
        scrollTimerRef.current = setTimeout(() => {
          scrollToBottom();
        }, 5000);
      }
    }
  };

  const messageSelect = async () => {
    const response = await messageapi.messageContentSelect(no);
    const data = response.data;
    setChatData(data);
    setMessages(data.messageList);
    setShopInfo(data.shopDTO);
    setUserData(data.user);
    if (data.productNumber) {
      productSelect(data.productNumber);
    }
  };

  const productSelect = async (productNumber) => {
    try {
      const response = await sellerapi.detailProductSelect(productNumber);
      const data = response.data;
      setSelectedProduct(data);
    } catch (error) {
      console.error('Error fetching product details:', error);
    }
  };

  const onSend = async () => {
    const roomId = no;
    const userId = userInfo?.userId;
    const sellerId = shopInfo.userId;
    const productNumber = chatData.productNumber;

    // console.log(productNumber);

    if (selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile);
      formData.append('roomId', roomId);
      formData.append('userId', userId);
      formData.append('sellerId', sellerId);
      formData.append('productNumber', productNumber);

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };

      try {
        const response = await messageapi.uploadFile(formData, config);
        const data = response.data;

        if (data === 'SUCCESS') {
          messageSelect();
          setSelectedFile(null);
          setPreview(null);
        }
      } catch (error) {
        console.error('File upload error:', error);
      }
    } else if(content !== '') {
      const response = await messageapi.sendAdd(roomId, userId, sellerId, content, productNumber);
      const data = response.data;

      if (data === 'SUCCESS') {
        messageSelect();
        setContent('');
      }
    }
  };

  const onContent = (e) => {
    setContent(e.target.value);
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);

    const fileTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'image/heic'];
    if (file && fileTypes.includes(file.type)) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setPreview(null);
    }
  };

  const removePreview = () => {
    setSelectedFile(null);
    setPreview(null);
  };

  const downloadFile = async (fileUrl, fileName) => {
    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('File download error:', error);
    }
  };
  const onPayBtn = (msg) => {
    // console.log('msg : ', msg);
    const paymentNumber = Date.now();
    
    navigate(`/message/payment/${paymentNumber}`, { state : {msg : msg, payment : paymentNumber}});
  }

  const handleImageClick = (imgUrl) => {
    setPreviewImage(imgUrl);  // 클릭한 이미지 URL 설정
    setIsPreviewVisible(true);  // 미리보기 모달 열기
  };

  const renderMessageContent = (msg) => {
    if (msg.messageSend) {
      return msg.messageSend;
    } else if (msg.messageFile) {
      const fileUrl = `${process.env.REACT_APP_API_SERVER}/chat/download?file=${msg.messageFile}`;
      const fileType = msg.messageFile.split('.').pop().toLowerCase();
      const imageTypes = ['png', 'jpg', 'jpeg', 'gif', 'heic'];

      if (imageTypes.includes(fileType)) {
        return (
          <>
            <div className={`${message.message_img_rander}`}>
              <div>
                <img 
                  src={`${process.env.REACT_APP_API_SERVER}/img?file=${msg.messageFile}`} 
                  alt="file preview"
                  onClick={() => handleImageClick(`${process.env.REACT_APP_API_SERVER}/img?file=${msg.messageFile}`)} // 이미지 클릭 이벤트 추가
                  style={{ cursor: 'pointer' }} // 클릭 가능하도록 스타일 추가
                />
              </div>
              <div><button onClick={() => downloadFile(fileUrl, msg.messageFile)}><span className="material-symbols-outlined">download</span></button></div>
            </div>
          </>
        );
      } else {
        return (
          <>
            <div className={`${message.message_file_rander}`}>
              <div><img src="../../img/seller/icon/default_file.png" alt="파일 아이콘" /></div>
              <div><span>{msg.messageFile}</span></div>
              <div><button onClick={() => downloadFile(fileUrl, msg.messageFile)}><span className="material-symbols-outlined">download</span></button></div>
            </div>
          </>
        );
      }
    } else if (msg.paymentMessage && msg.paymentPrice !== null) {
      const product = selectedProduct && selectedProduct.productNumber === chatData.productNumber ? selectedProduct : null;
      return (
        <div className={`${message.message_payment_box}`}>
          <div className={`${message.message_payment_title} py-2`}>
              <span>데일리덕 세이프결제를 요청했어요.</span>
          </div>
          {product && (
            <div className={`${message.message_payment_product}`}>
              <div><img src={product.representativeImage} alt={product.productName} /></div>
              <div className='ps-1'><span>{product.productName}</span></div>
            </div>
          )}
          <div className={`${message.message_payment_service}`}>
              <div><span>제공 결제 내용</span></div>
              <div><span>{msg.paymentMessage}</span></div>
          </div>
          <div className={`${message.message_payment_price}`}>
              <div><span>금액</span></div>
              <div><span>{msg.paymentPrice.toLocaleString()}원</span></div>
          </div>
          <div className={`${message.message_payment_btn}`}>
            <button onClick={() => onPayBtn(msg)}>결제하기</button>
          </div>
        </div>
      );
    }
  };

  const getProfileImageUrl = (url) => {
    // 소셜 로그인 이미지: 'https://' 또는 'http://'가 포함되면서 외부 도메인일 경우
    if (url.startsWith('https://') || url.startsWith('http://')) {
        return url; // 소셜 로그인 이미지 URL 그대로 반환
    }
    // 직접 업로드된 이미지: '/root/app/images/' 혹은 서버 경로를 포함하는 경우
    return `${process.env.REACT_APP_API_SERVER}/img?file=${url}`;
  };

  return (
    <div className={`${message.message_chat_container}`}>
      <div>
        <div className={`${message.message_chat_content_header}`}>
          <div>
            <button onClick={onBack}>
              <span className="material-symbols-outlined">arrow_back_ios</span>
            </button>
          </div>
          <div>
            <div>
              <span>{chatData.tradingState}</span>
            </div>
            <div>
              <span>{shopInfo.shopName}</span>
            </div>
          </div>
          <div>
            <button>
              <span className="material-symbols-outlined">menu</span>
            </button>
          </div>
        </div>
        <div className={`${message.message_chat_content}`} ref={messageContainerRef}>
          {
            messages.length !== 0 ?
            messages.map((msg, index) => (
              <div key={msg.messageNo}>
                {msg.sendId === userInfo?.userId ? (
                  <div className={`${message.message_chat_sender_box}`}>
                    <div className={`${message.message_chat_sender}`}>
                      <div>
                        {renderMessageContent(msg)}
                      </div>
                    </div>
                    <div>
                      <img src={getProfileImageUrl(userData.userProfile)} alt="유저프로필" />
                    </div>
                  </div>
                ) : (
                  <div className={`${message.message_chat_recipient_box}`}>
                    <div>
                      <img src={shopInfo.shopProfileImg} alt="셀러프로필" />
                    </div>
                    <div className={`${message.message_chat_recipient}`}>
                      <div>
                        {renderMessageContent(msg)}
                      </div>
                    </div>
                  </div>
                )}
                {index === messages.length - 1 && (
                  <div ref={messagesEndRef} />
                )}
              </div>
            ))
            :
            <div className={`${message.message_chat_no_data}`}><span>아직 주고 받은 메시지가 없어요 💬</span></div>
          }
        </div>
        {preview ? (
          <div className={`${message.message_chat_preview}`}>
            <div className='position-relative'>
              <img src={preview} alt="Preview" style={{ maxWidth: '100%', maxHeight: '300px' }} />
              <button className={`${message.message_chat_preview_cancel}`} onClick={removePreview}><span className="material-symbols-outlined">close</span></button>
            </div>
          </div>
        ) : selectedFile ? (
          <div className={`${message.message_chat_preview}`}>
            <div className='position-relative'>
              <div className={`${message.message_chat_preview_file}`}>
                <div><img src="../../img/seller/icon/default_file.png" alt="파일 아이콘" /></div>
                <div><span>{selectedFile.name}</span></div>
              </div>
              <button className={`${message.message_chat_preview_cancel}`} onClick={removePreview}><span className="material-symbols-outlined">close</span></button>
            </div>
          </div>
        ) : null}
        <div className={`${message.message_chat_content_footer}`}>
          <div className={`${message.message_chat_content_send_box}`}>
            <div>
              <input type="file" onChange={handleFileChange} style={{ display: 'none' }} id="file-upload" />
              <label htmlFor="file-upload">
                <button onClick={() => document.getElementById('file-upload').click()}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="18.6" viewBox="0 0 24 18.6">
                    <g id="그룹_989" data-name="그룹 989" transform="translate(-2368.691 -1504.497)">
                      <g id="_11-DSLR_Camera" data-name=" 11-DSLR Camera">
                        <path id="패스_97" data-name="패스 97" d="M2390.942,1506.984h-6.351l-1.285-2.148a.7.7,0,0,0-.6-.339h-4.032a.7.7,0,0,0-.6.339l-1.283,2.148h-2.083v-.474a.7.7,0,0,0-.7-.7h-2.569a.7.7,0,0,0-.7.7h0v.474h-.3a1.746,1.746,0,0,0-1.748,1.744v12.625a1.747,1.747,0,0,0,1.749,1.744h20.5a1.747,1.747,0,0,0,1.751-1.742v-12.627a1.747,1.747,0,0,0-1.749-1.744ZM2380.691,1520a4.964,4.964,0,1,1,4.979-4.963,4.971,4.971,0,0,1-4.979,4.963Zm9.159-9.425h-2.179a.7.7,0,0,1,0-1.387h2.18a.7.7,0,1,1,0,1.387Z" fill="#d2d3d5" />
                        <ellipse id="타원_133" data-name="타원 133" cx="3.478" cy="3.467" rx="3.478" ry="3.467" transform="translate(2377.213 1511.573)" fill="#d2d3d5" />
                      </g>
                    </g>
                  </svg>
                </button>
              </label>
            </div>
            <div>
              <input 
                type="text" 
                onChange={(e) => onContent(e)} 
                value={content}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    onSend();
                  }
                }} 
              />
            </div>
            <div>
              <button onClick={onSend}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                  <path id="패스_14" data-name="패스 14" d="M1510.263,1578.87q-8.7-4.33-17.412-8.641c-.433-.214-.928-.578-1.393-.153s-.153.958-.023,1.415c.568,1.985,1.218,3.948,1.752,5.942.33,1.234,1.282,1.442,2.3,1.581,2.352.322,4.719.536,7.069.866-2.351.33-4.717.545-7.069.866-1.014.138-1.965.346-2.3,1.581-.534,1.994-1.184,3.957-1.752,5.942-.13.457-.428,1,.023,1.414s.96.062,1.393-.152q8.712-4.309,17.412-8.641c.65-.324.976-.664.954-1.01C1511.239,1579.534,1510.913,1579.193,1510.263,1578.87Z" transform="translate(-1491.218 -1569.88)" fill="#d2d3d5" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
      {isPreviewVisible && (
        <div className={message.preview_modal_container}>
          <div className={message.preview_modal_box}>
            <div className={message.preview_modal_header}>
              <span className={message.preview_close} onClick={() => setIsPreviewVisible(false)}>&times;</span>
            </div>
            <div className={message.preview_modal_content}>
              <img src={previewImage} alt="Preview" className={message.preview_image} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MessageChat;
