import React from 'react'

const TermsOfUse = ( {showTermsOfUse, termsOfUseTransitions, animated, setShowTermsOfUse, labelText} ) => {
    const handleTermsOfUseBack = () => {
        setShowTermsOfUse(false);
    }
  return (
    <>
        {showTermsOfUse &&
            <div className="termofuse_background">
                {termsOfUseTransitions((style, item) =>
                    item ? 
                    <animated.div className="termofuse_box" style={style}>
                        <div className='agree_header'>
                            <div className='position-absolute top-0 start-0 ps-4 pt-4'>
                                <button onClick={() => handleTermsOfUseBack()} className='reservation_pay_close'>
                                <span className="material-symbols-outlined">arrow_back_ios</span>
                                </button>
                            </div>
                            <div>
                                <div className='text-center py-4'>
                                    <h5>{labelText}</h5>
                                </div>
                            </div>
                        </div>
                    </animated.div>
                    : null
                )}
            </div>
        }
    </>
  );
};

export default TermsOfUse