import React, {useState, useEffect, useContext, useRef} from 'react'
import Country from './Country';
import { checkEmail, checkUserId, cernumber, phoneAuth } from '../../apis/auth';
import * as auth from '../../apis/auth';
import { Input, Icon } from 'semantic-ui-react';
import EmailJoin3 from './EmailJoin3';
import { LoginContext } from '../../contexts/LoginContextProvider';
import dailyduck from '../../containers/my/MyContainner.module.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const EmailJoin2 = ( {
    handleJoinBack, handleJoinClose, animated, joinTransitions, showJoin, useTransition, setShowAgree, setShowJoin, handleJoinInfo,
    userList, setUserList, userInput, setUserInput, showJoinInfo
} ) => {

    const context = useContext(LoginContext);
    const countries = context.countries;
    const MySwal = withReactContent(Swal);
    
    // 상태 관리
    const [ showCountry, setShowCountry ] = useState(false);
    const [filteredCountries, setFilteredCountries] = useState(countries);
    const [searchTerm, setSearchTerm] = useState('');
    const [userName, setUserName] = useState('');
    const [userId, setUserId] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');
    const [idMessage, setIdMessage] = useState('');
    const [phoneMessage, setPhoneMessage] = useState('');
    const [color, setColor] = useState('black');
    const [idColor, setIdColor] = useState('black');
    const [phoneColor, setPhoneColor] = useState('black');
    const [borderColor, setBorderColor] = useState("");
    const [idBorderColor, setIdBorderColor] = useState("");
    const [phoneBorderColor, setPhoneBorderColor] = useState("");
    const [passwordConfirmCheck, setPasswordConfirmCheck] = useState(false);
    const [phoneAuthState, setPhoneAuthState] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [passwordConfirmVisible, setPasswordConfirmVisible] = useState(false);
    const [certificationInputVisible, setCertificationInputVisible] = useState(false);
    const [userCertificationNumber, setUserCertificationNumber] = useState('');
    const [showEmailAuth, setShowEmailAuth] = useState(false);
    const [emailCertification, setEmailCertification] = useState(false);
    const [authNumber, setAuthNumber] = useState('');
    const [telInputEmpty, setTelInputEmpty] = useState(false);
    const [isPasswordValid, setIsPasswordValid] = useState({
        hasEnglish: false,
        hasNumber: false,
        hasSpecialChar: false,
        isValidLength: false,
    });

    const phoneTimerRef = useRef(null);
    const [phoneTimer, setPhoneTimer] = useState(300);
    const [ isLoading, setIsLoading ] = useState(false);

    useEffect(() => {
        // console.log('userInput : ', userInput);
    }, [userInput]);


    const handlePasswordChange = (e) => {
        const updatedPassword = e.target.value;
        setPassword(updatedPassword);
        setIsPasswordValid(passwordCheck(updatedPassword)); // passwordCheck 함수를 업데이트된 비밀번호로 호출

        setUserInput({
            ...userInput,
            password: updatedPassword,
        });

    };





    const handlePasswordConfirm = (e) => {

        setPasswordConfirm(e.target.value);
        
        if(password === passwordConfirm) {
            setPasswordConfirmCheck(false);
        } else {
            setPasswordConfirmCheck(true);
        }
    }



    const handleCountry = () => {
        setShowCountry(true);
    }

    const handleCountryClose = () => {
        setShowCountry(false);
    }

    function handleSelectedCountry(country) {
        
        if (!country) {
            // console.log('Country not defined');
            return;
        }

        setSelectedCountry({
            name: country.name,
            number: country.number,
            img: country.img
        });

        setUserInput({
            ...userInput,
            countries: country.name,
        });

        // console.log(country.img);
    }

    const idCheck = (e) => {
        setUserId(e.target.value);

        setUserInput({
            ...userInput,
            id: e.target.value,
        });

    }
    const emailCheck = (e) => {
        setEmail(e.target.value);

        setUserInput({
            ...userInput,
            email: e.target.value,
        });
    }
    const handleUserName = (e) => {
        setUserName(e.target.value);

        setUserInput({
            ...userInput,
            username : e.target.value,
        })
    }
    const handlePhoneNumber = (e) => {
        if(e.target.value != '' && selectedCountry != null) {
            setTelInputEmpty(true);
        } else {
            setTelInputEmpty(false);
        }

        setPhoneNumber(e.target.value);

        setUserInput({
            ...userInput,
            phone: e.target.value,
        });
    }

    useEffect(() => {
        // 이메일 형식을 검증하는 정규 표현식
        const emailRegex = 
        /^[^\s@]+@[^\s@]+\.(com|net|co\.kr|xyz|cn|com\.cn|net\.cn|org\.cn|ph|in|co\.in|jp|co\.jp|tw|com\.tw|sq|com\.sq|hk|com\.hk|co\.id|vn|com\.vn|my|com\.my|ir|cx|af|am|com\.bd|io|co\.il|kg|la|mn|pk|ps|tj|tm|ae|us|co|bz|mx|com\.mx|com\.ar|ag|bo|com\.bo|cr|ac|com\.gt|ht|hn|com\.ni|vc|gs|gd|pa|com\.ve|ca|de|co\.uk|fr|es|com\.es|nl|it|ru|eu|be|me|dk|gr|se|ch|pl|ro|at|az|by|gl|gg|is|im|je|kz|lv|li|lt|mt|md|pt|sm|cz|com\.tr|so|mu|bi|cm|td|com\.et|cg|cd|co\.ls|mw|ma|na|com\.ng|rw|st|sc|co\.bw|tv|cc|ac|co\.ck|as|ws|com\.au|co\.nz|com\.ai|bs|com\.bs|do|com\.do|com\.fj|com\.jm|ki|ly|fm|ms|nr|nu|nf|pn|pr|sh|lccom\.sb|tt|vg|hm|tf)$/;
    
        if (emailRegex.test(email)) { // 정규 표현식을 사용하여 이메일 형식을 검증
            const timer = setTimeout(() => {
                checkEmail(email)  // emailCheck 함수 호출
                    .then(res => {
                        if (res.data.userEmail) {
                            setMessage('중복된 이메일입니다.');
                            setColor('rgba(255, 92, 92, 1)');
                            setBorderColor("rgba(255, 92, 92, 1)");
                        } else {
                            setMessage('사용 가능한 이메일입니다.');
                            setColor('green');
                            setBorderColor("green")
                        }
                    })
                    .catch(err => {
                        console.error(err);
                    });
            }, 1000);
    
            return () => clearTimeout(timer);
        } else {
            // 이메일 형식이 아닌 경우 메시지 업데이트
            if(email !== '') {
                setMessage('유효하지 않은 이메일 형식입니다.');
                setColor('rgba(255, 92, 92, 1)');
                setBorderColor("rgba(255, 92, 92, 1)");
                isFormFilled(false);
            } else {
                setMessage('');
            }
        }
    }, [email]);

    useEffect(() => {
        // 영어와 숫자만 포함하는 정규 표현식
        const idRegex = /^[A-Za-z0-9]+$/;
    
        if (idRegex.test(userId)) { // 정규 표현식을 사용하여 아이디 형식을 검증
            const timer = setTimeout(() => {
                checkUserId(userId)  // 아이디 중복 확인 함수 호출
                    .then(res => {
                        if (res.data.userId) {
                            setIdMessage('중복된 아이디입니다.');
                            setIdColor('rgba(255, 92, 92, 1)');
                            setIdBorderColor("rgba(255, 92, 92, 1)");
                        } else {
                            setIdMessage('사용 가능한 아이디입니다.');
                            setIdColor('green');
                            setIdBorderColor("green")
                        }
                    })
                    .catch(err => {
                        console.error(err);
                    });
            }, 1000);
    
            return () => clearTimeout(timer);
        } else {
            // 아이디 형식이 아닌 경우 메시지 업데이트
            if(userId !== '') {
                setIdMessage('아이디는 영어와 숫자만 포함해야 합니다.');
                setIdColor('rgba(255, 92, 92, 1)');
                setIdBorderColor("rgba(255, 92, 92, 1)");
            } else {
                setIdMessage('');
            }
        }
    }, [userId]);


  
    const handleTogglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };
      
    const handleTogglePasswordConfirmVisibility = () => {
        setPasswordConfirmVisible(!passwordConfirmVisible);
    };

    const checkEnglish = password => /[a-zA-Z]/.test(password);
    const checkNumber = password => /\d/.test(password);
    const checkSpecialChar = password => /[~@%^&*_|<>{}!]/.test(password);
    const checkLength = password => password.length >= 8;
    
    const passwordCheck = (password) => {
        // password가 문자열이 아니라면, 모든 검사를 false로 설정
        if (typeof password !== 'string') {
            return {
              hasEnglish: false,
              hasNumber: false,
              hasSpecialChar: false,
              isValidLength: false,
            };
        }
    
        return {
            hasEnglish: checkEnglish(password),
            hasNumber: checkNumber(password),
            hasSpecialChar: checkSpecialChar(password),
            isValidLength: checkLength(password),
        };
    };


    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        if (!password) {
        setIsFocused(false);
        }
    };

    const { hasEnglish, hasNumber, hasSpecialChar, isValidLength } = passwordCheck();

    const isFormFilled = () => {
        // 이메일 형식을 검사하는 정규 표현식
        const emailRegex = 
        /^[^\s@]+@[^\s@]+\.(com|net|co\.kr|xyz|cn|com\.cn|net\.cn|org\.cn|ph|in|co\.in|jp|co\.jp|tw|com\.tw|sq|com\.sq|hk|com\.hk|co\.id|vn|com\.vn|my|com\.my|ir|cx|af|am|com\.bd|io|co\.il|kg|la|mn|pk|ps|tj|tm|ae|us|co|bz|mx|com\.mx|com\.ar|ag|bo|com\.bo|cr|ac|com\.gt|ht|hn|com\.ni|vc|gs|gd|pa|com\.ve|ca|de|co\.uk|fr|es|com\.es|nl|it|ru|eu|be|me|dk|gr|se|ch|pl|ro|at|az|by|gl|gg|is|im|je|kz|lv|li|lt|mt|md|pt|sm|cz|com\.tr|so|mu|bi|cm|td|com\.et|cg|cd|co\.ls|mw|ma|na|com\.ng|rw|st|sc|co\.bw|tv|cc|ac|co\.ck|as|ws|com\.au|co\.nz|com\.ai|bs|com\.bs|do|com\.do|com\.fj|com\.jm|ki|ly|fm|ms|nr|nu|nf|pn|pr|sh|lccom\.sb|tt|vg|hm|tf)$/;

        const idRegex = /^[A-Za-z0-9]+$/;

        // 모든 필드가 채워져 있고, 이메일 형식도 유효한지 확인
        return userName && emailRegex.test(email) && idRegex.test(userId) && password 
                && passwordConfirm && phoneNumber && isPasswordValid
                && isPasswordValid.hasEnglish && isPasswordValid.hasNumber
                && isPasswordValid.hasSpecialChar && isPasswordValid.isValidLength
                && passwordConfirmCheck && phoneAuthState
                ;
    }

    const [mockCertificationNumber, setMockCertificationNumber] = useState('000000');
    const [isCheck, setIsCheck] = useState(false);
    const [timer, setTimer] = useState(300);
    const [certificationSuccess, setCertificationSuccess] = useState(false); // 인증 성공 상태 추가

        // 타이머 초기화 함수
        const startPhoneTimer = () => {
            // 기존 타이머를 클리어합니다.
            if (phoneTimerRef.current) {
            clearInterval(phoneTimerRef.current);
            }
    
            setPhoneTimer(300); // 타이머를 초기값으로 설정합니다.
            phoneTimerRef.current = setInterval(() => {
            setPhoneTimer(prevPhoneTimer => {
                if (prevPhoneTimer <= 1) {
                clearInterval(phoneTimerRef.current); // 타이머 정지
                setIsCheck(true); // 타이머가 끝나면 버튼을 활성화
                return 0;
                }
                return prevPhoneTimer - 1;
            });
            }, 1000);
        };

    const certificationNumber = async () => {
        setIsCheck(true); // 인증번호 받기 버튼 비활성화

        try {
            const response = await phoneAuth(phoneNumber);
            const data = await response.data;
            const statusCode = response.status;

            if (statusCode === 200) {
                MySwal.fire({
                    customClass: {
                      popup: `${dailyduck.my_info_address_popup}`
                    },
                    html: `
                    <div>
                      <div class='${dailyduck.my_info_address_title}'><span>인증문자가 발송되었습니다.</span></div>
                      <div class='${dailyduck.my_info_address_sub_title}'><span>인증 번호가 도착하지 않을 경우 휴대폰 번호를 확인해 주세요.</span></div>
                      <div class='${dailyduck.my_info_address_confirm_btn_box}'>
                        <button id='address_confirm'>확인</button>
                      </div>
                    </div>
                    `,
                    showConfirmButton: false,
                    showCancelButton: false,
                  });
          
                  document.addEventListener('click', addressConfirm);
          
                  function addressConfirm(e) {
                    if (e.target && e.target.id === 'address_confirm') {
                      MySwal.close();
                      document.removeEventListener('click', addressConfirm);
                      setCertificationInputVisible(true); // 인증번호 인풋 창 활성화
          
                      startPhoneTimer(); // 타이머 시작
                    }
                  }

            } else {
                console.error(response);
            }

        } catch (error) {
            console.error('API 호출 중 오류 발생:', error); // 오류 처리
        }

    };
        
    useEffect(() => {
        // 컴포넌트 언마운트 시 타이머 정리
        return () => {
          if (phoneTimerRef.current) {
            clearInterval(phoneTimerRef.current);
          }
        };
      }, []);

    useEffect(() => {
        if (userCertificationNumber === '') return;
        
        const timphoneTimerer = setTimeout(() => {
            checkCertificationNumber(userCertificationNumber);
        }, 3000);

        return () => clearTimeout(phoneTimer);
    }, [userCertificationNumber]);

    const checkCertificationNumber = async (certificationNumber) => {
        const response = await auth.phoneAuthCheck(userInput.phone, certificationNumber);
        const data = response.data;
        if (data === 'SUCCESS') {
            setIsCheck(true);
            setCertificationSuccess(true); // 인증번호가 맞으면 인증 성공 상태를 true로 설정
            setCertificationInputVisible(false);
            setPhoneMessage('핸드폰 인증에 성공하였습니다.');
            setPhoneColor('green');
            setPhoneBorderColor("green");
            setPhoneAuthState(true);

            // 인증 성공 시 타이머 초기화
            clearInterval(phoneTimer.current);
        } else {
            setIsCheck(false);
            setCertificationSuccess(false); // 인증번호가 틀리면 인증 성공 상태를 false로 설정
        }
    };

    const onEmailAuth = async () => {

        setIsLoading(true);

        const response = await auth.emailCheck(email);
        const data = response.data;

        // console.log(data);
        if (data === 'SUCCESS') {
            setIsLoading(false);
            MySwal.fire({
                customClass: {
                    popup: `${dailyduck.my_info_address_popup}`
                },
                html : 
                `
                <div>
                <div class='${dailyduck.my_info_address_title}'><span>인증메일이 발송되었습니다.</span></div>
                <div class='${dailyduck.my_info_address_sub_title}'><span>인증 메일이 보이지 않을 경우 스팸메일함을 확인해 주세요.</span></div>
                <div class='${dailyduck.my_info_address_confirm_btn_box}'>
                    <button id='address_confirm'>확인</button>
                </div>
                </div>
                `,
                showConfirmButton: false,
                showCancelButton: false,
              });
              document.addEventListener('click', addressConfirm);
              
              function addressConfirm(e) {
                  if(e.target && e.target.id == 'address_confirm') {
                      MySwal.close();
                      document.removeEventListener('click', addressConfirm);
                      setShowEmailAuth(true);
                  }
              }
        } else {
            MySwal.fire({
                customClass: {
                    popup: `${dailyduck.my_info_address_popup}`
                },
                html : 
                `
                <div>
                <div class='${dailyduck.my_info_address_title}'><span>인증메일 발송에 실패하였습니다.</span></div>
                <div class='${dailyduck.my_info_address_sub_title}'><span>계속 실패할 경우 데일리덕 고객센터로 연락주시기 바랍니다.</span></div>
                <div class='${dailyduck.my_info_address_confirm_btn_box}'>
                    <button id='address_confirm'>확인</button>
                </div>
                </div>
                `,
                showConfirmButton: false,
                showCancelButton: false,
              });
              document.addEventListener('click', addressConfirm);
              
              function addressConfirm(e) {
                  if(e.target && e.target.id == 'address_confirm') {
                      MySwal.close();
                      document.removeEventListener('click', addressConfirm);
                  }
              }
        }
    };

    const emailAuthCheck = async () => {
        const response = await auth.emailAuthNumberCheck(email, authNumber);
        const data = response.data;
        if (data === 'SUCCESS') {
            setShowEmailAuth(false);
            setEmailCertification(true);
        }
    };

    const emailAuthNumber = (e) => {
        setAuthNumber(e.target.value);
    };

    const danamicStyle = {
        backgroundColor: showEmailAuth ? 'rgba(223, 224, 226, 1)' : '',
        color : showEmailAuth ? 'gray' : ''
    }

    return (
        <>
            {
                isLoading &&
                <div className={`${dailyduck.loading_page}`}>
                    <div><img src={`${process.env.PUBLIC_URL}/img/kwangduck_loading.gif`} alt="" /></div>
                </div>
            }
            {showJoin &&
                <div className={`join_container ${showJoinInfo ? 'height_auto' : ''}`}>
                    {joinTransitions((style, item) =>
                        item ? 
                        <animated.div className="join_detail_box" style={style}>
                            <div className='join_detail_header'>
                                <div className='ps-3'><button className='region_close' onClick={() => handleJoinBack()}><span className="material-symbols-outlined">arrow_back_ios</span></button></div>
                                <div><span>회원가입</span></div>
                                <div className='pe-3'><button className='region_close' onClick={() => handleJoinClose()}><span className="material-symbols-outlined">close</span></button></div>
                            </div>
                            <div className='height_initialized'>
                                <div className='join_input_box'>
                                    <div className='pb-1'><span>이름</span></div>
                                    <div className='join_input'><input type="text" className='join_input_tag' placeholder='이름' onChange={(e) => handleUserName(e)}/></div>
                                </div>
                                <div className='join_input_box'>
                                    <div className='pb-1'><span>아이디</span></div>
                                    <div className='join_input'>
                                        <input type="text" className='join_input_tag' placeholder='아이디' onChange={idCheck} style={{ borderColor: `${idBorderColor}` }}/>
                                        <span style={{color : idColor}}>{idMessage}</span>
                                    </div>
                                </div>
                                <div className='join_input_box'>
                                    <div className='pb-1'><span>이메일</span></div>
                                    <div className='twitter_join_email_join_email'>
                                        <div className='position-relative'>
                                            <input type="text" className='join_input_tag' placeholder='이메일' value={userInput.email} onChange={emailCheck} style={{ borderColor: borderColor }} />
                                            {message === '사용 가능한 이메일입니다.' && (
                                                <div className='twitter_join_success_email_auth'>
                                                    {emailCertification ? (
                                                        <button className='twitter_join_auth_complete' disabled={emailCertification}>인증완료</button>
                                                    ) : (
                                                        <button  onClick={() => onEmailAuth()} disabled={showEmailAuth} style={danamicStyle}>인증메일 발송</button>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                        <span style={{ color: color }}>{message}</span>
                                        {showEmailAuth && (
                                            <div className='twitter_join_email_auth_number pt-2'>
                                                <div><span>인증번호</span></div>
                                                <div>
                                                    <div><input type="number" onChange={(e) => emailAuthNumber(e)} /></div>
                                                    <div><button onClick={() => emailAuthCheck()}>인증확인</button></div>
                                                </div>
                                                <div className={`${dailyduck.email_auth_info_resend} pt-1`}>
                                                    <div><span>인증번호를 받지 못하셨나요?</span></div>
                                                    <div><button onClick={onEmailAuth}>인증번호 재발송</button></div>
                                                </div>
                                                <div className={`${dailyduck.email_auth_info_spam_text}`}>
                                                    <div><span>*인증 메일이 보이지 않을 경우 스팸메일함을 확인해 주세요.</span></div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='join_input_box'>
                                    <div className='pb-1'><span>비밀번호</span></div>
                                    <div className='join_input' style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                                    <input 
                                        type={passwordVisible ? "text" : "password"} 
                                        className='join_input_tag' 
                                        placeholder='비밀번호' 
                                        onFocus={handleFocus} 
                                        onBlur={handleBlur} 
                                        onChange={(e) => handlePasswordChange(e)} 
                                        value={password} 
                                        style={{ paddingRight: '40px' }}
                                    />
                                    <button className='password_eye_btn' onClick={handleTogglePasswordVisibility} >
                                        {passwordVisible ? <span className="material-symbols-outlined">visibility</span> : <span className="material-symbols-outlined">visibility_off</span>}
                                    </button>
                                    </div>
                                    {isFocused && (
                                        <div className='d-flex justify-content-center pw_check_point'>
                                        <div className='pt-2 px-2 eng_check'>
                                            <span style={{ color: isPasswordValid.hasEnglish ? "rgba(0, 189, 103, 1)" : "rgba(196, 199, 202, 1)"}}>
                                            <span className="material-symbols-outlined" style={{ color: isPasswordValid.hasEnglish ? "rgba(0, 189, 103, 1)" : "rgba(196, 199, 202, 1)" }}>check</span>영문
                                            </span>
                                        </div>
                                        <div className='pt-2 px-2 number_check'>
                                            <span style={{ color: isPasswordValid.hasNumber ? "rgba(0, 189, 103, 1)" : "rgba(196, 199, 202, 1)"}}>
                                            <span className="material-symbols-outlined" style={{ color: isPasswordValid.hasNumber ? "rgba(0, 189, 103, 1)" : "rgba(196, 199, 202, 1)" }}>check</span>숫자
                                            </span>
                                        </div>
                                        <div className='pt-2 px-2 spec_check'>
                                            <span style={{ color: isPasswordValid.hasSpecialChar ? "rgba(0, 189, 103, 1)" : "rgba(196, 199, 202, 1)"}}>
                                            <span className="material-symbols-outlined" style={{ color: isPasswordValid.hasSpecialChar ? "rgba(0, 189, 103, 1)" : "rgba(196, 199, 202, 1)"}}>check</span>특수문자
                                            </span>
                                        </div>
                                        <div className='pt-2 px-2 word_check'>
                                            <span style={{ color: isPasswordValid.isValidLength ? "rgba(0, 189, 103, 1)" : "rgba(196, 199, 202, 1)"}}>
                                            <span className="material-symbols-outlined" style={{ color: isPasswordValid.isValidLength ? "rgba(0, 189, 103, 1)" : "rgba(196, 199, 202, 1)"}}>check</span>8자 이상
                                            </span>
                                        </div>
                                        </div>
                                    )}
                                </div>
                                <div className='join_input_box'>
                                    <div className='pb-1'><span>비밀번호 확인</span></div>
                                    <div className='join_input' style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                                        <input 
                                        type={passwordConfirmVisible ? "text" : "password"} 
                                        className='join_input_tag' 
                                        placeholder='비밀번호 확인' 
                                        style={{ paddingRight: '40px' }}
                                        onChange={(e) => handlePasswordConfirm(e)} 
                                        />
                                        <button className='password_eye_btn' onClick={handleTogglePasswordConfirmVisibility} >
                                        {passwordConfirmVisible ? <span className="material-symbols-outlined">visibility</span> : <span className="material-symbols-outlined">visibility_off</span>}
                                        </button>
                                    </div>
                                        {passwordConfirm !== '' && (
                                            password === passwordConfirm ? 
                                            <span style={{ color: 'green' }}>비밀번호가 일치합니다.</span> :
                                            <span style={{ color: 'red' }}>비밀번호가 일치하지 않습니다.</span>
                                        )}
                                </div>
                                <div className='join_input_box'>
                                    <div className='pb-1'><span>국가</span></div>
                                    <div className='join_input'>
                                        {!selectedCountry ? 
                                            <button type="text" className='d-flex' placeholder='국가' onClick={handleCountry}>
                                                <span className='country_name_input'>국가선택</span>
                                                <span className="material-symbols-outlined">expand_more</span>
                                            </button> : 
                                            <button type="text" className='d-flex' placeholder='국가' onClick={handleCountry}>
                                                <img src={selectedCountry?.img} alt={selectedCountry?.name} />
                                                <span className='country_name_input'>{selectedCountry?.name}</span>
                                                <span className="material-symbols-outlined">expand_more</span>
                                            </button>
                                        }
                                    </div>
                                </div>

                                <div className='join_input_box'>
                                    <div className='pb-1'><span>휴대폰 번호</span></div>
                                    <div className='join_input phone_number_box'>
                                        <input type="number" className='join_input_tag' placeholder='휴대폰 번호' onChange={(e) => handlePhoneNumber(e)} value={userInput?.phone === '' ? '' : userInput?.phone} style={{ borderColor: phoneBorderColor }} />
                                        <span style={{ color: 'rgba(255, 3, 102, 1)' }}>{selectedCountry?.number}</span>
                                    </div>
                                    {
                                        certificationInputVisible &&
                                        <div>
                                            <div className='pt-1'><input type="number" className='join_input_tag' placeholder='인증번호' onChange={(e) => setUserCertificationNumber(e.target.value)} /></div>
                                            <div>
                                                <span className='dd-fs-7 gray_color'>인증번호를 받지 못하셨나요?</span><span><button className={`${dailyduck.myinfo_authNumber_re} ps-2`} onClick={certificationNumber}>인증번호 재요청</button></span>
                                            </div>
                                        </div>
                                    }
                                    <div className='join_phone_auth'>{!certificationSuccess && <button className={`${!isCheck && 'join_phone_auth_active'}`} onClick={certificationNumber} disabled={isCheck}>인증번호 받기</button>}</div>
                                    <div><span style={{ color: phoneColor }}>{phoneMessage}</span></div>

                                </div>
                            </div>
                            <div className={`join_btn_box`}>
                                <button disabled={!isFormFilled()} className={`${isFormFilled() ? 'join_btn_box_active' : ''} mt-3`} onClick={handleJoinInfo}>다음</button>
                            </div>
                        </animated.div>
                        :null
                    )}
                    {
                        showCountry &&
                            <Country showCountry={showCountry} setShowCountry={setShowCountry} handleCountryClose={handleCountryClose} 
                                    searchTerm={searchTerm} setSearchTerm={setSearchTerm} countries={countries}
                                    filteredCountries={filteredCountries} setFilteredCountries={setFilteredCountries}
                                    setSelectedCountry={setSelectedCountry} handleSelectedCountry={handleSelectedCountry} />
                    }
                </div>
            }
        </>
    )
}

export default EmailJoin2