import React, { useContext, useEffect, useState } from 'react';
import { animated } from 'react-spring';
import { useSpring } from '@react-spring/core';
import dailyduck from './MyContainner.module.css';
import { useNavigate } from 'react-router-dom';
import * as auth from '../../apis/auth';
import { LoginContext } from '../../contexts/LoginContextProvider';
import { format } from 'date-fns';

const MyCouponPoint = () => {
  const navigate = useNavigate();
  const { userInfo } = useContext(LoginContext);
  const [myCpActiveButton, setMyCpActiveButton] = useState('coupon');
  const [couponList, setCouponList] = useState([]);
  const [activeFilter, setActiveFilter] = useState('all'); // 전체, 유효, 만료 필터
  const [couponType, setCouponType] = useState('new');
  const [user, setUser] = useState({});
  const [pointHistory, setPointHistory] = useState([]);

  useEffect(() => {
    couponSelect();
    userSelect();
    userPointHistorySelect();
  }, []);

  const couponSelect = async () => {
    const response = await auth.userCouponSelect(userInfo && userInfo.userId);
    const data = response.data;

    // console.log('coupon : ', data);
    setCouponList(data);
  };

  const userSelect = async () => {
    const response = await auth.userselect(userInfo && userInfo?.userId);
    const data = response.data;

    setUser(data);
  }

  const userPointHistorySelect = async () => {
    const response = await auth.userPointHistorySelect(userInfo && userInfo?.userId);
    const data = response.data;

    // console.log('history : ', data);
    setPointHistory(data);
};

  const handleCpBack = () => {
    navigate('/my');
  };

  const onPointRecharge = () => {
    navigate('/my/cpr');
  };

  const couponButtonClass = () => {
    setMyCpActiveButton('coupon');
  };

  const pointButtonClass = () => {
    setMyCpActiveButton('point');
  };

  const handleFilterChange = (filter) => {
    setActiveFilter(filter);
  };

  const typeCheck = (num, type) => {
    if (type === 'rate') {
      return `${num}% 할인`;
    } else if (type === 'won') {
      return `${num}원`;
    }
  };

  const formatDate = (date) => {
    return format(new Date(date), 'yyyy-MM-dd');
  };

  const dateCheck = (start, end) => {
    if (start === '1899-12-31T15:00:00.000+00:00' && end === '2999-12-29T15:00:00.000+00:00') {
      return <span>기간 제한 없음</span>;
    } else {
      return (
        <span>
          사용가능 기한 : <span>{formatDate(end)}</span>까지
        </span>
      );
    }
  };

  const filteredCouponList = couponList.filter((item) => {
    if (activeFilter === 'all') {
      return true;
    } else if (activeFilter === 'valid') {
      return !item.userCouponUse;
    } else if (activeFilter === 'expired') {
      return item.userCouponUse;
    }
  });


  return (
    <>
      <div className={`${dailyduck.mycouponporint_container}`}>
        <div className={dailyduck.mycouponporint_box}>
          <div>
            <div className={dailyduck.my_coupon_point_header}>
              <div>
                <button onClick={() => handleCpBack()}>
                  <span className="material-symbols-outlined">arrow_back_ios</span>
                </button>
              </div>
              <div>
                <div>
                  <span>쿠폰·포인트</span>
                </div>
              </div>
            </div>
            <div className={`bg-white ${dailyduck.my_coupon_point_tap_btn}`}>
              <div className="w-100 d-flex text-center">
                <div className="w-100">
                  <button
                    className={`w-100 ${dailyduck.coupon_button_class} ${myCpActiveButton === 'coupon' ? dailyduck.click_active : ''}`}
                    onClick={() => couponButtonClass()}
                  >
                    <span>쿠폰</span>
                  </button>
                </div>
                <div className="w-100">
                  <button
                    className={`w-100 ${dailyduck.point_button_class} ${myCpActiveButton === 'point' ? dailyduck.click_active : ''}`}
                    onClick={() => pointButtonClass()}
                  >
                    <span>포인트</span>
                  </button>
                </div>
              </div>
            </div>
            {
              myCpActiveButton === 'coupon' &&
                <div className={`${dailyduck.coupon_tap_btn}`}>
                  <div>
                    <button className={activeFilter === 'all' ? dailyduck.coupon_tap_btn_active : ''} onClick={() => handleFilterChange('all')}>전체</button>
                  </div>
                  <div>
                    <button className={activeFilter === 'valid' ? dailyduck.coupon_tap_btn_active : ''} onClick={() => handleFilterChange('valid')}>유효 쿠폰</button>
                  </div>
                  <div>
                    <button className={activeFilter === 'expired' ? dailyduck.coupon_tap_btn_active : ''} onClick={() => handleFilterChange('expired')}>만료된 쿠폰</button>
                  </div>
                </div>
            }
          </div>
          {myCpActiveButton === 'coupon' && (
            <div className={`${dailyduck.my_coupon_content_box}`}>
              <div className="bg-white pb-4 px-3 mt-2">
                <div>
                  <div>
                    <span>전체</span>
                    <span className="ps-2">{filteredCouponList.length}</span>
                  </div>
                </div>
              </div>
              <div className="bg-white mt-2">
                <div>
                  <div className="px-2">
                    {filteredCouponList.map((item, index) => (
                      <div key={index} className={`${dailyduck.my_coupon_box}`}>
                        <div className="py-4 px-2">
                          <span>{formatDate(item.userCouponDate)}</span>
                        </div>
                        <div className="d-flex text-center border-bottom w-100 d-flex justify-content-center flex-column align-items-center mb-3">
                          <div className={`${dailyduck.coupon_box} d-flex justify-content-center align-items-center position-relative`}>
                            {
                              item.userCouponUse &&
                              <div className={`${dailyduck.my_coupon_use_coupon}`}>
                                <div><span>사용된 쿠폰입니다.</span></div>
                              </div>
                            }
                            <div className={`${dailyduck.ribbon} ${dailyduck.ribbon_top_left} ${couponType}`}>
                              <span>신규</span>
                            </div>
                            <div className={`${dailyduck.my_coupon_title}`}>
                              <span>{item.couponName}</span>
                            </div>
                            <div className={`${dailyduck.my_coupon_price} mx-3 border-bottom`}>
                              <span>{typeCheck(item.couponBenefitNum, item.couponBenefitType)}</span>
                            </div>
                            <div className={`${dailyduck.my_coupon_content} pt-3`}>
                              <span>{item.couponDiscountCondition.toLocaleString()}원 이상 구매 시</span>
                              {item.couponMaximumDiscount !== null && (
                                <span className={`${dailyduck.my_coupon_content_maximum}`}>
                                  <br />
                                  최대 {item.couponMaximumDiscount.toLocaleString()} 할인
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="py-4">{dateCheck(item.couponStartDate, item.couponEndDate)}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
          {myCpActiveButton === 'point' && (
            <>
              <div className="bg-white d-flex justify-content-center align-items-center flex-column py-4 px-3">
                <div className={`${dailyduck.my_point_box} d-flex justify-content-center align-items-center flex-column py-4`}>
                  <div className={`${dailyduck.my_point_title} py-2`}>
                    <span>보유 포인트</span>
                  </div>
                  <div className={`${dailyduck.my_point_content} py-2`}>
                    <span>
                      {user.userPoint.toLocaleString()}<span>P</span>
                    </span>
                  </div>
                  <div className={`${dailyduck.my_point_footer} py-2`}>
                    <span>
                      소멸 예정 <span>0</span>P
                    </span>
                  </div>
                </div>
              </div>
              <div className="bg-white px-3 pb-3">
                <div className={`${dailyduck.my_point_btn} py-2`}>
                  <button onClick={() => onPointRecharge()}>포인트 충전하기</button>
                </div>
              </div>
              <div className='border-top'>
                <div className={`${dailyduck.my_point_history_title}`}><span>적립내역</span></div>
                <div className={`${dailyduck.my_point_history}`}>
                      <div>
                      {
                        Array.isArray(pointHistory) && pointHistory.map((item, index) => (
                            <div className={`${dailyduck.my_point_history_item}`}>
                              <div className="py-1">
                                <span>[{item.pointState}]</span>
                              </div>
                              <div className="d-flex justify-content-between py-1">
                                <div>
                                  <span>{item.pointContent}</span>
                                </div>
                                <div>
                                  <span className={item.pointState === '지급' ? dailyduck.btn_red_color : dailyduck.btn_blue_color}>
                                    <span>{item.pointState === '지급' ? '+' : '-'}</span>{item.point.toLocaleString()}
                                  </span>
                                </div>
                              </div>
                              <div className="py-1">
                                <span>{formatDate(item.pointRegDate)}</span>
                              </div>
                            </div>
                          ))
                      }
                      </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default MyCouponPoint;
