import React, { useState } from 'react';
import admin from '../../../admin.module.css';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { CSSTransition } from 'react-transition-group';

const AdminSellerProductDetail = () => {
    const navigate = useNavigate();
    const MySwal = withReactContent(Swal);

    const [ selectedRelation, setSelectedRelation ] = useState(null);
    const [ selectedPrice, setSelectedPrice ] = useState(null);
    const [ selectedOption, setSelectedOption ] = useState(null);
    const [ selectedInventory, setSelectedInventory ] = useState(null);

    const [openClassification, setOpenClassification] = useState(true);
    const [openRelation, setOpenRelation] = useState(true);
    const [openPriceInfo, setOpenPriceInfo] = useState(true);
    const [openDiscount, setOpenDiscount] = useState(true);
    const [openProductOption, setOpenProductOption] = useState(true);
    const [openInventory, setOpenInventory] = useState(true);
    const [openProductImage, setOpenProductImage] = useState(true);
    const [openProductDetail, setOpenProductDetail] = useState(true);
    const [openDelivery, setOpenDelivery] = useState(true);
    const [openFaq, setOpenFaq] = useState(true);
    const [openInput, setOpenInput] = useState(true);

    const classificationToggle = () => {
        setOpenClassification(!openClassification);
    }
    const relationToggle = () => {
        setOpenRelation(!openRelation);
    }
    const priceInfoToggle = () => {
        setOpenPriceInfo(!openPriceInfo);
    }
    const discountToggle = () => {
        setOpenDiscount(!openDiscount);
    }
    const productOptionToggle = () => {
        setOpenProductOption(!openProductOption);
    }
    const inventoryToggle = () => {
        setOpenInventory(!openInventory);
    }
    const productImageToggle = () => {
        setOpenProductImage(!openProductImage);
    }
    const productDetailToggle = () => {
        setOpenProductDetail(!openProductDetail)
    }
    const deliveryToggle = () => {
        setOpenDelivery(!openDelivery);
    }
    const faqToggle = () => {
        setOpenFaq(!openFaq);
    }
    const inputToggle = () => {
        setOpenInput(!openInput);
    }

    const handleRelationChange = (e) => {
        const relationSetting = e.target.id;
        setSelectedRelation(relationSetting);
    }
    const handlePriceChange = (e) => {
        const priceSetting = e.target.id;
        setSelectedPrice(priceSetting);
    }
    const handleOptionChange = (e) => {
        const optionSetting = e.target.id;
        setSelectedOption(optionSetting);
    }
    const handleInventoryChange = (e) => {
        const inventorySetting = e.target.id;
        setSelectedInventory(inventorySetting);
    }
    const onPrev = () => {
        navigate(-1);
    }
    const onApproval = () => {
        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html : 
            `
                <div>
                    <div class='${admin.admin_seller_add_title}'><span>선택한 상품을 승인하시겠습니까?</span></div>
                    <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                      <button class='mx-1' id='seller_add_cancel'>취소</button>
                      <button class='mx-1' id='seller_add_success'>승인</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });
        document.addEventListener('click', sellerSingleApprovalSuccess);
        document.addEventListener('click', sellerSingleApprovalCancel);

        function sellerSingleApprovalSuccess(e) {
            if(e.target && e.target.id == 'seller_add_success') {
                MySwal.close();
                document.removeEventListener('click', sellerSingleApprovalSuccess);
            }
        }
        function sellerSingleApprovalCancel(e) {
            if(e.target && e.target.id == 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerSingleApprovalCancel);
            }
        }
    }
    const onApprovalDenied = () => {
        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html : 
            `
                <div>
                    <div class='${admin.admin_seller_add_title}'><span>해당 상품의 승인을 거절 하시겠습니까?</span></div>
                    <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                      <button class='mx-1' id='seller_approval_denied_cancel'>취소</button>
                      <button class='mx-1' id='seller_approval_denied_success'>거절</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });

        document.addEventListener('click', sellerApprovalDeniedSuccess);
        document.addEventListener('click', sellerApprovalDeniedCancel);

        function sellerApprovalDeniedSuccess(e) {
            if(e.target && e.target.id == 'seller_approval_denied_success') {
                MySwal.fire({
                    customClass: {
                        popup: `${admin.admin_seller_approval_popup}`
                    },
                    html : 
                    `
                    <div>
                        <div class='${admin.admin_seller_add_title}'><span>거절 사유</span></div>
                        <div class='${admin.admin_seller_approval_content}'>
                            <div><span>상품명</span></div>
                            <div><span>Leon Bridges-Coming Home (deluxe Edition) - 1 LP vinyl</span></div>
                        </div>
                        <div class='${admin.admin_seller_approval_content}'>
                            <div><span>거절 사유</span></div>
                            <div><textarea name="" id="" cols="30" rows="10" placeholder='내용을 500자 이내로 입력하세요.'></textarea></div>
                        </div>
                        <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                        <button class='mx-1' id='seller_approval_cancel'>취소</button>
                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                        </div>
                    </div>
                    `,
                    showConfirmButton: false,
                    showCancelButton: false,
                    // preConfirm: () => {
                    //     document.addEventListener('click', sellerApprovalCancel);
                    // }
                });

                document.addEventListener('click', sellerAddConfirm);
                document.addEventListener('click', sellerApprovalCancel);
                // document.removeEventListener('click', sellerApprovalDeniedSuccess);
                
                function sellerAddConfirm(e) {
                    if(e.target && e.target.id == 'seller_add_confirm') {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html : 
                            `
                            <div>
                            <div class='${admin.admin_seller_add_title}'><span>거절 사유 등록이 완료 되었습니다.</span></div>
                            <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });

                        document.addEventListener('click', sellerApprovalDeniedConfirm);
                        document.removeEventListener('click', sellerAddConfirm);
                        
                        function sellerApprovalDeniedConfirm(e) {
                            if(e.target && e.target.id == 'seller_add_confirm') {
                                MySwal.close();
                                document.removeEventListener('click', sellerApprovalDeniedConfirm);
                            }
                    }
                }
            }
                function sellerApprovalCancel(e) {
                    if(e.target && e.target.id == 'seller_approval_cancel') {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html : 
                            `
                                <div>
                                    <div class='${admin.admin_seller_approval_title}'><span>등록을 취소 하시겠습니까?</span></div>
                                    <div class='${admin.admin_seller_approval_content}'><span>작성 중인 내용은 저장 되지 않습니다.</span></div>
                                    <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                                      <button class='mx-1' id='seller_approval_denied_cancel2'>닫기</button>
                                      <button class='mx-1' id='seller_approval_denied_success2'>확인</button>
                                    </div>
                                </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                            preConfirm: () => {
                                document.getElementById('seller_approval_denied_cancel2').addEventListener('click', sellerApprovalDeniedSuccess);
                            }
                        });

                        document.addEventListener('click', sellerApprovalDeniedClose2);
                        document.addEventListener('click', sellerApprovalDeniedCancel2);
                        
                        function sellerApprovalDeniedClose2(e) {
                            if(e.target && e.target.id == 'seller_approval_denied_success2') {
                                MySwal.close();
                                document.removeEventListener('click', sellerApprovalDeniedClose2);
                                // document.removeEventListener('click', sellerApprovalCancel);
                            }
                        }
                        
                        function sellerApprovalDeniedCancel2(e) {
                            if(e.target && e.target.id == 'seller_approval_denied_cancel2') {
                                MySwal.close();
                                document.removeEventListener('click', sellerApprovalCancel);
                            }
                        }
                    }
                }
        }
    }
        function sellerApprovalDeniedCancel(e) {
            if(e.target && e.target.id == 'seller_approval_denied_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerApprovalDeniedCancel);
            }
        }
    }

  return (
    <div className={`${admin.admin_seller_product_detail_box} px-3 py-3`}>
        <div>
            <div className={`${admin.admin_seller_product_detail_header}`}><span>셀러 상품 상세</span></div>
            {/* 상품명 */}
            <div className='px-3'>
                <div className='py-3'>
                    <div className={`${admin.admin_seller_product_detail_type}`}><span>상품명</span></div>
                    <div className={`${admin.admin_seller_product_detail_product_name}`}>
                        <div><span>상품명</span></div>
                        <div><input type="text" /></div>
                    </div>
                </div>
                {/* 상품 분류 */}
                <div className='py-3'>
                    <div className={`${admin.admin_seller_product_detail_type}`} onClick={() => {classificationToggle()}}>
                        <div><span>상품 분류</span></div>
                        <div className='d-flex align-items-center'><span className='dd-fs-8'>{openClassification ? '접기' : '펼치기' }</span><span className="material-symbols-outlined">{openClassification ? 'expand_less' : 'expand_more' }</span></div>
                    </div>
                    <CSSTransition in={openClassification} timeout={300} classNames="review" unmountOnExit>
                        <div>
                            <div className={`${admin.admin_seller_product_detail_three_box}`}>
                                <div><span>대분류</span></div>
                                <div><input type="text" /></div>
                                <div></div>
                            </div>
                            <div className={`${admin.admin_seller_product_detail_three_box}`}>
                                <div><span>중분류</span></div>
                                <div><input type="text" /></div>
                                <div></div>
                            </div>
                            <div className={`${admin.admin_seller_product_detail_three_box}`}>
                                <div><span>소분류</span></div>
                                <div><input type="text" /></div>
                                <div><span>*선택 시 상품 가격 아래 뱃지 형태로 표시됩니다.</span></div>
                            </div>
                            <div className={`${admin.admin_seller_product_detail_favority}`}>
                                <div><span>최애(선택)</span></div>
                                <div>
                                    <span>하니</span>
                                    <span>민지</span>
                                </div>
                            </div>
                        </div>
                    </CSSTransition>
                </div>
                {/* 연관 상품 */}
                <div className='py-3'>
                    <div className={`${admin.admin_seller_product_detail_type}`} onClick={() => {relationToggle()}}>
                        <div><span>연관 상품</span></div>
                        <div className='d-flex align-items-center'><span className='dd-fs-8'>{openRelation ? '접기' : '펼치기' }</span><span className="material-symbols-outlined">{openRelation ? 'expand_less' : 'expand_more' }</span></div>
                    </div>
                    <CSSTransition in={openRelation} timeout={300} classNames="review" unmountOnExit>
                        <div>
                            <div className={`${admin.admin_seller_product_detail_radio}`}>
                                <div><span>연관 상품</span></div>
                                <div>
                                    <div>
                                        <input type="checkbox" name="relation" id="relation_false" checked={selectedRelation === 'relation_false'} onChange={handleRelationChange} />
                                        <label className='ps-1' htmlFor="relation_false">사용 안함</label>
                                    </div>
                                    <div className='ps-5'>
                                        <input type="checkbox" name="relation" id="relation_true" checked={selectedRelation === 'relation_true'} onChange={handleRelationChange} />
                                        <label className='ps-1' htmlFor="relation_true">사용함</label>
                                    </div>
                                </div>
                                <div><span>*연관상품을 등록하면 해당 상품의 상세페이지에서 연관된 상품을 표시할 수 있습니다.</span></div>
                            </div>
                            <div>
                                <div><img src="" alt="" /></div>
                                <div><span></span></div>
                            </div>
                        </div>
                    </CSSTransition>
                </div>
                {/* 가격 정보 */}
                <div className='py-3'>
                    <div className={`${admin.admin_seller_product_detail_type}`} onClick={() => {priceInfoToggle()}}>
                        <div><span>가격 정보</span></div>
                        <div className='d-flex align-items-center'><span className='dd-fs-8'>{openPriceInfo ? '접기' : '펼치기' }</span><span className="material-symbols-outlined">{openPriceInfo ? 'expand_less' : 'expand_more' }</span></div>
                    </div>
                    <CSSTransition in={openPriceInfo} timeout={300} classNames="review" unmountOnExit>
                        <div>
                            <div className={`${admin.admin_seller_product_detail_radio}`}>
                                <div><span>금액설정</span></div>
                                <div>
                                    <div>
                                        <input type="checkbox" name="price" id="price_true" checked={selectedPrice === 'price_true'} onChange={handlePriceChange} />
                                        <label className='ps-1' htmlFor="price_true">고정 설정</label>
                                    </div>
                                    <div className='ps-5'>
                                        <input type="checkbox" name="price" id="price_false" checked={selectedPrice === 'price_false'} onChange={handlePriceChange} />
                                        <label className='ps-1' htmlFor="price_false">최소 금액 설정</label>
                                    </div>
                                </div>
                                <div></div>
                            </div>
                            <div className={`${admin.admin_seller_product_detail_three_box}`}>
                                <div><span>최소 금액</span></div>
                                <div><input type="text" name="" id="" /></div>
                                <div></div>
                            </div>
                        </div>
                    </CSSTransition>
                </div>
                {/* 할인 설정 */}
                <div className='py-3'>
                    <div className={`${admin.admin_seller_product_detail_type}`} onClick={() => {discountToggle()}}>
                        <div><span>할인 설정</span></div>
                        <div className='d-flex align-items-center'><span className='dd-fs-8'>{openDiscount ? '접기' : '펼치기' }</span><span className="material-symbols-outlined">{openDiscount ? 'expand_less' : 'expand_more' }</span></div>
                    </div>
                    <CSSTransition in={openDiscount} timeout={300} classNames="review" unmountOnExit>
                        <div className={`${admin.admin_seller_product_detail_radio}`}>
                            <div><span>적용 가능 할인</span></div>
                            <div>
                                <div>
                                    <input type="checkbox" name="" id="" />
                                    <label className='ps-1' htmlFor="">쿠폰</label>
                                </div>
                                <div className='ps-5'>
                                    <input type="checkbox" name="" id="" />
                                    <label className='ps-1' htmlFor="">적립금</label>
                                </div>
                            </div>
                            <div><span>*결제 시 해당 상품에 사용 가능한 할인항목을 설정합니다.</span></div>
                        </div>
                    </CSSTransition>
                </div>
                {/* 상품 옵션 */}
                <div className='py-3'>
                    <div className={`${admin.admin_seller_product_detail_type}`} onClick={() => {productOptionToggle()}}>
                        <div><span>상품 옵션</span></div>
                        <div className='d-flex align-items-center'><span className='dd-fs-8'>{openProductOption ? '접기' : '펼치기' }</span><span className="material-symbols-outlined">{openProductOption ? 'expand_less' : 'expand_more' }</span></div>
                    </div>
                    <CSSTransition in={openProductOption} timeout={300} classNames="review" unmountOnExit>
                        <div className={`${admin.admin_seller_product_detail_radio}`}>
                            <div><span>옵션</span></div>
                            <div>
                                <div>
                                    <input type="checkbox" name="option" id="option_true" checked={selectedOption === 'option_true'} onChange={handleOptionChange} />
                                    <label className='ps-1' htmlFor="option_true">옵션 있음</label>
                                </div>
                                <div className='ps-5'>
                                    <input type="checkbox" name="option" id="option_false" checked={selectedOption === 'option_false'} onChange={handleOptionChange} />
                                    <label className='ps-1' htmlFor="option_false">옵션 없음</label>
                                </div>
                            </div>
                            <div><span>*옵션 있음 선택 시, 옵션별 상품 가격은 상품 기본 설정 - 금액 설정에서 설정한 판매가 그대로 반영됩니다.</span></div>
                        </div>
                    </CSSTransition>
                </div>
                {/* 재고 설정 */}
                <div className='py-3'>
                    <div className={`${admin.admin_seller_product_detail_type}`} onClick={() => {inventoryToggle()}}>
                        <div><span>재고 설정</span></div>
                        <div className='d-flex align-items-center'><span className='dd-fs-8'>{openInventory ? '접기' : '펼치기' }</span><span className="material-symbols-outlined">{openInventory ? 'expand_less' : 'expand_more' }</span></div>
                    </div>
                    <CSSTransition in={openInventory} timeout={300} classNames="review" unmountOnExit>
                        <div>
                            <div className={`${admin.admin_seller_product_detail_radio}`}>
                                <div><span>재고 관리</span></div>
                                <div>
                                    <div>
                                        <input type="checkbox" name="inventory" id="inventory_true" checked={selectedInventory === 'inventory_true'} onChange={handleInventoryChange} />
                                        <label className='ps-1' htmlFor="inventory_true">사용</label>
                                    </div>
                                    <div className='ps-5'>
                                        <input type="checkbox" name="inventory" id="inventory_false" checked={selectedInventory === 'inventory_false'} onChange={handleInventoryChange} />
                                        <label className='ps-1' htmlFor="inventory_false">사용 안함</label>
                                    </div>
                                </div>
                                <div><span>*상품 재고를 관리하거나 재고 소진 이후 자동으로 품절 처리가 필요할 때 사용합니다.</span></div>
                            </div>
                            <div className={`${admin.admin_seller_product_detail_inventory}`}>
                                <div></div>
                                <div>
                                    <div><span>재고수량</span></div>
                                    <div><input type="text" /></div>
                                </div>
                                <div>
                                    <div><span>판매상태</span></div>
                                    <div><input type="text" /></div>
                                </div>
                            </div>
                        </div>
                    </CSSTransition>
                </div>
                {/* 상품 이미지 */}
                <div className='py-3'>
                    <div className={`${admin.admin_seller_product_detail_type}`} onClick={() => {productImageToggle()}}>
                        <div><span>상품 이미지</span></div>
                        <div className='d-flex align-items-center'><span className='dd-fs-8'>{openProductImage ? '접기' : '펼치기' }</span><span className="material-symbols-outlined">{openProductImage ? 'expand_less' : 'expand_more' }</span></div>
                    </div>
                    <CSSTransition in={openProductImage} timeout={300} classNames="review" unmountOnExit>
                        <div>
                            <div className={`${admin.admin_seller_product_detail_image}`}>
                                <div><span>대표이미지</span></div>
                                <div>
                                    <div><img src="" alt="" /></div>
                                </div>
                            </div>
                            <div className={`${admin.admin_seller_product_detail_image}`}>
                                <div><span>리스트 이미지</span></div>
                                <div>
                                    <div><img src="" alt="" /></div>
                                    <div><img src="" alt="" /></div>
                                    <div><img src="" alt="" /></div>
                                    <div><img src="" alt="" /></div>
                                </div>
                            </div>
                        </div>
                    </CSSTransition>
                </div>
                {/* 제품 상세 */}
                <div className='py-3'>
                    <div className={`${admin.admin_seller_product_detail_type}`} onClick={() => {productDetailToggle()}}>
                        <div><span>제품 상세</span></div>
                        <div className='d-flex align-items-center'><span className='dd-fs-8'>{openProductDetail ? '접기' : '펼치기' }</span><span className="material-symbols-outlined">{openProductDetail ? 'expand_less' : 'expand_more' }</span></div>
                    </div>
                    <CSSTransition in={openProductDetail} timeout={300} classNames="review" unmountOnExit>
                        <div>
                            <div className='py-3'><span>상세 내용</span></div>
                            <div className={`${admin.admin_seller_product_detail_product_content}`}></div>
                        </div>
                    </CSSTransition>
                </div>
                {/* 배송 템플릿 */}
                <div className='py-3'>
                    <div className={`${admin.admin_seller_product_detail_type}`} onClick={() => {deliveryToggle()}}>
                        <div><span>배송 템플릿</span></div>
                        <div className='d-flex align-items-center'><span className='dd-fs-8'>{openDelivery ? '접기' : '펼치기' }</span><span className="material-symbols-outlined">{openDelivery ? 'expand_less' : 'expand_more' }</span></div>
                    </div>
                    <CSSTransition in={openDelivery} timeout={300} classNames="review" unmountOnExit>
                        <div>
                            <div className={`${admin.admin_seller_product_detail_three_box}`}>
                                <div><span>배송 템플릿</span></div>
                                <div><input type="text" /></div>
                                <div></div>
                            </div>
                            <div className={`${admin.admin_seller_product_detail_delivery_box}`}>
                                <div className={`${admin.admin_seller_product_detail_delivery_header}`}>
                                    <div className='pe-1'><span>기본</span></div>
                                    <div className='ps-1'><span>배송 템플릿 A</span></div>
                                </div>
                                <div className={`${admin.admin_seller_product_detail_delivery_location}`}>
                                    <div><span className="material-symbols-outlined">location_on</span></div>
                                    <div><span>출고·반품·교환지명 A</span></div>
                                </div>
                                <div>
                                    <div className='border-bottom'><span className={`${admin.admin_signature_color}`}>대한민국</span></div>
                                </div>
                                <div className={`${admin.admin_seller_product_detail_delivery_info}`}>
                                    <div><span>배송방법</span></div>
                                    <div><span>택배(선불)</span></div>
                                </div>
                                <div className={`${admin.admin_seller_product_detail_delivery_info}`}>
                                    <div><span>기본 택배사</span></div>
                                    <div><span>한진택배</span></div>
                                </div>
                                <div className={`${admin.admin_seller_product_detail_delivery_info}`}>
                                    <div><span>기본 배송비</span></div>
                                    <div><span>KRW 2,500</span></div>
                                </div>
                                <div className={`${admin.admin_seller_product_detail_delivery_info}`}>
                                    <div><span>무료 배송 조건</span></div>
                                    <div><span>KRW 50,000 이상 구매시</span></div>
                                </div>
                                <div className={`${admin.admin_seller_product_detail_delivery_info}`}>
                                    <div><span>반품 배송비</span></div>
                                    <div><span>KRW 2,500</span></div>
                                </div>
                                <div className={`${admin.admin_seller_product_detail_delivery_info}`}>
                                    <div><span>교환 배송비</span></div>
                                    <div><span>KRW 5,000</span></div>
                                </div>
                            </div>
                        </div>
                    </CSSTransition>
                </div>
                {/* FAQ 등록(선택) */}
                <div className='py-3'>
                    <div className={`${admin.admin_seller_product_detail_type}`} onClick={() => {faqToggle()}}>
                        <div><span>FAQ 등록 (선택)</span></div>
                        <div className='d-flex align-items-center'><span className='dd-fs-8'>{openFaq ? '접기' : '펼치기' }</span><span className="material-symbols-outlined">{openFaq ? 'expand_less' : 'expand_more' }</span></div>
                    </div>
                    <CSSTransition in={openFaq} timeout={300} classNames="review" unmountOnExit>
                        <div>
                            <div className={`${admin.admin_seller_product_detail_faq}`}>
                                <div><span>자주쓰는 FAQ</span></div>
                                <div><span>*선택 시 상품의 FAQ 페이지에 노출됩니다.</span></div>
                            </div>
                            <div className={`${admin.admin_seller_product_detail_faq_select}`}>
                                <div><span>선택된 FAQ</span></div>
                                <div>
                                    <div>
                                        <div><span>Q. </span><span>자주 묻는 질문 제목을 입력해주세요.</span></div>
                                        <div><span>A. </span><span></span></div>
                                    </div>
                                    <div>
                                        <div><span>Q. </span><span></span></div>
                                        <div><span>A. </span><span></span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </CSSTransition>
                </div>
                {/* 입력폼 추가 */}
                <div className='py-3'>
                    <div className={`${admin.admin_seller_product_detail_type}`} onClick={() => {inputToggle()}}>
                        <div><span>입력폼 추가 (선택)</span></div>
                        <div className='d-flex align-items-center'><span className='dd-fs-8'>{openInput ? '접기' : '펼치기' }</span><span className="material-symbols-outlined">{openInput ? 'expand_less' : 'expand_more' }</span></div>
                    </div>
                    <CSSTransition in={openInput} timeout={300} classNames="review" unmountOnExit>
                        <div>
                            <div className={`${admin.admin_seller_product_detail_input_box}`}>
                                <div><span>사진 파일 입력폼</span></div>
                                <div>
                                    <input type="checkbox" name="" id="" />
                                    <label className='ps-2' htmlFor="">유저에게 사진·파일 등록 기능 제공</label>
                                </div>
                                <div><span>*추가 시 유저에게 사진·파일을 등록하는 기능이 제공됩니다.</span></div>
                            </div>
                            <div className={`${admin.admin_seller_product_detail_input_box}`}>
                                <div><span>날짜 선택 입력폼</span></div>
                                <div>
                                    <input type="checkbox" name="" id="" />
                                    <label className='ps-2' htmlFor="">유저에게 날짜 선택 등록 기능 제공</label>
                                </div>
                                <div><span>*추가 시 날짜를 등록하는 기능이 제공됩니다.</span></div>
                            </div>
                            <div className={`${admin.admin_seller_product_detail_input_content}`}>
                                <div><span>내용 입력 입력폼</span></div>
                                <div className={`${admin.admin_seller_product_detail_input_content_detail}`}>
                                    <div>
                                        <div><span>내용 입력폼 제목</span></div>
                                        <div><input type="text" /></div>
                                    </div>
                                    <div className='ps-4'>
                                        <div className='py-1'>
                                            <input type="checkbox" name="" id="" />
                                            <label className='ps-2' htmlFor="">숏 폼(90자 이하)</label>
                                        </div>
                                        <div className='py-1'>
                                            <input type="checkbox" name="" id="" />
                                            <label className='ps-2' htmlFor="">롱 폼(500자 제한)</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </CSSTransition>
                </div>
            </div>
            <div className={`${admin.admin_seller_product_detail_btn_box}`}>
                <div className='px-1'><button onClick={() => onPrev()}>이전으로</button></div>
                <div className='px-1'><button onClick={() => onApproval()}>승인하기</button></div>
                <div className='px-1'><button onClick={() => onApprovalDenied()}>승인거절</button></div>
            </div>
        </div>
    </div>
  )
}

export default AdminSellerProductDetail