import React from 'react'
import DaumPostcode from 'react-daum-postcode';

const PostModal = ({ onClose, onComplete, admin }) => {

    // 우편번호 검색이 완료되었을 때 호출되는 함수
  const handleComplete = (data) => {
    onComplete(data); // 상위 컴포넌트로 주소 데이터 전달
    onClose(); // 모달 닫기
  };

  return (
    <div className={`${admin.post_background}`}>
        <div className={`${admin.post_container}`}>
            <DaumPostcode onComplete={handleComplete} />
            <button onClick={onClose} className={`${admin.post_close}`}>닫기</button>
        </div>
    </div>
  )
}

export default PostModal