import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import * as messageapi from '../../apis/message';
import { LoginContext } from '../../contexts/LoginContextProvider';
import moment from 'moment';

const MessageComponent = ({ message }) => {
    const { userInfo } = useContext(LoginContext);
    const navigate = useNavigate();
    const [activeButton, setActiveButton] = useState('all');
    const [messageList, setMessageList] = useState([]);

    const messageSelect = useCallback(async () => {
        if (userInfo && userInfo.userId) {
            const response = await messageapi.messageListSelect(userInfo.userId);
            const data = response.data;

            // console.log(data);
            setMessageList(data);
        }
    }, [userInfo]);

    useEffect(() => {
        if (userInfo && userInfo.userId) {
            messageSelect();
            const interval = setInterval(messageSelect, 10000); // 10초마다 messageSelect 호출
            return () => clearInterval(interval); // 컴포넌트 언마운트 시 interval 클리어
        }
    }, [userInfo, messageSelect]);

    const onChat = (no) => {
        navigate(`/message/chat/${no}`);
    };

    const getTimeAgo = (date) => {
        return moment(date).fromNow();
    };

    const getUnreadCount = (messages) => {
        return messages.filter(message => !message.readingState).length;
    };

    const getLastMessage = (messages) => {
        if (messages.length > 0) {
            return messages[messages.length - 1];
        }
        return { messageSend: '', messageRegDate: new Date() };
    };

    return (
        <div>
            <div>
                <div className={`${message.message_tab_btn}`}>
                    <div><button className={`${activeButton === 'all' ? message.message_tab_btn_active : ''}`} onClick={() => setActiveButton('all')}>전체</button></div>
                    <div><button className={`${activeButton === 'trading' ? message.message_tab_btn_active : ''}`} onClick={() => setActiveButton('trading')}>거래중</button></div>
                    <div><button className={`${activeButton === 'confirm' ? message.message_tab_btn_active : ''}`} onClick={() => setActiveButton('confirm')}>거래완료</button></div>
                </div>
                <div>
                    {
                        Array.isArray(messageList) && messageList.length !== 0 ? messageList.map((item, index) => {
                            const lastMessage = getLastMessage(item.messageList);
                            return (
                                <div className={`${message.message_list_content}`} onClick={() => onChat(item.roomId)} key={index}>
                                    <div className={`${message.message_img_box}`}>
                                        <div><img src={item.shopDTO && item.shopDTO.shopProfileImg ? item.shopDTO.shopProfileImg : `${process.env.PUBLIC_URL}/img/shop/default_profile.png`} alt="상점프로필" /></div>
                                    </div>
                                    <div className={`${message.message_shop}`}>
                                        <div>
                                            <div><span>{item.tradingState}</span></div>
                                            <div><span>{item.shopDTO.shopName}</span></div>
                                        </div>
                                        <div><span>{lastMessage.messageSend}</span></div>
                                    </div>
                                    <div className={`${message.message_info}`}>
                                        <div><span>{getTimeAgo(lastMessage.messageRegDate)}</span></div>
                                        {
                                            getUnreadCount(item.messageList) === 0 ?
                                            null :
                                            <div><span>{getUnreadCount(item.messageList)}</span></div>
                                        }
                                    </div>
                                </div>
                            )
                        })
                        :
                        <div className={`${message.message_list_no_content}`}><span>주고 받은 메세지가 없어요😓</span></div>
                    }
                </div>
            </div>
        </div>
    );
};

export default MessageComponent;
