import React, { useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

// import required modules
import { FreeMode, Pagination } from 'swiper/modules';

const StoreCategory = ( {category, categoryList, store} ) => {

  const categoryRefs = {
    앨범: useRef(null),
    팬시: useRef(null),
    인형: useRef(null),
    커미션: useRef(null),
    생카템: useRef(null),
    팬싸템: useRef(null),
    콘서트템: useRef(null),
    손민수템: useRef(null),
  };

  // useEffect(() => {
  //   if (categoryRefs[category]) {
  //     categoryRefs[category].current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
  //   }
  // }, [category]);
  

  return (
    <div className={`border-bottom ${store.store_category}`}>
      <Swiper
                      slidesPerView={'auto'}
                      spaceBetween={10}
                      freeMode={true}
                      className="my_category_store"
      >
        {
          categoryList.map((item, index) => (
            <SwiperSlide>
              <div className={`${category === item.dailyduckStorecategoryEngName ? 'check' : 'noncheck'}`}>
                <Link to={`/store/${item.dailyduckStorecategoryEngName}`} ref={categoryRefs[item.dailyduckStorecategoryName]}  >
                  {item.dailyduckStorecategoryName}
                </Link>
              </div>
            </SwiperSlide>
          ))
        }
        {/* <SwiperSlide>
          <Link to={`/store/${'앨범'}`} ref={categoryRefs['앨범']} className={`store ${category === '앨범' ? 'check' : 'noncheck'}`}>
            앨범
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link to={`/store/${'팬시'}`} ref={categoryRefs['팬시']} className={`store ${category === '팬시' ? 'check' : 'noncheck'}`}>
            팬시
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link to={`/store/${'인형'}`} ref={categoryRefs['인형']} className={`store ${category === '인형' ? 'check' : 'noncheck'}`}>
            인형
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link to={`/store/${'커미션'}`} ref={categoryRefs['커미션']} className={`store ${category === '커미션' ? 'check' : 'noncheck'}`}>
            커미션
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link to={`/store/${'생카템'}`} ref={categoryRefs['생카템']} className={`store ${category === '생카템' ? 'check' : 'noncheck'}`}>
            생카템
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link to={`/store/${'팬싸템'}`} ref={categoryRefs['팬싸템']} className={`store ${category === '팬싸템' ? 'check' : 'noncheck'}`}>
            팬싸템
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link to={`/store/${'콘서트템'}`} ref={categoryRefs['콘서트템']} className={`store ${category === '콘서트템' ? 'check' : 'noncheck'}`}>
            콘서트템
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link to={`/store/${'손민수템'}`} ref={categoryRefs['손민수템']} className={`store ${category === '손민수템' ? 'check' : 'noncheck'}`}>
            손민수템
          </Link>
        </SwiperSlide> */}
      </Swiper>
    </div>
  )
}

export default StoreCategory