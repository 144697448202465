import React, { useContext, useEffect, useState } from 'react';
import admin from '../../../admin.module.css';
import * as adminApi from '../../../../apis/admin';
import { Pagination } from 'antd';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { LoginContext } from '../../../../contexts/LoginContextProvider';
import { format } from 'date-fns';

const AdminAuthSettle = () => {
    const { userInfo } = useContext(LoginContext);
    const MySwal = withReactContent(Swal);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(7);
    const [totalItems, setTotalItems] = useState(0);

    const [settleList, setSettleList] = useState([]);
    const [filteredSettleList, setFilteredSettleList] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);

    const [totalPaymentAmount, setTotalPaymentAmount] = useState(0);
    const [totalFees, setTotalFees] = useState(0);
    const [totalSettlementAmount, setTotalSettlementAmount] = useState(0);

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [searchText, setSearchText] = useState('');

    const [activeButton, setActiveButton] = useState('withdrawalRequest');

    const [withdrawalRequestCount, setWithdrawalRequestCount] = useState(0);
    const [withdrawalReadyCount, setWithdrawalReadyCount] = useState(0);
    const [withdrawalConfirmCount, setWithdrawalConfirmCount] = useState(0);

    useEffect(() => {
        // console.log('settleList : ', settleList);
    }, [settleList])

    useEffect(() => {
        settleAllSelect();
    }, []);

    useEffect(() => {
        filterData();
    }, [startDate, endDate, searchText, settleList, activeButton]);

    const settleAllSelect = async () => {
        const response = await adminApi.settleAllSelect();
        const data = response.data;

        setSettleList(data);
        calculateTotals(data); // 초기 데이터로 총합 계산

        // 상태별 개수 계산
        const requestCount = data && data.filter(item => item.withdrawalState === '출금대기').length;
        const readyCount = data && data.filter(item => item.withdrawalState === '출금준비').length;
        const confirmCount = data && data.filter(item => item.withdrawalState === '출금완료').length;

        setWithdrawalRequestCount(requestCount);
        setWithdrawalReadyCount(readyCount);
        setWithdrawalConfirmCount(confirmCount);
    };

    const calculateTotals = (data) => {
        if (data.length > 0) {
            const totalFees = data
                .filter(item => item.withdrawalState === '출금완료')
                .reduce((acc, item) => acc + item.totalSettlementAmount, 0);

            const totalSettlementAmount = data
                .filter(item => item.withdrawalState === '출금대기')
                .reduce((acc, item) => acc + item.totalSettlementAmount, 0);

            setTotalFees(totalFees);
            setTotalSettlementAmount(totalSettlementAmount);
        }
    };

    const filterData = () => {
        let filteredData = settleList;

        // 날짜 필터링
        if (startDate && endDate) {
            filteredData = filteredData.filter(item => {
                const settleDate = new Date(item.periodRegDate).setHours(0, 0, 0, 0); // 시간 제거
                const start = new Date(startDate).setHours(0, 0, 0, 0);
                const end = new Date(endDate).setHours(23, 59, 59, 999); // 종료일의 끝까지 포함

                return settleDate >= start && settleDate <= end;
            });
        }

        // 텍스트 필터링
        if (searchText) {
            filteredData = filteredData.filter(item =>
                item.sellerId.toLowerCase().includes(searchText.toLowerCase()) ||
                item.shopName.toLowerCase().includes(searchText.toLowerCase())
            );
        }

        // 상태 필터링
        filteredData = filteredData && filteredData.filter(item => {
            if (activeButton === 'withdrawalRequest') return item.withdrawalState === '출금대기';
            if (activeButton === 'withdrawalReady') return item.withdrawalState === '출금준비';
            if (activeButton === 'withdrawalConfirm') return item.withdrawalState === '출금완료';
            return true;
        });

        // 필터된 데이터로 총합 계산 및 상태 필터링 적용
        setFilteredSettleList(filteredData);
        setTotalItems(filteredData.length);
    };

    const handleDateChange = (e, isStart) => {
        if (isStart) {
            setStartDate(e.target.value);
        } else {
            setEndDate(e.target.value);
        }
    };

    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
    };

    const formatDate = (date) => {
        return format(new Date(date), 'yyyy-MM-dd HH:mm:ss');
    };

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            setSelectedItems(filteredSettleList); // 전체 객체를 저장
        } else {
            setSelectedItems([]);
        }
    };

    const handleSelectItem = (e, item) => {
        if (e.target.checked) {
            setSelectedItems([...selectedItems, item]); // 객체를 저장
        } else {
            setSelectedItems(selectedItems.filter(i => i.periodSettlementNo !== item.periodSettlementNo));
        }
    };

    const handleExcelDownload = async () => {
        try {
            // console.log('selectedItems : ', selectedItems);
    
            const response = await adminApi.settleExcelDown(selectedItems);
    
            // 응답 데이터를 Blob 객체로 변환
            const blob = new Blob([response.data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
    
            // Blob URL 생성
            const url = window.URL.createObjectURL(blob);
    
            // 다운로드 링크 생성 및 클릭
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'settlement.xlsx'); // 파일 이름 설정
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link); // 링크 제거
    
            // Blob URL 해제
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('엑셀 다운로드 실패:', error);
        }
    };
    const handleExcelHistoryDownload = async () => {
        try {
            // console.log('selectedItems : ', selectedItems);
    
            const response = await adminApi.settleHistoryExcelDown(selectedItems);
    
            // 응답 데이터를 Blob 객체로 변환
            const blob = new Blob([response.data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
    
            // Blob URL 생성
            const url = window.URL.createObjectURL(blob);
    
            // 다운로드 링크 생성 및 클릭
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'settlement.xlsx'); // 파일 이름 설정
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link); // 링크 제거
    
            // Blob URL 해제
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('엑셀 다운로드 실패:', error);
        }
    };
    

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredSettleList.slice(indexOfFirstItem, indexOfLastItem);

    const onWithdrawalReady = () => {
        if (selectedItems.length === 0 || selectedItems === null) {
            alert('선택된 정산 내역이 없습니다.');
            return;
        } else {
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html: `
                      <div>
                          <div class='${admin.admin_seller_add_title}'><span>출금준비 상태로 변경 하시겠습니까?</span></div>
                          <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_cancel'>취소</button>
                            <button class='mx-1' id='seller_add_success'>변경</button>
                          </div>
                      </div>
                  `,
                showConfirmButton: false,
                showCancelButton: false,
            });
            document.addEventListener('click', sellerAddSuccess);
            document.addEventListener('click', sellerAddCancel);

            async function sellerAddSuccess(e) {
                if (e.target && e.target.id === 'seller_add_success') {
                    try {
                        const response = await adminApi.withdrawalReady(selectedItems);
                        const data = response.data;

                        if (data === 'SUCCESS') {
                            MySwal.fire({
                                customClass: {
                                    popup: `${admin.admin_seller_add_popup}`
                                },
                                html: `
                                  <div>
                                    <div class='${admin.admin_seller_add_title}'><span>출금준비 상태로 변경되었습니다.</span></div>
                                    <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                                    </div>
                                  </div>
                                  `,
                                showConfirmButton: false,
                                showCancelButton: false,
                            });
                            document.addEventListener('click', sellerAddConfirm);

                            function sellerAddConfirm(e) {
                                if (e.target && e.target.id === 'seller_add_confirm') {
                                    settleAllSelect();
                                    MySwal.close();
                                    document.removeEventListener('click', sellerAddConfirm);
                                    document.removeEventListener('click', sellerAddSuccess);
                                    document.removeEventListener('click', sellerAddCancel);
                                }
                            }
                        } else {
                            MySwal.fire({
                                customClass: {
                                    popup: `${admin.admin_seller_add_popup}`
                                },
                                html: `
                                  <div>
                                    <div class='${admin.admin_seller_add_title}'><span>출금준비 상태 변경에 실패하였습니다.</span></div>
                                    <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                                    </div>
                                  </div>
                                  `,
                                showConfirmButton: false,
                                showCancelButton: false,
                            });
                            document.addEventListener('click', sellerAddConfirm);

                            function sellerAddConfirm(e) {
                                if (e.target && e.target.id === 'seller_add_confirm') {
                                    MySwal.close();
                                    document.removeEventListener('click', sellerAddConfirm);
                                    document.removeEventListener('click', sellerAddSuccess);
                                    document.removeEventListener('click', sellerAddCancel);
                                }
                            }
                        }
                    } catch (error) {
                        console.error('error : ', error);
                    }
                }
            }

            function sellerAddCancel(e) {
                if (e.target && e.target.id === 'seller_add_cancel') {
                    MySwal.close();
                    document.removeEventListener('click', sellerAddSuccess);
                    document.removeEventListener('click', sellerAddCancel);
                }
            }
        }
    }
    const onWithdrawalConfirm = () => {
        if (selectedItems.length === 0 || selectedItems === null) {
            alert('선택된 정산 내역이 없습니다.');
            return;
        } else {
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html: `
                      <div>
                          <div class='${admin.admin_seller_add_title}'><span>출금완료 상태로 변경하시겠습니까?</span></div>
                          <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_cancel'>취소</button>
                            <button class='mx-1' id='seller_add_success'>신청</button>
                          </div>
                      </div>
                  `,
                showConfirmButton: false,
                showCancelButton: false,
            });
            document.addEventListener('click', sellerAddSuccess);
            document.addEventListener('click', sellerAddCancel);

            async function sellerAddSuccess(e) {
                if (e.target && e.target.id === 'seller_add_success') {
                    try {
                        const response = await adminApi.withdrawalConfirm(selectedItems);
                        const data = response.data;

                        if (data === 'SUCCESS') {
                            MySwal.fire({
                                customClass: {
                                    popup: `${admin.admin_seller_add_popup}`
                                },
                                html: `
                                  <div>
                                    <div class='${admin.admin_seller_add_title}'><span>출금완료 상태로 변경되었습니다.</span></div>
                                    <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                                    </div>
                                  </div>
                                  `,
                                showConfirmButton: false,
                                showCancelButton: false,
                            });
                            document.addEventListener('click', sellerAddConfirm);

                            function sellerAddConfirm(e) {
                                if (e.target && e.target.id === 'seller_add_confirm') {
                                    settleAllSelect();
                                    MySwal.close();
                                    document.removeEventListener('click', sellerAddConfirm);
                                    document.removeEventListener('click', sellerAddSuccess);
                                    document.removeEventListener('click', sellerAddCancel);
                                }
                            }
                        } else {
                            MySwal.fire({
                                customClass: {
                                    popup: `${admin.admin_seller_add_popup}`
                                },
                                html: `
                                  <div>
                                    <div class='${admin.admin_seller_add_title}'><span>출금완료 상태 변경에 실패하였습니다.</span></div>
                                    <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                                    </div>
                                  </div>
                                  `,
                                showConfirmButton: false,
                                showCancelButton: false,
                            });
                            document.addEventListener('click', sellerAddConfirm);

                            function sellerAddConfirm(e) {
                                if (e.target && e.target.id === 'seller_add_confirm') {
                                    MySwal.close();
                                    document.removeEventListener('click', sellerAddConfirm);
                                    document.removeEventListener('click', sellerAddSuccess);
                                    document.removeEventListener('click', sellerAddCancel);
                                }
                            }
                        }
                    } catch (error) {
                        console.error('error : ', error);
                    }
                }
            }

            function sellerAddCancel(e) {
                if (e.target && e.target.id === 'seller_add_cancel') {
                    MySwal.close();
                    document.removeEventListener('click', sellerAddSuccess);
                    document.removeEventListener('click', sellerAddCancel);
                }
            }
        }
    }

    return (
        <div className={`${admin.admin_seller_box} px-3 py-3`}>
            <div>
                <div className={`${admin.admin_calculate_header}`}>
                    <div><span>정산관리</span></div>
                </div>
                {
                    settleList.length > 0 ?
                        <div>
                            <div>
                                <div className={`${admin.admin_calculate_proceed_total_box}`}>
                                    <div>
                                        <div><span>셀러 판매 순위</span></div>
                                        <div>
                                            <div><span>0위</span></div>
                                            <div><span>카테고리 명</span></div>
                                        </div>
                                        <div>
                                            <div><span>적용된 정산 기간 :</span></div>&nbsp;
                                            {
                                                startDate === '' && endDate === '' ?
                                                    <div>
                                                        <span>전체기간</span>
                                                    </div>
                                                    :
                                                    <div>
                                                        <span>{startDate ? new Date(startDate).toLocaleDateString('ko-KR') : '시작일'}</span>
                                                        <span> ~ </span>
                                                        <span>{endDate ? new Date(endDate).toLocaleDateString('ko-KR') : '종료일'}</span>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                    <div>
                                        <div><span>누적 출금 내역</span></div>
                                        <div>
                                            <div><span>{totalFees.toLocaleString()}</span></div>
                                            <div><span>KRW</span></div>
                                        </div>
                                        <div>
                                            <div><span>적용된 정산 기간 :</span></div>&nbsp;
                                            {
                                                startDate === '' && endDate === '' ?
                                                    <div>
                                                        <span>전체기간</span>
                                                    </div>
                                                    :
                                                    <div>
                                                        <span>{startDate ? new Date(startDate).toLocaleDateString('ko-KR') : '시작일'}</span>
                                                        <span> ~ </span>
                                                        <span>{endDate ? new Date(endDate).toLocaleDateString('ko-KR') : '종료일'}</span>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                    <div>
                                        <div><span>미출금 금액</span></div>
                                        <div>
                                            <div><span>{totalSettlementAmount.toLocaleString()}</span></div>
                                            <div><span>KRW</span></div>
                                        </div>
                                        <div>
                                            <div><span>적용된 정산 기간 :</span></div>&nbsp;
                                            {
                                                startDate === '' && endDate === '' ?
                                                    <div>
                                                        <span>전체기간</span>
                                                    </div>
                                                    :
                                                    <div>
                                                        <span>{startDate ? new Date(startDate).toLocaleDateString('ko-KR') : '시작일'}</span>
                                                        <span> ~ </span>
                                                        <span>{endDate ? new Date(endDate).toLocaleDateString('ko-KR') : '종료일'}</span>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={`${admin.admin_calculate_proceed_detail_box}`}>
                                <div>
                                    <div className={`${admin.admin_calculate_proceed_detail_title}`}><span>출금 내역</span></div>
                                    <div className={`${admin.admin_calculate_proceed_detail_search_btn_box}`}>
                                        <div>
                                            <div className={`${admin.admin_calculate_proceed_detail_payment_date}`}>
                                                <div><span>정산기간</span></div>
                                                <div>
                                                    <div><input type="date" value={startDate} onChange={(e) => handleDateChange(e, true)} /></div>
                                                    <div>&nbsp;<span>~</span>&nbsp;</div>
                                                    <div><input type="date" value={endDate} onChange={(e) => handleDateChange(e, false)} /></div>
                                                </div>
                                            </div>
                                            <div className={`${admin.admin_calculate_proceed_detail_search}`}>
                                                <div><span>검색</span></div>
                                                <div>
                                                    <div><input type="text" value={searchText} onChange={handleSearchChange} /></div>
                                                    <div><span className="material-symbols-outlined">search</span></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`${admin.admin_calculate_proceed_detail_btn}`}>
                                            <div><button>필터</button></div>
                                            {
                                            selectedItems.length > 0 && activeButton === 'withdrawalConfirm' && activeButton !== 'withdrawalRequest' && activeButton !== 'withdrawalReady' &&
                                                <div><button onClick={handleExcelHistoryDownload}>내역 엑셀 다운로드</button></div>
                                            }
                                            {
                                                selectedItems.length > 0 && activeButton !== 'withdrawalConfirm' &&
                                                <div><button onClick={handleExcelDownload}>정산 엑셀 다운로드</button></div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className={`${admin.admin_calculate_settle_detail_state_tab}`}>
                                    <div className={activeButton === 'withdrawalRequest' ? admin.admin_calculate_settle_detail_tab_btn_active : ''}>
                                        <button onClick={() => setActiveButton('withdrawalRequest')}>출금요청({withdrawalRequestCount})</button>
                                    </div>
                                    <div className={activeButton === 'withdrawalReady' ? admin.admin_calculate_settle_detail_tab_btn_active : ''}>
                                        <button onClick={() => setActiveButton('withdrawalReady')}>출금준비({withdrawalReadyCount})</button>
                                    </div>
                                    <div className={activeButton === 'withdrawalConfirm' ? admin.admin_calculate_settle_detail_tab_btn_active : ''}>
                                        <button onClick={() => setActiveButton('withdrawalConfirm')}>출금완료({withdrawalConfirmCount})</button>
                                    </div>
                                </div>
                                <div className={`${admin.admin_calculate_settle_detail_tab_btn}`}>
                                    <button onClick={() => onWithdrawalReady()}>출금준비</button>
                                    <button onClick={() => onWithdrawalConfirm()}>출금완료</button>
                                    <button>기타상태</button>
                                </div>
                                <div className={`${admin.admin_calculate_settle_detail_container}`}>
                                    <div>
                                        <div className={`${admin.admin_calculate_settle_detail_admin_header}`}>
                                            <div><input type="checkbox" checked={selectedItems.length === filteredSettleList.length} onChange={handleSelectAll} /></div>
                                            <div><span>셀러명</span></div>
                                            <div><span>정산 기간</span></div>
                                            <div><span>정산 요청 금액</span></div>
                                            <div><span>수수료</span></div>
                                            <div><span>신청상태</span></div>
                                            <div><span>출금신청일</span></div>
                                            <div><span>출금완료일</span></div>
                                            <div><span>상세내역</span></div>
                                        </div>
                                        {
                                            Array.isArray(currentItems) && currentItems.map((item, index) => (
                                                <div key={index} className={`${admin.admin_calculate_settle_detail_admin_content}`}>
                                                    <div><input type="checkbox" checked={selectedItems.some(i => i.periodSettlementNo === item.periodSettlementNo)} onChange={(e) => handleSelectItem(e, item)} /></div>
                                                    <div><span>{item.shopName}</span></div>
                                                    <div><span>{item.settlementStartDate}</span><span>&nbsp;~&nbsp;</span><span>{item.settlementEndDate}</span></div>
                                                    <div><span>{item.totalSettlementAmount.toLocaleString()}</span></div>
                                                    <div><span>{item.totalFee.toLocaleString()}</span></div>
                                                    <div>
                                                        <button className={`${admin.admin_calculate_settle_withdrawal_state_btn}`} disabled={true}>{item.withdrawalState}</button>
                                                    </div>
                                                    <div>
                                                        {
                                                            item.withdrawalDate !== null ?
                                                                <span>{formatDate(item.withdrawalDate)}</span>
                                                                : null
                                                        }
                                                    </div>
                                                    <div>
                                                        {
                                                            item.withdrawalConfirmDate !== null ?
                                                                <span>{formatDate(item.withdrawalConfirmDate)}</span>
                                                                : null
                                                        }
                                                    </div>
                                                    <div><button>다운로드</button></div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                <Pagination
                                    current={currentPage}
                                    total={totalItems}
                                    pageSize={itemsPerPage}
                                    onChange={(page) => setCurrentPage(page)}
                                />
                            </div>
                        </div>
                        :
                        <div className={`${admin.admin_calculate_proceed_detail_no_data}`}>
                            <div><span>등록된 내역이 없습니다.</span></div>
                        </div>
                }
            </div>
        </div>
    )
}

export default AdminAuthSettle;
