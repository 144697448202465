import React, { useState } from 'react'
import { CSSTransition } from 'react-transition-group';
import SalesCalendar from './board/SalesCalendar';

const SellerDashBoardMain = ( {seller} ) => {
    const [ activeButton, setActiveButton ] = useState('summary');

    // 각 리뷰의 열림/닫힘 상태를 관리하는 state
    const [openStates, setOpenStates] = useState(false);

    // 리뷰 열림/닫힘 상태를 변경하는 함수
    const toggleOpen = () => {
        setOpenStates(!openStates);
    };

    const salesData = [
        { date: '2024-04-01', sales: 100000, count: 10 },
        // 추가 데이터...
    ];
  return (
    <div className={`${seller.seller_dashboard_container} py-3 px-3`}>
        <div>
            <div className='pb-3 w-100 border-bottom fs-5'><span>대시보드</span></div>
        </div>
        <div>
            <div className={`${seller.seller_dashboard_tab_btn} pt-4 pb-3`}>
                <div className={`w-100 ${activeButton == 'summary' ? seller.seller_dashboard_tab_active : ''}`}><button onClick={() => setActiveButton('summary')}>요약 현황</button></div>
                <div className={`w-100 ${activeButton == 'salesbydate' ? seller.seller_dashboard_tab_active : ''}`}><button onClick={() => setActiveButton('salesbydate')}>일자별 매출 현황</button></div>
            </div>
        </div>
        {
            activeButton == 'summary' &&
            <div>
                <div className={`pt-4 pb-2 w-100 ${seller.seller_border_bottom_color} d-flex justify-content-between position-relative`}>
                    <div><span>요약</span></div>
                    <div>
                        <button className='d-flex align-center bg-body border-0' onClick={toggleOpen}>
                            <div className='dd-fs-7'>1주</div>
                            <div className='ps-2'><span className="material-symbols-outlined">{openStates ? 'expand_less' : 'expand_more'}</span></div>
                        </button>
                    </div>
                    <CSSTransition in={openStates} timeout={500} classNames="review" unmountOnExit>
                        <div className={`${seller.seller_date_select}`}>
                            <div className='w-100 border-bottom'><button className='w-100 py-2 px-4 border-0 bg-body'>오늘</button></div>
                            <div className='w-100 border-bottom'><button className='w-100 py-2 px-4 border-0 bg-body'>1주</button></div>
                            <div className='w-100 border-bottom'><button className='w-100 py-2 px-4 border-0 bg-body'>1개월</button></div>
                            <div className='w-100 border-bottom'><button className='w-100 py-2 px-4 border-0 bg-body'>3개월</button></div>
                        </div>
                    </CSSTransition>
                </div>
                <div className='py-4'>
                    <div className={`${seller.seller_dashboard_info_box}`}>
                        <div className={`${seller.seller_dashboard_visit_box} px-3`}>
                            <div className='py-3'><span>방문</span></div>
                            <div className='py-3'><span>0</span></div>
                            <div className='py-3'><span>+0%</span><span className='ps-3'>최근 1주</span></div>
                        </div>
                        <div className={`${seller.seller_dashboard_order_box} px-3`}>
                            <div className='py-3'><span>주문</span></div>
                            <div className='py-3'><span>0</span></div>
                            <div className='py-3'><span>+0%</span><span className='ps-3'>최근 1주</span></div>
                        </div>
                        <div className={`${seller.seller_dashboard_revenue_box} px-3`}>
                            <div className='py-3'><span>수익</span></div>
                            <div className='py-3'><span>0</span></div>
                            <div className='py-3'><span>+0%</span><span className='ps-3'>최근 1주</span></div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={`${seller.seller_dashboard_current_box}`}>
                        <div className={`${seller.seller_dashboard_current_order} px-3 py-3`}>
                            <div className='pt-2 pb-2 border-bottom'><span>주문 현황</span></div>
                            <div className='d-flex justify-content-between py-2'>
                                <div><span>신규 주문</span></div>
                                <div><span>0</span></div>
                            </div>
                            <div className='d-flex justify-content-between py-2'>
                                <div><span>출고 완료</span></div>
                                <div><span>0</span></div>
                            </div>
                            <div className='d-flex justify-content-between py-2'>
                                <div><span>배송중</span></div>
                                <div><span>0</span></div>
                            </div>
                        </div>
                        <div className={`${seller.seller_dashboard_current_cancel} px-3 py-3`}>
                            <div className='pt-2 pb-2 border-bottom'><span>취소·반품·교환</span></div>
                            <div className='d-flex justify-content-between py-2'>
                                <div><span>반품 요청</span></div>
                                <div><span>0</span></div>
                            </div>
                            <div className='d-flex justify-content-between py-2'>
                                <div><span>교환 요청</span></div>
                                <div><span>0</span></div>
                            </div>
                        </div>
                        <div className={`${seller.seller_dashboard_current_review} px-3 py-3`}>
                            <div className='pt-2 pb-2 border-bottom'><span>리뷰 현황</span></div>
                            <div className='d-flex justify-content-between py-2'>
                                <div><span>신규 리뷰</span></div>
                                <div><span>0</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        {
            activeButton == 'salesbydate' &&
            <div>
                <div className={`pt-4 pb-2 w-100 ${seller.seller_border_bottom_color}`}>
                    <div><span>일자별 매출</span></div>
                </div>
                <div className={`${seller.seller_calander} pt-3`}>
                    <SalesCalendar salesData={salesData} />
                </div>
            </div>
        }
    </div>
  )
}

export default SellerDashBoardMain