import React, { useState, useEffect } from 'react'
import {checkNickName} from '../../apis/auth'
import JoinFavority from './JoinFavority';
import * as adminApi from '../../apis/admin';

const EmailJoin3 = ( {showJoinInfo, animated, joinTransitions2, handleJoinInfoBack, handleJoinInfoClose, 
                      setShowAgree, setShowJoin, setShowJoinInfo, useTransition, userInput, setUserInput, userList,
                      showJoinFavority, setShowJoinFavority, handleJoinFavority
                    } ) => {
    
    const [nickName, setNickName] = useState('');
    const [message, setMessage] = useState('');
    const [color, setColor] = useState('black');
    const [borderColor, setBorderColor] = useState('');
    const [isNicknameValid, setIsNicknameValid] = useState(false); // 닉네임 유효성 상태
    
    const [year, setYear] = useState('');
    const [month, setMonth] = useState('');
    const [day, setDay] = useState('');
    const [gender, setGender] = useState('');
    const [formValid,  setFormValid] = useState('');
    const [keywordList, setKeywordList] = useState([]);

    useEffect(() => {
        excludedSelect();
    }, []);



    const excludedSelect = async () => {
        const response = await adminApi.excludedKeywordRead();
        const data = response.data;

        // console.log('excludedKeyword : ', data);
        setKeywordList(data);
    }


    const nickNameCheck = (e) => {
        let nickName = e.target.value;
    
        // 닉네임이 8자를 초과할 경우 8자로 잘라냄
        if (nickName.length > 8) {
            nickName = nickName.slice(0, 8);  // 8자로 강제 제한
            setMessage('닉네임은 최대 8자까지 입력 가능합니다.');
            setColor('rgba(255, 92, 92, 1)');
            setBorderColor('rgba(255, 92, 92, 1)');
            setIsNicknameValid(false);
        } else {
            setMessage('');
            setColor('black');
            setBorderColor('');
        }
    
        // 제한된 키워드 검사
        const isExcluded = keywordList.some(keyword => nickName.includes(keyword.excludedKeyword));
        if (isExcluded) {
            setMessage('사용이 불가능한 키워드입니다.');
            setColor('rgba(255, 92, 92, 1)');
            setBorderColor('rgba(255, 92, 92, 1)');
            setIsNicknameValid(false);
        } else if (nickName.trim().length < 2) {
            // 2자 미만일 경우 경고 메시지 표시
            setMessage('닉네임은 최소 2자 이상 입력해야 합니다.');
            setColor('rgba(255, 92, 92, 1)');
            setBorderColor('rgba(255, 92, 92, 1)');
            setIsNicknameValid(false);
        } else {
            setIsNicknameValid(true);
        }
    
        // 닉네임 상태 업데이트
        setNickName(nickName);
        setUserInput({
            ...userInput,
            nick: nickName,
        });
    };
    

    const yearCheck = (e) => {
        const value = e.target.value;
        const numericValue = value.replace(/\D/g, '');
        const limitedValue = numericValue.slice(0, 4);
        setYear(limitedValue);

        setUserInput({
            ...userInput,
            year: limitedValue,
        });
    };
    
    const monthCheck = (e) => {
        const value = e.target.value;
        const numericValue = value.replace(/\D/g, '');
        const limitedValue = numericValue.slice(0, 2);
        setMonth(limitedValue);

        setUserInput({
            ...userInput,
            month: limitedValue,
        });
    };
    
    const dayCheck = (e) => {
        const value = e.target.value;
        const numericValue = value.replace(/\D/g, '');
        const limitedValue = numericValue.slice(0, 2);
        setDay(limitedValue);

        setUserInput({
            ...userInput,
            day: limitedValue,
        });
    };
    const genderCheck = (e) => {
        setGender(e.target.value);

        setUserInput({
            ...userInput,
            gender: e.target.value,
        });
    }
    
    useEffect(() => {
        // 닉네임이 2자 이상 8자 이하일 때만 서버 검증 실행
        if (nickName.trim().length >= 2 && nickName.trim().length <= 8) {
            // 제한된 키워드 검사
            const isExcluded = keywordList.some(keyword => nickName.includes(keyword.excludedKeyword));
            if (isExcluded) {
                setMessage('사용이 불가능한 키워드입니다.');
                setColor('rgba(255, 92, 92, 1)');
                setBorderColor('rgba(255, 92, 92, 1)');
                setIsNicknameValid(false);
                return; // 서버 검증을 중단하고 함수 종료
            }
    
            // 서버 검증을 위한 타이머 설정
            const timer = setTimeout(() => {
                checkNickName(nickName)
                    .then(res => {
                        if (res.data.userEmail) {
                            setMessage('동일한 닉네임이 존재합니다.');
                            setColor('rgba(255, 92, 92, 1)');
                            setBorderColor('rgba(255, 92, 92, 1)');
                            setIsNicknameValid(false);
                        } else {
                            setMessage('사용 가능한 닉네임입니다.');
                            setColor('green');
                            setBorderColor('green');
                            setIsNicknameValid(true);
                        }
                    })
                    .catch(err => {
                        console.error(err);
                    });
            }, 1000);
    
            return () => clearTimeout(timer);
        } else {
            // 닉네임이 2자 미만이거나 8자를 초과할 때
            if (nickName.trim().length > 0) {
                setMessage('닉네임은 최소 2자 이상 최대 8자 이하로 입력해주세요.');
                setColor('rgba(255, 92, 92, 1)');
                setBorderColor('rgba(255, 92, 92, 1)');
            } else {
                setMessage('');
                setColor('black');
                setBorderColor('');
            }
            setIsNicknameValid(false);
        }
    }, [nickName, keywordList]);
    
    

    // 모든 입력 필드가 유효한지 확인하는 함수
    const isFormValid = () => {
        // 닉네임 유효성 확인
        const isNicknameValidCheck = isNicknameValid && nickName.trim().length >= 2 && nickName.trim().length <= 8;

        // 생년월일 유효성 확인
        const isBirthValid =
            year.trim().length === 4 &&
            month.trim().length === 2 &&
            day.trim().length === 2 &&
            year.trim() !== '' &&
            month.trim() !== '' &&
            day.trim() !== '';

        // 성별 유효성 확인
        const isGenderValid = gender.trim() !== '';

        // 모든 조건이 참이어야 true 반환
        return isNicknameValidCheck && isBirthValid && isGenderValid;
    };

    useEffect(() => {
        // 닉네임이나 생년월일, 성별 값이 변경될 때마다 isFormValid 상태를 업데이트
        // 이를 통해 form의 유효성 상태를 실시간으로 반영할 수 있음.
        setFormValid(isFormValid());
    }, [nickName, year, month, day, gender, isNicknameValid]);

  return (
    <>
        {showJoinInfo &&
            <div className='join_info_container'>
                {joinTransitions2((style, item) =>
                    item ? 
                    <animated.div className="join_info_detail_box" style={style}>
                        <div className='join_info_detail_header'>
                            <div><button onClick={() => handleJoinInfoBack()}><span className="material-symbols-outlined">arrow_back_ios</span></button></div>
                            <div><span>회원정보</span></div>
                            <div><button onClick={() => handleJoinInfoClose()}><span className="material-symbols-outlined">close</span></button></div>
                        </div>
                        <div className='py-3 px-3'>
                            <div className='nickname_box'>
                                <div className='pb-1'><span>닉네임</span></div>
                                <div>
                                    <div>
                                        <input type="text" className='nickname_input input_style' placeholder='닉네임' value={nickName ? nickName : ''} onChange={nickNameCheck} style={{ borderColor: borderColor }}/>
                                        <span style={{color : color}}>{message}</span>
                                    </div>
                                    <div><span>최소 2자 이상 최대 8자 이하로 입력해주세요.</span></div>
                                </div>
                            </div>
                            <div className='birth_box py-4'>
                                <div className='pb-1'><span>생년월일</span></div>
                                <div className='d-flex justify-content-between'>
                                    <div className='birth_input_div'><input type="number" className='year birth_input' value={year} placeholder='YYYY' onChange={yearCheck} /></div>
                                    <div className='birth_input_div'><input type="number" className='month birth_input' value={month} placeholder='MM' onChange={monthCheck} /></div>
                                    <div className='birth_input_div'><input type="number" className='day birth_input' value={day} placeholder='DD' onChange={dayCheck} /></div>
                                </div>
                            </div>
                            <div className='gender_box'>
                                <div>성별</div>
                                <div className='d-flex'>
                                    <div className='pe-4 gender_female_box'>
                                        <input type="radio" name='gender' value='female' id='female' onChange={genderCheck} />
                                        <label htmlFor="female">여성</label>
                                    </div>
                                    <div className='pe-4 gender_male_box'>
                                        <input type="radio" name='gender' value='male' id='male' onChange={genderCheck} />
                                        <label htmlFor="male">남성</label>
                                    </div>
                                    <div className='gender_nonselect_box'>
                                        <input type="radio" name='gender' value='nogender' id='nogender' onChange={genderCheck} />
                                        <label htmlFor="nogender">선택안함</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='info_btnbox'>
                            <button className={isFormValid() ? 'info_btn_active' : ''} disabled={!isFormValid()} onClick={() => handleJoinFavority()}>다음</button>
                        </div>
                    </animated.div>
                    :null
                )}
            </div>
        }
    </>
  )
}

export default EmailJoin3