import React, {useState, useEffect, useContext, useRef} from 'react';
import Country from './Country';
import { checkEmail, checkUserId, cernumber, phoneAuth } from '../../apis/auth';
import * as auth from '../../apis/auth';
import { Input, Icon } from 'semantic-ui-react';
import EmailJoin3 from './EmailJoin3';
import { LoginContext } from '../../contexts/LoginContextProvider';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSpring, useTransition } from '@react-spring/core';
import { animated } from 'react-spring';
import KakaoJoinNick from './KakaoJoinNick';

import * as adminApi from '../../apis/admin';
import {checkNickName} from '../../apis/auth';
import dailyduck from '../../containers/my/MyContainner.module.css';
import admin from '../../admin/admin.module.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Cookies from 'js-cookie';

const KakaoJoin = ({}) => {
    
    const { isLogin, login, logout, remove, loginCheck } = useContext(LoginContext);
    const context = useContext(LoginContext);
    const countries = context.countries;
    const navigate = useNavigate();
    const location = useLocation();
    const { kakao } = location.state || {};
    const MySwal = withReactContent(Swal);

    // 상태 관리
    const [showCountry, setShowCountry] = useState(false);
    const [showJoinInfo, setShowJoinInfo] = useState(false);
    const [filteredCountries, setFilteredCountries] = useState(countries);
    const [searchTerm, setSearchTerm] = useState('');
    const [userName, setUserName] = useState('');
    const [userId, setUserId] = useState('');
    const [email, setEmail] = useState('');
    const [isEmailCheck, setIsEmailCheck] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');
    const [phoneMessage, setPhoneMessage] = useState('');
    const [color, setColor] = useState('black');
    const [phoneColor, setPhoneColor] = useState('black');
    const [borderColor, setBorderColor] = useState("");
    const [phoneBorderColor, setPhoneBorderColor] = useState("");
    const [phoneAuthState, setPhoneAuthState] = useState(false);
    const [certificationInputVisible, setCertificationInputVisible] = useState(false);
    const [userCertificationNumber, setUserCertificationNumber] = useState('');
    const [regionNumber, setRegionNumber] = useState('');
    const [showEmailAuth, setShowEmailAuth] = useState(false);
    const [emailCertification, setEmailCertification] = useState(false);
    const [authNumber, setAuthNumber] = useState('');
    const [isCheck, setIsCheck] = useState(false);
    const [certificationSuccess, setCertificationSuccess] = useState(false); // 인증 성공 상태 추가
    
    const [nickName, setNickName] = useState('');
    const [isNicknameValid, setIsNicknameValid] = useState(false); // 닉네임 유효성 상태
    const [keywordList, setKeywordList] = useState([]);

    const [nickMessage, setNickMessage] = useState('');
    const [nickColor, setNickColor] = useState('black');
    const [nickBorderColor, setNickBorderColor] = useState("");
    const [ isLoading, setIsLoading ] = useState(false);

    const phoneTimerRef = useRef(null);
    const [phoneTimer, setPhoneTimer] = useState(300);

    const [kakaoExist, setKakaoExist] = useState(false);

    const [isPasswordValid, setIsPasswordValid] = useState({
        hasEnglish: false,
        hasNumber: false,
        hasSpecialChar: false,
        isValidLength: false,
    });
    const [userInput, setUserInput] = useState({
        userProfile: '',
        username: '',
        id: '',
        email: '',
        password: '',
        countries: '',
        phone: '',
        nick: '',
        year: '',
        month: '',
        day: '',
        gender: '',
        kakao: ''
    });

    // 입력 값 리스트로 관리
    const [userList, setUserList] = useState({});
    useEffect(() => {
        setUserList({
            userProfile: userInput.userProfile,
            userName: userInput.username,
            userId: userInput.id,
            userEmail: userInput.email,
            userPw: userInput.password,
            userRepublic: userInput.countries,
            userTel: userInput.phone,
            userNick: userInput.nick,
            userYear: userInput.year,
            userMonth: userInput.month,
            userDay: userInput.day,
            userGender: userInput.gender,
            kakao: userInput.kakao
        });
    }, [userInput]);

    useEffect(() => {
        excludedSelect();
    }, [kakao]);

    useEffect(() => {
        // console.log('userList : ', userList);
    }, [userList])
    
    // 타이머 초기화 함수
    const startPhoneTimer = () => {
        // 기존 타이머를 클리어합니다.
        if (phoneTimerRef.current) {
        clearInterval(phoneTimerRef.current);
        }

        setPhoneTimer(300); // 타이머를 초기값으로 설정합니다.
        phoneTimerRef.current = setInterval(() => {
        setPhoneTimer(prevPhoneTimer => {
            if (prevPhoneTimer <= 1) {
            clearInterval(phoneTimerRef.current); // 타이머 정지
            setIsCheck(true); // 타이머가 끝나면 버튼을 활성화
            return 0;
            }
            return prevPhoneTimer - 1;
        });
        }, 1000);
    };

    useEffect(() => {
        // 컴포넌트 언마운트 시 타이머 정리
        return () => {
          if (phoneTimerRef.current) {
            clearInterval(phoneTimerRef.current);
          }
        };
      }, []);

    const excludedSelect = async () => {
        const response = await adminApi.excludedKeywordRead();
        const data = response.data;

        // console.log('excludedKeyword : ', data);
        setKeywordList(data);
    }

    const nickNameCheck = (e) => {
        let nickName = e.target.value;
    
        // 닉네임이 8자를 초과할 경우 8자로 잘라냄
        if (nickName.length > 8) {
            nickName = nickName.slice(0, 8);  // 8자로 강제 제한
            setNickMessage('닉네임은 최대 8자까지 입력 가능합니다.');
            setNickColor('rgba(255, 92, 92, 1)');
            setNickBorderColor('rgba(255, 92, 92, 1)');
            setIsNicknameValid(false);
        } else {
            setNickMessage('');
            setNickColor('black');
            setNickBorderColor('');
        }
    
        // 제한된 키워드 검사
        const isExcluded = keywordList.some(keyword => nickName.includes(keyword.excludedKeyword));
        if (isExcluded) {
            setNickMessage('사용이 불가능한 키워드입니다.');
            setNickColor('rgba(255, 92, 92, 1)');
            setNickBorderColor('rgba(255, 92, 92, 1)');
            setIsNicknameValid(false);
        } else if (nickName.trim().length < 2) {
            // 2자 미만일 경우 경고 메시지 표시
            setNickMessage('닉네임은 최소 2자 이상 입력해야 합니다.');
            setNickColor('rgba(255, 92, 92, 1)');
            setNickBorderColor('rgba(255, 92, 92, 1)');
            setIsNicknameValid(false);
        } else {
            setIsNicknameValid(true);
        }
    
        // 닉네임 상태 업데이트
        setNickName(nickName);

        setUserInput({
            ...userInput,
            nick: nickName,
        });
    };

    const handleJoinBack = () => {
        navigate(-1);
    }

    const handleJoinClose = () => {
        navigate('/');
    }


    useEffect(() => {
        // console.log(kakao);

        // phoneNumber 분리
        const phoneNumber = kakao.phoneNumber; // +82 10-1111-1111 형식

        let region = '';
        let phone = '';
        if(phoneNumber !== null) {
            const [regionCode, localNumber] = phoneNumber.split(' ');

            // 지역 코드와 현지 번호를 처리
            region = regionCode; // +82
            phone = `0${localNumber.replace(/-/g, '')}`; // 10-1111-1111 -> 01011111111

            console.log(region);
            console.log(phone);
            
            setRegionNumber(region);
            setPhoneNumber(phone);
        }
        setUserName(kakao?.nuckname);
        setEmail(kakao?.email);

        setUserInput({
            ...userInput,
            email: kakao.email,
            username: kakao?.nickname,
            phone: phone,
            kakao: kakao.id,
            userProfile: kakao.profileImage,
            id: kakao.id
        });

    }, [kakao]);

    useEffect(() => {
        if (regionNumber) {
            const defaultCountry = countries.find(country => country.number === regionNumber);
            if (defaultCountry) {
                setSelectedCountry(defaultCountry);
                setUserInput(prevState => ({
                    ...prevState,
                    countries: defaultCountry.name,
                }));
            }
        }
    }, [regionNumber, countries]);

    const handleCountry = () => {
        setShowCountry(true);
    }

    const handleCountryClose = () => {
        setShowCountry(false);
    }

    function handleSelectedCountry(country) {
        if (!country) {
            // console.log('Country not defined');
            return;
        }

        setSelectedCountry({
            name: country.name,
            number: country.number,
            img: country.img
        });

        setUserInput({
            ...userInput,
            countries: country.name,
        });

        // console.log(country.img);
    }


    const emailCheck = (e) => {
        setEmail(e.target.value);

        setUserInput({
            ...userInput,
            email: e.target.value,
        });
    }
    const handleUserName = (e) => {
        setUserName(e.target.value);

        setUserInput({
            ...userInput,
            username: e.target.value,
        })
    }
    const handlePhoneNumber = (e) => {
        setPhoneNumber(e.target.value);

        setUserInput({
            ...userInput,
            phone: e.target.value,
        });
    }

    useEffect(() => {
        // 이메일 형식을 검증하는 정규 표현식
        const emailRegex =
            /^[^\s@]+@[^\s@]+\.(com|net|co\.kr|xyz|cn|com\.cn|net\.cn|org\.cn|ph|in|co\.in|jp|co\.jp|tw|com\.tw|sq|com\.sq|hk|com\.hk|co\.id|vn|com\.vn|my|com\.my|ir|cx|af|am|com\.bd|io|co\.il|kg|la|mn|pk|ps|tj|tm|ae|us|co|bz|mx|com\.mx|com\.ar|ag|bo|com\.bo|cr|ac|com\.gt|ht|hn|com\.ni|vc|gs|gd|pa|com\.ve|ca|de|co\.uk|fr|es|com\.es|nl|it|ru|eu|be|me|dk|gr|se|ch|pl|ro|at|az|by|gl|gg|is|im|je|kz|lv|li|lt|mt|md|pt|sm|cz|com\.tr|so|mu|bi|cm|td|com\.et|cg|cd|co\.ls|mw|ma|na|com\.ng|rw|st|sc|co\.bw|tv|cc|ac|co\.ck|as|ws|com\.au|co\.nz|com\.ai|bs|com\.bs|do|com\.do|com\.fj|com\.jm|ki|ly|fm|ms|nr|nu|nf|pn|pr|sh|lccom\.sb|tt|vg|hm|tf)$/;

        if (emailRegex.test(email)) { // 정규 표현식을 사용하여 이메일 형식을 검증
            const timer = setTimeout(() => {
                checkEmail(email)  // emailCheck 함수 호출
                    .then(res => {
                        if (res.data.userEmail) {
                            setMessage('중복된 이메일입니다.');
                            setColor('rgba(255, 92, 92, 1)');
                            setBorderColor("rgba(255, 92, 92, 1)");
                        } else {
                            setMessage('사용 가능한 이메일입니다.');
                            setColor('green');
                            setBorderColor("green")
                            setIsEmailCheck(true);
                        }
                    })
                    .catch(err => {
                        console.error(err);
                    });
            }, 1000);

            return () => clearTimeout(timer);
        } else {
            // 이메일 형식이 아닌 경우 메시지 업데이트
            if (email !== '') {
                setMessage('유효하지 않은 이메일 형식입니다.');
                setColor('rgba(255, 92, 92, 1)');
                setBorderColor("rgba(255, 92, 92, 1)");
                isFormFilled(false);
            } else {
                setMessage('');
            }
        }
    }, [email]);


    const isFormFilled = () => {
        // 이메일 형식을 검사하는 정규 표현식
        const emailRegex =
            /^[^\s@]+@[^\s@]+\.(com|net|co\.kr|xyz|cn|com\.cn|net\.cn|org\.cn|ph|in|co\.in|jp|co\.jp|tw|com\.tw|sq|com\.sq|hk|com\.hk|co\.id|vn|com\.vn|my|com\.my|ir|cx|af|am|com\.bd|io|co\.il|kg|la|mn|pk|ps|tj|tm|ae|us|co|bz|mx|com\.mx|com\.ar|ag|bo|com\.bo|cr|ac|com\.gt|ht|hn|com\.ni|vc|gs|gd|pa|com\.ve|ca|de|co\.uk|fr|es|com\.es|nl|it|ru|eu|be|me|dk|gr|se|ch|pl|ro|at|az|by|gl|gg|is|im|je|kz|lv|li|lt|mt|md|pt|sm|cz|com\.tr|so|mu|bi|cm|td|com\.et|cg|cd|co\.ls|mw|ma|na|com\.ng|rw|st|sc|co\.bw|tv|cc|ac|co\.ck|as|ws|com\.au|co\.nz|com\.ai|bs|com\.bs|do|com\.do|com\.fj|com\.jm|ki|ly|fm|ms|nr|nu|nf|pn|pr|sh|lccom\.sb|tt|vg|hm|tf)$/;

        const idRegex = /^[A-Za-z0-9]+$/;

        // 모든 필드가 채워져 있고, 이메일 형식도 유효한지 확인
        return userInput.username && userInput.nick && phoneNumber && phoneAuthState && isNicknameValid && isEmailCheck
            ;
    }


    const certificationNumber = async () => {

        
        if(!selectedCountry) {
            alert('국가를 선택해 주세요.');
            return;
        }
        
        if(phoneNumber === '' || phoneNumber === null) {
            alert('휴대폰 번호를 입력해주세요.');
            return;
        }
        
        setIsCheck(true); // 인증번호 받기 버튼 비활성화

        try {
            const response = await phoneAuth(phoneNumber);
            const data = await response.data;
            const statusCode = response.status;

            if (statusCode === 200) {
                MySwal.fire({
                    customClass: {
                      popup: `${dailyduck.my_info_address_popup}`
                    },
                    html: `
                    <div>
                      <div class='${dailyduck.my_info_address_title}'><span>인증문자가 발송되었습니다.</span></div>
                      <div class='${dailyduck.my_info_address_sub_title}'><span>인증문자가 도착하지 않을 경우 핸드폰번호를 확인해주세요.</span></div>
                      <div class='${dailyduck.my_info_address_confirm_btn_box}'>
                        <button id='address_confirm'>확인</button>
                      </div>
                    </div>
                    `,
                    showConfirmButton: false,
                    showCancelButton: false,
                  });
          
                  document.addEventListener('click', addressConfirm);
          
                  function addressConfirm(e) {
                    if (e.target && e.target.id === 'address_confirm') {
                      MySwal.close();
                      document.removeEventListener('click', addressConfirm);
                      setCertificationInputVisible(true); // 인증번호 인풋 창 활성화
          
                      startPhoneTimer(); // 타이머 시작
                    }
                  }

            } else {
                console.error(response);
            }

        } catch (error) {
            console.error('API 호출 중 오류 발생:', error); // 오류 처리
        }

    };

    useEffect(() => {
        // 닉네임이 2자 이상 8자 이하일 때만 서버 검증 실행
        if (nickName.trim().length >= 2 && nickName.trim().length <= 8) {
            // 제한된 키워드 검사
            const isExcluded = keywordList.some(keyword => nickName.includes(keyword.excludedKeyword));
            if (isExcluded) {
                setNickMessage('사용이 불가능한 키워드입니다.');
                setNickColor('rgba(255, 92, 92, 1)');
                setNickBorderColor('rgba(255, 92, 92, 1)');
                setIsNicknameValid(false);
                return; // 서버 검증을 중단하고 함수 종료
            }
    
            // 서버 검증을 위한 타이머 설정
            const timer = setTimeout(() => {
                checkNickName(nickName)
                    .then(res => {
                        if (res.data.userEmail) {
                            setNickMessage('동일한 닉네임이 존재합니다.');
                            setNickColor('rgba(255, 92, 92, 1)');
                            setNickBorderColor('rgba(255, 92, 92, 1)');
                            setIsNicknameValid(false);
                        } else {
                            setNickMessage('사용 가능한 닉네임입니다.');
                            setNickColor('green');
                            setNickBorderColor('green');
                            setIsNicknameValid(true);
                        }
                    })
                    .catch(err => {
                        console.error(err);
                    });
            }, 1000);
    
            return () => clearTimeout(timer);
        } else {
            // 닉네임이 2자 미만이거나 8자를 초과할 때
            if (nickName.trim().length > 0) {
                setNickMessage('닉네임은 최소 2자 이상 최대 8자 이하로 입력해주세요.');
                setNickColor('rgba(255, 92, 92, 1)');
                setNickBorderColor('rgba(255, 92, 92, 1)');
            } else {
                setNickMessage('');
                setNickColor('black');
                setNickBorderColor('');
            }
            setIsNicknameValid(false);
        }
    }, [nickName, keywordList]);

    useEffect(() => {
        if (userCertificationNumber === '') return;
        
        const timer = setTimeout(() => {
            checkCertificationNumber(userCertificationNumber);
        }, 3000);

        return () => clearTimeout(timer);
    }, [userCertificationNumber]);

    const checkCertificationNumber = async (certificationNumber) => {
        const response = await auth.phoneAuthCheck(userInput.phone, certificationNumber);
        const data = response.data;
        if (data === 'SUCCESS') {
            setIsCheck(true);
            setCertificationSuccess(true); // 인증번호가 맞으면 인증 성공 상태를 true로 설정
            setCertificationInputVisible(false);
            setPhoneMessage('핸드폰 인증에 성공하였습니다.');
            setPhoneColor('green');
            setPhoneBorderColor("green");
            setPhoneAuthState(true);
        } else {
            setIsCheck(false);
            setCertificationSuccess(false); // 인증번호가 틀리면 인증 성공 상태를 false로 설정
        }
    };
    const onEmailAuth = async () => {

        setIsLoading(true);

        const response = await auth.emailCheck(email);
        const data = response.data;

        // console.log(data);
        if (data === 'SUCCESS') {
            setIsLoading(false);
            MySwal.fire({
                customClass: {
                    popup: `${dailyduck.my_info_address_popup}`
                },
                html : 
                `
                <div>
                <div class='${dailyduck.my_info_address_title}'><span>인증메일이 발송되었습니다.</span></div>
                <div class='${dailyduck.my_info_address_sub_title}'><span>인증메일이 보이지 않으실 경우 스팸메일함도 확인해주세요.</span></div>
                <div class='${dailyduck.my_info_address_confirm_btn_box}'>
                    <button id='address_confirm'>확인</button>
                </div>
                </div>
                `,
                showConfirmButton: false,
                showCancelButton: false,
              });
              document.addEventListener('click', addressConfirm);
              
              function addressConfirm(e) {
                  if(e.target && e.target.id == 'address_confirm') {
                      MySwal.close();
                      document.removeEventListener('click', addressConfirm);
                      setShowEmailAuth(true);
                  }
              }
        } else {
            MySwal.fire({
                customClass: {
                    popup: `${dailyduck.my_info_address_popup}`
                },
                html : 
                `
                <div>
                <div class='${dailyduck.my_info_address_title}'><span>인증메일 발송에 실패하였습니다.</span></div>
                <div class='${dailyduck.my_info_address_sub_title}'><span>계속 실패할 경우 데일리덕 고객센터로 연락주시기 바랍니다.</span></div>
                <div class='${dailyduck.my_info_address_confirm_btn_box}'>
                    <button id='address_confirm'>확인</button>
                </div>
                </div>
                `,
                showConfirmButton: false,
                showCancelButton: false,
              });
              document.addEventListener('click', addressConfirm);
              
              function addressConfirm(e) {
                  if(e.target && e.target.id == 'address_confirm') {
                      MySwal.close();
                      document.removeEventListener('click', addressConfirm);
                  }
              }
        }
    };

    const emailAuthCheck = async () => {
        const response = await auth.emailAuthNumberCheck(email, authNumber);
        const data = response.data;
        if (data === 'SUCCESS') {
            setShowEmailAuth(false);
            setEmailCertification(true);
        }
    };

    const emailAuthNumber = (e) => {
        setAuthNumber(e.target.value);
    };

    const danamicStyle = {
        backgroundColor: showEmailAuth ? 'rgba(223, 224, 226, 1)' : '',
        color : showEmailAuth ? 'gray' : ''
    }

    const handleJoinInfo = async () => {

        const response = await auth.kakaoJoin(userList);
        const data = await response.data;

        if(data === 'SUCCESS') {
            MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html : 
            `
            <div>
            <div class='${admin.admin_seller_add_title}'><span>회원가입이 완료 되었습니다.</span></div>
            <div class='${admin.admin_seller_add_sub_title}'><span></span></div>
            <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
            <button class='mx-1' id='seller_add_confirm'>확인</button>
            </div>
            </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });
        document.addEventListener('click', sellerAddConfirm);
        
        function sellerAddConfirm(e) {
            if(e.target && e.target.id == 'seller_add_confirm') {
                MySwal.close();
                checkUserExists(userList);
                document.removeEventListener('click', sellerAddConfirm);
            }
        }

            
        } else {
            alert(`Server Error ${data}`);
        }
    }

    const checkUserExists = async (userList) => {
        const response = await auth.userSearch(userList.userId);
        const data = response.data;
    
        // console.log('user : ', data);
        // console.log('status : ', response.status);
        // console.log('userList.id : ', userList.userId);
    
        try {
          if (response.status === 200) {
            if (data === 'exist') {
              const userSelectResponse = await auth.kakaoUserSelect(userList.userId);
              const userSlectData = userSelectResponse.data;
    
              // console.log('userSlectData : ', userSlectData);
    
              // 로그인 처리를 위한 토큰 요청
              const tokenResponse = await auth.getToken({ userId: userSlectData.kakao });
              const tokenData = tokenResponse.data;
              // console.log('tokenData : ', tokenData);
              if (tokenResponse.status === 200) {
                const token = tokenResponse.data.token;
                // 쿠키에 토큰 저장
                Cookies.set("accessToken", token);
                localStorage.setItem("accessToken", token);
                // 로그인 체크 함수 호출
                await loginCheck();
                navigate('/market');
              }
            } else {
              setKakaoExist(false);
            }
          }
        } catch (error) {
          console.error('error : ', error);
        }
      };

    return (
        <>
            {
                isLoading &&
                <div className={`${dailyduck.loading_page}`}>
                    <div><img src={`${process.env.PUBLIC_URL}/img/kwangduck_loading.gif`} alt="" /></div>
                </div>
            }
            <div className={`join_container ${showJoinInfo ? 'height_auto' : ''}`}>
                <div className="join_detail_box">
                    <div className='kakao_join_detail_header'>
                            <div><button  onClick={() => handleJoinBack()}><span className="material-symbols-outlined">arrow_back_ios</span></button></div>
                            <div><span>회원가입</span></div>
                            <div><button onClick={() => handleJoinClose()}><span className="material-symbols-outlined">close</span></button></div>
                    </div>
                    <div className='height_initialized'>
                        <div className='join_input_box'>
                            <div className='pb-1'><span>이름</span></div>
                            <div className='join_input'><input type="text" className='join_input_tag' placeholder={kakao.nickname === '' ? '이름' : kakao.nickname} value={userInput.username} onChange={(e) => handleUserName(e)} /></div>
                        </div>
                        <div className='kakao_nick_name_box'>
                            <div className='pb-1'><span>닉네임</span></div>
                            <div>
                                <div>
                                    <input type="text" className='nickname_input input_style' placeholder='닉네임' value={nickName ? nickName : ''} onChange={nickNameCheck} style={{ borderColor: nickBorderColor }}/>
                                    <span style={{color : nickColor}}>{nickMessage}</span>
                                </div>
                                <div><span>최소 2자 이상 최대 8자 이하로 입력해주세요.</span></div>
                            </div>
                        </div>
                        <div className='join_input_box'>
                            <div className='pb-1'><span>이메일</span></div>
                            <div className='twitter_join_email_join_email'>
                                <div className='position-relative'>
                                    <input type="text" className='join_input_tag' placeholder='이메일' value={userInput.email} onChange={emailCheck} style={{ borderColor: borderColor }} />
                                    {message === '사용 가능한 이메일입니다.' && (
                                        <div className='twitter_join_success_email_auth'>
                                            {emailCertification ? (
                                                <button className='twitter_join_auth_complete' disabled={emailCertification}>인증완료</button>
                                            ) : (
                                                <button  onClick={() => onEmailAuth()} disabled={showEmailAuth} style={danamicStyle}>인증메일 발송</button>
                                            )}
                                        </div>
                                    )}
                                </div>
                                <span style={{ color: color }}>{message}</span>
                                {showEmailAuth && (
                                    <div className='twitter_join_email_auth_number pt-2'>
                                        <div><span>인증번호</span></div>
                                        <div>
                                            <div><input type="number" onChange={(e) => emailAuthNumber(e)} /></div>
                                            <div><button onClick={() => emailAuthCheck()}>인증확인</button></div>
                                        </div>
                                        <div className={`${dailyduck.email_auth_info_resend} pt-1`}>
                                            <div><span>인증번호를 받지 못하셨나요?</span></div>
                                            <div><button onClick={onEmailAuth}>인증번호 재발송</button></div>
                                        </div>
                                        <div className={`${dailyduck.email_auth_info_spam_text}`}>
                                            <div><span>*인증메일이 보이지 않으실 경우 스팸메일함도 확인해주세요.</span></div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='join_input_box'>
                            <div className='pb-1'><span>국가</span></div>
                            <div className='join_input'>
                                {!selectedCountry ?
                                    <button type="text" className='d-flex' placeholder='국가' onClick={handleCountry}>
                                        <span className='country_name_input'>국가선택</span>
                                        <span className="material-symbols-outlined">expand_more</span>
                                    </button> :
                                    <button type="text" className='d-flex' placeholder='국가' onClick={handleCountry}>
                                        <img src={selectedCountry?.img} alt={selectedCountry?.name} />
                                        <span className='country_name_input'>{selectedCountry?.name}</span>
                                        <span className="material-symbols-outlined">expand_more</span>
                                    </button>
                                }
                            </div>
                        </div>

                        <div className='join_input_box'>
                            <div className='pb-1'><span>휴대폰 번호</span></div>
                            <div className='join_input phone_number_box'>
                                <input type="number" className='join_input_tag' placeholder='휴대폰 번호' onChange={(e) => handlePhoneNumber(e)} value={userInput?.phone === '' ? '' : userInput?.phone} style={{ borderColor: phoneBorderColor }} />
                                <span style={{ color: 'rgba(255, 3, 102, 1)' }}>{selectedCountry?.number}</span>
                            </div>
                            {
                                certificationInputVisible &&
                                <div>
                                    <div className='pt-1'><input type="number" className='join_input_tag' placeholder='인증번호' onChange={(e) => setUserCertificationNumber(e.target.value)} /></div>
                                    <div>
                                        <span className='dd-fs-7 gray_color'>인증번호를 받지 못하셨나요?</span><span><button className={`${dailyduck.myinfo_authNumber_re} ps-2`} onClick={certificationNumber}>인증번호 재요청</button></span>
                                    </div>
                                </div>
                            }
                            <div className='join_phone_auth'>{!certificationSuccess && <button className={`${!isCheck && 'join_phone_auth_active'}`} onClick={certificationNumber} disabled={isCheck}>인증번호 받기</button>}</div>
                            <div><span style={{ color: phoneColor }}>{phoneMessage}</span></div>

                        </div>
                    </div>
                    <div className={`join_btn_box`}>
                        <button disabled={!isFormFilled()} className={`${isFormFilled() ? 'join_btn_box_active' : ''} mt-3`} onClick={handleJoinInfo}>회원가입</button>
                    </div>
                </div>
                {
                    showCountry &&
                    <Country showCountry={showCountry} setShowCountry={setShowCountry} handleCountryClose={handleCountryClose}
                        searchTerm={searchTerm} setSearchTerm={setSearchTerm} countries={countries}
                        filteredCountries={filteredCountries} setFilteredCountries={setFilteredCountries}
                        setSelectedCountry={setSelectedCountry} handleSelectedCountry={handleSelectedCountry} />
                }
                {/* {
                    showJoinInfo &&
                    <KakaoJoinNick showJoinInfo={showJoinInfo} animated={animated} joinTransitions2={joinTransitions2}
                        handleJoinInfoBack={handleJoinInfoBack} handleJoinInfoClose={handleJoinInfoClose}
                        setShowAgree={setShowAgree} setShowJoin={setShowJoin} setShowJoinInfo={setShowJoinInfo}
                        useTransition={useTransition} userList={userList} setUserInput={setUserInput}
                        userInput={userInput}
                    />
                } */}
            </div>
        </>
    )
}

export default KakaoJoin;
