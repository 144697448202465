import React from 'react'
import EventPaymentHeader from '../../components/event/EventPaymentHeader'
import EventPaymentContainer from '../../components/event/EventPaymentContainer'

const EventPaymentContent = () => {
  return (
    <>
      <div className='payment'>
        <EventPaymentHeader />
        <EventPaymentContainer />
      </div>
    </>
  )
}

export default EventPaymentContent