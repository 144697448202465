import React, { useEffect, useState } from 'react';
import { FaStar } from 'react-icons/fa';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import { FreeMode, Pagination } from 'swiper/modules';
import Lightbox from 'react-image-lightbox-rotate-latest';
import 'react-image-lightbox-rotate-latest/style.css';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns';
import { ko } from 'date-fns/locale';

const ARRAY = [0, 1, 2, 3, 4];

function ReviewComment({
  reviewList,
  reviewCount,
  ratingAverage,
  averageRatingDecimal,
  FaStar,
  clicked,
  ratings,
  highestCount,
  store,
  handleFilterModal,
  reviewFilterActive
}) {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isPhotoReviewOnly, setIsPhotoReviewOnly] = useState(false); // 사진 리뷰 필터링 상태 관리

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (Array.isArray(reviewList) && reviewList.length !== 0) {
      setImageList(
        reviewList.flatMap((item) => [item.photo1].filter(Boolean))
      );
    }
  }, [reviewList]);

  useEffect(() => {
    // console.log('imageList : ', imageList);
  }, [imageList]);

  const [isImageLoaded, setImageLoaded] = useState(false);
  const [isImageClicked, setImageClicked] = useState(false);

  const handleImageLoad = () => {
    // console.log('Image loaded');
    setImageLoaded(true);
    if (isImageClicked) {
      // console.log('Opening Lightbox from handleImageLoad');
      setIsOpen(true);
    }
  };

  const handleImageClick = (i) => {
    // console.log('Image clicked');
    if (!isOpen) {
      setImageClicked(true);
      setPhotoIndex(i);
      if (isImageLoaded) {
        // console.log('Opening Lightbox from handleImageClick');
        setIsOpen(true);
      }
    }
  };

  const togglePhotoReviewFilter = () => {
    setIsPhotoReviewOnly(!isPhotoReviewOnly); // 사진 리뷰 필터링 상태 토글
  };

  const filteredReviewList = isPhotoReviewOnly
  ? reviewList.filter(review => review.photo1 || review.photo2 || review.photo3 || review.photo4)
  : reviewList;

  const [openStates, setOpenStates] = useState(Array.isArray(reviewList) ? reviewList.map(() => false) : []);

  const toggleOpen = (index) => {
    const newOpenStates = [...openStates];
    newOpenStates[index] = !newOpenStates[index];
    setOpenStates(newOpenStates);
  };

  // UTC 시간을 로컬 시간으로 변환하는 함수
  const convertUTCtoLocal = (dateString) => {
    const date = new Date(dateString); // UTC 시간으로 받아온 값을
    return new Date(date.getTime() + date.getTimezoneOffset() * 60000); // 로컬 시간으로 변환
  };

  // 리뷰 날짜를 포맷하는 함수
  const formatDate = (dateString) => {
    const localDate = convertUTCtoLocal(dateString);
    return formatDistanceToNow(localDate, { addSuffix: true, locale: ko });
  };

  // 리뷰 목록을 reviewFilterActive 값에 따라 정렬하는 함수
  const sortedReviewList = () => {
    let sortedList = [...filteredReviewList];

    if (reviewFilterActive === 'popular') {
      // 인기순 정렬 (예: 좋아요가 많은 순으로 정렬)
      sortedList.sort((a, b) => (b.reviewLike || 0) - (a.reviewLike || 0));
    } else if (reviewFilterActive === 'rateHigh') {
      // 별점 높은 순으로 정렬
      sortedList.sort((a, b) => b.rating - a.rating);
    } else if (reviewFilterActive === 'rateRow') {
      // 별점 낮은 순으로 정렬
      sortedList.sort((a, b) => a.rating - b.rating);
    }

    return sortedList;
  };


  const filterActiveFormat = (text) => {
    if(text === 'basic') {
      return '기본순';
    }
    if(text === 'popular') {
      return '인기순';
    }
    if(text === 'rateHigh') {
      return '별점 높은 순';
    }
    if(text === 'rateRow') {
      return '별점 낮은 순';
    }
  }

  return (
    <div className='px-4 review_box'>
      <div className='venus_review_box pt-4'>
        <span>리뷰 </span><span>{reviewList.length !== 0 ? reviewList.length : 0}</span>
      </div>
      <div className='ratio_box d-flex justify-content-between mt-3'>
        <div className='venue_ratio_box text-center'>
          <div className='fs-3'>{averageRatingDecimal !== 'NaN' ? averageRatingDecimal : 0.0}</div>
          <div className='magin-0a'>
            <Wrap2 className='ps-2'>
              <Stars>
                {ARRAY.map((el, idx) => (
                  <FaStar key={idx} size="20" className={clicked[el] && 'yellowStar'} />
                ))}
              </Stars>
            </Wrap2>
          </div>
        </div>
        <div className="vertical_line my-3"></div>
        <div className='venue_ratio_graph'>
          <table>
            {Object.entries(ratings).sort((a, b) => b[0] - a[0]).map(([star, count]) => (
              <tr key={star}>
                <td width={'10%'} style={{fontWeight: count === highestCount ? 'bold' : 'normal', color: count === highestCount ? 'black' : '#aaa'}}>{star}</td>
                <td width={'80%'}>
                  <div className="barBackground mt-2">
                    <div className="bar" style={{width: `${count * 10}%`}}></div>
                  </div>
                </td>
                <td width={'10%'} style={{fontWeight: count === highestCount ? 'bold' : 'normal', color: count === highestCount ? 'black' : '#aaa'}}>{count}</td>
              </tr>
            ))}
          </table>
        </div>
      </div>
      <div className='mt-4'>
        <div>
          <Swiper
            slidesPerView={'auto'}
            spaceBetween={10}
            freeMode={true}
            className={`${store.store_review_my_swiper}`}
          >
            {imageList.map((src, i) => (
              <SwiperSlide key={i} onClick={() => handleImageClick(i)}>
                <div className={`${store.store_review_my_swiper_img}`}>
                  <img src={src} alt="리뷰이미지" onLoad={handleImageLoad} />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          {isOpen && (
            <Lightbox
              mainSrc={imageList[photoIndex]}
              nextSrc={imageList[(photoIndex + 1) % imageList.length]}
              prevSrc={imageList[(photoIndex + imageList.length - 1) % imageList.length]}
              onCloseRequest={() => setIsOpen(false)}
              onMovePrevRequest={() => setPhotoIndex((photoIndex + imageList.length - 1) % imageList.length)}
              onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % imageList.length)}
            />
          )}
        </div>
      </div>
      <div className={`${store.store_review_btn_box}`}>
        <div>
          <input type="checkbox" name="사진리뷰" id='photoReview' className='photo_review' checked={isPhotoReviewOnly} onChange={togglePhotoReviewFilter} />
          <label htmlFor="photoReview" className='ps-1'>사진 리뷰만</label>
        </div>
        <div className='list_select_box'>
          <button className='list_select_btn' onClick={handleFilterModal}>
            <span>{filterActiveFormat(reviewFilterActive)}</span>
            <span className="material-symbols-outlined">{"expand_more"}</span>
          </button>
        </div>
      </div>
      {sortedReviewList().length > 0 ?
      Array.isArray(sortedReviewList()) && sortedReviewList().map((review, index) => {
        const images = [review.photo1, review.photo2, review.photo3, review.photo4].filter(image => image !== null);

        return (
          <div className='pt-3 personal_review border-bottom pb-3' key={review.id}>
            <div className={`${store.store_user_review_box}`}>
              <div className={`${store.store_user_review_profile}`}>
                <div><img src={`${process.env.REACT_APP_API_SERVER}/img?file=${review.userProfile}`} alt="" /></div>
                <div><span>{review.userNick}</span></div>
              </div>
              <div className={`${store.store_user_review_date}`}>
                <span>{formatDate(review.reviewRegDate)}</span>
              </div>
            </div>
            {images.length > 0 && (
              <div className='py-3'>
                <Swiper
                  slidesPerView={"auto"}
                  spaceBetween={10}
                  // pagination={{ clickable: true }}
                  // modules={[Pagination]}
                  className={`${store.store_review_my_swiper}`}
                >
                  {images.map((image, idx) => (
                    <SwiperSlide key={idx}>
                      <div className={`${store.store_review_my_swiper_img}`}>
                        <img src={image} alt="개인리뷰이미지" />
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            )}
            <div className='personal_review_ratio'>
              <Wrap3 className='py-2'>
                <Stars>
                  {ARRAY.map((star, idx) => (
                    <FaStar key={idx} size="20" className={idx < review.rating ? 'yellowStar' : ''} />
                  ))}
                </Stars>
              </Wrap3>
            </div>
            <div className='personal_review_content'>
              <span className='shorten_words'>
                {review.reviewText}
              </span>
              <Link>더보기</Link>
            </div>
            <div className='personal_review_like mt-2'>
              <button className='px-2 py-1 d-flex align-items-center'>
                <img src="../../img/icon/review/like.png" alt="좋아요" />
                <span className='ps-2 pt-1'>0</span>
              </button>
            </div>
            <div className='d-flex justify-content-between mt-3 personal_review_review'>
              <div>
                <span>댓글</span>
                <span className='ps-2'>2</span>
              </div>
              <div className='personal_review_moreless'>
                <button onClick={() => toggleOpen(index)}>
                  <span className="material-symbols-outlined">{openStates[index] ? "expand_less" : "expand_more"}</span>
                </button>
              </div>
            </div>
            <CSSTransition in={openStates[index]} timeout={500} classNames="review" unmountOnExit>
              <div className='pt-2 personal_review_reviewBox'>
                <div className='py-1 border-bottom'>
                  <div className='py-1'><span>fdtgb*****</span></div>
                  <div><span>리뷰 댓글의 댓글입니다.</span></div>
                </div>
                <div className='py-1 border-bottom'>
                  <div className='py-1'><span>fdtgb*****</span></div>
                  <div><span>리뷰 댓글의 댓글입니다.</span></div>
                </div>
              </div>
            </CSSTransition>
            <div className='personal_review_reviewInput d-flex justify-content-between mt-3'>
              <div className='personal_review_reviewInputBox1'><input className="w-100 py-1" type='text' placeholder='댓글 작성' /></div>
              <div className='personal_review_reviewInputBox2 text-center d-flex align-items-center ps-3'>
                <button>
                  <img src="../../img/icon/review/communication.png" alt="업로드" />
                </button>
              </div>
            </div>
          </div>
        )
      })
      :
      <div className={`${store.store_no_review_box}`}><span>등록된 리뷰가 없습니다.</span></div>
    }
    </div>
  );
}

export default ReviewComment;

const Wrap = styled.div`
  display: flex;
  padding-top: 15px;
`;

const Wrap2 = styled.div`
  display: flex;
  justify-content: center;
`;

const Wrap3 = styled.div`
  display: flex;
`;

const Stars = styled.div`
  display: flex;
  padding-top: 5px;

  & svg {
    color: #e4e4e4;
  }

  .yellowStar {
    color: #fcc419;
  }
`;
