import React, { useContext, useEffect, useState } from 'react';
import FavorityDday from './FavorityDday';
import FavorityAdd from './FavorityAdd';
import * as auth from '../../apis/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoginContext } from '../../contexts/LoginContextProvider';
import dailyduck from '../../containers/my/MyContainner.module.css'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MyFavorityAdd = () => {

  const { userInfo } = useContext(LoginContext);
  const navigate = useNavigate();
  const location = useLocation();
  const MySwal = withReactContent(Swal);
  const userFavorityList = location?.state.userFavorityList;
  const [selectedItems, setSelectedItems] = useState([]); // 선택된 객체의 정보를 저장하는 state
  const [searchTerm, setSearchTerm] = useState(''); // 검색어를 저장하는 state
  const [itemsFromDatabase, setItemsFromDatabase] = useState([]);
  const [columnClass, setColumnClass] = useState('col-6'); // 초기값 설정

  useEffect(() => {
    // console.log('userFavorityList : ', userFavorityList);
  }, [location])

  useEffect(() => {
    const updateColumnClass = () => {
      if (window.innerWidth > 500) {
        setColumnClass('col-4'); // 화면이 500px 이상일 때
      } else {
        setColumnClass('col-6'); // 화면이 500px 이하일 때
      }
    };

    updateColumnClass();
    window.addEventListener('resize', updateColumnClass);

    return () => {
      window.removeEventListener('resize', updateColumnClass);
    };
  }, []);

  useEffect(() => {
    artistSelect();
  }, []);


  const favoritySearch = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleJoinFavBack = () => {
    // setShowJoinFavority(false);
    navigate(-1);
  }
  useEffect(() => {
    // console.log(selectedItems);
  }, [selectedItems]);

  const handleFavorityDdayClick = async () => {
    // userFavorityList가 비어있는 경우 예외 처리
    if (!userFavorityList || userFavorityList.length === 0) {
      // 중복 검사를 건너뛰고 바로 다음 페이지로 이동
      navigate('/my/favority/dday', { state: selectedItems });
      return;
    }
  
    // 중복 검사: userFavorityList의 favorityName과 selectedItems의 favorityName 비교
    const duplicateItems = selectedItems.filter((selectedItem) =>
      userFavorityList.some(
        (userFavority) => userFavority.favorityName === selectedItem.favorityName
      )
    );
  
    // console.log('duplicateItems : ', duplicateItems);
  
    if (duplicateItems.length > 0) {
      // 중복된 아이템의 favorityName 추출
      const duplicateNames = duplicateItems.map((item) => item.favorityName).join(', ');
  
      // 중복된 아이템이 있는 경우 alert 실행
      MySwal.fire({
        customClass: {
          popup: `${dailyduck.my_info_address_popup}`,
        },
        html: `
          <div>
            <div class='${dailyduck.my_info_address_title}'><span>선택하신 최애는 이미 등록되어있습니다.</span></div>
            <div class='${dailyduck.my_info_address_sub_title}'><span>중복된 최애는 "${duplicateNames}" 입니다.</span></div>
            <div class='${dailyduck.my_info_address_confirm_btn_box}'>
                <button id='address_confirm'>확인</button>
            </div>
          </div>
        `,
        showConfirmButton: false,
        showCancelButton: false,
      });
  
      document.addEventListener('click', addressConfirm);
  
      function addressConfirm(e) {
        if (e.target && e.target.id == 'address_confirm') {
          MySwal.close();
          document.removeEventListener('click', addressConfirm);
        }
      }
  
      return; // 중복이 있을 경우 함수 종료
    }
  
    // 중복이 없는 경우 다음 페이지로 이동
    navigate('/my/favority/dday', { state: selectedItems });
  };
  

  const artistSelect = async () => {
    const response = await auth.artistSelect();
    const data = response.data;

    // console.log(data);
    setItemsFromDatabase(data);
  }
  const handleFavorityAddClick = () => {
    navigate('/my/favority/request');
  }


  const handleItemClick = (item) => {
    const isSelected = selectedItems.find((selectedItem) => selectedItem.artistNo === item.artistNo);
  
    if (isSelected) {
      setSelectedItems(selectedItems.filter((selectedItem) => selectedItem.artistNo !== item.artistNo));
    } else {
      setSelectedItems([
        ...selectedItems,
        {
          artistNo: item.artistNo,
          userId: userInfo.userId,
          favorityName: item.artistName,
          favorityImgUrl: item.artistImg,
          favorityDday: '', // dday 초기값 설정
        },
      ]);
    }
  };

  useEffect(() => {
    // console.log(selectedItems);
  }, [selectedItems])

  // dday 업데이트 함수
  // const updateDday = (no, newDday) => {
  //   setSelectedItems(selectedItems.map(item => 
  //     item.no === no ? { ...item, dday: newDday } : item
  //   ));
  // };

  // 데이터베이스에서 가져온 값들
  // const itemsFromDatabase = [
  //   { id: 1, name: '해린', imageSrc: '../img/join/favority/1.png' },
  //   { id: 2, name: '제니', imageSrc: '../img/join/favority/2.png' },
  //   { id: 3, name: '사나', imageSrc: '../img/join/favority/3.png' },
  //   { id: 4, name: '마크', imageSrc: '../img/join/favority/4.png' },
  //   { id: 5, name: '카리나', imageSrc: '../img/join/favority/5.png' },
  //   { id: 6, name: '뷔', imageSrc: '../img/join/favority/6.png' },
  //   { id: 7, name: '지수', imageSrc: '../img/join/favority/7.png' },
  //   { id: 8, name: '지민', imageSrc: '../img/join/favority/8.png' },
  //   { id: 9, name: '지코', imageSrc: '../img/join/favority/9.png' },
  //   { id: 10, name: '지드래곤', imageSrc: '../img/join/favority/10.png' },
  //   { id: 11, name: '민지', imageSrc: '../img/join/favority/11.png' },
  //   { id: 12, name: '하니', imageSrc: '../img/join/favority/12.png' },
  //   { id: 13, name: '다니엘', imageSrc: '../img/join/favority/13.png' },
  //   { id: 14, name: '혜인', imageSrc: '../img/join/favority/14.png' },
  // ];

  // 검색어에 따라 필터된 아이템 목록
  const filteredItems = itemsFromDatabase.filter((item) =>
    (item.artistName?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
    (item.artistGroup?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
    (item.artistAgency?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
    (item.engName?.toLowerCase() || '').includes(searchTerm.toLowerCase()) || // 안전하게 처리
    (item.fandom?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
    (item.labels?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
    (item.realName?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
    (item.nickName?.toLowerCase() || '').includes(searchTerm.toLowerCase())
  );


  return (
    <>
        <div className={`${dailyduck.my_favority_add_container}`}>
          <div className={`${dailyduck.my_favority_add_box}`}>
            <div className={`${dailyduck.my_favority_add_header}`}>
              <div>
                <button className='region_close' onClick={() => handleJoinFavBack()}>
                  <span className='material-symbols-outlined'>arrow_back_ios</span>
                </button>
              </div>
              <div>
                <span>최애 선택</span>
              </div>
            </div>
            <div className={`${dailyduck.my_favority_add_search_box}`}>
              <span className="material-symbols-outlined">search</span>
              <input type='text' placeholder='검색' onChange={favoritySearch} />
            </div>
            <div className={`${dailyduck.my_favority_add_item_box} row`}>
            {filteredItems.map((item, index) => (
              <div
              key={index}
              className={`${columnClass}`}
              onClick={() => handleItemClick(item)}
              >
                <div className={`${selectedItems.some(selectedItem => selectedItem.artistNo === item.artistNo) ? dailyduck.list_select : ''}`}>
                  <div>
                    <img src={`${process.env.REACT_APP_API_SERVER}/img?file=${item.artistImg}`} alt={item.artistName} />
                  </div>
                  <div className='pt-3 pb-3'>
                    <span>{item.artistName}</span>
                  </div>
                </div>
              </div>
            ))}
            </div>
            <div className={`${dailyduck.my_favority_add_footer}`}>
              {selectedItems.length > 0 ? 
                <div className={`${dailyduck.my_favority_add_footer_select_btn}`}>
                  <div><span>선택됨 ({selectedItems.length})</span></div>
                  <div><button className='next_btn' onClick={handleFavorityDdayClick} >다음</button></div>
                </div>
                : 
                <div className={`${dailyduck.my_favority_add_footer_favority_request}`}>
                  <div>
                    <div>
                      <div><span>찾으시는 최애가 없으신가요?</span></div>
                      <div><span>최애추가를 신청해주시면 빠르게 등록해드리겠습니다.</span></div>
                      <div><button className='cheae_add_btn' onClick={handleFavorityAddClick}>최애추가 요청</button></div>
                    </div>
                    <div><button disabled={true} style={{backgroundColor: 'rgba(223, 224, 226, 1)', color: 'rgba(169, 173, 178, 1)'}}>다음</button></div>
                  </div>
                </div>
              }
            </div>
          </div>
          {/* {
            showFavorityDday &&
            <FavorityDday showFavorityDday={showFavorityDday} animated={animated} favorityDdayTransition={favorityDdayTransition}
            handleFavDdayBack={handleFavDdayBack} handleFavDdayClose={handleFavDdayClose} selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}  users={users} favorityList={favorityList} setFavorityList={setFavorityList}
            auth={auth} favoritySave={favoritySave}
            />
          } */}
          {/* {
            showFavorityAdd &&
            <FavorityAdd showFavorityAdd={showFavorityAdd} favorityAddTransition={favorityAddTransition} animated={animated} setShowFavorityAdd={setShowFavorityAdd}/>
          } */}
        </div>
    </>
  );
};

export default MyFavorityAdd;
