import React, { useContext, useEffect, useState } from 'react';
import LoadingPage from "../containers/loading/LoadingPage";
import * as admin from '../apis/admin';
import { LoginContext } from '../contexts/LoginContextProvider';
import { Link } from 'react-router-dom';

function Layout({ children }) {
  const { userInfo } = useContext(LoginContext);
  const [page, setPage] = useState({});
  const [showSplash, setShowSplash] = useState(false);
  const [loadingComplete, setLoadingComplete] = useState(false);
  const [isScreenLarge, setIsScreenLarge] = useState(window.innerWidth > 500);
  const [userId, setUserId] = useState('');
  const [pendingToken, setPendingToken] = useState(null); // 토큰 임시 저장

  useEffect(() => {
    if (userInfo && userInfo.userId) {
      setUserId(userInfo.userId);
    }
  }, [userInfo]);

  useEffect(() => {
    const pageSetting = async () => {
      const response = await admin.pageSettingSelect();
      const data = response.data;
      setPage(data);
    };

    pageSetting();

    const splashTimestamp = localStorage.getItem('splashTimestamp');
    const currentTime = new Date().getTime();

    if (!splashTimestamp || currentTime - splashTimestamp > 24 * 60 * 60 * 1000) {
      setShowSplash(true);
      localStorage.setItem('splashTimestamp', currentTime);

      setTimeout(() => {
        setShowSplash(false);
        setLoadingComplete(true);
      }, 2000);
    } else {
      setLoadingComplete(true);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsScreenLarge(window.innerWidth > 500);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Flutter에서 FCM 토큰을 받기 위한 함수 정의
  // useEffect(() => {
  //   console.log('receiveFcmToken function 등록');

  //   window.receiveFcmToken = (token) => {
  //     console.log("Received FCM Token:", token);

  //     if (userId && token) {
  //       fcmTokenCheck(userId, token);
  //     } else {
  //       console.log("유저 정보가 없거나 토큰이 없습니다.");
  //       // 토큰을 임시로 저장
  //       setPendingToken(token);
  //     }
  //   };
  // }, []);

  useEffect(() => {
    const handleFcmTokenReceived = (event) => {
      const token = event.detail.fcmToken;
      console.log("Received FCM Token via event:", token);

      if (userId && token) {
        fcmTokenCheck(userId, token);
      } else {
        console.log("유저 정보가 없거나 토큰이 없습니다.");
        setPendingToken(token);
      }
    };

    document.addEventListener('fcmTokenReceived', handleFcmTokenReceived);

    return () => {
      document.removeEventListener('fcmTokenReceived', handleFcmTokenReceived);
    };
  }, [userId]);

  // userId와 pendingToken이 모두 존재하면 서버로 전송
  useEffect(() => {
    console.log("fcmToken 확인 : ", userId);
    console.log("fcmToken 확인 : ", pendingToken);
    
    if (userId && pendingToken) {
      fcmTokenCheck(userId, pendingToken);
      setPendingToken(null); // 토큰 전송 후 초기화
    }
  }, [userId, pendingToken]);

  const fcmTokenCheck = async (userId, token) => {
    console.log('fcmTokenCheck 실행');
    const response = await admin.userFcmToken(userId, token);
    const data = response.data;
    
    console.log('서버 응답:', data);
  };

  return (
    <div className='page'>
      <div className="position-absolute page_back_img">
        {
          page.backgroundImg !== '' || page.backgroundImg !== null &&
          <img style={{ height: "100vh" }} src={page.backgroundImg} alt="Background" />
        }
      </div>
      <div className='page_side'></div>
      <div className='page_image'>
        {
          page.sideImg !== '' || page.sideImg !== null &&
          <img src={page.sideImg} alt="Side" />
        }
      </div>
      <div className='page_inner'>
        <div style={{ height: "100vh", overflowY: "auto" }}>
          <div className='staging_page'>
            <div>
              <div><span>&lt;데일리덕은 이사 중&gt;</span></div>
              <div><span>안녕하세요, 데일리덕입니다.</span></div>
              <div><span>현재 데일리덕 사이트 리뉴얼 작업이 진행 중이며,</span></div>
              <div><span>이에 따라 사이트 주소가 임시적으로 변경되었습니다.</span></div>
              <br />
              <div><span>이사 기간 동안 잠시 <Link to='https://dailyduck.co.kr/'>dailyduck.co.kr</Link>을 이용 부탁드리며,</span></div>
              <div><span>데일리덕 앱도 동시 점검 중이므로 PC나 모바일 웹을 이용 부탁드립니다.</span></div>
              <br />
              <div><span>점검이 완료되는 대로 공식 SNS를 통해 빠르게 안내드리겠습니다.</span></div>
              <div><span>이용에 불편을 드려 죄송하며,</span></div>
              <div><span>고객님의 많은 양해 부탁드립니다.</span></div>
              <br />
              <div><span>감사합니다.</span></div>
              <div className='staging_page_btn'><Link to='https://dailyduck.co.kr/'>데일리덕 이용하러 가기</Link></div>
            </div>
          </div>
          {children}
        </div>
      </div>
      <div className='page_side'></div>
    </div>
  );
}

export default Layout;
