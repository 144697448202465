import React from 'react'
import { Link, useNavigate} from 'react-router-dom';


const SupportHeader = ( {activeCategory, backNavigate, ss} ) => {
  return (
    <>
      <div>
          <div className={`${ss.support_header}`}>
            <div>
              <button className='support_header_btn' onClick={() => backNavigate()}>
                <span className="material-symbols-outlined">arrow_back_ios</span>
              </button>
            </div>
            <div>
              <span>
                서포트
              </span>
            </div>
          </div>
        </div>
    </>
  )
}

export default SupportHeader