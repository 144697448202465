import React, { useContext, useEffect, useState } from 'react';
import { Modal, Pagination, Button, Checkbox, Input, Select } from 'antd';
import 'react-quill/dist/quill.snow.css';
import admin from '../../../admin.module.css';
import * as adminApi from '../../../../apis/admin';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { LoginContext } from '../../../../contexts/LoginContextProvider';

const AdminSuggest = () => {
    const { userInfo } = useContext(LoginContext);
    const MySwal = withReactContent(Swal);

    const [suggestKeyword, setSuggestKeyword] = useState('');
    const [keywordList, setKeywordList] = useState([]);
    const [keywordString, setKeywordString] = useState('');

    useEffect(() => {
        if (userInfo) {
            suggestKeywordRead();
        }
    }, [userInfo]);

    const suggestKeywordRead = async () => {
        const response = await adminApi.suggestKeywordRead();
        const data = response.data;

        // console.log('suggestKeyword : ', data);
        setKeywordList(data);
        setKeywordString(data.map(item => item.suggestKeyword).join(', '));
    };

    const onSuggestKeyword = (e) => {
        setSuggestKeyword(e.target.value);
    };

    const handleSuggestKeywordAdd = () => {
        // 빈값 또는 공백만 있는지 확인
        if (!suggestKeyword.trim()) {
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html: `
                    <div>
                        <div class='${admin.admin_seller_add_title}'><span>빈값은 등록이 불가능합니다.</span></div>
                        <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-center'>
                            <button class='mx-1' id='alert_confirm'>확인</button>
                        </div>
                    </div>
                `,
                showConfirmButton: false,
                showCancelButton: false,
            });

            document.addEventListener('click', alertConfirm);
            function alertConfirm(e) {
                if (e.target && e.target.id === 'alert_confirm') {
                    MySwal.close();
                    document.removeEventListener('click', alertConfirm);
                }
            }
            return;
        }

        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html: `
                <div>
                    <div class='${admin.admin_seller_add_title}'><span>입력하신 키워드 ${suggestKeyword} 를 등록하시겠습니까?</span></div>
                    <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                      <button class='mx-1' id='seller_add_cancel'>아니오</button>
                      <button class='mx-1' id='seller_add_success'>예</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });

        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);

        async function sellerAddSuccess(e) {
            if (e.target && e.target.id === 'seller_add_success') {
                try {
                    const newItem = {
                        regUser: userInfo && userInfo?.userId,
                        suggestKeyword: suggestKeyword
                    };
                    const response = await adminApi.suggestKeywordAdd(newItem);
                    const data = response.data;

                    // console.log('refuseData : ', data);

                    if (data === 'SUCCESS') {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                                <div class='${admin.admin_seller_add_title}'><span>입력하신 ${suggestKeyword}가(이) 등록되었습니다.</span></div>
                                <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                    <button class='mx-1' id='seller_add_confirm'>확인</button>
                                </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);

                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                suggestKeywordRead();
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    } else {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                                <div class='${admin.admin_seller_add_title}'><span>입력하신 ${suggestKeyword} 키워드 등록에 실패했습니다.</span></div>
                                <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                    <button class='mx-1' id='seller_add_confirm'>확인</button>
                                </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);

                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                suggestKeywordRead();
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    }
                } catch (error) {
                    console.error('error : ', error);
                }
            }
        }

        function sellerAddCancel(e) {
            if (e.target && e.target.id === 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
            }
        }
    };

    const handleSuggestKeywordRemove = () => {
        // 비슷한 로직으로 빈값 검사를 추가할 수 있습니다.
        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html: `
                <div>
                    <div class='${admin.admin_seller_add_title}'><span>입력하신 키워드 ${suggestKeyword} 를 삭제하시겠습니까?</span></div>
                    <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                      <button class='mx-1' id='seller_add_cancel'>아니오</button>
                      <button class='mx-1' id='seller_add_success'>예</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });

        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);

        async function sellerAddSuccess(e) {
            if (e.target && e.target.id === 'seller_add_success') {
                try {
                    const response = await adminApi.suggestKeywordRemove(userInfo && userInfo?.userId, suggestKeyword);
                    const data = response.data;

                    // console.log('refuseData : ', data);

                    if (data === 'SUCCESS') {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                                <div class='${admin.admin_seller_add_title}'><span>입력하신 ${suggestKeyword}가(이) 삭제되었습니다.</span></div>
                                <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                    <button class='mx-1' id='seller_add_confirm'>확인</button>
                                </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);

                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                suggestKeywordRead();
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    } else {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                                <div class='${admin.admin_seller_add_title}'><span>입력하신 ${suggestKeyword} 키워드 삭제에 실패했습니다.</span></div>
                                <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                    <button class='mx-1' id='seller_add_confirm'>확인</button>
                                </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);

                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                suggestKeywordRead();
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    }
                } catch (error) {
                    console.error('error : ', error);
                }
            }
        }

        function sellerAddCancel(e) {
            if (e.target && e.target.id === 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
            }
        }
    };

    return (
        <div className={`${admin.adimn_operate_suggest_container} px-3 py-3`}>
            <div className={`${admin.adimn_operate_suggest_box}`}>
                <div className={`${admin.adimn_operate_suggest_header}`}>
                    <div><span>추천키워드</span></div>
                    <div>
                        <span>*키워드 등록 시 유저 검색창 하단에 추천키워드가 노출됩니다.</span>
                        <br />
                        <span>*최대 노출 키워드는 10개 입니다.</span>
                    </div>
                </div>
                <div className={`${admin.adimn_operate_suggest_content}`}>
                    <div className={`${admin.adimn_operate_suggest_content_input}`}>
                        <div><span>키워드</span></div>
                        <div><input type="text" onChange={onSuggestKeyword} /></div>
                        <div>
                            <div><button onClick={handleSuggestKeywordAdd}>등록</button></div>
                            <div><button onClick={handleSuggestKeywordRemove}>삭제</button></div>
                        </div>
                    </div>
                    <div className={`${admin.adimn_operate_suggest_content_textarea}`}>
                        <textarea name="" id="" disabled={true} value={keywordString}></textarea>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminSuggest;
