import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import event from '../../containers/event/event.module.css';
import * as admin from '../../apis/admin';

const StoreDiscountMore = () => {
    const navigate = useNavigate();
    const scrollRef = useRef(null);

    const [storeProduct, setStoreProduct] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [columnClass, setColumnClass] = useState('col-6'); // 초기 컬럼 클래스

    useEffect(() => {
        // 초기 데이터 로드
        const fetchInitialData = async () => {
            try {
                const response = await admin.discountProductSelect();
                const data = response.data;

                if (response.status === 200 && Array.isArray(data)) {
                    const sortedData = data.sort((a, b) => {
                        return a.discountExposureOrder - b.discountExposureOrder;
                    });

                    setStoreProduct(sortedData.slice(0, 10));
                }
            } catch (error) {
                console.error('Error in discountProductSelect:', error);
                setStoreProduct([]);
            }
        };

        fetchInitialData();
    }, []);

    useEffect(() => {
        // 스크롤 핸들러
        const handleScroll = () => {
            if (scrollRef.current) {
                const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;

                if (scrollTop + clientHeight >= scrollHeight - 5 && hasMore) {
                    loadMoreProducts();
                }
            }
        };

        // 스크롤 이벤트 리스너 추가
        const currentScrollRef = scrollRef.current;
        if (currentScrollRef) {
            currentScrollRef.addEventListener('scroll', handleScroll);
        }
        return () => {
            // 이벤트 리스너 제거
            if (currentScrollRef) {
                currentScrollRef.removeEventListener('scroll', handleScroll);
            }
        };
    }, [hasMore]);

    useEffect(() => {
        // 화면 크기에 따라 컬럼 클래스 업데이트
        const updateColumnClass = () => {
            if (window.innerWidth > 500) {
                setColumnClass('col-4'); // 화면이 500px 이상일 때
            } else {
                setColumnClass('col-6'); // 화면이 500px 이하일 때
            }
        };

        updateColumnClass();
        window.addEventListener('resize', updateColumnClass);

        return () => {
            window.removeEventListener('resize', updateColumnClass);
        };
    }, []);

    const loadMoreProducts = () => {
        const startIndex = page * 6;
        const endIndex = startIndex + 6;

        // 다음 페이지의 데이터를 추가
        setStoreProduct(prevProducts => {
            const moreProducts = prevProducts.slice(startIndex, endIndex);
            if (moreProducts.length === 0) {
                setHasMore(false);
                return prevProducts;
            }
            setPage(prevPage => prevPage + 1);
            return [...prevProducts, ...moreProducts];
        });
    };

    const onBack = () => {
        navigate(-1);
    };

    const onStoreProduct = (item) => {
        navigate(`/store/product/${item.productNo}`);
    };

    return (
        <div>
            <div className={`${event.event_store_more_header}`}>
                <div>
                    <button onClick={() => onBack()}>
                        <span className="material-symbols-outlined">arrow_back_ios</span>
                    </button>
                </div>
                <div>할인상품</div>
            </div>
            <div className={`${event.event_store_more_content}`} ref={scrollRef}>
                <div className='px-3'>
                    <div className='row'>
                        {storeProduct.map((item, index) => (
                            <div className={`search_content_store_item ${columnClass}`} key={index}>
                                <div onClick={() => onStoreProduct(item)}>
                                    <div><img src={item.productMainImage} alt="" /></div>
                                    <div><span>{item.shopName}</span></div>
                                    <div><span>{item.productName}</span></div>
                                    <div>
                                        <div><span>{item.discountRate}%</span></div>
                                        <div><span>{item.productsalePrice.toLocaleString()}원</span></div>
                                        <div><span>{item.productPrice.toLocaleString()}원</span></div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    {!hasMore && <div className={`${event.event_store_more_last_txt}`}><span>할인 상품의 마지막입니다.</span></div>}
                </div>
            </div>
        </div>
    );
};

export default StoreDiscountMore;
