import React, {useState} from 'react'
import RecomMender from './RecomMender';

const FavorityDday = ( {showFavorityDday, animated, favorityDdayTransition, 
                        setShowAgree, setShowJoin, setShowJoinInfo, setShowJoinFavority, 
                        handleFavDdayBack, handleFavDdayClose, selectedItems, useTransition,
                        userList, setSelectedItems, updateDday, recommendTransition,
                        setShowRecommend, showRecommend, favoritydDayNext
                    } ) => {

    // // console.log(`userName: ${userList.userName}, userEmail: ${userList.userEmail}, userPw: ${userList.userPw}, 
    // userRepublic: ${userList.userRepublic}, userTel: ${userList.userTel}, userNick: ${userList.userNick}, 
    // userYear: ${userList.userYear}, userMonth: ${userList.userMonth}, userDay: ${userList.userDay},
    // userGender: ${userList.userGender}`);


    // // console.log(selectedItems);
    // selectedItems.forEach(item => {
    //     // console.log(`no: ${item.no}, name: ${item.name}, imgUrl: ${item.imgUrl}, dday: ${item.dday}`);
    // });

    


    // 각 아이템에 대한 기념일 숫자를 저장할 객체 상태를 초기화합니다.
    const [numbers, setNumbers] = useState(() =>
        selectedItems.reduce((acc, item, index) => {
            acc[item.artistNo] = ''; // 여기서 item.id는 각 아이템을 구별할 수 있는 고유한 값입니다.
            return acc;
        }, {})
    );

    // const [showRecommend, setShowRecommend] = useState(false);

    // // console.log("showRecommend : " + showRecommend);



    const handleChange = (no, event) => {
        const value = event.target.value;
        const regex = /^[0-9]*$/;
  
        if (regex.test(value)) {
            setNumbers((prevNumbers) => ({
                ...prevNumbers,
                [no]: value, // 특정 아이템의 상태만 업데이트합니다.
            }));
            updateDday(no, value);
        }
    };

  return (
    <>
        {showFavorityDday && (
            <div className='favority_dday_container'>
                {favorityDdayTransition((style, item) =>
                    item ? (
                        <animated.div className='favority_day_detail_box' style={style}>
                            <div className='favority_day_detail_header'>
                                <div>
                                    <button onClick={() => handleFavDdayBack()}>
                                        <span className='material-symbols-outlined'>arrow_back_ios</span>
                                    </button>
                                </div>
                                <div>
                                    <span>최애와의 기념일 설정</span>
                                </div>
                                <div>
                                    <button onClick={() => handleFavDdayClose()}>
                                        <span className='material-symbols-outlined'>close</span>
                                    </button>
                                </div>
                            </div>
                            <div className='favority_day_detail_selected_item_box'>
                                {selectedItems.map((item, index) => (
                                    <div key={index} className='mx-3 py-4 border-bottom'>
                                        <div className='favority_dday_selectBox'>
                                            <div className='favority_ddayImgBox'><img src={`${process.env.REACT_APP_API_SERVER}/img?file=${item.imgUrl}`} alt={item.name} /></div>
                                            <div className='favority_ddayNumberBox'><span className='pe-2'>{item.name}</span><span>D+<span>{numbers[item.artistNo] || 1}</span></span></div>
                                        </div>
                                        <div className='favority_dday_inputBox'>
                                            <div><span>최애와의 기념일</span></div>
                                            <div className='d-flex position-relative dday_box'>
                                                <span>D+</span>
                                                <input
                                                    type="text"
                                                    value={numbers[item.artistNo] || ''} // 각 아이템의 고유한 상태를 사용합니다.
                                                    onChange={(event) => handleChange(item.artistNo, event)} // 핸들러에 아이템의 ID를 전달합니다.
                                                    className='dday_input'
                                                    placeholder='12 (숫자만 입력)'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className='favority_dday_btn_box'>
                                <div className='text-center favority_dday_info_box'>
                                    <div><span>미설정 시 최애 등록일을 기준으로 D+1로 설정되며</span></div>
                                    <div><span>내정보 - 나의 최애에서 변경이 가능합니다.</span></div>
                                    <div><button>건너뛰기</button></div>
                                </div>
                                <div className='favority_dday_next_btnbox pt-4'>
                                    <button onClick={favoritydDayNext}>다음</button>
                                </div>
                            </div>
                        </animated.div>
                    ) : null
                )}

            </div>
        )}
    </>
  )
}

export default FavorityDday