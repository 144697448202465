import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';

const AdminSide = ( {admin, activeMenu, setActiveMenu, handleMenuClick, activeUser, changeActiveUser, setActiveUser, 
    activeSeller, setActiveSeller, changeActiveSeller, activeShopping, setActiveShopping, changeActiveShopping,
    activeOperator, setActiveOperator, changeActiveOperator, activeEvent, setActiveEvent, changeActiveEvent,
    activeStatistics, setActiveStatistics, changeActiveStatistics, activeCouponPoint, setActiveCouponPoint, changeActiveCouponPoint,
    roles, userInfo, activeCalculate, setActiveCalculate, changeActiveCalculate, activeMessage, setActiveMessage, changeActiveMessage
} ) => {

    const [openStates, setOpenStates] = useState(false);
    const [openSeller, setOpenSeller] = useState(false);
    const [openShop, setOpenShop] = useState(false);
    const [openOperate, setOpenOperate] = useState(false);
    const [openEvent, setOpenEvent] = useState(false);
    const [openStatistics, setOpenStatistics] = useState(false);
    const [openCouponPoint, setOpenCouponPoint] = useState(false);
    const [openCalculate, setOpenCalculate] = useState(false);
    const [openMessage, setOpenMessage] = useState(false);

    const toggleOpen = () => {
        setOpenStates(!openStates);
        setOpenSeller(false);
        setOpenShop(false);
        setOpenOperate(false);
        setOpenEvent(false);
        setOpenStatistics(false);
        setOpenCouponPoint(false);
        setOpenCalculate(false);
        setOpenMessage(false);
    };
    const toggleOpen1 = () => {
        setOpenStates(false);
        setOpenSeller(!openSeller);
        setOpenShop(false);
        setOpenOperate(false);
        setOpenEvent(false);
        setOpenStatistics(false);
        setOpenCouponPoint(false);
        setOpenCalculate(false);
        setOpenMessage(false);
    };
    const toggleOpen2 = () => {
        setOpenStates(false);
        setOpenSeller(false);
        setOpenShop(!openShop);
        setOpenOperate(false);
        setOpenEvent(false);
        setOpenStatistics(false);
        setOpenCouponPoint(false);
        setOpenCalculate(false);
        setOpenMessage(false);
    };
    const toggleOpen3 = () => {
        setOpenStates(false);
        setOpenSeller(false);
        setOpenShop(false);
        setOpenOperate(!openOperate);
        setOpenEvent(false);
        setOpenStatistics(false);
        setOpenCouponPoint(false);
        setOpenCalculate(false);
        setOpenMessage(false);
    };
    const toggleOpen4 = () => {
        setOpenStates(false);
        setOpenSeller(false);
        setOpenShop(false);
        setOpenOperate(false);
        setOpenEvent(!openEvent);
        setOpenStatistics(false);
        setOpenCouponPoint(false);
        setOpenCalculate(false);
        setOpenMessage(false);
    };
    const toggleOpen5 = () => {
        setOpenStates(false);
        setOpenSeller(false);
        setOpenShop(false);
        setOpenOperate(false);
        setOpenEvent(false);
        setOpenStatistics(!openStatistics);
        setOpenCouponPoint(false);
        setOpenCalculate(false);
        setOpenMessage(false);
    };
    const toggleOpen6 = () => {
        setOpenStates(false);
        setOpenSeller(false);
        setOpenShop(false);
        setOpenOperate(false);
        setOpenEvent(false);
        setOpenStatistics(false);
        setOpenCouponPoint(!openCouponPoint);
        setOpenCalculate(false);
        setOpenMessage(false);
    };

    const calculateToggle = () => {
        setOpenStates(false);
        setOpenSeller(false);
        setOpenShop(false);
        setOpenOperate(false);
        setOpenEvent(false);
        setOpenStatistics(false);
        setOpenCouponPoint(false);
        setOpenCalculate(!openCalculate);
        setOpenMessage(false);
    }
    const messageToggleOpen = () => {
        setOpenStates(false);
        setOpenSeller(false);
        setOpenShop(false);
        setOpenOperate(false);
        setOpenEvent(false);
        setOpenStatistics(false);
        setOpenCouponPoint(false);
        setOpenCalculate(false);
        setOpenMessage(!openMessage);
    }

  return (
    <div className={`${admin.admin_side_container}`}>
        <div className={`${admin.admin_side_top_box} px-3 py-3`}>
            <div><img src={`${process.env.PUBLIC_URL}/img/dailyduck_logo.png`} alt="로고" /></div>
            <div className='ps-2'>
                <div><span>{userInfo && userInfo.userId}님,</span></div>
                <div><span>안녕하세요.</span></div>
            </div>
        </div>
        <div className={`${admin.admin_side_menu_box}`}>
            <div className='w-100 py-1'>
                <button className={`${admin.admin_side_btn_style} px-2 py-2 ${activeMenu=='board' ? admin.admin_menu_select : ''}`}
                    onClick={() => handleMenuClick('board')}
                >
                    <div className='d-flex align-items-center'>
                        <div><span className="material-symbols-outlined">grid_view</span></div>
                        <div className='ps-1'><span>대시보드</span></div>
                    </div>
                    <div></div>
                </button>
            </div>
            {
                roles.isAdmin && 
                <div>
                    <div className={`d-flex align-items-center py-1`}>
                        <button className={`${admin.admin_side_btn_style} px-2 py-2 ${activeMenu=='user' ? admin.admin_menu_select : ''}`} 
                            onClick={() => {handleMenuClick('user'); toggleOpen();}}
                        >
                            <div className='d-flex align-items-center'>
                                <div><span className="material-symbols-outlined">person</span></div>
                                <div className='ps-1'><span>사용자 관리</span></div>
                            </div>
                            <div><span className="material-symbols-outlined">{openStates ? 'expand_less' : 'expand_more'}</span></div>
                        </button>
                    </div>
                    {/* 펼쳐지는 영역 시작 */}
                    <CSSTransition in={openStates} timeout={300} classNames="review" unmountOnExit>
                        <div className='w-100'>
                            <div className={`w-100`}><button className={`ps-5 py-2 ${admin.admin_board_toggle_menu_btn} ${activeUser === 'userList' ? admin.admin_board_user_active : ''}`} onClick={() => {setActiveUser('userList'); changeActiveUser('userList')}}>사용자 목록</button></div>
                            <div className='w-100'><button className={`ps-5 py-2 ${admin.admin_board_toggle_menu_btn} ${activeUser === 'management' ? admin.admin_board_user_active : ''} `} onClick={() => {setActiveUser('management'); changeActiveUser('management')}}>운영진</button></div>
                            <div className='w-100'><button className={`ps-5 py-2 ${admin.admin_board_toggle_menu_btn} ${activeUser === 'dormentUser' ? admin.admin_board_user_active : ''} `} onClick={() => {setActiveUser('dormentUser'); changeActiveUser('dormentUser')}}>휴면 사용자</button></div>
                            <div className='w-100'><button className={`ps-5 py-2 ${admin.admin_board_toggle_menu_btn} ${activeUser === 'pushMessage' ? admin.admin_board_user_active : ''} `} onClick={() => {setActiveUser('pushMessage'); changeActiveUser('pushMessage')}}>메시지 보내기<br/>(푸시 알림)</button></div>
                            <div className='w-100'><button className={`ps-5 py-2 ${admin.admin_board_toggle_menu_btn} ${activeUser === 'userAdd' ? admin.admin_board_user_active : ''} `} onClick={() => {setActiveUser('userAdd'); changeActiveUser('userAdd')}}>유저 엑셀 등록</button></div>
                        </div>
                    </CSSTransition>
                    <div className={`d-flex align-items-center py-1`}>
                        <button className={`${admin.admin_side_btn_style} px-2 py-2 ${activeMenu=='seller' ? admin.admin_menu_select : ''}`}
                            onClick={() => {handleMenuClick('seller'); toggleOpen1();}}
                        >
                            <div className='d-flex align-items-center'>
                                <div><span className="material-symbols-outlined">group</span></div>
                                <div className='ps-1'><span>셀러 관리</span></div>
                            </div>
                            <div><span className="material-symbols-outlined">{openSeller ? 'expand_less' : 'expand_more'}</span></div>
                        </button>
                    </div>
                    {/* 펼쳐지는 영역 시작 */}
                    <CSSTransition in={openSeller} timeout={300} classNames="review" unmountOnExit>
                        <div className='w-100'>
                            <div className='w-100'><button className={`ps-5 py-2 ${admin.admin_board_toggle_menu_btn} ${activeSeller === 'sellerAdd' ? admin.admin_board_user_active : ''} `} onClick={() => {setActiveSeller('sellerAdd'); changeActiveSeller('sellerAdd')}}>셀러 등록</button></div>
                            <div className='w-100'><button className={`ps-5 py-2 ${admin.admin_board_toggle_menu_btn} ${activeSeller === 'sellerManage' ? admin.admin_board_user_active : ''} `} onClick={() => {setActiveSeller('sellerManage'); changeActiveSeller('sellerManage')}}>셀러 관리</button></div>
                            <div className='w-100'><button className={`ps-5 py-2 ${admin.admin_board_toggle_menu_btn} ${activeSeller === 'sellerGroup' ? admin.admin_board_user_active : ''} `} onClick={() => {setActiveSeller('sellerGroup'); changeActiveSeller('sellerGroup')}}>그룹 및 등급<br/>(셀러 등급 설정)</button></div>
                            <div className='w-100'><button className={`ps-5 py-2 ${admin.admin_board_toggle_menu_btn} ${activeSeller === 'sellerInspection' ? admin.admin_board_user_active : ''} `} onClick={() => {setActiveSeller('sellerInspection'); changeActiveSeller('sellerInspection')}}>셀러 상품 검수</button></div>
                            <div className='w-100'><button className={`ps-5 py-2 ${admin.admin_board_toggle_menu_btn} ${activeSeller === 'supportProduct' ? admin.admin_board_user_active : ''} `} onClick={() => {setActiveSeller('supportProduct'); changeActiveSeller('supportProduct')}}>서포트 상품 관리</button></div>
                            <div className='w-100'><button className={`ps-5 py-2 ${admin.admin_board_toggle_menu_btn} ${activeSeller === 'sellerNotice' ? admin.admin_board_user_active : ''} `} onClick={() => {setActiveSeller('sellerNotice'); changeActiveSeller('sellerNotice')}}>셀러 공지사항</button></div>
                            <div className='w-100'><button className={`ps-5 py-2 ${admin.admin_board_toggle_menu_btn} ${activeSeller === 'sellerFaq' ? admin.admin_board_user_active : ''} `} onClick={() => {setActiveSeller('sellerFaq'); changeActiveSeller('sellerFaq')}}>셀러 FAQ</button></div>
                        </div>
                    </CSSTransition>
                </div>
            }
            <div className={`d-flex align-items-center py-1`}>
                <button className={`${admin.admin_side_btn_style} px-2 py-2 ${activeMenu=='shopSetting' ? admin.admin_menu_select : ''}`}
                        onClick={() => {handleMenuClick('shopSetting')}}
                >
                    <div className={`d-flex align-items-center`}>
                        <div><span className="material-symbols-outlined">storefront</span></div>
                        <div className='ps-1'><span>상점 관리</span></div>
                    </div>
                    <div></div>
                </button>
            </div>
            <div className={`d-flex align-items-center py-1`}>
                <button className={`${admin.admin_side_btn_style} px-2 py-2 ${activeMenu=='message' ? admin.admin_menu_select : ''}`}
                    onClick={() => {handleMenuClick('message'); messageToggleOpen();}}
                >
                    <div className={`d-flex align-items-center`}>
                        <div><span className="material-symbols-outlined">confirmation_number</span></div>
                        <div className='ps-1'><span>메시지</span></div>
                    </div>
                    <div><span className="material-symbols-outlined">expand_more</span></div>
                </button>
            </div>
            {/* 펼쳐지는 영역 시작 */}
            <CSSTransition in={openMessage} timeout={300} classNames="review" unmountOnExit>
                <div className='w-100'>
                    <div className='w-100'><button className={`ps-5 py-2 ${admin.admin_board_toggle_menu_btn} ${activeMessage === 'message' ? admin.admin_board_user_active : ''}`} onClick={() => {setActiveMessage('message'); changeActiveMessage('message')}}>메시지</button></div>
                    <div className='w-100'><button className={`ps-5 py-2 ${admin.admin_board_toggle_menu_btn} ${activeMessage === 'messagePayment' ? admin.admin_board_user_active : ''}`} onClick={() => {setActiveMessage('messagePayment'); changeActiveMessage('messagePayment')}}>메시지 결제내역</button></div>
                </div>
            </CSSTransition>
            <div className={`d-flex align-items-center py-1`}>
                <button className={`${admin.admin_side_btn_style} px-2 py-2 ${activeMenu=='shopping' ? admin.admin_menu_select : ''}`}
                    onClick={() => {handleMenuClick('shopping'); toggleOpen2()}}
                >
                    <div className={`d-flex align-items-center`}>
                        <div><span className="material-symbols-outlined">shopping_bag</span></div>
                        <div className='ps-1'><span>쇼핑</span></div>
                    </div>
                    <div><span className="material-symbols-outlined">expand_more</span></div>
                </button>
            </div>
            {/* 펼쳐지는 영역 시작 */}
            <CSSTransition in={openShop} timeout={300} classNames="review" unmountOnExit>
                <div className='w-100'>
                    <div className='w-100'><button className={`ps-5 py-2 ${admin.admin_board_toggle_menu_btn} ${activeShopping === 'product' ? admin.admin_board_user_active : ''} `} onClick={() => {setActiveMenu('product'); changeActiveShopping('product')}}>상품</button></div>
                    <div className='w-100'><button className={`ps-5 py-2 ${admin.admin_board_toggle_menu_btn} ${activeShopping === 'order' ? admin.admin_board_user_active : ''} `} onClick={() => {setActiveMenu('order'); changeActiveShopping('order')}}>주문</button></div>
                    <div className='w-100'><button className={`ps-5 py-2 ${admin.admin_board_toggle_menu_btn} ${activeShopping === 'cancel' ? admin.admin_board_user_active : ''} `} onClick={() => {setActiveMenu('cancel'); changeActiveShopping('cancel')}}>취소</button></div>
                    <div className='w-100'><button className={`ps-5 py-2 ${admin.admin_board_toggle_menu_btn} ${activeShopping === 'return' ? admin.admin_board_user_active : ''} `} onClick={() => {setActiveMenu('return'); changeActiveShopping('return')}}>반품</button></div>
                    <div className='w-100'><button className={`ps-5 py-2 ${admin.admin_board_toggle_menu_btn} ${activeShopping === 'change' ? admin.admin_board_user_active : ''} `} onClick={() => {setActiveMenu('change'); changeActiveShopping('change')}}>교환</button></div>
                    {/* <div className='w-100'><button className={`ps-5 py-2 ${admin.admin_board_toggle_menu_btn} ${activeShopping === 'present' ? admin.admin_board_user_active : ''} `} onClick={() => {setActiveMenu('present'); changeActiveShopping('present')}}>선물하기</button></div> */}
                    <div className='w-100'><button className={`ps-5 py-2 ${admin.admin_board_toggle_menu_btn} ${activeShopping === 'review' ? admin.admin_board_user_active : ''} `} onClick={() => {setActiveMenu('review'); changeActiveShopping('review')}}>구매평</button></div>
                    {/* <div className='w-100'><button className={`ps-5 py-2 ${admin.admin_board_toggle_menu_btn} ${activeShopping === 'reserve' ? admin.admin_board_user_active : ''} `} onClick={() => {setActiveMenu('reserve'); changeActiveShopping('reserve')}}>적립금</button></div>
                    <div className='w-100'><button className={`ps-5 py-2 ${admin.admin_board_toggle_menu_btn} ${activeShopping === 'coupon' ? admin.admin_board_user_active : ''} `} onClick={() => {setActiveMenu('coupon'); changeActiveShopping('coupon')}}>쿠폰</button></div> */}
                    <div className='w-100'><button className={`ps-5 py-2 ${admin.admin_board_toggle_menu_btn} ${activeShopping === 'delivery' ? admin.admin_board_user_active : ''} `} onClick={() => {setActiveMenu('delivery'); changeActiveShopping('delivery')}}>배송설정</button></div>
                    <div className='w-100'><button className={`ps-5 py-2 ${admin.admin_board_toggle_menu_btn} ${activeShopping === 'setting' ? admin.admin_board_user_active : ''} `} onClick={() => {setActiveMenu('setting'); changeActiveShopping('setting')}}>환경설정</button></div>
                </div>
            </CSSTransition>
            <div className='py-1'>
                <button className={`${admin.admin_side_btn_style} px-2 py-2 ${activeMenu=='calculate' ? admin.admin_menu_select : ''}`}
                    onClick={() => {handleMenuClick('calculate'); calculateToggle();}}
                >
                    <div className={`d-flex align-items-center`}>
                        <div><span className="material-symbols-outlined">calculate</span></div>
                        <div className='ps-1'><span>정산</span></div>
                    </div>
                    <div><span className="material-symbols-outlined">{openCalculate ? 'expand_less' : 'expand_more'}</span></div>
                </button>
            </div>
            <CSSTransition in={openCalculate} timeout={300} classNames="review" unmountOnExit>
                <div className='w-100'>
                    {
                        roles.isStore && 
                        <div>
                            <div className='w-100'><button className={`py-2 ps-5 ${admin.admin_board_toggle_menu_btn} ${activeCalculate === 'proceed' ? admin.admin_board_user_active : ''}`} onClick={() => {setActiveCalculate('proceed'); changeActiveCalculate('proceed')}}>수익금 내역</button></div>
                            <div className='w-100'><button className={`py-2 ps-5 ${admin.admin_board_toggle_menu_btn} ${activeCalculate === 'settle' ? admin.admin_board_user_active : ''}`} onClick={() => {setActiveCalculate('settle'); changeActiveCalculate('settle')}}>정산 내역</button></div>
                        </div>
                    }
                    {
                        roles.isAdmin && 
                        <div>
                            <div className='w-100'><button className={`py-2 ps-5 ${admin.admin_board_toggle_menu_btn} ${activeCalculate === 'adminproceed' ? admin.admin_board_user_active : ''}`} onClick={() => {setActiveCalculate('adminproceed'); changeActiveCalculate('adminproceed')}}>수익금 내역</button></div>
                            <div className='w-100'><button className={`py-2 ps-5 ${admin.admin_board_toggle_menu_btn} ${activeCalculate === 'adminsettle' ? admin.admin_board_user_active : ''}`} onClick={() => {setActiveCalculate('adminsettle'); changeActiveCalculate('adminsettle')}}>정산 내역</button></div>
                            <div className='w-100'><button className={`py-2 ps-5 ${admin.admin_board_toggle_menu_btn} ${activeCalculate === 'feesetting' ? admin.admin_board_user_active : ''}`} onClick={() => {setActiveCalculate('feesetting'); changeActiveCalculate('feesetting')}}>수수료적용</button></div>
                        </div>
                    }
                </div>
            </CSSTransition>
            {
                roles.isAdmin &&
                <div>
                    <div className={`d-flex align-items-center py-1`}>
                        <button className={`${admin.admin_side_btn_style} px-2 py-2 ${activeMenu=='operate' ? admin.admin_menu_select : ''}`}
                            onClick={() => {handleMenuClick('operate'); toggleOpen3();}}
                        >
                            <div className={`d-flex align-items-center`}>
                                <div><span className="material-symbols-outlined">manage_history</span></div>
                                <div className='ps-1'><span>운영</span></div>
                            </div>
                            <div><span className="material-symbols-outlined">{openOperate ? 'expand_less' : 'expand_more'}</span></div>
                        </button>
                    </div>
                    {/* 펼쳐지는 영역 시작 */}
                    <CSSTransition in={openOperate} timeout={300} classNames="review" unmountOnExit>
                        <div className='w-100'>
                            <div className='w-100'><button className={`py-2 ps-5 ${admin.admin_board_toggle_menu_btn} ${activeOperator === 'banner' ? admin.admin_board_user_active : ''}`} onClick={() => {setActiveOperator('banner'); changeActiveOperator('banner')}}>배너</button></div>
                            <div className='w-100'><button className={`py-2 ps-5 ${admin.admin_board_toggle_menu_btn} ${activeOperator === 'popup' ? admin.admin_board_user_active : ''}`} onClick={() => {setActiveOperator('popup'); changeActiveOperator('popup')}}>팝업</button></div>
                            <div className='w-100'><button className={`py-2 ps-5 ${admin.admin_board_toggle_menu_btn} ${activeOperator === 'agree' ? admin.admin_board_user_active : ''}`} onClick={() => {setActiveOperator('agree'); changeActiveOperator('agree')}}>약관</button></div>
                            <div className='w-100'><button className={`py-2 ps-5 ${admin.admin_board_toggle_menu_btn} ${activeOperator === 'notice' ? admin.admin_board_user_active : ''}`} onClick={() => {setActiveOperator('notice'); changeActiveOperator('notice')}}>문의</button></div>
                            <div className='w-100'><button className={`py-2 ps-5 ${admin.admin_board_toggle_menu_btn} ${activeOperator === 'notification' ? admin.admin_board_user_active : ''}`} onClick={() => {setActiveOperator('notification'); changeActiveOperator('notification')}}>공지</button></div>
                            <div className='w-100'><button className={`py-2 ps-5 ${admin.admin_board_toggle_menu_btn} ${activeOperator === 'favority' ? admin.admin_board_user_active : ''}`} onClick={() => {setActiveOperator('favority'); changeActiveOperator('favority')}}>최애관리</button></div>
                            <div className='w-100'><button className={`py-2 ps-5 ${admin.admin_board_toggle_menu_btn} ${activeOperator === 'suggestKeyword' ? admin.admin_board_user_active : ''}`} onClick={() => {setActiveOperator('suggestKeyword'); changeActiveOperator('suggestKeyword')}}>추천키워드</button></div>
                            <div className='w-100'><button className={`py-2 ps-5 ${admin.admin_board_toggle_menu_btn} ${activeOperator === 'excludedKeyword' ? admin.admin_board_user_active : ''}`} onClick={() => {setActiveOperator('excludedKeyword'); changeActiveOperator('excludedKeyword')}}>제외키워드</button></div>
                            <div className='w-100'><button className={`py-2 ps-5 ${admin.admin_board_toggle_menu_btn} ${activeOperator === 'homepage' ? admin.admin_board_user_active : ''}`} onClick={() => {setActiveOperator('homepage'); changeActiveOperator('homepage')}}>페이지관리</button></div>
                            <div className='w-100'><button className={`py-2 ps-5 ${admin.admin_board_toggle_menu_btn} ${activeOperator === 'popularSection' ? admin.admin_board_user_active : ''}`} onClick={() => {setActiveOperator('popularSection'); changeActiveOperator('popularSection')}}>인기섹션관리</button></div>
                            <div className='w-100'><button className={`py-2 ps-5 ${admin.admin_board_toggle_menu_btn} ${activeOperator === 'pointProbability' ? admin.admin_board_user_active : ''}`} onClick={() => {setActiveOperator('pointProbability'); changeActiveOperator('pointProbability')}}>포인트확률조정</button></div>
                            <div className='w-100'><button className={`py-2 ps-5 ${admin.admin_board_toggle_menu_btn} ${activeOperator === 'category' ? admin.admin_board_user_active : ''}`} onClick={() => {setActiveOperator('category'); changeActiveOperator('category')}}>카테고리</button></div>
                        </div>
                    </CSSTransition>
                    <div className='py-1'>
                        <button className={`${admin.admin_side_btn_style} px-2 py-2 ${activeMenu=='event' ? admin.admin_menu_select : ''}`}
                            onClick={() => {handleMenuClick('event'); toggleOpen4();}}
                        >
                            <div className={`d-flex align-items-center`}>
                                <div><span className="material-symbols-outlined">event</span></div>
                                <div className='ps-1'><span>이벤트</span></div>
                            </div>
                            <div><span className="material-symbols-outlined">{openEvent ? 'expand_less' : 'expand_more'}</span></div>
                        </button>
                    </div>
                    {/* 펼쳐지는 영역 시작 */}
                    <CSSTransition in={openEvent} timeout={300} classNames="review" unmountOnExit>
                        <div className='w-100'>
                            <div className='w-100'><button className={`ps-5 py-2 ${admin.admin_board_toggle_menu_btn} ${activeEvent === 'eventAdd' ? admin.admin_board_user_active : ''}`} onClick={() => {setActiveEvent('eventAdd'); changeActiveEvent('eventAdd')}}>이벤트 관리</button></div>
                            <div className='w-100'><button className={`ps-5 py-2 ${admin.admin_board_toggle_menu_btn} ${activeEvent === 'eventParticipant' ? admin.admin_board_user_active : ''}`} onClick={() => {setActiveEvent('eventParticipant'); changeActiveEvent('eventParticipant')}}>이벤트 참여자</button></div>
                            <div className='w-100'><button className={`ps-5 py-2 ${admin.admin_board_toggle_menu_btn} ${activeEvent === 'winners' ? admin.admin_board_user_active : ''}`} onClick={() => {setActiveEvent('winners'); changeActiveEvent('winners')}}>당첨자 발표</button></div>
                            <div className='w-100'><button className={`ps-5 py-2 ${admin.admin_board_toggle_menu_btn} ${activeEvent === 'prevEvent' ? admin.admin_board_user_active : ''}`} onClick={() => {setActiveEvent('prevEvent'); changeActiveEvent('prevEvent')}}>지난 이벤트</button></div>
                        </div>
                    </CSSTransition>
                    <div className='py-1'>
                        <button className={`${admin.admin_side_btn_style} px-2 py-2 ${activeMenu=='statistics' ? admin.admin_menu_select : ''}`}
                            onClick={() => {handleMenuClick('statistics'); toggleOpen5();}}
                        >
                            <div className={`d-flex align-items-center`}>
                                <div><span className="material-symbols-outlined">event</span></div>
                                <div className='ps-1'><span>통계</span></div>
                            </div>
                            <div><span className="material-symbols-outlined">{openStatistics ? 'expand_less' : 'expand_more'}</span></div>
                        </button>
                    </div>
                    {/* 펼쳐지는 영역 시작 */}
                    <CSSTransition in={openStatistics} timeout={300} classNames="review" unmountOnExit>
                        <div className='w-100'>
                            <div className='w-100'><button className={`ps-5 py-2 ${admin.admin_board_toggle_menu_btn} ${activeStatistics === 'analysisByPeriod' ? admin.admin_board_user_active : ''}`} onClick={() => {setActiveStatistics('analysisByPeriod'); changeActiveStatistics('analysisByPeriod')}}>기간별 분석</button></div>
                            <div className='w-100'><button className={`ps-5 py-2 ${admin.admin_board_toggle_menu_btn} ${activeStatistics === 'inflowSite' ? admin.admin_board_user_active : ''}`} onClick={() => {setActiveStatistics('inflowSite'); changeActiveStatistics('inflowSite')}}>유입 사이트</button></div>
                            <div className='w-100'><button className={`ps-5 py-2 ${admin.admin_board_toggle_menu_btn} ${activeStatistics === 'inflowSearch' ? admin.admin_board_user_active : ''}`} onClick={() => {setActiveStatistics('inflowSearch'); changeActiveStatistics('inflowSearch')}}>유입 검색어</button></div>
                            <div className='w-100'><button className={`ps-5 py-2 ${admin.admin_board_toggle_menu_btn} ${activeStatistics === 'mostVisitedPages' ? admin.admin_board_user_active : ''}`} onClick={() => {setActiveStatistics('mostVisitedPages'); changeActiveStatistics('mostVisitedPages')}}>많이 방문한 페이지</button></div>
                            <div className='w-100'><button className={`ps-5 py-2 ${admin.admin_board_toggle_menu_btn} ${activeStatistics === 'trafic' ? admin.admin_board_user_active : ''}`} onClick={() => {setActiveStatistics('trafic'); changeActiveStatistics('trafic')}}>트래픽</button></div>
                        </div>
                    </CSSTransition>
                    <div className={`d-flex align-items-center py-1`}>
                        <button className={`${admin.admin_side_btn_style} px-2 py-2 ${activeMenu=='couponPoint' ? admin.admin_menu_select : ''}`}
                            onClick={() => {handleMenuClick('couponPoint'); toggleOpen6();}}
                        >
                            <div className={`d-flex align-items-center`}>
                                <div><span className="material-symbols-outlined">confirmation_number</span></div>
                                <div className='ps-1'><span>쿠폰 / 포인트</span></div>
                            </div>
                            <div><span className="material-symbols-outlined">expand_more</span></div>
                        </button>
                    </div>
                    {/* 펼쳐지는 영역 시작 */}
                    <CSSTransition in={openCouponPoint} timeout={300} classNames="review" unmountOnExit>
                        <div className='w-100'>
                            <div className='w-100'><button className={`ps-5 py-2 ${admin.admin_board_toggle_menu_btn} ${activeCouponPoint === 'coupon' ? admin.admin_board_user_active : ''}`} onClick={() => {setActiveCouponPoint('coupon'); changeActiveCouponPoint('coupon')}}>쿠폰 관리</button></div>
                            <div className='w-100'><button className={`ps-5 py-2 ${admin.admin_board_toggle_menu_btn} ${activeCouponPoint === 'point' ? admin.admin_board_user_active : ''}`} onClick={() => {setActiveCouponPoint('point'); changeActiveCouponPoint('point')}}>포인트 관리</button></div>
                        </div>
                    </CSSTransition>
                </div>
            }
        </div>
    </div>
  )
}

export default AdminSide