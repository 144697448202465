import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const DailyduckFooter = ({ market, admin, mode, basicTermsList, onTermsClick, setShowContentWarning }) => {

  const [firstHalf, setFirstHalf] = useState([]);
  const [secondHalf, setSecondHalf] = useState([]);

  useEffect(() => {
    const halfIndex = Math.ceil(basicTermsList.length / 2);

    const firstHalf = basicTermsList.slice(0, halfIndex);
    const secondHalf = basicTermsList.slice(halfIndex);

    setFirstHalf(firstHalf);
    setSecondHalf(secondHalf);

  }, [basicTermsList]);

  const handleCallClick = () => {
    // 전화 걸기 (전화번호로 텔레폰 링크 사용)
    window.location.href = 'tel:02-2088-1344';
  };

  const handleCopyToClipboard = () => {
    // 클립보드에 이메일 주소 복사
    navigator.clipboard.writeText('business@smim.xyz').then(() => {
      alert('이메일 주소가 클립보드에 복사되었습니다.');
    });
  };

  return (
    <> 
      <div className={market.market_footer_terms_container}>
        <div className={market.market_footer_terms_box}>
          <div className={market.market_footer_logo}>
            <div><img src={`${process.env.PUBLIC_URL}/img/white_logo1.png`} alt="" /></div>
          </div>
          <div className={market.market_footer_company_info}>
            <div>
              <span>주식회사 스밈</span>
              <span>&nbsp;|&nbsp;</span>
              <span>대표자 : 공정현</span>
              <span>&nbsp;|&nbsp;</span>
              <span>사업자 등록번호 : 142-86-02247</span>
              <span>&nbsp;|&nbsp;</span>
              <span>통신판매업신고번호 : 제 2024-서울은평-1103호</span>
            </div>
            <div className={market.market_footer_company_info_tel_customer}>
              <div>
                <div><span>고객센터</span></div>
                <div>
                  <div><span>02-2088-1344</span></div>
                  <div><button onClick={() => handleCallClick()}><span className="material-symbols-outlined">support_agent</span></button></div>
                </div>
              </div>
              <div>
                <div><span>광고제휴문의</span></div>
                <div><Link to="#" onClick={handleCopyToClipboard}>business@smim.xyz</Link></div>
              </div>
            </div>
          </div>
          
          <div className={market.market_footer_sns}>
              <Link to="https://twitter.com/_dailyduck" target="_blank"><img src={`${process.env.PUBLIC_URL}/img/icon/sns/twitter_icon.png`} alt="" /></Link>
              <Link to="https://www.youtube.com/channel/UCAnb9l2c8tERNvH3TmGq6SA" target="_blank"><img src={`${process.env.PUBLIC_URL}/img/icon/sns/youtube_icon.png`} alt="" /></Link>
              <Link to="https://www.tiktok.com/@dailyduck.official" target="_blank"><img src={`${process.env.PUBLIC_URL}/img/icon/sns/tiktok_icon.png`} alt="" /></Link>
              <Link to="https://www.instagram.com/dailyduck.official/" target="_blank"><img src={`${process.env.PUBLIC_URL}/img/icon/sns/instagram_icon.png`} alt="" /></Link>
              <Link to="https://pf.kakao.com/_gxaGhb" target="_blank"><img src={`${process.env.PUBLIC_URL}/img/icon/sns/kakaotalk_icon.png`} alt="" /></Link>
              <Link to="https://blog.naver.com/dailyduckapp" target="_blank"><img src={`${process.env.PUBLIC_URL}/img/icon/sns/naver_icon.png`} alt="" /></Link>
          </div>
          <div className={`${market.market_footer_terms_info}`}>
            {/* {
              Array.isArray(basicTermsList) && basicTermsList.map((item, index) => {
                return (
                  <div key={index} className='col-3'><button onClick={() => onTermsClick(item)}>{item.termsTitle}</button></div>
                )
              })
            } */}
            {/* 첫 번째 그룹 */}
            <div className={`${market.market_footer_terms_info_first}`}>
              {firstHalf.map((item, index) => (
                <div key={index}>
                  <button onClick={() => onTermsClick(item)}>
                    {item.termsTitle}
                  </button>
                </div>
              ))}
            </div>

            {/* 두 번째 그룹 */}
            <div className={`${market.market_footer_terms_info_second}`}>
              {secondHalf.map((item, index) => (
                <div key={index}>
                  <button onClick={() => onTermsClick(item)}>
                    {item.termsTitle}
                  </button>
                </div>
              ))}
            </div>
          </div>
          <div className={`${market.market_footer_content_warning}`}>
            <div>
              <span>주식회사 스밈은 통신판매 중개자로서 통신판매의 당사자가 아니며 상품의 예약, 이용 및 환불 등과 관련한 의무와 책임은 각 판매자에게 있습니다.</span>
            </div>
            <div>
              <span>주식회사 스밈이 소유/운영/관리하는 웹사이트 및 앱 내의 상품/판매자/이벤트 정보, 디자인 및 화면 구성, UI 등 모든 콘텐츠에 대한 무단 복제, 배포, 방송, 전송, 스크래핑 등은 저작권법 및 콘텐츠산업 진흥법 등 관련 법령에 의해 엄격히 금지됩니다.</span>
            </div>
            <div><button onClick={() => setShowContentWarning(true)}>콘텐츠산업 진흥법에 따른 표시</button></div>
          </div>
          <div className={`${market.market_footer_corp}`}>
            <span>© Smim Corp.</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default DailyduckFooter;
