import React, { useEffect, useState } from 'react';
import { animated } from 'react-spring';
import { useTransition } from '@react-spring/core';
import { Select } from 'antd';
import { CSSTransition } from 'react-transition-group';
import dailyduck from '../../containers/my/MyContainner.module.css';
import { useLocation, useNavigate } from 'react-router-dom';
import * as admin from '../../apis/admin';
import * as auth from '../../apis/auth';
import * as storeapi from '../../apis/store';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const { Option } = Select;

const Refund = () => {
    const MySwal = withReactContent(Swal);
    const navigate = useNavigate();
    const location = useLocation();
    const orderInfo = location.state?.order;
    const [activeRefundState, setActiveRefundState] = useState('');
    const [showBuyerBox, setShowBuyerBox] = useState(false);
    const [showSellerBox, setShowSellerBox] = useState(false);
    const [buyerSelect, setBuyerSelect] = useState('');
    const [sellerSelect, setSellerSelect] = useState('');
    const [returnContent, setReturnContent] = useState({
        paymentNo: orderInfo?.paymentNo || null,
        returnType: '',
        returnContent: '',
        refundAmount: 0,
        returnCollectMethod: '',
        returnDeliveryCompany: '',
        returnDeliveryNumber: '',
        returnSelect: ''
    });
    const [refundMethodSelect, setRefundMethodSelect] = useState('');
    const [agreeCheck, setAgreeCheck] = useState(false);
    const [deliveryCompanies, setDeliveryCompanies] = useState([]);
    const [sellerRefundAddress, setSellerRefundAddress] = useState(null);
    const [returnDeliveryPriceSelect, setReturnDeliveryPriceSelect] = useState('');
    const [shopInfo, setShopInfo] = useState({});

    useEffect(() => {
        // console.log('returnContent : ', returnContent);
    }, [returnContent]);

    useEffect(() => {
        // console.log('orderInfo : ', orderInfo);

        deliveryCompanySelect();

        const sellerRefundSelect = async () => {
            const response = await admin.sellerRefundSelect(orderInfo?.paymentProductNumber);
            const data = response.data;

            // console.log('data : ', data);
            setSellerRefundAddress(data);
        };
        sellerRefundSelect();

        const shopSelect = async () => {
            const response = await storeapi.sellerShopSelect(orderInfo?.sellerId);
            const data = response.data;

            // console.log('shop : ' , data);
            setShopInfo(data);
        }
        shopSelect();
        
    }, [orderInfo]);

    useEffect(() => {
        calculateRefundAmount();
    }, [activeRefundState, refundMethodSelect]);

    const transitions = useTransition(showBuyerBox, {
        from: { transform: "translateY(100%)" },
        enter: { transform: "translateY(0)" },
        leave: { transform: "translatey(100%)" },
        config: {
            duration: 200,
        },
    });
    const sellerTransitions = useTransition(showSellerBox, {
        from: { transform: "translateY(100%)" },
        enter: { transform: "translateY(0)" },
        leave: { transform: "translatey(100%)" },
        config: {
            duration: 200,
        },
    });

    const handleMyRefundBack = () => {
        navigate(-1);
    }

    const [openStates, setOpenStates] = useState(false);
    const toggleOpen = () => {setOpenStates(!openStates);};

    const onBuyerSelectBtn = (text) => {
        setBuyerSelect(text);
        setReturnContent(prevState => ({
            ...prevState, returnContent: text
        }));
        setShowBuyerBox(false);
    }

    const onSellerSelectBtn = (text) => {
        setSellerSelect(text);
        setReturnContent(prevState => ({
            ...prevState, returnContent: text
        }));
        setShowSellerBox(false);
    }

    const onDirectRefund = (e) => {
        // console.log(e.target.value);
        setReturnContent(prevState => ({
            ...prevState, returnContent: e.target.value
        }));
    }

    const calculateTotalPrice = () => {
        if(orderInfo.eventType === 'plan' && orderInfo.planProductRate !== 0) {
            return orderInfo.options.reduce((total, option) => {
                const optionTotal = ((parseInt(orderInfo.paymentPrice)) * option.quantity) + (option.addPrice * option.quantity);
                return total + optionTotal;
            }, 0).toLocaleString();
        } else {
            // return orderInfo.options.reduce((total, option) => {
            //     const optionTotal = (parseInt(orderInfo.paymentPrice) * option.quantity) + (option.addPrice * option.quantity);
            //     return total + optionTotal;
            // }, 0).toLocaleString();
            return parseInt(orderInfo.principalAmount * orderInfo.totalQuantity).toLocaleString();
        }
    };

    const onRefundMethod = (e) => {
        // console.log(e.target.id);
        setRefundMethodSelect(e.target.id);
        let method = ''
        if(e.target.id === 'type1') {
            method = '수거신청';
        } else {
            method = '직접배송'
        }
        setReturnContent(prevState => ({
            ...prevState, returnCollectMethod: method
        }));
    }

    const onAgreeCheck = (e) => {
        setAgreeCheck(e.target.checked);
    }

    const deliveryCompanySelect = async () => {
        try {
            const response = await fetch('https://apis.tracker.delivery/carriers');
            const data = await response.json();

            // console.log('delivery : ', data);
            setDeliveryCompanies(data);
        } catch (error) {
            console.error('Error fetching delivery companies:', error);
        }
    };

    const copyToClipboard = () => {
        const textToCopy = `${sellerRefundAddress.dtShippingAddress}, ${sellerRefundAddress.dtShippingAddressDetail}`;
        navigator.clipboard.writeText(textToCopy).then(() => {
            alert('반품주소지가 클립보드에 복사되었습니다.');
        }).catch(err => {
            console.error('Failed to copy: ', err);
        });
    };

    const handleDeliveryCompanyChange = (value) => {
        setReturnContent(prevState => ({
            ...prevState, returnDeliveryCompany: value
        }));
    };

    const onDeliveryNumber = (e) => {
        setReturnContent(prevState => ({
            ...prevState, returnDeliveryNumber: e.target.value
        }));
    }

    const calculateRefundAmount = () => {
        let refundAmount = 0;
        if (activeRefundState === 'buyer' && refundMethodSelect === 'type2') {
            refundAmount = parseInt(orderInfo.paymentPrice) - orderInfo.deliveryPrice;
        } else if (activeRefundState === 'buyer' && refundMethodSelect === 'type1') {
            refundAmount = parseInt(orderInfo.paymentPrice) - (orderInfo.deliveryPrice * 2);
        } else if (activeRefundState === 'seller') {
            refundAmount = parseInt(orderInfo.paymentPrice);
        }
        setReturnContent(prevState => ({
            ...prevState, refundAmount
        }));
    };

    const returnCheck = () => {
        return returnContent.returnType !== '' && 
        returnContent.returnContent !== '' && 
        returnContent.returnCollectMethod !== '' && 
        returnContent.refundAmount !== 0 && 
        agreeCheck &&
        returnDeliveryPriceSelect !== ''
    }

    const onReturnAdd = async () => {
        // console.log('returnContent : ', returnContent);
        MySwal.fire({
            customClass: {
                popup: `${dailyduck.order_cancel_swal_popup}`
            },
            html : 
            `
                <div>
                    <div class='${dailyduck.order_cancel_title}'><span>반품을 신청 하시겠습니까?</span></div>
                    <div class='${dailyduck.order_cancel_btn_box} py-2 d-flex w-100 justify-content-between'>
                      <button class='mx-1' id='seller_add_cancel'>아니요</button>
                      <button class='mx-1' id='seller_add_success'>네</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });
        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);

        async function sellerAddSuccess(e) {
            if(e.target && e.target.id == 'seller_add_success') {
                try {
                    const response = await auth.returnAdd(returnContent);
                    const data = response.data;
            
                    // console.log(data);
        
                    if(data === 'SUCCESS') {
                        MySwal.fire({
                            customClass: {
                                popup: `${dailyduck.order_cancel_swal_popup}`
                            },
                            html : 
                            `
                            <div>
                            <div class='${dailyduck.order_cancel_title}'><span>반품 신청이 완료 되었습니다.</span></div>
                            <div class='${dailyduck.order_cancel_sub_title}'><span>반품 신청 후 반품 완료 시점에 금액이 환불됩니다.</span></div>
                            <div class='${dailyduck.order_cancel_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);

                        
                        function sellerAddConfirm(e) {
                            if(e.target && e.target.id == 'seller_add_confirm') {
                                MySwal.close();
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                                document.removeEventListener('click', sellerAddConfirm);
                                navigate(-1);
                            }
                        }
                    }
                    
                } catch (error) {
                    console.error('cancel error : ', error);
                }
                
                
            }
        }
        
        function sellerAddCancel(e) {
            if(e.target && e.target.id == 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
            }
        }

    }

    const onReturnOption = (value) => {
        // console.log(value);
        setReturnDeliveryPriceSelect(value);
        let optionName = '';
        if(value === 'option1') {
            optionName = '환불금에서 차감';
            setReturnContent({
                ...returnContent, returnSelect : optionName
            })
        }
        if(value === 'option2') {
            optionName = '판매자에게 송금';
            setReturnContent({
                ...returnContent, returnSelect : optionName, refundAmount : (parseInt(orderInfo.paymentPrice) - orderInfo?.deliveryPrice )
            })
        }
        if(value === 'option3') {
            optionName = '수거시 박스에 동봉';
            setReturnContent({
                ...returnContent, returnSelect : optionName, refundAmount : (parseInt(orderInfo.paymentPrice) - orderInfo?.deliveryPrice )
            })
        }

    }

    return (
        <>
            <div className={`${dailyduck.myrefund_container}`}>
                <div className={dailyduck.myrefund_box}>
                    <div className={dailyduck.my_refund_header}>
                        <div>
                            <button onClick={() => handleMyRefundBack()} className='reservation_pay_close'>
                                <span className="material-symbols-outlined">arrow_back_ios</span>
                            </button>
                        </div>
                        <div>
                            <div className='text-center py-4'>
                                <span>반품 신청</span>
                            </div>
                        </div>
                    </div>
                    <div className={`${dailyduck.my_order_exchange_content}`}>
                        <div className=''>
                            <div>
                                <div className={`d-flex pb-3 ${dailyduck.my_order_detail_box}`}>
                                    <div className={`${dailyduck.my_exchange_img}`}><img src={orderInfo.paymentImg} alt="앨범이미지" /></div>
                                    <div className={`${dailyduck.my_order_detail_info}`}>
                                        <div><span>{orderInfo.shopName}</span></div>
                                        <div><span>{orderInfo.paymentProductName}</span></div>
                                        <div>
                                            {
                                                orderInfo.options.map((option, index) => (
                                                    <div key={index}><span>{option.selectionOptionName} :</span><span> {option.selectionOptionValue} /</span><span> {option.quantity}개</span></div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`${dailyduck.my_order_cancel_content_cancellation}`}>
                                <div><span>반품 사유</span></div>
                                <div>
                                    <div><button className={activeRefundState === 'buyer' ? dailyduck.my_order_cancel_content_cancellation_active : ''} onClick={() => {setActiveRefundState('buyer'); setReturnContent({...returnContent, returnType : '구매자 책임 사유', returnContent : ''}); setBuyerSelect(''); setSellerSelect('')}}>구매자 책임 사유</button></div>
                                    <div><button className={activeRefundState === 'seller' ? dailyduck.my_order_cancel_content_cancellation_active : ''} onClick={() => {setActiveRefundState('seller'); setReturnContent({...returnContent, returnType : '판매자 책임 사유', returnContent : ''}); setBuyerSelect(''); setSellerSelect('')}}>판매자 책임 사유</button></div>
                                    <div><button className={activeRefundState === 'direct' ? dailyduck.my_order_cancel_content_cancellation_active : ''} onClick={() => {setActiveRefundState('direct'); setReturnContent({...returnContent, returnType : '사유 직접 입력', returnContent : ''}); setBuyerSelect(''); setSellerSelect('')}}>사유 직접 입력</button></div>
                                </div>
                                <div>
                                    {
                                        activeRefundState === 'buyer' &&
                                        <div className={`${dailyduck.my_order_cancellation_box}`}><button onClick={() => setShowBuyerBox(true)}>{buyerSelect === '' ? '반품 사유를 선택해주세요.' : buyerSelect}</button></div>
                                    }
                                    {
                                        activeRefundState === 'seller' &&
                                        <div className={`${dailyduck.my_order_cancellation_box}`}><button onClick={() => setShowSellerBox(true)}>{sellerSelect === '' ? '반품 사유를 선택해주세요.' : sellerSelect}</button></div>
                                    }
                                    {
                                        activeRefundState === 'direct' &&
                                        <div className={`${dailyduck.my_order_cancellation_box}`}><input type="text" placeholder='반품 사유를 입력해주세요.' onChange={(e) => onDirectRefund(e)} /></div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={`${dailyduck.my_refund_method_box}`}>
                            <div>
                                <span>반품 방법</span>
                            </div>
                            <div>
                                <div className='py-2'><input type="radio" name="exchange_type" id="type1" onChange={(e) => onRefundMethod(e)} /><label className='ps-1' htmlFor='type1'>수거를 신청할게요.</label></div>
                                <div className='py-2'><input type="radio" name="exchange_type" id="type2" onChange={(e) => onRefundMethod(e)} /><label className='ps-1' htmlFor='type2'>직접 보낼게요.</label></div>
                            </div>
                            {
                                refundMethodSelect === 'type1' &&
                                <div className={`${dailyduck.my_refund_method_seller_refund}`}>
                                    <div>
                                        <div><span>판매자가 수거 신청을 합니다.</span></div>
                                        <div><span>* 상품 수거장소와 성함, 연락처를 확인해주세요.</span></div>
                                    </div>
                                    <div>
                                        <div className={`${dailyduck.my_refund_method_info_box}`}>
                                            <div><span>이름</span></div>
                                            <div><span>{orderInfo.deliveryUser}</span></div>
                                        </div>
                                        <div className={`${dailyduck.my_refund_method_info_box}`}>
                                            <div><span>연락처</span></div>
                                            <div><span>{orderInfo.deliveryPhone}</span></div>
                                        </div>
                                        <div className={`${dailyduck.my_refund_method_info_box}`}>
                                            <div><span>상품 수거장소</span></div>
                                            <div><span>{orderInfo.deliveryAddress}</span></div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                refundMethodSelect === 'type2' &&
                                <div className={`${dailyduck.my_refund_method_buyer_refund}`}>
                                    <div>
                                        <div><span>구매자가 직접 택배를 보내야 합니다.</span></div>
                                        <div><span>* 보내는 택배사, 운송장번호를 정확히 기재하시고 반품지를 확인해 주세요.</span></div>
                                    </div>
                                    <div>
                                        <div>
                                            <Select
                                                className={`${dailyduck.refund_method_delivery_select}`}
                                                placeholder='택배사 선택'
                                                onChange={(value) => handleDeliveryCompanyChange(value)}
                                            >
                                                {deliveryCompanies.map((company) => (
                                                    <Option key={company.id} value={company.name}>
                                                        {company.name}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </div>
                                        <div className='mt-1'>
                                            <input
                                                type="number"
                                                placeholder='운송장번호'
                                                onChange={(e) => onDeliveryNumber(e)}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <div><span>반품주소지</span></div>
                                            <div><span>{sellerRefundAddress?.dtShippingAddress} {sellerRefundAddress?.dtShippingAddressDetail}</span></div>
                                        </div>
                                        <div><button onClick={copyToClipboard}>반품주소지 복사</button></div>
                                    </div>
                                </div>
                            }
                        </div>
                        {
                            activeRefundState === 'buyer' &&
                            <div className={`${dailyduck.my_order_return_delivery_payment_info_box}`}>
                                <div><span>반품 배송비 결제</span></div>
                                <div>
                                    <Select 
                                        onChange={(value) => onReturnOption(value)} 
                                        placeholder={`반품 배송비 결제 선택`}
                                        className={`${dailyduck.my_order_return_delivery_payment_select}`}
                                    >
                                        <Option value="option1">환불금에서 차감</Option>
                                        <Option value="option2">판매자에게 송금</Option>
                                        <Option value="option3">수거시 박스에 동봉</Option>
                                    </Select>
                                </div>
                            </div>
                        }
                        <div className={`${dailyduck.my_order_cancel_payment_refund_method}`}>
                            <div><span>반품 완료 후 환불방법</span></div>
                            {
                                orderInfo.paymentType === '카드' &&
                                <div><span>카드결제 취소</span></div>
                            }
                        </div>
                        <div className={`${dailyduck.my_order_cancel_payment_info_box}`}>
                            <button className={`${dailyduck.my_order_cancel_payment_info_drop_down}`} onClick={() => toggleOpen()}>
                                <div><span>결제정보</span></div>
                                <div><span className="material-symbols-outlined">{openStates ? 'arrow_drop_up' : 'arrow_drop_down'}</span></div>
                            </button>
                            <CSSTransition in={!openStates} timeout={500} classNames="review" unmountOnExit>
                                <div className={`${dailyduck.my_order_cancel_payment_info_drop_down_info}`}>
                                    <div>
                                        <div className={`d-flex justify-content-between py-1`}>
                                            <div><span>총 상품금액</span></div>
                                            <div><span>{calculateTotalPrice()}원</span></div>
                                        </div>
                                        <div className={`d-flex justify-content-between py-1`}>
                                            <div><span> 배송비</span></div>
                                            <div><span>{orderInfo.deliveryPrice.toLocaleString()}원</span></div>
                                        </div>
                                        <div className={`d-flex justify-content-between py-1`}>
                                            <div><span>포인트</span></div>
                                            <div><span>(-) {orderInfo.point.toLocaleString()}원</span></div>
                                        </div>
                                        <div className={`d-flex justify-content-between py-1`}>
                                            <div><span>쿠폰</span></div>
                                            <div><span>(-) {orderInfo.coupon.toLocaleString()}원</span></div>
                                        </div>
                                        <div className={`d-flex justify-content-between py-1`}>
                                            <div><span>결제수단</span></div>
                                            <div><span>{orderInfo.paymentType}</span></div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className={`d-flex justify-content-between`}>
                                            <div><span>총 결제금액</span></div>
                                            <div><span>{parseInt(orderInfo.paymentPrice).toLocaleString()}원</span></div>
                                        </div>
                                    </div>
                                </div>
                            </CSSTransition>
                        </div>
                        {
                            returnDeliveryPriceSelect !== '' && refundMethodSelect !== '' &&
                            <div className={`${dailyduck.my_order_return_delivery_price_select_box}`}>
                                <div className={`${dailyduck.my_order_return_delivery_price_select_title}`}><span>환불정보</span></div>
                                {
                                    returnDeliveryPriceSelect === 'option1' &&
                                    <div className={`${dailyduck.my_order_return_delivery_price_select_first_option}`}>
                                        <div>
                                            <div>
                                                <div><span>총 결제금액</span></div>
                                                <div><span>{parseInt(orderInfo.paymentPrice).toLocaleString()}원</span></div>
                                            </div>
                                            <div>
                                                <div><span>{activeRefundState === 'buyer' && refundMethodSelect === 'type1' ? '왕복배송비' : '배송비'}</span></div>
                                                <div><span>{activeRefundState === 'buyer' && refundMethodSelect === 'type1' ? `(-) ${(orderInfo.deliveryPrice * 2).toLocaleString()}` : `-${orderInfo.deliveryPrice.toLocaleString()}`}원</span></div>
                                            </div>
                                        </div>
                                        <div>
                                            <div><span>총 환불금액</span></div>
                                            {
                                                activeRefundState === 'buyer' && refundMethodSelect === 'type2' &&
                                                <div><span>{((parseInt(orderInfo.paymentPrice) - orderInfo.deliveryPrice)).toLocaleString()}원</span></div>
                                            }
                                            {
                                                activeRefundState === 'buyer' && refundMethodSelect === 'type1' &&
                                                <div><span>{((parseInt(orderInfo.paymentPrice) - (orderInfo.deliveryPrice * 2))).toLocaleString()}원</span></div>
                                            }
                                            {
                                                activeRefundState === 'seller' && 
                                                <div><span>{((parseInt(orderInfo.principalAmount) + (orderInfo.deliveryPrice))).toLocaleString()}원</span></div>
                                            }
                                        </div>
                                    </div>
                                }
                                {
                                    returnDeliveryPriceSelect === 'option2' &&
                                    <div className={`${dailyduck.my_order_return_delivery_price_select_second_option}`}>
                                        <div>
                                            <div><span>입금은행</span></div>
                                            <div>
                                                <div><img src={`${process.env.PUBLIC_URL}${shopInfo.shopBankImg}`} alt="은행이미지" /></div>
                                                <div><span>{shopInfo.shopBankName}</span></div>
                                            </div>
                                        </div>
                                        <div>
                                            <div><span>입금계좌</span></div>
                                            <div>
                                                <div><span>{shopInfo.shopAccountNumber}</span></div>
                                                <div className='ps-2'><button><span className="material-symbols-outlined">content_copy</span></button></div>
                                            </div>
                                        </div>
                                        <div>
                                            <div><span>예금주명</span></div>
                                            <div><span>{shopInfo.shopDepositorName}</span></div>
                                        </div>
                                        <div>
                                            <div><span>입금금액</span></div>
                                            <div>
                                            {
                                                activeRefundState === 'buyer' &&
                                                <div><span>{(orderInfo.deliveryPrice).toLocaleString()}원</span></div>
                                            }
                                            {
                                                activeRefundState === 'seller' && 
                                                <div><span>{`0`}원</span></div>
                                            }
                                            </div>
                                        </div>
                                        <div>
                                            <div><span>환불금액</span></div>
                                            {
                                                activeRefundState === 'buyer' &&
                                                <div><span>{(parseInt(orderInfo.paymentPrice) - orderInfo.deliveryPrice).toLocaleString()}원</span></div>
                                            }
                                        </div>
                                        <div>
                                            <div><span>*반품 비용 입금시 계좌번호와 예금주를 확인하시고 입금해 주세요.</span></div>
                                        </div>
                                    </div> 
                                }
                                {
                                    returnDeliveryPriceSelect === 'option3' &&
                                    <div className={`${dailyduck.my_order_return_delivery_price_select_third_option}`}>
                                        <div>
                                            <div>
                                                <div><span>총 결제금액</span></div>
                                                <div><span>{parseInt(orderInfo.paymentPrice).toLocaleString()}원</span></div>
                                            </div>
                                            <div>
                                                <div><span>배송비</span></div>
                                                <div><span>(-) {orderInfo.deliveryPrice.toLocaleString()}원</span></div>
                                            </div>
                                            <div>
                                                <div><span>동봉금액</span></div>
                                                <div><span>{orderInfo.deliveryPrice.toLocaleString()}원</span></div>
                                            </div>
                                        </div>
                                        <div>
                                            <div><span>총 환불금액</span></div>
                                            <div><span>{((parseInt(orderInfo.paymentPrice) - orderInfo.deliveryPrice)).toLocaleString()}원</span></div>
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                        <div className={`${dailyduck.my_order_cancel_precautions}`}>
                            <div><button>&#183; 반품 신청시 주의사항</button></div>
                            <div><button>&#183; 반품 완료 후 환불 안내사항</button></div>
                        </div>
                        <div className={`${dailyduck.my_order_cancel_precautions_agree}`}>
                            <div><input type="checkbox" name="" id="agree" onChange={(e) => onAgreeCheck(e)} /></div>
                            <div><label htmlFor='agree'>주의 사항을 읽고 동의합니다.</label></div>
                        </div>
                    </div>
                    <div className={`${dailyduck.myrefund_btn} px-3`}>
                        <div className='py-4'>
                            <button className={returnCheck() ? dailyduck.my_return_btn_active : ''} onClick={() => onReturnAdd()} disabled={!returnCheck()}>반품신청하기</button>
                        </div>
                    </div>
                </div>
            </div>
            {
                showBuyerBox &&
                <div className={`${dailyduck.my_order_bottom_modal}`}>
                    {transitions((style, item) =>
                        item ?
                            <animated.div className={dailyduck.my_order_bottom_modal_container} style={style}>
                                <div className={dailyduck.my_order_bottom_modal_header}>
                                    <div><span>구매자 반품 사유 선택</span></div>
                                    <div><button onClick={() => setShowBuyerBox(false)}><span className="material-symbols-outlined">close</span></button></div>
                                </div>
                                <div className={dailyduck.my_order_bottom_modal_content}>
                                    <div><button className={buyerSelect === '배송지연' ? dailyduck.my_order_bottom_modal_content_active : ''} onClick={() => onBuyerSelectBtn('배송지연')}>배송지연</button></div>
                                    <div><button className={buyerSelect === '단순변심' ? dailyduck.my_order_bottom_modal_content_active : ''} onClick={() => onBuyerSelectBtn('단순변심')}>단순변심</button></div>
                                    <div><button className={buyerSelect === '상품 오주문' ? dailyduck.my_order_bottom_modal_content_active : ''} onClick={() => onBuyerSelectBtn('설명과 다른 제품')}>설명과 다른 제품</button></div>
                                    <div><button className={buyerSelect === '상품 불만족' ? dailyduck.my_order_bottom_modal_content_active : ''} onClick={() => onBuyerSelectBtn('가격 문제')}>가격 문제</button></div>
                                    <div><button className={buyerSelect === '중복 주문' ? dailyduck.my_order_bottom_modal_content_active : ''} onClick={() => onBuyerSelectBtn('중복 주문')}>중복 주문</button></div>
                                    <div><button className={buyerSelect === '주문실수' ? dailyduck.my_order_bottom_modal_content_active : ''} onClick={() => onBuyerSelectBtn('주문실수')}>주문실수</button></div>
                                    {/* <div><button className={buyerSelect === '색상 / 크기 문제' ? dailyduck.my_order_bottom_modal_content_active : ''} onClick={() => onBuyerSelectBtn('색상 / 크기 문제')}>색상 / 크기 문제</button></div>
                                    <div><button className={buyerSelect === '결제문제' ? dailyduck.my_order_bottom_modal_content_active : ''} onClick={() => onBuyerSelectBtn('결제문제')}>결제문제</button></div> */}
                                </div>
                            </animated.div>
                            : null
                    )}
                </div>
            }
            {
                showSellerBox &&
                <div className={`${dailyduck.my_order_bottom_modal}`}>
                    {sellerTransitions((style, item) =>
                        item ?
                            <animated.div className={dailyduck.my_order_bottom_modal_container} style={style}>
                                <div className={dailyduck.my_order_bottom_modal_header}>
                                    <div><span>판매자 반품 사유 선택</span></div>
                                    <div><button onClick={() => setShowSellerBox(false)}><span className="material-symbols-outlined">close</span></button></div>
                                </div>
                                <div className={dailyduck.my_order_bottom_modal_content}>
                                    <div><button className={sellerSelect === '상품불량' ? dailyduck.my_order_bottom_modal_content_active : ''} onClick={() => onSellerSelectBtn('상품불량')}>상품불량</button></div>
                                    <div><button className={sellerSelect === '오배송' ? dailyduck.my_order_bottom_modal_content_active : ''} onClick={() => onSellerSelectBtn('잘못된 상품 배송')}>잘못된 상품 배송</button></div>
                                    <div><button className={sellerSelect === '상품 불일치' ? dailyduck.my_order_bottom_modal_content_active : ''} onClick={() => onSellerSelectBtn('파손된 상품 배송')}>파손된 상품 배송</button></div>
                                    <div><button className={sellerSelect === '상품 누락' ? dailyduck.my_order_bottom_modal_content_active : ''} onClick={() => onSellerSelectBtn('상품 누락')}>상품 누락</button></div>
                                    <div><button className={sellerSelect === '배송 지연' ? dailyduck.my_order_bottom_modal_content_active : ''} onClick={() => onSellerSelectBtn('배송 지연')}>배송 지연</button></div>
                                    <div><button className={sellerSelect === '잘못된 상품 설명' ? dailyduck.my_order_bottom_modal_content_active : ''} onClick={() => onSellerSelectBtn('잘못된 상품 설명')}>잘못된 상품 설명</button></div>
                                    {/* <div><button className={sellerSelect === '고객 서비스 불만' ? dailyduck.my_order_bottom_modal_content_active : ''} onClick={() => onSellerSelectBtn('고객 서비스 불만')}>고객 서비스 불만</button></div>
                                    <div><button className={sellerSelect === '허위 광고' ? dailyduck.my_order_bottom_modal_content_active : ''} onClick={() => onSellerSelectBtn('허위 광고')}>허위 광고</button></div> */}
                                </div>
                            </animated.div>
                            : null
                    )}
                </div>
            }
        </>
    )
}

export default Refund;
