import React, { useState, useEffect, useContext } from 'react';
import { useSpring, animated } from 'react-spring';
import Select from "react-select";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as auth from '../../apis/auth';
import * as support from '../../apis/support';
import { format } from 'date-fns';
import axios from 'axios';
import ss from '../../containers/market/support/venue/support_shop.module.css';
import { LoginContext } from '../../contexts/LoginContextProvider';
import PaymentInfo from '../market/support/info/PaymentInfo';
import { loadTossPayments, ANONYMOUS } from '@tosspayments/tosspayments-sdk';

const generateRandomString = () => window.btoa(Math.random()).slice(0, 20);

const MessagePaymentContainer = () => {
    const navigate = useNavigate();
    const { userInfo } = useContext(LoginContext);
    const location = useLocation();
    const msg = location.state?.msg || [];
    // const paymentNumber = location.state?.payment || [];
    const { no: paymentNumber } = useParams();
    const [ user, setUser ] = useState();
    const [ activeButton, setActiveButton ] = useState('');
    const [ bankSelect, setBankSelect ] = useState('');
    const [ cashReceipt, setCashReceipt ] = useState('');
    const [ cashReceiptType, setCashReceiptType ] = useState('');
    const [ paymentMethod, setPaymentMethod ] = useState('');
    const [exchangeRate, setExchangeRate] = useState(null);
    const [priceInUSD, setPriceInUSD] = useState(0);

    const [selectedPayment, setSelectedPayment] = useState(null);
    const [point, setPoint] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [isChecked, setIsChecked] = useState(false);
    const [selectedInfo, setSelectedInfo] = useState(null);
    const totalPoint = user?.userPoint || 0;
    const [price, setPrice] = useState(0);
    const [couponDiscount, setCouponDiscount] = useState(0);
    const [ couponList, setCouponList ] = useState([]);

    const [ready, setReady] = useState(false);
    const [widgets, setWidgets] = useState(null);
    const [amount, setAmount] = useState({
        currency: "KRW",
        value: 50000,
    });

    const [options, setOptions] = useState([]);
    const [availableCoupons, setAvailableCoupons] = useState(0);
    const [selectionCoupon, setSelectionCoupon] = useState(null);


    useEffect(() => {
        if (ready) {
            widgets.setAmount({
                currency: "KRW",
                value: totalPrice,
            });
        }
    }, [totalPrice, ready]);

    useEffect(() => {
        const fetchPaymentWidgets = async () => {
            const tossPayments = await loadTossPayments(process.env.REACT_APP_TOSS_CLIENT_KEY);
            const widgets = tossPayments.widgets({ customerKey: ANONYMOUS });
            setWidgets(widgets);
        };

        fetchPaymentWidgets();
        couponSelect();
    }, []);

    const couponSelect = async () => {
        const response = await auth.userCouponSelect(userInfo && userInfo.userId);
        const data = response.data;

        // console.log(data);
        setCouponList(data);
    };

    useEffect(() => {
        if (couponList.length > 0) {
            const availableCouponsList = couponList.filter(coupon => !coupon.userCouponUse && price >= coupon.couponDiscountCondition);
            const couponOptions = availableCouponsList.map(coupon => ({
                label: coupon.couponName,
                value: {
                    couponNo: coupon.couponNo,
                    couponNumber: coupon.couponNumber,
                    couponName: coupon.couponName,
                    couponRate: coupon.couponBenefitType === 'rate' ? coupon.couponBenefitNum : 0,
                    couponPrice: coupon.couponBenefitType === 'amount' ? coupon.couponBenefitNum : 0,
                    userId: userInfo.userId,
                    couponExpirationPeriod: coupon.couponEndDate,
                    couponApprovalDate: coupon.couponRegDate,
                    minPrice: coupon.couponDiscountCondition,
                    rateMaxPrice: coupon.couponMaximumDiscount,
                }
            }));
            setOptions([{ label: "사용안함", value: { couponNo: 1, couponName: "사용안함", couponRate: 0, couponPrice: 0 } }, ...couponOptions]);
            setAvailableCoupons(availableCouponsList.length);
        }
    }, [couponList, price, userInfo.userId]);

    useEffect(() => {
        const renderPaymentWidgets = async () => {
            if (widgets == null) {
                return;
            }

            try {
                await widgets.setAmount(amount);

                await widgets.renderPaymentMethods({
                    selector: "#payment-method",
                    variantKey: "DEFAULT",
                });

                await widgets.renderAgreement({
                    selector: "#agreement",
                    variantKey: "AGREEMENT",
                });

                setReady(true);
            } catch (error) {
                if (error.name === 'AlreadyWidgetRenderedError') {
                    widgets.cleanup();
                    await renderPaymentWidgets();
                } else {
                    console.error(error);
                }
            }
        };

        renderPaymentWidgets();
    }, [widgets]);

    useEffect(() => {
        userSelect();
    }, [userInfo]);

    useEffect(() => {
        // console.log('msg : ', msg);
        // console.log('paymentNumber : ', paymentNumber);
    }, []);

    useEffect(() => {
        if (exchangeRate !== null) {
            setPriceInUSD((totalPrice / exchangeRate).toFixed(2));
        }
    }, [totalPrice, exchangeRate]);

    const fetchExchangeRate = async () => {
        try {
            const response = await axios.get('https://api.exchangerate-api.com/v4/latest/USD'); // USD 기준 환율 정보를 가져올 API
            setExchangeRate(response.data.rates.KRW);
        } catch (error) {
            console.error("환율 정보를 가져오는 중 오류 발생", error);
        }
    };

    useEffect(() => {
        fetchExchangeRate();
    }, []);

    const userSelect = async () => {
        if (userInfo?.userId) {
            const response = await auth.userselect(userInfo.userId);
            const data = response.data;

            // console.log(data);
            setUser(data);
        }
    };

    const formatDate = (date) => {
        return format(new Date(date), 'yyyy-MM-dd');
    };

    const handleClick = (payment) => {
        setSelectedPayment(payment);
    };

    const handleBack = () => {
        setSelectedInfo(null);
    };

    const calculateTotalPrice = () => {
        if (msg.length === null) return 0;
        let total = msg.paymentPrice;
        
        return total;
    };

    useEffect(() => {
        setTotalPrice(calculateTotalPrice() - point - couponDiscount);
        setPrice(calculateTotalPrice());
    }, [point, couponDiscount, msg]);

    const [isToggled, setToggle] = useState(false);
    const animation = useSpring({
        height: isToggled ? "150px" : "0px",
        overflow: "hidden",
        display: "flex",
        paddingTop: isToggled ? "1.5rem" : "0rem",
        paddingBottom: isToggled ? "1.5rem" : "0rem",
    });

    const handleCouponChange = (selectedOption) => {
        const selectedCoupon = selectedOption.value;
        if (price < selectedCoupon.minPrice) {
            alert(`현재 금액이 ${selectedCoupon.minPrice.toLocaleString()}원 보다 작아 이 쿠폰을 사용할 수 없습니다.`);
            setCouponDiscount(0);
            return;
        }

        let discount = 0;
        if (selectedCoupon.couponRate > 0) {
            discount = Math.min(price * (selectedCoupon.couponRate / 100), selectedCoupon.rateMaxPrice);
        } else if (selectedCoupon.couponPrice > 0) {
            discount = selectedCoupon.couponPrice;
        }
        setCouponDiscount(discount);
        setSelectionCoupon(selectedCoupon);
    };

    const handlePoint = (e) => {
        const value = e.target.value ? parseInt(e.target.value) : 0;
        const maxPointUsage = Math.min(value, totalPoint, calculateTotalPrice());
    
        if (value > maxPointUsage) {
            alert(`사용할 수 있는 최대 포인트는 ${maxPointUsage}P 입니다.`);
            setPoint(maxPointUsage);
        } else {
            setPoint(maxPointUsage);
        }
    };
    const handleUseAll = () => {
        const maxPointUsage = Math.min(totalPoint, calculateTotalPrice());
        setPoint(maxPointUsage);
    };

    const [cardInfo, setCardInfo] = useState({
        cardNumber: '',
        expiry: '',
        birth: '',
        pwd2Digit: ''
    });

    const [displayExpiry, setDisplayExpiry] = useState('');

    const handleInputChange = (field) => (e) => {
        let { value } = e.target;

        if (field === 'cardNumber') {
            value = value.replace(/[^0-9]/g, '').replace(/(\d{4})(?=\d)/g, '$1-');
        }

        if (field === 'expiry') {
            value = value.replace(/[^0-9]/g, '').replace(/(\d{2})(\d{4})/, '$1-$2');
            setDisplayExpiry(value);
        }

        if (field === 'birth') {
            value = value.replace(/[^0-9]/g, '').slice(0, 6);
        }

        if (field === 'pwd2Digit') {
            value = value.replace(/[^0-9]/g, '').slice(0, 2);
        }

        setCardInfo({
            ...cardInfo,
            [field]: value
        });
    };

    const handleBlur = (field) => (e) => {
        if (field === 'cardNumber') {
            const value = e.target.value.replace(/-/g, '');
            setCardInfo({
                ...cardInfo,
                [field]: value
            });
        }

        if (field === 'expiry') {
            const value = e.target.value.replace(/[^0-9]/g, '');
            if (value.length === 6) {
                const [month, year] = [value.slice(0, 2), value.slice(2)];
                setCardInfo({
                    ...cardInfo,
                    [field]: `${year}-${month}`
                });
                setDisplayExpiry(`${month}-${year}`);
            }
        }
    };

    const isCardInfoValid = () => {
        return Object.values(cardInfo).every((value) => value !== '');
    };

    const handleInfoClick = (info) => {
        setSelectedInfo(info);
    };

    useEffect(() => {
        setPaymentMethod(''); // activeButton이 변경될 때 paymentMethod 초기화
    }, [activeButton]);

    const onPayment = async () => {
        try {
            if (ready && widgets) {
                const orderId = generateRandomString();
                // const paymentKey = generateRandom(20); // paymentKey 생성

                const msgInfo = {
                    ...msg, 
                    point : point, 
                    coupon : couponDiscount, 
                    paymentNumber : paymentNumber,
                    couponNo : selectionCoupon ? selectionCoupon.couponNo : '',
                    couponName : selectionCoupon ? selectionCoupon.couponName : ''
                }

                localStorage.setItem('msg', JSON.stringify(msgInfo));

                await widgets.requestPayment({
                    orderId : orderId,
                    orderName: msg.paymentMessage,
                    customerName: user.userName,
                    customerEmail: user.userEmail,
                    successUrl: `${window.location.origin}/message/payment/successcomplete`,
                    failUrl: `${window.location.origin}/message/payment/fail`,
                });
            }
        } catch (error) {
            console.error('Payment request error:', error);
        }
    };

    const postPaymentResultToServer = async (rsp, paymentData) => {
        try {
            const response = await fetch('/api/payment', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    imp_uid: rsp.imp_uid,
                    merchant_uid: rsp.merchant_uid,
                    amount: paymentData.amount
                }),
            });
    
            const result = await response.json();
    
            // console.log('result : ', result);
            if (result.success) {
                // console.log(rsp.imp_uid);
                // console.log(rsp.merchant_uid);
                // console.log('activeButton : ', activeButton);
                // console.log('resultState : ', result.status);
                // console.log('resultVbankName : ', result.vbank_name);
                // console.log('resultVbankNum : ', result.vbank_num);
                
                const payment = paymentNumber;
                // console.log('paymentNumber = ', payment);

                // 서버 검증 성공 시 로직
                const response = await support.messagePayment(msg, rsp.imp_uid, rsp.merchant_uid, paymentData.amount, payment, point, couponDiscount, activeButton, result.vbank_name, result.vbank_num, result.vbank_date, result.status);
                const data = response.data;
    
                // console.log(data);
                
                // 가상계좌 결제 정보 사용자에게 알림
                if (result.status === 'ready') {
                    alert(`입금 확인 후 결제 완료처리 됩니다.`);
                } else {
                    alert('결제가 성공적으로 완료되었습니다.');
                    // navigate(`/payment/success/${paymentNumber}`);
                }

                navigate(`/message/payment/success/${payment}`);

            } else {
                // 서버 검증 실패 시 로직
                alert(`결제 검증에 실패하였습니다: ${result.error_msg}`);
            }
        } catch (error) {
            console.error('결제 검증 중 오류 발생', error);
            alert('결제 검증 중 오류가 발생했습니다.');
        }
    };

    return (
        <>
            <div className='payment_box'>
                <div className='d-flex justify-content-between px-3 pt-3 payment_toggle'>
                    <div><span className='bold'>결제상품</span></div>
                    <div>
                        <span>{isToggled || !msg ? '' : msg.paymentMessage}</span>
                        {isToggled ? (
                            <span className="material-symbols-outlined" onClick={() => setToggle(!isToggled)}>expand_less</span>
                        ) : (
                            <span className="material-symbols-outlined" onClick={() => setToggle(!isToggled)}>expand_more</span>
                        )}
                    </div>
                </div>
                {/* {venueDetail.length > 0 && ( */}
                    <animated.div style={animation} className='d-flex justify-content-between payment_reservation px-3'>
                        <div className='payment_img'>
                            {/* <img src={venueDetail[0].representativeImage} alt="사진" /> */}
                        </div>
                        <div className='payment_reservation_title ps-2'>
                            <div className='payment_reservation_area'><span>{msg.paymentMessage}</span></div>
                            <div className='payment_reservation_option dd-fs-7'>
                                {/* <div><span>{reservationList.length > 0 && reservationList[0].mainOptionName}</span></div>
                                {
                                    Array.isArray(reservationList) && reservationList.length > 0 && reservationList[0].options.map((option, index) => (
                                        <div key={index}><span>{option.value ? option.value : option.name}</span></div>
                                    ))
                                } */}
                            </div>
                        </div>
                    </animated.div>
                {/* )} */}
                <div className='payment_couse mt-2'></div>
                {/* {reservationList.length > 0 && ( */}
                    {/* <div className='d-flex justify-content-between align-items-center py-3 px-3 payment_reserve_date'> */}
                        {/* <div><span>예약 신청 일자</span></div> */}
                        {/* <div> */}
                            {/* <span>{formatDate(reservationList[0].dateRange[0])} ~ {formatDate(reservationList[0].dateRange[1])}</span> */}
                            {/* <span><img src="../img/icon/support/calendar.png" alt="달력" /></span> */}
                        {/* </div> */}
                    {/* </div> */}
                {/* )} */}
                <div className='payment_couse'></div>
                <div className='py-3 px-3'>
                    <div><span>포인트 사용</span></div>
                    <div className='d-flex justify-content-between pt-1 use_point_held_box'>
                        <div><span>보유 포인트</span></div>
                        <div><span>{user?.userPoint.toLocaleString()}P</span></div>
                    </div>
                    <div className='d-flex justify-content-between use_point_box'>
                        <div className='use_point_title'><span>사용할 포인트</span></div>
                        <div>
                            <div><input type="text" value={point} onChange={handlePoint} /></div>
                            <div><button onClick={handleUseAll}>전액사용</button></div>
                        </div>
                    </div>
                </div>
                <div className='payment_couse'></div>
                <div className='px-3 py-3 payment_coupon'>
                    <div><span>쿠폰 사용</span></div>
                    <div>
                        <Select
                            options={options}
                            isSearchable={false}
                            placeholder={`사용가능 쿠폰 ${availableCoupons}장 / 전체 ${availableCoupons}장`}
                            onChange={handleCouponChange}
                            styles={{
                                option: (provided) => ({
                                    ...provided,
                                    padding: 15,
                                    fontSize: '0.9em'
                                }),
                            }}
                            className='payment_coupon_select'
                        />
                    </div>
                </div>
                <div className='payment_couse'></div>
                <div className='py-3 mx-3 border-bottom payment_pay_price_box'>
                    <div><span>결제금액</span></div>
                    <div>
                        <div className='d-flex justify-content-between'>
                            <div><span>총 상품금액</span></div>
                            <div><span>{price.toLocaleString()}</span><span>원</span></div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div><span>쿠폰 할인</span></div>
                            <div><span>(-){couponDiscount.toLocaleString()}</span><span>원</span></div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div><span>포인트 사용</span></div>
                            <div><span>(-)</span>{point.toLocaleString()}<span></span><span>P</span></div>
                        </div>
                    </div>
                </div>
                <div className='py-3 px-3 d-flex justify-content-between bold'>
                    <div><span>총 결제금액</span></div>
                    <div>
                        <span>{totalPrice.toLocaleString()}</span><span>원</span>
                        {
                            paymentMethod === 'overseas' &&
                            <span>({priceInUSD} USD)</span>
                        }
                    </div>
                </div>
                <div className='payment_couse'></div>
                <div className='payment_select py-3 px-3'>
                    <div className='pb-2'><span>결제수단</span></div>
                    <div id="payment-method" className="payment_select_btn_box"></div>
                    <div id="agreement" className="w-100" />
                </div>
                <div className='payment_couse'></div>
                <div className='py-3 px-3'>
                    <div className='payment_info_box'>
                        <div className='py-1'><span className='signature_color'>· </span><span className='signature_color underline' onClick={() => handleInfoClick('개인정보 수집 및 이용')}>개인정보 수집 및 이용</span></div>
                        <div className='py-1'><span className='signature_color'>· </span><span className='signature_color underline' onClick={() => handleInfoClick('개인정보 제 3자 제공')}>개인정보 제 3자 제공</span></div>
                        <div className='py-1'><span className='signature_color'>· </span><span className='signature_color underline' onClick={() => handleInfoClick('예약상품 안내사항(취소 및 환불 사항 등)')}>예약상품 안내사항(취소 및 환불 사항 등)</span></div>
                        <div className='py-1'><span className='signature_color'>· </span><span className='signature_color underline' onClick={() => handleInfoClick('결제 전 주의사항 안내')}>결제 전 주의사항 안내</span></div>
                    </div>
                    <div className='payment_agreement py-3'>
                        <span className='pe-2'><input type="checkbox" id='agree' onChange={(e) => setIsChecked(e.target.checked)} /></span>
                        <label htmlFor='agree'>위 내용을 확인 하였으며 결제에 동의합니다.</label>
                    </div>
                </div>
                <div className='payment_couse'></div>
                <div className='py-3 px-3'>
                    <button
                        className={`payment_btn ${isChecked ? 'payment_btn_active' : ''}`}
                        disabled={!isChecked}
                        onClick={onPayment}
                    >
                        결제하기
                    </button>
                </div>
            </div>
            {selectedInfo && <PaymentInfo selectedInfo={selectedInfo} handleBack={handleBack} />}
        </>
    );
}

export default MessagePaymentContainer;