import React from 'react'

const PaymentInfo = ({handleBack, selectedInfo}) => {
  return (
    <>
        <div className='info_box'>
            <div className='d-flex justify-content-between py-4 px-2'>
                <div className='ps-3'><button className='region_close' onClick={() => handleBack()}><span className="material-symbols-outlined">arrow_back_ios</span></button></div>
                <div>{selectedInfo}</div>
                <div className='pe-3'><button className='region_close' onClick={() => handleBack()}><span className="material-symbols-outlined">close</span></button></div>
            </div>
            <div className='py-3 px-3'>
                <div className='fs-5 bold py-3'><span>{selectedInfo}</span></div>
                <div className='dd-fs-6 gray_color pb-2'><span>게시판 형태로</span></div>
                <div className='dd-fs-7'><span>작성한 상세 내용이 노출됩니다.</span></div>
            </div>
        </div>
    </>
  )
}

export default PaymentInfo