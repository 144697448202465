import React from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'; // 기본 스타일
import DashboardStyledCalendar from './DashboardStyledCalendar'; // StyledCalendar를 import

const SalesCalendar = ({ salesData, seller }) => {
    const onDayClick = (value, event) => {
        // console.log(value, event);
    };

    const formatDay = (locale, date) => date.getDate(); // "일"을 제외하고 숫자만 반환

    const tileContent = ({ date, view }) => {
        if (view === 'month') {
            // `salesData`의 날짜와 `Calendar`의 날짜를 일치시키기 위해 toDateString()으로 비교
            const salesInfo = salesData.find(d => {
                return new Date(d.date).toDateString() === date.toDateString();
            });

            if (salesInfo) {
                const salesAmount = Number(salesInfo.sales);
                const formattedSales = salesAmount.toLocaleString();

                if (salesInfo.count !== 0) {
                    return (
                        <div className={`${seller.dashboard_calendar_data_box}`}>
                            <div>
                                <span>&#8361;{formattedSales}</span>
                            </div>
                            <div>
                                <span>구매확정 {salesInfo.count}건</span>
                            </div>
                        </div>
                    );
                } else {
                    return (
                        <div className={`${seller.dashboard_calendar_no_data_box}`}>
                            <div>
                                <span>&#8361;{formattedSales}</span>
                            </div>
                        </div>
                    );
                }
            }
        }
    };

    return (
        <DashboardStyledCalendar
            onClickDay={onDayClick}
            formatDay={formatDay}
            tileContent={tileContent}
        />
    );
};

export default SalesCalendar;
