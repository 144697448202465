import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import * as auth from '../../apis/auth';
import axios from 'axios';
import store from '../../containers/market/store/store.module.css';
import admin from '../../admin/admin.module.css';
import Select from 'react-select';
import { loadTossPayments, ANONYMOUS } from '@tosspayments/tosspayments-sdk';
import PostModal from '../../components/market/store/PostModal';
import PaymentInfo from '../market/support/info/PaymentInfo';
import Country from '../../components/join/Country';
import { useSpring, animated } from 'react-spring';
import { LoginContext } from '../../contexts/LoginContextProvider';
import AddressCountry from '../../containers/my/AddressCountry';
import dailyduck from '../../containers/my/MyContainner.module.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const generateRandomString = () => window.btoa(Math.random()).slice(0, 20);

const CartPaymentContent = () => {
    const navigate = useNavigate();
    const MySwal = withReactContent(Swal);
    const { userInfo } = useContext(LoginContext);
    const context = useContext(LoginContext);
    const countries = context.countries;
    const location = useLocation();
    const storeItem = location.state?.selectedStoreItems || [];
    const supportItem = location.state?.selectedSupportItems || [];
    const totalAmount = location.state?.totalProductAmount;
    const [totalShippingFee, setTotalShippingFee] = useState(location.state?.totalShippingFee || 0);
    const [user, setUser] = useState(null);
    const [activeButton, setActiveButton] = useState('');
    const [bankSelect, setBankSelect] = useState('');
    const [cashReceipt, setCashReceipt] = useState('');
    const [cashReceiptType, setCashReceiptType] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('');
    const [exchangeRate, setExchangeRate] = useState(null);
    const [priceInUSD, setPriceInUSD] = useState(0);
    const [selectedPayment, setSelectedPayment] = useState(null);
    const [point, setPoint] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [isChecked, setIsChecked] = useState(false);
    const [selectedInfo, setSelectedInfo] = useState(null);
    const totalPoint = user?.userPoint || 0;
    const [price, setPrice] = useState(0);
    const [couponDiscount, setCouponDiscount] = useState(0);
    const [showDeliveryChange, setShowDeliveryChange] = useState(false);
    const [userDeliveryList, setUserDeliveryList] = useState([]);
    const [selectDelivery, setSelectDelivery] = useState(null);
    const [showDeliveryUpdate, setShowDeliveryUpdate] = useState(false);
    const [updateDeliveryInfo, setUpdateDeliveryInfo] = useState(null);
    const [showDeliveryAdd, setShowDeliveryAdd] = useState(false);
    const [addDeliveryInfo, setAddDeliveryInfo] = useState(null);
    const [addressCheck, setAddressCheck] = useState(false);
    const [address, setAddress] = useState('');
    const [modalVisible, setModalVisible] = useState(false);
    const [zoneCode, setZoneCode] = useState('');
    const [roadAddress, setroadAddress] = useState('');
    const [sigungu, setSigungu] = useState('');
    const [sido, setSido] = useState('');
    const [detailAddress, setDetailAddress] = useState();
    const [showCountry, setShowCountry] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredCountries, setFilteredCountries] = useState(countries);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [ couponList, setCouponList ] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState({
        img: "../../../../img/icon/country/South_Korea_(KR).png",
        name: user && user.userRepublic ? user.userRepublic : "Republic of Korea",
        number: "+82"
    });

    const [selectedPaymentType, setSelectedPaymentType] = useState('domestic');
    const [ready, setReady] = useState(false);
    const [widgets, setWidgets] = useState(null);
    const [amount, setAmount] = useState({
        currency: "KRW",
        value: 50000,
    });
    
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const [availableCoupons, setAvailableCoupons] = useState(0);
    const [selectionCoupon, setSelectionCoupon] = useState(null);
    const [showDeliveryOverseasAdd, setShowDeliveryOverseasAdd] = useState(false);
    const [internationalAddressComponent, setInternationalAddressComponent] = useState({});
    const [showAddressCompl, setShowAddressCompl] = useState(false);
    const [globalAddressSearch, setGlobalAddressSearch] = useState(false);    
    const [showDeliveryBubbleSelect, setShowDeliveryBubbleSelect] = useState(false);
    const [ showInternationalCountry, setShowInternationalCountry ] = useState(false);
    const [activeIntenationalSearch, setActiveIntenationalSearch] = useState(false);
    const [intenationalZipCode, setIntenationalZipCode] = useState('');
    const [searchTermInternational, setSearchTermInternational] = useState('');    
    const [internationalSelectedCountry, setInternationalSelectedCountry] = useState({
        img:  "",
        name: "",
        number: "",
        code: ""
    });

    useEffect(() => {
        console.log('storeItem : ', storeItem);
        console.log('supportItem : ', supportItem);
        console.log('totalAmount : ', totalAmount);
        console.log('totalShippingFee : ', totalShippingFee);
        console.log('User : ', user);
        console.log('selectDelivery : ', selectDelivery);
    }, [user, storeItem, supportItem, totalAmount, totalShippingFee, selectDelivery]);


    useEffect(() => {
        const fetchPaymentWidgets = async () => {
            const tossPayments = await loadTossPayments(process.env.REACT_APP_TOSS_CLIENT_KEY);
            const widgets = tossPayments.widgets({ customerKey: ANONYMOUS });
            setWidgets(widgets);
        };

        fetchPaymentWidgets();
        couponSelect();
        fetchExchangeRate();
        userDeliverySelect();
    }, [userInfo]);

    
    useEffect(() => {
        console.log('totalAmount:', totalAmount);
        console.log('totalShippingFee:', totalShippingFee);
        console.log('point:', point);
        console.log('couponDiscount:', couponDiscount);
    
        let shippingFee = 0;
        const addedSellers = new Set();
    
        if (selectDelivery && selectDelivery.deliveryState === '국내') {
            // 국내 배송일 경우
            storeItem.forEach((item) => {
                if (!addedSellers.has(item.sellerId)) {
                    // sellerId가 추가되지 않은 경우에만 처리
                    if (item.totalPrice >= item.deliveryInfo.dtDeliveryFree) {
                        // totalPrice가 dtDeliveryFree보다 크거나 같으면 배송비 0원
                        shippingFee += 0;
                    } else {
                        // 그렇지 않으면 dtDeliveryPrice를 더함
                        shippingFee += item.deliveryInfo.dtDeliveryPrice;
                    }
                    addedSellers.add(item.sellerId); // 해당 sellerId를 추가하여 중복 방지
                }
            });
        } else {
            // 해외 배송일 경우
            storeItem.forEach((item) => {
                if (!addedSellers.has(item.sellerId)) {
                    // sellerId가 추가되지 않은 경우에만 처리
                    shippingFee += item.deliveryInfo.overseasShippingCost;
                    addedSellers.add(item.sellerId); // 해당 sellerId를 추가하여 중복 방지
                }
            });
        }
    
        setTotalShippingFee(shippingFee);
    
        // 총 결제 금액을 계산 (상품 금액 + 배송비 - 포인트 - 쿠폰 할인)
        const calculatedTotalPrice = totalAmount + shippingFee - point - couponDiscount;
        console.log('calculatedTotalPrice:', calculatedTotalPrice);
        
        if (selectedPaymentType === 'domestic') {
            console.log('domestic calculatedTotalPrice:', calculatedTotalPrice);
            setPrice(totalAmount);
            setTotalPrice(calculatedTotalPrice);
        } else {
            // 해외 결제 시 소수점 두 자리로 변환
            const priceUSD = (totalAmount / exchangeRate).toFixed(2);
            const totalPriceUSD = (calculatedTotalPrice / exchangeRate).toFixed(2);
    
            console.log('priceUSD:', priceUSD);
            console.log('totalPriceUSD:', totalPriceUSD);
    
            setPrice(priceUSD);
            setTotalPrice(totalPriceUSD);
        }
    }, [point, couponDiscount, totalAmount, selectedPaymentType, exchangeRate, selectDelivery, storeItem]);
    
    
    

    useEffect(() => {
        if (ready) {
            if(selectedPaymentType === 'domestic') {
                console.log('useEffect domestic totalPrice : ', totalPrice);
                
                const settingPrice = (totalAmount + totalShippingFee) - point - couponDiscount;
                widgets.setAmount({
                    currency: "KRW",
                    value: settingPrice,
                });
            } else {

                console.log('useEffect oversease totalPrice : ', totalPrice);

                widgets.setAmount({
                    currency: "USD",
                    value: parseFloat(totalPrice),
                });
            }
        }
    }, [totalPrice, ready, selectedPaymentType]);

    // useEffect(() => {
    //     if (ready && widgets && totalPrice) {
    //         const amountValue = selectedPaymentType === 'domestic' ? totalPrice : parseFloat(totalPrice);
    
    //         console.log('totalPrice:', totalPrice);
    //         console.log('amountValue:', amountValue);
    //         // 금액이 유효한 숫자인지 체크 (NaN 또는 음수가 아닌지)
    //         if (!isNaN(amountValue) && amountValue > 0) {
    //             widgets.setAmount({
    //                 currency: selectedPaymentType === 'domestic' ? "KRW" : "USD",
    //                 value: amountValue,
    //             });
    //         } else {
    //             console.error('Invalid amount value:', amountValue);
    //         }
    //     }
    // }, [totalPrice, ready, selectedPaymentType, widgets]);
    

    const [isToggled, setToggle] = useState(false);
    const animation = useSpring({
        height: isToggled ? "300px" : "0px",
        overflow: "auto",
        // display: "flex",
        paddingTop: isToggled ? "1.5rem" : "0rem",
        paddingBottom: isToggled ? "1.5rem" : "0rem",
    });


    const couponSelect = async () => {
        const response = await auth.userCouponSelect(userInfo && userInfo.userId);
        const data = response.data;

        console.log(data);
        setCouponList(data);
    };

    useEffect(() => {
        if (couponList.length > 0) {
            const availableCouponsList = couponList.filter(coupon => !coupon.userCouponUse && price >= coupon.couponDiscountCondition);
            const couponOptions = availableCouponsList.map(coupon => ({
                label: coupon.couponName,
                value: {
                    couponNo: coupon.couponNo,
                    couponNumber: coupon.couponNumber,
                    couponName: coupon.couponName,
                    couponRate: coupon.couponBenefitType === 'rate' ? coupon.couponBenefitNum : 0,
                    couponPrice: coupon.couponBenefitType === 'amount' ? coupon.couponBenefitNum : 0,
                    userId: userInfo.userId,
                    couponExpirationPeriod: coupon.couponEndDate,
                    couponApprovalDate: coupon.couponRegDate,
                    minPrice: coupon.couponDiscountCondition,
                    rateMaxPrice: coupon.couponMaximumDiscount,
                }
            }));
            setOptions([{ label: "사용안함", value: { couponNo: 1, couponName: "사용안함", couponRate: 0, couponPrice: 0 } }, ...couponOptions]);
            setAvailableCoupons(availableCouponsList.length);
        }
    }, [couponList, price, userInfo]);

    useEffect(() => {
        let isMounted = true;
    
        const renderPaymentWidgets = async () => {
            if (widgets) {
                // DOM 초기화 전에 null 확인
                // const domesticElement = document.getElementById("domestic-payment-method");
                // const overseasElement = document.getElementById("overseas-payment-method");
                const pamentElement = document.getElementById("payment-method");
                const agreementElement = document.getElementById("agreement");
    
                // if (domesticElement) {
                //     domesticElement.innerHTML = '';
                // }
                // if (overseasElement) {
                //     overseasElement.innerHTML = '';
                // }
                if (agreementElement) {
                    pamentElement.innerHTML = '';
                }
                if (agreementElement) {
                    agreementElement.innerHTML = '';
                }
            }
    
            try {
                const tossPayments = await loadTossPayments(process.env.REACT_APP_TOSS_CLIENT_KEY);
                const newWidgets = tossPayments.widgets({ customerKey: ANONYMOUS });
                
                if (isMounted) {
                    setWidgets(newWidgets);
                    await newWidgets.setAmount(amount);
   
                    // if (selectedPaymentType === 'domestic') {
                    //     await newWidgets.renderPaymentMethods({
                    //         selector: "#domestic-payment-method",
                    //         variantKey: "DEFAULT",
                    //     });
                    // } else if (selectedPaymentType === 'overseas') {
                        //     await newWidgets.renderPaymentMethods({
                            //         selector: "#overseas-payment-method",
                            //         variantKey: "overseas",
                            //     });
                            // }
                            
                    await newWidgets.renderPaymentMethods({
                        selector: "#payment-method",
                        variantKey: `${selectedPaymentType === 'domestic' ? "DEFAULT" : "overseas"}`,
                    });

                    await newWidgets.renderAgreement({
                        selector: "#agreement",
                        variantKey: "AGREEMENT",
                    });
                    setReady(true);
                }
            } catch (error) {
                console.error('Error rendering widgets:', error);
            }
        };
    
        renderPaymentWidgets();
    
        return () => {
            isMounted = false;
            if (widgets) {
                setWidgets(null);  // 상태 초기화
            }
        };
    }, [selectedPaymentType, amount]);
    
    useEffect(() => {
        let isMounted = true; // 마운트 상태를 체크합니다.
    
        const renderPaymentWidgets = async () => {
            if (!widgets) return;
    
            try {
                if (isMounted) {
                    await widgets.setAmount(amount);
    
                    await widgets.renderPaymentMethods({
                        selector: "#payment-method",
                        variantKey: "DEFAULT",
                    });
    
                    await widgets.renderAgreement({
                        selector: "#agreement",
                        variantKey: "AGREEMENT",
                    });
                    setReady(true);
                }
            } catch (error) {
                console.error('Error rendering widgets:', error);
            }
        };
    
        renderPaymentWidgets();
    
        return () => {
            isMounted = false; // 컴포넌트가 언마운트될 때 마운트 상태를 false로 설정
        };
    }, [widgets]);

    const handleCountry = () => {
        setShowCountry(true);
    };

    const handleCountryClose = () => {
        setShowCountry(false);
    };

    const onDetailAddress = (e) => {
        setDetailAddress(e.target.value);
        setUpdateDeliveryInfo({
            ...updateDeliveryInfo, detailAddress: e.target.value
        });
    };

    const onGlobalDetailAddress = (e) => {
        setDetailAddress(e.target.value);
        setUpdateDeliveryInfo({
            ...updateDeliveryInfo, globalDtailAddress: e.target.value
        });
    };

    const onAddDetailAddress = (e) => {
        setDetailAddress(e.target.value);
        setAddDeliveryInfo({
            ...addDeliveryInfo, detailAddress: e.target.value
        });
    };

    function handleSelectedCountry(country) {
        if (!country) {
            return;
        }

        setSelectedCountry({
            name: country.name,
            number: country.number,
            img: country.img
        });
    }

    const onAddressSave = async () => {
        const user = {
            userId: userInfo.userId,
            zipCode: zoneCode,
            streetNameAddress: roadAddress,
            detailAddress: detailAddress,
            sigungu: sigungu,
            sido: sido,
        };

        const response = await auth.addressChange(user);
        const data = await response.data;
    };

    useEffect(() => {
        if(userInfo?.userId) {
            userSelect();
        }
    }, [userInfo]);


    useEffect(() => {
        if (exchangeRate !== null) {
            setPriceInUSD((totalPrice / exchangeRate).toFixed(2));
        }
    }, [totalPrice, exchangeRate]);

    const fetchExchangeRate = async () => {
        try {
            const response = await axios.get('https://api.exchangerate-api.com/v4/latest/USD');
            setExchangeRate(response.data.rates.KRW);
        } catch (error) {
            console.error("환율 정보를 가져오는 중 오류 발생", error);
        }
    };


    const userSelect = async () => {
        if (userInfo?.userId) {
            const response = await auth.userselect(userInfo.userId);
            const data = response.data;

            setUser(data);
        }
    };

    const userDeliverySelect = async () => {
        if (userInfo?.userId) {
            try {
                const response = await auth.userDeliverySelect(userInfo.userId);
                const data = response.data;

                setUserDeliveryList(data);
                const defaultDelivery = data.find(delivery => delivery.deliveryType === 1);
                if (defaultDelivery) {
                    setSelectDelivery(defaultDelivery);
                }
            } catch (error) {
                console.error('error : ', error);
            }
        }
    };

    // const calculateTotalPrice = () => {
    //     if (storeItem.length === 0) return 0;
    //     let total = storeItem.productSalePrice;
    //     storeItem.map((item) => {
    //         item.selectOption.forEach(option => {
    //             if (option.addPrice) {
    //                 total += option.addPrice;
    //             }
    //         });
    //     })
    //     // storeItem.selectOption.forEach(option => {
    //     //     if (option.addPrice) {
    //     //         total += option.addPrice;
    //     //     }
    //     // });
    //     return total + storeItem.deliveryPrice;
    // };
    // const calculateTotalProductPrice = () => {
    //     if (storeItem.length === 0) return 0;
    //     let total = storeItem.productSalePrice;
    //     storeItem.map((item) => {
    //         item.selectOption.forEach(option => {
    //             if (option.addPrice) {
    //                 total += option.addPrice;
    //             }
    //         });
    //     })
    //     // storeItem.selectOption.forEach(option => {
    //     //     if (option.addPrice) {
    //     //         total += option.addPrice;
    //     //     }
    //     // });
    //     return total;
    // };

    


    const handleCouponChange = (selectedOption) => {
        const selectedCoupon = selectedOption.value;
        if (price < selectedCoupon.minPrice) {
            alert(`현재 금액이 ${selectedCoupon.minPrice.toLocaleString()}원 보다 작아 이 쿠폰을 사용할 수 없습니다.`);
            setCouponDiscount(0);
            return;
        }

        let discount = 0;
        if (selectedCoupon.couponRate > 0) {
            discount = Math.min(price * (selectedCoupon.couponRate / 100), selectedCoupon.rateMaxPrice);
        } else if (selectedCoupon.couponPrice > 0) {
            discount = selectedCoupon.couponPrice;
        }
        setCouponDiscount(discount);
        setSelectionCoupon(selectedCoupon);

    };

    const handlePoint = (e) => {
        const value = e.target.value ? parseInt(e.target.value) : 0;
        if (value > totalPoint) {
            alert('보유한 포인트보다 높은 값을 입력할 수 없습니다.');
            setPoint(totalPoint);
        } else {
            setPoint(value);
        }
    };

    const handleUseAll = () => {
        setPoint(totalPoint);
    };

    const handleInfoClick = (info) => {
        setSelectedInfo(info);
    };

    const handleBack = () => {
        setSelectedInfo(null);
    };

    const addAddress = (sido, sigungu, streetNameAddress) => {
        return `${sido} ${sigungu} ${streetNameAddress}`;
    };

    const onDeliveryUpdate = (item) => {
        setUpdateDeliveryInfo(item);
        setShowDeliveryUpdate(true);
    };

    const onDeliveryDelete = async (deliveryNo) => {
        const response = await auth.deliveryDelete(deliveryNo);
        const data = response.data;

        if (data === 'SUCCESS') {
            alert('배송지가 삭제되었습니다.');
            userDeliverySelect();
        }
    };

    const onUpdateDeliverySave = async () => {
        try {
            const response = await auth.deliveryUpdate(updateDeliveryInfo);
            const data = response.data;

            if (data === 'SUCCESS') {
                userDeliverySelect();
                setShowDeliveryUpdate(false);
            }
        } catch (error) {
            console.error('error : ', error);
        }
    };

    const onAddDeliverySave = async () => {
        try {
            const response = await auth.deliveryAdd(addDeliveryInfo);
            const data = response.data;

            if (data === 'SUCCESS') {
                alert('배송지가 등록되었습니다.');
                userDeliverySelect();
                setShowDeliveryAdd(false);
            };
        } catch (error) {
            console.error('error : ', error);
        }
    };

    const handlePhoneNumber = (e) => {
        setPhoneNumber(e.target.value);

        setUpdateDeliveryInfo({
            ...updateDeliveryInfo, deliveryPhone: e.target.value
        });
    };

    const handleAddPhoneNumber = (e) => {
        setPhoneNumber(e.target.value);

        setAddDeliveryInfo({
            ...addDeliveryInfo, deliveryPhone: e.target.value
        });
    };

    const onUpdateDeliveryUser = (e) => {
        setUpdateDeliveryInfo({
            ...updateDeliveryInfo, deliveryUser: e.target.value
        });
    };

    const onUpdateDeliveryName = (e) => {
        setUpdateDeliveryInfo({
            ...updateDeliveryInfo, deliveryName: e.target.value
        });
    };

    const onAddDeliveryName = (e) => {
        setAddDeliveryInfo({
            ...addDeliveryInfo, deliveryName: e.target.value
        });
    };

    const onAddDeliveryUser = (e) => {
        setAddDeliveryInfo({
            ...addDeliveryInfo, deliveryUser: e.target.value
        });
    };

    const handleCloseModal = () => {
        if (address === '') {
            setAddressCheck(false);
        }
        setModalVisible(false);
    };

    const handleAddress = (data) => {
        const refinedRoadAddress = data.roadAddress.replace(`${data.sido} `, '').replace(`${data.sigungu} `, '');

        setZoneCode(data.zonecode);
        setSigungu(data.sigungu);
        setSido(data.sido);
        setroadAddress(refinedRoadAddress);
        setAddress(data.address);

        setUpdateDeliveryInfo((prevInfo) => ({
            ...prevInfo,
            zipcode: data.zonecode,
            sigungu: data.sigungu,
            sido: data.sido,
            streetNameAddress: refinedRoadAddress,
            detailAddress: ''
        }));

        setAddDeliveryInfo((prevInfo) => ({
            ...prevInfo,
            zipcode: data.zonecode,
            sigungu: data.sigungu,
            sido: data.sido,
            streetNameAddress: refinedRoadAddress,
            detailAddress: ''
        }));

        setTimeout(() => {
            setAddressCheck(true);
        }, 100);
    };

    function generateRandom(length) {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    useEffect(() => {
        console.log('selectionCoupon : ', selectionCoupon);
    }, [selectionCoupon])

    const onPayment = async () => {
        try {
            
            if(selectDelivery === null) {
                alert('배송지를 등록해주세요.');
                return;
            }

            if (ready && widgets) {
                const orderId = generateRandomString();
                const paymentKey = generateRandom(20); // paymentKey 생성

                // const flattenedOptions = storeItem.paymentOption.flat();
                

                // const newItem = {
                //     paymentNumber : storeItem.paymentNumber,
                //     shopName : storeItem.shopName,
                //     userId : user.userId,
                //     sellerId : storeItem.sellerId,
                //     paymentKey : '',
                //     paymentImg : storeItem.paymentImg,
                //     paymentProductName : storeItem.paymentProductName,
                //     paymentProductNumber : storeItem.paymentProductNumber,
                //     principalAmount : storeItem.principalAmount,
                //     deliveryType : storeItem.deliveryType,
                //     deliveryPrice : storeItem.deliveryPrice,
                //     deliveryCompany : '',
                //     waybillNumber : '',
                //     options : flattenedOptions,
                //     deliveryUser : selectDelivery.deliveryUser,
                //     deliveryAddress : selectDelivery.sido + ' ' + selectDelivery.sigungu + ' ' + selectDelivery.streetNameAddress + ' ' + selectDelivery.detailAddress,
                //     zipcode : selectDelivery.zipcode,
                //     deliveryPhone : selectDelivery.deliveryPhone,
                //     point : point,
                //     coupon : couponDiscount,
                //     paymentPrice : totalPrice,
                //     paymentType : activeButton,
                //     paymentBankName : '',
                //     paymentAccountNumber : '',
                //     paymentDepositDeadline : '',
                //     paymentState : '',
                //     couponNo : selectionCoupon ? selectionCoupon.couponNo : '',
                //     couponName : selectionCoupon ? selectionCoupon.couponName : ''
                // }

                localStorage.setItem('storePayment', JSON.stringify(storeItem));
                localStorage.setItem('supportPayment', JSON.stringify(supportItem));
                localStorage.setItem('point', JSON.stringify(point));
                localStorage.setItem('couponDiscount', JSON.stringify(couponDiscount));
                localStorage.setItem('totalPrice', JSON.stringify(totalPrice));
                localStorage.setItem('couponNo', JSON.stringify(selectionCoupon ? selectionCoupon.couponNo : ''));
                localStorage.setItem('couponName', JSON.stringify(selectionCoupon ? selectionCoupon.couponName : ''));
                localStorage.setItem('deliveryAddress', JSON.stringify(selectDelivery.sido + ' ' + selectDelivery.sigungu + ' ' + selectDelivery.streetNameAddress + ' ' + selectDelivery.detailAddress));

                await widgets.requestPayment({
                    orderId : orderId,
                    orderName: `${storeItem.productName} 외 3건`,
                    customerName: user.userName,
                    customerEmail: user.userEmail,
                    successUrl: `${window.location.origin}/cart/payment/successcomplete`,
                    failUrl: `${window.location.origin}/payment/fail`,
                    pendingUrl: `${window.location.origin}/payment/pending`
                });
            }
        } catch (error) {
            console.error('Payment request error:', error);
        }
    };

    const handleIntenationalCountryClose = () => {
        setShowInternationalCountry(false);
        reSet();
    }

    const reSet = () => {
        setIntenationalZipCode('');
    }

    function handleInternationalSelectedCountry(country) {
        
        if (!country) {
            // console.log('Country not defined');
            return;
        }

        setInternationalSelectedCountry({
            name: country.name,
            number: country.number,
            img: country.img,
            code: country.code
        });
    }

    // 국제 국가 선택 기능
    const handleIntenationalCountry = () => {
        setShowInternationalCountry(true);
    }

    const onInternationalZipCode = (e) => {
        let zipCode = e.target.value;

        setIntenationalZipCode(zipCode);
    }

    const addrSearch = async () => {
        if(intenationalZipCode === '' || internationalSelectedCountry.code === ''){
            alert("Please select your zip code and country.");
            return;
        }

        try {
            setLoading(true);
    
            const response = await auth.addressRequest(intenationalZipCode, internationalSelectedCountry.code);
            const data = response.data;
    
            console.log(data);
            
            if(response.status === 200) {
                setLoading(false);
                console.log('full Address : ', data);
                setInternationalAddressComponent(data);
                setShowAddressCompl(true);
    
            } else {
                alert('Address search failed.');
            }
        } catch (error) {
            console.error(error);
        }

    };
    const onInternationalDtailAddress = (e) => {
        setInternationalAddressComponent({
            ...internationalAddressComponent, detailAddress : e.target.value
        });
    }

    const handleGlobalAddressInsert = async () => {
        console.log('internationalAddressComponent : ', internationalAddressComponent);
        console.log('userId : ', userInfo.userId);
        console.log('internationalSelectedCountry : ', internationalSelectedCountry);

        // const newItem = {
        //     globalZipCode : intenationalZipCode,
        //     globalCountryCode : internationalSelectedCountry.code,
        //     globalAddress : internationalAddressComponent.fullAddress,
        //     globalDtailAddress : internationalAddressComponent.detailAddress,
        //     globalCity : internationalAddressComponent.city || internationalAddressComponent.city_district || internationalAddressComponent.town || internationalAddressComponent._normalized_city,
        //     globalState : internationalAddressComponent.state || internationalAddressComponent.province || internationalAddressComponent.county,
        //     globalCountryNumber : internationalSelectedCountry.number,
        //     userId : userInfo && userInfo?.userId
        // }

        // console.log('newItem : ', newItem);

        // const response = await auth.globalAddressUpdate(newItem);
        // const data = response.data;

        // if(data === 'SUCCESS') {
        //     setActiveIntenationalSearch(false);
        //     setShowAddressCompl(false);
        //     // fetchData();
        // }

        setUpdateDeliveryInfo((prevInfo) => ({
            ...prevInfo,
            globalZipCode : intenationalZipCode,
            globalCountryCode : internationalSelectedCountry.code,
            globalAddress : internationalAddressComponent.fullAddress,
            globalDtailAddress : internationalAddressComponent.detailAddress,
            globalCity : internationalAddressComponent.city || internationalAddressComponent.city_district || internationalAddressComponent.town || internationalAddressComponent._normalized_city,
            globalState : internationalAddressComponent.state || internationalAddressComponent.province || internationalAddressComponent.county,
            globalCountryNumber : internationalSelectedCountry.number
        }));

        setAddDeliveryInfo((prevInfo) => ({
            ...prevInfo,
            globalZipCode : intenationalZipCode,
            globalCountryCode : internationalSelectedCountry.code,
            globalAddress : internationalAddressComponent.fullAddress,
            globalDtailAddress : internationalAddressComponent.detailAddress,
            globalCity : internationalAddressComponent.city || internationalAddressComponent.city_district || internationalAddressComponent.town || internationalAddressComponent._normalized_city,
            globalState : internationalAddressComponent.state || internationalAddressComponent.province || internationalAddressComponent.county,
            globalCountryNumber : internationalSelectedCountry.number
        }));

            setActiveIntenationalSearch(false);
            setShowAddressCompl(false);
            setGlobalAddressSearch(true);
    }

    const isGlobalAddressConfirm = () => {
        return (
            addDeliveryInfo.deliveryName &&
            addDeliveryInfo.deliveryUser &&
            // addDeliveryInfo.globalAddress &&
            addDeliveryInfo.globalZipCode &&
            addDeliveryInfo.deliveryPhone 
        )
    }

    const selectedDelivery = (item) => {
        if (item.deliveryState === '해외') {
            // 조건에 맞지 않는 상품을 필터링하여 목록 생성
            const invalidItems = storeItem
                .filter((cartItem) => {
                    return !(cartItem.consignmentStatus === true || 
                             cartItem.sellerId === 'admin' || 
                             cartItem.sellerId === 'dailyduck');
                })
                .map((cartItem) => `${cartItem.shopName} - ${cartItem.productName}`);
            
            if (invalidItems.length > 0) {
                // 조건에 맞지 않는 상품이 있을 경우 경고창 표시
                MySwal.fire({
                    customClass: {
                        popup: `${admin.admin_seller_join_popup}`,
                    },
                    html: `
                        <div>
                          <div class='${admin.admin_seller_join_title}'><span>해외배송이 불가능한 상품이 포함되어 있습니다.</span></div>
                          <div class='${admin.admin_seller_join_sub_title}'><span>(This order contains products that cannot be shipped overseas.)</span></div>
                          <div class='${admin.admin_seller_join_sub_title}'><span>상품 목록:</span><br>${invalidItems.join('<br>')}</div>
                          <div class='${admin.admin_seller_join_confirm_btn_box}'>
                            <button id='address_confirm'>확인</button>
                          </div>
                        </div>
                    `,
                    showConfirmButton: false,
                    showCancelButton: false,
                });
    
                document.addEventListener('click', addressConfirm);
    
                function addressConfirm(e) {
                    if (e.target && e.target.id === 'address_confirm') {
                        MySwal.close();
                        document.removeEventListener('click', addressConfirm);
                    }
                }
            } else {
                // 모든 아이템이 조건에 부합할 때만 배송지 설정
                setSelectDelivery(item);
            }
        } else {
            setSelectDelivery(item); // 국내 배송일 경우
        }
    }

    return (
        <>
            <div className='payment_box'>
                <div className={`payment_toggle ${store.store_payment_order_product}`}>
                    <div><span className='bold'>주문상품</span></div>
                    <div className={`${store.store_order_product_title}`}>
                        <span>{isToggled || (!storeItem && !supportItem) ? '' : `${(storeItem?.length || 0) + (supportItem?.length || 0)}개 상품`}</span>
                        {isToggled ? (
                            <span className="material-symbols-outlined" onClick={() => setToggle(!isToggled)}>expand_less</span>
                        ) : (
                            <span className="material-symbols-outlined" onClick={() => setToggle(!isToggled)}>expand_more</span>
                        )}
                    </div>
                </div>
                {storeItem && (
                    <animated.div style={animation}>
                        {
                            Array.isArray(storeItem) && storeItem.map((item, index) => {

                                const options = item.selectOption;

                                console.log('options : ', options);
                                return (
                                    <div key={index} className={`${store.store_cart_payment_product_box}`}>
                                        <div className={`${store.store_cart_payment_product_img_box}`}>
                                            <img src={item.productImg} alt="사진" />
                                        </div>
                                        <div className={`${store.store_cart_payment_product__info_box}`}>
                                            <div className={`${store.store_cart_payment_product__info_name}`}><span>{item.productName}</span></div>
                                            {
                                                options.length > 0 &&
                                                <div className={`${store.store_cart_payment_product__info_option}`}>
                                                    {
                                                        Array.isArray(options) && options.length > 0 && options.map((option, index) => (
                                                            <div key={index}>
                                                                <div>
                                                                    <div><span>{option.optionName}</span></div>
                                                                    <div><span>{option.optionValue}</span></div>
                                                                </div>
                                                                <div><span>{option.quantity}개</span></div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            }
                                            <div className={`${store.store_cart_payment_product__info_price}`}>
                                                <div><span>&#8361;{item.totalPrice.toLocaleString()}</span></div>
                                            </div>
                                            <div className={`${store.store_cart_payment_product__info_quantity}`}>
                                                <div><span>총 수량 :</span></div>
                                                <div><span>&nbsp;{item.totalQuantity}</span></div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        {
                            Array.isArray(supportItem) && supportItem.map((item, index) => {

                                const options = item.optionItem;

                                console.log('options : ', options);
                                return (
                                    <div key={index} className={`${store.store_cart_payment_product_box}`}>
                                        <div className={`${store.store_cart_payment_product_img_box}`}>
                                            <img src={item.productImg} alt="사진" />
                                        </div>
                                        <div className={`${store.store_cart_payment_product__info_box}`}>
                                            <div className={`${store.store_cart_payment_product__info_name}`}><span>{item.productName}</span></div>
                                            {
                                                options.length > 0 &&
                                                <div className={`${store.store_cart_payment_support__info_option}`}>
                                                    {
                                                        Array.isArray(options) && options.length > 0 && options.map((option, index) => (
                                                            <div key={index}>
                                                                <div>
                                                                    <div><span>{option.name}</span></div>
                                                                    <div><span>{option.value}</span></div>
                                                                </div>
                                                                <div><span>{(option.price * (option.quantity ? option.quantity : 1)).toLocaleString()}원</span><span>{option.quantity ? ' - ' + option.quantity +'개' : ''}</span></div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            }
                                            <div className={`${store.store_cart_payment_product__info_price}`}>
                                                <div><span>&#8361;{item.totalPrice.toLocaleString()}</span></div>
                                            </div>
                                            <div className={`${store.store_cart_payment_product__info_quantity}`}>
                                                <div><span>총 수량 :</span></div>
                                                <div><span>&nbsp;{item.totalQuantity}</span></div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </animated.div>
                )}
                <div className='payment_couse mt-2'></div>
                <div className={`py-3 px-3 ${store.store_payment_orderer_info_box}`}>
                    <div><span>주문자 정보</span></div>
                    <div>
                        <div>
                            <div><span>이름</span></div>
                            <div><span>{user && user.userName}</span></div>
                        </div>
                        <div>
                            <div><span>이메일</span></div>
                            <div><span>{user && user.userEmail}</span></div>
                        </div>
                        <div>
                            <div><span>휴대폰</span></div>
                            <div><span>{user && user.userTel}</span></div>
                        </div>
                    </div>
                </div>
                <div className='payment_couse'></div>
                <div className={`${store.store_payment_delivery_info_box}`}>
                    <div>
                        <div><span>배송지</span></div>
                        <div><button onClick={() => setShowDeliveryChange(true)}>변경</button></div>
                    </div>
                    {
                        selectDelivery ?
                            <div>
                                <div><span>{selectDelivery.deliveryState === '국내' ? selectDelivery.deliveryName : selectDelivery.deliveryName}</span></div>
                                <div><span>{selectDelivery.deliveryState === '국내' ? selectDelivery.deliveryUser : selectDelivery.deliveryUser}</span></div>
                                <div><span>{selectDelivery.deliveryState === '국내' ? selectDelivery.zipcode : selectDelivery.globalZipCode}</span></div>
                                <div><span>{selectDelivery.deliveryState === '국내' ? addAddress(selectDelivery.sido, selectDelivery.sigungu, selectDelivery.streetNameAddress) : selectDelivery.globalAddress}</span></div>
                                <div><span>{selectDelivery.deliveryState === '국내' ? selectDelivery.detailAddress : selectDelivery.globalDetailAddress}</span></div>
                            </div>
                            :
                            <div className='py-3 text-center dd-fs-8'>
                                <span>회원 정보에 등록된 배송지가 없습니다.</span><br /><span>배송지 등록을 진행해주세요.</span>
                            </div>
                    }
                </div>
                <div className='payment_couse'></div>
                <div className='py-3 px-3'>
                    <div><span>포인트 사용</span></div>
                    <div className='d-flex justify-content-between pt-1 use_point_held_box'>
                        <div><span>보유 포인트</span></div>
                        <div><span>{user?.userPoint.toLocaleString()}P</span></div>
                    </div>
                    <div className='d-flex justify-content-between use_point_box'>
                        <div className='use_point_title'><span>사용할 포인트</span></div>
                        <div>
                            <div><input type="text" value={point} onChange={handlePoint} /></div>
                            <div><button onClick={handleUseAll}>전액사용</button></div>
                        </div>
                    </div>
                </div>
                {/* <div className='payment_couse'></div>
                <div className='px-3 py-3 payment_coupon'>
                    <div><span>쿠폰 사용</span></div>
                    <div>
                        <Select
                            options={options}
                            isSearchable={false}
                            placeholder={`사용가능 쿠폰 ${availableCoupons}장 / 전체 ${availableCoupons}장`}
                            onChange={handleCouponChange}
                            styles={{
                                option: (provided) => ({
                                    ...provided,
                                    padding: 15,
                                    fontSize: '0.9em'
                                }),
                            }}
                            className='payment_coupon_select'
                        />
                    </div>
                </div> */}
                <div className='payment_couse'></div>
                <div className='py-3 mx-3 border-bottom payment_pay_price_box'>
                    <div><span>결제금액</span></div>
                    <div>
                        <div className='d-flex justify-content-between'>
                            <div><span>총 상품금액</span></div>
                            <div><span>{price.toLocaleString()}</span><span>{selectedPaymentType === 'domestic' ? '원' : ' USD'}</span></div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div><span>배송비</span></div>
                            <div><span>(+) {selectedPaymentType === 'domestic' ? totalShippingFee.toLocaleString() + '원' : (totalShippingFee / exchangeRate).toFixed(2) + ' USD'}</span></div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div><span>쿠폰 할인</span></div>
                            <div><span>(-) {couponDiscount.toLocaleString()}</span><span>원</span></div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div><span>포인트 사용</span></div>
                            <div><span>(-) </span>{point.toLocaleString()}<span></span><span>P</span></div>
                        </div>
                    </div>
                </div>
                <div className='py-3 px-3 d-flex justify-content-between bold'>
                    <div><span>총 결제금액</span></div>
                    <div>
                        {
                            selectedPaymentType === 'domestic' &&
                            <span>{totalPrice.toLocaleString() + '원'}</span>
                        }
                        {
                            selectedPaymentType === 'overseas' &&
                            <span>{totalPrice + ' USD'} </span>
                        }
                    </div>
                </div>
                <div className='payment_couse'></div>
                <div className='payment_select py-3 px-3'>
                    <div className='pb-2'><span>결제수단</span></div>
                    <div className={`${store.store_payment_type_tap_btn}`}>
                        <div className={`${selectedPaymentType === 'domestic' ? store.store_payment_type_tap_btn_active : ''}`}><button onClick={() => setSelectedPaymentType('domestic')}>국내결제</button></div>
                        <div className={`${selectedPaymentType === 'overseas' ? store.store_payment_type_tap_btn_active : ''}`}><button onClick={() => setSelectedPaymentType('overseas')}>Overseas payment</button></div>
                    </div>
                    <div>
                        <div id="payment-method" className="payment_select_btn_box"></div>
                        <div id="agreement" className="w-100" />
                    </div>
                </div>
                <div className='payment_couse'></div>
                <div className='py-3 px-3'>
                    <div className='payment_info_box'>
                        <div className='py-1'><span className='signature_color'>· </span><span className='signature_color underline dd-fs-8' onClick={() => handleInfoClick('개인정보 수집 및 이용')}>개인정보 수집 및 이용</span></div>
                        <div className='py-1'><span className='signature_color'>· </span><span className='signature_color underline dd-fs-8' onClick={() => handleInfoClick('개인정보 제 3자 제공')}>개인정보 제 3자 제공</span></div>
                        <div className='py-1'><span className='signature_color'>· </span><span className='signature_color underline dd-fs-8' onClick={() => handleInfoClick('예약상품 안내사항(취소 및 환불 사항 등)')}>예약상품 안내사항(취소 및 환불 사항 등)</span></div>
                        <div className='py-1'><span className='signature_color'>· </span><span className='signature_color underline dd-fs-8' onClick={() => handleInfoClick('결제 전 주의사항 안내')}>결제 전 주의사항 안내</span></div>
                    </div>
                    <div className='payment_agreement py-3'>
                        <span className='pe-2'><input type="checkbox" id='agree' onChange={(e) => setIsChecked(e.target.checked)} /></span>
                        <label htmlFor='agree'>위 내용을 확인 하였으며 결제에 동의합니다.</label>
                    </div>
                </div>
                <div className='payment_couse'></div>
                <div className='py-3 px-3'>
                    <button
                        className={`payment_btn ${isChecked ? 'payment_btn_active' : ''}`}
                        disabled={!isChecked}
                        onClick={onPayment}
                    >
                        결제하기
                    </button>
                </div>
            </div>
            {selectedInfo && <PaymentInfo selectedInfo={selectedInfo} handleBack={handleBack} />}

            {showDeliveryChange &&
                <div className={`${store.store_delivery_change_modal_box}`}>
                    <div>
                        <div className={`${store.store_delivery_change_modal_header}`}>
                            <div><button onClick={() => setShowDeliveryChange(false)}><span className="material-symbols-outlined">arrow_back_ios</span></button></div>
                            <div><span>배송지 변경</span></div>
                        </div>
                        <div className={`${store.store_delivery_change_modal_content}`}>
                            {
                                Array.isArray(userDeliveryList) && userDeliveryList.map((item, index) => (
                                    <button
                                        key={index}
                                        className={`${store.store_delivery_change_modal_list_box}`}
                                        onClick={() => selectedDelivery(item)}
                                    >
                                        <div>
                                            <div>
                                                <div>
                                                    <span>{item.deliveryUser} ({item.deliveryName})</span>
                                                </div>
                                                {
                                                    item.deliveryType === 1 &&
                                                    <div><span>기본배송지</span></div>
                                                }
                                            </div>
                                            <div>
                                                <input type="radio" name="delivery" checked={selectDelivery.deliveryNo === item.deliveryNo} readOnly />
                                            </div>
                                        </div>
                                        <div><span>{item.deliveryPhone}</span></div>
                                        <div>
                                            <div><span>{item.deliveryState === '국내' ? item.zipcode : item.globalZipCode}</span></div>
                                            <div><span>{item.deliveryState === '국내' ? addAddress(item.sido, item.sigungu, item.streetNameAddress) : item.globalAddress}</span></div>
                                            <div><span>{item.deliveryState === '국내' ? item.detailAddress : item.globalDetailAddress}</span></div>
                                        </div>
                                        <div>
                                            <div><button onClick={(e) => { e.stopPropagation(); onDeliveryUpdate(item) }}>수정하기</button></div>
                                            <div><button onClick={(e) => { e.stopPropagation(); onDeliveryDelete(item.deliveryNo) }}>삭제하기</button></div>
                                        </div>
                                    </button>
                                ))
                            }
                        </div>
                        <div className={`${store.store_delivery_change_modal_footer}`}>
                            <div><button onClick={() => { setShowDeliveryAdd(true); setAddDeliveryInfo({ ...addDeliveryInfo, deliveryUserId: userInfo.userId }); }}>배송지 추가</button></div>
                            <div><button onClick={() => setShowDeliveryChange(false)}>배송지 선택</button></div>
                        </div>
                        {
                            showDeliveryBubbleSelect &&
                            <div className={`${store.store_delivery_change_modal_btn_select}`}>
                                <div className={`${store.store_delivery_change_modal_btn_bubble}`}>
                                    <div><button onClick={() => { setShowDeliveryBubbleSelect(false); setShowDeliveryAdd(true); setAddDeliveryInfo({ ...addDeliveryInfo, deliveryUserId: userInfo.userId, deliveryState: '국내' }); }}>국내 배송지</button></div>
                                    <div><button onClick={() => { setShowDeliveryOverseasAdd(true); setAddDeliveryInfo({ ...addDeliveryInfo, deliveryUserId: userInfo.userId, deliveryState: '해외' });}}>Global Address</button></div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            }

            {showDeliveryUpdate &&
                <div className={`${store.store_delivery_update_box}`}>
                    <div className={`${store.store_delivery_update_box_header}`}>
                        <div><button onClick={() => setShowDeliveryUpdate(false)}><span className="material-symbols-outlined">arrow_back_ios</span></button></div>
                        <div><span>배송지 수정</span></div>
                    </div>
                    <div className={`${store.store_delivery_update_box_content}`}>
                        <div className='myinfo_input_box'>
                            <div className='pb-1'><span>국가</span></div>
                            <div className='join_input'>
                                {!selectedCountry ?
                                    <button type="text" className='d-flex' placeholder='국가' onClick={handleCountry}>
                                        <span className='country_name_input'>국가선택</span>
                                        <span className="material-symbols-outlined">expand_more</span>
                                    </button> :
                                    <button type="text" className='d-flex' placeholder='국가' onClick={handleCountry}>
                                        <img src={selectedCountry?.img} alt={selectedCountry?.name} />
                                        <span className='country_name_input'>{selectedCountry?.name}</span>
                                        <span className="material-symbols-outlined">expand_more</span>
                                    </button>
                                }
                            </div>
                        </div>
                        <div className='py-3'>
                            <div><span>배송지이름</span></div>
                            <div><input type="text" className='join_input_tag' value={updateDeliveryInfo.deliveryName} onChange={(e) => onUpdateDeliveryName(e)} /></div>
                        </div>
                        <div className='py-3'>
                            <div><span>이름</span></div>
                            <div><input type="text" className='join_input_tag' value={updateDeliveryInfo.deliveryUser} onChange={(e) => onUpdateDeliveryUser(e)} /></div>
                        </div>
                        <div className=''>
                            <div className={`${store.store_delivery_update_address_search_box}`}>
                                <div>
                                    <span>배송지</span>
                                </div>
                                <div className='pt-1'>
                                    <button onClick={() => { setModalVisible(true); setAddressCheck(true); }}>주소검색</button>
                                </div>
                            </div>
                            <div className={`${store.adress_box}`}>
                                <div>
                                    <div className='py-1'>
                                        <input type="text" className='join_input_tag' placeholder={updateDeliveryInfo ? updateDeliveryInfo.zipcode : '우편번호'} disabled={true} />
                                    </div>
                                    <div className='py-1'>
                                        <input type="text" className='join_input_tag' placeholder={updateDeliveryInfo ? updateDeliveryInfo.sido : '특별시/광역시/도'} value={updateDeliveryInfo ? updateDeliveryInfo.sido : ''} disabled={true} />
                                    </div>
                                    <div className='py-1'>
                                        <input type="text" className='join_input_tag' placeholder={updateDeliveryInfo ? updateDeliveryInfo.sigungu : '시/군/구'} value={updateDeliveryInfo ? updateDeliveryInfo.sigungu : ''} disabled={true} />
                                    </div>
                                    <div className='py-1'>
                                        <input type="text" className='join_input_tag' placeholder={updateDeliveryInfo ? updateDeliveryInfo.streetNameAddress : '도로명 주소'} value={updateDeliveryInfo ? updateDeliveryInfo.streetNameAddress : ''} disabled={true} />
                                    </div>
                                    <div className='py-1'>
                                        <input type="text" className='join_input_tag' placeholder={updateDeliveryInfo ? updateDeliveryInfo.detailAddress : '상세주소'} onChange={(e) => onDetailAddress(e)} disabled={!addressCheck} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='py-3'>
                            <div className='pb-1'><span>휴대폰 번호</span></div>
                            <div className={`d-flex ${store.myinfo_phone_box}`}>
                                <div className={`join_input ${store.phone_number_box} pe-1`}>
                                    <input type="number" className='join_input_tag' placeholder={updateDeliveryInfo ? updateDeliveryInfo.deliveryPhone : '핸드폰 번호'} value={updateDeliveryInfo.deliveryPhone} onChange={(e) => handlePhoneNumber(e)} />
                                    <span style={{ color: 'rgba(255, 3, 102, 1)' }}>{selectedCountry?.number}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`${store.store_delivery_update_box_footer}`}>
                        <button onClick={onUpdateDeliverySave}>완료</button>
                    </div>
                </div>
            }

            {showDeliveryAdd &&
                <div className={`${store.store_delivery_update_box}`}>
                    <div className={`${store.store_delivery_update_box_header}`}>
                        <div><button onClick={() => setShowDeliveryAdd(false)}><span className="material-symbols-outlined">arrow_back_ios</span></button></div>
                        <div><span>배송지 추가</span></div>
                    </div>
                    <div className={`${store.store_delivery_update_box_content}`}>
                        <div className='myinfo_input_box'>
                            <div className='pb-1'><span>국가</span></div>
                            <div className='join_input'>
                                {!selectedCountry ?
                                    <button type="text" className='d-flex' placeholder='국가' onClick={handleCountry}>
                                        <span className='country_name_input'>국가선택</span>
                                        <span className="material-symbols-outlined">expand_more</span>
                                    </button> :
                                    <button type="text" className='d-flex' placeholder='국가' onClick={handleCountry}>
                                        <img src={selectedCountry?.img} alt={selectedCountry?.name} />
                                        <span className='country_name_input'>{selectedCountry?.name}</span>
                                        <span className="material-symbols-outlined">expand_more</span>
                                    </button>
                                }
                            </div>
                        </div>
                        <div className='py-3'>
                            <div><span>배송지이름</span></div>
                            <div><input type="text" className='join_input_tag' onChange={(e) => onAddDeliveryName(e)} /></div>
                        </div>
                        <div className='py-3'>
                            <div><span>이름</span></div>
                            <div><input type="text" className='join_input_tag' onChange={(e) => onAddDeliveryUser(e)} /></div>
                        </div>
                        <div className=''>
                            <div className={`${store.store_delivery_update_address_search_box}`}>
                                <div>
                                    <span>배송지</span>
                                </div>
                                <div className='pt-1'>
                                    <button onClick={() => { setModalVisible(true); setAddressCheck(true); }}>주소검색</button>
                                </div>
                            </div>
                            <div className={`${store.adress_box}`}>
                                <div>
                                    <div className='py-1'>
                                        <input type="text" className='join_input_tag' placeholder={'우편번호'} value={addDeliveryInfo?.zipcode ? addDeliveryInfo?.zipcode : ''} disabled={true} />
                                    </div>
                                    <div className='py-1'>
                                        <input type="text" className='join_input_tag' placeholder={'특별시/광역시/도'} value={addDeliveryInfo?.sido ? addDeliveryInfo?.sido : ''} disabled={true} />
                                    </div>
                                    <div className='py-1'>
                                        <input type="text" className='join_input_tag' placeholder={'시/군/구'} value={addDeliveryInfo?.sigungu ? addDeliveryInfo?.sigungu : ''} disabled={true} />
                                    </div>
                                    <div className='py-1'>
                                        <input type="text" className='join_input_tag' placeholder={'도로명 주소'} value={addDeliveryInfo?.streetNameAddress ? addDeliveryInfo?.streetNameAddress : ''} disabled={true} />
                                    </div>
                                    <div className='py-1'>
                                        <input type="text" className='join_input_tag' placeholder={'상세주소'} value={addDeliveryInfo?.detailAddress ? addDeliveryInfo?.detailAddress : ''} onChange={(e) => onAddDetailAddress(e)} disabled={!addressCheck} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='py-3'>
                            <div className='pb-1'><span>휴대폰 번호</span></div>
                            <div className={`d-flex ${store.myinfo_phone_box}`}>
                                <div className={`join_input ${store.phone_number_box} pe-1`}>
                                    <input type="number" className='join_input_tag' placeholder={'핸드폰 번호'} onChange={(e) => handleAddPhoneNumber(e)} />
                                    <span style={{ color: 'rgba(255, 3, 102, 1)' }}>{selectedCountry?.number}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`${store.store_delivery_update_box_footer}`}>
                        <button onClick={onAddDeliverySave}>완료</button>
                    </div>
                </div>
            }
            {showDeliveryOverseasAdd &&
                <div className={`${store.store_delivery_update_box}`}>
                    <div className={`${store.store_delivery_update_box_header}`}>
                        <div><button onClick={() => setShowDeliveryOverseasAdd(false)}><span className="material-symbols-outlined">arrow_back_ios</span></button></div>
                        <div><span>Global address add</span></div>
                    </div>
                    <div className={`${store.store_delivery_update_box_content}`}>
                        <div className='myinfo_input_box'>
                            <div className='pb-1'><span>Country</span></div>
                            <div className='join_input'>
                                {!selectedCountry ?
                                    <button type="text" className='d-flex' placeholder='Country' onClick={handleCountry}>
                                        <span className='country_name_input'>Country Select</span>
                                        <span className="material-symbols-outlined">expand_more</span>
                                    </button> :
                                    <button type="text" className='d-flex' placeholder='Country' onClick={handleCountry}>
                                        <img src={selectedCountry?.img} alt={selectedCountry?.name} />
                                        <span className='country_name_input'>{selectedCountry?.name}</span>
                                        <span className="material-symbols-outlined">expand_more</span>
                                    </button>
                                }
                            </div>
                        </div>
                        <div className='py-3'>
                            <div><span>Shipping address name</span></div>
                            <div><input type="text" className='join_input_tag' onChange={(e) => onAddDeliveryName(e)} /></div>
                        </div>
                        <div className='py-3'>
                            <div><span>User Name</span></div>
                            <div><input type="text" className='join_input_tag' onChange={(e) => onAddDeliveryUser(e)} /></div>
                        </div>
                        <div className=''>
                            <div className={`${store.store_delivery_update_address_search_box}`}>
                                <div>
                                    <span>배송지</span>
                                </div>
                                <div className='pt-1'>
                                    <button onClick={() => setActiveIntenationalSearch(true)}>International Address Search</button>
                                </div>
                            </div>
                            {/* <div className={`${store.adress_box}`}> */}
                                {/* <div>
                                    <div className='py-1'>
                                        <input type="text" className='join_input_tag' placeholder={'우편번호'} value={addDeliveryInfo?.zipcode ? addDeliveryInfo?.zipcode : ''} disabled={true} />
                                    </div>
                                    <div className='py-1'>
                                        <input type="text" className='join_input_tag' placeholder={'특별시/광역시/도'} value={addDeliveryInfo?.sido ? addDeliveryInfo?.sido : ''} disabled={true} />
                                    </div>
                                    <div className='py-1'>
                                        <input type="text" className='join_input_tag' placeholder={'시/군/구'} value={addDeliveryInfo?.sigungu ? addDeliveryInfo?.sigungu : ''} disabled={true} />
                                    </div>
                                    <div className='py-1'>
                                        <input type="text" className='join_input_tag' placeholder={'도로명 주소'} value={addDeliveryInfo?.streetNameAddress ? addDeliveryInfo?.streetNameAddress : ''} disabled={true} />
                                    </div>
                                    <div className='py-1'>
                                        <input type="text" className='join_input_tag' placeholder={'상세주소'} value={addDeliveryInfo?.detailAddress ? addDeliveryInfo?.detailAddress : ''} onChange={(e) => onAddDetailAddress(e)} disabled={!addressCheck} />
                                    </div>
                                </div> */}
                                
                                <div className={`${dailyduck.my_info_international_box}`}>
                                    <div className=''>
                                        <span>zip code</span>
                                        <input type="text" className='join_input_tag' placeholder={updateDeliveryInfo ? updateDeliveryInfo.globalZipCode : 'zip-code'} disabled={true} />
                                    </div>
                                    <div className='pt-1'>
                                        <span>address</span>
                                        <input type="text" className='join_input_tag' placeholder={updateDeliveryInfo ? updateDeliveryInfo.globalAddress : 'address'} value={updateDeliveryInfo ? updateDeliveryInfo.streetNameAddress : ''} disabled={true} />
                                    </div>
                                    <div className='py-1'>
                                        <span>detail address</span>
                                        <input type="text" className='join_input_tag' placeholder={updateDeliveryInfo ? updateDeliveryInfo.globalDtailAddress : 'detail Address'} value={updateDeliveryInfo ? updateDeliveryInfo.globalDtailAddress : ''} onChange={(e) => onGlobalDetailAddress(e)} disabled={!globalAddressSearch}/>
                                    </div>
                                    {/* <div className={`${dailyduck.my_info_international_btn}`}><button onClick={() => setActiveIntenationalSearch(true)}>International Address Search</button></div> */}
                                </div>
                            {/* </div> */}
                        </div>
                        <div className='py-3'>
                            <div className='pb-1'><span>Phone number</span></div>
                            <div className={`d-flex ${store.myinfo_phone_box}`}>
                                <div className={`join_input ${store.phone_number_box} pe-1`}>
                                    <input type="number" className='join_input_tag' placeholder={'phone number'} onChange={(e) => handleAddPhoneNumber(e)} />
                                    <span style={{ color: 'rgba(255, 3, 102, 1)' }}>{selectedCountry?.number}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`${store.store_delivery_update_box_footer}`}>
                        <button className={`${isGlobalAddressConfirm() ? store.store_global_address_active : ''}`} onClick={onAddDeliverySave} disabled={!isGlobalAddressConfirm()}>SUCCESS</button>
                    </div>
                </div>
            }
            {
                showCountry &&
                <Country showCountry={showCountry} setShowCountry={setShowCountry} handleCountryClose={handleCountryClose}
                    searchTerm={searchTerm} setSearchTerm={setSearchTerm} countries={countries}
                    filteredCountries={filteredCountries} setFilteredCountries={setFilteredCountries}
                    setSelectedCountry={setSelectedCountry} handleSelectedCountry={handleSelectedCountry} />
            }
            {
                modalVisible && (
                    <PostModal
                        onClose={handleCloseModal}
                        onComplete={handleAddress}
                        store={store}
                    />
                )
            }
                        {
                showInternationalCountry &&
                    <AddressCountry showInternationalCountry={showInternationalCountry} setShowInternationalCountry={setShowInternationalCountry} handleIntenationalCountryClose={handleIntenationalCountryClose} 
                            searchTermInternational={searchTermInternational} setSearchTermInternational={setSearchTermInternational} countries={countries}
                            filteredCountries={filteredCountries} setFilteredCountries={setFilteredCountries}
                            setInternationalSelectedCountry={setInternationalSelectedCountry} handleInternationalSelectedCountry={handleInternationalSelectedCountry} />
            }
            {
            activeIntenationalSearch && 
                <div className={`${dailyduck.my_info_international_modal_container}`}>
                    <div className={`${dailyduck.my_info_international_modal_box}`}>
                        <div className={`${dailyduck.my_info_international_modal_header}`}>
                            <div><span>Address Search</span></div>
                            <div><button onClick={() => setActiveIntenationalSearch(false)}><span className="material-symbols-outlined">close</span></button></div>
                        </div>
                        <div>
                            <div className={`myinfo_input_box pt-3 px-3 ${dailyduck.my_info_international_country_box}`}>
                                <div><span>Select country</span></div>
                                <div className='join_input'>
                                    {!internationalSelectedCountry ? 
                                        <button type="text" className='d-flex' placeholder='국가' onClick={handleIntenationalCountry}>
                                            <span className='country_name_input'>국가선택</span>
                                            <span className="material-symbols-outlined">expand_more</span>
                                        </button> : 
                                        <button type="text" className='d-flex' placeholder='국가' onClick={handleIntenationalCountry}>
                                            <img src={internationalSelectedCountry?.img} alt={internationalSelectedCountry?.name} />
                                            <span className='country_name_input'>{internationalSelectedCountry?.name}</span>
                                            <span className="material-symbols-outlined">expand_more</span>
                                        </button>
                                    }
                                </div>
                            </div>
                            <div className={`${dailyduck.my_info_international_zip_code}`}>
                                <div><span>zip code</span></div>
                                <div><input type="text" value={intenationalZipCode !== '' ? intenationalZipCode : ''} onChange={(e) => onInternationalZipCode(e)}/></div>
                            </div>
                            <div className={`${dailyduck.my_info_international_modal_search_btn}`}>
                                <div><button onClick={addrSearch}>search</button></div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
            showAddressCompl &&
            <div className={`${dailyduck.my_info_international_compl_address_container}`}>
                <div className={`${dailyduck.my_info_international_compl_address_box}`}>
                    <div className={`${dailyduck.my_info_international_compl_address_zip_code}`}>
                        <div><span>zip code</span></div>
                        <div><input type="text" value={intenationalZipCode} readOnly={showAddressCompl}/></div>
                    </div>
                    <div className={`${dailyduck.my_info_international_compl_address_basic_address}`}>
                        <div><span>address</span></div>
                        <div><input type="text" value={internationalAddressComponent.fullAddress} readOnly={showAddressCompl}/></div>
                    </div>
                    <div className={`${dailyduck.my_info_international_compl_address_detail_address}`}>
                        <div><span>detail address</span></div>
                        <div><input type="text" onChange={(e) => onInternationalDtailAddress(e)}/></div>
                    </div>
                    <div className={`${dailyduck.my_info_international_compl_address_btn}`}><button onClick={handleGlobalAddressInsert}>저장</button></div>
                </div>
            </div>
            }
        </>
    );
}

export default CartPaymentContent;
