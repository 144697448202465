import React, { useState, useEffect, useLayoutEffect, useRef, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DailyduckNavigator from '../../components/footer/Dailyduck_Navigator';
import DailyduckHeader from '../../components/header/Dailyduck_Header';
import MarketBanner from '../../components/market/Market_Banner';
import MarketCategory from '../../components/market/Market_Category';
import * as categorys from '../../apis/category';
import { LoginContext } from '../../contexts/LoginContextProvider';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import market from './market.module.css';
import MarketInfo from '../../components/market/MarketInfo';
import * as admin from '../../apis/admin';
import TopPopup from '../popup/TopPopup';
import MiddlePopup from '../popup/MiddlePopup';
import BottomPopup from '../popup/BottomPopup';
import DailyduckFooter from '../../components/footer/Dailyduck_Footer';

const MarketContainer = () => {
  const { roles } = useContext(LoginContext);
  const [banner, setBanner] = useState([]);
  const [showTopPopup, setShowTopPopUp] = useState(false);
  const [showMiddlePopup, setShowMiddlePopUp] = useState(false);
  const [showBottomPopup, setShowBottomPopUp] = useState(false);
  const [topPopupData, setTopPopupData] = useState(null);
  const [middlePopupData, setMiddlePopupData] = useState(null);
  const [bottomPopupData, setBottomPopupData] = useState(null);
  const [storeCategory, setStoreCategory] = useState([]);
  const [supportCategory, setSupportCategory] = useState([]);
  const [popupList, setPopupList] = useState([]);
  const scrollPosition = useRef(0);
  const containerRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);
  const isInitialRender = useRef(true);
  const searchParams = new URLSearchParams(location.search);
  const [mode, setMode] = useState(searchParams.get('mode'));
  const [basicTermsList, setBasicTermsList] = useState([]);
  const [showTermsPopup, setShowTermsPopup] = useState(false);
  const [filterTerms, setFilterTerms] = useState({});
  const [showContentWarning, setShowContentWarning] = useState(false);

  // 로드 상태 관리
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  // 팝업이 표시 가능한지 확인하는 함수
  const checkPopupDisplayTime = (popupName) => {
    const lastClosedTime = localStorage.getItem(`${popupName}PopupClosed`);
    if (lastClosedTime) {
      const now = new Date().getTime();
      const elapsed = now - parseInt(lastClosedTime);
      return elapsed >= 86400000; // 24시간(86400000ms) 이후 팝업을 다시 표시
    }
    return true; // 처음 방문 시 팝업 표시
  };

  // roles에 따른 seller 제어 로직
  useEffect(() => {
    if (roles && roles.isSeller) {
      MySwal.fire({
        customClass: {
          popup: `${market.market_auth_popup}`,
        },
        html: `
          <div>
            <div class='${market.market_auth_title}'><span>접근 제한</span></div>
            <div class='${market.market_auth_content}'><span>Seller 로그아웃 후 이용해 주세요.</span></div>
            <div class='${market.market_auth_btn} py-2'><button id='seller_sales_save'>확인</button></div>
          </div>
        `,
        showConfirmButton: false,
        showCancelButton: false,
      });

      document.addEventListener('click', function (e) {
        if (e.target && e.target.id === 'seller_sales_save') {
          MySwal.close();
        }
      });

      navigate('/seller');
    }
  }, [roles, navigate, MySwal]);  

  useEffect(() => {
    console.log('mode : ', mode);
    if (basicTermsList && mode) {
      const filteredData = basicTermsList.find(terms => terms.termsTitle.includes(mode));
      
      if (filteredData) {
        setFilterTerms(filteredData);
        setShowTermsPopup(true); // 데이터를 설정한 후에 모달을 띄웁니다.
      }
    }
  }, [mode, basicTermsList]);

  // 팝업 및 배너/카테고리 데이터를 가져오는 로직
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [bannerResponse, storeResponse, supportResponse] = await Promise.all([
          admin.bannerSelect(),
          categorys.storeCategory(),
          categorys.supportCategory(),
        ]);
        setBanner(bannerResponse.data);
        setStoreCategory(storeResponse.data);
        setSupportCategory(supportResponse.data);
        setIsDataLoaded(true);
      } catch (error) {
        console.error('Error loading data:', error);
      }
    };

    fetchData();
    popupSelect();
    basicTermsSelect();

  }, []);

  // 스크롤 위치 저장 및 복구 로직
  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        scrollPosition.current = containerRef.current.scrollTop;
        // console.log('현재 market_container 스크롤 위치:', scrollPosition.current);
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useLayoutEffect(() => {
    if (isInitialRender.current && isDataLoaded) {
      const savedPosition = sessionStorage.getItem('scrollPosition');
      if (savedPosition && containerRef.current) {
        requestAnimationFrame(() => {
          containerRef.current.scrollTo(0, parseInt(savedPosition, 10));
          // console.log('저장된 market_container 스크롤 위치로 이동:', savedPosition);
          sessionStorage.removeItem('scrollPosition');
        });
      }
      isInitialRender.current = false;
    }
  }, [location, isDataLoaded]); // 데이터 로딩 완료 후 실행

  const basicTermsSelect = async () => {
    const response = await admin.basicTermsSelect();
    const data = response.data;

    console.log('basicTermsSelect : ', data);
    setBasicTermsList(data);
  }

  // 팝업을 선택하고 제어하는 함수
  const popupSelect = async () => {
    const response = await admin.popupSelect();
    const data = response.data;
  
    // popupExposure가 true인 데이터만 필터링
    const filteredData = data.filter((popup) => popup.popupExposure === true);
  
    console.log('Filtered popUpData : ', filteredData);
    setPopupList(filteredData);
  
    // 이미 팝업이 하나라도 열린 경우 이후 팝업을 열지 않도록 처리
    let isPopupAlreadyShown = false;
  
    filteredData.some((popup) => {
      if (popup.popupExposureLocate === 'home') {
        // Bottom 팝업
        if (
          popup.popupExposureDirection === 'bottom' &&
          !localStorage.getItem('bottomPopupClosed') &&
          !isPopupAlreadyShown &&
          checkPopupDisplayTime('bottom')
        ) {
          console.log('Bottom');
          setBottomPopupData(popup);
          setShowBottomPopUp(true);
          isPopupAlreadyShown = true;
        }
        // Top 팝업
        else if (
          popup.popupExposureDirection === 'top' &&
          !localStorage.getItem('topPopupClosed') &&
          !isPopupAlreadyShown &&
          checkPopupDisplayTime('top')
        ) {
          console.log('Top');
          setTopPopupData(popup);
          setShowTopPopUp(true);
          isPopupAlreadyShown = true;
        }
        // Middle 팝업
        else if (
          popup.popupExposureDirection === 'middle' &&
          !localStorage.getItem('middlePopupClosed') &&
          !isPopupAlreadyShown &&
          checkPopupDisplayTime('middle')
        ) {
          console.log('Middle');
          setMiddlePopupData(popup);
          setShowMiddlePopUp(true);
          isPopupAlreadyShown = true;
        }
      }
    });
  };

  const onTermsClick = (item) => {
    console.log('item : ', item);
    setMode(item.termsTitle);
  }

  return (
    <div
      ref={containerRef}
      className={`${market.market_container}`}
      style={{ overflowY: 'auto', height: '100vh' }}
    >
      <DailyduckHeader />
      <MarketBanner banner={banner} />
      <MarketCategory storeCategory={storeCategory} supportCategory={supportCategory} />
      <div className='contour'></div>
      <MarketInfo market={market} admin={admin} containerRef={containerRef} />
      <DailyduckFooter market={market} admin={admin} mode={mode} basicTermsList={basicTermsList} onTermsClick={onTermsClick} setShowContentWarning={setShowContentWarning}/>
      <DailyduckNavigator />
      {showTopPopup && (
        <TopPopup
          showTopPopup={showTopPopup}
          setShowTopPopUp={setShowTopPopUp}
          popupData={topPopupData}
        />
      )}
      {showMiddlePopup && (
        <MiddlePopup
          showMiddlePopup={showMiddlePopup}
          setShowMiddlePopUp={setShowMiddlePopUp}
          popupData={middlePopupData}
        />
      )}
      {showBottomPopup && (
        <BottomPopup
          showBottomPopup={showBottomPopup}
          setShowBottomPopUp={setShowBottomPopUp}
          popupData={bottomPopupData}
        />
      )}

      
      {
        showTermsPopup && filterTerms.termsContent &&
        <div className={market.market_terms_modal_container}>
          <div className={market.market_terms_modal_box}>
            <div>
              <div><span>{filterTerms.termsTitle}</span></div>
              <div><button onClick={() => setShowTermsPopup(false)}><span className="material-symbols-outlined">close</span></button></div>
            </div>
            <div><div dangerouslySetInnerHTML={{ __html: filterTerms.termsContent}}></div></div>
          </div>
        </div>
      }
      {
        showContentWarning &&
        <div className={market.market_contents_warning_modal_container}>
          <div className={market.market_contents_warning_modal_box}>
            <div className={market.market_contents_warning_modal_header}>
              <div><span>콘텐츠 산업 진흥법에 따른 표시</span></div>
              <div><button onClick={() => setShowContentWarning(false)}><span className="material-symbols-outlined">close</span></button></div>
            </div>
            <div className={market.market_contents_warning_modal_img}>
              <div><img src={`${process.env.PUBLIC_URL}/img/icon/copyright.png`} alt="" /></div>
            </div>
            <div className={market.market_contents_warning_modal_content_box}>
              <div className={market.market_contents_warning_modal_content}>
                <div>
                  <div><span>콘텐츠의 종류</span></div>
                  <div><span>상품/판매회원/중개 서비스, 상품정보, 이벤트정보 디자인 및 화면의 구성, UI 등</span></div>
                </div>
                <div>
                  <div><span>제작연월일</span></div>
                  <div><span>개별콘텐츠의 개시일 또는 갱신일</span></div>
                </div>
                <div>
                  <div><span>제작자</span></div>
                  <div><span>주식회사 스밈 및 개별 판매회원</span></div>
                </div>
              </div>
              <div className={market.market_contents_warning_modal_footer}>
                <span>주식회사 스밈 사이트 상의 모든 콘텐츠는 '콘텐츠산업 진흥법'에 따라 개시일 또는 그 갱신일로부터 5년간 보호됩니다.</span>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default MarketContainer;
