import React, { useContext, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import * as auth from '../../../apis/auth';
import * as admin from '../../../apis/admin';
import { LoginContext } from '../../../contexts/LoginContextProvider';
import { useLocation, useNavigate } from 'react-router-dom';
import ss from '../../../containers/market/support/venue/support_shop.module.css';

const SearchContent = () => {
  const { userInfo } = useContext(LoginContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { selectKeyword } = location.state || {};
  const [inputValue, setInputValue] = useState(selectKeyword || ''); // selectKeyword를 기본값으로 설정
  const [relatedKeywords, setRelatedKeywords] = useState([]);
  const [recentKeyword, setRecentKeyword] = useState([]);
  const [totalKeyword, setTotalKeyword] = useState([]);
  const [sortedKeywords, setSortedKeywords] = useState([]);
  const [randomKeywords, setRandomKeywords] = useState([]);
  const [currentDate, setCurrentDate] = useState('');
  const [productList, setProductList] = useState([]);
  const [supportList, setSupportList] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [related, setRelated] = useState(false);
  const [activeSearch, setActiveSearch] = useState('all');
  const [totalItem, setTotalItem] = useState([]);
  const [searchEnter, setSearchEnter] = useState(false);
  const [suggestKeywordList, setSuggestKeywordList] = useState([]);
  const [columnClass, setColumnClass] = useState('col-6'); // 초기값 설정


  useEffect(() => {
    // console.log('supportList : ', supportList);
  }, [supportList]);
  useEffect(() => {
    suggestKeyword();
  }, []);

  useEffect(() => {
    const updateColumnClass = () => {
      if (window.innerWidth > 500) {
        setColumnClass('col-4'); // 화면이 500px 이상일 때
      } else {
        setColumnClass('col-6'); // 화면이 500px 이하일 때
      }
    };

    updateColumnClass();
    window.addEventListener('resize', updateColumnClass);

    return () => {
      window.removeEventListener('resize', updateColumnClass);
    };
  }, []);

  useEffect(() => {
    const date = new Date();
    const formattedDate = `${date.getFullYear()}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
    setCurrentDate(formattedDate);
  }, []);

  useEffect(() => {
    if (inputValue) {
      const filteredKeywords = totalKeyword.filter(item =>
        item.keyword.includes(inputValue)
      );
      const uniqueKeywords = Array.from(new Set(filteredKeywords.map(item => item.keyword)));
      setRelatedKeywords(uniqueKeywords);
    } else {
      setRelatedKeywords([]);
    }
  }, [inputValue, totalKeyword]);

  useEffect(() => {
    const fetchKeywords = async () => {
      await searchKeywordSelect();
      await allkeywordSelect();
    };
    fetchKeywords();
  }, []);
  
  const highlightText = (text, highlight) => {
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    return parts.map((part, index) =>
      part.toLowerCase() === highlight.toLowerCase() ? <span key={index} style={{ color: 'rgba(255, 3, 102, 1)' }}>{part}</span> : part
    );
  };

  useEffect(() => {
    const sorted = countAndSortKeywords(totalKeyword);
    setSortedKeywords(sorted);
  }, [totalKeyword]);

  useEffect(() => {
    if (suggestKeywordList.length > 0) {
      const shuffled = shuffleArray(suggestKeywordList);
      setRandomKeywords(shuffled.slice(0, 10));
    }
  }, [suggestKeywordList]);

  // selectKeyword가 있는 경우 자동으로 검색 실행
  useEffect(() => {
    if (selectKeyword) {
      searchValue(selectKeyword);
    }
  }, [selectKeyword]);

  // 추천 키워드 및 최근 검색어 클릭 시 검색 실행
  const handleKeywordClick = (keyword) => {
    setInputValue(keyword);
    searchValue(keyword);
  };
  const handlePopularKeywordClick = (keyword) => {
    setInputValue(keyword);
    searchValue(keyword);  
  }

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const suggestKeyword = async () => {
    const response = await admin.suggestKeywordRead();
    setSuggestKeywordList(response.data);
  };

  // 검색 함수
  const searchValue = async (keyword) => {
    const searchKeyword = keyword || inputValue;
    const response = await auth.searchData(searchKeyword, userInfo?.userId || 'anonymous');
    const data = response.data;

    // console.log('검색 데이터 : ', data);

    setTotalItem(data);
    const products = [];
    const supports = [];
    const events = [];

    data.forEach(item => {
      if (item.type === 'product') {
        products.push(item.data);
      } else if (item.type === 'support') {
        supports.push(item.data);
      } else if (item.type === 'event' && new Date(item.data.event_end_date) > new Date()) {
        events.push(item.data);
      }
    });

    setProductList(products);
    setSupportList(supports);
    setEventList(events);
    setRelated(true);
    setSearchEnter(true);
    searchKeywordSelect();
  };

  const searchKeywordSelect = async () => {
    const response = await auth.keywordSelect(userInfo?.userId);
    setRecentKeyword(response.data);
  };

  const allkeywordSelect = async () => {
    const response = await auth.allKeywordSelect();
    setTotalKeyword(response.data);
  };

  const clearInput = () => {
    setInputValue('');
  };

  const onKeywordDelete = async (item) => {
    const response = await auth.keywordDelete(item.userId, item.keyword);
    if (response.data === 'SUCCESS') {
      searchKeywordSelect();
    } else {
      alert('키워드 삭제가 실패했습니다.');
    }
  };

  const countAndSortKeywords = (keywords) => {
    const keywordCount = {};

    keywords.forEach(item => {
      if (item.keyword) {
        if (keywordCount[item.keyword]) {
          keywordCount[item.keyword]++;
        } else {
          keywordCount[item.keyword] = 1;
        }
      }
    });

    const sortedKeywords = Object.keys(keywordCount)
      .map(keyword => ({ keyword, count: keywordCount[keyword] }))
      .sort((a, b) => b.count - a.count);

    return sortedKeywords;
  };

  const splitKeywordsIntoColumns = (keywords) => {
    const halfLength = Math.ceil(keywords.length / 2);
    const leftColumn = keywords.slice(0, halfLength);
    const rightColumn = keywords.slice(halfLength);
    return [leftColumn, rightColumn];
  };

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const onRelatedBtn = () => {
    setRelated(!related);
  }

  const [leftColumn, rightColumn] = splitKeywordsIntoColumns(sortedKeywords.slice(0, 10));

  const onSupportItem = (item) => {
    navigate(`/venue/${item.productNumber}`);
  }

  const formatDate = (dateString) => {
    return dateString.replace('T', ' ').substring(0, 19);
  };

  const onStoreProduct = (item) => {
    navigate(`/store/product/${item.product_no}`);
  }

  const onEventProduct = (item) => {
    const newItem = {
      eventContent: item.event_content,
      eventType: item.event_type,
      eventEndDate: item.event_end_date,
      eventImage1: item.event_image1,
      eventImage2: item.event_image2,
      eventImage3: item.event_image3,
      eventImage4: item.event_image4,
      eventNo: item.event_no,
      eventNumber: item.event_number,
      eventPrice: item.event_price,
      eventRegDate: item.event_reg_date,
      eventStartDate: item.event_start_date,
      eventSubTitle: item.event_sub_title,
      eventTitle: item.event_title,
      eventUpdDate: item.event_upd_date,
      eventWinnerDate: item.event_winner_date,
      type: item.type,
      userId: item.user_id
    }

    navigate(`/event/${item.event_type}/${item.event_number}`, { state: { eventInfo: newItem } });
  }

  const onStoreMore = () => {
    navigate(`/event/store/more`, { state: { storeProduct: productList } });
  }

  const onSupportMore = () => {
    navigate(`/event/support/more`, { state: { supportProduct: supportList } });
  }

  const onEventMore = () => {
    navigate(`/event/event/more`, { state: { eventProduct: eventList } });
  }

  const handlePopularProduct = () => {
    navigate('/market/popular/product');
  }

  const handleSuggestSupport = () => {
    navigate('/market/popular/support');
  }

  const recentSearchesDelete = async () => {
    const response = await auth.recentSearchesDelete(userInfo?.userId);
    const data = response.data;

    // console.log(data);

    if(data === 'SUCCESS') {
      searchKeywordSelect();
    }
  }

  const getMinPrice = (items) => {
    if (!Array.isArray(items) || items.length === 0) return null;
    return Math.min(...items.map((item) => item.mainItemPrice));
  };

  const formatRating = (rating) => {
    if(rating !== null && rating !== '' && rating) {
      return rating.toFixed(1);
    } else {
      return rating;
    }
  };

  return (
    <div>
      <div>
        <div className='search_content_header'>
          <div>
            <input
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              placeholder="검색"
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  searchValue();
                }
              }}
            />
          </div>
          <div><span className="material-symbols-outlined">search</span></div>
          {inputValue && (
            <div>
              <button onClick={clearInput}><span className="material-symbols-outlined">cancel</span></button>
            </div>
          )}
        </div>
        {inputValue && (
          <div className='position-relative'>
            <div className={`related_keywords ${related ? 'related_display' : ''}`}>
              {relatedKeywords.length > 0 ? (
                relatedKeywords.map((keyword, index) => (
                  <div key={index} className='related_keyword_item' onClick={() => handleKeywordClick(keyword)}>
                    <span className="material-symbols-outlined">search</span>
                    <span className='ps-2'>{highlightText(keyword, inputValue)}</span>
                  </div>
                ))
              ) : (
                <div className='no_results'>관련 검색어가 없습니다.</div>
              )}
            </div>
            <div className='related_keywords_btn'>
              <button onClick={onRelatedBtn}>{related ? '자동완성 펼치기' : '자동완성 접기'}</button>
            </div>
          </div>
        )}
        {inputValue === '' ? (
          <div>
            <div className='search_content_recent_search'>
              <div>
                <span>최근 검색어</span>
                <button onClick={recentSearchesDelete}>전체삭제</button>
              </div>
              <div className='py-3'>
                <Swiper 
                  slidesPerView={"auto"} 
                  spaceBetween={10} 
                  modules={[Pagination, Navigation]}
                  className='search_content_recent_search_swiper'
                >
                  {recentKeyword.map((item, index) => (
                    <SwiperSlide key={index}>
                      <button className='search_content_recent_search_btn' onClick={() => handleKeywordClick(item.keyword)}>
                        <span>{item.keyword}</span>
                        <button onClick={(e) => { e.stopPropagation(); onKeywordDelete(item); }}><span className="material-symbols-outlined">close</span></button>
                      </button>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
            <div className='search_content_papularity_search'>
              <div>
                <div><span>인기 검색어</span></div>
                <div><span>{currentDate} 기준</span></div>
              </div>
              <div className='row'>
                <div className='col-6'>
                  {leftColumn.map((item, index) => (
                    <div className='search_content_papularity_search_txt' key={index} onClick={() => handlePopularKeywordClick(item.keyword)}>
                      <div><img src={`../../img/search/number${index + 1}.png`} alt="숫자" /></div>
                      <div><span>{item.keyword}</span></div>
                      <div>
                        {/* <img src="../../img/search/popularityUp.png" alt="업" /> */}
                        <span>-</span>
                      </div>
                    </div>
                  ))}
                </div>
                <div className='col-6'>
                  {rightColumn.map((item, index) => (
                    <div className='search_content_papularity_search_txt' key={index} onClick={() => handlePopularKeywordClick(item.keyword)}>
                      <div><img src={`../../img/search/number${index + leftColumn.length + 1}.png`} alt="숫자" /></div>
                      <div><span>{item.keyword}</span></div>
                      <div>
                        {/* <img src="../../img/search/popularityUp.png" alt="업" /> */}
                        <span>-</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className='search_content_suggestion_keyword'>
              <div><span>추천 키워드</span></div>
              <div className='py-2'>
                <Swiper 
                  slidesPerView={"auto"} 
                  spaceBetween={10} 
                  modules={[Pagination, Navigation]}
                  className='search_content_recent_search_swiper'
                >
                  {randomKeywords.map((item, index) => (
                    <SwiperSlide key={index}>
                      <button className='search_content_recent_search_btn' onClick={() => handleKeywordClick(item.suggestKeyword)}>
                        <span>{item.suggestKeyword}</span>
                      </button>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        ) : (
          <div className='mt-4'>
            {totalItem.length > 0 && searchEnter && (
              <div className='px-3'>
                <div><span>검색결과 ({productList.length + supportList.length + eventList.length}개)</span></div>
                <div className='py-2'>
                  <Swiper slidesPerView={6} spaceBetween={0} modules={[Pagination, Navigation]}>
                    <SwiperSlide>
                      <div className={`search_content_search_data_btn ${activeSearch === 'all' ? 'search_content_search_data_btn_active' : ''}`}>
                        <button onClick={() => setActiveSearch('all')}>
                          전체({(productList.length + supportList.length + eventList.length) > 99 ? '99+' : productList.length + supportList.length + eventList.length})
                        </button>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className={`search_content_search_data_btn ${activeSearch === 'product' ? 'search_content_search_data_btn_active' : ''}`}>
                        <button onClick={() => setActiveSearch('product')}>
                          스토어({productList.length > 99 ? '99+' : productList.length})
                        </button>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className={`search_content_search_data_btn ${activeSearch === 'support' ? 'search_content_search_data_btn_active' : ''}`}>
                        <button onClick={() => setActiveSearch('support')}>
                          서포트({supportList.length > 99 ? '99+' : supportList.length})
                        </button>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className={`search_content_search_data_btn ${activeSearch === 'event' ? 'search_content_search_data_btn_active' : ''}`}>
                        <button onClick={() => setActiveSearch('event')}>
                          이벤트({eventList.length > 99 ? '99+' : eventList.length})
                        </button>
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>
            )}
            {totalItem.length === 0 && searchEnter && (
              <div className='search_content_no_search_box'>
                <div><img src="../../img/user/no_search.png" alt="" /></div>
                <div><span>검색 결과가 없어요</span></div>
                <div><span>데일리덕의 인기상품을 보러 갈까요?</span></div>
                <div><button onClick={handlePopularProduct}>인기덕템 보러가기</button></div>
                <div><button onClick={handleSuggestSupport}>추천 서포트 보러가기</button></div>
              </div>
            )}
            {totalItem.length > 0 && searchEnter && activeSearch === 'all' && (
              <div className='search_result_box'>
                <div className='border-bottom border-5'>
                  <div className='search_store_box px-3'>
                  <div className='search_content_title'><span>스토어</span></div>
                    <div className='row'>
                      {productList.map((item, index) => (
                        <div className={`search_content_store_item ${columnClass}`} key={index} onClick={() => onStoreProduct(item)}>
                          <div onClick={() => onStoreProduct(item)}>
                            <div><img src={item.product_main_image} alt="" /></div>
                            <div><span>{item.shop_name}</span></div>
                            <div><span>{item.product_name}</span></div>
                            <div>
                              <div><span>{item.discount_rate}%</span></div>
                              <div><span>{item.productsale_price.toLocaleString()}원</span></div>
                              <div><span>{item.product_price.toLocaleString()}원</span></div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className='search_content_store_item_btn'><button onClick={() => onStoreMore()}>'{inputValue}' 스토어 검색결과 더보기</button></div>
                </div>
                <div className='border-bottom border-5'>
                  <div className='px-3 search_content_support_box'>
                    <div className='mt-3 search_content_title'><span>서포트</span></div>
                    <div>
                      {
                        supportList.map((item, index) => {

                          const minPrice = getMinPrice(item.mainItem);
                          const hasOptions = Array.isArray(item.mainItem) && item.mainItem.length > 0;

                          return (
                            <div className='search_content_support_info' key={index} onClick={() => onSupportItem(item)}>
                              <div>
                                <div><img src={item.shopProfile} alt="" /></div>
                                <div><span>{item.productName}</span></div>
                              </div>
                              <div><span>{item.productInfo}</span></div>
                              <div className={`${ss.support_content_price_rate_star_box}`}>
                                <div>
                                  <div>
                                    <img src={`${process.env.PUBLIC_URL}/img/icon/star_fill.png`} alt="별점" />
                                  </div>
                                  <div className="ps-1">
                                    <span>{formatRating(item.reviewRating)}</span>
                                  </div>
                                </div>
                                <div>
                                  <span>리뷰</span>
                                  <span>{item.reviewCount}</span>
                                </div>
                                <div>
                                  {minPrice !== null && (
                                    <span>
                                      &#8361;{minPrice.toLocaleString()}{hasOptions ? ' ~' : ''}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div>
                                <Swiper 
                                  slidesPerView={'auto'} 
                                  spaceBetween={10} 
                                  modules={[Pagination, Navigation]}
                                  className='search_content_support_item'
                                >
                                  {[item.representativeImage, item.listImage1, item.listImage2, item.listImage3, item.listImage4].map((img, idx) => (
                                    img && (
                                      <SwiperSlide key={idx}>
                                        <div className='search_content_support_item_img'>
                                          <img src={img} alt={`이미지${idx + 1}`} />
                                        </div>
                                      </SwiperSlide>
                                    )
                                  ))}
                                </Swiper>
                              </div>
                            </div>
                          ) 
                        })
                      }
                    </div>
                  </div>
                  <div className='search_content_store_item_btn'><button onClick={onSupportMore}>'{inputValue}' 서포트 검색결과 더보기</button></div>
                </div>
                <div className='py-2'>
                  <div className='px-3 search_content_event_item_box'>
                  <div className='mt-3 search_content_title'><span>이벤트</span></div>
                    {eventList.map((item, index) => (
                        <div className='search_content_event_item' onClick={() => onEventProduct(item)} key={index}>
                          <div><img src={item.event_image1} alt="" /></div>
                          <div>
                            <div><span>{item.event_title}</span></div>
                            <div><span>{item.event_sub_title}</span></div>
                            <div><span>{formatDate(item.event_start_date)}</span><span>~</span><span>{formatDate(item.event_end_date)}</span></div>
                          </div>
                        </div>
                    ))}
                  </div>
                  <div className='search_content_store_item_btn'><button onClick={onEventMore}>'{inputValue}' 이벤트 검색결과 더보기</button></div>
                </div>
              </div>
            )}
            {totalItem.length > 0 && searchEnter && activeSearch === 'product' && (
              <div className='search_result_box'>
                <div className='border-bottom border-5'>
                  <div className='search_store_single_box px-3'>
                    <div className='row'>
                      {productList.map((item, index) => (
                        <div className={`col-4 search_content_store_item ${columnClass}`} key={index}>
                          <div onClick={() => onStoreProduct(item)}>
                            <div><img src={item.product_main_image} alt="" /></div>
                            <div><span>{item.shop_name}</span></div>
                            <div><span>{item.product_name}</span></div>
                            <div>
                              <div><span>{item.discount_rate}%</span></div>
                              <div><span>{item.productsale_price.toLocaleString()}원</span></div>
                              <div><span>{item.product_price.toLocaleString()}원</span></div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className='search_content_store_item_btn'><button>'{inputValue}' 스토어 검색결과 더보기</button></div>
                </div>
              </div>
            )}
            {totalItem.length > 0 && searchEnter && activeSearch === 'support' && (
              <div className='search_result_box'>
                <div className='border-bottom border-5'>
                  <div className='px-3 search_content_support_single_box'>
                    <div>
                    {
                        supportList.map((item, index) => {

                          const minPrice = getMinPrice(item.mainItem);
                          const hasOptions = Array.isArray(item.mainItem) && item.mainItem.length > 0;

                          return (
                            <div className='search_content_support_info' key={index} onClick={() => onSupportItem(item)}>
                              <div>
                                <div><img src={item.shopProfile} alt="" /></div>
                                <div><span>{item.productName}</span></div>
                              </div>
                              <div><span>{item.productInfo}</span></div>
                              <div className={`${ss.support_content_price_rate_star_box}`}>
                                <div>
                                  <div>
                                    <img src={`${process.env.PUBLIC_URL}/img/icon/star_fill.png`} alt="별점" />
                                  </div>
                                  <div className="ps-1">
                                    <span>{formatRating(item.reviewRating)}</span>
                                  </div>
                                </div>
                                <div>
                                  <span>리뷰</span>
                                  <span>{item.reviewCount}</span>
                                </div>
                                <div>
                                  {minPrice !== null && (
                                    <span>
                                      &#8361;{minPrice.toLocaleString()}{hasOptions ? ' ~' : ''}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div>
                                <Swiper 
                                  slidesPerView={'auto'} 
                                  spaceBetween={10} 
                                  modules={[Pagination, Navigation]}
                                  className='search_content_support_item'
                                >
                                  {[item.representativeImage, item.listImage1, item.listImage2, item.listImage3, item.listImage4].map((img, idx) => (
                                    img && (
                                      <SwiperSlide key={idx}>
                                        <div className='search_content_support_item_img'>
                                          <img src={img} alt={`이미지${idx + 1}`} />
                                        </div>
                                      </SwiperSlide>
                                    )
                                  ))}
                                </Swiper>
                              </div>
                            </div>
                          ) 
                        })
                      }
                    </div>
                  </div>
                  <div className='search_content_store_item_btn'><button>'{inputValue}' 서포트 검색결과 더보기</button></div>
                </div>
              </div>
            )}
            {totalItem.length > 0 && searchEnter && activeSearch === 'event' && (
              <div className='py-2'>
                <div className='px-3'>
                <div className='mt-3 search_content_title'><span>이벤트</span></div>
                  {eventList.map((item, index) => (
                      <div className='search_content_event_item' key={index}  onClick={() => onEventProduct(item)}>
                        <div><img src={item.event_image1} alt="" /></div>
                        <div>
                          <div><span>{item.event_title}</span></div>
                          <div><span>{item.event_sub_title}</span></div>
                          <div><span>{formatDate(item.event_start_date)}</span><span>~</span><span>{formatDate(item.event_end_date)}</span></div>
                        </div>
                      </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchContent;
