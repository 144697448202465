import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as auth from '../../apis/auth';
import { LoginContext } from '../../contexts/LoginContextProvider';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import admin from '../../admin/admin.module.css';

const LineUserInfo = () => {
    const { isLogin, login, logout, remove, loginCheck } = useContext(LoginContext);
    const MySwal = withReactContent(Swal);
    const [userInfo, setUserInfo] = useState(null);
    const [lineExist, setLineExist] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [ joinObject, setJoinObject ] = useState({});

    useEffect(() => {
        const fetchUserInfo = () => {
            const searchParams = new URLSearchParams(location.search);
            const userInfo = {};
            for (let [key, value] of searchParams.entries()) {
                userInfo[key] = value;
            }
            // console.log('User info from URL params:', userInfo); // 디버그 메시지 추가
            setUserInfo(userInfo);

            // joinObject에 넣을 새로운 객체 구성
            const newJoinObject = {
                userProfile : userInfo.pictureUrl,
                userName: userInfo.name,
                userId: userInfo.userId,
                // userEmail: userList.userEmail,
                // userPw: userList.userPw,
                // userRepublic: userList.userRepublic,
                // userTel: userList.userTel,
                // userNick: userList.userNick,
                // userGender: userList.userGender,
                // userBirth: birth, // 재구성한 birth를 새로운 속성으로 추가
                // recode: inputValue,
                line: userInfo.userId
            };

            setJoinObject(newJoinObject);
        };

        fetchUserInfo();
    }, [location]);

    useEffect(() => {
        if (userInfo) {
            const lineUserSearch = async () => {
                // console.log('Searching LINE user with userInfo:', userInfo); // 디버그 메시지 추가
                const response = await auth.lineUserSearch(userInfo?.userId);
                const data = response.data;

                // console.log('LINE user search result:', data); // 디버그 메시지 추가

                if (data === 'exist') {
                    const userSelectResponse = await auth.lineUserSelect(userInfo.userId);
                    const userSlectData = userSelectResponse.data;
    
                    // console.log('userSlectData : ', userSlectData);

                    // 로그인 처리를 위한 토큰 요청
                    const tokenResponse = await auth.getToken({ userId: userSlectData.userId });
                    const tokenData = tokenResponse.data;
                    // console.log('tokenData : ', tokenData);
                    if (tokenResponse.status === 200) {
                        const token = tokenResponse.data.token;
                        // 쿠키에 토큰 저장
                        Cookies.set("accessToken", token);
                        localStorage.setItem("accessToken", token);
                        // 로그인 체크 함수 호출
                        await loginCheck();
                        navigate('/market');
                    }
                } else {
                    setLineExist(false); // 상태를 false로 설정
                }
            }
            lineUserSearch();
        }
    }, [userInfo]);

    const join = async () => {
        navigate('/line/join', { state: { line: userInfo } });
        // const response = await auth.lineJoin(joinObject);
        // const data = await response.data;

        // if(data === 'SUCCESS') {
        //     MySwal.fire({
        //     customClass: {
        //         popup: `${admin.admin_seller_add_popup}`
        //     },
        //     html : 
        //     `
        //     <div>
        //     <div class='${admin.admin_seller_add_title}'><span>회원가입이 완료 되었습니다.</span></div>
        //     <div class='${admin.admin_seller_add_sub_title}'><span></span></div>
        //     <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
        //     <button class='mx-1' id='seller_add_confirm'>확인</button>
        //     </div>
        //     </div>
        //     `,
        //     showConfirmButton: false,
        //     showCancelButton: false,
        // });
        // document.addEventListener('click', sellerAddConfirm);
        
        // function sellerAddConfirm(e) {
        //     if(e.target && e.target.id == 'seller_add_confirm') {
        //         MySwal.close();
        //         // success('/market', { state: {key: 'test2'}});
        //         // login( joinObject.userId, joinObject.userPw );
        //         checkUserExists(userInfo);
        //         document.removeEventListener('click', sellerAddConfirm);
        //     }
        // }

            
        // } else {
        //     alert(`Server Error ${data}`);
        // }
    }

    const checkUserExists = async (userInfo) => {
        const response = await auth.lineUserSearch(userInfo.userId);
        const data = response.data;
    
        // console.log('user : ', data);
        // console.log('status : ', response.status);
        // console.log('userInfo.id : ', userInfo.userId);
    
        try {
          if (response.status === 200) {
            if (data === 'exist') {
              const userSelectResponse = await auth.lineUserSelect(userInfo.userId);
              const userSlectData = userSelectResponse.data;
    
              // console.log('userSlectData : ', userSlectData);
    
              // 로그인 처리를 위한 토큰 요청
              const tokenResponse = await auth.getToken({ userId: userSlectData.line });
              const tokenData = tokenResponse.data;
              // console.log('tokenData : ', tokenData);
              if (tokenResponse.status === 200) {
                const token = tokenResponse.data.token;
                // 쿠키에 토큰 저장
                Cookies.set("accessToken", token);
                // 로그인 체크 함수 호출
                await loginCheck();
                navigate('/market');
              }
            } else {
              setLineExist(false);
            }
          }
        } catch (error) {
          console.error('error : ', error);
        }
    };

    return (
        <div>
            <div className='twitter_login_info_img_box'>
                <img src="../../img/logo_pink2.png" alt="데일리덕" />
            </div>
            {userInfo && !lineExist ? (
                <div className='line_login_info_box'>
                        <div><img src={userInfo?.pictureUrl} alt="Profile" /></div>
                        <div><span>{userInfo?.displayName}</span></div>
                        <div><span>{userInfo?.email}</span></div>
                        <div><button onClick={join}>데일리덕 회원가입</button></div>
                </div>
            ) : (
                <p>Loading user info...</p>
            )}
        </div>
    );
};

export default LineUserInfo;
