import React, { useState } from 'react';
import admin from '../../../admin.module.css';
import { Pagination } from 'antd';

const AdminSellerProductManagement = () => {

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(14);
    const [totalItems, setTotalItems] = useState(0);

    // 페이지 번호 클릭 시 호출될 함수
    const paginate = pageNumber => setCurrentPage(pageNumber);

  return (
    <div className={`${admin.admin_seller_product_management_box} px-3 py-3`}>
        <div>
            <div className={`${admin.admin_seller_product_management_header} py-3`}><span>셀러 상품 관리</span></div>
            <div>
                <div className={`${admin.admin_seller_product_management_top} py-2`}>
                    <div className={`${admin.admin_seller_product_management_state_btn}`}>
                        <button>판매중 처리</button>
                        <button>품절 처리</button>
                        <button>숨김 처리</button>
                        <button>복제</button>
                        <button>수정</button>
                    </div>
                    <div className='d-flex'>
                        <button className={`${admin.admin_seller_inspection_download} me-1`}>
                            <div><span>내보내기</span></div>
                            <div><span className="material-symbols-outlined">download</span></div>
                        </button>
                        <div className={`${admin.admin_seller_inspection_input}`}><input type="text" /><button className={`${admin.admin_seller_inspection_search_btn}`}><span className="material-symbols-outlined">search</span></button></div>
                    </div>
                </div>
                <div className={`${admin.admin_seller_inspection_product_header} pt-3 pb-2`}>
                    <div><input type="checkbox" name="" id="" /></div>
                    <div><span>번호</span></div>
                    <div><span>등록일</span></div>
                    <div><span>수정일</span></div>
                    <div><span>등록 상품 정보</span></div>
                    <div><span>카테고리</span></div>
                    <div><span>재고</span></div>
                    <div><span>판매 상태</span></div>
                </div>
                <div className={`${admin.admin_seller_inspection_product_content}`}>
                    <div><input type="checkbox" onClick={(e) => e.stopPropagation()} /></div>
                    <div><span>1</span></div>
                    <div><span>1시간 전</span></div>
                    <div><span>1시간 전</span></div>
                    <div className={`${admin.admin_seller_inspection_product_info}`}>
                        <div><img src="../../../img/album/album1.png" alt="상품이미지" /></div>
                        <div>
                            <div><span>Leon Bridges-Coming Home (deluxe Edition) - 1 LP vinyl</span></div>
                            <div><span>옵션명 2종</span></div>
                            <div><span>49,000&#8361;</span></div>
                        </div>
                    </div>
                    <div className='text-start'><span>*공식&gt;앨범&gt;오픈 예정</span></div>
                    <div><span>설정안함</span></div>
                    <div className={`${admin.admin_seller_inspection_btn_box}`}>
                        <div><span>판매중</span></div>
                    </div>
                </div>
            </div>
            <Pagination current={currentPage} total={totalItems} onChange={paginate} pageSize={itemsPerPage} />
        </div>
    </div>
  )
}

export default AdminSellerProductManagement