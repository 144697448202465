import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as auth from '../../../apis/auth';
import shop from './shop.module.css'
import { animated } from 'react-spring';
import { useSpring, useTransition } from '@react-spring/core';
import { Select } from 'antd';
import store from '../../../containers/market/store/store.module.css';
import * as storeapi from '../../../apis/store';
import { LoginContext } from '../../../contexts/LoginContextProvider';
import * as adminApi from '../../../apis/admin';

const { Option } = Select;

const ShopInfo = () => {

    const { partnerNo } = useParams();
    const navigate = useNavigate();
    const { userInfo, isLogin } = useContext(LoginContext);
    const [ shared, setShared ] = useState(false);
    const [ clipboardMessage, setClipboardMessage ] = useState('');
    const [ favorityState, setFavorityState ] = useState(false);
    const [ activeButton, setActiveButton ] = useState('product');
    const [ productList, setProductList ] = useState([]);
    const [ sortMethod, setSortMethod ] = useState('latest');
    const [ shopInfo, setShopInfo ] = useState({});
    const [columnClass, setColumnClass] = useState('col-6'); // 초기값 설정
    const [badgeList, setBadgeList] = useState([]); // 뱃지 목록 상태 추가
    const [filteredAndSortedProductList, setFilteredAndSortedProductList] = useState([]);

    useEffect(() => {
      const updateColumnClass = () => {
        if (window.innerWidth > 500) {
          setColumnClass('col-4'); // 화면이 500px 이상일 때
        } else {
          setColumnClass('col-6'); // 화면이 500px 이하일 때
        }
      };
  
      updateColumnClass();
      window.addEventListener('resize', updateColumnClass);
  
      return () => {
        window.removeEventListener('resize', updateColumnClass);
      };
    }, []);

    useEffect(() => {
        const getFilteredAndSortedProductList = () => {
            // 조건에 맞는 상품만 필터링
            const filteredProducts = productList.filter(
            (product) => product.saleState === "판매중" && product.enabledState === "승인완료"
            );
        
            // 정렬 로직
            return filteredProducts.sort((a, b) => {
            const priceA = a.productsalePrice ?? a.productPrice;
            const priceB = b.productsalePrice ?? b.productPrice;
        
            switch (sortMethod) {
                case 'latest':
                return new Date(b.regDate) - new Date(a.regDate);
                case 'popularity':
                return b.reviewRating - a.reviewRating;
                case 'lowprice':
                return priceA - priceB;
                case 'highprice':
                return priceB - priceA;
                default:
                return 0;
            }
            });
        };
        
        setFilteredAndSortedProductList(getFilteredAndSortedProductList());
    }, [sortMethod, productList]);

    const handleFavority = () => {
        setFavorityState(!favorityState);
    }
    
    // 선택 변경 이벤트 핸들러
    function handleSortChange(value) {
      setSortMethod(value);
    }

    // useEffect(() => {
    //     if (!Array.isArray(productList)) return;
      
    //     let sortedList = [...productList]; // productList를 직접 변경하지 않기 위해 복사
      
    //     switch (sortMethod) {
    //       case 'latest':
    //         sortedList.sort((a, b) => new Date(b.mallProduct.registerYmdt) - new Date(a.mallProduct.registerYmdt));
    //         break;
    //       case 'popularity':
    //         sortedList.sort((a, b) => b.mallProductOptionWebModels.saleCnt - a.mallProductOptionWebModels.saleCnt);
    //         break;
    //       case 'lowprice':
    //         sortedList.sort((a, b) => a.mallProduct.immediateDiscountApplyPrice - b.mallProduct.immediateDiscountApplyPrice);
    //         break;
    //       case 'highprice':
    //         sortedList.sort((a, b) => b.mallProduct.immediateDiscountApplyPrice - a.mallProduct.immediateDiscountApplyPrice);
    //         break;
    //       default:
    //         break;
    //     }
      
    //     setProductList(sortedList); // 정렬된 리스트로 상태 업데이트
    //   }, [sortMethod]);

    useEffect(() => {
        const shop = async () => {
            const response = await auth.shopInfo(partnerNo && partnerNo);
            const data = response.data;
    
            // console.log(data.shopInfo);
            // console.log(data.productList);
    
            // 조건에 맞는 상품만 필터링
            const filteredProducts = data.productList.filter(
                (product) => product.saleState === "판매중" && product.enabledState === "승인완료"
            );
    
            // 필터링된 결과를 상태에 저장
            setShopInfo(data.shopInfo);
            setProductList(filteredProducts);
        };
    
        if (partnerNo) {
            shop();
        }
    }, [partnerNo]);

    useEffect(() => {
        badgeSelect();
    }, []);

     // 뱃지 목록 가져오기
    const badgeSelect = async () => {
        const response = await adminApi.badgesSelect();
        const data = response.data;

        setBadgeList(data); // 뱃지 목록 상태에 저장
        // console.log('badgeSelect : ', data);
    };

    // productBadge에 해당하는 뱃지 스타일을 가져오는 함수
    const getBadgeStyle = (badgeName) => {
        const matchedBadge = Array.isArray(badgeList) && badgeList.find(
          (badge) => badge.korBadgeName === badgeName
        );
        return matchedBadge
          ? {
              color: matchedBadge.badgeFontColor,
              backgroundColor: matchedBadge.badgeBackColor,
            }
          : {};
      };

    const handleBack = () => {
        navigate(-1);
    }

    useEffect(() => {
        if (!window.Kakao.isInitialized()) {
          window.Kakao.init(process.env.REACT_APP_KAKAO_API_KEY);
        }
      }, []);

    const transitions = useTransition(shared, {
        from: { transform: "translateY(100%)" },
        enter: { transform: "translateY(0)" },
        leave: { transform: "translateY(100%)" },
        config: {
            duration: 300,
        },
    });

    const handleShare = () => {
        setShared(true);
    }
    const handleShardBack = () => {
        setShared(false);
    }
    const handleShareKakaoClick = () => {
        if (window.Kakao) {
            const kakao = window.Kakao;
        
            kakao.Share.sendDefault({
                objectType: 'feed',
                content: {
                title: `${shopInfo.shopName}`,
                description: '데일리덕에서 구매하고 포인트 받자!',
                imageUrl:
                    'https://dailyduck.com/img/kakaolink40_original.png',
                link: {
                    mobileWebUrl: `https://dailyduck.com/${shopInfo.userId}`,
                    webUrl: `https://dailyduck.com/${shopInfo.userId}`,
                },
                },
            });
        }
    }
    const handleTwitterShare = () => {
        const url = `https://dailyduck.com/${shopInfo.userId}`;
        const text = `데일리덕 ${shopInfo.shopName}의 상점입니다!`;
        const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
          text
        )}&url=${encodeURIComponent(url)}`;
        window.open(twitterUrl);
    };
    const handleNaverLineShare = () => {
        const url = `https://social-plugins.line.me/lineit/share?url=https://dailyduck.com/${shopInfo.userId}`
        window.open(url);
    }  

    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    const handleSmsShare = () => {
        const productLink = `https://dailyduck.com/${shopInfo.userId}`;
        const productName = `${shopInfo.shopName}`;
        const productDescription = `데일리덕 ${shopInfo.shopName}의 상점입니다!`;

        if (!isMobile) {
            alert("SMS 공유는 모바일 기기에서만 가능합니다.");
            return;
        }

        const message = encodeURIComponent(`${productName} - ${productDescription}\n링크: ${productLink}`);
        window.location.href = `sms:&body=${message}`;
    }
    const handleClipboardShare = async () => {
        const text = `https://dailyduck.com/${shopInfo.userId}`
        if ('clipboard' in navigator) {
            try {
                await navigator.clipboard.writeText(text);
                setClipboardMessage('클립보드에 복사되었습니다.');
                setTimeout(() => setClipboardMessage(''), 2000);
            } catch (err) {
                console.error('클립보드 복사에 실패했습니다: ', err);
            }
        } else {
            // Fallback for older browsers
            const textarea = document.createElement('textarea');
            textarea.value = text;
            document.body.appendChild(textarea);
            textarea.select();
            document.execCommand('copy');
            document.body.removeChild(textarea);
            setClipboardMessage('클립보드에 복사되었습니다.');
            setTimeout(() => setClipboardMessage(''), 2000);
          }
    }
    const handleMoreShare = () => {
        const productLink = `https://dailyduck.com/${shopInfo.userId}`;
        const productName = `test`;
        const productDescription = `데일리덕 ${shopInfo.shopName}의 상점입니다!`;

        if (navigator.share) {
            navigator.share({
            title: `${shopInfo.shopName}`,
            text: `${productDescription}`,
            url: `${productLink}`,
            })
            .then(() => alert('공유 성공!'))
            .catch((error) => alert('공유 실패:', error));
        } else {
            // Web Share API를 지원하지 않는 경우의 대체 처리
            alert('Web Share API를 지원하지 않는 브라우저입니다.');
        }
    }
    const onDetail = (productNo, index) => {
        navigate(`/store/product/${productNo}`, { state: {totalReviewCount : productList[index].totalReviewCount, reviewRating : productList[index].reviewRating, immediateDiscountUnitType : productList[index].immediateDiscountUnitType}});
    }

    
    const formatRating = (rating) => {
        return rating.toFixed(1); // 소수점 첫째 자리까지 표시하도록 고정
    };

    const isSoldOut = (item) => {
        // inventoryCnt가 0인 경우
        if (item.inventoryCnt === 0) {
          // optionValueList가 배열인지 확인하고, optionInventory가 모두 0 또는 null인지 확인
          if (
            Array.isArray(item.optionValueList) &&
            item.optionValueList.every(option => option.optionInventory === 0 || option.optionInventory === null)
          ) {
            return true;
          }
        }
        return false;
    };
      
    const onmessage = async () => {
        if(isLogin) {
            const response = await storeapi.messageCheck(null, userInfo && userInfo.userId, shopInfo && shopInfo.userId);
            const data = response.data;
    
            // console.log(data);
    
            if(data != 'FAIL') {
                navigate(`/message/chat/${data}`);
            }
        } else {
            alert('로그인 후 이용가능합니다.');
            navigate('/');
        }
    }
    
  return (
    <>
        <div className={`${shop.shop_info_container}`}>
            <div className={`${shop.shop_info_header}`}>
                <div className={`${shop.shop_info_header_left}`}><button onClick={() => handleBack()}><span className="material-symbols-outlined">arrow_back_ios</span></button></div>
                <div className={`${shop.shop_info_header_right}`}><button><span className="material-symbols-outlined" onClick={() => handleShare()}>share</span></button></div>
            </div>
            <div className={`${shop.shop_main_banner_box}`}>
                <div className={`${shop.shop_main_banner_img_box}`}>
                    <img src={shopInfo.shopBackImg ? shopInfo.shopBackImg : `${process.env.PUBLIC_URL}/img/shop/default_banner.png`} alt="기본이미지" />
                    <div className={`${shop.shop_main_banner_favority_box}`}>
                    {
                        !favorityState ? 
                        <div><button onClick={() => handleFavority()}><img src={`${process.env.PUBLIC_URL}/img/icon/venue/white_heart.png`} alt="하트" /></button></div>
                        :
                        <div><button onClick={() => handleFavority()}><img src={`${process.env.PUBLIC_URL}/img/icon/venue/heart_fill.png`} alt="하트필" /></button></div>
                    }
                    </div>
                    <div className={`${shop.shop_main_banner_profile_box}`}><img src={shopInfo.shopProfileImg ? shopInfo.shopProfileImg : `${process.env.PUBLIC_URL}/img/shop/default_profile.png`} alt="기본이미지" /></div>
                </div>
                
            </div>
            <div className={`${shop.shop_info_content_box}`}>
                <div className='d-flex justify-content-between'>
                    <div className={`${shop.shop_info_content_name}`}><span>{shopInfo.shopName}</span><br/><span>@</span><span>{shopInfo.userId}</span></div>
                    <div className={`${shop.shop_info_centent_message_btn}`}>
                        <button className='d-flex align-items-center position-relative' onClick={() => onmessage()}>
                            <div><img src="../../img/shop/message.png" alt="메세지" /></div>
                            <div><span>메세지</span></div>
                        </button>
                    </div>
                </div>
                <div className='py-4'>
                    <span>{shopInfo.shopDetailContent}</span>
                </div>
            </div>
            <div className='px-3'>
                <div className={`${shop.shop_tab_btn_box}`}>
                    <div className={`w-100 ${activeButton === 'product' ? shop.shop_active : ''}`}><button className={``} onClick={() => setActiveButton('product')}>상품</button></div>
                    <div className={`w-100 ${activeButton === 'review' ? shop.shop_active : ''}`}><button className={``} onClick={() => setActiveButton('review')}>리뷰</button></div>
                </div>
            </div>
            {
                activeButton === 'product' &&
                <div className='d-flex justify-content-between px-3 py-3'>
                    <div className='d-flex'>
                        <div className='pe-2'><span>전체</span></div>
                        <div><span>{productList && productList.length}</span></div>
                    </div>
                    <div>
                    <Select className={`shop_select`} placeholder="최신순" onChange={handleSortChange}>
                        <Option value='latest'>최신순</Option>
                        <Option value='popularity'>인기순</Option>
                        <Option value='lowprice'>낮은가격순</Option>
                        <Option value='highprice'>높은가격순</Option>
                    </Select>
                    </div>
                </div>
            }
            <div className={`row py-3 ${shop.shop_info_content}`}>
                {
                    activeButton === 'product' &&
                    Array.isArray(filteredAndSortedProductList) && filteredAndSortedProductList.map((item, index) => {
                        
                        const badgeStyle = getBadgeStyle(item.productBadge); // 스타일 가져오기

                        return (
                            <div key={index} className={`${store.store_item_list_box} ${columnClass}`}>
                            <div className={`${store.store_item_list}`} onClick={() => onDetail(item.productNo, index)}>
                            <div className={`${store.store_item_list_img_box}`}>
                                {isSoldOut(item) && (
                                    <div className={`${store.store_stockCnt_zero_box}`}>
                                        <div
                                        className={`${store.store_stockCnt_zero}`}
                                        onClick={() => onDetail(item.productNo, index)}
                                        >
                                        <span>품절</span>
                                        </div>
                                    </div>
                                )}
                                <img src={item.productMainImage} alt="" />
                            </div>
                            <div className={`py-1 ${store.store_gray} ${store.store_fs_small}`}><span>{item.shopName}</span></div>
                            <div className={`${store.store_item_list_productname} ${store.store_fs_middle}`}><span>{item.productName}</span></div>
                            {item.productsalePrice !== item.productPrice ? (
                                <div className='d-flex pt-2 align-items-center'>
                                    {item.productsalePrice !== null ? (
                                    <div className={`${store.store_item_list_discount_rate}`}>
                                        <span>{item.discountRate?.toLocaleString()}%</span>
                                    </div>
                                    ) : null}
                                    {item.productsalePrice !== null ? (
                                    <div className={`${store.store_item_list_discount_price}`}>
                                        <span>&#8361;{item.productsalePrice?.toLocaleString()}</span>
                                    </div>
                                    ) : (
                                    <div className={`${store.store_item_list_discount_price}`}>
                                        <span>&#8361;{item.productPrice?.toLocaleString()}</span>
                                    </div>
                                    )}
                                </div>
                                ) : (
                                <div className='d-flex py-2 align-items-center'>
                                    <div className={`${store.store_item_list_discount_price}`}>
                                    <span>&#8361;{item.productsalePrice !== null ? item.productsalePrice.toLocaleString() : item.productPrice?.toLocaleString()}</span>
                                    </div>
                                </div>
                                )}
                                <div className={`${store.store_item_list_badge_review}`}>
                                    <div className='d-flex align-items-center justify-content-start'>
                                    <div
                                        className={`${store.star_iconImg} d-flex align-items-center justify-content-start`}
                                    >
                                        <img src='../img/icon/star_fill.png' alt='별점' />
                                    </div>
                                    <div className='d-flex align-items-center justify-content-start ps-1'>
                                        <span className='star_grade'>
                                        {formatRating(item.reviewRating)}({item.reviewCount})
                                        </span>
                                    </div>
                                    </div>
                                    {item.productBadge !== null && (
                                    <div className='pt-1'>
                                        <div
                                        className={`${store.store_item_list_label}`}
                                        style={{
                                            ...badgeStyle
                                        }}
                                        >
                                        <span>{item.productBadge}</span>
                                        </div>
                                    </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        )
                    })
                }
            </div>
            {
                shared &&
                <div className={`${shop.shop_shared_background}`} >
                    {transitions((style, item) =>
                        item ? 
                        <animated.div className={`${shop.shop_shard_box}`} style={style}>
                            <div className='pt-5 px-3 d-flex justify-content-between'>
                                <div><h5>공유하기</h5></div>
                                <div><button className={`${shop.shop_shared_close}`} onClick={handleShardBack}><span className="material-symbols-outlined">close</span></button></div>
                            </div>
                            <div className='d-flex justify-content-center py-3'>
                                <div className={`${shop.shop_shard_btn_box}`}>
                                    <button onClick={handleShareKakaoClick}>
                                        <div>
                                            <div><img src="../../img/icon/share/kakaotalk.png" alt="카카오톡" /></div>
                                            <div className='py-1'><span>카카오톡</span></div>
                                        </div>
                                    </button>
                                </div>
                                <div className={`${shop.shop_shard_btn_box}`}>
                                    <button onClick={handleTwitterShare}>
                                        <div>
                                            <div><img src="../../img/icon/share/twiter.png" alt="트위터" /></div>
                                            <div className='py-1'><span>트위터</span></div>
                                        </div>
                                    </button>
                                </div>
                                <div className={`${shop.shop_shard_btn_box}`}>
                                    <button onClick={handleNaverLineShare}>
                                        <div>
                                            <div><img src="../../img/icon/share/line.png" alt="라인" /></div>
                                            <div className='py-1'><span>라인</span></div>
                                        </div>
                                    </button>
                                </div>
                                <div className={`${shop.shop_shard_btn_box}`}>
                                    <button onClick={handleSmsShare}>
                                        <div>
                                            <div><img src="../../img/icon/share/message.png" alt="SMS" /></div>
                                            <div className='py-1'><span>SMS</span></div>
                                        </div>
                                    </button>
                                </div>
                            </div>
                            <div className='d-flex'>
                                <div className={`${shop.shop_shard_btn_bottom_box}`}>
                                    <button onClick={() => handleClipboardShare()}>
                                        <div>
                                            <div><img src="../../img/icon/share/url.png" alt="" /></div>
                                            <div><span>URL</span></div>
                                        </div>
                                    </button>
                                </div>
                                <div className={`${shop.shop_shard_btn_bottom_box}`}>
                                    <button onClick={handleMoreShare}>
                                        <div>
                                            <div><img src="../../img/icon/share/more.png" alt="" /></div>
                                            <div><span>더보기</span></div>
                                        </div>
                                    </button>
                                </div>
                            </div>
                            {
                                clipboardMessage !== '' ? 
                                <div className={`${shop.shop_clipboard_box}`}><span>{clipboardMessage}</span></div>
                                :
                                null
                            }
                        </animated.div>
                        : null
                    )}
                </div>
            }
        </div>
    </>
  )
}

export default ShopInfo