import React, { useEffect, useState } from 'react';
import event from '../../containers/event/event.module.css';
import store from '../../containers/market/store/store.module.css';
import { useNavigate, useParams } from 'react-router-dom';
import * as storeApi from '../../apis/store';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay, EffectFade } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';

const EventPlan = () => {
  const { eventNumber } = useParams();
  const navigate = useNavigate();
  const [eventInfo, setEventInfo] = useState({});
  const [planProduct, setPlanProduct] = useState([]);
  const [banner, setBanner] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [columnClass, setColumnClass] = useState('col-6'); // 초기값 설정

  useEffect(() => {
    // console.log('eventNumber : ', eventNumber);
    planRead();
  }, [eventNumber]);

  useEffect(() => {
    const updateColumnClass = () => {
      if (window.innerWidth > 500) {
        setColumnClass('col-4'); // 화면이 500px 이상일 때
      } else {
        setColumnClass('col-6'); // 화면이 500px 이하일 때
      }
    };

    updateColumnClass();
    window.addEventListener('resize', updateColumnClass);

    return () => {
      window.removeEventListener('resize', updateColumnClass);
    };
  }, []);

  useEffect(() => {
    if(eventInfo) {
      setBanner([
        eventInfo.eventImage1,
        eventInfo.eventImage2,
        eventInfo.eventImage3,
        eventInfo.eventImage4,
        eventInfo.eventImage5,
      ]);
    }
  }, [eventInfo])

  const planRead = async () => {
    const response = await storeApi.planRead(eventNumber);
    const data = response.data;

    // console.log(data);
    setEventInfo(data.eventDTO);
    setPlanProduct(data.planProductList);
  }

  const handleBack = () => {
    navigate(-1);
  }

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const onDetaile = (item) => {
    // console.log('item : ', item);
    navigate(`/plan/product/${item.productNumber}`, { state : {product : item, rate : eventInfo.planProductRate}});
  }

  const calculateSalePrice = (item) => {
    // return item.productPrice - (item.productPrice * (item.eventRate * 0.01));
    return item.eventRatePrice ? item.eventRatePrice : item.productsalePrice ? item.productsalePrice : item.productPrice;
  }

  const isSoldOut = (item) => {
    // inventoryCnt가 0인 경우
    if (item.inventoryCnt === 0) {
      // optionValueList가 배열인지 확인하고, optionInventory가 모두 0 또는 null인지 확인
      if (
        Array.isArray(item.optionValueList) &&
        item.optionValueList.every(option => option.optionInventory === 0 || option.optionInventory === null)
      ) {
        return true;
      }
    }
    return false;
  };

  return (
    <div>
      <div>
        <div className={`${event.event_plan_haeader}`}>
          <div>
            <button onClick={handleBack}><span className="material-symbols-outlined">arrow_back_ios</span></button>
          </div>
          <div><span>기획전</span></div>
        </div>
        <div className={`${event.event_plan_box}`}>
          <div>
            <Swiper
              slidesPerView={1}
              spaceBetween={30}
              loop={true}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              effect="fade"
              fadeEffect={{ crossFade: true }}
              speed={2000} // 여기에서 페이드인 아웃 효과의 속도를 설정합니다.
              modules={[Pagination, Navigation, Autoplay, EffectFade]}
              className={`${event.main_banner_swiper}`}
            >
              {banner.map((item, index) => (
                <SwiperSlide key={index}>
                  <div className={`${event.event_main_banner}`}>
                    <img src={item} alt="배너이미지2" className='w-100' />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className={`${event.event_plan_content}`} style={{ height: isExpanded ? 'auto' : '350px', overflow: 'hidden' }}>
          <div dangerouslySetInnerHTML={{ __html: eventInfo.eventContent }}></div>
        </div>
        <div className={`${event.event_plan_content_btn_box}`}>
          <button onClick={toggleExpand}>{isExpanded ? '접기' : '자세히 보기'}</button>
        </div>
        <div>
          <div className={`${event.event_plan_title}`}><span>{eventInfo.eventTitle}</span></div>
          {/* {
            eventInfo.planType === 'priceDiscount' &&
            <div className={`${event.event_plan_event_type}`}>
              <div><span>전 품목 {eventInfo.planProductRate}% 할인</span></div>
            </div>
          }
          {
            eventInfo.planType === 'deliveryFree' &&
            <div className={`${event.event_plan_event_type}`}>
              <div><span>전 품목 배송비 할인</span></div>
            </div>
          } */}
          <div className='row px-2 pb-3'>
              {
                planProduct.map((item, index) => (
                  <div className={`${columnClass} ${event.event_plan_product_item}`} key={index} onClick={() => onDetaile(item)}>
                    <div>
                    {isSoldOut(item) && (
                        <div className={`${store.store_stockCnt_zero_box}`}>
                          <div
                            className={`${store.store_stockCnt_zero}`}
                            onClick={() => onDetaile(item)}
                          >
                            <span>품절</span>
                          </div>
                        </div>
                        )}
                      <img src={item.productMainImage} alt="" />
                    </div>
                    <div><span>상점명</span></div>
                    <div><span>{item.productName}</span></div>
                    <div>
                      {
                        item.eventRate !== 0 &&
                        <div className={`${event.event_plan_product_item_event_rate}`}><span>{item.eventRate}%</span></div>
                      }
                      {
                        item.planType === 'deliveryFree' && item.discountRate !== 0 &&
                        <div className={`${event.event_plan_product_item_discount_rate}`}><span>{item.discountRate}%</span></div>
                      }
                      <div className={`${event.event_plan_product_item_event_price}`}><span>{calculateSalePrice(item).toLocaleString()}원</span></div>
                      {
                        item.productsalePrice !== item.productPrice &&
                        <div className={`${event.event_plan_product_item_event_principal}`}><span>{item.productPrice.toLocaleString()}원</span></div>
                      }
                      </div>
                  </div>
                ))
              }
          </div>
        </div>
        </div>
      </div>
    </div>
  );
}

export default EventPlan;
