import React from 'react';
import { FunctionComponent, useEffect, useState } from "react";

export const usePreventLeave = (global = false) => {
    const [prevent, setPrevent] = useState(false);
   
    useEffect(() => {
      if (!global) return;
   
      window.history.pushState(null, '', window.location.href);
      window.addEventListener('popstate', handlePopstate);
      return () => {
        window.removeEventListener('popstate', handlePopstate);
      };
    }, [global]);
   
    const handleBeforeUnload = (event: Event) => {
      event.preventDefault();
      event.returnValue = false; // Chrome requires returnValue to be set.
    };
    const handlePopstate = () => setPrevent(true);
    const handlePopupClose = () => {
      window.history.pushState(null, '', window.location.href);
      setPrevent(false);
    };
    const handlePopupLeave = (onLeave: () => void) => {
      setPrevent(false);
      onLeave();
    };
    const preventLeave = (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();
      setPrevent(true);
    };
    const onPreventLeave = () => {
      window.history.pushState(null, '', window.location.href);
      window.addEventListener('popstate', handlePopstate);
      window.addEventListener('beforeunload', handleBeforeUnload);
    };
    const offPreventLeave = () => {
      window.removeEventListener('popstate', handlePopstate);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
   
    const PreventPopup: FunctionComponent<{ onLeave: () => void }> = ({
      onLeave,
    }) => (
      <>
        {prevent && (
          <div>
            <h1>페이지을 떠나시겠습니까?</h1>
            <button onClick={handlePopupClose}>아니요</button>
            <button onClick={() => handlePopupLeave(onLeave)}>네</button>
          </div>
        )}
      </>
    );
   
    return { preventLeave, PreventPopup, onPreventLeave, offPreventLeave };
  };