import React, { useState } from 'react'
import { CSSTransition } from 'react-transition-group';

const StoreSellerSide = ( {
                            seller, activeMenu, handleMenuClick, activeBoard, setActiveBoard, activeSettlement, setActiveSettlement, changeActiveBoard, 
                            changeActiveSettlement, showReviewModal,activeShopManagement, setActiveShopManagement, changeActiveShopManagement
                        } ) => {

    const [openStates, setOpenStates] = useState(false);
    const [openSettlementStates, setOpenSettlementStates] = useState(false);
    const [openShopState, setOpenShopState] = useState(false);

    const toggleOpen = () => {setOpenStates(!openStates);};
    const toggleSettlementOpen = () => {setOpenSettlementStates(!openSettlementStates);};
    const toggleShopOpen = () => {setOpenShopState(!openShopState);};

  return (
    <div className={`${seller.seller_side_container}`} 
     style={{ ...(showReviewModal ? { height: '100vh', overflowY: 'hidden' } : {}) }}>
        {/* 유저 정보 (아이콘, 아이디, 권한명) */}
        <div>
            <div className='d-flex pt-4 px-3 pb-5 align-items-center'>
                <div><img src="../../img/seller/icon/profile_circle.png" alt="기본이미지" /></div>
                <div className='ps-2'>
                    <div><span>selleradmin</span></div>
                    <div className='dd-fs-7'><span>스토어 관리자</span></div>
                </div>
            </div>
        </div>

        {/* Menu */}
        <div className='px-3'>
            <div><span>스토어</span></div>
            <div className='pt-2 pb-4 border-bottom'>
                <div className='py-2 w-100'>
                    <button className={`${seller.seller_menu_btn} d-flex align-items-center py-3 px-3 ${activeMenu === 'dashboard' ? seller.seller_menu_active : ''}`}
                            onClick={() => handleMenuClick('dashboard')}            
                    >
                        {/* Icon Img */}
                        <div className={`${seller.seller_menu_icon_box}`}><img src="../../img/seller/icon/dashboard.png" alt="대시보드" /></div>
                        {/* Menu Name */}
                        <div className={`${seller.seller_menu_name_box} ps-3`}><span>대시보드</span></div>
                    </button>
                </div>
                <div className='py-2 w-100'>
                    <button className={`${seller.seller_menu_btn} d-flex align-items-center py-3 px-3 ${activeMenu === 'board' ? seller.seller_menu_active : ''}`} 
                            onClick={() => { handleMenuClick('board'); toggleOpen();}}
                    >
                        {/* Icon Img */}
                        <div className={`${seller.seller_menu_icon_box}`}><img src="../../img/seller/icon/board.png" alt="게시판" /></div>
                        {/* Menu Name */}
                        <div className={`${seller.seller_menu_name_box} ps-3`}><span>게시판</span></div>
                        {/* allow Icon */}
                        <div className={`${seller.seller_allow_icon}`}>
                            <span className="material-symbols-outlined">{openStates ? 'expand_less' : 'expand_more'}</span>
                        </div>
                    </button>
                </div>
                {/* 펼쳐지는 영역 시작 */}
                <CSSTransition in={openStates} timeout={500} classNames="review" unmountOnExit>
                    <div className='px-5 w-100'>
                        <div className='w-100 py-2'><button className={`${seller.seller_board_toggle_menu_btn} ${activeBoard=='board' ? seller.seller_signature_color : ''}`} onClick={() => {setActiveBoard('board'); changeActiveBoard('board')}}>공지사항</button></div>
                        <div className='w-100 py-2'><button className={`${seller.seller_board_toggle_menu_btn} ${activeBoard=='faq' ? seller.seller_signature_color : ''}`} onClick={() => {setActiveBoard('faq'); changeActiveBoard('faq')}}>FAQ</button></div>
                    </div>
                </CSSTransition>
                <div className='py-2 w-100'>
                    <button className={`${seller.seller_menu_btn} d-flex align-items-center py-3 px-3 ${activeMenu === 'settlement' ? seller.seller_menu_active : ''}`} 
                            onClick={() => {handleMenuClick('settlement'); toggleSettlementOpen();}}
                    >
                        {/* Icon Img */}
                        <div className={`${seller.seller_menu_icon_box}`}><img src="../../img/seller/icon/settlement_management.png" alt="정산관리" /></div>
                        {/* Menu Name */}
                        <div className={`${seller.seller_menu_name_box} ps-3`}><span>정산 관리</span></div>
                        {/* allow Icon */}
                        <div className={`position-absolute ${seller.seller_allow_icon}`}>
                            <span className="material-symbols-outlined">{openSettlementStates ? 'expand_less' : 'expand_more'}</span>
                        </div>
                    </button>
                </div>
                {/* 펼쳐지는 영역 시작 */}
                <CSSTransition in={openSettlementStates} timeout={500} classNames="review" unmountOnExit>
                    <div className='px-5 w-100'>
                        <div className='w-100 py-2'><button className={`${seller.seller_board_toggle_menu_btn} ${activeSettlement=='proceeds' ? seller.seller_signature_color : ''}`} onClick={() => {setActiveSettlement('proceeds'); changeActiveSettlement('proceeds');}}>수익금 내역</button></div>
                        <div className='w-100 py-2'><button className={`${seller.seller_board_toggle_menu_btn} ${activeSettlement=='calculate' ? seller.seller_signature_color : ''}`} onClick={() => {setActiveSettlement('calculate'); changeActiveSettlement('calculate')}}>정산 내역</button></div>
                    </div>
                </CSSTransition>
                <div className='py-2 w-100'>
                    <button className={`${seller.seller_menu_btn} d-flex align-items-center py-3 px-3 ${activeMenu === 'review' ? seller.seller_menu_active : ''}`} 
                            onClick={() => handleMenuClick('review')}
                    >
                        {/* Icon Img */}
                        <div className={`${seller.seller_menu_icon_box}`}><img src="../../img/seller/icon/review_management.png" alt="리뷰관리" /></div>
                        {/* Menu Name */}
                        <div className={`${seller.seller_menu_name_box} ps-3`}><span>리뷰 관리</span></div>
                    </button>
                </div>
            </div>
            <div className='pt-3 pb-3 border-bottom'>
                <div className='py-2 w-100'>
                    <button className={`${seller.seller_menu_btn} d-flex align-items-center py-3 px-3 ${activeMenu === 'store' ? seller.seller_menu_active : ''}`} 
                            onClick={() => {handleMenuClick('store'); toggleShopOpen();}}
                    >
                        {/* Icon Img */}
                        <div className={`${seller.seller_menu_icon_box}`}><img src="../../img/seller/icon/store_management.png" alt="상점관리" /></div>
                        {/* Menu Name */}
                        <div className={`${seller.seller_menu_name_box} ps-3`}><span>상점 관리</span></div>
                        {/* allow Icon */}
                        <div className={`position-absolute ${seller.seller_allow_icon}`}>
                            <span className="material-symbols-outlined">{openShopState ? 'expand_less' : 'expand_more'}</span>
                        </div>
                    </button>
                </div>
                {/* 펼쳐지는 영역 시작 */}
                <CSSTransition in={openShopState} timeout={500} classNames="review" unmountOnExit>
                    <div className='px-5 w-100'>
                        <div className='w-100 py-2'><button className={`${seller.seller_board_toggle_menu_btn} ${activeShopManagement=='profile' ? seller.seller_signature_color : ''}`} onClick={() => {setActiveShopManagement('profile'); changeActiveShopManagement('profile');}}>프로필</button></div>
                        <div className='w-100 py-2'><button className={`${seller.seller_board_toggle_menu_btn} ${activeShopManagement=='settlementinfo' ? seller.seller_signature_color : ''}`} onClick={() => {setActiveShopManagement('settlementinfo'); changeActiveShopManagement('settlementinfo')}}>정산 정보</button></div>
                        <div className='w-100 py-2'><button className={`${seller.seller_board_toggle_menu_btn} ${activeShopManagement=='businessinfo' ? seller.seller_signature_color : ''}`} onClick={() => {setActiveShopManagement('businessinfo'); changeActiveShopManagement('businessinfo')}}>사업자 정보</button></div>
                    </div>
                </CSSTransition>
                <div className='py-2 w-100'>
                    <button className={`${seller.seller_menu_btn} d-flex align-items-center py-3 px-3 ${activeMenu === 'sales' ? seller.seller_menu_active : ''}`}
                            onClick={() => handleMenuClick('sales')}
                    >
                        {/* Icon Img */}
                        <div className={`${seller.seller_menu_icon_box}`}><img src="../../img/seller/icon/sales_policy.png" alt="판매정책" /></div>
                        {/* Menu Name */}
                        <div className={`${seller.seller_menu_name_box} ps-3`}><span>판매 정책</span></div>
                        {/* allow Icon */}
                        <div className={`position-absolute ${seller.seller_allow_icon}`}>
                            <span className="material-symbols-outlined">expand_more</span>
                        </div>
                    </button>
                </div>
                <div className='py-2 w-100'>
                    <button className={`${seller.seller_menu_btn} d-flex align-items-center py-3 px-3 ${activeMenu === 'edit' ? seller.seller_menu_active : ''}`} 
                            onClick={() => handleMenuClick('edit')}
                    >
                        {/* Icon Img */}
                        <div className={`${seller.seller_menu_icon_box}`}><img src="../../img/seller/icon/edit_square.png" alt="상품등록" /></div>
                        {/* Menu Name */}
                        <div className={`${seller.seller_menu_name_box} ps-3`}><span>상품 등록</span></div>
                    </button>
                </div>
                <div className='py-2 w-100'>
                    <button className={`${seller.seller_menu_btn} d-flex align-items-center py-3 px-3 ${activeMenu === 'product' ? seller.seller_menu_active : ''}`} 
                            onClick={() => handleMenuClick('product')}
                    >
                        {/* Icon Img */}
                        <div className={`${seller.seller_menu_icon_box}`}><img src="../../img/seller/icon/product_category.png" alt="상품관리" /></div>
                        {/* Menu Name */}
                        <div className={`${seller.seller_menu_name_box} ps-3`}><span>상품 관리</span></div>
                    </button>
                </div>
                <div className='py-2 w-100'>
                    <button className={`${seller.seller_menu_btn} d-flex align-items-center py-3 px-3 ${activeMenu === 'order' ? seller.seller_menu_active : ''}`} 
                            onClick={() => handleMenuClick('order')}
                    >
                        {/* Icon Img */}
                        <div className={`${seller.seller_menu_icon_box}`}><img src="../../img/seller/icon/order_management.png" alt="주문관리" /></div>
                        {/* Menu Name */}
                        <div className={`${seller.seller_menu_name_box} ps-3`}><span>주문 관리</span></div>
                    </button>
                </div>
                <div className='py-2 w-100'>
                    <button className={`${seller.seller_menu_btn} d-flex align-items-center py-3 px-3 ${activeMenu === 'cancel' ? seller.seller_menu_active : ''}`} 
                            onClick={() => handleMenuClick('cancel')}
                    >
                        {/* Icon Img */}
                        <div className={`${seller.seller_menu_icon_box}`}><img src="../../img/seller/icon/cancel_management.png" alt="취소관리" /></div>
                        {/* Menu Name */}
                        <div className={`${seller.seller_menu_name_box} ps-3`}><span>취소 관리</span></div>
                    </button>
                </div>
                <div className='py-2 w-100'>
                    <button className={`${seller.seller_menu_btn} d-flex align-items-center py-3 px-3 ${activeMenu === 'return' ? seller.seller_menu_active : ''}`} 
                            onClick={() => handleMenuClick('return')}
                    >
                        {/* Icon Img */}
                        <div className={`${seller.seller_menu_icon_box}`}><img src="../../img/seller/icon/return_management.png" alt="반품관리" /></div>
                        {/* Menu Name */}
                        <div className={`${seller.seller_menu_name_box} ps-3`}><span>반품 관리</span></div>
                    </button>
                </div>
                <div className='py-2 w-100'>
                    <button className={`${seller.seller_menu_btn} d-flex align-items-center py-3 px-3 ${activeMenu === 'swap' ? seller.seller_menu_active : ''}`} 
                            onClick={() => handleMenuClick('swap')}
                    >
                        {/* Icon Img */}
                        <div className={`${seller.seller_menu_icon_box}`}><img src="../../img/seller/icon/swap_management.png" alt="교환관리" /></div>
                        {/* Menu Name */}
                        <div className={`${seller.seller_menu_name_box} ps-3`}><span>교환 관리</span></div>
                    </button>
                </div>
            </div>
            <div className={`${seller.seller_footer_btn_box} px-5 py-3`}>
                <div className='py-2'><button>비밀번호 재설정</button></div>
                <div className='py-2'><button>로그아웃</button></div>
            </div>
        </div>
    </div>
  )
}

export default StoreSellerSide