import React, { useEffect, useLayoutEffect, useState } from 'react';
import admin from '../../../admin.module.css';
import { Select } from 'antd';
import { Option } from 'antd/es/mentions';
import { useLocation, useNavigate, useBlocker, useParams} from 'react-router-dom';
import { usePrompt } from './use-prompt.ts';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { usePreventLeave } from './usePreventLeave.tsx';
import seller from '../../../../seller/seller.module.css';
import * as adminApi from '../../../../apis/admin.js';
import * as support from '../../../../apis/support.js';

const AdminSellerUpdate = () => {
    const {no} = useParams();
    const navigate = useNavigate();
    const MySwal = withReactContent(Swal);
    const [ userInfo, setUserInfo] = useState({});
    const [isDirty, setIsDirty] = useState(false);
    const { preventLeave, PreventPopup, onPreventLeave, offPreventLeave } = usePreventLeave(false);
    const [ activeBankStock, setActiveBankStock ] = useState('bank');
    const [ bankList, setBankList ] = useState([]);
    const [ stockList, setStockList ] = useState([]);
    const [ showBankSelect, setShowBankSelect ] = useState(false);
    const [ update, setUpdate ] = useState(false);
    const [ selectBank, setSelectBank ] = useState({bankImg : '', bankName : ''});
    const [ idCheckText, setIdCheckText ] = useState('');
    const [ idCheckState, setIdCheckState ] = useState(false);
    const [ idCheckConfirm, setIdCheckConfirm ] = useState(false);
    const [ idCheckColor, setIdCheckColor ] = useState('');
    const [isFocused, setIsFocused] = useState(false);
    const [isPasswordValid, setIsPasswordValid] = useState({
        hasEnglish: false,
        hasNumber: false,
        hasSpecialChar: false,
        isValidLength: false,
    });
    
    const [hasInput, setHasInput] = useState(false); // 사용자 입력 추적 상태
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState(false); // 비밀번호 오류 상태
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [passwordConfirmCheck, setPasswordConfirmCheck] = useState(false);
    const [passwordConfirmCheckText, setPasswordConfirmCheckText] = useState('');
    const [passwordConfirmCheckColor, setPasswordConfirmCheckColor] = useState('');

    const [ emailCheckText, setEmailCheckText ] = useState('');
    const [ emailCheckState, setEmailCheckState ] = useState(false);
    const [ emailCheckConfirm, setEmailCheckConfirm ] = useState(false);
    const [ emailCheckColor, setEmailCheckColor ] = useState('');

    const [ sellerInfoList, setSellerInfoList ] = useState(
        {
          userNo: '', userId: ``, userPw: '', userPwConfirm: '', userEmail: '', businessRegistrationNumber : '', representativeName : '',
          businessStatus : '', businessSectors : '', businessName : '', businessLocation : '', businessBankbookImg : '', 
          businessMailOrderNumber : '', businessMailOrderImg : '', userClassification : ''
        }
    );
    const [ shopInfo, setShopInfo ] = useState({});

    useEffect(() => {
        // console.log('userInfo : ', userInfo);
        // console.log('shopInfo : ', shopInfo);
    }, [userInfo, shopInfo]);

    useEffect(() => {
        userSelect();
        shopSelect();
    }, [no]);

    const userSelect = async () => {
        const response = await adminApi.noUserSelect(no);
        const data = response.data;

        // console.log('userSelect : ', data);
        setUserInfo(data);
    }
    const shopSelect = async () => {
        const response = await adminApi.noShopSelect(no);
        const data = response.data;

        // console.log('shopSelect : ', data);
        setShopInfo(data);

        setSelectBank({
            ...selectBank, bankImg : data.shopBankImg, bankName : data.shopBankName
        });
    }

    const reset = () => {
        setSelectBank({bankImg : '', bankName : ''});
        setIdCheckText('');
        setIdCheckState(false);
        setIdCheckConfirm(false);
        setIdCheckColor('');
        setIsFocused(false);
        setIsPasswordValid({
            hasEnglish: false,
            hasNumber: false,
            hasSpecialChar: false,
            isValidLength: false,
        });
        
        setHasInput(false); // 사용자 입력 추적 상태
        setPassword("");
        setPasswordError(false); // 비밀번호 오류 상태
        setPasswordConfirm('');
        setPasswordConfirmCheck(false);
        setPasswordConfirmCheckText('');
        setPasswordConfirmCheckColor('');
    
        setEmailCheckText('');
        setEmailCheckState(false);
        setEmailCheckConfirm(false);
        setEmailCheckColor('');
    
        setSellerInfoList(
            {
              userNo: '', userId: ``, userPw: '', userPwConfirm: '', userEmail: '', businessRegistrationNumber : '', representativeName : '',
              businessStatus : '', businessSectors : '', businessName : '', businessLocation : '', businessBankbookImg : '', 
              businessMailOrderNumber : '', businessMailOrderImg : '', userClassification : ''
            }
        );
        setShopInfo({})
    }
  

    // 사용자가 페이지를 이탈하려고 할 때 경고 메시지를 표시합니다.
    // 'isDirty' 상태가 true일 때만 경고 메시지가 표시되도록 설정합니다.
    usePrompt('변경 사항이 저장되지 않았습니다. 페이지를 벗어나시겠습니까?', isDirty);

    useEffect(() => {
        // console.log('sellerInfoList : ', sellerInfoList);
        // console.log('shopInfo : ', shopInfo);
    }, [sellerInfoList, shopInfo])
  
    // 예시: 양식에 입력이 있을 때 'isDirty' 상태를 true로 설정하는 함수
    const onSellerId = (e) => {

        if(e.target.value !== '') {
            setIsDirty(true);

            setUserInfo({
                ...userInfo, userId : e.target.value
            });

            setShopInfo({
                ...shopInfo, userId : e.target.value
            });
        }
    }

    useEffect(() => {
        setBankList([
          {bankImg : `${process.env.PUBLIC_URL}/img/bank/kb.jpg`, bankName : '국민은행'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/ibk.jpg`, bankName : '기업은행'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/nh.jpg`, bankName : '농협은행'},
          {bankImg : `${process.env.PUBLIC_URL}/img/bank/shinhan.jpg`, bankName : '신한은행'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/koreapost.jpg`, bankName : '우체국'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/sc.jpg`, bankName : 'SC제일은행'},
          {bankImg : `${process.env.PUBLIC_URL}/img/bank/hana.jpg`, bankName : '하나은행'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/citi.jpg`, bankName : '한국씨티은행'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/woori.jpg`, bankName : '우리은행'},
          {bankImg : `${process.env.PUBLIC_URL}/img/bank/bnk.jpg`, bankName : '경남은행'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/kjb.jpg`, bankName : '광주은행'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/dgb.jpg`, bankName : '대구은행'},
          {bankImg : `${process.env.PUBLIC_URL}/img/bank/deutsche.jpg`, bankName : '도이치은행'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/bnk.jpg`, bankName : '부산은행'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/kdb.jpg`, bankName : '산업은행'},
          {bankImg : `${process.env.PUBLIC_URL}/img/bank/suhyup.jpg`, bankName : '수협중앙회'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/kjb.jpg`, bankName : '전북은행'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/shinhan.jpg`, bankName : '제주은행'},
          {bankImg : `${process.env.PUBLIC_URL}/img/bank/mg.jpg`, bankName : '새마을금고'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/shinhyup.jpg`, bankName : '신용협동조합(신협)'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/hsbc.jpg`, bankName : 'HSBC은행'},
          {bankImg : `${process.env.PUBLIC_URL}/img/bank/sb.jpg`, bankName : '저축은행'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/boa.jpg`, bankName : 'BOA은행'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/jd.jpg`, bankName : 'JP모간체이스은행'},
          {bankImg : `${process.env.PUBLIC_URL}/img/bank/bnp.jpg`, bankName : 'BNP파리바은행'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/ccb.jpg`, bankName : '중국건설은행'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/sj.jpg`, bankName : '산림조합'},
          {bankImg : `${process.env.PUBLIC_URL}/img/bank/icbc.jpg`, bankName : '중국공상은행'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/nh.jpg`, bankName : '지역농축협'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/kakaobank.jpg`, bankName : '카카오뱅크'},
          {bankImg : `${process.env.PUBLIC_URL}/img/bank/toss.jpg`, bankName : '토스뱅크'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/kbank.jpg`, bankName : '케이뱅크'}
        ]);
        setStockList([
          {bankImg : `${process.env.PUBLIC_URL}/img/bank/nh.jpg`, bankName : 'NH투자증권'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/yuanta.jpg`, bankName : '유안타증권'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/kb.jpg`, bankName : 'KB증권'},
          {bankImg : `${process.env.PUBLIC_URL}/img/bank/mirae.jpg`, bankName : '미래에셋대우'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/samsung.jpg`, bankName : '삼성증권'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/truetriend.jpg`, bankName : '한국투자증권'},
          {bankImg : `${process.env.PUBLIC_URL}/img/bank/kyonbo.jpg`, bankName : '교보증권'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/hi.jpg`, bankName : '하이투자증권'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/hyundai.jpg`, bankName : '현대차증권'},
          {bankImg : `${process.env.PUBLIC_URL}/img/bank/sk.jpg`, bankName : 'SK증권'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/hanhwa.jpg`, bankName : '한화투자증권'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/hana.jpg`, bankName : '하나금융투자'},
          {bankImg : `${process.env.PUBLIC_URL}/img/bank/shinhan.jpg`, bankName : '신한금융투자'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/eugene.jpg`, bankName : '유진투자증권'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/meritz.jpg`, bankName : '메리츠종합금융증권'},
          {bankImg : `${process.env.PUBLIC_URL}/img/bank/si.jpg`, bankName : '신영증권'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/ebest.jpg`, bankName : '이베스트투자증권'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/cape.jpg`, bankName : '케이프투자증권'},
          {bankImg : `${process.env.PUBLIC_URL}/img/bank/buguk.jpg`, bankName : '부국증권'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/kiwoom.jpg`, bankName : '키움증권'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/daishin.jpg`, bankName : '대신증권'},
          {bankImg : `${process.env.PUBLIC_URL}/img/bank/db.jpg`, bankName : 'DB금융투자'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/ktb.jpg`, bankName : 'KTB투자증권'},{bankImg : `${process.env.PUBLIC_URL}/img/bank/fundonline.jpg`, bankName : '한국포스증권'},
          {bankImg : `${process.env.PUBLIC_URL}/img/bank/toss.jpg`, bankName : '토스증권'}
        ]);
      }, []);

    useEffect(() => {
        if(password === passwordConfirm) {
            setPasswordConfirmCheck(true);
            setPasswordConfirmCheckText('비밀번호가 일치합니다.');
            setPasswordConfirmCheckColor('green');
        } 
        if(password !== passwordConfirm) {
            setPasswordConfirmCheck(true);
            setPasswordConfirmCheckText('비밀번호가 일치하지 않습니다.');
            setPasswordConfirmCheckColor('red');
        }
    }, [passwordConfirm]);

    const onBankAccount = (e) => {
        setShopInfo({
            ...shopInfo, shopAccountNumber : e.target.value
        })
    }

    const onDepositerName = (e) => {
        setShopInfo({
            ...shopInfo, shopDepositorName : e.target.value
        })
    }

    const onSellerUpdate = () => {
        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html : 
            `
                <div>
                    <div class='${admin.admin_seller_add_title}'><span>입력한 셀러 정보를 수정 하시겠습니까?</span></div>
                    <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                      <button class='mx-1' id='seller_add_cancel'>취소</button>
                      <button class='mx-1' id='seller_add_success'>수정</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });
        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);
        
        async function sellerAddSuccess(e) {
            if(e.target && e.target.id == 'seller_add_success') {
                const response = await adminApi.sellerInfoUpdate(userInfo);
                const data = response.data;

                if(data === 'SUCCESS') {
                    shopUpdate();

                    MySwal.fire({
                        customClass: {
                            popup: `${admin.admin_seller_add_popup}`
                        },
                        html : 
                        `
                        <div>
                        <div class='${admin.admin_seller_add_title}'><span>셀러 수정이 완료 되었습니다.</span></div>
                        <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                        </div>
                        </div>
                        `,
                        showConfirmButton: false,
                        showCancelButton: false,
                    });
                    document.addEventListener('click', sellerAddConfirm);
                    document.removeEventListener('click', sellerAddSuccess);
                    
                    function sellerAddConfirm(e) {
                        if(e.target && e.target.id == 'seller_add_confirm') {
                            userSelect();
                            MySwal.close();
                            document.removeEventListener('click', sellerAddConfirm);
                            document.removeEventListener('click', sellerAddCancel);
                        }
                    }
                } else {
                    MySwal.fire({
                        customClass: {
                            popup: `${admin.admin_seller_add_popup}`
                        },
                        html : 
                        `
                        <div>
                        <div class='${admin.admin_seller_add_title}'><span>셀러 수정에 실패하였습니다.</span></div>
                        <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                        </div>
                        </div>
                        `,
                        showConfirmButton: false,
                        showCancelButton: false,
                    });
                    document.addEventListener('click', sellerAddConfirm);
                    document.removeEventListener('click', sellerAddSuccess);
                    
                    function sellerAddConfirm(e) {
                        if(e.target && e.target.id == 'seller_add_confirm') {
                            MySwal.close();
                            document.removeEventListener('click', sellerAddConfirm);
                            document.removeEventListener('click', sellerAddCancel);
                        }
                    }
                }
            }
        }
        
        function sellerAddCancel(e) {
            if(e.target && e.target.id == 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
            }
        }
    }

    const shopUpdate = async () => {
        const response = await adminApi.sellerShopUpdate(shopInfo);
        const data = response.data;
    }

    const onBankSelect = (item) => {
        // console.log('item : ', item);
        setSelectBank({
          ...selectBank, ...item
        });
        setShopInfo(shopInfo => {
          return {...shopInfo, shopBankImg: item.bankImg, shopBankName: item.bankName};
        });
        setShowBankSelect(false);
    }

    const handleIdDuplicate = async () => {
        const response = await adminApi.sellerIdDuplicate(sellerInfoList.userId);
        const data = response.data;

        // console.log('sellerId duplicate : ', data);

        if(data === 'DUPLICATE') {
            setIdCheckState(true);
            setIdCheckText('중복된 아이디입니다.');
            setIdCheckColor('red');
            setIdCheckConfirm(false);
        }
        
        if(data === 'NODATA') {
            setIdCheckState(true);
            setIdCheckText('사용 가능한 아이디입니다.');
            setIdCheckColor('green');
            setIdCheckConfirm(true);
        }
    }

    const handleFocus = () => {
        setIsFocused(true);
    }

    const checkEnglish = password => /[a-zA-Z]/.test(password);
    const checkNumber = password => /\d/.test(password);
    const checkSpecialChar = password => /[~@%^&*_|<>{}!]/.test(password);
    const checkLength = password => password.length >= 8;

    const passwordCheck = (password) => {
        // password가 문자열이 아니라면, 모든 검사를 false로 설정
        if (typeof password !== 'string') {
            return {
              hasEnglish: false,
              hasNumber: false,
              hasSpecialChar: false,
              isValidLength: false,
            };
        }
    
        return {
            hasEnglish: checkEnglish(password),
            hasNumber: checkNumber(password),
            hasSpecialChar: checkSpecialChar(password),
            isValidLength: checkLength(password),
        };
    };

    const handlePasswordChange = (e) => {
        const updatedPassword = e.target.value;
        setPassword(updatedPassword); // 항상 setPassword 호출
    
        if(updatedPassword.length > 7) {
            setIsPasswordValid(passwordCheck(updatedPassword)); // passwordCheck 함수를 업데이트된 비밀번호로 호출
            setHasInput(true);

            setUserInfo({
                ...userInfo, userPw : updatedPassword
            })
        } else {
            setHasInput(false);
            setPasswordError(false);
            setIsPasswordValid(passwordCheck(updatedPassword));
            
            setUserInfo({
                ...userInfo, userPw : updatedPassword
            })
        }
    };

    const handlePwConfirm = (e) => {
        let pwConfirm = e.target.value;

        setPasswordConfirm(pwConfirm);
        setUserInfo({
            ...userInfo, userPwConfirm : e.target.value
        })
    }

    const onSellerEmail = (e) => {
        // console.log(e.target.value);

        setUserInfo({
            ...userInfo, userEmail : e.target.value
        })
    }

    const handleEmailDuplicate = async () => {
        const response = await adminApi.sellerEmailDuplicate(sellerInfoList.userEmail);
        const data = response.data;

        // console.log('email duplicate : ', data);

        if(data === 'DUPLICATE') {
            setEmailCheckState(true);
            setEmailCheckText('중복된 이메일입니다.');
            setEmailCheckColor('red')
            setEmailCheckConfirm(false);
        }

        if(data === 'SUCCESS') {
            setEmailCheckState(true);
            setEmailCheckText('사용 가능한 이메일입니다.');
            setEmailCheckColor('green')
            setEmailCheckConfirm(true);
        }

    }

    const onSellerShopName = (e) => {
        setShopInfo({
            ...shopInfo, shopName : e.target.value
        });
    }

    const onSellerRegistration = (e) => {
        setUserInfo({
            ...userInfo, businessRegistrationNumber : e.target.value
        })
    }
    const onSellerPresent = (e) => {
        setUserInfo({
            ...userInfo, representativeName : e.target.value
        })
    }
    const onSellerBusinessState = (e) => {
        setUserInfo({
            ...userInfo, businessStatus : e.target.value
        })
    }
    const onSellerBusinessSectors = (e) => {
        setUserInfo({
            ...userInfo, businessSectors : e.target.value
        })
    }
    const onSellerBusinessName = (e) => {
        setUserInfo({
            ...userInfo, businessName : e.target.value
        })
    }
    const onSellerBusinessAddress = (e) => {
        setUserInfo({
            ...userInfo, businessLocation : e.target.value
        })
    }
    const onSellerMailOrderNumber = (e) => {
        setUserInfo({
            ...userInfo, businessMailOrderNumber : e.target.value
        })
    }

    const handleBankBookImgAdd = () => {
        document.getElementById('hiddenBusinessBankBook').click();
    };
    const handleMailOrderImgAdd = () => {
        document.getElementById('hiddenMailOrderImg').click();
    };

    const bankBookAdd = async (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('image', file);
        const config = { headers: { 'content-type': 'multipart/form-data' } };
        const response = await support.uploadImageToServer(formData, config);
        const data = response.data;
        const imageUrl = `${process.env.REACT_APP_API_SERVER}/img?file=${encodeURIComponent(data)}`;
        // setImageRepresetativePreview(imageUrl);

        setUserInfo(userInfo => ({
            ...userInfo,
            businessBankbookImg: imageUrl
        }));
    };
    const mailOrderAdd = async (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('image', file);
        const config = { headers: { 'content-type': 'multipart/form-data' } };
        const response = await support.uploadImageToServer(formData, config);
        const data = response.data;
        const imageUrl = `${process.env.REACT_APP_API_SERVER}/img?file=${encodeURIComponent(data)}`;
        // setImageRepresetativePreview(imageUrl);

        // console.log('imageUrl : ', imageUrl);

        setUserInfo(userInfo => ({
            ...userInfo,
            businessMailOrderImg: imageUrl
        }));
    };

    const handleSellerType = (e) => {
        setUserInfo(userInfo => ({
            ...userInfo,
            userClassification : e.target.id
        }))
    }

    return (
    <div className={`${admin.admin_seller_box} px-3 py-3`}>
              {/* <button onClick={onPreventLeave}>페이지 떠날 때 경고 활성화</button>
      <button onClick={offPreventLeave}>경고 비활성화</button>
        <PreventPopup onLeave={() => // console.log("페이지를 떠나는 로직을 실행합니다.")}/> */}
        <div>
        {/* <button onClick={() => setIsDirty(false)}>저장</button> */}
            <div className={`${admin.admin_seller_top_header} py-3`}><span>셀러 수정</span></div>

            {/* 셀러 로그인정보 */}
            <div className='px-3 py-3'>
                <div className={`${admin.admin_seller_header} py-1`}><span>셀러 로그인 정보</span></div>
                <div>
                    {/* 셀러 구분 */}
                    <div className={`${admin.admin_seller_radio_input_box}`}>
                        <div><span>셀러 구분</span></div>
                        <div>
                            <div>
                                <div><input type="radio" name="sellerType" id="storeseller" onChange={(e) => handleSellerType(e)} checked={userInfo.userClassification === 'storeseller'}/></div>
                                <div><label htmlFor="storeseller">스토어</label></div>
                            </div>
                            <div>
                                <div><input type="radio" name="sellerType" id="supportseller" onChange={(e) => handleSellerType(e)} checked={userInfo.userClassification === 'supportseller'}/></div>
                                <div><label htmlFor="supportseller">서포트</label></div>
                            </div>
                        </div>
                    </div>
                    {/* 아이디 */}
                    <div className={`${admin.admin_seller_input_box}`}>
                        <div>
                            <div><span>아이디</span></div>
                        </div>
                        <div>
                            <div><input type="text" value={userInfo.userId !== '' ? userInfo.userId : ''} onChange={(e) => onSellerId(e)} /></div>
                            {
                                idCheckState &&
                                <div className={`${admin.admin_seller_id_check}`}><span style={{'color' : `${idCheckColor}`}}>{idCheckText}</span></div>
                            }
                        </div>
                        <div>
                            <div className={`${admin.admin_seller_id_check_btn} ps-2`}><button onClick={handleIdDuplicate}>중복확인</button></div>
                        </div>
                    </div>
                    {/* 비밀번호 */}
                    <div className={`${admin.admin_seller_input_box}`}>
                        <div><span>비밀번호</span></div>
                        <div>
                            <div><input type="password" value={userInfo.userPw !== '' ? userInfo.userPw : ''} onFocus={handleFocus} onChange={(e) => handlePasswordChange(e)} disabled/></div>
                            {
                            isFocused && (
                                    <div className={`${admin.admin_seller_add_pw_check_box}`}>
                                        <div>
                                            <span style={{ color: isPasswordValid.hasEnglish ? "rgba(0, 189, 103, 1)" : "rgba(196, 199, 202, 1)"}}>
                                            <span className="material-symbols-outlined" style={{ color: isPasswordValid.hasEnglish ? "rgba(0, 189, 103, 1)" : "rgba(196, 199, 202, 1)" }}>check</span>영문
                                            </span>
                                        </div>
                                        <div>
                                            <span style={{ color: isPasswordValid.hasNumber ? "rgba(0, 189, 103, 1)" : "rgba(196, 199, 202, 1)"}}>
                                            <span className="material-symbols-outlined" style={{ color: isPasswordValid.hasNumber ? "rgba(0, 189, 103, 1)" : "rgba(196, 199, 202, 1)" }}>check</span>숫자
                                            </span>
                                        </div>
                                        <div>
                                            <span style={{ color: isPasswordValid.hasSpecialChar ? "rgba(0, 189, 103, 1)" : "rgba(196, 199, 202, 1)"}}>
                                            <span className="material-symbols-outlined" style={{ color: isPasswordValid.hasSpecialChar ? "rgba(0, 189, 103, 1)" : "rgba(196, 199, 202, 1)"}}>check</span>특수문자
                                            </span>
                                        </div>
                                        <div>
                                            <span style={{ color: isPasswordValid.isValidLength ? "rgba(0, 189, 103, 1)" : "rgba(196, 199, 202, 1)"}}>
                                            <span className="material-symbols-outlined" style={{ color: isPasswordValid.isValidLength ? "rgba(0, 189, 103, 1)" : "rgba(196, 199, 202, 1)"}}>check</span>8자 이상
                                            </span>
                                        </div>
                                    </div>
                            )}
                        </div>
                        <div></div>
                    </div>
                    {/* 비밀번호 확인*/}
                    <div className={`${admin.admin_seller_input_box}`}>
                        <div><span>비밀번호 확인</span></div>
                        <div>
                            <div><input type="text" value={sellerInfoList.userPwConfirm !== '' ? sellerInfoList.userPwConfirm : ''} onChange={(e) => handlePwConfirm(e)} disabled/></div>
                            {
                                passwordConfirm !== '' && passwordConfirmCheck &&
                                <div className={`${admin.admin_seller_id_check}`}><span style={{'color' : `${passwordConfirmCheckColor}`}}>{passwordConfirmCheckText}</span></div>
                            }
                        </div>
                        <div></div>
                    </div>
                    {/* 이메일 */}
                    <div className={`${admin.admin_seller_input_box}`}>
                        <div><span>이메일</span></div>
                        <div>
                            <div><input type="text" onChange={(e) => onSellerEmail(e)} value={userInfo.userEmail !== '' ? userInfo.userEmail : ''}/></div>
                            {
                                emailCheckState &&
                                <div className={`${admin.admin_seller_id_check}`}><span style={{'color' : `${emailCheckColor}`}}>{emailCheckText}</span></div>
                            }
                        </div>
                        <div className={`${admin.admin_seller_id_check_btn} ps-2`}><button onClick={handleEmailDuplicate}>중복확인</button></div>
                    </div>
                </div>
            </div>

            {/* 셀러 정산 정보 */}
            <div className='px-3 py-3'>
                <div className={`${admin.admin_seller_header} py-1`}><span>상점 정보</span></div>
                <div>
                    {/* 성명 */}
                    <div className={`${admin.admin_seller_input_box}`}>
                        <div><span>상점명</span></div>
                        <div><input type="text" value={shopInfo.shopName !== '' ? shopInfo.shopName : ''} onChange={(e) => onSellerShopName(e)}/></div>
                        <div></div>
                    </div>
                    {/* 주민등록번호 */}
                    {/* <div className={`${admin.admin_seller_input_box}`}>
                        <div><span>주민등록번호</span></div>
                        <div className='d-flex align-items-center'>
                            <div className='pe-2'><input type="text" /></div>
                            <div><span>-</span></div>
                            <div className='ps-2'><input type="text" /></div>
                        </div>
                        <div></div>
                    </div> */}
                    <div className={`${admin.admin_seller_input_box}`}>
                        <div><span>정산은행</span></div>
                        <div>
                            <button onClick={() => setShowBankSelect(true)} className={`${admin.seller_shop_setting_bank_select_btn} ${selectBank.bankImg === '' && selectBank.bankName === '' ? admin.seller_bank_no_data : admin.seller_bank_data}`}>
                            {
                                selectBank.bankImg === '' && selectBank.bankName === '' ?
                                <div>
                                <span>은행을 선택해주세요.</span>
                                </div>
                                :
                                <div>
                                <div><img src={`${process.env.PUBLIC_URL}${selectBank.bankImg}`} alt="은행이미지" /></div>
                                <div><span>{selectBank.bankName}</span></div>
                                </div>
                            }
                            
                            </button>
                        </div>
                    </div>
                    <div className={`${admin.admin_seller_input_box}`}>
                        <div><span>정산계좌번호</span></div>
                        <div><input type="text" value={shopInfo.shopAccountNumber !== '' ? shopInfo.shopAccountNumber : ''} onChange={(e) => onBankAccount(e)} /></div>
                    </div>
                    <div className={`${admin.admin_seller_input_box}`}>
                        <div><span>예금주명</span></div>
                        <div><input type="text" value={shopInfo.shopDepositorName !== '' ? shopInfo.shopDepositorName : ''} onChange={(e) => onDepositerName(e)} /></div>
                    </div>
                </div>
            </div>

            {/* 셀러 사업자 정보 */}
            <div className='px-3 py-3'>
                <div className={`${admin.admin_seller_header} py-1`}><span>사업자 정보</span></div>
                <div>
                    {/* 사업자 등록번호 */}
                    <div className={`${admin.admin_seller_input_box}`}>
                        <div><span>사업자등록번호</span></div>
                        <div><input type="text" value={userInfo.businessRegistrationNumber !== '' ? userInfo.businessRegistrationNumber : ''} onChange={(e) => onSellerRegistration(e)}/></div>
                        <div><span></span></div>
                    </div>
                    {/* 대표자명 */}
                    <div className={`${admin.admin_seller_input_box}`}>
                        <div><span>대표자명</span></div>
                        <div><input type="text" value={userInfo.representativeName !== '' ? userInfo.representativeName : ''} onChange={(e) => onSellerPresent(e)}/></div>
                        <div><span></span></div>
                    </div>
                    {/* 업태 */}
                    <div className={`${admin.admin_seller_input_box}`}>
                        <div><span>업태</span></div>
                        <div><input type="text" value={userInfo.businessStatus !== '' ? userInfo.businessStatus : ''} onChange={(e) => onSellerBusinessState(e)}/></div>
                        <div><span></span></div>
                    </div>
                    {/* 거래 형태 */}
                    <div className={`${admin.admin_seller_input_box}`}>
                        <div><span>업종</span></div>
                        <div><input type="text" value={userInfo.businessSectors !== '' ? userInfo.businessSectors : ''} onChange={(e) => onSellerBusinessSectors(e)}/></div>
                        <div><span></span></div>
                    </div>
                    {/* 상호 */}
                    <div className={`${admin.admin_seller_input_box}`}>
                        <div><span>상호명</span></div>
                        <div><input type="text" value={userInfo.businessName !== '' ? userInfo.businessName : ''} onChange={(e) => onSellerBusinessName(e)}/></div>
                        <div><span></span></div>
                    </div>
                    {/* 사업장 소재지 */}
                    <div className={`${admin.admin_seller_input_box}`}>
                        <div><span>사업장 소재지</span></div>
                        <div><input type="text" value={userInfo.businessLocation !== '' ? userInfo.businessLocation : ''} onChange={(e) => onSellerBusinessAddress(e)}/></div>
                        <div><span></span></div>
                    </div>
                    {/* 사업자 통장 사본 */}
                    <div className={`${admin.admin_seller_input_box}`}>
                        <div><span>사업자 통장 사본</span></div>
                        <div className={`${admin.admin_seller_input_upload_box}`}>
                            <input
                                id="hiddenBusinessBankBook"
                                type="file"
                                onChange={(event) => bankBookAdd(event)}
                                style={{display: 'none'}}
                            />
                            <div>
                                <div className='pe-1'><input type="text" value={userInfo.businessBankbookImg !== '' ? userInfo.businessBankbookImg : ''} readOnly/></div>
                                {
                                    userInfo.businessBankbookImg !== '' && userInfo.businessBankbookImg !== null &&
                                    <div className={`${admin.admin_seller_input_upload_img_box}`}><img src={userInfo.businessBankbookImg !== '' ? userInfo.businessBankbookImg : ''} alt="" /></div>
                                }
                            </div>
                            <div className='ps-1'><button onClick={() => handleBankBookImgAdd()}>업로드</button></div>
                        </div>
                        <div><span></span></div>
                    </div>
                    {/* 통신 판매업 신고번호 */}
                    <div className={`${admin.admin_seller_input_box}`}>
                        <div><span>통신판매업신고번호 (선택)</span></div>
                        <div><input type="text" value={userInfo.businessMailOrderNumber !== '' ? userInfo.businessMailOrderNumber : ''} onChange={(e) => onSellerMailOrderNumber(e)}/></div>
                        <div><span></span></div>
                    </div>
                    {/* 통신 판매업 신고증 */}
                    <div className={`${admin.admin_seller_input_box}`}>
                        <div><span>통신판매업신고증 (선택)</span></div>
                        <div className={`${admin.admin_seller_input_upload_box}`}>
                            <input
                                id="hiddenMailOrderImg"
                                type="file"
                                onChange={(event) => mailOrderAdd(event)}
                                style={{display: 'none'}}
                            />
                            <div>
                                <div className='pe-1'><input type="text" value={userInfo.businessMailOrderImg !== '' ? userInfo.businessMailOrderImg : ''} readOnly/></div>
                                {
                                    userInfo.businessMailOrderImg !== '' && userInfo.businessMailOrderImg !== null &&
                                    <div className={`${admin.admin_seller_input_upload_img_box}`}><img src={userInfo.businessMailOrderImg !== '' ? userInfo.businessMailOrderImg : ''} alt="" /></div>
                                }
                            </div>
                            <div className='ps-1'><button onClick={() => handleMailOrderImgAdd()}>업로드</button></div>
                        </div>
                        <div><span></span></div>
                    </div>
                </div>
            </div>
            <div className={`${admin.admin_seller_add_btn} py-4`}>
                <div><button onClick={() => onSellerUpdate()}>수정하기</button></div>
            </div>
        </div>
        {
            showBankSelect &&
            <div className={`${seller.seller_bank_select_modal_container}`}>
            <div className={`${seller.seller_bank_select_modal_box}`}>
                <div className={`${seller.seller_bank_select_modal_header}`}>
                <div><span>은행선택</span></div>
                <div><button onClick={() => setShowBankSelect(false)}><span className="material-symbols-outlined">close</span></button></div>
                </div>
                <div className={`${seller.seller_bank_select_modal_tab}`}>
                <div><button className={activeBankStock === 'bank' ? seller.bank_select_active : ''} onClick={() => setActiveBankStock('bank')}>은행</button></div>
                <div><button className={activeBankStock === 'stock' ? seller.bank_select_active : ''} onClick={() => setActiveBankStock('stock')}>증권</button></div>
                </div>
                <div className={`${seller.seller_bank_select_modal_content} `}>
                {
                    activeBankStock === 'bank' &&
                    <div className={`${seller.seller_bank_select_modal_bank_box} `}>
                    {
                        bankList.map((item, index) => (
                        <button key={index} onClick={() => onBankSelect(item)}>
                            <div><img src={item.bankImg} alt="은행이미지" /></div>
                            <div><span>{item.bankName}</span></div>
                        </button>
                        ))
                    }
                    </div>
                }
                {
                    activeBankStock === 'stock' &&
                    <div className={`${seller.seller_bank_select_modal_bank_box} `}>
                    {
                        stockList.map((item, index) => (
                        <button key={index} onClick={() => onBankSelect(item)}>
                            <div><img src={item.bankImg} alt="은행이미지" /></div>
                            <div><span>{item.bankName}</span></div>
                        </button>
                        ))
                    }
                    </div>
                }
                </div>
            </div>
            </div>
        }
    </div>
  )
}

export default AdminSellerUpdate