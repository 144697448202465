import React, { useEffect, useState } from 'react';
import dailyduck from './MyContainner.module.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { animated } from 'react-spring';
import { useSpring, useTransition } from '@react-spring/core';
import * as auth from '../../apis/auth';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MyOrderCancel = () => {
    const MySwal = withReactContent(Swal);
    const location = useLocation();
    const orderInfo = location.state?.order;
    const navigate = useNavigate();
    const [activeCancelState, setActiveCancelState] = useState('');
    const [showBuyerBox, setShowBuyerBox] = useState(false);
    const [showSellerBox, setShowSellerBox] = useState(false);
    const [buyerSelect, setBuyerSelect] = useState('');
    const [sellerSelect, setSellerSelect] = useState('');
    const [openStates, setOpenStates] = useState(false);
    const [cancelContent, setCancelContent] = useState({ cancelType: '', cancelContent: '' });
    const [agreeCheck, setAgreeCheck] = useState(false);

    const transitions = useTransition(showBuyerBox, {
        from: { transform: "translateY(100%)" },
        enter: { transform: "translateY(0)" },
        leave: { transform: "translatey(100%)" },
        config: {
            duration: 200,
        },
    });
    const sellerTransitions = useTransition(showSellerBox, {
        from: { transform: "translateY(100%)" },
        enter: { transform: "translateY(0)" },
        leave: { transform: "translatey(100%)" },
        config: {
            duration: 200,
        },
    });
    
    useEffect(() => {
        setCancelContent({
            ...cancelContent, 
            paymentNo: orderInfo.paymentNo, 
            paymentPrice : orderInfo.paymentPrice, 
            paymentKey : orderInfo.paymentKey, 
            point : orderInfo.point, 
            couponNo : orderInfo.couponNo, 
            userId : orderInfo.userId, 
            productNumber : orderInfo.paymentProductNumber, 
            paymentNumber : orderInfo.paymentNumber, 
            totalQuantity : orderInfo.totalQuantity
        })
    }, []);

    useEffect(() => {
        // console.log('orderInfo : ', orderInfo);
    }, [orderInfo]);

    useEffect(() => {
        // console.log('cancelContent : ', cancelContent);
    }, [cancelContent]);

    const onBack = () => {
        navigate(-1);
    }

    const toggleOpen = () => {
        setOpenStates(!openStates);
    };

    const calculateTotalPrice = () => {
        return orderInfo.options.reduce((total, option) => {
            const optionTotal = (parseInt(orderInfo.principalAmount) * option.quantity) + (option.addPrice * option.quantity);
            return total + optionTotal;
        }, 0).toLocaleString();
    };

    const onBuyerSelectBtn = (text) => {
        setBuyerSelect(text);
        setCancelContent({
            ...cancelContent, cancelContent: text
        });
        setShowBuyerBox(false);
    }

    const onSellerSelectBtn = (text) => {
        setSellerSelect(text);
        setCancelContent({
            ...cancelContent, cancelContent: text
        });
        setShowSellerBox(false);
    }

    const onDirectCancel = (e) => {
        // console.log(e.target.value);
        setCancelContent({
            ...cancelContent, cancelContent: e.target.value
        });
    }

    const onOrderCancel = async () => {
        MySwal.fire({
            customClass: {
                popup: `${dailyduck.order_cancel_swal_popup}`
            },
            html : 
            `
                <div>
                    <div class='${dailyduck.order_cancel_title}'><span>주문을 취소 하시겠습니까?</span></div>
                    <div class='${dailyduck.order_cancel_btn_box} py-2 d-flex w-100 justify-content-between'>
                      <button class='mx-1' id='seller_add_cancel'>아니요</button>
                      <button class='mx-1' id='seller_add_success'>네</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });
        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);
        
        async function sellerAddSuccess(e) {
            if(e.target && e.target.id == 'seller_add_success') {
                try {
                    const response = await auth.storeOrderCancel(cancelContent);
                    const data = response.data;
            
                    // console.log('cancelData : ', data);
        
                    if(data === 'SUCCESS') {
                        MySwal.fire({
                            customClass: {
                                popup: `${dailyduck.order_cancel_swal_popup}`
                            },
                            html : 
                            `
                            <div>
                            <div class='${dailyduck.order_cancel_title}'><span>주문 취소가 완료 되었습니다.</span></div>
                            <div class='${dailyduck.order_cancel_sub_title}'><span>카드 취소는 요청 후 영업일 기준 3~4일이 소요됩니다.</span></div>
                            <div class='${dailyduck.order_cancel_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);

                        
                        function sellerAddConfirm(e) {
                            if(e.target && e.target.id == 'seller_add_confirm') {
                                MySwal.close();
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                                document.removeEventListener('click', sellerAddConfirm);
                                navigate(-1);
                            }
                        }
                    }
                    
                } catch (error) {
                    console.error('cancel error : ', error);
                }
                
                
            }
        }
        
        function sellerAddCancel(e) {
            if(e.target && e.target.id == 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
            }
        }
        
    }

    const onOrderCancelRequst = () => {
        MySwal.fire({
            customClass: {
                popup: `${dailyduck.order_cancel_swal_popup}`
            },
            html : 
            `
                <div>
                    <div class='${dailyduck.order_cancel_title}'><span>주문 취소요청을 하시겠습니까?</span></div>
                    <div class='${dailyduck.order_cancel_btn_box} py-2 d-flex w-100 justify-content-between'>
                      <button class='mx-1' id='seller_add_cancel'>아니요</button>
                      <button class='mx-1' id='seller_add_success'>네</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });
        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);
        
        async function sellerAddSuccess(e) {
            if(e.target && e.target.id == 'seller_add_success') {
                try {
                    const response = await auth.storeOrderCancelRequest(cancelContent);
                    const data = response.data;
            
                    // console.log('cancelData : ', data);
        
                    if(data === 'SUCCESS') {
                        MySwal.fire({
                            customClass: {
                                popup: `${dailyduck.order_cancel_swal_popup}`
                            },
                            html : 
                            `
                            <div>
                            <div class='${dailyduck.order_cancel_title}'><span>주문 취소요청이 완료 되었습니다.</span></div>
                            <div class='${dailyduck.order_cancel_sub_title}'><span>판매자 승인 후 취소가 완료됩니다.</span></div>
                            <div class='${dailyduck.order_cancel_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);

                        
                        function sellerAddConfirm(e) {
                            if(e.target && e.target.id == 'seller_add_confirm') {
                                MySwal.close();
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                                document.removeEventListener('click', sellerAddConfirm);
                                navigate(-1);
                            }
                        }
                    }
                    
                } catch (error) {
                    console.error('cancel error : ', error);
                }
                
                
            }
        }
        
        function sellerAddCancel(e) {
            if(e.target && e.target.id == 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
            }
        }
    }

    const onAgreeCheck = (e) => {
        setAgreeCheck(e.target.checked);
    }

    const cancelCheck = () => {
        return cancelContent.cancelType !== '' && cancelContent.cancelContent !== '' && agreeCheck;
    }

    return (
        <div className={`${dailyduck.my_order_cancel_container}`}>
            <div className={`${dailyduck.my_order_cancel_header}`}>
                <div><button onClick={onBack}><span className="material-symbols-outlined">arrow_back_ios</span></button></div>
                <div><span>주문 취소</span></div>
            </div>
            <div className={`${dailyduck.my_order_cancel_content}`}>
                <div className={`${dailyduck.my_order_cancel_content_product_info}`}>
                    <div><img src={orderInfo.paymentImg} alt="상품이미지" /></div>
                    <div>
                        <div><span>{orderInfo.shopName}</span></div>
                        <div><span>{orderInfo.paymentProductName}</span></div>
                        <div>
                            {
                                orderInfo.options.map((item, index) => (
                                    <div key={index}><span>{item.selectionOptionName} :</span><span> {item.selectionOptionValue} /</span> {item.quantity}개<span></span></div>
                                ))
                            }
                        </div>
                        <div><span>환불금액 :</span><span> {parseInt(orderInfo.paymentPrice).toLocaleString()}원</span></div>
                    </div>
                </div>
                <div className={`${dailyduck.my_order_cancel_content_cancellation}`}>
                    <div><span>취소 사유</span></div>
                    <div>
                        <div><button className={activeCancelState === 'buyer' ? dailyduck.my_order_cancel_content_cancellation_active : ''} onClick={() => {setActiveCancelState('buyer'); setCancelContent({...cancelContent, cancelType : '구매자 책임 사유', cancelContent : ''}); setBuyerSelect(''); setSellerSelect('')}}>구매자 책임 사유</button></div>
                        <div><button className={activeCancelState === 'seller' ? dailyduck.my_order_cancel_content_cancellation_active : ''} onClick={() => {setActiveCancelState('seller'); setCancelContent({...cancelContent, cancelType : '판매자 책임 사유', cancelContent : ''}); setBuyerSelect(''); setSellerSelect('')}}>판매자 책임 사유</button></div>
                        <div><button className={activeCancelState === 'direct' ? dailyduck.my_order_cancel_content_cancellation_active : ''} onClick={() => {setActiveCancelState('direct'); setCancelContent({...cancelContent, cancelType : '사유 직접 입력', cancelContent : ''}); setBuyerSelect(''); setSellerSelect('')}}>사유 직접 입력</button></div>
                    </div>
                    <div>
                        {
                            activeCancelState === 'buyer' &&
                            <div className={`${dailyduck.my_order_cancellation_box}`}><button onClick={() => setShowBuyerBox(true)}>{buyerSelect === '' ? '취소 사유를 선택해주세요.' : buyerSelect}</button></div>
                        }
                        {
                            activeCancelState === 'seller' &&
                            <div className={`${dailyduck.my_order_cancellation_box}`}><button onClick={() => setShowSellerBox(true)}>{sellerSelect === '' ? '취소 사유를 선택해주세요.' : sellerSelect}</button></div>
                        }
                        {
                            activeCancelState === 'direct' &&
                            <div className={`${dailyduck.my_order_cancellation_box}`}><input type="text" placeholder='취소 사유를 입력해주세요.' onChange={(e) => onDirectCancel(e)} /></div>
                        }
                    </div>
                </div>
                <div className={`${dailyduck.my_order_cancel_payment_info_box}`}>
                    <button className={`${dailyduck.my_order_cancel_payment_info_drop_down}`} onClick={() => toggleOpen()}>
                        <div><span>결제정보</span></div>
                        <div><span className="material-symbols-outlined">{openStates ? 'arrow_drop_up' : 'arrow_drop_down'}</span></div>
                    </button>
                    {/* 펼쳐지는 영역 시작 */}
                    <CSSTransition in={!openStates} timeout={500} classNames="review" unmountOnExit>
                        <div className={`${dailyduck.my_order_cancel_payment_info_drop_down_info}`}>
                            <div>
                                <div className={`d-flex justify-content-between py-1`}>
                                    <div><span>총 상품금액</span></div>
                                    <div><span>{calculateTotalPrice()}원</span></div>
                                </div>
                                <div className={`d-flex justify-content-between py-1`}>
                                    <div><span>배송비</span></div>
                                    <div><span>{orderInfo.deliveryPrice.toLocaleString()}원</span></div>
                                </div>
                                <div className={`d-flex justify-content-between py-1`}>
                                    <div><span>포인트</span></div>
                                    <div><span>(-) {orderInfo.point.toLocaleString()}원</span></div>
                                </div>
                                <div className={`d-flex justify-content-between py-1`}>
                                    <div><span>쿠폰</span></div>
                                    <div><span>(-) {orderInfo.coupon.toLocaleString()}원</span></div>
                                </div>
                                <div className={`d-flex justify-content-between py-1`}>
                                    <div><span>결제수단</span></div>
                                    <div><span>{orderInfo.paymentType}</span></div>
                                </div>
                            </div>
                            <div>
                                <div className={`d-flex justify-content-between`}>
                                    <div><span>총 환불금액</span></div>
                                    <div><span>{(parseInt(orderInfo.paymentPrice).toLocaleString()).toLocaleString()}원</span></div>
                                </div>
                            </div>
                        </div>
                    </CSSTransition>
                    {/* 펼쳐지는 영역 끝 */}
                </div>
                <div className={`${dailyduck.my_order_cancel_payment_refund_method}`}>
                    <div><span>환불방법</span></div>
                    {
                        orderInfo.paymentType === '카드' &&
                        <div><span>카드결제 취소</span></div>
                    }
                </div>
                <div className={`${dailyduck.my_order_cancel_precautions}`}>
                    <div><button>&#183; 주문 취소시 주의사항</button></div>
                    <div><button>&#183; 환불 시 주의사항</button></div>
                </div>
                <div className={`${dailyduck.my_order_cancel_precautions_agree}`}>
                    <div><input type="checkbox" name="" id="agree" onChange={(e) => onAgreeCheck(e)} /></div>
                    <div><label htmlFor='agree'>주의 사항을 읽고 동의합니다.</label></div>
                </div>
            </div>
            <div className={`${dailyduck.my_order_cancel_footer}`}>
                {
                    orderInfo.paymentState === '결제완료' ?
                        <button className={cancelCheck() ? dailyduck.my_order_cancel_btn_active : ''} onClick={() => onOrderCancel()} disabled={!cancelCheck()}>취소하기</button>
                        :
                        <button className={cancelCheck() ? dailyduck.my_order_cancel_btn_active : ''} onClick={() => onOrderCancelRequst()} disabled={!cancelCheck()}>취소요청</button>
                }
            </div>
            {
                showBuyerBox &&
                <div className={`${dailyduck.my_order_bottom_modal}`}>
                    {transitions((style, item) =>
                        item ?
                            <animated.div className={dailyduck.my_order_bottom_modal_container} style={style}>
                                <div className={dailyduck.my_order_bottom_modal_header}>
                                    <div><span>구매자 취소 사유 선택</span></div>
                                    <div><button onClick={() => setShowBuyerBox(false)}><span className="material-symbols-outlined">close</span></button></div>
                                </div>
                                <div className={dailyduck.my_order_bottom_modal_content}>
                                    <div><button className={buyerSelect === '배송지연' ? dailyduck.my_order_bottom_modal_content_active : ''} onClick={() => onBuyerSelectBtn('배송지연')}>배송지연</button></div>
                                    <div><button className={buyerSelect === '단순변심' ? dailyduck.my_order_bottom_modal_content_active : ''} onClick={() => onBuyerSelectBtn('단순변심')}>단순변심</button></div>
                                    <div><button className={buyerSelect === '설명과 다른 제품' ? dailyduck.my_order_bottom_modal_content_active : ''} onClick={() => onBuyerSelectBtn('설명과 다른 제품')}>설명과 다른 제품</button></div>
                                    <div><button className={buyerSelect === '가격 문제' ? dailyduck.my_order_bottom_modal_content_active : ''} onClick={() => onBuyerSelectBtn('가격 문제')}>가격 문제</button></div>
                                    <div><button className={buyerSelect === '중복 주문' ? dailyduck.my_order_bottom_modal_content_active : ''} onClick={() => onBuyerSelectBtn('중복 주문')}>중복 주문</button></div>
                                    <div><button className={buyerSelect === '색상 / 크기 문제' ? dailyduck.my_order_bottom_modal_content_active : ''} onClick={() => onBuyerSelectBtn('색상 / 크기 문제')}>색상 / 크기 문제</button></div>
                                    <div><button className={buyerSelect === '주문실수' ? dailyduck.my_order_bottom_modal_content_active : ''} onClick={() => onBuyerSelectBtn('주문실수')}>주문실수</button></div>
                                    <div><button className={buyerSelect === '결제문제' ? dailyduck.my_order_bottom_modal_content_active : ''} onClick={() => onBuyerSelectBtn('결제문제')}>결제문제</button></div>
                                </div>
                            </animated.div>
                            : null
                    )}
                </div>
            }
            {
                showSellerBox &&
                <div className={`${dailyduck.my_order_bottom_modal}`}>
                    {sellerTransitions((style, item) =>
                        item ?
                            <animated.div className={dailyduck.my_order_bottom_modal_container} style={style}>
                                <div className={dailyduck.my_order_bottom_modal_header}>
                                    <div><span>구매자 취소 사유 선택</span></div>
                                    <div><button onClick={() => setShowSellerBox(false)}><span className="material-symbols-outlined">close</span></button></div>
                                </div>
                                <div className={dailyduck.my_order_bottom_modal_content}>
                                    <div><button className={sellerSelect === '상품불량' ? dailyduck.my_order_bottom_modal_content_active : ''} onClick={() => onSellerSelectBtn('상품불량')}>상품불량</button></div>
                                    <div><button className={sellerSelect === '잘못된 상품 배송' ? dailyduck.my_order_bottom_modal_content_active : ''} onClick={() => onSellerSelectBtn('잘못된 상품 배송')}>잘못된 상품 배송</button></div>
                                    <div><button className={sellerSelect === '파손된 상품 배송' ? dailyduck.my_order_bottom_modal_content_active : ''} onClick={() => onSellerSelectBtn('파손된 상품 배송')}>파손된 상품 배송</button></div>
                                    <div><button className={sellerSelect === '상품 누락' ? dailyduck.my_order_bottom_modal_content_active : ''} onClick={() => onSellerSelectBtn('상품 누락')}>상품 누락</button></div>
                                    <div><button className={sellerSelect === '잘못된 상품 설명' ? dailyduck.my_order_bottom_modal_content_active : ''} onClick={() => onSellerSelectBtn('잘못된 상품 설명')}>잘못된 상품 설명</button></div>
                                    <div><button className={sellerSelect === '배송 지연' ? dailyduck.my_order_bottom_modal_content_active : ''} onClick={() => onSellerSelectBtn('배송 지연')}>배송 지연</button></div>
                                    <div><button className={sellerSelect === '고객 서비스 불만' ? dailyduck.my_order_bottom_modal_content_active : ''} onClick={() => onSellerSelectBtn('고객 서비스 불만')}>고객 서비스 불만</button></div>
                                    <div><button className={sellerSelect === '허위 광고' ? dailyduck.my_order_bottom_modal_content_active : ''} onClick={() => onSellerSelectBtn('허위 광고')}>허위 광고</button></div>
                                </div>
                            </animated.div>
                            : null
                    )}
                </div>
            }
        </div>
    )
}

export default MyOrderCancel
