import React, { useEffect, useState } from 'react'
import { animated } from 'react-spring';
import { useSpring, useTransition } from '@react-spring/core';
import { Link, useNavigate } from 'react-router-dom';
import dailyduck from './MyContainner.module.css'
import MyNoticeDetail from '../../components/my/MyNoticeDetail';
import * as auth from '../../apis/auth';

const MyNotice = ( {} ) => {

  const navigate = useNavigate();
  const [ showNoticeDetail, setShowNoticeDetail ] = useState(false);
  const [ noticeList, setNoticeList] = useState([]);
  const [ detailNotice, setDetailNotice ] = useState({});

  const onNotice = (item) => {
    setDetailNotice(item);  
    setShowNoticeDetail(true);
  }
  
  const handleNoticeBack = () => {
    navigate('/my');
  }

  useEffect(() => {
    noticeSelect();
  }, []);

  useEffect(() => {
    const elements = document.getElementsByClassName('page_inner');
    if(showNoticeDetail) {
        for (let element of elements) {
            element.style.height = '100vh';
        }
    } else {
        for (let element of elements) {
            element.style.height = '';
        }
    }
    
    return () => {
        for (let element of elements) {
            element.style.height = '';
        }
    }
  }, [showNoticeDetail]);

  const noticeSelect = async () => {
    const response = await auth.noticeSelect();
    const data = response.data;

    // console.log('notice : ', data);
    setNoticeList(data);
  }

  return (
    <>
        {
            <div className={`${dailyduck.my_notice_container}`}>
                    <div className={dailyduck.my_notice_box}>
                        <div className={`${dailyduck.my_notice_header}`}>
                            <div>
                                <button onClick={() => handleNoticeBack()}>
                                  <span className="material-symbols-outlined">arrow_back_ios</span>
                                </button>
                            </div>
                            <div>
                                <div>
                                    <span>공지사항</span>
                                </div>
                            </div>
                        </div>
                        <div className={`${dailyduck.my_notice_content} pt-3 px-3`}>
                          <div className={`${dailyduck.my_notice_content_box}`}>
                            {
                              noticeList.length != 0 ? (
                                noticeList.map((item, index) => (
                                  <button className='w-100' onClick={() => onNotice(item)}>
                                    <div className={`${dailyduck.my_notice_info}`}>
                                      <div>
                                        <div><span>{item.notificationTitle}</span></div>
                                        <div><span>{item.notificationRegDate}</span></div>
                                      </div>
                                      <div><span className="material-symbols-outlined">arrow_forward_ios</span></div>
                                    </div>
                                  </button>
                                ))
                              ) : (
                                <div className={`${dailyduck.my_notice_none}`}>
                                  <div><img src="../img/my/none.png" alt="없음" /></div>
                                  <div><span>새로운 공지사항이 없어요.</span></div>
                                </div>
                              )
                            }
                          </div>
                        </div>
                    </div>
                </div>
          }
          {
            showNoticeDetail &&
            <MyNoticeDetail dailyduck={dailyduck} showNoticeDetail={showNoticeDetail} setShowNoticeDetail={setShowNoticeDetail} detailNotice={detailNotice} />
          }
      </>
  )
}

export default MyNotice