import { useTransition } from '@react-spring/core'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { animated } from 'react-spring'
import { ToastContainer, toast } from 'react-toastify'
import Swal from 'sweetalert2'
import * as auth from '../../apis/auth'
import DailyduckNavigator from '../../components/footer/Dailyduck_Navigator'
import DailyduckMyTop from '../../components/my/DailyduckMyTop'
import { LoginContext } from '../../contexts/LoginContextProvider'
import dailyduck from './MyContainner.module.css'
import MyNotification from './MyNotification'

const MyContainer = () => {

  const { isLogin, login, logout, userInfo, remove } = useContext(LoginContext);

  const navigateLogin = useNavigate();
  useEffect(() => {
    if(isLogin == false) {
      navigateLogin('/');
    }
  })

  const [ showNotification, setShowNotification ] = useState(false);
  const [ users, setUser ] = useState({});
  const [ userFavorityList, setUserFavorityList ] = useState([{}]);
  const [ userImg, setUserImg ] = useState(users && users.userProfile ? users.userProfile : '../../img/my/Profile_Circle.png');
  const [ items, setItems ] = useState({
    top: [],
    bottom: [],
  });

  const myInfo = useNavigate();
  const handleMyInfo = () => {
    myInfo('/my/myinfo');
  }
  const handleMyFavority = () => {
    myInfo('/my/favority');
  }
  

  useEffect(() => { 
    // 비동기 요청을 위한 함수 선언
    const fetchData = async () => {
        try {
            // 비동기 요청 실행
            const response = await auth.userselect(userInfo.userId);
            // 데이터 설정
            setUser(response.data);
  
            // // console.log(response.data);
        } catch (error) {
            // 오류 처리
            console.error("데이터를 불러오는 데 실패했습니다.", error);
        }
    };
  
    const favorityData = async () => {
      try {
        const response = await auth.favorityList(userInfo.userId);
        
        setUserFavorityList(response.data);
        
        // // console.log(response.data);
  
      } catch (error) {
        console.error("데이터를 불러오는 데 실패했습니다.", error);
      }
    }

    // 선언한 비동기 함수 호출
    fetchData();
    favorityData();
  }, [userImg, items]);

  const handleMyOD = () => {
    myInfo('/my/od', {state : users});
  }
  const handleMyCP = () => {
    myInfo('/my/cp', {state: users});
  }

  const handleMyAD = () => {
    myInfo('/my/ad', {state: users});
  }

  const handleMyReview = () => {
    myInfo('/my/review', {state: users});
  }
  const handleMySave = () => {
    myInfo('/my/save', {state: users});
  }
  const handleNotice = () => {
    myInfo('/my/notice', {state: users});
  }
  const handleCustomerService = () => {
    myInfo('/my/service', {state: users});
  }
  const handleStoreEntry = () => {
    myInfo('/my/entry', {state: users});
  }
  const handleTerms = () => {
    myInfo('/my/terms', {state: users});
  }
  const handleNotification = () => {
    myInfo('/my/notification', {state: users});
  }
  const handleSmining = () => {
    myInfo('/smiming');
  }
  return (
    <div className={dailyduck.dailyduck_my}>
      {
        !showNotification && 
        <DailyduckMyTop dailyduck={dailyduck} handleMyInfo={handleMyInfo} handleMyFavority={handleMyFavority} users={users}
                        userImg={userImg} setUserImg={setUserImg} handleMyOD={handleMyOD} handleMyCP={handleMyCP} handleMyAD={handleMyAD}
                        handleMyReview={handleMyReview} handleMySave={handleMySave} handleNotice={handleNotice} handleCustomerService={handleCustomerService}
                        handleStoreEntry={handleStoreEntry} handleTerms={handleTerms} handleNotification={handleNotification} handleSmining={handleSmining}
        />
      }
      <DailyduckNavigator />
      <ToastContainer position="bottom-center" autoClose="200" closeButton={false} />
      {
        showNotification &&
        <MyNotification dailyduck={dailyduck} showNotification={showNotification} setShowNotification={setShowNotification} />
      }
    </div>
  )
}

export default MyContainer