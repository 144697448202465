import React, { useContext, useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'; // 기본 스타일
import MonthlyStyledCalendar from './MonthlyStyledCalendar';
import admin from '../../../admin.module.css';
import { LoginContext } from '../../../../contexts/LoginContextProvider';
import * as adminApi from '../../../../apis/admin';
import moment from 'moment';

const MonthlyCalendar = () => {
    const { roles, userInfo } = useContext(LoginContext);
    const [orderList, setOrderList] = useState([]);
    const [deliveryInfo, setDeliveryInfo] = useState({});
    const [monthlySummary, setMonthlySummary] = useState({ month: '', orderCount: 0, totalSum: 0, visitorCount: 0 });
    const [pageViews, setPageViews] = useState([]);

    useEffect(() => {
        // orderList와 pageViews가 업데이트되면 현재 월을 기준으로 요약을 다시 계산
        if (orderList.length > 0 && pageViews.length > 0) {
            calculateMonthlySummary(new Date());
        }
    }, [orderList, pageViews]);

    useEffect(() => {
        if (roles.isAdmin) {
            adimnOrderSelect();
            viewAllSelect();
        } else if (roles.isStore) {
            orderSelect();
            viewSelect();
        }
    }, [roles, userInfo]);

    const onDayClick = (value) => {
        // console.log("Clicked date:", value);
    };

    const viewSelect = async () => {
        const response = await adminApi.viewSelect(userInfo && userInfo?.userId);
        const data = response.data;
        if (data !== '') {
            setPageViews(data);
        }
    };

    const viewAllSelect = async () => {
        const response = await adminApi.viewAllSelect();
        const data = response.data;

        // console.log('pageViews : ', data);
        if (data !== '') {
            setPageViews(data);
        }
    };
    
    const orderSelect = async () => {
        const response = await adminApi.orderSelect(userInfo.userId);
        const data = response.data;
        const initialDeliveryInfo = data.reduce((acc, order) => {
            acc[order.paymentNo] = {
                deliveryCompany: order.deliveryCompany || '',
                waybillNumber: order.waybillNumber || ''
            };
            return acc;
        }, {});
        
        setOrderList(data);
        setDeliveryInfo(initialDeliveryInfo);
    };
    
    const adimnOrderSelect = async () => {
        const response = await adminApi.adminOrderSelect();
        const data = response.data;
        // console.log('orderData : ', data);
        const initialDeliveryInfo = data.reduce((acc, order) => {
            acc[order.paymentNo] = {
                deliveryCompany: order.deliveryCompany || '',
                waybillNumber: order.waybillNumber || ''
            };
            return acc;
        }, {});

        setOrderList(data);
        setDeliveryInfo(initialDeliveryInfo);
    };

    const formatDay = (locale, date) => date.getDate(); // "일"을 제외하고 숫자만 반환

    const tileContent = ({ date }) => {
        // 타일 날짜를 UTC 기준의 YYYY-MM-DD로 변환
        const tileDate = date.toLocaleDateString('en-CA');  
        // console.log('tileDate : ', tileDate);
    
        const dayOrders = orderList.filter(order => {
            if (order.paymentDate) {
                // UTC 시간대의 'YYYY-MM-DD' 부분만 추출
                const orderDate = new Date(order.paymentDate).toISOString().split('T')[0]; 
                // console.log('order.paymentDate : ', order.paymentDate);
                // console.log('orderDate : ', orderDate);
                return orderDate === tileDate; // 날짜만 비교
            }
            return false;
        });
    
        const orderCount = dayOrders.length || 0;
        const totalSum = dayOrders.reduce((sum, order) => sum + parseInt(order.paymentPrice), 0) || 0;
    
        const dayPageViews = pageViews.filter(view => {
            const pageViewDate = new Date(view.pageViewDate).toISOString().split('T')[0]; // 페이지 뷰 날짜의 'YYYY-MM-DD' 부분만 추출
            return pageViewDate === tileDate; // 날짜만 비교
        });
        const visitorCount = dayPageViews.length || 0;
    
        return (
            <div className={`${admin.admin_dayly_total}`}>
                <div>주문수: {orderCount}</div>
                <div>합계: {totalSum.toLocaleString()}원</div>
                <div>방문자: {visitorCount}</div>
            </div>
        );
    };
    
    
    
    
    const calculateMonthlySummary = (activeStartDate) => {
        const activeMonth = activeStartDate.toISOString().slice(0, 7); // YYYY-MM 부분만 추출
    
        const currentMonthOrders = orderList.filter(order => 
            new Date(order.paymentDate).toISOString().slice(0, 7) === activeMonth // 월 단위로 비교
        );
    
        const orderCount = currentMonthOrders.length;
        const totalSum = currentMonthOrders.reduce((sum, order) => sum + parseInt(order.paymentPrice), 0);
    
        const currentMonthPageViews = pageViews.filter(view => 
            new Date(view.pageViewDate).toISOString().slice(0, 7) === activeMonth // 월 단위로 비교
        );
    
        const visitorCount = currentMonthPageViews.length;
    
        setMonthlySummary({
            year: `${activeStartDate.getFullYear()}년`,  // 여기서 getFullYear()로 수정
            month: `${activeStartDate.getMonth() + 1}월`,
            orderCount,
            totalSum,
            visitorCount
        });
    };
    
    
    return (
        <div className={`${admin.admin_monthly_container}`}>
            <div className={`${admin.admin_monthly_header}`}><span>월별 통계</span></div>
            <MonthlyStyledCalendar
                onClickDay={onDayClick}
                formatDay={formatDay}
                tileContent={tileContent}
                onActiveStartDateChange={({ activeStartDate }) => calculateMonthlySummary(activeStartDate)}
            />
            <div className={`${admin.admin_monthly_content}`}>
                <div>{monthlySummary.year} {monthlySummary.month} 합계</div>
                <div>
                    <div><span>주문수</span></div>
                    <div><span>합계</span></div>
                    <div><span>방문자</span></div>
                </div>
                <div>
                    <div><span>{monthlySummary.orderCount}</span></div>
                    <div><span>{monthlySummary.totalSum.toLocaleString()}원</span></div>
                    <div><span>{monthlySummary.visitorCount}</span></div>
                </div>
            </div>
        </div>
    );
};

export default MonthlyCalendar;
