import React, { useContext, useEffect, useState } from 'react';
import admin from '../../../admin.module.css';
import { CSSTransition } from 'react-transition-group';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { LoginContext } from '../../../../contexts/LoginContextProvider';
import * as adminApi from '../../../../apis/admin';
import { Pagination, Select } from 'antd';
import { Option } from 'antd/es/mentions';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

const AdminChange = () => {
    const { userInfo, roles } = useContext(LoginContext);
    const MySwal = withReactContent(Swal);
    const [activeButton, setActiveButton] = useState('newChange');
    const [changeProgress, setChangeProgress] = useState(false);
    const [orderList, setOrderList] = useState([]);
    const [deliveryCompanies, setDeliveryCompanies] = useState([]);
    const [deliveryInfo, setDeliveryInfo] = useState({});
    const [collectionDeliveryInfo, setCollectionDeliveryInfo] = useState({});
    const [expandedOrder, setExpandedOrder] = useState(null); // Manage expanded order state
    const [selectedOrders, setSelectedOrders] = useState([]);
    const [allSelected, setAllSelected] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(14);
    const [totalItems, setTotalItems] = useState(0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [showExchangeRefuse, setShowExchangeRefuse] = useState(false);
    const [showExchangePending, setShowExchangePending] = useState(false);
    const [refuseList, setRefuseList] = useState([]);
    const [showExchangeMobile, setShowExchangeMobile] = useState(false);
    const [showExchangeOtherMobile, setShowExchangeOtherMobile] = useState(false);

    const paginate = pageNumber => setCurrentPage(pageNumber);

    useEffect(() => {
        if (userInfo) {
            if(roles.isAdmin) {
                adimnOrderSelect();
                deliveryCompanySelect();
            } else {
                orderSelect();
                deliveryCompanySelect();
            }
        }
    }, [userInfo]);

    useEffect(() => {
        console.log('orderList : ', orderList);
    }, [orderList])

    const orderSelect = async () => {
        const response = await adminApi.orderSelect(userInfo.userId);
        const data = response.data.filter(order =>
            ["신규교환", "교환요청", "교환승인", "교환보류", "교환수거중", "교환수거완료", "재배송중", "교환완료"].includes(order.paymentState)
        );

        const initialDeliveryInfo = data.reduce((acc, order) => {
            acc[order.paymentNo] = {
                redeliveryCompany: order.redeliveryCompany || '',
                redeliveryNumber: order.redeliveryNumber || ''
            };
            return acc;
        }, {});

        const collectionInitialDeliveryInfo = data.reduce((acc, order) => {
            acc[order.paymentNo] = {
                collectionDeliveryCompany: order.collectionDeliveryCompany || '',
                collectionDeliveryNumber: order.collectionDeliveryNumber || ''
            };
            return acc;
        }, {});

        setOrderList(data);
        setTotalItems(data.length);
        setDeliveryInfo(initialDeliveryInfo); 
        setCollectionDeliveryInfo(collectionInitialDeliveryInfo);
    };
    const adimnOrderSelect = async () => {
        const response = await adminApi.adminOrderSelect();
        const data = response.data.filter(order =>
            ["신규교환", "교환요청", "교환승인", "교환보류", "교환수거중", "교환수거완료", "재배송중", "교환완료"].includes(order.paymentState)
        );

        const initialDeliveryInfo = data.reduce((acc, order) => {
            acc[order.paymentNo] = {
                redeliveryCompany: order.redeliveryCompany || '',
                redeliveryNumber: order.redeliveryNumber || ''
            };
            return acc;
        }, {});

        const collectionInitialDeliveryInfo = data.reduce((acc, order) => {
            acc[order.paymentNo] = {
                collectionDeliveryCompany: order.collectionDeliveryCompany || '',
                collectionDeliveryNumber: order.collectionDeliveryNumber || ''
            };
            return acc;
        }, {});

        setOrderList(data);
        setTotalItems(data.length);
        setDeliveryInfo(initialDeliveryInfo); 
        setCollectionDeliveryInfo(collectionInitialDeliveryInfo);
    }

    const deliveryCompanySelect = async () => {
        try {
            const response = await fetch('https://apis.tracker.delivery/carriers');
            const data = await response.json();

            console.log('delivery : ', data);
            setDeliveryCompanies(data);
        } catch (error) {
            console.error('Error fetching delivery companies:', error);
        }
    };

    const filteredOrders = orderList.filter(order => {
        if (activeButton === 'newChange') {
            return ["신규교환", "교환요청", "교환승인", "교환수거중", "교환수거완료"].includes(order.paymentState);
        }
        if (activeButton === 'requestChange') {
            return order.paymentState === '교환요청';
        }
        if (activeButton === 'exchangeApprove') {
            return order.paymentState === '교환승인';
        }
        if (activeButton === 'exchangePending') {
            return order.paymentState === '교환보류';
        }
        if (activeButton === 'collecting') {
            return order.paymentState === '교환수거중';
        }
        if (activeButton === 'completeCollecting') {
            return order.paymentState === '교환수거완료';
        }
        if (activeButton === 'progressRedelivery') {
            return order.paymentState === '재배송중';
        }
        if (activeButton === 'completeChage') {
            return order.paymentState === '교환완료';
        }
        return true;
    });

    const calculateProductPrice = (order) => {
        if(order.options.length !== 0) {
            const optionsTotal = order.options.reduce((total, option) => {
                return total + (order.principalAmount) + (option.addPrice);
            }, 0);
            
            return optionsTotal;
        } else {
            console.log('여기 접근')
            const principalAmount = order.principalAmount;
            return principalAmount;
            
        }
    }

    const handleDeliveryCompanyChange = (value, paymentNo) => {
        setDeliveryInfo(prev => ({
            ...prev,
            [paymentNo]: {
                ...prev[paymentNo],
                redeliveryCompany: value
            }
        }));
    };

    const handleWaybillNumberChange = (e, paymentNo) => {
        setDeliveryInfo(prev => ({
            ...prev,
            [paymentNo]: {
                ...prev[paymentNo],
                redeliveryNumber: e.target.value
            }
        }));
    };

    const handleDeliveryCompanyColletionChange = (value, paymentNo) => {
        setCollectionDeliveryInfo(prev => ({
            ...prev,
            [paymentNo]: {
                ...prev[paymentNo],
                collectionDeliveryCompany: value
            }
        }));
    };

    const handleWaybillNumberColletionChange = (e, paymentNo) => {
        setCollectionDeliveryInfo(prev => ({
            ...prev,
            [paymentNo]: {
                ...prev[paymentNo],
                collectionDeliveryNumber: e.target.value
            }
        }));
    };

    const onReDeliveryAdd = (order) => {
        console.log('order : ',deliveryInfo);
    }

    const toggleExpandOrder = (paymentNo) => {
        setExpandedOrder(prev => (prev === paymentNo ? null : paymentNo));
    }

    const handleSelectOrder = (paymentNo) => {
        setSelectedOrders(prev => {
            const newSelectedOrders = prev.includes(paymentNo)
                ? prev.filter(no => no !== paymentNo)
                : [...prev, paymentNo];
            setAllSelected(newSelectedOrders.length === filteredOrders.length);
            return newSelectedOrders;
        });
    };

    const handleSelectAll = () => {
        if (allSelected) {
            setSelectedOrders([]);
        } else {
            const allOrderNos = filteredOrders.map(order => order.paymentNo);
            setSelectedOrders(allOrderNos);
        }
        setAllSelected(!allSelected);
    };

    const hadleExchangReDelivery = () => {
        if(selectedOrders.length === 0) {
            alert('선택된 교환 상품이 없습니다.');

            return;
        }
        console.log('selectedOrders : ', selectedOrders);
        const deliveryProcessing = [];
    
        let hasEmptyFields = false;
    
        selectedOrders.forEach(paymentNo => {
            const info = deliveryInfo[paymentNo];
            console.log(`Payment No: ${paymentNo}, Delivery Company: ${info?.redeliveryCompany}, Waybill Number: ${info?.redeliveryNumber}`);
    
            if (!info?.redeliveryCompany || !info?.redeliveryNumber) {
                hasEmptyFields = true;
            }
    
            deliveryProcessing.push({
                paymentNo: paymentNo,
                redeliveryCompany: info?.redeliveryCompany,
                redeliveryNumber: info?.redeliveryNumber
            });
        });
    
        if (hasEmptyFields) {
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html: `<div><div class='${admin.admin_seller_add_title}'><span>선택된 상품의 택배사와 운송장번호를 입력해주세요</span></div></div>`,
                showConfirmButton: false,
                showCancelButton: false,
                timer: 2000
            });
            return;
        }
    
        console.log('deliveryProcessing : ', deliveryProcessing);
        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html: `
                <div>
                    <div class='${admin.admin_seller_add_title}'><span>${selectedOrders.length}개 상품을 재발송 처리 하시겠습니까?</span></div>
                    <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                      <button class='mx-1' id='seller_add_cancel'>취소</button>
                      <button class='mx-1' id='seller_add_success'>재발송처리</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });
        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);
    
        async function sellerAddSuccess(e) {
            if (e.target && e.target.id === 'seller_add_success') {
                try {
                    const response = await adminApi.redeliveryUpdate(deliveryProcessing);
                    const data = response.data;
        
                    if (data === 'SUCCESS') {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                            <div class='${admin.admin_seller_add_title}'><span>${selectedOrders.length}개 상품이 재발송 처리 되었습니다.</span></div>
                            <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);
        
                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                if(roles.isAdmin) {
                                    adimnOrderSelect();
                                } else {
                                    orderSelect();
                                }
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    } else {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                            <div class='${admin.admin_seller_add_title}'><span>${selectedOrders.length}개 상품 재발송 처리에 실패했습니다.</span></div>
                            <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);
        
                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                if(roles.isAdmin) {
                                    adimnOrderSelect();
                                } else {
                                    orderSelect();
                                }
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    }
                } catch (error) {
                    console.error('error : ', error);
                }
            }
        }
    
        function sellerAddCancel(e) {
            if (e.target && e.target.id === 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
            }
        }
    }

    const handleExchangeSuccess = () => {
        if(selectedOrders.length === 0) {
            alert('선택된 교환 상품이 없습니다.');

            return;
        }
        console.log('selectedOrders : ', selectedOrders);
        const deliveryProcessing = [];
    
        let hasEmptyFields = false;
    
        selectedOrders.forEach(paymentNo => {

            const info = deliveryInfo[paymentNo];

            console.log(`Payment No: ${paymentNo}, Delivery Company: ${info?.redeliveryCompany}, Waybill Number: ${info?.redeliveryNumber}`);


            deliveryProcessing.push({
                paymentNo: paymentNo,
                redeliveryCompany: info?.redeliveryCompany,
                redeliveryNumber: info?.redeliveryNumber
            });
        });


        console.log('deliveryProcessing : ', deliveryProcessing);
        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html: `
                <div>
                    <div class='${admin.admin_seller_add_title}'><span>${selectedOrders.length}개 상품을 교환완료 처리 하시겠습니까?</span></div>
                    <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                      <button class='mx-1' id='seller_add_cancel'>취소</button>
                      <button class='mx-1' id='seller_add_success'>교환완료</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });
        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);
    
        async function sellerAddSuccess(e) {
            if (e.target && e.target.id === 'seller_add_success') {
                try {
                    const response = await adminApi.redeliveryExchangeSuccess(deliveryProcessing);
                    const data = response.data;
        
                    if (data === 'SUCCESS') {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                            <div class='${admin.admin_seller_add_title}'><span>${selectedOrders.length}개 상품이 교환완료 처리 되었습니다.</span></div>
                            <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);
        
                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                if(roles.isAdmin) {
                                    adimnOrderSelect();
                                } else {
                                    orderSelect();
                                }
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    } else {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                            <div class='${admin.admin_seller_add_title}'><span>${selectedOrders.length}개 상품 교환완료 처리에 실패했습니다.</span></div>
                            <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);
        
                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                if(roles.isAdmin) {
                                    adimnOrderSelect();
                                } else {
                                    orderSelect();
                                }
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    }
                } catch (error) {
                    console.error('error : ', error);
                }
            }
        }
    
        function sellerAddCancel(e) {
            if (e.target && e.target.id === 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
            }
        }
    }

    const handleCollection = () => {
        if(selectedOrders.length === 0) {
            alert('선택된 교환 상품이 없습니다.');

            return;
        }
        console.log('selectedOrders : ', selectedOrders);
        const selectedOrderDetails = orderList.filter(order => selectedOrders.includes(order.paymentNo))
        const deliveryProcessing = [];
    
        let hasEmptyFields = false;
    
        for (let order of selectedOrderDetails) {

            if(order.paymentState !== '교환요청') {
                alert('교환요청인 상품만 수거지시가 가능합니다.');
                return;
            }

            const info = collectionDeliveryInfo[order.paymentNo];
            console.log(`Payment No: ${order.paymentNo}, Delivery Company: ${info?.collectionDeliveryCompany}, Waybill Number: ${info?.collectionDeliveryNumber}`);
                   
            if (collectionDeliveryInfo[order.paymentNo]?.collectionDeliveryCompany === '' || collectionDeliveryInfo[order.paymentNo]?.collectionDeliveryNumber === '') {
                alert(`주문번호 ${order.paymentNumber}의 수거택배명과 운송장번호가 비었습니다.`);
                return;
            }

            deliveryProcessing.push({
                paymentNo: order.paymentNo,
                collectionDeliveryCompany: info?.collectionDeliveryCompany,
                collectionDeliveryNumber: info?.collectionDeliveryNumber,
                userId: order.userId,
                paymentProductName: order.paymentProductName
            });
        };

        console.log('deliveryProcessing : ', deliveryProcessing);

        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html: `
                <div>
                    <div class='${admin.admin_seller_add_title}'><span>${selectedOrders.length}개 상품을 수거지시 처리 하시겠습니까?</span></div>
                    <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                      <button class='mx-1' id='seller_add_cancel'>취소</button>
                      <button class='mx-1' id='seller_add_success'>수거지시</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });
        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);
    
        async function sellerAddSuccess(e) {
            if (e.target && e.target.id === 'seller_add_success') {
                try {
                    const response = await adminApi.collectionDelivery(deliveryProcessing);
                    const data = response.data;
        
                    if (data === 'SUCCESS') {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                            <div class='${admin.admin_seller_add_title}'><span>${selectedOrders.length}개 상품이 수거지시 처리 되었습니다.</span></div>
                            <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);
        
                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                if(roles.isAdmin) {
                                    adimnOrderSelect();
                                } else {
                                    orderSelect();
                                }
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    } else {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                            <div class='${admin.admin_seller_add_title}'><span>${selectedOrders.length}개 상품 수거지시 처리에 실패했습니다.</span></div>
                            <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);
        
                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                if(roles.isAdmin) {
                                    adimnOrderSelect();
                                } else {
                                    orderSelect();
                                }
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    }
                } catch (error) {
                    console.error('error : ', error);
                }
            }
        }
    
        function sellerAddCancel(e) {
            if (e.target && e.target.id === 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
            }
        }
    }

    const handleCollectionComplete = () => {
        if(selectedOrders.length === 0) {
            alert('선택된 교환 상품이 없습니다.');

            return;
        }
        console.log('selectedOrders : ', selectedOrders);
        const selectedOrderDetails = orderList.filter(order => selectedOrders.includes(order.paymentNo))
        const deliveryProcessing = [];
    
        let hasEmptyFields = false;
    
        for (let order of selectedOrderDetails) {

            if(order.paymentState !== '교환수거중') {
                alert('교환수거중인 상품만 수거완료 처리가 가능합니다.');
                return;
            }

            const info = collectionDeliveryInfo[order.paymentNo];
            console.log(`Payment No: ${order.paymentNo}, Delivery Company: ${info?.collectionDeliveryCompany}, Waybill Number: ${info?.collectionDeliveryNumber}`);

            deliveryProcessing.push({
                paymentNo: order.paymentNo,
                collectionDeliveryCompany: info?.collectionDeliveryCompany,
                collectionDeliveryNumber: info?.collectionDeliveryNumber,
                userId: order.userId
            });
        };

        console.log('deliveryProcessing : ', deliveryProcessing);

        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html: `
                <div>
                    <div class='${admin.admin_seller_add_title}'><span>${selectedOrders.length}개 상품을 수거완료 처리 하시겠습니까?</span></div>
                    <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                      <button class='mx-1' id='seller_add_cancel'>취소</button>
                      <button class='mx-1' id='seller_add_success'>수거지시</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });
        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);
    
        async function sellerAddSuccess(e) {
            if (e.target && e.target.id === 'seller_add_success') {
                try {
                    const response = await adminApi.collectionDeliveryComplete(deliveryProcessing);
                    const data = response.data;
        
                    if (data === 'SUCCESS') {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                            <div class='${admin.admin_seller_add_title}'><span>${selectedOrders.length}개 상품이 수거완료 처리 되었습니다.</span></div>
                            <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);
        
                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                if(roles.isAdmin) {
                                    adimnOrderSelect();
                                } else {
                                    orderSelect();
                                }
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    } else {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                            <div class='${admin.admin_seller_add_title}'><span>${selectedOrders.length}개 상품 수거완료 처리에 실패했습니다.</span></div>
                            <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);
        
                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                if(roles.isAdmin) {
                                    adimnOrderSelect();
                                } else {
                                    orderSelect();
                                }
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    }
                } catch (error) {
                    console.error('error : ', error);
                }
            }
        }
    
        function sellerAddCancel(e) {
            if (e.target && e.target.id === 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
            }
        }
    }

    const handleExchangeApproval = () => {
        if (selectedOrders.length === 0) {
            alert('선택된 교환 주문이 없습니다.');
            return;
        }
    
        const hasInvalidState = selectedOrders.some(paymentNo => {
            const order = orderList.find(order => order.paymentNo === paymentNo);
            console.log('order : ', order);
    
            return ['교환승인', '교환수거중', '교환수거완료', '재배송중', '교환완료'].includes(order.paymentState);
        });
    
        if (hasInvalidState) {
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html: `<div><div class='${admin.admin_seller_add_title}'><span>교환 승인이 필요한 아이템만 선택해주세요.</span></div></div>`,
                html: `<div><div class='${admin.admin_seller_sub_title}'><span>교환요청 상태인 아이템만 교환 승인이 가능합니다..</span></div></div>`,
                showConfirmButton: false,
                showCancelButton: false,
                timer: 2000
            });
            return;
        } else {
            const selectedOrderDetails = orderList.filter(order => selectedOrders.includes(order.paymentNo));
        
            // `selectedOrderDetails`에 올바른 데이터가 있는지 확인하기 위해 콘솔에 출력합니다.
            console.log('selectedOrderDetails : ', selectedOrderDetails);
        
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html: `
                    <div>
                        <div class='${admin.admin_seller_add_title}'><span>교환 승인 처리하시겠습니까?</span></div>
                        <div class='${admin.admin_seller_add_sub_title}'><span>교환 요청인 상품만 승인처리됩니다.</span></div>
                        <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                          <button class='mx-1' id='seller_add_cancel'>아니오</button>
                          <button class='mx-1' id='seller_add_success'>예</button>
                        </div>
                    </div>
                `,
                showConfirmButton: false,
                showCancelButton: false,
            });
        
            document.addEventListener('click', sellerAddSuccess);
            document.addEventListener('click', sellerAddCancel);
        
            async function sellerAddSuccess(e) {
                if (e.target && e.target.id === 'seller_add_success') {
                    try {
                        const response = await adminApi.exchangeApproval(selectedOrderDetails);
                        const data = response.data;
        
                        console.log('API Response : ', data);
        
                        if (data === 'SUCCESS') {
                            MySwal.fire({
                                customClass: {
                                    popup: `${admin.admin_seller_add_popup}`
                                },
                                html: `
                                <div>
                                    <div class='${admin.admin_seller_add_title}'><span>교환 승인이 완료 되었습니다.</span></div>
                                    <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                                    </div>
                                </div>
                                `,
                                showConfirmButton: false,
                                showCancelButton: false,
                            });
                            document.addEventListener('click', sellerAddConfirm);
        
                            function sellerAddConfirm(e) {
                                if (e.target && e.target.id === 'seller_add_confirm') {
                                    if(roles.isAdmin) {
                                        adimnOrderSelect();
                                    } else {
                                        orderSelect();
                                    }
                                    MySwal.close();
                                    document.removeEventListener('click', sellerAddConfirm);
                                    document.removeEventListener('click', sellerAddSuccess);
                                    document.removeEventListener('click', sellerAddCancel);
                                }
                            }
                        } else {
                            MySwal.fire({
                                customClass: {
                                    popup: `${admin.admin_seller_add_popup}`
                                },
                                html: `
                                <div>
                                    <div class='${admin.admin_seller_add_title}'><span>교환 승인 상태 변경 처리에 실패했습니다.</span></div>
                                    <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                                    </div>
                                </div>
                                `,
                                showConfirmButton: false,
                                showCancelButton: false,
                            });
                            document.addEventListener('click', sellerAddConfirm);
        
                            function sellerAddConfirm(e) {
                                if (e.target && e.target.id === 'seller_add_confirm') {
                                    if(roles.isAdmin) {
                                        adimnOrderSelect();
                                    } else {
                                        orderSelect();
                                    }
                                    MySwal.close();
                                    document.removeEventListener('click', sellerAddConfirm);
                                    document.removeEventListener('click', sellerAddSuccess);
                                    document.removeEventListener('click', sellerAddCancel);
                                }
                            }
                        }
                    } catch (error) {
                        console.error('error : ', error);
                    }
                }
            }
        
            function sellerAddCancel(e) {
                if (e.target && e.target.id === 'seller_add_cancel') {
                    MySwal.close();
                    document.removeEventListener('click', sellerAddSuccess);
                    document.removeEventListener('click', sellerAddCancel);
                }
            }
        }
    
    }

    // 교환거절
    const handleExchangeRefuse = () => {
        if (selectedOrders.length === 0) {
            alert('선택된 교환 주문이 없습니다.');
            return;
        }

        const hasInvalidState = selectedOrders.some(paymentNo => {
            const order = orderList.find(order => order.paymentNo === paymentNo);
            console.log('order : ', order);
    
            return ['교환수거중', '교환수거완료', '재배송중', '교환완료'].includes(order.paymentState);
        });
    
        if (hasInvalidState) {
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html: `<div><div class='${admin.admin_seller_add_title}'><span>교환 거부가능한 아이템이 없습니다..</span></div></div>`,
                html: `<div><div class='${admin.admin_seller_sub_title}'><span>교환요청, 승인 상태인 아이템만 교환 거부 가능합니다.</span></div></div>`,
                showConfirmButton: false,
                showCancelButton: false,
                timer: 2000
            });
            return;
        } else {
            const selectedOrderDetails = orderList.filter(order => selectedOrders.includes(order.paymentNo));
    
            console.log(selectedOrderDetails); // 최종 리스트 확인
            setRefuseList(selectedOrderDetails);
            // API 호출 등 필요한 로직 추가
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html: `
                    <div>
                        <div class='${admin.admin_seller_add_title}'><span>${selectedOrders.length}개 상품을 교환거절 처리 하시겠습니까?</span></div>
                        <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                        <button class='mx-1' id='seller_add_cancel'>취소</button>
                        <button class='mx-1' id='seller_add_success'>교환거절</button>
                        </div>
                    </div>
                `,
                showConfirmButton: false,
                showCancelButton: false,
            });
    
    
            document.addEventListener('click', sellerAddSuccess);
            document.addEventListener('click', sellerAddCancel);
    
            async function sellerAddSuccess(e) {
                if (e.target && e.target.id === 'seller_add_success') {
                    document.removeEventListener('click', sellerAddSuccess);
                    document.removeEventListener('click', sellerAddCancel);
                    if(roles.isAdmin) {
                        adimnOrderSelect();
                    } else {
                        orderSelect();
                    }
                    MySwal.close();
                    setShowExchangeRefuse(true);
                }
            }
    
            function sellerAddCancel(e) {
                if (e.target && e.target.id === 'seller_add_cancel') {
                    MySwal.close();
                    document.removeEventListener('click', sellerAddSuccess);
                    document.removeEventListener('click', sellerAddCancel);
                }
            }
        }
    }

    // 교환보류
    const handleExchangeHold = () => {
        if (selectedOrders.length === 0) {
            alert('선택된 교환 주문이 없습니다.');
            return;
        }

        const hasInvalidState = selectedOrders.some(paymentNo => {
            const order = orderList.find(order => order.paymentNo === paymentNo);
            console.log('order : ', order);

            return ['교환수거중', '교환수거완료', '재배송중', '교환완료'].includes(order.paymentState);
        });

        if (hasInvalidState) {
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html: `<div><div class='${admin.admin_seller_add_title}'><span>교환 보류가능한 아이템이 없습니다..</span></div></div>`,
                html: `<div><div class='${admin.admin_seller_sub_title}'><span>교환요청, 승인 상태인 아이템만 교환 보류 가능합니다.</span></div></div>`,
                showConfirmButton: false,
                showCancelButton: false,
                timer: 2000
            });
            return;
        } else {
            const selectedOrderDetails = orderList.filter(order => selectedOrders.includes(order.paymentNo));

            console.log(selectedOrderDetails); // 최종 리스트 확인
            setRefuseList(selectedOrderDetails);
            // API 호출 등 필요한 로직 추가
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html: `
                    <div>
                        <div class='${admin.admin_seller_add_title}'><span>${selectedOrders.length}개 상품을 교환보류 처리 하시겠습니까?</span></div>
                        <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                        <button class='mx-1' id='seller_add_cancel'>취소</button>
                        <button class='mx-1' id='seller_add_success'>교환보류</button>
                        </div>
                    </div>
                `,
                showConfirmButton: false,
                showCancelButton: false,
            });


            document.addEventListener('click', sellerAddSuccess);
            document.addEventListener('click', sellerAddCancel);

            async function sellerAddSuccess(e) {
                if (e.target && e.target.id === 'seller_add_success') {
                    document.removeEventListener('click', sellerAddSuccess);
                    document.removeEventListener('click', sellerAddCancel);
                    if(roles.isAdmin) {
                        adimnOrderSelect();
                    } else {
                        orderSelect();
                    }
                    MySwal.close();
                    setShowExchangePending(true);
                }
            }

            function sellerAddCancel(e) {
                if (e.target && e.target.id === 'seller_add_cancel') {
                    MySwal.close();
                    document.removeEventListener('click', sellerAddSuccess);
                    document.removeEventListener('click', sellerAddCancel);
                }
            }
        }
    }

    // 교환 보류 해제
    const handleExchangeHoldUnlock = () => {
        if (selectedOrders.length === 0) {
            alert('선택된 교환 주문이 없습니다.');
            return;
        }
    
        const hasInvalidState = selectedOrders.some(paymentNo => {
            const order = orderList.find(order => order.paymentNo === paymentNo);
            console.log('order : ', order);
    
            return ['교환요청', '교환승인', '교환수거중', '교환수거완료', '재배송중', '교환완료'].includes(order.paymentState);
        });
    
        if (hasInvalidState) {
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html: `<div><div class='${admin.admin_seller_add_title}'><span>교환 보류 상태인 아이템만 선택해주세요.</span></div></div>`,
                html: `<div><div class='${admin.admin_seller_sub_title}'><span>교환보류 상태인 아이템만 교환 보류 해제가 가능합니다.</span></div></div>`,
                showConfirmButton: false,
                showCancelButton: false,
                timer: 2000
            });
            return;
        } else {
            const selectedOrderDetails = orderList.filter(order => selectedOrders.includes(order.paymentNo));
        
            // `selectedOrderDetails`에 올바른 데이터가 있는지 확인하기 위해 콘솔에 출력합니다.
            console.log('selectedOrderDetails : ', selectedOrderDetails);
        
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html: `
                    <div>
                        <div class='${admin.admin_seller_add_title}'><span>교환 보류 해제 처리하시겠습니까?</span></div>
                        <div class='${admin.admin_seller_add_sub_title}'><span>기존에 작성한 보류 사유가 제거되며 사용자에게 알림이 발송됩니다.</span></div>
                        <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                          <button class='mx-1' id='seller_add_cancel'>아니오</button>
                          <button class='mx-1' id='seller_add_success'>예</button>
                        </div>
                    </div>
                `,
                showConfirmButton: false,
                showCancelButton: false,
            });
        
            document.addEventListener('click', sellerAddSuccess);
            document.addEventListener('click', sellerAddCancel);
        
            async function sellerAddSuccess(e) {
                if (e.target && e.target.id === 'seller_add_success') {
                    try {
                        const response = await adminApi.exchangeHoldUnlock(selectedOrderDetails);
                        const data = response.data;
        
                        console.log('API Response : ', data);
        
                        if (data === 'SUCCESS') {
                            MySwal.fire({
                                customClass: {
                                    popup: `${admin.admin_seller_add_popup}`
                                },
                                html: `
                                <div>
                                    <div class='${admin.admin_seller_add_title}'><span>교환 보류 해제가 완료 되었습니다.</span></div>
                                    <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                                    </div>
                                </div>
                                `,
                                showConfirmButton: false,
                                showCancelButton: false,
                            });
                            document.addEventListener('click', sellerAddConfirm);
        
                            function sellerAddConfirm(e) {
                                if (e.target && e.target.id === 'seller_add_confirm') {
                                    if(roles.isAdmin) {
                                        adimnOrderSelect();
                                    } else {
                                        orderSelect();
                                    }
                                    MySwal.close();
                                    document.removeEventListener('click', sellerAddConfirm);
                                    document.removeEventListener('click', sellerAddSuccess);
                                    document.removeEventListener('click', sellerAddCancel);
                                }
                            }
                        } else {
                            MySwal.fire({
                                customClass: {
                                    popup: `${admin.admin_seller_add_popup}`
                                },
                                html: `
                                <div>
                                    <div class='${admin.admin_seller_add_title}'><span>교환 보류 해제 상태 변경 처리에 실패했습니다.</span></div>
                                    <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                                    </div>
                                </div>
                                `,
                                showConfirmButton: false,
                                showCancelButton: false,
                            });
                            document.addEventListener('click', sellerAddConfirm);
        
                            function sellerAddConfirm(e) {
                                if (e.target && e.target.id === 'seller_add_confirm') {
                                    if(roles.isAdmin) {
                                        adimnOrderSelect();
                                    } else {
                                        orderSelect();
                                    }
                                    MySwal.close();
                                    document.removeEventListener('click', sellerAddConfirm);
                                    document.removeEventListener('click', sellerAddSuccess);
                                    document.removeEventListener('click', sellerAddCancel);
                                }
                            }
                        }
                    } catch (error) {
                        console.error('error : ', error);
                    }
                }
            }
        
            function sellerAddCancel(e) {
                if (e.target && e.target.id === 'seller_add_cancel') {
                    MySwal.close();
                    document.removeEventListener('click', sellerAddSuccess);
                    document.removeEventListener('click', sellerAddCancel);
                }
            }
        }
    }
    const handleExchangeRequest = () => {
        if (selectedOrders.length === 0) {
            alert('선택된 교환 주문이 없습니다.');
            return;
        }
    
        const hasInvalidState = selectedOrders.some(paymentNo => {
            const order = orderList.find(order => order.paymentNo === paymentNo);
            console.log('order : ', order);
    
            return ['교환요청', '재배송중', '교환완료'].includes(order.paymentState);
        });
    
        if (hasInvalidState) {
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html: `<div><div class='${admin.admin_seller_add_title}'><span>교환 요청 상태로 되돌릴 수 없습니다.</span></div></div>`,
                html: `<div><div class='${admin.admin_seller_sub_title}'><span>교환승인, 수거중, 수거완료 상태인 아이템만 교환요청 상태 변경이 가능합니다.</span></div></div>`,
                showConfirmButton: false,
                showCancelButton: false,
                timer: 2000
            });
            return;
        } else {
            const selectedOrderDetails = orderList.filter(order => selectedOrders.includes(order.paymentNo));
        
            // `selectedOrderDetails`에 올바른 데이터가 있는지 확인하기 위해 콘솔에 출력합니다.
            console.log('selectedOrderDetails : ', selectedOrderDetails);
        
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html: `
                    <div>
                        <div class='${admin.admin_seller_add_title}'><span>교환 요청 상태로 변경하시겠습니까?</span></div>
                        <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                          <button class='mx-1' id='seller_add_cancel'>아니오</button>
                          <button class='mx-1' id='seller_add_success'>예</button>
                        </div>
                    </div>
                `,
                showConfirmButton: false,
                showCancelButton: false,
            });
        
            document.addEventListener('click', sellerAddSuccess);
            document.addEventListener('click', sellerAddCancel);
        
            async function sellerAddSuccess(e) {
                if (e.target && e.target.id === 'seller_add_success') {
                    try {
                        const response = await adminApi.exchangeRequest(selectedOrderDetails);
                        const data = response.data;
        
                        console.log('API Response : ', data);
        
                        if (data === 'SUCCESS') {
                            MySwal.fire({
                                customClass: {
                                    popup: `${admin.admin_seller_add_popup}`
                                },
                                html: `
                                <div>
                                    <div class='${admin.admin_seller_add_title}'><span>교환요청으로 되돌리기가 완료 되었습니다.</span></div>
                                    <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                                    </div>
                                </div>
                                `,
                                showConfirmButton: false,
                                showCancelButton: false,
                            });
                            document.addEventListener('click', sellerAddConfirm);
        
                            function sellerAddConfirm(e) {
                                if (e.target && e.target.id === 'seller_add_confirm') {
                                    if(roles.isAdmin) {
                                        adimnOrderSelect();
                                    } else {
                                        orderSelect();
                                    }
                                    MySwal.close();
                                    document.removeEventListener('click', sellerAddConfirm);
                                    document.removeEventListener('click', sellerAddSuccess);
                                    document.removeEventListener('click', sellerAddCancel);
                                }
                            }
                        } else {
                            MySwal.fire({
                                customClass: {
                                    popup: `${admin.admin_seller_add_popup}`
                                },
                                html: `
                                <div>
                                    <div class='${admin.admin_seller_add_title}'><span>교환요청 상태 변경 처리에 실패했습니다.</span></div>
                                    <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                                    </div>
                                </div>
                                `,
                                showConfirmButton: false,
                                showCancelButton: false,
                            });
                            document.addEventListener('click', sellerAddConfirm);
        
                            function sellerAddConfirm(e) {
                                if (e.target && e.target.id === 'seller_add_confirm') {
                                    if(roles.isAdmin) {
                                        adimnOrderSelect();
                                    } else {
                                        orderSelect();
                                    }
                                    MySwal.close();
                                    document.removeEventListener('click', sellerAddConfirm);
                                    document.removeEventListener('click', sellerAddSuccess);
                                    document.removeEventListener('click', sellerAddCancel);
                                }
                            }
                        }
                    } catch (error) {
                        console.error('error : ', error);
                    }
                }
            }
        
            function sellerAddCancel(e) {
                if (e.target && e.target.id === 'seller_add_cancel') {
                    MySwal.close();
                    document.removeEventListener('click', sellerAddSuccess);
                    document.removeEventListener('click', sellerAddCancel);
                }
            }
        }
    }

    const handleExchangeCollecting = () => {
        if (selectedOrders.length === 0) {
            alert('선택된 교환 주문이 없습니다.');
            return;
        }

    
        const hasInvalidState = selectedOrders.some(paymentNo => {
            const order = orderList.find(order => order.paymentNo === paymentNo);
            console.log('order : ', order);
    
            return ['교환수거중', '교환보류', '재배송중', '교환완료'].includes(order.paymentState);
        });
    
        if (hasInvalidState) {
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html: `
                    <div>
                        <div class='${admin.admin_seller_add_title}'><span>교환 수거중 상태로 되돌릴 수 없습니다.</span></div>
                    </div>
                `,
                html: `
                    <div>
                        <div class='${admin.admin_seller_sub_title}'><span>교환요청, 승인, 수거완료 상태인 아이템만 교환요청 상태 변경이 가능합니다.</span></div>
                    </div>
                `,
                showConfirmButton: false,
                showCancelButton: false,
                timer: 2000
            });
            return;
            } else {
                const selectedOrderDetails = orderList.filter(order => selectedOrders.includes(order.paymentNo));
            
            // `selectedOrderDetails`에 올바른 데이터가 있는지 확인하기 위해 콘솔에 출력합니다.
            console.log('selectedOrderDetails : ', selectedOrderDetails);
            
            const deliveryProcessing = [];
            for (let order of selectedOrderDetails) {          
                if(collectionDeliveryInfo[order.paymentNo]?.collectionDeliveryCompany === '' || collectionDeliveryInfo[order.paymentNo]?.collectionDeliveryNumber === '' ) {
                    alert('수거 배송정보가 비어있습니다.');
                    return;
                }
                const info = collectionDeliveryInfo[order.paymentNo];
                console.log(`Payment No: ${order.paymentNo}, Delivery Company: ${info?.collectionDeliveryCompany}, Waybill Number: ${info?.collectionDeliveryNumber}`);
            
                deliveryProcessing.push({
                    paymentNo: order.paymentNo,
                    collectionDeliveryCompany: info?.collectionDeliveryCompany,
                    collectionDeliveryNumber: info?.collectionDeliveryNumber,
                    userId: order.userId
                });
            };
            
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html: `
                    <div>
                        <div class='${admin.admin_seller_add_title}'><span>교환 수거중 상태로 변경하시겠습니까?</span></div>
                        <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                          <button class='mx-1' id='seller_add_cancel'>아니오</button>
                          <button class='mx-1' id='seller_add_success'>예</button>
                        </div>
                    </div>
                `,
                showConfirmButton: false,
                showCancelButton: false,
            });
            
            document.addEventListener('click', sellerAddSuccess);
            document.addEventListener('click', sellerAddCancel);
            
            async function sellerAddSuccess(e) {
                if (e.target && e.target.id === 'seller_add_success') {
                    try {
                        const response = await adminApi.exchangeCollecting(deliveryProcessing);
                        const data = response.data;
            
                        console.log('API Response : ', data);
            
                        if (data === 'SUCCESS') {
                            MySwal.fire({
                                customClass: {
                                    popup: `${admin.admin_seller_add_popup}`
                                },
                                html: `
                                <div>
                                    <div class='${admin.admin_seller_add_title}'><span>교환 수거중으로 되돌리기가 완료 되었습니다.</span></div>
                                    <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                                    </div>
                                </div>
                                `,
                                showConfirmButton: false,
                                showCancelButton: false,
                            });
                            document.addEventListener('click', sellerAddConfirm);
            
                            function sellerAddConfirm(e) {
                                if (e.target && e.target.id === 'seller_add_confirm') {
                                    if(roles.isAdmin) {
                                        adimnOrderSelect();
                                    } else {
                                        orderSelect();
                                    }
                                    MySwal.close();
                                    document.removeEventListener('click', sellerAddConfirm);
                                    document.removeEventListener('click', sellerAddSuccess);
                                    document.removeEventListener('click', sellerAddCancel);
                                }
                            }
                        } else {
                            MySwal.fire({
                                customClass: {
                                    popup: `${admin.admin_seller_add_popup}`
                                },
                                html: `
                                <div>
                                    <div class='${admin.admin_seller_add_title}'><span>교환수거중 상태 변경 처리에 실패했습니다.</span></div>
                                    <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                                    </div>
                                </div>
                                `,
                                showConfirmButton: false,
                                showCancelButton: false,
                            });
                            document.addEventListener('click', sellerAddConfirm);
            
                            function sellerAddConfirm(e) {
                                if (e.target && e.target.id === 'seller_add_confirm') {
                                    if(roles.isAdmin) {
                                        adimnOrderSelect();
                                    } else {
                                        orderSelect();
                                    }
                                    MySwal.close();
                                    document.removeEventListener('click', sellerAddConfirm);
                                    document.removeEventListener('click', sellerAddSuccess);
                                    document.removeEventListener('click', sellerAddCancel);
                                }
                            }
                        }
                    } catch (error) {
                        console.error('error : ', error);
                    }
                }
            }
            
            function sellerAddCancel(e) {
                if (e.target && e.target.id === 'seller_add_cancel') {
                    MySwal.close();
                    document.removeEventListener('click', sellerAddSuccess);
                    document.removeEventListener('click', sellerAddCancel);
                }
            }
        }
    }
            

    const handleExchangeCollectComp = () => {
        if (selectedOrders.length === 0) {
            alert('선택된 교환 주문이 없습니다.');
            return;
        }
    
        const hasInvalidState = selectedOrders.some(paymentNo => {
            const order = orderList.find(order => order.paymentNo === paymentNo);
            console.log('order : ', order);
    
            return ['교환수거완료', '교환보류', '재배송중', '교환완료'].includes(order.paymentState);
        });
    
        if (hasInvalidState) {
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html: `<div><div class='${admin.admin_seller_add_title}'><span>교환수거완료 상태로 되돌릴 수 없습니다.</span></div></div>`,
                html: `<div><div class='${admin.admin_seller_sub_title}'><span>교환요청, 승인, 수거중 상태인 아이템만 교환요청 상태 변경이 가능합니다.</span></div></div>`,
                showConfirmButton: false,
                showCancelButton: false,
                timer: 2000
            });
            return;
        } else {
            const selectedOrderDetails = orderList.filter(order => selectedOrders.includes(order.paymentNo));
    
            // `selectedOrderDetails`에 올바른 데이터가 있는지 확인하기 위해 콘솔에 출력합니다.
            console.log('selectedOrderDetails : ', selectedOrderDetails);
    
            let deliveryProcessing = [];
            for (let order of selectedOrderDetails) {          
                if(collectionDeliveryInfo[order.paymentNo]?.collectionDeliveryCompany === '' || collectionDeliveryInfo[order.paymentNo]?.collectionDeliveryNumber === '' ) {
                    alert('수거 배송정보가 비어있습니다.');
                    return;
                }
                const info = collectionDeliveryInfo[order.paymentNo];
                console.log(`Payment No: ${order.paymentNo}, Delivery Company: ${info?.collectionDeliveryCompany}, Waybill Number: ${info?.collectionDeliveryNumber}`);
    
                deliveryProcessing.push({
                    paymentNo: order.paymentNo,
                    collectionDeliveryCompany: info?.collectionDeliveryCompany,
                    collectionDeliveryNumber: info?.collectionDeliveryNumber,
                    userId: order.userId
                });
            };
    
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html: `
                    <div>
                        <div class='${admin.admin_seller_add_title}'><span>교환수거완료 상태로 변경하시겠습니까?</span></div>
                        <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                          <button class='mx-1' id='seller_add_cancel'>아니오</button>
                          <button class='mx-1' id='seller_add_success'>예</button>
                        </div>
                    </div>
                `,
                showConfirmButton: false,
                showCancelButton: false,
            });
    
            document.addEventListener('click', sellerAddSuccess);
            document.addEventListener('click', sellerAddCancel);
    
            async function sellerAddSuccess(e) {
                if (e.target && e.target.id === 'seller_add_success') {
                    try {
                        const response = await adminApi.exchangeCollectComp(selectedOrderDetails);
                        const data = response.data;
    
                        console.log('API Response : ', data);
    
                        if (data === 'SUCCESS') {
                            MySwal.fire({
                                customClass: {
                                    popup: `${admin.admin_seller_add_popup}`
                                },
                                html: `
                                <div>
                                    <div class='${admin.admin_seller_add_title}'><span>교환 수거완료로 되돌리기가 완료 되었습니다.</span></div>
                                    <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                                    </div>
                                </div>
                                `,
                                showConfirmButton: false,
                                showCancelButton: false,
                            });
                            document.addEventListener('click', sellerAddConfirm);
    
                            function sellerAddConfirm(e) {
                                if (e.target && e.target.id === 'seller_add_confirm') {
                                    if(roles.isAdmin) {
                                        adimnOrderSelect();
                                    } else {
                                        orderSelect();
                                    }
                                    MySwal.close();
                                    document.removeEventListener('click', sellerAddConfirm);
                                    document.removeEventListener('click', sellerAddSuccess);
                                    document.removeEventListener('click', sellerAddCancel);
                                }
                            }
                        } else {
                            MySwal.fire({
                                customClass: {
                                    popup: `${admin.admin_seller_add_popup}`
                                },
                                html: `
                                <div>
                                    <div class='${admin.admin_seller_add_title}'><span>교환수거완료 상태 변경 처리에 실패했습니다.</span></div>
                                    <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                                    </div>
                                </div>
                                `,
                                showConfirmButton: false,
                                showCancelButton: false,
                            });
                            document.addEventListener('click', sellerAddConfirm);
    
                            function sellerAddConfirm(e) {
                                if (e.target && e.target.id === 'seller_add_confirm') {
                                    if(roles.isAdmin) {
                                        adimnOrderSelect();
                                    } else {
                                        orderSelect();
                                    }
                                    MySwal.close();
                                    document.removeEventListener('click', sellerAddConfirm);
                                    document.removeEventListener('click', sellerAddSuccess);
                                    document.removeEventListener('click', sellerAddCancel);
                                }
                            }
                        }
                    } catch (error) {
                        console.error('error : ', error);
                    }
                }
            }
    
            function sellerAddCancel(e) {
                if (e.target && e.target.id === 'seller_add_cancel') {
                    MySwal.close();
                    document.removeEventListener('click', sellerAddSuccess);
                    document.removeEventListener('click', sellerAddCancel);
                }
            }
        }
    }
    

    const handleExchangeRedelivery = () => {
        if (selectedOrders.length === 0) {
            alert('선택된 교환 주문이 없습니다.');
            return;
        }
    
        const hasInvalidState = selectedOrders.some(paymentNo => {
            const order = orderList.find(order => order.paymentNo === paymentNo);
            console.log('order : ', order);
    
            return ['재배송중', '교환완료'].includes(order.paymentState);
        });
    
        if (hasInvalidState) {
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html: `<div><div class='${admin.admin_seller_add_title}'><span>재배송 상태로 되돌릴 수 없습니다.</span></div></div>`,
                html: `<div><div class='${admin.admin_seller_sub_title}'><span>교환요청, 승인, 수거중, 수거완료, 수거보류 상태인 아이템만 교환요청 상태 변경이 가능합니다.</span></div></div>`,
                showConfirmButton: false,
                showCancelButton: false,
                timer: 2000
            });
            return;
        } else {
            const selectedOrderDetails = orderList.filter(order => selectedOrders.includes(order.paymentNo));
    
            // `selectedOrderDetails`에 올바른 데이터가 있는지 확인하기 위해 콘솔에 출력합니다.
            console.log('selectedOrderDetails : ', selectedOrderDetails);
    
            let deliveryProcessing = [];
            for (let order of selectedOrderDetails) {          
                if(deliveryInfo[order.paymentNo]?.redeliveryCompany === '' || deliveryInfo[order.paymentNo]?.redeliveryNumber === '' ) {
                    alert('재배송정보가 비어있습니다.');
                    return;
                }
                const info = deliveryInfo[order.paymentNo];
                console.log(`Payment No: ${order.paymentNo}, Delivery Company: ${info?.redeliveryCompany}, Waybill Number: ${info?.redeliveryNumber}`);
    
                deliveryProcessing.push({
                    paymentNo: order.paymentNo,
                    redeliveryCompany: info?.redeliveryCompany,
                    redeliveryNumber: info?.redeliveryNumber,
                    userId: order.userId
                });
            };
    
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html: `
                    <div>
                        <div class='${admin.admin_seller_add_title}'><span>재배송중 상태로 변경하시겠습니까?</span></div>
                        <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                          <button class='mx-1' id='seller_add_cancel'>아니오</button>
                          <button class='mx-1' id='seller_add_success'>예</button>
                        </div>
                    </div>
                `,
                showConfirmButton: false,
                showCancelButton: false,
            });
    
            document.addEventListener('click', sellerAddSuccess);
            document.addEventListener('click', sellerAddCancel);
    
            async function sellerAddSuccess(e) {
                if (e.target && e.target.id === 'seller_add_success') {
                    try {
                        const response = await adminApi.exchangeRedelivery(deliveryProcessing);
                        const data = response.data;
    
                        console.log('API Response : ', data);
    
                        if (data === 'SUCCESS') {
                            MySwal.fire({
                                customClass: {
                                    popup: `${admin.admin_seller_add_popup}`
                                },
                                html: `
                                <div>
                                    <div class='${admin.admin_seller_add_title}'><span>재배송중으로 되돌리기가 완료 되었습니다.</span></div>
                                    <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                                    </div>
                                </div>
                                `,
                                showConfirmButton: false,
                                showCancelButton: false,
                            });
                            document.addEventListener('click', sellerAddConfirm);
    
                            function sellerAddConfirm(e) {
                                if (e.target && e.target.id === 'seller_add_confirm') {
                                    if(roles.isAdmin) {
                                        adimnOrderSelect();
                                    } else {
                                        orderSelect();
                                    }
                                    MySwal.close();
                                    document.removeEventListener('click', sellerAddConfirm);
                                    document.removeEventListener('click', sellerAddSuccess);
                                    document.removeEventListener('click', sellerAddCancel);
                                }
                            }
                        } else {
                            MySwal.fire({
                                customClass: {
                                    popup: `${admin.admin_seller_add_popup}`
                                },
                                html: `
                                <div>
                                    <div class='${admin.admin_seller_add_title}'><span>재배송중 상태 변경 처리에 실패했습니다.</span></div>
                                    <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                        <button class='mx-1' id='seller_add_confirm'>확인</button>
                                    </div>
                                </div>
                                `,
                                showConfirmButton: false,
                                showCancelButton: false,
                            });
                            document.addEventListener('click', sellerAddConfirm);
    
                            function sellerAddConfirm(e) {
                                if (e.target && e.target.id === 'seller_add_confirm') {
                                    if(roles.isAdmin) {
                                        adimnOrderSelect();
                                    } else {
                                        orderSelect();
                                    }
                                    MySwal.close();
                                    document.removeEventListener('click', sellerAddConfirm);
                                    document.removeEventListener('click', sellerAddSuccess);
                                    document.removeEventListener('click', sellerAddCancel);
                                }
                            }
                        }
                    } catch (error) {
                        console.error('error : ', error);
                    }
                }
            }
    
            function sellerAddCancel(e) {
                if (e.target && e.target.id === 'seller_add_cancel') {
                    MySwal.close();
                    document.removeEventListener('click', sellerAddSuccess);
                    document.removeEventListener('click', sellerAddCancel);
                }
            }
        }
    }
    

    const onExchangeRefuseText = (e, item) => {
        const updatedRefuseList = refuseList.map(refuseItem => {
            if (refuseItem.paymentNumber === item.paymentNumber) {
                return { ...refuseItem, exchangeRefuseText: e.target.value };
            }
            return refuseItem;
        });
        setRefuseList(updatedRefuseList);
    }
    
    const onExchangePendingText = (e, item) => {
        const updatedRefuseList = refuseList.map(refuseItem => {
            if (refuseItem.paymentNumber === item.paymentNumber) {
                return { ...refuseItem, exchangePendingText: e.target.value };
            }
            return refuseItem;
        });
        setRefuseList(updatedRefuseList);
    }
    
    const onExchangeRefuseBtn = () => {
        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html: `
                <div>
                    <div class='${admin.admin_seller_add_title}'><span>교환거절 처리 하시겠습니까?</span></div>
                    <div class='${admin.admin_seller_add_sub_title}'><span>교환거절 시 교환요청 상태로 되돌아갑니다.</span></div>
                    <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                      <button class='mx-1' id='seller_add_cancel'>아니오</button>
                      <button class='mx-1' id='seller_add_success'>예</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });
    
        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);
    
        async function sellerAddSuccess(e) {
            if (e.target && e.target.id === 'seller_add_success') {
                try {
                    const response = await adminApi.exchangeRefuseUpdate(refuseList);
                    const data = response.data;
            
                    console.log('refuseData : ', data);
    
                    if (data === 'SUCCESS') {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                                <div class='${admin.admin_seller_add_title}'><span>교환 거절이 완료 되었습니다.</span></div>
                                <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                    <button class='mx-1' id='seller_add_confirm'>확인</button>
                                </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);
    
                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                if(roles.isAdmin) {
                                    adimnOrderSelect();
                                } else {
                                    orderSelect();
                                }
                                setShowExchangeRefuse(false);
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    } else {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                                <div class='${admin.admin_seller_add_title}'><span>교환거절 처리에 실패했습니다.</span></div>
                                <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                    <button class='mx-1' id='seller_add_confirm'>확인</button>
                                </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);
    
                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                if(roles.isAdmin) {
                                    adimnOrderSelect();
                                } else {
                                    orderSelect();
                                }
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    }
                } catch (error) {
                    console.error('error : ', error);
                }
            }
        }
    
        function sellerAddCancel(e) {
            if (e.target && e.target.id === 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
            }
        }
    }
    
    const onExchangePendingBtn = () => {
        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html: `
                <div>
                    <div class='${admin.admin_seller_add_title}'><span>교환보류 처리 하시겠습니까?</span></div>
                    <div class='${admin.admin_seller_add_sub_title}'><span>교환보류 시 고객에게 보류 상태 메세지가 전송됩니다.</span></div>
                    <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                      <button class='mx-1' id='seller_add_cancel'>아니오</button>
                      <button class='mx-1' id='seller_add_success'>예</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });
    
        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);
    
        async function sellerAddSuccess(e) {
            if (e.target && e.target.id === 'seller_add_success') {
                try {
                    const response = await adminApi.exchangePendingUpdate(refuseList);
                    const data = response.data;
            
                    console.log('refuseData : ', data);
    
                    if (data === 'SUCCESS') {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                                <div class='${admin.admin_seller_add_title}'><span>교환 보류가 완료 되었습니다.</span></div>
                                <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                    <button class='mx-1' id='seller_add_confirm'>확인</button>
                                </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);
    
                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                if(roles.isAdmin) {
                                    adimnOrderSelect();
                                } else {
                                    orderSelect();
                                }
                                setShowExchangePending(false);
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    } else {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                                <div class='${admin.admin_seller_add_title}'><span>교환보류 처리에 실패했습니다.</span></div>
                                <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                    <button class='mx-1' id='seller_add_confirm'>확인</button>
                                </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);
    
                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                if(roles.isAdmin) {
                                    adimnOrderSelect();
                                } else {
                                    orderSelect();
                                }
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    }
                } catch (error) {
                    console.error('error : ', error);
                }
            }
        }
    
        function sellerAddCancel(e) {
            if (e.target && e.target.id === 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
            }
        }
    }

    const handleExchangeMobileBtn = () => {
        setShowExchangeMobile(!showExchangeMobile);
    }
    const handleExchangeMobileOtherBtn = () => {
        setShowExchangeOtherMobile(!showExchangeOtherMobile);
    }
    

    return (
        <div className={`${admin.admin_shop_return_box} px-3 py-3`}>
            <div className={`${admin.admin_shop_cancel_header}`}><span>교환</span></div>
            <div className={`${admin.admin_shop_return_tap}`}>
                <Swiper
                    slidesPerView={'auto'}
                    spaceBetween={20}
                    freeMode={true}
                    className={`${admin.admin_shop_product_tap_swiper} py-2`}
                >
                    <SwiperSlide>
                        <div className={`${activeButton === 'newChange' ? admin.admin_shop_cancel_tap_active : ''}`}>
                            <button onClick={() => setActiveButton('newChange')}>신규교환 ({orderList.filter(order => ["신규교환", "교환요청", "교환승인", "교환수거중", "교환수거완료"].includes(order.paymentState)).length})</button>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className={`${activeButton === 'requestChange' ? admin.admin_shop_cancel_tap_active : ''}`}>
                            <button onClick={() => setActiveButton('requestChange')}>교환요청 ({orderList.filter(order => order.paymentState === '교환요청').length})</button>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className={`${activeButton === 'exchangeApprove' ? admin.admin_shop_cancel_tap_active : ''}`}>
                            <button onClick={() => setActiveButton('exchangeApprove')}>교환승인 ({orderList.filter(order => order.paymentState === '교환승인').length})</button>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className={`${activeButton === 'exchangePending' ? admin.admin_shop_cancel_tap_active : ''}`}>
                            <button onClick={() => setActiveButton('exchangePending')}>교환보류 ({orderList.filter(order => order.paymentState === '교환보류').length})</button>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className={`${activeButton === 'collecting' ? admin.admin_shop_cancel_tap_active : ''}`}>
                            <button onClick={() => setActiveButton('collecting')}>교환수거중 ({orderList.filter(order => order.paymentState === '교환수거중').length})</button>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className={`${activeButton === 'completeCollecting' ? admin.admin_shop_cancel_tap_active : ''}`}>
                            <button onClick={() => setActiveButton('completeCollecting')}>교환수거완료 ({orderList.filter(order => order.paymentState === '교환수거완료').length})</button>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className={`${activeButton === 'progressRedelivery' ? admin.admin_shop_cancel_tap_active : ''}`}>
                            <button onClick={() => setActiveButton('progressRedelivery')}>재배송중 ({orderList.filter(order => order.paymentState === '재배송중').length})</button>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className={`${activeButton === 'completeChage' ? admin.admin_shop_cancel_tap_active : ''}`}>
                            <button onClick={() => setActiveButton('completeChage')}>교환완료 ({orderList.filter(order => order.paymentState === '교환완료').length})</button>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
            <div className={`${admin.admin_shop_exchange_progress_btn}`}>
                <div><button onClick={handleCollection}>수거지시</button></div>
                <div className='ps-1'><button onClick={handleCollectionComplete}>교환수거 완료</button></div>
                <div className='ps-1'><button onClick={hadleExchangReDelivery}>교환 재발송</button></div>
                <div className='ps-1'><button onClick={handleExchangeApproval}>교환승인</button></div>
                <div className='ps-1'><button onClick={handleExchangeRefuse}>교환 거부</button></div>
                <div className='ps-1'><button onClick={handleExchangeHold}>교환 보류</button></div>
                <div className='ps-1'><button onClick={handleExchangeHoldUnlock}>교환 보류 해제</button></div>
                <div className='ps-1 position-relative'>
                    <div><button className='d-flex align-items-center' onClick={() => setChangeProgress(!changeProgress)}>기타 교환처리<span class={`material-symbols-outlined ${admin.admin_gray_font_2}`}>arrow_drop_down</span></button></div>
                    <CSSTransition in={changeProgress} timeout={300} classNames="review" unmountOnExit>
                        <div className={`${admin.admin_shop_cancel_progress_other_box}`}>
                            <div><button onClick={handleExchangeRequest}>교환요청으로 되돌리기</button></div>
                            <div><button onClick={handleExchangeCollecting}>수거중으로 되돌리기</button></div>
                            <div><button onClick={handleExchangeCollectComp}>수거완료로 되돌리기</button></div>
                            <div><button onClick={handleExchangeRedelivery}>재배송중으로 되돌리기</button></div>
                            <div><button onClick={handleExchangeSuccess}>교환완료 처리</button></div>
                        </div>
                    </CSSTransition>
                </div>
            </div>
            <div className={`${admin.admin_shop_exchange_progress_mobile_btn}`}>
                <div><button onClick={handleExchangeMobileBtn}>상태처리</button></div>
                {
                    showExchangeMobile &&
                    <div className={`${admin.admin_shop_exchange_progress_mobile_btn_content}`}>
                        <div><button onClick={handleCollection}>수거지시</button></div>
                        <div><button onClick={handleCollectionComplete}>교환수거 완료</button></div>
                        <div><button onClick={hadleExchangReDelivery}>교환 재발송</button></div>
                        <div><button onClick={handleExchangeApproval}>교환승인</button></div>
                        <div><button onClick={handleExchangeRefuse}>교환 거부</button></div>
                        <div><button onClick={handleExchangeHold}>교환 보류</button></div>
                        <div><button onClick={handleExchangeHoldUnlock}>교환 보류 해제</button></div>
                        <div>
                            <div><button onClick={handleExchangeMobileOtherBtn}>기타 교환처리<span class={`material-symbols-outlined ${admin.admin_gray_font_2}`}>arrow_drop_down</span></button></div>
                            {
                                showExchangeOtherMobile &&
                                <div>
                                    <div><button onClick={handleExchangeRequest}>교환요청으로 되돌리기</button></div>
                                    <div><button onClick={handleExchangeCollecting}>수거중으로 되돌리기</button></div>
                                    <div><button onClick={handleExchangeCollectComp}>수거완료로 되돌리기</button></div>
                                    <div><button onClick={handleExchangeRedelivery}>재배송중으로 되돌리기</button></div>
                                    <div><button onClick={handleExchangeSuccess}>교환완료 처리</button></div>
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
            <div className={`${admin.admin_shop_change_content_box}`}>
                <div>
                    <div className={`${admin.admin_shop_change_content_header}`}>
                        <div><input type="checkbox" checked={allSelected} onChange={handleSelectAll} /></div>
                        <div><span>주문번호/시각</span></div>
                        <div><span>주문상품</span></div>
                        <div><span>상품금액</span></div>
                        <div><span>수량</span></div>
                        <div><span>주문상태</span></div>
                        <div><span>수거/재배송</span></div>
                        <div><span>교환 사유</span></div>
                        <div><span>기타비용</span></div>
                        <div><span>수거지/재배송 정보</span></div>
                        <div><span>교환 배송비</span></div>
                        <div><span>결제금액</span></div>
                    </div>
                    {filteredOrders.map((order) => (
                        <div className={`${admin.admin_shop_change_content_content}`} key={order.paymentNumber}>
                            {/* 체크박스 */}
                            <div><input type="checkbox" checked={selectedOrders.includes(order.paymentNo)} onChange={() => handleSelectOrder(order.paymentNo)} /></div>

                            {/* 주문번호 / 시각 */}
                            <div className={`${admin.admin_shop_cancel_order_number}`}>
                                <div><span>{order.paymentNumber}</span></div>
                                <div><span>{new Date(order.paymentRegDate).toLocaleString()}</span></div>
                                <div className='py-3'><button><span className={`material-symbols-outlined ${admin.admin_gray_font_2}`}>smartphone</span>{order.deliveryUser}</button></div>
                                <div><button>주문서 출력</button></div>
                            </div>

                            {/* 주문상품  */}
                            <div className={`${admin.admin_shop_exchage_order_product_info_box}`}>
                                <div>
                                    <div><input type="checkbox" name="" id="" /></div>
                                    <div className='ps-1'><img src={order.paymentImg} alt="주문이미지" /></div>
                                    <div className='ps-1'>
                                        <div><span>{order.paymentProductNumber}</span></div>
                                        <div><span>{order.paymentProductName}</span></div>
                                        <div>
                                            {
                                                order.options.map((option, index) => (
                                                    <div key={index}><span>{option.selectionOptionName} :</span><span> {option.selectionOptionValue}</span></div>
                                                ))
                                            }
                                            
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div><span>교환요청옵션</span></div>
                                    <div>
                                        <div><span>{order.exchangeOptionName}</span></div>
                                        <div><span>{order.exchangeOptionValue}</span></div>
                                    </div>
                                </div>
                            </div>

                            {/* 상품금액 */}
                            <div className='text-center'><span>{calculateProductPrice(order).toLocaleString()}원</span></div>

                            {/* 수량 */}
                            <div className='text-center'>
                                {
                                    order && order.options.length !== 0 ?
                                    <span>{order.options.reduce((total, option) => total + option.quantity, 0)}</span>
                                    :
                                    <span>{order.totalQuantity}</span>
                                }
                            </div>

                            {/* 주문상태 */}
                            <div>
                                <div><span>{order.paymentState}</span></div>
                                <div className='py-2'>
                                    <div><span>교환요청일</span></div>
                                    <div><span>{new Date(order.exchangeRequestDate).toLocaleString()}</span></div>
                                </div>
                                {order.returnCollectComplateDate && (
                                    <div className='py-2'>
                                        <div><span>교환수거완료일</span></div>
                                        <div><span>{new Date(order.returnCollectComplateDate).toLocaleString()}</span></div>
                                    </div>
                                )}
                                {order.redeliveryDate && (
                                    <div className='py-2'>
                                        <div><span>재발송 완료일</span></div>
                                        <div><span>{new Date(order.redeliveryDate).toLocaleString()}</span></div>
                                    </div>
                                )}
                            </div>

                            {/* 수거/재배송 */}
                            <div>
                                <div>
                                    <div><span>수거방법</span></div>
                                    <div><span>{order.exchangeCollectMethod}</span></div>
                                </div>
                                <div>
                                    <div className='pt-3'>
                                        <div><span>원배송정보</span></div>
                                        <div>
                                            <div><span>{order.deliveryType}</span></div>
                                            <div><span>{order.deliveryCompany}</span></div>
                                            <div><span>{order.waybillNumber}</span></div>
                                        </div>
                                    </div>
                                    <div><button>조회</button></div>
                                </div>
                                <CSSTransition in={expandedOrder === order.paymentNo} timeout={300} classNames="review" unmountOnExit>
                                    <div className={`${admin.admin_shop_change_more_box}`}>
                                        {
                                            order.exchangeCollectMethod === '직접배송' ?
                                            <div>
                                                <div className='pt-3'>
                                                    <div><span>고객배송정보</span></div>
                                                    <div>
                                                        <div><span>{order.deliveryType}</span></div>
                                                        <div><span>{order.exchangeDeliveryCompany}</span></div>
                                                        <div><span>{order.exchangeDeliveryNumber}</span></div>
                                                    </div>
                                                </div>
                                                <div><button>조회</button></div>
                                            </div>
                                            :
                                            <div>
                                                <div className={`pt-3 ${admin.admin_shop_exchange_redelivery_info}`}>
                                                    <div><span>수거배송정보</span></div>
                                                    <div>
                                                        <div>
                                                            <Select
                                                                className={`${admin.admin_order_delivery_select}`}
                                                                placeholder='택배사 선택'
                                                                onChange={(value) => handleDeliveryCompanyColletionChange(value, order.paymentNo)}
                                                                value={collectionDeliveryInfo[order.paymentNo]?.collectionDeliveryCompany || order.collectionDeliveryCompany || undefined}
                                                            >
                                                                {deliveryCompanies.map((company) => (
                                                                    <Option key={company.id} value={company.name}>
                                                                        {company.name}
                                                                    </Option>
                                                                ))}
                                                            </Select>
                                                        </div>
                                                        <div className='mt-1'>
                                                            <input
                                                                type="number"
                                                                placeholder='송장번호'
                                                                onChange={(e) => handleWaybillNumberColletionChange(e, order.paymentNo)}
                                                                value={collectionDeliveryInfo[order.paymentNo]?.collectionDeliveryNumber || order.collectionDeliveryNumber || ''}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='py-1'><button>조회</button></div>
                                            </div>
                                        }
                                        <div>
                                            <div className={`pt-3 ${admin.admin_shop_exchange_redelivery_info}`}>
                                                <div><span>재배송정보</span></div>
                                                <div>
                                                    <div>
                                                        <Select
                                                            className={`${admin.admin_order_delivery_select}`}
                                                            placeholder='택배사 선택'
                                                            onChange={(value) => handleDeliveryCompanyChange(value, order.paymentNo)}
                                                            value={deliveryInfo[order.paymentNo]?.redeliveryCompany || order.redeliveryCompany || undefined}
                                                        >
                                                            {deliveryCompanies.map((company) => (
                                                                <Option key={company.id} value={company.name}>
                                                                    {company.name}
                                                                </Option>
                                                            ))}
                                                        </Select>
                                                    </div>
                                                    <div className='mt-1'>
                                                        <input
                                                            type="number"
                                                            placeholder='송장번호'
                                                            onChange={(e) => handleWaybillNumberChange(e, order.paymentNo)}
                                                            value={deliveryInfo[order.paymentNo]?.redeliveryNumber || order.redeliveryNumber || ''}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='py-1'>
                                                {/* {
                                                    order.redeliveryCompany === null && order.redeliveryNumber === null ?
                                                    <button onClick={() => onReDeliveryAdd(order)}>등록</button>
                                                    : */}
                                                    <button>조회</button>

                                                {/* } */}
                                            </div>
                                        </div>
                                    </div>
                                </CSSTransition>
                                
                                <div className='pt-1'>
                                    <button className={`${admin.admin_shop_change_more_btn}`} onClick={() => toggleExpandOrder(order.paymentNo)}>
                                        <div><span>{expandedOrder === order.paymentNo ? '접기' : '더보기'}</span></div>
                                        <div><span className="material-symbols-outlined">{expandedOrder === order.paymentNo ? 'keyboard_arrow_up' :'keyboard_arrow_down'}</span></div>
                                    </button>
                                </div>
                            </div>

                            {/* 교환 사유 */}
                            <div className={`px-2 ${admin.admin_shop_return_reason_box}`}>
                                <div><span>교환사유</span></div>
                                <div>
                                    <div><span>{order.exchangeType}</span></div>
                                    <div><span>{order.exchangeContent}</span></div>
                                </div>
                                {
                                    order.exchangeRefuseText !== null &&
                                    <div className={`py-2 ${admin.admin_shop_return_refuse_box}`}>
                                        <div><span>교환거부사유</span></div>
                                        <div>
                                            <div><span>{order.exchangeRefuseText}</span></div>
                                        </div>
                                    </div>
                                }
                                {
                                    order.exchangePendingText !== null &&
                                    <div className={`py-2 ${admin.admin_shop_return_refuse_box}`}>
                                        <div><span>교환보류사유</span></div>
                                        <div>
                                            <div><span>{order.exchangePendingText}</span></div>
                                        </div>
                                    </div>
                                }

                            </div>

                            {/* 기타 비용 */}
                            <div className={`px-2`}>
                                <div>
                                    {
                                        order.productAddPrice > 0 ?
                                        <div>
                                            <div><span>옵션추가금</span></div>
                                            <div className={`${admin.admin_shop_exchange_other_costs}`}><span>{order.productAddPrice.toLocaleString()}원</span></div>
                                        </div>
                                        :
                                        <div><span>없음</span></div>
                                    }
                                </div>
                            </div>

                            {/* 수거지 정보 */}
                            <div className='px-2'>
                                <div className='border-bottom'>
                                    <div>
                                        <div><span>수거지 정보</span></div>
                                        <div className='d-flex'>
                                            <div><span>{order.deliveryUser}</span></div>
                                            <div><span>/</span></div>
                                            <div><span>{order.deliveryPhone}</span></div>
                                        </div>
                                    </div>
                                    <div className='py-2'>
                                        <div><span>{order.deliveryAddress}</span></div>
                                    </div>
                                </div>
                                <div className='py-2'>
                                    <div>
                                        <div><span>재배송지 정보</span></div>
                                        <div className='d-flex'>
                                            <div><span>{order.deliveryUser}</span></div>
                                            <div><span>/</span></div>
                                            <div><span>{order.deliveryPhone}</span></div>
                                        </div>
                                    </div>
                                    <div className='py-2'>
                                        <div><span>{order.deliveryAddress}</span></div>
                                        <div><span>우&#41;{order.zipcode}</span></div>
                                    </div>
                                </div>
                                <div className={`${admin.admin_shop_exchange_collection_point_btn}`}><button>관리자 메모</button></div>
                                <div></div>
                            </div>

                            {/* 교환 배송비 */}
                            <div>
                                <div><span>{order.exchangeDeliveryPrice ? `${order.exchangeDeliveryPrice.toLocaleString()}원` : '없음'}</span></div>
                            </div>

                            {/* 결제금액 */}
                            <div className='px-2'>
                                <div className='d-flex justify-content-between'>
                                    <div><span>총 결제금액</span></div>
                                    <div><span>{parseInt(order.paymentPrice).toLocaleString()}원</span></div>
                                </div>
                                <div>
                                    <div className='d-flex justify-content-between'>
                                        <div><span>소계</span></div>
                                        <div><span>{parseInt(order.principalAmount).toLocaleString()}원</span></div>
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                        <div><span>배송비</span></div>
                                        <div><span>{order.deliveryPrice.toLocaleString()}원</span></div>
                                    </div>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div><span>포인트 사용</span></div>
                                        <div><span>-{order.point.toLocaleString()}원</span></div>
                                    </div>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div><span>쿠폰 사용</span></div>
                                        <div><span>-{order.coupon.toLocaleString()}원</span></div>
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                        <div><span>결제방법</span></div>
                                        <div className='text-end'>
                                            <div><span>{order.paymentType}</span></div>
                                            <div><span>{order.paymentBankName ? `${order.paymentBankName}(${order.paymentAccountNumber})` : ''}</span></div>
                                        </div>
                                    </div>
                                    <div className={`${admin.admin_shop_exchange_exchange_payment_box}`}>
                                        <div><span>교환결제 완료금액</span></div>
                                        <div className='d-flex justify-content-between'>
                                            <div><span>교환금액</span></div>
                                            <div><span>{order.exchangeAddTotalPrice.toLocaleString()}원</span></div>
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            <div><span>결제방법</span></div>
                                            <div className='text-end'>
                                                <div><span>{order.paymentType}</span></div>
                                                <div><span>{order.paymentBankName ? `${order.paymentBankName}(${order.paymentAccountNumber})` : ''}</span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Pagination
                current={currentPage}
                pageSize={itemsPerPage}
                total={totalItems}
                onChange={paginate}
                style={{ marginTop: '20px', textAlign: 'center' }}
            />
            {
                showExchangeRefuse &&
                <div className={`${admin.admin_shop_cancel_refuse_modal_container}`}>
                    <div className={`${admin.admin_shop_cancel_refuse_modal_box}`}>
                        <div className={`${admin.admin_shop_cancel_refuse_modal_header}`}>
                            <div><span>교환거절사유</span></div>
                            <div><button onClick={() => setShowExchangeRefuse(false)}><span className="material-symbols-outlined">close</span></button></div>
                        </div>
                        <div className={`${admin.admin_shop_cancel_refuse_modal_txt}`}>
                            <div><span>*선택하신 {refuseList && refuseList.length}개의 교환 주문을 처리합니다.</span></div>
                        </div>
                        <div className={`${admin.admin_shop_cancel_refuse_modal_content}`}>
                            <div>
                                <div>
                            {
                                Array.isArray(refuseList) && refuseList.map((item, index) => (
                                    <div key={index} className={`${admin.admin_shop_cancel_refuse_modal_refuse_box}`}>
                                        <div className={`${admin.admin_shop_cancel_refuse_modal_refuse_info}`}>
                                            <div className={`${admin.admin_shop_cancel_refuse_modal_refuse_order}`}>
                                                <div><span>주문정보</span></div>
                                                <div>
                                                    <div><img src={item.paymentImg} alt="" /></div>
                                                    <div>
                                                        <div><span>{item.paymentProductNumber}</span></div>
                                                        <div><span>{item.paymentProductName}</span></div>
                                                        <div><span>{parseInt(item.paymentPrice).toLocaleString()}원</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`${admin.admin_shop_cancel_refuse_modal_refuse_cancel}`}>
                                                <div><span>교환정보</span></div>
                                                <div>
                                                    <div className={`${admin.admin_shop_cancel_refuse_modal_refuse_cancel_info}`}>
                                                        <div><span>주문번호</span></div>
                                                        <div><span>{item.paymentNumber}</span></div>
                                                    </div>
                                                    <div className={`${admin.admin_shop_cancel_refuse_modal_refuse_cancel_info}`}>
                                                        <div><span>교환타입</span></div>
                                                        <div><span>{item.exchangeType}</span></div>
                                                    </div>
                                                    <div className={`${admin.admin_shop_cancel_refuse_modal_refuse_cancel_info}`}>
                                                        <div><span>교환사유</span></div>
                                                        <div><span>{item.exchangeContent}</span></div>
                                                    </div>
                                                    <div className={`${admin.admin_shop_cancel_refuse_modal_refuse_cancel_info}`}>
                                                        <div><span>교환배송비</span></div>
                                                        <div><span>{parseInt(item.exchangeDeliveryPrice).toLocaleString()}원</span></div>
                                                    </div>
                                                    <div className={`${admin.admin_shop_cancel_refuse_modal_refuse_cancel_info}`}>
                                                        <div><span>교환요청일</span></div>
                                                        <div><span>{new Date(item.paymentUpdDate).toLocaleString()}</span></div>
                                                    </div>
                                                    <div className={`${admin.admin_shop_cancel_refuse_modal_refuse_cancel_info}`}>
                                                        <div><span>교환옵션</span></div>
                                                        <div>
                                                            <div><span>{item.exchangeOptionName} :</span><span> {item.exchangeOptionValue}</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`${admin.admin_shop_cancel_refuse_modal_refuse_textarea}`}>
                                            <div><span>교환 거절사유</span></div>
                                            <div><textarea name="" id="" onChange={(e) => onExchangeRefuseText(e, item)}></textarea></div>
                                        </div>
                                    </div>
                                ))
                            }
                                </div>
                            </div>
                        </div>
                        <div className={`${admin.admin_shop_cancel_refuse_modal_footer}`}>
                            <div><button onClick={() => setShowExchangeRefuse(false)}>취소</button></div>
                            <div><button onClick={() => onExchangeRefuseBtn()}>교환거절</button></div>
                        </div>
                    </div>
                </div>
            }
            {
                showExchangePending &&
                <div className={`${admin.admin_shop_cancel_refuse_modal_container}`}>
                    <div className={`${admin.admin_shop_cancel_refuse_modal_box}`}>
                        <div className={`${admin.admin_shop_cancel_refuse_modal_header}`}>
                            <div><span>교환보류사유</span></div>
                            <div><button onClick={() => setShowExchangePending(false)}><span className="material-symbols-outlined">close</span></button></div>
                        </div>
                        <div className={`${admin.admin_shop_cancel_refuse_modal_txt}`}>
                            <div><span>*선택하신 {refuseList && refuseList.length}개의 교환 주문을 처리합니다.</span></div>
                        </div>
                        <div className={`${admin.admin_shop_cancel_refuse_modal_content}`}>
                            <div>
                                <div>
                            {
                                Array.isArray(refuseList) && refuseList.map((item, index) => (
                                    <div key={index} className={`${admin.admin_shop_cancel_refuse_modal_refuse_box}`}>
                                        <div className={`${admin.admin_shop_cancel_refuse_modal_refuse_info}`}>
                                            <div className={`${admin.admin_shop_cancel_refuse_modal_refuse_order}`}>
                                                <div><span>주문정보</span></div>
                                                <div>
                                                    <div><img src={item.paymentImg} alt="" /></div>
                                                    <div>
                                                        <div><span>{item.paymentProductNumber}</span></div>
                                                        <div><span>{item.paymentProductName}</span></div>
                                                        <div><span>{parseInt(item.paymentPrice).toLocaleString()}원</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`${admin.admin_shop_cancel_refuse_modal_refuse_cancel}`}>
                                                <div><span>교환정보</span></div>
                                                <div>
                                                    <div className={`${admin.admin_shop_cancel_refuse_modal_refuse_cancel_info}`}>
                                                        <div><span>주문번호</span></div>
                                                        <div><span>{item.paymentNumber}</span></div>
                                                    </div>
                                                    <div className={`${admin.admin_shop_cancel_refuse_modal_refuse_cancel_info}`}>
                                                        <div><span>교환타입</span></div>
                                                        <div><span>{item.exchangeType}</span></div>
                                                    </div>
                                                    <div className={`${admin.admin_shop_cancel_refuse_modal_refuse_cancel_info}`}>
                                                        <div><span>교환사유</span></div>
                                                        <div><span>{item.exchangeContent}</span></div>
                                                    </div>
                                                    <div className={`${admin.admin_shop_cancel_refuse_modal_refuse_cancel_info}`}>
                                                        <div><span>교환배송비</span></div>
                                                        <div><span>{parseInt(item.exchangeDeliveryPrice).toLocaleString()}원</span></div>
                                                    </div>
                                                    <div className={`${admin.admin_shop_cancel_refuse_modal_refuse_cancel_info}`}>
                                                        <div><span>교환요청일</span></div>
                                                        <div><span>{new Date(item.paymentUpdDate).toLocaleString()}</span></div>
                                                    </div>
                                                    <div className={`${admin.admin_shop_cancel_refuse_modal_refuse_cancel_info}`}>
                                                        <div><span>교환옵션</span></div>
                                                        <div>
                                                            <div><span>{item.exchangeOptionName} :</span><span> {item.exchangeOptionValue}</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`${admin.admin_shop_cancel_refuse_modal_refuse_textarea}`}>
                                            <div><span>교환 보류사유</span></div>
                                            <div><textarea name="" id="" onChange={(e) => onExchangePendingText(e, item)}></textarea></div>
                                        </div>
                                    </div>
                                ))
                            }
                                </div>
                            </div>
                        </div>
                        <div className={`${admin.admin_shop_cancel_refuse_modal_footer}`}>
                            <div><button onClick={() => setShowExchangePending(false)}>취소</button></div>
                            <div><button onClick={() => onExchangePendingBtn()}>교환보류</button></div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default AdminChange;

