import React, { useEffect, useState } from 'react'
import { animated } from 'react-spring';
import { useSpring, useTransition } from '@react-spring/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import { FaStar, FaStarHalfAlt, FaRegStar } from 'react-icons/fa';
import Lightbox from 'react-image-lightbox-rotate-latest';
import 'react-image-lightbox-rotate-latest/style.css'; // 이 스타일은 lightbox CSS 입니다.
import dailyduck from '../../containers/my/MyContainner.module.css'

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import { useLocation, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

const ReviewWritten = () => {

    const location = useLocation();
    const navigate = useNavigate();

    const reservation = location.state?.reservation;
    const reservationReview = location.state?.reservationReview;
    const support = location.state?.support;

    const storeReview = location.state?.storeReview;
    const productDTO = location.state?.productDTO;
    const storePayment = location.state?.storePayment;
    const [rating, setRating] = useState(0); // 사용자가 선택한 별점을 저장할 상태
    const [hoverRating, setHoverRating] = useState(undefined); // 마우스 호버 중인 별점 상태
    const [images, setImages] = useState([]);

    useEffect(() => {
        // console.log('reservation : ', reservation);
        // console.log('reservationReview : ', reservationReview);
        // console.log('support : ', support);

        if(reservation !== undefined && reservationReview !== undefined && support !== undefined) {
            setRating(reservationReview.rating);
            setImages(reservationReview.fileList);
        }

    }, [reservation, reservationReview, support]);

    useEffect(() => {
        // console.log('storeReview : ', storeReview);
        // console.log('productDTO : ', productDTO);
        // console.log('storePayment : ', storePayment);

        if(storeReview !== undefined && productDTO !== undefined && storePayment !== undefined) {
            setRating(storeReview.rating);
            setImages(storeReview.fileList);
        }

    }, [storeReview, productDTO, storePayment]);

    const ARRAY = [...Array(5).keys()]; // 5개의 별을 위한 배열 생성


    useEffect(() => {
        // console.log(`호버 별점: ${hoverRating}점`); // 이 부분에서 서버로 별점을 전송하는 코드를 추가할 수 있습니다.
        // console.log(`별점 등록: ${rating}점`); // 이 부분에서 서버로 별점을 전송하는 코드를 추가할 수 있습니다.
    }, [hoverRating]);

    const handleReviewWrittenBack = () => {
        navigate(-1);
    }

    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);


    const [isImageLoaded, setImageLoaded] = useState(false);
    const [isImageClicked, setImageClicked] = useState(false);

    const handleImageLoad = () => {
      // console.log('Image loaded');
      setImageLoaded(true);
      if (isImageClicked) {
        // console.log('Opening Lightbox from handleImageLoad');
        setIsOpen(true);
      }
    };

    const handleImageClick = (i) => {
      // console.log('Image clicked');
      if (!isOpen) {
        setImageClicked(true);
        setPhotoIndex(i);
        if (isImageLoaded) {
          // console.log('Opening Lightbox from handleImageClick');
          setIsOpen(true);
        }
      }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return format(date, 'yyyy-MM-dd hh:mm:ss');
    }

    const handleStoreReviewProduct = () => {
        if(productDTO !== null && productDTO !== undefined ) {
            navigate(`/store/product/${productDTO.productNo}`);
        } else {
            alert('상품이 삭제됐거나 이동되었습니다.');
        }
    }
    const handleSupportReviewProduct = () => {
        if(support !== null && support !== undefined) {
            navigate(`/venue/${support.productNumber}`);
        } else {
            alert('상품이 삭제됐거나 이동되었습니다.');
        }
    }

    return (
        <>
        {
            reservation !== undefined && reservationReview !== undefined && support !== undefined &&
            <div className={`${dailyduck.my_review_container}`}>
                    <div className={dailyduck.mycouponporint_box}>
                        <div className={dailyduck.my_review_written_header}>
                            <div>
                                <button onClick={() => handleReviewWrittenBack()} className='reservation_pay_close'>
                                    <span className="material-symbols-outlined">arrow_back_ios</span>
                                </button>
                            </div>
                            <div>
                                <span>리뷰 상세</span>
                            </div>
                        </div>
                        <div className={`${dailyduck.my_support_review_content}`}>
                            <div className='py-3 px-3 bg-white'>
                                <div className={`${dailyduck.my_review_written_box}`} onClick={handleSupportReviewProduct}>
                                    <div className={`${dailyduck.my_review_written_img} px-2 py-2`}><img src={reservation.mainItemImg} alt="리뷰상품이미지" /></div>
                                    <div className={`${dailyduck.my_review_written_content} px-3`}>
                                        <div><span>{reservation.shopName}</span></div>
                                        <div><span>{support.productName}</span></div>
                                        <div><span>&#8361;{reservation.amount.toLocaleString()}</span></div>
                                    </div>
                                </div>
                            </div>
                            <div className='bg-white py-3 px-3'>
                                <div className='d-flex justify-content-between py-3'>
                                    <div><span>{reservationReview.userId}</span></div>
                                    <div><span>{formatDate(reservationReview.reviewRegDate)}</span></div>
                                </div>
                                <div>
                                <Swiper
                                    slidesPerView={1}
                                    spaceBetween={30}
                                    loop={true}
                                    pagination={{ clickable: true }}
                                    modules={[Pagination, Navigation]}
                                    className={`${dailyduck.mySwiper}`}
                                >
                                    {
                                        reservationReview.fileList != null ?
                                        reservationReview.fileList.map((image, index) => (
                                                <SwiperSlide key={index} onClick={() => handleImageClick(index)}>
                                                    <img src={image} alt="이미지" onLoad={handleImageLoad} />
                                                </SwiperSlide>
                                        ))
                                        :
                                            <div>
                                                <img src="../../img/my/review_no_img.png" alt="이미지" onLoad={handleImageLoad} />
                                            </div>
                                    }
                                </Swiper>
                                {isOpen && (
                                    <Lightbox
                                        mainSrc={images[photoIndex]}
                                        nextSrc={images[(photoIndex + 1) % images.length]}
                                        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                                        onCloseRequest={() => setIsOpen(false)}
                                        onMovePrevRequest={() =>
                                        setPhotoIndex((photoIndex + images.length - 1) % images.length)
                                        }
                                        onMoveNextRequest={() =>
                                        setPhotoIndex((photoIndex + 1) % images.length)
                                        }
                                    />
                                    )}
                                </div>
                                <div>
                                    <div className={`${dailyduck.my_review_written_wrap} py-2`}>
                                        <div className={`${dailyduck.my_review_written_stars}`}>
                                        {ARRAY.map((_, idx) => {
                                            const fullStar = idx < Math.floor(rating);
                                            const halfStar = idx === Math.floor(rating) && rating % 1 !== 0;
                                            return (
                                                <span key={idx}>
                                                    {fullStar && <FaStar size="20" className={dailyduck.yellowStar} />}
                                                    {halfStar && <FaStarHalfAlt size="20" className={dailyduck.yellowStar} />}
                                                    {!fullStar && !halfStar && <FaRegStar size="20" />}
                                                </span>
                                            );
                                        })}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className={`${dailyduck.my_review_review_text}`}>
                                        <span>{reservationReview.reviewText}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        }
        {
            storeReview !== undefined && productDTO !== undefined && storePayment !== undefined &&
            <div className={`${dailyduck.my_review_container}`}>
                    <div className={dailyduck.mycouponporint_box}>
                        <div className={dailyduck.my_review_written_header}>
                            <div>
                                <button onClick={() => handleReviewWrittenBack()} className='reservation_pay_close'>
                                    <span className="material-symbols-outlined">arrow_back_ios</span>
                                </button>
                            </div>
                            <div>
                                <span>리뷰 상세</span>
                            </div>
                        </div>
                        <div className={`${dailyduck.my_support_review_content}`}>
                            <div className='py-3 px-3 bg-white'>
                                <div className={`${dailyduck.my_review_written_box}`} onClick={() => handleStoreReviewProduct()}>
                                    <div className={`${dailyduck.my_review_written_img} px-2 py-2`}><img src={storePayment.paymentImg} alt="리뷰상품이미지" /></div>
                                    <div className={`${dailyduck.my_review_written_content} px-3`}>
                                        <div><span>{storePayment.shopName}</span></div>
                                        <div><span>{storePayment.paymentProductName}</span></div>
                                        <div><span>&#8361;{parseInt(storePayment.paymentPrice).toLocaleString()}</span></div>
                                    </div>
                                </div>
                            </div>
                            <div className='bg-white py-3 px-3'>
                                <div className='d-flex justify-content-between py-3'>
                                    <div><span>{storePayment.userId}</span></div>
                                    <div><span>{formatDate(storeReview.reviewRegDate)}</span></div>
                                </div>
                                <div>
                                <Swiper
                                    slidesPerView={1}
                                    spaceBetween={30}
                                    loop={true}
                                    pagination={{ clickable: true }}
                                    modules={[Pagination, Navigation]}
                                    className={`${dailyduck.mySwiper}`}
                                >
                                    {
                                        storeReview.fileList != null ?
                                        storeReview.fileList.map((image, index) => (
                                                <SwiperSlide key={index} onClick={() => handleImageClick(index)}>
                                                    <img src={image} alt="이미지" onLoad={handleImageLoad} />
                                                </SwiperSlide>
                                        ))
                                        :
                                            <div>
                                                <img src="../../img/my/review_no_img.png" alt="이미지" onLoad={handleImageLoad} />
                                            </div>
                                    }
                                </Swiper>
                                {isOpen && (
                                    <Lightbox
                                        mainSrc={images[photoIndex]}
                                        nextSrc={images[(photoIndex + 1) % images.length]}
                                        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                                        onCloseRequest={() => setIsOpen(false)}
                                        onMovePrevRequest={() =>
                                        setPhotoIndex((photoIndex + images.length - 1) % images.length)
                                        }
                                        onMoveNextRequest={() =>
                                        setPhotoIndex((photoIndex + 1) % images.length)
                                        }
                                    />
                                    )}
                                </div>
                                <div>
                                    <div className={`${dailyduck.my_review_written_wrap} py-2`}>
                                        <div className={`${dailyduck.my_review_written_stars}`}>
                                        {ARRAY.map((_, idx) => {
                                            const fullStar = idx < Math.floor(rating);
                                            const halfStar = idx === Math.floor(rating) && rating % 1 !== 0;
                                            return (
                                                <span key={idx}>
                                                    {fullStar && <FaStar size="20" className={dailyduck.yellowStar} />}
                                                    {halfStar && <FaStarHalfAlt size="20" className={dailyduck.yellowStar} />}
                                                    {!fullStar && !halfStar && <FaRegStar size="20" />}
                                                </span>
                                            );
                                        })}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className={`${dailyduck.my_review_review_text}`}>
                                        <span>{storeReview.reviewText}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        }
        </>
    )
}

export default ReviewWritten
