import React, { useState } from 'react'
import { Select } from 'antd';

const { Option } = Select;

const SellerStoreSettleinfo = ( {seller} ) => {
    // 주민등록번호 뒷자리 입력값 상태
    const [backNumber, setBackNumber] = useState('');

    // 입력값 변경 처리 함수
    const handleBackNumberChange = (e) => {
      const input = e.target.value;
      // 숫자만 입력받도록 필터링 및 첫 번째 숫자 이후 별표 처리
      const filteredInput = input.replace(/\D/g, '').replace(/(\d)(\d*)/, '$1' + '*'.repeat(Math.max(0, input.length - 1)));
      setBackNumber(filteredInput);
    };
  return (
          <div className={`px-3 ${seller.seller_right_container}`}>
            <div>
              <div className='pt-3 pb-2 border-bottom'><span>정산 정보</span></div>
              <div className={`pt-4 pb-1 ${seller.seller_border_bottom_color}`}><span>정산 계좌</span></div>
              <div>
                <div className='d-flex w-100 border-bottom align-items-center py-3'>
                  <div className={`${seller.seller_shop_left}`}><span>성명</span></div>
                  <div className={`${seller.seller_shop_middle} px-3`}><input type="text" placeholder='홍길동'  disabled={true} /></div>
                </div>
                <div className='d-flex w-100 border-bottom align-items-center py-3'>
                  <div className={`${seller.seller_shop_left}`}><span>주민등록번호</span></div>
                  <div className={`${seller.seller_shop_middle} px-3 d-flex align-items-center`}>
                    <div>
                      <input type="number" placeholder='123456'  disabled={true}/>
                    </div>
                    <div>
                      <span>-</span>
                    </div>
                    <div>
                    <input 
                      type="text" 
                      value={backNumber}
                      onChange={handleBackNumberChange} 
                      placeholder='1******'
                      disabled={true}
                    />
                    </div>
                  </div>
                </div>
                <div className='d-flex w-100 border-bottom align-items-center py-3'>
                  <div className={`${seller.seller_shop_left}`}><span>정산 계좌 은행</span></div>
                  <div className={`${seller.seller_shop_middle} px-3`}>
                    <Select className={`${seller.shop_bank_select}`} placeholder="국민은행" disabled={true}>
                        <Option value="option1">1</Option>
                        <Option value="option2">2</Option>
                        <Option value="option3">3</Option>
                        <Option value="option4">4</Option>
                    </Select>
                  </div>
                </div>
                <div className='d-flex w-100 border-bottom align-items-center py-3'>
                  <div className={`${seller.seller_shop_left}`}><span>정산 계좌 번호</span></div>
                  <div className={`${seller.seller_shop_middle} px-3`}>
                    <input type="number" placeholder='123456789912121' disabled={true}/>
                  </div>
                </div>
                <div className='d-flex w-100 border-bottom align-items-center py-3'>
                  <div className={`${seller.seller_shop_total}`}><span>*수정사항이 있는 경우 관리자에게 문의하여 요청해주세요.</span></div>
                </div>
              </div>
            </div>
          </div>
  )
}

export default SellerStoreSettleinfo