import React, { useContext, useEffect, useState } from 'react';
import admin from '../../../admin.module.css';
import * as adminApi from '../../../../apis/admin';
import { format } from 'date-fns';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination as SwiperPagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import TextEditor from '../shop/TextEditor';
import { LoginContext } from '../../../../contexts/LoginContextProvider';
import { Select } from 'antd';
import { Option } from 'antd/es/mentions';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const AdminWinner = () => {
    const {userInfo} = useContext(LoginContext);
    const MySwal = withReactContent(Swal);
    const [ showEventWinnerModal, setShowEventWinnerModal ] = useState(false);
    const [ eventList, setEventList ] = useState([]);
    const [ filteredEventList, setFilteredEventList ] = useState([]);
    const [ filterText, setFilterText ] = useState('');
    const [ selectedEvent, setSelectedEvent ] = useState(null);
    const [ selectEventNumber, setSelectEventNumber ] = useState('');
    const [ userList, setUserList ] = useState([]);
    const [ selectedUsers, setSelectedUsers ] = useState([]);
    const [ searchValue, setSearchValue ] = useState('');
    const [data, setData] = useState("");
    const [fileName, setFileName] = useState('');
    const [update, setUpdate] = useState(false);
    const [ announcementInfo, setAnnouncementInfo ] = useState({
        announcementTitle : '',
        announcementEventNumber : '',
        announcementEventType : '',
        announcementRegUser : userInfo && userInfo.userId,
        announcementContent : '',
        announcementState : '',
        announcementUser: [],
    });
    const [ announcementList, setAnnouncementList ] = useState([]);

    useEffect(() => {
        eventSelect();
        announcementSelect();
    }, []);

    useEffect(() => {
        if(selectEventNumber !== '') {
            const particeSelect = async () => {
                const response = await adminApi.particeSelect(selectEventNumber);
                const data = response.data;

                // console.log('data : ', data);
                setUserList(data);
            }

            particeSelect();
        }
    }, [selectEventNumber])

    const eventSelect = async () => {
        const response = await adminApi.eventSelect();
        const data = response.data;

        // console.log('eventData : ', data);
        setEventList(data);
        setFilteredEventList(data);
    };

    const announcementSelect = async () => {
        const response = await adminApi.announcementSelect();
        const data = response.data;

        // console.log('data : ', data);
        setAnnouncementList(data);
    }

    useEffect(() => {
        filterEvents();
    }, [filterText, eventList]);

    const filterEvents = () => {
        const filtered = eventList.filter(item =>
            item.eventTitle.includes(filterText) ||
            (item.eventSubTitle && item.eventSubTitle.includes(filterText)) ||
            item.eventContent.includes(filterText) ||
            eventTypeFIleter(item.eventType).includes(filterText)
        );
        setFilteredEventList(filtered);
    };

    const eventTypeFIleter = (eventType) => {
        if (eventType === 'lucked') {
            return '럭키드로우';
        } else if (eventType === 'plan') {
            return '기획전';
        }
        return '';
    };
    const formatDate = (dateString) => {
        return dateString.replace('T', ' ').substring(0, 19);
    };

    const KSTformatDate = (date) => {
        return format(new Date(date), 'yyyy-MM-dd HH:mm:ss');
    };

    const handleEventSelect = (event) => {
        const eventNumber = event.target.value;
        const selected = eventList.find(item => item.eventNumber === eventNumber);
        setSelectedEvent(selected);
        setSelectEventNumber(eventNumber);
        setAnnouncementInfo({
            ...announcementInfo, announcementEventNumber: eventNumber, announcementEventType: selected.eventType
        });
    };

    useEffect(() => {
        setAnnouncementInfo({
            ...announcementInfo, announcementContent : data, announcementUser : selectedUsers
        })
    }, [data, selectedUsers])

    const escapeRegExp = (string) => {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $&는 일치된 전체 문자열을 의미합니다.
    };
    
    const filteredUserList = userList.filter(user => {
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        return searchRegex.test(user.userName) || searchRegex.test(user.userNick) || searchRegex.test(user.userTel) || searchRegex.test(user.userId) || searchRegex.test(user.userEmail);
    });

    const handleUserCheckboxChange = (user) => {
        setSelectedUsers(prevSelectedUsers => {
            if (prevSelectedUsers.some(selectedUser => selectedUser.userNo === user.userNo)) {
                return prevSelectedUsers.filter(selectedUser => selectedUser.userNo !== user.userNo);
            } else {
                return [...prevSelectedUsers, user];
            }
        });
    };

    const onSearchValue = (e) => {
        setSearchValue(e.target.value);
    };

    const onDetailContent = (data) => {}

    useEffect(() => {
        // console.log('announcementInfo : ', announcementInfo);
    }, [announcementInfo])

    const onAnnouncementTitle = (e) => {
        setAnnouncementInfo({
            ...announcementInfo, announcementTitle : e.target.value
        })
    }

    const onExposure = (value) => {
        setAnnouncementInfo({
            ...announcementInfo, announcementState: value
        })
    }

    const handleEventWinnerAdd = () => {
        MySwal.fire({
            customClass: {
              popup: `${admin.admin_seller_add_popup}`
            },
            html: `
                  <div>
                      <div class='${admin.admin_seller_add_title}'><span>당첨자 발표를 등록하시겠습니까?</span></div>
                      <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                        <button class='mx-1' id='seller_add_cancel'>취소</button>
                        <button class='mx-1' id='seller_add_success'>등록</button>
                      </div>
                  </div>
              `,
            showConfirmButton: false,
            showCancelButton: false,
          });

          document.addEventListener('click', sellerAddSuccess);
          document.addEventListener('click', sellerAddCancel);

          async function sellerAddSuccess(e) {
            if (e.target && e.target.id === 'seller_add_success') {
              try {
                
                 const response = await adminApi.announcementCreate(announcementInfo);
                 const data = response.data;
                
      
                if (data === 'SUCCESS') {
                  MySwal.fire({
                    customClass: {
                      popup: `${admin.admin_seller_add_popup}`
                    },
                    html: `
                              <div>
                              <div class='${admin.admin_seller_add_title}'><span>당첨자 발표 등록이 완료되었습니다.</span></div>
                              <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                              <button class='mx-1' id='seller_add_confirm'>확인</button>
                              </div>
                              </div>
                              `,
                    showConfirmButton: false,
                    showCancelButton: false,
                  });
                  document.addEventListener('click', sellerAddConfirm);
      
                  function sellerAddConfirm(e) {
                    if (e.target && e.target.id === 'seller_add_confirm') {
                      eventSelect();
                      setShowEventWinnerModal(false);
                      MySwal.close();
                      document.removeEventListener('click', sellerAddConfirm);
                      document.removeEventListener('click', sellerAddSuccess);
                      document.removeEventListener('click', sellerAddCancel);
                    }
                  }
                } else {
                  MySwal.fire({
                    customClass: {
                      popup: `${admin.admin_seller_add_popup}`
                    },
                    html: `
                              <div>
                              <div class='${admin.admin_seller_add_title}'><span>당첨자 발표 등록에 실패하였습니다.</span></div>
                              <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                              <button class='mx-1' id='seller_add_confirm'>확인</button>
                              </div>
                              </div>
                              `,
                    showConfirmButton: false,
                    showCancelButton: false,
                  });
                  document.addEventListener('click', sellerAddConfirm);
      
                  function sellerAddConfirm(e) {
                    if (e.target && e.target.id === 'seller_add_confirm') {
                      MySwal.close();
                      document.removeEventListener('click', sellerAddConfirm);
                      document.removeEventListener('click', sellerAddSuccess);
                      document.removeEventListener('click', sellerAddCancel);
                    }
                  }
                }
              } catch (error) {
                console.error('error : ', error);
              }
            }
          }
      
          function sellerAddCancel(e) {
            if (e.target && e.target.id === 'seller_add_cancel') {
              MySwal.close();
              document.removeEventListener('click', sellerAddSuccess);
              document.removeEventListener('click', sellerAddCancel);
            }
          }
    }

    const onAnnouncementStateSelect = (e) => {
        // console.log("value : ", e.target.value);
    }

    return (
        <div className={`${admin.admin_event_winner_box} px-3 py-3`}>
            <div>
                <div className={`${admin.admin_event_winner_title}`}><span>당첨자 발표</span></div>
                <div className={`py-3 ${admin.admin_event_winner_add_btn}`}>
                    <div><button onClick={() => setShowEventWinnerModal(true)}>게시글 등록</button></div>
                </div>
                <div>
                    <div>
                        <div className={`${admin.admin_event_winner_content_header}`}>
                            <div><span>번호</span></div>
                            <div><span>제목</span></div>
                            <div><span>노출여부</span></div>
                            <div><span>작성일자</span></div>
                            <div><span>동작</span></div>
                        </div>
                        {
                            announcementList.map((item, index) => (
                                <div className={`${admin.admin_event_winner_content_content}`} key={index}>
                                    <div><span>{item.announcementNo}</span></div>
                                    <div><span>{item.announcementTitle}</span></div>
                                    <div>
                                        <select 
                                            className={admin.admin_event_winner_content_content_select}
                                            value={item.announcementState}
                                            onChange={(e) => onAnnouncementStateSelect(e)}
                                        >
                                            <option value='exposure'>노출</option>
                                            <option value='hide'>숨김</option>
                                        </select>
                                    </div>
                                    <div><span>{KSTformatDate(item.announcementRegDate)}</span></div>
                                    <div>
                                        <div className='py-1'><button>수정</button></div>
                                        <div className='py-1'><button>삭제</button></div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            {
                showEventWinnerModal &&
                <div className={admin.admin_event_winner_modal_container}>
                    <div className={admin.admin_event_winner_modal_box}>
                        <div className={admin.admin_event_winner_modal_header}>
                            <div><span>당첨자 발표 등록</span></div>
                            <div><button onClick={() => setShowEventWinnerModal(false)}><span className="material-symbols-outlined">close</span></button></div>
                        </div>
                        <div className={admin.admin_event_winner_modal_content}>
                            <div className={admin.admin_event_winner_modal_content_title}>
                                <div><span>당첨자 발표 제목</span></div>
                                <div><input type="text" onChange={(e) => onAnnouncementTitle(e)}/></div>
                            </div>
                            <div className={admin.admin_event_winner_modal_content_state}>
                                <div><span>노출여부</span></div>
                                <div>
                                <Select
                                    className={`${admin.admin_event_setting_plan_select}`}
                                    onChange={(value) => onExposure(value)}
                                >
                                    <Option value='exposure'>노출</Option>
                                    <Option value='hide'>숨김</Option>
                                </Select>
                                </div>
                            </div>
                            <div className={admin.admin_event_winner_modal_content_event_select}>
                                <div>
                                    <div><span>이벤트 선택</span></div>
                                    <div><input type="text" value={filterText} onChange={(e) => setFilterText(e.target.value)} /></div>
                                </div>
                                <div>
                                    <div className='row'>
                                        {
                                            filteredEventList.map((item, index) => (
                                                <div key={index} className={`col-4 ${admin.admin_event_winner_modal_content_item}`}>
                                                    <div>
                                                        <div><input type="radio" name="eventList" value={item.eventNumber} onChange={handleEventSelect} id={item.eventNumber} /></div>
                                                        <label htmlFor={item.eventNumber} className={`${admin.admin_event_winner_modal_content_item_info}`}>
                                                            <div>
                                                                <img src={item.eventImage1} alt="" />
                                                            </div>
                                                            <div>
                                                                <div><span>{eventTypeFIleter(item.eventType)}</span></div>
                                                                <div><span>{item.eventTitle}</span></div>
                                                                <div><span>{item.eventPrice.toLocaleString()}원</span></div>
                                                                <div><span>{KSTformatDate(item.eventRegDate)}</span></div>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                {selectedEvent &&
                                    <div className={`${admin.admin_event_winner_modal_content_select_item}`}>
                                        <div>
                                            <div>
                                                <Swiper
                                                    slidesPerView={1}
                                                    spaceBetween={30}
                                                    loop={true} // 루프 활성화
                                                    autoplay={{
                                                        delay: 2000, // 2초마다 자동 재생
                                                        disableOnInteraction: false, // 사용자의 스와이프 후에도 자동 재생을 계속 작동하도록 설정
                                                    }}
                                                    modules={[SwiperPagination, Navigation, Autoplay]} // Autoplay 모듈 추가
                                                    className={`${admin.admin_participants_swiper}`}
                                                >
                                                    <SwiperSlide><img src={selectedEvent.eventImage1} alt="이미지" /></SwiperSlide>
                                                    <SwiperSlide><img src={selectedEvent.eventImage2} alt="이미지" /></SwiperSlide>
                                                    <SwiperSlide><img src={selectedEvent.eventImage3} alt="이미지" /></SwiperSlide>
                                                    <SwiperSlide><img src={selectedEvent.eventImage4} alt="이미지" /></SwiperSlide>
                                                    <SwiperSlide><img src={selectedEvent.eventImage5} alt="이미지" /></SwiperSlide>
                                                </Swiper>
                                            </div>
                                            <div className={`${admin.admin_event_winner_modal_content_select_item_detail}`}>
                                                <div>
                                                    <div><span>이벤트제목</span></div>
                                                    <div><span>{selectedEvent.eventTitle}</span></div>
                                                </div>
                                                <div>
                                                    <div><span>이벤트요약</span></div>
                                                    <div><span>{selectedEvent.eventSubTitle}</span></div>
                                                </div>
                                                <div>
                                                    <div><span>이벤트금액</span></div>
                                                    <div><span>{selectedEvent.eventPrice.toLocaleString()}원</span></div>
                                                </div>
                                                <div>
                                                    <div><span>이벤트종류</span></div>
                                                    <div><span>{eventTypeFIleter(selectedEvent.eventType)}</span></div>
                                                </div>
                                                <div>
                                                    <div><span>등록일자</span></div>
                                                    <div><span>{KSTformatDate(selectedEvent.eventRegDate)}</span></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`${admin.admin_event_winner_modal_content_select_item_detail2}`}>
                                            <div>
                                                <div><span>응모기간</span></div>
                                                <div><span>{formatDate(selectedEvent.eventStartDate)} </span><span>~</span><span> {formatDate(selectedEvent.eventEndDate)}</span></div>
                                            </div>
                                            <div>
                                                <div><span>발표일</span></div>
                                                <div><span>{selectedEvent.eventWinnerDate !== null ? KSTformatDate(selectedEvent.eventWinnerDate) : '없음'}</span></div>
                                            </div>
                                            <div>
                                                <div><span>상세내용</span></div>
                                                <div>
                                                    <div dangerouslySetInnerHTML={{ __html: selectedEvent.eventContent }}></div>
                                                    <div><button>상세보기</button></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className={`${admin.admin_event_setting_add_modal_lucked_content} px-3`}>
                                <div><span>당첨자발표 내용</span></div>
                                <div>
                                <TextEditor setData={setData} admin={admin} fileName={fileName} onDetailContent={onDetailContent} setFileName={setFileName} update={update} data={data} />
                                </div>
                            </div>
                            <div className={admin.admin_event_participants_search}>
                                <div><span>참여자 검색</span></div>
                                <div><input type="text" onChange={onSearchValue} /></div>
                            </div>
                            <div className={`${admin.admin_point_modal_content}`}>
                            <div>
                                <div><span>참여자 리스트</span></div>
                                <div><span>당첨자 리스트</span></div>
                            </div>
                            <div>
                                <div className={`${admin.admin_point_modal_content_total_user_list}`}>
                                    {
                                        filteredUserList.map((user, index) => (
                                            <div key={index}>
                                                <div><input 
                                                    type="checkbox" 
                                                    checked={selectedUsers.some(selectedUser => selectedUser.userNo === user.userNo)} 
                                                    onChange={() => handleUserCheckboxChange(user)} 
                                                /></div>
                                                <div className='ps-1'>
                                                    <div><span>{user.userName !== null ? user.userName : '이름없음'}</span>&nbsp;/&nbsp;</div>
                                                    <div><span>{user.userId}</span>&nbsp;/&nbsp;</div>
                                                    <div><span>{user.userEmail}</span></div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className={`${admin.admin_point_modal_content_select_user_list}`}>
                                    {
                                        selectedUsers.map((user, index) => (
                                            <div key={index}>
                                                <div><input type="checkbox" checked={true} onChange={() => handleUserCheckboxChange(user)} /></div>
                                                <div className='ps-1'>
                                                    <div><span>{user.userName !== null ? user.userName : '이름없음'}</span>&nbsp;/&nbsp;</div>
                                                    <div><span>{user.userId}</span>&nbsp;/&nbsp;</div>
                                                    <div><span>{user.userEmail}</span></div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                        </div>
                        <div className={admin.admin_event_winner_modal_footer}>
                            <div><button onClick={() => setShowEventWinnerModal(false)}>취소</button></div>
                            <div><button onClick={handleEventWinnerAdd}>등록</button></div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default AdminWinner;
