import React, { useEffect, useRef, useState } from 'react';
import admin from '../../../admin.module.css';
import * as support from '../../../../apis/support';
import * as adminApi from '../../../../apis/admin';

const AdminPageSetting = () => {
    const backgroundImageInputRef = useRef(null);
    const sideImageInputRef = useRef(null);
    const splashImageInputRef = useRef(null);
    const [ backgroundImage, setBackgroundImage ] = useState('');
    const [ backgroundImageName, setBackgroundImageName ] = useState('');
    const [ sideImage, setSideImage ] = useState('');
    const [ sideImageName, setSideImageName ] = useState('');
    const [ splashImage, setSplashImage ] = useState('');
    const [ splashImageName, setSplashImageName ] = useState('');

    useEffect(() => {
        pageSettingSelect();
    }, [])

    const pageSettingSelect = async () => {
        const response = await adminApi.pageSettingSelect();
        const data = response.data;

        // console.log('pageSetting', data);
        setBackgroundImage(data.backgroundImg);
        setSideImage(data.sideImg);
        setSplashImage(data.splashImg);

        try {
            if(backgroundImage != null) {
                const queryParams1 = backgroundImage.split('?')[1];
                let filePath1 = '';
                const params1 = queryParams1.split('&');
    
                for (let param1 of params1) {
                    if (param1.startsWith('file=')) {
                        filePath1 = decodeURIComponent(param1.split('=')[1]);
                        break;
                    }
                }
    
                const fileName1 = filePath1.split('/').pop();
                // console.log('fileName1 : ', fileName1);
                setBackgroundImageName(fileName1);
            }

            if(sideImage != null) {
                const queryParams2 = sideImage.split('?')[1];
                let filePath2 = '';
                const params2 = queryParams2.split('&');
    
                for (let param2 of params2) {
                    if (param2.startsWith('file=')) {
                        filePath2 = decodeURIComponent(param2.split('=')[1]);
                        break;
                    }
                }
    
                const fileName2 = filePath2.split('/').pop();
                setSideImageName(fileName2);
            }

            if(splashImage != null) {
                const queryParams3 = splashImage.split('?')[1];
                let filePath3 = '';
                const params3 = queryParams3.split('&');
                
                for (let param3 of params3) {
                    if (param3.startsWith('file=')) {
                        filePath3 = decodeURIComponent(param3.split('=')[1]);
                        break;
                    }
                }
    
                const fileName3 = filePath3.split('/').pop();
                // console.log('fileName3 : ', fileName3);
                setSplashImageName(fileName3);
            }

        } catch (error) {
            
        }
    }
    // 백그라운드 이미지 파일 변경 핸들러
    const handleBackgroundImageChange = async(e) => {
        const file = e.target.files[0];
        if (file) {
        // 파일 크기가 5MB를 초과하는지 검사
        if(file.size > 5 * 1024 * 1024) {
            alert("파일 크기는 5MB를 초과할 수 없습니다.");
            return; // 함수 종료
        }

        const formData = new FormData();
        formData.append('image', file);

        const config = {
            headers: {
            'content-type': 'multipart/form-data'
            }
        };

      const response = await support.uploadImageToServer(formData, config);
      const data = response.data;

      // console.log('data : ', data);
      if(data != '') {
          const imageUrl = `${process.env.REACT_APP_API_SERVER}/img?file=${encodeURIComponent(data)}`;
    
          // console.log(imageUrl);
          // setImageListImgPreview4(imageUrl);
    
          // setMainImage(URL.createObjectURL(file));
          setBackgroundImage(imageUrl);

          const backgroundImgResponse = await adminApi.backgroundImgUpload(imageUrl);
          const backgroundImgData = backgroundImgResponse.data;

          // console.log('backgroundImgData : ', backgroundImgData);

          if(backgroundImgData === 'SUCCESS') {
            pageSettingSelect();
          } else {
            alert('업로드에 실패했습니다.')
          }
      }
    }
  };

    const handleRepresentativeDeleteClick = async () => {
        try {
            const queryParams = backgroundImage.split('?')[1];
            let filePath = '';
            const params = queryParams.split('&');
            for (let param of params) {
                if (param.startsWith('file=')) {
                    filePath = decodeURIComponent(param.split('=')[1]);
                    break;
                }
            }
            const fileName = filePath.split('/').pop();
            // console.log('fileName : ', fileName);
            await fetch(`/api/file/delete/${fileName}`, { method: 'DELETE' });
            setBackgroundImage('');
        } catch (error) {
            console.error('Failed to delete image from server', error);
        }
    };

    // 사이드 이미지 파일 변경 핸들러
    const handleSideImageChange = async(e) => {
        const file = e.target.files[0];
        if (file) {
            // 파일 크기가 5MB를 초과하는지 검사
            if(file.size > 5 * 1024 * 1024) {
                alert("파일 크기는 5MB를 초과할 수 없습니다.");
                return; // 함수 종료
            }

            const formData = new FormData();
            formData.append('image', file);

            const config = {
                headers: {
                'content-type': 'multipart/form-data'
                }
            };

            const response = await support.uploadImageToServer(formData, config);
            const data = response.data;

            // console.log('data : ', data);
            if(data != '') {
                const imageUrl = `${process.env.REACT_APP_API_SERVER}/img?file=${encodeURIComponent(data)}`;

                // console.log(imageUrl);
                // setImageListImgPreview4(imageUrl);

                // setMainImage(URL.createObjectURL(file));
                setSideImage(imageUrl);

                const sideImgResponse = await adminApi.sideImgUpload(imageUrl);
                const sideImgData = sideImgResponse.data;

                // console.log('sideImgData : ', sideImgData);
                if(sideImgData === 'SUCCESS') {
                    pageSettingSelect();
                } else {
                    alert('업로드 실패!');
                }
            }
        }
    };

    // 스플래쉬 이미지 파일 변경 핸들러
    const handleSplashImageChange = async(e) => {
        const file = e.target.files[0];
        if (file) {
            // 파일 크기가 5MB를 초과하는지 검사
            if(file.size > 5 * 1024 * 1024) {
                alert("파일 크기는 5MB를 초과할 수 없습니다.");
                return; // 함수 종료
            }

            const formData = new FormData();
            formData.append('image', file);

            const config = {
                headers: {
                'content-type': 'multipart/form-data'
                }
            };

            const response = await support.uploadImageToServer(formData, config);
            const data = response.data;

            // console.log('data : ', data);
            if(data != '') {
                const imageUrl = `${process.env.REACT_APP_API_SERVER}/img?file=${encodeURIComponent(data)}`;

                // console.log(imageUrl);
                // setImageListImgPreview4(imageUrl);

                // setMainImage(URL.createObjectURL(file));
                setSplashImage(imageUrl);

                const splashImgResponse = await adminApi.splashImgUpload(imageUrl);
                const splashImgData = splashImgResponse.data;

                // console.log('splashImgData : ', splashImgData);

                if(splashImgData === 'SUCCESS') {
                    pageSettingSelect();
                } else {
                    alert('업로드 실패!');
                }
            }
        }
    };

  const handleBackgroundImageUploadClick = () => {
    backgroundImageInputRef.current.click();
  };
  const handleSideImageUploadClick = () => {
    sideImageInputRef.current.click();
  };
  const handleSplashImageUploadClick = () => {
    splashImageInputRef.current.click();
  };
  return (
    <div className={`${admin.admin_operate_pagesetting_box} px-3 py-3`}>
        <div>
            <div className={`${admin.admin_operate_pagesetting_title}`}><span>페이지 세팅</span></div>
            <div className={`${admin.admin_operate_pagesetting_sub_title} pb-3`}><span>추가 시 기존 이미지를 대체합니다.</span></div>
            <div className={`${admin.admin_operate_pagesetting_content}`}>
                <div><span>백그라운드 이미지</span></div>
                <div><span>PC 화면에서 전체 백그라운드 이미지에 노출됩니다. <br />(1920px x 1080px)</span></div>
                <div>
                    <div className={`${admin.admin_operate_pagesetting_btn} py-1`}>
                    <input
                        type="file"
                        ref={backgroundImageInputRef}
                        onChange={handleBackgroundImageChange}
                        style={{ display: 'none' }}
                      />
                        <div><button onClick={() => handleBackgroundImageUploadClick()}>추가</button></div>
                        <div className='ps-2'><button onClick={() => handleRepresentativeDeleteClick()}>삭제</button></div>
                    </div>
                    <div className={`${admin.admin_operate_pagesetting_content}`}>
                        <div className={`${admin.admin_operate_pagesetting_content_header}`}>
                            <div><span>이미지</span></div>
                            <div>이미지 이름</div>
                        </div>
                        <div className={`${admin.admin_operate_pagesetting_content_info}`}>
                            {
                                backgroundImage != null ?
                                <div>
                                    <div><img src={backgroundImage} alt="" /></div>
                                    <div><span>{backgroundImageName}</span></div>
                                </div>
                                :
                                <div>
                                    <div><span>등록된 이미지가 없습니다.</span></div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${admin.admin_operate_pagesetting_content} mt-2`}>
                <div><span>사이드 이미지</span></div>
                <div><span>PC 화면에서 사이드 이미지에 노출됩니다. <br />(400px x 1000px)</span></div>
                <div>
                    <div className={`${admin.admin_operate_pagesetting_btn} py-1`}>
                        <input
                            type="file"
                            ref={sideImageInputRef}
                            onChange={handleSideImageChange}
                            style={{ display: 'none' }}
                        />
                        <div><button onClick={() => handleSideImageUploadClick()}>추가</button></div>
                        <div className='ps-2'><button>삭제</button></div>
                    </div>
                    <div className={`${admin.admin_operate_pagesetting_content}`}>
                        <div className={`${admin.admin_operate_pagesetting_content_header}`}>
                            <div><span>이미지</span></div>
                            <div>이미지 이름</div>
                        </div>
                        <div className={`${admin.admin_operate_pagesetting_content_side_info}`}>
                            {
                                sideImage != null ?
                                <div>
                                    <div><img src={sideImage} alt="" /></div>
                                    <div><span>{sideImageName}</span></div>
                                </div>
                                :
                                <div><span>등록된 이미지가 없습니다.</span></div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${admin.admin_operate_pagesetting_content} mt-2`}>
                <div><span>스플래시 이미지</span></div>
                <div><span>PC,Mobile 화면에서 메인 이미지에 노출됩니다. <br />(500px x 1000px)</span></div>
                <div>
                    <div className={`${admin.admin_operate_pagesetting_btn} py-1`}>
                        <input
                            type="file"
                            ref={splashImageInputRef}
                            onChange={handleSplashImageChange}
                            style={{ display: 'none' }}
                        />
                        <div><button onClick={() => handleSplashImageUploadClick()}>추가</button></div>
                        <div className='ps-2'><button>삭제</button></div>
                    </div>
                    <div className={`${admin.admin_operate_pagesetting_content}`}>
                        <div className={`${admin.admin_operate_pagesetting_content_header}`}>
                            <div><span>이미지</span></div>
                            <div>이미지 이름</div>
                        </div>
                        <div className={`${admin.admin_operate_pagesetting_content_side_info}`}>
                            {
                                splashImage != null ?
                                <div>
                                    <div><img src={splashImage} alt="" /></div>
                                    <div><span>{splashImageName}</span></div>
                                </div>
                                :
                                <div><span>등록된 이미지가 없습니다.</span></div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AdminPageSetting