import React, { useContext, useEffect, useState } from 'react';
import admin from '../../../admin.module.css';
import { CSSTransition } from 'react-transition-group';
import { useNavigate } from 'react-router-dom';
import * as adminApi from '../../../../apis/admin';
import { LoginContext } from '../../../../contexts/LoginContextProvider';

const AdminDeliverySetting = () => {
    const { userInfo, roles } = useContext(LoginContext);
    const [expandedIndex, setExpandedIndex] = useState(null); // 현재 확장된 템플릿의 인덱스 저장
    const navigate = useNavigate();

    const [deliveryList, setDeliveryList] = useState([]);

    useEffect(() => {
        if(userInfo) {
            if(roles.isAdmin) {
                adminDeliveryTemplate();
            } else {
                deliveryTemplate();
            }
        }
    }, [userInfo]);

    const deliveryTemplate = async () => {
        const response = await adminApi.deliveryTemplate(userInfo?.userId);
        const data = response.data;

        // console.log('deliveryTemplate : ', data);
        setDeliveryList(data);
    }

    const adminDeliveryTemplate = async () => {
        const response = await adminApi.adminDeliveryTemplate();
        const data = response.data;

        // console.log('adminDeliveryTemplate : ', data);
        setDeliveryList(data);
    }

    const onDeliveryAdd = () => {
        navigate('/admin/shop/delivery/add');
    }

    const toggleDelivery = (index) => {
        setExpandedIndex(expandedIndex === index ? null : index); // 클릭된 항목을 토글
    }

    return (
        <div className={`${admin.admin_shop_delivery_setting_box} px-3 py-3`}>
            <div>
                <div className={`${admin.admin_shop_delivery_setting_header}`}><span>배송템플릿</span></div>
                <div className={`${admin.admin_shop_delivery_setting_btn}`}><button onClick={() => onDeliveryAdd()}>배송템플릿 추가</button></div>
                <div>
                    <div>
                        <div className={`${admin.admin_shop_delivery_setting_content_header}`}>
                            <div><span>템플릿번호</span></div>
                            <div><span>템플릿설정</span></div>
                            <div><span>템플릿이름</span></div>
                            <div><span></span></div>
                        </div>
                        <div>
                            {
                                Array.isArray(deliveryList) && deliveryList.map((item, index) => (
                                    <div key={index}>
                                        <div 
                                            className={`${admin.admin_shop_delivery_setting_content_content}`} 
                                            onClick={() => toggleDelivery(index)} // 클릭된 인덱스를 전달
                                        >
                                            <div><span>{index + 1}</span></div>
                                            <div><span>{item.dtState}</span></div>
                                            <div><span>{item.dtName}</span></div>
                                            <div className='pe-2'><span className="material-symbols-outlined">{expandedIndex === index ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</span></div>
                                        </div>

                                        <CSSTransition in={expandedIndex === index} timeout={300} classNames="review" unmountOnExit>
                                            <div className={`${admin.admin_shop_delivery_setting_content_detail_content}`}>
                                                <div>
                                                    <div><span>배송 및 결제방법</span></div>
                                                    <div><span>{item.dtDeliveryType} / {item.dtDeliveryPm}</span></div>
                                                </div>
                                                <div>
                                                    <div><span>기본 택배사</span></div>
                                                    <div><span>{item.dtDeliveryCompany}</span></div>
                                                </div>
                                                <div>
                                                    <div><span>기본 배송비</span></div>
                                                    <div><span>KRW {item.dtDeliveryPrice.toLocaleString()}</span></div>
                                                </div>
                                                <div>
                                                    <div><span>무료배송 조건</span></div>
                                                    <div><span>KRW {item.dtDeliveryFree.toLocaleString()} 이상 구매시</span></div>
                                                </div>
                                                <div>
                                                    <div><span>지역별배송비</span></div>
                                                    <div>
                                                        <div><span>제주도 추가 배송비 | KRW {item.dtJejuAddPrice.toLocaleString()}</span></div>
                                                        <div><span>도서산간 추가 배송비 | KRW {item.dtOtherAddPrice.toLocaleString()}</span></div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div><span>해외 배송비</span></div>
                                                    <div><span>KRW {item.overseasShippingCost.toLocaleString()}</span></div>
                                                </div>
                                                <div>
                                                    <div><span>반품 배송비</span></div>
                                                    <div><span>KRW {item.dtReturnDeliveryPrice.toLocaleString()}</span></div>
                                                </div>
                                                <div>
                                                    <div><span>교환 배송비</span></div>
                                                    <div><span>KRW {item.dtExchangeDeliveryPrice.toLocaleString()}</span></div>
                                                </div>
                                                <div>
                                                    <div><span>출고지</span></div>
                                                    <div>
                                                        <div><span>{item.dtShippingAddress}</span></div>
                                                        <div><span>{item.dtShippingAddressDetail}</span></div>
                                                        <div><span>{item.dtShippingAddressZipcode}</span></div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div><span>반품/교환지</span></div>
                                                    <div>
                                                        <div><span>{item.dtReturnLocation}</span></div>
                                                        <div><span>{item.dtReturnLocationDetail}</span></div>
                                                        <div><span>{item.dtReturnLocationZipcode}</span></div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div><span>대표연락처</span></div>
                                                    <div><span>{item.dtCompanyTel}</span></div>
                                                </div>
                                            </div>
                                        </CSSTransition>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminDeliverySetting;
